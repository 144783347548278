import Controller from './form-submission-error.controller';
import template from './form-submission-error.html';

const Component = {
    template,
    require: {
        form: '^form',
    },
    bindings: {
        overrideText: '@?',
        customClass: '@?',
    },
    controller: Controller,
};

export default Component;
