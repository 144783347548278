import { Injectable, Inject } from '@angular/core';
import { CR_CONFIG } from '../config';
import { Config } from '../../../core';
import { AnalyticsService } from '../analytics/analytics.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorLoggingService {
  constructor(@Inject(CR_CONFIG) private config: Config, private analyticsService: AnalyticsService) {}

  logError(message: string, err?: Error, data?: any, isJsError?: boolean) {
    if (!this.config.customer) {
      this.config.customer = {
        env: 'local',
        localizationEnabled: false,
      };
    }

    const isLocal = this.config.customer.env === 'local';
    const isProd = this.config.customer.env === 'prod';

    // Only log to console when env is not prod
    if (!isProd) {
      console.error(message, err, data);
    }

    // Only send analytics when env is not local
    if (!isLocal) {
      const payload: { message: string; err: any; data: any } = { message, err, data };

      if (err && !payload.err.status) {
        payload.err = err.stack;
      }

      const trackingName = isJsError ? message : 'Error';
      this.analyticsService.track(trackingName, payload);
    }
  }
}
