<div class="nav-header">
    <nav class="nav-item-container" role="navigation">
        <div
            (click)="navigate(NavLocation.Reservations)"
            class="nav-item"
            [ngClass]="{ selected: navLocation === NavLocation.Reservations }"
        >
            {{ text.reservationListTitle }}
        </div>
        <div
            *ngIf="showDiningLocations"
            (click)="navigate(NavLocation.DiningLocations)"
            class="nav-item"
            [ngClass]="{
                selected: navLocation === NavLocation.DiningLocations
            }"
        >
            {{ text.diningLocationsTitle }}
        </div>
    </nav>
</div>
