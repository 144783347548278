class BeaconsMapTooltipController {
    constructor($state) {
        this.$state = $state;
    }

    goToDetailsPage(id) {
        const params = {
            id,
            customerId: this.$state.params.customerId,
        };

        this.$state.go('client.beacons.details', params);
    }
}

const BeaconsMapTooltipComponent = {
    template:
        '<div class="details-link" ng-click="$ctrl.goToDetailsPage($ctrl.resolve.id)">{{ ::$ctrl.resolve.label }}</div>',
    bindings: {
        resolve: '<',
    },
    controller: ['$state', BeaconsMapTooltipController],
};

export default BeaconsMapTooltipComponent;
