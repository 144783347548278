import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import moment from 'moment';
import { LocalizedText } from '../../../../core';
import text from './resources/en.json';

@Component({
  selector: 'cr-ptw-remove-all-info',
  templateUrl: './ptw-remove-all-info.component.html',
  styleUrls: ['./ptw-remove-all-info.component.scss']
})
export class PtwRemoveAllInfoComponent{

  @Input() modalRef: BsModalRef

  @Input() submittedOrderCount: number

  text: LocalizedText

  date: string;

  constructor() {
    this.text = text;
    this.date = moment().format('L')
   }

}
