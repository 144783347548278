import _ from 'lodash';

class EventsListService {
    constructor(crEntityService, crImageService, crConstants) {
        this.crEntityService = crEntityService;
        this.crImageService = crImageService;
        this.crConstants = crConstants;
    }

    getData(params) {
        const queryParams = _.pickBy(params, (value) => !_.isNil(value) && value !== '' && value !== 'all');

        return this.crEntityService.getEntityList(this.crConstants.entity.types.EVENTS, queryParams);
    }

    getCategories(venueId, locale) {
        return this.crEntityService.getCategoriesWithHierarchy(
            this.crConstants.entity.types.EVENT_CATEGORIES,
            venueId,
            locale
        );
    }

    mapToRowsModel(events, categories) {
        return events.map((event) => {
            const categoriesMap = _.keyBy(categories, 'id');

            const row = {
                data: {
                    id: event.id,
                    stickerSrc: this.crImageService.getStickerThumbSrc(event.images),
                    name: {
                        label: event.name,
                    },
                    title: {
                        label: event.title,
                    },
                    categories: (event.displayCategories || []).map((category) => {
                        const matchedCategory = categoriesMap[category.id];

                        return matchedCategory ? matchedCategory.hierarchyLabel : category.label;
                    }),
                    location: (event.associatedPois || []).map((poi) => poi.label),
                    state: {
                        label: event.state.toLowerCase(),
                    },
                    missingTranslations: _.isArray(event.missingTranslations)
                        ? event.missingTranslations.join(', ')
                        : '',
                    originalData: event,
                },
            };

            return row;
        });
    }
}

export default EventsListService;
