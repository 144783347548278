import angular from 'angular';

import component from './fnb-reporting.component';
import FnbReportingService from './fnb-reporting.service';

const FnbReporting = angular
    .module('control-room.fnb-reporting', [])
    .service('fnbReportingService', ['crCommerceService', 'crPlacesService', 'crConfig', '$q', FnbReportingService])
    .component('crFnbReporting', component);

export default FnbReporting;
