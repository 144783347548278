import { Component, OnInit } from "@angular/core";
import { StateService } from "@uirouter/core";
import { sanitize } from 'dompurify';
import { LocalizedText } from '../../../../core';
import { Place } from "../../../../shared/models/places.model";
import { PlacesService } from "../../../../shared/services/places/places.service";
import { menuRoutes } from "../../menus.routes";
import text from './resources/en.json';
@Component({
    selector: "cr-id-detail-content",
    templateUrl: "./id-detail-content.component.html",
    styleUrls: ["./id-detail-content.component.scss"]
})
export class IdDetailContentComponent implements OnInit {
    phonePreview: {
        idDetailTitle: string,
        idDetailDescription: string
    };

    venueData: Place;

    text: LocalizedText;

    idDetailLabel: string;

    sanitize: (html: string) => string;

    isLoading = false;

    constructor(private state: StateService, private crPlacesService: PlacesService) {
        this.text = text;
        this.sanitize = sanitize;
    }

    async ngOnInit() {
       await this.initVenueData();
    }

    async initVenueData() {
        this.isLoading = true;
        try {
            const res: Place = await this.crPlacesService.getPlace(`venue:${this.state.params.venueId}`);
            this.venueData = res;
            this.idDetailLabel = this.venueData?.webContent?.idDetailLabel?.length > 0 ? this.venueData.webContent.idDetailLabel : this.text.pageTitle;
            this.updatePhonePreview();
        } catch (err) {
            console.log(err);
        } finally {
            this.isLoading = false;
        }
    }

    updatePhonePreview(): void {
        if(this.venueData?.webContent?.idDetailTitle && 
            this.venueData?.webContent?.idDetailDescription )
        this.phonePreview = {
            idDetailTitle: this.venueData.webContent.idDetailTitle,
            idDetailDescription: this.sanitize(this.venueData.webContent.idDetailDescription)
        }
    }

    goToEditPage(): void {
        this.state.go(menuRoutes.EDIT_ID_DETAIL_CONTENT, { edit: true });
    }

}