<cr-split-view-page pageClass="cr-edit details-page">
  <cr-title-bar label="{{ pageHeader }}">
    <div titleBarCenter>
      <cr-form-submission-error
        *ngIf="isSubmitted && form.invalid"
        [message]="text.formSubmissionErrorText"
      ></cr-form-submission-error>
    </div>
    <div titleBarRight>
      <button
        (click)="goBack()"
        class="btn btn-nobg"
        data-global-qa-id="manage-cancel"
      >
        {{ text.cancel }}
      </button>
      <button
        (click)="onSave()"
        class="btn btn-primary"
        data-global-qa-id="manage-save"
      >
        {{ text.save }}
      </button>
    </div>
  </cr-title-bar>
  <div *ngIf="isLoading || isSaving" top>
    <div class="text-center cr-page-padding-top">
      <cr-spinner></cr-spinner>
    </div>
  </div>
  <section *ngIf="!isSaving" left class="cr-left">
    <form [formGroup]="form">
      <cr-entity-header
        manage="true"
        [entity]="bundleContent"
        (onUpdate)="onHeaderUpdated($event)"
      ></cr-entity-header>
      <cr-entity-content
        manage="true"
        [locale]="locale"
        [entity]="bundleContent"
        (onUpdate)="onContentUpdated($event)"
      ></cr-entity-content>
      <hr class="details-divider" />
      <cr-section-heading>
        <div>{{ text.presentationSectionLabel }}</div>
      </cr-section-heading>
      <cr-form-label-error
        for="categories"
        data-global-qa-id="entity-categories-error"
      >
        <span>{{ text.categoryLabel }}</span> *
      </cr-form-label-error>
      <cr-chip-selector
        class="cr-stretch"
        sort="asc"
        [currentTags]="selectedCategoryChips"
        [options]="categoryChips$ | async"
        [addButton]="true"
        [isLoading]="isLoadingCategories"
        (onSelect)="onCategorySelected($event)"
      ></cr-chip-selector>
      <hr class="details-divider" />
      <cr-section-heading>
        <div>{{ text.itemDetailsHeader }}</div>
      </cr-section-heading>
      <div class="input-row">
        <cr-input
          label="{{ text.priceLabel }} ({{ currencyCode }})*"
          type="number"
          [validationMessages]="validationMessages"
          formControlName="price"
        ></cr-input>
      </div>
      <div class="input-row" *ngIf="showTaxCodes">
        <cr-input
          label="{{ text.productTaxCodeLabel }} *"
          formControlName="productCode"
        ></cr-input>
      </div>
      <div>
        <cr-input
          label="{{ text.maxQuantityLabel }} *"
          formControlName="maxQuantity"
          [validationMessages]="validationMessages"
          type="number"
        ></cr-input>
      </div>

      <hr class="details-divider" />
      <cr-section-heading>
        <div>{{ text.bundleGroupsHeader }}</div>
      </cr-section-heading>
      <ng-template #bundleGroupItemTemplate let-bundleGroup="item">
        <div class="bundle-group-item-container">
          <div class="bundle-group-label">
            <div>{{ bundleGroup.displayName }}</div>
          </div>
          <div class="cr-stretch">
            <div>
              <span class="cr-label"
                >Required quantity of menu items to order</span
              >
            </div>
            <div>{{ bundleGroup.min }}</div>
            <div>
              <div>
                <span class="cr-label">Menu Items</span>
              </div>
              <div *ngFor="let bundleProduct of bundleGroup.bundledProducts">
                {{ bundleProduct.displayName }} ({{ text.maxQuantity }}:
                {{ bundleProduct.maxQuantity }}, {{ text.upsellPrice }}:
                {{ bundleProduct.upsellPrice }})
              </div>
            </div>
          </div>
          <div>
            <a
              class="cursor-pointer icon-cr-close"
              (click)="removeBundleGroup(bundleGroup.id)"
            ></a>
          </div>
        </div>
      </ng-template>
      <ng-container
        *ngIf="selectedBundleGroups$ | async as selectedBundleGroups"
      >
        <cr-reorder-list
          class="bundle-reorder"
          *ngIf="selectedBundleGroups.length > 0"
          formArrayName="productBundleGroupIds"
          [items]="selectedBundleGroups"
          [itemTemplate]="bundleGroupItemTemplate"
          (reorder)="reorder($event)"
        ></cr-reorder-list>
      </ng-container>
      <div class="add-bundle-group-container">
        <cr-dropdown
          mode="filter"
          label="{{ text.addBundleGroup }}"
          [hideResetOption]="true"
          (onSelect)="onBundleGroupSelectionChange($event)"
          custom-class="bundle-group-dropdown"
          [menuItems]="bundleGroupOptions$ | async"
          [showSearch]="true"
          [hideCurrentSelection]="true"
        ></cr-dropdown>
        <cr-form-label-error
          for="productBundleGroupIds"
          [errorMessages]="{ required: validationMessages.requiredBundles }"
          data-global-qa-id="entity-categories-error"
        >
          <!-- empty on purpose -->
        </cr-form-label-error>
      </div>

      <hr class="details-divider" />

      <div class="detail-section-title">
        <cr-section-heading>
          <div>{{ text.pointOfSale }}</div>
        </cr-section-heading>
        <div class="input-row">
          <cr-input
            label="{{ text.externalId }}"
            formControlName="externalId"
          ></cr-input>
        </div>
        <div>
          <cr-input
            label="{{ text.pricingCode }}"
            formControlName="externalPriceCode"
          ></cr-input>
        </div>
      </div>
      <cr-required-field></cr-required-field>
    </form>
  </section>
  <section class="cr-right" right></section>
</cr-split-view-page>
