import AttributesBuilderRowController from './attributes-builder-row.controller';

import template from './attributes-builder-row.html';
import './attributes-builder-row.less';

const AttributesBuilderRowComponent = {
    template,
    bindings: {
        row: '<',
        isSubmitted: '<',
    },
    require: {
        form: '^form',
        crRowBuilder: '^crRowBuilder',
    },
    controller: ['crConstants', '$timeout', AttributesBuilderRowController],
};

export default AttributesBuilderRowComponent;
