import { Component, Input } from '@angular/core';

@Component({
  selector: 'cr-split-view-page',
  templateUrl: './split-view-page.component.html',
  styleUrls: ['./split-view-page.component.scss'],
})

export class SplitViewComponent {
  @Input() pageClass = '';
}
