import controller from './venue-hours-phone-preview.controller';
import template from './venue-hours-phone-preview.html';
import './venue-hours-phone-preview.less';

const component = {
    template,
    bindings: {
        schedules: '<',
    },
    controller: ['crConfig', controller],
};

export default component;
