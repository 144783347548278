import _ from 'lodash';
import blueColorPalette from '../../../basic/constant/color-palette/resources/palettes/blue.json';

const defaultOptions = {
    axisY: {
        labelOffset: {
            x: 0,
            y: 8,
        },
    },
    height: 211,
    classNames: {
        label: 'cr-chart-label cr-stat',
    },
    stackBars: true,
    colorPalette: blueColorPalette,
    holeColor: '#ffffff',
    chartPadding: {
        top: 16,
    },
    plugins: [],
    holeWidth: '8px',
};

const miniDefaultOptions = _.merge(_.cloneDeep(defaultOptions), {
    height: 147,
    classNames: {
        label: 'cr-chart-label cr-graph-axis',
    },
});

export default {
    colorPalettes: {
        blue: blueColorPalette,
    },
    defaultOptions,
    miniDefaultOptions,
};
