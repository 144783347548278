class PoiTypeListService {
    constructor(poiService, crImageService) {
        this.poiService = poiService;
        this.crImageService = crImageService;
    }

    getPois(venueId) {
        return this.poiService
            .getPois({
                venueId,
            })
            .then((res) =>
                res.content.map((poi) => ({
                    stickerSrc: this.getStickerSrc(poi),
                    id: poi.id,
                    name: poi.name,
                    type: poi.type,
                }))
            );
    }

    getTypes(venueId) {
        return this.poiService
            .getPoiTypes({
                venueId,
            })
            .then((res) => res.content);
    }

    getStickerSrc(poi) {
        return this.crImageService.getStickerThumbSrc(poi.images);
    }

    create(name, id) {
        // TODO: handle PLACE as well
        return this.poiService.createPoiType(name, { id, type: 'VENUE' });
    }

    delete(id) {
        return this.poiService.deletePoiType(id);
    }

    update(id, name) {
        return this.poiService.updatePoiType(id, name);
    }
}

export default PoiTypeListService;
