<section>
    <header>
        <div class="cr-flex-between">
            <div class="icon-cr-back"></div>
            <div class="heading-text">{{ text.showsAndEvents }}</div>
            <div class="icon-cr-search"></div>
        </div>
    </header>

    <section class="featured-event-phone-container" *ngIf="showFeatured">
        <label
            class="text-capitalize"
            data-local-qa-id="featured-mobile-header"
        >
            {{ text.featuredEvent }}
        </label>

        <div>
            <div>
                <img
                    [src]="featuredImageSrc"
                    data-local-qa-id="featured-mobile-image"
                />
            </div>
            <div class="featured-data">
                <label data-local-qa-id="featured-mobile-title">{{
                    featuredTitle
                }}</label>
                <section class="metadata-item">
                    <div class="icon-cr-calendar"></div>
                    <div>
                        <div class="metadata-label">{{ text.dateAndTime }}</div>
                        <div
                            class="metadata-value"
                            data-local-qa-id="featured-mobile-time"
                        >
                            HH:MM
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </section>

    <section class="content-heading cr-flex-between cr-uppercase">
        <div>{{ text.today }}</div>
        <div
            class="trending-tab"
            *ngIf="showTrending"
            data-local-qa-id="trending-mobile-header"
        >
            {{ text.trending }}
        </div>
        <div class="icon-cr-calendar"></div>
    </section>

    <section class="trending-content-phone" *ngIf="showTrending">
        <section *ngFor="let item of featuredEvents.trending; index as i">
            <div class="image-container">
                <img
                    [src]="item.iconSrc"
                    attr.data-local-qa-id="trending-mobile-image-{{ i }}"
                />
            </div>
            <div class="metadata">
                <div attr.data-local-qa-id="trending-mobile-title-{{ i }}">
                    {{ item.title }}
                </div>
                <section class="cr-flex-between">
                    <section class="metadata-item">
                        <div class="icon-cr-calendar"></div>
                        <div>
                            <div class="metadata-label">
                                {{ text.dateAndTime }}
                            </div>
                            <div
                                class="metadata-value"
                                attr.data-local-qa-id="trending-mobile-time-{{
                                    i
                                }}"
                            >
                                HH:MM
                            </div>
                        </div>
                    </section>
                    <section
                        class="metadata-item"
                        *ngIf="item?.associatedPois?.length"
                    >
                        <div class="icon-cr-marker"></div>
                        <div>
                            <div class="metadata-label">
                                {{ text.location }}
                            </div>
                            <div
                                class="metadata-value"
                                attr.data-local-qa-id="trending-mobile-location-{{
                                    i
                                }}"
                            >
                                {{ item.locationText }}
                            </div>
                        </div>
                    </section>
                </section>
            </div>
        </section>
    </section>
</section>
