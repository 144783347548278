<div class="modal-header">
  <h4 class="modal-title pull-left">{{ text.title }} {{ date }}</h4>
  <button
    type="button"
    class="btn-close close pull-right"
    aria-label="Close"
    (click)="modalRef?.hide()"
  >
    <span aria-hidden="true" class="visually-hidden">&times;</span>
  </button>
</div>
<div class="modal-body master-detail" id="list">
  <div class="list-component">
    <div class="list-master" [ngStyle]="isFnbAdmin && {'height': '90%'}">
      <div *ngFor="let ptw of ptws" role="menuitem">
        <cr-ptw-list-item
          class="dropdown-item"
          (selectPTW)="selectPTW($event)"
          [ptw]="ptw"
        ></cr-ptw-list-item>
      </div>
    </div>
    <div *ngIf = "isFnbAdmin">
      <button
          class="btn btn-danger remove-all-btn"
          (click)="openModal(removeAll, ptwRemoveActionModal)"
        >
         {{text.removeAllPtw}}
      </button>
    </div>
  </div>
  <div class="list-details">
    <div class="detail-override">
      <div class="override-title">{{ text.override }}</div>
      <div class="override-desc">{{ text.overrideDesc }}</div>
      <div class="override">
        <button
          class="btn btn-danger override-btn"
          [disabled]="!enoughCapacity10"
          (click)="overridePTW(-10)"
        >
          -10
        </button>
        <button
          class="btn btn-danger override-btn"
          [disabled]="!enoughCapacity5"
          (click)="overridePTW(-5)"
        >
          -5
        </button>
        <button
          class="btn btn-danger override-btn"
          [disabled]="!enoughCapacity1"
          (click)="overridePTW(-1)"
        >
          -1
        </button>
        <div class="current-capacity">
          <span class="current-capacity-header">{{ text.capacity }}</span>
          <span class="current-capacity-count">{{ selectedPTW.capacity }}</span>
        </div>
        <button class="btn btn-success override-btn" (click)="overridePTW(1)">
          +1
        </button>
        <button class="btn btn-success override-btn" (click)="overridePTW(5)">
          +5
        </button>
        <button class="btn btn-success override-btn" (click)="overridePTW(10)">
          +10
        </button>
      </div>
    </div>
    <div class="detail-summary">
      <div class="detail-summary-header">
        {{ selectedPTW.startTime }} - {{ selectedPTW.endTime }}
        {{ text.summary }}
      </div>
      <div class="order-summary-info">
        <div class="orders-info">
          <div *ngFor="let info of selectedWindowInfo">
            <div class="info-title">{{ info.title }}</div>
            <div class="info-data">{{ info.data }}</div>
          </div>
        </div>
        <div class="orders-status">
          <div *ngFor="let info of statusWindowInfo">
            <div class="info-title">{{ info.title }}</div>
            <div class="info-data">{{ info.data }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<ng-template #removeAll>
  <cr-ptw-remove-all [modalRef]="removeAllModalRef" (onRemoveAll)="onRemoveAll()"> </cr-ptw-remove-all>
</ng-template>

<ng-template #removeAllInfo>
  <cr-ptw-remove-all-info [modalRef]="removeAllInfoModalRef" [submittedOrderCount]="submittedOrderCount"> </cr-ptw-remove-all-info>
</ng-template>
