import { Injectable, Inject } from '@angular/core';
import { StateService, TransitionService } from '@uirouter/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import _ from 'lodash';

import { LocalizedText } from '../../../core';
import { WINDOW } from '../window';
import { SimpleModalComponent } from '../../content/simple-modal/simple-modal.component';

import text from './resources/locale/en.json';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  text: LocalizedText = text;

  history: any[];

  modalText: { message: string; submit: string };

  discardModalText: { message: string; submit: string };

  ignoreNextAddState: boolean;

  confirmNavigationEnabled: boolean;

  confirmModal: BsModalRef;

  conditionalLambda: () => boolean;

  deregisterStateListener: Function;

  constructor(
    @Inject(WINDOW) private window: Window,
    private state: StateService,
    private transitions: TransitionService,
    private modalService: BsModalService,
  ) {
    this.discardModalText = {
      message: this.text.discardModalMessage,
      submit: this.text.discardModalSubmit,
    };

    this.ignoreNextAddState = false;
    this.confirmNavigationEnabled = false;
    this.history = [];
  }

  clearHistory() {
    this.history.length = 0;
  }

  addState(from, to) {
    if (this.ignoreNextAddState) {
      this.ignoreNextAddState = false;
      return;
    }

    if (this.history.length) {
      if (from.state === to.state) {
        this.history.pop();

        return;
      }
    }

    this.history.push({
      from,
      to,
    });
  }

  replaceState({ state, params }) {
    const lastTransition = this.history[this.history.length - 1];
    if (lastTransition) {
      lastTransition.to = {
        state,
        params,
      };
    }
  }

  replaceParams(params) {
    const lastTransition = this.history[this.history.length - 1];
    if (lastTransition) {
      lastTransition.to.params = params;
    }
  }

  initState(transition) {
    if (this.history.length === 0) {
      this.history.push({
        from: null,
        to: transition,
      });
    }
  }

  goBack(state?, params?) {
    if (this.confirmNavigationEnabled && this.conditionalLambda()) {
      return this.openConfirmModal()
        .then(() => {
          this.disableConfirmNavigation();
          this.goBack(state, params);
        })
        .catch(() => null);
    }
    if (this.confirmNavigationEnabled) {
      this.disableConfirmNavigation();
    }

    this.ignoreNextAddState = true;
    if (state) {
      this.state.go(state, params);
    } else if (this.history.length > 1) {
      // Pop current state off the history stack
      this.history.pop();

      const lastTransition = this.history[this.history.length - 1];

      params = _.merge({}, lastTransition.to.params, { toast: null }, params);

      this.state.go(lastTransition.to.state.name, params);
    }
  }

  goToFromCreate(state, params, options) {
    this.history.splice(-2);
    this.state.go(state, params, options);
  }

  disableConfirmNavigation() {
    this.confirmNavigationEnabled = false;
    this.conditionalLambda = () => false;

    if (this.confirmModal) {
      this.confirmModal.hide();
    }

    this.window.onbeforeunload = null;
    if (this.deregisterStateListener) {
      this.deregisterStateListener();
    }
  }

  enableConfirmNavigation(conditionalLambda, modalText = this.discardModalText) {
    this.confirmNavigationEnabled = true;
    this.modalText = modalText;
    this.conditionalLambda = conditionalLambda;

    this.window.onbeforeunload = (e) => {
      if (!this.conditionalLambda()) {
        return;
      }

      const { message } = modalText;
      e.returnValue = message;
      return message;
    };

    this.deregisterStateListener = this.transitions.onExit({}, (transition) => {
      const nextState = transition.to();
      const nextParams = transition.params();

      if (this.confirmNavigationEnabled && this.conditionalLambda()) {
        this.openConfirmModal()
          .then(() => {
            this.disableConfirmNavigation();
            this.goBack(nextState.name, nextParams);
          })
          .catch(() => null);

        return false;
      }
      if (this.confirmNavigationEnabled) {
        this.disableConfirmNavigation();
      }
    });
  }

  openConfirmModal() {
    this.confirmModal = this.modalService.show(SimpleModalComponent, {
      initialState: {
        message: this.modalText.message,
        submitText: this.modalText.submit,
        confirm: true,
      },
      backdrop: 'static',
      class: 'cr-modal-size-sm',
    });

    return this.confirmModal.content.result;
  }
}
