import angular from 'angular';
import text from './resources/locale/en.json';

import Component from './category-archive-modal.component';

const CategoryArchiveModalModule = angular
    .module('control-room.app.menus.category-archive-modal', [])
    .constant('crCategoryArchiveModalText', text)
    .component('crCategoryArchiveModal', Component);

export default CategoryArchiveModalModule;
