import locale from './resources/locale/en.json';

class EntityLocalizationService {
    constructor() {
        this.locale = locale;
    }

    getLocalizedEntityName(entityType, isPlural = true) {
        if (isPlural) {
            return this.locale.plural[entityType];
        }

        return this.locale.single[entityType];
    }
}

export default EntityLocalizationService;
