import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Printer } from "../../models/printer.model";

@Injectable({
    providedIn: 'root',
  })
export class PrintManagerService {
    private baseUrl = '/rest/printers';
    constructor(private http: HttpClient) {}

    getPrinters(placeId: string): Promise<Printer[]> {
        const path = `${this.baseUrl}/places/${placeId}/printers`;
        return this.http.get<Printer[]>(path, {}).toPromise();
    }

    updatePrinters(placeId: string, venueId: string, printers: Printer[]): Promise<Printer[]> {
        const path = `${this.baseUrl}/places/${placeId}/printers`;
        return this.http.put<Printer[]>(path, { venueId, printers }).toPromise();
    }
}
