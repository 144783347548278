import { Inject, Injectable } from '@angular/core';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { Constants } from '../../core';
import { CR_CONSTANTS } from '../services/constants/constants';

@Injectable({
  providedIn: 'root',
})
export class ValidatorService {
  constructor(@Inject(CR_CONSTANTS) private constants: Constants) {}

  get passwordValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const { value } = control;
      const passwordRegExp = this.constants.regex.PASSWORD;

      if (passwordRegExp.test(value)) {
        return null;
      }

      return { password: value };
    };
  }

  matchValidator(otherControl: AbstractControl): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const { value } = control;
      const match = otherControl.value;

      if (value === match || JSON.stringify(value) === JSON.stringify(match)) {
        return null;
      }

      return { match: { value, match } };
    };
  }

  noMatchValidator(otherControl: AbstractControl): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const { value } = control;
      const noMatch = otherControl.value;

      if (value === noMatch || JSON.stringify(value) === JSON.stringify(noMatch)) {
        return { noMatch: { value, noMatch } };
      }

      return null;
    };
  }

  get passwordRequirementsValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const { value } = control;

      const hasUpperCase = value?.length > 0 && this.constants.regex.UPPER_CASE.test(value);
      const hasLowerCase = value?.length > 0 && this.constants.regex.LOWER_CASE.test(value);
      const hasNumber = value?.length > 0 && this.constants.regex.NUMBER.test(value);
      const meetsRequirements = hasUpperCase && hasLowerCase && hasNumber;

      if (!meetsRequirements) {
        return { notMet: { value } };
      }
      
      return null;
    };
  }
}
