<div class="cr-modal">
    <div class="cr-modal-contents">
        <div class="cr-title">{{ text.sendRefundEmail }}</div>
        <p>
            {{ text.sendRefundEmailBody }}
        </p>
        <form [formGroup]="orderActionsForm">
            <cr-input
                type="email"
                name="emailControl"
                label="Email address"
                showValidMessage="emailControl.valid && emailControl.value"
                hideValidation="false"
                [formControl]="emailControl"
            ></cr-input>
        </form>
    </div>
    <div class="cr-modal-footer">
        <div class="cr-modal-buttons pull-right">
            <button class="btn btn-nobg" (click)="closeModal()">Cancel</button>
            <button
                class="btn btn-primary"
                (click)="sendEmailReceipt(emailControl.value)"
            >
                Send Now
            </button>
        </div>
    </div>
</div>
