import { Directive, ElementRef, Input } from '@angular/core';
import { PolicyService } from '../services/policy/policy.service';

@Directive({ selector: '[crPolicy]' })
export class CrPolicyDirective {
  @Input() policyAction: string;

  @Input() policyObject: string;

  @Input() prohibitedVisibility: string;

  constructor(private el: ElementRef, private ps: PolicyService) {}

  ngOnInit() {
    this.ps.hasAccess(this.policyAction, this.policyObject).then((allowed) => {
      if (!allowed) {
        const visibility = this.prohibitedVisibility || 'none';
        const property = visibility === 'none' ? 'display' : 'visibility';
        this.el.nativeElement.style[property] = visibility;
      }
    });
  }
}
