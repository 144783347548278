import PosEnableController from './pos-enable.controller';
import template from './pos-enable.html';
import './pos-enable.less';

const PosEnableComponent = {
    template,
    controller: [
        '$q',
        '$state',
        '$uibModal',
        '$timeout',
        'crPosEnableLocale',
        'crPosEnableService',
        'crAnalyticsService',
        'crErrorLoggingService',
        PosEnableController,
    ],
};

export default PosEnableComponent;
