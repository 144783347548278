import controller from './preferences-guidelines.controller';
import template from './preferences-guidelines.html';
import './preferences-guidelines.less';

const component = {
    template,
    bindings: {
        onClick: '&',
    },
    controller: [controller],
};

export default component;
