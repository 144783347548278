<div
    ngbDropdown
    #selectDropdown="ngbDropdown"
    autoClose="outside"
    (openChange)="onOpenChange($event)"
    data-global-qa-id="select-container"
    (keydown)="onKeydown($event)"
    class="select-container"
    [placement]="placement"
>
    <button
        ngbDropdownToggle
        #selectToggle
        type="button"
        [disabled]="isDisabled"
        data-local-qa-id="select-toggle"
        class="select-toggle"
        [ngbTooltip]="currentSelectionText"
        [disableTooltip]="disableSelectionTooltip"
        openDelay="300"
        (blur)="onBlur($event)"
        [ngClass]="{ 'is-open': isOpen }"
    >
        <div *ngIf="label" class="select-label cursor-pointer inline-block">
            {{ label }}
        </div>

        <div class="select-box cr-flex-between">
            <cr-spinner
                *ngIf="isLoading"
                small
                class="center-vertically"
            ></cr-spinner>
            <div class="image-container" *ngIf="currentIcon">
                <img [src]="currentIcon" />
            </div>
            <div class="selection-text" #selectionText>
                {{ currentSelectionText }}
            </div>
            <cr-icon
                class="icon clear"
                name="clear"
                *ngIf="enableClear && hasSelections"
                (click)="clearSelected($event)"
                data-local-qa-id="select-clear"
            ></cr-icon>
            <cr-icon class="icon dropdown-down" name="dropdown-down"></cr-icon>
        </div>
    </button>
    <div
        ngbDropdownMenu
        #selectMenu
        data-global-qa-id="dropdown-menu"
        [ngClass]="{
            'search-enabled': showSearch,
            'has-data-error': hasDataError,
            'not-ready': isOverflowing === null
        }"
        (mousemove)="enableMouseEvents()"
    >
        <ng-container *ngIf="showSearch">
            <div class="typeahead-filter" *ngIf="showSearch">
                <cr-search
                    #searchInput
                    [placeholder]="text.search"
                    [ngModel]="filter"
                    (focus)="onSearchFocus()"
                    (blur)="onSearchBlur()"
                    (update)="onSearchUpdate($event)"
                    (change)="$event.stopPropagation()"
                    class="cr-search-black"
                ></cr-search>
            </div>
            <div class="divider"></div>
        </ng-container>

        <div
            *ngIf="hasDataError"
            class="data-error"
            data-global-qa-id="select-data-error"
        >
            <cr-icon
                class="icon data-error-icon"
                name="error-bandaid"
            ></cr-icon>
            <div class="data-error-message">{{ text.dataErrorMessage }}</div>
        </div>

        <div class="loading-container" *ngIf="isLoading">
            <cr-spinner small></cr-spinner>
        </div>

        <div
            *ngIf="!filteredOptions.length && !isLoading && !hasDataError"
            class="no-results text-center"
        >
            {{ text.noResultsFound }}
        </div>

        <ng-container
            *ngIf="
                recents &&
                recents.length > 0 &&
                !filter &&
                !isLoading &&
                !hasDataError
            "
        >
            <h5 class="option-header t-section">{{ text.recent }}</h5>
            <button
                ngbDropdownItem
                type="button"
                *ngFor="let recent of recents; let i = index"
                #selectOption
                [disabled]="recent.disabled && !optionSelected(recent)"
                [ngClass]="{
                    indent: recent.indented,
                    selected:
                        optionSelected(recent) &&
                        !multi &&
                        !hideCurrentSelection,
                    highlighted: highlighted === recent
                }"
                [attr.data-local-qa-id]="'option-' + i"
                (click)="select(recent)"
                (focus)="onOptionFocus(recent)"
                (mouseover)="onOptionMouseover(recent, i)"
            >
                <div class="dropdown-icon-container" *ngIf="multi">
                    <cr-icon
                        class="icon done"
                        *ngIf="optionSelected(recent)"
                        name="done"
                    ></cr-icon>
                </div>
                <div class="image-container" *ngIf="recent.iconSrc">
                    <img [src]="recent.iconSrc" />
                </div>
                <span>{{ recent.label }}</span>
            </button>
            <div class="divider"></div>
        </ng-container>

        <h5
            class="option-header t-section"
            *ngIf="
                recents &&
                recents.length > 0 &&
                !filter &&
                !isLoading &&
                !hasDataError
            "
        >
            {{ text.all }}
        </h5>

        <button
            ngbDropdownItem
            type="button"
            *ngFor="let option of filteredOptions; let i = index"
            #selectOption
            [disabled]="option.disabled && !optionSelected(option)"
            [ngClass]="{
                indent: option.indented,
                selected:
                    optionSelected(option) && !multi && !hideCurrentSelection,
                highlighted: highlighted === option
            }"
            [attr.data-local-qa-id]="'option-' + i"
            (click)="select(option)"
            (focus)="onOptionFocus(option)"
            (mouseover)="onOptionMouseover(option, i)"
        >
            <div class="dropdown-icon-container" *ngIf="multi">
                <cr-icon
                    class="icon done"
                    *ngIf="optionSelected(option)"
                    name="done"
                ></cr-icon>
            </div>
            <div class="image-container" *ngIf="option.iconSrc">
                <img [src]="option.iconSrc" />
            </div>
            <span>{{ option.label }}</span>
        </button>
    </div>
</div>
