import {NgModule} from "@angular/core";
import {ReportSelectComponent} from "./components";
import {SharedModule} from '../../shared';
import {CommonModule} from "@angular/common";

@NgModule({
     imports: [
          SharedModule,
          CommonModule
     ],
  declarations: [ReportSelectComponent],
  providers: [],
  entryComponents: [ReportSelectComponent],
})
export class SystemReportsModule {}
