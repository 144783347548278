import angular from 'angular';

import EntityCategoriesMobilePreviewModule from './entity-categories-mobile-preview/entity-categories-mobile-preview';
import ManageCategory from './manage-category/manage-category';

const EntityCategoriesComponentsModule = angular.module('control-room.commons.content.entity-categories.components', [
    EntityCategoriesMobilePreviewModule.name,
    ManageCategory.name,
]);

export default EntityCategoriesComponentsModule;
