import controller from './app-settings.controller';
import template from './app-settings.html';

const component = {
    template,
    bindings: {},
    controller: ['$state', 'crConstants', 'crAnalyticsService', 'crEntityService', controller],
};

export default component;
