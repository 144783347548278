import template from './collapsible-pane.html';
import './collapsible-pane.less';
import CollapsiblePaneController from './collapsible-pane.controller';

const CollapsiblePaneComponent = {
    template,
    bindings: {
        drag: '<',
        panes: '<',
        readOnly: '<',
        onPaneSelect: '&',
        onChange: '&',
    },
    controller: CollapsiblePaneController,
};

export default CollapsiblePaneComponent;
