<cr-dropdown-upgraded
  [mode]="mode"
  [upgradedFormControl]="control"
  [menuItems]="menuItems"
  [isDisabled]="isDisabled"
  [hideResetOption]="hideResetOption"
  [showSearch]="showSearch"
  [showStatus]="showStatus"
  [disableTracking]="disableTracking"
  [label]="label"
  [placeholder]="placeholder"
  (onSelect)="onStateUpdate($event)"
></cr-dropdown-upgraded>
