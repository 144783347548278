import template from './toggle.html';
import './toggle.less';
import ToggleController from './toggle.controller';

export const ToggleComponent = {
    template,
    bindings: {
        label: '@',
        inputName: '@',
        disabled: '<',
        valueMessages: '<',
        onToggle: '&',
        isOn: '<',
    },
    controller: [ToggleController],
};
