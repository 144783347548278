class ImageViewerController {
    constructor(crImageService, crConstants) {
        this.crImageService = crImageService;
        this.constants = crConstants.image;
    }

    $onInit() {
        this.images = this.images || [];
        this.imageIndex = this.images.length ? 0 : null;

        this.initImages();
    }

    initImages() {
        const { nonTargetImages, targetImages } = this.crImageService.initImages(this.images, this.imageType);
        this.nonTargetImages = nonTargetImages;
        this.targetImages = targetImages;

        if (this.targetImages.length) {
            if (this.imageIndex === null) {
                this.imageIndex = 0;
            }
            this.selectImage(this.imageIndex);
        }
    }

    selectImage(index) {
        this.imageSrc = this.targetImages[index].displaySrc;
        this.imageIndex = index;
    }
}

export default ImageViewerController;
