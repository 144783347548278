import {
  Component, ContentChild, EventEmitter, Input, Output, TemplateRef,
} from '@angular/core';
import { CrTableColumnOptions, CrTableOptions } from './table.model';

@Component({
  selector: 'cr-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})

export class CrTableComponent  {
  @Input() options: CrTableOptions<unknown>;

  @Output() columnSort = new EventEmitter();

  @Output() columnClick = new EventEmitter<unknown>();

  @Output() hoverIconClick = new EventEmitter();

  @ContentChild(TemplateRef, { static: true }) itemTemplate: TemplateRef<any>;

  /**
   * If a column option is passed to be sortable then fire this sort function
   * @param e
   * @param column The column that is being sorted
   */
  sortColumn(e, column: CrTableColumnOptions): void {
    column.sort = !column.sort;

    this.columnSort.emit(column);
  }

  /**
   * This will fire when a custom click is passed to a column option, we pass the original
   * data item as well as attach the column information so you can handle which column
   * if multiple are selected
   *
   * @param dataItem A row item
   * @param column the column information
   */
  onColumnItemClick(dataItem: unknown, column: CrTableColumnOptions): void {
    // add the column information to the item as you can only send 1 object back on an emitter
    (dataItem as any).column = column;
    this.columnClick.emit(dataItem);
  }

  onHoverIconClick(action: string, item): void {
    const hoverClickEvent = { action, item };
    this.hoverIconClick.emit(hoverClickEvent);
  }
}
