import text from './resources/locale/en.json';

class MenuModifierGroup {
    $onInit() {
        this.text = text;
        this.behavior = this.getBehaviorDisplayText(this.group);
        this.modifiers = this.getModifiersDisplayText(this.group.modifiers);
    }

    getBehaviorDisplayText(group) {
        return group.min === 0 ? this.text.optional : this.text.required;
    }

    getModifiersDisplayText(modifiers) {
        return modifiers.map((mod) => `${mod.displayName} (${parseFloat(mod.price).toFixed(2)})`).join(', ');
    }
}

export default MenuModifierGroup;
