import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CrSpinnerModule } from '../../../../shared/spinner/spinner.module';
import { ReachComponent } from './components/reach/reach.component';

@NgModule({
  imports: [CommonModule, CrSpinnerModule],
  exports: [],
  declarations: [ReachComponent],
  providers: [],
  entryComponents: [ReachComponent],
})
export class ExperienceManageModule {}
