export enum EntityType {
  PLACES = 'places',
  POIS = 'pois',
  POIS_SELECT_ONLY = 'pois-select-only',
  EVENTS = 'events',
  BEACONS = 'beacons',
  POI_TYPES = 'poi-types',
  POI_CATEGORIES = 'poi-categories',
  POI_ATTRIBUTES = 'poi-attributes',
  EVENT_CATEGORIES = 'event-categories',
  EVENT_ATTRIBUTES = 'event-attributes',
  VENUE_HOURS = 'venue-hours',
  PRODUCTS = 'products',
  MENUS = 'menus',
  MENU_PRODUCTS = 'menu-products',
  MENU_BUNDLES = 'menu-bundles',
  MENU_CATEGORIES = 'menu-categories',
  MENU_MODIFIER_GROUPS = 'menu-modifierGroups',
  MENU_MODIFIERS = 'menu-modifiers',
  SCREEN_FLOWS = 'screen-flows',
  TAG_DEFINITIONS = 'tag-definitions',
  TAG_DEFINITION_VALUES = 'tag-definition-values',
  EXPERIENCES = 'experiences',
  COUPONS = 'coupons'
}
