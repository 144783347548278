export enum AppNavType {
  COUPON_DETAILS = 'couponDetails',
  EVENT_DETAILS = 'eventDetails',
  DINING_MENU = 'diningMenu',
  GUEST_PREFS_BRAND = 'guestPreferencesBrand',
  GUEST_PREFS_VENUE = 'guestPreferencesVenue',
  LOQUEUE_PRODUCT_DETAILS = 'loQueueProductDetails',
  LOQUEUE_RIDE_DETAILS = 'loQueueRideDetails',
  LITERAL = 'literal',
  POI_DETAILS = 'poiDetails',
  PRODUCT_DETAILS = 'productDetails',
  WEBVIEW = 'webview',
}
