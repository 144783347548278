<cr-title-bar icon="icon-cr-waiter" [label]="text.header">
    <div cr-title-bar-right>
        <div
            class="icon-cr-add cta"
            *ngIf="hasAddButton"
            (click)="openCreateRestaurantModal()"
            cr-policy
            cr-policy-action="write:reservation"
            data-local-qa-id="manage-create"
        ></div>
    </div>
</cr-title-bar>

<cr-nav-header [navLocation]="navLocation"></cr-nav-header>

<div class="content">
    <div class="search-container">
        <div class="search-bar">
            <span *ngIf="!isSearching" class="icon icon-cr-search"></span>
            <span
                *ngIf="isSearching"
                (click)="clearSearch()"
                class="icon icon-cr-chevron-left"
            ></span>
            <input
                #searchReservations
                [value]="searchQuery"
                [placeholder]="text.searchPlaceholder"
                (keydown)="searchChangeEvent($event)"
                (focus)="searchFocus()"
                (blur)="searchBlur()"
            />
        </div>
    </div>
    <div class="dining-locations">
        <table class="dining-locations-table">
            <thead>
                <tr>
                    <th scope="col">{{ text.tableNameColumTitle }}</th>
                    <th scope="col">{{ text.tableLocationColumnTitle }}</th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody *ngIf="!isLoading; else loadingTableBody">
                <tr
                    *ngFor="let location of filteredEventLocations"
                    class="content"
                    (click)="previewLocationAction(location.id)"
                >
                    <td>{{ location.name }}</td>
                    <td>
                        <span
                            *ngFor="let poi of location.associatedPois"
                            class="poi-pill"
                        >
                            {{ poi.name }}
                        </span>
                    </td>
                    <td
                        class="action-cell"
                        (click)="editLocationAction(location.id)"
                    >
                        <span class="icon icon-cr-edit"></span>
                    </td>
                </tr>
                <tr *ngIf="!filteredEventLocations.length">
                    <td colspan="3" class="wide-cell">
                        {{ text.noResultsText }}
                    </td>
                </tr>
            </tbody>
            <ng-template #loadingTableBody>
                <tbody>
                    <tr>
                        <td colspan="3" class="wide-cell">
                            <cr-spinner></cr-spinner>
                        </td>
                    </tr>
                </tbody>
            </ng-template>
        </table>
    </div>
</div>
