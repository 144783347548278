import {
  Component, ViewEncapsulation, OnInit, OnDestroy,
} from '@angular/core';
import { StateService } from '@uirouter/core';
import bulletTrain from 'bullet-train-client';
import { of } from 'rxjs';
import { take, catchError } from 'rxjs/operators';
import { PrinterService } from '../../../../shared/services/printer/printer.service';
import { PolicyService } from '../../../../shared/services/policy/policy.service';
import { ErrorLoggingService, NavigationService, OrderService } from '../../../../shared';

import text from './resources/locale/en.json';
import { LocalizedText } from '../../../../core';
import { Order } from '../../../../shared/models/order.model';

@Component({
  selector: 'cr-fnb-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OrderDetailsComponent implements OnInit, OnDestroy {
  hasError: boolean;

  isLoading: boolean;

  text: LocalizedText;

  order?: Order;

  now: number = Date.now();

  intervalId: number;

  // When the edit pane is up
  isEditing = false;

  // When the API is working
  isUpdating = false;

  isPrintingEnabled = false;

  printStatus?: string = '';

  isWriteAllowed = false;

  displayOrderAge = '';

  isRefundsEnabled: boolean = bulletTrain.hasFeature('refunds');

  constructor(
    private state: StateService,
    private navigationService: NavigationService,
    private orderService: OrderService,
    private policyService: PolicyService,
    private printerService: PrinterService,
    private logger: ErrorLoggingService,
  ) {
    this.isLoading = true;
    this.text = text as LocalizedText;
    this.intervalId = window.setInterval(() => this.clockUpdate(), 10000);
    this.policyService.hasAccess('write:fnb-order').then((allowed) => {
      if (allowed) {
        this.isWriteAllowed = true;
      }
    });
  }

  ngOnInit(): void {
    this.loadOrder();
    this.printerService.getPrinters(this.state.params.kitchenId).then((response) => {
      this.isPrintingEnabled = response.printers.length > 0;
    });
  }

  ngOnDestroy() {
    window.clearInterval(this.intervalId);
  }

  clockUpdate(): void {
    this.now = Date.now();
    this.displayOrderAge = this.getOrderAge(this.order);
  }

  getOrderClass(order): object {
    return {
      'in-progress': order.fulfillmentStatus === 'IN_PROCESS',
      completed: order.fulfillmentStatus === 'FULFILLED',
      refunded: order.paymentStatus === 'REFUNDED' || order.paymentStatus === 'PARTIAL_REFUND_APPLIED',
    };
  }

  editOrder(): void {
    if (this.isWriteAllowed) {
      this.isEditing = !this.isEditing;
    }
  }

  updateOrderFulfillment(fulfillmentStatus: string) {
    if (this.isUpdating) {
      return;
    }

    const currentFulfillmentStatus = this.order.fulfillmentStatus;

    this.isUpdating = true;
    this.isEditing = false;

    this.orderService
      .updateFulfillmentStatus(this.state.params.kitchenId, this.order.orderId, fulfillmentStatus)
      .pipe(take(1), catchError((err) => of(this.handleFulfillmentError(err))))
      .subscribe((res) => {
        // Update status locally, no data is returned from this service
        this.order.fulfillmentStatus = fulfillmentStatus;
        // Go back to parent screen
        this.onBack(this.order.orderId, currentFulfillmentStatus);
      });
  }

  openRefundScreen(orderId: string) {
    const { kitchenId, state } = this.state.params;
    this.state.go('client.fnb-order-queue.order-refund', { kitchenId, state, orderId });
  }

  printOrder(order: Order) {
    const { kitchenId } = this.state.params;

    if (this.printStatus === 'SENT') {
      // Don't print if there's one in progress already
      return;
    }

    this.printStatus = 'SENT';
    this.printerService
      .printOrder(kitchenId, order)
      .then(() => {
        this.printStatus = 'SUCCESS';
      })
      .catch((err) => {
        console.error(err);
        this.printStatus = 'FAIL';
      });
  }

  dismissNotice(order: Order) {
    order.notice = null;
    order.error = false;
  }

  getOrderAge(order): string {
    if (!order) {
      return '';
    }
    const ageMs = this.now - order._date.getTime();
    const ageMinutes = Math.round(ageMs / (1000 * 60));
    if (ageMinutes < 60) {
      return this.text.orderCreatedAgo.replace('%min%', `${ageMinutes}`);
    }
    const ageHours = Math.floor(ageMinutes / 60);
    return this.text.orderCreatedAgoHours.replace('%min%', `${ageMinutes % 60}`).replace('%hr%', `${ageHours}`);
  }

  loadOrder(): void {
    this.orderService
      .getOrderById(this.state.params.orderId)
      .then((order) => {
        if (order) {
          this.order = order;
          this.clockUpdate();
          this.hasError = false;
        } else {
          this.hasError = true;
          console.error('Could not locate order in results');
        }
        this.isLoading = false;
      })
      .catch((err) => {
        this.hasError = true;
        this.order = null;
        console.error('Could not retrieve order', err);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  onBack(orderId = '', orderNotice = ''): void {
    const { kitchenId, state } = this.state.params;
    this.navigationService.goBack('client.fnb-order-queue.order-list', {
      kitchenId, state, orderId, orderNotice,
    });
  }

  private handleFulfillmentError(err): void {
    this.logger.logError('order details fufillment error', err);
    this.order.error = true;
  }
}
