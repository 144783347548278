import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonsModule } from 'ngx-bootstrap';
import { CrFormsModule, SharedModule } from '../../shared';
import { PTWService } from '../../shared/services/ptw/ptw.service';
import {
  KitchenSelectComponent,
  OrderDetailsComponent,
  OrderReceiptModule,
  OrderRefundComponent,
} from './components';
import { OrderHistoryModule } from './components/order-history/order-history.module';
import { OrderListModule } from './components/order-list/order-list.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    OrderReceiptModule,
    ButtonsModule,
    CrFormsModule,
    OrderListModule,
    OrderHistoryModule,
  ],
  declarations: [
    KitchenSelectComponent,
    OrderDetailsComponent,
    OrderRefundComponent,
  ],
  providers: [PTWService],
  entryComponents: [KitchenSelectComponent, OrderDetailsComponent, OrderRefundComponent],
})
export class FnbOrderQueueModule {}
