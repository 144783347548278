import { sprintf } from 'sprintf-js';
import ExtendableError from 'es6-error';
import text from './resources/locale.json';

class InvalidPropertyError extends ExtendableError {
    constructor(property) {
        super(sprintf(text.invalidProperty, property));
    }
}

export default InvalidPropertyError;
