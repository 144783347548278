<cr-title-bar
  icon="icon-cr-back"
  [label]="text.bundleGroups"
  (onClick)="onBack()"
>
  <div titleBarRight>
    <div class="icon-cr-add cta" (click)="goToCreatePage()"></div>
  </div>
</cr-title-bar>
<section class="bundle-group-list-header">
  <cr-search
    class="header-item"
    [placeholder]="text.search"
    (update)="handleSearch($event)"
    [formControl]="searchFormControl"
  >
  </cr-search>
</section>
<cr-table
  [options]="crTableOptions"
  (columnSort)="handleSort($event)"
  (hoverIconClick)="handleRowIconClick($event)"
>
</cr-table>
