import CtaButtonBuilderRowController from './cta-button-builder-row.controller';

import template from './cta-button-builder-row.html';
import './cta-button-builder-row.less';

const CtaButtonBuilderRowComponent = {
    template,
    bindings: {
        row: '<',
        rows: '<',
        entity: '<',
    },
    require: {
        form: '^form',
    },
    controller: ['crValidationBuilderService', CtaButtonBuilderRowController],
};

export default CtaButtonBuilderRowComponent;
