import text from './resources/locale/en.json';

class EventScheduleViewerController {
    constructor($state, crConstants, crVenueService, crScheduleService) {
        this.$state = $state;
        this.crConstants = crConstants;
        this.crVenueService = crVenueService;
        this.crScheduleService = crScheduleService;
    }

    $onInit() {
        this.text = text;
        this.timezone = this.crVenueService.getTimezone(this.$state.params.venueId);

        this.isoTimeFormat = this.crConstants.time.format.isoNoOffset;
        this.shortTimeFormat = this.crConstants.time.format.short;
        this.isoDateFormat = this.crConstants.date.format.isoNoOffset;
        this.shortDateFormat = this.crConstants.date.format.short;
        this.shortDateNoYearFormat = this.crConstants.date.format.shortNoYear;
        this.iCalDateFormat = this.crConstants.iCal.format.date;
        this.scheduleKey = this.crConstants.calendarService.scheduleKey;

        this.setStates(this.scheduleData);
    }

    setStates(data) {
        const instance = data.instances[0];
        const { startTime } = instance;
        const { endTime } = instance;

        this.repeat = data.recurrence;
        this.neverEnds = this.repeat && !data.recurrence.includes('UNTIL');
        this.allDay = startTime && endTime && this.crScheduleService.isAllDay(startTime, endTime) && data.scheduleKey?.toUpperCase() !== this.scheduleKey.CHECKPARK.toUpperCase();
        this.checkPark = data.scheduleKey?.toUpperCase() === this.scheduleKey.CHECKPARK.toUpperCase();
        this.repeatTimesState = this.repeat && !this.allDay && !this.checkPark;
        this.customTimesState = !this.allDay && !this.repeat && !this.checkPark;
        this.neverEndsState = this.repeat && this.neverEnds;

        this.setData(data, startTime, endTime);
    }

    setData(data, startTime, endTime) {
        // Handle recurrence rule
        if (this.repeat) {
            this.recurrence = this.crScheduleService.getRecurrenceObject(data.recurrence);
        }

        // Handle repeat times
        if (this.repeatTimesState) {
            const repeatTimesArr = data.instances.map((instance) => {
                const time = this.crScheduleService.formatDateTime(
                    instance.startTime,
                    this.isoDateFormat,
                    this.shortTimeFormat
                );
                return time;
            });

            this.repeatTimes = repeatTimesArr.join(', ');

            this.durationSecs = data.durationSecs;

            // Split duration in seconds into hours and minutes
            this.durationHrs = Math.floor(this.durationSecs / 3600);
            this.durationMins = (this.durationSecs % 3600) / 60;
        }

        // Handle times and dates
        if (this.customTimesState) {
            if (startTime) {
                this.startDate = startTime;
                this.startTime = this.crScheduleService.getTimeFromIsoString(startTime);
            }

            if (endTime) {
                this.endDate = endTime;
                this.endTime = this.crScheduleService.getTimeFromIsoString(endTime);
            }
        } else if (this.repeatTimesState) {
            this.startDate = this.crScheduleService.getBeginningOfDay(startTime);
            this.startTime = this.crScheduleService.getTimeFromIsoString(startTime);

            if (!this.neverEnds) {
                this.endDate = this.recurrence.until;
            }
        } else if (this.allDay || this.checkPark) {
            this.startDate = startTime;

            if (this.repeat) {
                if (!this.neverEnds) {
                    this.endDate = this.recurrence.until;
                }
            } else {
                this.endDate = this.crScheduleService.getEndOfPreviousDay(endTime);
            }
        }

        // Format dates and times
        if (this.startDate) {
            this.startDate = this.crScheduleService.formatDateTime(
                this.startDate,
                this.isoDateFormat,
                this.shortDateFormat
            );
        }

        if (this.endDate) {
            this.endDate = this.crScheduleService.formatDateTime(
                this.endDate,
                this.isoDateFormat,
                this.shortDateFormat
            );
        }

        if (this.startTime) {
            this.startTime = this.crScheduleService.formatDateTime(
                this.startTime,
                this.isoTimeFormat,
                this.shortTimeFormat
            );
        }

        if (this.endTime) {
            this.endTime = this.crScheduleService.formatDateTime(
                this.endTime,
                this.isoTimeFormat,
                this.shortTimeFormat
            );
        }

        this.setCancellationData(data);
    }

    setCancellationData(data) {
        if (data.recurrenceExceptions) {
            this.exceptionsList = this.crScheduleService.getRecurrenceExceptionsArray(data.recurrenceExceptions);
        }
    }
}

export default EventScheduleViewerController;
