import * as angular from 'angular';
import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';
import { ExportService } from './export.service';
import { CrExportButtonComponent } from './export-button.component';

export const Ng1ExportButtonModule = angular
  .module('control-room.angular.export-button', [])
  .directive(
    'crExportButton',
    downgradeComponent({ component: CrExportButtonComponent }) as angular.IDirectiveFactory,
  )
  .factory('crExportService', downgradeInjectable(ExportService));
