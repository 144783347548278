export default class CreateKitchenService {
    constructor(crEntityService, crConstants) {
        this.crEntityService = crEntityService;
        this.crConstants = crConstants;
    }

    getKitchenList(venueId) {
        const queryParams = {
            venueId,
            page: 1,
            perPage: 99999,
            sort: 'asc',
            sortby: 'name',
            state: 'enabled',
        };

        return this.crEntityService.getEntityList(this.crConstants.entity.types.PLACES, queryParams);
    }
}
