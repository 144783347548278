class OrdersService {
    constructor($q, commerceService, placesService) {
        this.$q = $q;
        this.commerceService = commerceService;
        this.placesService = placesService;
    }

    getOrdersPlacedAndTopItemsPurchased(timeframe, channel, interval) {
        return this.commerceService.ordersReports(null, timeframe, channel, interval, 10);
    }

    getPlaces(timeframe, channel, sortOrder) {
        return this.$q((resolve, reject) => {
            this.commerceService
                .placesReports(timeframe, channel, 'revenue', sortOrder, 5)
                .then((places) => {
                    this.$q
                        .all(
                            places.rows.map((row) =>
                                this.$q((resolveInner, rejectInner) => {
                                    if (row.data.place.name) {
                                        return resolveInner();
                                    }
                                    this.placesService
                                        .getPlaceWithICalDays(row.data.place.id)
                                        .then((details) => {
                                            row.data.place.name = details.name;

                                            resolveInner();
                                        })
                                        .catch(rejectInner);
                                })
                            )
                        )
                        .then(() => {
                            resolve(places);
                        })
                        .catch(reject);
                })
                .catch(reject);
        });
    }

    getTopPlaces(timeframe, channel) {
        return this.getPlaces(timeframe, channel, 'desc');
    }

    getBottomPlaces(timeframe, channel) {
        return this.getPlaces(timeframe, channel, 'asc');
    }
}

export default OrdersService;
