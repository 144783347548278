import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root',
  })
export class QuicksightService {
    private url = '/rest/quicksight';
    constructor(private http: HttpClient) {
    }

    getDashboards(venueId: string): Promise<any> {
        return this.http.get(`${this.url}/dashboards/${venueId}`).toPromise().then((res) => res);
    }
}
