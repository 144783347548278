import uuidv4 from 'uuid/v4';

import text from './resources/locale/en.json';

class EnumBuilderController {
    constructor(crAnalyticsService, crValidationBuilderService, crConstants) {
        this.crAnalyticsService = crAnalyticsService;
        this.crValidationBuilderService = crValidationBuilderService;
        this.crConstants = crConstants;
    }

    $onInit() {
        this.text = text;
        this.enumBuilderForm = {};
        this.enumValueMaxLength = this.crConstants.input.maxLength.DEFAULT;

        if (!this.enumValues) {
            this.enumValues = [{ id: uuidv4(), label: '' }];
        }

        this.isUnique = this.crValidationBuilderService.isUnique(() => this.enumValues, 'label');

        this.errorMessages = {
            'is-unique': this.text.valuesMustBeUnique,
        };
    }

    $onChanges(changes) {
        if (changes && changes.isSubmitted && changes.isSubmitted.currentValue && this.enumBuilderForm) {
            this.enumBuilderForm.$setSubmitted();
        }
    }

    update(model) {
        this.onUpdate({
            $event: {
                model,
            },
        });
    }

    moveRowToTop(from, to) {
        this.swapRows(from, to);
    }

    moveRow(from, to) {
        if (from !== to) {
            // This accounts for dragging up, where technically the new index is 1 less than desired.
            if (to < from) {
                to += 1;
            }

            this.swapRows(from, to);
        }
    }

    swapRows(from, to) {
        this.setDirty();
        this.enumValues.splice(to, 0, this.enumValues.splice(from, 1)[0]);
        this.crAnalyticsService.track('enum-builder | order updated', { from, to });
        this.update(this.enumValues);
    }

    addRow() {
        if (this.enumBuilderForm.$valid && !this.disableAdd) {
            this.enumBuilderForm.$setPristine();
            this.enumValues.push({ id: uuidv4(), label: '' });
            this.crAnalyticsService.track('enum-builder | row added');
        }
    }

    removeRow(index) {
        this.setDirty();
        this.enumValues.splice(index, 1);
        this.update(this.enumValues);
        this.crAnalyticsService.track('enum-builder | row removed');
    }

    canRemove() {
        return this.enumValues.length > 1;
    }

    handleKeypress(e) {
        if (e.which === 13) {
            this.addRow();
            this.update(this.enumValues);
        }
    }

    setDirty() {
        if (!this.enumBuilderForm.$dirty) {
            this.enumBuilderForm.$setDirty();
        }
    }

    isDefaultLocale() {
        return this.locale.current === this.locale.default;
    }

    getLabelPlaceholder(enumValue) {
        let txt = '';

        if (!this.isDefaultLocale()) {
            txt = `${enumValue.localization[this.locale.default].label} (${this.locale.default})`;
        }

        return txt;
    }
}

export default EnumBuilderController;
