import controller from './quicksight-embed.controller';
import template from './quicksight-embed.html';
import './quicksight-embed.less';

const component = {
    template,
    bindings: {
        url: '@',
        enabled: '<',
    },
    controller: ['$q', '$state', 'crQuicksightService', controller],
};

export default component;
