import LayoutController from './layout.controller';
import template from './layout.html';
import './layout.less';

const LayoutComponent = {
    template,
    bindings: {
        disableNavigation: '<?',
    },
    transclude: {
        header: 'crHeader',
        navPanel: 'crNavPanel',
        content: 'crContent',
        toast: 'crToast',
        alert: 'crAlert',
    },
    controller: ['$scope', 'crConstants', 'layoutService', LayoutController],
};

export default LayoutComponent;
