import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortNumber',
})
export class ShortNumberPipe implements PipeTransform {
  powers = [
    { suffix: 'Q', value: Math.pow(10, 15) },
    { suffix: 'T', value: Math.pow(10, 12) },
    { suffix: 'B', value: Math.pow(10, 9) },
    { suffix: 'M', value: Math.pow(10, 6) },
    { suffix: 'K', value: Math.pow(10, 3) },
  ];

  transform(number: number): string {
    if (isNaN(number)) {
      return null;
    }

    const abs = Math.abs(number);
    let shortNumber = `${number}`;
    for (let i = 0; i < this.powers.length; i++) {
      const power = this.powers[i].value;
      const { suffix } = this.powers[i];
      const reduced = Math.round((abs / power) * 10) / 10;
      if (reduced >= 1) {
        shortNumber = `${reduced}${suffix}`;
        break;
      }
    }

    return `${number < 0 ? '-' : ''}${shortNumber}`;
  }
}
