import { Component, Input } from "@angular/core";

@Component({
  selector: "cr-form-submission-error",
  templateUrl: "./form-submission-error.component.html",
  styleUrls: ["./form-submission-error.component.scss"]
})

export class FormSubmissionErrorComponent {

  @Input() message = "";
  customClass = "";

}