import text from './resources/locale/en.json';

class ExtensionsBuilderRowController {
    constructor(crValidationBuilderService) {
        this.crValidationBuilderService = crValidationBuilderService;
    }

    $onInit() {
        this.isUniqueId = this.crValidationBuilderService.isUnique(this.rows, 'id');

        this.row.value = this.row.value || '{}';

        this.text = text;
    }
}

export default ExtensionsBuilderRowController;
