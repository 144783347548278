<section class="cr-weekday-editor" [ngClass]="customClass">
    <div
        class="weekday-container cr-flex-between"
        [ngClass]="{ disabled: disabled }"
    >
        <div
            *ngFor="let weekday of weekdays; let i = index"
            [attr.data-local-qa-id]="'day-' + i"
            (click)="update(weekday)"
            class="cr-flex-center day"
            [ngClass]="{ selected: isSelected(weekday) }"
        >
            {{ weekday }}
        </div>
    </div>
    <input type="hidden" [formControl]="control" [attr.name]="name" />
    <cr-form-error [for]="control"></cr-form-error>
</section>
