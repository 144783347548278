import Controller from './calendar-day-heading.controller';
import template from './calendar-day-heading.html';
import './calendar-day-heading.less';

const Component = {
    template,
    bindings: {
        day: '<',
    },
    controller: ['$scope', '$state', 'crVenueService', 'crConstants', 'crAnalyticsService', Controller],
};

export default Component;
