import angular from 'angular';

import BeaconsListModule from './beacons-list/beacons-list';
import BeaconDetailsModule from './beacon-details/beacon-details';
import BeaconEditModule from './beacon-edit/beacon-edit';
import BeaconsMapModule from './beacons-map/beacons-map';

const ComponentsModule = angular.module('control-room.beacons.components', [
    BeaconsListModule.name,
    BeaconDetailsModule.name,
    BeaconEditModule.name,
    BeaconsMapModule.name,
]);

export default ComponentsModule;
