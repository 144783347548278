import ExperiencePhonePreviewController from './experience-phone-preview.controller';
import template from './experience-phone-preview.html';
import './experience-phone-preview.less';

const ExperiencePhonePreviewComponent = {
    bindings: {
        experience: '<',
        locale: '<?',
        manage: '<?',
        onImageUpdated: '&',
        coupons: '<',
    },
    template,
    controller: [
        '$state',
        'crConstants',
        'crImageService',
        'crErrorLoggingService',
        ExperiencePhonePreviewController,
    ],
};

export default ExperiencePhonePreviewComponent;
