import angular from 'angular';

import PlacesListModule from './places-list/places-list';
import PlaceDetailsModule from './place-details/place-details';

const ComponentsModule = angular.module('control-room.places.components', [
    PlacesListModule.name,
    PlaceDetailsModule.name,
]);

export default ComponentsModule;
