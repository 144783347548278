import _ from 'lodash';

class FilterMenuController {
    constructor(text, $state) {
        this.$state = $state;
        this.showAllFilter = { id: 'all', label: text.all };
    }

    $postLink() {
        this.deepLinkId = this.$state.params[this.routeQueryId];
        this.currentFilter = this.defaultFilterId && !this.deepLinkId ? this.getDefaultFilter() : this.showAllFilter;
        this.handleDeepLink();
    }

    $onChanges(changes) {
        if (changes.currentOptionId) {
            this.currentFilter = _.find(this.options, { id: this.currentOptionId });
            this.updateRoute(this.currentOptionId);
        }
    }

    getDefaultFilter() {
        const option = _.find(this.options, { id: this.defaultFilterId });
        this.updateRoute(option.id);
        return option;
    }

    handleDeepLink() {
        if (this.deepLinkId) {
            this.currentFilter = _.find(this.options, { id: this.deepLinkId }) || this.showAllFilter;
        }
    }

    updateRoute(id) {
        const currentState = this.$state.current.name;
        this.$state.params[this.routeQueryId] = id;
        this.$state.go(currentState, this.$state.params, {
            location: 'replace',
        });
    }

    optionSelected(option, e) {
        if (option.disabled) {
            e.stopPropagation();
            return;
        }

        this.currentFilter = option;
        this.onSelect({ option });
        this.updateRoute(option.id);
    }
}

export default FilterMenuController;
