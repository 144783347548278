import _ from 'lodash';
import fabIconSrc from '../../../../commons/basic/resources/images/btn_walkingman.png';
import placeholderSrc from '../../../../commons/basic/resources/images/img_placeholder@2x.png';
import text from './resources/locale/en.json';

class EventPhonePreviewController {
    constructor(crConstants, crImageService, crScheduleService, crErrorLoggingService) {
        this.crConstants = crConstants;
        this.crImageService = crImageService;
        this.crScheduleService = crScheduleService;
        this.crErrorLoggingService = crErrorLoggingService;
    }

    $onInit() {
        this.text = text;
        this.placeholderSrc = placeholderSrc;
        this.fabIconSrc = fabIconSrc;
        this.locationIconSrc = this.crImageService.getIconSrc('/global-icons/event-attributes/location.png');
        this.isoDateFormat = this.crConstants.date.format.isoUtc;
        this.shortDateFormat = this.crConstants.date.format.shortNoYear;
        this.shortTimeFormat = this.crConstants.time.format.short;

        this.setUpDisplayData();

        if (this.manage) {
            this.nextSchedule = this.text.schedulePlaceholder;
        } else {
            this.setupNextScheduleData();
        }
    }

    $onChanges() {
        this.event = _.cloneDeep(this.event);
        this.setUpDisplayData();

        if (!this.manage && this.locale) {
            _.forOwn(this.event.localization[this.locale.current], (value, key) => {
                this.event[key] = value;
            });
        }
    }

    setUpDisplayData() {
        if (this.event) {
            this.getDisplayImage();
        }
    }

    setupNextScheduleData() {
        this.crScheduleService
            .getNextEventInstance(this.event.id)
            .then((data) => {
                if (data.content && data.content.length) {
                    const nextStart = data.content[0].scheduleInstance.start;
                    const nextEnd = data.content[0].scheduleInstance.end;
                    this.nextSchedule = this.getScheduleStartAndEndText(nextStart, nextEnd);
                }
            })
            .catch((err) => {
                this.nextSchedule = this.text.schedulePlaceholder;
                this.crErrorLoggingService.logError(
                    'Could not retrieve next schedule instance for event',
                    err,
                    this.event
                );
            });
    }

    getScheduleStartAndEndText(startDate, endDate) {
        const isSameDay = this.crScheduleService.isSameDay(startDate, endDate);
        let startDateText;
        let endDateText = '';

        // Determine if start date is today, tomorrow, or beyond
        if (this.crScheduleService.isToday(startDate)) {
            startDateText = this.text.today;
        } else if (this.crScheduleService.isTomorrow(startDate)) {
            startDateText = this.text.tomorrow;
        } else {
            startDateText = this.crScheduleService.formatDateTimeInVenueTime(
                startDate,
                this.isoDateFormat,
                this.shortDateFormat
            );
        }

        const startTimeText = this.crScheduleService.formatDateTimeInVenueTime(
            startDate,
            this.isoDateFormat,
            this.shortTimeFormat
        );

        // If not the same day, determine if end date is tomorrow or beyond
        if (!isSameDay) {
            if (this.crScheduleService.isTomorrow(endDate)) {
                endDateText = this.text.tomorrow;
            } else {
                endDateText = this.crScheduleService.formatDateTimeInVenueTime(
                    endDate,
                    this.isoDateFormat,
                    this.shortDateFormat
                );
            }
        }

        const endTimeText = this.crScheduleService.formatDateTimeInVenueTime(
            endDate,
            this.isoDateFormat,
            this.shortTimeFormat
        );

        return `${startDateText} ${startTimeText} - ${endDateText} ${endTimeText}`;
    }

    getDisplayImage() {
        const phoneImgWidth = this.crConstants.image.phoneDisplaySize.MAIN.width;
        const phoneImgHeight = this.crConstants.image.phoneDisplaySize.MAIN.height;
        if (this.event.images) {
            this.phoneImgSrc = this.crImageService.getMainImageSrc(this.event.images, phoneImgWidth, phoneImgHeight);
        }
    }

    getEnumDisplayValue(value) {
        if (_.isArray(value)) {
            return value.map((obj) => obj.label).join(', ');
        }

        return value;
    }
}

export default EventPhonePreviewController;
