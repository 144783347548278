import QuestionListController from './question-list.controller';
import template from './question-list.html';

const QuestionListComponent = {
    template,
    controller: [
        '$state',
        'crErrorLoggingService',
        'crAnalyticsService',
        'crToastService',
        'crLocaleService',
        'crConfig',
        'crNavigationService',
        'crQuestionListService',
        QuestionListController,
    ],
};

export default QuestionListComponent;
