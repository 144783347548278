import template from './image-uploader.html';
import './image-uploader.less';
import ImageUploaderController from './image-uploader.controller';

const ImageUploaderComponent = {
    template,
    require: {
        form: '?^form',
    },
    bindings: {
        name: '@',
        crRequired: '<?',
        upgradedFormControl: '<?',
        imageType: '@',
        images: '<',
        onChange: '&',
        label: '@',
        singleImageMode: '<?',
        imageMinSize: '<?',
        cropperToolHeader: '@?',
    },
    controller: ['crImageService', 'crConstants', '$uibModal', 'crAnalyticsService','bsModalService', ImageUploaderController],
};

export default ImageUploaderComponent;
