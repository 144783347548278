import text from './resources/locale/en.json';

import ListPageController from '../../../../commons/controller/list-page';

class PoiListController extends ListPageController {
    constructor(
        $state,
        crErrorLoggingService,
        crAnalyticsService,
        crToastService,
        crLocaleService,
        crConfig,
        crPoiListService,
        $q,
        crConstants,
        crEntitySelectionManager
    ) {
        super($state, crErrorLoggingService, crAnalyticsService, crToastService, crLocaleService, crConfig);
        this.crPoiListService = crPoiListService;
        this.$q = $q;
        this.crConstants = crConstants;
        this.crEntitySelectionManager = crEntitySelectionManager;
    }

    $onInit() {
        super.$onInit();

        this.text = text;

        this.exportFilename = `${this.$state.params.venueId}_POI`;

        this.registerParam('category', '');
        this.registerParam('state', '');

        const { columns } = this.text;

        this.listData = {
            columns: [
                { key: 'image', label: '' },
                { key: 'name', label: columns.name, class: 'large-column name-column', sortable: true },
                { key: 'title', label: columns.title, class: 'large-column', sortable: true },
                { key: 'categories', label: columns.categories },
                { key: 'state', label: columns.state, class: 'medium-column' },
                { key: 'editButton', label: '', class: 'small-column' },

                /* { key: 'experiences', label: columns.experiences, sortable: false },
                { key: 'events', label: columns.events, sortable: false }, */
            ],
            rows: [],
        };

        if (this.hasMultipleLanguages()) {
            this.addMissingTranslationsColumnHeader();
        }

        this.stateFilterData = [
            { id: 'ACTIVE', label: this.text.active },
            { id: 'INACTIVE', label: this.text.inActive },
            { id: 'DRAFT', label: this.text.draft },
        ];

        this.stateFilterData[0].isSelected = true;
        this.getCategoriesData();
    }

    getData() {
        this.isLoading = true;
        this.dataError = false;

        return this.crPoiListService
            .getData(this.params)
            .then((data) => {
                data.search = data.query.search || '';

                if (data.search === this.params.search) {
                    const categoriesPromise = !this.categoriesFilterData ? this.getCategoriesData() : this.$q.resolve();

                    return categoriesPromise.then(() => {
                        this.isLoading = false;
                        return this.crPoiListService.mapToRowsModel(data.content, this.categoriesFilterData);
                    });
                }
            })
            .catch((err) => {
                this.isLoading = false;
                this.dataError = true;
                this.endOfResults = true;
                this.listData.rows = [];
                this.crErrorLoggingService.logError('Could not get POI List data', err);
            });
    }

    getCategoriesData() {
        return this.crPoiListService.getPoiCategories(this.$state.params.venueId).then((poiCategories) => {
            this.categoriesFilterData = poiCategories;
            return poiCategories;
        });
    }
}

export default PoiListController;
