import controller from './venue-hours-order.controller';
import template from './venue-hours-order.html';
import './venue-hours-order.less';

const component = {
    template,
    bindings: {},
    controller: ['$state', 'crConstants', 'crEntityService', 'crErrorLoggingService', 'crToastService', controller],
};

export default component;
