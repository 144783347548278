import template from './hours-builder-row.html';
import './hours-builder-row.less';
import HoursBuilderRowController from './hours-builder-row.controller';

const HoursBuilderRowComponent = {
    template,
    bindings: {
        change: '&',
        rowShift: '&',
        remove: '&',
        parentState: '<',
        state: '<',
        key: '<',
    },
    controller: ['hoursBuilderRowText', HoursBuilderRowController],
};

export default HoursBuilderRowComponent;
