import _ from 'lodash';
import ReorderList from '../../../../commons/controller/reorder-list';
import text from './resources/locale/en.json';

class PreferencesManageController extends ReorderList {
    constructor(
        $state,
        $q,
        $uibModal,
        $sce,
        crConfig,
        crConstants,
        crLocaleService,
        crNavigationService,
        crErrorLoggingService,
        crAnalyticsService,
        crEntityService,
        crQuestionService,
        crUserPreferencesService
    ) {
        super();

        this.$state = $state;
        this.$q = $q;
        this.$uibModal = $uibModal;
        this.crConfig = crConfig;
        this.crConstants = crConstants;
        this.crLocaleService = crLocaleService;
        this.crNavigationService = crNavigationService;
        this.crErrorLoggingService = crErrorLoggingService;
        this.crAnalyticsService = crAnalyticsService;
        this.crEntityService = crEntityService;
        this.crQuestionService = crQuestionService;
        this.crUserPreferencesService = crUserPreferencesService;
        this.text = text;

        this.stateInfo = $sce.trustAsHtml(this.text.stateInfo);
    }

    $onInit() {
        this.index = 0;
        this.titleBarLabel = this.edit ? this.text.editPreferences : this.text.createPreferences;
        this.questionLocalizationCache = {};
        this.questionType = this.crConstants.entity.types.TAG_DEFINITIONS;

        this.venueId = this.$state.params.venueId;
        this.crNavigationService.enableConfirmNavigation(() => this.form.$dirty);
        this.locale = this.crLocaleService.getLocale(null, this.$state.params.locale, !this.edit);
        this.showDropdown =
            this.crConfig.customer.localizationEnabled && this.locale.list && this.locale.list.length > 1;

        this.states = {
            customer: [
                { id: 'ACTIVE', label: this.text.active },
                { id: 'INACTIVE', label: this.text.inactive },
            ],
            venue: [
                { id: 'ACTIVE', label: this.text.active },
                { id: 'INACTIVE', label: this.text.inactive },
            ],
        };

        this.selectedClass = {
            customer: '',
            venue: '',
        };

        if (this.edit) {
            this.loadPreferences();
        } else {
            this.initPreferences();
        }
    }

    loadPreferences() {
        this.isLoading = true;
        const promises = [this.crUserPreferencesService.getUserPreferences(this.locale)];

        promises.push(
            this.crUserPreferencesService.getUserPreferences(this.locale, this.venueId).catch(() => {
                this.venueCreate = true;
                return this.createVenuePreferences();
            })
        );

        this.$q
            .all(promises)
            .then((data) => {
                this.preferences = {
                    customer: data[0],
                    venue: data[1],
                    originalCustomer: _.cloneDeep(data[0]),
                };

                this.onStateUpdate('customer', { model: [{ id: this.preferences.customer.state }] });
                this.onStateUpdate('venue', { model: [{ id: this.preferences.venue.state }] });

                if (this.preferences.customer.screens[0].tags.length === 0) {
                    this.addQuestion('customer');
                }

                if (this.preferences.venue.screens[0].tags.length === 0) {
                    this.addQuestion('venue');
                }

                this.initQuestionData()
                    .then(() => this.getQuestionLocalizations())
                    .then(() => {
                        this.isLoading = false;
                        this.refreshLocaleStatus();
                        this.phoneData = _.cloneDeep(this.preferences);
                    });
            })
            .catch((err) => {
                this.hasDataError = true;
                this.crErrorLoggingService.logError('Could not get preferences', err);
                this.isLoading = false;
            });
    }

    getQuestionLocalizations() {
        const promises = [];

        this.preferences.customer.screens[0].tags.forEach((question) => {
            promises.push(this.getQuestionLocalization(question));
        });

        this.preferences.venue.screens[0].tags.forEach((question) => {
            promises.push(this.getQuestionLocalization(question, this.venueId));
        });

        return this.$q.all(promises);
    }

    getQuestionLocalization(question, venueId) {
        if (!question.tagDefinitionKey) {
            delete question.tagDefinition.localization;
            return Promise.resolve();
        }

        if (this.questionLocalizationCache[question.tagDefinitionKey]) {
            question.tagDefinition.localization = this.questionLocalizationCache[question.tagDefinitionKey];
            return Promise.resolve();
        }

        return this.crQuestionService
            .getQuestionLocalization(this.questionType, question.tagDefinitionKey, venueId, question.tagDefinition)
            .then((response) => {
                question.tagDefinition.localization = response;
                this.questionLocalizationCache[question.tagDefinitionKey] = response;
            });
    }

    createVenuePreferences() {
        return {
            localization: {},
            screens: [
                {
                    tags: [],
                    label: 'control-room',
                },
            ],
            state: 'INACTIVE',
        };
    }

    initPreferences() {
        this.preferences = {
            customer: {
                localization: {},
                screens: [
                    {
                        tags: [],
                        label: 'control-room',
                    },
                ],
            },
            venue: {
                localization: {},
                screens: [
                    {
                        tags: [],
                        label: 'control-room',
                    },
                ],
            },
        };

        this.addQuestion('customer');
        this.addQuestion('venue');

        this.initQuestionData();

        this.phoneData = _.cloneDeep(this.preferences);
    }

    initQuestionData() {
        this.baseQuestionLists = {
            customer: [],
            venue: [],
        };

        return this.$q.all([this.getQuestions('customer'), this.getQuestions('venue')]).then((data) => {
            this.baseQuestionLists.customer = data[0];
            this.baseQuestionLists.venue = data[1];

            const customerSelections = this.preferences.customer.screens[0].tags.map(
                (question) => question.tagDefinitionKey
            );
            const venueSelections = this.preferences.venue.screens[0].tags.map((question) => question.tagDefinitionKey);

            this.preferences.customer.screens[0].tags.forEach((question) => {
                question.index = this.index;
                question.options = _.cloneDeep(this.baseQuestionLists.customer);
                question.options.forEach((option) => {
                    option.isSelected = option.tagKey === question.tagDefinitionKey;
                    option.isDisabled = customerSelections.includes(option.tagKey);
                });
                question.requiredOptions = [
                    {
                        label: this.text.setAsRequired,
                        name: `customer-question-required-${this.index}`,
                        checked: question.required,
                    },
                ];
                this.index += 1;
            });

            this.preferences.venue.screens[0].tags.forEach((question) => {
                question.index = this.index;
                question.options = _.cloneDeep(this.baseQuestionLists.venue);
                question.options.forEach((option) => {
                    option.isSelected = option.tagKey === question.tagDefinitionKey;
                    option.isDisabled = venueSelections.includes(option.tagKey);
                });
                question.requiredOptions = [
                    {
                        label: this.text.setAsRequired,
                        name: `venue-question-required-${this.index}`,
                        checked: question.required,
                    },
                ];
                this.index += 1;
            });

            this.phoneData = _.cloneDeep(this.preferences);
        });
    }

    getQuestions(level) {
        const params = {
            level: level.toUpperCase(),
            definitionType: 'QUESTION',
        };

        if (params.level === 'VENUE') {
            params.venueId = this.venueId;
        }

        const route = this.crConstants.entity.types.TAG_DEFINITIONS;
        return this.crEntityService.getEntityList(route, params);
    }

    get isDefaultLocale() {
        return this.locale.current === this.locale.default;
    }

    addQuestion(level) {
        const questions = this.preferences[level].screens[0].tags;
        if (questions.length >= 8) {
            return;
        }

        const question = {
            index: this.index,
            required: false,
            requiredOptions: [
                {
                    label: this.text.setAsRequired,
                    name: `${level}-question-required-${this.index}`,
                    checked: false,
                },
            ],
            tagDefinition: {},
            controlType: 'control-room',
        };

        if (this.baseQuestionLists) {
            question.options = _.cloneDeep(this.baseQuestionLists[level]);

            const selections = this.preferences[level].screens[0].tags.map((item) => item.tagDefinitionKey);
            question.options.forEach((option) => {
                option.isDisabled = selections.includes(option.tagKey);
            });
        }

        questions.push(question);
        this.index += 1;
    }

    onBrandContentChange(field, event) {
        const model = event.target ? event.target.value : event.model;
        if (this.isDefaultLocale) {
            this.preferences.customer.screens[0][field] = model;
        }

        if (!this.preferences.customer.localization[this.locale.current]) {
            this.preferences.customer.localization[this.locale.current] = {};
        }

        const localization = this.preferences.customer.localization[this.locale.current];
        if (!localization.screens) {
            localization.screens = [];
        }
        if (localization.screens.length !== 1) {
            localization.screens.length = 0;
            localization.screens.push({});
        }
        localization.screens[0][field] = model;

        this.refreshLocaleStatus();
    }

    onLocaleUpdate(event) {
        this.locale.current = event.model[0].id;
        this.crAnalyticsService.track('Language Selected', { language: event.model[0].label });
    }

    dirtyForm() {
        this.form.$setDirty();
    }

    isInvalidForm() {
        const localization = this.preferences.customer.localization[this.locale.default];
        if (!localization) {
            return false;
        }
        return (
            this.isDefaultLocale &&
            !(localization.screens[0].descriptionTitle && localization.screens[0].descriptionText)
        );
    }

    onStateUpdate(level, event) {
        this.selectedClass[level] = event.model[0].id;
        this.preferences[level].state = event.model[0].id;

        this.states[level].forEach((state) => {
            state.isSelected = state.id === event.model[0].id;
        });
    }

    onQuestionAdd(level) {
        this.addQuestion(level);
        this.dirtyForm();
    }

    addQuestionsDisabled(level) {
        const customerQuestions = this.preferences.customer.screens[0].tags.length;
        const venueQuestions = this.preferences.venue.screens[0].tags.length;
        const totalQuestions = customerQuestions + venueQuestions;
        if (totalQuestions >= 10) {
            return true;
        }

        const questions = this.preferences[level].screens[0].tags;
        for (let i = 0; i < questions.length; i++) {
            const question = questions[i];

            if (!question.tagDefinitionKey) {
                return true;
            }
        }
    }

    onQuestionRemove(level, index) {
        this.dirtyForm();
        this.preferences[level].screens[0].tags.splice(index, 1);
        this.setQuestionOptionDisabled(level);
        this.phoneData[level] = _.cloneDeep(this.preferences[level]);
    }

    questionOrderChange(level, data, index) {
        this.moveRow(data, index, this.preferences[level].screens[0].tags);
        this.dirtyForm();
        this.phoneData[level] = _.cloneDeep(this.preferences[level]);
    }

    questionOrderChangeTop(level, data, index) {
        this.moveRowToTop(data, index, this.preferences[level].screens[0].tags);
        this.dirtyForm();
        this.phoneData[level] = _.cloneDeep(this.preferences[level]);
    }

    questionSelected(level, event, question) {
        question.tagDefinitionKey = event.model[0] ? event.model[0].tagKey : null;
        question.tagDefinition = event.model[0];
        this.setQuestionOptionDisabled(level);
        this.getQuestionLocalizations().then(() => {
            this.phoneData.customer = _.cloneDeep(this.preferences.customer);
            this.phoneData.venue = _.cloneDeep(this.preferences.venue);
        });
    }

    onRequiredUpdate(event, question) {
        question.requiredOptions[0].checked = event.model[question.requiredOptions[0].name];
        question.required = event.model[question.requiredOptions[0].name];
        this.dirtyForm();
        this.phoneData = _.cloneDeep(this.preferences);
    }

    setQuestionOptionDisabled(level) {
        const selections = this.preferences[level].screens[0].tags.map((question) => question.tagDefinitionKey);
        this.preferences[level].screens[0].tags.forEach((question) => {
            question.options.forEach((option) => {
                option.isDisabled = selections.includes(option.tagKey);
            });
        });
    }

    refreshLocaleStatus() {
        const localeList = _.cloneDeep(this.locale.list);
        localeList.forEach((locale) => {
            const localization = this.preferences.customer.localization[locale.id];
            if (!localization || !localization.screens || !localization.screens[0]) {
                locale.hasStatusWarning = true;
            } else {
                locale.hasStatusWarning = !(
                    localization.screens[0].descriptionTitle && localization.screens[0].descriptionText
                );
            }
        });

        this.locale.list = localeList;
    }

    goBack() {
        if (this.edit) {
            this.crAnalyticsService.track('Edit Preferences Canceled');
            this.crNavigationService.goBack('client.app-settings.preferences');
        } else {
            this.crAnalyticsService.track('Create Preferences Canceled');
            this.crNavigationService.goBack('client.app-settings.preferences');
        }
    }

    get customerChanges() {
        let contentChange = false;
        let questionsChange = false;

        if (this.preferences.customer.state === 'ACTIVE' && this.preferences.originalCustomer) {
            const customerQuestions = this.preferences.customer.screens[0];
            const originalQuestions = this.preferences.originalCustomer.screens[0];
            if (
                customerQuestions.descriptionText !== originalQuestions.descriptionText ||
                customerQuestions.descriptionTitle !== originalQuestions.descriptionTitle
            ) {
                contentChange = true;
            }

            if (customerQuestions.tags.length !== originalQuestions.tags.length) {
                questionsChange = true;
            } else {
                for (let i = 0; i < customerQuestions.tags.length; i++) {
                    const question = customerQuestions.tags[i];
                    const originalQuestion = originalQuestions.tags[i];

                    if (
                        question.tagDefinitionKey !== originalQuestion.tagDefinitionKey ||
                        question.required !== originalQuestion.required
                    ) {
                        questionsChange = true;
                        break;
                    }
                }
            }
        }

        return { contentChange, questionsChange };
    }

    get customerStateActivated() {
        return (
            this.preferences.customer.state === 'ACTIVE' &&
            (!this.preferences.originalCustomer || this.preferences.originalCustomer.state !== 'ACTIVE')
        );
    }

    save() {
        this.form.$setSubmitted();

        if (this.form.$valid) {
            if (this.form.$dirty) {
                if (this.edit) {
                    if (this.customerStateActivated) {
                        this.$uibModal
                            .open({
                                backdrop: 'static',
                                component: 'crSimpleModal',
                                windowClass: 'cr-modal-size-sm',
                                resolve: {
                                    closeText: () => this.text.cancel,
                                    saveText: () => this.text.save,
                                    type: () => this.crConstants.modalTypes.SUBMIT,
                                    message: () => this.text.confirmCustomerActivation,
                                },
                            })
                            .result.then(() => {
                                this.isSaving = true;
                                this.updatePreferences();
                            })
                            .catch(() => null);
                    } else {
                        const { contentChange, questionsChange } = this.customerChanges;
                        if (contentChange || questionsChange) {
                            let message = this.text.confirmCustomerChange;
                            if (contentChange) {
                                message = `${message}${this.text.customerContentChange}`;
                            }
                            if (questionsChange) {
                                message = `${message}${this.text.customerQuestionsChange}`;
                            }
                            this.$uibModal
                                .open({
                                    backdrop: 'static',
                                    component: 'crSimpleModal',
                                    windowClass: 'cr-modal-size-md',
                                    resolve: {
                                        closeText: () => this.text.cancel,
                                        saveText: () => this.text.save,
                                        type: () => this.crConstants.modalTypes.SUBMIT,
                                        message: () => message,
                                    },
                                })
                                .result.then(() => {
                                    this.isSaving = true;
                                    this.updatePreferences();
                                })
                                .catch(() => null);
                        } else {
                            this.isSaving = true;
                            this.updatePreferences();
                        }
                    }
                } else if (this.customerStateActivated) {
                    this.$uibModal
                        .open({
                            backdrop: 'static',
                            component: 'crSimpleModal',
                            windowClass: 'cr-modal-size-sm',
                            resolve: {
                                closeText: () => this.text.cancel,
                                saveText: () => this.text.save,
                                type: () => this.crConstants.modalTypes.SUBMIT,
                                message: () => this.text.confirmCustomerActivation,
                            },
                        })
                        .result.then(() => {
                            this.isSaving = true;
                            this.createPreferences();
                        })
                        .catch(() => null);
                } else {
                    this.isSaving = true;
                    this.createPreferences();
                }
            } else {
                this.goBack();
            }
        }
    }

    preparePayload() {
        const payload = _.cloneDeep(this.preferences);

        payload.customer.screens[0].tags = payload.customer.screens[0].tags.filter((tag) => !!tag.tagDefinitionKey);
        payload.venue.screens[0].tags = payload.venue.screens[0].tags.filter((tag) => !!tag.tagDefinitionKey);

        payload.customer.screens[0].tags.forEach((tag) => {
            delete tag.options;
            delete tag.tagDefinition;
            delete tag.requiredOptions;
        });

        payload.venue.screens[0].tags.forEach((tag) => {
            delete tag.options;
            delete tag.tagDefinition;
            delete tag.requiredOptions;
        });

        return payload;
    }

    updatePreferences() {
        const payload = this.preparePayload();
        const promises = [this.crUserPreferencesService.updateUserPreferences(payload.customer)];

        if (this.venueCreate) {
            promises.push(this.crUserPreferencesService.createUserPreferences(payload.venue, this.venueId));
        } else {
            promises.push(this.crUserPreferencesService.updateUserPreferences(payload.venue, this.venueId));
        }

        this.$q
            .all(promises)
            .then(() => this.onSaveSuccess(payload))
            .catch((err) => this.handleError('Could not update preferences', err))
            .finally(() => {
                this.isSaving = false;
            });
    }

    createPreferences() {
        const payload = this.preparePayload();
        this.$q
            .all([
                this.crUserPreferencesService.createUserPreferences(payload.customer),
                this.crUserPreferencesService.createUserPreferences(payload.venue, this.venueId),
            ])
            .then((res) => this.onSaveSuccess({ customer: res[0], venue: res[1] }))
            .catch((err) => this.handleError('Could not create preferences', err))
            .finally(() => {
                this.isSaving = false;
            });
    }

    onSaveSuccess(data) {
        this.crNavigationService.disableConfirmNavigation();
        const params = {
            toast: {
                msg: `Guest preferences ${this.edit ? this.text.hasBeenUpdated : this.text.hasBeenCreated}`,
            },
        };

        if (this.edit) {
            this.crAnalyticsService.track('Edit Preferences Success', data);
            this.crNavigationService.goBack('client.app-settings.preferences', params);
        } else {
            this.crAnalyticsService.track('Create Preferences Success', data);
            this.crNavigationService.goToFromCreate('client.app-settings.preferences', params);
        }
    }

    handleError(errMsg, err) {
        this.showErrorModal();
        this.crErrorLoggingService.logError(errMsg, err);
    }

    showErrorModal() {
        const message = this.edit ? this.text.updateError : this.text.createError;

        this.$uibModal
            .open({
                backdrop: 'static',
                component: 'crSimpleModal',
                windowClass: 'cr-modal-size-sm',
                resolve: {
                    closeText: () => this.text.closeError,
                    message: () => message,
                },
            })
            .result.catch(() => null);
    }
}

export default PreferencesManageController;
