import template from './image-viewer.html';
import './image-viewer.less';
import ImageViewerController from './image-viewer.controller';

const ImageViewerComponent = {
    template,
    bindings: {
        imageType: '@',
        images: '<',
    },
    controller: ['crImageService', 'crConstants', ImageViewerController],
};

export default ImageViewerComponent;
