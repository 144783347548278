import { Component, Input } from '@angular/core';
import { StateService } from '@uirouter/core';
import { LocalizedText } from '../../../../core';
import { Menu } from '../../../../shared/services/menus/menu.model';
import { menuRoutes } from '../../menus.routes';
import text from './resources/en.json';

@Component({
  selector: 'cr-kitchen-menus',
  templateUrl: './kitchen-menus.component.html',
  styleUrls: ['./kitchen-menus.component.scss']
})
export class KitchenMenusComponent {
  
  @Input() menus: Menu[];

  @Input() showAddMenuButton = false;


  // Will be used only for the add menu button. Needed to determine which location to tie the new menu to.
  @Input() placeId: string;
  
  text: LocalizedText;

  constructor(private state: StateService) {
    this.text = text;
   }

  goToViewMenu(menu: Menu): void {
    this.state.go(menuRoutes.PRODUCTS, { menuId: menu.id, placeId: menu.placeInfo.id, tabId: 'overview' });
  }

  goToAddMenu(): void {
    this.state.go(menuRoutes.CREATE_MENU, { kitchenId: this.placeId });
  }
}
