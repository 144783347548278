<div class="modal-header">
    <h4 class="modal-title pull-left">{{ text.header }}</h4>
</div>
<div class="poi-search-container">
    <!-- TODO: Add poi category selector -->
    <div class="search-bar">
        <span *ngIf="!isSearching" class="icon icon-cr-search"></span>
        <span
            *ngIf="isSearching"
            (click)="clearSearch()"
            class="icon icon-cr-chevron-left"
        ></span>
        <input
            #searchReservations
            [value]="searchQuery"
            [placeholder]="text.searchPlaceholder"
            (keydown)="searchChangeEvent($event)"
            (focus)="searchFocus()"
            (blur)="searchBlur()"
        />
    </div>
</div>
<div class="poi-container">
    <div *ngIf="isLoading" class="spinner-container">
        <cr-spinner></cr-spinner>
    </div>
    <div
        *ngFor="let poi of poiList"
        class="poi"
        (click)="selectPoi(poi.id)"
        [ngClass]="{ selected: selectedPoi && poi.id === selectedPoi.id }"
    >
        <div class="poi-image"></div>
        <div class="poi-name">
            <span>{{ poi.name }}</span>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button
        type="button"
        class="btn btn-secondary"
        data-dismiss="modal"
        (click)="bsModalRef.hide()"
    >
        {{ text.cancelPoiButton }}
    </button>
    <button
        (click)="createDiningLocation()"
        [disabled]="!selectedPoi"
        type="button"
        class="btn btn-primary"
    >
        {{ text.createPoiButton }}
    </button>
</div>
