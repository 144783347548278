import {
  Component, OnInit, Input, SimpleChanges, OnChanges,
} from '@angular/core';

import { LocalizedText } from '../../core';

import text from './resources/locale/en.json';

@Component({
  selector: 'cr-phone',
  templateUrl: './phone.component.html',
  styleUrls: ['./phone.component.scss'],
})
export class PhoneComponent implements OnChanges, OnInit {
  @Input() cta: any;

  @Input() customClass: string;

  @Input() links: any;

  @Input() locale: any;

  dynamicStyle: any;

  text: LocalizedText;

  constructor() {
    this.text = text;
  }

  ngOnInit() {
    this.refreshClass();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.links.currentValue) {
      this.refreshClass();
    }
  }

  refreshClass() {
    this.dynamicStyle = {};
    this.dynamicStyle['has-cta'] = this.cta && this.cta.label && this.cta.link;
    if (this.links) {
      this.dynamicStyle[`has-links-${this.links.length}`] = true;
    }
  }
}
