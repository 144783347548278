import text from './resources/locale/en.json';

class BroadcastSelect {
    constructor($state, crConstants, crAnalyticsService, crNavigationService, crExperiencesService) {
        this.$state = $state;
        this.crConstants = crConstants;
        this.crAnalyticsService = crAnalyticsService;
        this.crNavigationService = crNavigationService;
        this.crExperiencesService = crExperiencesService;

        this.text = text;
    }

    $onInit() {
        this.types = {};
        this.categories = [];
        this.loading = true;
        this.crExperiencesService
            .getBroadcastTypes()
            .then((types) => {
                types.broadcasts.forEach((type) => {
                    if (!this.types[type.category]) {
                        this.types[type.category] = [];
                    }

                    if (!this.categories.includes(type.category)) {
                        this.categories.push(type.category);
                    }

                    this.types[type.category].push(type);
                    this.loading = false;
                });
            })
            .catch((error) => {
                console.error(error);
                this.loading = false;
                this.error = true;
            });
    }

    goBack() {
        this.crAnalyticsService.track('Create Broadcast Canceled');
        this.crNavigationService.goBack('client.experience-promoter.dashboard', { tabId: this.$state.params.tabId });
    }

    next(type) {
        this.$state.go('client.experience-promoter.create-broadcast', { type });
    }
}

export default BroadcastSelect;
