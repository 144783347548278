import controller from './cvt-dashboard-header.controller';
import template from './cvt-dashboard-header.html';
import './cvt-dashboard-header.less';

const component = {
    template,
    bindings: {
        lastRun: '@',
        isRunComplete: '<',
        hasPendingExecution: '<',
        onClick: '&',
    },
    controller: [controller],
};

export default component;
