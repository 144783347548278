import {
  Component, EventEmitter, Input, Output,
} from '@angular/core';

@Component({
  selector: 'cr-image-grid',
  templateUrl: './image-grid.component.html',
  styleUrls: ['./image-grid.component.scss'],
})
export class ImageGridComponent {
  @Input()
  images: { displaySrc: string }[];

  @Input()
  maxImages = -1;

  @Input()
  selectedIndex: number;

  @Output()
  onAddClick = new EventEmitter();

  @Output()
  onImageClick = new EventEmitter<number>();
}
