import text from './resources/locale/en.json';

class SimpleModalController {
    constructor($sce, constants) {
        this.$sce = $sce;
        this.modalTypes = constants.modalTypes;
        this.text = text;
    }

    $onInit() {
        this.type = this.resolve.type || this.modalTypes.CANCEL;
        const msg = this.resolve.message || this.text.genericErrorMessage;
        this.message = this.$sce.trustAsHtml(msg);
    }

    stopPropagation(event) {
        event.stopPropagation();
    }

    onSubmit(event) {
        event.stopPropagation();
        this.close();
    }

    onCancel(event) {
        event.stopPropagation();
        this.dismiss();
    }
}

export default SimpleModalController;
