import controller from './fnb-reservations.controller';
import template from './fnb-reservations.html';

const component = {
    template,
    bindings: {},
    controller: [controller],
};

export default component;
