import template from './card.html';
import './card.less';

const cardComponent = {
    template,
    transclude: {
        chartContainer: '?crChartContainer',
        statContainer: '?crStatContainer',
    },
    bindings: {
        label: '@',
        customClass: '@',
        onClick: '&',
    },
};

export default cardComponent;
