<section class="cr-venue-hours">
    <div class="title">
        <cr-title-bar [label]="text.title" icon="icon-cr-time"></cr-title-bar>
        <span class="icon-cr-edit" (click)="isEdit()"></span>
        <div
            class="icon-cr-add cta create-disabled"
            *ngIf="this.isCreateEnabled"
            uib-tooltip="{{ this.text.createLimitMessage }}"
            (click)="isAdd()"></div>
    </div>

    <div *ngIf="isLoading || isSaving" class="preloader">
        <cr-spinner class="spinner" smallSpinner="true"></cr-spinner>
    </div>

    <cr-data-error *ngIf="dataError" [message]="text.genericError"></cr-data-error>
    <cr-data-error *ngIf="accessError" [message]="text.notAuthorizedError"></cr-data-error>

    <div class="content-container" *ngIf="!isLoading && !dataError">
        <div class="hours-list-container">
            <cr-venue-hours-drop-down-list
                [venueHoursList]="this.venueHoursList"
            ></cr-venue-hours-drop-down-list>
        </div>
        <div class="phone-preview-container">
            <cr-phone>
                <cr-venue-hours-phone-preview [phonePreviewList]="this.phonePreviewHours"></cr-venue-hours-phone-preview>
            </cr-phone>
        </div>
    </div>
</section>
