import _ from 'lodash';

class ListPageController {
    constructor($state, crErrorLoggingService, crAnalyticsService, crToastService, crLocaleService, crConfig) {
        this.$state = $state;
        this.crErrorLoggingService = crErrorLoggingService;
        this.crAnalyticsService = crAnalyticsService;
        this.crToastService = crToastService;
        this.crLocaleService = crLocaleService;
        this.crConfig = crConfig;
    }

    $onInit() {
        this.isLoading = false;
        this.endOfResults = false;
        this.dataError = false;

        this.locale = this.crLocaleService.getLocale(this.$state.params.venueId);

        this.params = {
            page: 1,
            perPage: 40,
            sort: '',
            sortby: '',
            search: this.$state.params.search || '',
            venueId: this.$state.params.venueId,
        };

        // See if we need to show a toast when coming to this page
        this.crToastService.autoToast(this.$state.params);

        this.debounceUpdateData = _.debounce(this.updateData, 350);

        this.loadNextPage = () => {
            if (!this.isLoading && !this.endOfResults) {
                this.params.page += 1;
                this.crAnalyticsService.track('Page Scroll', { currentPage: this.params.page });
                this.getData().then((rows) => {
                    this.listData.rows.push(...rows);
                    this.isLoading = false;
                    this.endOfResults = rows.length !== this.params.perPage;
                });
            }
        };
    }

    $postLink() {
        this.handleDeepLink();
        this.updateData();
    }

    updateData() {
        this.params.page = 1;
        this.endOfResults = false;
        this.listData.rows = [];

        if (!this.getData) {
            throw Error('ListPage | method not found: getData');
        }

        this.getData().then((rows) => {
            this.listData.rows = rows || [];
            this.updateSearchDeepLink();
        });
    }

    updateParam(key, event) {
        this.params[key] = _.isEmpty(event.model) ? '' : event.model[0].id;
        this.updateData();
    }

    sortChange(column) {
        this.params.sort = column.sort;
        this.params.sortby = column.key;
        this.updateData();
    }

    updateSearch(term = '') {
        this.params.search = term;

        if (term.length === 0 || term.length >= 3) {
            this.debounceUpdateData();
        } else {
            this.setShortSearchTimer(term);
        }
    }

    setShortSearchTimer(term) {
        setTimeout(() => {
            if (term === this.params.search) {
                this.debounceUpdateData();
            }
        }, 1000);
    }

    updateSearchDeepLink() {
        const { search } = this.params;
        if (search !== this.$state.params.search) {
            this.$state.go(this.$state.current.name, { search }, { location: 'replace' });

            if (search) {
                this.crAnalyticsService.track('Search', { term: search });
            }
        }
    }

    handleDeepLink() {
        const truthyParams = _.pickBy(this.$state.params, (p) => !_.isNil(p));

        _.forOwn(truthyParams, (value, key) => {
            const hasKey = _.has(this.params, key);

            if (hasKey && key !== 'timeframe') {
                this.params[key] = value;
            }
        });
    }

    listIsEmpty() {
        return !this.listData.rows.length && !this.isLoading && !this.dataError;
    }

    registerParam(paramName, value) {
        this.params[paramName] = value;
    }

    hasMultipleLanguages() {
        return this.crConfig.customer.localizationEnabled && this.locale.list.length > 1;
    }

    addMissingTranslationsColumnHeader() {
        const missingTranslationsColumn = { key: 'missingTranslations', label: 'Missing Translations' };
        const columnCount = this.listData.columns.length;

        // insert before the edit button
        this.listData.columns.splice(columnCount - 1, 0, missingTranslationsColumn);
    }
}

export default ListPageController;
