import controller from './entity-link.controller';
import template from './entity-link.html';
import './entity-link.less';

const component = {
    template,
    transclude: true,
    bindings: {
        type: '@',
        page: '@',
        id: '@',
        customClass: '@?',
    },
    controller: ['$state', controller],
};

export default component;
