import template from './beacon-edit.html';
import './beacon-edit.less';
import BeaconEditController from './beacon-edit.controller';

const BeaconEditComponent = {
    template,
    bindings: {
        edit: '@',
    },
    controller: [
        '$q',
        '$state',
        '$uibModal',
        'crConstants',
        'crBeaconEditService',
        'crNavigationService',
        'crErrorLoggingService',
        'crGraphicalMapService',
        'crEntityService',
        BeaconEditController,
    ],
};

export default BeaconEditComponent;
