import controller from './cta-buttons-configure.controller';
import template from './cta-buttons-configure.html';
import './cta-buttons-configure.less';

const component = {
    template,
    require: {
        form: '^form',
    },
    bindings: {
        entity: '<',
        locale: '<',
        menus: '<',
        update: '&',
        ctaLimit: '<',
    },
    controller: [controller],
};

export default component;
