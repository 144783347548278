export interface Image {
  type: ImageType;
  src: string;
  width?: number;
  height?: number;
  crop?: string;
}

export enum ImageType {
  STICKER = 'STICKER',
  MAIN = 'MAIN',
  ICON = 'ICON',
  DEFAULT = 'DEFAULT',
}

export type ImageSize = {
  [type in ImageType]: {
    [size: string]: {
      width: number;
      height: number;
    };
  };
};

export interface ImageDto {
  height: number,
  width: number,
  resolution: ImageResolution,
  src: string
  alt: string
}

export enum ImageResolution {
  MDPI = 'MDPI',
  HDPI = 'HDPI',
  XHDPI = 'XHDPI',
  XXXHDPI = 'XXXHDPI',
  SMALL_ICON = 'SMALL_ICON',
  LARGE_ICON = 'LARGE_ICON',
}

export interface IconsResponse {
  icons: IconDto[]
}

export interface IconDto {
  name: string,
  src: string,
}
