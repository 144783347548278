import _ from 'lodash';

class TimeFramePickerController {
    constructor($state, crDateRangeService) {
        this.$state = $state;
        this.crDateRangeService = crDateRangeService;
    }

    $onInit() {
        // TODO: put these in constants
        this.simplePickerData = [
            { id: 'week', label: 'Past 7 days' },
            { id: 'month', label: 'Past 30 days' },
            { id: 'quarter', label: 'Past 90 days' } /* ,
            { id: 'year', label: 'Past Year' } */,
        ];

        this.currentRangeOption = this.$state.params.timeframe || this.defaultRange;
        this.update(this.crDateRangeService.getPastRange(this.currentRangeOption));
    }

    $onChanges(changes) {
        if (changes.range && this.range.start && this.range.end) {
            this.range = _.clone(this.range);
        }

        if (changes.interval) {
            this.interval = _.clone(this.interval);
        }
    }

    optionSelected(option) {
        this.currentRangeOption = option.id;
        this.update(this.crDateRangeService.getPastRange(option.id));
    }

    update(range) {
        const interval = this.crDateRangeService.getIntervalsForRange(range)[0];

        this.onUpdate({
            $event: {
                range,
                interval,
            },
        });
    }
}

export default TimeFramePickerController;
