import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { cloneDeep, forEach, isUndefined, merge } from 'lodash';
import { LocalizationByIdResponse, LocalizedTagDefinition, LocalizedTagDefinitionValue, UserPrefsLocalizationByLocaleModel, UserPrefsLocalizationModel } from '../../models/entity-localization.model';
import { EntityType } from '../../models/entity.model';
import { LocaleService } from '../locale/locale.service';

const localizedSchemas: { [key: string]: Record<string, unknown> } = {
    'events': {
        title: '',
        description: '',
        callToAction: {
            label: '',
            link: '',
        },
    },
    'pois': {
        title: '',
        description: '',
        callToAction: {
            label: '',
            link: '',
        },
        callsToAction: [],
    },
    'places': {
        content: {
            priceDisclaimer: '',
        },
    },
    'event-attributes': {
        label: '',
        enumValues: [],
    },
    'poi-attributes': {
        label: '',
        enumValues: [],
    },
    'poi-categories': {
        label: '',
    },
    'event-categories': {
        label: '',
    },
    'venue-hours': {
        label: '',
        url: '',
    },
    'menu-products': {
        displayName: '',
        content: {
            description: '',
        },
    },
    'menu-categories': {
        displayName: '',
    },
    'menu-modifierGroups': {
        displayName: '',
        
        /* modifiers: [] */
    },
    'menu-modifiers': {
        displayName: '',
    },
    'tag-definitions': {
        label: '',
        title: '',
        values: [],
    },
    'screen-flows': {
        /* screen flows are special */
    },
};

@Injectable({
    providedIn: 'root',
})
export class LocalizationService {
    constructor(private http: HttpClient, private localeService: LocaleService) {}
    
    public getEntityLocalization(type: EntityType, identifiers: { venueId?: string; tagKey?: string, flowKey?: string, id?: string }): Promise<LocalizationByIdResponse> {
        if (!localizedSchemas[type]) {
            throw Error('Invalid Entity Type - unable to retrieve localization');
        }

        return this.http.get<LocalizationByIdResponse>(`/rest/localization/${type}`, { params: identifiers }).toPromise().then((res) => {
            const gsrModel = res;
            const localeList = this.localeService.getLocale(identifiers.venueId, null, null, true).list;

            localeList.forEach((value) => {
                const locale = value.id;
                const model = this.getDefaultSchema(type);

                if (!gsrModel[locale]) {
                    gsrModel[locale] = model;
                } else {
                    gsrModel[locale] = merge({}, model, gsrModel[locale]);
                }

                if (model.callToAction && !gsrModel[locale].callToAction) {
                    gsrModel[locale].callToAction = {};
                }
            });

            return gsrModel;
        });
    }

    public updateEntityLocalization(type: EntityType, data: Record<string, unknown>, identifiers: { flowKey?: string, tagKey?: string; venueId?: string; id?: string }): Promise<Record<string, unknown>> {
        if (!localizedSchemas[type]) {
            throw Error('Invalid Entity Type - unable to update localization');
        }

        return this.http.put<Record<string, unknown>>(`/rest/localization/${type}`, data, { params: identifiers }).toPromise();
    }

    public getLocalizationDefaultModel(type: EntityType, data: { [key: string]: any }, venueId: string): any {
        const model = cloneDeep(localizedSchemas[type]);
        const locale = this.localeService.getLocale(venueId, null, null, true);
        const defaultLocale = locale.default;
        const localeList = locale.list;

        forEach(model, (value, key) => {
            model[key] = !isUndefined(data[key]) ? cloneDeep(data[key]) : model[key];
        });

        const defaultModel = {};

        localeList.forEach((loc) => {
            defaultModel[loc.id] = cloneDeep(localizedSchemas[type]);
        });

        defaultModel[defaultLocale] = model;

        return defaultModel;
    }

    public getUserPrefsLocalizationDefaultModel(data: UserPrefsLocalizationModel, venueId: string): UserPrefsLocalizationByLocaleModel {
        const locale = this.localeService.getLocale(venueId);
        const defaultLocale = locale.default;
        const localeList = locale.list;

        const defaultModel: UserPrefsLocalizationByLocaleModel = {};

        localeList.forEach((loc) => {
            defaultModel[loc.id] = {
                screens: [
                    {
                        descriptionTitle: '',
                        descriptionText: '',
                    },
                ],
            };
        });

        defaultModel[defaultLocale].screens[0].descriptionTitle = data.screens[0].descriptionTitle;
        defaultModel[defaultLocale].screens[0].descriptionText = data.screens[0].descriptionText;

        return defaultModel;
    }

    public getQuestionLocalizationDefaultModel(data: LocalizedTagDefinitionValue, venueId: string): LocalizedTagDefinition {
        const locale = this.localeService.getLocale(venueId, null, null, true);
        const defaultLocale = locale.default;
        const localeList = locale.list;

        const defaultModel: LocalizedTagDefinition = {};
        const defaultValuesModel = data.values
            ? data.values.map((value) => ({
                  tagValueKey: value.tagValueKey,
                  label: '',
              }))
            : [];

        localeList.forEach((loc) => {
            defaultModel[loc.id] = cloneDeep(localizedSchemas[EntityType.TAG_DEFINITIONS]) as LocalizedTagDefinitionValue;
            defaultModel[loc.id].values = cloneDeep(defaultValuesModel);
        });

        defaultModel[defaultLocale].title = data.title;
        defaultModel[defaultLocale].label = data.label;

        defaultModel[defaultLocale].values.forEach((value, index) => {
            value.label = data.values[index].label;
        });

        return defaultModel;
    }
    
    public isLocalized(type: EntityType): boolean {
        return !!localizedSchemas[type];
    }

    private getDefaultSchema(type: EntityType): Record<string, unknown> {
        const schema = cloneDeep(localizedSchemas[type]);
        if (schema.callToAction) {
            schema.callToAction = {};
        }
        return schema;
    }
}
