import _ from 'lodash';

class Alert {
    constructor($scope, $sce, crConstants) {
        this.$scope = $scope;
        this.$sce = $sce;
        this.crConstants = crConstants;
    }

    $onInit() {
        this.alerts = [];

        this.$scope.$on(this.crConstants.events.SHOW_ALERT, (event, alert) => {
            alert.msg = this.$sce.trustAsHtml(alert.msg);
            this.alerts.push(alert);
        });
    }

    handleCta(alert) {
        alert.cta.onClick();
        this.dismissAlert(alert);
    }

    dismissAlert(alert) {
        this.alerts.splice(_.findIndex(this.alerts, alert), 1);
    }
}

export default Alert;
