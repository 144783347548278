import Controller from './associate-entity-modal.controller';
import template from './associate-entity-modal.html';
import './associate-entity-modal.less';

const Component = {
    template,
    bindings: {
        close: '&',
        dismiss: '&',
        resolve: '<',
    },
    controller: [
        '$state',
        '$sce',
        'crConstants',
        'crLocaleService',
        'crEntityService',
        'crErrorLoggingService',
        Controller,
    ],
};

export default Component;
