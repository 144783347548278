import _ from 'lodash';

class ToastController {
    constructor($scope, $timeout, $sce, crConstants) {
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.$sce = $sce;
        this.crConstants = crConstants;
    }

    $onInit() {
        this.toasts = [];

        this.$scope.$on(this.crConstants.events.SHOW_TOAST, (event, toast) => {
            toast.msg = this.$sce.trustAsHtml(toast.msg);
            this.toasts.push(toast);
            this.setTimeToLive(toast);
        });
    }

    setTimeToLive(toast) {
        const transitionTime = 500;
        const defaultTimeToLive = 5000;
        const timeToLive = Math.max(defaultTimeToLive, toast.timeToLive || 0) - transitionTime;

        this.$timeout(() => {
            this.toasts.splice(_.findIndex(this.toasts, toast), 1);
        }, timeToLive);
    }
}

export default ToastController;
