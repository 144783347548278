import angular from 'angular';
import 'angular-ui-validate';
import text from './resources/locale/en.json';
import InputComponent from './input.component';
import InputValidators from './input-validators';

const InputModule = angular
    .module('control-room.commons.form.components.input-legacy', ['ui.validate'])
    .constant('crInputText', text)
    .factory('crInputValidators', ['crInputText', InputValidators])
    .component('crInputLegacy', InputComponent);

export default InputModule;
