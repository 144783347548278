import ExtensionsBuilderRowController from './extensions-builder-row.controller';

import template from './extensions-builder-row.html';
import './extensions-builder-row.less';

const ExtensionsBuilderRowComponent = {
    template,
    bindings: {
        row: '<',
        rows: '<',
    },
    require: {
        form: '^form',
    },
    controller: ['crValidationBuilderService', ExtensionsBuilderRowController],
};

export default ExtensionsBuilderRowComponent;
