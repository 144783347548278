import { NgModule } from '@angular/core';

import { DropdownComponent } from './dropdown.component';

@NgModule({
  imports: [],
  exports: [DropdownComponent],
  declarations: [DropdownComponent],
  providers: [],
})
export class CrDropdownModule { }
