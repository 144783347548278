import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FeaturedEvents } from '../../models/featured-events.model';

@Injectable({
  providedIn: 'root',
})
export class FeaturedEventsService {
  baseUrl = '/rest/featured-events';

  constructor(private http: HttpClient) {}

  get(venueId: string): Promise<FeaturedEvents> {
    return this.http.get<FeaturedEvents>(this.baseUrl, { params: { venueId } }).toPromise();
  }

  update(body: any, venueId: string): Promise<any> {
    return this.http.put(this.baseUrl, body, { params: { venueId } }).toPromise();
  }
}
