import Controller from './event-schedule.controller';
import template from './event-schedule.html';
import './event-schedule.less';

const Component = {
    template,
    bindings: {
        customClass: '@?',
    },
    require: {
        form: '^form',
        crRowManagerRow: '^crRowManagerRow',
    },
    controller: ['$scope', '$timeout', 'crConstants', 'crScheduleService', Controller],
};

export default Component;
