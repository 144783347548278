import {
  Directive, ElementRef, Injector, Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { UpgradeComponent } from '@angular/upgrade/static';
import { Image } from '../../models';

export interface ImageUploaderChangeEvent {
  $event: {
    images: Image[]
  }
}

@Directive({
  selector: 'cr-image-uploader',
})
export class ImageUploaderComponent extends UpgradeComponent {
  @Input('name')
  crName: string;

  @Input()
  imageType: string;

  @Input()
  label: string;

  @Input()
  crRequired?: boolean;

  @Input()
  upgradedFormControl?: FormControl;

  @Input()
  images?: Image[];

  @Input()
  singleImageMode?: boolean;

  @Input()
  imageMinSize?: {
    height: number,
    width: number,
    aspectRatio: number,
  };

  @Input()
  cropperToolHeader?: string;

  @Output()
  onChange: EventEmitter<ImageUploaderChangeEvent>;

  constructor(elementRef: ElementRef, injector: Injector) {
    super('crImageUploader', elementRef, injector);
  }
}
