<div class="form-section">
    <div class="section-title">
        <hr />
        <span>{{ text.formSectionTitle.reservationSchedule }}</span>
    </div>

    <div
        class="control-label"
        *ngIf="!noActiveSchedules; else noActiveSchedulesMessage"
    >
        {{ text.formSectionDescription.reservationSchedule }}
    </div>
    <ng-template #noActiveSchedulesMessage>
        <div class="control-label">
            {{ text.formSectionDescription.noReservationSchedules }}
        </div>
    </ng-template>

    <div
        *ngFor="let scheduleForm of scheduleForms; let i = index"
        [formGroup]="scheduleForm"
        class="schedule-container"
    >
        <hr class="details-divider list-divider" />
        <div class="schedule-item">
            <cr-form-error
                *ngIf="controlHasError(scheduleForm)"
                class="input-error"
                [for]="scheduleForm"
                [messages]="validationMessages"
            >
            </cr-form-error>

            <div class="schedule-row">
                <label class="schedule-label control-label">{{
                    text.schedule.startDateLabel
                }}</label>
                <div class="schedule-inputs">
                    <div
                        class="input-container"
                        *ngIf="!isReadOnly; else startDatePreview"
                    >
                        <input
                            type="text"
                            [placeholder]="datePlaceHolder"
                            formControlName="startDate"
                            [bsConfig]="getStartDateConfig()"
                            #startDatePicker="bsDatepicker"
                            [isDisabled]="isExistingSchedule(i)"
                            bsDatepicker
                        />
                        <span
                            class="edit-field-icon icon-cr-calendar-basic"
                            (click)="startDatePicker.toggle()"
                        ></span>
                    </div>
                    <ng-template #startDatePreview>
                        <div class="input-container">
                            {{
                                scheduleForm.controls.startDate.value
                                    | date: "MM/dd/yyyy"
                            }}
                        </div>
                    </ng-template>
                    <div
                        class="input-container"
                        *ngIf="!isReadOnly; else endDatePreview"
                    >
                        <span>{{ text.schedule.endDateLabel }}</span>
                        <input
                            type="text"
                            [placeholder]="datePlaceHolder"
                            formControlName="endDate"
                            [bsConfig]="getEndDateConfig(scheduleForm)"
                            #endDatePicker="bsDatepicker"
                            bsDatepicker
                        />
                        <span
                            class="edit-field-icon icon-cr-calendar-basic"
                            (click)="endDatePicker.toggle()"
                        ></span>
                    </div>
                    <ng-template #endDatePreview>
                        <div class="input-container">
                            <span>{{ text.schedule.endDateLabel }}</span>
                            {{
                                scheduleForm.controls.endDate.value
                                    ? (scheduleForm.controls.endDate.value
                                      | date: "MM/dd/yyyy")
                                    : text.schedule.endDateFallback
                            }}
                        </div>
                    </ng-template>
                    <div class="input-container">
                        <span>{{ text.schedule.selectedDaysLabel }}</span>
                        <cr-weekday-editor
                            [name]="'schedule-days-' + i"
                            [selectedDays]="getSelectedDays(scheduleForm)"
                            crRequired="true"
                            [disabled]="isExistingSchedule(i)"
                            (selectedDaysEmitter)="
                                onWeekdaySelect($event, scheduleForm)
                            "
                        ></cr-weekday-editor>
                    </div>
                </div>
                <div
                    *ngIf="scheduleForms.length > 1 && !isExistingSchedule(i)"
                    class="schedule-remove"
                >
                    <span
                        (click)="removeSchedule(i)"
                        class="cursor-pointer icon-cr-close"
                    ></span>
                </div>
            </div>
            <div class="schedule-row">
                <label class="schedule-label control-label">{{
                    text.schedule.firstInstanceTimeLabel
                }}</label>
                <cr-time-picker
                    formControlName="firstInstanceTime"
                    step="15"
                    *ngIf="!isReadOnly; else firstInstancePreview"
                ></cr-time-picker>
                <ng-template #firstInstancePreview>
                    <span>{{
                        scheduleForm.controls.firstInstanceTime.value
                    }}</span>
                </ng-template>
            </div>
            <div class="schedule-row">
                <label class="schedule-label control-label">{{
                    text.schedule.lastInstanceTimeLabel
                }}</label>
                <cr-time-picker
                    formControlName="lastInstanceTime"
                    step="15"
                    *ngIf="!isReadOnly; else lastInstancePreview"
                ></cr-time-picker>
                <ng-template #lastInstancePreview>
                    <span>{{
                        scheduleForm.controls.lastInstanceTime.value
                    }}</span>
                </ng-template>
            </div>
            <div class="schedule-row">
                <label class="schedule-label control-label">{{
                    text.schedule.durationMinLabel
                }}</label>
                <div class="schedule-inputs">
                    <select
                        formControlName="durationMin"
                        [ngClass]="{
                            placeholder: !scheduleForm.controls.durationMin
                                .value
                        }"
                        *ngIf="!isReadOnly; else minutesPreview"
                    >
                        <option ngValue="" disabled="true">
                            0 {{ text.schedule.durationMinUnits }}
                        </option>
                        <option
                            *ngFor="let minutes of minuteOptions"
                            [ngValue]="minutes"
                        >
                            {{ minutes }} {{ text.schedule.durationMinUnits }}
                        </option>
                    </select>
                    <ng-template #minutesPreview>
                        <span
                            >{{ scheduleForm.controls.durationMin.value }}
                            {{ text.schedule.durationMinUnits }}</span
                        >
                    </ng-template>
                    <div class="input-container">
                        <span>{{ text.schedule.quantityLabel }}</span>
                        <input
                            class="short"
                            type="number"
                            min="0"
                            formControlName="quantity"
                            placeholder="0"
                            *ngIf="!isReadOnly; else quantityPreview"
                        />
                        <ng-template #quantityPreview>
                            <span>{{
                                scheduleForm.controls.quantity.value
                            }}</span>
                        </ng-template>
                    </div>
                    <span
                        *ngIf="reservationUnit === 'TABLE'; else guestUnits"
                        >{{ text.schedule.quantityTablesUnits }}</span
                    >
                    <ng-template #guestUnits>
                        <span>{{ text.schedule.quantityGuestsUnits }}</span>
                    </ng-template>
                </div>
            </div>

            <div *ngIf="instanceTimes[i] && instanceTimes[i].length">
                <div class="control-label">
                    {{ text.formSectionDescription.scheduleInstances }}
                </div>

                <div class="schedule-instances">
                    <span
                        *ngFor="let instanceTime of instanceTimes[i]"
                        class="cr-chip"
                        >{{ instanceTime | date: "shortTime" }}</span
                    >
                </div>
            </div>
        </div>
        <input type="hidden" formControlName="scheduleIndex" />
    </div>

    <hr *ngIf="!isReadOnly" class="details-divider list-divider" />
    <a
        *ngIf="!isReadOnly"
        role="button"
        (click)="addSchedule()"
        [ngClass]="addScheduleClasses"
        >{{ text.schedule.addScheduleButton }}</a
    >
</div>

<!-- Reservation Schedule Modification -->
<div class="form-section">
    <div class="section-title">
        <hr />
        <span>{{
            text.formSectionTitle.reservationScheduleModifications
        }}</span>
    </div>

    <div class="control-label">
        {{ text.formSectionDescription.reservationScheduleModifications }}
    </div>

    <div
        *ngFor="let modificationForm of modificationForms; let i = index"
        [formGroup]="modificationForm"
        class="modification-container"
    >
        <div *ngIf="!noActiveSchedules">
            <hr class="details-divider list-divider" />

            <div class="location-input">
                <label>{{ text.scheduleModification.dateLabel }}</label>
                <div
                    class="date-input-container"
                    *ngIf="!isReadOnly; else modificationDatePreview"
                >
                    <input
                        type="text"
                        [placeholder]="datePlaceHolder"
                        formControlName="date"
                        [bsConfig]="getModificationDateConfig()"
                        #datePicker="bsDatepicker"
                        [isDisabled]="!isModificationEditable(i)"
                        bsDatepicker
                    />
                    <span
                        class="edit-field-icon icon-cr-calendar-basic"
                        (click)="datePicker.toggle()"
                    ></span>
                </div>
                <ng-template #modificationDatePreview>
                    <div class="date-input-container">
                        {{
                            modificationForm.controls.date.value
                                | date: "MM/dd/yyyy"
                        }}
                    </div>
                </ng-template>
            </div>

            <div class="location-input">
                <label>{{
                    text.scheduleModification.selectedSessionsLabel
                }}</label>
                <div
                    class="modification-inputs"
                    *ngIf="!isReadOnly; else selectedSessionsPreview"
                >
                    <cr-chip-selector
                        name="sessions"
                        [ngClass]="getSessionClasses(i)"
                        [currentTags]="getSelectedSessions(i)"
                        [options]="getAvailableSessions(i)"
                        addButton="true"
                        (onSelect)="sessionsSelected(i, $event)"
                        [disableRemove]="
                            modificationForm.controls.selectedSessions.disabled
                        "
                    ></cr-chip-selector>
                    <label class="no-wrap">
                        <input type="checkbox" formControlName="allSessions" />
                        {{ text.scheduleModification.allSessionsLabel }}
                    </label>
                </div>
                <ng-template #selectedSessionsPreview>
                    <div>
                        <span
                            *ngFor="let session of getSelectedSessions(i)"
                            class="cr-chip"
                            >{{ session.label }}</span
                        >
                    </div>
                </ng-template>
                <cr-form-error
                    *ngIf="
                        controlHasError(
                            modificationForm.controls.selectedSessions
                        )
                    "
                    class="input-error"
                    [for]="modificationForm.controls.selectedSessions"
                    [messages]="validationMessages"
                >
                </cr-form-error>
            </div>

            <div
                class="location-input"
                *ngIf="!isReadOnly; else modificationTypePreview"
            >
                <div class="modification-inputs">
                    <label>
                        <input
                            type="radio"
                            formControlName="modificationType"
                            [value]="capacityModificationType"
                            [attr.disabled]="
                                modificationFormIsWithinScheduleWindow(
                                    modificationForm
                                ) === true || null
                            "
                        />
                        {{ text.scheduleModification.modifyCapacityLabel }}
                    </label>
                </div>
                <div
                    *ngIf="isCapacityModification(i)"
                    class="modification-inputs"
                >
                    <label class="indented">
                        <input
                            type="number"
                            class="short"
                            formControlName="capacity"
                            min="0"
                            placeholder="0"
                        />
                        <span
                            *ngIf="reservationUnit === 'TABLE'; else guestUnits"
                            >{{ text.schedule.quantityTablesUnits }}</span
                        >
                        <ng-template #guestUnits>
                            <span>{{ text.schedule.quantityGuestsUnits }}</span>
                        </ng-template>
                    </label>
                </div>
                <div class="modification-inputs">
                    <label>
                        <input
                            type="radio"
                            formControlName="modificationType"
                            [value]="cancelFutureModificationType"
                            [attr.disabled]="
                                modificationFormIsWithinScheduleWindow(
                                    modificationForm
                                ) === true || null
                            "
                        />
                        {{ text.scheduleModification.modifyCancelFutureLabel }}
                    </label>
                </div>
                <div class="modification-inputs">
                    <label>
                        <input
                            type="radio"
                            formControlName="modificationType"
                            [value]="cancelCurrentModificationType"
                            [attr.disabled]="
                                modificationFormIsWithinScheduleWindow(
                                    modificationForm
                                ) !== true ||
                                modificationForm.controls.date.valid !== true ||
                                null
                            "
                        />
                        {{ text.scheduleModification.modifyCancelCurrentLabel }}
                    </label>
                </div>
            </div>
            <ng-template #modificationTypePreview>
                <div *ngIf="isCapacityModification(i)" class="location-input">
                    {{ text.scheduleModification.modifyCapacityLabel }}
                    <div class="indented">
                        {{ modificationForm.controls.capacity.value }}
                        <span
                            *ngIf="reservationUnit === 'TABLE'; else guestUnits"
                            >{{ text.schedule.quantityTablesUnits }}</span
                        >
                        <ng-template #guestUnits>
                            <span>{{ text.schedule.quantityGuestsUnits }}</span>
                        </ng-template>
                    </div>
                </div>
                <div
                    *ngIf="isCancelFutureModification(i)"
                    class="location-input"
                >
                    {{ text.scheduleModification.modifyCancelFutureLabel }}
                </div>
                <div
                    *ngIf="isCancelCurrentModification(i)"
                    class="location-input"
                >
                    {{ text.scheduleModification.modifyCancelCurrentLabel }}
                </div>
            </ng-template>
        </div>
    </div>

    <hr *ngIf="!isReadOnly" class="details-divider list-divider" />
    <a
        *ngIf="!isReadOnly"
        role="button"
        (click)="addModification()"
        [ngClass]="addModificationClasses"
        >{{ text.scheduleModification.addModificationButton }}</a
    >
</div>
