import _ from 'lodash';

class PoiEditService {
    constructor($q, crPoiService, crImageService, crEntityService, crMenusService, crLoQueueService) {
        this.$q = $q;
        this.crPoiService = crPoiService;
        this.crImageService = crImageService;
        this.crEntityService = crEntityService;
        this.crMenusService = crMenusService;
        this.crLoQueueService = crLoQueueService;
    }

    getStickerImage(images) {
        return this.crImageService.getStickerImage(images);
    }

    getMainImage(images) {
        return this.crImageService.getMainImage(images);
    }

    getStickerSrc(images) {
        return this.crImageService.getStickerSrc(images);
    }

    getMainImageSrc(images) {
        return this.crImageService.getMainImageSrc(images);
    }

    getPoiCategories(venueId, locale) {
        return this.crEntityService.getCategoriesWithHierarchy('poi-categories', venueId, locale);
    }

    attributeCategoryChipLabels(categories, displayCategories) {
        const availableCategories = categories.map((category) => ({
            label: category.hierarchyLabel,
            id: category.id,
            parentId: category.parentId,
        }));

        const availableCategoriesMap = _.keyBy(availableCategories, 'id');

        displayCategories = (displayCategories || []).map((category) => availableCategoriesMap[category.id]);

        return { availableCategories, displayCategories };
    }

    getPoiAttributes(venueId) {
        return this.crPoiService.getPoiAttributes({ venueId }).then((res) => res.content);
    }

    getData(poiId, venueId) {
        return this.crPoiService.getPoiDetails(poiId, venueId).then((res) => res);
    }

    getPoiTypes(venueId) {
        return this.crPoiService.getPoiTypes({ venueId }).then((res) => res.content);
    }

    getQsmartRides(venueId) {
        return this.crLoQueueService.getRides(venueId).catch(() => []) || [];
    }

    getMenus(venueId, poiId, locale) {
        // If no poi ID is specified, this was likely called from the "Create POI" screen.
        if (!poiId) {
            return Promise.resolve([]);
        }
        const fixMenuName = (menu) => ({ name: menu.content.name, ...menu });
        return this.crMenusService
            .getMenusForPoi(venueId, poiId, locale)
            .then((res) => res.content)
            .then((content) => content.map(fixMenuName));
    }
}

export default PoiEditService;
