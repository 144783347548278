import _ from 'lodash';

class PoiDetailsService {
    constructor($q, crPoiService, crImageService, crEntityService, crMenusService, crLoQueueService) {
        this.$q = $q;
        this.crPoiService = crPoiService;
        this.crImageService = crImageService;
        this.crEntityService = crEntityService;
        this.crMenusService = crMenusService;
        this.crLoQueueService = crLoQueueService;
    }

    getStickerSrc(images) {
        return this.crImageService.getStickerSrc(images);
    }

    getMainImageSrc(images) {
        return this.crImageService.getMainImageSrc(images);
    }

    updatePoiState(poiId, poiData) {
        return this.crPoiService.updatePoiState(poiId, poiData);
    }

    deletePoi(id) {
        return this.crPoiService.deletePoi(id).then((res) => res);
    }

    getCategoryHierarchyForCategories(venueId, categories, locale) {
        return this.crEntityService
            .getCategoriesWithHierarchy('poi-categories', venueId, locale)
            .then((displayCategories) =>
                this.$q.all(categories.map((category) => _.find(displayCategories, { id: category.id })))
            );
    }

    getMenus(venueId, poiId, locale) {
        const fixMenuName = (menu) => Object.assign(menu, { name: menu.content.name });
        return this.crMenusService
            .getMenusForPoi(venueId, poiId, locale)
            .then((res) => res.content)
            .then((content) => content.map(fixMenuName));
    }

    getQsmartRides(venueId) {
        return this.crLoQueueService.getRides(venueId).catch(() => []) || [];
    }
}

export default PoiDetailsService;
