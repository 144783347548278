import angular from 'angular';

import BeaconEditComponent from './beacon-edit.component';

import BeaconEditService from './beacon-edit.service';

const BeaconEditModule = angular
    .module('control-room.beacons.components.beacon-edit', [])
    .component('crBeaconEdit', BeaconEditComponent)
    .service('crBeaconEditService', ['$q', 'crBeaconsService', 'crImageService', BeaconEditService]);

export default BeaconEditModule;
