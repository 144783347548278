import _ from 'lodash';
import angular from 'angular';

function InfiniteScrollDirective($window) {
    return {
        restrict: 'A',
        link: (scope, elem, attrs) => {
            const scrollFn = scope.$ctrl[attrs.crInfiniteScroll];
            const scrollContainerSelector = attrs.crInfiniteScrollContainerSelector;
            const loadOffset = 50;
            let windowHeight = $window.document.documentElement.clientHeight;

            function triggerScroll() {
                const y = $window.pageYOffset;
                const elemTop = elem[0].offsetTop;
                const elemBottom = elemTop + elem[0].clientHeight;

                if (elemBottom < y + windowHeight + loadOffset) {
                    scrollFn();
                }
            }

            function elementScrollTrigger(element) {
                const viewportBottom = element.getClientRects()[0].bottom;

                const children = Array.prototype.slice.call(element.children);
                const childrenBottom = children
                    .map((child) => child.getClientRects()[0].bottom)
                    .reduce((a, v) => Math.max(a, v), 0);

                if (childrenBottom < viewportBottom + loadOffset) {
                    scrollFn();
                }
            }

            if (scrollContainerSelector) {
                const $scrollContainer = angular.element(elem[0].querySelector(scrollContainerSelector));
                $scrollContainer.on(
                    'scroll',
                    _.debounce(elementScrollTrigger.bind(null, $scrollContainer[0]), 250, { maxWait: 1000 })
                );
                scope.$on('$destroy', () => {
                    $scrollContainer.off('scroll');
                });
            } else {
                const $win = angular.element($window);
                $win.on('scroll', _.debounce(triggerScroll, 250, { maxWait: 1000 }));
                $win.on(
                    'resize',
                    _.debounce(() => {
                        windowHeight = $window.document.documentElement.clientHeight;
                    }, 250)
                );

                scope.$on('$destroy', () => {
                    $win.off('scroll');
                    $win.off('resize');
                });
            }
        },
    };
}

export default InfiniteScrollDirective;
