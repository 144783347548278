import angular from 'angular';

import TabsComponent from './tabs.component';
import TabComponent from './tab/tab.component';

const TabsModule = angular
    .module('control-room.commons.layout.components.tabs', [])
    .component('crTabs', TabsComponent)
    .component('crTab', TabComponent);

export default TabsModule;
