<div class="modal-header">
    <h4 class="modal-title pull-left">{{ text.title }}</h4>
</div>
<div class="modal-body">
  <div class="warning-text">
    <span class="warning-icon">&#9888;</span> {{ text.warningText }}
  </div>
  <div>
    {{ text.modalBodyText }}
  </div> 
</div>
<div class="modal-footer-buttons">
    <button
          class="btn btn-danger cancel-btn"
          (click)="onCloseModal()"
    >
      {{ text.cancelText }}
    </button>
    <button
          class="btn btn-danger submit-btn"
          (click)="onCancelAll()"
    >
      {{ text.confirmText }}
    </button>
</div>