import { Component, Input } from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
  selector: 'cr-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class CrSpinnerComponent {
  get small(): boolean {
    return this._small;
  }

  @Input()
  set small(value: boolean) {
    this._small = coerceBooleanProperty(value);
  }

  get background(): boolean {
    return this._background;
  }

  @Input()
  set background(value: boolean) {
    this._background = coerceBooleanProperty(value);
  }

  private _small: boolean;

  private _background: boolean;
}
