import _ from 'lodash';

class Breadcrumbs {
    $onChanges(changes) {
        if (
            changes.currentStep &&
            (_.isNil(this.farthestStep) || changes.currentStep.currentValue > this.farthestStep)
        ) {
            this.farthestStep = changes.currentStep.currentValue;
        }
    }

    getStepClasses(step) {
        return {
            active: step.value === this.currentStep,
            disabled: step.value > this.farthestStep,
        };
    }

    changeStep(step) {
        if (step.value !== this.currentStep && step.value <= this.farthestStep) {
            this.onStepChange({
                $event: { step },
            });
        }
    }
}

export default Breadcrumbs;
