import { isPlatformBrowser } from '@angular/common';
import { BrowserWindowRef } from '../../../core';

export function windowFactory(browserWindowRef: BrowserWindowRef, platformId: {}): Window | {} {
  if (isPlatformBrowser(platformId)) {
    return browserWindowRef.nativeWindow;
  }

  return {};
}
