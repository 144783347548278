import controller from './cvt.controller';
import template from './cvt.html';

const component = {
    template,
    bindings: {},
    controller: ['crConstants', controller],
};

export default component;
