import moment from 'moment';
import { VenueService } from '../venue/venue.service';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
class DateRangeService {
   
    constructor( private crVenueService: VenueService) {}

    getToday() {
        return {
            start: moment().startOf('day').toISOString(), 
            end: moment().endOf('day').toISOString(),
        };
    }

    getPastRange(option) {
        return {
            start: moment().startOf('day').subtract(1, option).toISOString(),
            end: moment().startOf('day').subtract(1, 'millisecond').toISOString(),
        };
    }

    getFutureRange(option, venueId) {
        const tz = this.crVenueService.getTimezone(venueId).name;
        let start;
        let end;

        if (option === 'week') {
            start = moment().tz(tz).startOf('week').toISOString();
            end = moment().tz(tz).endOf('week').toISOString();
        } else {
            start = moment().tz(tz).startOf('day').toISOString();
            end = moment().tz(tz).endOf('day').add(1, option).toISOString();
        }

        return { start, end };
    }

    getIntervalsForRange(range) {
        let intervals = [];
        const start = moment(range.start);
        const end = moment(range.end);

        const duration = Math.round(moment.duration(end.diff(start)).asDays());

        if (duration <= 8) {
            intervals = ['day'];
        } else if (duration > 8 && duration <= 32) {
            intervals = ['week', 'day'];
        } else if (duration > 30 && duration <= 91) {
            intervals = ['month', 'week'];
        } else if (duration > 91 && duration <= 365) {
            intervals = ['month', /* 'quarter', */ 'week'];
        } else if (duration > 365) {
            intervals = ['month'];
        }

        return intervals;
    }
}

export default DateRangeService;
