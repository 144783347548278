import angular from 'angular';
import text from './resources/locale/en.json';

import component from './create-kitchen-modal.component';
import service from './create-kitchen.service';

const CreateKitchenModalModule = angular
    .module('control-room.app.menus.create-kitchen-modal', [])
    .constant('crCreateKitchenModalText', text)
    .component('crCreateKitchenModal', component)
    .service('crCreateKitchenService', ['crEntityService', 'crConstants', service]);

export default CreateKitchenModalModule;
