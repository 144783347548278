import { NgModule } from '@angular/core';

import { ImageUploaderComponent } from './image-uploader.component';

@NgModule({
  imports: [],
  exports: [ImageUploaderComponent],
  declarations: [ImageUploaderComponent],
  providers: [],
})
export class CrImageUploaderModule { }
