import {
  Component, Input, Output, EventEmitter, ViewChild, ElementRef, OnInit,
} from '@angular/core';
import { FormControl, NgControl, ControlValueAccessor } from '@angular/forms';
import { noop } from 'rxjs';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
  selector: 'cr-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements ControlValueAccessor, OnInit {
  @Input() placeholder: string;

  @Input() maxlength: string;

  @Output() focus = new EventEmitter<FocusEvent>();

  @Output() blur = new EventEmitter<FocusEvent>();

  @Output() keypress = new EventEmitter<KeyboardEvent>();

  @Output() keydown = new EventEmitter<KeyboardEvent>();

  @Output() keyup = new EventEmitter<KeyboardEvent>();

  @Output() update = new EventEmitter<string>();

  control: FormControl;

  @ViewChild('input', { static: true }) inputElement: ElementRef;

  private _value: string;

  private _disabled: boolean;

  constructor(private ngControl: NgControl) {
    this.ngControl.valueAccessor = this;
  }

  get disabled(): boolean {
    return this._disabled;
  }

  @Input()
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
  }

  ngOnInit() {
    this.control = this.ngControl.control as FormControl;
  }

  onChange: (_?: any) => void = () => noop();

  onTouched: (_?: any) => void = () => noop();

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  get value(): string {
    return this._value;
  }

  set value(value: string) {
    this._value = value;
    this.onChange(this._value);
    this.update.emit(this._value);
  }

  writeValue(value: string) {
    this.value = value;
  }

  onInput(value: string) {
    this.value = value;
  }

  onFocus(event: FocusEvent) {
    this.focus.emit(event);
  }

  onBlur(event: FocusEvent) {
    this.onTouched(event);
    this.blur.emit(event);
  }

  onKeydown(event: KeyboardEvent) {
    this.keydown.emit(event);
  }

  onKeypress(event: KeyboardEvent) {
    this.keypress.emit(event);
  }

  onKeyup(event: KeyboardEvent) {
    this.keyup.emit(event);
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  clear(event: MouseEvent) {
    event.stopPropagation();
    this.value = '';

    setTimeout(() => {
      this.inputElement.nativeElement.focus();
    });
  }

  focusInput() {
    this.inputElement.nativeElement.focus();
  }
}
