import { Component, OnInit } from '@angular/core';
import { StateService } from '@uirouter/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { LocalizedText } from '../../../../../core';
import { POI } from '../../../../../shared/models/poi.model';
import { PoiService } from '../../../../../shared/services/poi/poi.service';
import text from './resources/locale/en.json';

@Component({
  selector: 'cr-create-dining-location-modal',
  templateUrl: './create-dining-location-modal.component.html',
  styleUrls: ['./create-dining-location-modal.component.scss'],
})
export class CreateDiningLocationModalComponent implements OnInit {
  text: LocalizedText;

  isLoading: boolean;

  isSearching: boolean;

  searchSub = new Subject<string>();

  searchQuery = '';

  poiList: POI[] = [];

  selectedPoi: POI;

  poiCategories: any[] = [];

  constructor(public bsModalRef: BsModalRef, private state: StateService, private poiService: PoiService) {
    this.text = text;
    this.isLoading = true;
  }

  ngOnInit() {
    Promise.all([
      this.poiService.getPoisPage(this.state.params.venueId),
      this.poiService.getPoiCategories(this.state.params.venueId),
    ])
      .then(([poiList, poiCategories]) => {
        this.poiList = poiList.content;
        this.poiCategories = poiCategories;
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        this.isLoading = false;
      });

    this.searchSub
      .pipe(
        tap((query) => {
          this.searchQuery = query;
        }),
        debounceTime(300),
      )
      .subscribe((query) => {
        console.log(query);
      });
  }

  searchChangeEvent(event: Event) {
    this.searchSub.next((event.target as HTMLInputElement).value);
  }

  clearSearch() {
    this.searchQuery = '';
    this.isSearching = false;
  }

  searchFocus() {
    this.isSearching = true;
  }

  searchBlur() {
    this.isSearching = false;
  }

  selectPoi(poiId: string) {
    this.selectedPoi = this.poiList.find((poi) => poi.id === poiId);
  }

  createDiningLocation() {
    this.state.go('client.fnb-reservations.dining-locations-editor', { poiId: this.selectedPoi.id }).then(() => {
      this.bsModalRef.hide();
    });
  }
}
