import moment from 'moment';

class DatePickerController {
    constructor($state, crConstants, crVenueService, $timeout) {
        this.$state = $state;
        this.crConstants = crConstants;
        this.crVenueService = crVenueService;
        this.$timeout = $timeout;
    }

    $onInit() {
        this.dateFormat = this.crConstants.date.format.pickerModel;
        this.isoDateFormat = this.crConstants.date.format.isoNoOffset;
        this.placeholder = this.crConstants.placeholder.datePicker;
        this.isOpen = false;

        this.dateOptions = {
            formatDay: this.crConstants.date.format.pickerDay,
            showWeeks: false,
            minDate: this.minDate,
            maxDate: this.maxDate,
        };

        this.handleUpdatedIsoDateString();
    }

    $onChanges(changes) {
        if (changes && changes.isoDateString) {
            if (!changes.isoDateString.isFirstChange()) {
                this.handleUpdatedIsoDateString();
            }
        }

        if (changes && changes.minDate && !changes.minDate.isFirstChange()) {
            this.dateOptions.minDate = this.minDate;
        }

        if (changes && changes.maxDate && !changes.maxDate.isFirstChange()) {
            this.dateOptions.maxDate = this.maxDate;
        }
    }

    handleUpdatedIsoDateString() {
        if (this.isoDateString) {
            this.date = moment(this.isoDateString).toDate();
            this.updateDateString();
        } else {
            this.date = null;
            this.dateString = '';
        }
    }

    updateDateString() {
        this.dateString = moment(this.date).format(this.dateFormat);
    }

    // Change via the picker
    onDateChange() {
        this.isOpen = false;
        this.setValidity(true);
        this.updateIsoDateString(this.date);
        this.setTouched();
    }

    // Change via the input
    onDateStringChange(event) {
        const dateString = event.model;

        // Update our iso date or clear it if empty
        if (dateString) {
            const momentDate = moment(dateString, this.dateFormat);
            const valid = this.isValid(momentDate);

            if (valid) {
                this.date = momentDate.toDate();
                this.updateIsoDateString(this.date);
            }

            this.setValidity(valid);
        } else {
            this.update(dateString);
        }
    }

    toggled(open) {
        // When closing, act like a blur by setting form to touched
        if (!open) {
            this.setTouched();
        }
    }

    updateIsoDateString(date) {
        const isoDateString = this.getIsoDateString(date);

        // Update only if the string changed
        if (isoDateString !== this.isoDateString) {
            this.update(isoDateString);
        } else {
            this.revertValue();
        }
    }

    isValid(momentDate) {
        let valid = momentDate.isValid();

        if (valid && this.maxDate) {
            valid = momentDate.isSameOrBefore(this.maxDate);
        }

        if (valid && this.minDate) {
            valid = momentDate.isSameOrAfter(this.minDate);
        }

        return valid;
    }

    // TODO: Find a better way to handle reverting a value. Using $timeout to force $onChanges to fire.
    revertValue() {
        this.dateString = '--';
        this.$timeout(() => {
            this.updateDateString();
        });
    }

    getIsoDateString(date) {
        let isoDate = moment(date);

        if (this.endOfDay) {
            isoDate = isoDate.endOf('day');
        }

        return isoDate.format(this.isoDateFormat);
    }

    update(isoDateString) {
        let dateString = isoDateString;
        if (this.outputDateFormat) {
            dateString = moment(isoDateString).format(this.outputDateFormat);
        }

        this.onUpdate({
            $event: {
                model: dateString,
            },
        });
    }

    setValidity(valid) {
        const formElem = this.form[this.name];

        if (formElem) {
            formElem.$setValidity('date', valid);
        }
    }

    setTouched() {
        this.$timeout(() => {
            const formElem = this.form[this.name];

            if (formElem && !formElem.$touched) {
                formElem.$setTouched();
            }
        });
    }

    keepOpen(e) {
        e.stopPropagation();
    }
}

export default DatePickerController;
