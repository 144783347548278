import template from './pdf-modal.html';
import './pdf-modal.less';
import PdfModalController from './pdf-modal.controller';

const PdfModalComponent = {
    template,
    bindings: {
        close: '&',
        dismiss: '&',
        resolve: '<',
    },
    controller: ['$scope', '$state', '$sce', 'crFileUploadService', PdfModalController],
};

export default PdfModalComponent;
