import { Inject, Injectable } from '@angular/core';
import { uniq } from 'lodash';
import moment from 'moment';
import { Constants } from '../../../core/constants';
import { CR_CONSTANTS } from '../constants/constants';

@Injectable({
    providedIn: 'root',
})
export class TimeService {
    constructor(@Inject(CR_CONSTANTS) private constants: Constants) {}

    getTimeSeries(minutes: moment.DurationInputArg1, format: string, start: moment.DurationInputArg1, end: moment.DurationInputArg1): string[] {
        const day = moment().startOf('day');
        let endTime = moment().endOf('day');
        const times: string[] = [];

        if (start) {
            day.add(start, 'hours');
        }

        if (end) {
            endTime = moment().startOf('day').add(end, 'hours');
        }

        while (day.isSameOrBefore(endTime)) {
            times.push(day.format(format));
            day.add(minutes, 'minute');
        }

        return uniq(times);
    }

    getShortTimeRange(isoDateStart: moment.MomentInput, isoDateEnd: moment.MomentInput, tz: string): string {
        const tzStart = moment(isoDateStart).tz(tz);
        const tzEnd = moment(isoDateEnd).tz(tz);

        // Determine if same day (midnight to midnight is same day in our case so subtract a millisecond from end)
        const sameDay = tzStart.isSame(tzEnd.clone().subtract(1, 'millisecond'), 'day');

        // See if the event is over 12 hours. We don't want to show 12 - 12 AM when both times are midnight
        const halfDayOrMore = tzEnd.diff(tzStart, 'hours') >= 12;

        const excludeMeridiem = !halfDayOrMore && sameDay && tzStart.format('A') === tzEnd.format('A');
        const startTime = this.getShortTimeFromIsoDate(isoDateStart, tz, excludeMeridiem);
        const endTime = this.getShortTimeFromIsoDate(isoDateEnd, tz, null);

        let timeRange: string;

        if (!sameDay) {
            const startDay = `${tzStart.format(this.constants.date.format.shortNoYear)}, `;
            const endDay = `${tzEnd.format(this.constants.date.format.shortNoYear)}, `;
            timeRange = `${startDay}${startTime} - ${endDay}${endTime}`;
        } else {
            timeRange = `${startTime} - ${endTime}`;
        }

        return timeRange;
    }

    getShortTimeFromIsoDate(isoDate: moment.MomentInput, tz: string, excludeMeridiem: boolean): string {
        const h: string = moment.tz(isoDate, tz).format('h');
        const m: string = moment.tz(isoDate, tz).format('m');
        const A: string = moment.tz(isoDate, tz).format('A');

        const minsPart = m === '0' ? '' : `:${m}`;

        return excludeMeridiem ? `${h}${minsPart}` : `${h}${minsPart} ${A}`;
    }

    roundTimeToNearQuarterHour(hours: number, minutes: number): string {
        const roundedMin = (Math.floor((minutes + 7.5)/15) * 15) % 60;
        const roundedHour = minutes > 52 ? (hours === 23 ? 0 : ++hours) : hours;
        
        return `${this.checkTime(roundedHour)}:${this.checkTime(roundedMin)}:00` 
       
    }

    checkTime(i: number): string {
        let result = `${i}`;
        if (i < 10) {
          result = `0${i}`;
        }
        return result;
    }
}
