import moment from 'moment';

class TimePickerController {
    constructor(crConstants, crTimeService, $timeout) {
        this.crConstants = crConstants;
        this.crTimeService = crTimeService;
        this.$timeout = $timeout;
    }

    $onInit() {
        this.timeFormat = this.crConstants.time.format.pickerModel;
        this.interval = this.interval || 30;
        this.timeSeries = this.crTimeService.getTimeSeries(this.interval, this.timeFormat, this.start, this.end);
        this.placeholder = this.crConstants.placeholder.timePicker;
        this.isoTimeFormat = this.crConstants.time.format.isoNoOffset;

        this.handleUpdatedIsoTimeString();
    }

    $onChanges(changes) {
        if (changes && changes.isoTimeString) {
            if (!changes.isoTimeString.isFirstChange()) {
                this.handleUpdatedIsoTimeString();
            }
        }
    }

    handleUpdatedIsoTimeString() {
        if (this.isoTimeString) {
            this.updateTimeString();
        } else {
            this.timeString = '';
            this.setValidity(true);
        }
    }

    updateTimeString() {
        this.timeString = moment(this.isoTimeString, this.isoTimeFormat).format(this.timeFormat);
    }

    // Change via the picker
    onTimeChange(time) {
        this.timeString = time;
        this.setValidity(true);
        this.updateIsoTimeString(this.timeString);
        this.setTouched();
    }

    // Change via the input
    onTimeStringChange(event) {
        let timeString = event.model;

        // Update our iso time or clear it if empty
        if (timeString) {
            const momentTime = moment(timeString, this.timeFormat);
            const valid = momentTime.isValid();

            if (valid) {
                timeString = momentTime.format(this.timeFormat);
                this.updateIsoTimeString(timeString);
            }

            this.setValidity(valid);
        } else {
            this.setValidity(true);
            this.update(timeString);
        }
    }

    toggled(open) {
        // When closing, act like a blur by setting form to touched
        this.timeSeries = this.crTimeService.getTimeSeries(this.interval, this.timeFormat, this.start, this.end);
        if (!open) {
            this.setTouched();
        }
    }

    updateIsoTimeString(timeString) {
        const isoTimeString = this.getIsoTimeString(timeString);

        // Update only if the string changed
        if (isoTimeString !== this.isoTimeString) {
            this.update(isoTimeString);
        } else {
            this.revertValue();
        }
    }

    // TODO: Find a better way to handle reverting a value. Using $timeout to force $onChanges to fire.
    revertValue() {
        this.timeString = '--';
        this.$timeout(() => {
            this.updateTimeString();
            this.update(this.isoTimeString);
        });
    }

    getIsoTimeString(timeString) {
        return moment(timeString, this.timeFormat).format(this.isoTimeFormat);
    }

    update(isoTimeString) {
        this.onUpdate({
            $event: {
                model: isoTimeString,
            },
        });
    }

    close() {
        this.onClose({
            $event: {
                model: this.isoTimeString,
            },
        });
    }

    setValidity(valid) {
        const formElem = this.form[this.name];

        if (formElem) {
            formElem.$setValidity('time', valid);
        }
    }

    setTouched() {
        this.$timeout(() => {
            const formElem = this.form[this.name];

            if (formElem && !formElem.$touched) {
                formElem.$setTouched();
            }
        });
    }
}

export default TimePickerController;
