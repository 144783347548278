import controller from './cta-button-configure.controller';
import template from './cta-button-configure.html';
import './cta-button-configure.less';

const component = {
    template,
    require: {
        form: '^form',
    },
    bindings: {
        entity: '<',
        locale: '<',
        menus: '<',
        onUpdate: '&',
    },
    controller: [controller],
};

export default component;
