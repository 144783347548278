import { Component, HostListener, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { StateService } from '@uirouter/core';
import { LocalizedText } from '../../../../core';
import { ToastService } from '../../../../ng1-factories';
import { KitchenService } from '../../../../shared';
import { KitchenMinimal, KitchenMinimalList } from '../../../../shared/models/kitchen.model';
import { NavigationService } from '../../../../shared/services/navigation/navigation.service';
import { menuRoutes } from '../../menus.routes';
import text from './resources/en.json';

@Component({
  selector: 'cr-kitchen-master-details',
  templateUrl: './kitchen-master-details.component.html',
  styleUrls: ['./kitchen-master-details.component.scss']
})
export class KitchenMasterDetailsComponent implements OnInit {

  kitchens: KitchenMinimal[] = [];
  filteredKitchens: KitchenMinimal[] = [];
  kitchenId: string = null;
  editKitchen = false;
  searchFormControl = new FormControl();
  isLoading = true;
  isError = false;
  isMobile = false;

  text: LocalizedText

  params = {
    page: 1,
    perPage: 40,
    sort: 'asc',
    sortby: 'name',
    state: 'enabled',
    search: '',
    venueId: ''
};

  constructor(private state: StateService, private kitchenService: KitchenService, private navigationService: NavigationService, private toastService: ToastService) {
    this.text = text;
  }

  ngOnInit(): void {
    if (window.innerWidth < 992)
      this.isMobile = true;
    this.params.venueId = this.state.params.venueId;
    this.params.search = this.state.params.search;

    if (this.state.params.kitchenId) {
      this.kitchenId = this.state.params.kitchenId;
    }

    this.getKitchensForList();
    this.toastService.autoToast(this.state.params);
  }

  @HostListener('window:resize', ['$event'])
  onResize($event) {
    this.isMobile = window.innerWidth < 992;
  }

  getKitchensForList(): void {
    this.isError = false;
    this.isLoading = true;
    this.kitchenService.getMinimalKitchensList(this.params.venueId, this.params.page, this.params.sortby, 'asc', this.params.state).then((data: KitchenMinimalList) => {
      this.kitchens = data.places;
      this.filteredKitchens = data.places;

      if (this.state.params.kitchenId) {
        if (!this.state.current.url.endsWith('details')) {
          this.editKitchen = true;
        }
        this.kitchenId = this.state.params.kitchenId;
      }
      this.isLoading = false;
    }).catch(err => {
      this.isLoading = false;
      this.isError = true;
      console.log(err);
    });
  }

  onKitchenSelected(kitchen: KitchenMinimal): void {
    if (this.navigationService.conditionalLambda === undefined || !this.navigationService.conditionalLambda()) {
      this.navigationService.disableConfirmNavigation();
      this.editKitchen = false;
      this.kitchenId = kitchen.id;
      this.state.go(menuRoutes.KITCHEN, { kitchenId: kitchen.id, created: false });
      this.editKitchen = false;
    } else {
      this.navigationService.openConfirmModal().then(() => {
        this.navigationService.disableConfirmNavigation();
        this.state.go(menuRoutes.KITCHEN, { kitchenId: kitchen.id, created: false });
        this.editKitchen = false;
        this.kitchenId = kitchen.id;
      }).catch(() => null);
    }
  }

  onKitchenBack(): void {
    this.kitchenId = null;
    this.state.go(menuRoutes.DASHBOARD, { tabId: 'kitchens' });
  }

  onKitchenEdit(event: any): void {
    this.editKitchen = true;
  }

  onKitchenArchive($event): void {
    this.kitchenId = null;
    this.state.go(menuRoutes.DASHBOARD, { tabId: 'kitchens' });
    this.toastService.autoToast($event);
    this.getKitchensForList();
  }

  onKitchenEditCancel(event: any) {
    if (this.navigationService.conditionalLambda === undefined || !this.navigationService.conditionalLambda()) {
      this.navigationService.disableConfirmNavigation();
      this.editKitchen = false;
    } else {
      this.navigationService.openConfirmModal().then(() => {
        this.navigationService.disableConfirmNavigation();
        this.editKitchen = false;
      }).catch(() => null);
    }
  }

  onKitchenEditSave($event: any) {
    this.editKitchen = false;
    this.getKitchensForList();
  }

  handleSearch(searchQuery: string): void {
    if (this.kitchens) {
      this.filteredKitchens = this.kitchens.filter((item) => item.name.toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase()) ||
                                                             item.poiName.toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase()) );
      this.handleSort(false);
    }
  }

  handleSort(flipDirection = true): void {
    if (flipDirection) {
      this.params.sort === 'asc' ? this.params.sort = 'desc' : this.params.sort = 'asc';
    }

    if (this.params.sort === 'asc') {
      this.filteredKitchens.sort((a, b) => a.name.localeCompare(b.name));
    } else {
      this.filteredKitchens.sort((a, b) => b.name.localeCompare(a.name));
    }
  }
}
