import text from './resources/locale/en.json';

class EntityCategoriesEditController {
    constructor(
        $state,
        crEntityCategoriesService,
        crErrorLoggingService,
        crImageService,
        crNavigationService,
        $uibModal,
        crAnalyticsService
    ) {
        this.$state = $state;
        this.crEntityCategoriesService = crEntityCategoriesService;
        this.crErrorLoggingService = crErrorLoggingService;
        this.crImageService = crImageService;
        this.crNavigationService = crNavigationService;
        this.$uibModal = $uibModal;
        this.crAnalyticsService = crAnalyticsService;
    }

    $onInit() {
        this.text = text;
        this.drag = false;
        this.loading = true;
        this.panes = [];
        this.parentRoute = this.$state.$current.parent.self.name;
        this.titleBarLabel = this.crEntityCategoriesService.getLocalizedName(this.entityType);

        const params = [this.$state.params.venueId, this.entityType, null];
        const { menuId } = this.$state.params;

        this.entityRoute = menuId ? `menus/${menuId}/${this.entityType}` : this.entityType;

        if (menuId) {
            params.push(this.entityRoute);
        }

        this.crEntityCategoriesService
            .getParentCategoriesHierarchy(...params)
            .then((hierarchy) => {
                this.panes = hierarchy;
                this.crImageService.setIconSrc(this.panes);
                this.categories = hierarchy.map((cat) => ({ iconSrc: cat.iconSrc, label: cat.label }));
            })
            .catch((err) => {
                this.crErrorLoggingService.logError(`Could not get ${this.entityType}`, err, {
                    venueId: this.$state.params.venueId,
                });

                this.dataError = true;
            })
            .finally(() => {
                this.loading = false;
            });

        this.crNavigationService.enableConfirmNavigation(() => this.dirty);
    }

    getToastParams() {
        const params = {
            toast: {
                msg: this.text.toast,
            },
            menuId: this.$state.params.menuId,
        };

        return params;
    }

    showErrorModal() {
        this.$uibModal
            .open({
                backdrop: 'static',
                component: 'crSimpleModal',
                windowClass: 'cr-modal-size-sm',
                resolve: {
                    message: () => this.text.updateError,
                },
            })
            .result.catch(() => null);
    }

    onPaneChange($event) {
        this.dirty = true;
        this.categories = $event.map((cat) => ({ iconSrc: cat.iconSrc, label: cat.label }));
    }

    save() {
        this.loading = true;
        this.crAnalyticsService.track('Categories Adjust Order - Save', {
            entityType: this.entityType,
            data: this.panes,
        });

        if (this.dirty) {
            this.crEntityCategoriesService
                .updateEntityCategoriesRank(this.$state.params.venueId, this.panes, this.entityRoute)
                .then(() => {
                    this.crNavigationService.disableConfirmNavigation();
                    this.crNavigationService.goBack(`${this.parentRoute}.categories`, this.getToastParams());
                })
                .catch((err) => {
                    this.showErrorModal();
                    this.crErrorLoggingService.logError(
                        `EntityCategoriesService::update${this.entityType}CategoriesRank`,
                        err
                    );
                })
                .finally(() => {
                    this.loading = false;
                });
        } else {
            this.crNavigationService.disableConfirmNavigation();
            this.crNavigationService.goBack(`${this.parentRoute}.categories`);
        }
    }

    goBack() {
        this.crAnalyticsService.track('Categories Adjust Order - Cancel', { entityType: this.entityType });
        this.crNavigationService.goBack(`${this.parentRoute}.categories`);
    }
}

export default EntityCategoriesEditController;
