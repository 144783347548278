import template from './image-modal.html';
import './image-modal.less';
import ImageModalController from './image-modal.controller';

const ImageModalComponent = {
    template,
    bindings: {
        close: '&',
        dismiss: '&',
        resolve: '<',
    },
    controller: ['$scope', '$state', '$timeout', 'crImageService', 'crAnalyticsService', ImageModalController],
};

export default ImageModalComponent;
