import controller from './duration-editor.controller';
import template from './duration-editor.html';
import './duration-editor.less';

const Component = {
    template,
    require: {
        form: '^form',
    },
    bindings: {
        name: '@',
        onUpdate: '&',
        duration: '<',

        customClass: '@?',
    },
    controller: ['$timeout', controller],
};

export default Component;
