const maxLength = 78;

class SearchController {
    constructor(text) {
        this.availableSelections = this.availableSelections || false;
        this.customClass = this.customClass || '';
        this.placeholder = this.placeholder || text.placeholder;
        this.onChange = this.onChange || (() => null);

        this.closeDropdown();
        this.filteredSelections = [];
    }

    $onInit() {
        this.model = this.defaultTerm || '';
        this.maxlength = this.maxlength || maxLength;
        this.clearVisibility = !!this.model;
    }

    $onChanges(changes) {
        if (changes.term) {
            this.model = this.term;
        }
    }

    onInputChange() {
        this.setWatched();

        this.onChange({ term: this.model });
    }

    onInputBlur() {
        setTimeout(this.closeDropdown, 0);
    }

    closeDropdown() {
        this.isOpen = false;
    }

    openDropdown() {
        this.isOpen = true;
    }

    onItemSelect(e, item) {
        e.stopPropagation();

        this.model = item;
        this.closeDropdown();

        this.onChange(item);
    }

    focusInput(e) {
        e.currentTarget.querySelector('input').focus();

        this.setWatched();
    }

    clear(e) {
        e.stopPropagation();
        e.currentTarget.parentElement.querySelector('input').focus();
        this.model = '';
        this.setWatched();
        this.onChange('');
    }

    setFilteredSelections() {
        this.filteredSelections = this.availableSelections.filter((item) =>
            new RegExp(`^${this.model}`, 'i').test(item)
        );

        this.isOpen = !!this.filteredSelections.length;
    }

    setWatched() {
        this.clearVisibility = this.model.length > 0;

        if (this.model.length && this.availableSelections) {
            this.setFilteredSelections();
        } else {
            this.closeDropdown();
        }
    }
}

export default SearchController;
