import _ from 'lodash';

import text from './resources/locale/en.json';

class PosEnableModalController {
    constructor($state, crPosEnableService, crErrorLoggingService) {
        this.$state = $state;
        this.crPosEnableService = crPosEnableService;
        this.crErrorLoggingService = crErrorLoggingService;
    }

    $onInit() {
        this.text = text;
        this.form = '';
        this.isLoading = true;
        this.systemOptions = [];
        this.currentSystem = {};
        this.isIntegrated = true;

        this.integratedOptions = [
            {
                label: this.text.integrated,
                name: 'integrated',
                checked: this.isIntegrated,
            },
        ];

        this.crPosEnableService
            .getPosAdaptersList(this.$state.params.customerId)
            .then((posConfig) => {
                _.forIn(posConfig, (value) => {
                    this.systemOptions.push(value);
                });

                let posInfo = '';

                if (this.resolve.modalData && this.resolve.modalData.posInfo) {
                    posInfo = this.resolve.modalData.posInfo;
                }

                if (posInfo && posInfo.adapterId) {
                    this.currentSystem = _.find(this.systemOptions, { id: posInfo.adapterId });
                    this.currentReference = posInfo.posLocationIdentifier;
                    this.currentMenu = posInfo.posMenuIdentifier;
                } else {
                    this.currentSystem = this.systemOptions[0];
                }

                this.requiresIsIntegratedFlag = this.currentSystem.requiresIsIntegratedFlag;
            })
            .catch((e) => {
                this.crErrorLoggingService.logError('could not retrieve pos config', e);
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    setSystem(system) {
        this.currentSystem = system;
        this.requiresIsIntegratedFlag = this.currentSystem.requiresIsIntegratedFlag;
    }

    onCancel() {
        this.dismiss();
    }

    toggleIntegrated(e) {
        this.isIntegrated = e.model.integrated;
    }

    onSubmit() {
        if (this.form.$valid) {
            this.resolve.modalData.posInfo = {
                system: this.currentSystem,
                reference: this.form.reference.$viewValue,
                menu: this.form.menu.$viewValue,
            };

            if (this.currentSystem.requiresIsIntegratedFlag) {
                this.resolve.modalData.posInfo.isIntegrated = this.isIntegrated;
            }

            this.close();
        }
    }
}

export default PosEnableModalController;
