import BeaconDetailsController from './beacon-details.controller';
import template from './beacon-details.html';
import './beacon-details.less';

const BeaconDetailsComponent = {
    template,
    controller: [
        '$state',
        '$uibModal',
        'crBeaconDetailsService',
        'crNavigationService',
        'crGraphicalMapService',
        'crConstants',
        'crToastService',
        'crErrorLoggingService',
        BeaconDetailsController,
    ],
};

export default BeaconDetailsComponent;
