import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { CrIconModule } from '../../icon/icon.module';

import { SearchComponent } from './search.component';

@NgModule({
  imports: [CrIconModule, BrowserModule, FormsModule, ReactiveFormsModule],
  exports: [SearchComponent],
  declarations: [SearchComponent],
  providers: [],
})
export class CrSearchModule { }
