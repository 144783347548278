import angular from 'angular';
import _ from 'lodash';

const JSONValidatorModule = angular
    .module('control-room.commons.form.validators.json', [])
    .directive('json', () => ({
        require: 'ngModel',
        link(scope, elm, attrs, ctrl) {
            ctrl.$validators.json = function (modelValue, viewValue) {
                if (ctrl.$isEmpty(modelValue)) {
                    return true;
                }

                try {
                    JSON.parse(viewValue);
                    return true;
                } catch (err) {
                    return false;
                }
            };
        },
    }))
    .directive('jsonObject', () => ({
        require: 'ngModel',
        link(scope, elm, attrs, ctrl) {
            ctrl.$validators['json-object'] = function (modelValue, viewValue) {
                if (ctrl.$isEmpty(modelValue)) {
                    return true;
                }

                try {
                    const parsed = JSON.parse(viewValue);
                    return _.isPlainObject(parsed);
                } catch (err) {
                    return false;
                }
            };
        },
    }));

export default JSONValidatorModule;
