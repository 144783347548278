import Controller from './tabs.controller';
import template from './tabs.html';
import './tabs.less';

const Component = {
    template,
    transclude: true,
    controller: ['$state', 'crAnalyticsService', 'crNavigationService', Controller],
    bindings: {
        overflowItems: '<?',
        stickyIndex: '@',
    },
};

export default Component;
