import { menuRoutes } from '../../menus.routes';
import text from './resources/locale/en.json';

class MenuDetails {
    constructor(
        $state,
        $sce,
        crConstants,
        crEntityService,
        crMenusService,
        crLocaleService,
        crErrorLoggingService,
        $uibModal,
        crPolicyService
    ) {
        this.$state = $state;
        this.$sce = $sce;
        this.crConstants = crConstants;
        this.crEntityService = crEntityService;
        this.crMenusService = crMenusService;
        this.crLocaleService = crLocaleService;
        this.crErrorLoggingService = crErrorLoggingService;
        this.$uibModal = $uibModal;
        this.crPolicyService = crPolicyService;

        this.text = text;
    }

    $onInit() {
        this.loading = true;
        this.menuId = this.$state.params.menuId;
        this.venueId = this.$state.params.venueId;
        this.placeId = this.$state.params.placeId || this.venueId;

        this.locale = this.crLocaleService.getLocale(this.venueId, this.$state.params.locale);
        const type = this.crConstants.entity.types.MENUS;

        this.crMenusService
            .getMenu(this.venueId, this.placeId, this.menuId, this.locale.current)
            .then((entity) => {
                this.menu = entity;
                if (this.menu.links) {
                    this.nutritionInfo = this.menu.links.find((link) => link.type === 'NUTRITION_INFO');
                    if (this.nutritionInfo) {
                        const filename = this.nutritionInfo.ref;
                        this.pdfLinkFileName = filename.substring(filename.lastIndexOf('/') + 1);
                    }
                }
            })
            .catch((err) => {
                this.crErrorLoggingService.logError(`Could not get ${type}`, err, this.menuId);

                if (err.status === 404) {
                    this.$state.go('client.404', {
                        customerId: this.$state.params.customerId,
                        venueId: this.venueId,
                    });
                } else {
                    this.error = true;
                }
            })
            .finally(() => {
                this.loading = false;
            });

        this.crMenusService.getMenuLayout(this.venueId, this.placeId, this.menuId).then((response) => {
            const menuLayout = { categories: response.content };
            this.hasLayout = menuLayout.categories.length > 0;
            this.menuCategories = this.mapMenuToPanes(menuLayout);
            this.menuLayoutLoaded = true;
        });

        this.hasFnbWriteAccess().then((result) => {
            this.readOnlyCategories = !result;
        });
    }

    hasFnbWriteAccess() {
        return this.crPolicyService.hasAccess('write:fnb');
    }

    trustUrl(url) {
        return this.$sce.trustAsResourceUrl(url);
    }

    expandPdf() {
        this.$uibModal
            .open({
                backdrop: 'static',
                component: 'crPdfPreviewModal',
                windowClass: 'cr-modal-size-full',
                resolve: {
                    pdf: () => this.nutritionInfo.ref,
                },
            })
            .result.catch(() => null);
    }

    mapMenuToPanes(menuLayout) {
        menuLayout.categories.forEach((category) => {
            category.expanded = true;
            category.label = category.displayName;
            category.products.forEach((product) => {
                product.isProduct = true;
                product.label = `${product.displayName} - ${product.price}`;
            });
            category.bundles.forEach((product) => {
                product.isBundle = true;
                product.label = `${product.displayName} - ${product.price}`;
            });
            if(category.bundles.length > 0 && category.products.length > 0) {
                category.hasChildHeader = true;
                category.children = [{isChildHeader: true, label: this.text.menuItems}, ...category.products,
                                    {isChildHeader: true, label: this.text.bundles}, ...category.bundles];
            } else {
                category.children = [...category.products, ...category.bundles];
            }
        });
        return menuLayout.categories;
    }
    

    gotoEditCategoryPage(selectedItem) {
        this.hasFnbWriteAccess().then((result) => {
            if (!result) {
                return;
            }

            if (selectedItem.isProduct) {
                this.$state.go(menuRoutes.EDIT_PRODUCT, {
                    id: selectedItem.id,
                    menuId: this.menuId,
                    customerId: this.$state.params.customerId,
                    placeId: this.$state.params.placeId,
                });
            }
            else if (selectedItem.isBundle){
                this.$state.go(menuRoutes.EDIT_BUNDLE, {
                    id: selectedItem.id,
                    menuId: this.menuId,
                    customerId: this.$state.params.customerId,
                    placeId: this.$state.params.placeId
                });
            } else {
                this.$state.go(menuRoutes.EDIT_CATEGORY, {
                    id: selectedItem.id,
                    menuId: this.menuId,
                    customerId: this.$state.params.customerId,
                    placeId: this.$state.params.placeId,
                });
            }
        });
    }

    createCategory() {
        this.$state.go(menuRoutes.CREATE_CATEGORY, {
            menuId: this.menuId,
            customerId: this.$state.params.customerId,
            placeId: this.$state.params.placeId,
        });
    }

    createModifierGroup() {
        this.$state.go(menuRoutes.CREATE_MODIFIER_GROUPS, {
            menuId: this.menuId,
            customerId: this.$state.params.customerId,
            placeId: this.$state.params.placeId,
        });
    }

    createProduct() {
        this.$state.go(menuRoutes.CREATE_PRODUCT, {
            menuId: this.menuId,
            customerId: this.$state.params.customerId,
            placeId: this.$state.params.placeId,
        });
    }

    goToEdit() {
        this.$state.go(menuRoutes.EDIT_MENU, {
            menuId: this.menu.id,
            customerId: this.$state.params.customerId,
            placeId: this.$state.params.placeId,
            fromDetails: true
        });
    }
}

export default MenuDetails;
