/* eslint no-bitwise: 0 */
import _ from 'lodash';
import { catchError, take } from 'rxjs/operators';
import ReorderList from '../../../../commons/controller/reorder-list';
import { menuRoutes } from '../../menus.routes';
import text from './resources/locale/en.json';

export default class KitchenManage extends ReorderList {
    constructor(
        $state,
        $uibModal,
        $q,
        crKitchenManageService,
        crKitchenService,
        crLocaleService,
        crLocalizationService,
        crConfig,
        crAnalyticsService,
        crErrorLoggingService,
        crNavigationService,
        crPlacesService,
        crLockerService,
        crConstants,
        crPrintManagerService,
        crMenuCategoriesService,
        crMenusService,
        crFeesService,
        crTimeService
    ) {
        super();
        this.$state = $state;
        this.$uibModal = $uibModal;
        this.$q = $q;
        this.crKitchenManageService = crKitchenManageService;
        this.crLockerService = crLockerService;
        this.crKitchenService = crKitchenService;
        this.crLocaleService = crLocaleService;
        this.crLocalizationService = crLocalizationService;
        this.crConfig = crConfig;
        this.crAnalyticsService = crAnalyticsService;
        this.crErrorLoggingService = crErrorLoggingService;
        this.crNavigationService = crNavigationService;
        this.crPlacesService = crPlacesService;
        this.crConstants = crConstants;
        this.crPrintManagerService = crPrintManagerService;
        this.crMenuCategoriesService = crMenuCategoriesService;
        this.crMenusService = crMenusService;
        this.crFeesService = crFeesService;
        this.crTimeService = crTimeService;
        this.printers = [];
        this.pickupZoneRadioOptions = [];
        this.pickupZones = [];
        this.lockers = [];
        this.selectedLocker = null;
        this.lockerHoldTimes = [];
        this.selectedLockerHoldTime = null;
        this.lockerLocationDescription = '';
        this.categories = [];
        this.fees = [];
        this.feesOptions = [];
        this.selectedDays = [];
    }

    $onInit() {
        this.crNavigationService.enableConfirmNavigation(() => this.form.$dirty);
        this.text = text;
        this.loading = true;
        this.isDistributedChitPrintingSupported = false;
        this.phoneNumberCollectionEnabled = true;

        this.locale = this.crLocaleService.getLocale(this.$state.params.venueId, this.$state.params.locale, !this.edit);
        this.initLocaleDropdown();
        this.checkDistributedChitPrintingSupported();

        if (!this.kitchenid && this.edit) {
            this.kitchenid = this.$state.params.kitchenId;
        } else if (!this.kitchenid) {
            this.kitchenid = `poi:${this.$state.params.venueId}.${this.$state.params.poiId}`;
        }

        this.printerOptions = [
            {
                label: this.text.printerAutomaticPrint,
                name: 'printOnCheckin',
                checked: true,
            },
        ];
        this.updatePrinterUrl();

        this.loadConfigOptions();

        this.getCurrencyCode();

        this.requireOnSiteOptions = [
            {
                label: this.text.requireOnSiteToOrder,
                name: 'requireOnSite',
                checked: true,
            },
        ];
        this.specialRequestsEnabledOptions = [
            {
                label: this.text.specialRequestsEnabled,
                name: 'specialRequestsEnabled',
                checked: true,
            },
        ];
        this.blockOrderNotificationsOptions = [
            {
                label: this.text.orderReadyNotification,
                name: 'blockOrderNotifications',
                checked: false,
            },
        ];
        this.phoneNumberRequiredOptions = [
            {
                label: this.text.phoneNumberRequired,
                name: "phoneNumberRequired",
                checked: false,
            }
        ]
        let loadContent;
        if (this.edit) {
            loadContent = this.loadKitchen().then(() => {
                this.updateConfigDropdowns();
                this.checkAndEnablePtwOrCtw();
                this.updateRequireOnSite();
                this.updateSpecialRequestsEnabled();
                this.updateBlockOrderNotifications();
                this.updatePhoneNumberRequired();
                this.loadPrinters();
                this.loadMenus();
                this.loadFees();
            });
        } else {
            this.poiId = this.$state.params.poiId;
            loadContent = new Promise((resolve) => {
                this.initKitchen();
                this.loadCategoriesForPrinter();
                this.loadFees();
                resolve();
            });
        }

        this.lockerHoldTimes = this.crLockerService.getLockerHoldTimes(this.text.pickupZoneLockerHoldTimeUnits);
        this.crLockerService.getLockers().then((result) => {
            this.lockers = result;
        }).finally(() => {
            loadContent
            .then(() => {
                this.initPickupZones();
                this.setSelectedLocker();
                this.setPhoneNumberCollection();
                if(this.kitchen && this.kitchen.features && this.kitchen.features.pickupTimeWindowsEnabled) {
                    this.updatePtwWebContent(true);
                } else {
                    this.updatePtwWebContent(false);
                }
            })
            .finally(() => {
                this.loading = false;
            });
        });

        this.blockOrderReadyNotificationTooltip = `
                                 <div>${this.text.orderReadyNotificationTT}
                                `;
        this.phoneTooltipInfo = `<h2>${this.text.tooltip.countryCode}</h2>
                                 <div>${this.text.tooltip.countryCodeDesc1}
                                 <br />${this.text.tooltip.countryCodeDesc2}
                                 <br />${this.text.tooltip.countryCodeDesc3}
                                 <br />${this.text.tooltip.countryCodeDesc4}</div>
                                 <h2>${this.text.tooltip.extension}</h2>
                                 <div>${this.text.tooltip.extensionDesc1}
                                 <br />${this.text.tooltip.extensionDesc2}</div>
                                `;
        this.categoryTooltipInfo = `<div>${this.text.tooltip.categoryDesc}</div>`;
        this.mobileWebContent = [
            'preorderConfirmationMessage',
            'preorderConfirmationPickupLocation',
            'preorderConfirmationPickupInstructions',
        ];

        this.webContentWithoutPtw = [
            'preorderBillingMessage',
            'preorderTaxAndDiscount',
            'preorderPickupMessage',
        ];
        this.webContentWithPtw = [
            'checkinMessage',
            'preCheckinMessage',
            'checkInButton',
            'checkedInMessage',
            'postCheckinMessage',
            'postCheckinMessageButton',
            'checkinErrorTitle',
            'checkinErrorBody',
        ];
        this.webContentWithLockers = [
            'lockerPickupDetailsHeader',
            'lockerPickupDetailsBody',
            'lockerFulfillmentNotificationInfo'
        ];
        this.webContentWithphoneNumberCollection = [
            'phoneNumberCollectionTitle',
            'phoneNumberCollectionContent',
            'phoneNumberCollectionTooltip',
            'phoneNumberCollectionOptInTitleOne',
        ];
    }


    loadConfigOptions() {
        // Load the commerce configuration options to set up the F&B location
        this.crKitchenManageService.getConfigOptions(this.$state.params.customerId).then((result) => {
            this.configOptions = result.configOptions; // This is an array
            this.updateConfigDropdowns();
            this.checkAndEnablePtwOrCtw();
        });

        this.crKitchenManageService.getConfigOptionsForZones(this.$state.params.customerId).then((result) => {
            this.configOptionsForZones = result;
            this.initPickupZoneCustomOptions();
        });
    }



    updateConfigDropdowns() {
        if (this.kitchen && this.configOptions) {
            const selectedConfigs = this.kitchen.features.configurationIdMap;
            this.configOptions.forEach((config) => {
                config.options.forEach((option) => {
                    if (selectedConfigs[option.optionId]) {
                        option.isSelected = true;
                    }
                });
            });
        }
    }

    checkAndEnablePtwOrCtw() {
        if(this.kitchen && this.configOptions) {
            const selectedConfigs = this.kitchen.features.configurationIdMap;
            let pickupTimeWindowEnabled = false;
            let checkInTimeWindowEnabled = false;
            this.configOptions.forEach((config) => {
                if(config.categoryId === 'BASE' || config.categoryId === 'PREP_TIME') {
                    // if combineSubmitAndCheckIn is enabled then it is truw ptw and check-in properties needs to be disabled
                    config.options.forEach((option) => {
                        if (selectedConfigs[option.optionId] && option.pickupTimeWindowsEnabled && !option.combineSubmitAndCheckin) {
                            pickupTimeWindowEnabled = true;
                            checkInTimeWindowEnabled = true;
                            this.tooltipEnabledCategory = config.categoryId;
                        }
                        if (selectedConfigs[option.optionId] && option.pickupTimeWindowsEnabled  && option.combineSubmitAndCheckin) {
                            pickupTimeWindowEnabled = true;
                            checkInTimeWindowEnabled = false;
                            this.tooltipEnabledCategory = config.categoryId;
                        }
                    });
                }
            });
            this.pickupTimeWindowEnabled = pickupTimeWindowEnabled;
            this.checkInTimeWindowEnabled = checkInTimeWindowEnabled;
        }
    }

    updatePtwWebContent(isPickupTimeWindowEnabled) {
        if(isPickupTimeWindowEnabled) {
            this.webContent = [...this.mobileWebContent, ...this.webContentWithPtw];
        } else {
            this.webContent = [...this.mobileWebContent, ...this.webContentWithoutPtw];
        }
    }

    get titleBarLabel() {
        return this.edit ? this.text.editTitle : this.text.createTitle;
    }

    addHours() {
        if (_.isNil(this.kitchen.hours)) {
            this.kitchen.hours = [];
        }
        this.kitchen.hours.push({
            days: [],
            openingTime: '',
            closingTime: '',
        });
        this.form.$setDirty();
    }

    goBack() {
        this.crAnalyticsService.track('Edit Menu Cancelled');
        this.crNavigationService.goBack(menuRoutes.DASHBOARD, { tabId: 'kitchens' });
    }

    cancelBackToParent() {
        this.cancel();
    }

    saveBackToParent() {
        this.callbackToParent = true;
        this.save();
    }

    initKitchen() {
        this.kitchen = {
            id: this.kitchenid,
            hours: [
                {
                    days: [],
                    duration: [{
                        openingTime: '',
                        closingTime: '',
                        earlyCheckin: '',
                        lateCheckin: '',
                        earlyOrdering: '',
                        pickupWindowProperties: [{
                            startTime: '',
                            endTime: '',
                            capacity: 1,
                            length: 0
                        }]
                    }]
                },
            ],
            content: {},
            localization: {},
            features: {
                commerceEnabled: true,
                overrideInVenue: false,
                specialRequestsEnabled: false,
                configurationIdMap: {},
                pickupZones: [],

            },
            controls: {
                blockOrderNotifications: true
            },
            applicableFees: []
        };
        this.printers = [];
    }

    loadKitchen() {
        return this.crPlacesService
            .getPlaceWithICalDays(this.kitchenid)
            .then((kitchen) => {
                this.kitchen = kitchen;
                this.mapSelectedDays(kitchen);
                this.mapKitchenHours(kitchen);
                return this.getLocalization();
            })
            .catch((err) => {
                this.crErrorLoggingService.logError('Could not get kitchen', err, this.kitchenid);
                this.error = true;
            });
    }

    mapKitchenHours(kitchen) {
        if(kitchen.hours && kitchen.hours && kitchen.hours.length > 0) {

            let hours = kitchen.hours;

            hours = hours.reduce((acc, obj) => {
                let key = obj['days'].sort((a,b)=> a.localeCompare(b));
                if (!acc[key]) {
                    acc[key] = []
                  }

                  const ptwObj = { startTime: this.roundTimeToNearQuarterHour(obj.openingTime),
                                endTime: this.roundTimeToNearQuarterHour(obj.closingTime),
                                capacity: 1,
                                length: 0
                                };
                  let hoursObj = {
                      openingTime: this.roundTimeToNearQuarterHour(obj.openingTime),
                      closingTime: this.roundTimeToNearQuarterHour(obj.closingTime),
                      earlyOrdering: obj.earlyOrdering,
                      earlyCheckin: obj.earlyCheckin,
                      lateCheckin: obj.lateCheckin,
                      pickupWindowProperties: obj.pickupWindowProperties || [ptwObj],
                      days: obj.days || []
                  }

                  acc[key].push(hoursObj)
                  return acc
            }, {});

            let mappedHours = [];

            for(const h in hours) {
                mappedHours.push({
                    days: hours[h][0].days,
                    duration : [...hours[h]]
                });
            };

            this.kitchen.hours = mappedHours;

        }

    }

    roundTimeToNearQuarterHour(time) {
        time = time.split(":");
        let hours = parseInt(time[0]);
        let minutes = parseInt(time[1]);
        if(hours === 23 && minutes > 52) {
            return '23:45:00';
        } else {
            return this.crTimeService.roundTimeToNearQuarterHour(hours, minutes)
        }
    }

    mapSelectedDays(kitchen) {
        if(kitchen.hours && kitchen.hours && kitchen.hours.length > 0) {
            this.selectedDays = this.kitchen.hours.map((h, index) => {
                return { days: h.days,index: index };
            });
        }
    }

    loadPrinters() {
        this.crPrintManagerService.getPrinters(this.kitchenid).then((response) => {
            this.printers = response.printers;
            this.printers.forEach(p => {
                p.selectedCategories = [];
                if (!p.categoryIds) {
                    p.categoryIds = [];
                }
            });
            this.printerOptions[0].checked = !!_.find(this.printers, (printer) => printer.printOnCheckin);
            if(this.isDistributedChitPrintingSupported) {
                this.loadCategoriesForPrinter();
            }
        });
    }

    loadMenus() {
        this.crMenusService.getMenusForPlace(this.kitchen.id, this.locale.current).then((menus) => {
            this.relatedMenus = menus.content;
        }).catch((err) => {
            this.crErrorLoggingService.logError('Could not get menu by place data', err);
          this.relatedMenus = [];
        });
    }

    mapSelectedCategoryToPrinter() {
        this.printers.forEach((printer) => {
            printer.selectedCategories = this.categories
                                         .filter(cat => printer.categoryIds.includes(cat.id));
        });
    }

    loadFees() {
        this.crFeesService.getFeesByVenue(this.$state.params.venueId).pipe(
            take(1),
            catchError((err) => {
                this.error = true;
                this.crErrorLoggingService.logError('Could not get kitchen', err, this.kitchenid)}
            ),
          ).subscribe((fees) => {
            if(fees.length) {
                this.originalFeeList = [...fees];
                this.feesOptions = fees.filter(f => f.displayName && f.publishingStatus === "ACTIVE");
                this.feesOptions.forEach((f) => {
                    f.label = f.displayName;
                });
                this.feesOptions.sort((a,b) => a.displayName.localeCompare(b.displayName));
                this.mapSelectedFees();
            }
        });
    }

    mapSelectedFees() {
        if(!this.kitchen.features.applicableFees) {
            this.kitchen.features.applicableFees = [];
        }

        const feeIdList = this.originalFeeList.map((fee) => fee.id)
        const allFeeExist = this.kitchen.features.applicableFees.every((feeId) => {
                return feeIdList.includes(feeId);
        });

        if(allFeeExist) {
            const selectedFees = this.kitchen.features.applicableFees.map((feeId) => {
            return this.originalFeeList.find(fee => fee.id === feeId);
            });
            this.fees = selectedFees.map((fee) => ({
                ...fee,
                type: this.determineFeeCalcDisplay(fee),
            }));
        } else {
            const promises = [];

            this.kitchen.features.applicableFees.forEach((feeId) => {
                promises.push(
                    this.crFeesService.getFeeById(feeId).toPromise().
                    catch((err) => {
                        if(err.status === 404) return null;
                    }
                ));
            });

            this.$q.all(promises).then((selectedFees) => {
                selectedFees = selectedFees.filter((fee) => fee!== null);
                this.fees = selectedFees.map((fee) => ({
                    ...fee,
                    type: this.determineFeeCalcDisplay(fee),
                }));
            });
        }
    }

    getCurrencyCode() {
        this.currencyCode = _.find(this.crConfig.customer.venues, {
          id: this.$state.params.venueId,
        }).currencyCode;
    }

    onFeeSelected(event, index) {
        if(event && event.model && event.model.length) {
            const selectedFee = event.model[0];
            if(selectedFee && !this.fees.find(f => f.id === selectedFee.id)) {
                selectedFee.type = this.determineFeeCalcDisplay(selectedFee);
                this.fees.push(selectedFee);
                this.updateKitchenWithFees(selectedFee.id);
            }
        }
    }

    determineFeeCalcDisplay(fee) {
        let displayValue = 'unknown';

        if (fee.type === 'FIXED') {
        displayValue = `Flat Rate (${fee.amountOrPercent} ${this.currencyCode})`;
        } else if (fee.type === 'PERCENT') {
        displayValue = `Percentage of total (${fee.amountOrPercent}%)`;
        }

        return displayValue;
    }

    onRemoveFee(index) {
        if (index < this.fees.length) {
            this.fees.splice(index, 1);
            this.kitchen.features.applicableFees.splice(index, 1)
        }
    }

    updateKitchenWithFees(feeId) {
        if(!this.kitchen.features.applicableFees) {
            this.kitchen.features.applicableFees = [];
        }
        this.kitchen.features.applicableFees.push(feeId);
    }

    loadCategories() {
        this.params = {
            page: 1,
            perPage: 40,
            sort: '',
            sortby: '',
            search: '',
            state: 'ACTIVE',
            venueId: this.$state.params.venueId,
            placeId: this.kitchenid,
            printerCategories: 'ONLY'

        };
        return this.crMenuCategoriesService
            .getData(this.menuId, this.params)
            .then((data) => {
                this.categories = data.content;
                this.categories.forEach((cat) => {
                    cat.label = cat.displayName;
                    cat.appValueKey = cat.id;
                });
                if(this.edit) {
                    this.mapSelectedCategoryToPrinter();
                }
            })
            .catch((err) => {
                this.crErrorLoggingService.logError('Could not get menu categories data', err);
            });

    }

    loadCategoriesForPrinter() {
        this.crMenusService.getMenusForPlace(this.kitchen.id, this.locale.current).then((menus) => {
            this.menus= menus.content.filter(menu => {
                return menu.state === 'ACTIVE' && menu.menuClass === 'DINING'
            });
            if(this.menus.length > 0) {
                this.menuId = this.menus[0].id;
                this.loadCategories();
            }
        })
        .catch((err) => {
            this.crErrorLoggingService.logError('Could not get menu by place data', err);
        });
    }

    onCategorySelect(event, index) {
        if(event && event.length > 0) {
            this.printers[index].categoryIds = event.map(cat => cat.id);
            console.log(this.printers);
        }
        else {
            this.printers[index].categoryIds = [];
        }
    }

    updatePrinterUrl() {
        const apiGateway = 'https://api.us.te2.io';
        this.printerUrl = `${apiGateway}/customer/${this.$state.params.customerId}/venue/${this.$state.params.venueId}/print-jobs`;
    }

    onPrinterUpdate(field, event, index) {
        if (field === 'printOnCheckin') {
            this.printerOptions[0].checked = event.model.printOnCheckin;
        } else if (index < this.printers.length) {
            this.printers[index][field] = event.model;
        }
        this.form.$setDirty();
    }

    onRequireOnSiteUpdate(event) {
        this.kitchen.features.overrideInVenue = !event.model.requireOnSite;
        this.form.$setDirty();
    }

    updateRequireOnSite() {
        this.requireOnSiteOptions[0].checked = !this.kitchen.features.overrideInVenue;
    }

    onSpecialRequestsEnabledUpdate(event) {
        this.kitchen.features.specialRequestsEnabled = event.model.specialRequestsEnabled;
        this.form.$setDirty();
    }

    updateSpecialRequestsEnabled() {
        this.specialRequestsEnabledOptions[0].checked = this.kitchen.features.specialRequestsEnabled;
    }

    onBlockOrderNotificationUpdate(event) {
        this.kitchen.controls.blockOrderNotifications = !event.model.blockOrderNotifications;
        this.form.$setDirty();
    }

    onPhoneNumberRequiredUpdate(event) {
        this.kitchen.controls.phoneNumberRequired = event.model.phoneNumberRequired;
        this.form.$setDirty();
    }

    updateBlockOrderNotifications() {
        // false and null will send a notification
        if(this.kitchen.controls.blockOrderNotifications === null){
            this.kitchen.controls.blockOrderNotifications = false;
        }
        // but ui text is opposite, so swap the checkbox
        this.blockOrderNotificationsOptions[0].checked = !this.kitchen.controls.blockOrderNotifications;
    }

    updatePhoneNumberRequired() {
        // false and null will send a notification
        if(this.kitchen.controls.phoneNumberRequired === null){
            this.kitchen.controls.phoneNumberRequired = false;
        }
        // but ui text is opposite, so swap the checkbox
        this.phoneNumberRequiredOptions[0].checked = this.kitchen.controls.phoneNumberRequired;
    }


    onPrinterAdd() {
        if (_.isEmpty(this.printers)) {
            this.printerOptions[0].checked = true;
        }
        this.printers.push({
            name: 'local_printer',
            username: '',
            password: '',
            selectedCategories: [],
            categoryIds: []
        });
        this.form.$setDirty();
    }

    onPrinterRemove(index) {
        if (index < this.printers.length) {
            this.printers.splice(index, 1);
        }
        this.form.$setDirty();
    }

    onPriceDisclaimerUpdate(event) {
        if (this.isDefaultLocale()) {
            _.set(this.kitchen, ['content', 'priceDisclaimer'], event.model);
        }
        _.set(this.kitchen, ['localization', this.locale.current, 'content', 'priceDisclaimer'], event.model);
        this.updateLocaleStatus();
    }

    onPhoneNumberUpdate(event) {
        this.kitchen.content.phoneNumber = event.model;
    }

    onWebContentUpdate(event, id) {
        if (this.isDefaultLocale()) {
            _.set(this.kitchen, ['webContent', id], event.model);
        }
        _.set(this.kitchen, ['localization', this.locale.current, 'webContent', id], event.model);
        this.updateLocaleStatus();
    }

    onAddressUpdate(event, id) {
        _.set(this.kitchen, ['content', 'addressComponents', id], event.model);
    }

    onCloseTimeUpdate(event, index) {
        this.kitchen.hours[index].closingTime = event.model;
        this.form.$setDirty();
    }

    onOpenTimeUpdate(event, index) {
        this.kitchen.hours[index].openingTime = event.model;
        this.form.$setDirty();
    }

    onNameUpdate(event) {
        if (event) {
            this.kitchen.name = event.model;
        }
    }

    onCommerceConfigSelect(categoryId, event) {
        // Deselect the category from the kitchen features
        if (this.kitchen && this.configOptions && this.kitchen.features && this.kitchen.features.configurationIdMap) {
            this.configOptions
                .filter((config) => config.categoryId === categoryId)
                .forEach((config) => {
                    config.options.forEach((option) => {
                        delete this.kitchen.features.configurationIdMap[option.optionId];
                    });
                });
        }

        // Select the new option if there is one
        if (event && event.model && event.model[0]) {
            const selection = event.model[0];
            _.set(this.kitchen, ['features', 'configurationIdMap', selection.optionId], selection.optionName);
            if(categoryId === 'BASE' || categoryId === 'PREP_TIME') {
                this.checkAndEnablePtwOrCtw();
            }
        }
    }


    onPickupZoneOptionChange(value) {
        // Need to reset the pickup zones list when coming from locker pickup type since the required fields are different
        if (this.pickupZoneOption === 'LOCKER_PICKUP') {
            this.pickupZones = [{
                pickupZoneId: `zone_${this.uuidv4()}`,
                label: '',
                localization: {},
                requiresEntry: false,
            },{
                pickupZoneId: `zone_${this.uuidv4()}`,
                label: '',
                localization: {},
                requiresEntry: false,
            }];
            this.kitchen.features.pickupZones = undefined;
        }
        if(value === 'LOCKER_PICKUP') {
            this.removePhoneNumberCollectionFields();
        } else {
            this.phoneNumberCollectionEnabled = true;
        }
        this.pickupZoneOption = value;
    }

    onPickupzoneUpdateLabel(event, index) {
        if (this.isDefaultLocale()) {
            _.set(this.pickupZones, [index, 'label'], event.model);
        }
        _.set(this.pickupZones, [index, 'localization', this.locale.current, 'label'], event.model);
        this.updateLocaleStatus();
    }

    pickupzoneOrderChangeTop(data, index) {
        this.moveRowToTop(data, index, this.pickupZones);
        this.moveRowToTop(data, index, this.pickupZoneRegexOptions);
        this.form.$setDirty();
    }

    pickupzoneOrderChange(data, index) {
        this.moveRow(data, index, this.pickupZones);
        this.moveRow(data, index, this.pickupZoneRegexOptions);
        this.form.$setDirty();
    }

    onPickupZoneRemove(index) {
        this.pickupZones.splice(index, 1);
        this.pickupZoneRegexOptions.splice(index, 1);
        this.form.$setDirty();
    }

    uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
            const r = (Math.random() * 16) | 0;
            const v = c === 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    }

    isAddZoneDisabled() {
        return !this.form.$valid;
    }

    onPickupZoneAdd() {
        const index = this.pickupZones.length;
        const zone = {
            pickupZoneId: `zone_${this.uuidv4()}`,
            label: '',
            localization: {},
            requiresEntry: false,
        };
        this.initPickupZoneCustomOptionForZone(zone, index);
        this.pickupZones.push(zone);
    }

    onCustomZoneToggle(index) {
        const zone = this.pickupZones[index];
        zone.requiresEntry = !zone.requiresEntry;
        if (zone.requiresEntry && !zone.rule) {
            zone.rule = { sampleInput: '', regex: '' };
        }
    }

    onZoneCustomLabelChange($event, $index) {
        if (this.isDefaultLocale()) {
            _.set(this.pickupZones, [$index, 'entryPrompt'], $event.model);
        }
        _.set(this.pickupZones, [$index, 'localization', this.locale.current, 'entryPrompt'], $event.model);
        // Copy the value over to the top level label
        if (this.pickupZoneOption === 'SINGLE') {
            this.onPickupzoneUpdateLabel($event, $index);
        }
        this.updateLocaleStatus();
    }

    onZoneCustomRegexChange($event, $index) {
        const zone = this.pickupZones[$index];
        zone.rule.regex = $event.model[0].id;
    }

    onSaveError(errMsg, err) {
        const message = this.edit ? this.text.updateError : this.text.createError;

        this.crErrorLoggingService.logError(errMsg, err);

        this.$uibModal
            .open({
                backdrop: 'static',
                component: 'crSimpleModal',
                windowClass: 'cr-modal-size-sm',
                resolve: {
                    closeText: () => this.text.closeError,
                    message: () => message,
                },
            })
            .result.catch(() => null);
    }

    onSaveSuccess(kitchenId) {
        this.crNavigationService.disableConfirmNavigation();

        if (this.edit) {
            if(this.isSameDayScheduleChanged && this.kitchen.features.pickupTimeWindowsEnabled) {
                this.openSameDayEditModal(kitchenId);
            }
            else {
                this.crAnalyticsService.track('Edit Kitchen Success', this.kitchen);
                this.crNavigationService.goBack(menuRoutes.KITCHEN, { kitchenId });
            }
        } else {
            this.crAnalyticsService.track('Add New Kitchen Success', this.kitchen);
            this.crNavigationService.goToFromCreate(menuRoutes.KITCHEN, { kitchenId, created: true });
        }

        if (this.callbackToParent) {
            this.saveEvent();
        }
    }

    openSameDayEditModal(kitchenId) {

        const resolve = () => {
            this.$state.go('client.fnb-order-queue.order-list', {
                venueId: this.$state.params.venueId,
                customerId: this.$state.params.customerId,
                kitchenId: kitchenId
            });
        };

        const cancel = () => {
            this.crAnalyticsService.track('Edit Kitchen Success', this.kitchen);
            this.crNavigationService.goBack(menuRoutes.KITCHEN, { kitchenId });
        }

        const modalMessage = `${this.text.sameDayPopup1}
                                <br />
                                <br />
                                ${this.text.sameDayPopup2}`;
        this.$uibModal
        .open({
            backdrop: 'static',
            component: 'crSimpleModal',
            windowClass: 'cr-modal-size-touch-sm',
            resolve: {
                message: () => modalMessage,
                type: () => this.crConstants.modalTypes.SUBMIT,
                submitText: () => this.text.goToOrderQueue,
                cancelText: () => this.text.modalDone
            },
        })
        .result.then(resolve, cancel)
        .catch(() => null);

    }

    onWeekdaySelect(event, index) {
        if(!this.isDuplicateDaySelected(event.model,index)) {
            this.kitchen.hours[index].days = event.model;
            this.updateSelectedDays(event, index);
            this.form.$setDirty();

        }
    }

    updateSelectedDays(event, index) {
        if(this.selectedDays.find(f => f.index === index)) {
            this.selectedDays[index] = { days: event.model, index: index };
        } else {
            this.selectedDays.push(
                { days: event.model,
                  index: index });
        }
    }

    isDuplicateDaySelected(userSelected, index) {
        let isDuplicateDay = false;
        let originalSelection =  this.selectedDays.filter(f => f.index !== index);
        let originalSelectedDays = []
        if(originalSelection) {
            originalSelection.forEach(s => {
                originalSelectedDays = originalSelectedDays.concat(s.days);
            });
            originalSelectedDays = _.flattenDeep(originalSelectedDays);
            const dayExist = _.intersection(_.uniq(originalSelectedDays), userSelected);

            isDuplicateDay = dayExist.length > 0 ? true : false;
        }

        return isDuplicateDay;
    }

    removeHours(index) {
        this.kitchen.hours.splice(index, 1);
        this.form.$setDirty();
    }

    save() {
        this.form.$setSubmitted();
        if (this.form.$valid) {
            this.saving = true;
            this.updateKitchenWithHours();
            this.updateKitchenWithPickupZones();
            this.updateWebContent();

            if (this.edit) {
                this.updateKitchen();
            } else {
                this.createKitchen();
            }
        }
    }

    updateWebContent() {
        const removeWebContent = (webContentArray) => {
            webContentArray.forEach((webContent) => {
                this.onWebContentUpdate({ model: undefined }, webContent);
                });
            };

        if (!this.kitchen.controls.promptForPhoneNumber) {
            removeWebContent(this.webContentWithphoneNumberCollection);
        }

        if (this.pickupTimeWindowEnabled) {
            removeWebContent(this.webContentWithoutPtw);
        } else {
            removeWebContent(this.webContentWithPtw);
        }

        if (this.pickupZoneOption !== 'LOCKER_PICKUP') {
            removeWebContent(this.webContentWithLockers);
        }
    }

    updateKitchenWithHours() {
        let hours = [];
        this.isSameDayScheduleChanged = this.kitchen.hours.some(s=> s.isSameDayScheduleChanged);
        this.kitchen.hours.forEach(h => {
            let ptwCommonProperties = {};
            h.duration.forEach((d,index) => {

                if(index === 0 ) {
                    ptwCommonProperties = {
                        earlyOrdering: d.earlyOrdering,
                        earlyCheckin: d.earlyCheckin,
                        lateCheckin: d.lateCheckin,
                    }
                }
                let hoursDTO = {
                    days: h.days,
                    closingTime: d.closingTime,
                    openingTime: d.openingTime,
                    earlyOrdering: parseInt(d.earlyOrdering),
                    earlyCheckin: parseInt(d.earlyCheckin),
                    lateCheckin: parseInt(d.lateCheckin),
                    pickupWindowProperties: d.pickupWindowProperties.map(ptw => {
                        return {
                            startTime: ptw.startTime,
                            endTime: ptw.endTime,
                            length: parseInt(ptw.length),
                            capacity: parseInt(ptw.capacity),
                        }
                    })
                }
                if(!this.kitchen.features.pickupTimeWindowsEnabled) {
                    delete hoursDTO.pickupWindowProperties;
                    delete hoursDTO.earlyOrdering;
                    delete hoursDTO.earlyCheckin;
                    delete hoursDTO.lateCheckin;
                }
                hours.push(hoursDTO);
            });
        });
        this.kitchen.hours = hours;
    }

    savePrinters() {
        const printOnCheckin = this.printerOptions[0].checked;
        const printerRequests = this.printers.map((p) => ({
            id: p.id,
            name: p.name,
            username: p.username,
            password: p.password,
            categoryIds: p.categoryIds,
            printOnCheckin,
        }));
        const { kitchenId, venueId } = this.$state.params;
        return this.crPrintManagerService.updatePrinters(kitchenId || this.kitchen.id, venueId, printerRequests);
    }

    updateKitchen() {
        const entityType = this.crConstants.entity.types.PLACES;
        return this.$q
            .all([
                this.crPlacesService.updatePlaceWithICalDays(this.kitchen.id, this.kitchen),
                this.crLocalizationService.updateEntityLocalization(entityType, this.kitchen.localization, {
                    id: this.getSafeKitchenId(),
                    venueId: this.$state.params.venueId,
                }),
                this.savePrinters(),
            ])
            .then(() => this.onSaveSuccess(this.kitchen.id))
            .catch((err) => this.onSaveError('Could not update kitchen', err))
            .finally(() => {
                this.saving = false;
            });
    }

    createKitchen() {
        const entityType = this.crConstants.entity.types.PLACES;
        return this.$q
            .all([
                this.crKitchenService.createKitchen(this.$state.params.customerId, this.kitchen.id, this.kitchen, {
                    venueId: this.$state.params.venueId,
                }),
                this.crLocalizationService.updateEntityLocalization(entityType, this.kitchen.localization, {
                    id: this.getSafeKitchenId(),
                    venueId: this.$state.params.venueId,
                }),
                this.savePrinters(),
            ])
            .then(() => this.onSaveSuccess(this.kitchen.id))
            .catch((err) => this.onSaveError('Could not create kitchen', err))
            .finally(() => {
                this.saving = false;
            });
    }

    // Localization support

    isDefaultLocale() {
        return this.locale.current === this.locale.default;
    }

    getSafeKitchenId() {
        // The GSR treats dots in a special way. The mnemonic also does this replacement in content service
        return this.kitchen.id.replace(/\./g, '_');
    }

    checkDistributedChitPrintingSupported() {
        this.isDistributedChitPrintingSupported = (this.crConfig.customer.configFlags.enable_distributed_chit_printing &&
                this.crConfig.customer.configFlags.enable_distributed_chit_printing.includes(this.$state.params.venueId)) ? true: false;
    }

    updateLocaleStatus() {
        this.locale.list = this.crLocaleService.updateLocaleStatus(this.kitchen.localization, this.locale);
        if (!this.kitchen.features.pickupZones) {
            this.kitchen.features.pickupZones = [];
        }
        const keys = [
            'content.priceDisclaimer',
            ...this.webContent.map((key) => `webContent.${key}`),
            ...this.kitchen.features.pickupZones.map((v, idx) => `features.pickupZones.${idx}.label`),
            ...this.kitchen.features.pickupZones.map((v, idx) => `features.pickupZones.${idx}.entryPrompt`),
        ];
        this.locale.list.forEach((loc) => {
            const hasLocalizedContent = keys.map((key) =>
                _.get(this.kitchen.localization, [loc.id, ...key.split('.')])
            );
            const isFullyLocalized = hasLocalizedContent.reduce((a, b) => a && b, true);
            loc.hasStatusWarning = !isFullyLocalized;
        });
    }

    getLocalization() {
        const entityType = this.crConstants.entity.types.PLACES;
        this.crLocalizationService
            .getEntityLocalization(entityType, { id: this.getSafeKitchenId(), venueId: this.$state.params.venueId })
            .then((res) => {
                this.kitchen.localization = res;
                this.updateLocaleStatus();
                this.initPickupZones();
            });
    }

    initPickupZones() {
        const pickupZones = this.kitchen.features.pickupZones || [];
        // Prime the localization object with zone labels for the current locale, if missing
        pickupZones.forEach((zone, index) => {
            if (zone.type !== 'LOCKER_PICKUP') {
                zone.pickupZoneId = `zone_${index + 1}`;
            }
            const lPath = ['localization', this.locale.current, 'features', 'pickupZones', index, 'label'];
            if (!_.get(this.kitchen, lPath)) {
                _.setWith(this.kitchen, lPath, zone.label, Object);
            }
            const entryPromptPath = [
                'localization',
                this.locale.current,
                'features',
                'pickupZones',
                index,
                'entryPrompt',
            ];
            if (!_.get(this.kitchen, entryPromptPath)) {
                _.setWith(this.kitchen, entryPromptPath, zone.entryPrompt, Object);
            }
        });

        // Combine pickup zones with localization
        this.pickupZones = pickupZones.map((zone, index) => {
            const localization = this.getLocalizedValuesForPickupZoneIndex(index);
            return { ...zone, localization };
        });

        // Set up initial state of radio options
        this.pickupZoneRadioOptions = [
            {
                label: this.text.pickupZoneSingle,
                name: 'single',
                value: 'SINGLE',
            },
            {
                label: this.text.pickupZoneMultiple,
                name: 'multi',
                value: 'MULTI',
            },
            {
                label: this.text.pickupZoneLockers,
                name: 'lockers',
                value: 'LOCKER_PICKUP',
            },
        ];

        // Setting per zone
        this.initPickupZoneCustomOptions();

        if (this.pickupZones && this.pickupZones.length === 1 && this.pickupZones[0].type === 'LOCKER_PICKUP') {
            this.pickupZoneOption = 'LOCKER_PICKUP';
        } else {
            this.pickupZoneOption = this.pickupZones && this.pickupZones.length >= 2 ? 'MULTI' : 'SINGLE';
        }

        if (this.pickupZoneOption !== 'LOCKER_PICKUP') {
            // Add zones until there are two
            while (this.pickupZones.length < 2) {
                this.onPickupZoneAdd();
            }
        }
    }

    initPickupZoneCustomOptions() {
        this.pickupZoneRegexOptions = [];
        this.pickupZones.forEach(this.initPickupZoneCustomOptionForZone.bind(this));
    }

    initPickupZoneCustomOptionForZone(zone, index) {
        // Regex dropdown
        if (this.configOptionsForZones) {
            const mapResultToOptions = (opt) => ({ id: opt.regex, label: opt.label });
            this.pickupZoneRegexOptions[index] = this.configOptionsForZones.map(mapResultToOptions);
            // Is it already selected?
            this.pickupZoneRegexOptions[index].forEach((option) => {
                option.isSelected = zone.rule && zone.rule.regex === option.id;
            });
        }
    }

    updateKitchenWithPickupZones() {
        this.kitchen.features.pickupZones = _.clone(this.pickupZones);

        // Truncate the list if the option is set to SINGLE (and there are enough options)
        if (this.pickupZoneOption === 'SINGLE' && this.kitchen.features.pickupZones.length >= 1) {
            const zone = this.pickupZones[0];
            zone.type = undefined;
            zone.details = undefined;
            if (_.isEmpty(zone.label) && !zone.requiresEntry) {
                this.kitchen.features.pickupZones = null;
                return;
            }
            this.kitchen.features.pickupZones = [zone];
        } else if (this.pickupZoneOption === 'LOCKER_PICKUP' && this.selectedLocker != null) {
            const zone = {
                label: 'Locker Pickup',
                pickupZoneId: this.selectedLocker.id,
                type: 'LOCKER_PICKUP',
                details: {
                    hold: this.selectedLockerHoldTime.value,
                    lockerLocation: this.lockerLocationDescription
                }
            }
            this.kitchen.features.pickupZones = [zone];
        } else if (this.pickupZoneOption === 'MULTI') {
            this.kitchen.features.pickupZones.forEach((zone, index) => {
                zone.type = undefined;
                zone.details = undefined;
            });
        }

        // Pick numerical pickupZoneIDs, fix up the zone rule so it doesn't get saved if it's not set
        this.kitchen.features.pickupZones.forEach((zone, index) => {
            if (zone.type !== 'LOCKER_PICKUP') {
                zone.pickupZoneId = `zone_${index + 1}`;
                if (!zone.requiresEntry) {
                    zone.rule = null;
                }
            }
        });

        this.pickupZones.forEach((zone, index) => {
            Object.keys(zone.localization).forEach((locale) => {
                _.setWith(
                    this.kitchen.localization,
                    [locale, 'features', 'pickupZones', index],
                    zone.localization[locale],
                    Object
                );
            });
        });
    }

    getLocalizedValuesForPickupZoneIndex(index) {
        const localized = {};
        Object.keys(this.kitchen.localization).forEach((key) => {
            const lPath = ['localization', key, 'features', 'pickupZones', index];
            localized[key] = _.get(this.kitchen, lPath, { label: '' });
        });
        return localized;
    }

    initLocaleDropdown() {
        this.showLocaleDropdown =
            this.crConfig.customer.localizationEnabled && this.locale.list && this.locale.list.length > 1;
    }

    onLocaleSelect(event) {
        this.locale.current = event.model[0].id;
        // Trigger other dropdowns to refresh
        this.locale.list = _.clone(this.locale.list);
    }

    setSelectedLocker() {
        if (this.pickupZones && this.pickupZones.length === 1 && this.pickupZones[0].type === 'LOCKER_PICKUP' && this.selectedLocker === null) {
            this.pickupZoneOption = 'LOCKER_PICKUP';
            this.selectedLocker = this.lockers.filter(locker => locker.id === this.pickupZones[0].pickupZoneId)[0];

            if (this.selectedLocker) {
                this.lockers.filter(locker => locker.id === this.pickupZones[0].pickupZoneId)[0].isSelected = true;
                this.lockers = [...this.lockers];
            }

            this.selectedLockerHoldTime = this.lockerHoldTimes.filter(hold => hold.value === this.pickupZones[0].details.hold)[0];
            if (this.selectedLockerHoldTime){
                this.lockerHoldTimes.filter(hold => hold.value === this.pickupZones[0].details.hold)[0].isSelected = true;
                this.lockerHoldTimes = [...this.lockerHoldTimes];
            }
        }
    }

    onLockerSelect(event) {
        this.selectedLocker = event.model[0];
    }

    onLockerHoldTimeSelect(event) {
        this.selectedLockerHoldTime = event.model[0];
    }

    onLockerLocationUpdate(event) {
        this.lockerLocationDescription = event.model;
        _.set(this.pickupZones, [0, 'localization', this.locale.current, 'details', 'lockerLocation'], event.model);
        this.updateLocaleStatus();
    }

    onPromptForPhoneNumberToggleUpdate(){
        this.kitchen.controls.promptForPhoneNumber = !this.kitchen.controls.promptForPhoneNumber;
        if (!this.kitchen.controls.promptForPhoneNumber) {
            this.kitchen.controls.phoneNumberRequired = false;
        }

        this.form.$setDirty();
    }

    setPhoneNumberCollection() {
        if(this.kitchen && this.kitchen.features && this.kitchen.features.pickupZones) {
            this.phoneNumberCollectionEnabled = !this.kitchen.features.pickupZones.some((pz) => pz.type === 'LOCKER_PICKUP');
        }
    }

    removePhoneNumberCollectionFields() {
        this.phoneNumberCollectionEnabled = false;
        this.kitchen.controls.phoneNumberRequired = false;
        this.webContentWithphoneNumberCollection.forEach((field) => {
            this.onWebContentUpdate({model: undefined}, field);
        });
    }
}
