import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CrTitleBarComponent } from './title-bar.component';
import { PositionStickyDirective } from '../directives/position-sticky.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [CrTitleBarComponent, PositionStickyDirective],
  exports: [CrTitleBarComponent],
})
export class CrTitleBarModule {}
