import moment from 'moment';
import 'moment-timezone';

class CalendarDay {
    constructor($interval, $timeout) {
        this.$interval = $interval;
        this.$timeout = $timeout;
    }

    $onInit() {
        const tz = this.calendar.timezone.name;
        const nowTime = moment().tz(tz);

        this.isCurrentDay = nowTime.isSameOrAfter(this.schedule.start) && nowTime.isBefore(this.schedule.end);

        if (this.isCurrentDay) {
            this.updateNowTime();
            this.$interval(() => {
                this.updateNowTime();
            }, 30000);
        }
    }

    updateNowTime() {
        const tz = this.calendar.timezone.name;
        const nowTime = moment().tz(tz);
        const midnight = moment(nowTime).startOf('day');
        const minutesFromMidnight = nowTime.diff(midnight, 'minute');
        const pixelRatio = 60 / this.calendar.hourInPixels;

        if (nowTime.isSameOrBefore(this.schedule.end)) {
            this.currentTimeInPx = minutesFromMidnight / pixelRatio;
        } else {
            this.isCurrentDay = false;
        }
    }
}

export default CalendarDay;
