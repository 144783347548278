import controller from './menu-manage.controller';
import template from './menu-manage.html';
import './menu-manage.less';

const component = {
    template,
    bindings: {
        edit: '<?',
    },
    controller: [
        '$state',
        '$q',
        '$uibModal',
        'crAnalyticsService',
        'crConstants',
        'crEntityService',
        'crErrorLoggingService',
        'crMenusService',
        'crNavigationService',
        'crPlacesService',
        controller,
    ],
};

export default component;
