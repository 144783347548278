import * as angular from 'angular';
import { downgradeInjectable } from '@angular/upgrade/static';

import { ConfigService } from './config.service';
import { CR_CONFIG } from './cr-config';

export const Ng1ConfigModule = angular
  .module('control-room.angular.config', ['control-room.angular.user-service'])
  .factory('crConfigService', downgradeInjectable(ConfigService))
  .factory('crConfig', downgradeInjectable(CR_CONFIG));
