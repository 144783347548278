import angular from 'angular';
import AngularUI from 'angular-ui-bootstrap';
import UIRouter from '@uirouter/angularjs';
import AngularSanitize from 'angular-sanitize';
import AngularAnimate from 'angular-animate';
import NgFileUpload from 'ng-file-upload';

import 'ng-cropperjs/dist/ngCropperjs.all.min.css';
import 'ng-cropperjs';
import 'ng-draggable';
import 'ng-pattern-restrict';
import 'angular-bootstrap-contextmenu';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.bubble.css';
import 'quill/dist/quill.snow.css';

import AngularMessages from 'angular-messages';

import ClientLocale from './resources/locale/en.json';
import ClientRouting from './client.routing';

import CommonsModule from '../commons';
import ClientComponent from './client.component';

import NavPanelOptions from './resources/nav-panel.json';

import DashboardModule from './dashboard/dashboard';
import QuicksightDashboardModule from './quicksight-dashboard/quicksight-dashboard';
import EventsModule from './events/events';
import ServicesModule from './services/services';
import ComponentsModule from './components/components';
import PlacesModule from './places/places';
import POIModule from './poi/poi';
import ReportsModule from './reports/reports';
import InternalModule from './internal/internal';
import BeaconsModule from './beacons/beacons';
import McClassicModule from './mc-classic/mc-classic';
import Menus from './menus/menus';
import VenueHoursModule from './venue-hours/venue-hours';
import AppSettingsModule from './app-settings/app-settings';
import ExperiencePromoterModule from './experience-promoter/experience-promoter';
import FnbOrderQueueModule from './fnb-order-queue/fnb-order-queue';
import FnbReservationModule from './fnb-reservations/fnb-reservations';

// Flyout templates
import EventFlyoutTemplate from './events/components/event-flyout/flyout-template/event-flyout.html';
import ExperienceFlyoutTemplate from './experience-promoter/components/experience-flyout/flyout-template/experience-flyout.html';

const ClientModule = angular
    .module('control-room.client', [
        // Libraries
        AngularUI,
        UIRouter,
        AngularMessages,
        AngularAnimate,
        AngularSanitize,
        'ui.bootstrap.contextMenu',
        NgFileUpload,
        'ngCropper',
        'ngDraggable',
        'ngPatternRestrict',

        // Dependencies
        CommonsModule.name,

        // App Modules
        DashboardModule.name,
        QuicksightDashboardModule.name,
        EventsModule.name,
        ServicesModule.name,
        ComponentsModule.name,
        ReportsModule.name,
        PlacesModule.name,
        POIModule.name,
        InternalModule.name,
        BeaconsModule.name,
        McClassicModule.name,
        Menus.name,
        VenueHoursModule.name,
        AppSettingsModule.name,
        ExperiencePromoterModule.name,
        FnbOrderQueueModule.name,
        FnbReservationModule.name
    ])
    .component('crClient', ClientComponent)
    .run([
        '$templateCache',
        ($templateCache) => {
            $templateCache.put('event-flyout', EventFlyoutTemplate);
            $templateCache.put('experience-flyout', ExperienceFlyoutTemplate);
        },
    ])
    .constant('clientLocale', ClientLocale)
    .constant('navPanelOptions', NavPanelOptions)
    .config(['$stateProvider', '$urlRouterProvider', ClientRouting])
    .factory('$exceptionHandler', [
        '$injector',
        function ($injector) {
            return function catchUnhandledException(exception, cause) {
                $injector.get('crErrorLoggingService').logError('unhandled exception', exception, cause, true);
            };
        },
    ]);

export default ClientModule;
