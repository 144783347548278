import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { CrDropdownModule } from '../../../commons/basic/components/dropdown/dropdown.module';
import { TooltipWrapperDirective } from '../../../commons/basic/components/tooltip-wrapper/tooltip-wrapper.component';
import { SharedModule } from '../../../shared';
import { LiveMapAppUsageComponent } from './live-map-app-usage/live-map-app-usage.component';
import { LiveMapMetricsComponent } from './live-map-metrics/live-map-metrics.component';
import { LiveMapPopupComponent } from './live-map-popup/live-map-popup.component';
import { LiveMapComponent } from './live-map.component';
import { ToggleModule } from "../../../commons/form/components/toggle/toggle.module";

@NgModule({
  declarations: [
    LiveMapComponent,
    LiveMapAppUsageComponent,
    LiveMapMetricsComponent,
    LiveMapPopupComponent,
    TooltipWrapperDirective,
  ],
  imports: [CommonModule, FormsModule, NgxMapboxGLModule, SharedModule, CrDropdownModule, ToggleModule],
  entryComponents: [LiveMapComponent],
})
export class LiveMapModule {}
