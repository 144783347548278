import angular from 'angular';

import component from './venue-hours.component';

import componentsModule from './components/components';

const VenueHours = angular
    .module('control-room.venue-hours', [componentsModule.name])
    .component('crVenueHours', component);

export default VenueHours;
