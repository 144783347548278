import _ from 'lodash';
import text from './resources/locale/en.json';

class WeekdayEditorController {
    constructor(crConstants) {
        this.crConstants = crConstants;
    }

    $onInit() {
        this.text = text;
        this.weekdays = this.crConstants.iCal.weekdays;
    }

    $onChanges(changes) {
        if (changes && changes.selectedDays) {
            if (changes.selectedDays.currentValue) {
                this.selectedDays = _.cloneDeep(this.selectedDays);

                if (!changes.selectedDays.isFirstChange()) {
                    this.form[this.name].$setTouched();
                }
            }
        }

        if (changes.validDays && changes.validDays.currentValue) {
            this.updateValidDays();
        }
    }

    isSelected(weekday) {
        if (!this.validDays) {
            return _.includes(this.selectedDays, weekday);
        }

        return _.includes(this.selectedDays, weekday) && _.includes(this.validDays, weekday);
    }

    isDisabled(weekday) {
        if (!this.validDays) {
            return false;
        }

        return !_.includes(this.validDays, weekday);
    }

    update(weekday) {
        const selectedDays = this.selectedDays.filter((day) => day);

        // Add or remove the weekday from the list
        if (!_.includes(selectedDays, weekday)) {
            if (!this.validDays || _.includes(this.validDays, weekday)) {
                selectedDays.push(weekday);
            }
        } else {
            _.pull(selectedDays, weekday);
        }

        this.onSelect({
            $event: {
                model: selectedDays,
                selectedDay: weekday
            },
        });
    }

    updateValidDays() {
        if (this.selectedDays) {
            const selectedDays = [];

            this.selectedDays.forEach((day) => {
                if (_.includes(this.validDays, day)) {
                    selectedDays.push(day);
                }
            });

            this.onValidDaysChange({
                $event: {
                    model: selectedDays,
                },
            });
        }
    }
}

export default WeekdayEditorController;
