import text from './resources/locale/en.json';

class AppSettings {
    constructor($state, crConstants, crAnalyticsService, crEntityService) {
        this.$state = $state;
        this.crConstants = crConstants;
        this.crAnalyticsService = crAnalyticsService;
        this.crEntityService = crEntityService;
    }

    $onInit() {
        this.text = text;
        this.overflowItems = [
            {
                name: this.text.questions,
                state: 'client.app-settings.question-list',
            },
        ];
    }
}

export default AppSettings;
