import angular from 'angular';

import PlacesComponent from './places.component';
import ComponentsModule from './components/components';

const PlacesModule = angular
    .module('control-room.places', [ComponentsModule.name])
    .component('crPlaces', PlacesComponent);

export default PlacesModule;
