import _ from 'lodash';
import locationTargetUrl from '../../../../commons/basic/resources/images/location_target.svg';
import text from './resources/locale/en.json';

class BeaconEditController {
    constructor(
        $q,
        $state,
        $uibModal,
        crConstants,
        crBeaconEditService,
        crNavigationService,
        crErrorLoggingService,
        crGraphicalMapService,
        crEntityService
    ) {
        this.$q = $q;
        this.$state = $state;
        this.$uibModal = $uibModal;
        this.crConstants = crConstants;
        this.imageConstants = crConstants.image;
        this.beaconEditService = crBeaconEditService;
        this.navigationService = crNavigationService;
        this.errorLoggingService = crErrorLoggingService;
        this.graphicalMapService = crGraphicalMapService;
        this.crEntityService = crEntityService;
    }

    $onInit() {
        this.beacon = {};
        this.text = text;

        this.isLoading = false;
        this.hasDataError = false;

        this.debouncedUpdateLatLon = _.debounce(this.updateLatLon, this.crConstants.debounce.SHORT);

        this.marker = {
            source: locationTargetUrl,
            w: this.crConstants.marker.defaultDimensions.h,
            h: this.crConstants.marker.defaultDimensions.w,
            x: 0,
            y: 0,
        };
        this.markers = [this.marker];

        this.units = [
            { id: -30, label: `${this.text.units[-30].value} ${this.text.units[-30].range}` },
            { id: -20, label: `${this.text.units[-20].value} ${this.text.units[-20].range}` },
            { id: -16, label: `${this.text.units[-16].value} ${this.text.units[-16].range}` },
            { id: -12, label: `${this.text.units[-12].value} ${this.text.units[-12].range}` },
            { id: -8, label: `${this.text.units[-8].value} ${this.text.units[-8].range}` },
            { id: -4, label: `${this.text.units[-4].value} ${this.text.units[-4].range}` },
            { id: 0, label: `${this.text.units[0].value} ${this.text.units[0].range} ` },
            { id: 4, label: `${this.text.units[4].value} ${this.text.units[4].range}` },
        ];

        this.poiList = [];

        if (this.$state.params.id) {
            this.getEditData();
        } else {
            this.createBeaconForm();
        }

        this.navigationService.enableConfirmNavigation(() => this.form.$dirty);
    }

    getEditData() {
        this.isLoading = true;
        this.label = this.edit ? this.text.edit : this.text.create;

        this.beaconEditService
            .getData(this.$state.params.id)
            .then((details) => {
                this.beacon = details;
                if (!this.beacon.images) {
                    this.beacon.images = [];
                }
                if (!this.edit) {
                    this.beacon.name = `${this.text.copy} ${this.beacon.name}`;
                    if (this.form) {
                        this.form.name.$setDirty();
                    }
                }
                this.getPoiList();
                this.selectValue(this.beacon.rssi || null, this.units, 'id');
                this.units = _.cloneDeep(this.units);

                this.getMapMarkerData([this.beacon.location]);
            })
            .catch((err) => {
                this.errorLoggingService.logError('Could not get beacons data', err);

                if (err.status === 404) {
                    this.$state.go('client.404', {
                        customerId: this.$state.params.customerId,
                        venueId: this.$state.params.venueId,
                    });
                } else {
                    this.$uibModal
                        .open({
                            backdrop: 'static',
                            component: 'crSimpleModal',
                            windowClass: 'cr-modal-size-sm',
                            resolve: {
                                message: () => this.text.standardErrorMessage,
                            },
                        })
                        .result.catch(() => {
                            this.$state.go('client.beacons.list', {
                                customerId: this.$state.params.customerId,
                                venueId: this.$state.params.venueId,
                            });
                        });
                }
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    createBeaconForm() {
        this.isLoading = true;
        this.label = this.text.create;
        this.beacon.location = {};
        this.beacon.owner = {
            id: this.$state.params.venueId,
            type: 'VENUE',
        };
        this.getPoiList();
        this.getMapMarkerData([]);
    }

    selectValue(value, array, key) {
        if (!_.isNull(value)) {
            _.find(array, [key, value]).isSelected = true;
        }
    }

    getPoiList() {
        const params = {
            sort: 'name,asc',
            venueId: this.$state.params.venueId,
        };

        this.crEntityService
            .getEntityList(this.crConstants.entity.types.POIS, params)
            .then((data) => {
                const emptyPoi = {
                    id: null,
                    label: this.crConstants.emptyRepresentation,
                };

                this.poiList.push(emptyPoi);

                _.forEach(data.content, (poi) => {
                    this.poiList.push({
                        id: poi.id,
                        label: poi.name,
                    });
                });

                if (this.beacon.associatedPoi) {
                    this.selectValue(this.beacon.associatedPoi.name, this.poiList, 'label');
                } else {
                    emptyPoi.isSelected = true;
                }

                this.poiList = _.cloneDeep(this.poiList);
            })
            .catch((err) => {
                this.errorLoggingService.logError('Could not get Poi list data for beacons', err);
            });
    }

    getMapMarkerData(locations) {
        this.mapLoading = true;
        this.mapError = false;

        return this.graphicalMapService
            .getMapMarkerData(this.$state.params.venueId, locations)
            .then((res) => {
                this.defaultMapId = res.defaultMapId;
                if (res.translatedLocations.length) {
                    this.focus = [res.translatedLocations[0].position.x, res.translatedLocations[0].position.y];
                }

                this.mapImageSrc = res.mapImageSrc;

                if (!this.$state.params.id) {
                    return this.graphicalMapService
                        .getLatLonFromXY(this.$state.params.venueId, this.defaultMapId, { x: 0.5, y: 0.5 })
                        .then(() => {
                            this.setLatLon(res);
                        });
                }
            })
            .catch((err) => {
                this.mapError = true;
                this.showErrorModal();
                this.errorLoggingService.logError('Could not get graphical map for beacon', err);
            })
            .finally(() => {
                this.mapLoading = false;
                this.isLoading = false;
            });
    }

    goBack() {
        if (this.edit) {
            this.navigationService.goBack('client.beacons.details', { id: this.beacon.id });
        } else {
            this.navigationService.goBack('client.beacons.list');
        }
    }

    onSaveSuccess(beaconData) {
        this.navigationService.disableConfirmNavigation();
        const params = this.getToastParams(beaconData);

        if (this.edit) {
            params.id = this.beacon.id;
            this.navigationService.goBack('client.beacons.details', params);
        } else {
            this.navigationService.goToFromCreate('client.beacons.list', params);
        }
    }

    getToastParams(beaconData) {
        const toastMsg = this.edit ? this.text.updateSuccess : this.text.createSuccess;

        const params = {
            customerId: this.$state.params.customerId,
            id: beaconData.id,
            toast: {
                msg: `"${beaconData.name}" ${toastMsg}`,
            },
        };

        return params;
    }

    onMapLoad(event) {
        this.mapImage = event.mapImage;
        this.canvas = event.canvas;
        if (!this.focus) {
            this.focus = [this.mapImage.width / 2, this.mapImage.height / 2];
        }
        this.marker.x = this.focus[0];
        this.marker.y = this.focus[1];
    }

    onMapDrag(e) {
        if (!this.fullscreen) {
            this.marker.x = Math.min(this.mapImage.width, Math.max(0, this.marker.x - e.mapMovementX));
            this.marker.y = Math.min(this.mapImage.height, Math.max(0, this.marker.y - e.mapMovementY));

            this.debouncedUpdateLatLon();
        }
    }

    onMapClick(e) {
        if (this.fullscreen) {
            const mapX = Math.min(this.mapImage.width, Math.max(0, e.mapX));
            const mapY = Math.min(this.mapImage.height, Math.max(0, e.mapY));
            this.marker.x = mapX;
            this.marker.y = mapY;

            this.focus[0] = mapX;
            this.focus[1] = mapY;

            this.debouncedUpdateLatLon();
        }
    }

    updateLatLon() {
        this.graphicalMapService
            .getLatLonFromXY(this.$state.params.venueId, this.defaultMapId, {
                x: this.marker.x / this.mapImage.width,
                y: this.marker.y / this.mapImage.height,
            })
            .then((res) => {
                this.setLatLon(res);
            })
            .catch((err) => {
                this.errorLoggingService.logError('Could not get lat-lon from x-y', err);
            });
    }

    setLatLon(location) {
        if (location && !_.isNil(location.lat) && !_.isNil(location.lon)) {
            this.beacon.location.lat = location.lat;
            this.beacon.location.lon = location.lon;

            if (this.form) {
                this.form.$setDirty();
            }
        }
    }

    updateType(event, dropdown) {
        if (dropdown === 'rssi') {
            this.beacon.rssi = event.model[0].id;
        } else if (event.model[0].id) {
            this.beacon.associatedPoi = {
                id: event.model[0].id,
                name: event.model[0].label,
            };
        } else {
            this.beacon.associatedPoi = null;
        }
    }

    onImageChange(event) {
        this.beacon.images = event.images;
    }

    updateBeacon(beacon) {
        this.beaconEditService
            .updateBeacon(beacon.id, beacon)
            .then(() => {
                this.onSaveSuccess(beacon);
            })
            .catch((err) => {
                this.handleError('Could not update beacon', err);
            })
            .finally(() => {
                this.saving = false;
            });
    }

    createBeacon(beacon) {
        this.beaconEditService
            .createBeacon(beacon)
            .then(() => {
                this.onSaveSuccess(beacon);
            })
            .catch((err) => {
                this.handleError('Could not create Beacon', err);
            })
            .finally(() => {
                this.saving = false;
            });
    }

    save() {
        this.form.$setSubmitted();

        if (this.form.$valid) {
            if (this.form.$dirty) {
                this.saving = true;
                this.beacon.notes = this.form.notes.$viewValue;

                if (this.edit) {
                    this.updateBeacon(this.beacon);
                } else {
                    this.createBeacon(this.beacon);
                }
            } else {
                this.goBack();
            }
        }
    }

    handleError(errMsg, err) {
        this.showErrorModal();
        this.errorLoggingService.logError(errMsg, err);
    }

    showErrorModal() {
        const message = this.edit ? this.text.updateError : this.text.createError;

        this.$uibModal
            .open({
                backdrop: 'static',
                component: 'crSimpleModal',
                windowClass: 'cr-modal-size-sm',
                resolve: {
                    message: () => message,
                },
            })
            .result.catch(() => null);
    }

    updateFormValue(key, event) {
        this.beacon[key] = event.model;
    }

    updateLocation(key, event) {
        this.beacon.location[key] = event.model;
    }
}

export default BeaconEditController;
