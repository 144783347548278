import { Component, ViewEncapsulation } from '@angular/core';
import { StateService } from '@uirouter/core';
import { LocalizedText } from '../../../../core';
import { ReservationService } from '../../../../shared';
import { ReservationLocation } from '../../../../shared/services/reservations/reservation.types';
import { NavLocation } from '../nav-header/nav-header.component';
import text from './resources/locale/en.json';

@Component({
  selector: 'cr-restaurant-select',
  templateUrl: './restaurant-select.component.html',
  styleUrls: ['./restaurant-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RestaurantSelectComponent {
  hasError: boolean;

  isLoading: boolean;

  locations: ReservationLocation[];

  text: LocalizedText;

  navLocation: NavLocation;

  constructor(private reservationService: ReservationService, private state: StateService) {
    this.isLoading = true;
    this.text = text as LocalizedText;
    this.navLocation = NavLocation.Reservations;
  }

  ngOnInit(): void {
    this.reservationService
      .getReservationLocationList(this.state.params.venueId)
      .then((res) => {
        this.locations = res?.places ?? [];
      })
      .catch((err) => {
        console.error(err);
        this.hasError = true;
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  selectLocation(productId: string, name: string) {
    this.state.go('client.fnb-reservations.reservations-list', { productId, name });
  }
}
