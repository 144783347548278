import moment from 'moment';
import 'moment-timezone';

import text from './resources/locale/en.json';

class Calendar {
    constructor($window, $state, crCalendarService, crVenueService, crConstants) {
        this.$window = $window;
        this.$state = $state;
        this.crCalendarService = crCalendarService;
        this.crVenueService = crVenueService;
        this.crConstants = crConstants;
    }

    $onChanges(changes) {
        if (changes && this.schedule) {
            this.initData(this.schedule);

            const { start } = this.$state.params;
            const { end } = this.$state.params;
            this.headings = this.crCalendarService.getDaysModel({ start, end });
        }
    }

    $onInit() {
        this.text = text;
        this.positionSticky = this.positionSticky ? parseInt(this.positionSticky) : 158;
        this.hourInPixels = this.$window.innerHeight <= 960 ? 40 : 50;
        this.timeSlots = [
            '12 AM',
            '1 AM',
            '2 AM',
            '3 AM',
            '4 AM',
            '5 AM',
            '6 AM',
            '7 AM',
            '8 AM',
            '9 AM',
            '10 AM',
            '11 AM',
            '12 PM',
            '1 PM',
            '2 PM',
            '3 PM',
            '4 PM',
            '5 PM',
            '6 PM',
            '7 PM',
            '8 PM',
            '9 PM',
            '10 PM',
            '11 PM',
        ];
        this.timezone = this.crVenueService.getTimezone(this.$state.params.venueId);

        const { start } = this.$state.params;
        const { end } = this.$state.params;
        this.headings = this.crCalendarService.getDaysModel({ start, end });

        this.scrollInit();
    }

    scrollInit() {
        const nowTime = moment().tz(this.timezone.name);
        if (nowTime) {
            setTimeout(() => {
                window.scrollTo(0, 320);
            }, 0);
        }
    }

    initData(data) {
        const { start } = this.$state.params;
        const { end } = this.$state.params;
        this.events = this.crCalendarService.getCalendarModel(
            data,
            { start, end },
            this.hourInPixels,
            this.locationMap
        );
    }
}

export default Calendar;
