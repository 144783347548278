import controller from './manage-buttons.controller';
import template from './manage-buttons.html';

const component = {
    template,
    bindings: {
        onCancel: '&',
        onSave: '&',
        splitButton: '<?',
        splitActions: '<?',
    },
    controller: [controller],
};

export default component;
