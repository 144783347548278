import controller from './change-password-modal.controller';
import template from './change-password-modal.html';
import './change-password-modal.less';

const component = {
    template,
    bindings: {
        close: '&',
        dismiss: '&',
        modalInstance: '<',
    },
    controller: [
        '$timeout',
        'crAuthService',
        'crChangePasswordModalText',
        'crInputValidators',
        'crErrorLoggingService',
        'crConstants',
        'crAnalyticsService',
        controller,
    ],
};

export default component;
