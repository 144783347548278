import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Coupons, Coupon, Query } from "./coupons.model";

@Injectable({
    providedIn: 'root',
})
export class CouponsService {
    url: string;

    constructor(private http: HttpClient) {
        this.url = '/rest/coupons';
    }

    getCoupons(params: any): Promise<Coupons> {
        return this.http.get<Coupons>(this.url, { params }).toPromise().then((res) => res);
    }

    getCoupon(id: string, includeAssociated): Promise<Coupon> {
        return this.http
            .get<Coupon>(`${this.url}/${id}?includeAssociatedExperiences=${includeAssociated || false}`).toPromise()
            .then((res) => res);
    }

    createCoupon(coupon: Coupon): Promise<Coupon> {
        return this.http.post<Coupon>(this.url, coupon).toPromise();
    }

    updateCoupon(coupon: Coupon): Promise<Coupon> {
        return this.http.put<Coupon>(`${this.url}/${coupon.id}`, coupon).toPromise();
    }

    updateStatus(id: string, data: Coupon) {
        return this.http.put(`${this.url}/${id}/state`, data).toPromise();
    }

    archiveCoupon(id: string) {
        return this.http.delete(`${this.url}/${id}`).toPromise();
    }
}

export default CouponsService;
