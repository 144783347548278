import template from './simple-table.html';
import './simple-table.less';
import SimpleTableController from './simple-table.controller';
import TableDirective from '../table/table.directive';

class SimpleTableDirective extends TableDirective {
    constructor($compile) {
        super();
        this.restrict = 'E';
        this.trTemplate = template;
        this.controller = SimpleTableController;
        this.controllerAs = 'simpleTableCtrl';
        this.bindToController = true;
        this.$compile = $compile;
        this.scope = {
            data: '=',
        };
    }

    static directiveFactory($compile) {
        return new SimpleTableDirective($compile);
    }
}

SimpleTableDirective.directiveFactory.$inject = ['$compile'];

export default SimpleTableDirective;
