import template from './weekday-editor.html';
import './weekday-editor.less';
import Controller from './weekday-editor.controller';

const Component = {
    template,
    require: {
        form: '^form',
    },
    bindings: {
        name: '@',
        onSelect: '&',
        onValidDaysChange: '&',
        selectedDays: '<',
        validDays: '<?',
        crRequired: '<?',
        customClass: '@?',
    },
    controller: ['crConstants', Controller],
};

export default Component;
