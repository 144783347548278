class CategoryArchiveModalController {
    constructor($sce, crConstants, text) {
        this.$sce = $sce;
        this.crConstants = crConstants;
        this.text = text;
        this.type = this.crConstants.modalTypes.SUBMIT;
    }

    $onInit() {
        if (this.resolve) {
            this.resolve.title = this.$sce.trustAsHtml(this.resolve.title);
            this.resolve.description = this.$sce.trustAsHtml(this.resolve.description);

            const categoriesWithProducts = this.resolve.items.filter((cat) => {
                const activeProducts = cat.products.filter((prod) => prod.state !== 'ARCHIVED');
                return activeProducts.length > 0;
            });

            if (categoriesWithProducts.length > 0) {
                this.hasError = true;
                this.type = this.crConstants.modalTypes.CANCEL;
            }
        }
    }

    onCancel() {
        this.dismiss();
    }

    onSubmit() {
        this.close();
    }
}

export default CategoryArchiveModalController;
