import _ from 'lodash';

class DurationInputController {
    constructor(crConstants, $timeout) {
        this.crConstants = crConstants;
        this.$timeout = $timeout;
    }

    $onInit() {
        this.handleUpdatedDuration();
        this.restrictPattern = this.crConstants.input.restrictPattern.twoDigit;
    }

    $onChanges(changes) {
        if (changes && changes.duration) {
            if (!_.isNil(changes.duration.currentValue)) {
                if (!changes.duration.isFirstChange()) {
                    this.handleUpdatedDuration();
                }
            }
        }
    }

    handleUpdatedDuration() {
        if (!_.isNil(this.duration)) {
            this.updateDurationString();
        }
    }

    updateDurationString() {
        this.durationString = Math.round(this.duration / this.secondsPerUnit).toString();
    }

    onDurationStringChange(event) {
        const durationString = event.model;
        const durationInt = parseInt(durationString);

        // Update our duration or clear it if empty
        if (durationString !== '') {
            let valid = !_.isNaN(durationInt);

            if (valid && this.maxValue && durationInt > this.maxValue) {
                valid = false;
            }

            if (valid) {
                this.updateDuration(durationInt.toString());
            }

            this.setValidity(valid);
        } else {
            this.update(durationString);
        }
    }

    updateDuration(durationString) {
        const duration = parseInt(durationString) * this.secondsPerUnit;

        // Update only if the string changed
        if (duration !== this.duration) {
            this.update(duration);
        } else {
            this.revertValue();
        }
    }

    // TODO: Find a better way to handle reverting a value. Using $timeout to force $onChanges to fire.
    revertValue() {
        this.durationString = '--';
        this.$timeout(() => {
            this.updateDurationString();
        });
    }

    update(duration) {
        this.onUpdate({
            $event: {
                model: duration,
            },
        });
    }

    setValidity(valid) {
        const formElem = this.form[this.name];

        if (formElem) {
            formElem.$setValidity('duration', valid);
        }
    }
}

export default DurationInputController;
