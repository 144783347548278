import controller from './icons.controller';
import template from './icons.html';
import '../../../../commons/basic/styles/styles.less';
import './icons.less';

const component = {
    template,
    controller: ['crIconNames', controller],
};

export default component;
