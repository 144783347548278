import {
  Component, Output, EventEmitter, OnInit,
} from '@angular/core';

import text from './resources/locale/en.json';
import { LocalizedText } from '../../../core/index.js';

@Component({
  selector: 'cr-edit-header',
  templateUrl: './edit-header.component.html',
  styleUrls: ['./edit-header.component.scss'],
})
export class EditHeaderComponent implements OnInit {
  text: LocalizedText = text;

  gray: boolean;

  @Output()
  save = new EventEmitter<null>();

  @Output()
  cancel = new EventEmitter<null>();

  ngOnInit() {
    this.gray = true;
  }

  saveClicked() {
    this.save.emit();
  }

  cancelClicked() {
    this.cancel.emit();
  }
}
