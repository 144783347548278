import moment from 'moment';
import text from './resources/locale/en.json';

class DashboardController {
    constructor($state, crReportFileService, crAnalyticsService, crErrorLoggingService) {
        this.text = text;
        this.$state = $state;
        this.crReportFileService = crReportFileService;
        this.crAnalyticsService = crAnalyticsService;
        this.crErrorLoggingService = crErrorLoggingService;
    }

    $onInit() {
        this.isLoading = true;
        this.venueId = this.$state.params.venueId;
        this.getReports(this.venueId);
    }

    getReports(venueId) {
        this.crReportFileService
            .getReportFile(venueId)
            .then((data) => {
                this.newestReport = data.shift();
                this.noRecentReport = !this.newestReport;
                this.frameSrc = this.crReportFileService.getFrameSrcUrl(this.newestReport);

                if (!data.length) {
                    this.noReports = true;
                }

                this.reports = data;
            })
            .catch((err) => {
                this.crErrorLoggingService.logError('Could not get Reports', err, { venueId });
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    onSelectLink(report) {
        this.crAnalyticsService.track('Report file opened', { fileName: report.fileName });
    }

    getReportLinkText(report) {
        const formattedDate = moment(report.date).format('ll');
        return `${this.text.weekEnding} ${formattedDate}`;
    }
}

export default DashboardController;
