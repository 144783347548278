import text from './resources/locale/en.json';

class PreferencesQuestionsOverview {
    $onInit() {
        this.text = text;
    }

    $onChanges() {
        if (this.tags && this.locale) {
            this.localizedTags = this.tags.map((tag) => {
                const { localization } = tag.tagDefinition;
                return {
                    required: tag.required,
                    type: tag.tagDefinition.type,
                    label: localization[this.locale.current].label || localization[this.locale.default].label,
                    answersString: this.getAnswersString(tag.tagDefinition.localization),
                };
            });
        }
    }

    getAnswersString(localization) {
        const { values } = localization[this.locale.current];
        const defaultValues = localization[this.locale.default].values;

        values.forEach((value, index) => {
            if (!value.label) {
                value.label = defaultValues[index].label;
            }
        });

        return values.map((v) => v.label).join(', ');
    }
}

export default PreferencesQuestionsOverview;
