class AutofocusDirective {
    constructor($timeout) {
        this.$timeout = $timeout;
        this.restrict = 'A';
    }

    static directiveFactory($timeout) {
        return new AutofocusDirective($timeout);
    }

    link(scope, element, attrs) {
        if (attrs.crAutofocus === 'true') {
            this.$timeout(() => {
                element[0].focus();
            });
        }
    }
}

AutofocusDirective.directiveFactory.$inject = ['$timeout'];

export default AutofocusDirective;
