import { Component, Input, OnInit } from '@angular/core';
import { from, Observable } from 'rxjs';
import { PolicyService } from '../../services/policy/policy.service';

@Component({
  selector: 'cr-policy-check',
  templateUrl: './policy-check.component.html',
})
export class CrPolicyCheckComponent implements OnInit {
  @Input()
  crAction: string;

  hasAccess: Observable<boolean>;

  constructor(private policyService: PolicyService) {}

  ngOnInit(): void {
    this.hasAccess = from(this.policyService.hasAccess(this.crAction));
  }
}
