import _ from 'lodash';
import moment from 'moment';

import text from './resources/locale/en.json';

class CvtDashboardHeader {
    $onInit() {
        this.text = text;
    }

    $onChanges(changes) {
        if (changes.lastRun) {
            this.showLastRun = moment(this.lastRun).isValid();
            this.lastRun = _.cloneDeep(moment(this.lastRun).calendar());
        }
    }
}

export default CvtDashboardHeader;
