import angular from 'angular';
import BeaconDetailsComponent from './beacon-details.component';
import BeaconDetailsService from './beacon-details.service';

const BeaconDetails = angular
    .module('control-room.beacons.components.beacon-details', [])
    .component('crBeaconDetails', BeaconDetailsComponent)
    .service('crBeaconDetailsService', ['$q', 'crBeaconsService', 'crBeaconsService', BeaconDetailsService]);

export default BeaconDetails;
