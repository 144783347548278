import angular from 'angular';

const FlyoutModule = angular.module('control-room.commons.directives.flyout', []).directive('crFlyout', [
    'crConstants',
    '$compile',
    (crConstants, $compile) => ({
        restrict: 'A',
        scope: {
            flyoutTemplate: '@crFlyout',
            flyoutData: '<',
            flyoutPlacement: '@?',
            isOpen: '=?',
        },
        link: {
            pre: (scope) => {
                scope.$ctrl = scope.$parent.$ctrl;
            },
            post: (scope, elem) => {
                /**
                 * Due to limitations with Angular UI Bootstrap's popover
                 * component, templates for each type of flyout must be
                 * created and imported into app.js. They are then added
                 * to the app's $templateCache.
                 */

                // Prevent infinite compile loop
                elem.removeAttr('cr-flyout');

                // Add popover attrs
                elem.attr('uib-popover-template', `'${scope.flyoutTemplate}'`);

                elem.attr('popover-is-open', 'isOpen');
                elem.attr('popover-trigger', "'outsideClick'");
                elem.attr('popover-class', 'cr-flyout');
                elem.attr('popover-popup-delay', crConstants.flyoutOpenDelay);
                elem.attr('popover-append-to-body', true);

                if (scope.flyoutPlacement) {
                    elem.attr('popover-placement', scope.flyoutPlacement);
                } else {
                    elem.attr('popover-placement', 'auto top');
                }

                // Compile the element
                $compile(elem)(scope);
            },
        },
    }),
]);

export default FlyoutModule;
