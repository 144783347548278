import controller from './data-error.controller';
import template from './data-error.html';
import './data-error.less';

const DataErrorComponent = {
    template,
    transclude: true,
    bindings: {
        hasDataError: '<',
        iconOverride: '@?',
        showStandardMessage: '<?',
        customClass: '@?',
    },
    controller,
};

export default DataErrorComponent;
