import bulletTrain from 'bullet-train-client';
import _ from 'lodash';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
  })
export class FeatureFlagsService {
    flags: any
    initialize(apiKey, user) {
        const onChange = (flags) => {
            this.flags = flags;
        };

        return new Promise<void>((resolve) => {
            bulletTrain.identify(user.username);
            bulletTrain.init({
                environmentID: apiKey,
                onChange: (flags: any) => {
                    this.flags = flags;
                    bulletTrain.onChange = onChange;
                    resolve();
                },
            });
        });
    }

    logout() {
        bulletTrain.logout();
    }

    hasFeature(flag) {
        if (_.isEmpty(flag)) {
            return true;
        }
        return bulletTrain.hasFeature(flag);
    }
}

export default FeatureFlagsService;
