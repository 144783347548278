import AppUsageController from './app-usage.controller';
import template from './app-usage.html';
import './app-usage.less';

const AppUsageComponent = {
    template,
    controller: [
        '$state',
        'crReportingAppUsageMockService',
        'crReportingAppUsageLocale',
        'numberFilter',
        'crConstants',
        'crColorPaletteConstant',
        AppUsageController,
    ],
    controllerAs: 'appUsageCtrl',
};

export default AppUsageComponent;
