import ClientController from './client.controller';
import template from './client.html';
import './client.less';

const ClientComponent = {
    template,
    controller: [
        '$http',
        '$state',
        '$rootScope',
        '$window',
        '$uibModal',
        '$transitions',
        'crConstants',
        'layoutService',
        'socket',
        'clientLocale',
        'navPanelOptions',
        'crAnalyticsService',
        'crUserService',
        'crConfig',
        'crNavigationService',
        ClientController,
    ],
};

export default ClientComponent;
