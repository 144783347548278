class CalendarAllDayEvent {
    constructor($state) {
        this.$state = $state;
    }

    $onInit() {
        this.isPlaceHolder = !this.data.metadata;
        this.classes = {
            'starts-today': this.data.startsToday,
            'ends-today': this.data.endsToday,
            'multi-day': this.data.startsToday && !this.data.endsToday,
            'recurred-yesterday': this.data.recurredYesterday,
            'recurs-tomorrow': this.data.recursTomorrow,
            automation: this.data.isAutomation,
            inactive: this.data.inactive,
        };

        this.showAutomationIcon = this.data.isAutomation && !this.data.recursTomorrow;
    }
}

export default CalendarAllDayEvent;
