<div [ngClass]="{ 'square-image': roundedCorners && !circleImage, 'circle-image': circleImage }" class="img-holder {{ size }}">
  <div
    *ngIf="!src && !imageSrc"
    class="img-holder-placeholder {{ size }}"
  >
    <span class="icon-cr-image"></span>
  </div>
  <img *ngIf="src" [ngClass]="{'square-image': roundedCorners === true && !circleImage, 'circle-image': circleImage}" [src]="src" />
  <img *ngIf="imageSrc" [ngClass]="{'square-image': roundedCorners === true && !circleImage, 'circle-image': circleImage}" [src]="imageSrc" />
</div>
