import _ from 'lodash';
import * as moment from 'moment-timezone';

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Config } from '../../../core';
import { VenueDto } from '../../models/venue.model';
import { CR_CONFIG } from '../config';

export interface VenueTimezone {
  name: string,
  offset: string,
  abbreviation: string
}

@Injectable({
  providedIn: 'root',
})
export class VenueService {
  baseUrl: string;

  constructor(@Inject(CR_CONFIG) private config: Config, private http: HttpClient) {
    this.baseUrl = '/rest/venue';
  }

  getById(venueId: string): Promise<VenueDto> {
    return this.http.get(`${this.baseUrl}/${venueId}`).toPromise();
  }

  getPublicById(venueId: string): Promise<any> {
    return this.http.get(`${this.baseUrl}/public/${venueId}`).toPromise();
  }

  getMetadataById(venueId: string, metadataId: string): Promise<any> {
    return this.http.get(`${this.baseUrl}/${venueId}/${metadataId}`).toPromise();
  }

  getUserLocations(venueId: string, since: string = null): Promise<any> {
    let url = `${this.baseUrl}/${venueId}/userlocation`;
    if (since) {
      url = `${url}?since=${since}`;
    }
    return this.http.get<any>(url).toPromise();
  }

  getTimezone(venueId: string): VenueTimezone {
    const venue = _.find(this.config.customer.venues, { id: venueId });
    const name: string = venue.timezone;
    const offset: string = moment.tz(name).format('Z');
    const abbreviation: string = moment.tz(name).format('z');

    return {
      name,
      offset,
      abbreviation,
    };
  }
}
