import { Injectable, Inject } from '@angular/core';
import { StateService } from '@uirouter/core';
import mixpanel from 'mixpanel-browser';

import { CR_CONFIG } from '../config';
import { Config } from '../../../core';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private mixpanel: any;

  constructor(private state: StateService, @Inject(CR_CONFIG) private config: Config) {
    if (this.config.mixpanel) {
      this.mixpanel = mixpanel;
      this.mixpanel.init(this.config.mixpanel);
    }
  }

  register(superProperties) {
    if (this.mixpanel) {
      this.mixpanel.reset();
      this.mixpanel.register(superProperties);
    }
  }

  destroy() {
    if (this.mixpanel) {
      this.mixpanel.reset();
    }
  }

  identify(id, userProfile?) {
    if (this.mixpanel) {
      this.mixpanel.identify(id);

      if (userProfile) {
        this.mixpanel.people.set(userProfile);
      }
    }
  }

  track(eventName, data?: any) {
    if (this.mixpanel && this.state.params.venueId !== 'TE2_FEA') {
      if (!data) {
        data = {};
      }

      data.pageName = this.getPageName();
      data.customerId = this.state.params.customerId;
      data.venueId = this.state.params.venueId;
      this.mixpanel.track(eventName, data);
    }
  }

  trackPageView() {
    this.track('Page View', this.state.params);
  }

  getPageName() {
    let pageName;

    if (this.state.params.tabId) {
      pageName = `${this.state.current.name.split('.')[0]} ${this.state.params.tabId}`;
    } else {
      pageName = this.state.current.name;
    }

    return pageName;
  }
}
