import _ from 'lodash';
import text from './resources/locale/en.json';

class EventDetails {
    constructor(
        $state,
        $uibModal,
        crConstants,
        crEntityService,
        crLocaleService,
        crToastService,
        crErrorLoggingService,
        crImageService,
        crNavigationService
    ) {
        this.$state = $state;
        this.$uibModal = $uibModal;
        this.crConstants = crConstants;
        this.crEntityService = crEntityService;
        this.crLocaleService = crLocaleService;
        this.crToastService = crToastService;
        this.crErrorLoggingService = crErrorLoggingService;
        this.crImageService = crImageService;
        this.crNavigationService = crNavigationService;
    }

    $onInit() {
        this.text = text;
        this.entity = {};

        this.attributeTypes = this.crConstants.attributeTypes;

        this.locale = this.crLocaleService.getLocale(this.$state.params.venueId, this.$state.params.locale);

        this.entityType = this.crConstants.entity.types.EVENTS;

        const params = [this.entityType, this.$state.params.id, this.$state.params.venueId, this.locale.current];

        this.getData(...params);

        // See if we need to show a toast when coming to this page
        this.crToastService.autoToast(this.$state.params);
    }

    getData(entityType, id, venueId, locale) {
        this.isLoading = true;

        this.crEntityService
            .getEntity(entityType, id, venueId, locale)
            .then((entity) => {
                this.entity = entity;
                this.entity.mainImageSrc = this.crImageService.getMainImageSrc(entity.images);
                this.entity.stickerSrc = this.crImageService.getStickerSrc(entity.images) || this.entity.mainImageSrc;

                // Sort schedules in priority order
                this.entity.scheduleGroup.schedules = this.sortSchedules(this.entity.scheduleGroup.schedules);

                this.arrivalDisplayValue = this.getArrivalDisplayValue(this.entity.suggestedArrival);

                this.updateLocaleMap(entity, this.locale);

                // always display categories in english
                entity.localization[locale].displayCategories = entity.displayCategories;
                this.getCategoryHierarchyForCategories(
                    'event-categories',
                    venueId,
                    entity.displayCategories,
                    this.locale.default
                );

                this.entity = _.cloneDeep(this.entity);
            })
            .catch((err) => {
                this.crErrorLoggingService.logError(`Could not get ${entityType}`, err, id);

                if (err.status === 404) {
                    this.$state.go('client.404', {
                        customerId: this.$state.params.customerId,
                        venueId,
                    });
                } else {
                    this.hasDataError = true;
                }
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    sortSchedules(schedules) {
        return _.orderBy(schedules, 'priority', 'desc');
    }

    getArrivalDisplayValue(value) {
        const arrivalEnums = this.crConstants.eventArrivalEnums;

        let arrivalDisplayValue = '';

        switch (value) {
            case arrivalEnums.ANYTIME:
                arrivalDisplayValue = this.text.arriveAnytime;
                break;
            case arrivalEnums.ON_TIME:
                arrivalDisplayValue = this.text.arriveOnTime;
                break;
            case arrivalEnums.EARLY:
                arrivalDisplayValue = this.text.arriveEarly;
                break;
        }

        return arrivalDisplayValue;
    }

    localeUpdated(event) {
        this.locale.current = event.locale.id;

        const entityType = this.crConstants.entity.types.EVENTS;
        const { venueId } = this.$state.params;
        const locale = this.locale.current;
        const { id } = this.$state.params;
        const localeModel = this.entity.localization[locale];

        if (!localeModel.attributes || !localeModel.associatedPois) {
            this.crEntityService
                .getEntity(entityType, id, venueId, locale)
                .then((entity) => {
                    this.updateLocaleMap(entity, this.locale);
                })
                .catch((err) => {
                    this.crErrorLoggingService.logError('could not get locale data', err, locale);
                });
        } else {
            this.locale = _.cloneDeep(this.locale);
        }
    }

    updateLocaleMap(entity, locale) {
        const currentLocale = this.entity.localization[locale.current];

        // use default language if localization values are not present.
        if (!currentLocale.description) {
            currentLocale.description = this.entity.localization[locale.default].description;
        }

        if (!currentLocale.title) {
            currentLocale.title = this.entity.localization[locale.default].title;
        }

        if (!currentLocale.callToAction.label && !currentLocale.callToAction.link) {
            currentLocale.callToAction = this.entity.localization[locale.default].callToAction;
        }

        currentLocale.attributes = entity.attributes || [];
        currentLocale.associatedPois = entity.associatedPois;
        currentLocale.navigationTabs = entity.navigationTabs || [];

        if (!_.isEmpty(currentLocale.attributes)) {
            this.crImageService.setIconSrc(currentLocale.attributes);
        }

        this.entity = _.cloneDeep(this.entity);
    }

    getCategoryHierarchyForCategories(entityType, venueId, categories = [], locale) {
        return this.crEntityService
            .getCategoriesWithHierarchy(entityType, venueId, locale)
            .then((hierarchyCategories) => {
                this.entity.localization[locale].hierarchyCategories = categories.map((category) =>
                    _.find(hierarchyCategories, { id: category.id })
                );
            })
            .catch((err) => {
                this.crErrorLoggingService.logError(`Could not get ${entityType}`, err);
            })
            .finally(() => {
                this.categoriesLoading = false;
            });
    }

    // TODO: consolidate deactivate, activate, archive methods into an updateState method

    deactivate() {
        if (this.isLoading) {
            return;
        }

        this.isLoading = true;

        this.crEntityService
            .updateState(this.entityType, this.$state.params.id, { state: this.crConstants.entity.states.INACTIVE })
            .then(() => {
                this.crToastService.toast(`"${this.entity.name}" ${this.text.hasBeenDeactivated}`);
                this.entity.state = this.crConstants.entity.states.INACTIVE;
            })
            .catch(() => this.openModal(`${this.text.problemDeactivating} "${this.entity.name}"`))
            .finally(() => {
                this.isLoading = false;
            });
    }

    activate() {
        if (this.isLoading) {
            return;
        }

        this.isLoading = true;

        this.crEntityService
            .updateState(this.entityType, this.$state.params.id, { state: this.crConstants.entity.states.ACTIVE })
            .then(() => {
                this.crToastService.toast(`"${this.entity.name}" ${this.text.hasBeenActivated}`);
                this.entity.state = this.crConstants.entity.states.ACTIVE;
            })
            .catch(() => this.openModal(`${this.text.problemActivating} "${this.entity.name}"`))
            .finally(() => {
                this.isLoading = false;
            });
    }

    archive() {
        if (this.isLoading) {
            return;
        }

        const resolve = () => {
            this.isLoading = true;

            this.crEntityService
                .deleteEntity(this.entityType, this.$state.params.id)
                .then(() => {
                    this.$state.go('client.events.list', {
                        toast: {
                            msg: `"${this.entity.name}" ${this.text.hasBeenArchived}`,
                        },
                    });
                })
                .catch(() => {
                    this.openModal(`${this.text.problemArchiving} "${this.entity.name}"`);
                    this.isLoading = false;
                });
        };

        this.$uibModal
            .open({
                backdrop: 'static',
                component: 'crSimpleModal',
                windowClass: 'cr-modal-size-sm',
                resolve: {
                    message: () => this.text.archiveModalMessage,
                    type: () => this.crConstants.modalTypes.SUBMIT,
                    submitText: () => this.text.archiveModalSubmit,
                },
            })
            .result.then(resolve)
            .catch(() => null);
    }

    saveCopy() {
        this.$state.go('client.events.create', {
            id: this.$state.params.id,
            customerId: this.$state.params.customerId,
        });
    }

    goToEditPage() {
        this.$state.go('client.events.edit', {
            id: this.$state.params.id,
            customerId: this.$state.params.customerId,
        });
    }

    openModal(message) {
        this.$uibModal
            .open({
                backdrop: 'static',
                component: 'crSimpleModal',
                windowClass: 'cr-modal-size-sm',
                resolve: {
                    message: () => message,
                },
            })
            .result.catch(() => null);
    }

    goBack() {
        this.crNavigationService.goBack('client.events.list');
    }
}

export default EventDetails;
