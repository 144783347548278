import _ from 'lodash';

class ImageModalController {
    constructor($scope, $state, $timeout, crImageService, crAnalyticsService) {
        this.$scope = $scope;
        this.$state = $state;
        this.$timeout = $timeout;
        this.crImageService = crImageService;
        this.crAnalyticsService = crAnalyticsService;
    }

    reset() {
        this.base64DataUrl = null;
        this.file = null;
        this.errorMessage = '';
        this.loading = false;
        this.cropperShown = false;
        this.cropperReady = false;
        this.dimensions = {
            width: null,
            height: null,
        };
    }

    $onInit() {
        this.reset();

        // It's ok if this is undefined when uploading images for non-venue customers
        this.venueId = this.$state.params.venueId;

        const { width } = this.resolve.cropDisplaySize;
        const { height } = this.resolve.cropDisplaySize;

        this.showEvent = 'show';
        this.hideEvent = 'hide';

        this.cropperApi = {};

        this.cropperOptions = {
            background: false,
            center: false,
            modal: false,
            guides: false,
            viewMode: 3,
            minContainerWidth: width,
            minContainerHeight: height,
            minCanvasWidth: width,
            minCanvasHeight: height,
            minCropBoxWidth: width,
            minCropBoxHeight: height,
            dragMode: 'move',
            cropBoxMovable: false,
            cropBoxResizable: false,
            zoomOnWheel: false,
            responsive: false,
            crop: (e) => {
                if (!this.cropperReady) {
                    this.cropperReady = true;
                    this.$scope.$apply();
                }
                const x1 = Math.round(e.detail.x);
                const y1 = Math.round(e.detail.y);
                const x2 = x1 + Math.round(e.detail.width);
                const y2 = y1 + Math.round(e.detail.height);

                this.cropValues = `${x1}x${y1}:${x2}x${y2}`;
            },
        };
    }

    showCropper() {
        this.cropperShown = true;
        this.$scope.$broadcast(this.showEvent);
    }

    hideCropper() {
        this.cropperShown = false;
        this.$scope.$broadcast(this.hideEvent);
    }

    onSubmit() {
        if (!this.isDisabled()) {
            this.uploadImageFile(this.base64DataUrl).then(() => {
                if (this.resolve.imageIndex === null) {
                    this.resolve.targetImages.push(this.newImage);
                } else {
                    this.resolve.targetImages[this.resolve.imageIndex] = this.newImage;
                }
                this.$timeout(this.hideCropper.bind(this));
                this.close();

                const imageTrackingData = _.cloneDeep(this.resolve.imageTrackingData);
                imageTrackingData.minDimensions = this.isMinDimensions();
                imageTrackingData.filename = this.file.name;
                this.crAnalyticsService.track('Add New Image Saved', imageTrackingData);
            });
        }
    }

    onCancel() {
        if (this.upload) {
            this.upload.abort();
        }

        const imageTrackingData = _.cloneDeep(this.resolve.imageTrackingData);
        imageTrackingData.minDimensions = this.base64DataUrl ? this.isMinDimensions() : 'N/A';
        this.crAnalyticsService.track('Add New Image Canceled', imageTrackingData);

        this.dismiss();
    }

    isDisabled() {
        return !this.base64DataUrl;
    }

    isMinDimensions() {
        return this.errorMessage !== this.resolve.text.minimumDimensionsMessage;
    }

    updateErrorMessage(msg) {
        this.errorMessage = msg;
    }

    handleImageFile() {
        const txt = this.resolve.text;

        // Reset any error messages
        this.updateErrorMessage('');

        // Make the file types human readable for error messages
        const readableFileTypes = this.resolve.fileExtensions.replace(/,/g, ', ');

        if (this.file && !this.file.$error) {
            this.loading = true;
            this.getDimensions();
            this.crImageService.getBase64DataUrl(this.file).then((res) => {
                this.base64DataUrl = res;
                this.loading = false;
                this.$timeout(this.showCropper.bind(this));
            });
        } else if (this.file) {
            this.updateErrorMessage(`${txt.fileErrorMessage} ${txt.pleaseTryAgain}`);
        } else {
            this.updateErrorMessage(
                `${txt.invalidFileTypeMessage} ${readableFileTypes} ${txt.maxFileSizeMessage} ${this.resolve.maxFileSize}. ${txt.pleaseTryAgain}`
            );
        }
    }

    uploadImageFile(base64DataUrl) {
        const txt = this.resolve.text;
        this.cropperOptions.disabled = true;
        this.loading = true;

        const filename = this.file.name.replace(/[^a-zA-Z0-9\-_.]/g, '');
        this.upload = this.crImageService.upload(filename, base64DataUrl, this.venueId);

        this.upload
            .then((res) => {
                const src = encodeURI(res.data.src);
                const { width } = this.dimensions;
                const { height } = this.dimensions;
                const crop = this.cropValues;

                this.newImage = {
                    type: this.resolve.imageType,
                    width,
                    height,
                    src,
                    crop,
                };

                this.newImage.displaySrc = this.crImageService.getImageSrc(this.newImage, width, height, crop);
            })
            .catch(() => {
                this.updateErrorMessage(`${txt.problemUploadingMessage} ${this.file.name}. ${txt.pleaseTryAgain}`);
                this.loading = false;
            })
            .finally(() => {
                this.upload = null;
                this.loading = false;
            });

        return this.upload;
    }

    zoomIn() {
        this.zoom(this.resolve.zoomAmount);
    }

    zoomOut() {
        this.zoom(-this.resolve.zoomAmount);
    }

    zoom(amount) {
        this.cropperApi('zoom', [amount]);
    }

    cancelImage() {
        this.reset();
    }

    getDimensions() {
        this.crImageService.getImageDimensions(this.file).then((dims) => {
            this.dimensions.width = dims.width;
            this.dimensions.height = dims.height;

            if (
                this.dimensions.width < this.resolve.minSize.width ||
                this.dimensions.height < this.resolve.minSize.height
            ) {
                this.updateErrorMessage(this.resolve.text.minimumDimensionsMessage);
            }
        });
    }
}

export default ImageModalController;
