import text from './resources/locale/en.json';

class ExperiencePromoter {
    constructor($state, crConstants, crAnalyticsService, crFeatureFlagsService) {
        this.$state = $state;
        this.crConstants = crConstants;
        this.crAnalyticsService = crAnalyticsService;
        this.crFeatureFlagsService = crFeatureFlagsService;

        this.text = text;
    }

    $onInit() {
        this.createStates = {
            automations: `${this.text.create} ${this.text.automation}`,
            broadcasts: `${this.text.create} ${this.text.broadcast}`,
        };

        this.overflowItems = [
            {
                name: this.text.coupons,
                state: 'client.experience-promoter.coupons-list',
            },
        ];
    }

    get dashboardTitle() {
        return this.crFeatureFlagsService.hasFeature('live_branded_map')
            ? this.text.liveBrandedMap
            : this.text.dashboard;
    }

    goToCreatePage() {
        this.crAnalyticsService.track(`Add new ${this.$state.params.tabId} experience`);
        if (this.$state.params.tabId === 'automations') {
            this.$state.go('client.experience-promoter.automation-select', { tabId: this.$state.params.tabId });
        } else if (this.$state.params.tabId === 'broadcasts') {
            this.$state.go('client.experience-promoter.broadcast-select', { tabId: this.$state.params.tabId });
        }
    }
}

export default ExperiencePromoter;
