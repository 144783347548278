function LayoutService() {
    let isSlim = true;

    this.getSlim = () => isSlim;

    this.setSlim = (slim) => {
        isSlim = slim;
    };
}

export default LayoutService;
