import controller from './experience-list.controller';
import template from './experience-list.html';

import './experience-list.less';

const component = {
    template,
    bindings: {
        experienceGroupType: '@',
    },
    controller: [
        '$state',
        '$sce',
        'crErrorLoggingService',
        'crAnalyticsService',
        'crToastService',
        'crLocaleService',
        'crConfig',
        'crConstants',
        'crExperienceListService',
        'crEntitySelectionManager',
        'crExperiencesService',
        controller,
    ],
};

export default component;
