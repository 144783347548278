import { Component, OnInit } from '@angular/core';
import { StateService } from '@uirouter/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  NavigationService,
  OrderService,
  UserService,
  SimpleModalComponent,
  ErrorLoggingService,
} from '../../../../shared';

import text from './resources/locale/en.json';
import { LocalizedText, ValidationMessages } from '../../../../core';
import { Order, OrderReceiptLineItem, FeesCategory } from '../../../../shared/models/order.model';
import { RefundType } from '../../../../shared/enums/refund';
import { ConfigurationService } from '../../../../shared/services/configuration/configuration.service';
import { ConfigurationProfile } from '../../../../shared/services/configuration/configuration.model';

interface RefundOption {
  label: string,
  value: string
}

@Component({
  selector: 'cr-fnb-order-refund',
  templateUrl: './order-refund.component.html',
  styleUrls: ['./order-refund.component.scss'],
})
export class OrderRefundComponent implements OnInit {
  hasError: boolean;

  isLoading: boolean;

  text: LocalizedText;

  order?: Order;

  orderLineItems?: OrderReceiptLineItem[];

  refundTypeSelected: RefundType = RefundType.entire;

  refundType = RefundType;

  refundValidationMessages: ValidationMessages;

  isRefunding = false;

  hidden = false;

  isInputValid = false;

  partialRefundAmountControl: FormControl;

  refundForm: FormGroup;

  users: any = [];

  authorizationUser = '';

  authorizationCode = '';

  sendAdditionalEmail = true;
  
  printRefundCopy = true;

  additionalEmail = '';

  receiptForm = new FormGroup({});

  sendAdditionalEmailControl = new FormControl('', Validators.email);

  sendAdditionalEmailCheckboxControl = new FormControl(false);
  
  printRefundCopyControl = new FormControl(false);

  refundReasonOptions: RefundOption[] = [];

  refundReasonSelection = '';

  refundReasonOther = '';

  feesCategory: FeesCategory = {
    taxable: [],
    nonTaxable: []
  };

  errors: { [key :string]: boolean } = {};

  constructor(
    private state: StateService,
    private navigationService: NavigationService,
    private orderService: OrderService,
    private modalService: BsModalService,
    private userService: UserService,
    private errorLoggingService: ErrorLoggingService,
    private configurationService: ConfigurationService,
  ) {
    this.isLoading = true;
    this.text = text as LocalizedText;
  }

  ngOnInit(): void {
    this.partialRefundAmountControl = new FormControl(0);
    this.refundForm = new FormGroup({ partialRefundAmountControl: this.partialRefundAmountControl });
    this.loadOrder();
    this.loadUsersWhoCanRefund();
    this.loadRefundReasons();
  }

  loadUsersWhoCanRefund(): void {
    const { venueId } = this.state.params;
    const role = 'fnb_refunds';
    this.userService
      .getUsersByRole(venueId, role)
      .then((users: { displayName: string }[]) => {
        this.users = users.sort((a, b) => (a.displayName > b.displayName ? 1 : -1));
      })
      .catch((err) => {
        this.hasError = true;
        this.errorLoggingService.logError('Could not retrieve authorizers user list', err);
      });
  }

  initializeForm(): void {
    this.partialRefundAmountControl.setValue('00.00');
    this.partialRefundAmountControl.setValidators([
      Validators.required,
      Validators.max(this.order.orderTotal),
      Validators.min(0),
    ]);
  }

  loadOrder(): void {
    this.orderService
      .getOrderById(this.state.params.orderId)
      .then((order) => {
        if (order) {
          this.order = order;
          this.splitOrderItems();
          this.hasError = false;
          this.categorizeFees(this.order);
        } else {
          this.hasError = true;
          this.errorLoggingService.logError('Could not locate order in results');
        }
        this.isLoading = false;
      })
      .catch((err) => {
        this.hasError = true;
        this.errorLoggingService.logError('Could not retrieve order', err);
      })
      .finally(() => {
        this.initializeForm();
        this.isLoading = false;
      });
  }

  private categorizeFees(order: Order) {
    this.feesCategory.taxable = order.fees.filter(f => f.taxable);
    this.feesCategory.nonTaxable = order.fees.filter(f => !f.taxable);
  }

  splitOrderItems(): void {
    this.orderLineItems = [];
    this.order.orderReceipt.lineItems.forEach((lineItem) => {
      for (let i = 0; i < lineItem.quantity; i += 1) {
        this.orderLineItems.push(lineItem);
      }
    });
  }

  toggleHidden(): void {
    this.hidden = !this.hidden;
  }

  get canSubmit(): boolean {
    return !this.isRefunding;
  }

  onRefundReasonChange(value: string): void {
    this.refundReasonSelection = value;
    this.onValidateRefundReason();
    if (this.refundReasonSelection !== 'OTHER') {
      this.refundReasonOther = '';
    }
  }

  selectAuthorizer(event: { target: { value: string } }): void {
    this.authorizationUser = event.target.value;
    this.onValidateAuthorizer();
  }

  onAuthCodeChanged(event: { target: { value: string } }): void {
    this.authorizationCode = event.target.value;
  }

  onRefundReasonOtherChanged(event: { target: { value: string } }): void {
    this.refundReasonOther = event.target.value;
  }

  onValidateRefundReason(): void {
    this.errors.reason = this.refundReasonSelection.length === 0;
  }

  onValidateAuthorizer(): void {
    this.errors.username = this.authorizationUser.length === 0;
  }

  onValidateAuthCode(): void {
    this.errors.authorizationCode = this.authorizationCode.length === 0;
    this.errors.authorizationCodeValidation = !this.errors.authorizationCode
      && this.authorizationCode.length < 6;
  }

  onValidateRefundReasonOther(): void {
    if (this.refundReasonSelection === 'OTHER') {
      this.errors.refundReasonOther = this.refundReasonOther.length === 0;
    }
  }

  onValidateAdditionalEmail(): void {
    if (this.sendAdditionalEmailCheckboxControl.value === true) {
      this.sendAdditionalEmailControl.markAsTouched();
      this.additionalEmail = this.sendAdditionalEmailControl.value as string;
    } else {
      this.additionalEmail = '';
    }
  }

  validateAll(): boolean {
    this.onValidateRefundReason();
    this.onValidateAuthorizer();
    this.onValidateAuthCode();
    this.onValidateRefundReasonOther();
    this.onValidateAdditionalEmail();
    return (
      this.errors.reason
            || this.errors.username
            || this.errors.authorizationCode
            || this.errors.authorizationCodeValidation
            || this.errors.refundReasonOther
            || !this.sendAdditionalEmailControl.valid
            || !this.partialRefundAmountControl.valid
    );
  }

  calculateRefundAmount(): number {
    if (this.refundTypeSelected === this.refundType.entire) {
      return this.order.orderTotal;
    }
    return this.partialRefundAmountControl.value as number;
  }

  doRefund(): void {
    if (this.validateAll()) {
      // Validation errors
      return;
    }

    if (!this.isRefunding) {
      this.isRefunding = true;

      const refundReason = this.refundReasonOther || this.refundReasonSelection;
      this.orderService
        .refundOrder(
          this.state.params.kitchenId,
          this.order.orderId,
          this.calculateRefundAmount(),
          refundReason,
          this.sendAdditionalEmailControl.value,
          this.printRefundCopyControl.value,
          this.authorizationUser,
          this.authorizationCode,
        ).pipe(catchError((err) => of(this.handleRefundError(err)))).subscribe(() => {
          this.onRefundComplete();
        });
    }
  }

  private handleRefundError(err: HttpErrorResponse): void {
    console.error('Had a problem refunding the order');
    this.isRefunding = false;
    if (err.status === 401) {
      this.showOrderErrorModal(this.text.refundError401);
    } else {
      this.showOrderErrorModal(this.text.refundErrorAny);
    }
  }

  showOrderErrorModal(message: string): void {
    this.modalService.show(SimpleModalComponent, {
      initialState: { message },
      backdrop: 'static',
      class: 'cr-modal-size-sm',
    });
  }

  onBack(): void {
    const { kitchenId, state, orderId } = this.state.params;
    // trusting the state
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this.navigationService.goBack('client.fnb-order-queue.order-list', { kitchenId, state, orderId });
  }

  onRefundComplete(): void {
    const { kitchenId, orderId } = this.state.params;

    // trusting the state
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this.navigationService.goBack('client.fnb-order-queue.order-list', { kitchenId, state: 'REFUNDED', orderId });
  }

  private loadRefundReasons(): void {
    this.configurationService.getConfigurationForRefundReasons(
      {
        venueId: this.state.params.venueId as string,
        placeId: this.state.params.kitchenId as string,
      },
    )
      .then((configProfile: ConfigurationProfile) => {
        this.refundReasonOptions = [
          ...this.mapConfigProfileToReasonOption(configProfile),
          {
            label: this.text.reasons.OTHER as string,
            value: 'OTHER',
          },
        ];
      })
      .catch((err: Error) => {
        this.errorLoggingService.logError('Error loading refund reasons', err);
      });
  }

  private mapConfigProfileToReasonOption(configProfile: ConfigurationProfile): RefundOption[] {
    const configOptions: {
      label?: string,
      code?: string
    }[] = [];

    Object.keys(configProfile.configuration).forEach((key: string) => {
      const parts = key.split('.');
      const name = parts[2];
      const value = configProfile.configuration[key];
      const index = Number(parts[1]);
      if (!configOptions[index]) {
        configOptions[index] = {};
      }
      configOptions[index][name] = value;
    });

    return configOptions.map((option) => ({
      label: `${option.label}`,
      value: option.code,
    }));
  }
}
