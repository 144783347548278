import _ from 'lodash';

class BeaconsListService {
    constructor(crBeaconsService) {
        this.crBeaconsService = crBeaconsService;
    }

    getData(params) {
        const queryParams = _.pickBy(params, (value) => !_.isNil(value) && value !== '' && value !== 'all');

        return this.crBeaconsService.getBeacons(queryParams).then((res) => res);
    }

    mapToRowsModel(beacons) {
        let row;
        return beacons.map((beacon) => {
            row = {
                data: {
                    id: beacon.id,
                    name: {
                        label: beacon.name,
                    },
                    zone: {
                        label: beacon.zone,
                    },
                    uuid: {
                        label: beacon.uuid,
                    },
                    major: {
                        label: beacon.major,
                    },
                    minor: {
                        label: beacon.minor,
                    },
                    associatedPoi: beacon.associatedPoi ? beacon.associatedPoi.name : '',
                    originalData: beacon,
                },
            };

            return row;
        });
    }
}

export default BeaconsListService;
