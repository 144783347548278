import { Injectable } from '@angular/core';
import { countBy } from 'lodash';

@Injectable({
    providedIn: 'root',
  })
export class ValidationBuilderService {
    isUnique(collection: any, property): (prevValue, nextVal) => boolean {
        return (prevValue: any, newValue: any) => {
            const countMap = countBy(typeof collection === 'function' ? collection() : collection, (row) =>
                property ? row[property] : row
            );

            return !newValue || !countMap[newValue] || newValue === prevValue;
        };
    }
}
