import angular from 'angular';

import VenueHoursEditOrderModule from './venue-hours-edit-order/venue-hours-edit-order';
import VenueHoursManageModule from './venue-hours-manage/venue-hours-manage.js';
import VenueHoursOrderModule from './venue-hours-order/venue-hours-order';
import VenueHoursPhonePreviewModule from './venue-hours-phone-preview/venue-hours-phone-preview';

const Components = angular.module('control-room.venue-hours.components', [
    VenueHoursEditOrderModule.name,
    VenueHoursManageModule.name,
    VenueHoursOrderModule.name,
    VenueHoursPhonePreviewModule.name,
]);

export default Components;
