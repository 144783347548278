import { Component, Input } from "@angular/core";
import { LocalizedText } from "../../../../../core";
import text from '../../../resources/locale/en.json';
import { ParkHourStatus, WeekDays } from "../../../constants/statuses";
import { VenueHours } from "../../../constants/hours.types";
import { DatePipe } from "@angular/common";

@Component({
    selector: 'cr-venue-hours-drop-down',
    templateUrl: './venue-hours-drop-down.component.html',
    styleUrls: ['./venue-hours-drop-down.component.scss']
})
export class VenueHoursDropDownComponent {
    @Input()
    venueHours: {
        label: string;
        venueHours: VenueHours[];
    };

    label: string;
    isOpen = false;
    text: LocalizedText;
    days: string[] = []

    constructor(
        private datePipe: DatePipe
    ) {
        this.text = text as LocalizedText
        this.label = ""
        this.days = WeekDays
    }

    getDateByDay(day: string) {
        const hours = this.venueHours.venueHours.find((hours) => {
            return hours.day === day
        })
        return hours.date
    }

    getHoursByDay(day: string) {
        const hours = this.venueHours.venueHours.find((hours) => {
            return hours.day === day
        })
        const formattedStartTime = this.datePipe.transform(hours.startDate, 'shortTime');
        const formattedEndTime = this.datePipe.transform(hours.endDate, 'shortTime');

        return hours.statusLabel === ParkHourStatus.OPEN
            ? `${formattedStartTime} - ${formattedEndTime}`
            : "CLOSED"
    }
}
