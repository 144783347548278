import angular from 'angular';
import _ from 'lodash';

import text from './resources/locale/en.json';

const modeTypes = {
    select: 'select',
    filter: 'filter',
    chip: 'chip',
};

class DropdownController {
    constructor($state, $element, $window, crConstants, crAnalyticsService) {
        this.$state = $state;
        this.$element = $element;
        this.$window = $window;
        this.crConstants = crConstants;
        this.crAnalyticsService = crAnalyticsService;

        this.text = text;
    }

    $onInit() {
        this.keys = this.crConstants.keys;
        this.modeTypes = modeTypes;
        this.searchTerm = '';

        this.isFormElem = this.form && this.name;

        if (this.mode !== modeTypes.filter) {
            this.hideResetOption = !(this.hideResetOption === false);
        }
    }

    $onChanges(changes) {
        if (changes && changes.menuItems) {
            if (changes.menuItems.currentValue) {
                this.setInitialState();
            } else {
                this.currentSelectionText = '';
                this.isLoading = true;
            }
        }

        if (changes && this.hasDataError) {
            this.isLoading = false;
        }
    }

    sortItems(items, dir) {
        if (dir) {
            if (dir === this.crConstants.sort.ASC || dir === this.crConstants.sort.DESC) {
                items = _.sortBy(items, (item) => {
                    const displayValue = this.getDisplayValue(item);
                    return displayValue ? displayValue.toLowerCase() : null;
                });

                if (dir === this.crConstants.sort.DESC) {
                    items = items.reverse();
                }
            }
        }

        return items;
    }

    setInitialState() {
        this.displayKey = this.displayKey || 'label';
        this.secondaryDisplayKey = this.secondaryDisplayKey || null;
        this.subtextDisplayKey = this.subtextDisplayKey || null;
        this.resetFilter = { id: 'all', label: this.text.all };

        this.menuItems = this.sortItems(this.menuItems, this.sort);

        this.filteredMenuItems = this.menuItems;

        if (this.showStatus) {
            this.hasStatusWarning = _.some(this.menuItems, 'hasStatusWarning');
        }

        const deepLink = this.$state.params[this.routeQueryId];

        if (deepLink) {
            this.handleDeepLink(deepLink);
        } else {
            this.model = _.filter(this.menuItems, { isSelected: true });
            this.updateRoute(this.model);
        }

        this.model = !_.isEmpty(this.model) ? this.model : null;

        this.setCurrentSelection();

        this.isLoading = false;
    }

    handleDeepLink(param) {
        this.model = [];

        this.menuItems.forEach((item) => {
            if (_.includes(param.split(','), item.id)) {
                item.isSelected = true;
                this.model.push(item);
            } else {
                item.isSelected = false;
            }
        });
    }

    updateRoute(model) {
        if (this.routeQueryId) {
            const currentState = this.$state.current.name;
            this.$state.params[this.routeQueryId] = _.map(model, 'id').join(',');
            this.$state.go(currentState, this.$state.params, {
                location: 'replace',
            });
        }
    }

    update(model) {
        this.updateRoute(model);
        this.setCurrentSelection();
        this.handleChipMode();
        this.onSelect({
            $event: {
                model,
            },
        });

        this.handleFormValidation();
        this.trackFilterChange(model);
    }

    trackFilterChange(model) {
        if (this.mode === modeTypes.filter && !this.disableTracking) {
            this.crAnalyticsService.track('Filter View', {
                filterName: this.routeQueryId,
                filterValue: _.map(model, (item) => this.getDisplayValue(item)).join(',') || this.text.all,
            });
        }
    }

    handleChipMode() {
        if (this.mode === modeTypes.chip) {
            this.selectedClass = this.model[0].id;
        }
    }

    handleFormValidation() {
        if (_.isEmpty(this.model)) {
            this.model = null;
        }
    }

    itemSelected(item, e) {
        if (item.isDisabled) {
            e.stopPropagation();
            return;
        }

        if (this.isMultiSelect) {
            this.handleMultiSelect(item);
        } else {
            this.handleSingleSelect(item);
        }

        this.resetFilter.isSelected = false;

        if (this.form && !this.ignoreDirty) {
            this.form.$setDirty();
        }
    }

    resetFilterSelected(e) {
        if (e) {
            e.stopPropagation();
        }

        this.isOpen = false;
        this.menuItems.forEach((item) => {
            item.isSelected = false;
        });
        this.model = [];
        this.update(this.model);
    }

    handleMultiSelect(item) {
        item.isSelected = !item.isSelected;
        this.model = _.filter(this.menuItems, { isSelected: true });

        this.update(this.model);
    }

    handleSingleSelect(item) {
        this.isOpen = false;

        this.menuItems.forEach((menuItem) => {
            menuItem.isSelected = false;
        });

        item.isSelected = true;
        this.model = [item];
        this.update(this.model);
    }

    unsetSelectedIndex() {
        const index = this.hideResetOption ? -1 : -2;

        this.selectedIndex = index;
        this.filteredIndex = index;
        this.selectedItem = null;
    }

    resetSearchTerm() {
        this.filterMenuItems();
    }

    onToggle(isOpen) {
        this.isOverflowing = false;
        this.isShowing = false;

        if (!isOpen && this.searchTerm) {
            this.resetSearchTerm();
        }

        if (this.isDisabled) {
            this.isOpen = false;
            return;
        }

        if (!isOpen) {
            this.unsetSelectedIndex();
        }

        if (!isOpen && this.isFormElem && !this.form[this.name].$touched) {
            this.form[this.name].$setTouched();
        }

        if (isOpen && this.showSearch) {
            this.$element[0].querySelector('cr-search input').focus();
        }

        if (isOpen) {
            const dropdown = this.$element[0].querySelector('.dropdown-menu').getBoundingClientRect();
            this.isOverflowing = dropdown.y + dropdown.height > this.$window.innerHeight;
            this.isShowing = true;
        }
    }

    onSelectMousedown() {
        this.focusFromMouse = true;
    }

    onSelectFocus() {
        if (!this.focusFromMouse && !this.ignoreNextFocus) {
            this.onToggle(true);
            this.isOpen = true;

            this.selectFirstMenuItem();
        }

        this.focusFromMouse = false;
        this.ignoreNextFocus = false;

        // touch provided upgraded form control
        if (this.upgradedFormControl) {
            this.upgradedFormControl.markAsTouched(true);
        }
    }

    onItemMouseover(item) {
        if (item.isDisabled) {
            return;
        }

        this.keyboardWasLastMenuEvent = false;

        const filteredItems = this.menuItems.filter((menuItem) => !menuItem.isDisabled);

        this.filteredIndex = _.findIndex(filteredItems, (fitem) => fitem === item);

        this.selectedItem = filteredItems[this.filteredIndex];
        this.selectedIndex = _.findIndex(this.menuItems, (menuItem) => menuItem === this.selectedItem);
    }

    onItemMouseout() {
        this.unsetSelectedIndex();
    }

    handleSubmitKey() {
        if (this.isOpen && this.selectedIndex === -1 && !this.hideResetOption) {
            this.resetFilterSelected();

            this.ignoreNextFocus = true;
            this.$element[0].querySelector('.current-selection').focus();
        } else if (this.isOpen && this.selectedItem) {
            if (this.isMultiSelect) {
                this.handleMultiSelect(this.selectedItem);
            } else {
                this.handleSingleSelect(this.selectedItem);

                this.ignoreNextFocus = true;
                this.$element[0].querySelector('.current-selection').focus();
            }
        } else {
            this.onToggle(true);
            this.isOpen = true;

            this.selectFirstMenuItem();
        }
    }

    handleArrowKey($event) {
        const filteredItems = this.menuItems.filter((item) => !item.isDisabled);

        this.keyboardWasLastMenuEvent = true;

        if (!this.isOpen) {
            this.onToggle(true);
            this.isOpen = true;
        }

        this.filteredIndex = Math.max(
            this.hideResetOption ? 0 : -1,
            Math.min(filteredItems.length - 1, this.filteredIndex + ($event.keyCode === this.keys.ARROW_DOWN ? 1 : -1))
        );

        this.selectedItem = filteredItems[this.filteredIndex];
        this.selectedIndex = _.findIndex(this.menuItems, (item) => item === this.selectedItem);

        this.scrollToMenuItem();
    }

    // TODO: Completely refactor keyboard navigation on our pages with a well designed system
    onKeydown($event) {
        if ($event.keyCode === this.keys.ENTER) {
            $event.preventDefault();

            this.handleSubmitKey($event);
        } else if ($event.keyCode === this.keys.ESCAPE) {
            this.$element[0].querySelector('.current-selection').focus();
        } else if ($event.keyCode === this.keys.TAB) {
            this.onToggle(false);
            this.isOpen = false;
        } else if ($event.keyCode === this.keys.ARROW_UP || $event.keyCode === this.keys.ARROW_DOWN) {
            $event.preventDefault();

            this.handleArrowKey($event);
        }
    }

    selectFirstMenuItem() {
        const filteredItems = this.menuItems.filter((item) => !item.isDisabled);

        this.filteredIndex = this.hideResetOption ? 0 : -1;

        this.selectedItem = this.hideResetOption ? filteredItems[0] : null;

        this.selectedIndex = !this.hideResetOption
            ? -1
            : _.findIndex(this.menuItems, (item) => item === this.selectedItem);
    }

    scrollToMenuItem() {
        const dropdown = this.$element[0].querySelector('ul.dropdown-menu');
        const dropdownItem = dropdown.querySelector(
            `ul.dropdown-menu li:nth-child(${
                this.selectedIndex + (this.showSearch ? 3 : 1) + (this.hideResetOption ? 0 : 2)
            })`
        );

        if (this.selectedItem && dropdownItem) {
            const dropdownItemBottom = dropdownItem.offsetTop + dropdownItem.offsetHeight;
            const scrollBottom = dropdown.offsetHeight + dropdown.scrollTop;

            if (dropdownItemBottom > scrollBottom) {
                if (this.selectedIndex === this.menuItems.length - 1) {
                    dropdown.scrollTop = dropdown.scrollHeight;
                } else {
                    dropdown.scrollTop = dropdownItemBottom - dropdown.offsetHeight;
                }
            } else if (this.selectedIndex === 0 && this.hideResetOption) {
                dropdown.scrollTop = 0;
            } else if (dropdownItem.offsetTop < dropdown.scrollTop) {
                dropdown.scrollTop = dropdownItem.offsetTop;
            }
        } else if (this.selectedIndex === -1) {
            dropdown.scrollTop = 0;
        }
    }

    filterMenuItems(term = '') {
        this.searchTerm = term;
        this.filteredMenuItems = _.filter(this.menuItems, (item) => {
            const displayValue = this.getDisplayValue(item);
            return displayValue ? _.includes(displayValue.toLowerCase(), term.toLowerCase()) : false;
        });
    }

    setCurrentIcon() {
        if (!this.isMultiSelect && !_.isEmpty(this.model)) {
            this.currentIcon = this.model[0].iconSrc;
        }
    }

    setCurrentSelectionText() {
        if (_.isEmpty(this.model)) {
            this.resetFilter.isSelected = true;
            this.currentSelectionText =
                this.mode === 'filter' && !this.hideResetOption ? this.resetFilter.label : `${this.placeholderText}`;
        } else {
            this.currentSelectionText = _.map(this.model, (item) => this.getDisplayValue(item)).join(', ');
        }
    }

    setCurrentSelection() {
        this.setCurrentIcon();
        this.setCurrentSelectionText();
        setTimeout(() => this.handleTooltip(), 0);
    }

    handleTooltip() {
        if (this.mode === modeTypes.select) {
            const $elem = this.$element[0].querySelector('.selection-text');

            const $copy = angular
                .element($elem)
                .clone()
                .css({ display: 'inline-block', width: 'auto', maxWidth: 'none', visibility: 'hidden' });

            $elem.appendChild($copy[0]);

            this.enableTooltip = $copy[0].offsetWidth >= $elem.offsetWidth;

            $copy[0].remove();
        }
    }

    getToolTipString(item) {
        if (!item.tooltip || !item.tooltip.length) {
            return null;
        }

        return _.map(item.tooltip, (tooltip) => this.getDisplayValue(tooltip)).join(', ');
    }

    crValidation(value) {
        return this.crValidator ? this.crValidator(value) : true;
    }

    getDisplayValue(item) {
        const keys = this.displayKey.split('.');
        let val = item;
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            val = val[key];

            if (_.isNil(val)) {
                break;
            }
        }

        return val;
    }

    getSecondaryDisplayValue(item) {
        const keys = this.secondaryDisplayKey.split('.');
        let val = item;
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            val = val[key];

            if (_.isNil(val)) {
                break;
            }
        }

        return val;
    }

    getSubtextDisplayValue(item) {
        const keys = this.subtextDisplayKey.split('.');
        let val = item;
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            val = val[key];

            if (_.isNil(val)) {
                break;
            }
        }

        return val;
    }

    get noResultsFound() {
        return this.noResultsMessage || this.text.noResultsFound;
    }

    get placeholderText() {
        return this.placeholder || this.text.select;
    }
}

export default DropdownController;
