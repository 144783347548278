import * as _ from 'lodash';
import moment from 'moment';
import { Injectable, Inject, OnDestroy } from '@angular/core';
import { StateService } from '@uirouter/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CR_CONSTANTS, ReportsService, TagService } from '../../../shared';
import { Constants } from '../../../core';

// TODO Move mapbox filter construction to this service
@Injectable()
export class LiveMapService implements OnDestroy {
  intervalId: any;

  lastUpdated: Observable<string>;

  metrics: Observable<any>;

  tags: Observable<any>;

  tagValues: any;

  users: any[];

  private lastUpdated$: BehaviorSubject<string>;

  private metrics$: BehaviorSubject<any>;

  private tags$: BehaviorSubject<any>;

  constructor(
    @Inject(CR_CONSTANTS) private constants: Constants,
    private reportsService: ReportsService,
    private state: StateService,
    private tagService: TagService,
  ) {
    this.lastUpdated$ = new BehaviorSubject<string>(moment().format(this.constants.date.format.dateAtTime));
    this.lastUpdated = this.lastUpdated$.asObservable();
    this.metrics$ = new BehaviorSubject<any>({});
    this.metrics = this.metrics$.asObservable();
    this.tags$ = new BehaviorSubject<any>([]);
    this.tags = this.tags$.asObservable();

    this.loadData();
    this.loadTags();
    this.intervalId = setInterval(() => {
      this.loadData();
    }, 60000);
  }

  ngOnDestroy() {
    clearInterval(this.intervalId);
  }

  isValuePresent(value) {
    return this.tagValues[value];
  }

  loadData() {
    Promise.all([
      this.reportsService.getVenueUsers(this.state.params.venueId),
      this.reportsService.getVenueConversions(this.state.params.venueId, {
        start: moment().startOf('day').format(this.constants.date.format.yearFirstDate),
        end: moment().format(this.constants.date.format.yearFirstDate),
      }),
    ]).then(([venueUsers, venueConversions]: any[]) => {
      this.lastUpdated$.next(moment().format(this.constants.date.format.dateAtTime));
      this.metrics$.next({
        currentUsers: venueUsers ? venueUsers.usersInVenue : 0,
        totalUsers: venueUsers ? venueUsers.uniqueUsers : 0,
        experiencesSent: venueConversions.sent,
      });
    });
  }

  loadTags() {
    const customerParams = {
      level: 'CUSTOMER',
      supportsSegmentation: true,
    };
    const venueParams = {
      ...customerParams,
      level: 'VENUE',
      venueId: this.state.params.venueId,
    };
    Promise.all([
      this.tagService.getTagDefinitionsByLevel(customerParams),
      this.tagService.getTagDefinitionsByLevel(venueParams),
    ]).then(([customerTags, venueTags]: any[]) => {
      const tags = customerTags.concat(venueTags);
      tags.forEach((tag: any) => {
        tag.id = tag.tagKey;
        tag.values.forEach((value: any) => {
          value.id = value.tagValueKey;
        });
      });
      this.tags$.next(tags);
    });
  }

  setUsers(users: any[]) {
    this.users = users;
    this.tagValues = this.users.reduce((values, user) => {
      _.forEach(user.properties, (value) => {
        values[value] = true;
      });
      return values;
    }, {});
  }
}
