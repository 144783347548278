import angular from 'angular';

import EntitySelectionManagerService from './entity-selection-manager.service';

const EntitySelectionManager = angular
    .module('control-room.commons.content.services.entity-selection-manager', [])
    .service('crEntitySelectionManager', [
        '$q',
        '$state',
        '$uibModal',
        'crConstants',
        'crEntityService',
        'crPolicyService',
        'crAnalyticsService',
        'crEntityLocalizationService',
        EntitySelectionManagerService,
    ]);

export default EntitySelectionManager;
