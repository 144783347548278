import Controller from './poi-phone-preview.controller';
import template from './poi-phone-preview.html';
import './poi-phone-preview.less';

const Component = {
    bindings: {
        poi: '<',
        manage: '<?',
        locale: '<?',
    },
    template,
    controller: ['crImageService', Controller],
};

export default Component;
