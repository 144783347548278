import {
    Directive, ElementRef, EventEmitter, Injector, Input, Output
  } from '@angular/core';
  import { UpgradeComponent } from '@angular/upgrade/static';
  
  @Directive({
    selector: 'cr-kitchen-details',
  })
  export class KitchenDetailsComponent extends UpgradeComponent {
  
    @Input() kitchenid?: string;

    @Output() onBack: EventEmitter<any>;

    @Output() onEdit: EventEmitter<any>;

    @Output() onArchive: EventEmitter<any>;

    constructor(elementRef: ElementRef, injector: Injector) {
      super('crKitchenDetails', elementRef, injector);
    }
  }
  