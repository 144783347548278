import template from './input.html';
import './input.less';
import InputController from './input.controller';

const InputComponent = {
    template,
    require: {
        form: '^form',
    },
    bindings: {
        name: '@',
        model: '<',

        type: '@?',
        crRequired: '<?',
        maxlength: '@?',
        autofocus: '<?',
        customClass: '@?',
        modelOptions: '<?',
        hideValidation: '<?',
        placeholder: '@?',
        restrictPattern: '@?',
        readonly: '@?',

        validators: '<?',

        onUpdate: '&?',
        onKeypress: '&?',
        onChange: '&?',
        isDisabled: '<?',
    },
    controller: ['crConstants', InputController],
};

export default InputComponent;
