import {
  Component, OnDestroy, OnInit, TemplateRef, ViewEncapsulation
} from '@angular/core';
import { StateService } from '@uirouter/core';
import bulletTrain from 'bullet-train-client';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { debounceTime, take, tap } from 'rxjs/operators';
import {
  NavigationService,
  OrderService,
  SimpleModalComponent
} from '../../../../shared';
import { PolicyService } from '../../../../shared/services/policy/policy.service';
import { PrinterService } from '../../../../shared/services/printer/printer.service';

import { LocalizedText } from '../../../../core';
import { asciiContains, normalize } from '../../../../shared/helpers/string-normalizer';
import { Kitchen } from '../../../../shared/models/kitchen.model';
import { Order, OrderFulfillmentParams } from '../../../../shared/models/order.model';
import { KitchenService } from '../../../../shared/services/kitchen/kitchen.service';
import { PTWCapacity } from '../../../../shared/services/ptw/ptw.model';
import { PTWService } from '../../../../shared/services/ptw/ptw.service';
import { fnbRoutes } from '../../constants/routes';
import text from './resources/locale/en.json';

interface FilterCount {
  UNFULFILLED?: number;
  IN_PROCESS?: number;
  FULFILLED?: number;
  REFUNDED?: number;
  SUBMITTED?: number;
}

interface TextSearch {
  kind: 'text';
  weight: number;
  value: string;
}

const creditCardPattern = /^[0-9]{4}$/;

interface CreditCardSearch {
  kind: 'credit-card';
  weight: number;
  lastFour: string;
}

const orderTotalPattern = /^\$?[0-9]+(\.[0-9]{2})?\$?$/;
const stripDollarSign = /^\$?(.+)\$?$/g;
const currencySymbolPattern = /\$|\./;

interface OrderTotalSearch {
  kind: 'order-total';
  weight: number;
  amount: number;
}

interface ModalConfig {
    animated?: boolean,
    keyboard?: boolean,
    backdrop?: boolean,
    ignoreBackdropClick?: boolean,
    class?: string
}

type SearchFilter = TextSearch | CreditCardSearch | OrderTotalSearch;

const AUTO_REFRESH_TIME_MS = 5 * 60 * 1000;

@Component({
  selector: 'cr-fnb-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OrderListComponent implements OnInit, OnDestroy {
  hasError: boolean;

  isLoading: boolean;

  isRefreshing = false;

  allPTW: PTWCapacity = {
      allocation: 0,
      capacity: 0,
      startTime: 'ALL',
      endTime: 'never',
      windowId: '0',
      selected: true
    };

  selectedPTW: PTWCapacity;

  ptwFilterText = 'ALL'

  ptws: PTWCapacity[] = null;

  isWriteAllowed = false;

  isPrintingEnabled = false;

  text: LocalizedText;

  kitchen?: Kitchen;

  orders: Order[] = [];

  ordersFiltered: Order[] = [];

  selectedTab: string;

  counts: FilterCount = {};

  lastUpdated: Date = new Date();

  now: number = Date.now();

  intervalId: number;

  modalRef?: BsModalRef;

  orderMeta = {};

  editingOrderId?: string;

  exportFilename: string;

  isSearching: boolean;

  searchQuery = '';

  searchSub = new Subject<string>();

  isRefundsEnabled: boolean = bulletTrain.hasFeature('refunds');

  isPtwEnabled: boolean = bulletTrain.hasFeature('fnb_ptw');

  sortTypeAsc = true;

  ptwOverrideModalConfig : ModalConfig = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: false,
    class: "ptw-modal"
  }

  

  constructor(
    private state: StateService,
    private navigationService: NavigationService,
    private kitchenService: KitchenService,
    private orderService: OrderService,
    private modalService: BsModalService,
    private policyService: PolicyService,
    private printerService: PrinterService,
    private ptwService: PTWService
  ) {
    this.isLoading = true;
    this.text = text as LocalizedText;
    this.intervalId = window.setInterval(() => this.clockUpdate(), 10000);
    this.searchSub
      .pipe(
        tap((q) => {
          this.searchQuery = q;
        }),
        debounceTime(200),
      )
      .subscribe((q) => {
        this.searchQuery = q;
        this.handleSearch();
      });
    this.policyService.hasAccess('write:fnb-order').then((allowed) => {
      if (allowed) {
        this.isWriteAllowed = true;
      }
    });
  }

  ngOnInit(): void {
    this.selectedPTW = this.allPTW
    this.selectedTab = this.state.params.state || 'UNFULFILLED';
    this.searchQuery = this.state.params.search || '';
    this.isSearching = !!this.searchQuery;
    this.exportFilename = `${this.state.params.kitchenId.replace('.', '_').replace(':', '_')}__orders`;
    this.updateFilter();
    this.loadOrders();
    this.kitchenService
    .getKitchen(this.state.params.kitchenId)
    .then((kitchen) => {
      this.kitchen = kitchen;
      this.ptwService.getPTWByPlace(kitchen.id).pipe(take(1)).subscribe((res) => {
        this.ptws = [];
        this.ptws.push(this.allPTW)
        this.ptws = [...this.ptws, ...res];

      })
      })
      .catch((err) => {
        console.error(err);
        this.hasError = true;
      });

    this.printerService.getPrinters(this.state.params.kitchenId).then((response) => {
      this.isPrintingEnabled = response.printers.length > 0;
    });

  }

  ngOnDestroy() {
    window.clearInterval(this.intervalId);
    this.searchSub.unsubscribe();
  }

  clockUpdate(): void {
    this.now = Date.now();
    const ageMs = this.now - this.lastUpdated.getTime();
    if (ageMs >= AUTO_REFRESH_TIME_MS) {
      this.loadOrders();
    }
  }

  selectTab(newTab: string): void {
    this.selectedTab = newTab;
    this.state.go('.', { state: newTab }, { inherit: true, location: 'replace' });
    this.dismissAllNotices();
  }
  
  updateFilter(): void {
    const byRefunded = (ord: Order) => ord.paymentStatus === 'REFUNDED' || ord.paymentStatus === 'PARTIAL_REFUND_APPLIED';
    const byFulfillment = (status) => (ord: Order) => ord.fulfillmentStatus === status && !byRefunded(ord) && ord.orderStatus !== 'SUBMITTED';
    const byOrderStatus = (status) => (ord: Order) => ord.orderStatus === status;
    const byFulfillmentOrNotice = (status) => (ord: Order) => (status === 'REFUNDED'
    ? byRefunded(ord) && ord.orderStatus !== 'SUBMITTED'
    : !byRefunded(ord) && ord.orderStatus !== 'SUBMITTED' && (ord.fulfillmentStatus === status || ord.notice === status));
    if(this.selectedTab==='SUBMITTED') {
      this.ordersFiltered = this.orders.filter(byOrderStatus(this.selectedTab))
    } else {
      this.ordersFiltered = this.orders.filter(byFulfillmentOrNotice(this.selectedTab));
    }
    this.filterPTW(this.selectedPTW, this.ordersFiltered);
    this.counts.UNFULFILLED = this.orders.filter(byFulfillment('UNFULFILLED')).length;
    this.counts.IN_PROCESS = this.orders.filter(byFulfillment('IN_PROCESS')).length;
    this.counts.FULFILLED = this.orders.filter(byFulfillment('FULFILLED')).length;
    this.counts.REFUNDED = this.orders.filter(byRefunded).length;
    this.counts.SUBMITTED = this.orders.filter(byOrderStatus('SUBMITTED')).length;
  }

  advancedSearch(): void {
    this.state.go(fnbRoutes.orderHistory, { kitchenId: this.state.params.kitchenId });
  }

  editOrder(order: Order): void {
    // Can't edit refunded orders
    if (order.paymentStatus === 'REFUNDED') {
      return;
    }

    const { orderId } = order;
    if (this.editingOrderId !== orderId) {
      this.editingOrderId = orderId;
    } else {
      this.editingOrderId = '';
    }
  }

  getPageAge(): string {
    const ageMs = this.now - this.lastUpdated.getTime();
    const ageMinutes = Math.round(ageMs / (1000 * 60));
    return this.text.updatedAgo.replace('%min%', `${ageMinutes}`);
  }

  loadOrders(): void {
    this.isRefreshing = true;
    const params: OrderFulfillmentParams = {status: 'RELEASED,SUBMITTED'};
    this.orderService
      .getOrderFulfillment(this.state.params.kitchenId, params)
      .then((orders) => {
        this.orders = orders;

        // The order details screen can transition to this screen with a notice set.
        if (this.state.params.orderId && this.state.params.orderNotice) {
          this.orders
            .filter((o) => o.orderId === this.state.params.orderId)
            .forEach((o) => {
              o.notice = this.state.params.orderNotice;
            });
        }

        this.lastUpdated = new Date();
        this.updateFilter();
        if(this.isPtwEnabled) {
          this.sortByPTW(this.sortTypeAsc);
        }
        this.isLoading = false;
        this.hasError = false;

        if (this.modalRef) {
          this.modalRef.hide();
          this.modalRef = null;
        }

        this.handleSearch();
      })
      .catch((err) => {
        this.showOrderErrorModal(this.text.loadOrdersErrorMsg);
        this.hasError = true;
        console.error('Could not retrieve order queue', err);
      })
      .finally(() => {
        this.isLoading = false;
        this.isRefreshing = false;
      });
  }

  showOrderErrorModal(message: string): void {
    if (!this.modalRef) {
      this.modalRef = this.modalService.show(SimpleModalComponent, {
        initialState: { message },
        backdrop: 'static',
        class: 'cr-modal-size-sm',
      });
    }
  }

  searchPlaceholder(): string {
    return this.isSearching ? '' : this.text.searchPlaceholder as string;
  }

  searchChanged(newValue: string) {
    this.searchSub.next(newValue);
  }

  searchFocus() {
    this.dismissAllNotices();
    this.isSearching = true;
    this.handleSearch();
  }

  searchBlur() {
    this.isSearching = !!this.searchQuery;
    if (!this.isSearching) {
      this.updateFilter();
    }
  }

  clearSearch() {
    this.isSearching = false;
    this.searchQuery = '';
    this.updateFilter();
    this.state.go('.', { search: null }, { inherit: true, location: 'replace' });
  }

  handleSearch() {
    if (!this.isSearching) {
      this.state.go('.', { search: null }, { inherit: true, location: 'replace' });
      return;
    }
    this.state.go('.', { search: this.searchQuery }, { inherit: true, location: 'replace' });

    if (this.searchQuery === '') {
      this.ordersFiltered = [];
      return;
    }

    const filters = this.parseSearchQuery(this.searchQuery);
    this.ordersFiltered = this.orders
      .map((order) => {
        const score = filters
          .map((f) => {
            let s = 0;
            switch (f.kind) {
              case 'text': {
                if (asciiContains(order.customerName, f.value)) {
                  s += f.weight + 5;
                }
                if (asciiContains(order.customerEmail, f.value)) {
                  s += f.weight + 5;
                }
                if (asciiContains(order.externalOrderId, f.value)) {
                  s += f.weight;
                }
                break;
              }
              case 'credit-card': {
                if (order.cardLast4 === f.lastFour) {
                  s += f.weight;
                }
                break;
              }
              case 'order-total': {
                if (Math.abs(order.orderTotal - f.amount) <= 0.01) {
                  s += f.weight;
                }
                if (order.orderGratuity) {
                  const billTotal = order.orderTotal - order.orderGratuity;
                  if (Math.abs(billTotal - f.amount) <= 0.01) {
                    s += f.weight;
                  }
                }
                break;
              }
              default: {
                break;
              }
            }
            return s;
          })
          .reduce((a, b) => a + b);
        return { score, order };
      })
      .filter(({ score }) => score > 0)
      .sort((a, b) => b.score - a.score)
      .map(({ order }) => order);
  }

  selectPTWFilter(ptw: PTWCapacity){
    this.selectedPTW.selected = false;
    this.selectedPTW = ptw;
    this.selectedPTW.selected = true;

    this.updateFilter();
  }

  filterPTW(ptw: PTWCapacity, ordersList: Order[] = this.orders) {
    if(ptw !== this.allPTW){
      this.ptwFilterText = `${this.selectedPTW.startTime} - ${this.selectedPTW.endTime}`
      this.ordersFiltered = ordersList.filter(order => order.pickupWindowId === ptw.windowId)
    } else {
      this.ptwFilterText = 'ALL'
      this.ordersFiltered = ordersList
    }
  }

  parseSearchQuery(q: string): SearchFilter[] {
    const parts = q.split(' ');
    const filters = parts
      .filter((part) => part !== '')
      .map((part) => {
        const searchFilter: SearchFilter[] = [];
        if (creditCardPattern.exec(part)) {
          searchFilter.push({ kind: 'credit-card', weight: 5, lastFour: part });
          searchFilter.push({ kind: 'order-total', weight: 1, amount: parseInt(part, 10) });
          searchFilter.push({ kind: 'text', weight: 1, value: part });
        } else if (orderTotalPattern.exec(part)) {
          const withoutDollarSign = part.replace(stripDollarSign, '$1');
          searchFilter.push({ kind: 'order-total', weight: 5, amount: parseFloat(withoutDollarSign) });
          if (!(currencySymbolPattern.exec(part))) {
            searchFilter.push({ kind: 'text', weight: 1, value: part });
          }
        } else {
          searchFilter.push({ kind: 'text', weight: 1, value: normalize(part) });
        }
        return searchFilter;
      });
    return [].concat(...filters);
  }

  onBack(): void {
    this.navigationService.goBack('client.fnb-order-queue.dashboard', {});
  }

  openModal(template: TemplateRef<any>, config: ModalConfig) {
    this.modalRef = this.modalService.show(template, config);
  }

  sortByPTW(sort: boolean) {
    if(this.orders.length > 0) {
      if(sort) {
        this.orders.sort((a, b) => a?.pickupWindow?.startTime > b?.pickupWindow?.startTime ? 1 : a?.pickupWindow?.startTime < b?.pickupWindow?.startTime ? -1 : 0)
        this.sortTypeAsc = !this.sortTypeAsc;
      } else { 
        this.orders.sort((a, b) => a?.pickupWindow?.startTime < b?.pickupWindow?.startTime ? 1 : a?.pickupWindow?.startTime > b?.pickupWindow?.startTime ? -1 : 0)
        this.sortTypeAsc = !this.sortTypeAsc;
      }
    }
    this.updateFilter();
    
}

  private dismissAllNotices() {
    this.orders = this.orders.map((order) => {
      order.notice = null;
      order.error = false;
      return order;
    });
    this.updateFilter();
  }
}
