import * as moment from 'moment-timezone';
import { color } from "../constants/options";
import { SNOAA_Type, Time } from "../constants/statuses";

/**
 * @param {string} time
 * @return {number} The 24-hour decimal notation of a given time string. `1:30 pm` is returned as `13.5`.
 */
export function timeTo24HourDecimal(time: string): number {
    const [hour, minutes, amPm] = time.split(/(?::|\s)/);
    let totalTime = parseInt(hour);
    totalTime = totalTime === 12 ? 0 : totalTime; // 12am should be 0
    if (minutes) {
        totalTime += (parseInt(minutes) / 60);
    }
    if (amPm?.toLowerCase() === Time.PM) {
        totalTime += 12;
    }
    return totalTime;
}

/**
 * @returns {number} The current time of the day in 24-hour decimal notation. `1:30 pm` is returned as `13.5`.
 */
export function getCurrentTimeOfDayIn24HourDecimal(): number {
    const now = new Date();
    return now.getHours() + (now.getMinutes() / 60);
}

/**
 * @returns {string} The name of the day such as "Monday", etc.
 */
export function getCurrentDayName(timezone: string): string {
    return moment.tz(timezone).format('dddd');
}

export function replaceText(text): string {
    return text.split('_').join(' ');
}

export function getAutoStatusInfo(extValue: any, text: any, timezone: string) {
    const currentDay = getCurrentDayName(timezone).toLowerCase();
    const operatingHours = extValue?.hours?.[currentDay];
    const opening = operatingHours?.open ? timeTo24HourDecimal(operatingHours.open) : -1;
    const closing = operatingHours?.close ? timeTo24HourDecimal(operatingHours.close) : -1;

    if (opening >= 0 && closing >= 0) {
        const startTime = moment.tz(timezone).seconds(0).minutes(opening % 1 * 60).hours(Math.floor(opening));
        const endTime = moment.tz(timezone).seconds(0).minutes(closing % 1 * 60).hours(Math.floor(closing));
        const now = moment.tz(timezone);

        if (startTime.isBefore(now) && endTime.isAfter(now)) {
            return {
                label: '',
                labelName: '',
                status: 'open',
                color: color.green,
            };
        }
    }

    return {
        label: '',
        labelName: '',
        status: 'closed',
        color: color.red,
    };
}

export function getIconUrl(type: string, icon: string, text: string) {
    if (icon === undefined) return;

    if (type === SNOAA_Type.LIFT) {
        return text + '/lift_icons2/' + icon + '.svg';
    }
    return text + '/difficulty_icons2/' + icon + '.svg';
}
