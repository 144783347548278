import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import {
  AbstractControl, FormControl, FormGroup, ValidatorFn, Validators,
} from '@angular/forms';
import moment, { Moment } from 'moment';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { LocalizedText, ValidationMessages } from '../../../../../../core';
import { ReservationService } from '../../../../../../shared';
import { ChipTag } from '../../../../../../shared/forms/chip-selector/chip-selector.component';
import { constants } from '../../../../../../shared/services/constants/constants';
import { Schedule, ScheduleInstance } from '../../../../../../shared/services/reservations/reservation.types';
import { EditMode } from '../dining-location-editor.component';
import { ScheduleData } from './schedule-data';

const SCHEDULE_TYPE = 'reservation';

enum ModificationType {
  Capacity,
  CancelFuture,
  CancelCurrent,
}

interface Modification {
  date?: Date;
  selectedSessions?: ChipTag[];
  allSessions?: boolean;
  modificationType?: ModificationType;
  capacity?: number;
  scheduleId?: string;
  isWithinCreationWindow?: boolean;
}

@Component({
  selector: 'cr-reservation-schedules',
  templateUrl: './reservation-schedules.component.html',
  styleUrls: [
    '../dining-location-editor.component.scss',
    './reservation-schedules.component.scss',
    '../../../../../../../../node_modules/ngx-bootstrap/datepicker/bs-datepicker.scss',
  ],
  encapsulation: ViewEncapsulation.None,
})
export class ReservationSchedulesComponent implements OnInit, OnChanges {
  @Input()
  text: LocalizedText;

  @Input()
  reservationUnit: string;

  @Input()
  schedules: Schedule[];

  @Input()
  editMode: EditMode;

  @Input()
  set reservationDaysAhead(value: number) {
    if (!this._reservationDaysAhead) {
      this._reservationDaysAhead = value;
    }
  }

  @Output()
  schedulesEmitter: EventEmitter<SchedulesState> = new EventEmitter<SchedulesState>();

  readonly minuteOptions: number[] = [];

  readonly dateTimePickerFormat = `${constants.date.format.yearFirstDate}T${constants.time.format.pickerModel}`;

  validationMessages: ValidationMessages = {};

  scheduleForms: FormGroup[] = [];

  modificationForms: FormGroup[] = [];

  instanceTimes: string[][];

  private readonly scheduleConflictValidator: ValidatorFn;

  private readonly startDateValidator: ValidatorFn;

  private readonly scheduleExceptionsMap: Map<number, Map<number, string[]>> = new Map();

  private recurringSchedules: Schedule[] = [];

  private modificationSchedules: Schedule[] = [];

  private _reservationDaysAhead: number;

  constructor(private reservationService: ReservationService) {
    for (let i = 15; i <= 120; i += 15) {
      this.minuteOptions.push(i);
    }
    this.scheduleConflictValidator = this.scheduleConflictValidatorFactory();
    this.startDateValidator = this.minDateValidatorFactory(this.today);
  }

  ngOnInit() {
    this.validationMessages = this.text.schedule.formValidation;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.schedules) {
      this.updateSchedules();
      const recurringScheduleDataList = this.recurringSchedules.map((schedule) => new ScheduleData(schedule));

      recurringScheduleDataList.forEach((schedule, index) => {
        if (this.scheduleForms.length > index) {
          this.updateScheduleForm(schedule, this.scheduleForms[index]);
        } else {
          this.scheduleForms.push(this.createScheduleForm(schedule, index));
        }
      });
      this.instanceTimes = recurringScheduleDataList.map((schedule) => schedule.instanceTimes);
      const modificationScheduleDataList = this.modificationSchedules.map(
        (schedule) => new ScheduleData(schedule),
      );
      this.updateModificationForms(recurringScheduleDataList, modificationScheduleDataList);
    }
  }

  addModificationForm(modification: Modification) {
    const index = this.modificationForms.length;
    const modificationIsWithinCreationWindow = this.isWithinCreationWindow(modification.date);

    const modificationForm = new FormGroup({
      date: new FormControl(modification.date, Validators.required),
      selectedSessions: new FormControl(modification.selectedSessions),
      allSessions: new FormControl(modification.allSessions),
      modificationType: new FormControl(modification.modificationType, Validators.required),
      capacity: new FormControl(modification.capacity),
      scheduleId: new FormControl(modification.scheduleId),
      index: new FormControl(index),
      isWithinCreationWindow: new FormControl(modificationIsWithinCreationWindow),
    });
    if (modificationIsWithinCreationWindow) {
      modificationForm.controls.date.disable({ emitEvent: false });
      modificationForm.controls.selectedSessions.disable({ emitEvent: false });
      modificationForm.controls.allSessions.disable({ emitEvent: false });
      modificationForm.controls.modificationType.disable({ emitEvent: false });
      modificationForm.controls.capacity.disable({ emitEvent: false });
    }
    this.modificationForms.push(modificationForm);

    modificationForm.controls.selectedSessions.setValidators(this.selectedSessionsValidator(index));
    modificationForm.valueChanges.subscribe((event) => this.handleModificationFormChange(event));
  }

  addModification() {
    if (this.modificationFormsAreValid()) {
      this.addModificationForm({});
    }
  }

  onWeekdaySelect(selectedDays, scheduleForm: FormGroup) {
    scheduleForm.controls.selectedDays.setValue(selectedDays);
  }

  getSelectedDays(scheduleForm: FormGroup) {
    return scheduleForm.controls.selectedDays.value;
  }

  isExistingSchedule(scheduleIndex: number): boolean {
    return !!this.recurringSchedules[scheduleIndex].id;
  }

  addSchedule() {
    if (this.scheduleFormsAreValid()) {
      this.scheduleForms.push(this.createScheduleForm(new ScheduleData(), this.scheduleForms.length));
      this.recurringSchedules.push({ scheduleType: SCHEDULE_TYPE });
      this.instanceTimes.push([]);
      this.emitScheduleState();
    }
  }

  removeSchedule(index) {
    this.scheduleForms.splice(index, 1);
    this.recurringSchedules.splice(index, 1);
    this.instanceTimes.splice(index, 1);
    this.scheduleForms.forEach((form, i) => {
      if (form.controls.scheduleIndex.value >= index) {
        form.controls.scheduleIndex.setValue(i);
      }
    });
    this.emitScheduleState();
  }

  getStartDateConfig(): Partial<BsDatepickerConfig> {
    return {
      minDate: this.today,
      showWeekNumbers: false,
      containerClass: 'theme-default',
    };
  }

  getModificationDateConfig(): Partial<BsDatepickerConfig> {
    const config: Partial<BsDatepickerConfig> = {
      showWeekNumbers: false,
      containerClass: 'theme-default',
    };
    const today = moment().startOf('day');

    const startDates = this.scheduleForms
      .map((form) => form.controls.startDate)
      .filter((control) => control.valid || control.disabled)
      .map((control) => moment(control.value));

    if (startDates && startDates.length > 0) {
      // The minDate should be the first schedules start date
      // unless it occurs in the past
      const minScheduleStart = moment.min(...startDates);
      config.minDate = minScheduleStart.isAfter(today) ? minScheduleStart.toDate() : today.toDate();
    }

    const endDates = this.scheduleForms
      .map((form) => form.controls.endDate)
      .filter((control) => control.valid)
      .map((control) => moment(control.value));
    if (endDates && endDates.length && endDates.length === this.scheduleForms.length) {
      // If all schedules have an end date, do not allow a modification outside of that range
      config.maxDate = moment.max(...endDates).toDate();
    }

    return config;
  }

  isModificationEditable(): boolean {
    return this.scheduleFormsAreValid();
  }

  getSelectedSessions(modificationIndex: number): ChipTag[] {
    const modificationForm = this.modificationForms[modificationIndex];
    if (this.isReadOnly || !modificationForm.controls.allSessions.value) {
      return modificationForm.controls.selectedSessions.value;
    }
    return [];
  }

  sessionsSelected(modificationIndex: number, tags: ChipTag[]) {
    const controller = this.modificationForms[modificationIndex].controls.selectedSessions;
    controller.setValue(tags);
    controller.markAsTouched();
  }

  getSessionClasses(modificationIndex: number) {
    const selectedSessiosControl = this.modificationForms[modificationIndex].controls.selectedSessions;
    return {
      'ng-invalid': selectedSessiosControl.invalid,
      'ng-touched': selectedSessiosControl.touched,
    };
  }

  getAvailableSessions(modificationIndex: number): ChipTag[] {
    const modificationForm = this.modificationForms[modificationIndex];
    if (modificationForm.controls.date.valid && !modificationForm.controls.allSessions.value) {
      return this.sessionsForDate(moment(modificationForm.controls.date.value));
    }
    return [];
  }

  get noActiveSchedules(): boolean {
    return this.editMode === EditMode.Preview && this.recurringSchedules.length === 0;
  }

  get capacityModificationType() {
    return ModificationType.Capacity;
  }

  get cancelFutureModificationType() {
    return ModificationType.CancelFuture;
  }

  get cancelCurrentModificationType() {
    return ModificationType.CancelCurrent;
  }

  isCapacityModification(modificationIndex: number) {
    const modificationTypeControl = this.modificationForms[modificationIndex].controls.modificationType;
    return modificationTypeControl.value === ModificationType.Capacity;
  }

  isCancelFutureModification(modificationIndex: number) {
    const modificationTypeControl = this.modificationForms[modificationIndex].controls.modificationType;
    return modificationTypeControl.value === ModificationType.CancelFuture;
  }

  isCancelCurrentModification(modificationIndex: number) {
    const modificationTypeControl = this.modificationForms[modificationIndex].controls.modificationType;
    return modificationTypeControl.value === ModificationType.CancelCurrent;
  }

  getEndDateConfig(scheduleForm: FormGroup): Partial<BsDatepickerConfig> {
    return {
      minDate: this.getMinEndDate(scheduleForm),
      showWeekNumbers: false,
      containerClass: 'theme-default',
    };
  }

  controlHasError(control: AbstractControl): boolean {
    return !!control.errors;
  }

  modificationSessionsHasError(modificationForm: FormGroup) {
    return !!modificationForm.controls.selectedSessions.errors;
  }

  get isReadOnly(): boolean {
    return this.editMode === EditMode.Preview;
  }

  get datePlaceHolder() {
    return constants.placeholder.datePicker;
  }

  get addScheduleClasses() {
    return {
      disabled: !this.scheduleFormsAreValid() || this.isReadOnly,
    };
  }

  get addModificationClasses() {
    return {
      disabled: !this.modificationFormsAreValid(),
    };
  }

  private minDateValidatorFactory(minDate: Date): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value) {
        return null;
      }
      return minDate > control.value ? { invalidDate: { value: control.value } } : null;
    };
  }

  private get today(): Date {
    return moment().startOf('day').toDate();
  }

  private getMinEndDate(scheduleForm: FormGroup): Date {
    if (this.editMode === EditMode.Create) {
      return scheduleForm.controls.startDate.value || this.today;
    }

    const windowClosedDate = moment().startOf('day');
    const minDate = moment(scheduleForm.controls.startDate.value || this.today).startOf('day');

    if (this._reservationDaysAhead) {
      windowClosedDate.add(this._reservationDaysAhead, 'days');
    }

    if (minDate.isAfter(windowClosedDate)) {
      return minDate.toDate();
    }

    return windowClosedDate.toDate();
  }

  private sortSchedulesAndInstances() {
    this.recurringSchedules.sort((a, b) => a.priority - b.priority);
    this.recurringSchedules.forEach((schedule) => {
      if (schedule.instances) {
        schedule.instances.sort((a, b) => {
          if (a.startTime > b.startTime) {
            return 1;
          }
          if (a.startTime < b.startTime) {
            return -1;
          }
          return 0;
        });
      }
    });
  }

  private createScheduleForm(schedule: ScheduleData, index: number): FormGroup {
    const firstInstanceTime = schedule.instanceTimes
      ? moment(schedule.instanceTimes[0]).format(constants.time.format.pickerModel)
      : '';
    const lastInstanceTime = schedule.instanceTimes
      ? moment(schedule.instanceTimes[schedule.instanceTimes.length - 1]).format(
        constants.time.format.pickerModel,
      )
      : '';
    const durationMin = schedule.durationSecs ? schedule.durationSecs / 60 : 0;
    const isExistingSchedule = !!schedule.id;
    const scheduleForm = new FormGroup({
      startDate: new FormControl({ value: schedule.startDate, disabled: isExistingSchedule }, [
        Validators.required,
        this.startDateValidator,
      ]),
      endDate: new FormControl(schedule.endDate),
      selectedDays: new FormControl({ value: schedule.days, disabled: isExistingSchedule }, [
        Validators.required,
      ]),
      firstInstanceTime: new FormControl({ value: firstInstanceTime, disabled: isExistingSchedule }, [
        Validators.required,
      ]),
      lastInstanceTime: new FormControl({ value: lastInstanceTime, disabled: isExistingSchedule }, [
        Validators.required,
      ]),
      durationMin: new FormControl({ value: durationMin || '', disabled: isExistingSchedule }, [
        Validators.required,
      ]),
      quantity: new FormControl({ value: schedule.quantity, disabled: isExistingSchedule }, [
        Validators.required,
        Validators.min(1),
      ]),
      scheduleIndex: new FormControl(index),
    });

    if (isExistingSchedule) {
      const endDateValidator = this.minDateValidatorFactory(this.getMinEndDate(scheduleForm));
      scheduleForm.controls.endDate.setValidators([endDateValidator]);
    }

    scheduleForm.setValidators(this.scheduleConflictValidator);
    scheduleForm.controls.startDate.valueChanges.subscribe(() => this.startDateChanged(scheduleForm));
    scheduleForm.valueChanges.subscribe((event) => this.handleScheduleFormChange(event));
    return scheduleForm;
  }

  private updateScheduleForm(schedule: ScheduleData, scheduleForm: FormGroup) {
    if (schedule.startDate) {
      scheduleForm.controls.startDate.setValue(schedule.startDate);
    }
    if (schedule.endDate) {
      scheduleForm.controls.endDate.setValue(schedule.endDate);
    }
    if (schedule.days) {
      scheduleForm.controls.selectedDays.setValue(schedule.days);
    }
    if (schedule.instanceTimes) {
      const firstInstanceTime = moment(schedule.instanceTimes[0]).format(constants.time.format.pickerModel);
      const lastInstanceTime = moment(schedule.instanceTimes[schedule.instanceTimes.length - 1]).format(
        constants.time.format.pickerModel,
      );
      scheduleForm.controls.firstInstanceTime.setValue(firstInstanceTime);
      scheduleForm.controls.lastInstanceTime.setValue(lastInstanceTime);
    }
    if (schedule.durationSecs) {
      scheduleForm.controls.durationMin.setValue(schedule.durationSecs / 60);
    }
    if (schedule.quantity) {
      scheduleForm.controls.quantity.setValue(schedule.quantity);
    }

    // disable most fields if schedule ID is set
    if (schedule.id) {
      scheduleForm.controls.startDate.disable();
      scheduleForm.controls.selectedDays.disable();
      scheduleForm.controls.firstInstanceTime.disable();
      scheduleForm.controls.lastInstanceTime.disable();
      scheduleForm.controls.durationMin.disable();
      scheduleForm.controls.quantity.disable();

      const endDateValidator = this.minDateValidatorFactory(this.getMinEndDate(scheduleForm));
      scheduleForm.controls.endDate.setValidators([endDateValidator]);
    }
  }

  private scheduleConflictValidatorFactory(): ValidatorFn {
    return (form: FormGroup): { [key: string]: any } | null => {
      if (this.scheduleConflictExists(form)) {
        return { scheduleConflict: { value: form.value } };
      }
      return null;
    };
  }

  private scheduleConflictExists(scheduleForm: FormGroup): boolean {
    const startDate: string = scheduleForm.controls.startDate.value;
    const endDate: string = scheduleForm.controls.endDate.value || '';
    const selectedDays: string[] = scheduleForm.controls.selectedDays.value;
    if (!startDate || !selectedDays || selectedDays.length === 0) {
      return false;
    }

    const startDateMoment = moment(startDate).startOf('day');
    const endDateMoment = moment(endDate).startOf('day');
    const previousSchedules = this.scheduleForms.slice(0, scheduleForm.controls.scheduleIndex.value);
    return previousSchedules.some((form) => this.formDatesOverlap(form, startDateMoment, endDateMoment, selectedDays));
  }

  private formDatesOverlap(
    scheduleForm: FormGroup,
    startDate: Moment,
    endDate: Moment,
    selectedDays: string[],
  ): boolean {
    const formStartDate: string = scheduleForm.controls.startDate.value;
    const formEndDate: string = scheduleForm.controls.endDate.value || '';
    const formSelectedDays: string[] = scheduleForm.controls.selectedDays.value;
    if (!formStartDate || !formSelectedDays || formSelectedDays.length === 0) {
      return false;
    }

    const currentStartDateMoment = moment(formStartDate).startOf('day');
    const currentEndDateMoment = moment(formEndDate).startOf('day');
    if (
      this.isStartDateBeforeEndDate(startDate, currentEndDateMoment)
            && this.isStartDateBeforeEndDate(currentStartDateMoment, endDate)
    ) {
      return formSelectedDays.some((day) => selectedDays.includes(day));
    }
    return false;
  }

  private isStartDateBeforeEndDate(startDate: Moment, endDate: Moment) {
    return !endDate.isValid() || startDate.isSameOrBefore(endDate);
  }

  private startDateChanged(scheduleForm: FormGroup) {
    const endDateControl = scheduleForm.controls.endDate;
    endDateControl.setValidators([this.minDateValidatorFactory(this.getMinEndDate(scheduleForm))]);
    endDateControl.updateValueAndValidity();
  }

  private handleScheduleFormChange(formValues: any) {
    const index = formValues.scheduleIndex;
    const schedule: Schedule = this.recurringSchedules.length > index ? this.recurringSchedules[index] : { scheduleType: SCHEDULE_TYPE };
    const scheduleForm = this.scheduleForms[index];
    schedule.scheduleType = 'reservation';

    if (formValues.selectedDays) {
      schedule.recurrence = this.computeRecurrence(formValues.selectedDays, formValues.endDate);
    } else {
      schedule.recurrence = this.computeRecurrence(scheduleForm.controls.selectedDays.value, formValues.endDate);
    }

    if (formValues.quantity) {
      schedule.quantity = formValues.quantity;
    }

    if (formValues.durationMin) {
      schedule.durationSecs = formValues.durationMin * 60;

      if (formValues.startDate && formValues.firstInstanceTime && formValues.lastInstanceTime) {
        const currentInstanceTimes = this.computeInstances(formValues);
        this.instanceTimes[index] = currentInstanceTimes;
        schedule.instances = currentInstanceTimes.map((startTime): ScheduleInstance => ({ startTime }));
      }
    }

    this.recurringSchedules[index] = schedule;

    // need to re-validate future schedules in case this change resolves a conflict
    if (this.scheduleForms.length > index) {
      this.scheduleForms.slice((index as number) + 1).forEach((form) => form.updateValueAndValidity());
    }

    // need to re-validate modifications in case this change invalidates one of our modifications
    this.modificationForms.forEach((form) => form.updateValueAndValidity());

    this.emitScheduleState();
  }

  private emitScheduleState() {
    const updatedSchedules = this.recurringSchedules.concat(this.modificationSchedules);
    if (this.schedules.length > updatedSchedules.length) {
      this.schedules.splice(updatedSchedules.length);
    }
    for (let i = 0; i < updatedSchedules.length; i++) {
      this.schedules[i] = updatedSchedules[i];
      this.schedules[i].priority = i + 1;
    }
    const newCancellationModification = this.modificationForms.some(
      (modForm) => modForm.controls.isWithinCreationWindow.value && modForm.controls.date.touched,
    );

    this.schedulesEmitter.emit({
      schedules: this.schedules,
      valid: this.scheduleFormsAreValid() && this.modificationFormsAreValidOrUntouched(),
      newCancellationModification,
    });
  }

  private computeRecurrence(selectedDays?: string[], endDate?: Date | string) {
    let recurrence = 'FREQ=WEEKLY';
    if (selectedDays && selectedDays.length > 0) {
      const selectedDaysString = selectedDays.join(',');
      recurrence += `;BYDAY=${selectedDaysString}`;
      if (endDate) {
        recurrence += `;UNTIL=${moment(endDate).format(constants.iCal.format.date)}`;
      }
    }
    return recurrence;
  }

  private computeInstances(formValues: any) {
    const currentInstanceTimes = [];

    const formattedStartDate = moment(formValues.startDate).format(constants.date.format.yearFirstDate);
    const firstInstanceTime = moment(
      `${formattedStartDate}T${formValues.firstInstanceTime}`,
      this.dateTimePickerFormat,
    );
    const lastInstanceTime = moment(
      `${formattedStartDate}T${formValues.lastInstanceTime}`,
      this.dateTimePickerFormat,
    );
    if (firstInstanceTime.isAfter(lastInstanceTime)) {
      lastInstanceTime.add(1, 'day');
    }
    for (let currentTime = firstInstanceTime; firstInstanceTime.isSameOrBefore(lastInstanceTime);) {
      currentInstanceTimes.push(currentTime.format(constants.date.format.isoNoOffset));
      currentTime = currentTime.add(formValues.durationMin, 'minutes');
    }
    return currentInstanceTimes;
  }

  private scheduleFormsAreValid(): boolean {
    return this.scheduleForms.every((form) => form.valid);
  }

  private modificationFormsAreValid(): boolean {
    return this.modificationForms.every((form) => form.valid);
  }

  private modificationFormsAreValidOrUntouched(): boolean {
    return this.modificationForms.every((form) => form.untouched || form.valid);
  }

  private updateSchedules() {
    this.recurringSchedules = [];
    this.modificationSchedules = [];
    this.schedules.forEach((schedule) => {
      if (!schedule.recurrence) {
        this.modificationSchedules.push(schedule);
      } else {
        this.recurringSchedules.push(schedule);
      }
    });
    if (this.recurringSchedules.length === 0 && this.editMode !== EditMode.Preview) {
      this.recurringSchedules = [
        {
          scheduleType: SCHEDULE_TYPE,
          recurrence: this.computeRecurrence(),
        },
      ];
    }
    this.sortSchedulesAndInstances();
  }

  private updateModificationForms(
    recurringScheduleDataList: ScheduleData[],
    modificationScheduleDataList: ScheduleData[],
  ) {
    const modifications: Modification[] = this.buildModificationsArray(
      recurringScheduleDataList,
      modificationScheduleDataList,
    );
    modifications.forEach((modification, index) => {
      if (this.modificationForms.length > index) {
        const modificationForm = this.modificationForms[index];
        modificationForm.patchValue(
          {
            date: modification.date,
            selectedSessions: modification.selectedSessions,
            allSessions: modification.allSessions,
            modificationType: modification.modificationType,
            capacity: modification.capacity,
            scheduleId: modification.scheduleId,
          },
          { emitEvent: false },
        );

        modificationForm.updateValueAndValidity({ emitEvent: false });

        const modificationIsWithinCreationWindow = this.isWithinCreationWindow(modification.date);

        if (modificationIsWithinCreationWindow && modificationForm.valid) {
          modificationForm.controls.date.disable({ emitEvent: false });
          modificationForm.controls.selectedSessions.disable({ emitEvent: false });
          modificationForm.controls.allSessions.disable({ emitEvent: false });
          modificationForm.controls.modificationType.disable({ emitEvent: false });
          modificationForm.controls.capacity.disable({ emitEvent: false });
        }
      } else {
        this.addModificationForm(modification);
      }
    });
  }

  private buildModificationsArray(
    recurringScheduleDataList: ScheduleData[],
    modificationScheduleDataList: ScheduleData[],
  ) {
    const modifications: Modification[] = [];
    recurringScheduleDataList.forEach((schedule) => {
      schedule.recurrenceExceptionsMap.forEach((sessions: Moment[], dateString: string) => {
        const selectSessionDate = moment(dateString);
        const selectedSessions = sessions
          .map((session) => this.instanceTimeToChipTag(session))
          .sort(this.chipTagSort);
        const allSessions = this.allSessionsSelected(selectSessionDate, selectedSessions);
        modifications.push({
          date: selectSessionDate.toDate(),
          selectedSessions,
          allSessions,
          modificationType: this.isWithinCreationWindow(selectSessionDate.toDate())
            ? ModificationType.CancelCurrent
            : ModificationType.CancelFuture,
        });
      });
    });
    modificationScheduleDataList.forEach((schedule) => {
      const firstInstanceTime = schedule.instanceTimes.find((instance) => moment(instance).isValid());
      const selectedSessions: ChipTag[] = schedule.instanceTimes
        .map((instance) => this.instanceTimeToChipTag(instance))
        .sort(this.chipTagSort);
      const allSessions = this.allSessionsSelected(moment(firstInstanceTime), selectedSessions);
      modifications.push({
        date: schedule.startDate,
        selectedSessions,
        allSessions,
        modificationType: ModificationType.Capacity,
        capacity: schedule.quantity,
        scheduleId: schedule.id,
      });
    });

    if (modifications.length === 0) {
      modifications.push({});
    } else {
      modifications.sort((a, b) => a.date.valueOf() - b.date.valueOf());
    }
    return modifications;
  }

  private chipTagSort(tag1: ChipTag, tag2: ChipTag): number {
    if (tag1.id < tag2.id) {
      return -1;
    }
    if (tag1.id > tag2.id) {
      return 1;
    }
    return 0;
  }

  private allSessionsSelected(modificationDate: Moment, selectedSessions: ChipTag[]) {
    const availableSessions = this.sessionsForDate(modificationDate);
    let allSessions = selectedSessions.length === availableSessions.length;
    for (let i = 0; allSessions && i < selectedSessions.length; i++) {
      allSessions = selectedSessions[i].id === availableSessions[i].id;
    }
    return allSessions;
  }

  private sessionsForDate(date: Moment): ChipTag[] {
    const matchingSchedule = this.getScheduleFormForDate(date);
    if (matchingSchedule) {
      const instanceTimes = this.instanceTimes[matchingSchedule.controls.scheduleIndex.value];
      const dateDiff = date.diff(moment(instanceTimes[0]).startOf('day'), 'days');
      return instanceTimes.map((instanceTime) => this.instanceTimeToChipTag(instanceTime, dateDiff));
    }
    return [];
  }

  private getScheduleFormForDate(date: moment.Moment): FormGroup {
    return this.scheduleForms.find((form) => this.scheduleFormMatchesDate(form, date));
  }

  private scheduleFormMatchesDate(form: FormGroup, date: Moment): boolean {
    if (!form.valid) {
      return false;
    }

    const startDate = moment(form.controls.startDate.value);
    const endDate = moment(form.controls.endDate.value);
    if (!date.isBetween(startDate, endDate, 'date', '[]')) {
      return false;
    }

    const weekDay = this.momentToWeekDayString(date);
    return form.controls.selectedDays.value.includes(weekDay);
  }

  private momentToWeekDayString(modificationDate: moment.Moment): string {
    const isoWeekday = modificationDate.isoWeekday();
    return constants.iso.weekdays[isoWeekday - 1];
  }

  private instanceTimeToChipTag(instanceTime: string | Moment, dateDiff = 0): ChipTag {
    const instanceMoment = moment(instanceTime).add(dateDiff, 'days');
    const timeString = instanceMoment.format(constants.time.format.pickerModel);
    return {
      id: instanceMoment.format(),
      label: timeString,
      value: timeString,
    };
  }

  private selectedSessionsValidator(modificationIndex: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (this.modificationForms[modificationIndex].controls.allSessions.value) {
        // no need to validate if the all sessions flag is set
        return null;
      }
      if (!control.value) {
        return {
          required: 'Selected sessions is requried',
        };
      }

      const availableSessions = this.getAvailableSessions(modificationIndex).map(
        (session: ChipTag) => session.value,
      );
      const invalidSessions = control.value.filter(
        (session: ChipTag) => !availableSessions.includes(session.value),
      );
      if (invalidSessions.length > 0) {
        return {
          invalidSessions: [invalidSessions.map((session: ChipTag) => session.label)],
        };
      }
      return null;
    };
  }

  private handleModificationFormChange(changes: any) {
    const modificationForm = this.modificationForms[changes.index];

    const validityChanged = this.updateModificationFormValidity(modificationForm, changes);

    if (validityChanged) {
      modificationForm.updateValueAndValidity();
      return;
    }

    if (modificationForm.valid) {
      if (
        modificationForm.controls.modificationType.value === ModificationType.CancelFuture
                || modificationForm.controls.modificationType.value === ModificationType.CancelCurrent
      ) {
        this.updateScheduleExceptionsMap(modificationForm);
      } else {
        // removing exception map entry if this was changed from an exception to a modification
        this.scheduleExceptionsMap.forEach((innerMap) => innerMap.delete(changes.index));
      }

      this.updateScheduleExceptions();
      this.updateModificationSchedules();
    } else if (modificationForm.controls.date.touched) {
      if (!changes.date) {
        // reset the form if they clear out the date field
        // this allows users to effectively remove a modification
        modificationForm.reset({
          allSessions: false,
          index: changes.index,
        });
      }
    }

    this.emitScheduleState();
  }

  private isWithinCreationWindow(selectedDate: Date): boolean {
    const minDate = moment().startOf('day');
    const selectedDateMoment = moment(selectedDate).startOf('day');

    if (!selectedDate) {
      return false;
    }

    if (this._reservationDaysAhead) {
      minDate.add(this._reservationDaysAhead, 'days');
    }

    if (selectedDateMoment.isAfter(minDate, 'day')) {
      return false;
    }

    return true;
  }

  private modificationFormIsWithinScheduleWindow(modificationForm: FormGroup): boolean {
    return modificationForm.controls.isWithinCreationWindow.value;
  }

  private updateModificationFormValidity(modificationForm: FormGroup, changes: any): boolean {
    const selectedSessionsControl = modificationForm.controls.selectedSessions;
    const oldSelectedSessionsValidity = selectedSessionsControl.valid;
    selectedSessionsControl.updateValueAndValidity({ onlySelf: true });

    const isCancellation = this.isWithinCreationWindow(changes.date);
    const modificationTypeControl = modificationForm.controls.modificationType;
    const oldModificationTypeControlValidity = modificationTypeControl.valid;

    if (changes.isWithinCreationWindow !== null && isCancellation !== changes.isWithinCreationWindow) {
      // If the user has moved into or out of the days ahead window
      // remove their ModificationType selection and re-compute form validity
      modificationForm.controls.isWithinCreationWindow.setValue(isCancellation, { emitEvent: false });
      modificationTypeControl.setValue(null, { emitEvent: false });
      modificationTypeControl.updateValueAndValidity({ onlySelf: true });
    }

    const capacityControl = modificationForm.controls.capacity;
    const oldCapacityValidity = capacityControl.valid;
    if (modificationForm.controls.modificationType.value === ModificationType.Capacity) {
      capacityControl.setValidators(Validators.required);
    } else {
      capacityControl.setValidators([]);
    }
    capacityControl.updateValueAndValidity({ onlySelf: true });

    return (
      oldSelectedSessionsValidity !== selectedSessionsControl.valid
            || oldCapacityValidity !== capacityControl.valid
            || oldModificationTypeControlValidity !== modificationTypeControl.valid
    );
  }

  private updateScheduleExceptionsMap(modificationForm: FormGroup) {
    const modificationDate = moment(modificationForm.controls.date.value);
    const scheduleForm = this.getScheduleFormForDate(modificationDate);
    const scheduleIndex = scheduleForm.controls.scheduleIndex.value;
    if (!this.scheduleExceptionsMap.has(scheduleIndex)) {
      this.scheduleExceptionsMap.set(scheduleIndex, new Map());
    }
    const scheduleExceptionEntry = this.scheduleExceptionsMap.get(scheduleIndex);

    const sessions: ChipTag[] = this.getSessionsForModificationForm(modificationForm);
    const modificationExceptions = sessions.map((session) => moment(session.id).format(constants.iCal.format.date));
    // eslint-disable-next-line @typescript-eslint/require-array-sort-compare
    modificationExceptions.sort();
    scheduleExceptionEntry.set(modificationForm.controls.index.value, modificationExceptions);
  }

  private updateScheduleExceptions() {
    // first clear out any existing exceptions
    this.recurringSchedules.forEach((schedule) => {
      schedule.recurrenceExceptions = null;
    });

    this.scheduleExceptionsMap.forEach((innerMap: Map<number, string[]>, scheduleIndex: number) => {
      const schedule = this.recurringSchedules[scheduleIndex];
      const exceptions = [];
      innerMap.forEach((entry) => {
        entry
          .filter((exception) => !exceptions.includes(exception))
          .forEach((exception) => exceptions.push(exception));
      });
      // eslint-disable-next-line @typescript-eslint/require-array-sort-compare
      schedule.recurrenceExceptions = exceptions.sort().join();
    });
  }

  private updateModificationSchedules() {
    this.modificationSchedules = this.modificationForms
      .filter((form) => form.controls.modificationType.value === ModificationType.Capacity)
      .map((form) => this.modificationFormToModificationSchedule(form));
  }

  private modificationFormToModificationSchedule(modificationForm: FormGroup) {
    let schedule: Schedule = { scheduleType: SCHEDULE_TYPE };
    if (modificationForm.controls.scheduleId.value) {
      schedule = this.modificationSchedules.find((s) => s.id === modificationForm.controls.scheduleId.value) || schedule;
    }
    const modificationDate = moment(modificationForm.controls.date.value);
    const scheduleForm = this.getScheduleFormForDate(modificationDate);
    const recurringSchedule = this.recurringSchedules[scheduleForm.controls.scheduleIndex.value];
    schedule.quantity = modificationForm.controls.capacity.value;
    schedule.durationSecs = recurringSchedule.durationSecs;

    const sessions: ChipTag[] = this.getSessionsForModificationForm(modificationForm);
    schedule.instances = sessions.map((session) => ({
      startTime: moment(session.id).format(constants.date.format.isoNoOffset),
    }));

    return schedule;
  }

  private getSessionsForModificationForm(form: FormGroup) {
    let sessions: ChipTag[];
    if (form.controls.allSessions.value) {
      const modificationDate = moment(form.controls.date.value);
      sessions = this.sessionsForDate(modificationDate);
    } else {
      sessions = form.controls.selectedSessions.value;
    }
    return sessions;
  }
}

export interface SchedulesState {
  schedules: Schedule[];
  valid: boolean;
  newCancellationModification: boolean;
}
