import template from './entity-categories.html';
import './entity-categories.less';
import EntityCategoriesController from './entity-categories.controller';

const EntityCategoriesComponent = {
    template,
    bindings: {
        entityType: '@',
        requiredWritePermission: '@',
    },
    controller: [
        '$state',
        'crEntityCategoriesService',
        'crErrorLoggingService',
        'crToastService',
        'crImageService',
        'crNavigationService',
        'crPolicyService',
        EntityCategoriesController,
    ],
};

export default EntityCategoriesComponent;
