import template from './table-container.html';
import Controller from './table-container.controller';
import './table-container.less';

const TableContainerComponent = {
    template,
    transclude: {
        crTable: 'crTable',
        crDataError: 'crDataError',
    },
    bindings: {
        isLoading: '<',
        isTableEmpty: '<',
        noResultsText: '@',
        noResultsIcon: '@?',
        customClass: '@?',
    },
    controller: ['$sce', Controller],
};

export default TableContainerComponent;
