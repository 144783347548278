export default (text) => {
    const required = {
        priority: 10,
        messages: {
            error: text.errors.required,
        },
    };

    const email = {
        validator: (value) => {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            return re.test(value);
        },
        messages: {
            error: text.errors.email,
        },
    };

    const password = {
        validator: (value) => /^[a-zA-Z0-9!@#$%^&*()\-+=_[\]{}|;':",./<>?]*$/.test(value),
        messages: {
            error: text.errors.password,
        },
    };

    const passwordMinlength = {
        priority: 9,
        messages: {
            error: text.errors.passwordMinlength,
        },
    };

    return {
        required,
        email,
        password,
        passwordMinlength,
    };
};
