import controller from './cvt-issues-header.controller';
import template from './cvt-issues-header.html';
import './cvt-issues-header.less';

const component = {
    template,
    bindings: {
        module: '<',
        isExpanded: '<',
    },
    controller: [controller],
};

export default component;
