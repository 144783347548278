class PosEnableService {
    constructor($http) {
        this.$http = $http;
    }

    getCommercePlaceConfig(placeId) {
        return this.$http.get(`/rest/commerce/configs/places/${placeId}`).then((res) => res.data);
    }

    updateCommerceConfig(placeId, config) {
        return this.$http.put(`/rest/commerce/configs/places/${placeId}`, {
            config,
        });
    }

    deactivateCommerce(placeId) {
        return this.$http.patch(`/rest/commerce/configs/places/${placeId}`, {
            commerceEnabled: false,
        });
    }

    getPosAdaptersList() {
        return this.$http.get('/rest/commerce/configs/pos').then((res) => res.data);
    }

    getPosAdapterConfig(eurekaServiceName, placeId) {
        return this.$http.get(`/rest/pos/${eurekaServiceName}/configs/places/${placeId}`).then((res) => res.data);
    }

    validatePosConfig(placeId, pos) {
        return this.$http
            .put(`/rest/pos/${pos.system.eurekaServiceName}/configs/places/${placeId}`, {
                posLocationIdentifier: pos.reference,
                posMenuIdentifier: pos.menu,
                isIntegrated: pos.isIntegrated,
            })
            .then((res) => res.data);
    }

    getWrangleState(eurekaServiceName, placeId) {
        return this.$http.get(`/rest/pos/${eurekaServiceName}/wrangle/places/${placeId}`);
    }

    startWrangling(eurekaServiceName, placeId) {
        return this.$http.post(`/rest/pos/${eurekaServiceName}/wrangle/places/${placeId}`);
    }
}

export default PosEnableService;
