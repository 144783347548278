import angular from 'angular';

import CanvasMapDirective from './canvas-map.directive';
import CanvasMapLocale from './resources/locale/en.json';

const CanvasMapModule = angular
    .module('control-room.commons.data.components.canvas-map', [])
    .constant('crCanvasMapLocale', CanvasMapLocale)
    .directive('crCanvasMap', CanvasMapDirective.directiveFactory);

export default CanvasMapModule;
