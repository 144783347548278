class PolicyDirective {
    constructor(crPolicyService) {
        this.crPolicyService = crPolicyService;
    }

    static directiveFactory(crPolicyService) {
        return new PolicyDirective(crPolicyService);
    }

    link(scope, el, attrs) {
        this.crPolicyService.hasAccess(attrs.crPolicyAction, attrs.crPolicyObject).then((allowed) => {
            if (!allowed) {
                const visibility = attrs.crProhibitedVisibility || 'none';
                const property = visibility === 'none' ? 'display' : 'visibility';
                el.css(property, visibility);
            }
        });
    }
}

PolicyDirective.directiveFactory.$inject = ['crPolicyService'];

export default PolicyDirective;
