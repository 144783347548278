import { Component, ElementRef, EventEmitter, HostListener, Input, Output } from "@angular/core";

@Component({
    selector: 'cr-split-btn',
    templateUrl: './split-button.component.html',
    styleUrls: ['./split-button.component.scss'],
})
export class SplitButtonComponent {
    @Input() label = "";
    isOpen = false;
    @Output() onButtonClick = new EventEmitter<any>();
    
    @HostListener('document:click', ['$event']) toggleOpen(event: Event) {
        if(!this.elRef.nativeElement.contains(event.target)) {
            this.close();
        }
    }

    constructor(private elRef: ElementRef){ }

    close = () => {
        this.isOpen = false;
    }

    toggle = () => {
        this.isOpen = !this.isOpen
    }

    handleButtonClick() {
        this.onButtonClick.emit();
        this.close();
    }
}