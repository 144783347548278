import angular from 'angular';
import ComponentsModule from './components/components';
import ServicesModule from './services/services';
import SelectionManagerModule from './directives/selection-manager';
import DisableContextemenuPropagationModule from './directives/disable-contextmenu-propagation.directive';

const Content = angular.module('control-room.commons.content', [
    ComponentsModule.name,
    ServicesModule.name,
    SelectionManagerModule.name,
    DisableContextemenuPropagationModule.name,
]);

export default Content;
