import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import _ from 'lodash';
import { constants } from '../../services/constants/constants';
import text from './resources/locale/en.json';

export interface ChipTag {
  id: string;
  label: string;
  value?: string;
}

@Component({
  selector: 'cr-chip-selector',
  templateUrl: './chip-selector.component.html',
  styleUrls: ['./chip-selector.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ChipSelectorComponent implements OnInit, OnChanges {
  @Input() sort: string;

  @Input() maxlength: number;

  @Input() options: ChipTag[];

  @Input() currentTags: ChipTag[];

  @Input() crRequired: boolean;

  @Input() addButton = false;

  @Input() canAdd = false;

  @Input() disableRemove = false;

  @Input() isLoading = false;

  @Input() emptyOptionsText: string;

  @Output() onSelect: EventEmitter<ChipTag[]> = new EventEmitter<ChipTag[]>();

  @ViewChild('inputElement', { static: false }) inputElement: ElementRef;

  @ViewChild('dropdown', { static: false }) dropdown: NgbDropdown;

  text: any;

  isFocused = false;

  filteredOptions: ChipTag[];

  currentTagsControl: FormControl;

  inputControl: FormControl;

  constructor() {
    this.text = text;
  }

  ngOnInit(): void {
    this.maxlength = this.maxlength || 32;
    this.emptyOptionsText = this.emptyOptionsText || this.text.emptyOptions;

    const validators = [];
    if (this.crRequired) {
      validators.push(Validators.required);
    }
    this.currentTagsControl = new FormControl(this.currentTags, validators);
    this.inputControl = new FormControl('');
    if (!this.options) {
      this.inputControl.disable();
    }

    this.setFilteredOptions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options && this.inputControl) {
      if (!this.options.length) {
        this.inputControl.disable();
      } else {
        this.inputControl.enable();
      }

      this.setFilteredOptions();
    }
  }

  get input(): string {
    return this.inputControl.value;
  }

  setFilteredOptions() {
    if (!this.options) {
      return;
    }

    let subsetOptions = this.options.filter((option) => !_.find(this.currentTags, (tag) => _.isEqual(tag, option)));

    subsetOptions = _.differenceBy(this.options, this.currentTags, 'id');

    this.filteredOptions = subsetOptions.filter((option) => option.label.includes(this.input));
    this.filteredOptions = this.sortOptions(this.filteredOptions, this.sort);
  }

  sortOptions(items: ChipTag[], dir: string) {
    if (dir) {
      if (dir === constants.sort.ASC || dir === constants.sort.DESC) {
        items = _.sortBy(items, (item: ChipTag) => item.label.toLowerCase());

        if (dir === constants.sort.DESC) {
          items = items.reverse();
        }
      }
    }

    return items;
  }

  trackByTagId(tag: ChipTag): string {
    return tag.id;
  }

  onAddClick(e: Event) {
    e.stopPropagation();

    this.setFilteredOptions();
    this.focusInput();
  }

  setFocus(to: boolean) {
    this.isFocused = to;

    if (!this.isFocused) {
      this.currentTagsControl.markAsTouched();
    } else {
      this.dropdown.open();
    }
  }

  onClick() {
    this.focusInput();
  }

  focusInput() {
    if (this.inputElement) {
      this.inputElement.nativeElement.focus();
    }
  }

  onKeydown(e: KeyboardEvent) {
    if (e.code === 'Backspace' && this.input.length === 0 && this.currentTags) {
      this.currentTags.pop();

      this.update();
    }
    if (e.code === 'Enter' || e.code === 'NumpadEnter') {
      e.stopPropagation();
      e.preventDefault();
      const matchingOption = this.filteredOptions.find((option) => option.label === this.input);
      if (matchingOption) {
        this.addTag(matchingOption);
        this.dropdown.open();
      }
    }
  }

  onKeyup() {
    this.setFilteredOptions();
  }

  update() {
    this.setFilteredOptions();

    this.currentTagsControl.setValue(this.currentTags);
    this.onSelect.emit(this.currentTags);
  }

  onToggle(isOpen: boolean) {
    if (!isOpen) {
      this.currentTagsControl.markAsTouched();
    }
  }

  addTag(tag: ChipTag) {
    this.dropdown.close();
    this.inputControl.setValue('');

    this.currentTags = this.currentTags || [];
    this.currentTags.push(tag);

    this.update();
  }

  removeTag(e: Event, index: number) {
    e.stopPropagation();
    this.currentTags.splice(index, 1);

    // This lets the input tag know that it's actually empty,
    // which is important for the "required" directive to work
    if (_.isEmpty(this.currentTags)) {
      this.currentTags = null;
    }

    this.update();
  }
}
