import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {UsersWithRolesAndVenues} from "./user.model";

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private loggedIn: boolean;

  private user: any;

  constructor(private http: HttpClient) {}

  isLoggedIn() {
    if (this.loggedIn === true) {
      return Promise.resolve();
    }
    if (this.loggedIn === false) {
      return Promise.reject();
    }

    return this.getUser()
      .then(() => {
        this.loggedIn = true;
      })
      .catch(() => {
        this.loggedIn = false;
        return Promise.reject();
      });
  }

  login() {
    this.loggedIn = true;
  }

  logout() {
    this.loggedIn = false;
    this.user = null;
    return this.http.get('/auth/logout').toPromise();
  }

  timeout() {
    this.loggedIn = false;
    this.user = null;
    return this.http.get('/auth/timeout').toPromise();
  }

  getUser() {
    // If the user is missing the policy or model it'll fetch it from the backend again
    if (this.validateUser(this.user)) {
      return Promise.resolve(this.user);
    }

    return this.http
      .get('/auth/me')
      .toPromise()
      .then((res) => {
        this.user = res;
        return this.user;
      });
  }

  validateUser(user) {
    return user && user.policy && user.model;
  }

  getAllUsers() {
    return this.http.get('/rest/users').toPromise();
  }

  getAllUsersWithRolesAndVenues(): Promise<UsersWithRolesAndVenues[]> {
      return this.http.get<UsersWithRolesAndVenues[]>('/rest/users/role/allVenue').toPromise();
  }

  getUsersByRole(venueId, role) {
    return this.http.get(`/rest/users?venueId=${venueId}&role=${role}`).toPromise();
  }
}
