import angular from 'angular';

const FormElemModule = angular
    .module('control-room.commons.forms.directives.form-elem', [])
    .directive('crFormElem', () => ({
        require: '?^form',
        scope: {
            upgradedForm: '<?'
        },
        link: (scope, elem, attrs, form) => {
            const name = attrs.crFormElem;

            if (!name && !scope.upgradedForm) {
                return;
            }

            elem.addClass('cr-form-elem');

            scope.$watch(
                ($scope) => {
                    if (form && form[name]) {
                        return form[name].$invalid;
                    }

                    if ($scope.upgradedForm) {
                        return $scope.upgradedForm.invalid
                    }

                    return false;
                },
                (invalid) => {
                    if (invalid) {
                        elem.addClass('ng-invalid');
                    } else {
                        elem.removeClass('ng-invalid');
                    }
                }
            );

            scope.$watch(
                ($scope) => {
                    if (form && form[name]) {
                        return form[name].$touched;
                    }

                    if ($scope.upgradedForm) {
                        return $scope.upgradedForm.touched
                    }

                    return false;
                },
                (touched) => {
                    if (touched) {
                        elem.addClass('ng-touched');
                    } else {
                        elem.removeClass('ng-touched');
                    }
                }
            );
        },
    }));

export default FormElemModule;
