import angular from 'angular';

import DataErrorModule from './data-error/data-error';
import FiltersContainerModule from './filters-container/filters-container';
import FootNoteModule from './foot-note/foot-note';
import IdentityBarModule from './identity-bar/identity-bar';
import LayoutModule from './layout/layout';
import ModalModule from './modal/modal';
import NavPanelModule from './nav-panel/nav-panel';
import SplitViewPageModule from './split-view-page/split-view-page';
import TabsModule from './tabs/tabs';
import TableContainerModule from './table-container/table-container';
import TitleBarModule from './title-bar/title-bar';
import PageNotFoundModule from './page-not-found/page-not-found';
import AccessDeniedModule from './access-denied/access-denied';

const ComponentsModule = angular.module('control-room.commons.layout.components', [
    DataErrorModule.name,
    FiltersContainerModule.name,
    FootNoteModule.name,
    IdentityBarModule.name,
    LayoutModule.name,
    ModalModule.name,
    NavPanelModule.name,
    SplitViewPageModule.name,
    TabsModule.name,
    TableContainerModule.name,
    TitleBarModule.name,
    PageNotFoundModule.name,
    AccessDeniedModule.name,
]);

export default ComponentsModule;
