import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FnbFee } from './fees.model';

@Injectable({
  providedIn: 'root',
})
export class FeesService {
  constructor(private http: HttpClient) { }

  public getFeesByVenue(venueId: string): Observable<FnbFee[]> {
    return this.http.get<FnbFee[]>(`/rest/fees/admin/fees/venue/${venueId}`);
  }

  public createFee(fee: FnbFee): Observable<FnbFee> {
    return this.http.post<FnbFee>('/rest/fees/admin/fees', fee);
  }

  public getFeeById(feeId: string): Observable<FnbFee> {
    return this.http.get<FnbFee>(`/rest/fees/admin/fees/${feeId}`);
  }

  public updateFee(fee: FnbFee): Observable<FnbFee> {
    return this.http.put<FnbFee>('/rest/fees/admin/fees', fee);
  }

  public deleteFee(feeId: string): Observable<FnbFee> {
    return this.http.delete<FnbFee>(`/rest/fees/admin/fees/${feeId}`);
  }
}
