<section>
  <cr-title-bar
    icon="icon-cr-back"
    [label]="kitchen && kitchen.name"
    (onClick)="onBack()"
  >
  </cr-title-bar>

  <!-- Search bar -->
  <div *ngIf="!isLoading" class="queue-header">
    <div class="queue-title">{{ text.title }}</div>
    <cr-venue-hours [kitchen]="kitchen"></cr-venue-hours>
    <div class="search">
      <div class="search-bar">
        <span *ngIf="!isSearching" class="icon icon-cr-search"></span>
        <span
          *ngIf="isSearching"
          (click)="clearSearch()"
          class="icon icon-cr-chevron-left"
        ></span>
        <input
          #searchOrders
          [value]="searchQuery"
          [placeholder]="searchPlaceholder()"
          (keyup)="searchChanged(searchOrders.value)"
          (focus)="searchFocus()"
          (blur)="searchBlur()"
        />
      </div>
      <div class="advanced-search" (click)="advancedSearch()">
        {{ text.advancedSearch }}
      </div>
      <div
        class="advanced-search"
        (click)="openModal(override, ptwOverrideModalConfig)"
        *ngIf="isPtwEnabled"
      >
        {{ text.managePickupWindows }}
      </div>
    </div>
    <div *ngIf="isSearching" class="search-instructions">
      {{ text.searchInstruction }}
    </div>
  </div>

  <!-- Tabs -->
  <div *ngIf="!isLoading && !isSearching" class="queue-controls scroll-fix">
    <div class="shadow">
      <div class="tabs adjustable-padding">
        <div
          class="tab"
          [ngClass]="{ selected: selectedTab === 'SUBMITTED' }"
          *ngIf="isPtwEnabled"
          (click)="selectTab('SUBMITTED')"
        >
          {{ text.status.SUBMITTED }} ({{ counts.SUBMITTED }})
        </div>
        <div
          class="tab"
          [ngClass]="{ selected: selectedTab === 'UNFULFILLED' }"
          (click)="selectTab('UNFULFILLED')"
        >
          {{ text.status.UNFULFILLED }} ({{ counts.UNFULFILLED }})
        </div>
        <div
          class="tab"
          [ngClass]="{ selected: selectedTab === 'IN_PROCESS' }"
          (click)="selectTab('IN_PROCESS')"
        >
          {{ text.status.IN_PROCESS }} ({{ counts.IN_PROCESS }})
        </div>
        <div
          class="tab"
          [ngClass]="{ selected: selectedTab === 'FULFILLED' }"
          (click)="selectTab('FULFILLED')"
        >
          {{ text.status.FULFILLED }} ({{ counts.FULFILLED }})
        </div>
        <div
          class="tab"
          *ngIf="isRefundsEnabled"
          [ngClass]="{ selected: selectedTab === 'REFUNDED' }"
          (click)="selectTab('REFUNDED')"
        >
          {{ text.status.REFUNDED }} ({{ counts.REFUNDED }})
        </div>
        <div class="export-tab">
          <cr-export-button
            cr-feature-flags="export_order"
            type="order"
            baseFilename="{{ exportFilename }}"
          ></cr-export-button>
        </div>
      </div>
      <div *ngIf="orders.length > 0">
        <div class="sort-row adjustable-margin">
          <div
            class="sort"
            *ngIf="
              ordersFiltered.length > 0 &&
              !kitchen.features.pickupTimeWindowsEnabled
            "
          >
            {{ text.sortRecent }}
            <span class="icon icon-cr-sort-ascending"></span>
          </div>
          <div
            class="sort ptw-filter"
            *ngIf="kitchen.features.pickupTimeWindowsEnabled && isPtwEnabled"
            (click)="sortByPTW(sortTypeAsc)"
          >
            <span
              class="icon"
              [ngClass]="
                sortTypeAsc
                  ? 'icon-cr-sort-ascending'
                  : 'icon-cr-sort-descending'
              "
            ></span>
            {{ text.sortByPtw }}
          </div>
          <!-- PTW Filter -->
          <div
            class="list-filters"
            *ngIf="
              orders.length > 0 &&
              !isLoading &&
              !isSearching &&
              kitchen.features.pickupTimeWindowsEnabled &&
              isPtwEnabled
            "
          >
            <div class="btn-group" dropdown>
              <button
                id="button-basic"
                dropdownToggle
                type="button"
                class="btn btn-link dropdown-toggle"
                aria-controls="dropdown-basic"
              >
                PTW filter: {{ ptwFilterText }}
                <span class="caret"></span>
              </button>
              <ul
                id="dropdown-basic"
                *dropdownMenu
                class="dropdown-menu"
                role="menu"
                aria-labelledby="button-basic"
              >
                <li *ngFor="let ptw of ptws" role="menuitem">
                  <cr-ptw-list-item
                    class="dropdown-item"
                    (selectPTW)="selectPTWFilter($event)"
                    [ptw]="ptw"
                  ></cr-ptw-list-item>
                </li>
              </ul>
            </div>
          </div>
          <div class="last-updated">{{ getPageAge() }}</div>
        </div>
      </div>
    </div>
  </div>

  <!-- Order List -->
  <div *ngIf="!isLoading" class="content">
    <!-- Orders -->
    <section class="orders" *ngIf="ordersFiltered.length > 0">
      <div *ngFor="let order of ordersFiltered">
        <cr-order-list-item
          [order]="order"
          [selectedTab]="selectedTab"
          [text]="text"
          [isSearching]="isSearching"
          [writeAllowed]="isWriteAllowed"
          [isPrintingEnabled]="isPrintingEnabled"
          [kitchenId]="state.params.kitchenId"
          (updateFilter)="updateFilter()"
          (updateTab)="selectTab($event)"
        ></cr-order-list-item>
      </div>
    </section>
    <!-- Empty Order -->
    <section
      class="empty-queue"
      *ngIf="ordersFiltered.length === 0 && !isSearching"
    >
      <div class="icon-container">
        <span class="icon icon-cr-receipt"></span>
      </div>
      <div>{{ text.emptyQueue }}</div>
      <div *ngIf="selectedTab === 'UNFULFILLED' && !isRefreshing">
        <div>{{ text.refreshPrompt }}</div>
        <div class="refresh-now" (click)="loadOrders()">
          {{ text.refreshNow }}
        </div>
      </div>
      <div *ngIf="selectedTab === 'UNFULFILLED' && isRefreshing">
        <cr-spinner></cr-spinner>
      </div>
    </section>
  </div>
  <!-- Loading Spinner -->
  <div
    *ngIf="!hasError && isLoading"
    class="cr-page-padding-top cr-flex-center"
  >
    <cr-spinner></cr-spinner>
  </div>
</section>

<ng-template #override>
  <cr-ptw-override [modalRef]="modalRef" [orders]="orders"> </cr-ptw-override>
</ng-template>
