import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BundleGroupListData } from './bundle-groups.model';

@Injectable({ providedIn: 'root' })
export class BundleGroupsService {
  constructor(private httpClient: HttpClient) { }

  getBundleGroupsList(placeId: string, menuId: string): Observable<BundleGroupListData[]> {
    const url = `rest/bundle-groups/admin/places/${placeId}/menus/${menuId}/bundle-groups`;
    return this.httpClient.get<BundleGroupListData[]>(url);
  }

  getBundleGroupById(placeId: string, menuId: string, bundleGroupId: string): Observable<BundleGroupListData> {
    const url = `rest/bundle-groups/admin/places/${placeId}/menus/${menuId}/bundle-groups/${bundleGroupId}`;
    return this.httpClient.get<BundleGroupListData>(url);
  }

  createBundleGroup(placeId: string, menuId: string, bundleGroupData: BundleGroupListData): Observable<BundleGroupListData> {
    const url = `rest/bundle-groups/admin/places/${placeId}/menus/${menuId}/bundle-groups`;
    return this.httpClient.post<BundleGroupListData>(url, bundleGroupData);
  }

  updateBundleGroupById(placeId: string, menuId: string, bundleGroupId: string, bundleGroupData: BundleGroupListData): Observable<BundleGroupListData> {
    const url = `rest/bundle-groups/admin/places/${placeId}/menus/${menuId}/bundle-groups/${bundleGroupId}`;
    return this.httpClient.put<BundleGroupListData>(url, bundleGroupData);
  }

  deleteBundleGroupById(placeId: string, menuId: string, bundleGroupId: string): Observable<BundleGroupListData> {
    const url = `rest/bundle-groups/admin/places/${placeId}/menus/${menuId}/bundle-groups/${bundleGroupId}`;
    return this.httpClient.delete<BundleGroupListData>(url);
  }
}
