import text from './resources/locale/en.json';

class FormSubmissionError {
    $onInit() {
        this.text = text;
        this.errorText = this.overrideText || this.text.errorText;
    }
}

export default FormSubmissionError;
