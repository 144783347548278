import _ from 'lodash';
import text from './resources/locale/en.json';

class PreferencesPhonePreview {
    constructor(crConstants) {
        this.crConstants = crConstants;
    }

    $onInit() {
        this.text = text;
    }

    $onChanges(changes) {
        if (changes.brand && this.brand && this.brand.screens[0].tags) {
            this.brand = _.cloneDeep(this.brand);
            this.isBrandActive = this.brand.state === this.crConstants.entity.states.ACTIVE;
            this.addDescriptionDefaults(this.brand.localization);
            this.addTagDefaults(this.brand.screens[0].tags);
        }

        if (changes.venue && this.venue && this.venue.screens[0].tags) {
            this.venue = _.cloneDeep(this.venue);
            this.isVenueActive = this.venue.state === this.crConstants.entity.states.ACTIVE;
            this.addDescriptionDefaults(this.venue.localization);
            this.addTagDefaults(this.venue.screens[0].tags);
        }
    }

    addDescriptionDefaults(localization, defaultLocale = this.locale.default) {
        _.forEach(localization, (locale) => {
            if (!locale.screens) {
                locale.screens = [{}];
            }

            if (!locale.screens[0].descriptionTitle) {
                locale.screens[0].descriptionTitle = localization[defaultLocale].screens[0].descriptionTitle;
            }

            if (!locale.screens[0].descriptionText) {
                locale.screens[0].descriptionText = localization[defaultLocale].screens[0].descriptionText;
            }
        });
    }

    addTagDefaults(tags, defaultLocale = this.locale.default) {
        _.forEach(tags, (tag) => {
            _.forEach(tag.tagDefinition.localization, (locale) => {
                if (!locale.title) {
                    locale.title = tag.tagDefinition.localization[defaultLocale].title;
                }

                if (!locale.label) {
                    locale.label = tag.tagDefinition.localization[defaultLocale].label;
                }

                locale.values.forEach((value, index) => {
                    const { values } = tag.tagDefinition.localization[defaultLocale];

                    if (values[index]) {
                        value.label = value.label || values[index].label;
                    }
                });
            });
        });
    }
}

export default PreferencesPhonePreview;
