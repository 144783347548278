import template from './associated-entities-list.html';
import './associated-entities-list.less';

const component = {
    template,
    bindings: {
        entities: '<',
    },
};

export default component;
