import { AppNavData } from './app-nav-data';

export const APP_ORIGIN = 'https://applink.te2.io';

export const baseData: { [key: string]: AppNavData } = {
  couponDetails: {
    origin: APP_ORIGIN,
    context: 'venue',
    contextId: '.+',
    category: 'coupon',
    feature: 'details',
    query: {
      couponId: '.+',
    },
  },
  eventDetails: {
    origin: APP_ORIGIN,
    context: 'venue',
    contextId: '.+',
    category: 'events',
    feature: 'details',
    query: {
      eventId: '.+',
    },
  },
  diningMenu: {
    origin: APP_ORIGIN,
    context: 'venue',
    contextId: '.+',
    category: 'commerce',
    feature: 'menus',
    query: {
      placeId: '.+',
    },
  },
  guestPreferencesBrand: {
    origin: APP_ORIGIN,
    context: 'app',
    category: 'me',
    feature: 'preferences',
  },
  guestPreferencesVenue: {
    origin: APP_ORIGIN,
    context: 'venue',
    contextId: '.+',
    category: 'me',
    feature: 'preferences',
  },
  poiDetails: {
    origin: APP_ORIGIN,
    context: 'venue',
    contextId: '.+',
    category: 'map',
    feature: 'poi',
    subFeature: 'details',
    query: {
      poiId: '.+',
    },
  },
  productDetails: {
    origin: APP_ORIGIN,
    context: 'venue',
    contextId: '.+',
    category: 'products',
    feature: 'details',
    query: {
      productId: '.+',
    },
  },
  webview: {
    origin: APP_ORIGIN,
    context: 'venue',
    contextId: '.+',
    category: 'webview',
    query: {
      url: '.+',
      title: '.*',
    },
  },
  loQueueProductDetails: {
    origin: APP_ORIGIN,
    context: 'venue',
    contextId: '.+',
    category: 'loqueue',
    feature: 'products',
    subFeature: 'details',
    query: {
      productId: '.+',
    },
  },
  loQueueRideDetails: {
    origin: APP_ORIGIN,
    context: 'venue',
    contextId: '.+',
    category: 'loqueue',
    feature: 'ride',
    subFeature: 'details',
    query: {
      poiId: '.+',
      rideId: '.+',
    },
  },
};
