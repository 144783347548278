<span
    class="cr-error-icon icon-cr-error"
    data-global-qa-id="change-password-error-icon"
    [ngClass]="{ 'cr-invisible': !showErrorIcon }"
>
</span>
<h1 data-global-qa-id="change-password-title">{{ title }}</h1>
<div data-global-qa-id="change-password-sub-title" class="cr-sub-title">
    {{ subtitle }}
</div>

<form [formGroup]="form" (submit)="submit()">
    <div class="cr-inputs">
        <div class="cr-inputs-current">
            <label for="username">{{ text.username.label }}</label>
            <cr-input
                formControlName="username"
                class="stretch"
                [placeholder]="text.username.placeholder"
            ></cr-input>

            <label for="oldPassword">{{ text.oldPassword }}</label>
            <cr-input
                class="stretch"
                type="password"
                formControlName="oldPassword"
                showValidMessage
            >
            </cr-input>
        </div>

        <label for="newPassword">{{ text.newPassword }}</label>
        <cr-input
            class="stretch"
            type="password"
            formControlName="newPassword"
            [validationMessages]="validationMessages"
            showValidMessage
            (keyup)="validatePassword($event.target.value)"
            (blur)="onBlur()"
            (focus)="onFocus()"
        >
        </cr-input>

        <div class="password-requirements-wrapper">
            <div class="password-requirements-header">
                <span class="icon icon-cr-info"></span>    
                <span>New password must meet the following minimum requirements:</span>
            </div>
            <div class="password-requirement">
                <div class="icon icon-cr-done requirement-indicator"
                     [ngClass]="{ 'requirement-met': atLeast8Characters === true, 'not-met': atLeast8Characters === null }"></div>
                <span>Minimum of 8 characters</span>
            </div>
            <div class="password-requirement">
                <div class="icon icon-cr-done requirement-indicator"
                     [ngClass]="{ 'requirement-met': hasUpperCase === true, 'not-met': hasUpperCase === null }"></div>
                <span>An uppercase letter (A-Z)</span>
            </div>
            <div class="password-requirement">
                <div class="icon icon-cr-done requirement-indicator"
                     [ngClass]="{ 'requirement-met': hasLowerCase === true, 'not-met': hasLowerCase === null }"></div>
                <span>A lowercase letter (a-z)</span>
            </div>
            <div class="password-requirement">
                <div class="icon icon-cr-done requirement-indicator"
                     [ngClass]="{ 'requirement-met': hasNumber === true, 'not-met': hasNumber === null }"></div>
                <span>A number (0-9)</span>
            </div>
        </div>

        <label for="confirmNewPassword">{{ text.newPasswordConfirm }}</label>
        <cr-input
            class="stretch"
            type="password"
            formControlName="confirmNewPassword"
            [validationMessages]="validationMessages"
            showValidMessage
        >
        </cr-input>

        <button
            class="btn btn-primary"
            data-global-qa-id="change-password-button"
            [disabled]="!form.valid || pending"
        >
            <div
                class="cr-submit-spinner"
                [ngClass]="{ 'cr-invisible': !pending }"
            >
                <cr-spinner></cr-spinner>
            </div>
            <span [ngClass]="{ 'cr-invisible': pending }">{{
                text.submit
            }}</span>
        </button>
    </div>
</form>
