class PoiMapTooltipController {
    constructor($state, crAnalyticsService) {
        this.$state = $state;
        this.crAnalyticsService = crAnalyticsService;
    }

    $onInit() {
        // By default, link opens in the current page.
        // Specify tooltip-target="_blank" on the canvas map component to open in a new tab.
        this.target = this.target || '_self';
    }

    goToDetailsPage(id) {
        const params = {
            id,
            customerId: this.$state.params.customerId,
        };

        this.crAnalyticsService.track('POI Map Title Link Clicked', { id });
        this.$state.go('client.poi.details', params);
    }
}

const PoiMapTooltipComponent = {
    template:
        '<a class="details-link" ui-sref="client.poi.details({ id: $ctrl.resolve.id })" target="{{ ::$ctrl.target }}">{{ ::$ctrl.resolve.label }}</a>',

    bindings: {
        resolve: '<',
        target: '@',
    },
    controller: ['$state', 'crAnalyticsService', PoiMapTooltipController],
};

export default PoiMapTooltipComponent;
