import angular from 'angular';

import component from './menu-categories.component';
import service from './menu-categories.service';

const MenuCategories = angular
    .module('control-room.menus.components.menu-categories', [])
    .component('crMenuCategories', component)
    .service('crMenuCategoriesService', ['crEntityService', 'crConstants', service]);

export default MenuCategories;
