import angular from 'angular';

import PositionStickyModule from './position-sticky/position-sticky';
import InfiniteScrollModule from './infinite-scroll/infinite-scroll';

const DirectivesModule = angular.module('control-room.commons.layout.directives', [
    PositionStickyModule.name,
    InfiniteScrollModule.name,
]);

export default DirectivesModule;
