<section 
    class="cr-title-bar" 
    data-global-qa-id="title-bar"
    [ngClass]="{ 'cr-disabled': disabled || disableNavigation, 'extra-if-no-icon': !icon }"
    crPositionSticky
    crPositionStickyIndex="{{ stickyIndex }}">
    <section>
        <div
            *ngIf="icon"
            class="title-bar-icon {{ icon }} {{
                icon === 'icon-cr-back' && 'cursor-pointer'
            }}"
            (click)="handleClick()"
            data-local-qa-id="goBack"
        ></div>
        <div *ngIf="label" class="title cr-title">{{ label }}</div>
    </section>

    <ng-content select="[titleBarCenter]"></ng-content>

    <ng-content select="[titleBarRight]"></ng-content>
</section>
