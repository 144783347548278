import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import moment from 'moment';
import { DiscountType, Product, TaxCode } from './commerce.model';

@Injectable({
  providedIn: 'root',
})
export class CommerceService {
  constructor(private http: HttpClient) {
  }

  ordersReports(placeIds: string[], timeframe: {start: string, end: string}, channel: string, interval: string, productLimit?: number, raw?: boolean, currency?: string): Promise<any> {
    
    return this.http
            .post('/rest/commerce/orders', {
                placeIds,
                timeframe: {
                    start: moment(timeframe.start).format('YYYY-MM-DD'),
                    end: moment(timeframe.end).format('YYYY-MM-DD'),
                },
                channel,
                interval,
                productLimit,
                currency,
            }).toPromise()
            .then((data: any) => {
                
                if (raw) {
                    return data;
                }

                const ordersPlaced = {
                    labels: [],
                    series: [[]],
                };

                const topItemsPurchased = {
                    labels: [],
                    series: [[]],
                };

                data[0].orderCount.forEach((sample) => {
                    ordersPlaced.labels.push(moment(sample.timeStamp).format('MMM D'));

                    ordersPlaced.series[0].push({
                        value: sample.count,
                        meta: sample.revenue,
                        currency: sample.currency,
                    });
                });

                data[0].productCount.forEach((sample) => {
                    topItemsPurchased.labels.push(sample.displayName);

                    topItemsPurchased.series[0].push({
                        value: sample.count,
                        meta: sample.displayName,
                    });
                });

                return {
                    ordersPlaced,
                    topItemsPurchased,
                };
            });
    
  }

  placesReports(timeframe: {start: string, end: string}, channel?: string, sortField?: string, sortOrder?: string, limit?: number): Promise<any>  {
      let interval: any;
      return this.http.post('/rest/commerce/places', {
                  placeIds: [],
                  timeframe: {
                      start: moment(timeframe.start).format('YYYY-MM-DD'),
                      end: moment(timeframe.end).format('YYYY-MM-DD'),
                  },
                  channel,
                  interval,
                  limit,
                  sortField,
                  sortOrder,
              }).toPromise()
              .then((data: any) => {

                  const rows = [];

                  data.forEach((sample) => {
                      rows.push({
                          data: {
                              place: {
                                  id: sample.placeId,
                                  name: sample.placeName,
                              },
                              orders: sample.count,
                              revenue: sample.revenue,
                          },
                      });
                  });

                  return {
                      columns: [{ label: 'Place' }, { label: 'Orders placed' }, { label: 'Revenue' }],
                      rows,
                  };
              });
      
  }

  getProducts(venueId: string): Promise<Product> {
      return this.http.get<Product>(`/rest/commerce/${venueId}/products`).toPromise();
  }

  getDiscountTypes(): Promise<DiscountType[]> {
      return this.http.get<DiscountType[]>('/rest/commerce/discountTypes').toPromise();
  }

  /**
     *
     * @param id  This will take a placeId or a venueId
  */
  getTaxCodes(placeId: string): Promise<TaxCode[]> {
      return this.http.get<TaxCode[]>(`/rest/commerce/${placeId}/tax-codes`).toPromise();
  }

  
}
