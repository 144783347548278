/* eslint-disable dot-notation */
import { Component, OnInit, Inject } from '@angular/core';
import { StateService } from '@uirouter/core';
import { WINDOW } from '../shared';

@Component({
  selector: 'cr-uac',
  templateUrl: './uac.component.html',
  styleUrls: ['./uac.component.scss'],
})
export class UacComponent implements OnInit {
  constructor(@Inject(WINDOW) private window: Window, private state: StateService) {}

  ngOnInit(): void {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    if (typeof this.window['Promise'] === 'undefined') {
      this.state.go('legacy-browser');
    }
  }
}
