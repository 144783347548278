import { Component, Inject, OnInit } from '@angular/core';
import { Constants, LocalizedText } from "../../../../core";
import text from '../../resources/locale/en.json';
import { StateService } from "@uirouter/core";
import { EntityService } from "../../../../shared/services/entity/entity.service";
import { LocaleService } from "../../../../shared/services/locale/locale.service";
import { PoiService } from "../../../../shared/services/poi/poi.service";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import {
    AnalyticsService,
    CR_CONSTANTS,
    ErrorLoggingService,
    NavigationService,
    VenueService
} from "../../../../shared";
import {
    difficultyLabelList,
    iconTypeList,
    ListType,
    statusList,
    trailIconList,
    typeList,
    daysList
} from "../../constants/options";
import { getAutoStatusInfo, getIconUrl, timeTo24HourDecimal } from "../../common/common.util";
import { ToastService } from "../../../../ng1-factories";
import { InternalLegacyStatuses, SNOAA_Type } from "../../constants/statuses";

@Component({
    selector: 'cr-edit-details',
    templateUrl: './edit-details.component.html',
    styleUrls: ['./edit-details.component.scss']
})
export class EditDetailsComponent implements OnInit {

    text: LocalizedText = text;
    poi: any;
    locale: any;
    poiExtension: any;
    legacyStatus: any;
    entityType: any;
    isLoading = false;
    isLift = false;
    isTrail = false;
    isSaving = false;
    hasDataError = false;
    isActivity = false;
    isEdit = false;
    categoriesLoading = false;
    statusList = statusList;
    typeOptions = typeList;
    difficultyOptions = difficultyLabelList;
    iconTypeOptions = iconTypeList;
    trailIconOptions = trailIconList;
    daysOptions = daysList;
    title: string;
    timezone: string;
    label = '';
    editForm: FormGroup;
    selectedType: string;
    selectedIconType: string;
    selectedTrailIcon: string;
    selectedDifficultyId: string;
    iconSrc: string;
    isGlades: boolean;
    isGrooming: boolean;
    isMoguls: boolean;
    isNightSkiing: boolean;
    isSnowMaking: boolean;
    tabId: ListType = 'lift';
    operationStatus: any = { label: '', status: '', color: '' };

    constructor(
        @Inject(CR_CONSTANTS) public constants: Constants,
        public state: StateService,
        private crEntityService: EntityService,
        private crLocaleService: LocaleService,
        private crPoiService: PoiService,
        private crNavigationService: NavigationService,
        private crErrorLoggingService: ErrorLoggingService,
        private crToastService: ToastService,
        private crAnalyticsService: AnalyticsService,
        private formBuilder: FormBuilder,
        venueService: VenueService,
    ) {
        this.timezone = venueService.getTimezone(state.params.venueId).name;
    }

    ngOnInit(): void {
        this.tabId = this.state.params.tabId;
        this.locale = this.crLocaleService.getLocale(this.state.params.venueId, this.state.params.locale);
        this.label = this.isEdit ? this.text.editPOI : this.text.createPOI;
        this.entityType = this.constants.entity.types.POIS;

        this.editForm = this.formBuilder.group({
            mountainArea: ['', Validators.required],
            waitTime: [''],
            title:['',Validators.required],
            ...(this.daysOptions.reduce((controls, opt) => ({
                ...controls,
                [opt.key + '_open']: [''],
                [opt.key + '_close']: [''],
            }), {}))
        }, {validators: this.createRangeValidator.bind(this)});

        this.getData(this.entityType, this.state.params.id, this.state.params.venueId, this.locale.current);
    }

    createRangeValidator(fg: FormControl) {
        const errors: any = {};
        for (const {key} of this.daysOptions) {
            const open = fg.get(`${key}_open`);
            const close = fg.get(`${key}_close`);

            if (open.value || close.value) {
                if (!open.value) {
                    errors[`${key}_open_error`] = this.text.invalidTimeRange;
                } else if (!close.value) {
                    errors[`${key}_close_error`] = this.text.invalidTimeRange;
                } else {
                    const start = timeTo24HourDecimal(open.value);
                    const end = timeTo24HourDecimal(close.value);
                    if (start > end) {
                        errors[`${key}_close_error`] = this.text.invalidTimeRange;
                    }
                }
            }
        }
        return errors;
    }

    getData(entityType, id, venueId, locale) {
        this.isLoading = true;
        this.categoriesLoading = true;

        this.crEntityService.getEntity(entityType, id, venueId, locale, entityType)
            .then((results: any) => {
                if (results) {
                    this.poi = results;

                    const type = this.state.params.tabId;
                    this.isLift = type === SNOAA_Type.LIFT;
                    this.isTrail = type === SNOAA_Type.TRAIL;
                    this.isActivity = type === SNOAA_Type.ACTIVITY;

                    const legacyData = this.poi.extensions.find(e => e.id === InternalLegacyStatuses.INTERNAL_LEGACY_CURRENT_STATUS);
                    this.legacyStatus = legacyData.value;
                    if (!this.legacyStatus)
                        return;

                    for (const ex of this.poi.extensions) {
                        if (ex.id.match(/^internalLift/) && this.isLift) {
                            this.poiExtension = ex.value || {};
                            this.title = this.poiExtension.name;
                            break;
                        }
                        if (ex.id.match(/^internalTrail/) && this.isTrail) {
                            this.poiExtension = ex.value || {};
                            this.title = this.poiExtension.name;
                            break;
                        }
                        if (ex.id.match(/^internalActivity/) && this.isActivity) {
                            this.poiExtension = ex.value || {};
                            this.title = this.poiExtension.name;
                            break;
                        }
                    }

                    this.setOperationStatusValues();

                    this.typeOptions = this.typeOptions.map(t => ({
                        ...t,
                        isSelected: t.id === this.poiExtension.SNOAAType
                    }));
                    this.difficultyOptions = this.difficultyOptions.map(d => ({
                        ...d,
                        isSelected: d.id === this.poiExtension.difficulty
                    }));
                    this.iconTypeOptions = this.iconTypeOptions.map(i => ({
                        ...i,
                        isSelected: i.id === this.poiExtension.type
                    }));
                    this.trailIconOptions = this.trailIconOptions.map(tr => ({
                        ...tr,
                        isSelected: tr.id === this.poiExtension.trailIcon
                    }));

                    this.isGlades = this.poiExtension.glades;
                    this.isGrooming = this.poiExtension.grooming;
                    this.isMoguls = this.poiExtension.moguls;
                    this.isNightSkiing = this.poiExtension.nightSkiing;
                    this.isSnowMaking = this.poiExtension.snowMaking;
                    this.iconSrc = this.poiExtension.type ? getIconUrl(this.poiExtension.SNOAAType, this.poiExtension.type, this.text.imageUrl) : getIconUrl(this.poiExtension.SNOAAType, this.poiExtension.trailIcon, this.text.imageUrl)

                    this.editForm.patchValue({
                        mountainArea: this.poiExtension.mountainArea,
                        waitTime: this.legacyStatus?.waitTime,
                        ...(this.daysOptions.reduce((controls, opt) => ({
                            ...controls,
                            [opt.key + '_open']: this.poiExtension?.hours?.[opt.key]?.open ?? '',
                            [opt.key + '_close']: this.poiExtension?.hours?.[opt.key]?.close ?? '',
                        }), {}))
                    });
                }
            })
            .catch((err) => {
                this.crErrorLoggingService.logError('Could not get poi details', err, id);

                if (err.status === 404) {
                    this.state.go('client.404', {
                        customerId: this.state.params.customerId,
                        venueId,
                    });
                } else {
                    this.hasDataError = true;
                }
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    setOperationStatusValues() {
        this.operationStatus = (this.poiExtension.autoStatus)
            ? getAutoStatusInfo(this.poiExtension, this.text, this.timezone)
            : {
                label: this.poiExtension.statusLabel ?? '',
                status: this.legacyStatus.operationalStatus?.toLowerCase() ?? '',
                color: this.poiExtension.statusColor
            };

        this.statusList = this.statusList
            .filter(s => (s.visibleIn.includes(this.tabId)))
            .map(s => ({
            ...s,
            isSelected: s.id === this.operationStatus.label
        }));
    }

    onChangeOperationStatus(labelId) {
        this.poiExtension.autoStatus = false;
        this.poiExtension.statusLabel = labelId;

        if (labelId) {
            const newStatus = statusList.find(s => s.id === labelId);
            this.poiExtension.statusLabel = newStatus?.id;
            this.poiExtension.statusColor = newStatus?.color;
            this.legacyStatus.operationalStatus = newStatus?.type;
        }

        this.setOperationStatusValues();
    }

    onToggleAutoStatus() {
        this.poiExtension.autoStatus = !this.poiExtension.autoStatus;
        this.setOperationStatusValues();
    }

    handleError(errMsg, err) {
        this.crErrorLoggingService.logError(errMsg, err);
    }

    goBack() {
        if (this.isEdit) {
            this.crAnalyticsService.track('Edit Canceled');
            this.state.go('client.lifts-trails-activities.list', {
                id: this.poi.id,
                tabId: this.state.params.tabId
            });
        }
    }

    onCancel() {
        this.crAnalyticsService.track('Edit Canceled');
        this.state.go('client.lifts-trails-activities.list', {
            id: this.poi.id,
            tabId: this.state.params.tabId
        });
    }

    onSave() {
        if (!this.editForm.valid) {
            this.hasDataError = true;
            return;
        }

        this.hasDataError = false;
        const newValues: any = this.editForm.value;
        this.isSaving = true;

        this.poiExtension.mountainArea = newValues.mountainArea;
        this.poiExtension.name = newValues.title;
        this.poiExtension.hours = this.poiExtension.hours ?? {};
        this.daysOptions.forEach(opt => {
            const timeRange = this.poiExtension.hours?.[opt.key] ?? {};
            timeRange.open = newValues[opt.key + '_open'];
            timeRange.close = newValues[opt.key + '_close'];
            this.poiExtension.hours[opt.key] = timeRange;
        });

        this.legacyStatus.waitTime = newValues.waitTime;
        this.poiExtension.glades = this.isGlades;
        this.poiExtension.grooming = this.isGrooming;
        this.poiExtension.moguls = this.isMoguls;
        this.poiExtension.nightSkiing = this.isNightSkiing;
        this.poiExtension.snowMaking = this.isSnowMaking;

        if (this.selectedType) {
            this.poiExtension.SNOAAType = this.selectedType;
        }

        if (this.selectedIconType) {
            this.poiExtension.type = this.selectedIconType;
        }

        if (this.selectedTrailIcon) {
            this.poiExtension.trailIcon = this.selectedTrailIcon;
        }

        if (this.selectedDifficultyId) {
            this.poiExtension.difficulty = this.selectedDifficultyId;
        }

        const poiToSave = {
            ...this.poi,
            extensions: this.poi.extensions.map(ex => ({
                ...ex,
                value: JSON.stringify(ex.value)
            }))
        };

        const payload = this.crEntityService.prepareEntityPayload(poiToSave);
        const {venueId} = this.state.params;
        this.crEntityService
            .updateEntity(this.entityType, this.poi.id, payload, venueId)
            .then(() => {
                this.crToastService.toast('Data successfully updated.', '');
                this.state.go('client.lifts-trails-activities.details', {
                    id: this.poi.id,
                    tabId: this.state.params.tabId
                });
            })
            .catch((err) => {
                this.handleError('Could not update poi', err);
                this.isSaving = false;
            })
            .finally(() => this.isSaving = false);
    }

    onChangeIcon() {
        this.iconSrc = this.selectedIconType ? getIconUrl(this.poiExtension.SNOAAType, this.selectedIconType, this.text.imageUrl) : getIconUrl(this.poiExtension.SNOAAType, this.selectedTrailIcon, this.text.imageUrl);
    }
}
