import controller from './questions-phone-preview.controller';
import template from './questions-phone-preview.html';
import './questions-phone-preview.less';

const component = {
    template,
    bindings: {
        tags: '<',
        locale: '<',
    },
    controller: [controller],
};

export default component;
