import template from './simple-modal.html';
import './simple-modal.less';
import SimpleModalController from './simple-modal.controller';

const SimpleModalComponent = {
    template,
    bindings: {
        close: '&',
        dismiss: '&',
        resolve: '<',
    },
    controller: ['$sce', 'crConstants', SimpleModalController],
};

export default SimpleModalComponent;
