import controller from './preferences-phone-preview.controller';
import template from './preferences-phone-preview.html';
import './preferences-phone-preview.less';

const component = {
    template,
    bindings: {
        locale: '<',
        brand: '<',
        venue: '<',
    },
    controller: ['crConstants', controller],
};

export default component;
