import text from './resources/locale/en.json';

import ListPage from '../../../../commons/controller/list-page';

export default class KitchensList extends ListPage {
    constructor(
        $state,
        crAnalyticsService,
        crConfig,
        crEntitySelectionManager,
        crErrorLoggingService,
        crKitchensListService,
        crLocaleService,
        crToastService
    ) {
        super($state, crErrorLoggingService, crAnalyticsService, crToastService, crLocaleService, crConfig);
        this.crEntitySelectionManager = crEntitySelectionManager;
        this.crKitchensListService = crKitchensListService;
    }

    $onInit() {
        super.$onInit();
        this.registerParam('state', 'enabled');

        this.text = text;

        const { columns } = this.text;

        this.listData = {
            columns: [
                { key: 'placeholder', label: '' },
                { key: 'name', label: columns.name, class: 'name-column', sortable: true },
            ],
            rows: [],
        };

        this.crToastService.autoToast(this.$state.params);
    }

    getData() {
        this.isLoading = true;
        this.dataError = false;

        return this.crKitchensListService
            .getData(this.params)
            .then((data) => {
                data.search = data.query.search || '';

                if (data.search === this.params.search) {
                    this.isLoading = false;
                    return this.crKitchensListService.mapToRowsModel(data.places);
                }
            })
            .catch((err) => {
                this.isLoading = false;
                this.dataError = true;
                this.endOfResults = true;
                this.listData.rows = [];
                this.crErrorLoggingService.logError('Could not get Kitchens List data', err);
            });
    }
}
