import BeaconsMapController from './beacons-map.controller';
import template from './beacons-map.html';
import './beacons-map.less';

const BeaconsMapComponent = {
    template,
    controller: ['$state', 'crBeaconsMapService', 'crErrorLoggingService', 'crAnalyticsService', BeaconsMapController],
};

export default BeaconsMapComponent;
