import 'core-js/features/reflect';
import 'core-js/stable';
import "regenerator-runtime/runtime";
import 'zone.js';
import * as angular from 'angular';

import { NgZone } from '@angular/core';
import { UIRouter, UrlService } from '@uirouter/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { setAngularJSGlobal } from '@angular/upgrade/static';
import { AppModule } from './app/app.module';

// This provides swipe guestures
import 'hammerjs';

import './styles.scss';

setAngularJSGlobal(angular);

declare const VERSION: string;
declare const COMMIT: string;

function testReady(callback) {
  if (/in/.test(document.readyState)) {
    setTimeout(testReady, 9, callback);
  } else {
    callback();
  }
}

testReady(() => {
  const spinner = document.getElementById('app-spinner');
  const container = document.getElementById('app-container');

  spinner.style.opacity = '0';
  container.style.opacity = '1';
});

function printWelcomeMessage() {
  const lineStyle = 'background-color: #181818; color: #cbcbcb; line-height: 14px';
  const crStyle = 'background-color: #181818; color: #d60000; line-height: 14px;';
  const versionStyle = 'background-color: #181818; color: #9a9a9a; line-height: 14px;';

  console.log(
    `%c
o--------------------------------------------------------------------------------------------------------------------------------------------------------------o
|      𝔚𝔢𝔩𝔠𝔬𝔪𝔢 𝔗𝔬…                                                                                                                                             |
|                                                                                                                                                              |
|%c        ...                                         s                                   ..        ..      ...                                                 %c|
|%c     xH88"\`~ .x8X                                  :8                             x .d88"      :~"8888x :"%888x                                               %c|
|%c   :8888   .f"8888Hf        u.      u.    u.      .88       .u    .          u.    5888R      8    8888Xf  8888>         u.          u.      ..    .     :    %c|
|%c  :8888>  X8L  ^""\`   ...ue888b   x@88k u@88c.   :888ooo  .d88B :@8c   ...ue888b   '888R     X88x. ?8888k  8888X   ...ue888b   ...ue888b   .888: x888  x888.  %c|
|%c  X8888  X888h        888R Y888r ^"8888""8888" -*8888888 ="8888f8888r  888R Y888r   888R     '8888L'8888X  '%88X   888R Y888r  888R Y888r ~\`8888~'888X\`?888f\` %c|
|%c  88888  !88888.      888R I888>   8888  888R    8888      4888>'88"   888R I888>   888R      "888X 8888X:xnHH(\`\`  888R I888>  888R I888>   X888  888X '888>  %c|
|%c  88888   %88888      888R I888>   8888  888R    8888      4888> '     888R I888>   888R        ?8~ 8888X X8888    888R I888>  888R I888>   X888  888X '888>  %c|
|%c  88888 '> \`8888>     888R I888>   8888  888R    8888      4888>       888R I888>   888R      -~\`   8888> X8888    888R I888>  888R I888>   X888  888X '888>  %c|
|%c  \`8888L %  ?888   ! u8888cJ888    8888  888R   .8888Lu=  .d888L .+   u8888cJ888    888R      :H8x  8888  X8888   u8888cJ888  u8888cJ888    X888  888X '888>  %c|
|%c   \`8888  \`-*""   /   "*888*P"    "*88*" 8888"  ^%888*    ^"8888*"     "*888*P"    .888B .    8888> 888~  X8888    "*888*P"    "*888*P"    "*88%""*88" '888!\` %c|
|%c     "888.      :"      'Y"         ""   'Y"      'Y"        "Y"         'Y"       ^*888%     48"\` '8*~   \`8888!\`    'Y"         'Y"         \`~    "    \`"\`   %c|
|%c       \`""***~"\`                                                                     "%        ^-==""      \`""                                                %c|
|                                                                                                                                                              |
|%c      Version ${VERSION} (${COMMIT})                                                                                                                                %c|
o--------------------------------------------------------------------------------------------------------------------------------------------------------------o
`,
    lineStyle,
    crStyle,
    lineStyle,
    crStyle,
    lineStyle,
    crStyle,
    lineStyle,
    crStyle,
    lineStyle,
    crStyle,
    lineStyle,
    crStyle,
    lineStyle,
    crStyle,
    lineStyle,
    crStyle,
    lineStyle,
    crStyle,
    lineStyle,
    crStyle,
    lineStyle,
    crStyle,
    lineStyle,
    crStyle,
    lineStyle,
    versionStyle,
    lineStyle,
  );
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((platformRef) => {
    printWelcomeMessage();
    // get() the UIRouter instance from DI to initialize the router
    const { urlService } = platformRef.injector.get(UIRouter);

    // Instruct UIRouter to listen to URL changes
    function startUIRouter() {
      urlService.listen();
      urlService.sync();
    }

    platformRef.injector.get<NgZone>(NgZone).run(startUIRouter);
  });
