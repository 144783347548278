import angular from 'angular';

import DashboardComponent from './quicksight-dashboard.component';
import ComponentsModule from './components/components';

const DashboardModule = angular
    .module('control-room.quicksight-dashboard', [ComponentsModule.name])
    .component('crQuicksightDashboard', DashboardComponent);

export default DashboardModule;
