<div
    class="phone-container"
    crStickyPosition
    [ngClass]="customClass"
    data-global-qa-id="mobile-container"
>
    <div class="phone">
        <div
            class="phone-screen"
            [ngClass]="dynamicStyle"
            data-global-qa-id="mobile-content"
            cr-mobile-scroll
        >
            <ng-content></ng-content>
        </div>
        <div
            class="cta-container cr-mobile-scroll"
            *ngIf="cta?.label && cta?.link"
        >
            <div
                class="btn cr-stretch cr-mobile-scroll"
                data-global-qa-id="mobile-cta"
            >
                {{ cta.label }}
            </div>
        </div>
        <div
            class="links-container cr-mobile-scroll"
            *ngIf="links?.length === 1"
        >
            <div class="link" *ngFor="let link of links">
                <div class="link-button cr-mobile-scroll">
                    {{ link.label[locale.current] }}
                </div>
            </div>
        </div>
        <div class="links-container cr-mobile-scroll" *ngIf="links?.length > 1">
            <div class="link-section">
                <div class="link-header cr-mobile-scroll">{{ text.links }}</div>
            </div>
            <div class="link-text-container" *ngFor="let link of links">
                <div class="link-text cr-mobile-scroll">
                    {{ link.label[locale.current] }}
                </div>
            </div>
        </div>
    </div>
</div>
