import Controller from './identity-bar.controller';
import template from './identity-bar.html';
import './identity-bar.less';

const Component = {
    template,
    transclude: true,
    bindings: {
        customerName: '@',
        onToggle: '&',
        disabled: '<?',
        categoryActions: '<',
    },
    controllerAs: 'identityBarCtrl',
    controller: [
        'crConfig',
        '$transitions',
        '$state',
        '$window',
        'crAnalyticsService',
        'crNavigationService',
        Controller,
    ],
};

export default Component;
