import angular from 'angular';

import TabularOrdersPlaced from './tabular-orders-placed/tabular-orders-placed';
import TabularTopItems from './tabular-top-items/tabular-top-items';

const ComponentsModule = angular.module('control-room.reports.components.orders.components', [
    TabularOrdersPlaced.name,
    TabularTopItems.name,
]);

export default ComponentsModule;
