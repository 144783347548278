
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SplitViewComponent } from './split-view-page.component';

@NgModule({
  imports: [CommonModule],
  declarations: [SplitViewComponent],
  exports: [SplitViewComponent],
})
export class CrSplitViewModule {}
