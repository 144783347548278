import { InjectionToken } from '@angular/core';
import { Constants } from '../../../core';

export const constants: Constants = {
  sort: {
    ASC: 'asc',
    DESC: 'desc',
  },
  attributeTypes: {
    MULTI_SELECT_ENUM: 'MULTI_SELECT_ENUM',
    ENUM: 'ENUM',
  },
  events: {
    NAV_PANEL_CLOSE: 'cr-nav-panel-close',
    NAV_PANEL_OPEN: 'cr-nav-panel-open',
    SHOW_TOAST: 'cr-show-toast',
    SHOW_ALERT: 'cr-show-alert',
    CANCEL_CALENDAR_INSTANCE: 'cr-cancel-calendar-instance',
    UPDATE_CALENDAR_VIEW: 'cr-update-calendar-view',
  },
  defaultLanguage: 'en-US',
  filterTypes: {
    channel: [
      { id: 'mobile', label: 'Mobile app' },
      { id: 'web', label: 'Mobile web' },
    ],
    state: [
      { id: 'enabled', label: 'Enabled' },
      { id: 'disabled', label: 'Disabled' },
    ],
    interval: [
      { id: 'day', label: 'Day' },
      { id: 'week', label: 'Week' },
      {
        id: 'month',
        label: 'Month',
      }, /* ,            { id: 'quarter', label: 'Quarter' },
            { id: 'year', label: 'Year' } */
    ],
  },
  entity: {
    categoryLabelEllipsifyLength: 24,
    types: {
      PLACES: 'places',
      POIS: 'pois',
      POIS_SELECT_ONLY: 'pois-select-only',
      EVENTS: 'events',
      BEACONS: 'beacons',
      POI_TYPES: 'poi-types',
      POI_CATEGORIES: 'poi-categories',
      POI_ATTRIBUTES: 'poi-attributes',
      EVENT_CATEGORIES: 'event-categories',
      EVENT_ATTRIBUTES: 'event-attributes',
      VENUE_HOURS: 'venue-hours',
      PRODUCTS: 'products',

      MENUS: 'menus',
      MENU_PRODUCTS: 'menu-products',
      MENU_BUNDLES: 'menu-bundles',
      MENU_CATEGORIES: 'menu-categories',
      MENU_MODIFIER_GROUPS: 'menu-modifierGroups',
      MENU_MODIFIERS: 'menu-modifiers',
      SCREEN_FLOWS: 'screen-flows',
      TAG_DEFINITIONS: 'tag-definitions',
      TAG_DEFINITION_VALUES: 'tag-definition-values',
      EXPERIENCES: 'experiences',
      COUPONS: 'coupons',
    },
    states: {
      ARCHIVED: 'ARCHIVED',
      ACTIVE: 'ACTIVE',
      INACTIVE: 'INACTIVE',
      DRAFT: 'DRAFT',
    },
  },
  userLocationEnums: {
    IN_VENUE: 'IN_VENUE',
    OUTSIDE_VENUE: 'OUTSIDE_VENUE',
    ANYWHERE: 'ANYWHERE',
  },
  categoryPlacementLocations: {
    TOP: 'top',
    WITHIN: 'within',
  },
  weekdays: ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'],
  eventArrivalEnums: {
    ANYTIME: 'ANYTIME',
    ON_TIME: 'ON_TIME',
    EARLY: 'EARLY',
  },
  marker: {
    defaultDimensions: {
      w: 24,
      h: 24,
    },
  },
  modalTypes: {
    CONFIRM: 'confirm',
    SUBMIT: 'submit',
    CANCEL: 'cancel',
  },
  emptyRepresentation: '--',
  regex: {
    ALLOW_ALL: /.*/,
    NO_WHITE_SPACE: /^\S+$/,
    EMAIL: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    INTEGER: /^\d+$/,
    FLOAT: /^\d+(\.\d+)?$/,
    PRICE: /^(\d+(\.\d{0,2})?)$/,
    LATITUDE: /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)$/,
    LONGITUDE: /^[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/,
    URL: /^(https?:\/\/)([-a-zA-Z0-9+&@#%?=~_|!:,;]+\.)+([-a-zA-Z0-9+&@#%?=~_|!:,;]{2,})(\/[-a-zA-Z0-9+&@#/%=~_.?|]*)?$/,
    PASSWORD: /^[a-zA-Z0-9!@#$%^&*()\-+=_[\]{}|;':",./<>?]*$/,
    AUTH_CODE: /^[0-9]{6}$/,
    PHONE_EXTENSION: /^ ?\d+$/,
    ALPHANUMERIC_PLUS: /^[A-Za-z]+[A-Za-z0-9-_]*$/,
    UPPER_CASE: /(.*[A-Z].*)/,
    LOWER_CASE: /(.*[a-z].*)/,
    NUMBER: /(.*[0-9].*)/,
  },
  debounce: {
    SHORT: 250,
    MEDIUM: 500,
    LONG: 1000,
  },
  input: {
    maxLength: {
      DEFAULT: 78,
      POI_TYPE: 25,
      CATEGORY: 25,
      SUBCATEGORY: 25,
    },
    restrictPattern: {
      twoDigit: '^\\d{0,2}$',
    },
  },
  keys: {
    ENTER: 13,
    ESCAPE: 27,
    SPACE: 32,
    TAB: 9,
    ARROW_UP: 38,
    ARROW_DOWN: 40,
    ARROW_LEFT: 37,
    ARROW_RIGHT: 39,
  },
  calendarService: {
    scheduleKey: {
      FULLDAY: 'fullday',
      CHECKPARK: 'checkPark',
    },
  },
  iso: {
    weekdays: ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU'],
  },
  iCal: {
    weekdays: ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'],
    format: {
      date: 'YYYYMMDDTHHmmss',
    },
  },
  date: {
    format: {
      pickerDay: 'd',
      pickerModel: 'MM/DD/YYYY',
      yearFirstDate: 'YYYY-MM-DD',

      // moment's format() defaults to iso string with offset so it is not included here
      isoNoOffset: 'YYYY-MM-DDTHH:mm:ss',
      isoUtc: 'YYYY-MM-DDTHH:mm:ssZ',
      short: 'll',
      shortNoYear: 'MMM D',
      dateAtTime: 'MMM Do, YYYY [at] LT',
    },
  },
  time: {
    format: {
      pickerModel: 'h:mm A',
      isoNoOffset: 'HH:mm:ss',
      short: 'h:mm A',
    },
  },
  schedule: {
    defaultWeekdays: ['MO', 'TU', 'WE', 'TH', 'FR'],
    defaultExperienceWeekdays: ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'],
  },
  placeholder: {
    datePicker: 'MM/DD/YYYY',
    timePicker: 'HH:MM',
  },
  pdf: {
    fileExtensions: '.pdf',
    maxFileSize: '10MB',
    recommendedFileSizeBytes: 1024 * 1024,
  },
  image: {
    fileExtensions: '.png,.jpg,.jpeg',
    maxFileSize: '10MB',
    zoomAmount: 0.1,
    folderNames: {
      'poi-categories': 'poi-categories',
      'poi-attributes': 'poi-attributes',
      'event-categories': 'event-categories',
      'event-attributes': 'event-attributes',
    },
    types: {
      STICKER: 'STICKER',
      MAIN: 'MAIN',
      ICON: 'ICON',
      DEFAULT: 'DEFAULT',
    },

    // TODO get min sizes from content service once ported over from platform
    minSize: {
      STICKER: {
        width: 224,
        height: 224,
        aspectRatio: 1/1
      },
      MAIN: {
        width: 1024,
        height: 768,
        aspectRatio: 4/3
      },
      DEFAULT: {
        width: 1024,
        height: 768,
        aspectRatio: 4/3
      },
    },
    cropDisplaySize: {
      STICKER: {
        width: 224,
        height: 224,
      },
      MAIN: {
        width: 530,
        height: 350,
      },
      DEFAULT: {
        width: 530,
        height: 350,
      },
    },
    phoneDisplaySize: {
      MAIN: {
        width: 246,
        height: 162,
      },
      DEFAULT: {
        width: 246,
        height: 162,
      },
    },
    maxCount: {
      STICKER: 1,
      MAIN: 4,
      DEFAULT: 4,
    },
    sizes: {
      STICKER: {
        small: {
          width: 30,
          height: 30,
        },
        medium: {
          width: 82,
          height: 82,
        },
      },
      MAIN: {
        medium: {
          width: 320,
          height: 211,
        },
      },
      DEFAULT: {
        medium: {
          width: 320,
          height: 211,
        },
      },
    },
  },
  stickyZindex: 900,
  flyoutOpenDelay: 250,
  appUsersTagKey: '__AppUsers',
  externalPriceCode: {
    alcohol: 'ALCOHOL',
    beer: 'BEER',
    wine: 'WINE',
    food: 'FOOD',
  },
  noneSelected: 'None selected',
};

export function constantsFactory(): Constants {
  return constants;
}

export const CR_CONSTANTS = new InjectionToken<Constants>('app constants', {
  providedIn: 'root',
  factory: constantsFactory,
});
