import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationOptions, ConfigurationProfile } from './configuration.model';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  private baseUrl: string;

  constructor(private readonly http: HttpClient) {
    this.baseUrl = '/rest/configuration';
  }

  async getConfigurationOptions(params: { [key: string]: string }): Promise<ConfigurationOptions> {
    return this.http.get<ConfigurationOptions>(this.baseUrl, { params }).toPromise();
  }

  async getConfigurationForZones(params: { [key: string]: string }): Promise<ConfigurationProfile> {
    return this.http.get<ConfigurationProfile>(`${this.baseUrl}/profile/control_room_zone`, { params })
      .toPromise();
  }

  async getConfigurationForRefundReasons(params: { [key: string]: string }): Promise<ConfigurationProfile> {
    return this.http.get<ConfigurationProfile>(`${this.baseUrl}/profile/refund_reason`, { params })
      .toPromise();
  }
}
