import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CrContentModule } from '../content/content.module';
import { CrIconModule } from '../icon/icon.module';
import { CrSpinnerModule } from '../spinner/spinner.module';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { ChipSelectorComponent } from './chip-selector/chip-selector.component';
import { EntityHeaderComponent } from './entity-header/entity-header.component';
import { FormErrorComponent } from './form-error/form-error.component';
import { FormLabelErrorComponent } from './form-label-error/form-label-error.component';
import { FormSubmissionErrorComponent } from './form-submission-error/form-submission-error.component';
import { InputComponent } from './input/input.component';
import { ReorderListComponent } from './reorder-list/reorder-list.component';
import { RequiredFieldComponent } from './required-field/required-field.component';
import { CrSearchModule } from './search/search.module';
import { SelectComponent } from './select/select.component';
import { TimePickerComponent } from './time-picker/time-picker.component';
import { WeekdayEditorComponent } from './weekday-editor/weekday-editor.component';
import { CrDropdownModule } from './dropdown/dropdown.module';
import { EntityContentComponent } from './entity-content/entity-content.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CrContentModule,
    CrIconModule,
    CrSpinnerModule,
    NgbDropdownModule,
    NgbTooltipModule,
    DragDropModule,
    CrDropdownModule,
    CrSearchModule,
  ],
  declarations: [
    InputComponent,
    FormErrorComponent,
    SelectComponent,
    ReorderListComponent,
    WeekdayEditorComponent,
    TimePickerComponent,
    ChipSelectorComponent,
    CheckboxComponent,
    FormSubmissionErrorComponent,
    FormLabelErrorComponent,
    RequiredFieldComponent,
    EntityHeaderComponent,
    EntityContentComponent,
  ],
  exports: [
    FormsModule,
    CheckboxComponent,
    ReactiveFormsModule,
    CrDropdownModule,
    InputComponent,
    FormErrorComponent,
    SelectComponent,
    ReorderListComponent,
    WeekdayEditorComponent,
    TimePickerComponent,
    ChipSelectorComponent,
    FormSubmissionErrorComponent,
    FormLabelErrorComponent,
    RequiredFieldComponent,
    EntityHeaderComponent,
    EntityContentComponent,
  ],
})
export class CrFormsModule {}
