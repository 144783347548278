import controller from './category-archive-modal.controller';
import template from './category-archive-modal.html';
import './category-archive-modal.less';

const component = {
    template,
    bindings: {
        close: '&',
        dismiss: '&',
        resolve: '<',
    },
    controller: ['$sce', 'crConstants', 'crCategoryArchiveModalText', controller],
};

export default component;
