import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ModalModule } from 'ngx-bootstrap/modal';

import { ImageCropperModule } from 'ngx-image-cropper';
import { CrIconModule } from '../icon/icon.module';
import { CrSpinnerModule } from '../spinner/spinner.module';
import { DataErrorComponent } from './data-error/data-error.component';
import { EditHeaderComponent } from './edit-header/edit-header.component';
import { ImageCropperModalComponent } from './image-cropper-modal/image-cropper-modal.component';
import { CrImageUploaderModule } from './image-uploader/image-uploader.module';
import { SectionHeadingComponent } from './section-heading/section-heading.component';
import { SimpleModalComponent } from './simple-modal/simple-modal.component';

@NgModule({
  imports: [CommonModule, ModalModule, CrIconModule, CrSpinnerModule, CrImageUploaderModule, ImageCropperModule],
  declarations: [EditHeaderComponent, SimpleModalComponent, DataErrorComponent, SectionHeadingComponent, ImageCropperModalComponent],
  entryComponents: [SimpleModalComponent, ImageCropperModalComponent],
  exports: [
    EditHeaderComponent,
    SimpleModalComponent,
    ImageCropperModalComponent,
    DataErrorComponent,
    SectionHeadingComponent,
    CrImageUploaderModule,
    ],
})
export class CrContentModule {}
