import Controller from './row-manager-row.controller';
import template from './row-manager-row.html';
import './row-manager-row.less';

const Component = {
    template,
    bindings: {
        rowData: '<',
        index: '<',
        disableRemove: '<',
    },
    require: {
        crRowManager: '^crRowManager',
    },
    transclude: true,
    controller: ['$scope', Controller],
};

export default Component;
