import controller from './attributes-list.controller';
import template from './attributes-list.html';
import './attributes-list.less';

const component = {
    template,
    bindings: {
        entityType: '@',
        attributeType: '@',
        requiredWritePermission: '@',
    },
    controller: [
        '$state',
        'crErrorLoggingService',
        'crAnalyticsService',
        'crToastService',
        'crLocaleService',
        'crConfig',
        'crImageService',
        'crAttributesService',
        'crNavigationService',
        'crEntityLocalizationService',
        controller,
    ],
};

export default component;
