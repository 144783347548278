import controller from './automation-select.controller';
import template from './automation-select.html';

import './automation-select.less';

const component = {
    template,
    bindings: {
        experience: '<',
        onUpdate: '&',
    },
    controller: [
        '$state',
        'crConstants',
        'crAnalyticsService',
        'crNavigationService',
        'crExperiencesService',
        controller,
    ],
};

export default component;
