import { Component, EventEmitter, Input, Output } from '@angular/core';
import { StateService } from '@uirouter/core';
import { BsModalRef } from 'ngx-bootstrap';
import { take } from 'rxjs/operators';
import { LocalizedText } from '../../../../core';
import text from './resources/en.json';
import { PTWService } from '../../../../shared/services/ptw/ptw.service';
import { UserService } from '../../../../shared';
import { ClosePlaceWithPtw } from '../../../../shared/services/ptw/ptw.model';

@Component({
  selector: 'cr-ptw-remove-all',
  templateUrl: './ptw-remove-all.component.html',
  styleUrls: ['./ptw-remove-all.component.scss']
})
export class PtwRemoveAllComponent{

  @Input() modalRef: BsModalRef

  @Output() onRemoveAll = new EventEmitter<void>();

  userId: string

  text: LocalizedText

  constructor(private ptwService: PTWService, private state: StateService, private crUserService: UserService) {
    this.text = text;
    this.crUserService.getUser().then((user) => {
      this.userId = user.email;
    });
  }

  onCancelAll() {
    const closePlaceWithPtwDto: ClosePlaceWithPtw =  {user: this.userId};
    this.ptwService.closePlaceWithPtw(this.state.params.kitchenId, closePlaceWithPtwDto).pipe(take(1)).subscribe((res) => {
      this.modalRef.hide();
      this.onRemoveAll.emit();
    })
  }

  onCloseModal() {
    this.modalRef.hide()
  }

}
