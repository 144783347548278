import _ from 'lodash';
import text from './resources/locale/en.json';

class CtaButtonConfigure {
    $onChanges(changes) {
        if (changes.entity) {
            this.cta = _.cloneDeep(this.entity.localization[this.locale.current].callToAction);
        }

        if (changes.locale) {
            this.isDefaultLocale = this.locale.current === this.locale.default;
            this.cta = _.cloneDeep(this.entity.localization[this.locale.current].callToAction);

            // When switching locales, the type of a cta needs to be copied over from the non-localized entity
            // so that the form remains in a good state. The label and link can be cleared out though.
            if (this.entity.callToAction) {
                if (!this.cta) {
                    this.cta = { label: '' };
                }

                this.cta.type = this.entity.callToAction.type;
                this.cta.options = this.entity.callToAction.options;
            }

            this.refreshMenuDropdown();
        }
    }

    $onInit() {
        this.text = text;
        const defaultCta = this.entity.localization[this.locale.default].callToAction;
        this.showCta = defaultCta && defaultCta.label && defaultCta.link;

        const isMenu = this.showCta && this.cta.type === 'MENU';

        this.options = {};
        this.options.types = [
            { name: this.text.web, id: 'WEB', isSelected: !isMenu },
            { name: this.text.menu, id: 'MENU', isSelected: isMenu },
        ];

        this.refreshMenuDropdown();
    }

    refreshMenuDropdown() {
        if (this.cta && this.cta.options) {
            this.menus
                .filter((menu) => menu.id === this.cta.options.menuId)
                .forEach((menu) => {
                    menu.isSelected = true;
                });
        }
    }

    onLabelChange(event) {
        if (!this.cta) {
            this.cta = { type: 'WEB' };
        }
        this.cta.label = event.model;
        this.update(this.cta);
    }

    onLinkChange(event) {
        if (!this.cta) {
            this.cta = { type: 'WEB' };
        }
        this.cta.link = event.model;
        this.update(this.cta);
    }

    // Link type dropdown was changed
    onTypeChange(event) {
        if (!this.cta) {
            this.cta = {};
        }
        this.cta.type = event.model[0].id;
        if (this.cta.type === 'MENU') {
            this.cta.label = this.text.orderNow;
            this.cta.link = '';
        } else {
            // WEB
            this.cta.label = '';
            this.cta.link = 'https://';
            this.cta.options = null;
        }
        this.entity.callToAction.type = this.cta.type;
        this.update(this.cta);
    }

    // Menu dropdown was changed
    onMenuChange(event) {
        if (!this.cta) {
            this.cta = { type: 'MENU' };
        }
        const menuId = event.model[0].id;
        this.cta.link = `/menus/${menuId}`;
        this.cta.options = { menuId };
        this.update(this.cta);
    }

    update(cta) {
        this.onUpdate({
            $event: { model: cta },
        });
    }

    addButton() {
        if (this.isDefaultLocale) {
            if (!this.cta) {
                this.cta = { label: '', link: 'https://', type: 'WEB' };
            }
            this.showCta = true;

            this.update(this.cta);
        }
    }

    removeButton() {
        this.showCta = false;
        this.update();

        if (this.form && !this.form.$dirty) {
            this.form.$setDirty();
        }
    }
}

export default CtaButtonConfigure;
