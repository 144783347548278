import {
  Component, Input, Output, EventEmitter, TemplateRef,
} from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ReorderEvent } from '../../../core';

@Component({
  selector: 'cr-reorder-list',
  templateUrl: './reorder-list.component.html',
  styleUrls: ['./reorder-list.component.scss'],
})
export class ReorderListComponent {
  @Input()
  items: any[];

  @Input()
  itemTemplate: TemplateRef<any>;

  @Input()
  set disableMove(value: boolean) {
    this._disableMove = coerceBooleanProperty(value);
  }

  get disableMove(): boolean {
    return this._disableMove;
  }

  @Output()
  reorder = new EventEmitter<ReorderEvent>();

  private _disableMove: boolean;

  drop(event: CdkDragDrop<any[]>) {
    if (!this.disableMove) {
      moveItemInArray(this.items, event.previousIndex, event.currentIndex);
    }

    this.reorder.emit({
      previousIndex: event.previousIndex,
      currentIndex: event.currentIndex,
      item: event.item.data,
    });
  }
}
