import Controller from './dropdown.controller';
import template from './dropdown.html';
import './dropdown.less';

const Component = {
    template,
    require: {
        form: '?^form',
    },
    bindings: {
        onSelect: '&',
        menuItems: '<',

        name: '@?',
        mode: '@?',
        label: '@?',
        placeholder: '@?',
        sort: '@?',
        enableClear: '<?',
        hasDataError: '<?',
        crRequired: '<?',
        displayKey: '@?',
        secondaryDisplayKey: '@?',
        subtextDisplayKey: '@?',
        showSearch: '<?',
        customClass: '@?',
        showStatus: '<?',
        routeQueryId: '@?',
        isMultiSelect: '<?',
        isDisabled: '<?',
        selectedClass: '@?',
        hideResetOption: '<?',
        hideCurrentSelection: '<?',
        disableTracking: '<?',
        validator: '<?',
        noResultsMessage: '<?',
        ignoreDirty: '<?',
        upgradedFormControl: '@?'
    },
    controller: ['$state', '$element', '$window', 'crConstants', 'crAnalyticsService', Controller],
};

export default Component;
