import controller from './create-menu-modal.controller';
import template from './create-menu-modal.html';
import './create-menu-modal.less';

const component = {
    template,
    bindings: {
        close: '&',
        modalInstance: '<',
    },
    controller: [
        '$state',
        'crCreateMenuModalText',
        'crErrorLoggingService',
        'crAnalyticsService',
        'crKitchensListService',
        controller,
    ],
};

export default component;
