import Chartist from 'chartist';
import moment from 'moment';

class AppUsageController {
    constructor($state, service, text, numberFilter, crConstants, colorPalette) {
        this.$state = $state;
        this.service = service;
        this.text = text;
        this.numberFilter = numberFilter;
        this.crConstants = crConstants;
        this.colorPalette = colorPalette;
    }

    $onInit() {
        this.activeUsersOptions = {
            axisY: {
                labelInterpolationFnc: (value) => this.numberFilter(value, 0),
            },
            tooltip: {
                tooltipFnc: this.tooltipFnc.bind(this),
            },
            type: Chartist.FixedScaleAxis,
            low: 0,
        };

        this.miniOptions = {
            axisY: {
                labelInterpolationFnc: (value) => this.numberFilter(value, 0),
            },
            tooltip: {
                tooltipFnc: this.tooltipFnc.bind(this),
            },
            type: Chartist.FixedScaleAxis,
            hideMiddleLabels: true,
            low: 0,
        };

        this.topViewedOptions = {
            axisY: {
                labelInterpolationFnc: (value) => this.numberFilter(value, 0),
            },
        };

        this.intervalFilterData = this.crConstants.filterTypes.interval;
        this.channelFilterData = this.crConstants.filterTypes.channel;

        this.loading = true;

        this.interval = this.$state.params.interval || '7';
        this.channel = this.$state.params.channel;

        this.updateData();
    }

    goBack() {
        const params = {
            customerId: this.$state.params.customerId,
        };

        this.$state.go('performance.reporting.list', params);
    }

    tooltipFnc(meta, value) {
        const txt = document.createElement('textarea');
        txt.innerHTML = !meta || meta === 'null' ? '0' : meta;
        meta = txt.value;

        return `<div class="cr-stat-title">${meta}</div>` + `<div><b>${this.numberFilter(value, 0)}</b></div>`;
    }

    updateParam(param, value) {
        if (this[param] !== value.id) {
            this[param] = value.id;
            this.updateData();
        }
    }

    updateData() {
        let end = moment();
        let start;

        // TODO: this should be passed via a field
        switch (this.interval) {
            case '7':
                start = end.clone().subtract(7, 'day');
                break;
            case '84':
                end = end.subtract(1, 'week').endOf('week');
                start = end.clone().subtract(12, 'week').startOf('week');
                break;
            case '365':
                end = end.subtract(1, 'month').endOf('month');
                start = end.clone().subtract(12, 'month').startOf('month');
                break;
        }

        this.startDate = start.format('ddd, MMM D');
        this.endDate = end.format('ddd, MMM D');

        let done = 0;

        const doneCheck = () => {
            done += 1;

            this.loading = !(done === 4);
        };

        this.service.getActiveUsers().then((activeUsers) => {
            this.activeUsers = activeUsers;

            doneCheck();
        });

        this.service.getTopViewed().then((topViewed) => {
            this.topViewed = topViewed;

            doneCheck();
        });

        this.service.getDownloads().then((downloads) => {
            this.downloads = downloads;

            doneCheck();
        });

        this.service.getCrashes().then((crashes) => {
            this.crashes = crashes;

            doneCheck();
        });
    }
}

export default AppUsageController;
