import template from './filters-container.html';
import './filters-container.less';

const FiltersContainerComponent = {
    template,
    transclude: true,
    bindings: {
        customClass: '@',
    },
};

export default FiltersContainerComponent;
