class ActionSummaryModal {
    constructor($sce) {
        this.$sce = $sce;
    }

    $onInit() {
        if (this.resolve) {
            this.resolve.title = this.$sce.trustAsHtml(this.resolve.title);
            this.resolve.description = this.$sce.trustAsHtml(this.resolve.description);
        }
    }

    onCancel() {
        this.dismiss();
    }

    onSubmit() {
        this.close();
    }
}

export default ActionSummaryModal;
