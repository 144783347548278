import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import {
  DiningLocationsComponent,
  NavigationHeaderComponent,
  ReservationsListComponent,
  RestaurantSelectComponent,
  CreateDiningLocationModalComponent,
  DiningLocationEditorComponent,
} from './components';
import { SharedModule } from '../../shared';
import { ReservationSchedulesComponent } from './components/dining-locations/dining-location-editor/reservation-schedules/reservation-schedules.component';

@NgModule({
  imports: [CommonModule, SharedModule, BsDatepickerModule, BsDropdownModule, ModalModule],
  declarations: [
    RestaurantSelectComponent,
    ReservationsListComponent,
    DiningLocationsComponent,
    NavigationHeaderComponent,
    DiningLocationEditorComponent,
    CreateDiningLocationModalComponent,
    ReservationSchedulesComponent,
  ],
  entryComponents: [
    RestaurantSelectComponent,
    ReservationsListComponent,
    DiningLocationsComponent,
    NavigationHeaderComponent,
    DiningLocationEditorComponent,
    CreateDiningLocationModalComponent,
  ],
})
export class FnbReservationsModule {}
