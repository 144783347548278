import text from './resources/locale/en.json';

class PoiTypeListModalController {
    constructor($state, poiTypeListService, constants, errorLoggingpoiTypeListService) {
        this.$state = $state;
        this.poiTypeListService = poiTypeListService;
        this.constants = constants;
        this.modalTypes = constants.modalTypes;
        this.errorLoggingpoiTypeListService = errorLoggingpoiTypeListService;

        this.text = text;
    }

    $onInit() {
        if (!this.resolve.type) {
            this.title = this.text.createTitle;
        } else {
            this.title = this.text.editTitle;
            this.value = this.resolve.type.name;
        }

        this.type = this.modalTypes.SUBMIT;
        this.maxCharValue = this.constants.input.maxLength.POI_TYPE;
    }

    onNameUpdate($event) {
        this.value = $event.model;
    }

    onSubmit(e) {
        e.stopPropagation();

        if (!this.form.$valid || this.pending) {
            return false;
        }

        this.pending = true;

        if (!this.resolve.type) {
            this.createType(this.value);
        } else {
            this.updateType(this.value);
        }
    }

    onCancel() {
        if (this.pending) {
            return false;
        }

        this.dismiss();
    }

    deleteType() {
        if (this.pending) {
            return false;
        }

        if (this.resolve.type.pois.length) {
            this.type = this.modalTypes.CANCEL;

            return;
        }

        this.pending = true;

        this.poiTypeListService
            .delete(this.resolve.type.id)
            .then(() => {
                this.close();
            })
            .catch(() => {
                this.errorLoggingpoiTypeListService.logError('Could not delete POI type', {
                    venueId: this.$state.params.venueId,
                });
                this.dismiss();
            });
    }

    createType(typeText) {
        this.poiTypeListService
            .create(typeText, this.$state.params.venueId)
            .then(() => {
                this.close();
            })
            .catch(() => {
                this.errorLoggingpoiTypeListService.logError('Could not create POI type', {
                    venueId: this.$state.params.venueId,
                    poiId: this.resolve.type.id,
                    name: typeText,
                });
                this.dismiss();
            });
    }

    updateType(newTypeText) {
        this.poiTypeListService
            .update(this.resolve.type.id, newTypeText)
            .then(() => {
                this.close();
            })
            .catch(() => {
                this.errorLoggingpoiTypeListService.logError('Could not update POI type', {
                    venueId: this.$state.params.venueId,
                    poiId: this.resolve.type.id,
                    name: newTypeText,
                });
                this.dismiss();
            });
    }

    gotoPoi(poi) {
        this.dismiss();
        this.$state.go('client.poi.details', { id: poi.id });
    }
}

export default PoiTypeListModalController;
