import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

export interface Locker {
  id: string;
  name: string;
  description: string;
}

export interface LockerHoldTimeListItem {
  label: string;
  value: string;
}

@Injectable({
  providedIn: 'root',
})
export class LockerService {
  private baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = '/rest/puc-adapter';
  }

  async getLockers(): Promise<Locker> {
    const resp: any = await this.http
      .get(this.baseUrl + '/lockers')
      .toPromise();
    return resp;
  }

  getLockerHoldTimes(unitsLabel: string, timeInterval = 10, maxHold = 60): LockerHoldTimeListItem[] {
    const holdTimes: LockerHoldTimeListItem[] = [];
    for (let i=10; i <= maxHold; i+=timeInterval) {
      holdTimes.push({
        label: `${i} ${unitsLabel}`,
        value: `${i}`
      });
    }
    
    return holdTimes;
  }
}
