import _ from 'lodash';

import text from './resources/locale/en.json';

class ToggleController {
    constructor() {
        this.text = text;
    }

    $onInit() {
        this.valueMessages = this.valueMessages || {};

        this.mergedValueMessages = _.merge(
            {
                on: this.text.on,
                off: this.text.off,
                disabled: this.text.disabled,
            },
            this.valueMessages
        );

        this.updateText();
    }

    $onChanges(changes) {
        if (!changes.isOn.isFirstChange()) {
            this.isOn = _.clone(this.isOn);
            this.updateText();
        }
    }

    updateText() {
        let messageKey;
        if (this.disabled) {
            messageKey = 'disabled';
        } else if (this.isOn) {
            messageKey = 'on';
        } else {
            messageKey = 'off';
        }
        this.currentMessage = this.mergedValueMessages[messageKey];
    }

    toggleValue() {
        if (this.disabled) {
            return;
        }
        this.onToggle({
            $event: {
                isOn: this.isOn,
            },
        });
    }
}

export default ToggleController;
