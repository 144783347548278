import angular from 'angular';
import io from 'socket.io-client';

const SocketModule = angular.module('control-room.app.services.socket', []).factory('socket', () => {
    if (typeof io !== 'undefined') {
        window.socket = io.connect({ autoConnect: false });

        return window.socket;
    }

    return {
        on: () => null,
        emit: () => null,
    };
});

export default SocketModule;
