import * as pdfjs from 'pdfjs-dist';

pdfjs.GlobalWorkerOptions.workerSrc = './assets/pdf.worker.min.js';

class PdfPreviewModalController {
    constructor($state, crConstants, text) {
        this.$state = $state;
        this.crConstants = crConstants;
        this.text = text;
    }

    $onInit() {
        this.customerId = this.$state.params.customerId;
        this.container = document.getElementById('pdf-container');
        this.scrollContainer = document.getElementsByClassName('modal')[0];
        this.scrollContainer.addEventListener('scroll', this.onScroll.bind(this));

        if (this.resolve) {
            const src = this.resolve.pdf;
            this.pdfLinkFileName = src.substring(src.lastIndexOf('/') + 1);

            const srcWithoutProtocol = src.substring(src.indexOf('//') + 2);
            const resourcePath = srcWithoutProtocol.substring(srcWithoutProtocol.indexOf('/') + 1);
            const safePdfUrl = `/rest/static-resource/${this.customerId}/${resourcePath}`;
            const loadingTask = pdfjs.getDocument(safePdfUrl);

            loadingTask.promise
                .then((pdfDocument) => {
                    this.pdfDocument = pdfDocument;
                    this.currentPage = 0;
                    return this.loadNextPage();
                })
                .catch((err) => {
                    console.error(`Rendering PDF from url ${safePdfUrl} failed:`);
                    console.error(err);
                });
        }
    }

    onScroll() {
        if (!this.loading) {
            this.checkScrollBoundaries();
        }
    }

    checkScrollBoundaries() {
        const { scrollTop } = this.scrollContainer;
        const wiggleRoom = this.scrollContainer.scrollHeight - this.scrollContainer.clientHeight;
        const threshold = 50;
        if (wiggleRoom - scrollTop <= threshold) {
            this.loadNextPage();
        }
    }

    loadNextPage() {
        if (this.currentPage < this.pdfDocument.numPages) {
            this.currentPage += 1;
            return this.renderPageNumber(this.currentPage);
        }
        return false;
    }

    renderPageNumber(pageNumber) {
        this.loading = true;
        return this.pdfDocument.getPage(pageNumber).then((page) => this.renderPage(page));
    }

    renderPage(page) {
        const viewport = page.getViewport({ scale: 1.0 });
        const wrapper = document.createElement('div');
        wrapper.className = 'canvas-wrapper';
        const canvas = document.createElement('canvas');
        const canvasContext = canvas.getContext('2d');
        const renderContext = { canvasContext, viewport };
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        wrapper.appendChild(canvas);
        this.container.appendChild(wrapper);

        return page.render(renderContext).promise.then(() => {
            this.loading = false;
            // Check to see if there's available space at the bottom of the screen
            this.checkScrollBoundaries();
        });
    }

    onCancel() {
        this.dismiss();
    }
}

export default PdfPreviewModalController;
