import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EventTabs, EventTabsResponse, EventTabsType } from "./event-tabs.model";
import { StateService } from "@uirouter/core";

@Injectable({
    providedIn: 'root'
})
export class EventTabsService {
    constructor(private http: HttpClient, private state: StateService) {
    }

    public async getEventTabs(): Promise<EventTabs[]> {
        return this.http.get<EventTabsResponse>('/rest/navigationtabs', {
            params: {
                venueId: this.state.params.venueId,
                
            },
        }).toPromise().then((response) => {
            if(response && response.content.length > 0) {
                return response.content;
            }
            return this.getDefaultEventTabs();
        });
    }

    public async saveEventTabs(eventTabs: EventTabs[]): Promise<EventTabs[]> {
        const venueId = this.state.params.venueId;
        return this.http
            .put<EventTabsResponse>(`/rest/navigationtabs/${venueId}`, {
                content: eventTabs,
            })
            .toPromise()
            .then((response) => {
                return response.content;
            });
    }

    private getDefaultEventTabs(): EventTabs[] {
        return [
            {
                name: 'Today',
                displayOrder: 1,
                owner: {
                    id: this.state.params.venueId,
                    type: EventTabsType.VENUE,
                },
            },
            {
                name: 'Calendar',
                displayOrder: 2,
                owner: {
                    id: this.state.params.venueId,
                    type: EventTabsType.VENUE,
                },
            },
        ]
    }


}