import _ from 'lodash';

class QuestionListService {
    constructor(crEntityService, crConstants) {
        this.crEntityService = crEntityService;
        this.crConstants = crConstants;
    }

    getData(params) {
        const queryParams = _.pickBy(params, (value) => !_.isNil(value) && value !== '' && value !== 'all');

        queryParams.definitionType = 'QUESTION';

        const route = this.crConstants.entity.types.TAG_DEFINITIONS;
        return this.crEntityService.getEntityList(route, queryParams);
    }

    mapToRowsModel(questions) {
        return questions.map((question) => ({
            data: {
                key: question.tagKey,
                label: question.label || '**Missing label property**',
                answers: question.values.map((value) => value.label).join(', '),
                displayLevel: question.userTagLevel === 'VENUE' ? 'Venue' : 'Brand',
                level: question.userTagLevel,
                missingTranslations: _.isArray(question.missingTranslations)
                    ? question.missingTranslations.join(', ')
                    : '',
                originalData: question,
            },
        }));
    }
}

export default QuestionListService;
