import angular from 'angular';
import template from './table.html';
import './table.less';
import TableController from './table.controller';

class TableDirective {
    constructor($compile) {
        this.restrict = 'E';
        this.trTemplate = template;
        this.controller = ['$state', 'crAnalyticsService', TableController];
        this.controllerAs = '$ctrl';
        this.bindToController = true;
        this.$compile = $compile;
        this.scope = {
            data: '=',
            positionSticky: '=',
            onSortChange: '&',
            onRowSelect: '&',
            defaultSortBy: '=?',
            selectionManagerService: '=?',
            entityType: '@',
            contextPolicyEntity: '@',
            disableMultiSelect: '=?',
        };
    }

    static directiveFactory($compile) {
        return new TableDirective($compile);
    }

    compile(element) {
        const trTemplate = element.html();
        const ngTemplate = angular.element(this.trTemplate);

        angular.element(ngTemplate[0].querySelector('.cr-tbody .cr-tr')).html(trTemplate);

        element.empty();

        return this.link.bind(this, ngTemplate);
    }

    link(ngTemplate, scope, element, attrs) {
        element.empty();

        const clone = ngTemplate.clone();

        element.append(clone);

        this.$compile(clone)(scope);

        scope.$parentCtrl = scope.$parent[attrs.parentCtrl];
    }
}

TableDirective.directiveFactory.$inject = ['$compile'];

export default TableDirective;
