class FeatureFlagsDirective {
    constructor($compile, $interpolate, crFeatureFlagsService) {
        this.multiElement = true;
        this.transclude = 'element';
        this.priority = 599;
        this.terminal = true;
        this.restrict = 'A';
        this.$$tlb = true;

        this.$compile = $compile;
        this.$interpolate = $interpolate;
        this.crFeatureFlagsService = crFeatureFlagsService;
    }

    static directiveFactory($compile, $interpolate, crFeatureFlagsService) {
        return new FeatureFlagsDirective($compile, $interpolate, crFeatureFlagsService);
    }

    link(scope, element, attrs, ctrl, $transclude) {
        let childScope;
        let childContent;
        scope.$watch(
            () => {
                const flag = this.$interpolate(attrs.crFeatureFlags)(scope);
                let inverse = false;
                if (attrs.featureFlagsInverse) {
                    inverse = this.$interpolate(attrs.featureFlagsInverse)(scope);
                }
                return inverse === 'true'
                    ? !this.crFeatureFlagsService.hasFeature(flag)
                    : this.crFeatureFlagsService.hasFeature(flag);
            },
            (active) => {
                if (active) {
                    if (!childScope) {
                        $transclude((clone, newScope) => {
                            element.after(clone).remove();
                            childScope = newScope;
                            childContent = clone;
                        });
                    }
                } else {
                    if (childScope) {
                        childScope.$destroy();
                        childScope = null;
                    }
                    if (childContent) {
                        childContent.after(element).remove();
                        childContent = null;
                    }
                }
            }
        );
    }
}

FeatureFlagsDirective.directiveFactory.$inject = ['$compile', '$interpolate', 'crFeatureFlagsService'];

export default FeatureFlagsDirective;
