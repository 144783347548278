import {
  AbstractControl, ValidationErrors, ValidatorFn, Validators,
} from '@angular/forms';

/**
 * This is a helper Validator that will allow you to require a field based on the value of another field in the form
 * @param fieldName The name of the field you want to condition to go against
 * @param fieldValue The value of that field that would make this required
 * @returns Required Validator
 */
export function conditionallyRequireBasedOnField(fieldName: string, fieldValue): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const { parent } = control;
    if (!parent) {
      return null;
    }
    if (parent.get(fieldName).value === fieldValue) {
      return Validators.required(control);
    }
    return null;
  };
}
