import angular from 'angular';
import { downgradeComponent } from '@angular/upgrade/static';
import component from './menus-tabs.component';
import { FeesListComponent } from '../fees-list/fees-list.component';
import { DiscountContentManageComponent } from '../discount-content-manage/discount-content-manage.component';
import { KitchenMasterDetailsComponent } from '../kitchen-master-details/kitchen-master-details.component';
import { DiscountContentComponent } from '../discount-content/discount-content.component';
import { IdDetailContentComponent } from '../id-detail-content/id-detail-content.component';
import { IdDetailContentManageComponent } from '../id-detail-content-manage/id-detail-content-manage.component';

const MenusTabs = angular.module('control-room.menus-tabs', [
])
    .component('crMenusTabs', component)
    .directive('crFeesList', downgradeComponent({component: FeesListComponent}))
    .directive('crDiscountContent', downgradeComponent({component: DiscountContentComponent}))
    .directive('crDiscountContentManage', downgradeComponent({component: DiscountContentManageComponent}))
    .directive('crIdDetailContent', downgradeComponent({component: IdDetailContentComponent}))
    .directive('crIdDetailContentManage', downgradeComponent({component: IdDetailContentManageComponent}))
    .directive('crKitchenMasterDetails', downgradeComponent({component: KitchenMasterDetailsComponent}))

export default MenusTabs;
