<cr-title-bar icon="icon-cr-receipt" [label]="text.header"> </cr-title-bar>

<div *ngIf="!hasError && !isLoading" class="content">
    <div class="title">{{ text.title }}</div>
    <div class="subtitle">{{ text.subtitle }}</div>
    <div class="kitchens">
        <div
            *ngFor="let kitchen of kitchens"
            class="kitchen"
            (click)="selectKitchen(kitchen.id)"
        >
            <span class="kitchen-icon icon-cr-dining"></span>
            <div class="kitchen-name">{{ kitchen.name }}</div>
        </div>
    </div>
</div>

<div *ngIf="!hasError && isLoading" class="cr-page-padding-top cr-flex-center">
    <cr-spinner></cr-spinner>
</div>

<cr-data-error *ngIf="hasError" [message]="text.loadingFailed"></cr-data-error>
