import { HammerGestureConfig } from '@angular/platform-browser';
import { Injectable } from '@angular/core';
import * as hammer from 'hammerjs';

// This configuration tells HammerJS to ignore vertical swiping. This allows
// touchscreens to scroll vertically.
@Injectable({ providedIn: 'root' })
export class HammerConfig extends HammerGestureConfig {
  overrides = {
    swipe: { direction: hammer.DIRECTION_HORIZONTAL },
    pinch: { enable: false },
    rotate: { enable: false },
  } as any;
}
