import text from './resources/locale/en.json';

class McClassicController {
    constructor($state) {
        this.$state = $state;
    }

    $onInit() {
        this.text = text;
    }
}

export default McClassicController;
