import moment from 'moment';

class PlaceDetailsController {
    constructor(
        $state,
        placeDetailsService,
        crConstants,
        placeDetailsLocale,
        numberFilter,
        crErrorLoggingService,
        navigationService
    ) {
        this.$state = $state;
        this.text = placeDetailsLocale;
        this.numberFilter = numberFilter;
        this.weekdays = crConstants.weekdays;
        this.placeDetailsService = placeDetailsService;
        this.errorLoggingService = crErrorLoggingService;
        this.navigationService = navigationService;
    }

    $onInit() {
        this.params = {
            timeframe: {
                start: '',
                end: '',
            },
            interval: '',
        };

        this.placeId = this.$state.params.placeId;

        this.chartOptions = {
            axisY: {
                labelInterpolationFnc: (value) => this.numberFilter(value, 0),
            },
            tooltip: {
                tooltipFnc: (meta, value) => this.numberFilter(value, 0),
            },
        };

        this.barChartOptions = {
            axisY: {
                labelInterpolationFnc: (value) => this.numberFilter(value, 0),
            },
            tooltip: {
                tooltipFnc: this.tooltipBarFnc.bind(this),
            },
            rotateLabels: true,
        };

        this.getDetails();
    }

    formatTime(time) {
        return moment(time, 'HH:mm').format('hh:mm a');
    }

    getDetails() {
        this.isLoading = true;
        this.placeDetailsService
            .getDetails(this.placeId)
            .then((details) => {
                this.name = details.name;
                this.orgName = details.organization.name;
                this.content = details.content;
                this.address = details.content.addressComponents;
                this.features = details.tags;
                this.isCommerceEnabled = details.features.commerceEnabled;
                this.isVisible = details.visibility === 'VISIBLE';
                this.lastUpdated = moment(details.lastUpdated).format('h:mm a MMM Do, YYYY');
            })
            .catch((err) => {
                this.errorLoggingService.logError('could not retrieve details for place', err, this.placeId);

                if (err.status === 404) {
                    this.$state.go('client.404', {
                        customerId: this.$state.params.customerId,
                        venueId: this.$state.params.venueId,
                    });
                } else {
                    this.hasDataError = true;
                }
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    getChartData() {
        this.commerceError = false;
        this.isLoadingCommerce = true;
        return this.placeDetailsService
            .getOrdersReports(this.placeId, this.params.timeframe, this.params.interval)
            .then((data) => {
                this.ordersPlacedData = data.ordersPlaced;
                this.topItemsData = data.topItemsPurchased;
                this.totalOrders = data.totalOrders;
                this.ordersValue = data.ordersValue;
            })
            .catch((err) => {
                this.commerceError = true;
                this.errorLoggingService.logError('Could not retrieve orders reports for place', err);
            })
            .finally(() => {
                this.isLoadingCommerce = false;
            });
    }

    rangeUpdated(event) {
        this.params.timeframe = event.range;
        this.params.interval = event.interval;

        this.getChartData();
    }

    tooltipBarFnc(meta, value) {
        const txt = document.createElement('textarea');
        txt.innerHTML = !meta || meta === 'null' ? '0' : meta;
        meta = txt.value;

        return `<div class="cr-stat-title">${meta}</div><div><b>${this.numberFilter(value, 0)}</b></div>`;
    }

    goBack() {
        this.navigationService.goBack('client.places.list');
    }
}

export default PlaceDetailsController;
