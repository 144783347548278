import _ from 'lodash';
import { Component, OnInit } from '@angular/core';
import {
  animate, style, transition, trigger,
} from '@angular/animations';

import { ToastService } from '../services/toast/toast.service';

@Component({
  selector: 'cr-toast',
  templateUrl: 'toast.component.html',
  styleUrls: ['./toast.component.scss'],
  animations: [
    trigger('insertRemoveTrigger', [
      transition(':enter', [
        style({ 'margin-top': '20px', opacity: 0 }),
        animate('250ms', style({ 'margin-top': '-20px', opacity: 1 })),
      ]),
      transition(':leave', [animate('250ms', style({ opacity: 0 }))]),
    ]),
  ],
})
export class ToastComponent implements OnInit {
  DEFAULT_TIME_TO_LIVE = 5000;

  TRANSITION_TIME = 500;

  toasts: any[];

  constructor(private toastService: ToastService) {}

  ngOnInit() {
    this.toastService.toasts.subscribe((toast) => {
      this.toasts.push(toast);
      this.setTimeToLive(toast);
    });
  }

  setTimeToLive(toast) {
    const timeToLive = (_.isFinite(toast.timeToLive) ? toast.timeToLive : this.DEFAULT_TIME_TO_LIVE) - this.TRANSITION_TIME;
    setTimeout(() => {
      this.toasts.splice(_.findIndex(this.toasts, toast), 1);
    }, timeToLive);
  }
}
