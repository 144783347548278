import _ from 'lodash';
import { Component, Input, OnInit } from '@angular/core';
import { Image } from '../../../client/menus/components/bundle-list/bundle-list.model';
import { ImageService } from '../../../ng1-factories';

const ICON_HEIGHT = 30;
const ICON_WIDTH = 30;

const MAIN_HEIGHT = 184;
const MAIN_WIDTH = 272;

const STICKER_HEIGHT = 80;
const STICKER_WIDTH = 80;
@Component({
  selector: 'cr-img-holder',
  templateUrl: './img-holder.component.html',
  styleUrls: ['./img-holder.component.scss'],
})
export class ImgHolderComponent implements OnInit {
  @Input() src: string;

  @Input() type: 'sticker' | 'main' | 'icon';

  @Input() size: 'sticker' | 'main' | 'icon' = undefined;

  @Input() height: number;

  @Input() roundedCorners = false;

  @Input() circleImage = false;

  @Input() width: number;

  @Input() images: Image[];

  imageSrc: string;

  constructor(private imageService: ImageService) { }

  ngOnInit(): void {
    if (this.type !== undefined && this.size === undefined) {
      this.size = this.type;
    }

    if (this.images) {
      this.determineImageSrcFromType();
    }
  }

  private determineImageSrcFromType(): void {    
    switch (this.size) {
      case 'sticker':
        if (!this.height) {
          this.height = STICKER_HEIGHT;
        }
        if (!this.width) {
          this.width = STICKER_WIDTH;
        }
        break;
      case 'main':
        if (!this.height) {
          this.height = MAIN_HEIGHT;
        }
        if (!this.width) {
          this.width = MAIN_WIDTH;
        }
        break;
      case 'icon':
        if (!this.height) {
          this.height = ICON_HEIGHT;
        }
        if (!this.width) {
          this.width = ICON_WIDTH;
        }
        break;
      default:
        if (!this.height) {
          this.height = MAIN_HEIGHT;
        }
        if (!this.width) {
          this.width = MAIN_WIDTH;
        }
        break;
      }

      const image = _.find(this.images, { type: this.type.toString().toUpperCase() }) || _.find(this.images, { type: 'MAIN' });
      this.imageSrc = this.imageService.getIconSrc(image.src, this.height, this.width);
  }
}
