import controller from './set-auth-code-modal.controller';
import template from './set-auth-code-modal.html';
import './set-auth-code-modal.less';

const component = {
    template,
    bindings: {
        close: '&',
        dismiss: '&',
        modalInstance: '<',
    },
    controller: [
        'crAuthService',
        'crSetAuthCodeModalText',
        'crInputValidators',
        'crErrorLoggingService',
        'crConstants',
        'crAnalyticsService',
        controller,
    ],
};

export default component;
