import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { BundleManageComponent } from './bundle-manage.component';
import {  SharedModule } from '../../../../shared';
import { CrDropdownModule } from '../../../../commons/basic/components/dropdown/dropdown.module';


@NgModule({
  imports: [CommonModule, ReactiveFormsModule, SharedModule, CrDropdownModule],
  exports: [BundleManageComponent],
  declarations: [BundleManageComponent],
  entryComponents: [BundleManageComponent],
})
export class BundleManageModule {}
