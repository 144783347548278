import text from './resources/locale/en.json';

class CtaButtonsDetails {
    $onInit() {
        this.text = text;
        this.callsToAction = this.entity.callsToAction;

        this.callsToAction.forEach((cta, index) => {
            if (cta.type === 'MENU' && this.menus.length > 0) {
                const { menuId } = cta.options;
                if (
                    this.entity &&
                    this.entity.localization &&
                    this.entity.localization[this.locale.current] &&
                    this.entity.localization[this.locale.current].callsToAction &&
                    this.entity.localization[this.locale.current].callsToAction[index]
                ) {
                    const localization = this.entity.localization[this.locale.current].callsToAction[index];
                    cta.label = localization.label || cta.label;
                    cta.link = localization.link || cta.link;
                }
                const validMenus = this.menus.filter(menu => menu.id === menuId);
                if (validMenus.length > 0) {
                    cta.menu = validMenus[0];
                }
            }
        });
    }

    $onChanges() {
        this.entity = _.cloneDeep(this.entity);
        this.callsToAction = this.entity.callsToAction;
    }
}

export default CtaButtonsDetails;
