import _ from 'lodash';
import moment from 'moment';
import 'moment-timezone';

class CalendarAllDay {
    constructor($state, crConstants) {
        this.$state = $state;
        this.crConstants = crConstants;
    }

    $onChanges(changes) {
        if (changes && this.events) {
            this.getAllDayModel(this.events);
        }
    }

    $onInit() {
        const { start } = this.$state.params;
        const { end } = this.$state.params;
        this.days = this.calendar.crCalendarService.getDaysModel({ start, end });
    }

    getAllDayModel(events) {
        const rows = [];
        const { start } = this.$state.params;
        const { end } = this.$state.params;
        const recurrences = _.groupBy(events, (event) => event.metadata.id);
        this.days = this.calendar.crCalendarService.getDaysModel({ start, end });

        _.forOwn(recurrences, (value) => {
            if (value.length === 1) {
                rows.push(this.getRow(value[0], this.days));
            } else {
                rows.push(this.getRecurrenceRow(value, this.days));
            }
        });

        rows.forEach((row) => {
            this.days.forEach((day, index) => {
                day.events.push(row[index]);
            });
        });
    }

    getRecurrenceRow(events, days) {
        const rows = [];
        events.forEach((event) => {
            rows.push(this.getRow(event, days));
        });

        const recurrenceRow = _.merge(...rows);

        recurrenceRow.forEach((recurrenceObj, index) => {
            if (!_.isEmpty(recurrenceObj)) {
                const yesterdayObj = recurrenceRow[index - 1];
                if (yesterdayObj && !_.isEmpty(yesterdayObj)) {
                    yesterdayObj.recursTomorrow = true;
                    recurrenceObj.recurredYesterday = true;
                }
            }
        });

        return recurrenceRow;
    }

    getRow(event, days) {
        let row = [];
        const schedule = event.scheduleInstance;

        if (this.calendar.crCalendarService.scheduleEndsSameDay(schedule)) {
            days.forEach((day) => {
                let model = {};

                if (moment(schedule.start).isSame(day.start) && moment(schedule.end).isSame(day.end)) {
                    model = this.getBlockModel(event, day);
                }

                row.push(model);
            });
        } else {
            row = this.getMultiDayRow(event, days);
        }

        return row;
    }

    getMultiDayRow(event, days) {
        const schedule = event.scheduleInstance;
        const scheduleStartBeforeWeek = moment(schedule.start).isBefore(days[0].start);
        let showTitle = scheduleStartBeforeWeek;
        let startIndex;
        const row = [];

        if (scheduleStartBeforeWeek) {
            startIndex = 0;
        }

        days.forEach((day, index) => {
            let model = {};

            // is last day
            if (
                !_.isUndefined(startIndex) &&
                moment(schedule.end).isSameOrBefore(day.end) &&
                moment(day.start).isBefore(schedule.end)
            ) {
                model = this.getBlockModel(event, day, showTitle);
                startIndex += 1;
            }

            // is in middle
            if (!_.isUndefined(startIndex) && moment(day.end).isBefore(schedule.end)) {
                model = this.getBlockModel(event, day, showTitle);
                startIndex += 1;
                showTitle = false;
            }

            // is first day
            if (moment(schedule.start).isSameOrAfter(day.start) && moment(schedule.start).isBefore(day.end)) {
                startIndex = index;
                model = this.getBlockModel(event, day);
            }

            row.push(model);
        });

        return row;
    }

    getBlockModel(event, day, isFirst = true) {
        const schedule = event.scheduleInstance;
        const tz = this.calendar.timezone.name;
        const dayStart = moment(day.start).tz(tz);
        const scheduleStart = moment(schedule.start).tz(tz);
        const dayEnd = moment(day.end).tz(tz).subtract(1, 'millisecond');
        const scheduleEnd = moment(schedule.end).tz(tz).subtract(1, 'millisecond');

        const model = {
            metadata: event.metadata,
            showLabel: isFirst,
            endsToday: scheduleEnd.isSame(dayEnd, 'day'),
            startsToday: scheduleStart.isSame(dayStart, 'day'),
            isAutomation: event.type === 'experience' && event.metadata.type.indexOf('BROADCAST') === -1,
            inactive: event.type === 'experience' && event.metadata.status === this.crConstants.entity.states.INACTIVE,
        };

        model.metadata.schedule = schedule;

        return model;
    }
}

export default CalendarAllDay;
