import angular from 'angular';

import PosEnableModule from './pos-enable/pos-enable';
import PosEnableModalModule from './pos-enable-modal/pos-enable-modal';

const ComponentModule = angular.module('control-room.places.components.place-details.components', [
    PosEnableModule.name,
    PosEnableModalModule.name,
]);

export default ComponentModule;
