import moment from 'moment';
import 'moment-timezone';

const pastEventColor = '#7cb4d8';
const pastBroadcastColor = '#c8a5c7';
const pastInactiveColor = '#d3d3d3';

class CalendarEvent {
    constructor($timeout) {
        this.$timeout = $timeout;
    }

    $onInit() {
        this.isOpen = false;

        const nowTime = moment().tz(this.calendar.timezone.name);
        const eventEnd = moment(this.data.metadata.schedule.end);
        const midnight = moment(nowTime).endOf('day').add(1, 'millisecond');
        const endsByMidnight = eventEnd.isSameOrBefore(midnight);

        this.isAutomation = this.data.type === 'experience' && this.data.metadata.type.indexOf('BROADCAST') === -1;
        this.isBroadcast = this.data.type === 'experience' && this.data.metadata.type.indexOf('BROADCAST') > -1;

        if (nowTime.isAfter(eventEnd)) {
            this.setPastEvent();
        } else if (endsByMidnight) {
            const millisecondsToEnd = eventEnd.diff(nowTime, 'milliseconds');
            this.$timeout(() => {
                this.setPastEvent();
            }, millisecondsToEnd);
        }
    }

    setPastEvent() {
        this.pastEvent = true;
        if (this.data.inactive) {
            this.data.backgroundColor = pastInactiveColor;
        } else {
            this.data.backgroundColor = this.isBroadcast ? pastBroadcastColor : pastEventColor;
        }
    }
}

export default CalendarEvent;
