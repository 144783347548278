export interface DiscountType {
  displayName: string;
  id: string;
}

export interface TaxCode {
  id: string;
  label: string;
  // UI attribute
  isSelected?: boolean
}

export interface Product {
  extension:                   any;
  id:                          string;
  label:                       string;
  type:                        string;
  status:                      string;
  lastChanged:                 string;
  lastChangedBy:               string;
  description:                 string;
  venueId:                     string;
  purchasePhone:               string;
  purchaseLink:                string;
  mainImage:                   any[];
  infoImage:                   any[];
  trackingPurchaseLinkPostfix: string;
  details:                     any[];
}

export enum TestPostStructure {
  ARRAY =  'ARRAY',
  OTHER =  'OTHER',
}
