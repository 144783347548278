import angular from 'angular';

import _ from 'lodash';

const SelectionManager = angular
    .module('control-room.commons.content.directives.selection-manager', [])
    .directive('crSelectionManager', () => ({
        link: (scope, elem, attrs, ctrl) => {
            ctrl.selectionManagerService = scope.$ctrl.selectionManagerService;
            ctrl.entityType = scope.$ctrl.entityType;
            ctrl.contextPolicyEntity = scope.$ctrl.contextPolicyEntity;
            ctrl.getRows = () => scope.$ctrl.data.rows;
            ctrl.disableMultiSelect = attrs.crSelectionManager === 'disableMultiSelect';

            // reset items if user changes data (filter, search, etc...)
            scope.$on('$locationChangeSuccess', () => {
                ctrl.items = [];
            });
        },
        controller() {
            let items = [];

            this.addItem = (item) => {
                if (!item.isSelected) {
                    item.isSelected = true;
                    items.push(item);
                }
            };

            this.removeItem = (item) => {
                item.isSelected = false;
                _.remove(items, (i) => i.id === item.id);
            };

            this.clearSelected = () => {
                items.forEach((item) => {
                    item.isSelected = false;
                });
                items = [];
            };

            this.handleShiftClick = (item) => {
                if (this.disableMultiSelect) {
                    return;
                }

                if (_.isEmpty(items)) {
                    this.addItem(item);
                } else {
                    const rows = _.map(this.getRows(), (row) => row.data);
                    let startIndex = _.findIndex(rows, { id: _.last(items).id });
                    let endIndex = _.findIndex(rows, { id: item.id });

                    if (startIndex > endIndex) {
                        const temp = startIndex;
                        startIndex = endIndex;
                        endIndex = temp;
                    } else {
                        startIndex += 1;
                        endIndex += 1;
                    }

                    rows.slice(startIndex, endIndex).forEach((row) => this.addItem(row));
                }
            };

            this.getMenuOptions = () =>
                this.selectionManagerService.getConfig(this.entityType, items, this.contextPolicyEntity);
        },
    }))
    .directive('crSelectionItem', () => ({
        require: '^crSelectionManager',
        link: (scope, elem, attrs, selectionManagerCtrl) => {
            elem.on('contextmenu', (e) => {
                if (!selectionManagerCtrl.selectionManagerService) {
                    e.stopPropagation();
                    return;
                }

                const item = scope.row.data;

                if (!item.isSelected) {
                    selectionManagerCtrl.clearSelected();
                    selectionManagerCtrl.addItem(item);
                }

                item.menuOptions = selectionManagerCtrl
                    .getMenuOptions()
                    .then((options) => (options.length > 0 ? options : null));
            });

            elem.on('click', (e) => {
                if (!selectionManagerCtrl.selectionManagerService) {
                    e.stopPropagation();
                    return;
                }

                const item = scope.row.data;

                if (item.isDisabled) {
                    e.stopPropagation();
                    return;
                }

                if (e.shiftKey) {
                    selectionManagerCtrl.handleShiftClick(item);
                } else if (e.ctrlKey || e.metaKey) {
                    if (!selectionManagerCtrl.disableMultiSelect) {
                        if (item.isSelected) {
                            selectionManagerCtrl.removeItem(item);
                        } else {
                            selectionManagerCtrl.addItem(item);
                        }
                    }
                } else {
                    selectionManagerCtrl.clearSelected();
                    selectionManagerCtrl.addItem(item);
                }

                // update the highlight on click, instead of the next digest cycle
                scope.$apply();
            });
        },
    }));

export default SelectionManager;
