import template from './change-arrow.html';
import './change-arrow.less';

const ChangeArrowComponent = {
    template,
    transclude: true,
    bindings: {
        isDescending: '<',
        customClass: '@',
    },
};

export default ChangeArrowComponent;
