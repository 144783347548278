import _ from 'lodash';
import text from './resources/locale/en.json';

class PreferencesDetailsController {
    constructor(
        $q,
        $state,
        crConstants,
        crLocaleService,
        crEntityService,
        crErrorLoggingService,
        crUserPreferencesService,
        crQuestionService
    ) {
        this.$q = $q;
        this.$state = $state;
        this.crLocaleService = crLocaleService;
        this.crConstants = crConstants;
        this.crEntityService = crEntityService;
        this.crErrorLoggingService = crErrorLoggingService;
        this.crUserPreferencesService = crUserPreferencesService;
        this.crQuestionService = crQuestionService;
    }

    $onInit() {
        this.text = text;
        this.userPrefs = {};
        this.hasQuestions = false;
        this.hasPreferences = false;

        this.locale = this.crLocaleService.getLocale(this.$state.params.venueId, this.$state.params.locale);

        this.isLoading = true;
        this.isBrandQuestionsLoading = true;
        this.isVenueQuestionsLoading = true;
        this.getBrandAndVenuePreferences()
            .then((prefs) => {
                let brandTags;
                let venueTags;

                if (!_.isEmpty(prefs.brand)) {
                    brandTags = prefs.brand.screens[0].tags;
                    delete prefs.brand.screens[0].tags;
                    this.hasPreferences = true;
                }

                if (!_.isEmpty(prefs.venue)) {
                    venueTags = prefs.venue.screens[0].tags;
                    delete prefs.venue.screens[0].tags;
                    this.hasPreferences = true;
                }

                this.userPrefs = prefs;

                if (this.hasPreferences) {
                    this.getBrandLocalizedQuestions(brandTags);

                    if (!_.isEmpty(this.userPrefs.venue) && !_.isEmpty(venueTags)) {
                        this.getVenueLocalizedQuestions(venueTags);
                    } else {
                        this.isVenueQuestionsLoading = false;
                    }
                }
            })
            .catch((err) => {
                this.hasDataError = true;
                this.crErrorLoggingService.logError('Could not get user Preferences', err);
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    getBrandAndVenuePreferences() {
        const type = this.crConstants.entity.types.SCREEN_FLOWS;
        return this.$q
            .all([
                this.getUserPreferences(type),
                this.getUserPreferences(type, this.$state.params.venueId),
                this.getQuestions(),
            ])
            .then((res) => {
                this.hasQuestions = !!res[2].content.length;
                return {
                    brand: res[0],
                    venue: res[1],
                };
            });
    }

    getBrandLocalizedQuestions(tags) {
        return this.getQuestionsLocalization(tags)
            .then((localizedQuestions) => {
                this.userPrefs.brand.screens[0].tags = this.getTagsWithLocalization(localizedQuestions, tags);
                this.userPrefs.brand = _.cloneDeep(this.userPrefs.brand);
            })
            .catch((err) => {
                this.hasDataError = true;
                this.crErrorLoggingService.logError('could not get questions localization', err);
            })
            .finally(() => {
                this.isBrandQuestionsLoading = false;
            });
    }

    getVenueLocalizedQuestions(tags, venueId = this.$state.params.venueId) {
        return this.getQuestionsLocalization(tags, venueId)
            .then((localizedQuestions) => {
                this.userPrefs.venue.screens[0].tags = this.getTagsWithLocalization(localizedQuestions, tags);
                this.userPrefs.venue = _.cloneDeep(this.userPrefs.venue);
            })
            .catch((err) => {
                this.hasDataError = true;
                this.crErrorLoggingService.logError('could not get questions localization', err);
            })
            .finally(() => {
                this.isVenueQuestionsLoading = false;
            });
    }

    getQuestionsLocalization(questions, level, type = this.crConstants.entity.types.TAG_DEFINITIONS) {
        return this.$q.all(
            questions.map((question) =>
                this.crQuestionService.isLocalizationEnabled
                    ? this.crQuestionService.getQuestionLocalization(
                          type,
                          question.tagDefinitionKey,
                          level,
                          question.tagDefinition
                      )
                    : this.crQuestionService.getDefaultLocalization(question.tagDefinition, this.$state.params.venueId)
            )
        );
    }

    getTagsWithLocalization(localizedQuestions, tags) {
        return tags.map((tag, index) => {
            this.syncValueOrder(localizedQuestions[index], tag.tagDefinition.values);
            tag.tagDefinition.localization = localizedQuestions[index];
            return tag;
        });
    }

    getUserPreferences(route, venueId) {
        return this.$q((resolve, reject) => {
            this.crUserPreferencesService
                .getUserPreferences(route, venueId)
                .then((data) => resolve(data))
                .catch((err) => (err.status === 404 ? resolve(null) : reject()));
        });
    }

    getQuestions() {
        const route = this.crConstants.entity.types.TAG_DEFINITIONS;
        const { venueId } = this.$state.params;
        const definitionType = 'QUESTION';
        return this.$q((resolve, reject) => {
            this.crEntityService
                .getEntityList(route, { venueId, definitionType })
                .then((data) => resolve(data))
                .catch((err) => (err.status === 404 ? resolve([]) : reject()));
        });
    }

    syncValueOrder(localizedQuestion, orderedValues) {
        const orderingMap = {};

        orderedValues.forEach((value, index) => {
            orderingMap[value.tagValueKey] = index;

            _.forEach(localizedQuestion, (locale) => {
                locale.values = locale.values || [];
                const valueLocalization = locale.values.find((vl) => vl.tagValueKey === value.tagValueKey);

                if (!valueLocalization) {
                    locale.values.push({ tagValueKey: value.tagValueKey, label: '' });
                }
            });
        });

        _.forEach(localizedQuestion, (locale) => {
            if (!locale.values) {
                locale.values = [];
            } else {
                locale.values.sort((a, b) => {
                    if (_.isUndefined(orderingMap[a.tagValueKey])) {
                        return 1;
                    }
                    if (_.isUndefined(orderingMap[b.tagValueKey])) {
                        return -1;
                    }
                    return orderingMap[a.tagValueKey] - orderingMap[b.tagValueKey];
                });
            }
        });
    }

    localeUpdated(event) {
        this.locale.current = event.locale.id;
        this.locale = _.cloneDeep(this.locale);
    }

    goToPrefCreate() {
        this.$state.go('client.app-settings.preferences-create');
    }

    goToEditPage() {
        this.$state.go('client.app-settings.preferences-edit');
    }

    goToCreateQuestionPage() {
        this.$state.go('client.app-settings.question-create');
    }
}

export default PreferencesDetailsController;
