import template from './foot-note.html';
import controller from './foot-note.controller';
import './foot-note.less';

const Component = {
    template,
    transclude: true,
    bindings: {
        lastChanged: '@?',
        lastChangedBy: '@?',
        customClass: '@?',
    },
    controller: ['$state', 'crVenueService', controller],
};

export default Component;
