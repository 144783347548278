import angular from 'angular';

import text from './resources/locale/en.json';
import TabularOrdersPlacedMockService from './tabular-orders-placed.mock.service';
import TabularOrdersPlacedComponent from './tabular-orders-placed.component';

const TabularOrdersPlacedModule = angular
    .module('.control-room.reports.components.orders.components.tabular-orders-placed', [])
    .service('crReportingTabularOrdersPlacedMockService', TabularOrdersPlacedMockService)
    .constant('crReportingTabularOrdersPlacedText', text)
    .component('crReportingTabularOrdersPlaced', TabularOrdersPlacedComponent);

export default TabularOrdersPlacedModule;
