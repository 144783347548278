import HoursBuilderController from './hours-builder.controller';

import template from './hours-builder.html';

const HoursBuilderComponent = {
    template,
    bindings: {
        onChange: '&?',
        rows: '<',
    },
    controller: ['hoursBuilderText', HoursBuilderController],
};

export default HoursBuilderComponent;
