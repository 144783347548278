import controller from './what.controller';
import template from './what.html';

import './what.less';

const component = {
    template,
    bindings: {
        experience: '<',
        edit: '<',
        form: '<',
        events: '<',
        pois: '<',
        coupons: '<',
        products: '<',
        loQueueProducts: '<',
        poisWithRides: '<',
        onChanges: '&',
    },
    controller: [
        '$state',
        '$q',
        '$timeout',
        'crConfig',
        'crConstants',
        'crLocaleService',
        'crAnalyticsService',
        'crEntityService',
        'crErrorLoggingService',
        'crPoiService',
        controller,
    ],
};

export default component;
