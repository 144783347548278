import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { StateService } from '@uirouter/core';

import { LocalizedText } from '../../../../core';
import { Kitchen } from '../../../../shared/models/kitchen.model';
import { KitchenService } from '../../../../shared/services/kitchen/kitchen.service';

import text from './resources/locale/en.json';

@Component({
  selector: 'cr-kitchen-select',
  templateUrl: './kitchen-select.component.html',
  styleUrls: ['./kitchen-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class KitchenSelectComponent implements OnInit {
  hasError: boolean;

  isLoading: boolean;

  kitchens: Kitchen[];

  text: LocalizedText;

  constructor(private kitchenService: KitchenService, private state: StateService) {
    this.isLoading = true;
    this.text = text as LocalizedText;
  }

  ngOnInit(): void {
    this.kitchenService
      .getKitchenList(this.state.params.venueId)
      .then((res) => {
        this.kitchens = res.places;
      })
      .catch((err) => {
        console.error(err);
        this.hasError = true;
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  selectKitchen(kitchenId: string) {
    this.state.go('client.fnb-order-queue.order-list', { kitchenId });
  }
}
