import angular from 'angular';
import text from '../resources/locale/en.json';

const PoiConstantsModule = angular.module('control-room.poi.constants', []).constant('crPoiConstants', {
    categoryLabelEllipsifyLength: 24,
    states: {
        ARCHIVED: 'ARCHIVED',
        ACTIVE: 'ACTIVE',
        INACTIVE: 'INACTIVE',
        DRAFT: 'DRAFT',
    },
    stateItems: [
        { id: 'ACTIVE', label: text.active },
        { id: 'INACTIVE', label: text.inactive },
        { id: 'DRAFT', label: text.draft, restricted: true },
    ],
});

export default PoiConstantsModule;
