<div class="order">
  <div class="order-details">
    <div class="order-column small-col">
      <div class="order-field">
        <div class="field-label">
          {{ text.orderNumber }}
        </div>
        <div class="field-value">{{ order.externalOrderId }}</div>
      </div>
      <div class="order-field">
        <div class="field-label">
          {{ text.guestName }}
        </div>
        <div class="field-value">
          {{ order.customerName }}
        </div>
      </div>
    </div>
    <div class="order-column small-col">
      <div class="order-field">
        <div class="field-label">
          {{ text.orderDate }}
        </div>
        <div class="order-value">
          {{ order.displayDate }}
        </div>
      </div>
      <div class="order-field">
        <div class="field-label">
          {{ text.guestEmail }}
        </div>
        <div class="field-value">
          {{ order.customerEmail }}
        </div>
      </div>
    </div>
    <div
      class="order-column"
      *ngIf="order.customerPhone || order.estimatedWaitTime !== null"
    >
      <div *ngIf="order.estimatedWaitTime !== null" class="order-field">
        <div class="field-label">
          {{ text.promiseTime }}
        </div>
        <div class="field-value">
          {{ order.displayPrepTime }}
        </div>
      </div>
      <div class="order-field" *ngIf="order.customerPhone">
        <div class="field-label">
          {{ text.guestContactMumber }}
        </div>
        <div class="field-value">
          {{ order.customerPhone }}
        </div>
      </div>
    </div>
    <div class="order-column">
      <div class="order-field">
        <div class="field-label">
          {{ text.orderTotal }}
        </div>
        <div class="field-value">
          {{ order.displayOrderTotal }}
        </div>
        <span class="value-refund" *ngIf="order.isRefund">
          {{ order.displayOrderRefundAmount }}
          {{ text.refunded }}
        </span>
      </div>
    </div>
  </div>
  <div class="order-controls">
    <div
      class="fulfillment-button"
      crPolicy
      policyAction="write:fnb-order"
      [ngClass]="orderClassStyles"
      (click)="editOrder()"
      *ngIf="order.orderStatus === 'SUBMITTED'"
    >
      {{ text.status[order.orderStatus] }}
      <span *ngIf="writeAllowed" class="icon icon-cr-edit"></span>
    </div>
    <div
      class="fulfillment-button"
      crPolicy
      policyAction="write:fnb-order"
      [ngClass]="orderClassStyles"
      (click)="editOrder()"
      *ngIf="order.orderStatus !== 'SUBMITTED'"
    >
      {{ text.status[order.paymentStatus] }}
      {{ text.status[order.fulfillmentStatus] }}
      <span *ngIf="writeAllowed" class="icon icon-cr-edit"></span>
    </div>
    <div
      class="refund-link"
      crPolicy
      policyAction="read:fnb-refund"
      *ngIf="
        isRefundsEnabled && !order.isRefund && order.orderStatus !== 'SUBMITTED'
      "
      (click)="openRefundScreen()"
    >
      {{ text.issueRefund }}
    </div>
    <div class="ptw-control" *ngIf="order.pickupWindow">
      <div class="ptw-control-label">{{ text.pickUpTimeWindow }}</div>
      <div class="ptw-control-value" [ngClass]="orderClassStyles">
        {{ ptwStartTimeDisplay }} - {{ ptwEndTimeDisplay }}
      </div>
    </div>
  </div>
</div>
<div *ngIf="isExpanded">
  <cr-fnb-order-receipt
    [order]="order"
    [kitchenId]="kitchenId"
    [isPrintingEnabled]="isPrintingEnabled"
  ></cr-fnb-order-receipt>
</div>
<div class="more-info">
  <section class="small-screen">
    <div class="details-button" (click)="openOrderDetails()">
      <div>
        {{ text.fullDetails }}
        <span class="icon icon-cr-chevron-right"></span>
      </div>
    </div>
  </section>
  <section class="large-screen">
    <div class="details-button" (click)="isExpanded = !isExpanded">
      <div *ngIf="!isExpanded">
        {{ text.fullDetails }}
        <span class="icon icon-cr-chevron-down"></span>
      </div>
      <div *ngIf="isExpanded">
        {{ text.hideDetails }}
        <span class="icon icon-cr-chevron-up"></span>
      </div>
    </div>
  </section>
  <div class="order-age">
    {{ orderAge }}
  </div>
</div>
