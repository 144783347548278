import _ from 'lodash';

class AssociateEntityModal {
    constructor($state, $sce, crConstants, crLocaleService, crEntityService, crErrorLoggingService) {
        this.$state = $state;
        this.$sce = $sce;
        this.crConstants = crConstants;
        this.crLocaleService = crLocaleService;
        this.crEntityService = crEntityService;
        this.crErrorLoggingService = crErrorLoggingService;
    }

    $onInit() {
        this.tags = [];
        this.options = [];
        this.isLoading = true;

        this.resolve.modalData.title = this.$sce.trustAsHtml(this.resolve.modalData.title);

        const { associateType } = this.resolve.modalData;
        const { venueId } = this.$state.params;

        this.locale = this.crLocaleService.getLocale(venueId);

        const params = [associateType, venueId, this.locale.default];
        const hierarchyType = this.crConstants.entity.types.POI_CATEGORIES;
        const dataProvider =
            associateType === hierarchyType
                ? this.getCategoriesWithHierarchy.bind(this)
                : this.getEntityList.bind(this);

        dataProvider(...params)
            .catch((err) => {
                this.crErrorLoggingService.logError(
                    'Associate Entity Model - could not retrieve data',
                    err,
                    associateType
                );
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    getCategoriesWithHierarchy(associateType, venueId, locale = this.locale.default) {
        return this.crEntityService.getCategoriesWithHierarchy(associateType, venueId, locale).then((categories) => {
            this.options = categories.map((category) => ({
                label: category.hierarchyLabel,
                id: category.id,
                parentId: category.parentId,
                indent: category.parentId,
            }));
        });
    }

    getEntityList(associateType, venueId, locale = this.locale.default) {
        return this.crEntityService.getEntityList(associateType, { venueId }, locale).then((res) => {
            this.options = res.content.map((entity) => ({
                label: entity.label || entity.name,
                id: entity.id,
            }));
        });
    }

    onCancel() {
        this.dismiss();
    }

    onSubmit() {
        if (!_.isEmpty(this.resolve.modalData.tags)) {
            this.close();
        } else {
            this.dismiss();
        }
    }

    onSelected(tags) {
        this.resolve.modalData.tags = tags;
    }
}

export default AssociateEntityModal;
