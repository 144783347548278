import angular from 'angular';
import text from './resources/locale/en.json';
import ModalComponent from './modal.component';

const ModalModule = angular
    .module('control-room.commons.layout.components.modal', [])
    .constant('crModalText', text)
    .component('crModal', ModalComponent);

export default ModalModule;
