import _ from 'lodash';
import elementResizeDetectorMaker from 'element-resize-detector';

import angular from 'angular';

import template from './canvas-map.html';
import './canvas-map.less';

class CanvasMapDirective {
    constructor(locale, $compile, $q, crAnalyticsService) {
        this.text = locale;
        this.restrict = 'E';
        this.template = template;
        this.scope = {
            source: '@',
            onDrag: '&',
            onClick: '&',
            onMousedown: '&',
            onMouseup: '&',
            onMousewheel: '&',
            onMousemove: '&',
            onMapLoad: '&',
            onFocusChange: '&',
            onZoomChange: '&',
            markers: '<',
            focus: '=?',
            isLoading: '<',
            hasDataError: '<',
            defaultZoomLevel: '@',
            tooltipComponent: '@',
            tooltipTarget: '@',
            zoom: '=?',
            zoomUi: '<',
            zoomEnabled: '<',
            fullscreen: '=?',
            fullscreenUi: '<',
            isScrollable: '<',
        };

        this.$compile = $compile;
        this.$q = $q;
        this.crAnalyticsService = crAnalyticsService;
    }

    static directiveFactory(locale, $compile, $q, crAnalyticsService) {
        return new CanvasMapDirective(locale, $compile, $q, crAnalyticsService);
    }

    compile() {
        const _this = new CanvasMapDirective(this.text, this.$compile, this.$q, this.crAnalyticsService);

        return this.link.bind(_this);
    }

    link(scope, element) {
        this.active = true;
        this.setBinds();

        this.container = element.parent()[0];
        this.tooltip = this.container.querySelector('.tooltip');
        this.tooltipInner = this.container.querySelector('.tooltip-inner');

        this.currCanvas = 2;

        this.canvas1 = element[0].querySelector(".cr-canvas[data-canvas='1']");
        this.ctx1 = this.canvas1.getContext('2d');

        this.canvas2 = element[0].querySelector(".cr-canvas[data-canvas='2']");
        this.ctx2 = this.canvas2.getContext('2d');

        this.mapCanvas = document.createElement('canvas');
        this.mapCtx = this.mapCanvas.getContext('2d');
        this.markerCanvas = document.createElement('canvas');
        this.markerCtx = this.markerCanvas.getContext('2d');

        this.setReRender();

        this.mapImage = new Image();
        this.mapImage.src = scope.source;

        this.scope = scope;
        this.minZoom = 1;

        this.scope.text = this.text;
        this.scope.imagesLoading = true;

        this.scope.$ctrl = this.scope;
        this.scope.isScrollable = !_.isUndefined(this.scope.isScrollable) ? this.scope.isScrollable : true;
        this.scope.zoomEnabled = !_.isUndefined(this.scope.zoomEnabled) ? this.scope.zoomEnabled : true;

        this.scope.zoom = !_.isUndefined(this.scope.zoom) ? this.scope.zoom : 1;
        this.scope.onZoomChange({ $event: { zoom: this.scope.zoom } });
        this.scope.fullscreen = !_.isUndefined(this.scope.fullscreen) ? this.scope.fullscreen : false;
        this.scope.markers = this.scope.markers || [];

        this.scope.toggleFullscreen = this.toggleFullscreen;
        this.scope.zoomIn = this.zoomIn;
        this.scope.zoomOut = this.zoomOut;

        this.scope.$on('$destroy', () => {
            this.active = false;
            this.removeListeners();
        });

        this.preloadedImages = {};

        const preloadPromises = this.scope.markers.map((marker) =>
            this.$q((resolve) => {
                const image = new Image();

                image.src = marker.source;

                image.onload = () => {
                    resolve();
                };

                this.preloadedImages[marker.source] = image;
            })
        );

        preloadPromises.push(
            this.$q((resolve) => {
                this.mapImage.onload = () => {
                    resolve();
                };
            })
        );

        this.$q.all(preloadPromises).then(() => {
            this.scope.focus = this.scope.focus || [
                Math.round(this.mapImage.width / 2),
                Math.round(this.mapImage.height / 2),
            ];
            this.scope.onFocusChange({ $event: { focus: this.scope.focus } });
            this.minFocus = [0, 0];
            this.maxFocus = [this.mapImage.width, this.mapImage.height];

            this.syncCanvasDimensions();

            if (this.scope.defaultZoomLevel) {
                this.scope.zoom = (this.canvas1.width / this.mapImage.width) * this.scope.defaultZoomLevel;
            } else {
                this.scope.zoom = Math.max(
                    this.container.offsetWidth / this.mapImage.width,
                    this.container.offsetHeight / this.mapImage.height
                );
            }

            this.scope.onZoomChange({ $event: { zoom: this.scope.zoom } });

            this.addListeners();
            this.scope.imagesLoading = false;
            this.setReRender();
            this.render();
            this.scope.onMapLoad({
                event: {
                    mapImage: this.mapImage,
                    canvas: this.canvas1,
                },
            });
        });

        this.scope.$watch('isLoading', () => {
            this.hideTooltip();
        });

        this.scope.$watch('imagesLoading', () => {
            this.hideTooltip();
        });

        this.scope.$watch('zoom', () => {
            this.hideTooltip();
        });

        this.scope.$watch('source', (source) => {
            this.mapImage.src = source;
        });

        this.scope.$watch(
            'markers',
            () => {
                this.markerReRender = true;
                this.hideTooltip();
            },
            true
        );

        this.scope.$watchCollection('focus', () => {
            this.setReRender();
        });
    }

    zoomIn() {
        this.scope.zoom += 0.1;
        this.scope.onZoomChange({ $event: { zoom: this.scope.zoom, user: true } });
        this.setReRender();
    }

    zoomOut() {
        this.scope.zoom = Math.max(this.scope.zoom - 0.1, this.minZoom);
        this.scope.onZoomChange({ $event: { zoom: this.scope.zoom, user: true } });
        this.setReRender();
    }

    toggleFullscreen() {
        this.scope.fullscreen = !this.scope.fullscreen;
    }

    setBinds() {
        this.toggleFullscreen = this.toggleFullscreen.bind(this);
        this.zoomIn = this.zoomIn.bind(this);
        this.zoomOut = this.zoomOut.bind(this);
        this.syncCanvasDimensions = this.syncCanvasDimensions.bind(this);
        this.mousewheelHandler = this.mousewheelHandler.bind(this);
        this.mousedownHandler = this.mousedownHandler.bind(this);
        this.mouseupHandler = this.mouseupHandler.bind(this);
        this.mousemoveHandler = this.mousemoveHandler.bind(this);
        this.render = this.render.bind(this);
        this.showTooltip = this.showTooltip.bind(this);
    }

    preventDefault(e) {
        e.preventDefault();
    }

    mousewheelHandler(e) {
        if (this.scope.isLoading) {
            return;
        }

        e.preventDefault();
        const target = this.findTargetMarker(e);

        if (target && target.onMousewheel) {
            target.onMousewheel(e);
        } else {
            if (this.scope.zoomEnabled) {
                const delta = Math.max(-1, Math.min(1, -e.deltaY || -e.detail));

                this.scope.zoom = Math.max(this.scope.zoom + delta / 10, this.minZoom);

                this.scope.onZoomChange({ $event: { zoom: this.scope.zoom, user: true } });
                this.setReRender();
            }

            this.scope.onMousewheel({ event: e });
        }

        this.scope.$apply();
    }

    mousedownHandler(e) {
        if (this.scope.isLoading) {
            return;
        }

        this.noMousemove = true;
        this.hideTooltip();

        e = this.transformMouseEvent(e);

        const target = this.findTargetMarker(e);

        if (target && (target.onDrag || target.onMouseDown || target.onClick || target.tooltip)) {
            if (e.button === 0 && (target.onDrag || target.onClick || target.tooltip)) {
                this.targetDrag = target;

                if (this.targetDrag.tooltip) {
                    this.showTooltip(this.targetDrag);
                    this.crAnalyticsService.track('POI Map Pin Clicked');
                }
            }

            if (target.onMousedown) {
                target.onMousedown(e);
            }
        } else {
            this.scope.onMousedown({ event: e });
        }

        if (e.button === 0) {
            this.drag = true;
        }

        this.scope.$apply();
    }

    getAnchorOffset(marker) {
        const image = this.getImage(marker);

        const zoomedMultiplier = marker.zoomed ? this.scope.zoom : 1;

        const w = (marker.w || image.width) * zoomedMultiplier;
        const h = (marker.h || image.height) * zoomedMultiplier;

        const anchor = (marker.anchor || 'center-center').toLowerCase().split('-');

        let xAnchorOffset;
        if (anchor[0] === 'center') {
            xAnchorOffset = -(w / 2);
        } else if (anchor[0] === 'right') {
            xAnchorOffset = w;
        } else {
            xAnchorOffset = 0;
        }

        let yAnchorOffset;
        if (anchor[1] === 'center') {
            yAnchorOffset = -(h / 2);
        } else if (anchor[1] === 'bottom') {
            yAnchorOffset = -h;
        } else {
            yAnchorOffset = 0;
        }

        return [xAnchorOffset, yAnchorOffset];
    }

    hideTooltip() {
        this.tooltip.classList.remove('in');
    }

    getImage(marker) {
        let image;

        if (this.preloadedImages[marker.source]) {
            image = this.preloadedImages[marker.source];
        } else {
            this.scope.imagesLoading = true;
            image = new Image();
            image.src = marker.source;

            image.onload = () => {
                this.markerReRender = true;
                this.scope.imagesLoading = false;

                this.scope.$apply();
            };

            this.preloadedImages[marker.source] = image;
        }

        return image;
    }

    positionTooltip() {
        if (!this.currentTooltipTarget) {
            return;
        }

        const position = this.getTargetClientPosition(this.currentTooltipTarget);

        this.tooltip.style.left = `${position.x}px`;
        this.tooltip.style.bottom = `calc(100% - ${position.y}px)`;
    }

    showTooltip(target) {
        const label = target.label ? `${target.label}<br>` : '';
        const { tooltipComponent } = this.scope;
        const tooltipTarget = this.scope.tooltipTarget || '';
        const tooltipInner = angular.element(this.tooltipInner);

        tooltipInner.empty();

        this.tooltip.classList.add('in');

        this.currentTooltipTarget = target;

        this.positionTooltip();

        if (!tooltipComponent) {
            tooltipInner.html(`${label}${target.x}, ${target.y}`);
        } else {
            if (this.tooltipScope) {
                this.tooltipScope.$destroy();
            }
            this.tooltipScope = this.scope.$new(true);
            this.tooltipScope.resolve = target;
            tooltipInner.append(
                this.$compile(
                    `<${tooltipComponent} target="${tooltipTarget}" resolve="resolve"></${tooltipComponent}>`
                )(this.tooltipScope)
            );
        }
    }

    mouseupHandler(e) {
        if (this.scope.isLoading) {
            return;
        }

        e = this.transformMouseEvent(e);
        if (this.targetDrag) {
            if (e.button === 0 && this.targetDrag.onMouseup) {
                this.targetDrag.onMouseup(e);
            }

            if (this.noMousemove) {
                if (this.targetDrag.onClick) {
                    this.targetDrag.onClick(_.merge({ type: 'click' }, e));
                }
            }

            if (e.button === 0) {
                this.targetDrag = false;
            }
        }
        if (this.drag) {
            if (e.button === 0) {
                this.drag = false;
            }

            this.scope.onMouseup({ event: e });

            if (this.noMousemove) {
                this.scope.onClick({ event: _.merge({ type: 'click' }, e) });
            }
        } else if (!this.drag) {
            const target = this.findTargetMarker(e);

            if (target && target.onMouseup) {
                if (target.drag) {
                    target.drag = false;
                }

                target.onMouseup(e);
            } else {
                this.scope.onMouseup({ event: e });
            }
        }

        this.scope.$apply();
    }

    mousemoveHandler(e) {
        if (this.scope.isLoading) {
            return;
        }

        this.noMousemove = false;

        e = this.transformMouseEvent(e);

        if (this.targetDrag && !this.targetDrag.onDrag) {
            this.targetDrag = null;
            this.drag = null;
        }

        if (this.targetDrag) {
            if (this.targetDrag.onMousemove) {
                this.targetDrag.onMousemove(e);
            }

            document.documentElement.style.cursor = 'move';

            this.targetDrag.onDrag(e);
        } else if (this.drag) {
            if (this.scope.isScrollable) {
                this.scope.focus = [this.scope.focus[0] - e.mapMovementX, this.scope.focus[1] - e.mapMovementY];
                this.scope.focus[0] = Math.min(this.maxFocus[0], Math.max(this.minFocus[0], this.scope.focus[0]));
                this.scope.focus[1] = Math.min(this.maxFocus[1], Math.max(this.minFocus[1], this.scope.focus[1]));

                this.setReRender();

                document.documentElement.style.cursor = 'move';

                this.scope.onFocusChange({ $event: { focus: this.scope.focus } });
            }

            this.scope.onMousemove({ event: e });
            this.scope.onDrag({ event: e });
        } else {
            const target = this.findTargetMarker(e);

            if (target) {
                if (target.cursor) {
                    document.documentElement.style.cursor = target.cursor;
                }

                if (target.onMousemove) {
                    target.onMousemove(e);
                } else {
                    this.scope.onMousemove({ event: e });
                }
            } else {
                document.documentElement.style.cursor = null;

                this.scope.onMousemove({ event: e });
            }
        }

        this.scope.$apply();
    }

    addListeners() {
        this.canvas1.addEventListener('wheel', this.mousewheelHandler, false);
        this.canvas1.addEventListener('mousedown', this.mousedownHandler, false);
        this.canvas2.addEventListener('wheel', this.mousewheelHandler, false);
        this.canvas2.addEventListener('mousedown', this.mousedownHandler, false);
        document.addEventListener('mouseup', this.mouseupHandler, false);
        document.addEventListener('mousemove', this.mousemoveHandler, false);

        this.erd = elementResizeDetectorMaker();
        this.erd.listenTo(this.container, this.syncCanvasDimensions);
    }

    removeListeners() {
        this.canvas1.removeEventListener('wheel', this.mousewheelHandler, false);
        this.canvas1.removeEventListener('mousedown', this.mousedownHandler, false);
        this.canvas2.removeEventListener('wheel', this.mousewheelHandler, false);
        this.canvas2.removeEventListener('mousedown', this.mousedownHandler, false);
        document.removeEventListener('mouseup', this.mouseupHandler, false);
        document.removeEventListener('mousemove', this.mousemoveHandler, false);

        if (this.erd && this.container) {
            this.erd.uninstall(this.container);
        }
    }

    render() {
        if (!this.active || !this.scope.focus) {
            return;
        }

        const offsetX = this.scope.focus[0] * this.scope.zoom - this.container.offsetWidth / 2;
        const offsetY = this.scope.focus[1] * this.scope.zoom - this.container.offsetHeight / 2;

        if (this.mapReRender) {
            this.mapCtx.clearRect(0, 0, this.mapCanvas.width, this.mapCanvas.height);
            this.mapCtx.drawImage(
                this.mapImage,
                -offsetX,
                -offsetY,
                this.mapImage.width * this.scope.zoom,
                this.mapImage.height * this.scope.zoom
            );
        }
        if (this.markerReRender) {
            this.markerCtx.clearRect(0, 0, this.markerCanvas.width, this.markerCanvas.height);
            this.scope.markers.forEach((marker) => {
                const image = this.getImage(marker);

                const x = marker.x * this.scope.zoom;
                const y = marker.y * this.scope.zoom;

                const zoomedMultiplier = marker.zoomed ? this.scope.zoom : 1;

                const w = (marker.w || image.width) * zoomedMultiplier;
                const h = (marker.h || image.height) * zoomedMultiplier;

                const anchorOffset = this.getAnchorOffset(marker);

                this.markerCtx.drawImage(image, -offsetX + x + anchorOffset[0], -offsetY + y + anchorOffset[1], w, h);
            });
        }

        if (this.markerReRender || this.mapReRender) {
            const prevCanvas = this[`canvas${this.currCanvas}`];
            this.currCanvas = this.currCanvas === 1 ? 2 : 1;
            const canvas = this[`canvas${this.currCanvas}`];
            const ctx = canvas.getContext('2d');

            canvas.width = this.currentWidth;
            canvas.height = this.currentHeight;

            ctx.scale(this.ratio || 1, this.ratio || 1);

            this.mapReRender = false;
            this.markerReRender = false;

            ctx.clearRect(0, 0, canvas.width, canvas.height);

            if (this.mapCanvas.width && this.mapCanvas.height) {
                ctx.drawImage(this.mapCanvas, 0, 0, this.mapCanvas.width, this.mapCanvas.height);
                ctx.drawImage(this.markerCanvas, 0, 0, this.markerCanvas.width, this.markerCanvas.height);
            }

            canvas.style.display = 'block';
            prevCanvas.style.display = 'none';
        }

        requestAnimationFrame(this.render);
    }

    syncCanvasDimensions() {
        const dpr = window.devicePixelRatio || 1;
        const backingStoreRatio =
            this.ctx1.webkitBackingStorePixelRatio ||
            this.ctx1.mozBackingStorePixelRatio ||
            this.ctx1.msBackingStorePixelRatio ||
            this.ctx1.oBackingStorePixelRatio ||
            this.ctx1.backingStorePixelRatio ||
            1;

        const ratio = dpr / backingStoreRatio;

        this.currentWidth = this.container.offsetWidth;
        this.mapCanvas.width = this.container.offsetWidth;
        this.markerCanvas.width = this.container.offsetWidth;

        this.currentHeight = this.container.offsetHeight;
        this.mapCanvas.height = this.container.offsetHeight;
        this.markerCanvas.height = this.container.offsetHeight;

        if (dpr !== backingStoreRatio) {
            const newWidth = this.currentWidth * ratio;
            this.currentWidth = newWidth;
            this.mapCanvas.width = newWidth;
            this.markerCanvas.width = newWidth;

            const newHeight = this.currentHeight * ratio;
            this.currentHeight = newHeight;
            this.mapCanvas.height = newHeight;
            this.markerCanvas.height = newHeight;

            this.ratio = ratio;
        }

        if (this.mapImage && this.mapImage.width && this.mapImage.height) {
            const oldMinZoom = this.minZoom;

            const widthZoom = this.container.offsetWidth / this.mapImage.width;
            const heightZoom = this.container.offsetHeight / this.mapImage.height;
            this.minZoom = Math.min(widthZoom, heightZoom);

            if (oldMinZoom === this.scope.zoom) {
                this.scope.zoom = this.minZoom;

                this.scope.onZoomChange({ $event: { zoom: this.scope.zoom } });
            }

            this.scope.focus[0] = Math.min(this.maxFocus[0], Math.max(this.minFocus[0], this.scope.focus[0]));
            this.scope.focus[1] = Math.min(this.maxFocus[1], Math.max(this.minFocus[1], this.scope.focus[1]));

            this.scope.onFocusChange({ $event: { focus: this.scope.focus } });

            this.setReRender();

            this.positionTooltip();
        }
    }

    transformMouseEvent(e) {
        const rect = this.container.getBoundingClientRect();
        const clientX = e.clientX - rect.left;
        const clientY = e.clientY - rect.top;

        const offsetX = this.scope.focus[0] * this.scope.zoom - this.container.offsetWidth / 2;
        const offsetY = this.scope.focus[1] * this.scope.zoom - this.container.offsetHeight / 2;

        const mapX = clientX / this.scope.zoom + offsetX / this.scope.zoom;
        const mapY = clientY / this.scope.zoom + offsetY / this.scope.zoom;

        const movementX = this.lastEvent ? e.clientX - this.lastEvent.clientX : 0;
        const movementY = this.lastEvent ? e.clientY - this.lastEvent.clientY : 0;

        this.lastEvent = e;

        return {
            type: e.type,
            button: e.button,
            clientX,
            clientY,
            mapX,
            mapY,
            mapWidth: this.mapImage.width,
            mapHeight: this.mapImage.height,
            movementX: e.movementX || movementX,
            movementY: e.movementY || movementY,
            mapMovementX: (e.movementX || movementX) / this.scope.zoom,
            mapMovementY: (e.movementY || movementY) / this.scope.zoom,
        };
    }

    getTargetClientPosition(target) {
        const offsetX = this.scope.focus[0] * this.scope.zoom - this.container.offsetWidth / 2;
        const offsetY = this.scope.focus[1] * this.scope.zoom - this.container.offsetHeight / 2;

        const image = this.getImage(target);

        const w = target.w || image.w;
        const h = target.h || image.h;

        const anchorOffset = this.getAnchorOffset(target);

        const zoomedMultiplier = target.zoomed ? this.scope.zoom : 1;
        const zoomedWidth = w * zoomedMultiplier;
        const zoomedHeight = h * zoomedMultiplier;

        const targetClientX =
            target.x * this.scope.zoom - offsetX + anchorOffset[0] * zoomedMultiplier + zoomedWidth / 2;
        const targetClientY =
            target.y * this.scope.zoom - offsetY + anchorOffset[1] * zoomedMultiplier + zoomedHeight / 2;

        return { x: targetClientX, y: targetClientY };
    }

    findTargetMarker(e) {
        let match;

        this.scope.markers.forEach((marker) => {
            const image = this.getImage(marker);

            const w = marker.w || image.w;
            const h = marker.h || image.h;

            const anchorOffset = this.getAnchorOffset(marker);

            const zoomedMultiplier = marker.zoomed ? 1 : 1 / this.scope.zoom;
            const zoomedWidth = w * zoomedMultiplier;
            const zoomedHeight = h * zoomedMultiplier;

            const minX = marker.x + anchorOffset[0] * zoomedMultiplier;
            const maxX = marker.x + zoomedWidth + anchorOffset[0] * zoomedMultiplier;
            const minY = marker.y + anchorOffset[1] * zoomedMultiplier;
            const maxY = marker.y + zoomedHeight + anchorOffset[1] * zoomedMultiplier;

            if (e.mapX >= minX && e.mapX <= maxX && e.mapY >= minY && e.mapY <= maxY) {
                match = marker;
            }
        });

        return match;
    }

    setReRender() {
        this.mapReRender = true;
        this.markerReRender = true;
    }
}

CanvasMapDirective.directiveFactory.$inject = ['crCanvasMapLocale', '$compile', '$q', 'crAnalyticsService'];

export default CanvasMapDirective;
