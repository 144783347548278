import text from './resources/locale/en.json';

class BeaconsController {
    constructor($state, crConstants) {
        this.$state = $state;
        this.crConstants = crConstants;
    }

    $onInit() {
        this.text = text;
    }

    goToCreatePage() {
        this.$state.go('client.beacons.create', {
            customerId: this.$state.params.customerId,
        });
    }
}

export default BeaconsController;
