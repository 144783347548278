import controller from './menus-list.controller';
import template from './menus-list.html';
import './menus-list.less';

const component = {
    template,
    bindings: {},
    controller: [
        '$state',
        'crErrorLoggingService',
        'crAnalyticsService',
        'crToastService',
        'crLocaleService',
        'crConfig',
        'crMenusListService',
        'crEntitySelectionManager',
        controller,
    ],
};

export default component;
