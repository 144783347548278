import controller from './pdf-preview-modal.controller';
import template from './pdf-preview-modal.html';
import './pdf-preview-modal.less';

const component = {
    template,
    bindings: {
        close: '&',
        dismiss: '&',
        resolve: '<',
    },
    controller: ['$state', 'crConstants', 'crPdfPreviewModalText', controller],
};

export default component;
