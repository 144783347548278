import { Component, Inject, Input } from '@angular/core';
import { Constants, LocalizedText } from "../../../../core";
import { FormControl } from "@angular/forms";
import { CR_CONSTANTS, ToastService } from "../../../../shared";
import _ from "lodash";
import { getAutoStatusInfo, getCurrentDayName, getIconUrl } from "../../common/common.util";
import text from '../../resources/locale/en.json';
import { ListType, statusList, typeList } from "../../constants/options";
import { StateService } from "@uirouter/core";
import { Extension, OperationStatus, PoiModel, StatusOption } from "../../common/types";
import { InternalLegacyStatuses, SNOAA_Type } from "../../constants/statuses";

export type RowDataModel = {
    id: string,
    name: string,
    type: string,
    iconSrc: string,
    iconType: string,
    operationStatus: OperationStatus,
    hours: Record<string, any>,
    grooming: boolean,
    snowMaking: boolean,
    waitTime: string,
    autoStatus: boolean,
    isDisabled: boolean,
    statusOptions?: StatusOption[],
    _poi: PoiModel,
    _extension: Record<string, any>,
    _legacyStatus: Record<string, any>
};

export type RowData = { data: RowDataModel };

@Component({
    selector: 'cr-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss']
})
export class ListComponent {

    @Input()
    extensionId: string;

    @Input()
    timezone: string;

    @Input()
    savePoiFunction: (poi: any) => Promise<any>;

    text: LocalizedText = text;
    searchFormControl: FormControl = new FormControl('');
    searchText = '';
    unfilteredData: RowData[] = [];
    rowData: RowData[] = [];
    isRowDataSet = false;
    isLift = false;
    isTrail = false;

    constructor(
        @Inject(CR_CONSTANTS) public constants: Constants,
        public state: StateService,
        private toastService: ToastService
    ) {
    }

    get listType(): ListType {
        if (this.extensionId === InternalLegacyStatuses.INTERNAL_LIFT) {
            return 'lift';
        }
        if (this.extensionId === InternalLegacyStatuses.INTERNAL_TRAIL) {
            return 'trail';
        }
        return 'activity';
    }

    onSearchTextChange(searchText: string): void {
        this.searchText = searchText;
        this.applyFilter();
    }

    applyFilter(): void {
        if (this.searchText) {
            this.rowData = this.unfilteredData
                ?.filter(row => row.data?.name?.toLowerCase()?.includes(this.searchText.toLowerCase())) ?? [];
        } else {
            this.rowData = this.unfilteredData;
        }
    }

    @Input()
    set poiList(list: PoiModel[]) {
        this.isRowDataSet = !!list;
        list = list ?? [];
        this.unfilteredData = this.mapData(list);
        this.applyFilter();
    }

    mapData(pois: PoiModel[]) {
        const rows: RowData[] = [];

        pois.forEach(poi => {
            const legacyStatus = poi.extensions.find(e => e.id === InternalLegacyStatuses.INTERNAL_LEGACY_CURRENT_STATUS);
            if (!legacyStatus)
                return;

            poi.extensions.forEach(ex => {
                if (!ex.id.startsWith(this.extensionId))
                    return

                if ((/^internalLift/).exec(ex.id))
                    this.isLift = true;
                if ((/^internalTrail/).exec(ex.id))
                    this.isTrail = true;

                rows.push({data: this.createRowDataModel(poi, ex, legacyStatus)});
            });
        });

        return rows;
    }

    createRowDataModel(poi: PoiModel, ex: Extension, legacyStatus: Extension) {
        let iconUrl = '';

        if (ex.value.type) {
            iconUrl = getIconUrl(ex.value.SNOAAType, ex.value.type, this.text.imageUrl);
        }
        if (ex.value.trailIcon) {
            iconUrl = getIconUrl(ex.value.SNOAAType, ex.value.trailIcon, this.text.imageUrl);
        }

        const currentDay = getCurrentDayName(this.timezone).toLowerCase();
        const operatingHours = ex.value?.hours?.[currentDay];

        const data: RowDataModel = {
            id: poi.id,
            name: ex.value.name,
            type: ex.value.SNOAAType,
            iconSrc: iconUrl,
            iconType: ex.value.type ? ex.value.type : ex.value.trailIcon,
            operationStatus: {
                label: ex.value.statusLabel ?? '',
                status: legacyStatus.value?.operationalStatus?.toLowerCase() ?? '',
                color: ex.value.statusColor
            },
            hours: operatingHours ?? {},
            waitTime: legacyStatus.value.waitTime,
            grooming: ex.value.grooming ?? false,
            snowMaking: ex.value.snowMaking ?? false,
            autoStatus: ex.value.autoStatus,
            isDisabled: poi.isDisabled,
            _poi: poi,
            _extension: ex,
            _legacyStatus: legacyStatus
        };

        if (ex.value.autoStatus) {
            data.operationStatus = getAutoStatusInfo(ex.value, this.text, this.timezone);
        }

        data.statusOptions = statusList
            .filter(s => (s.visibleIn.includes(this.listType)))
            .map(s => ({...s, isSelected: s.id === data.operationStatus.label}));

        return data;
    }

    async saveRow(row) {
        const data = row.data;
        const poi = data._poi;
        const ext = data._extension;
        const ils = data._legacyStatus;

        ils.value.waitTime = data.waitTime;
        ext.value.autoStatus = data.autoStatus;
        ext.value.grooming = data.grooming
        ext.value.snowMaking = data.snowMaking

        if (!data.autoStatus) {
            const newStatus = statusList.find(s => s.id === data.operationStatus.label);
            ext.value.statusLabel = newStatus?.id;
            ils.value.operationalStatus = newStatus?.type;
            ext.value.statusColor = newStatus?.color;
        }

        const poiToSave = {
            ...poi,
            extensions: poi.extensions.map(ex => ({
                ...ex,
                value: JSON.stringify(ex.value)
            }))
        };

        await this.savePoiFunction(poiToSave);
        _.extend(row.data, this.createRowDataModel(poi, ext, ils));
        this.toastService.toast(`Data successfully updated.`, '');
    }

    onChangeOperationStatus(labelId, row) {
        row.data.autoStatus = false;
        row.data.operationStatus.label = labelId;
        this.saveRow(row);
    }

    onToggleGroomingStatus(row) {
        row.data.grooming = !row.data.grooming;
        this.saveRow(row)
    }

    onToggleSnowMakingStatus(row) {
        row.data.snowMaking = !row.data.snowMaking;
        this.saveRow(row)
    }

    onToggleAutoStatus(row) {
        row.data.autoStatus = !row.data.autoStatus;
        if (row.data.autoStatus) {
            row.data.operationStatus.label = '';
        } else {
            row.data.operationStatus.label = row.data._extension.value.statusLabel;
        }
        this.saveRow(row);
    }

    protected readonly typeList = typeList;
}
