import angular from 'angular';
import ToastComponent from './toast.component';
import ToastService from './toast.service';

const ToastModule = angular
    .module('control-room.commons.basic.components.toast', [])
    .component('crToast', ToastComponent)
    .service('crToastService', ['$rootScope', 'crConstants', ToastService]);

export default ToastModule;
