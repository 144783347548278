import _ from 'lodash';
import text from './resources/locale/en.json';

class CtaButtonsConfigure {
    $onInit() {
        this.text = text;
        this.refreshMenuDropdown();
        this.setDefaultState();
    }

    $onChanges(changes) {
        if (changes.entity) {
            this.callsToAction = _.cloneDeep(this.entity.localization[this.locale.current].callsToAction);
        }

        if (changes.locale) {
            this.isDefaultLocale = this.locale.current === this.locale.default;
            this.callsToAction = _.cloneDeep(this.entity.localization[this.locale.current].callsToAction);

            if (this.entity.callsToAction) {
                if (this.callsToAction && this.callsToAction.length === 0) {
                    this.callsToAction = [];
                }
            }
            this.refreshMenuDropdown();
            this.setDefaultState();
        }
    }

    setDefaultState() {
        this.defaultState = {
            label: '',
            link: 'https://',
            type: 'WEB',
            isDefaultLocale: this.locale.current === this.locale.default,
            types: [{ name: 'Web', id: 'WEB', isSelected: true },
                { name: 'Menu', id: 'MENU', isSelected: false },],
            menus: this.menus.map(menuItem => Object.assign({}, menuItem))
        };
    }


    refreshMenuDropdown(){
        if(this.callsToAction) {
            this.callsToAction.forEach((cta, index) => {
                cta.menus =  this.menus.map(menuItem => Object.assign({}, menuItem));
                cta.isDefaultLocale = this.locale.current === this.locale.default;
                if(this.entity && this.entity.callsToAction[index]) {
                    const { displayOrder, type, options } = this.entity.callsToAction[index];
                    cta.displayOrder = displayOrder;
                    cta.type = type;
                    if(options && options.menuId) {
                        cta.menus = cta.menus.map(menu => {
                            if(menu.id === options.menuId) {
                                menu.isSelected = true;
                            }
                            return menu;
                        });
                    }
                }
            });
        }
    }

    updateCta(ctas) {
        this.update({
            $event: { model: ctas },
        });
    }

    onCtaDragEnd($event) {
        this.updateCta($event.rows);
    }
}

export default CtaButtonsConfigure;
