import angular from 'angular';

import WhatModule from './what/what';
import WhoModule from './who/who';
import WhenModule from './when/when';
import ExperienceSummaryModule from './experience-summary/experience-summary';
import ExperienceTestMessage from './experience-test-message/experience-test-message';
import AdditionalField from './additional-field/additional-field';
import ReachModule from './reach/reach';

const Components = angular.module('control-room.experience-promoter.components.experience-manage.components', [
    WhatModule.name,
    WhoModule.name,
    ExperienceSummaryModule.name,
    ExperienceTestMessage.name,
    WhenModule.name,
    AdditionalField.name,
    ReachModule.name,
]);

export default Components;
