<cr-title-bar icon="icon-cr-back" label="Advanced Search" (onClick)="onBack()">
</cr-title-bar>

<div class="order-history-content">
  <div class="header">
    <div class="oh-header">{{ text.advancedSearchHeader }}</div>
    <div class="oh-subheader">{{ text.advancedSearchSubHeader }}</div>

    <form
      [formGroup]="orderHistoryForm"
      class="oh-form"
      (ngSubmit)="onAdvancedSearch(1, true)"
    >
      <div class="oh-form-grouping">
        <cr-input
          type="number"
          name="orderNumberControl"
          label="Order Number"
          [formControl]="orderNumberControl"
        ></cr-input>
        <cr-input
          type="number"
          name="orderGuestLast4Control"
          label="Last 4 digits of guest's credit card"
          minlength="4"
          maxlength="4"
          [formControl]="orderGuestLast4Control"
        ></cr-input>
      </div>
      <div class="oh-form-grouping">
        <cr-input
          type="input"
          name="orderGuestNameControl"
          label="Guest Name"
          [formControl]="orderGuestNameControl"
        ></cr-input>
        <cr-input
          type="email"
          name="orderGuestEmailControl"
          label="Guest Email"
          [formControl]="orderGuestEmailControl"
        ></cr-input>
      </div>
      <button
        class="btn btn-primary btn-form-submit"
        type="submit"
        [disabled]="!orderHistoryForm.valid"
      >
        <span *ngIf="!isLoading">{{ text.advancedSearchButton }}</span>
        <cr-spinner *ngIf="isLoading"></cr-spinner>
      </button>
    </form>
    <hr />
    <div class="order-results-header" *ngIf="!newSearch">
      <div class="order-history-results">{{ orderHistoryResult }}</div>
      <div class="order-history-results">
        <div>{{ totalShown }}</div>
        <div>
          <cr-export-button
            cr-feature-flags="export_order"
            type="order-history"
            baseFilename="{{ exportFilename }}"
            [orderHistoryExportParams]="orderHistoryExportParams"
          ></cr-export-button>
        </div>
      </div>
    </div>
  </div>

  <section class="orders" *ngIf="orderHistory.orders.length > 0">
    <div *ngFor="let order of orderHistory.orders">
      <cr-order-list-item
        [order]="order"
        [text]="text"
        [isSearching]="false"
        [writeAllowed]="false"
        [isPrintingEnabled]="isPrintingEnabled"
        [kitchenId]="state.params.kitchenId"
      ></cr-order-list-item>
    </div>
  </section>
  <div *ngIf="resultsError" class="load-error">
    {{ text.resultsError }}
  </div>
  <div
    (click)="onAdvancedSearch(currentPage + 1, false)"
    *ngIf="isMoreResults"
    class="show-more"
  >
    <span *ngIf="!isLoading">{{ text.showMore }}</span>
    <cr-spinner *ngIf="isLoading"></cr-spinner>
  </div>
</div>
