import _ from 'lodash';

class MenusListService {
    constructor(crEntityService, crConstants) {
        this.crEntityService = crEntityService;
        this.crConstants = crConstants;
    }

    getData(params) {
        const queryParams = _.pickBy(params, (value) => !_.isNil(value) && value !== '' && value !== 'all');

        return this.crEntityService.getEntityList(this.crConstants.entity.types.MENUS, queryParams);
    }

    mapToRowsModel(menus) {
        return menus.map((menu) => {
            const row = {
                data: {
                    id: menu.id,
                    name: menu.content ? menu.content.name : `Menu ID ${menu.id}`,
                    state: menu.state.toLowerCase(),
                    placeId: menu.placeInfo.id,
                    originalData: menu,
                },
            };

            return row;
        });
    }
}

export default MenusListService;
