import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Automation, Broadcast, Experiences } from './experiences.model';

@Injectable({
  providedIn: 'root',
})
export class ExperiencesService {
  url: string;
  typesUrl: string;
  typesCache: any;

  constructor(private http: HttpClient) {
    this.url = '/rest/experiences';
        this.typesUrl = '/rest/experience-types';
        this.typesCache = {};
  }

    getList(params) {
        return this.http.get(this.url, { params }).toPromise().then((res: Experiences) => res);
    }

    getExperience(id: string):Promise<Experiences> {
        return this.http
            .get<Experiences>(`${this.url}/${id}`)
            .toPromise()
            .catch((err) => {
                if (err.status === 404) {
                    return this.http.get(`${this.url}/${id}?version=latest`).toPromise()
                        .then((res: Experiences) => res)
                        .catch((err) => { throw err });
                }

                throw err;
            })
            .then((res: Experiences) => 
            {
                return res;
            });
    }

    createExperience(experience: Broadcast | Automation): Promise<Broadcast | Automation> {
        return this.http.post<Broadcast | Automation>(this.url, experience).toPromise();
    }

    updateExperience(experience: Broadcast | Automation): Promise<Broadcast | Automation>  {
        return this.http.put< Broadcast | Automation>(`${this.url}/${experience.id}`, experience).toPromise();
    }

    archiveExperience(id: string): Promise<any> {
        return this.http.delete<any>(`${this.url}/${id}`).toPromise();
    }

    getExperienceTypes(groupName: string): Promise<Experiences> {
        return this.http.get(this.typesUrl, { params: groupName ? { group: groupName } : {} }).toPromise().then((res: Experiences) => {
            const types = res;

            if (types.automations) {
                types.automations.forEach((type) => {
                    this.typesCache[type.id] = type;
                });
            }

            if (types.broadcasts) {
                types.broadcasts.forEach((type) => {
                    this.typesCache[type.id] = type;
                });
            }

            return types;
        });
    }

    getAutomationTypes(): Promise<Experiences> {
        return this.getExperienceTypes('automations');
    }

    getBroadcastTypes(): Promise<Experiences> {
        return this.getExperienceTypes('broadcasts');
    }

    getExperienceType(type: string, group) {
        if (this.typesCache[type]) {
            return Promise.resolve(this.typesCache[type]);
        }

        return this.http.get(`${this.typesUrl}/${type}`, { params: group ?  { group } : {} }).toPromise().then((res: Broadcast | Automation) => {
            
            this.typesCache[res.id] = res;

            return res;
        });
    }
}

export default ExperiencesService;
