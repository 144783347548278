import text from './resources/locale/en.json';

class CvtIssuesHeader {
    $onInit() {
        this.text = text;
        this.hasErrors = false;
        this.errorCountText = '';
        this.warningCountText = '';

        this.setCount(this.module);
    }

    getErrorAndWarningCount(issueTypes) {
        let error = 0;
        let warning = 0;

        issueTypes.forEach((section) => {
            section.issues.forEach((issue) => {
                if (issue.status === 'ERROR') {
                    error += 1;
                }

                if (issue.status === 'WARNING') {
                    warning += 1;
                }
            });
        });

        return { error, warning };
    }

    setCount(m) {
        let counts = { error: 0, warning: 0 };
        if (m.issueTypes && !m.entities) {
            counts = this.getErrorAndWarningCount(m.issueTypes);
        } else {
            let entityCounts = {};
            m.entities.forEach((entity) => {
                entityCounts = this.getErrorAndWarningCount(entity.issueTypes);
                counts.error += entityCounts.error;
                counts.warning += entityCounts.warning;
            });
        }

        this.hasErrors = counts.error >= 1;
        this.hasWarnings = counts.warning >= 1;

        if (counts.error) {
            this.errorCountText = `${counts.error} ${counts.error > 1 ? this.text.errors : this.text.error}`;
        }

        if (counts.warning) {
            this.warningCountText = `${counts.warning} ${counts.warning > 1 ? this.text.warnings : this.text.warning}`;
        }
    }
}

export default CvtIssuesHeader;
