import _ from 'lodash';

class DurationEditorController {
    constructor($timeout) {
        this.$timeout = $timeout;
    }

    $onInit() {
        this.handleUpdatedDuration();
        this.hoursName = `${this.name}Hrs`;
        this.minutesName = `${this.name}Mins`;
    }

    $onChanges(changes) {
        if (changes && changes.duration) {
            if (!_.isNil(changes.duration.currentValue)) {
                if (!changes.duration.isFirstChange()) {
                    this.handleUpdatedDuration();

                    // Set all inputs to touched so both fields show validation error border
                    this.setAllTouched();
                }
            }
        }
    }

    handleUpdatedDuration() {
        if (!_.isNil(this.duration)) {
            // Split duration in seconds into hours and minutes
            this.durationHrs = Math.floor(this.duration / 3600) * 3600;
            this.durationMins = this.duration % 3600;
        }
    }

    onHrDurationUpdate(event) {
        event.model = event.model || 0;
        const seconds = (this.durationMins || 0) + event.model;

        if (this.durationHrs !== event.model) {
            this.setValidity(seconds > 0);
            this.update(seconds);
        } else {
            this.revertValue();
        }
    }

    onMinDurationUpdate(event) {
        event.model = event.model || 0;
        const seconds = (this.durationHrs || 0) + event.model;

        if (this.durationMins !== event.model) {
            this.setValidity(seconds > 0);
            this.update(seconds);
        } else {
            this.revertValue();
        }
    }

    // TODO: Find a better way to handle reverting a value. Using $timeout to force $onChanges to fire.
    revertValue() {
        this.durationMins = '--';
        this.durationHrs = '--';
        this.$timeout(() => {
            this.handleUpdatedDuration();
        });
    }

    update(duration) {
        this.onUpdate({
            $event: {
                model: duration,
            },
        });
    }

    setAllTouched() {
        const hiddenFormElem = this.form[this.name];
        const hrsFormElem = this.form[this.hoursName];
        const minsFormElem = this.form[this.minutesName];

        this.setTouched(hiddenFormElem);
        this.setTouched(hrsFormElem);
        this.setTouched(minsFormElem);
    }

    setTouched(formElem) {
        if (!formElem.$touched) {
            formElem.$setTouched();
        }
    }

    setValidity(valid) {
        const hiddenFormElem = this.form[this.name];
        const hrsFormElem = this.form[this.hoursName];
        const minsFormElem = this.form[this.minutesName];

        // Timeout helps with a timing edge case where the unedited field reverts back to valid
        this.$timeout(() => {
            hiddenFormElem.$setValidity('duration', valid);
            hrsFormElem.$setValidity('duration', valid);
            minsFormElem.$setValidity('duration', valid);
        });
    }
}

export default DurationEditorController;
