import text from './resources/locale/en.json';

import ListPage from '../../../../commons/controller/list-page';

class ProductsList extends ListPage {
    constructor(
        $state,
        crErrorLoggingService,
        crAnalyticsService,
        crToastService,
        crLocaleService,
        crConfig,
        crProductsListService,
        $q,
        crEntitySelectionManager
    ) {
        super($state, crErrorLoggingService, crAnalyticsService, crToastService, crLocaleService, crConfig);
        this.crProductsListService = crProductsListService;
        this.$q = $q;
        this.crEntitySelectionManager = crEntitySelectionManager;
    }

    $onInit() {
        super.$onInit();

        this.text = text;

        this.registerParam('state', '');
        this.registerParam('category', '');

        const { columns } = this.text;

        this.listData = {
            columns: [
                { key: 'image', label: '' },
                { key: 'name', label: columns.name, class: 'large-column name-column', sortable: true },
                { key: 'title', label: columns.title, class: 'large-column' },
                { key: 'categories', label: columns.categories },
                { key: 'state', label: columns.state, class: 'medium-column' },
                { key: 'editButton', label: '', class: 'small-column' },
            ],
            rows: [],
        };

        if (this.hasMultipleLanguages()) {
            this.addMissingTranslationsColumnHeader();
        }

        this.stateFilterData = [
            { id: 'ACTIVE', label: this.text.active },
            { id: 'INACTIVE', label: this.text.inActive },
            { id: 'DRAFT', label: this.text.draft },
        ];
        this.stateFilterData[0].isSelected = true;

        this.getCategoriesData();
    }

    getData() {
        this.isLoading = true;
        this.dataError = false;

        this.params.placeId = this.$state.params.placeId;

        return this.crProductsListService.getData(this.params, this.$state.params.menuId).then((data) => {
            data.search = data.query.search || '';

            if (data.search === this.params.search) {
                const categoriesPromise = !this.categoriesFilterData ? this.getCategoriesData() : this.$q.resolve();

                return categoriesPromise.then(() => {
                    this.isLoading = false;
                    return this.crProductsListService.mapToRowsModel(data.content, this.categoriesFilterData);
                });
            }
        });
    }

    getCategoriesData(params = this.$state.params) {
        return this.crProductsListService
            .getCategories(params.menuId, params.venueId, params.placeId)
            .then((eventCategories) => {
                this.categoriesFilterData = eventCategories;
            });
    }
}

export default ProductsList;
