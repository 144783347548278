<div class="cr-modal-contents cr-image-modal">
  <div class="cr-title">
    {{ headerText }}
  </div>
  <section
    class="main-container"
    [ngClass]="{ sticker: imageType === 'STICKER' }"
  >
    <div
      class="image-control image-rotate"
      *ngIf="isFile"
      (click)="rotateRight()"
    >
      <span class="icon-cr-refresh"></span>
    </div>
    <div
      class="image-control image-cancel"
      *ngIf="isFile"
      (click)="resetImage()"
    >
      <span class="icon-cr-close"></span>
    </div>
    <div
      class="drop-zone cr-flex-center circle-drop-zone"
      *ngIf="!isFile"
      [ngClass]="{ 'circle-drop-zone': showAsCircle }"
    >
      <div class="instructions cr-title cr-flex-center" ng-show="!isFile">
        <span class="drag-cta">{{ text.dropImageText }}</span>
        <span class="drag-cta">{{ text.or }}</span>
        <span class="browse-cta" data-global-qa-id="handle-image-file"
          ><span
            class="icon-cr-folder-upload upload-icon"
            (click)="zoomOut()"
          ></span
          >{{ text.uploadAPhoto }}</span
        >
        <input
          type="file"
          class="file-input"
          (change)="fileChangeEvent($event)"
        />
      </div>
    </div>
    <div class="cr-detail" *ngIf="!isFile">
      {{ text.recommendedSize }} {{ minSize.width }}x{{ minSize.height }}
    </div>

    <div
      class="image-cropper-container"
      [ngStyle]="
        imageType === 'STICKER' ? { height: '398px' } : { height: '513px' }
      "
      *ngIf="isFile"
    >
      <image-cropper
        [aspectRatio]="minSize.aspectRatio"
        [imageChangedEvent]="imageChangedEvent"
        [canvasRotation]="canvasRotation"
        [resizeToWidth]="minSize.width"
        [resizeToHeight]="minSize.height"
        [transform]="transform"
        [alignImage]="'center'"
        [style.display]="isFile ? null : 'none'"
        format="png"
        (imageLoaded)="imageLoaded()"
        (cropperReady)="cropperReady($event)"
        (loadImageFailed)="loadImageFailed()"
        [autoCrop]="false"
      ></image-cropper>
    </div>
  </section>
  <div class="cr-modal-footer">
    <div class="slider" *ngIf="isFile">
      <span class="icon-cr-zoom-out" (click)="zoomIn()"></span>
      <input
        type="range"
        min="0.5"
        max="2"
        value="1"
        step="0.1"
        (change)="sliderChange($event.target.value)"
      />
      <span class="icon-cr-zoom-in" (click)="zoomOut()"></span>
    </div>

    <div class="save-section" *ngIf="!loading">
      <button
        class="btn btn-nobg"
        type="button"
        data-local-qa-id="cancel"
        (click)="cancel()"
      >
        {{ text.cancel }}
      </button>
      <button
        class="btn btn-primary"
        type="button"
        data-local-qa-id="submit"
        (click)="submit()"
        [disabled]="!isFile"
      >
        {{ text.submit }}
      </button>
    </div>
    <div class="save-section" *ngIf="loading">
      <cr-spinner></cr-spinner>
    </div>
  </div>
  <section class="cr-flex-center error-message" *ngIf="error">
    <p>
      {{ text.error }}
    </p>
  </section>
</div>
