import angular from 'angular';

import PosEnableComponent from './pos-enable.component';
import PosEnableService from './pos-enable.service';

import locale from './resources/locale/en.json';

const PosEnableModule = angular
    .module('control-room.places.components.place-details.components.pos-enable', [])
    .constant('crPosEnableLocale', locale)
    .service('crPosEnableService', ['$http', PosEnableService])
    .component('crPosEnable', PosEnableComponent);

export default PosEnableModule;
