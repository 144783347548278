import angular from 'angular';

import Component from './events-list.component';
import Service from './events-list.service';

const EventsList = angular
    .module('control-room.events.components.events-list', [])
    .component('crEventsList', Component)
    .service('crEventsListService', ['crEntityService', 'crImageService', 'crConstants', Service]);

export default EventsList;
