import Controller from './coupon-details.controller';
import template from './coupon-details.html';
import './coupon-details.less';

const Component = {
    template,
    controller: [
        '$state',
        '$uibModal',
        'crConfig',
        'crConstants',
        'crCouponsService',
        'crLocaleService',
        'crAnalyticsService',
        'crNavigationService',
        'crToastService',
        'crErrorLoggingService',
        Controller,
    ],
};

export default Component;
