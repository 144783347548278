<section>
    <div
        [ngClass]="{
            'order-details': true,
            'order-refunded': order.isFullRefund
        }"
    >
        <div
            *ngIf="order.orderReceipt"
            class="receipt-header-row adjustable-padding"
        >
            <div class="item-col1 item-quantity">
                <span class="large-screen">{{ text.quantity }}</span
                ><span class="small-screen">{{ text.qty }}</span>
            </div>
            <div class="item-name">{{ text.itemsInOrder }}</div>
            <div class="item-price">{{ text.lineItemPrice }}</div>
        </div>
        <div *ngIf="order.orderReceipt" class="line-item-container">
            <div
                *ngFor="let lineItem of order.orderReceipt.lineItems"
                class="line-item adjustable-margin"
            >
                <section *ngIf="lineItem.lineItemType === 'LINE_ITEM_PRODUCT'">
                    <div *ngFor="let subItem of lineItem.subItems">
                        <div class="receipt-row">
                            <div class="item-col1 item-quantity">
                                {{ subItem.quantity }}
                            </div>
                            <div class="item-name">
                                {{ subItem.displayName }}
                            </div>
                            <div class="item-price">
                                {{ subItem.displayPrice }}
                            </div>
                        </div>
                        <div
                            *ngIf="
                                subItem.modifiers.length === 0 &&
                                order.isFullRefund
                            "
                            class="receipt-row item-modifier"
                        >
                            <div class="item-col1"></div>
                            <div class="item-name item-tabbed"></div>
                            <div class="item-refunded">{{ text.refunded }}</div>
                        </div>
                        <div
                            *ngFor="
                                let modifier of subItem.modifiers;
                                let first = first
                            "
                            class="receipt-row item-modifier"
                        >
                            <div class="item-col1"></div>
                            <div class="item-name item-tabbed">
                                {{ modifier.displayName }}
                            </div>
                            <div class="item-refunded">
                                <span *ngIf="first && order.isFullRefund">{{
                                    text.refunded
                                }}</span>
                            </div>
                        </div>
                    </div>
                </section>
                <section
                    *ngIf="lineItem.lineItemType === 'LINE_ITEM_BUNDLE'"
                    class="order-bundle"
                >
                    <div class="receipt-row">
                        <div class="item-col1 item-quantity">
                            {{ lineItem.quantity }}
                        </div>
                        <div class="item-name">{{ lineItem.displayName }}</div>
                        <div class="item-price">
                            {{ lineItem.displayPrice }}
                        </div>
                    </div>
                    <div *ngFor="let subItem of lineItem.subItems">
                        <div class="receipt-row">
                            <div class="item-col1 item-quantity">
                                {{ subItem.quantity }}
                            </div>
                            <div class="item-name item-tabbed">
                                {{ subItem.displayName }}
                            </div>
                            <div class="item-price">
                                {{ subItem.displayPrice }}
                            </div>
                        </div>
                        <div
                            *ngFor="let modifier of subItem.modifiers"
                            class="receipt-row item-modifier"
                        >
                            <div class="item-col1"></div>
                            <div class="item-name item-tabbed">
                                {{ modifier.displayName }}
                            </div>
                            <div class="item-price">
                                {{ modifier.displayPrice }}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        <div
            class="special-instructions adjustable-margin"
            *ngIf="order.specialInstructions"
        >
            <div class="receipt-row">
                <div class="item-col1"></div>
                <div class="item-name">{{ text.specialRequests }}</div>
                <div class="item-price"></div>
            </div>
            <div class="receipt-row">
                <div class="item-col1"></div>
                <div class="item-name item-tabbed">
                    {{ order.specialInstructions }}
                </div>
                <div class="item-price"></div>
            </div>
        </div>
        <div class="order-total" *ngIf="order.orderStatus!=='SUBMITTED'">
            <div class="receipt-header-row adjustable-padding">
                {{ text.orderTotalHeader }}
            </div>
            <div class="receipt-row adjustable-margin">
                <div class="item-col1">{{ text.subtotal }}</div>
                <div class="item-name"></div>
                <div class="item-price">{{ order.displayOrderSubtotal }}</div>
            </div>
            <div class="receipt-row adjustable-margin"
                *ngIf="order.orderDiscountTotal > 0"
            >
                <div class="item-col1 item-discount">{{ text.discount }}</div>
                <div class="item-name item-discount"></div>
                <div class="item-price item-discount">- {{ order.displayOrderDiscountTotal }}</div>
            </div>
            <div class="receipt-row adjustable-margin" *ngFor = "let fee of feesCategory.taxable">
                <div class="item-col1">{{ fee.displayName }}</div>
                <div class="item-name"></div>
                <div class="item-price">{{ fee.displaySubtotal }}</div>
            </div>
            <div class="receipt-row adjustable-margin">
                <div class="item-col1">{{ text.salesTax }}</div>
                <div class="item-name"></div>
                <div class="item-price">{{ order.displayOrderTax }}</div>
            </div>
            <div class="receipt-row adjustable-margin" *ngFor = "let fee of feesCategory.nonTaxable">
                <div class="item-col1">{{ fee.displayName }}</div>
                <div class="item-name"></div>
                <div class="item-price">{{ fee.displaySubtotal }}</div>
            </div>
            <div
                class="receipt-row adjustable-margin"
                *ngIf="order.orderGratuity"
            >
                <div class="item-col1">{{ text.gratuity }}</div>
                <div class="item-name"></div>
                <div class="item-price">{{ order.displayOrderGratuity }}</div>
            </div>
            <div class="receipt-row adjustable-margin receipt-bold">
                <div class="item-col1">{{ text.ordertotal }}</div>
                <div class="item-name"></div>
                <div class="item-price">{{ order.displayOrderTotal }}</div>
            </div>
            <div class="receipt-row adjustable-margin" *ngIf="order.isRefund">
                <div class="item-col1 value-refund">{{ text.refund }}</div>
                <div class="item-name"></div>
                <div class="item-price value-refund">
                    - {{ order.displayOrderRefundAmount }}
                </div>
            </div>
            <div
                class="receipt-row adjustable-margin receipt-payment"
                *ngIf="
                    (getCardBrand(order) && order.cardLast4) ||
                    (order.orderReceipt && order.orderReceipt.pickupZoneLabel)
                "
            >
                <div
                    class="item-col1"
                    *ngIf="getCardBrand(order) && order.cardLast4"
                >
                    <span class="icon icon-cr-creditcard"></span>
                    <span
                        >{{ text.chargedTo }}
                        {{ getCardBrand(order).toUpperCase() }} ****{{
                            order.cardLast4
                        }}</span
                    >
                </div>
                <div *ngIf="order.orderReceipt.pickupZoneLabel && order.pickupZoneId !== 'INSIDE_TAKE_OUT'">
                    <span class="icon icon-cr-marker"></span>
                    <span>{{ order.orderReceipt.pickupZoneLabel }}</span>
                </div>
            </div>
        </div>
        <div
            class="adjustable-margin"
            *ngIf="order.orderStatus!=='SUBMITTED' && isPrintingEnabled || order.isRefund">
            <div
                class="order-print"
                crPolicy
                policyAction="write:fnb-order"
                *ngIf="isPrintingEnabled">
                <div class="receipt-print">
                    <div class="print-spinner" *ngIf="printChitStatus === 'SENT'">
                        <cr-spinner></cr-spinner>
                    </div>
                    <div
                        class="print-button"
                        *ngIf="printChitStatus !== 'SENT'"
                        (click)="printOrder(order)"
                    >
                        <span class="icon icon-cr-print"></span>
                        <span>{{ text.printChit }}</span>
                    </div>
                    <div class="print-status" *ngIf="printChitStatus === 'SUCCESS'">
                        <span class="icon icon-cr-confirm"></span>
                        <span>{{ text.printSuccess }}</span>
                    </div>
                    <div
                        class="print-status print-fail"
                        *ngIf="printChitStatus === 'FAIL'"
                    >
                        <span>{{ text.printFail }}</span>
                    </div>
                </div>
            </div>
            <div
                class="order-print"
                crPolicy
                policyAction="write:fnb-order"
                *ngIf="isPrintingEnabled && order.isRefund">
                <div class="receipt-print">
                    <div class="print-spinner" *ngIf="printRefundStatus === 'SENT'">
                        <cr-spinner></cr-spinner>
                    </div>
                    <div
                        class="print-button"
                        *ngIf="printRefundStatus !== 'SENT'"
                        (click)="printRefund(order)">
                        <span class="icon icon-cr-print"></span>
                        <span>{{ text.printRefundReceipt }}</span>
                    </div>
                    <div class="print-status" *ngIf="printRefundStatus === 'SUCCESS'">
                        <span class="icon icon-cr-confirm"></span>
                        <span>{{ text.printSuccess }}</span>
                    </div>
                    <div
                        class="print-status print-fail"
                        *ngIf="printRefundStatus === 'FAIL'">
                        <span>{{ text.printFail }}</span>
                    </div>
                </div>
            </div>
            <div
                class="receipt-row receipt-payment receipt-bold"
                *ngIf="order.isRefund">
                <a class="item-col1 row-link" (click)="openModal()">
                    <span class="icon icon-cr-alternate-email"></span>
                    <span>{{ text.sendRefundEmail }}</span>
                </a>
            </div>
        </div>
    </div>
</section>
