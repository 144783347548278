<div class="reorder-list" cdkDropList (cdkDropListDropped)="drop($event)">
    <div
        class="reorder-item"
        *ngFor="let item of items; let i = index"
        cdkDrag
        cdkDragLockAxis="y"
        cdkDragBoundary=".reorder-list"
        [cdkDragData]="item"
    >
        <div class="reorder-handle-container">
            <cr-icon name="sort" cdkDragHandle></cr-icon>
        </div>
        <div class="reorder-item-content">
            <ng-container
                *ngIf="itemTemplate"
                [ngTemplateOutlet]="itemTemplate"
                [ngTemplateOutletContext]="{ item: item, index: i, itemsLength: items.length }"
            ></ng-container>
        </div>
    </div>
</div>
