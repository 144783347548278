class HoursBuilderController {
    constructor(text) {
        this.text = text;
    }

    onRowBuilderChange(state) {
        this.onChange({
            state,
        });
    }
}

export default HoursBuilderController;
