import _ from 'lodash';
import text from './resources/locale/en.json';

class Who {
    constructor($sce, $state, $timeout, $window, crConstants, crVenueService, crConfig, crPolicyService) {
        this.$sce = $sce;
        this.$state = $state;
        this.$timeout = $timeout;
        this.crConstants = crConstants;
        this.text = text;
        this.fieldOptions = {};
        this.isSegmentationCollapsed = false;
        this.crVenueService = crVenueService;
        this.crConfig = crConfig;
        this.crPolicyService = crPolicyService;
        this.$window = $window;
        this.trustedHtmlCache = {};
        this.locationEnums = this.crConstants.userLocationEnums;
    }

    $onInit() {
        this.experienceType = _.cloneDeep(this.experienceType);

        this.errorMessages = {
            atLeastOne: this.text.atLeastOne,
            required: '',
        };

        this.isCorporateMarketor = false;

        this.hasUserBehavior =
            this.experienceType.userBehavior &&
            this.experienceType.userBehavior.length &&
            this.experienceType.userBehavior.length > 0;

        if (this.collapseUserTagSection) {
            // If tags are already selected, don't collapse it
            if (this.experience.audience.tags.filter((tag) => tag.key !== null).length === 0) {
                this.isSegmentationCollapsed = true;
            }
        }
        if (this.isAppSelectionBroadcastSupported) {
            this.generateAppList();
            this.mapExistingApps();
        }
        
        this.hasCorporateMarketerRole();
    }

    $onChanges(changes) {
        if (changes.tagDefinitions && changes.tagDefinitions.currentValue) {
            this.updateTagLists();
        }
    }

    $postLink() {
        this.$timeout(() => {
            this.checkBehaviorValidity();
        }, 0);
    }

    get showInclude() {
        return (
            this.experienceType.userTags.include.enabled || !!this.experienceType.userTags.include.overrideDescription
        );
    }

    get showAltInclude() {
        return !!this.experienceType.userTags.include.overrideDescription;
    }

    get showExclude() {
        return (
            this.experienceType.userTags.exclude.enabled || !!this.experienceType.userTags.exclude.overrideDescription
        );
    }

    get showAltExclude() {
        return !!this.experienceType.userTags.exclude.overrideDescription;
    }

    get collapseUserTagSection() {
        return this.experienceType.userTags && this.experienceType.userTags.type === 'COLLAPSED';
    }

    get isUserTagsUnsupported() {
        return this.experienceType.userTags && this.experienceType.userTags.type === 'UNSUPPORTED';
    }

    get isAppSelectionBroadcastSupported() {
        return this.crConfig.customer.configFlags.enable_app_selection_broadcast || 
                !!this.crConfig.customer.configFlags.enable_app_selection_broadcast;
    }

    get isInVenueSelected() {
        const { additionalFields, id } =  this.experienceType
        const location = _.find(additionalFields, { type: 'LOCATION_SELECT' });
        if (location) {
            return (
                id === 'BROADCAST_NOW' &&
                _.get(this.experience, `${location.targetPath}.type`) === 'IN_VENUE'
            );
        }
        return false;
    }

    checkBehaviorValidity() {
        // Checks for cases where the user must choose at least one of multiple fields
        if (this.experienceType.userBehavior) {
            this.experienceType.userBehavior.forEach((behavior, index) => {
                if (
                    !behavior.optional &&
                    behavior.fields.filter((field) => field.optional).length === behavior.fields.length
                ) {
                    let valid = false;
                    behavior.fields.forEach((field) => {
                        if (_.has(this.experience, field.targetPath)) {
                            valid = true;
                        }
                    });
                    this.form[`behavior${index}`].$setValidity('atLeastOne', valid);
                }
            });
        }
    }


    toggleAdvancedSegmentation() {
        this.isSegmentationCollapsed = !this.isSegmentationCollapsed;
    }

    generateAppList() {
        this.userApps = {
            selectedApps: [],
            appOptions: [],
            key: '_userApps'

        };
        this.globalAppIds = [];
        if(this.crConfig.customer.configFlags.global_apps && this.crConfig.customer.configFlags.global_apps.length > 0) {
            this.globalAppIds = this.crConfig.customer.configFlags.global_apps;
        }
        this.userAppsList = this.crVenueService.config.customer
                        .venues.find(venue => venue.id === this.$state.params.venueId)
                        .appConfigs;
               
        if(this.userAppsList) {
            this.userAppsList.forEach((app) => {
                app.id = app.appId;
                app.label = app.displayName;
                app.appValueKey = app.idMask;
                if(this.globalAppIds) {
                    app.isGlobalApp = this.globalAppIds.includes(app.id);
                }
            });
            this.userApps.appOptions = this.userAppsList;
        }
                      
    }


    mapExistingApps() {
        if(this.experience.appIds && this.experience.appIds.length > 0) {
            const selectedApps = this.experience.appIds;
            this.userApps.selectedApps = selectedApps.map(selectedApp => {
                return this.userApps.appOptions.find((app) => app.id === selectedApp);
            });
        }
    }


    onAppSelect(event) {
        if(event && event.length > 0) {
            const userSelectedApps = event.map(app => app.id);
            const isGlobalAppSelected = _.intersection(userSelectedApps, this.globalAppIds);
            if(!this.isCorporateMarketor && isGlobalAppSelected.length > 0 && this.updateOutOfVenueLocation) {
                this.updateOutOfVenueLocation.updateLocation('remove');
            } else if (this.updateOutOfVenueLocation){
                this.updateOutOfVenueLocation.updateLocation('add');
            }
            this.experience.appIds = userSelectedApps;
        }
    }

    updateTagLists() {
        this.eqTags = [];
        this.neqTags = [];

        // Update the EQ tags if the default one is empty, so that the placeholder one is used for all app users
        const currentEqTags = this.experience.audience.tags.filter((tag) => tag.op === 'EQ');
        if (currentEqTags.length === 1) {
            const currentEq = currentEqTags[0];
            if (currentEq.key === null) {
                currentEq.key = this.crConstants.appUsersTagKey; // See experience-manage.controller preparePayload()
                currentEq.values = ['all'];
                currentEq.options = null; // Reset for the next step
            }
        }

        this.experience.audience.tags.forEach((tag) => {
            if ((!tag.options || tag.options.length === 0) && this.tagDefinitions) {
                tag.options = _.cloneDeep(this.tagDefinitions);

                tag.options.forEach((option) => {
                    const isSelected = option.tagKey === tag.key;
                    option.isSelected = isSelected;

                    if (isSelected) {
                        tag.valueOptions = option.values;
                        tag.definition = option;

                        tag.fullValues = [];
                        if (tag.values) {
                            tag.values.forEach((valueKey) => {
                                tag.fullValues.push(tag.valueOptions.find((tvo) => valueKey === tvo.tagValueKey));
                            });
                        }
                    }
                });
            }

            if (tag.op === 'EQ') {
                this.eqTags.push(tag);
            } else {
                // Don't include the fake All App Users tag in the NEQ section
                tag.options = tag.options.filter((tagOption) => !tagOption.isFakeTag);
                this.neqTags.push(tag);
            }
        });
    }

    dirtyForm() {
        this.form.$setDirty();
    }

    onTagSelect(event, tag) {
        const tagDefinition = event.model[0];
        tag.fullValues = [];
        tag.values = [];

        if (tagDefinition) {
            tag.key = tagDefinition.tagKey;
            tag.definition = tagDefinition;
            tag.valueOptions = tagDefinition.values;
            if (tagDefinition.isFakeTag) {
                // Populate the tag values automatically, since the field will be
                // disabled and the user won't be able to do it themselves
                tag.fullValues = tagDefinition.values;
                tag.values = tag.fullValues.map((value) => value.tagValueKey);
            }
        } else {
            tag.key = null;
            tag.definition = null;
            tag.valueOptions = null;
        }
        this.experience.audience.tags = [...this.experience.audience.tags];
        this.dirtyForm();
    }

    onFieldUpdate(path, data, checked) {
        if (checked) {
            _.set(this.experience, path, data);
        } else {
            _.unset(this.experience, path);
        }

        this.dirtyForm();
        this.checkBehaviorValidity();
    }

    onUpdateAppOptions(locationType) {
        if(this.isAppSelectionBroadcastSupported && this.userApps) {
            if(!this.isCorporateMarketor 
                && locationType === this.locationEnums.OUTSIDE_VENUE) {
                this.userApps.appOptions = this.userAppsList.filter(app => !app.isGlobalApp);
            }
            else {
                this.userApps.appOptions = this.userAppsList;
            }
        }
    }

    addTag(op) {
        this.experience.audience.tags.push({
            key: null,
            op,
            values: [],
        });

        this.dirtyForm();
        this.updateTagLists();
    }

    get eqTagsValid() {
        if (this.eqTags) {
            for (let i = 0; i < this.eqTags.length; i++) {
                const tag = this.eqTags[i];

                if (!tag.key || tag.values.length === 0) {
                    return false;
                }
            }
        }

        return true;
    }

    get neqTagsValid() {
        if (this.neqTags) {
            for (let i = 0; i < this.neqTags.length; i++) {
                const tag = this.neqTags[i];

                if (!tag.key || tag.values.length === 0) {
                    return false;
                }
            }
        }

        return true;
    }

    onValueSelect(values, tag) {
        if (values === null) {
            tag.fullValues = [];
            tag.values = [];
        } else {
            tag.fullValues = values;
            tag.values = values.map((value) => value.tagValueKey);
        }
        this.experience.audience.tags = [...this.experience.audience.tags];
    }

    onDateTagDateUpdate(event, tag) {
        if (!event.model) {
            tag.values = [];
        } else {
            tag.values = [event.model];
        }
        this.experience.audience.tags = [...this.experience.audience.tags];
        this.dirtyForm();
    }

    removeTag(tag) {
        const index = this.experience.audience.tags.indexOf(tag);
        this.experience.audience.tags.splice(index, 1);
        this.dirtyForm();
        this.updateTagLists();
    }

    hasExamples(behavior) {
        return behavior.examples && behavior.examples.length;
    }

    getExampleHtml(behavior) {
        const html = behavior.examples
            .map(
                (example) => `<div class="title">${_.escape(example.title)}</div>
             <div class="description">${_.escape(example.description)}</div>`
            )
            .join('');

        if (!this.trustedHtmlCache[html]) {
            this.trustedHtmlCache[html] = this.$sce.trustAsHtml(html);
        }

        return this.trustedHtmlCache[html];
    }

    hasCorporateMarketerRole() {
        this.crPolicyService.hasRole('exp_corporate_marketer').then(res => this.isCorporateMarketor = res);
    }
}

export default Who;
