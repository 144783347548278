import angular from 'angular';

import text from './resources/locale/en.json';
import RowBuilderLegacyDirective from './row-builder-legacy.directive';

const RowBuilderLegacyModule = angular
    .module('control-room.commons.form.components.row-builder-legacy', [])
    .constant('rowBuilderLocale', text)
    .directive('crRowBuilderLegacy', RowBuilderLegacyDirective.directiveFactory);

export default RowBuilderLegacyModule;
