import PlaceDetailsController from './place-details.controller';
import template from './place-details.html';
import './place-details.less';

const PlaceDetailsComponent = {
    template,
    controller: [
        '$state',
        'placeDetailsService',
        'crConstants',
        'placeDetailsLocale',
        'numberFilter',
        'crErrorLoggingService',
        'crNavigationService',
        PlaceDetailsController,
    ],
};

export default PlaceDetailsComponent;
