import _ from 'lodash';
import pinUrl from './resources/pin_orange.svg';

function withinBounds(position) {
    return position.x >= 0 && position.x <= 1 && position.y >= 0 && position.y <= 1;
}

class BeaconsMapService {
    constructor(crConstants, crBeaconsService, crGraphicalMapService, crVenueService) {
        this.constants = crConstants;
        this.crBeaconsService = crBeaconsService;
        this.crGraphicalMapService = crGraphicalMapService;
        this.crVenueService = crVenueService;
    }

    getData(venueId, params) {
        const queryParams = _.pickBy(params, (value) => !_.isNil(value) && value !== '' && value !== 'all');

        let mapId;

        return this.crVenueService
            .getById(venueId)
            .then((res) => {
                mapId = res.defaultMapId;

                queryParams.mapId = mapId;

                return this.crBeaconsService.getBeacons(queryParams);
            })
            .then((res) =>
                this.crGraphicalMapService
                    .attributeOptimizedMapPositions(
                        venueId,
                        mapId,
                        res.content.map((beacon) => beacon.mapPosition)
                    )
                    .then((mapImageSrc) => ({ beacons: res.content, mapImageSrc, query: res.query }))
            );
    }

    mapToMarkersModel(beacons) {
        return beacons
            .filter(
                (beacon) =>
                    beacon.mapPosition &&
                    beacon.mapPosition.translatedPosition &&
                    withinBounds(beacon.mapPosition.position)
            )
            .map((beacon) => ({
                tooltip: true,
                cursor: 'pointer',
                anchor: 'center-bottom',
                source: pinUrl,
                id: beacon.id,
                label: beacon.name,
                w: this.constants.marker.defaultDimensions.w,
                h: this.constants.marker.defaultDimensions.h,
                x: beacon.mapPosition.translatedPosition.x,
                y: beacon.mapPosition.translatedPosition.y,
            }));
    }
}

export default BeaconsMapService;
