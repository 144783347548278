import template from './poi-type-list.html';
import './poi-type-list.less';
import PoiTypeListController from './poi-type-list.controller';

const PoiTypeListComponent = {
    template,
    bindings: {},
    controller: [
        '$state',
        '$uibModal',
        'crPoiTypeListService',
        'crNavigationService',
        'crErrorLoggingService',
        PoiTypeListController,
    ],
};

export default PoiTypeListComponent;
