import controller from './menu-categories.controller';
import template from './menu-categories.html';

const component = {
    template,
    bindings: {},
    controller: [
        '$state',
        'crErrorLoggingService',
        'crAnalyticsService',
        'crToastService',
        'crLocaleService',
        'crConfig',
        'crMenuCategoriesService',
        'crEntitySelectionManager',
        'crNavigationService',
        controller,
    ],
};

export default component;
