import _ from 'lodash';

class InputController {
    constructor(text) {
        this.text = text;
    }

    $onInit() {
        this.required = this.required || false;
        this.hideValidationMessaging = this.hideValidationMessaging || false;
        this.validation = this.validation || {};
        this.defaultValidationMessages = _.cloneDeep(this.text.validationMessages);
        this.validationMessages = this.validationMessages || this.defaultValidationMessages;
        this.charactersRemaining = !_.isUndefined(this.charactersRemaining) ? this.charactersRemaining : 'focused';
        this.model = this.value || '';
        this.minlength = this.minlength || false;
        this.maxlength = this.maxlength || false;
        this.inputCustomClass = this.inputCustomClass || false;
        this.name = this.name || '';
        this.placeholder = this.placeholder || '';
        this.type = this.type || 'text';
        this.disabled = this.disabled || false;
        this.onFocus = this.onFocus || (() => null);
        this.onChange = this.onChange || (() => null);
        this.onKeyup = this.onKeyup || (() => null);
        this.onBlur = this.onBlur || (() => null);

        this.isFocused = false;

        this.uiValidate = {};
        this.uiValidateAsync = {};

        Object.keys(this.validation).forEach(this.transformValidation.bind(this));
        this.charactersRemainingCount = this.getCharactersRemaining();
    }

    $onChanges(changesObj) {
        if (changesObj.value) {
            this.model = changesObj.value.currentValue;
        }

        if (this.form && changesObj.disabled) {
            this.setWatched();
        }
    }

    transformValidation(key) {
        if (this.validation[key].validator) {
            if (this.validation[key].async) {
                this.uiValidateAsync[key] = `inputCtrl.validation.${key}.validator($value)`;
            } else {
                this.uiValidate[key] = `inputCtrl.validation.${key}.validator($value)`;
            }
        }
    }

    onNgFocus(e) {
        this.form.$setPristine();
        this.form.$setUntouched();
        this.isFocused = true;
        this.setWatched();
        this.onFocus({ e });
    }

    onNgBlur(e) {
        this.isFocused = false;
        this.form[this.name].$setTouched();
        this.setWatched();
        this.onBlur({ e });
    }

    onNgKeyup(e) {
        this.setWatched();
        this.onKeyup({ e });
    }

    onNgChange() {
        this.form.$name = this.name;
        this.onChange({ value: this.model });
    }

    setWatched() {
        if (this.form) {
            const formName = this.form[this.name];
            if (formName && formName.$error && Object.keys(formName.$error).length) {
                let errorType;

                Object.keys(formName.$error).forEach((key) => {
                    if (this.validation[key]) {
                        if (errorType) {
                            if ((this.validation[errorType].priority || -1) < (this.validation[key].priority || -1)) {
                                errorType = key;
                            }
                        } else {
                            errorType = key;
                        }
                    }
                });

                const vt = this.validation[errorType];

                if (vt && vt.messages && vt.messages.error) {
                    this.validationMessages.error = vt.messages.error;
                }
            }
            this.valid = this.isValid();
            this.invalid = this.isInvalid();
            this.pending = this.isPending();
            this.charactersRemainingVisibility = this.showCharactersRemaining();
            this.charactersRemainingCount = this.getCharactersRemaining();
        }
    }

    getCharactersRemaining() {
        const currLength = this.model ? this.model.length : 0;
        return Math.max(0, this.maxlength - currLength);
    }

    showCharactersRemaining() {
        return (
            this.maxlength &&
            this.charactersRemaining &&
            this.charactersRemaining !== 'false' &&
            (this.charactersRemaining === 'focused' ? this.isFocused : true)
        );
    }

    isValid() {
        return this.form[this.name] && this.form[this.name].$valid && this.form[this.name].$touched && !this.disabled;
    }

    isPending() {
        return this.form[this.name] && this.form[this.name].$pending && !this.disabled;
    }

    isInvalid() {
        return (
            this.form[this.name] &&
            this.form[this.name].$invalid &&
            this.form[this.name].$touched &&
            !this.form[this.name].$pending &&
            !this.disabled
        );
    }
}

export default InputController;
