import ResizeObserver from 'resize-observer-polyfill';
import {
  Directive, Input, ElementRef, NgZone, AfterViewInit, OnDestroy,
} from '@angular/core';
import { Subject, fromEvent } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';

@Directive({ selector: '[crDraggable]' })
export class CrDraggableDirective implements AfterViewInit, OnDestroy {
  @Input() dragHandle: string;

  @Input() dragTarget: string;

  @Input() startingOffset: any;

  delta = { x: 0, y: 0 };

  destroy$ = new Subject<void>();

  handle: HTMLElement;

  offset = { x: 0, y: 0 };

  parent: HTMLElement;

  target: HTMLElement;

  constructor(private elementRef: ElementRef, private zone: NgZone) {}

  ngAfterViewInit(): void {
    this.handle = this.dragHandle ? document.querySelector(this.dragHandle) : this.elementRef.nativeElement;
    this.target = document.querySelector(this.dragTarget);
    this.parent = this.elementRef.nativeElement.parentElement;

    if (this.startingOffset) {
      this.offset = this.startingOffset;
    }

    this.zone.runOutsideAngular(() => {
      const mousedown$ = fromEvent(this.handle, 'mousedown');
      const mousemove$ = fromEvent(document, 'mousemove');
      const mouseup$ = fromEvent(document, 'mouseup');

      const mousedrag$ = mousedown$.pipe(
        switchMap((event: MouseEvent) => {
          this.handle.style.cursor = 'grabbing';
          const startX = event.clientX;
          const startY = event.clientY;

          return mousemove$.pipe(
            map((e: MouseEvent) => {
              event.preventDefault();
              this.delta = {
                x: e.clientX - startX,
                y: e.clientY - startY,
              };
            }),
            takeUntil(mouseup$),
          );
        }),
        takeUntil(this.destroy$),
      );

      mousedrag$.subscribe(() => {
        if (this.delta.x === 0 && this.delta.y === 0) {
          return;
        }

        this.translate();
      });

      mouseup$.pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.offset.x += this.delta.x;
        this.offset.y += this.delta.y;
        this.delta = { x: 0, y: 0 };
        this.handle.style.cursor = '';
      });

      new ResizeObserver((entries) => {
        const parent = entries[0].contentRect;
        if (Math.abs(this.offset.x) + this.target.clientWidth > parent.width) {
          this.offset.x = (parent.width - this.target.clientWidth) * -1;
        }
        if (this.offset.y + this.target.clientHeight > parent.height) {
          this.offset.y = parent.height - this.target.clientHeight;
        }
        this.translate();
      }).observe(this.elementRef.nativeElement.parentElement);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  translate() {
    requestAnimationFrame(() => {
      let newX = this.offset.x + this.delta.x;
      let newY = this.offset.y + this.delta.y;
      if (newX > 0) {
        newX = 0;
      } else if (Math.abs(newX) + this.target.clientWidth > this.parent.clientWidth) {
        newX = (this.parent.clientWidth - this.target.clientWidth) * -1;
      }
      if (newY < 0) {
        newY = 0;
      } else if (newY + this.target.clientHeight > this.parent.clientHeight) {
        newY = this.parent.clientHeight - this.target.clientHeight;
      }
      this.target.style.transform = `
              translate(${newX}px,
                        ${newY}px)
            `;
    });
  }
}
