import {
  Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Config, Constants, LocalizedText } from '../../../core';
import text from './resources/locale/en.json';
import { CR_CONSTANTS } from '../../services/constants/constants';
import { ImageUploaderChangeEvent } from '../../content/image-uploader/image-uploader.component';
import { CrEvent, Image } from '../../models';
import { Locale } from '../../services/locale/locale.model';
import { CR_CONFIG } from '../../services/config';

export interface EntityContentComponentModel {
  title: string;
  description?: string;
  images?: Image[];
}

export interface EntityConentComponentUpdateEvent {
  model: EntityContentComponentModel
}

@Component({
  selector: 'cr-entity-content',
  templateUrl: './entity-content.component.html',
  styleUrls: ['./entity-content.component.scss'],
})
export class EntityContentComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  entity?: EntityContentComponentModel;

  @Input()
  manage = true;

  @Input()
  locale: Locale;

  @Input()
  maxTitleLength?: number;

  @Input()
  maxDescriptionLength?: number;

  @Output()
  onUpdate: EventEmitter<CrEvent<EntityConentComponentUpdateEvent>> =
  new EventEmitter<CrEvent<EntityConentComponentUpdateEvent>>();

  text: LocalizedText;

  imageMinSize: {
    width: number,
    height: number
  };

  form: FormGroup;

  isDefaultLocale = true;

  showLocaleDropdown = false;

  private onFormUpdatedSubscription: Subscription;

  constructor(
    @Inject(CR_CONSTANTS) public constants: Constants,
    @Inject(CR_CONFIG) private config: Config,
    private formBuilder: FormBuilder,
  ) {
    this.initForm();
  }

  ngOnInit(): void {
    this.text = text;
    this.showLocaleDropdown = this.config.customer.localizationEnabled
      && this.locale.list
      && this.locale.list.length > 1;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.entity && changes.entity.currentValue) {
      const { currentValue }: { currentValue: EntityContentComponentModel } = changes.entity;
      if (this.form) {
        this.form.patchValue({
          title: currentValue.title,
          description: currentValue.description || '',
          images: currentValue.images || [],
        } as EntityContentComponentModel);
      }
    }
  }

  ngOnDestroy(): void {
    if (this.onFormUpdatedSubscription) {
      this.onFormUpdatedSubscription.unsubscribe();
    }
  }

  onImageUpdate({ $event }: ImageUploaderChangeEvent): void {
    this.form.get('images').setValue($event.images);
  }

  updateContent(model?: EntityContentComponentModel): void {
    this.onUpdate.emit({
      $event: { model },
    });
  }

  private initForm() {
    this.form = this.formBuilder.group({
      title: ['', [Validators.required]],
      description: [''],
      images: [[]],
      selectedLocale: [],
    });

    this.onFormUpdatedSubscription = this.form.valueChanges.subscribe((value: EntityContentComponentModel) => {
      this.updateContent({
        title: value.title,
        description: value.description,
        images: value.images,
      });
    });
  }
}
