import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Menu, MenuProductCategory, ProductCategory, ProductCategoryRequest } from './menu.model';
import {MenuReport} from "./menu-report.model";

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  private baseUrl: string;
  constructor(private http: HttpClient) {
    this.baseUrl = '/rest/menus';
  }

  getMenuCategoriesWithHierarchy(menuId: string, placeId: string, venueId: string, locale: string): Observable<MenuProductCategory & { hierarchyLabel: string, parentId: string, id: string }[]> {
    return this.http.get<MenuProductCategory & { hierarchyLabel: string, parentId: string, id: string }[]>(`${this.baseUrl}/${menuId}/menu-categories/hierarchy`, {
      headers: {
        'Accept-Language': locale,
      },
      params: {
        placeId,
        venueId,
      },
    });
  }

  getMenusForPoi(venueId: string, poiId: string, locale: string): Promise<Menu[]> {
      return this.getMenusForPlace(`poi:${venueId}.${poiId}`, locale, new HttpParams({ fromObject: { state: 'ACTIVE' } }));
  }

  getMenusForPlace(placeId: string, locale: string, params: HttpParams): Promise<Menu[]> {
      const path = `${this.baseUrl}/places/${placeId}`;
      return this.http.get<Menu[]>(path, {
          params: {
            ...params
          },
          headers: {
            'Accept-Language': locale,
          },
      }).toPromise();
  }

  getMenu(venueId: string, placeId: string, menuId: string, locale: string): Promise<Menu> {
      const headers = locale ? { 'Accept-Language': locale } : {};
      const path = `${this.baseUrl}/${menuId}?venueId=${venueId}&placeId=${placeId}`;
      return this.http.get<Menu>(path, { headers: headers }).toPromise();
  }

  updateMenu(venueId: string, placeId: string, menuId: string, menu: Menu): Promise<Menu> {
      return this.http.put<Menu>(`${this.baseUrl}/${menuId}?venueId=${venueId}&placeId=${placeId}`, menu).toPromise();
  }

  createMenu(venueId: string, placeId: string, menu: Menu): Promise<Menu> {
      return this.http.post<Menu>(`${this.baseUrl}/?venueId=${venueId}&placeId=${placeId}`, menu).toPromise();
  }

  deleteMenu(venueId: string, placeId: string, menuId: string): Promise<any> {
      return this.http.delete(`${this.baseUrl}/${menuId}?venueId=${venueId}&placeId=${placeId}`).toPromise();
  }

  async getMenuLayout(venueId: string, placeId: string, menuId: string): Promise<MenuProductCategory> {
      const params = { sortby: 'rank', sort: 'asc', state: 'ACTIVE', venueId, placeId };
      const path = `${this.baseUrl}/${menuId}/menu-categories`;
      return await this.http.get<MenuProductCategory>(path, { params: params }).toPromise().then((res) => {
        res.content.forEach((category) => {
          category.products = category.products.filter((product) => product.state === 'ACTIVE');
        });
        return res;
      });
  }

  updateMenuRanks(menuId: string, ranksRequest: ProductCategoryRequest): Promise<ProductCategory> {
      const path = `${this.baseUrl}/${menuId}/categoryRanks`;
      return this.http.put<ProductCategory>(path, ranksRequest).toPromise();
  }

  getMenusInAllVenues(): Promise<MenuReport[]> {
      return this.http.get<MenuReport[]>(`${this.baseUrl}/allVenue`).toPromise();
  }
}
