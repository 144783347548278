import angular from 'angular';

import component from './menus-list.component';
import service from './menus-list.service';

const MenusList = angular
    .module('control-room.menus.components.menus-list', [])
    .component('crMenusList', component)
    .service('crMenusListService', ['crEntityService', 'crConstants', service]);

export default MenusList;
