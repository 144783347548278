<header [formGroup]="form" data-global-qa-id="entityHeader">
  <section *ngIf="manage" class="cr-flex-between">
    <section class="image-container">
      <cr-image-uploader
        [imageType]="constants.image.types.STICKER"
        [upgradedFormControl]="form.get('images')"
        [images]="model.images"
        [name]="'entityImage'"
        (onChange)="onImageUpdate($event)"
      ></cr-image-uploader>
    </section>
    <section class="flex-remaining-space">
      <cr-form-label-error data-global-qa-id="entity-name-error" for="name"
        >{{ text.name }} *</cr-form-label-error
      >
      <div>
        <cr-input
          name="entityName"
          formControlName="name"
          data-global-qa-id="entity-name"
          hideValidation="true"
          class="input-large stretch"
          (onUpdate)="onNameUpdate($event)"
        ></cr-input>
      </div>

      <section class="state-container">
        <cr-form-label-error data-global-qa-id="entity-state-error" for="state"
          >{{ text.state }} *</cr-form-label-error
        >
        <cr-dropdown
          mode="chip"
          name="state"
          formControlName="state"
          data-global-qa-id="manage-state"
          [menuItems]="states"
        ></cr-dropdown>
      </section>
    </section>
  </section>
  <hr class="details-divider" />
</header>
