import moment from 'moment';
import 'moment-timezone';

import text from './resources/locale/en.json';

class WeekPicker {
    constructor($state, crConstants, crVenueService, crAnalyticsService) {
        this.$state = $state;
        this.crConstants = crConstants;
        this.crVenueService = crVenueService;
        this.crAnalyticsService = crAnalyticsService;
    }

    $onChanges(changes) {
        if (changes && changes.range && !changes.range.isFirstChange()) {
            this.updateCurrentWeekText();
        }
    }

    $onInit() {
        this.text = text;
        this.venueTimezone = this.crVenueService.getTimezone(this.$state.params.venueId);
        this.startOfWeek = moment(this.$state.params.start).tz(this.venueTimezone.name).startOf('week').toDate();

        this.dateOptions = {
            formatDay: this.crConstants.date.format.pickerDay,
            showWeeks: false,
        };

        this.weekOptions = [
            { id: 'current', label: this.text.thisWeek },
            { id: 'next', label: this.text.nextWeek },
            { id: 'select', label: `${this.text.selectWeek} ...` },
        ];

        if (!this.$state.params.start || !this.$state.params.end) {
            this.setDefaultWeek();
        } else {
            this.updateCurrentWeekText();
        }
    }

    emitChange(start, end) {
        this.onUpdate({
            $event: {
                model: { start, end },
            },
        });

        this.updateStartOfWeek(start);

        this.updateRoute().then(this.updateCurrentWeekText.bind(this));
    }

    weekSelected(option, e) {
        switch (option.id) {
            case 'current':
                this.goToThisWeek();
                break;
            case 'next':
                this.goToNextWeek();
                break;
            case 'select':
                if (e) {
                    e.stopPropagation();
                    this.showWeekSelector = true;
                }
                break;
        }
    }

    updateStartOfWeek(start) {
        this.startOfWeek = start;
    }

    weekUpdate() {
        this.isOpen = false;
        this.startOfWeek = moment(this.startOfWeek).startOf('week').toDate();
        this.$state.params.start = moment(this.startOfWeek).toISOString();
        this.$state.params.end = moment(this.startOfWeek).add(1, 'week').toISOString();
        this.crAnalyticsService.track('Week Picker - Select Week', { start: this.$state.params.start });
        this.emitChange(this.$state.params.start, this.$state.params.end);
    }

    goToPrev(e) {
        this.keepOpen(e);
        this.isOpen = false;
        const diff = moment(this.$state.params.end).diff(this.$state.params.start, 'day');
        this.$state.params.end = this.$state.params.start;
        this.$state.params.start = moment(this.$state.params.end).subtract(diff, 'day').toISOString();
        this.crAnalyticsService.track('Week Picker - Left Arrow', { start: this.$state.params.start });
        this.emitChange(this.$state.params.start, this.$state.params.end);
    }

    goToNext(e) {
        this.keepOpen(e);
        this.isOpen = false;
        const diff = moment(this.$state.params.end).diff(this.$state.params.start, 'day');
        this.$state.params.start = this.$state.params.end;
        this.$state.params.end = moment(this.$state.params.start).add(diff, 'day').toISOString();
        this.crAnalyticsService.track('Week Picker - Right Arrow', { start: this.$state.params.start });
        this.emitChange(this.$state.params.start, this.$state.params.end);
    }

    goToThisWeek() {
        const start = moment().tz(this.venueTimezone.name).startOf('week').toISOString();
        this.crAnalyticsService.track('Week Picker - This Week', { start });
        this.goToWeek(start);
    }

    goToNextWeek() {
        const start = moment().tz(this.venueTimezone.name).startOf('week').add(1, 'week').toISOString();
        this.crAnalyticsService.track('Week Picker - Next Week', { start });
        this.goToWeek(start);
    }

    goToWeek(start) {
        this.$state.params.start = start;
        this.$state.params.end = moment(start).add(1, 'week').toISOString();
        this.emitChange(this.$state.params.start, this.$state.params.end);
    }

    updateCurrentWeekText() {
        const start = moment(this.$state.params.start).tz(this.venueTimezone.name);
        const end = moment(this.$state.params.end).tz(this.venueTimezone.name).subtract(1, 'millisecond');

        this.currentWeekText = start.format('MMM DD');

        if (!moment(start).isSame(end, 'year')) {
            this.currentWeekText = `${start.format('MMM DD, YYYY')}-${end.format('MMM DD, YYYY')}`;
            return;
        }

        if (!moment(start).isSame(end, 'month')) {
            this.currentWeekText += `-${end.format('MMM DD')}`;
            return;
        }

        if (!moment(start).isSame(end, 'day')) {
            this.currentWeekText += `-${end.format('DD')}`;
        }
    }

    setDefaultWeek() {
        const start = moment().tz(this.venueTimezone.name).startOf('week').toISOString();
        this.$state.params.start = start;
        this.$state.params.end = moment(start).tz(this.venueTimezone.name).add(1, 'week').toISOString();
        this.updateRoute()
            .catch(() => null)
            .finally(() => {
                this.updateCurrentWeekText();
            });
    }

    updateRoute() {
        const currentState = this.$state.current.name;
        return this.$state.go(currentState, this.$state.params, {
            location: 'replace',
        });
    }

    keepOpen(e) {
        if (e) {
            e.stopPropagation();
        }
    }
}

export default WeekPicker;
