import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BsDropdownModule } from 'ngx-bootstrap';
import { CrIconModule } from '../../../../shared';
import { ChipModule } from '../../../../shared/components/chip/chip.module';
import { ImgHolderModule } from '../../../../shared/components/img-holder/img-holder.module';
import { CrTitleBarModule } from '../../../../shared/title-bar/title-bar.module';

import { BundleItemComponent } from './bundle-item.component';

@NgModule({
  imports: [CrTitleBarModule, CrIconModule, ChipModule, BrowserModule, ImgHolderModule, BsDropdownModule],
  exports: [BundleItemComponent],
  declarations: [BundleItemComponent],
  entryComponents: [BundleItemComponent],
  providers: [],
})
export class BundleItemModule { }
