<section class="lifts">
    <div class="filters">
        <cr-search
                class="header-item"
                placeholder="Search by name"
                (update)="onSearchTextChange($event)"
                [formControl]="searchFormControl"
        ></cr-search>
    </div>

    <div class="table-responsive-sm">
        <table class="table table-hover borderless">
            <thead>
                <tr>
                    <th scope="col" class="col-icon" *ngIf="isLift || isTrail"></th>
                    <th scope="col" class="col-name">{{ text.name }}</th>
                    <th scope="col" class="col-hours">{{ text.openHours }}</th>
                    <th scope="col" class="col-hours">{{ text.closeHours }}</th>
                    <th scope="col" class="col-operation-status">{{ text.operationStatus }}</th>
                    <th scope="col" class="col-grooming-status" *ngIf="isTrail">{{ text.grooming }}</th>
                    <th scope="col" class="col-snowmaking-status" *ngIf="isTrail">{{ text.snowMaking }}</th>
                    <th scope="col" class="col-wait-time">{{ text.waitTime }}</th>
                    <th scope="col" class="col-status-override">{{ text.statusOverride }}</th>
                    <th scope="col" class="col-automatic-status">{{ text.automaticStatus }}</th>
                    <th scope="col" class="col-action"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let row of rowData">
                    <td class="col-icon" *ngIf="isLift || isTrail">
                        <div
                                class="sticker-image square-image"
                                [ngStyle]="{'background-image': 'url(\'' + row.data.iconSrc + '\')'}"
                        >
                            <div class="cr-flex-center" *ngIf="!row.data.iconSrc">
                                <span class="icon-cr-image"
                                      [ngStyle]="{'color': '#444'}"></span>
                            </div>
                        </div>
                    </td>
                    <td class="col-name">
                        <span (click)="this.state.go('client.lifts-trails-activities.details', { id: row.data.id, tabId: listType });">{{ row.data.name }}</span>
                    </td>
                    <td class="col-hours">
                        <span *ngIf="!row.data.hours.open"> - </span>
                        {{row.data.hours.open}}
                    </td>
                    <td class="col-hours">
                        <span *ngIf="!row.data.hours.close"> - </span>
                        {{row.data.hours.close}}
                    </td>
                    <td class="col-operation-status">
                        <span class="icon-cr-circle"
                              style="border-color:  {{row.data.operationStatus.color}} "
                              *ngIf="row.data.operationStatus?.status?.toLowerCase() === text.open || row.data.operationStatus?.status?.toLowerCase() === text.hold"></span>
                        <span class="icon-cr-close"
                              style="color: {{ row.data.operationStatus.color }}"
                              *ngIf="row.data.operationStatus?.status?.toLowerCase() === text.closed"></span>
                        <span class="text-capitalize">{{ row.data.operationStatus?.status }}</span>
                    </td>
                    <td class="col-grooming-status" *ngIf="isTrail">
                        <cr-toggle
                            [valueMessages]="text.booleanToggleOptions"
                            [isOn]="row.data.grooming"
                            (onToggle)="onToggleGroomingStatus(row)"
                        ></cr-toggle>
                    </td>
                    <td class="col-snowmaking-status" *ngIf="isTrail">
                        <cr-toggle
                            [valueMessages]="text.booleanToggleOptions"
                            [isOn]="row.data.snowMaking"
                            (onToggle)="onToggleSnowMakingStatus(row)"
                        ></cr-toggle>
                    </td>
                    <td class="col-wait-time">
                        <input type="number" name="waitTime"
                               [ngModel]="row.data.waitTime"
                               (ngModelChange)="row.data.waitTime = $event"
                               (change)="saveRow(row)">
                        <span class="clear"
                              *ngIf="row.data.waitTime"
                              (click)="row.data.waitTime = ''; saveRow(row)">
                            <cr-icon class="icon-cr-close"></cr-icon>
                        </span>
                    <td class="col-status-override">
                        <cr-dropdown class="header-item"
                                     [label]=""
                                     [menuItems]="row.data.statusOptions"
                                     (onSelect)="onChangeOperationStatus($event.$event.model[0].id, row)"
                                     mode="select"
                                     placeholder="-"
                        ></cr-dropdown>
                    </td>
                    <td class="col-automatic-status">
                        <cr-toggle
                                [valueMessages]="text.activeInactiveToggleOptions"
                                [isOn]="row.data.autoStatus"
                                (onToggle)="onToggleAutoStatus(row)"
                        ></cr-toggle>
                    </td>
                    <td class="col-edit">
                        <div class="icon-cr-edit" (click)="this.state.go('client.lifts-trails-activities.edit', { id: row.data.id, tabId: listType });"></div>
                    </td>
                </tr>
                <tr *ngIf="rowData.length == 0 && isRowDataSet">
                    <td colspan="8" class="text-center no-record">No record found.</td>
                </tr>
            </tbody>
        </table>
    </div>
</section>
