import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ElementRef,
  ViewChildren,
  QueryList,
  Inject,
  Output,
  EventEmitter,
} from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { noop } from 'rxjs';

import { WINDOW } from '../../services/window';
import { SelectOption, LocalizedText } from '../../../core';
import { SearchComponent } from '../search/search.component';

import text from './resources/locale/en.json';

@Component({
  selector: 'cr-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent implements OnChanges, ControlValueAccessor {
  text: LocalizedText = text;

  isLoading: boolean;

  filter = '';

  filteredOptions: SelectOption[] = [];

  highlighted: SelectOption;

  isOverflowing: boolean = null;

  currentSelectionText: string;

  @Input()
  options: SelectOption[];

  @Input()
  recents: SelectOption[];

  @Input()
  hasDataError: boolean;

  @Input()
  label: string;

  @Output()
  change = new EventEmitter<any>();

  @ViewChild('selectDropdown', { static: true })
  selectDropdown: NgbDropdown;

  @ViewChild('searchInput', { static: false })
  searchInput: SearchComponent;

  @ViewChild('selectionText', { static: true })
  selectionText: ElementRef;

  @ViewChild('selectMenu', { static: true })
  selectMenu: ElementRef;

  @ViewChild('selectToggle', { static: true })
  selectToggle: ElementRef;

  @ViewChildren('selectOption')
  selectOptions: QueryList<ElementRef>;

  private _disabled: boolean;

  private _search: boolean;

  private _clear: boolean;

  private _multi: boolean;

  private _searchFocused: boolean;

  private _mouseEvents: boolean;

  private _indexToFocus: number = null;

  private _selections: Set<any> = new Set<any>();

  private _optionsMap: Map<any, SelectOption> = new Map<any, SelectOption>();

  constructor(@Inject(WINDOW) private window: Window, private ngControl: NgControl) {
    this.ngControl.valueAccessor = this;
  }

  get isOpen(): boolean {
    return this.selectDropdown.isOpen();
  }

  get isDisabled(): boolean {
    return this._disabled;
  }

  @Input('disabled')
  set isDisabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
  }

  get showSearch(): boolean {
    return this._search;
  }

  @Input('search')
  set showSearch(value: boolean) {
    this._search = coerceBooleanProperty(value);
  }

  get enableClear(): boolean {
    return this._clear;
  }

  @Input('clear')
  set enableClear(value: boolean) {
    this._clear = coerceBooleanProperty(value);
  }

  get multi(): boolean {
    return this._multi;
  }

  @Input()
  set multi(value: boolean) {
    this._multi = coerceBooleanProperty(value);
  }

  onChange: (_?: any) => void = () => noop();

  onTouched: (_?: any) => void = () => noop();

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  writeValue(value: any) {
    this._selections.clear();
    if (this.multi) {
      if (!Array.isArray(value)) {
        this._updateValue();
        return;
      }

      value.forEach((v) => {
        this._selections.add(v);
      });
    } else if (value) {
      this._selections.add(value);
    }

    this._updateCurrentSelectionText();
  }

  setDisabledState(isDisabled: boolean) {
    this._disabled = isDisabled;
  }

  get placement(): string[] {
    return this.isOverflowing ? ['top-left'] : ['bottom-left'];
  }

  get currentIcon(): string {
    if (this.multi && this._selections.size > 1) {
      return null;
    }

    const selection = this._selections.values().next().value;
    const option = this._optionsMap.get(selection);
    return option ? option.iconSrc : null;
  }

  get hasSelections(): boolean {
    return this._selections.size > 0;
  }

  get disableSelectionTooltip(): boolean {
    return (
      !this.hasSelections
            || this.isOpen
            || this.selectionText.nativeElement.scrollWidth <= this.selectionText.nativeElement.clientWidth
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    this.isLoading = this.options ? false : !this.hasDataError;

    if (changes && (changes.options || changes.filter)) {
      this.filteredOptions = this._filterOptions();
    }

    if (changes && changes.options) {
      this._buildOptionsMap();
      this._updateCurrentSelectionText();
    }
  }

  onOpenChange(open: boolean) {
    if (open) {
      setTimeout(() => {
        const rect = this.selectMenu.nativeElement.getBoundingClientRect();
        this.isOverflowing = rect.bottom >= this.window.innerHeight;
      });

      if (this.searchInput) {
        setTimeout(() => {
          this.searchInput.focusInput();
        });
      }
    } else {
      if (this.filter) {
        this.filter = '';
        this.filteredOptions = this._filterOptions();
      }

      this.clearHighlight();
      this.selectToggle.nativeElement.focus();
      this.isOverflowing = null;
    }
  }

  onKeydown(event: KeyboardEvent) {
    if (this.isOpen) {
      this.disableMouseEvents();
      if (event.key === 'Enter') {
        event.preventDefault();

        if (!this._searchFocused) {
          this.select(this.highlighted);
        } else if (this._indexToFocus !== null) {
          const option = this.filteredOptions[this._indexToFocus];
          if (option) {
            this.select(option);
          }
          this._indexToFocus = null;
        }
      } else if (event.key === ' ' && !this._searchFocused) {
        event.preventDefault();
        this.select(this.highlighted);
      } else if (event.key === 'Tab') {
        event.preventDefault();

        if (this._searchFocused) {
          const index = this.filteredOptions.findIndex((option) => !option.disabled);
          const first = this.selectOptions.toArray()[index];

          if (first) {
            first.nativeElement.focus();
          }
        } else if (this.showSearch && !this._searchFocused && event.shiftKey) {
          this.searchInput.focusInput();
          this.clearHighlight();
        }
        this._indexToFocus = null;
      } else if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
        if (this._indexToFocus !== null) {
          event.preventDefault();
          event.stopPropagation();

          const direction = event.key === 'ArrowUp' ? -1 : 1;
          const options = this.selectOptions.toArray();

          const target = options[this._indexToFocus + direction];
          if (target) {
            target.nativeElement.focus();
          } else {
            const backupTarget = options[this._indexToFocus];
            if (backupTarget) {
              backupTarget.nativeElement.focus();
            }
          }
        } else if (this._searchFocused) {
          event.preventDefault();
          event.stopPropagation();

          const index = this.filteredOptions.findIndex((option) => !option.disabled);
          const first = this.selectOptions.toArray()[index];

          if (first) {
            first.nativeElement.focus();
          }
        }

        this._indexToFocus = null;
      }
    } else if (event.key === 'Enter') {
      event.preventDefault();
    }
  }

  onBlur(event: FocusEvent) {
    if (!this.isOpen) {
      this.onTouched(event);
    }
  }

  onSearchFocus() {
    this._searchFocused = true;
  }

  onSearchBlur() {
    this._searchFocused = false;
  }

  onSearchUpdate(value) {
    this.filter = value;
    this.filteredOptions = this._filterOptions();
    this.clearHighlight();
  }

  optionSelected(option: SelectOption): boolean {
    return this._selections.has(option.value);
  }

  select(option: SelectOption) {
    if (!option) {
      this.selectDropdown.close();
      return;
    }

    if (option.disabled) {
      if (this.optionSelected(option) && !this.multi) {
        this.selectDropdown.close();
      }

      return;
    }

    if (this.multi) {
      if (this.optionSelected(option)) {
        this._selections.delete(option.value);
      } else {
        this._selections.add(option.value);
      }
      this._updateValue();
    } else {
      if (!this.optionSelected(option)) {
        this._selections.clear();
        this._selections.add(option.value);
        this._updateValue();
      }

      this.selectDropdown.close();
    }
  }

  clearSelected(event: MouseEvent) {
    event.stopPropagation();
    this._selections.clear();

    this._updateValue();
  }

  disableMouseEvents() {
    this._mouseEvents = false;
  }

  enableMouseEvents() {
    this._mouseEvents = true;
  }

  onOptionMouseover(option: SelectOption, index: number) {
    if (!this._mouseEvents) {
      return;
    }

    if (!option.disabled || this.optionSelected(option)) {
      this.highlighted = option;

      if (this._searchFocused) {
        this._indexToFocus = index;
      } else {
        const el = this.selectOptions.toArray()[index];

        if (el) {
          const item = el.nativeElement;
          const menu = this.selectMenu.nativeElement;

          const itemTop: number = item.offsetTop - menu.offsetTop;
          const itemBot = itemTop + (item.clientHeight as number);

          if (itemTop >= 0 && itemBot <= menu.clientHeight) {
            el.nativeElement.focus();
          } else {
            this._indexToFocus = index;
          }
        }
      }
    }
  }

  onOptionFocus(option: SelectOption) {
    if (!option.disabled || this.optionSelected(option)) {
      this.highlighted = option;
    }
  }

  clearHighlight() {
    this.highlighted = null;
    this._indexToFocus = null;
  }

  private _updateCurrentSelectionText() {
    if (this.isLoading) {
      this.currentSelectionText = this.text.loading;
      return;
    }

    if (this._selections.size === 0) {
      this.currentSelectionText = this.text.select;
      return;
    }

    let selectionText = '';
    let i = 0;
    this._selections.forEach((selection) => {
      if (i > 0) {
        selectionText += ', ';
      }

      const option = this._optionsMap.get(selection);
      if (option) {
        selectionText += option.label;
      }

      i += 1;
    });

    this.currentSelectionText = selectionText;
  }

  private _filterOptions(): SelectOption[] {
    if (!this.options) {
      return [];
    }

    if (!this.filter) {
      return this.options.slice().sort(this._sortOptions);
    }

    const filtered = this.options.filter((item) => item.label.toLocaleLowerCase().includes(this.filter.toLocaleLowerCase()));

    return filtered.sort(this._sortOptions);
  }

  private _sortOptions(a: SelectOption, b: SelectOption) {
    const labelA = a.label || '';
    const labelB = b.label || '';

    return labelA.toLocaleLowerCase().localeCompare(labelB.toLocaleLowerCase());
  }

  private _buildOptionsMap() {
    this._optionsMap.clear();
    if (this.options) {
      this.options.forEach((option) => {
        this._optionsMap.set(option.value, option);
      });
    }
  }

  private _updateValue() {
    let value: any;
    if (this.multi) {
      value = [];
      this._selections.forEach((selection) => {
        value.push(selection);
      });
    } else {
      const selection: any = this._selections.values().next().value;
      if (selection) {
        value = selection;
      }
    }
    this._updateCurrentSelectionText();
    this.onChange(value);
    this.change.emit(value);
  }
}
