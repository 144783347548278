import Controller from './calendar.controller';
import template from './calendar.html';
import './calendar.less';

const Component = {
    template,
    bindings: {
        schedule: '<',
        positionSticky: '@?',
        locationMap: '<?',
    },
    controller: ['$window', '$state', 'crCalendarService', 'crVenueService', 'crConstants', Controller],
};

export default Component;
