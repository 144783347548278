import _ from 'lodash';

class SetAuthCodeModalController {
    constructor(authService, text, validators, errorLoggingService, crConstants, crAnalyticsService) {
        this.authService = authService;
        this.text = text;
        this.validators = validators;
        this.errorLoggingService = errorLoggingService;
        this.regex = crConstants.regex;
        this.crAnalyticsService = crAnalyticsService;
    }

    onFocus() {
        this.errorMessage = '';
    }

    onSubmit(e) {
        e.stopPropagation();

        if (!this.form.$valid || this.pending) {
            return false;
        }

        this.pending = true;

        this.authService
            .authcode(this.authCode)
            .then((data) => {
                if (data.error) {
                    this.errorMessage = this.text.errors.server;
                    return false;
                }

                this.crAnalyticsService.track('Change Auth Code - Success');
                this.close();
            })
            .catch((res) => {
                const errorMessage = _.get(res.error, 'data.errors.0');
                if (errorMessage) {
                    this.errorMessage = Object.values(errorMessage)[0];
                } else {
                    this.errorMessage = this.text.errors.server;
                }
                this.crAnalyticsService.track('Change Auth Code - Failure');
            })
            .finally(() => {
                this.pending = false;
            });
    }

    onCancel() {
        this.close(false);
    }
}

export default SetAuthCodeModalController;
