import controller from './attribute-phone-preview.controller';
import template from './attribute-phone-preview.html';
import './attribute-phone-preview.less';

const component = {
    template,
    bindings: {
        attribute: '<',
    },
    controller: [controller],
};

export default component;
