import text from './resources/locale/en.json';

class QuicksightDashboardController {
    constructor($state, crReportFileService, crAnalyticsService, crErrorLoggingService, crQuicksightService) {
        this.text = text;
        this.$state = $state;
        this.crReportFileService = crReportFileService;
        this.crAnalyticsService = crAnalyticsService;
        this.crErrorLoggingService = crErrorLoggingService;
        this.crQuicksightService = crQuicksightService;
    }

    $onInit() {
        this.isLoading = true;
        this.venueId = this.$state.params.venueId;
        this.getReports(this.venueId);
    }

    getReports() {
        this.crQuicksightService
            .getDashboards(this.venueId)
            .then((res) => {
                this.isError = false;
                this.quicksightEnabled = res.isEnabled;
                if (res.isEnabled && res.dashboards && res.dashboards.home) {
                    this.quicksightUrl = res.dashboards.home;
                    this.crAnalyticsService.track('Quicksight report loaded', { url: res.dashboards.home });
                }
            })
            .catch((err) => {
                this.isError = true;
                console.error(`The quicksight request failed: ${err}`);
            })
            .finally(() => {
                this.isLoading = false;
            });
    }
}

export default QuicksightDashboardController;
