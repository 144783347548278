import _ from 'lodash';
import angular from 'angular';
import validator from 'validator';

export default angular.module('control-room.commons.forms.directives.validator', []).directive('crValidator', [
    'crConstants',
    (crConstants) => ({
        restrict: 'A',
        require: 'ngModel',
        scope: {
            type: '=',
        },
        link: (scope, elem, attrs, ctrl) => {
            let validatorOptions = [];
            if (attrs.validatorOptions) {
                validatorOptions = scope.$eval(attrs.validatorOptions);
            }

            // match anything containing a mustache template unless it appears after a ?
            const tokenUrlPattern = /^[^?]*?{.+}.*$/;

            /* eslint-disable @typescript-eslint/camelcase */
            const urlValidator = (modelVal) =>
                validator.isURL(modelVal, {
                    protocols: ['http', 'https'],
                    require_tld: true,
                    require_protocol: true,
                });
            /* eslint-enable @typescript-eslint/camelcase */

            switch (attrs.crValidator) {
                case 'email':
                    ctrl.$validators.pattern = (modelVal) => {
                        if (_.isNil(modelVal) || modelVal.length === 0) {
                            return true;
                        }
                        return validator.isEmail(modelVal);
                    };
                    break;
                case 'number': {
                    ctrl.$validators.pattern = (modelVal) => {
                        if (_.isNil(modelVal) || modelVal.length === 0) {
                            return true;
                        }
                        const val = `${modelVal}`; // Cast to string
                        return validator.isInt(val);
                    };

                    const minValues = validatorOptions
                        .filter((option) => option.type === 'MIN_VALUE')
                        .map((option) => option.value);

                    if (minValues.length > 0) {
                        const minValue = minValues.reduce((acc, cur) => Math.max(acc, cur), minValues[0]);
                        ctrl.$validators.minValue = (modelVal) => {
                            const val = `${modelVal}`; // Cast to string
                            return validator.isInt(val, { min: minValue });
                        };
                    }

                    const minValueOrNulls = validatorOptions
                        .filter((option) => option.type === 'MIN_VALUE_OR_NULL')
                        .map((option) => option.value);

                    if (minValueOrNulls.length > 0) {
                        const minValue = minValueOrNulls.reduce((acc, cur) => Math.max(acc, cur), minValueOrNulls[0]);
                        ctrl.$validators.minValue = (modelVal) => {
                            if (modelVal === null || modelVal === '') {
                                return true;
                            }
                            const val = `${modelVal}`; // Cast to string
                            return validator.isInt(val, { min: minValue });
                        };
                    }

                    break;
                }
                case 'float':
                    ctrl.$validators.pattern = (modelVal) => {
                        if (_.isNil(modelVal) || modelVal.length === 0) {
                            return true;
                        }
                        return validator.isFloat(`${modelVal}`);
                    };
                    break;
                case 'price': {
                    ctrl.$validators.pattern = (modelVal) => {
                        if (_.isNil(modelVal) || modelVal.length === 0) {
                            return true;
                        }
                        return validator.isCurrency(`${modelVal}`);
                    };

                    const minValues = validatorOptions
                        .filter((option) => option.type === 'MIN_VALUE')
                        .map((option) => option.value);

                    if (minValues.length > 0) {
                        const minValue = minValues.reduce((acc, cur) => Math.max(acc, cur), minValues[0]);
                        ctrl.$validators.minValue = (modelVal) => {
                            const val = `${modelVal}`; // Cast to string
                            return validator.isFloat(val, { min: minValue }) && validator.isCurrency(val);
                        };
                    }

                    break;
                }
                case 'lat':
                    ctrl.$validators.pattern = (modelVal) => {
                        if (_.isNil(modelVal) || modelVal.length === 0) {
                            return true;
                        }
                        return validator.matches(`${modelVal}`, crConstants.regex.LATITUDE);
                    };
                    break;
                case 'lon':
                    ctrl.$validators.pattern = (modelVal) => {
                        if (_.isNil(modelVal) || modelVal.length === 0) {
                            return true;
                        }
                        return validator.matches(`${modelVal}`, crConstants.regex.LONGITUDE);
                    };
                    break;
                case 'url':
                    ctrl.$validators.pattern = (modelVal) => {
                        if (_.isNil(modelVal) || modelVal.length === 0) {
                            return true;
                        }
                        return urlValidator(modelVal);
                    };
                    break;
                case 'token-url':
                    ctrl.$validators.pattern = (modelVal) => {
                        if (_.isNil(modelVal) || modelVal.length === 0) {
                            return true;
                        }
                        if (tokenUrlPattern.test(modelVal)) {
                            return true;
                        }
                        return urlValidator(modelVal);
                    };
                    break;
                case 'password':
                    ctrl.$validators.pattern = (modelVal) => {
                        if (_.isNil(modelVal) || modelVal.length === 0) {
                            return true;
                        }
                        return validator.matches(`${modelVal}`, crConstants.regex.PASSWORD);
                    };
                    break;
                case 'phone':
                    ctrl.$validators.pattern = (modelVal) => {
                        if (_.isNil(modelVal) || modelVal.length === 0) {
                            return true;
                        }
                        // Split into phone number + extension
                        const parts = modelVal.split(',');

                        if (parts.length === 1) {
                            return validator.isMobilePhone(`${modelVal}`, 'any', { strictMode: true });
                        }

                        const phoneNumber = parts[0];
                        const phoneExtension = parts[1];

                        return (
                            validator.isMobilePhone(`${phoneNumber}`, 'any', { strictMode: true }) &&
                            validator.matches(`${phoneExtension}`, crConstants.regex.PHONE_EXTENSION)
                        );
                    };
                    break;

                case 'alphanumericplus':
                    ctrl.$validators.pattern = (modelVal) => {
                        if (_.isNil(modelVal) || modelVal.length === 0) {
                            return true;
                        }
                        return validator.matches(`${modelVal}`, crConstants.regex.ALPHANUMERIC_PLUS);
                    };
                    break;

                default:
                    return true;
            }
        },
    }),
]);
