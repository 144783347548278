import template from './reorder-item.html';
import './reorder-item.less';

const Component = {
    transclude: true,
    template,
    bindings: {
        index: '<',
        first: '<',
        orderChange: '&',
        orderChangeTop: '&',
    },
};

export default Component;
