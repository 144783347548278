<!-- Order Notice -->
<div
  *ngIf="order.notice && !order.error"
  class="order-notice adjustable-padding"
>
  <div class="notice-text">
    <span class="icon icon-cr-done"></span>
    {{ orderNoticeMessage }}
  </div>
  <div class="notice-buttons">
    <span (click)="selectTab(order.fulfillmentStatus)">{{
      text.showOrder
    }}</span>
    <span (click)="dismissNotice(order)">{{ text.dismiss }}</span>
  </div>
</div>
<!-- Order Error -->
<div *ngIf="order.error" class="order-notice order-error adjustable-padding">
  <div class="notice-text">
    <div class="icon icon-cr-error"></div>
    <div>{{ text.orderApiErrorMessage }}</div>
  </div>
  <div class="notice-buttons">
    <span (click)="dismissNotice(order)">{{ text.dismiss }}</span>
  </div>
</div>
<!-- Order -->
<div
  class="order-parent"
  *ngIf="!order.notice && (isSearching || true)"
  [ngClass]="{
    'order-refunded': order.isFullRefund
  }"
>
  <div
    class="order-container shadow"
    [ngClass]="orderClassStyles"
    (swipeleft)="onSwipeLeft()"
    (swiperight)="onSwipeRight()"
  >
    <cr-order-list-item-details
      [text]="text"
      [order]="order"
      [kitchenId]="kitchenId"
      [isPrintingEnabled]="isPrintingEnabled"
      [orderClassStyles]="orderClassStyles"
      [isRefundsEnabled]="isRefundsEnabled"
      [writeAllowed]="writeAllowed"
      (orderEdit)="onItemEdit()"
    ></cr-order-list-item-details>
    <cr-order-state-button
      [text]="text"
      [order]="order"
      [kitchenId]="kitchenId"
      [selectedTab]="selectedTab"
      [orderClassStyles]="orderClassStyles"
      (updateFulfillment)="onFulfillmentUpdate()"
      (onCancel)="onSwipeRight()"
    ></cr-order-state-button>
  </div>
</div>
