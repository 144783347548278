import angular from 'angular';

import PoiListComponent from './poi-list.component';
import PoiListService from './poi-list.service';

const PoiListModule = angular
    .module('control-room.poi.components.poi-list', [])
    .component('crPoiList', PoiListComponent)
    .service('crPoiListService', ['crPoiService', 'crImageService', 'crEntityService', PoiListService]);

export default PoiListModule;
