import Controller from './search.controller';
import template from './search.html';
import './search.less';

const Component = {
    template,
    bindings: {
        availableSelections: '<',
        customClass: '@',
        placeholder: '@',
        maxlength: '@',
        onChange: '&',
        onKeypress: '&',
        onKeydown: '&',
        onKeyup: '&',
        term: '@?',
        defaultTerm: '@?',
    },
    controller: ['searchText', Controller],
};

export default Component;
