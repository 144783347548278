import controller from './phone-filter-view.controller';
import template from './phone-filter-view.html';
import './phone-filter-view.less';

const component = {
    template,
    bindings: {
        items: '<',
        label: '@',
    },
    controller: [controller],
};

export default component;
