import angular from 'angular';
import text from './resources/locale/en.json';
import HoursBuilderRowComponent from './hours-builder-row.component';

const HoursBuilderRowModule = angular
    .module('control-room.commons.form.components.hours-builder.components.hours-builder-row', [])
    .constant('hoursBuilderRowText', text)
    .component('crHoursBuilderRow', HoursBuilderRowComponent);

export default HoursBuilderRowModule;
