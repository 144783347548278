import text from './resources/locale/en.json';

class FoodAndBeverageReporting {
    constructor(
        $state,
        crConfig,
        crConstants,
        crAnalyticsService,
        errorLoggingService,
        numberFilter,
        fnbReportingService
    ) {
        this.$state = $state;
        this.crConfig = crConfig;
        this.crConstants = crConstants;
        this.crAnalyticsService = crAnalyticsService;
        this.errorLoggingService = errorLoggingService;
        this.numberFilter = numberFilter;
        this.fnbReportingService = fnbReportingService;
    }

    $onInit() {
        this.text = text;

        this.isLoading = true;
        this.isLoadingCharts = true;
        this.isLoadingPlaces = true;

        this.venueId = this.$state.params.venueId;
        this.selectedPlaces = []; // Treated as all places

        this.initFilterOptions();
        this.initChartOptions();

        this.getPlacesData();
    }

    getPlacesData() {
        this.isLoadingPlaces = true;

        this.fnbReportingService
            .getPlacesFromVenueId(this.venueId)
            .then((data) => {
                this.placeFilterData = data.map((place) => ({ id: place.id, label: place.name }));
                if (this.placeFilterData.length > 0) {
                    this.placeFilterData[0].isSelected = true;
                    this.selectedPlaces = [this.placeFilterData[0]];
                } else {
                    this.selectedPlaces = [];
                }
            })
            .catch((err) => {
                if (err) {
                    console.error(err);
                    this.errorLoggingService.logError(`could not retrieve places for venue ${this.venueId}`, err);
                }
            })
            .finally(() => {
                this.isLoadingPlaces = false;
                this.getChartData();
            });
    }

    getChartData() {
        // Don't retrieve the chart data until places are loaded
        if (this.isLoadingPlaces) {
            return;
        }

        this.isLoadingCharts = true;
        this.fnbReportingService
            .getOrdersReports(this.venueId, this.selectedPlaces, this.params.timeframe, this.params.interval)
            .then((data) => {
                this.ordersPlacedData = data.ordersPlaced;
                this.topItemsData = data.topItemsPurchased;
                this.hasDataError = false;
            })
            .catch((err) => {
                this.errorLoggingService.logError(
                    `could not retrieve reports for customer ${this.$state.params.customerId}`,
                    err
                );
                this.hasDataError = true;
            })
            .finally(() => {
                this.isLoadingCharts = false;
                this.isLoading = false;
            });
    }

    // //////////////////////////////////////////////////////////////////////////////
    // Chart util methods

    tooltipLineFnc(meta, value) {
        // Meta gets JSON.stringified, so it needs to be converted back
        const { data } = JSON.parse(meta.replace(/&quot;/g, '"'));
        return (
            `<div class="cr-stat-title">${data.date}</div>` +
            `<div><b>${this.numberFilter(value, 0)}</b></div>` +
            `<div>${data.currency} ${data.price}</div>`
        );
    }

    tooltipBarFnc(meta, value) {
        const txt = document.createElement('textarea');
        txt.innerHTML = !meta || meta === 'null' ? '0' : meta;
        meta = txt.value;

        return `<div class="cr-stat-title">${meta}</div>` + `<div><b>${this.numberFilter(value, 0)}</b></div>`;
    }

    convertDatetime(time) {
        return time ? time.split('.')[0] : '';
    }

    // //////////////////////////////////////////////////////////////////////////////
    // Form update callbacks

    onUpdateTimeframe(event) {
        this.params.timeframe.start = event.model.start;
        this.params.timeframe.end = event.model.end;
        this.getChartData();
    }

    onUpdateInterval(event) {
        this.params.interval = event.model[0].id;
        this.getChartData();
    }

    onUpdatePlace(event) {
        if (event.model && event.model.length > 0) {
            this.selectedPlaces = event.model;
        } else {
            // Select all places
            this.selectedPlaces = []; // Treated as all places
        }
        this.getChartData();
    }

    onUpdateRange(event) {
        this.params.timeframe.start = this.convertDatetime(event.range.start);
        this.params.timeframe.end = this.convertDatetime(event.range.end);
        this.range = event.range;
        this.getChartData();
    }

    // //////////////////////////////////////////////////////////////////////////////
    // Initialization methods that aren't very interesting

    initFilterOptions() {
        this.placeFilterData = [];

        this.range = {
            start: '',
            end: '',
        };

        this.params = {
            timeframe: {
                start: '',
                end: '',
            },
            interval: 'DAY',
        };

        this.intervalData = [
            { id: 'DAY', label: this.text.byDay, isSelected: true },
            { id: 'WEEK', label: this.text.byWeek },
            { id: 'MONTH', label: this.text.byMonth },
        ];
    }

    initChartOptions() {
        this.chartOptions = {
            axisY: {
                labelInterpolationFnc: (value) => this.numberFilter(value, 0),
            },
            tooltip: {
                tooltipFnc: this.tooltipLineFnc.bind(this),
            },
        };

        this.barChartOptions = {
            axisY: {
                labelInterpolationFnc: (value) => this.numberFilter(value, 0),
            },
            tooltip: {
                tooltipFnc: this.tooltipBarFnc.bind(this),
            },
            rotateLabels: true,
        };
    }
}

export default FoodAndBeverageReporting;
