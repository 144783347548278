import { Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { CrDropdownUpgradedComponentItem, CrDropdownUpgradedEvent } from './dropdown-upgraded.component';

@Component({
  selector: 'cr-dropdown-upgraded',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent implements OnInit, ControlValueAccessor {
  @Input()
  mode: string;

  @Input()
  menuItems: CrDropdownUpgradedComponentItem[];

  @Input()
  hideResetOption?: boolean;

  @Input()
  showStatus?: boolean;

  @Input()
  showSearch?: boolean;

  @Input()
  disableTracking?: boolean;

  @Input()
  label?: string;

  @Input()
  placeholder?: string;

  control: FormControl;

  isDisabled?: boolean;

  onChange: (_?: CrDropdownUpgradedComponentItem) => void = () => {
    //
  };

  onTouched: (_?: unknown) => void = () => { 
    //
  };

  constructor(private ngControl: NgControl) {
    this.ngControl.valueAccessor = this;
  }

  ngOnInit(): void {
    this.control = this.ngControl.control as FormControl;
  }

  onStateUpdate(event: CrDropdownUpgradedEvent): void {
    if (event) {
      this.onChange(event.$event.model[0]);
    }
  }

  registerOnChange(onChange: (_?: unknown) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: (_?:unknown) => void): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(value: CrDropdownUpgradedComponentItem): void {
    this.onChange(value);
  }
}
