<cr-icon class="error-icon" name="error"></cr-icon>

<div class="cr-title">{{ text.whoops }}</div>

<div class="sub-title">{{ text.subtitle }}</div>
<div>
    <span>{{ text.weRecommend }} </span>
    <a href="https://www.google.com/chrome/browser/desktop/" target="_blank">{{
        text.chrome
    }}</a>
</div>
