import Controller from './event-details.controller';
import template from './event-details.html';
import './event-details.less';

const Component = {
    template,
    controller: [
        '$state',
        '$uibModal',
        'crConstants',
        'crEntityService',
        'crLocaleService',
        'crToastService',
        'crErrorLoggingService',
        'crImageService',
        'crNavigationService',
        Controller,
    ],
};

export default Component;
