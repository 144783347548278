import Controller from './title-bar.controller';
import template from './title-bar.html';
import './title-bar.less';

const Component = {
    template,
    transclude: {
        crTitleBarCenter: '?crTitleBarCenter',
    },
    bindings: {
        disabled: '<?',
        iconClass: '@',
        label: '@',
        onClick: '&?',
    },
    controller: ['$state', 'crConstants', Controller],
};

export default Component;
