
import { Inject, Injectable, Injector } from '@angular/core';

@Injectable({
    providedIn: 'root',
  })
export class FileUploadService {
	
	public url: string;

    public Upload: any;

    constructor(@Inject('Upload') Upload: any) {
        this.Upload = Upload;
        this.url = '/rest/file-upload';
    }

    getFileName(src: string) {
        return src ? src.substring(src.lastIndexOf('/') + 1) : '';
    }

    getEntityType(fileName: string) {
        return fileName.substring(fileName.lastIndexOf('.') + 1) || 'unknown-entity';
    }

    upload(fileName: string, dataUrl: string, venueId: string) {
        const folderName = this.getEntityType(fileName);
        return this.Upload.upload({
            url: venueId ? `${this.url}/venue/${venueId}/${folderName}` : `${this.url}/${folderName}`,
            data: {
                file: this.Upload.dataUrltoBlob(dataUrl, fileName),
            },
        });
    }

    getBase64DataUrl(file) {
        return this.Upload.base64DataUrl(file);
    }
}
