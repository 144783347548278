import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GetOrderHistoryParams, OrderHistoryResponse } from './order-history.model';

@Injectable({ providedIn: 'root' })
export class OrderHistoryService {
  private baseUrl = '/rest/commerce-fulfillment/orders';

  constructor(private httpClient: HttpClient) { }

  public getOrderHistory(placeId: string, orderHistoryQuery: GetOrderHistoryParams): Observable<OrderHistoryResponse> {
    return this.httpClient.get<OrderHistoryResponse>(`${this.baseUrl}/fulfillment/${placeId}/history`, { params: orderHistoryQuery as HttpParams });
  }
}
