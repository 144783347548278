import angular from 'angular';
import text from './resources/locale/en.json';

import ChangePasswordModalComponent from './change-password-modal.component';

const ChangePasswordModalModule = angular
    .module('control-room.app.components.change-password-modal', [])
    .constant('crChangePasswordModalText', text)
    .component('crChangePasswordModal', ChangePasswordModalComponent);

export default ChangePasswordModalModule;
