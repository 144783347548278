import angular from 'angular';

import component from './app-settings.component';

import ComponentsModule from './components/components';

const AppSettings = angular
    .module('control-room.app-settings', [ComponentsModule.name])
    .component('crAppSettings', component);

export default AppSettings;
