import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TestMessageRequest, TestMessageResponse } from "../../models/test-messages.model";

@Injectable({
    providedIn: 'root',
})
export class TestMessagesService {
    private url = '/rest/test-messages';
    constructor(private http: HttpClient) {
    }

    sendTestMessages(testMessagesRequest: TestMessageRequest): Promise<HttpResponse<TestMessageResponse>>  {
        return this.http.post<TestMessageResponse>(`${this.url}`, testMessagesRequest, { observe: 'response' }).toPromise();
    }
}
