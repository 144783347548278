class BarGraph {
    $onInit() {
        this.calculateBarStyle();
    }

    $onChanges() {
        this.calculateBarStyle();
    }

    calculateBarStyle() {
        this.barStyle = {
            width: `${((this.value || 0) / (this.max || 0)) * 100}%`,
        };
        if (this.unfilteredValue) {
            this.unfilteredBarStyle = {
                width: `${((this.unfilteredValue || 0) / (this.max || 0)) * 100}%`,
            };
        }
    }
}

export default BarGraph;
