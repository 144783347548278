import angular from 'angular';

import BeaconsListComponent from './beacons-list.component';
import BeaconsListService from './beacons-list.service';

const BeaconsListModule = angular
    .module('control-room.beacons.components.beacons-list', [])
    .component('crBeaconsList', BeaconsListComponent)
    .service('crBeaconsListService', ['crBeaconsService', BeaconsListService]);

export default BeaconsListModule;
