<section class="details-page">
    <cr-title-bar
            class="text-capitalize"
            [label]="title"
            icon="icon-cr-back"
            (click)="this.state.go('client.lifts-trails-activities.list', { tabId: tabId });"
    >
    </cr-title-bar>
    <div class="edit-icon">
        <span
                class="icon-cr-edit"
                (click)="this.state.go('client.lifts-trails-activities.edit', { id: poi.id, type: poi.details.SNOAAType, tabId: tabId });"
        ></span>
    </div>
    <div class="delete-icon">
        <span
                class="icon-cr-delete"
                (click)="openModal(deleteTemplate)"
        ></span>
    </div>
    <cr-data-error
            hasDataError="hasDataError"
            showStandardMessage="true"
            *ngIf="hasDataError"
    ></cr-data-error>
    <div class="text-center spinner" *ngIf="isLoading">
        <cr-spinner></cr-spinner>
    </div>
    <div class="cr-list-item" >
        <div
                class="cr-item-row"
                *ngIf="!isLoading && poi.id && !hasDataError"
        >
            <section>
                <h1>{{ title }}</h1>
                <label>
                    {{text.poiTitle}} : {{ poi.title }}<br>
                    {{text.poiId}} : {{ poi.id }}
                </label>
            </section>

            <section>
                <hr class="details-divider" />
                <cr-section-heading customClass="grey-text">{{ text.status }}</cr-section-heading>
                <div class="detail-item-heading"> {{ text.automaticStatus }} </div>
                <div class="text-capitalize">
                    <span *ngIf="poi.details.autoStatus"> Active </span>
                    <span *ngIf="!poi.details.autoStatus"> Inactive </span>
                </div>
                <div class="detail-item-heading"> {{ text.statusText }} </div>
                <div class="text-capitalize"> {{ poi.details.autoStatus ? '-' : operationStatus?.labelName }} </div>
                <div class="detail-item-heading"> {{ text.status }} </div>
                <div class="text-capitalize"> {{ operationStatus?.status }} </div>
                <div class="detail-item-heading"> {{ text.statusColor }} </div>
                <div>
                    <span class="icon-cr-circle"
                          style="border-color: {{ operationStatus?.color }}"
                          *ngIf="operationStatus?.status?.toLowerCase() === text.open || operationStatus?.status?.toLowerCase() === text.hold"></span>
                    <span class="icon-cr-close"
                          style="color: {{ operationStatus?.color }}"
                          *ngIf="operationStatus?.status?.toLowerCase() === text.closed"></span>
                    <span class="text-capitalize" *ngIf="operationStatus?.color?.toLowerCase() === text.redHexColor"> {{ text.red }} </span>
                    <span class="text-capitalize" *ngIf="operationStatus?.color?.toLowerCase() === text.greenHexColor"> {{ text.green }} </span>
                    <span class="text-capitalize" *ngIf="operationStatus?.color?.toLowerCase() === text.blueHexColor"> {{ text.blue }} </span>
                    <span class="text-capitalize" *ngIf="operationStatus?.color?.toLowerCase() === text.yellowHexColor"> {{ text.yellow }} </span>
                </div>
            </section>

            <section>
                <hr class="details-divider" />
                <cr-section-heading customClass="grey-text"> {{ text.details }} </cr-section-heading>

                <div class="detail-item-heading"
                     *ngIf="poi.details.mountainArea"> {{ text.mountainArea }} </div>
                <div *ngIf="poi.details.mountainArea"> {{ poi.details.mountainArea }} </div>

                <div class="detail-item-heading"
                     *ngIf="poi.details.type || poi.details.trailIcon"> {{ text.icon }} </div>
                <div class="text-capitalize"
                     *ngIf="poi.details.type || poi.details.trailIcon">
                    <div
                            class="sticker-image square-image"
                            [ngStyle]="{'background-image': 'url(\'' + iconSrc + '\')'}"
                    >
                        <div class="cr-flex-center" *ngIf="!iconSrc">
                            <span class="icon-cr-image"></span>
                        </div>
                    </div>

                    {{ poi.details.iconTypeText }} {{ poi.details.trailIconText }}
                </div>

                <div class="detail-item-heading"
                     *ngIf="poi.details.SNOAAType"> {{ text.type }} </div>
                <div class="text-capitalize"
                     *ngIf="poi.details.SNOAAType"> {{ poi.details.SNOAAType }} </div>

                <div class="detail-item-heading"
                     *ngIf="poi.legacyStatus.waitTime"> {{ text.waitTime }} </div>
                <div *ngIf="poi.legacyStatus.waitTime"> {{ poi.legacyStatus.waitTime }} </div>

                <div class="detail-item-heading"
                     *ngIf="poi.details.difficulty"> {{ text.difficulty }} </div>
                <div *ngIf="poi.details.difficulty"> {{ poi.details.difficulty }} </div>

                <div class="detail-item-heading"
                     *ngIf="isTrail"> {{ text.glades }} </div>
                <div *ngIf="isTrail" class="text-capitalize"> {{ poi.details.glades }} </div>

                <div class="detail-item-heading"
                     *ngIf="isTrail"> {{ text.grooming }} </div>
                <div *ngIf="isTrail" class="text-capitalize"> {{ poi.details.grooming }} </div>

                <div class="detail-item-heading"
                     *ngIf="isTrail"> {{ text.moguls }} </div>
                <div *ngIf="isTrail" class="text-capitalize"> {{ poi.details.moguls }} </div>

                <div class="detail-item-heading"
                     *ngIf="isTrail"> {{ text.nightSkiing }} </div>
                <div *ngIf="isTrail" class="text-capitalize"> {{ poi.details.nightSkiing }} </div>

                <div class="detail-item-heading"
                     *ngIf="isTrail"> {{ text.snowMaking }} </div>
                <div *ngIf="isTrail" class="text-capitalize"> {{ poi.details.snowMaking }} </div>
            </section>

            <section *ngIf="!isEmpty(poi.details.groupedHours)">
                <hr class="details-divider" />
                <cr-section-heading customClass="grey-text"> {{ text.hours }} </cr-section-heading>
                <div *ngFor="let hour of poi.details.groupedHours" class="group-hours">
                    <div>
                        <div class="poi-details"> {{ text.days }} </div>
                        <div class="float-left"> {{ hour.daysNotation }} </div>
                    </div>
                    <div>
                        <div class="poi-details"> {{ text.openAt }} </div>
                        <div class="float-left"> {{ hour.open }} </div>
                    </div>
                    <div>
                        <div class="poi-details"> {{ text.closesAt }} </div>
                        <div class="float-left"> {{ hour.close }} </div>
                    </div>
                </div>
            </section>

        </div>
    </div>
</section>

<ng-template #deleteTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">
            <span class="text-uppercase>">
                {{ text.delete }} {{ tabId }}
            </span>
        </h4>
        <button
                type="button"
                class="close pull-right"
                aria-label="Close"
                (click)="modalRef.hide()"
        >
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body">
        {{ text.deleteMessage }} {{ tabId }}?
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="modalRef.hide()">
            {{ text.cancel }}
        </button>
        <button type="button" class="btn btn-danger" (click)=" delete()">
            {{ text.delete }}
        </button>
    </div>
</ng-template>
