import text from './resources/locale/en.json';

import locationTargetUrl from '../../../../commons/basic/resources/images/location_target.svg';

class BeaconDetailsController {
    constructor(
        $state,
        $uibModal,
        crBeaconDetailsService,
        crNavigationService,
        crGraphicalMapService,
        crConstants,
        crToastService,
        crErrorLoggingService
    ) {
        this.$state = $state;
        this.$uibModal = $uibModal;
        this.crBeaconDetailsService = crBeaconDetailsService;
        this.crNavigationService = crNavigationService;
        this.crGraphicalMapService = crGraphicalMapService;
        this.constants = crConstants;
        this.imageConstants = crConstants.image;
        this.crToastService = crToastService;
        this.crErrorLoggingService = crErrorLoggingService;
    }

    $onInit() {
        this.text = text;
        this.hasDataError = false;
        this.isLoading = false;

        this.marker = {
            source: locationTargetUrl,
            w: this.constants.marker.defaultDimensions.w,
            h: this.constants.marker.defaultDimensions.h,
            x: 0,
            y: 0,
        };

        this.markers = [this.marker];
        this.beacon = {};

        // See if we need to show a toast when coming to this page
        this.crToastService.autoToast(this.$state.params);

        this.getData();
    }

    getData() {
        this.isLoading = true;

        this.crBeaconDetailsService
            .getData(this.$state.params.id)
            .then((details) => {
                this.beacon = details;
                this.id = details.id;
                this.name = details.name;
                this.location = details.location;
                this.notes = details.notes;
                this.images = details.images;
                this.radius = details.radius;
                this.major = details.major;
                this.minor = details.minor;
                this.zone = details.zone;
                this.rssi = this.text.units[details.rssi];
                this.uuid = details.uuid;
                this.associatedPoi = details.associatedPoi;
                this.lastChanged = details.lastChanged;

                this.getMapMarkerData([this.location]);
            })
            .catch((err) => {
                this.crErrorLoggingService.logError(
                    'Could not get beacon details',
                    err,
                    `id: ${this.$state.params.id}`
                );

                if (err.status === 404) {
                    this.$state.go('client.404', {
                        venueId: this.$state.params.venueId,
                        customerId: this.$state.params.customerId,
                    });
                } else {
                    this.hasDataError = true;
                }
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    getMapMarkerData(locations) {
        this.mapLoading = true;
        this.mapError = false;

        return this.crGraphicalMapService
            .getMapMarkerData(this.$state.params.venueId, locations)
            .then((res) => {
                this.defaultMapId = res.defaultMapId;
                this.focusRatio = [res.translatedLocations[0].position.x, res.translatedLocations[0].position.y];
                this.mapImageSrc = res.mapImageSrc;
            })
            .catch((err) => {
                this.mapError = true;
                this.crErrorLoggingService.logError(
                    'Could not get graphical map for beacon',
                    err,
                    `id: ${this.$state.params.id}`
                );
            })
            .finally(() => {
                this.mapLoading = false;
            });
    }

    onMapLoad(event) {
        this.canvas = event.canvas;
        this.mapImage = event.mapImage;
        this.focus = [this.focusRatio[0], this.focusRatio[1]];
        this.marker.x = this.focus[0];
        this.marker.y = this.focus[1];
    }

    goBack() {
        this.crNavigationService.goBack('client.beacons.list');
    }

    goToEditPage() {
        this.$state.go('client.beacons.edit', {
            id: this.$state.params.id,
            customerId: this.$state.params.customerId,
        });
    }

    saveCopy() {
        this.$state.go('client.beacons.create', {
            id: this.$state.params.id,
            customerId: this.$state.params.customerId,
        });
    }

    deleteBeacon(beacon) {
        this.$uibModal
            .open({
                backdrop: 'static',
                component: 'crSimpleModal',
                windowClass: 'cr-modal-size-sm',
                resolve: {
                    type: () => 'submit',
                    submitText: () => this.text.delete,
                    message: () => `${this.text.deleteConfirmationMessage} "${beacon.name}"?`,
                },
            })
            .result.then(() =>
                this.crBeaconDetailsService
                    .deleteBeacon(beacon.id)
                    .then(() => {
                        this.goToListPage(`"${beacon.name}" ${this.text.hasBeenDeleted}`);
                    })
                    .catch(() => {
                        this.showErrorModal();
                        this.crErrorLoggingService.logError('Could not delete beacon', beacon);
                    })
            )
            .catch(() => null);
    }

    goToListPage(msg) {
        this.$state.go('client.beacons.list', {
            toast: {
                msg,
            },
        });
    }

    showErrorModal() {
        const message = this.edit ? this.text.updateError : this.text.createError;

        this.$uibModal
            .open({
                backdrop: 'static',
                component: 'crSimpleModal',
                windowClass: 'cr-modal-size-sm',
                resolve: {
                    message: () => message,
                },
            })
            .result.catch(() => null);
    }
}

export default BeaconDetailsController;
