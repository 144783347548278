import _ from 'lodash';
import text from './resources/locale/en.json';

class ExperienceSummary {
    constructor(
        $state,
        crLocaleService,
        crAnalyticsService,
        crScheduleService,
        crConstants,
        crExperienceManageService,
        crConfig
    ) {
        this.$state = $state;
        this.crLocaleService = crLocaleService;
        this.crAnalyticsService = crAnalyticsService;
        this.crScheduleService = crScheduleService;
        this.crConstants = crConstants;
        this.crExperienceManageService = crExperienceManageService;
        this.crConfig = crConfig;

        this.text = text;
    }

    $onInit() {
        this.locale = this.crLocaleService.getLocale(this.$state.params.venueId, this.$state.params.locale);
        this.refreshLocaleStatus();
        this.refreshCouponsMap();
        this.refreshPoi();
        this.refreshMenuItems();
        this.currencyCode = _.find(this.crConfig.customer.venues, { id: this.$state.params.venueId }).currencyCode;
    }

    $onChanges(changes) {
        if (changes.step && (_.isNil(this.farthestStep) || changes.step.currentValue > this.farthestStep)) {
            this.farthestStep = changes.step.currentValue;
        }

        if (changes.experience && changes.experience.currentValue) {
            this.updateTagLists();
            this.when = this.experience.trigger.active ? this.experience.trigger.active.schedules[0] : null;
            this.specificWeekdays = this.getSpecificWeekdays();
            this.isTagsUnsupportedExperience = this.experienceType.userTags.type === 'UNSUPPORTED';
            this.isAlwaysExperience = this.experienceType.active.type === 'ALWAYS';
            this.showSendsDaily =
                this.experienceType.active.type !== 'ONCE' &&
                this.experienceType.active.type !== 'RECURRING_INSTANT' &&
                this.experienceType.active.type !== 'NOW';
            this.showActiveFrom = this.experienceType.active.type !== 'ONCE';

            if (this.when && this.when.recurrenceException) {
                this.exceptions = this.when.recurrenceException
                    .split(',')
                    .sort()
                    .filter((exception) => !this.crScheduleService.isPast(exception));
            } else {
                this.exceptions = null;
            }
        }

        if (changes.coupons) {
            this.refreshCouponsMap();
        }
    }

    refreshCouponsMap() {
        this.couponsMap = {};
        if (this.coupons) {
            this.coupons.forEach((coupon) => {
                this.couponsMap[coupon.id] = coupon;
            });
        }
    }

    refreshPoi() {
        this.poiTags = this.crExperienceManageService.updateTriggerLabels(
            this.experience,
            'trigger.params.poi',
            this.poiDefinitions,
            this.poiCategories,
            'POI',
            'POI_CATEGORY'
        );
    }

    refreshMenuItems() {
        this.menuTags = this.crExperienceManageService.updateTriggerLabels(
            this.experience,
            'trigger.params.menuItems',
            this.menuItems,
            this.menuCategories,
            'MENU_ITEM',
            'MENU_ITEM_CATEGORY'
        );
    }

    updateTagLists() {
        this.eqTags = [];
        this.neqTags = [];

        this.experience.audience.tags
            .filter((tag) => tag.key !== this.crConstants.appUsersTagKey)
            .forEach((tag) => {
                if (tag.key && tag.op === 'EQ') {
                    this.eqTags.push(tag);
                } else if (tag.key && tag.op === 'NEQ') {
                    this.neqTags.push(tag);
                }
            });
    }

    get appDisplayText() {
        if (this.experience) {
            return this.experience.content.onHomeScreen ? this.text.inboxAndHome : this.text.inboxOnly;
        }
        return '';
    }

    getTagText(tag) {
        if (tag && this.tagDefinitions) {
            const tagDefinition = this.tagDefinitions.find((tagDef) => tag.key === tagDef.tagKey);
            const t = `${tagDefinition.title} › `;
            const values = [];

            if (tagDefinition.type === 'DATE' || tagDefinition.type === 'MULTIDATE') {
                const tagDate = tag.values[0].split('T')[0];
                values.push(this.formatDate(tagDate));
            }

            tag.values.forEach((value) => {
                const foundValue = tagDefinition.values.find((tdv) => value === tdv.tagValueKey);
                if (foundValue) {
                    values.push(foundValue.label);
                }
            });

            return `${t}${values.join(', ')}`;
        }
    }

    get hasPoiSelect() {
        return (
            this.experienceType &&
            this.experienceType.additionalFields &&
            !!this.experienceType.additionalFields.find((fields) => fields.key === 'poi-select')
        );
    }

    get poiUserLocation() {
        if (this.experience && this.experience.trigger && this.experience.trigger.params) {
            const id = this.experience.trigger.params.poiId;
            if (id) {
                const poi = this.poiDefinitions.find((poiDef) => poiDef.id === id);
                if (poi) {
                    return poi.name;
                }
            }
        }
        return this.text.worldwide;
    }

    get broadcastUserLocation() {
        const { location } = this.experience.audience;
        if (location.type === 'IN_GEOFENCE') {
            if (this.geofences) {
                const geofence = this.geofences.find((g) => g.id === location.id);
                if (geofence && geofence.label) {
                    return geofence.label;
                }
            }
            return location.id;
        }
        return this.text[location.type] || location.type;
    }

    changeStep(step) {
        this.onStepChange({ step });
    }

    onLocaleUpdate(event) {
        this.locale.current = event.model[0].id;
        this.crAnalyticsService.track('Language Selected', { language: event.model[0].label });
        this.onModelChanged();
    }

    onModelChanged() {
        this.onChanges({ $event: { locale: this.locale } });
    }

    refreshLocaleStatus() {
        if (this.experience) {
            this.locale.list = this.locale.list.map((locale) => {
                let warning = false;
                const { content } = this.experience;

                if (!content.title[locale.id] || !content.body[locale.id]) {
                    warning = true;
                }

                if (this.experience.content.push && !this.experience.content.push[locale.id]) {
                    warning = true;
                }

                const invalidLink = this.experience.content.links.some(
                    (link) =>
                        !link.type ||
                        !link.label[locale.id] ||
                        (!_.isEmpty(link.link) && !_.get(link, `link.${locale.id}`))
                );

                if (invalidLink) {
                    warning = true;
                }

                locale.hasStatusWarning = warning;

                return locale;
            });
        }
    }

    get activeFrom() {
        const instance = this.when.instances[0];
        const startDate = instance.startTime.split('T')[0];
        let activeFrom = this.formatDate(startDate);

        if (this.when.recurrence) {
            // handle UNTIL in recurrence rule
            const untilKey = 'UNTIL=';
            const index = this.when.recurrence.indexOf(untilKey);

            if (index > -1) {
                // '20190102T020030'.length = 15
                const until = this.when.recurrence.substring(index + untilKey.length, index + untilKey.length + 15);

                const year = until.substring(0, 4); // 2019
                const month = until.substring(4, 6); // 01
                const day = until.substring(6, 8); // 02

                activeFrom = `${activeFrom} ${this.text.to} ${month}/${day}/${year}`;
            }
        }

        return activeFrom;
    }

    get activeAt() {
        const instance = this.when.instances[0];
        const startDate = instance.startTime.split('T')[0];
        return this.formatDate(startDate);
    }

    getSpecificWeekdays() {
        if (this.when && this.when.recurrence) {
            const byDayKey = 'BYDAY=';
            const index = this.when.recurrence.indexOf(byDayKey);
            if (index > -1) {
                const endIndex = this.when.recurrence.indexOf(';', index);
                const byDay = this.when.recurrence.substring(index + byDayKey.length, endIndex);

                const days = byDay.split(',').map((day) => this.text.weekdays[day]);

                return days;
            }
        }
    }

    get sendsDaily() {
        if (this.when.scheduleKey && this.when.scheduleKey.toUpperCase() === 'FULLDAY') {
            return this.text.allDay;
        }

        const instance = this.when.instances[0];
        const startTime = instance.startTime.split('T')[1];
        let daily = this.formatTime(startTime);

        if (instance.endTime) {
            const endTime = instance.endTime.split('T')[1];
            daily = `${daily} ${this.text.to} ${this.formatTime(endTime)}`;
        }

        return daily;
    }

    get sendsAt() {
        if (this.when) {
            const instance = this.when.instances[0];
            const startTime = instance.startTime.split('T')[1];
            return this.formatTime(startTime);
        }

        return this.text.experienceActivation;
    }

    get expires() {
        let expires = '';

        switch (this.experience.expires.kind) {
            case 'DURATION': {
                const s = this.experience.expires.secs;
                if (s % 86400 === 0) {
                    // is a number of days
                    expires = `${s / 86400} ${this.text.days}`;
                } else if (s % 3600 === 0) {
                    // is a number of hours
                    expires = `${s / 3600} ${this.text.hours}`;
                } else {
                    // gotta be minutes
                    expires = `${Math.floor(s / 60)} ${this.text.minutes}`;
                }

                expires = `${expires} ${this.text.afterReceipt}`;
                break;
            }
            case 'TIME': {
                const dateTime = this.experience.expires.at;
                const [date, time] = dateTime.split('T');

                expires = `${this.text.on} ${this.formatDate(date)} ${this.text.at} ${this.formatTime(time)}`;
                break;
            }
            case 'DAILY': {
                expires = `${this.formatTime(this.experience.expires.daily)} ${this.text.eachDay}`;
                break;
            }
        }

        return expires;
    }

    get canceledOn() {
        if (this.exceptions) {
            const last = this.exceptions.length - 1;
            let canceledOn = '';

            this.exceptions.forEach((exception, index) => {
                const year = exception.substring(0, 4); // YYYY
                const month = exception.substring(4, 6); // MM
                const day = exception.substring(6, 8); // DD

                canceledOn = `${canceledOn}${month}/${day}/${year}`;
                if (index < last) {
                    canceledOn = `${canceledOn}, `;
                }
            });

            return canceledOn;
        }
        return '';
    }

    formatTime(time) {
        const parts = time.split(':');
        const hour = parseInt(parts[0]);

        parts[0] = hour > 12 ? `${hour - 12}` : `${hour}`;

        if (parts[0] === '0') {
            parts[0] = '12';
        }

        return `${parts[0]}:${parts[1]} ${hour >= 12 ? this.text.pm : this.text.am}`;
    }

    formatDate(date) {
        const parts = date.split('-');

        return `${parts[1]}/${parts[2]}/${parts[0]}`;
    }

    // Minutes should be an integer
    formatPeriodOfTimeMinutes(minutes) {
        const labels = this.experienceType.userBehavior
            .filter((behavior) => behavior.key === 'periodOfTime')
            .flatMap((field) => field.fields)
            .flatMap((field) => field.options)
            .filter((option) => Math.floor(option.value) === Math.floor(minutes))
            .map((option) => option.label);

        if (labels.length >= 1) {
            return labels[0];
        }

        // Fallback
        const mins = Number(minutes);
        if (mins > 60) {
            const hours = Math.floor(mins / 60);
            return `${hours} ${this.text.hours}`;
        }
        return `${mins} ${this.text.minutes}`;
    }

    get hasPoiTags() {
        return this.poiTags && this.poiTags.length && this.poiTags.length > 0;
    }

    get hasMenuTags() {
        return this.menuTags && this.menuTags.length && this.menuTags.length > 0;
    }

    userBehavior(key) {
        for (let i = 0; i < this.experienceType.userBehavior.length; i++) {
            const item = this.experienceType.userBehavior[i];
            const field = _.find(item.fields, { key });
            if (field) {
                return field;
            }
        }
    }
}

export default ExperienceSummary;
