<cr-title-bar icon="icon-cr-mobile" [label]="text.header"> </cr-title-bar>

<div *ngIf="!hasError" class="content">
    <div class="title">{{ text.title }}</div>
    <div class="subtitle">{{ text.subtitle }}</div>
    <div class="list">
        <div class="item">
            <div class="title">{{ text.userReportTitle }}</div>
            <div class="subtitle">{{ text.userReportSubtitle }}</div>
            <div class="bottom">
                <button (click)="generateUserAuditReport()" [disabled]="isLoading" a>{{ text.runNowLabel }}</button>
                <cr-spinner *ngIf="isLoading && loaderFor === 'USER_REPORT'"></cr-spinner>
            </div>
        </div>
        <div class="item">
            <div class="title">{{ text.menuReportTitle }}</div>
            <div class="subtitle">{{ text.menuReportSubtitle }}</div>
            <div class="bottom">
                <button (click)="generateMenuAuditReport()" [disabled]="isLoading" a>{{ text.runNowLabel }}</button>
                <cr-spinner *ngIf="isLoading && loaderFor === 'MENU_REPORT'"></cr-spinner>
            </div>
        </div>
        <div class="item">
            <div class="title">{{ text.placeReport }}</div>
            <div class="subtitle">{{ text.placeReportSubtitle }}</div>
            <div class="bottom">
                <button (click)="generatePlaceReport()" [disabled]="isLoading" a>{{ text.runNowLabel }}</button>
                <cr-spinner *ngIf="isLoading && loaderFor === 'PLACE_REPORT'"></cr-spinner>
            </div>
        </div>
    </div>
</div>
<cr-data-error *ngIf="hasError" [message]="text.genericErrorReport"></cr-data-error>
