import { OnInit, ɵCodegenComponentFactoryResolver } from '@angular/core';
import {
  Component, Input, ViewEncapsulation,
} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { LocalizedText } from '../../../../core';
import { PrintStatuses } from '../../../../shared/enums/print-status';
import { FeesCategory, Order } from '../../../../shared/models/order.model';
import { PrinterService } from '../../../../shared/services/printer/printer.service';
import { ErrorLoggingService} from '../../../../shared';
import { OrderActionsModalComponent } from './orderActionsModal/order-actions-modal.component';
import text from './resources/locale/en.json';

@Component({
  selector: 'cr-fnb-order-receipt',
  templateUrl: './order-receipt.component.html',
  styleUrls: ['./order-receipt.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OrderReceiptComponent implements OnInit{
  @Input() order: Order;

  @Input() isPrintingEnabled: boolean;

  @Input() kitchenId: string;

  modalRef: BsModalRef;

  feesCategory: FeesCategory = {
    taxable: [],
    nonTaxable: []
  };

  text: LocalizedText;

  printChitStatus: PrintStatuses = PrintStatuses.none;
  printRefundStatus: PrintStatuses = PrintStatuses.none;

  constructor(private printerService: PrinterService, private modalService: BsModalService, private logger: ErrorLoggingService) {
    this.text = text as LocalizedText;
  }

  ngOnInit() {
    this.categorizeFees(this.order);
  }

  private categorizeFees(order: Order) {
    this.feesCategory.taxable = order.fees.filter(f => f.taxable);
    this.feesCategory.nonTaxable = order.fees.filter(f => !f.taxable);
  }

  public getCardBrand(order: Order): string {
    const { ewalletType, cardBrand } = order;
    if (ewalletType && ewalletType !== 'NONE' && ewalletType !== 'STOREDPAYMENT') {
      return ewalletType;
    }

    return cardBrand || '';
  }

  public openModal(): void {
    const initialState = {
      order: this.order,
      kitchenId: this.kitchenId,
    };
    this.modalRef = this.modalService.show(OrderActionsModalComponent, { initialState });
    (this.modalRef.content as OrderActionsModalComponent).closeEvent.subscribe((res) => {
      if (res) {
        this.modalRef.hide();
      }
    });
  }

  public printRefund(order: Order): void {
    if (this.printRefundStatus === PrintStatuses.sent) {
      // Don't print if there's one in progress already
      return;
    }

    this.printRefundStatus = PrintStatuses.sent;
    this.printerService
      .printRefund(this.kitchenId, order)
      .then(() => {
        this.printRefundStatus = PrintStatuses.success;
      })
      .catch((err) => {
        this.logger.logError('Print refund receipt error', err);
        this.printRefundStatus = PrintStatuses.fail;
      });
  }

  public printOrder(order: Order): void {
    if (this.printChitStatus === PrintStatuses.sent) {
      // Don't print if there's one in progress already
      return;
    }
    this.printChitStatus = PrintStatuses.sent;
    this.printerService
      .printOrder(this.kitchenId, order)
      .then(() => {
        this.printChitStatus = PrintStatuses.success;
      })
      .catch((err) => {
        this.logger.logError('Print order receipt error', err);
        this.printChitStatus = PrintStatuses.fail;
      });
  }
}
