import ReportsController from './reports.controller';
import template from './reports.html';

const ReportsComponent = {
    template,
    controller: [
        '$state',
        'crCommerceService',
        'crReportsLocale',
        'crChartService',
        'crErrorLoggingService',
        'crDateRangeService',
        ReportsController,
    ],
};

export default ReportsComponent;
