import { Order } from '../models/order.model';

function formatDateTime(date: Date): string {
  const dateOptions = { month: 'short', day: 'numeric', year: 'numeric' };
  const dateText = new Intl.DateTimeFormat(navigator.language, dateOptions).format(date);
  const timeOptions = { hour: 'numeric', minute: 'numeric' };
  const timeText = new Intl.DateTimeFormat(navigator.language, timeOptions).format(date);
  return `${dateText} at ${timeText}`;
}

function formatTime(date: Date): string {
  try {
    const timeOptions = { hour: 'numeric', minute: 'numeric' };
    const timeText = new Intl.DateTimeFormat(navigator.language, timeOptions).format(date);
    return `${timeText}`;
  } catch (e) {
    return '';
  }
}

function patchPartialRefundAmount(formatter: Intl.NumberFormat, order: Order): void {
  const refundAmount = parseFloat(order.paymentAttributes.refundAmount);
  if (order.paymentStatus === 'PARTIAL_REFUND_APPLIED') {
    order.displayOrderRefundAmount = formatter.format(refundAmount);
    order.displayTotalMinusRefund = formatter.format(order.orderTotal - refundAmount);
  }
}

export function patchOrderProductDisplayPrice(order: Order): void {
  const formatter = new Intl.NumberFormat(navigator.language, {
    style: 'currency',
    currency: order.currency || 'USD',
  });
  order.orderReceipt.lineItems
    .filter((li) => li.lineItemType === 'LINE_ITEM_PRODUCT')
    .filter((lineItem) => typeof lineItem.price === 'number')
    .forEach((lineItem) => {
      lineItem.subItems.forEach((product) => {
        const rollUpPrice = product.modifiers.map((m) => m.price).reduce((a, p) => a + p, product.price);
        product.displayPrice = formatter.format(rollUpPrice);
      });
    });
  // Separate the gratuity from the total
  order.orderCheckTotal = order.orderTotal - order.orderGratuity;
  order.displayOrderCheckTotal = formatter.format(order.orderCheckTotal);

  // Order refund details
  order.isFullRefund = order.paymentStatus === 'REFUNDED';
  order.isRefund = order.isFullRefund || order.paymentStatus === 'PARTIAL_REFUND_APPLIED';
  if (order.isFullRefund) {
    order.displayOrderRefundAmount = formatter.format(order.orderTotal);
    order.displayTotalMinusRefund = formatter.format(0);
  }
  if (order.paymentStatus === 'PARTIAL_REFUND_APPLIED') {
    patchPartialRefundAmount(formatter, order);
  }
  if (!order.displayOrderDiscountTotal) {
    order.displayOrderDiscountTotal = formatter.format(order.orderDiscountTotal || 0);
  }
}

export function patchOrderDates(order: Order): void {
  // set front end only variable as false on load
  order.editing = false;
  order._date = new Date(order.lastUpdated);
  order.displayDate = formatDateTime(order._date);
  if (order.estimatedPrepTime) {
    order.displayPrepTime = formatTime(new Date(order.estimatedPrepTime));
  } else {
    // No prep time available, make sure this is null so that the UI knows how to display it
    order.estimatedWaitTime = null;
    order.displayPrepTime = '';
  }
}
