import angular from 'angular';

import CalendarHeadingModule from './calendar-day-heading/calendar-day-heading';
import CalendarEventModule from './calendar-event/calendar-event';
import CalendarDayModule from './calendar-day/calendar-day';
import CalendarAllDayModule from './calendar-all-day/calendar-all-day';
import CalendarAllDayEventModule from './calendar-all-day-event/calendar-all-day-event';

const Components = angular.module('control-room.commons.data.components.calendar.components', [
    CalendarHeadingModule.name,
    CalendarEventModule.name,
    CalendarDayModule.name,
    CalendarAllDayModule.name,
    CalendarAllDayEventModule.name,
]);

export default Components;
