import _ from 'lodash';

const maxLength = 78;
const readonly = false;

class InputController {
    constructor(crConstants) {
        this.regex = crConstants.regex;
    }

    $onInit() {
        this.maxLength = this.maxlength || maxLength;
        this.readonly = this.readonly || readonly;
        this.oldModel = _.cloneDeep(this.model);
    }

    $onChanges(changes) {
        if (changes) {
            this.model = _.cloneDeep(this.model);

            this.oldModel = _.cloneDeep(this.model);
        }
    }

    update(model) {
        if (model !== this.oldModel && this.onUpdate) {
            this.oldModel = _.cloneDeep(model);

            this.onUpdate({
                $event: { model },
            });
        }
    }

    keypress(event) {
        if (this.onKeypress) {
            this.onKeypress({ $event: event });
        }
    }

    change(model) {
        if (model !== this.oldModel && this.onChange) {
            this.oldModel = _.cloneDeep(model);

            this.onChange({
                $event: { model },
            });
        }
    }
}

export default InputController;
