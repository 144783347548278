import moment from 'moment';
import 'moment-timezone';
import _ from 'lodash';

import IntervalTree from 'node-interval-tree';

class CalendarService {
    constructor($state, crConstants, crVenueService, crErrorLoggingService) {
        this.$state = $state;
        this.crConstants = crConstants;
        this.crVenueService = crVenueService;
        this.crErrorLoggingService = crErrorLoggingService;
    }

    getCalendarModel(events, range, hourInPixels, locationMap) {
        const allDay = [];
        const days = this.getDaysModel(range);

        events.forEach((event) => {
            const schedule = event.scheduleInstance;

            if (moment(schedule.start).isSame(schedule.end)) {
                this.crErrorLoggingService.logError('Invalid Calendar Event', null, { event });
                return;
            }

            if (this.isDayOrMore(schedule)) {
                if (event.type === 'experience' && locationMap) {
                    const map = locationMap[event.metadata.locationType];
                    if (event.metadata.locationType === 'POI') {
                        event.metadata.locationLabel = map[event.metadata.locationId];
                    } else {
                        event.metadata.locationLabel = map;
                    }
                }

                allDay.push(event);
            } else if (this.scheduleEndsSameDay(schedule)) {
                this.addEventToDay(days, this.getEventModel(event, hourInPixels, locationMap), schedule);
            } else {
                this.splitEvent(days, event, hourInPixels, locationMap);
            }
        });

        this.handleOverlaps(days);
        this.handleDSTTransition(range, days, hourInPixels);

        return {
            allDay,
            days,
        };
    }

    getDaysModel(range) {
        const days = [];
        const dayCount = moment(range.end).diff(range.start, 'days');
        const tz = this.crVenueService.getTimezone(this.$state.params.venueId).name;

        for (let i = 0; i < dayCount; i++) {
            days.push({
                start: moment(range.start).tz(tz).startOf('day').add(i, 'days').toISOString(),
                end: moment(range.start)
                    .tz(tz)
                    .startOf('day')
                    .add(i + 1, 'days')
                    .toISOString(),
                events: [],
            });
        }

        return days;
    }

    getEventModel(event, hourInPixels, locationMap, overrideSchedule = {}) {
        const pixelRatio = 60 / hourInPixels;
        const borderOffset = 1;
        const layoutSchedule = {
            start: overrideSchedule.start || event.scheduleInstance.start,
            end: overrideSchedule.end || event.scheduleInstance.end,
        };
        const tz = this.crVenueService.getTimezone(this.$state.params.venueId).name;
        const midnight = moment(layoutSchedule.start).tz(tz).startOf('day');
        const eventStart = moment(layoutSchedule.start);
        const eventEnd = moment(layoutSchedule.end);
        const minutesFromMidnight = eventStart.diff(midnight, 'minute');

        const model = {
            type: event.type,
            metadata: event.metadata,
            top: minutesFromMidnight / pixelRatio + borderOffset,
            height: eventEnd.diff(eventStart, 'minute') / pixelRatio - borderOffset,
            left: 0,
            width: 100,
            zIndex: 100,
            start: layoutSchedule.start,
            end: layoutSchedule.end,
            inactive: event.type === 'experience' && event.metadata.status === this.crConstants.entity.states.INACTIVE,
        };

        model.metadata.schedule = event.scheduleInstance;

        if (event.type === 'experience' && locationMap) {
            if (event.metadata.locationType === 'POI') {
                event.metadata.locationLabel = locationMap[event.metadata.locationType][event.metadata.locationId];
            } else {
                event.metadata.locationLabel = locationMap[event.metadata.locationType];
            }
        }

        // set min height for events
        if (model.height < hourInPixels / 2) {
            model.height = hourInPixels / 2;
        }

        return model;
    }

    handleOverlaps(days) {
        let events = [];
        const intervalTree = new IntervalTree();

        days.forEach((day) => {
            if (day.events.length < 2) {
                return;
            }

            day.events.forEach((event) => {
                const start = moment(event.start).valueOf();
                const end = moment(event.end).subtract(1, 'millisecond').valueOf();
                try {
                    intervalTree.insert(start, end, event);
                    events.push(event);
                } catch (e) {
                    this.crErrorLoggingService.logError('Invalid Calendar Event', e, { event });
                }
            });

            // strip out invalid events
            day.events = events;
            events = [];

            this.positionOverlapGroups(day.events, intervalTree);
        });
    }

    positionOverlapGroups(events, intervalTree) {
        const overlapPercent = 5;
        events.forEach((event) => {
            const start = moment(event.start).valueOf();
            const end = moment(event.end).subtract(1, 'millisecond').valueOf();
            const overlaps = _.orderBy(intervalTree.search(start, end), ['start', 'height'], ['asc', 'desc']);
            const multiplier = 100 / overlaps.length;

            if (overlaps.length > 1) {
                overlaps.forEach((overLapEvent, index) => {
                    overLapEvent.left = index * multiplier;
                    overLapEvent.width = multiplier + overlapPercent;
                    overLapEvent.zIndex -= 1;
                });

                _.last(overlaps).width = multiplier;
            }
        });
    }

    handleDSTTransition(range, days, hourInPixels) {
        const tz = this.crVenueService.getTimezone(this.$state.params.venueId).name;
        if (moment(range.start).tz(tz).utcOffset() !== moment(range.end).tz(tz).utcOffset()) {
            days.some((day) => {
                if (moment(day.start).tz(tz).utcOffset() !== moment(day.end).tz(tz).utcOffset()) {
                    day.events.forEach((event) => {
                        const offset = moment(day.start).tz(tz).utcOffset() - moment(event.end).tz(tz).utcOffset();
                        const pixelRatio = 60 / hourInPixels;
                        if (offset !== 0) {
                            event.top -= offset / pixelRatio;
                        }
                    });

                    return true;
                }
            });
        }
    }

    addEventToDay(days, event) {
        days.some((day) => {
            if (moment(event.start).isSameOrAfter(day.start) && moment(event.end).isSameOrBefore(day.end)) {
                day.events.push(event);
                return true;
            }
        });
    }

    splitEvent(days, event, hourInPixels, locationMap) {
        const tz = this.crVenueService.getTimezone(this.$state.params.venueId).name;
        const firstPartOverrideSchedule = {
            start: event.scheduleInstance.start,
            end: moment(event.scheduleInstance.start).tz(tz).endOf('day').toISOString(),
        };
        const secondPartOverrideSchedule = {
            start: moment(event.scheduleInstance.end).tz(tz).startOf('day').toISOString(),
            end: event.scheduleInstance.end,
        };

        this.addEventToDay(days, this.getEventModel(event, hourInPixels, locationMap, firstPartOverrideSchedule));
        this.addEventToDay(days, this.getEventModel(event, hourInPixels, locationMap, secondPartOverrideSchedule));
    }

    isDayOrMore(schedule) {
        const tz = this.crVenueService.getTimezone(this.$state.params.venueId).name;
        const start = moment(schedule.start).tz(tz);
        const end = moment(schedule.end).tz(tz);

        let dayInMinutes = 1440;

        // when dst starts, the transition day is 23 hours
        if (start.utcOffset() < end.utcOffset()) {
            dayInMinutes -= end.diff(start, 'minutes');
        }

        return moment(schedule.end).diff(schedule.start, 'minutes') >= dayInMinutes;
    }

    scheduleEndsSameDay(schedule) {
        const tz = this.crVenueService.getTimezone(this.$state.params.venueId).name;
        const start = moment(schedule.start).tz(tz);
        const end = moment(schedule.end).tz(tz).subtract(1, 'millisecond');
        return start.isSame(end, 'day');
    }
}

export default CalendarService;
