class RowManagerRowController {
    constructor($scope) {
        this.$scope = $scope;
    }

    $onInit() {
        this.DATA_EVENT = 'data';
        this.rowId = this.crRowManager.getRowId();
    }

    $onChanges(changes) {
        if (changes && changes.rowData && changes.rowData.currentValue) {
            this.$scope.$broadcast(this.DATA_EVENT, this.rowData);
        }
    }

    removeRow() {
        this.crRowManager.removeRow(this.index);
    }

    update() {
        this.crRowManager.update(this.crRowManager.rowsData);
    }
}

export default RowManagerRowController;
