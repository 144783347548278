import _ from 'lodash';
import angular from 'angular';
import template from './row-builder-legacy.html';
import './row-builder-legacy.less';
import text from './resources/locale/en.json';

class RowBuilderLegacyDirective {
    constructor($compile) {
        this.restrict = 'E';
        this.template = template;
        this.$compile = $compile;
        this.scope = {
            disabled: '@',
            label: '@',
            addText: '@',
            onChange: '&?',
            rows: '<',
            showSearch: '<?',
            typeList: '<',
            type: '@',
        };
    }

    static directiveFactory($compile) {
        return new RowBuilderLegacyDirective($compile);
    }

    compile() {
        const _this = new RowBuilderLegacyDirective(this.$compile);

        return this.link.bind(_this);
    }

    link(scope, element) {
        this.element = element;
        this.currKey = 0;
        this.scope = scope;

        scope.rows = scope.rows || [];

        scope.rows.forEach((row) => {
            this.currKey += 1;
            row.key = this.currKey;
        });

        scope.$ctrl = scope;
        scope.state = {
            valid: true,
            rows: scope.rows || [],
        };
        scope.onChange = scope.onChange || (() => null);
        scope.addText = scope.addText || text.addText;
        scope.insertRow = this.insertRow.bind(this);
        scope.onRowChange = this.onRowChange.bind(this);
        scope.onRowShift = this.onRowShift.bind(this);
        scope.onRowRemove = this.onRowRemove.bind(this);
        scope.onSelect = this.onSelect.bind(this);

        this.refreshRows(true);
    }

    insertRow(type, state) {
        state = state || {};

        if (!this.scope.state.valid) {
            return;
        }

        const key = this.currKey;
        this.currKey += 1;

        this.scope.state.rows.push({
            type,
            valid: false,
            key,
            state,
        });

        this.refreshRows();
    }

    onSelect($event) {
        const selectItem = $event.model[0];

        this.insertRow(selectItem.type, selectItem.state);
    }

    onRowChange(key, state, valid, initialization) {
        const row = _.find(this.scope.state.rows, { key });

        row.valid = valid;
        row.state = state;

        this.setValidity();

        this.scope.onChange({ state: this.scope.state, initialization });
    }

    onRowRemove(key) {
        const index = _.findIndex(this.scope.state.rows, { key });

        this.scope.state.rows.splice(index, 1);

        this.setValidity();

        this.refreshRows();
    }

    onRowShift(moveKey, positionKey, top) {
        const moveIndex = _.findIndex(this.scope.state.rows, { key: moveKey });
        const positionIndex = _.findIndex(this.scope.state.rows, { key: positionKey });

        const after = positionIndex > moveIndex ? -1 : 0;

        top = top ? 0 : 1;

        const move = this.scope.state.rows.splice(moveIndex, 1)[0];
        this.scope.state.rows.splice(positionIndex + top + after, 0, move);

        this.refreshRows();
    }

    setValidity() {
        this.scope.state.valid = this.scope.state.rows.reduce((prev, curr) => prev && curr.valid, true);
    }

    refreshRows(initialization) {
        const newElement = angular.element(template);

        const rows = newElement[0].querySelector('.cr-rows');

        this.scope.state.rows.forEach((row, i) => {
            const element = document.createElement(row.type);

            element.setAttribute('disabled', '$ctrl.disabled');
            element.setAttribute('change', '$ctrl.onRowChange(key, state, valid, initialization)');
            element.setAttribute('row-shift', '$ctrl.onRowShift(moveKey, positionKey, top)');
            element.setAttribute('remove', '$ctrl.onRowRemove(key)');
            element.setAttribute('parent-state', '$ctrl.state');
            element.setAttribute('state', `$ctrl.state.rows[${i}].state`);
            element.setAttribute('key', row.key);

            rows.appendChild(element);
        });

        const oldElement = this.element;

        this.element = this.$compile(newElement)(this.scope);

        oldElement.replaceWith(newElement);

        this.setValidity();

        this.scope.onChange({ state: this.scope.state, initialization });
    }
}

RowBuilderLegacyDirective.directiveFactory.$inject = ['$compile'];

export default RowBuilderLegacyDirective;
