import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Config } from '../../../core/config';
import { LocalizationByIdResponse, LocalizedTagDefinition } from '../../models/entity-localization.model';
import { EntityType } from '../../models/entity.model';
import { CR_CONFIG } from '../config/cr-config';
import { EntityService } from '../entity/entity.service';
import { LocalizationService } from '../localization/localization.service';

@Injectable({
    providedIn: 'root',
})
export class QuestionService extends EntityService {

    private type = EntityType.TAG_DEFINITIONS;

    constructor(http: HttpClient, 
                localizationService: LocalizationService,
                @Inject(CR_CONFIG) config: Config) 
    {
        super(http, localizationService, config);
    }

    public getQuestion(tagKey: string, venueId: string, locale: string): Promise<Record<string, unknown>> {
        return this.getEntity(this.type, tagKey, venueId, locale, this.type);
    }

    public getQuestionLocalization(entityType: EntityType,
                                   tagKey: string,
                                   venueId: string,
                                   data: { values: Record<string, unknown>[] } & Record<string, unknown>
                                   ): Promise<Record<string, unknown> | void> {
        venueId = venueId || 'default';
        if (this.config.customer.localizationEnabled && this.localizationService.isLocalized(entityType)) {
            return new Promise((resolve, reject) => {
                this.localizationService
                    .getEntityLocalization(entityType, { tagKey, venueId })
                    .then((res) => {
                        const backfilledLocalization = this.backfillLocalization(res, data);
                        resolve(backfilledLocalization);
                    })
                    .catch((err: HttpErrorResponse) => {
                        if (err.status === 404) {
                            resolve(data ? this.getDefaultLocalization(data, venueId) : {});
                        } else {
                            reject();
                        }
                    });
            });
        }
        return Promise.resolve();
    }

    public getLocalization(entityType: EntityType,
                                   tagKey: string,
                                   venueId: string
                                   ): Promise<LocalizationByIdResponse> {
        venueId = venueId || 'default';
        if (this.config.customer.localizationEnabled && this.localizationService.isLocalized(entityType)) {
            return new Promise((resolve, reject) => {
                this.localizationService
                    .getEntityLocalization(entityType, { tagKey, venueId })
                    .then((res) => {
                        const backfilledLocalization = this.backfillLocalization(res, null);
                        resolve(backfilledLocalization);
                    })
                    .catch((err: HttpErrorResponse) => {
                        if (err.status === 404) {
                            resolve({});
                        } else {
                            reject();
                        }
                    });
            });
        }
        return Promise.resolve({});
    }

    get isLocalizationEnabled(): boolean {
        return this.config.customer.localizationEnabled && this.localizationService.isLocalized(this.type);
    }

    public getDefaultLocalization(data: Record<string, unknown>, venueId: string): LocalizedTagDefinition {
        if (this.localizationService.isLocalized(this.type)) {
            return this.localizationService.getQuestionLocalizationDefaultModel(data, venueId);
        }
    }

    public createQuestion(payload: { localization?: Record<string, unknown> } & Record<string, unknown>, venueId: string): Promise<Record<string, unknown>> {
        return this.http.post<{ id: string } & Record<string, unknown>>(`/rest/${this.type}`, payload).toPromise().then((res) => {
            if (this.config.customer.localizationEnabled) {
                venueId = venueId || 'default';
                return this.localizationService
                    .updateEntityLocalization(this.type, payload.localization, { tagKey: res.id, venueId })
                    .then(() => res);
            }
            return res;
        });
    }

    public updateQuestion(tagKey: string, payload: { localization: Record<string, unknown> } & Record<string, unknown>, venueId: string): Promise<Record<string, unknown>> {
        let params = {};
        if (venueId) {
            params = { venueId };
        }
        return this.http.put<Record<string, unknown>>(`/rest/${this.type}/${tagKey}`, payload, { params }).toPromise().then((res) => {
            if (this.config.customer.localizationEnabled) {
                venueId = venueId || 'default';
                return this.localizationService
                    .updateEntityLocalization(this.type, payload.localization, { tagKey, venueId })
                    .then(() => res);
            }
            return res;
        });
    }

    public deleteQuestion(tagKey: string, venueId: string): Promise<Record<string, unknown>> {
        return super.deleteEntity(this.type, tagKey, venueId);
    }

    private backfillLocalization(localization: LocalizationByIdResponse, data: { values: Record<string, unknown>[] } & Record<string, unknown>) {
        if (!data) {
            return localization;
        }

        data.values.forEach((value) => {
            Object.keys(localization).forEach((locale) => {
                localization[locale].values = localization[locale].values || [];
                const valueLocalization = localization[locale].values.find((vl) => vl.tagValueKey === value.tagValueKey);

                if (!valueLocalization) {
                    localization[locale].values.push({ tagValueKey: value.tagValueKey, label: '' });
                }
            });
        });

        return localization;
    }
}
