import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { ChipComponent } from './chip.component';

@NgModule({
  imports: [BrowserModule],
  exports: [ChipComponent],
  declarations: [ChipComponent],
  providers: [],
})
export class ChipModule { }
