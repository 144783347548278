import { Component, Inject, OnInit } from "@angular/core";
import { EventTabsService } from "../../../../shared/services/event-tabs/event-tabs.service";
import { EventTabs } from "../../../../shared/services/event-tabs/event-tabs.model";
import { CrTableOptions } from "../../../../shared/table/table.model";
import { ErrorLoggingService, NavigationService } from "../../../../shared";

@Component({
    selector: 'cr-event-tabs-list',
    templateUrl: './event-tabs-list.component.html',
    styleUrls: ['./event-tabs-list.component.scss']
})
export class EventTabsListComponent implements OnInit {

    crTableOptions: CrTableOptions<EventTabs> = {
        data: undefined,
        columns: undefined,
        emptyStateText: 'There are currently no tabs configured.'
    }

    eventTabs: EventTabs[];

    loading = false;

    label = 'Event tab navigation';
    
    constructor(
        private navigationService: NavigationService, 
        private eventTabNavigationService: EventTabsService,
        private logger: ErrorLoggingService) { }

    ngOnInit() {
        this.generateColumns();
        this.getEventTabs();
    }

    async getEventTabs() {
        this.loading = true;
        try {
            const res = await this.eventTabNavigationService.getEventTabs();
            if (res) {
                this.eventTabs = res;
                this.crTableOptions.data = this.eventTabs;
            }
        }
        catch (err) {
            this.handleError(err);
        }
        this.loading = false;
    }

    private generateColumns() {
        this.crTableOptions.columns = [
            {
                width: 20,
                displayName: '',
                key: '',
                customRowStyles: { 'padding-left': '16px' },
            },
            {
                width: 200,
                displayName: 'Display order',
                key: 'displayOrder',
            },
            {
                width: 200,
                displayName: 'Name',
                key: 'name',
            } ];
    }

    handleError(err: Error) {
        this.logger.logError('event tabs list get data', err);
    }

    onBack() {
        this.navigationService.goBack(`client.events.list`);
    }

    goToEditPage() {
        this.navigationService.goBack(`client.events.event-tabs-manage`);
    }
}