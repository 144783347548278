<button
    type="button"
    class="btn btn-link export-button"
    *ngIf="!exporting"
    [tooltip]="text.exportAll"
    placement="left"
    (click)="doExport()"
>
    <span class="icon-cr-export"></span>
</button>

<cr-spinner *ngIf="exporting" small="true"></cr-spinner>
