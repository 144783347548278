import template from './pdf-uploader.html';
import './pdf-uploader.less';
import Controller from './pdf-uploader.controller';

const Component = {
    template,
    require: {
        form: '^form',
    },
    bindings: {
        name: '@',
        crRequired: '<?',
        pdfLink: '<',
        onChange: '&',
        label: '@',
    },
    controller: ['$sce', 'crFileUploadService', 'crConstants', '$uibModal', Controller],
};

export default Component;
