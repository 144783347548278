import { Component, OnInit } from '@angular/core';
import { ErrorLoggingService } from '../../shared';
import { LocalizedText } from '../../core';

import text from './resources/locale/en.json';

@Component({
  selector: 'cr-legacy-browser',
  templateUrl: './legacy-browser.component.html',
  styleUrls: ['./legacy-browser.component.scss'],
})
export class LegacyBrowserComponent implements OnInit {
  text: LocalizedText;

  constructor(private errorLoggingService: ErrorLoggingService) {
    this.text = text;
  }

  ngOnInit() {
    this.errorLoggingService.logError('legacyBrowser');
  }
}
