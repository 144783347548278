import controller from './cta-button-details.controller';
import template from './cta-button-details.html';

const component = {
    template,
    bindings: {
        entity: '<',
        locale: '<',
        menus: '<',
    },
    controller: [controller],
};

export default component;
