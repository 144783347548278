import Controller from './event-manage.controller';
import template from './event-manage.html';
import './event-manage.less';

const Component = {
    template,
    bindings: {
        edit: '<?',
    },
    controller: [
        '$q',
        '$state',
        '$uibModal',
        'crConstants',
        'crEntityService',
        'crLocaleService',
        'crNavigationService',
        'crScheduleService',
        'crErrorLoggingService',
        'crAnalyticsService',
        'crEventTabsService',
        Controller,
    ],
};

export default Component;
