import _ from 'lodash';
import text from './resources/locale/en.json';
import ManageCategoryController from '../../../../commons/content/components/entity-categories/components/manage-category/manage-category.controller';
import { menuRoutes } from '../../menus.routes';

class MenuCategoryManageController extends ManageCategoryController {
    constructor(
        $q,
        $state,
        $uibModal,
        crConstants,
        crConfig,
        crEntityService,
        crLocaleService,
        crImageService,
        navigationService,
        errorLoggingService,
        crAnalyticsService
    ) {
        super(
            $q,
            $state,
            $uibModal,
            crConstants,
            crConfig,
            crEntityService,
            crLocaleService,
            crImageService,
            navigationService,
            errorLoggingService,
            crAnalyticsService
        );

        this.entityType = 'menu-categories';
        this.key = 'displayName';
    }

    $onInit() {
        super.$onInit();
        this.text = Object.assign(this.text, text);
        this.id = this.$state.params.id;

        const menus = this.crConstants.entity.types.MENUS;
        const menuItems = this.crConstants.entity.types.MENU_PRODUCTS;
        const associatedItemsRoute = `${menus}/${this.$state.params.menuId}/${menuItems}`;

        const params = {
            category: this.$state.params.id,
            venueId: this.$state.params.venueId,
            placeId: this.$state.params.placeId,
            sortby: 'displayName',
            sort: 'asc',
            all: true,
        };

        this.categoryOptionValues = {
            menuCategory: 1,
            printerCategory: 2,
            notSelected: 0
        }
        
        this.categoryDisplayOptions = [
            {
                label: this.text.menuCategory,
                value: this.categoryOptionValues.menuCategory,
            },
            {
                label: this.text.printerCategory,
                value: this.categoryOptionValues.printerCategory,
            },
        ];

        this.crEntityService
            .getEntityList(associatedItemsRoute, params)
            .then((data) => {
                this.associatedItems = data.content;
            })
            .catch((err) => {
                this.errorLoggingService.logError('Could not get associated items for category', err, this.id);
            });
    }

    addNewCategory() {
        super.save(true).then(() => {
            this.$onInit();
            this.form.$setPristine();
        });
    }

    deleteCategory() {
        if (_.size(this.associatedItems) > 0) {
            this.$uibModal.open({
                backdrop: 'static',
                component: 'crSimpleModal',
                windowClass: 'cr-modal-size-md',
                resolve: {
                    message: () => this.text.cannotDelete,
                    type: () => this.crConstants.modalTypes.CANCEL,
                },
            });
        } else {
            super.deleteCategory();
        }
    }


    onCategoryDisplayChange(value) {
        if(value === this.categoryOptionValues.printerCategory) {
            this.category.categoryGroup = 'PRINTER';
        }
        else {
            delete this.category.categoryGroup;
        }
        this.selectedCategory = value;
    }

    goToItem(id) {
        this.$state.go(menuRoutes.PRODUCT_DETAILS, {
            id,
            menuId: this.$state.params.menuId,
            placeId: this.$state.params.placeId,
        });
    }

    goBack() {
        this.navigationService.goBack(menuRoutes.CATEGORIES, {
            menuId: this.$state.params.menuId,
            placeId: this.$state.params.placeId,
        });
    }
}

export default MenuCategoryManageController;
