import _ from 'lodash';
import {
  Component, Input, OnChanges, SimpleChanges,
} from '@angular/core';

import { LocalizedText } from '../../../core/index.js';
import { ImageService } from '../../../ng1-factories';

import text from './resources/locale/en.json';

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
// eslint-disable-next-line @typescript-eslint/no-var-requires
const iconPlaceHolderSrc = require('../../../../assets/images/img_placeholder@2x.png');

@Component({
  selector: 'cr-featured-events-phone-preview',
  templateUrl: './featured-events-phone-preview.component.html',
  styleUrls: ['./featured-events-phone-preview.component.scss'],
})
export class FeaturedEventsPhonePreviewComponent implements OnChanges {
  @Input() featuredEvents: any;

  featuredImageSrc: string;

  featuredTitle: string;

  showFeatured: boolean;

  showTrending: boolean;

  text: LocalizedText;

  constructor(private imageService: ImageService) {
    this.text = text;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.featuredEvents.currentValue && this.featuredEvents) {
      this.featuredEvents = _.cloneDeep(this.featuredEvents);
      const { featured } = this.featuredEvents;
      this.showFeatured = featured && !_.isEmpty(featured[0]);
      if (this.featuredEvents.trending) {
        this.featuredEvents.trending = this.featuredEvents.trending.filter((item) => item.id);
        this.showTrending = this.featuredEvents.trending.length;
      }

      if (this.showFeatured) {
        this.featuredImageSrc = this.getFeaturedImage(featured[0].images);
        this.featuredTitle = this.featuredEvents.featured[0].title;
      }

      if (this.showTrending) {
        this.featuredEvents.trending.forEach((item) => {
          if (!_.isEmpty(item.associatedPois)) {
            item.locationText = item.associatedPois.length === 1 ? item.associatedPois[0].label : this.text.multiple;
          }
          item.iconSrc = !_.isEmpty(item.images) ? this.getIconSrc(item.images) : iconPlaceHolderSrc;
        });
      }
    }
  }

  getFeaturedImage(images) {
    let featuredImage = iconPlaceHolderSrc;
    if (!_.isEmpty(images)) {
      featuredImage = this.imageService.getImageSrc(_.find(images, { type: 'MAIN' }), 110, 73);
    }

    return featuredImage;
  }

  getIconSrc(images) {
    const icon = _.find(images, { type: 'ICON' }) || _.find(images, { type: 'MAIN' });
    return !icon ? iconPlaceHolderSrc : this.imageService.getIconSrc(icon.src);
  }
}
