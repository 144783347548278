import angular from 'angular';

import PreferencePhonePreviewComponent from './preferences-phone-preview.component';

import ComponentsModule from './components/components';

const PreferencesPhonePreview = angular
    .module('control-room.app-settings.components.preferences-phone-preview', [ComponentsModule.name])
    .component('crPreferencesPhonePreview', PreferencePhonePreviewComponent);

export default PreferencesPhonePreview;
