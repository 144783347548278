import * as _ from 'lodash';
import { Component, Input } from '@angular/core';

import { LocalizedText } from '../../../../core';

import text from './resources/locale/en.json';

@Component({
  selector: 'cr-live-map-popup',
  templateUrl: './live-map-popup.component.html',
  styleUrls: ['./live-map-popup.component.scss'],
})
export class LiveMapPopupComponent {
  @Input() isLoading: boolean;

  @Input() properties: any;

  text: LocalizedText;

  constructor() {
    this.text = text;
  }

  get displayProps() {
    if (_.isString(this.properties.displayProps)) {
      return JSON.parse(this.properties.displayProps);
    }
    return this.properties.displayProps;
  }

  formatValues(values: string[]) {
    return values.reduce((formattedValues, value) => `${formattedValues}, ${value}`);
  }
}
