import controller from './menus.controller';
import template from './menus.html';
import './menus.less';

const component = {
    template,
    bindings: {},
    controller: [
        '$state',
        'crConstants',
        'crAnalyticsService',
        'crNavigationService',
        'crMenusService',
        'crLocaleService',
        'crFeatureFlagsService',
        controller,
    ],
};

export default component;
