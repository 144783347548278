import template from './spinner.html';
import './spinner.less';

const SpinnerComponent = {
    template,
    bindings: {
        customClass: '@?',
        showBackground: '<?',
        smallSpinner: '<?',
    },
};

export default SpinnerComponent;
