import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ClosePlaceWithPtw, PTWCapacity, PTWCapacityOverride } from "./ptw.model";


@Injectable({
    providedIn: 'root'
})
export class PTWService {
    constructor(private http: HttpClient){}

    public getPTWByPlace(placeId: string): Observable<PTWCapacity[]> {
        return this.http.get<PTWCapacity[]>(`/rest/ptw/${placeId}/capacity`)
    }

    public overridePTWbyPlace(placeId: string, ptwOverride: PTWCapacityOverride[]): Observable<PTWCapacity[]> {
        return this.http.post<PTWCapacity[]>(`/rest/ptw/${placeId}/capacity`, ptwOverride)
    }

    public closePlaceWithPtw(placeId: string, closePlaceWithPtwDto: ClosePlaceWithPtw): Observable<ClosePlaceWithPtw> {
        return this.http.post<ClosePlaceWithPtw>(`/rest/ptw/closure/${placeId}`,closePlaceWithPtwDto)
    }

}