import {
  Component, Input, OnInit, ViewChild,
} from '@angular/core';
import { StateService } from '@uirouter/core';

import {
  AbstractControl,
  FormArray, FormControl, FormGroup, NgForm, Validators,
} from '@angular/forms';

import { catchError, finalize } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  NavigationService,
  ErrorLoggingService,
} from '../../../../shared';

import text from './resources/locale/en.json';
import { LocalizedText } from '../../../../core';
import { ConfigurationService } from '../../../../shared/services/configuration/configuration.service';
import { FormErrorHelper } from '../../../../shared/helpers/form-error-helper';
import { BundleGroupsService } from '../../../../shared/services/bundle-groups/bundle-groups.service';
import { BundleGroupBundlesProduct, BundleGroupListData } from '../../../../shared/services/bundle-groups/bundle-groups.model';
import { ProductsService } from '../../../../shared/services/products/products.service';
import { ProductsList } from '../../../../shared/services/products/product.model';
import { menuRoutes } from '../../menus.routes';

@Component({
  selector: 'cr-bundle-groups-manage',
  templateUrl: './bundle-groups-manage.component.html',
  styleUrls: ['./bundle-groups-manage.component.scss'],
})

export class BundleGroupsManageComponent implements OnInit {
  @ViewChild('bundleViewChild', { static: false }) bundleViewChild: NgForm;

  text: LocalizedText;

  title = '';

  bundleGroup: BundleGroupListData = null;

  bundleGroupForm: FormGroup;

  bundleGroupItemsDefaultSelectionIndex = -1;

  isLoading = false;

  isSaving = false;

  hasDataError = false;

  showStandardMessage = false;

  requiredItems: any = {};

  entity = { min: 0, max: 20 };

  customClass = '';

  iconOverride = '';

  bundleGroupArrayIndex = 0;

  checkedSelection = 0;

  menuItems: Array<{ label: string, value: any }> = [];

  // incorrectFormat: boolean = false;
  sumIsLessThanRequired = false;

  constructor(
    private state: StateService,
    private bundleService: BundleGroupsService,
    private navigationService: NavigationService,
    private logger: ErrorLoggingService,
    private productsService: ProductsService,
    private configurationService: ConfigurationService,
  ) {
    this.text = text as LocalizedText;
  }

  ngOnInit(): void {
    const { placeId, menuId, bundleGroupId } = this.state.params;
    // Get the Bundle group items here
    if (this.isEdit) {
      this.loadBundleGroup(placeId, menuId, bundleGroupId);
    }

    this.getProducts(menuId, placeId);
    this.title = this.isEdit ? this.text.editItem : this.text.createItem;
    this.bundleGroupForm = new FormGroup({
      displayName: new FormControl(null, [Validators.required, Validators.maxLength(25)]),
      menuItemsQuantity: new FormControl(null, [Validators.required, Validators.min(2)]),
      bundledProducts: new FormArray([null, null].map((bGI) => this.getReorderItemFormGroup(bGI))),
    });
  }

  get isEdit(): boolean { return this.state.params.action === 'edit'; }

  getProducts(menuId: string, placeId: string) {
    this.productsService.getProductsList(menuId, placeId)
      .subscribe((data: ProductsList) => {
        this.menuItems = data && data.content && data.content.map(
          ({ displayName, id }) => ({ label: displayName, value: id }),
        ) || [];
      });
  }

  loadBundleGroup(placeId, menuId, bundleGroupId): void {
    this.isLoading = true;
    this.bundleService.getBundleGroupById(placeId, menuId, bundleGroupId)
    .pipe(finalize(() => { this.isLoading = false; }))
    .subscribe((data: BundleGroupListData) => {
      this.setFormValueByBundleGroup(data)
    });
  }

  setFormValueByBundleGroup(bundleGroup: BundleGroupListData) {
    this.bundleGroup = bundleGroup;
    this.bundleGroupForm.get('displayName').setValue(bundleGroup.displayName);
    this.bundleGroupForm.get('menuItemsQuantity').setValue(bundleGroup.max);
    this.bundleGroupsReorder.setValue(bundleGroup.bundledProducts.map((bGI) => {
      const {
        defaultSelection = false, maxQuantity = 0, productId, upsellPrice = 0,
      } = bGI;
      return {
        defaultSelection, maxQuantity, productId, upsellPrice,
      };
    }));
  }

  onSave() {
    this.bundleViewChild.onSubmit(undefined);
  }

  private handleError(err): void {
    this.isSaving = false;
    this.logger.logError('BundleGroupManage', err);
  }

  onSubmit = () => {
    this.isSaving = true;
    if(this.bundleGroupForm.valid) {
      const { placeId, menuId, bundleGroupId, action } = this.state.params;
      const bundleGroupValue = this.getBundleGroup({ ...this.bundleGroupForm.value }, bundleGroupId);
      if (action === 'create') {
        this.bundleService.createBundleGroup(placeId, menuId, bundleGroupValue)
        .pipe(catchError((err) => of(this.handleError(err))))
        .subscribe(() => this.goBack());
      } else if(this.isEdit && !this.isTheSame(bundleGroupValue)){
        this.bundleService.updateBundleGroupById(placeId, menuId, bundleGroupId, bundleGroupValue)
        .pipe(catchError((err) => of(this.handleError(err))))
        .subscribe(() => this.goBack());
      }
    }
  };

  // Submit Functions

  private isTheSame = (bundleGroupValue : BundleGroupListData): boolean => {
    if (this.bundleGroup === null) { return false; }

    if (this.bundleGroup.displayName !== bundleGroupValue.displayName) { return false; }

    if (this.bundleGroup.max !== bundleGroupValue.max || this.bundleGroup.min !== bundleGroupValue.min) { return false; }

    if (this.bundleGroup.bundledProducts.length !== bundleGroupValue.bundledProducts.length) { return false; }

    for (let i = 0; i < this.bundleGroup.bundledProducts.length; i++) {
      const original: BundleGroupBundlesProduct = {
        ...this.bundleGroup.bundledProducts[i],
        defaultSelection: this.bundleGroup.bundledProducts[i].defaultSelection || false,
      };
      const newBundleGroup: BundleGroupBundlesProduct = bundleGroupValue.bundledProducts[i];

      if (original.productId !== newBundleGroup.productId) { return false; }

      if (original.maxQuantity !== newBundleGroup.maxQuantity) { return false; }

      if (original.defaultSelection !== newBundleGroup.defaultSelection) { return false; }

      if (original.upsellPrice !== newBundleGroup.upsellPrice) { return false; }
    }
    return true
  }

  getBundleGroup = (bundleGroupFormData: any, bundleGroupId: string): BundleGroupListData => {
    try {
      const { displayName, menuItemsQuantity, bundledProducts } = bundleGroupFormData;
      const { missingTranslations, state } = this.bundleGroup ? this.bundleGroup : { missingTranslations: [], state: 'ACTIVE' };
      return {
        displayName,
        missingTranslations,
        state,
        min: parseInt(menuItemsQuantity),
        max: parseInt(menuItemsQuantity),
        id: this.isEdit ? bundleGroupId : null,
        bundledProducts: bundledProducts.map((bP) => this.getBundledProduct(bP)),
      };
    } catch (error) {
      this.handleError(error);
    }
  };

  getBundledProduct = (bundleProduct: any): BundleGroupBundlesProduct => {
    const {
      displayName, upsellPrice, productId, maxQuantity, defaultSelection,
    } = bundleProduct;
    return {
      displayName,
      productId,
      defaultSelection,
      maxQuantity: maxQuantity && parseInt(maxQuantity) || 0,
      upsellPrice: upsellPrice && parseFloat(upsellPrice) || 0,
    };
  };

  goBack(): void {
    const { placeId, menuId } = this.state.params;
    this.state.go(menuRoutes.BUNDLE_GROUPS, { menuId, placeId });
  }

  onRequiredModeSelect($event): void {
    // todo: add something maybe?
  }

  minModifierValidators(): void {
    // todo: add something maybe?
  }

  onMinModifierCountUpdate(event): void {
    // todo: add something maybe?
  }

  onBundleGroupItemRemove(index: number): void {
    this.bundleGroupsReorder.controls.splice(index, 1);
  }

  onBundleGroupItemReorder(event): void {
    const { previousIndex, currentIndex } = event;
    const toBeReordered = this.bundleGroupsReorder.controls[previousIndex];
    this.bundleGroupsReorder.controls.splice(previousIndex, 1);
    this.bundleGroupsReorder.controls.splice(currentIndex, 0, toBeReordered);
  }

  // Validators
  exceedRequired = (control: FormControl): { [s: string]: boolean } => {
    const controlValue = parseInt(control.value);
    const requiredQuantityValue = parseInt(this.bundleGroupForm.get('menuItemsQuantity').value);
    const evaluation = controlValue > requiredQuantityValue;
    return evaluation ? { exceedRequired: true } : null;
  };

  notInteger = (control: FormControl): { [s: string]: boolean } => {
    if (control.value === undefined || control.value === null || control.value === '') { return; }

    return /^\d+$/gm.test(control.value) ? null : { notInteger: true };
  };

  incorrectFormat = (control: FormControl): { [s: string]: boolean } => {
    if (control.value === undefined || control.value === null || control.value === '' || control.value == '0') { return; }

    return /^\d+\.\d{0,2}$/gm.test(control.value) ? null : { incorrectFormat: true };
  };

  getError = (field: string, errorKey: string): boolean => this.bundleGroupsReorder.controls.map((group: FormGroup) => {
    const { errors = null, touched } = group.get(field);
    return errors ? errors.hasOwnProperty(errorKey) && touched : false;
  }).filter(Boolean).length > 0;

  checkSumIsLessThanRequired = (): boolean => {
    let bundleGroupItemQuantitySum = 0;
    this.bundleGroupsReorder.controls.forEach((group: FormGroup) => {
      bundleGroupItemQuantitySum += parseInt(group.get('maxQuantity').value) || 0;
    });
    const requiredQuantity = parseInt(this.bundleGroupForm.get('menuItemsQuantity').value) || 0;
    return bundleGroupItemQuantitySum < requiredQuantity;
  };

  matchSum = (control: FormControl) => {
    this.sumIsLessThanRequired = this.checkSumIsLessThanRequired();
    if (this.sumIsLessThanRequired) {
      this.bundleGroupsReorder.controls.forEach((group: FormGroup) => {
        const bundleItemControl: AbstractControl = group.get('maxQuantity');
        FormErrorHelper.addErrors({ matchSum: true }, bundleItemControl);
        bundleItemControl.markAsTouched();
      });
      this.bundleGroupForm.get('menuItemsQuantity').markAllAsTouched();
      return { matchSum: true };
    }
    if (!this.sumIsLessThanRequired) {
      this.bundleGroupsReorder.controls.forEach((group: FormGroup) => {
        const bundleItemControl: AbstractControl = group.get('maxQuantity');
        FormErrorHelper.removeErrors(['matchSum'], bundleItemControl);
      });
    }
    this.bundleGroupForm.updateValueAndValidity();
    return null;
  };

  getReorderItemFormGroup(bundleGroupItem?: BundleGroupBundlesProduct): FormGroup {
    const newGroup = new FormGroup({
      productId: new FormControl(bundleGroupItem && bundleGroupItem.productId || '', Validators.required),
      maxQuantity: new FormControl(bundleGroupItem && bundleGroupItem.maxQuantity || null),
      upsellPrice: new FormControl(bundleGroupItem && bundleGroupItem.upsellPrice || null),
      defaultSelection: new FormControl(bundleGroupItem && bundleGroupItem.defaultSelection || false),
    });
    newGroup.get('maxQuantity').setValidators([
      Validators.required, Validators.min(1), this.notInteger, this.exceedRequired, this.matchSum,
    ]);
    newGroup.get('upsellPrice').setValidators([this.incorrectFormat]);
    (newGroup.controls.defaultSelection as any).index = this.bundleGroupArrayIndex;
    this.bundleGroupArrayIndex++;
    return newGroup;
  }

  setDefaultSelectionIndex(index: number): void {
    this.checkedSelection = index;
    const defaultControls = this.bundleGroupsReorder.controls.map((g: FormGroup) => g.controls.defaultSelection);
    defaultControls.forEach((control: any) => {
      if (control.index !== index) {
        control.setValue(false);
      }
    });
  }

  onBundleGroupItemAdd(bundleGroupItem?: BundleGroupBundlesProduct): void {
    this.bundleGroupsReorder.push(this.getReorderItemFormGroup(bundleGroupItem));
  }

  get bundleGroupsReorder() {
    return (<FormArray> this.bundleGroupForm.get('bundledProducts'));
  }

  onMenuItemSelect(event) {
    // todo: add something maybe?
  }

  archiveBundleGroup() {
    // todo: really add something
  }

  get icon(): string {
    return this.iconOverride ? this.iconOverride : 'icon-cr-error-bandaid';
  }
}
