import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CrSpinnerComponent } from './spinner.component';

@NgModule({
  imports: [CommonModule],
  declarations: [CrSpinnerComponent],
  exports: [CrSpinnerComponent],
})
export class CrSpinnerModule {}
