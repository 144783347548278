import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BsDropdownModule, ButtonsModule, ModalModule } from 'ngx-bootstrap';
import { CrFormsModule, OrderService, SharedModule } from '../../../../shared';
import { OrderListItemModule } from '../order-list-item/order-list-item.module';
import { OrderReceiptModule } from '../order-receipt/order-receipt.module';
import { PtwListItemComponent } from '../ptw-list-item/ptw-list-item.component';
import { PtwOverrideComponent } from '../ptw-override/ptw-override.component';
import { VenueHoursModule } from '../venue-hours/venue-hours.module';
import { PtwRemoveAllComponent } from '../ptw-remove-all/ptw-remove-all.component';

import { OrderListComponent } from './order-list.component';
import { PtwRemoveAllInfoComponent } from '../ptw-remove-all-info/ptw-remove-all-info.component';

@NgModule({
  imports: [BsDropdownModule, BsDropdownModule.forRoot(), CommonModule, SharedModule, OrderListItemModule, OrderReceiptModule, CrFormsModule, ButtonsModule, VenueHoursModule, ModalModule],
  exports: [],
  declarations: [
    OrderListComponent,
    PtwListItemComponent,
    PtwOverrideComponent,
    PtwRemoveAllComponent,
    PtwRemoveAllInfoComponent
  ],
  providers: [OrderService],
  entryComponents: [OrderListComponent],
})
export class OrderListModule { }
