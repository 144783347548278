import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { StateService } from "@uirouter/core";
import { sanitize } from 'dompurify';
import { Subscription } from "rxjs";
import { LocalizedText } from '../../../../core';
import { NavigationService } from "../../../../shared";
import { PlacesService } from "../../../../shared/services/places/places.service";
import { menuRoutes } from "../../menus.routes";
import text from './resources/en.json';

import { Place } from "../../../../shared/models/places.model";
@Component({
    selector: "cr-discount-content-manage",
    templateUrl: "./discount-content-manage.component.html",
    styleUrls: ["./discount-content-manage.component.scss"]
})
export class DiscountContentManageComponent implements OnInit, OnDestroy {
    pageTitle: string;
    
    text: LocalizedText;

    edit = true;

    isSubmitted = false;

    venueData: Place

    isLoading = false;

    form = new FormGroup({});

    discountsLabelControl: FormControl;

    discountContentHeaderControl: FormControl;

    discountContentDescriptionControl: FormControl;

    sanitize: (html: string) => string;

    isSaving = false;

    phonePreview: {
        discountTitle: string,
        discountDescription: string
    }

    formSubs: Subscription[] = [];

    quillConfig = {
        toolbar: {
          container: [
            ["bold"]
          ]
        },
      };

    quillStyles = {height: '347px'};

    constructor(public state: StateService, private crPlacesService: PlacesService, 
         private navigationService: NavigationService) {}
    
    ngOnInit() {
        this.text = text;
        this.sanitize = sanitize;
        this.pageTitle = this.state.params.edit ? this.text.pageTitleEdit : this.text.pageTitleCreate;
        this.isLoading = true;
        this.initForm();
        this.crPlacesService
            .getPlace(`venue:${this.state.params.venueId}`)
            .then((data) => {
                this.isLoading = false;
                this.venueData = data;
                this.fillFormEditFields();
                this.initFormState();
            })
            .catch((err) => {
                this.isLoading = false;
                console.log(err);
            });
    }

    initForm(): void {
        this.discountsLabelControl = new FormControl( '', [Validators.maxLength(29)]);
        this.discountContentHeaderControl = new FormControl( '', [Validators.required,  Validators.maxLength(25)]);
        this.discountContentDescriptionControl = new FormControl( '', [Validators.required]);
        this.form.addControl('discountLabel', this.discountsLabelControl);
        this.form.addControl('discountContentHeaderControl', this.discountContentHeaderControl);
        this.form.addControl('discountContentDescriptionControl', this.discountContentDescriptionControl);
        const discountHeader$ = this.discountContentHeaderControl.valueChanges.subscribe((value) => {
            this.updatePhonePreview();
          });
      
        const discountDescrition$ = this.discountContentDescriptionControl.valueChanges.subscribe((value) => {
        this.updatePhonePreview();
        });
      
        this.formSubs.push(discountHeader$);
        this.formSubs.push(discountDescrition$);
    }

    initFormState(): void {
        if(this.venueData.webContent && this.venueData.webContent.discountTitle){
            this.edit = false
        }
    }

    fillFormEditFields(): void {
        if (this.venueData.webContent && this.venueData.webContent.discountsLabel) {
            this.discountsLabelControl.setValue(this.venueData.webContent.discountsLabel);
        } else {
            this.discountsLabelControl.setValue(this.text.defaultDiscountsLabel);
        }
        if(this.venueData.webContent && this.venueData.webContent.discountTitle) {
            this.discountContentHeaderControl.setValue(this.venueData.webContent.discountTitle);
        }
        if(this.venueData.webContent && this.venueData.webContent.discountDescription) {
            this.discountContentDescriptionControl.setValue(this.venueData.webContent.discountDescription);
        }
    }

    ngOnDestroy() {
        this.formSubs.forEach((sub) => {
            sub.unsubscribe();
          });
    }


    onCancel(): void {
        this.goBack();
    }

    onSave(): void{
        if(this.form.valid){
            this.isSaving = true;
            this.venueData.webContent = { ...this.venueData.webContent,
                discountsLabel: this.discountsLabelControl.value?.length > 0 ? this.discountsLabelControl.value : this.text.defaultDiscountsLabel,
                discountTitle: this.discountContentHeaderControl.value,
                discountDescription: this.sanitize(this.discountContentDescriptionControl.value)
            };
            if(this.venueData.transientPlace){
                this.crPlacesService
                .createPlace(this.venueData)
                .then((data) => {
                    this.isSaving = false;
                    this.goBack();
                }).catch((err) => {
                    this.isSaving = false;
                    console.log(err);
                }
                );
            } else {
                this.crPlacesService
                .updatePlace(this.venueData)
                .then((data) => {
                    this.isSaving = false;
                    this.goBack();
                }).catch((err) => {
                    this.isSaving = false;
                    console.log(err);
                }
                );
            }
        }
    }

    goBack(): void {
        this.navigationService.goBack(menuRoutes.DASHBOARD, {
          tabId: 'discount-content'
        });
      }

      updatePhonePreview(): void {
        this.phonePreview = {
            discountTitle: this.discountContentHeaderControl.value,
            discountDescription: this.discountContentDescriptionControl.value
        }
      }

}