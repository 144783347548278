import template from './split-btn.html';
import './split-btn.less';

const SplitBtnComponent = {
    template,
    transclude: true,
    bindings: {
        label: '@',
        onButtonClick: '&',
    },
};

export default SplitBtnComponent;
