import controller from './products-list.controller';
import template from './products-list.html';

const component = {
    template,
    bindings: {},
    controller: [
        '$state',
        'crErrorLoggingService',
        'crAnalyticsService',
        'crToastService',
        'crLocaleService',
        'crConfig',
        'crProductsListService',
        '$q',
        'crEntitySelectionManager',
        controller,
    ],
};

export default component;
