import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { CrDropdownModule } from '../../../../commons/basic/components/dropdown/dropdown.module';
import { CrSearchModule } from '../../../../shared/forms/search/search.module';
import { BundleService } from '../../../../shared/services/bundle/bundle.service';
import { CrTableModule } from '../../../../shared/table/table.module';
import { BundleListComponent } from './bundle-list.component';

@NgModule({
  imports: [BrowserModule, CrTableModule, CrDropdownModule, CrSearchModule, FormsModule, ReactiveFormsModule],
  exports: [],
  declarations: [BundleListComponent],
  entryComponents: [BundleListComponent],
  providers: [BundleService],
})
export class BundleListModule {
}
