import _ from 'lodash';

class CheckController {
    $onInit() {
        if (Array.isArray(this.options) && this.options.length) {
            this.handleUpdatedOptions();
        }
    }

    $onChanges(changes) {
        if (changes && changes.options) {
            if (!_.isNil(changes.options.currentValue)) {
                if (!changes.options.isFirstChange()) {
                    this.handleUpdatedOptions();
                    this.update();
                }
            }
        }
    }

    handleUpdatedOptions() {
        this.values = this.options.map((option) => option.checked || false);
    }

    toggleValue(i) {
        if (!this.disabled) {
            this.values[i] = !this.values[i];
            this.update();
        }
    }

    update() {
        const valueMap = {};
        this.options.forEach((option, i) => {
            valueMap[option.name] = this.values[i];
        });

        this.onUpdate({
            $event: {
                model: valueMap,
            },
        });
    }
}

export default CheckController;
