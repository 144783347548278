import { Component, OnInit, ViewChild } from '@angular/core';
import { StateService } from '@uirouter/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Dimensions, ImageCropperComponent, ImageTransform } from 'ngx-image-cropper';
import { LocalizedText } from '../../../core';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { ImageService } from '../../services/image/image.service';
import text from './resources/locale/en.json';


interface ImageTrackingData {
  entityType: string;
  imageType: string;
  minDimensions?: boolean;
  filename?: string;
}

interface ImageData {
  type: string,
  width: number,
  height: number,
  src: string,
  displaysrc: string,
}

@Component({
  selector: 'cr-image-cropper-modal',
  templateUrl: './image-cropper-modal.component.html',
  styleUrls: ['./image-cropper-modal.component.scss'],
})
export class ImageCropperModalComponent implements OnInit{
  
  headerText: string;

  targetImages: ImageData[];

  imageIndex: number;

  imageType: string;

  imageTypes: string[];

  minSize: { width: number; height: number, aspectRatio: number };
  
  showAsCircle: boolean;

  text: LocalizedText;

  loading = false;

  error = false;

  isFile: boolean;

  newImage: ImageData;

  uploadedImageSize : Dimensions;

  venueId: string;

  imageChangedEvent: any = '';

  canvasRotation = 0;

  rotation = 0;

  scale = 1;

  fileName: string;

  currentFileBase64: string;

  transform: ImageTransform = {};

  imageTrackingData: ImageTrackingData;

  _resolve: (value?: ImageData[]) => void;

  _reject: () => void;

  _result: Promise<any>;

  get result() {
    return this._result;
  }

  @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;
  
  constructor(private _modalRef: BsModalRef, private crImageService: ImageService, private state: StateService, private analyticService: AnalyticsService) {
    this.text = text;
    this._result = new Promise((resolve, reject) => {
      this._resolve = resolve;
      this._reject = reject;
    });
  }

  ngOnInit() {
      this.venueId = this.state.params.venueId;
  }

  cancel() {
    this._reject();
    this.analyticService.track('Add New Image Canceled', this.imageTrackingData);
    this._modalRef.hide();
  }

  submit() {
    this.loading = true;
    this.error = false;
    const cropperEvent =this.imageCropper.crop();
    if (!cropperEvent) {
      this.loading = false;
      this.error = true;
      return;
    }
    const width = this.uploadedImageSize.width;
    const height = this.uploadedImageSize.height;
    const x1 = Math.round(cropperEvent.imagePosition.x1);
    const x2 = Math.round(cropperEvent.imagePosition.x2);
    const y1 = Math.round(cropperEvent.imagePosition.y1);
    const y2 = Math.round(cropperEvent.imagePosition.y2);
    const crop = `${x1}x${y1}:${x2}x${y2}`;
    this.uploadImageFile(this.currentFileBase64, width, height, crop).then(() => {
      if(this.imageIndex === null) {
        this.targetImages.push(this.newImage);
      } else {
        this.targetImages[this.imageIndex] = this.newImage;
      }
      this._resolve(this.targetImages);
      this._modalRef.hide();
      this.loading = false;
      
      this.imageTrackingData.filename = this.fileName;
      this.imageTrackingData.minDimensions = this.isMinimumDimensions(width, height);
      this.analyticService.track('Add New Image Saved', this.imageTrackingData);
    }).catch(() => {
      this.error = true;
      this.loading = false;
    });
  }

  isMinimumDimensions(width: number, height: number) {
    return width < this.minSize.width && height < this.minSize.height;
  }

  async fileChangeEvent(event: any): Promise<void> {
    this.error = false;
    if (event.target.files && event.target.files.length > 0) {
      try {
        const file = event.target.files[0];
        this.fileName = file.name;
        this.uploadedImageSize = await this.crImageService.getImageDimensions(file);
        this.currentFileBase64 = await this.crImageService.getBase64DataUrl(file);
        this.isFile = true;
      } catch (error) {
        this.error = true;
      }
    }
    this.imageChangedEvent = event;
  }

  imageLoaded() {
    this.error = false;
  }

  sliderChange(value: number) {
    this.sliderZoomIn(value);
  }

  sliderZoomIn(value: number) {
    this.scale = value;
      this.transform = {
          ...this.transform,
          scale: this.scale
      };
    this.scale = value;
  
  }

  cropperReady(sourceImageDimensions: Dimensions) {
      // Workaround for resizing the image because of a bug in ngx-image-cropper
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      });
  }

  loadImageFailed() {
    this.error = true;
  }


  rotateRight() {
      this.canvasRotation++;
      this.flipAfterRotate();
  }

  flipAfterRotate() {
      const flippedH = this.transform.flipH;
      const flippedV = this.transform.flipV;
      this.transform = {
          ...this.transform,
          flipH: flippedV,
          flipV: flippedH
      };
  }

  resetImage() {
      this.scale = 1;
      this.canvasRotation = 0;
      this.transform = {};
      this.isFile = false;
  }

  zoomOut() {
      this.scale -= .1;
      this.transform = {
          ...this.transform,
          scale: this.scale
      };
  }

  zoomIn() {
      this.scale += .1;
      this.transform = {
          ...this.transform,
          scale: this.scale
      };
  }

  
  uploadImageFile(base64DataUrl: string, width: number, height: number, crop: string): Promise<void> {
    const fileName = this.fileName.replace(/[^a-zA-Z0-9\-_.]/g, '');
    return this.crImageService.upload(fileName, base64DataUrl, this.venueId).then((res) =>{
      const src = encodeURI(res.data.src);
      const savedImage = {
        type: this.imageType,
        width,
        height,
        src,
        displaysrc: '',
        crop,
      }
      savedImage.displaysrc = this.crImageService.getImageSrc(savedImage, width.toString(), height.toString());
      this.newImage = savedImage;
    });
  }

}
