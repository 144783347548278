import controller from './fnb-reporting.controller';
import template from './fnb-reporting.html';

import './fnb-reporting.less';

const component = {
    template,
    bindings: {},
    controller: [
        '$state',
        'crConfig',
        'crConstants',
        'crAnalyticsService',
        'crErrorLoggingService',
        'numberFilter',
        'fnbReportingService',
        controller,
    ],
};

export default component;
