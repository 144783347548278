import _ from 'lodash';
import text from './resources/locale/en.json';

class EventScheduleController {
    constructor($scope, $timeout, crConstants, crScheduleService) {
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.crConstants = crConstants;
        this.crScheduleService = crScheduleService;
    }

    $onInit() {
        this.text = text;

        // Get data from the parent row
        this.data = this.crRowManagerRow.rowData;

        // Get a unique ID from the parent row to use for form names
        this.uniqueId = this.crRowManagerRow.rowId;

        this.DATA_EVENT = 'data';

        this.scheduleKey = this.crConstants.calendarService.scheduleKey;

        this.NAME_START_DATE = `${this.uniqueId}-startDate`;
        this.NAME_START_TIME = `${this.uniqueId}-startTime`;
        this.NAME_END_DATE = `${this.uniqueId}-endDate`;
        this.NAME_END_TIME = `${this.uniqueId}-endTime`;
        this.NAME_WEEKDAYS = `${this.uniqueId}-weekdays`;
        this.NAME_DURATION = `${this.uniqueId}-duration`;
        this.NAME_HIDDEN_TIMES = `${this.uniqueId}-hiddenTimes`;

        this.VALIDATION_TIME_DURATION = 'time-duration';
        this.VALIDATION_END_TIME = 'end-time';
        this.VALIDATION_END_DATE = 'end-date';

        this.repeatTimesIndex = 0;

        // Template for new repeat times
        this.repeatTimeObj = { index: this.repeatTimesIndex, startTime: null };

        this.setInitialStates(this.data);

        this.allDayCheckOptions = [{ label: this.text.allDay, name: 'allDay', checked: this.allDay }];
        this.checkParkOptions = [{ label: this.text.checkPark, name: 'checkPark', checked: this.checkPark }];
        this.repeatCheckOptions = [{ label: this.text.repeat, name: 'repeat', checked: this.repeat }];
        this.neverEndsCheckOptions = [{ label: this.text.never, name: 'neverEnds', checked: this.neverEnds }];

        // Listen for data changes
        this.$scope.$on(this.DATA_EVENT, (evt, data) => {
            this.data = data;
        });
    }

    setInitialStates(data) {
        const instance = data.instances[0];
        const { startTime } = instance;
        const { endTime } = instance;

        if (data.instances.length === 1 && !startTime && !endTime) {
            // Create state
            this.repeat = false;
            this.neverEnds = true;
            this.allDay = false;
            this.checkPark = false;
        } else {
            // Edit state
            this.repeat = data.recurrence;
            this.neverEnds = this.repeat && !data.recurrence.includes('UNTIL');
            this.allDay = startTime && endTime && this.crScheduleService.isAllDay(startTime, endTime) && data.scheduleKey?.toUpperCase() !== this.scheduleKey.CHECKPARK.toUpperCase();
            this.checkPark = data.scheduleKey?.toUpperCase() === this.scheduleKey.CHECKPARK.toUpperCase();
        }

        this.endDateRequired = !this.neverEndsState;

        this.setInitialData(data, startTime, endTime);
        this.setCancellationData(data);
    }

    setInitialData(data, startTime, endTime) {
        // Handle recurrence rule
        if (this.repeat) {
            this.recurrence = this.crScheduleService.getRecurrenceObject(data.recurrence);
        } else {
            this.recurrence = {
                freq: 'weekly',
                byDay: _.cloneDeep(this.crConstants.schedule.defaultWeekdays),
            };
        }

        // Handle repeat times
        if (this.repeatTimesState) {
            this.repeatTimes = data.instances.map((instance) => {
                this.repeatTimesIndex += 1;
                return {
                    index: this.repeatTimesIndex,
                    startTime: this.crScheduleService.getTimeFromIsoString(instance.startTime),
                };
            });
        } else {
            this.repeatTimes = [_.cloneDeep(this.repeatTimeObj)];
        }

        // Handle times and dates
        if (this.customTimesState) {
            if (startTime) {
                this.startDate = startTime;
                this.startTime = this.crScheduleService.getTimeFromIsoString(startTime);
            }

            if (endTime) {
                this.endDate = endTime;
                this.endTime = this.crScheduleService.getTimeFromIsoString(endTime);
            }
        } else if (this.repeatTimesState) {
            this.startDate = this.crScheduleService.getBeginningOfDay(startTime);
            this.startTime = this.crScheduleService.getTimeFromIsoString(startTime);

            if (!this.neverEnds) {
                this.endDate = this.recurrence.until;
            }
        } else if (this.allDay || this.checkPark) {
            this.startDate = startTime;

            if (this.repeat) {
                if (!this.neverEnds) {
                    this.endDate = this.recurrence.until;
                }
            } else {
                this.endDate = this.crScheduleService.getEndOfPreviousDay(endTime);
            }
        }
    }

    // State getters

    get repeatTimesState() {
        return this.repeat && !this.allDay && !this.checkPark;
    }

    get customTimesState() {
        return !this.allDay && !this.repeat && !this.checkPark;
    }

    get neverEndsState() {
        return this.repeat && this.neverEnds;
    }

    // Date and time management

    onStartDateUpdate(event) {
        this.startDate = event.model;
        this.updateDateTime('startTime', this.startDate, this.startTime);

        if (this.repeatTimesState) {
            this.updateInstancesFromRepeatTimes();
        }

        this.validateEndDateAndTime();
        this.update();
    }

    onEndDateUpdate(event) {
        this.endDate = event.model;
        this.toggleNeverEnds(!this.endDate);
        this.updateDateTime('endTime', this.endDate, this.endTime);
        this.manageEndDateState();
        this.validateEndDateAndTime();
        this.update();
    }

    onStartTimeUpdate(event) {
        this.startTime = event.model;
        this.updateDateTime('startTime', this.startDate, this.startTime);
        this.validateEndDateAndTime();
        this.update();
    }

    onEndTimeUpdate(event) {
        this.endTime = event.model;
        this.updateDateTime('endTime', this.endDate, this.endTime);
        this.validateEndDateAndTime();
        this.update();
    }

    manageEndDateState() {
        if (this.repeat) {
            // If repeating and never ends, remove 'until' from the recurrence rule
            if (this.neverEnds) {
                delete this.recurrence.until;
            } else if (this.endDate) {
                // End date needs to be the end of the day
                this.recurrence.until = this.crScheduleService.getEndOfDay(this.endDate);
            }

            // If repeating and not all day, we don't need an 'endTime'
            if (!this.allDay && !this.checkPark) {
                delete this.data.instances[0].endTime;
            } else {
                // If repeating and all day, end date is the beginning of the following day
                this.addAllDayEndDate(this.startDate);
            }
        } else if (this.allDay || this.checkPark) {
            // If not repeating and all day, we need to send midnight (start of) the following day
            // This is because the End date picker is bound to 23:59:59 of the date selected.
            this.addAllDayEndDate(this.endDate);
        }
    }

    updateDateTime(prop, date, time) {
        // When repeating or all day, do not use the custom time entered
        time = this.allDay || this.repeat || this.checkPark ? null : time;

        // Start time for allDay should be midnight
        if ((this.allDay || this.checkPark) && prop === 'startTime') {
            time = '00:00:00';
        }

        const dateTime = this.crScheduleService.getCombinedDateTimeIsoString(date, time);
        this.data.instances[0][prop] = dateTime;
    }

    updateDateTimes() {
        this.updateDateTime('startTime', this.startDate, this.startTime);
        this.updateDateTime('endTime', this.endDate, this.endTime);
        this.manageEndDateState();
    }

    addAllDayEndDate(date) {
        if (date) {
            const endDate = this.crScheduleService.getBeginningOfNextDay(date);
            this.data.instances[0].endTime = endDate;
        }
    }

    // State management

    manageState(updateDateTimes = true) {
        if (this.repeatTimesState) {
            // Do not update all date times
            updateDateTimes = false;

            // If switching to repeat and we have a start time, use if for the first repeat time
            if (this.startTime && !this.repeatTimes[0].startTime) {
                this.repeatTimes[0].startTime = this.startTime;
            }

            // Only update the start time and the repeat times
            this.updateDateTime('startTime', this.startDate, this.startTime);
            this.updateInstancesFromRepeatTimes();
        } else {
            // Only one instance should exist when not repeating times
            this.data.instances = [this.data.instances[0]];
        }

        if (this.repeat) {
            if (this.allDay || this.checkPark) {
                this.storePreviousDuration();
            } else {
                this.calculateDuration();
            }
        } else {
            // Store previous values to restore later
            this.storePreviousDuration();

            // Remove recurrence when not in repeat state
            delete this.data.recurrence;
        }

        if (this.neverEnds) {
            this.storePreviousEndDate();
        } else {
            this.restorePreviousEndDate();
        }

        this.endDateRequired = !this.neverEndsState;

        if (updateDateTimes) {
            this.updateDateTimes();
        } else {
            this.manageEndDateState();
        }

        this.update();
    }

    // Component update callbacks

    onCheckChange(event) {
        const checkName = _.keys(event.model)[0];
        this[checkName] = event.model[checkName];
        this.manageState();
        this.validateEndDateAndTime();
    }

    onDurationUpdate(event) {
        this.data.durationSecs = event.model;
        this.update();
        this.validateTimesBasedOnDuration();
    }

    onWeekdaySelect(event) {
        this.recurrence.byDay = _.cloneDeep(event.model);
        this.update();
    }

    // Repeat times methods

    updateInstancesFromRepeatTimes() {
        this.data.instances = this.repeatTimes.map((repeatTime) => ({
            startTime: this.crScheduleService.getCombinedDateTimeIsoString(this.startDate, repeatTime.startTime),
        }));
    }

    addRepeatTime() {
        if (this.canAddRepeatTimes()) {
            const repeatTimeObj = _.cloneDeep(this.repeatTimeObj);
            this.repeatTimesIndex += 1;
            repeatTimeObj.index = this.repeatTimesIndex;

            this.repeatTimes.push(repeatTimeObj);
        }
    }

    onRepeatTimeUpdate(event, index) {
        this.repeatTimes[index].startTime = event.model;
        this.updateInstancesFromRepeatTimes();
        this.manageState(false);
        this.validateTimesBasedOnDuration();
    }

    onRepeatTimeClose(event, index) {
        this.repeatTimes.splice(index, 1);
        this.updateInstancesFromRepeatTimes();
        this.manageState(false);
        this.validateTimesBasedOnDuration();
    }

    validateTimesBasedOnDuration() {
        if (this.repeatTimes.length > 1 && this.data.durationSecs) {
            let allValid = true;

            this.repeatTimes.reduce((prev, curr) => {
                const durationSecs = this.crScheduleService.getDurationInSeconds(prev.startTime, curr.startTime);
                const valid = durationSecs >= this.data.durationSecs;

                if (!valid) {
                    allValid = false;
                }

                this.setValidity(`${this.NAME_START_TIME}-${curr.index}`, this.VALIDATION_TIME_DURATION, valid);
                return curr;
            });

            this.setTouched(this.NAME_HIDDEN_TIMES);
            this.setValidity(this.NAME_HIDDEN_TIMES, this.VALIDATION_TIME_DURATION, allValid);
        } else {
            this.setValidity(this.NAME_HIDDEN_TIMES, this.VALIDATION_TIME_DURATION, true);
            this.repeatTimes.forEach((time) => {
                this.setValidity(`${this.NAME_START_TIME}-${time.index}`, this.VALIDATION_TIME_DURATION, true);
            });
        }

        // Index 0 should always be valid. This index may be removed, resulting in an invalid time
        // becoming index 0.
        this.setValidity(`${this.NAME_START_TIME}-${this.repeatTimes[0].index}`, this.VALIDATION_TIME_DURATION, true);
    }

    validateEndDateAndTime() {
        let validEndDate = false;

        // See if end date is on or before start date
        if (this.startDate && this.endDate) {
            validEndDate = this.crScheduleService.isSameOrAfter(this.startDate, this.endDate);
            this.setValidity(this.NAME_END_DATE, this.VALIDATION_END_DATE, validEndDate);
        } else {
            validEndDate = true;
            this.setValidity(this.NAME_END_DATE, this.VALIDATION_END_DATE, validEndDate);
        }

        if (validEndDate && !this.repeat && !this.allDay && !this.checkPark) {
            // If dates are valid, check order of times if on the same day
            const sameDay = this.crScheduleService.isSameDay(this.startDate, this.endDate, true);

            if (sameDay) {
                const validEndTime = this.crScheduleService.endTimeIsAfterStartTime(this.startTime, this.endTime);
                this.setValidity(this.NAME_END_TIME, this.VALIDATION_END_TIME, validEndTime);
            } else {
                this.setValidity(this.NAME_END_TIME, this.VALIDATION_END_TIME, true);
            }
        } else {
            this.setValidity(this.NAME_END_TIME, this.VALIDATION_END_TIME, true);
        }
    }

    canAddRepeatTimes() {
        const valid = !_.some(this.repeatTimes, (obj) => !obj.startTime);

        return valid;
    }

    // Helpers

    storePreviousEndDate() {
        this.prevEndDate = this.endDate || this.prevEndDate;
        this.endDate = null;
    }

    restorePreviousEndDate() {
        this.endDate = this.endDate || this.prevEndDate;
    }

    storePreviousDuration() {
        this.prevDurationSecs = this.data.durationSecs || this.prevDurationSecs;
        delete this.data.durationSecs;
    }

    calculateDuration() {
        // Uses the previous duration or calculates a duration based on selected times
        if (!this.data.durationSecs) {
            if (this.prevDurationSecs) {
                this.data.durationSecs = this.prevDurationSecs;
            } else if (
                this.startTime &&
                this.endTime &&
                this.crScheduleService.isSameDay(this.startDate, this.endDate, true)
            ) {
                const durationSecs = this.crScheduleService.getDurationInSeconds(this.startTime, this.endTime);

                if (durationSecs > 0) {
                    this.data.durationSecs = durationSecs;
                }
            }
        }
    }

    toggleNeverEnds(checked) {
        this.neverEnds = checked;
        this.neverEndsCheckOptions[0].checked = this.neverEnds;
        this.neverEndsCheckOptions = _.cloneDeep(this.neverEndsCheckOptions);
    }

    setCancellationData(data) {
        if (data.recurrenceExceptions) {
            this.exceptionsList = this.crScheduleService.getRecurrenceExceptionsArray(data.recurrenceExceptions);
        }
    }

    removeException(event, index) {
        this.exceptionsList.splice(index, 1);
        this.update(false);
    }

    // Updates the parent row with new data
    update(removeExceptions = true) {
        if (this.repeat) {
            this.data.recurrence = this.crScheduleService.getRecurrenceString(this.recurrence);
        }

        if (this.checkPark) {
            this.data.scheduleKey = this.scheduleKey.CHECKPARK;
        } else if (this.allDay) {
            this.data.scheduleKey = this.scheduleKey.ALLDAY;
        }

        if (removeExceptions) {
            this.data.recurrenceExceptions = null;
            this.exceptionsList = [];
        } else {
            this.data.recurrenceExceptions = this.crScheduleService.getRecurrenceExceptionsString(this.exceptionsList);
        }

        this.crRowManagerRow.update();
    }

    setValidity(formName, validationId, valid) {
        this.$timeout(() => {
            const formElem = this.form[formName];

            if (formElem) {
                formElem.$setValidity(validationId, valid);
            }
        });
    }

    setTouched(formName) {
        this.$timeout(() => {
            const formElem = this.form[formName];

            if (formElem && !formElem.$touched) {
                formElem.$setTouched();
            }
        });
    }
}

export default EventScheduleController;
