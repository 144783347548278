import angular from 'angular';

import PlacesListComponent from './places-list.component';
import PlacesListService from './places-list.service';

import PlacesListLocale from './resources/locale/en.json';

import PlacesListMockService from './places-list.mock.service';

const PlacesList = angular
    .module('control-room.places.components.places-list', [])
    .component('crPlacesList', PlacesListComponent)
    .service('placesListService', ['crPlacesService', 'crCommerceService', PlacesListService])
    .service('placesListMockService', ['$q', PlacesListMockService])
    .constant('placesListLocale', PlacesListLocale);

export default PlacesList;
