import { Component, Input } from '@angular/core';

@Component({
  selector: 'cr-section-heading',
  templateUrl: './section-heading.component.html',
  styleUrls: ['./section-heading.component.scss'],
})
export class SectionHeadingComponent {
  @Input() customClass: string;
}
