import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import angular from 'angular';

@Injectable({
    providedIn: 'root',
})
export class ScrollService {
    private bodyElem: angular.IAugmentedJQuery;
    constructor(@Inject(DOCUMENT) private document: Document) {
        this.init();
    }

    private init(): void {
        this.bodyElem = angular.element(this.document.body);
    }

    public enablePageScroll(): void {
        this.bodyElem.removeClass('no-scroll');
    }

    public disablePageScroll(): void {
        this.bodyElem.addClass('no-scroll');
    }
}
