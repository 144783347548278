import _ from 'lodash';

import text from './resources/locale/en.json';
import iconPlaceHolderSrc from '../../resources/images/img_placeholder@2x.png';

class IconLabelValue {
    constructor(crImageService) {
        this.crImageService = crImageService;
    }

    $onChanges(changes) {
        if (changes.data) {
            this.iconSrc = this.getDisplayImage(this.data.images);
            this.label = this.data.label || text.label;
            this.value = this.getDisplayValue(this.data[this.valueKey]);
        }
    }

    $onInit() {
        this.iconSrc = iconPlaceHolderSrc;
    }

    getDisplayValue(value) {
        return value ? value.map((obj) => obj.label).join(', ') : text.value;
    }

    getDisplayImage(images) {
        const icon = _.find(images, { type: 'ICON' });
        return _.isEmpty(images) ? iconPlaceHolderSrc : this.crImageService.getIconSrc(icon.src);
    }
}

export default IconLabelValue;
