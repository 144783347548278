import { NgModule } from '@angular/core';

import { VenueHoursComponent } from './venue-hours.component';

@NgModule({
  imports: [],
  exports: [VenueHoursComponent],
  declarations: [VenueHoursComponent],
  providers: [],
})
export class VenueHoursModule { }
