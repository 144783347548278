import text from './resources/locale/en.json';

class ExperienceDashboard {
    constructor($state) {
        this.$state = $state;
    }

    $onInit() {
        this.text = text;
    }

    selectButtonAutomation() {
        this.$state.go('client.experience-promoter.automation-select');
    }

    selectButtonBroadcast() {
        this.$state.go('client.experience-promoter.broadcast-select');
    }
}

export default ExperienceDashboard;
