import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import bulletTrain from 'bullet-train-client';
import { Observable } from 'rxjs';
import { Order, OrderReceiptEmailRequest, OrderRefundResponse, OrderFulfillmentParams, CheckinOrderRequest } from '../../models/order.model';
import { patchOrderDates, patchOrderProductDisplayPrice } from '../../helpers/patch-order';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  private baseUrl: string;

  private baseCommerceUrl = '/rest/commerce';

  constructor(private http: HttpClient) {
    this.baseUrl = '/rest/commerce-fulfillment/orders';
  }

  public async getOrderFulfillment(placeId: string, statusParam?: OrderFulfillmentParams): Promise<Order[]> {
    const isRefundsEnabled: boolean = bulletTrain.hasFeature('refunds');
    const resp: Order[] = await this.http.get<Order[]>(`${this.baseUrl}/fulfillment/${placeId}`, {params: statusParam as HttpParams}).toPromise();
    resp.forEach(patchOrderDates.bind(this));
    resp.forEach(patchOrderProductDisplayPrice.bind(this));
    // Filter out refunds if it's flagged off
    if (!isRefundsEnabled) {
      return resp.filter((o) => !o.isRefund);
    }
    return resp;
  }

  public async getOrderById(orderId: string): Promise<Order> {
    const resp: any = await this.http.get(`${this.baseUrl}/fulfillment/order/${orderId}`).toPromise();
    patchOrderDates(resp);
    patchOrderProductDisplayPrice(resp);
    return resp;
  }

  public updateFulfillmentStatus(placeId: string, orderId: string, fulfillmentStatus: string): Observable<void> {
    const url = `${this.baseUrl}/fulfillment/${placeId}/${orderId}/fulfillmentStatus/${fulfillmentStatus}`;
    return this.http.put<void>(url, null);
  }

  public sendRefundOrderReceiptEmail(orderRequest: OrderReceiptEmailRequest): Observable<any> {
    return this.http.post<any>(`${this.baseCommerceUrl}/sendOrderReceipt`, orderRequest);
  }

  public refundOrder(
    placeId: string,
    orderId: string,
    refundAmount: number,
    refundReason: string,
    alternateEmail: string,
    printChit: boolean,
    username: string,
    authCode: string,
  ): Observable<OrderRefundResponse> {
    const refundUrl = `${this.baseUrl}/fulfillment/${placeId}/order/${orderId}/refund`;
    const payload = {
      refundAmount,
      cancelOrder: false,
      refundReason,
      alternateEmail,
      printChit,
      username,
      authCode,
    };
    return this.http.post<OrderRefundResponse>(refundUrl, payload);
  }

  public checkInOrder(orderId: string, checkInData: CheckinOrderRequest): Observable<any> {
    const checkinUrl = `${this.baseUrl}/fulfillment/${checkInData.placeId}/order/${orderId}/checkin/force`;
    return this.http.post<any>(checkinUrl, checkInData);
  }
}
