import controller from './date-range-picker.controller';
import template from './date-range-picker.html';
import './date-range-picker.less';

const Component = {
    template,
    bindings: {
        range: '<',
        defaultRange: '@',
        menuRight: '<?',
        onUpdate: '&',
        hasFutureDates: '<?',
    },
    controller: [
        'crDateRangePickerLocale',
        '$state',
        'crConstants',
        'crDateRangeService',
        'crVenueService',
        controller,
    ],
};

export default Component;
