import moment from 'moment';
import 'moment-timezone';

import text from './resources/locale/en.json';

class FootNote {
    constructor($state, crVenueService) {
        this.$state = $state;
        this.crVenueService = crVenueService;

        this.text = text;
    }

    $onChanges(changes) {
        if (changes) {
            if (this.lastChanged) {
                const timezone = this.crVenueService.getTimezone(this.$state.params.venueId).name;
                const timeFormat = 'h:mmA z MMM D YYYY';

                this.model = moment(this.lastChanged).tz(timezone).format(timeFormat);

                if (this.lastChangedBy) {
                    this.model = `${this.model} ${this.text.by} ${this.lastChangedBy}`;
                }
            }
        }
    }
}

export default FootNote;
