import text from './resources/locale/en.json';

class PoiTypeInventoryController {
    constructor($state, poiTypeInventoryService, navigationService, errorLoggingService) {
        this.$state = $state;
        this.poiTypeInventoryService = poiTypeInventoryService;
        this.navigationService = navigationService;
        this.errorLoggingService = errorLoggingService;

        this.text = text;
    }

    $onInit() {
        this.isLoading = true;
        this.hasDataError = false;

        if (!this.$state.params.typeName) {
            this.getTypeName();
        } else {
            this.typeName = this.$state.params.typeName;
        }

        this.pois = [];

        this.getData();
    }

    getData() {
        this.poiTypeInventoryService
            .getPois({ venueId: this.$state.params.venueId, type: this.$state.params.typeId })
            .then((pois) => {
                this.pois = pois;
            })
            .catch(() => {
                this.hasDataError = true;
                this.errorLoggingService.logError('Could not get poi types', {
                    venueId: this.$state.params.venueId,
                    typeId: this.$state.params.typeId,
                });
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    getTypeName() {
        this.poiTypeInventoryService
            .getPoiType(this.$state.params.typeId)
            .then((res) => {
                this.typeName = res.name;
            })
            .catch((err) => {
                this.errorLoggingService.logError('Could not get poi type', {
                    venueId: this.$state.params.venueId,
                    typeId: this.$state.params.typeId,
                });

                if (err.status === 404) {
                    this.$state.go('client.404', {
                        customerId: this.$state.params.customerId,
                        venueId: this.$state.params.venueId,
                    });
                } else {
                    this.hasDataError = true;
                    this.isLoading = false;
                }
            });
    }

    goBack() {
        this.navigationService.goBack('client.poi.types');
    }

    gotoPoi(poi) {
        this.$state.go('client.poi.details', { id: poi.id });
    }
}

export default PoiTypeInventoryController;
