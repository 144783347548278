import controller from './venue-hours-edit-order.controller';
import template from './venue-hours-edit-order.html';
import './venue-hours-edit-order.less';

const component = {
    template,
    bindings: {},
    controller: [
        '$state',
        '$uibModal',
        'crConstants',
        'crEntityService',
        'crNavigationService',
        'crErrorLoggingService',
        'crAnalyticsService',
        controller,
    ],
};

export default component;
