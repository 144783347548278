import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ImageGridComponent } from './image-grid.component';

@NgModule({
  imports: [CommonModule],
  declarations: [ImageGridComponent],
  exports: [ImageGridComponent],
  entryComponents: [ImageGridComponent],
})
export class CrImageGridModule {}
