import TabularOrdersPlacedController from './tabular-orders-placed.controller';
import template from './tabular-orders-placed.html';
import './tabular-orders-placed.less';

const TabularOrdersPlacedComponent = {
    template,
    controller: [
        '$state',
        'crReportingTabularOrdersPlacedMockService',
        'crConstants',
        'crDateRangeService',
        'crNavigationService',
        'crReportingTabularOrdersPlacedText',
        TabularOrdersPlacedController,
    ],
};

export default TabularOrdersPlacedComponent;
