import {
  Component, Input, OnInit,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { noop } from 'rxjs';

@Component({
  selector: 'cr-checkbox',
  templateUrl: 'checkbox.component.html',
  styleUrls: ['checkbox.component.scss'],
})

export class CheckboxComponent implements ControlValueAccessor, OnInit {
  @Input() label: string;

  control: FormControl;

  checked = false;

  isDisabled: boolean;

  constructor(private ngControl: NgControl) {
    this.ngControl.valueAccessor = this;
  }

  ngOnInit() {
    this.control = this.ngControl.control as FormControl;
  }

  onChange: (_?: any) => void = () => noop();

  onTouched: (_?: any) => void = () => noop();

  get value(): boolean {
    return this.checked;
  }

  set value(value: boolean) {
    this.checked = value;
    this.onChange(this.checked);
  }

  writeValue(value: boolean): void {
    this.value = value;
  }

  registerOnChange(fn): void {
    this.onChange = fn;
  }

  registerOnTouched(fn): void {
    this.onTouched = fn;
  }

  toggleCheckbox(): void {
    this.value = !this.checked;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}
