import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder, FormControl, FormGroup, Validators
} from '@angular/forms';
import { StateService } from '@uirouter/core';
import { Constants, LocalizedText, ValidationMessages } from '../../core';
import { AnalyticsService, AuthService, CR_CONSTANTS, UserService, ValidatorService } from '../../shared';

import text from './resources/locale/en.json';

@Component({
  selector: 'cr-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  text: LocalizedText;

  form: FormGroup;

  title: string;

  subtitle: string;

  username: string;

  showErrorIcon: boolean;

  pending: boolean;

  validationMessages: ValidationMessages;
  
  atLeast8Characters: boolean;

  hasUpperCase: boolean;

  hasLowerCase: boolean;

  hasNumber: boolean;

  constructor(
    @Inject(CR_CONSTANTS) private constants: Constants,
    private fb: FormBuilder,
    private state: StateService,
    private authService: AuthService,
    private analyticsService: AnalyticsService,
    private userService: UserService,
    private validators: ValidatorService,
  ) {
    this.text = text;
    this.atLeast8Characters = false;
    this.hasUpperCase = false;
    this.hasLowerCase = false;
    this.hasNumber = false;
  }

  ngOnInit() {
    this.title = this.text.title;
    this.subtitle = this.text.subtitle;
    this.username = this.state.params.username;

    this.form = this.fb.group({
      username: [{ value: this.username, disabled: true }],
      oldPassword: ['', Validators.required],
    });

    this.form.addControl(
      'newPassword',
      new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        this.validators.passwordRequirementsValidator,
        this.validators.passwordValidator,
        this.validators.noMatchValidator(this.form.controls.oldPassword),
      ]),
    );

    this.form.addControl(
      'confirmNewPassword',
      new FormControl('', [Validators.required, this.validators.matchValidator(this.form.controls.newPassword)]),
    );

    this.validationMessages = {
      match: this.text.errors.match,
      noMatch: this.text.errors.noMatch,
      notMet: this.text.errors.notMet
    };
  }

  onFocus(): void {
      this.atLeast8Characters = this.atLeast8Characters ?? false;
      this.hasUpperCase = this.hasUpperCase ?? false;
      this.hasLowerCase = this.hasLowerCase ?? false;
      this.hasNumber = this.hasNumber ?? false;
  }

  onBlur(): void {
      this.atLeast8Characters = this.atLeast8Characters ? this.atLeast8Characters : null;
      this.hasUpperCase = this.hasUpperCase ? this.hasUpperCase : null;
      this.hasLowerCase = this.hasLowerCase ? this.hasLowerCase : null;
      this.hasNumber = this.hasNumber ? this.hasNumber : null;
  }

  validatePassword(value: string): boolean {
      this.atLeast8Characters = value?.length >= 8;
      this.hasUpperCase = value?.length > 0 && this.constants.regex.UPPER_CASE.test(value);
      this.hasLowerCase = value?.length > 0 && this.constants.regex.LOWER_CASE.test(value);
      this.hasNumber = value?.length > 0 && this.constants.regex.NUMBER.test(value);
      const meetsRequirements = this.atLeast8Characters && this.hasUpperCase && this.hasLowerCase && this.hasNumber;

      return meetsRequirements && value !== this.form.value.oldPassword;
  }

  submit(): boolean {
    if (!this.form.valid || this.pending) {
      return false;
    }

    const { value } = this.form;
    this.pending = true;

    this.authService
      .password(value.oldPassword, value.newPassword)
      .then((res) => {
        if (res.error) {
          this.showErrorIcon = true;
          this.title = this.text.errors.server.title;
          this.subtitle = this.text.errors.server.subtitle;

          return false;
        }

        this.analyticsService.identify(this.username);
        this.analyticsService.track('First Time Sign In', { username: this.username });

        this.userService.login();
        this.state.go('client');
      })
      .catch((err) => {
        this.showErrorIcon = true;
        this.title = this.text.errors.server.title;
        this.subtitle = this.text.errors.server.subTitle;

        this.analyticsService.track('Change Password Failed', { username: this.username, error: err });
      })
      .finally(() => {
        this.pending = false;
      });
  }
}
