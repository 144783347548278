import { Component, Inject, OnInit, TemplateRef } from '@angular/core';
import { StateService } from "@uirouter/core";
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Constants, LocalizedText } from "../../../../core";
import { CR_CONSTANTS, ErrorLoggingService, NavigationService, ToastService, VenueService } from "../../../../shared";
import text from '../../resources/locale/en.json';
import { EntityService } from "../../../../shared/services/entity/entity.service";
import { LocaleService } from "../../../../shared/services/locale/locale.service";
import { PoiService } from "../../../../shared/services/poi/poi.service";
import { EntityType } from "../../../../shared/models/entity.model";
import { isEmpty } from "lodash";
import { getAutoStatusInfo, getIconUrl, replaceText } from "../../common/common.util";
import { statusList } from "../../constants/options";
import { PoiExtension, PoiModel } from "../../common/types";
import { InternalLegacyStatuses, LegacyStatuses, SNOAA_Type } from "../../constants/statuses";

const dayKeys = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

@Component({
    selector: 'cr-view-details',
    templateUrl: './view-details.component.html',
    styleUrls: ['./view-details.component.scss']
})
export class ViewDetailsComponent implements OnInit {

    text: LocalizedText = text;
    extensionId: string;
    poi: any;
    locale: any;
    isLoading = false;
    isLift = false;
    isTrail = false;
    hasDataError = false;
    title: string;
    iconSrc: string;
    tabId = 'lift';
    operationStatus = {label: '', labelName: '', status: '', color: ''};
    timezone: string;
    modalRef: BsModalRef;

    constructor(
        @Inject(CR_CONSTANTS) public constants: Constants,
        public state: StateService,
        private crEntityService: EntityService,
        private crLocaleService: LocaleService,
        private crPoiService: PoiService,
        private crNavigationService: NavigationService,
        private crErrorLoggingService: ErrorLoggingService,
        private crToastService: ToastService,
        venueService: VenueService,
        private modalService: BsModalService,
    ) {
        this.timezone = venueService.getTimezone(state.params.venueId).name;
    }

    ngOnInit(): void {
        this.tabId = this.state.params.tabId;
        this.poi = {};

        this.locale = this.crLocaleService.getLocale(this.state.params.venueId, this.state.params.locale);
        this.crToastService.autoToast(this.state.params);
        this.getData(EntityType.POIS, this.state.params.id, this.state.params.venueId, this.locale.current);
    }

    getData(entityType, id, venueId, locale) {
        this.isLoading = true;

        this.crEntityService.getEntity(entityType, id, venueId, locale, entityType)
            .then((poi: any) => {

                this.poi = {
                    ...poi,
                    displayCategories: poi.displayCategories || [],
                    attributes: poi.attributes || [],
                    externalIds: poi.externalIds || [],
                    _origPoi: poi,
                };

                if (poi.extensions) {
                    this.poi.legacyStatus = poi.extensions.find(ex => ex.id === InternalLegacyStatuses.INTERNAL_LEGACY_CURRENT_STATUS)?.value ?? {};
                    const ex:PoiExtension = poi.extensions.find(ex => ex.id.match(/^(internalLift|internalTrail|internalActivity)/)) ?? {};

                    if ((/^internalLift/).exec(ex.id))
                        this.poi.lift = ex.value || [];
                    if ((/^internalTrail/).exec(ex.id))
                        this.poi.trail = ex.value || [];
                    if ((/^internalActivity/).exec(ex.id))
                        this.poi.activity = ex.value || [];

                    this.extensionId = ex.id;
                    const type = this.state.params.tabId;

                    if (type === SNOAA_Type.LIFT) {
                        this.poi.details = this.poi.lift;
                        this.title = this.poi.details.name;
                        this.isLift = true;
                    }
                    else if (type === SNOAA_Type.TRAIL) {
                        this.poi.details = this.poi.trail;
                        this.title = this.poi.details.name;
                        this.isTrail = true;
                    }
                    else if (type === SNOAA_Type.ACTIVITY) {
                        this.poi.details = this.poi.activity;
                        this.title = this.poi.details.name;
                    }
                }

                if (this.poi.details) {
                    this.poi.details.groupedHours = this.groupHours(this.poi.details);
                    this.poi.details.iconTypeText = this.poi.details.type ? replaceText(this.poi.details.type) : '';
                    this.poi.details.trailIconText = this.poi.details.trailIcon ? replaceText(this.poi.details.trailIcon) : '';
                    this.poi.details.statusText = this.poi.details.statusLabel ? replaceText(this.poi.details.statusLabel) : '';
                    this.poi.details.status = this.poi.legacyStatus?.operationalStatus?.toLowerCase() ?? '';
                    this.iconSrc = this.poi.details.type ? getIconUrl(this.poi.details.SNOAAType, this.poi.details.type, this.text.imageUrl) : getIconUrl(this.poi.details.SNOAAType, this.poi.details.trailIcon, this.text.imageUrl);

                    this.operationStatus = (this.poi.details.autoStatus)
                        ? getAutoStatusInfo(this.poi.details, this.text, this.timezone)
                        : {
                            label: this.poi.details.statusLabel ?? '',
                            labelName: this.poi.details.statusLabel
                                ? statusList.find(s => s.id === this.poi.details.statusLabel)?.label
                                : '',
                            status: this.poi.legacyStatus.operationalStatus?.toLowerCase() ?? '',
                            color: this.poi.details.statusColor
                        };
                }
            })
            .catch((err) => {
                this.crErrorLoggingService.logError('Could not get poi details', err, id);

                if (err.status === 404) {
                    this.state.go('client.404', {
                        customerId: this.state.params.customerId,
                        venueId,
                    });
                } else {
                    this.hasDataError = true;
                }
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    groupHours(details) {
        const hoursToDaysIndex = {};
        const groupedByHours = [];

        if (details.hours) {
            dayKeys.forEach(day => {
                const hour = details.hours[day];
                if (!hour || !hour.open || !hour.open)
                    return;

                const key = `${hour.open}-${hour.close}`;
                let group = hoursToDaysIndex[key];
                if (!group) {
                    group = {...hour, days: []};
                    hoursToDaysIndex[key] = group;
                    groupedByHours.push(group);
                }
                group.days.push(day);
            });
            groupedByHours.forEach(g => g.daysNotation = g.days.map(d => d.substring(0, 1).toUpperCase()).join(' '));
            return groupedByHours;
        }
    }

    protected readonly isEmpty = isEmpty;

    delete() {
        this.modalRef.hide();

        const poiToSave: PoiModel = this.poi._origPoi;
        poiToSave.extensions = poiToSave.extensions.filter(ex => ex.id !== this.extensionId);
        poiToSave.extensions.forEach(ex => {
           if (ex.id === InternalLegacyStatuses.INTERNAL_LEGACY_CURRENT_STATUS) {
               ex.value.operationalStatus = LegacyStatuses.CLOSED;
               ex.value.waitTime = '';
           }
            ex.value = JSON.stringify(ex.value);
        });

        const payload = this.crEntityService.prepareEntityPayload(poiToSave);
        const {venueId} = this.state.params;
        this.isLoading = true;
        this.crEntityService
            .updateEntity(EntityType.POIS, this.poi.id, payload, venueId)
            .then(() => {
                this.crToastService.toast('Data successfully deleted.', '');
                this.state.go('client.lifts-trails-activities.list', { tabId: this.state.params.tabId });
            })
            .catch((err) => {
                this.handleError('Could not delete poi extension' + this.tabId, err);
                this.isLoading = false;
            })
            .finally(() => this.isLoading = false);
    }

    handleError(errMsg, err) {
        this.crErrorLoggingService.logError(errMsg, err);
    }

    public openModal(template: TemplateRef<any>): void {
        this.modalRef = this.modalService.show(template);
    }
}
