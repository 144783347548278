import angular from 'angular';

const DisableContextmenuPropagation = angular
    .module('control-room.commons.contents.directives.disable-contextmenu-propagation', [])
    .directive('crDisableContextmenuPropagation', () => ({
        link: (scope, elem) => {
            elem.on('contextmenu', (e) => {
                e.stopPropagation();
            });
        },
    }));

export default DisableContextmenuPropagation;
