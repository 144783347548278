import angular from 'angular';

import EntityHeaderModule from './entity-header/entity-header';
import CtaButtonConfigureModule from './cta-button-configure/cta-button-configure';
import CtaButtonBuilderRowModule from './cta-buttons-configure/cta-button-builder-row';
import CtaButtonsConfigureModule from './cta-buttons-configure/cta-buttons-configure';
import InputModule from './input/input';
import InputLegacyModule from './input-legacy/input';
import FormErrorModule from './form-error/form-error';
import FormLabelErrorModule from './form-label-error/form-label-error';
import FormSubmissionErrorModule from './form-submission-error/form-submission-error';
import SearchModule from './search/search';
import ToggleModule from './toggle/toggle';
import RadioModule from './radio/radio';
import CheckModule from './check/check';
import EnumBuilderModule from './enum-builder/enum-builder';
import InlineCheckModule from './inline-check/inline-check';
import HoursSelectorModule from './hours-selector/hours-selector';
import RequiredFieldModule from './required-field/required-field';
import RowBuilderLegacyModule from './row-builder-legacy/row-builder-legacy';
import RowBuilderModule from './row-builder/row-builder';
import ExtensionsBuilderModule from './extensions-builder/extensions-builder';
import HoursBuilderModule from './hours-builder/hours-builder';
import ChipSelectorModule from './chip-selector/chip-selector';
import EntityContentModule from './entity-content/entity-content';
import RowManagerModule from './row-manager/row-manager';
import EventScheduleModule from './event-schedule/event-schedule';
import ExperienceScheduleModule from './experience-schedule/experience-schedule';

const ComponentsModule = angular.module('control-room.commons.form.components', [
    EntityHeaderModule.name,
    CtaButtonConfigureModule.name,
    InputModule.name,
    InputLegacyModule.name,
    FormErrorModule.name,
    FormLabelErrorModule.name,
    FormSubmissionErrorModule.name,
    SearchModule.name,
    ToggleModule.name,
    RadioModule.name,
    CheckModule.name,
    EnumBuilderModule.name,
    InlineCheckModule.name,
    HoursSelectorModule.name,
    RequiredFieldModule.name,
    RowBuilderLegacyModule.name,
    RowBuilderModule.name,
    ExtensionsBuilderModule.name,
    HoursBuilderModule.name,
    ChipSelectorModule.name,
    EntityContentModule.name,
    RowManagerModule.name,
    EventScheduleModule.name,
    ExperienceScheduleModule.name,
    CtaButtonBuilderRowModule.name,
    CtaButtonsConfigureModule.name,
]);

export default ComponentsModule;
