import {
  Component, ElementRef, OnInit, ViewChild, ViewEncapsulation
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { StateService } from '@uirouter/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LocalizedText } from '../../../../../core';
import { ReservationService } from '../../../../../shared';
import { POI } from '../../../../../shared/models/poi.model';
import { PoiService } from '../../../../../shared/services/poi/poi.service';
import {
  CreateReservationObject,
  ReservationEvent,
  Schedule
} from '../../../../../shared/services/reservations/reservation.types';
import { SchedulesState } from './reservation-schedules/reservation-schedules.component';
import text from './resources/locale/en.json';

export enum EditMode {
  Preview,
  Edit,
  Create,
}

@Component({
  selector: 'cr-dining-location-editor',
  templateUrl: './dining-location-editor.component.html',
  styleUrls: ['./dining-location-editor.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class DiningLocationEditorComponent implements OnInit {
  static readonly DINING_LOCATIONS_STATE = 'client.fnb-reservations.dining-locations';

  @ViewChild('confirmArchiveModal', { static: true }) archiveModal: ElementRef;

  @ViewChild('archiveDisabledModal', { static: true }) archiveDisabledModal: ElementRef;

  @ViewChild('errorModal', { static: true }) errorModal: ElementRef;

  @ViewChild('confirmCancelModal', { static: true }) cancelModal: ElementRef;

  bsModalRef: BsModalRef;

  text: LocalizedText;

  poi: POI;

  poiId: string;

  schedules: Schedule[] = [];

  eventName: string;

  errorMessage: string;

  editMode: EditMode;

  editEvent: ReservationEvent;

  locationForm = new FormGroup({
    name: new FormControl(null, [Validators.required]),
    reservationUnit: new FormControl('GUEST', [Validators.required]),
    reservationMaxCapacity: new FormControl(0, [Validators.required, Validators.min(1), Validators.max(30)]),
    reservationDaysAhead: new FormControl(0, [Validators.required, Validators.min(1), Validators.max(31)]),
  });

  isLoading = false;

  private schedulesAreValid: boolean;

  private schedulesTouched = false;

  private newCancellationModification = false;

  private eventId: string;

  constructor(
    private stateService: StateService,
    private poiService: PoiService,
    private reservationService: ReservationService,
    private modalService: BsModalService,
  ) {
    this.text = text;
  }

  ngOnInit() {
    this.getReservationEventData();
  }

  getReservationEventData() {
    const { poiId, eventId, preview } = this.stateService.params;
    this.eventId = eventId || preview;

    if (this.eventId) {
      this.isLoading = true;
      this.editMode = eventId ? EditMode.Edit : EditMode.Preview;

      this.reservationService
        .getReservationEventById(this.eventId)
        .then((reservationEvent) => {
          this.locationForm.patchValue({
            name: reservationEvent.name,
            reservationUnit: reservationEvent.reservationUnit,
            reservationMaxCapacity: reservationEvent.maxQtyPerOrder,
            reservationDaysAhead: reservationEvent.daysAdvanceBookings,
          });

          const schedules = reservationEvent.scheduleGroup && reservationEvent.scheduleGroup.schedules
            ? reservationEvent.scheduleGroup.schedules.slice()
            : [];
          this.schedules = schedules.filter((schedule) => this.reservationService.scheduleIsActive(schedule));
          this.editEvent = reservationEvent;

          const poi = reservationEvent.associatedPois[0];
          this.poiId = poi.id;
          this.eventName = reservationEvent.name;
        })
        .catch(() => {
          this.displayErrorModal(this.text.error.errorFetchingEvent);
        })
        .then(() => {
          // Assuming incoming schedules are valid, as they've previously been created successfully
          this.newCancellationModification = false;
          this.schedulesAreValid = true;
          this.schedulesTouched = false;
          this.isLoading = false;
        });
    } else if (poiId) {
      this.isLoading = true;
      this.editMode = EditMode.Create;

      this.poiService
        .getPoi(poiId)
        .then((poi) => {
          this.poi = poi;
          this.poiId = poi.id;
          this.eventName = poi.name;
          this.schedules = [];

          this.locationForm.patchValue({
            name: poi.name,
          });
        })
        .catch(() => {
          this.displayErrorModal(this.text.error.errorFetchingPoi);
        })
        .then(() => {
          this.isLoading = false;
        });
    }
  }

  returnToDininLocations() {
    this.stateService.go('client.fnb-reservations.dining-locations');
  }

  navigateToEdit() {
    this.stateService
      .go(
        'client.fnb-reservations.dining-locations-editor',
        { eventId: this.eventId },
        {
          location: 'replace',
        },
      )
      .then(() => {
        this.getReservationEventData();
      });
  }

  selectReservationUnit(event) {
    this.locationForm.get('reservationUnit').setValue(event.target.value);
  }

  get formIsValid() {
    return this.locationForm.valid && this.schedulesAreValid;
  }

  get isReadOnly(): boolean {
    return this.editMode === EditMode.Preview;
  }

  get isCreateMode(): boolean {
    return this.editMode === EditMode.Create;
  }

  get pageTitle(): string {
    switch (this.editMode) {
      case EditMode.Create:
        return text.createTitle;
      case EditMode.Edit:
        return text.editTitle;
      default:
        return text.viewTitle;
    }
  }

  schedulesUpdated(schedulesState: SchedulesState) {
    this.schedules = schedulesState.schedules;
    this.schedulesAreValid = schedulesState.valid;
    this.schedulesTouched = true;
    this.newCancellationModification = schedulesState.newCancellationModification;
  }

  checkReservationEventBeforeSubmit() {
    if (this.newCancellationModification) {
      this.bsModalRef = this.modalService.show(this.cancelModal);
    } else {
      this.submitForm();
    }
  }

  async submitForm() {
    this.isLoading = true;

    if (this.bsModalRef) {
      this.bsModalRef.hide();
    }

    try {
      if (this.editMode === EditMode.Create) {
        await this.createReservationEvent();
      } else if (this.editMode === EditMode.Edit) {
        await this.editReservationEvent();
      }
      this.returnToDininLocations();
    } catch (error) {
      this.displayErrorModal(this.text.error.errorSavingEvent);
      console.error('Failed to save reservation event', error);

      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  get submitIsDisabled(): boolean {
    if (this.isLoading) {
      return true;
    }

    if (this.editMode === EditMode.Create) {
      return !this.formIsValid;
    }

    if (this.editMode === EditMode.Edit) {
      return (!this.locationForm.touched && !this.schedulesTouched) || !this.formIsValid;
    }

    return true;
  }

  displayErrorModal(message: string): void {
    this.errorMessage = message;
    this.bsModalRef = this.modalService.show(this.errorModal);
  }

  submitArchiveEvent() {
    this.reservationService.updateEventState(this.eventId, 'ARCHIVED').then(() => {
      this.bsModalRef.hide();
      this.stateService.go(DiningLocationEditorComponent.DINING_LOCATIONS_STATE);
    });
  }

  archiveEvent() {
    const schedules = this.schedules.slice();
    const noActiveSchedules = schedules.every((schedule) => !this.reservationService.scheduleIsActive(schedule));

    if (noActiveSchedules || !schedules || !schedules.length) {
      this.bsModalRef = this.modalService.show(this.archiveModal);
    } else {
      this.bsModalRef = this.modalService.show(this.archiveDisabledModal);
    }
  }

  editReservationEvent() {
    const {
      name, reservationUnit, reservationDaysAhead, reservationMaxCapacity,
    } = this.locationForm.value;

    const editEvent = {
      ...this.editEvent,
      name,
      title: name,
      reservationUnit,
      daysAdvanceBookings: reservationDaysAhead,
      maxQtyPerOrder: reservationMaxCapacity,
      scheduleGroup: {
        ...this.editEvent.scheduleGroup,
        schedules: this.schedules.slice(),
      },
    };

    return this.reservationService.updateEventById(this.eventId, editEvent);
  }

  createReservationEvent(): Promise<any> {
    const {
      name, reservationUnit, reservationDaysAhead, reservationMaxCapacity,
    } = this.locationForm.value;
    const schedules = this.schedules.slice();

    const eventObject: CreateReservationObject = {
      name,
      title: name,
      reservationUnit,
      daysAdvanceBookings: reservationDaysAhead,
      maxQtyPerOrder: reservationMaxCapacity,
      state: 'ACTIVE',
      eventType: 'reservation',
      scheduleGroup: {
        schedules,
      },
      associatedPois: [
        {
          id: this.poiId,
          name: this.poi.name,
          label: this.poi.name,
        },
      ],
      suggestedArrival: 'ON_TIME',
      owner: this.poi.owner,
    };

    return this.reservationService.createReservationEvent(eventObject);
  }

  get reservationDaysAhead() {
    return this.locationForm.controls.reservationDaysAhead.value || 0;
  }
}
