import template from './poi-type-list-modal.html';
import './poi-type-list-modal.less';
import PoiTypeListModalController from './poi-type-list-modal.controller';

const PoiTypeListModalComponent = {
    template,
    bindings: {
        close: '&',
        dismiss: '&',
        resolve: '<',
    },
    controller: ['$state', 'crPoiTypeListService', 'crConstants', 'crErrorLoggingService', PoiTypeListModalController],
};

export default PoiTypeListModalComponent;
