import angular from 'angular';

import EventDetailsModule from './event-details/event-details';
import EventFlyoutModule from './event-flyout/event-flyout';
import EventManageModule from './event-manage/event-manage';
import EventPhonePreview from './event-phone-preview/event-phone-preview';
import EventsListModule from './events-list/events-list';
import EventsScheduleModule from './events-schedule/events-schedule';
import FeaturedEventsModule from './featured-events/featured-events';

const Components = angular.module('control-room.events.components', [
    EventDetailsModule.name,
    EventFlyoutModule.name,
    EventManageModule.name,
    EventPhonePreview.name,
    EventsListModule.name,
    EventsScheduleModule.name,
    FeaturedEventsModule.name,
]);

export default Components;
