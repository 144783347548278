import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { CrSearchModule } from '../../../../shared/forms/search/search.module';
import { FeesService } from '../../../../shared/services/fees/fees.service';
import { CrTableModule } from '../../../../shared/table/table.module';

import { FeesListComponent } from './fees-list.component';

@NgModule({
  imports: [BrowserModule, CrTableModule, CrSearchModule, ReactiveFormsModule],
  exports: [],
  declarations: [FeesListComponent],
  entryComponents: [FeesListComponent],
  providers: [FeesService],
})
export class FeesListModule { }
