<span
    class="cr-error-icon icon-cr-error"
    data-global-qa-id="login-error-icon"
    [ngClass]="{ 'cr-invisible': !showErrorIcon }"
></span>
<h1 data-global-qa-id="login-title">{{ title }}</h1>
<div data-global-qa-id="login-sub-title" class="cr-sub-title">
    {{ subtitle }}
</div>
<div *ngIf="sessionExpired" class="session-expired-header">
    <div class="timeout-message-wrapper">
        <div class="info-icon icon-cr-info-line"></div>
        <label>{{ text.timeoutSubTitle }}</label>
    </div>
        <button
        class="btn dismiss-button"
        (click)="handleTimeoutDismiss()">
        {{ text.dismiss }}
    </button>
</div>
<form [formGroup]="form" (submit)="login()" data-global-qa-id="login-form">
    <div class="cr-inputs">
        <cr-input
            label="{{ text.username }}"
            type="text"
            class="stretch login-input"
            formControlName="username"
        ></cr-input>
        <cr-input
            label="{{ text.password }}"
            type="password"
            class="stretch login-input"
            formControlName="password"
        ></cr-input>
        <button
            type="submit"
            class="btn btn-primary"
            data-global-qa-id="login-button"
        >
            <div
                class="cr-submit-spinner"
                [ngClass]="{ 'cr-invisible': !pending }"
            >
                <cr-spinner></cr-spinner>
            </div>
            <span [ngClass]="{ 'cr-invisible': pending }">
                {{ text.signIn }}
            </span>
        </button>
    </div>
</form>
