import template from './radio.html';
import './radio.less';
import RadioController from './radio.controller';

const RadioComponent = {
    template,
    require: {
        form: '?^form',
    },
    bindings: {
        options: '<',
        disabled: '<',
        required: '@',
        name: '@',
        model: '=?',
        onChange: '&',
    },
    controller: RadioController,
    controllerAs: 'radioCtrl',
};

export default RadioComponent;
