import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder, FormGroup, Validators
} from '@angular/forms';
import { StateService } from '@uirouter/core';
import _ from 'lodash';
import { BehaviorSubject, from, Observable, Subscription } from 'rxjs';
import {
  finalize,
  map, share, switchMap, tap
} from 'rxjs/operators';
import {
  Config, LocalizedText, SelectOption, ValidationMessages
} from '../../../../core';
import { CommerceService, CrEvent, CR_CONFIG, DiscountType, Image, MenuService, NavigationService } from '../../../../shared';
import { ChipTag } from '../../../../shared/forms/chip-selector/chip-selector.component';
import { CrDropdownUpgradedComponentItem } from '../../../../shared/forms/dropdown/dropdown-upgraded.component';
import { EntityConentComponentUpdateEvent, EntityContentComponentModel } from '../../../../shared/forms/entity-content/entity-content.component';
import {
  EntityHeaderComponentModel,
  EntityHeaderComponentUpdateEvent
} from '../../../../shared/forms/entity-header/entity-header.component';
import { BundleGroupListData } from '../../../../shared/services/bundle-groups/bundle-groups.model';
import { BundleGroupsService } from '../../../../shared/services/bundle-groups/bundle-groups.service';
import { Bundle } from '../../../../shared/services/bundle/bundle.model';
import { BundleService } from '../../../../shared/services/bundle/bundle.service';
import FeatureFlagsService from '../../../../shared/services/feature-flags/feature-flags';
import { Locale } from '../../../../shared/services/locale/locale.model';
import { LocaleService } from '../../../../shared/services/locale/locale.service';
import { menuRoutes } from '../../menus.routes';
import { Validator } from './bundle-manage.validators';
import text from './resources/locale/en.json';

export interface CrDropdownEvent{
  $event:{
    model: [{
      id: string,
      isSelected: boolean,
      label: string,

    }]
  }
}

@Component({
  selector: 'cr-bundle-manage',
  templateUrl: './bundle-manage.component.html',
  styleUrls: ['./bundle-manage.component.scss'],
})
export class BundleManageComponent implements OnInit, OnDestroy {
  edit = true;

  isSubmitted = false;

  isLoading = false;

  isSaving = false;

  images : Image[] = [];

  text: LocalizedText;

  bundleContent: EntityHeaderComponentModel | EntityContentComponentModel;

  form: FormGroup;

  isLoadingDiscounts = true;

  isLoadingCategories = true;

  showTaxCodes = false;

  addBundleGroupFormControl = this.formBuilder.control('');

  discountTypeOptions$: Observable<SelectOption[]>;

  bundleGroupOptions$: Observable<CrDropdownUpgradedComponentItem[]>;

  selectedBundleGroups$: Observable<BundleGroupListData[]>;

  locale: Locale;

  selectedCategoryChips: ChipTag[];

  categoryChips$: Observable<ChipTag[]>;

  pageHeader: string;

  currencyCode: string;

  validationMessages: ValidationMessages;

  loadedBundleItem: Bundle;

  private discountTypes$: Observable<DiscountType[]>;

  private selectedBundleGroups$$ = new BehaviorSubject<string[]>([]);

  private venueId: string;

  private onSubmitSubscription: Subscription;

  private getBundleGroupSubscription: Subscription;

  private addBundleGroupSubscription: Subscription;

  constructor(
    @Inject(CR_CONFIG) private config: Config,
    private commerceService: CommerceService,
    private menuService: MenuService,
    private bundleGroupService: BundleGroupsService,
    private bundleService: BundleService,
    private localeService: LocaleService,
    private state: StateService,
    private navigationService: NavigationService,
    private featureFlagsService: FeatureFlagsService,
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.text = text;
    this.showTaxCodes = this.featureFlagsService.hasFeature('manage_tax_codes') as boolean;
    this.validationMessages = {
      notInteger: this.text.notIntegerError as string,
      incorrectFormat: this.text.incorrectFormatError as string,
      requiredBundles: this.text.requiredBundles as string,
    };
    this.edit = (this.state.current.data.edit) as boolean || false;
    this.pageHeader = (this.edit ? text.editBundlePageHeader : text.createBundlePageHeader);
    this.bundleContent = {
      name: '',
      state: '',
    };
    this.venueId = this.state.params.venueId as string;

    this.locale = this.localeService.getLocale(
      this.venueId,
      this.state.params.locale,
      !this.edit,
    );
    this.currencyCode = _.find(this.config.customer.venues, {
      id: this.venueId,
    }).currencyCode;

    this.initDiscountTypes();
    this.initCategories();
    this.initBundleGroups();
    this.initForm();
    window.scroll(0, 0);
  }

  ngOnDestroy(): void {
    if (this.onSubmitSubscription) {
      this.onSubmitSubscription.unsubscribe();
    }

    if (this.getBundleGroupSubscription) {
      this.getBundleGroupSubscription.unsubscribe();
    }

    if (this.addBundleGroupSubscription) {
      this.addBundleGroupSubscription.unsubscribe();
    }
  }

  onSave(): void {
    this.isSubmitted = true;
    Object.values(this.form.controls).forEach((c) => c.markAllAsTouched());
    if (this.form.invalid) {
      return;
    }
    this.isSaving = true;
    if (this.edit) {
      const bundleId: string = this.state.params.id as string;
      this.onSubmitSubscription = this.bundleService.updateBundle(
        this.state.params.placeId,
        this.state.params.menuId,
        this.state.params.id,
        {
          id: bundleId,
          ...this.form.value,
        },
      ).subscribe(() => this.goBack());
    } else {
      this.onSubmitSubscription = this.bundleService.createBundle(
        this.state.params.placeId,
        this.state.params.menuId,
        this.form.value,
      ).subscribe(() => this.goBack());
    }
  }

  onHeaderUpdated({ $event: { model } }: EntityHeaderComponentUpdateEvent): void {
    this.form.get('displayName').setValue(model.name);
    this.form.get('state').setValue(model.state);
    this.setImages(model.images, 'STICKER');
  }

  onContentUpdated({ $event: { model } }: CrEvent<EntityConentComponentUpdateEvent>): void {
    if (model) {
      this.form.get('content.description').setValue(model.description);
      this.form.get('content.name').setValue(model.title);
      this.setImages(model.images, 'MAIN');
    }
  }

  onCategorySelected(tags: ChipTag[]): void {
    if (tags) {
      this.selectedCategoryChips = tags;
      this.form.get('categories').setValue(tags.map((tag) => tag.id));
    }
  }

  goBack(): void {
    this.navigationService.goBack(menuRoutes.PRODUCTS, {
      menuId: this.state.params.menuId,
      placeId: this.state.params.placeId,
      tabId: 'menu-bundles',
    });
  }

  private setImages(newImages: Image[], type: string) {
    const existingImages = this.form.get('images').value as Image[];
    if (existingImages && newImages) {
      const filteredImages = existingImages.filter((i) => i.type !== type);
      const images = newImages.filter((i) => i.type === type);
      this.form.get('images').setValue([...filteredImages, ...images]);
    }
  }

  private initForm(): void {
    this.form = this.formBuilder.group({
      externalId: [],
      externalPriceCode: [],
      displayName: [''],
      state: [''],
      images: [[]],
      categories: [[], [(ctrl) => Validators.required(ctrl)]],
      price: ['', [
        (ctrl) => Validators.required(ctrl),
        (ctrl) => Validator.incorrectFormat(ctrl),
      ]],
      maxQuantity: ['', [(ctrl) => Validators.required(ctrl), (ctrl) => Validator.notInteger(ctrl)]],
      productBundleGroupIds: [[], [(ctrl) => Validators.required(ctrl)]],
      productCode: [null, [(ctrl) => Validators.required(ctrl)]],
      content: this.formBuilder.group({
        name: [''],
        description: [''],
      }),
    });

    if (!this.showTaxCodes) {
      this.form.get('productCode').disable();
    }

    if (this.state.params.id) {
      this.isLoading = true;
      this.getBundleGroupSubscription = this.bundleService.getBundle(
        this.state.params.placeId,
        this.state.params.menuId,
        this.state.params.id,
      ).pipe(finalize(() => {
        this.isLoading = false;
      })).subscribe((bundle) => {
        this.form.patchValue({
          externalId: bundle.externalId,
          externalPriceCode: bundle.externalPriceCode,
          displayName: bundle.displayName,
          state: bundle.state,
          images: bundle.images || [],
          categories: bundle.categories.map((cat) => cat.id) || [],
          price: bundle.price.toFixed(2),
          maxQuantity: bundle.maxQuantity,
          productBundleGroupIds: bundle.productBundleGroupIds || [],
          content: bundle.content,
        });
        this.bundleContent = {
          title: bundle.content ? bundle.content.name : '',
          name: bundle.displayName,
          state: bundle.state,
          description: bundle.content ? bundle.content.description : '',
          images: bundle.images || [],
        };
        if (bundle.categories) {
          this.selectedCategoryChips = bundle.categories.map((cat) => ({
            id: cat.id,
            label: cat.displayName,
            value: cat.id,
          } as ChipTag));
        }

        if (bundle.productBundleGroupIds.length && bundle.productBundleGroupIds.length > 0) {
          this.selectedBundleGroups$$.next([
            ...bundle.productBundleGroupIds,
          ]);
        }
      });
    }
    if (this.state.params.loadedBundleItem) {
      const { loadedBundleItem } = this.state.params;
      this.form.patchValue({
        externalId: loadedBundleItem.externalId,
        externalPriceCode: loadedBundleItem.externalPriceCode,
        displayName: loadedBundleItem.displayName,
        state: loadedBundleItem.state,
        images: loadedBundleItem.images || [],
        categories: loadedBundleItem.categories.map((cat) => cat.id) || [],
        price: loadedBundleItem.price.toFixed(2),
        maxQuantity: loadedBundleItem.maxQuantity,
        productBundleGroupIds: loadedBundleItem.productBundleGroupIds || [],
        content: loadedBundleItem.content,
      });

      if (this.form && loadedBundleItem) {
        this.form.get('content.name').setValue(loadedBundleItem.displayName);
      }

      this.bundleContent = {
        title: loadedBundleItem.displayName,
        name: loadedBundleItem.content ? loadedBundleItem.content.name : '',
        state: loadedBundleItem.state,
        description: loadedBundleItem.content ? loadedBundleItem.content.description : '',
        images: loadedBundleItem.images || [],
      };

      if (loadedBundleItem.categories) {
        this.selectedCategoryChips = loadedBundleItem.categories.map((cat) => ({
          id: cat.id,
          label: cat.displayName,
          value: cat.id,
        } as ChipTag));
      }

      if (loadedBundleItem.productBundleGroupIds) {
        this.selectedBundleGroups$$.next([
          ...this.selectedBundleGroups$$.value,
          ...loadedBundleItem.productBundleGroupIds,
        ]);
      }
    }
  }

  private initDiscountTypes(): void {
    this.discountTypes$ = from(this.commerceService.getDiscountTypes());
    this.discountTypeOptions$ = this.discountTypes$.pipe(map((discountTypes) => discountTypes.map((type) => ({
      value: type.id,
      label: type.displayName,
    }))));
  }

  private initCategories(): void {
    this.isLoadingCategories = true;
    const categories = this.menuService.getMenuCategoriesWithHierarchy(
      this.state.params.menuId,
      this.state.params.placeId,
      this.state.params.venueId,
      this.locale.current,
    ).pipe();

    this.categoryChips$ = categories.pipe(
      map((cats) => cats.map((cat) => ({
        label: cat.hierarchyLabel,
        id: cat.id,
        parentId: cat.parentId,
      }))),
      tap(() => {
        this.isLoadingCategories = false;
      }),
    );
  }

  private initBundleGroups() {
    this.bundleGroupOptions$ = this.filterBundleGroupOptions('removeSelected').pipe(
      map((bundleGroups) => bundleGroups.map((bundleGroup) => ({
        id: bundleGroup.id,
        label: bundleGroup.displayName,
        value: bundleGroup,
      }))),
    );

    this.selectedBundleGroups$ = this.filterBundleGroupOptions('selectedOnly');

    this.addBundleGroupSubscription = this.addBundleGroupFormControl.valueChanges.subscribe(
      (bundleGroup: { id: string }) => {
        if (bundleGroup) {
          const newSelectedValues = [...this.selectedBundleGroups$$.value, bundleGroup.id];
          this.selectedBundleGroups$$.next(newSelectedValues);
          this.addBundleGroupFormControl.setValue(null);
          this.form.get('productBundleGroupIds').setValue(newSelectedValues);
        }
      },
    );
  }

  onBundleGroupSelectionChange($event: CrDropdownEvent) {
    if($event?.$event?.model[0]) {
      const newSelectedValues = [...this.selectedBundleGroups$$.value, $event.$event.model[0].id];
      this.selectedBundleGroups$$.next(newSelectedValues);
      this.addBundleGroupFormControl.setValue(null);
      this.form.get('productBundleGroupIds').setValue(newSelectedValues);
    }
  }

  removeBundleGroup(bundleGroupId: string): void {
    const newSelectedValues = [...this.selectedBundleGroups$$.value].filter((id) => id !== bundleGroupId);
    this.selectedBundleGroups$$.next(newSelectedValues);
    this.form.get('productBundleGroupIds').setValue(newSelectedValues);
  }

  reorder($event: { currentIndex: number, previousIndex: number }) {
    const newSelectedValues = [...this.form.get('productBundleGroupIds').value];
    const movedItem = newSelectedValues.splice($event.previousIndex, 1)[0];
    newSelectedValues.splice($event.currentIndex, 0, movedItem);
    this.form.get('productBundleGroupIds').setValue(newSelectedValues);
  }

  /*
   * Filter bundle groups.
   * selectedOnly - Returns only selected bundle groups.
   * removeSelected - Returns only bundle groups which haven't been selected.
   */
  private filterBundleGroupOptions(filter: 'selectedOnly' | 'removeSelected') {
    const bundleGroups$ = this.bundleGroupService.getBundleGroupsList(
      this.state.params.placeId,
      this.state.params.menuId,
    ).pipe(share());

    return this.selectedBundleGroups$$.pipe(
      switchMap((selectedGroups) => bundleGroups$.pipe(
        share(),
        map((bundleGroups) => {
          const filteredGroups = bundleGroups.filter((bundleGroup) => {
            switch (filter) {
              case 'selectedOnly':
                return selectedGroups.includes(bundleGroup.id);
              case 'removeSelected':
                return !selectedGroups.includes(bundleGroup.id);
              default:
                throw new Error(`Unknown filter ${filter as string}`);
            }
          });
          if (filter === 'selectedOnly') {
            filteredGroups.sort((a, b) => selectedGroups.indexOf(a.id) - selectedGroups.indexOf(b.id));
          }
          return filteredGroups;
        }),
      )),
    );
  }
}
