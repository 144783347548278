import _ from 'lodash';

class ChangePasswordModalController {
    constructor($timeout, authService, text, validators, errorLoggingService, crConstants, crAnalyticsService) {
        this.$timeout = $timeout;
        this.authService = authService;
        this.text = text;
        this.validators = validators;
        this.errorLoggingService = errorLoggingService;
        this.regex = crConstants.regex;
        this.crAnalyticsService = crAnalyticsService;
        this.atLeast8Characters = false;
        this.hasUpperCase = false;
        this.hasLowerCase = false;
        this.hasNumber = false;
    }

    onFocus() {
        this.errorMessage = '';
        this.atLeast8Characters = this.atLeast8Characters ?? false;
        this.hasUpperCase = this.hasUpperCase ?? false;
        this.hasLowerCase = this.hasLowerCase ?? false;
        this.hasNumber = this.hasNumber ?? false;
    }

    onBlur() {
        this.atLeast8Characters = this.atLeast8Characters ? this.atLeast8Characters : null;
        this.hasUpperCase = this.hasUpperCase ? this.hasUpperCase : null;
        this.hasLowerCase = this.hasLowerCase ? this.hasLowerCase : null;
        this.hasNumber = this.hasNumber ? this.hasNumber : null;
    }

    validatePassword(value) {
        this.atLeast8Characters = value?.length >= 8;
        this.hasUpperCase = value?.length > 0 && this.regex.UPPER_CASE.test(value);
        this.hasLowerCase = value?.length > 0 && this.regex.LOWER_CASE.test(value);
        this.hasNumber = value?.length > 0 && this.regex.NUMBER.test(value);
        const meetsRequirements = this.atLeast8Characters && this.hasUpperCase && this.hasLowerCase && this.hasNumber;

        return meetsRequirements && value !== this.oldPassword;
    }

    onSubmit(e) {
        e.stopPropagation();

        if (!this.form.$valid || this.pending) {
            return false;
        }

        this.pending = true;

        this.$timeout(() => {
            this.authService
                .password(this.oldPassword, this.newPassword)
                .then((data) => {
                    if (data.error) {
                        this.errorMessage = this.text.errors.server;
                        return false;
                    }

                    this.crAnalyticsService.track('Change Password - Success');
                    this.close();
                })
                .catch((res) => {
                    const errorMessage = _.get(res.error, 'data.errors.0');
                    if (errorMessage) {
                        this.errorMessage = Object.values(errorMessage)[0];
                    } else {
                        this.errorMessage = this.text.errors.server;
                    }
                    this.crAnalyticsService.track('Change Password - Failure');
                })
                .finally(() => {
                    this.pending = false;
                });
        }, 1500);
    }

    onCancel() {
        this.close(false);
    }
}

export default ChangePasswordModalController;
