import _ from 'lodash';
import { menuRoutes } from '../../menus.routes';
import text from './resources/locale/en.json';

class MenuManage {
    constructor(
        $state,
        $q,
        $uibModal,
        crAnalyticsService,
        crConstants,
        crEntityService,
        crErrorLoggingService,
        crMenusService,
        crNavigationService,
        crPlacesService
    ) {
        this.$state = $state;
        this.$q = $q;
        this.$uibModal = $uibModal;
        this.crAnalyticsService = crAnalyticsService;
        this.crConstants = crConstants;
        this.crEntityService = crEntityService;
        this.crErrorLoggingService = crErrorLoggingService;
        this.crMenusService = crMenusService;
        this.crNavigationService = crNavigationService;
        this.crPlacesService = crPlacesService;
    }

    $onInit() {
        const { venueId } = this.$state.params;
        this.placeId = this.$state.params.placeId;
        this.menuId  = this.$state.params.menuId;

        this.crNavigationService.enableConfirmNavigation(() => this.form.$dirty);
        this.text = text;
        this.loading = true;
        this.disableState = true;
        this.states = [
            { id: 'ACTIVE', label: this.text.active },
            { id: 'INACTIVE', label: this.text.inactive },
            { id: 'DRAFT', label: this.text.draft },
        ];
        this.splitActions = [
            {
                label: this.text.archive,
                action: () => {
                    this.deleteMenu();
                },
            },
        ];

        if (this.edit) {
            this.loadMenu()
                .then(() => this.loadKitchens())
                .then(() => {
                    this.loading = false;
                });

            this.crMenusService.getMenuLayout(venueId, this.placeId, this.menuId).then((response) => {
                const menuLayout = { categories: response.content };
                this.hasLayout = menuLayout.categories.length > 0;
                this.menuCategories = this.mapMenuToPanes(menuLayout).filter((category) => category.categoryGroup !== 'PRINTER');
                this.menuLayoutLoaded = true;
            });
        } else {
            // Bring in the kitchen id that was set in the create menu modal
            this.kitchen = this.$state.params.kitchenId;
            this.initMenu();
            this.loadKitchens().then(() => {
                this.loading = false;
            });
        }
    }

    get titleBarLabel() {
        return this.edit ? this.text.editTitle : this.text.createTitle;
    }

    createMenu() {
        this.crMenusService
            .createMenu(this.$state.params.venueId, this.kitchen, this.menu)
            .then((response) => this.onSaveSuccess(response.id, this.$state.params.kitchenId))
            .catch((err) => this.onSaveError('Could not create menu', err))
            .finally(() => {
                this.saving = false;
            });
    }

    deleteMenu() {
        if (this.isLoading) {
            return;
        }

        this.$uibModal
            .open({
                backdrop: 'static',
                component: 'crActionSummaryModal',
                resolve: {
                    type: () => this.crConstants.modalTypes.SUBMIT,
                    submitText: () => this.text.archiveModalSubmit,
                    title: () => this.text.archiveTitle,
                    description: () => this.text.archiveDescription,
                    imageShape: () => 'hide',
                    items: () => [this.menu],
                },
            })
            .result.then(() => {
                this.isLoading = true;
                this.crMenusService
                    .deleteMenu(this.$state.params.venueId, this.$state.params.placeId, this.menu.id)
                    .then(() => {
                        this.$state.go(menuRoutes.KITCHEN, {
                            toast: {
                                msg: `"${this.menu.content.name}" ${this.text.hasBeenArchived}`,
                            },
                            kitchenId: this.$state.params.placeId,
                        });
                    })
                    .catch(() => {
                        this.isLoading = false;
                        this.$uibModal
                            .open({
                                backdrop: 'static',
                                component: 'crSimpleModal',
                                windowClass: 'cr-modal-size-sm',
                                resolve: {
                                    message: () => `${this.text.problemArchiving} "${this.menu.content.name}"`,
                                },
                            })
                            .result.catch(() => null);
                    });
            })
            .catch(() => null);
    }

    goBack() {
        this.crAnalyticsService.track('Create Menu Canceled');
        if (this.$state.params.fromDetails) {
            this.crNavigationService.goBack(menuRoutes.PRODUCTS, { menuId: this.menuId, placeId: this.placeId, tabId: 'overview' });
        }
        else if (this.kitchen) {
            this.crNavigationService.goBack(menuRoutes.KITCHEN, { kitchenId: this.kitchen });            
        }
        else {
            this.crNavigationService.goBack(menuRoutes.KITCHEN, { kitchenId: this.$state.params.placeId });
        }
    }

    initMenu() {
        this.menu = {
            content: {},
            state: 'DRAFT',
            menuClass: 'DINING',
        };

        this.states[2].isSelected = true;
    }

    loadKitchens() {
        const params = {
            sort: 'asc',
            sortby: 'state',
            state: 'enabled',
            venueId: this.$state.params.venueId,
        };
        this.kitchenMap = {};
        return this.crPlacesService.getPlaces(params).then((res) => {
            this.kitchens = res.places;
            this.kitchens.forEach((kitchen) => {
                this.kitchenMap[kitchen.id] = kitchen;
            });
            if (this.kitchen) {
                this.currentKitchen = this.kitchenMap[this.kitchen];
            }
        });
    }

    loadMenu() {
        const { venueId } = this.$state.params;
        const { placeId } = this.$state.params;
        return this.crMenusService.getMenu(venueId, placeId, this.$state.params.menuId).then((data) => {
            this.menu = data;
            this.kitchen = this.menu.placeInfo.id;

            // Prime the content block if it was null/undefined
            if (!this.menu.content) {
                this.menu.content = {};
            }

            if (!this.menu.menuClass) {
                this.menu.menuClass = 'DINING';
            }

            const params = {
                page: 1,
                perPage: 1,
                sort: 'asc',
                sortby: 'name',
                state: this.crConstants.entity.states.ACTIVE,
                venueId,
                placeId,
            };
            const productType = this.crConstants.entity.types.MENU_PRODUCTS;
            const productRoute = `menus/${this.menu.id}/${productType}`;

            const categoryType = this.crConstants.entity.types.MENU_CATEGORIES;
            const categoryRoute = `menus/${this.menu.id}/${categoryType}`;

            return Promise.all([
                this.crEntityService.getEntityList(productRoute, params),
                this.crEntityService.getCategoriesWithHierarchy(categoryType, venueId, null, categoryRoute, {
                    placeId,
                }),
            ]).then((result) => {
                if (result[0].content.length > 0 && result[1].length > 0) {
                    this.selectedClass = this.menu.state;
                    this.states = this.states.map((state) => {
                        state.isSelected = state.id === this.menu.state;
                        return state;
                    });
                    this.disableState = false;
                } else {
                    this.states[2].isSelected = true;
                }
            });
        });
    }

    mapMenuToPanes(menuLayout) {
        menuLayout.categories.forEach((category) => {
            category.expanded = true;
            category.label = category.displayName;
            category.products.forEach((product) => {
                product.isProduct = true;
                product.label = `${product.displayName} - ${product.price}`;
            });
            category.bundles.forEach((product) => {
                product.isBundle = true;
                product.label = `${product.displayName} - ${product.price}`;
            });
            if(category.bundles.length > 0 && category.products.length > 0) {
                category.hasChildHeader = true;
                category.children = [{isChildHeader: true, label: this.text.menuItems}, ...category.products,
                                    {isChildHeader: true, label: this.text.bundles}, ...category.bundles];
                category.children.forEach((product) => {
                    if(!product.isChildHeader) {
                        product.isUnderSubCategory = true;
                    }
                });
            }
            else {
                category.children = [...category.products, ...category.bundles];
            }
        });
        return menuLayout.categories;
    }

    onKitchenUpdate(event) {
        this.kitchen = event.model[0].id;
    }

    onNameUpdate(event) {
        this.menu.content.name = event.model;
    }

    onPdfChange(event) {
        this.pdfLink = event.pdf.url;

        if (this.pdfLink) {
            _.set(this.menu, 'links.0', { type: 'NUTRITION_INFO', ref: this.pdfLink });
        } else {
            _.unset(this.menu, 'links');
        }
    }

    onPaneChange(event) {
        this.newRanks = event.map((category) => ({
            id: category.id,
            displayName: category.displayName,
            content: category.content,
            state: category.state,
            maxQuantity: category.maxQuantity,
            productIds: category.children.filter(item => item.isProduct).map(item => item.id),
            bundleIds: category.children.filter(item => item.isBundle).map(item => item.id)
        }));
    }

    onSaveError(errMsg, err) {
        const message = this.edit ? this.text.updateError : this.text.createError;

        this.crErrorLoggingService.logError(errMsg, err);

        this.$uibModal
            .open({
                backdrop: 'static',
                component: 'crSimpleModal',
                windowClass: 'cr-modal-size-sm',
                resolve: {
                    closeText: () => this.text.closeError,
                    message: () => message,
                },
            })
            .result.catch(() => null);
    }

    onSaveSuccess(menuId, placeId) {
        this.crNavigationService.disableConfirmNavigation();
        const params = {
            menuId,
            placeId,
        };

        if (this.edit) {
            this.crAnalyticsService.track('Edit Menu Success', this.menu);
            this.crNavigationService.goBack(menuRoutes.PRODUCTS, params);
        } else {
            this.crAnalyticsService.track('Add New Menu Success', this.menu);
            this.crNavigationService.goToFromCreate(menuRoutes.PRODUCTS, params);
        }
    }

    onStateUpdate(event) {
        this.selectedClass = event.model[0].id;
        this.menu.state = event.model[0].id;
    }

    save() {
        this.form.$setSubmitted();
        if (this.form.$valid) {
            this.saving = true;

            if (this.edit) {
                this.updateMenu();
            } else {
                this.createMenu();
            }
        }
    }

    updateMenu() {
        const { venueId } = this.$state.params;
        const { placeId } = this.$state.params;
        const menuId = this.menu.id;

        const menuPromise = this.crMenusService.updateMenu(venueId, placeId, menuId, this.menu);

        const rankPromise = !this.newRanks
            ? Promise.resolve(true)
            : this.crMenusService.updateMenuRanks(menuId, this.newRanks);

        this.$q
            .all([menuPromise, rankPromise])
            .then(() => this.onSaveSuccess(this.menu.id, this.$state.params.placeId))
            .catch((err) => this.onSaveError('Could not update menu', err))
            .finally(() => {
                this.saving = false;
            });
    }
}

export default MenuManage;
