import Controller from './week-picker.controller';
import template from './week-picker.html';
import './week-picker.less';

const Component = {
    template,
    bindings: {
        onUpdate: '&',
        range: '<',
    },
    controller: ['$state', 'crConstants', 'crVenueService', 'crAnalyticsService', Controller],
};

export default Component;
