import * as angular from 'angular';
import { Ng1PolicyCheckModule } from './basic/policy-check/policy-check.ng1';
import { Ng1ExportButtonModule } from './export-button/export-button.ng1';
import { Ng1ImageGridModule } from './layout/image-grid/image-grid.module.ng1';
import { Ng1AnalyticsModule } from './services/analytics/analytics.ng1';
import { Ng1AuthModule } from './services/auth/auth.ng1';
import { Ng1BeaconsModule } from './services/beacons/beacons.ng1';
import { Ng1CommerceModule } from './services/commerce/commerce.ng1';
import { Ng1ConfigModule } from './services/config/config.ng1';
import { Ng1ConfigurationModule } from './services/configuration/configuration.service.ng1';
import { Ng1ConstantsModule } from './services/constants/constants.ng1';
import { Ng1CouponsModule } from './services/coupons/coupons.ng1';
import { Ng1CustomerModule } from './services/customer/customer.ng1';
import { Ng1DateRangeModule } from './services/date-range/date-range.ng1';
import { Ng1EntityModule } from './services/entity/entity.ng1';
import { Ng1ErrorLoggingModule } from './services/error-logging/error-logging.ng1';
import { Ng1ExperiencesModule } from './services/experiences/experiences.ng1';
import { Ng1EventTabsModule } from './services/event-tabs/event-tabs.ng1';
import { Ng1FeatureFlagsModule } from './services/feature-flags/feature-flags.ng1';
import { Ng1FeesModule } from './services/fees/fees.ng1';
import { Ng1FileUploadModule } from './services/file-upload/file-upload.ng1';
import { Ng1GraphicalMapModule } from './services/graphical-map/graphical-map.ng1';
import { Ng1ImageModule } from './services/image/image.ng1';
import { Ng1KitchenModule } from './services/kitchen/kitchen.ng1';
import { Ng1LocaleModule } from './services/locale/locale.service.ng1';
import { Ng1LocalizationModule } from './services/localization/localization.ng1';
import { Ng1LockerModule } from './services/lockers/lockers.ng1';
import { Ng1LoQueueModule } from './services/loqueue/loqueue.ng1';
import { Ng1MenusModule } from './services/menus/menu.ng1';
import { Ng1NavigationModule } from './services/navigation/navigation.ng1';
import { Ng1OrderModule } from './services/order/order.ng1';
import { Ng1PlacesModule } from './services/places/places.ng1';
import { Ng1PoiMapModule } from './services/poi-map/poi-map.ng1';
import { Ng1PoiModule } from './services/poi/poi.ng1';
import { Ng1PolicyModule } from './services/policy/policy.ng1';
import { Ng1PrintManagerModule } from './services/print-manager/print-manager.ng1';
import { Ng1PrinterModule } from './services/printer/printer.ng1';
import { Ng1QuestionModule } from './services/question/question.ng1';
import { Ng1QuicksightModule } from './services/quicksight/quicksight.ng1';
import { Ng1ReportFileModule } from './services/report-file/report-file.ng1';
import { Ng1ReportsModule } from './services/reports/reports.ng1';
import { Ng1ScheduleModule } from './services/schedule/schedule.ng1';
import { Ng1ScrollModule } from './services/scroll/scroll.ng1';
import { Ng1TagModule } from './services/tag/tag.ng1';
import { Ng1TestMessagesModule } from './services/test-messages/test-messages.ng1';
import { Ng1TimeModule } from './services/time/time.ng1';
import { Ng1UserPreferencesModule } from './services/user-preferences/user-preferences.ng1';
import { Ng1UserModule } from './services/user/user.ng1';
import { Ng1ValidationBuilderModule } from './services/validation-builder/validation-builder.ng1';
import { Ng1VenueModule } from './services/venue/venue.ng1';
import { Ng1BsModalService } from './services/bsmodal/bsmodal.ng1';

export const Ng1SharedModule = angular.module('control-room.angular', [
  Ng1ConfigModule.name,
  Ng1UserModule.name,
  Ng1AuthModule.name,
  Ng1ConfigurationModule.name,
  Ng1AnalyticsModule.name,
  Ng1ConstantsModule.name,
  Ng1ErrorLoggingModule.name,
  Ng1PolicyModule.name,
  Ng1TagModule.name,
  Ng1VenueModule.name,
  Ng1ReportsModule.name,
  Ng1MenusModule.name,
  Ng1LockerModule.name,
  Ng1OrderModule.name,
  Ng1ExportButtonModule.name,
  Ng1NavigationModule.name,
  Ng1PolicyCheckModule.name,
  Ng1PrinterModule.name,
  Ng1LocaleModule.name,
  Ng1ImageGridModule.name,
  Ng1FeesModule.name,
  Ng1PlacesModule.name,
  Ng1GraphicalMapModule.name,
  Ng1LocalizationModule.name,
  Ng1EntityModule.name,
  Ng1QuestionModule.name,
  Ng1PoiModule.name,
  Ng1PoiMapModule.name,
  Ng1PrintManagerModule.name,
  Ng1ReportFileModule.name,
  Ng1ScrollModule.name,
  Ng1TestMessagesModule.name,
  Ng1TimeModule.name,
  Ng1ExperiencesModule.name,
  Ng1EventTabsModule.name,
  Ng1FileUploadModule.name,
  Ng1BeaconsModule.name,
  Ng1LoQueueModule.name,
  Ng1CustomerModule.name,
  Ng1CommerceModule.name,
  Ng1CouponsModule.name,
  Ng1DateRangeModule.name,
  Ng1FeatureFlagsModule.name,
  Ng1ImageModule.name,
  Ng1ScheduleModule.name,
  Ng1UserPreferencesModule.name,
  Ng1QuicksightModule.name,
  Ng1ValidationBuilderModule.name,
  Ng1KitchenModule.name,
  Ng1BsModalService.name,
]);
