export const LegacyStatuses = {
    OPEN: 'OPEN',
    CLOSED: 'CLOSED'
};

export const InternalLegacyStatuses = {
    INTERNAL_LEGACY_CURRENT_STATUS: 'internalLegacyCurrentStatus',
    INTERNAL_LIFT: 'internalLift',
    INTERNAL_TRAIL: 'internalTrail',
    INTERNAL_ACTIVITY: 'internalActivity'
};

export const SNOAA_Type = {
    LIFT: 'lift',
    TRAIL: 'trail',
    ACTIVITY: 'activity'
};

export const Time = {
    PM: 'pm',
    AM: 'am'
};
