import { Component, OnInit } from "@angular/core";
import { StateService } from "@uirouter/core";
import { sanitize } from 'dompurify';
import { LocalizedText } from '../../../../core';
import { Place } from "../../../../shared/models/places.model";
import { PlacesService } from "../../../../shared/services/places/places.service";
import { menuRoutes } from "../../menus.routes";
import text from './resources/en.json';
@Component({
    selector: "cr-discount-content",
    templateUrl: "./discount-content.component.html",
    styleUrls: ["./discount-content.component.scss"]
})
export class DiscountContentComponent implements OnInit {
    phonePreview: {
        discountTitle: string,
        discountDescription: string
    };

    venueData: Place;

    text: LocalizedText;

    discountsLabel: string;

    sanitize: (html: string) => string;

    isLoading = false;

    constructor(private state: StateService, private crPlacesService: PlacesService) {
        this.text = text;
        this.sanitize = sanitize;
    }

    ngOnInit() {
        this.isLoading = true;
        this.crPlacesService
            .getPlace(`venue:${this.state.params.venueId}`)
            .then((data) => {
                this.isLoading = false
                this.venueData = data;
                this.discountsLabel = this.venueData?.webContent?.discountsLabel?.length > 0 ? this.venueData.webContent.discountsLabel : this.text.pageTitle;
                this.updatePhonePreview();
            })
            .catch((err) => {
                this.isLoading = false;
                console.log(err);
            });
    }

    updatePhonePreview(): void {
        if(this.venueData?.webContent?.discountTitle && 
            this.venueData?.webContent?.discountDescription )
        this.phonePreview = {
            discountTitle: this.venueData.webContent.discountTitle,
            discountDescription: this.sanitize(this.venueData.webContent.discountDescription)
        }
    }

    goToEditPage(): void {
        this.state.go(menuRoutes.EDIT_DISCOUNT_CONTENT, { edit: true });
    }

}