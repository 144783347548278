import controller from './preferences-questions-overview.controller';
import template from './preferences-questions-overview.html';
import './preferences-questions-overview.less';

const component = {
    template,
    bindings: {
        heading: '@',
        description: '@',
        state: '@',
        tags: '<',
        locale: '<',
        isLoading: '<',
    },
    controller: [controller],
};

export default component;
