import moment from 'moment';

class TabularOrdersPlacedMockService {
    getOrdersPlaced(timeframe, interval) {
        const curr = moment(timeframe.end);
        const start = moment(timeframe.start);

        const scaleMap = {
            DAY: 10,
            WEEK: 70,
            MONTH: 300,
            QUARTER: 900,
        };

        const rows = [];

        while (curr.isAfter(start)) {
            const next = curr.clone().subtract(1, interval);
            const orders =
                Math.floor(Math.random() * scaleMap[interval.toUpperCase()]) + scaleMap[interval.toUpperCase()];
            const revenue = orders * 3.02;

            rows.push({
                data: {
                    timeframe:
                        interval !== 'DAY'
                            ? `${next.format('MMM Do')} to ${curr.format('MMM Do')}`
                            : curr.format('MMM Do'),
                    orders,
                    revenue,
                },
            });

            curr.subtract(1, interval);
        }

        return Promise.resolve(rows);
    }
}

export default TabularOrdersPlacedMockService;
