<div class="content">
    <div class="content-underlay"></div>
    <div class="control-bar">
        <div class="title-section">
            <span class="title">{{ pageTitle }}</span>
        </div>
        <div class="controls">
            <button class="btn btn-clear" (click)="returnToDininLocations()">
                {{ text.cancelButton }}
            </button>
            <div
                dropdown
                #dropdown="bs-dropdown"
                class="state-dropdown btn-group"
            >
                <button
                    class="btn btn-primary"
                    (click)="checkReservationEventBeforeSubmit()"
                    [disabled]="submitIsDisabled"
                    *ngIf="!isReadOnly; else editButton"
                >
                    {{ text.saveButton }}
                </button>
                <ng-template #editButton>
                    <button class="btn btn-primary" (click)="navigateToEdit()">
                        {{ text.editButton }}
                    </button>
                </ng-template>
                <button
                    *ngIf="!isCreateMode"
                    id="button-split"
                    type="button"
                    dropdownToggle
                    class="btn btn-primary dropdown-toggle dropdown-toggle-split"
                    aria-controls="dropdown-options"
                >
                    <span class="caret"></span>
                </button>
                <ul
                    id="dropdown-options"
                    *dropdownMenu
                    class="dropdown-menu"
                    role="menu"
                    aria-labelledby="button-split"
                >
                    <li role="menuitem">
                        <a
                            href="javascript:void(0);"
                            class="dropdown-item"
                            (click)="archiveEvent()"
                            >{{ text.archiveOption }}</a
                        >
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div class="row editor-container">
        <div class="col-sm-12 title-section" *ngIf="!isLoading; else loading">
            <h2 class="location-title">{{ eventName }}</h2>
            <span class="location-poi-subtitle"
                >{{ text.poiTitle }} {{ poiId }}</span
            >
        </div>
        <ng-template #loading>
            <div class="col-sm-12 title-section">
                <div class="spinner-container">
                    <cr-spinner></cr-spinner>
                </div>
            </div>
        </ng-template>
        <div class="col-sm-10">
            <form [formGroup]="locationForm">
                <div class="form-section">
                    <div class="location-input">
                        <label *ngIf="!isReadOnly; else readOnlyTitle">
                            {{ text.formFieldTitle.name }}
                            <input
                                class="wide"
                                type="text"
                                formControlName="name"
                            />
                        </label>

                        <ng-template #readOnlyTitle>
                            <label>{{ text.formFieldTitle.name }}</label>
                            <span class="static-value">{{
                                locationForm.value.name
                            }}</span>
                        </ng-template>
                    </div>
                </div>

                <!-- Core Configurations -->
                <div class="form-section">
                    <div class="section-title">
                        <hr />
                        <span>{{
                            text.formSectionTitle.coreConfigurations
                        }}</span>
                    </div>

                    <div class="location-input">
                        <label
                            *ngIf="isCreateMode; else readOnlyReservationUnit"
                        >
                            {{ text.formFieldTitle.reservationUnit }}
                            <select
                                class="narrow"
                                type="text"
                                formControlName="reservationUnit"
                                (change)="selectReservationUnit($event)"
                            >
                                <option value="GUEST">
                                    {{ text.guestsCapacityType }}
                                </option>
                                <option value="TABLE">
                                    {{ text.tablesCapacityType }}
                                </option>
                            </select>
                        </label>

                        <ng-template #readOnlyReservationUnit>
                            <label>{{
                                text.formFieldTitle.reservationUnit
                            }}</label>
                            <span class="static-value">{{
                                locationForm.value.reservationUnit
                            }}</span>
                        </ng-template>
                    </div>

                    <div class="location-input">
                        <label
                            *ngIf="
                                !isReadOnly;
                                else readOnlyReservationMaxCapacity
                            "
                        >
                            {{ text.formFieldTitle.reservationMaxCapacity }}
                            <input
                                type="number"
                                formControlName="reservationMaxCapacity"
                                min="1"
                                max="30"
                            />
                        </label>
                        <ng-template #readOnlyReservationMaxCapacity>
                            <label>{{
                                text.formFieldTitle.reservationMaxCapacity
                            }}</label>
                            <span class="static-value">{{
                                locationForm.value.reservationMaxCapacity
                            }}</span>
                        </ng-template>
                    </div>

                    <div class="location-input">
                        <label
                            *ngIf="
                                !isReadOnly;
                                else readOnlyReservationDaysAhead
                            "
                        >
                            {{ text.formFieldTitle.reservationDaysAhead }}
                            <input
                                type="number"
                                formControlName="reservationDaysAhead"
                                min="1"
                                max="31"
                            />
                        </label>
                        <ng-template #readOnlyReservationDaysAhead>
                            <label>{{
                                text.formFieldTitle.reservationDaysAhead
                            }}</label>
                            <span class="static-value">{{
                                locationForm.value.reservationDaysAhead
                            }}</span>
                        </ng-template>
                    </div>
                </div>

                <!-- Reservation Schedule -->
                <cr-reservation-schedules
                    [text]="text"
                    [reservationUnit]="
                        locationForm.controls.reservationUnit.value
                    "
                    [schedules]="schedules"
                    [editMode]="editMode"
                    [reservationDaysAhead]="reservationDaysAhead"
                    (schedulesEmitter)="schedulesUpdated($event)"
                ></cr-reservation-schedules>
            </form>

            <div class="footer">
                <hr />
                {{ text.requiredFieldsFooter }}
            </div>
        </div>
    </div>
</div>

<ng-template #confirmArchiveModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">
            {{ text.modals.archiveModal.title }}
        </h4>
    </div>
    <div class="modal-body">
        {{ text.modals.archiveModal.body }}
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-clear" (click)="bsModalRef.hide()">
            {{ text.modals.archiveModal.cancelButton }}
        </button>
        <button
            type="button"
            class="btn btn-info"
            (click)="submitArchiveEvent()"
        >
            {{ text.modals.archiveModal.confirmButton }}
        </button>
    </div>
</ng-template>

<ng-template #archiveDisabledModal>
    <div class="modal-body">
        {{ text.modals.archiveDisabled.body }}
    </div>
    <div class="modal-footer">
        <button
            type="button"
            class="btn btn-default"
            (click)="bsModalRef.hide()"
        >
            {{ text.modals.archiveDisabled.closeButton }}
        </button>
    </div>
</ng-template>

<ng-template #errorModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{ text.error.errorModalHeader }}</h4>
    </div>
    <div class="modal-body">
        {{ errorMessage }}
    </div>
    <div class="modal-footer">
        <button
            type="button"
            class="btn btn-default"
            (click)="bsModalRef.hide()"
        >
            {{ text.error.errorModalHideButton }}
        </button>
    </div>
</ng-template>

<ng-template #confirmCancelModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">
            {{ text.modals.cancelModal.title }}
        </h4>
    </div>
    <div class="modal-body">
        {{ text.modals.cancelModal.body }}
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-clear" (click)="bsModalRef.hide()">
            {{ text.modals.cancelModal.cancelButton }}
        </button>
        <button type="button" class="btn btn-danger" (click)="submitForm()">
            {{ text.modals.cancelModal.confirmButton }}
        </button>
    </div>
</ng-template>
