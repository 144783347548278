import text from './resources/locale/en.json';

class ExperienceFlyoutController {
    constructor(
        $scope,
        $state,
        $uibModal,
        crConstants,
        crVenueService,
        crTimeService,
        crScheduleService,
        crScrollService,
        crAnalyticsService,
        crExperiencesService
    ) {
        this.$scope = $scope;
        this.$state = $state;
        this.$uibModal = $uibModal;
        this.crConstants = crConstants;
        this.crVenueService = crVenueService;
        this.crTimeService = crTimeService;
        this.crScheduleService = crScheduleService;
        this.crScrollService = crScrollService;
        this.crAnalyticsService = crAnalyticsService;
        this.crExperiencesService = crExperiencesService;

        this.text = text;
    }

    $onInit() {
        this.crScrollService.disablePageScroll();
        this.tz = this.crVenueService.getTimezone(this.$state.params.venueId).name;
        this.schedule = this.data.schedule;

        this.CANCEL_EXPERIENCE = this.crConstants.events.CANCEL_CALENDAR_INSTANCE;
        this.trackingData = { eventName: this.data.name, eventId: this.data.id };
        this.crAnalyticsService.track('Calendar - Experience Clicked', this.trackingData);

        this.isAutomation = this.data.type.indexOf('BROADCAST') === -1;
        if (!this.isAutomation) {
            this.sendsAt = `${this.text.sendsAt} ${this.crTimeService.getShortTimeFromIsoDate(
                this.schedule.start,
                this.tz
            )}`;
        } else {
            this.startAndEnd = this.crTimeService.getShortTimeRange(this.schedule.start, this.schedule.end, this.tz);
        }

        this.crExperiencesService.getExperienceType(this.data.type).then((data) => {
            this.experienceText = `${this.isAutomation ? this.text.automation : this.text.broadcast}: ${data.name}`;
        });
    }

    $onDestroy() {
        this.crScrollService.enablePageScroll();
    }

    editExperience() {
        this.crAnalyticsService.track('Calendar Event Flyout - Edit Experience', this.trackingData);
        this.goToEditPage();
    }

    goToEditPage() {
        const state = this.isAutomation ? 'edit-automation' : 'edit-broadcast';
        this.$state.go(`client.experience-promoter.${state}`, { id: this.data.id });
    }

    viewExperience() {
        this.crAnalyticsService.track('Calendar Event Flyout - View Experience', this.trackingData);
        this.goToDetailsPage();
    }

    goToDetailsPage() {
        const state = this.isAutomation ? 'details-automation' : 'details-broadcast';
        this.$state.go(`client.experience-promoter.${state}`, { id: this.data.id });
    }

    confirmCancelInstance() {
        this.$uibModal
            .open({
                backdrop: 'static',
                component: 'crSimpleModal',
                windowClass: 'cr-modal-size-sm',
                resolve: {
                    type: () => 'submit',
                    submitText: () => this.text.yes,
                    message: () => this.text.deleteConfirmationMessage,
                },
            })
            .result.then(() => {
                this.cancelInstance(this.data.id, this.schedule.scheduleId, this.schedule.start);
            })
            .catch(() => {
                this.crAnalyticsService.track('Calendar Experience Flyout - Cancel Not Confirmed', this.trackingData);
            });
    }

    cancelInstance(experienceId, scheduleId, scheduleStart) {
        this.crScheduleService
            .cancelExperienceSchedule(experienceId, scheduleId, scheduleStart, this.tz)
            .then((data) => {
                const toastObj = { toast: null };

                // Set a toast if this was the final schedule instance
                if (data.finalInstance && this.data.state === this.crConstants.entity.states.ACTIVE) {
                    toastObj.toast = `"${this.data.name}" ${this.text.inactiveMessage}`;
                }

                this.$scope.$emit(this.CANCEL_EXPERIENCE, toastObj);
                this.crAnalyticsService.track('Calendar Experience Flyout - Instance Cancelled', this.trackingData);
            })
            .catch(() => {
                this.showErrorModal();
            });
    }

    showErrorModal() {
        this.crAnalyticsService.track('Calendar Experience Flyout - Error Canceling', this.trackingData);
        this.$uibModal
            .open({
                backdrop: 'static',
                component: 'crSimpleModal',
                windowClass: 'cr-modal-size-sm',
                resolve: {
                    message: () => this.text.errorMessage,
                },
            })
            .result.catch(() => null);
    }
}

export default ExperienceFlyoutController;
