export const Time = {
    PM: 'pm',
    AM: 'am'
};

export const WeekDays = [
    'Sunday,',
    'Monday,',
    'Tuesday,',
    'Wednesday,',
    'Thursday,',
    'Friday,',
    'Saturday,'
]

export const ParkHourStatus = {
    OPEN: 'Open',
    CLOSED: 'Closed'
}
