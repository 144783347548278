import { Component, Input, ViewEncapsulation } from '@angular/core';
import { StateService } from '@uirouter/core';
import bulletTrain from 'bullet-train-client';
import { LocalizedText } from '../../../../core';
import text from './resources/locale/en.json';
import { ConfigService } from '../../../../shared';

export enum NavLocation {
  Reservations,
  DiningLocations,
}

@Component({
  selector: 'cr-nav-header',
  templateUrl: './nav-header.component.html',
  styleUrls: ['./nav-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NavigationHeaderComponent {
  static readonly DASHBOARD_STATE = 'client.fnb-reservations.dashboard';

  static readonly DINING_LOCATIONS_STATE = 'client.fnb-reservations.dining-locations';

  NavLocation = NavLocation;

  text: LocalizedText;

  @Input() navLocation: NavLocation;

  showDiningLocations: boolean;

  constructor(private stateService: StateService, private configService: ConfigService) {
    this.text = text as LocalizedText;
    this.hasLocationAccess();
  }

  navigate(destinationLocation: NavLocation) {
    if (destinationLocation === NavLocation.Reservations) {
      this.stateService.go(NavigationHeaderComponent.DASHBOARD_STATE);
    } else if (destinationLocation === NavLocation.DiningLocations) {
      this.stateService.go(NavigationHeaderComponent.DINING_LOCATIONS_STATE);
    }
  }

  hasLocationAccess() {
    const config = this.configService.props;

    if (config.customer.bulletTrain) {
      bulletTrain.init({
        environmentID: config.customer.bulletTrain,
        onChange: (flags) => {
          if (flags.dining_reservation_management) {
            this.showDiningLocations = flags.dining_reservation_management.enabled;
          }
        },
      });
    }
  }
}
