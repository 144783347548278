import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input } from '@angular/core';
import { StateService } from '@uirouter/core';
import moment from 'moment';
import { BsModalService } from 'ngx-bootstrap';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SimpleModalComponent } from '..';
import { LocalizedText } from '../../core';
import { ErrorLoggingService } from '../services/error-logging/error-logging.service';
import { WINDOW } from '../services/window';
import { ExportService } from './export.service';
import text from './resources/locale/en.json';
import { OrderHistoryExportParams } from '../services/order-history/order-history.model';

@Component({
  selector: 'cr-export-button',
  templateUrl: './export-button.component.html',
  styleUrls: ['./export-button.component.scss'],
})
export class CrExportButtonComponent {
  text: LocalizedText;

  exporting = false;

  @Input()
  type: string;

  @Input()
  baseFilename: string;

  @Input()
  orderHistoryExportParams?: OrderHistoryExportParams;

  constructor(
    private state: StateService,
    private exportService: ExportService,
    @Inject(WINDOW) private window: Window,
    @Inject(DOCUMENT) private document: Document,
    private crErrorLoggingService: ErrorLoggingService,
    private modalService: BsModalService,
  ) {
    this.text = text;
  }

  doExport() {
    if (!this.type) {
      return;
    }

    this.exporting = true;

    let obs;
    if (this.type === 'poi') {
      obs = this.exportService.exportPOI(this.state.params.venueId);
    } else if (this.type === 'order') {
      obs = this.exportService.exportOrders(this.state.params.venueId, this.state.params.kitchenId);
    }
    else if(this.type === 'order-history' && this.orderHistoryExportParams) {
      this.orderHistoryExportParams.placeId = this.state.params.kitchenId;
      this.orderHistoryExportParams.venueId = this.state.params.venueId;
      obs = this.exportService.exportOrderHistory(this.orderHistoryExportParams);
    }

    obs.pipe(catchError((err) => of(this.handleError('Export Error', err)))).subscribe((blob) => {
      this.download(blob);
      this.exporting = false;
    });
  }

  private download(data: Blob) {
    const timestamp = moment.utc().format('YYYYMMDDTHHmm[Z]');
    const filename = `${this.baseFilename}_${timestamp}.xlsx`;
    const blobUrl = (this.window as any).URL.createObjectURL(data);

    const link = this.document.createElement('a');
    link.href = blobUrl;
    link.setAttribute('download', filename);
    this.document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  }

  private handleError(errMsg, err) {
    this.exporting = false;
    this.showErrorModal();
    this.crErrorLoggingService.logError(errMsg, err);
  }

  private showErrorModal() {
    this.modalService.show(SimpleModalComponent, {
      initialState: { message: this.text.errorMessage },
      backdrop: 'static',
      class: 'cr-modal-size-sm',
    });
  }
}
