import _ from 'lodash';
import text from './resources/locale/en.json';
import closeIconSrc from '../../../../commons/basic/resources/images/ic_close_24.svg';
import favIconSrc from '../../../../commons/basic/resources/images/ic_fav_off_24.svg';
import fabIconSrc from '../../../../commons/basic/resources/images/fab.svg';
import placeholderSrc from '../../../../commons/basic/resources/images/img_placeholder@2x.png';

class PoiPhonePreviewController {
    constructor(crImageService) {
        this.crImageService = crImageService;
    }

    $onInit() {
        this.text = text;
        this.closeIconSrc = closeIconSrc;
        this.favIconSrc = favIconSrc;
        this.fabIconSrc = fabIconSrc;
        this.placeholderSrc = placeholderSrc;
        this.setUpDisplayData();
    }

    $onChanges() {
        this.poi = _.cloneDeep(this.poi);
        this.setUpDisplayData();
    }

    setUpDisplayData() {
        if (this.poi) {
            this.getDisplayImage();

            if (!this.manage && this.locale) {
                _.forOwn(this.poi.localization[this.locale.current], (value, key) => {
                    this.poi[key] = value;
                });
            }

            this.noAssignedAttributes = _.isEmpty(this.poi.attributes);
        }
    }

    getDisplayImage() {
        const phoneImgWidth = this.crImageService.constants.image.phoneDisplaySize.MAIN.width;
        const phoneImgHeight = this.crImageService.constants.image.phoneDisplaySize.MAIN.height;
        if (this.poi.images) {
            this.phoneImgSrc = this.crImageService.getMainImageSrc(this.poi.images, phoneImgWidth, phoneImgHeight);
        }
    }

    getEnumDisplayValue(value) {
        if (_.isArray(value)) {
            return value.map((obj) => obj.label).join(', ');
        }

        return value;
    }
}

export default PoiPhonePreviewController;
