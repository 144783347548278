import template from './section-heading.html';
import './section-heading.less';

const component = {
    template,
    transclude: true,
    bindings: {
        customClass: '@?',
    },
};

export default component;
