import controller from './experience-dashboard.controller';
import template from './experience-dashboard.html';

import './experience-dashboard.less';

const component = {
    template,
    bindings: {},
    controller: ['$state', controller],
};

export default component;
