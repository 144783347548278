<div
  *ngIf="ptw.windowId !== '0'"
  class="ptw-item"
  [ngClass]="isSelected()"
  (click)="onSelectPTW(ptw)"
>
  {{ ptw.startTime }} - {{ ptw.endTime }}
  <div class="capacity" [ngClass]="capacityStringStyle">
    <div [ngClass]="capacityStyle"></div>
    {{ ptw.allocation }}/{{ ptw.capacity }}
  </div>
</div>

<div
  *ngIf="ptw.windowId === '0'"
  class="ptw-item"
  [ngClass]="isSelected()"
  (click)="onSelectPTW(ptw)"
>
  ALL
  <li class="divider"></li>
</div>
