import _ from 'lodash';

export default class KitchensListService {
    constructor(crEntityService, crConstants) {
        this.crEntityService = crEntityService;
        this.crConstants = crConstants;
    }

    getData(params) {
        const queryParams = _.pickBy(params, (value) => !_.isNil(value) && value !== '');

        return this.crEntityService.getEntityList(this.crConstants.entity.types.PLACES, queryParams);
    }

    mapToRowsModel(kitchens) {
        return kitchens.map((kitchen) => {
            const row = {
                data: {
                    id: kitchen.id,
                    name: kitchen.name,
                    originalData: kitchen,
                },
            };

            return row;
        });
    }
}
