import controller from './alert.controller';
import template from './alert.html';
import './alert.less';

const component = {
    template,
    bindings: {},
    controller: ['$scope', '$sce', 'crConstants', controller],
};

export default component;
