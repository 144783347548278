import { menuRoutes } from './menus.routes';
import text from './resources/locale/en.json';

class Menus {
    constructor(
        $state,
        crConstants,
        crAnalyticsService,
        crNavigationService,
        crMenusService,
        crLocaleService,
        crFeatureFlagsService
    ) {
        this.$state = $state;
        this.crConstants = crConstants;
        this.crAnalyticsService = crAnalyticsService;
        this.crNavigationService = crNavigationService;
        this.crMenusService = crMenusService;
        this.crLocaleService = crLocaleService;
        this.crFeatureFlagsService = crFeatureFlagsService;
    }

    $onInit() {
        this.text = text;
        this.overflowItems = [
            {
                name: this.text.menuCategories,
                state: menuRoutes.CATEGORIES,
            },
            {
                name: this.text.modifierGroups,
                state: menuRoutes.MODIFIER_GROUPS,
            },
        ];

        if (this.crFeatureFlagsService.hasFeature('menu_product_bundles')) {
            this.overflowItems.push({
                name: this.text.bundleGroups,
                state: menuRoutes.BUNDLE_GROUPS,
            });
        }

        this.locale = this.crLocaleService.getLocale(this.venueId, this.$state.params.locale);

        const params = [this.$state.params.menuId, this.$state.params.venueId, this.$state.params.placeId];

        this.getMenuName(...params);
    }

    getMenuName(menuId, venueId, placeId) {
        this.crMenusService.getMenu(venueId, placeId, menuId, this.locale.current).then((entity) => {
            this.menuName = entity.content.name;
        });
    }

    goToCreatePage() {
        this.crAnalyticsService.track('Add New Menu Clicked');
        const routeParams = {
            customerId: this.$state.params.customerId,
            menuId: this.$state.params.menuId,
            placeId: this.$state.params.placeId,
        };
        if(this.$state.params.tabId === 'menu-bundles') {
            this.$state.go(menuRoutes.CREATE_BUNDLE, routeParams);
        } else {
            this.$state.go(menuRoutes.CREATE_PRODUCT, routeParams);
        }
    }

    goBack() {
        this.crNavigationService.goBack(menuRoutes.KITCHEN, { kitchenId: this.$state.params.placeId });
    }

    get isItemList() {
        return this.$state.params.tabId === 'menu-items' || 'menu-bundles';
    }
}

export default Menus;
