import angular from 'angular';
import TimeFramePickerComponent from './time-frame-picker.component';

const TimeFramePickerModule = angular
    .module('control-room.commons.basic.components.time-frame-picker', [
        'control-room.commons.basic.components.filter-menu',
    ])
    .component('crTimeFramePicker', TimeFramePickerComponent);

export default TimeFramePickerModule;
