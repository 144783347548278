import controller from './icon-label-value.controller';
import template from './icon-label-value.html';
import './icon-label-value.less';

const component = {
    template,
    bindings: {
        data: '<',
        valueKey: '@',
    },
    controller: ['crImageService', controller],
};

export default component;
