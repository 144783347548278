import angular from 'angular';

import PreferencesDetailsModule from './preferences-details/preferences-details';
import PreferencesManageModule from './preferences-manage/preferences-manage';
import PreferencesPhonePreviewModule from './preferences-phone-preview/preferences-phone-preview';
import QuestionListModule from './question-list/question-list';
import QuestionManageModule from './question-manage/question-manage';

const Components = angular.module('control-room.app-settings.components', [
    PreferencesDetailsModule.name,
    PreferencesManageModule.name,
    PreferencesPhonePreviewModule.name,
    QuestionListModule.name,
    QuestionManageModule.name,
]);

export default Components;
