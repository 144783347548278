import angular from 'angular';

import ComponentsModule from './components/components';
import LiveMapModule from './live-map/live-map';
import component from './experience-promoter.component';

const ExperiencePromoter = angular
    .module('control-room.experience-promoter', [ComponentsModule.name, LiveMapModule.name])
    .component('crExperiencePromoter', component);

export default ExperiencePromoter;
