import angular from 'angular';

import locale from './resources/locale/en.json';
import AppUsageMockService from './app-usage.mock.service';
import AppUsageService from './app-usage.service';
import AppUsageComponent from './app-usage.component';

const AppUsageModule = angular
    .module('control-room.reports.components.app-usage', [])
    .service('crReportingAppUsageMockService', AppUsageMockService)
    .service('crReportingAppUsageService', ['$q', 'crCommerceService', AppUsageService])
    .constant('crReportingAppUsageLocale', locale)
    .component('crReportingAppUsage', AppUsageComponent);

export default AppUsageModule;
