import _ from 'lodash';

class TabularTopItemsController {
    constructor($state, service, crConstants, crDateRangeService, navigationService, text) {
        this.$state = $state;
        this.service = service;
        this.crConstants = crConstants;
        this.crDateRangeService = crDateRangeService;
        this.navigationService = navigationService;
        this.text = text;
    }

    $onInit() {
        this.isLoading = false;
        this.endOfResults = false;
        this.dataError = false;

        this.intervalFilterData = this.crConstants.filterTypes.interval;
        this.channelFilterData = this.crConstants.filterTypes.channel;

        const { columns } = this.text;
        this.productsListData = {
            columns: [
                { key: 'name', label: columns.name, sortable: false },
                { key: 'id', label: columns.id, sortable: false },
                { key: 'categories', label: columns.categories, sortable: false },
                { key: 'menus', label: columns.menus, sortable: false },
                { key: 'orders', label: columns.orders, sortable: false },
                { key: 'revenue', label: columns.revenue, sortable: false },
            ],
            rows: [],
        };

        this.timeframe = {
            start: '',
            end: '',
        };

        this.interval = this.$state.params.interval || 'day';

        this.updateData();
    }

    $postLink() {
        this.isLinked = true;
        this.updateData();
    }

    rangeUpdated(event) {
        this.timeframe = event.range;

        if (this.isLinked) {
            this.updateIntervalPicker();
            this.updateData();
        }
    }

    updateIntervalPicker() {
        const intervals = this.crDateRangeService.getIntervalsForRange(this.timeframe);

        this.interval = intervals[0];

        _.forEach(this.intervalFilterData, (interval) => {
            interval.disabled = !_.includes(intervals, interval.id);
        });
    }

    updateParam(param, value) {
        if (this[param] !== value.id) {
            this[param] = value.id;
            this.updateData();
        }
    }

    sortChange(column) {
        this.params.sort = column.sort;
        this.params.sortby = column.key;
        this.updateData();
    }

    updateData() {
        this.service.getTopItems(this.timeframe, this.interval).then((rows) => {
            this.productsListData.rows = rows;
        });
    }

    goBack() {
        this.navigationService.goBack('performance.reporting.orders');
    }
}

export default TabularTopItemsController;
