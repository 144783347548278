class PoiTypeInventoryService {
    constructor(poiService, crImageService) {
        this.poiService = poiService;
        this.crImageService = crImageService;
    }

    getPoiType(typeId) {
        return this.poiService.getPoiType(typeId);
    }

    getPois(params) {
        return this.poiService.getPois(params).then((res) =>
            res.content.map((poi) => ({
                stickerSrc: this.getStickerSrc(poi),
                id: poi.id,
                name: poi.name,
            }))
        );
    }

    getStickerSrc(poi) {
        return this.crImageService.getStickerThumbSrc(poi.images);
    }
}

export default PoiTypeInventoryService;
