import angular from 'angular';
import './styles/styles';

import ComponentsModule from './components/components';
import ConstantModule from './constant/constant';
import DirectivesModule from './directives/directives';

const BasicModule = angular.module('control-room.commons.basic', [
    ComponentsModule.name,
    ConstantModule.name,
    DirectivesModule.name,
]);

export default BasicModule;
