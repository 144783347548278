import _ from 'lodash';
import {
  Component, Input
} from '@angular/core';
import { LocalizedText } from '../../../core/index.js';
import text from './resources/locale/en.json';
@Component({
  selector: 'cr-id-detail-content-phone-preview',
  templateUrl: './id-detail-content-phone-preview.component.html',
  styleUrls: ['./id-detail-content-phone-preview.component.scss'],
})
export class IdDetailContentPhonePreviewComponent{
  @Input() featuredEvents = { idDetailTitle: "",
        idDetailDescription: ""};

  text: LocalizedText;

  constructor() {
    this.text = text;
  }

}
