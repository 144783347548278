import controller from './date-picker.controller';
import template from './date-picker.html';
import './date-picker.less';

const Component = {
    template,
    require: {
        form: '^form',
    },
    bindings: {
        name: '@',
        onUpdate: '&',
        isoDateString: '<',
        outputDateFormat: '@',

        hideValidation: '<?',
        crRequired: '<?',
        endOfDay: '<?',
        minDate: '<?',
        maxDate: '<?',
        customClass: '@?',
    },
    controller: ['$state', 'crConstants', 'crVenueService', '$timeout', controller],
};

export default Component;
