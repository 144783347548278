import template from './event-flyout.html';
import './event-flyout.less';
import Controller from './event-flyout.controller';

const Component = {
    template,
    bindings: {
        data: '<',
    },
    controller: [
        '$scope',
        '$state',
        '$uibModal',
        'crConstants',
        'crVenueService',
        'crTimeService',
        'crScheduleService',
        'crScrollService',
        'crAnalyticsService',
        Controller,
    ],
};

export default Component;
