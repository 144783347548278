import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap';
import { stateServiceProvider } from '../../../../ng1-factories';
import { CrContentModule, CrFormsModule, CrSpinnerModule, ErrorLoggingService, NavigationService, UserService } from '../../../../shared';
import { CrSplitViewModule } from '../../../../shared/layout/split-view-page/split-view-page.module';
import { BundleGroupsService } from '../../../../shared/services/bundle-groups/bundle-groups.service';
import { ConfigurationService } from '../../../../shared/services/configuration/configuration.service';
import { ProductsService } from '../../../../shared/services/products/products.service';
import { CrSplitBtnModule } from '../../../../shared/split-button/split-button.module';
import { CrTitleBarModule } from '../../../../shared/title-bar/title-bar.module';
import { BundleGroupsManageComponent } from './bundle-groups-manage.component';

@NgModule({
    imports: [
        CommonModule,
        CrTitleBarModule,
        CrSplitBtnModule,
        CrSpinnerModule,
        CrSplitViewModule,
        CrContentModule,
        CrFormsModule
    ],
    exports: [BundleGroupsManageComponent],
    declarations: [BundleGroupsManageComponent],
    entryComponents: [BundleGroupsManageComponent],
    providers: [UserService, BsModalService, ErrorLoggingService, ConfigurationService, NavigationService, stateServiceProvider, BundleGroupsService, ProductsService]
})

export class BundleGroupsManageModule { }