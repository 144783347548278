import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Bundle, BundleRequest } from './bundle.model';
import { BundleListData } from '../../../client/menus/components/bundle-list/bundle-list.model';

@Injectable({
  providedIn: 'root',
})
export class BundleService {
  constructor(private http: HttpClient) {
  }

  createBundle(placeId: string, menuId: string, bundle: BundleRequest): Observable<any> {
    return this.http.post<any>(`/rest/bundles/admin/places/${placeId}/menus/${menuId}/bundles`, bundle, {
    });
  }

  updateBundle(placeId: string, menuId: string, bundleId: string, bundle: BundleRequest): Observable<Bundle> {
    return this.http.post<Bundle>(`/rest/bundles/admin/places/${placeId}/menus/${menuId}/bundles/${bundleId}`, bundle);
  }

  getBundle(placeId: string, menuId: string, bundleId: string): Observable<Bundle> {
    return this.http.get<Bundle>(`/rest/bundles/admin/places/${placeId}/menus/${menuId}/bundles/${bundleId}`);
  }

  getBundleListData(placeId: string, menuId: string): Observable<BundleListData[]> {
    const url = `rest/bundles/admin/places/${placeId}/menus/${menuId}/bundles`;
    return this.http.get<BundleListData[]>(url);
  }

  deleteBundle(placeId: string, menuId: string, bundleId: string): Observable<any> {
    return this.http.delete<any>(`/rest/bundles/admin/places/${placeId}/menus/${menuId}/bundles/${bundleId}`);
  }
}
