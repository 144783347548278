import angular from 'angular';
import AngularUI from 'angular-ui-bootstrap';

import BasicModule from './basic/basic';
import ContentModule from './content/content';
import DataModule from './data/data';
import FormModule from './form/form';
import LayoutModule from './layout/layout';

import { Ng1SharedModule } from '../shared/shared.ng1';

const CommonsModule = angular.module('control-room.commons', [
    AngularUI,
    BasicModule.name,
    DataModule.name,
    FormModule.name,
    LayoutModule.name,
    ContentModule.name,
    Ng1SharedModule.name,
]);

export default CommonsModule;
