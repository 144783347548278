import Controller from './bar-graph.controller';
import template from './bar-graph.html';
import './bar-graph.less';

const Component = {
    template,
    bindings: {
        max: '<',
        unfilteredValue: '<',
        value: '<',
    },
    controller: [Controller],
};

export default Component;
