import text from './resources/locale/en.json';

class PoiController {
    constructor($state, crConstants, crAnalyticsService) {
        this.$state = $state;
        this.crConstants = crConstants;
        this.crAnalyticsService = crAnalyticsService;
    }

    $onInit() {
        this.text = text;
        this.overflowItems = [
            {
                name: this.text.poiDisplayCategories,
                state: 'client.poi.categories',
            },
            {
                name: this.text.poiTypes,
                state: 'client.poi.types',
            },
            {
                name: this.text.poiAttributes,
                state: 'client.poi.attributes-list',
            },
        ];
    }

    goToCreatePage() {
        this.crAnalyticsService.track('Add New POI Clicked');
        this.$state.go('client.poi.create', {
            customerId: this.$state.params.customerId,
        });
    }
}

export default PoiController;
