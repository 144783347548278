import angular from 'angular';
import { downgradeComponent } from '@angular/upgrade/static';
import { LiveMapComponent } from './live-map.component';

import LiveMapAppUsageModule from './live-map-app-usage/live-map-app-usage';
import LiveMapMetricsModule from './live-map-metrics/live-map-metrics';
import LiveMapPopupModule from './live-map-popup/live-map-popup';

export default angular
    .module('control-room.experience-promoter.live-map', [
        LiveMapAppUsageModule.name,
        LiveMapMetricsModule.name,
        LiveMapPopupModule.name,
    ])
    .directive('crLiveMap', downgradeComponent({ component: LiveMapComponent }));
