import Controller from './access-denied.controller';
import template from './access-denied.html';
import './access-denied.less';

const Component = {
    template,
    controller: Controller,
};

export default Component;
