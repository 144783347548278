import { Component, Input, OnInit } from '@angular/core';
import { LocalizedText } from '../../../../core';
import { Kitchen } from '../../../../shared/models/kitchen.model';
import text from './resources/en.json';

@Component({
  selector: 'cr-venue-hours',
  templateUrl: 'venue-hours.component.html',
  styleUrls: ['./venue-hours.component.scss'],
})

export class VenueHoursComponent implements OnInit {
  @Input() kitchen: Kitchen;

  text: LocalizedText;

  orderingHoursString: string;

  todaysHours:{
    closingTime: string
    days: string[],
    openingTime: string,
  }[] = [];

  day = new Date().toLocaleString('en-us', { weekday: 'long' });

  constructor() {
    this.text = text;
  }

  public ngOnInit(): void {
    this.orderingHoursString = `${text.hoursUnavailable}`;
    if (this.kitchen.hours.length > 0) {
      this.getVenueHoursString();
    }
  }

  private getVenueHoursString(): void {
    this.findTodaysHours();

    let hoursString = `${this.text.today}`;

    this.todaysHours.forEach((entry) => {
      const hours = `, ${this.toVenueLocalHours(entry.openingTime)} - ${this.toVenueLocalHours(entry.closingTime)}`;

      hoursString += hours;
    });

    if (this.todaysHours.length === 0) {
      hoursString += `, ${this.text.closed}`;
    }

    this.orderingHoursString = hoursString;
  }

  private toVenueLocalHours(time: string): string {
    const date = new Date(`June 18, 2021 ${time}`);
    const options = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };
    const timeString = date.toLocaleString('en-US', options);

    return timeString;
  }

  private findTodaysHours(): void {
    const today = new Date().toLocaleString('en-us', { weekday: 'long' });
    // loop through the entries to see if today is inlcuded
    this.kitchen.hours.forEach((entry) => {
      if (entry.days.includes(today.toLocaleUpperCase())) {
        this.todaysHours.push(entry);
      }
    });
  }
}
