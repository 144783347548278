import controller from './kitchens-list.controller';
import template from './kitchens-list.html';
import './kitchens-list.less';

export default {
    template,
    bindings: {},
    controller: [
        '$state',
        'crAnalyticsService',
        'crConfig',
        'crEntitySelectionManager',
        'crErrorLoggingService',
        'crKitchensListService',
        'crLocaleService',
        'crToastService',
        controller,
    ],
};
