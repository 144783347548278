import _ from 'lodash';
import moment from 'moment';

class TabularTopItemsMockService {
    constructor() {
        this.itemsAvailable = [
            'Smokehouse Brisket Sandwich',
            'Reuben',
            'Loaded Italian',
            'Roast Turkey Gyro',
            'Roast Beef Gyro',
            'Classic Roast Beef',
            'Roast Beef - Mid',
            'Roast Beef - Max',
            'Beef n Cheddar - Classic',
            'Beef n Cheddar - Mid',
            'French Dip & Swiss',
            'Three Cheese Steak Sandwich',
            'Jalapeno Roast Beef Slider',
            "Corned Beef 'N Cheese Slider",
            'Reuben',
            "Roast Beef 'N Cheese Slider",
            'Smokehouse Brisket Sandwich',
            'Roast Beef Gyro',
            'Fire Roasted Philly',
            'Buttermilk Chicken Cordon Bleu Sandwich',
            'Loaded Italian',
            "Ham 'N Cheese Slider",
            'Buttermilk Chicken Bacon & Swiss',
            'Crispy Chicken Farmhouse Salad',
            'Grand Turkey Club',
            'Roast Turkey Farmhouse Salad',
            'Roast Turkey Ranch & Bacon Sandwich',
            'Jr. Ham and Cheddar Melt',
            'Jr. Bacon Cheddar Melt',
        ];

        this.menusAvailable = ['Dinner', 'Lunch', 'Breakfast', 'Weekday', 'Weekend'];

        this.categoriesAvailable = ['Beef', 'Cheezy', 'BBQ', 'Zesty', 'Horsey', 'Chicken'];
    }

    getRandom(arr) {
        return arr[Math.floor(Math.random() * arr.length)];
    }

    getTopItems(timeframe, interval) {
        const curr = moment(timeframe.end);

        const scaleMap = {
            DAY: 10,
            WEEK: 70,
            MONTH: 300,
            QUARTER: 900,
        };

        const rows = [];

        _.shuffle(this.itemsAvailable).forEach((item) => {
            const orders =
                Math.floor(Math.random() * scaleMap[interval.toUpperCase()]) + scaleMap[interval.toUpperCase()];
            const revenue = orders * 3.02;

            const menus = [];
            const categories = [];

            for (let i = 0; i < Math.floor(Math.random() * 2) + 1; i++) {
                menus.push(this.getRandom(this.menusAvailable));
            }

            for (let i = 0; i < Math.floor(Math.random() * 3) + 1; i++) {
                categories.push(this.getRandom(this.categoriesAvailable));
            }

            rows.push({
                data: {
                    product: {
                        name: item,
                        id: Math.floor(Math.random() * 1000) + 500,
                        menus,
                        categories,
                    },
                    orders,
                    revenue,
                },
            });

            curr.subtract(1, interval);
        });

        return Promise.resolve(rows);
    }
}

export default TabularTopItemsMockService;
