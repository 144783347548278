import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { isEmpty } from 'lodash';
import { Config } from '../../../core/config';
import { LocalizationByIdResponse } from '../../models/entity-localization.model';
import { EntityType } from '../../models/entity.model';
import { UserPreference, UserPreferenceWithLocalization } from '../../models/user-preferences.model';
import { CR_CONFIG } from '../config/cr-config';
import { LocalizationService } from '../localization/localization.service';

const SCREEN_FLOW_KEY = 'user-preferences';

@Injectable({
    providedIn: 'root',
})
export class UserPreferencesService {
    private createUrl = `/rest/${EntityType.SCREEN_FLOWS}`;
    private url = `${this.createUrl}/${SCREEN_FLOW_KEY}`;
    constructor(private http:HttpClient,
                @Inject(CR_CONFIG) private config: Config,
                private localizationService: LocalizationService) {}

    getUserPreferences(locale: string, venueId: string = null): Promise<UserPreference> {
        const { localizationEnabled } = this.config.customer;
        if (!venueId && localizationEnabled && this.localizationService.isLocalized(EntityType.SCREEN_FLOWS)) {
            return this.getWithLocalization(locale);
        }
        return this.getWithDefaultLocalization(locale, venueId);
    }

    getWithDefaultLocalization(locale: string, venueId: string): Promise<UserPreferenceWithLocalization> {
        return this.getData(locale, venueId).then((data: UserPreferenceWithLocalization) => {

            // only add localization property for supported entity types
            if (this.localizationService.isLocalized(EntityType.SCREEN_FLOWS)) {
                data.localization = this.localizationService.getUserPrefsLocalizationDefaultModel(data, venueId);
            }

            return data;
        });
    }

    getData(locale: string, venueId: string = null): Promise<UserPreference> {
        const params = venueId ? { locale, venueId } : { locale };
        return this.http.get<UserPreference>(this.url, {
            params: { ...params, includeTagDefinitions: 'true' },
            headers: {
                'Accept-Language': locale,
            },
        }).toPromise();
    }

    getLocalization(): Promise<LocalizationByIdResponse> {
        const venueId = 'default';
        return new Promise((resolve, reject) => {
            this.localizationService
                .getEntityLocalization(EntityType.SCREEN_FLOWS, { flowKey: SCREEN_FLOW_KEY, venueId })
                .then((res) => resolve(res))
                .catch((err: HttpErrorResponse) => {
                    if (err.status === 404) {
                        resolve({});
                    } else {
                        reject();
                    }
                });
        });
    }

    createUserPreferences(preferences: UserPreferenceWithLocalization, venueId?: string): Promise<UserPreference> {
        let params = {};
        if(venueId) {
            params = {venueId};
        }
        preferences.flowKey = SCREEN_FLOW_KEY;
        preferences.screens[0].screenKey = SCREEN_FLOW_KEY;
        preferences.venueId = venueId || null;

        if (preferences.localization) {
            Object.keys(preferences.localization).forEach((locale) => {
                const localization = preferences.localization[locale];
                if (localization.screens && localization.screens[0]) {
                    localization.screens[0].screenKey = SCREEN_FLOW_KEY;
                } else {
                    delete preferences.localization[locale];
                }
            });
        }

        return this.http.post<UserPreference>(this.createUrl, preferences, { params }).toPromise().then((res) => {
            venueId = venueId || 'default';
            if (this.config.customer.localizationEnabled && this.localizationService.isLocalized(EntityType.SCREEN_FLOWS)) {
                return this.localizationService
                    .updateEntityLocalization(EntityType.SCREEN_FLOWS, preferences.localization, {
                        flowKey: SCREEN_FLOW_KEY,
                        venueId,
                    })
                    .then(() => res);
            }
            return res;
        });
    }

    updateUserPreferences(preferences: UserPreferenceWithLocalization, venueId?: string): Promise<Record<string, unknown>> {
        let params = {};
        if(venueId) {
            params = {venueId};
        }
        preferences.flowKey = SCREEN_FLOW_KEY;
        preferences.screens[0].screenKey = SCREEN_FLOW_KEY;
        preferences.venueId = venueId || null;

        if (preferences.localization) {
            Object.keys(preferences.localization).forEach((locale) => {
                const localization = preferences.localization[locale];
                if (localization.screens && localization.screens[0]) {
                    localization.screens[0].screenKey = SCREEN_FLOW_KEY;
                } else {
                    delete preferences.localization[locale];
                }
            });
        }

        return this.http.put<Record<string, unknown>>(this.url, preferences, { params }).toPromise().then((res) => {
            venueId = venueId || 'default';
            if (this.config.customer.localizationEnabled && this.localizationService.isLocalized(EntityType.SCREEN_FLOWS)) {
                return this.localizationService
                    .updateEntityLocalization(EntityType.SCREEN_FLOWS, preferences.localization, {
                        flowKey: SCREEN_FLOW_KEY,
                        venueId,
                    })
                    .then(() => res);
            }
            return res;
        });
    }

    private getWithLocalization(locale: string): Promise<UserPreferenceWithLocalization> {
        return Promise.all([this.getData(locale, null), this.getLocalization()]).then((res) => {
            const data: UserPreferenceWithLocalization = res[0];

            if (isEmpty(res[1])) {
                data.localization = this.localizationService.getUserPrefsLocalizationDefaultModel(data, null);
            } else {
                data.localization = res[1];
            }

            return data;
        });
    }
}
