import angular from 'angular';

import ChangePasswordModalModule from './change-password-modal/change-password-modal';
import SetAuthCodeModalModule from './set-auth-code-modal/set-auth-code-modal';

const ComponentsModule = angular.module('control-room.app.components', [
    ChangePasswordModalModule.name,
    SetAuthCodeModalModule.name,
]);

export default ComponentsModule;
