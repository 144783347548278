import template from './hours-selector.html';
import './hours-selector.less';
import HoursSelectorController from './hours-selector.controller';

const HoursSelectorComponent = {
    template,
    bindings: {
        disabled: '<',
        state: '<',
        name: '@',
        interval: '@',
        onChange: '&',
    },
    controller: HoursSelectorController,
};

export default HoursSelectorComponent;
