import controller from './modifier-groups-manage.controller';
import template from './modifier-groups-manage.html';
import './modifier-groups-manage.less';

const component = {
    template,
    bindings: {
        edit: '<?',
    },
    controller: [
        '$state',
        '$uibModal',
        '$q',
        'crConfig',
        'crConstants',
        'crEntityService',
        'crLocaleService',
        'crLocalizationService',
        'crNavigationService',
        'crErrorLoggingService',
        'crAnalyticsService',
        controller,
    ],
};

export default component;
