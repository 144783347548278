import {
  Directive, ElementRef, Injector, Input, Output, EventEmitter,
} from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
  selector: 'cr-toggle',
})
export class ToggleDirective extends UpgradeComponent {
  @Input() disabled: boolean;

  @Input() inputName: string;

  @Input() isOn: boolean;

  @Input() label: string;

  @Input() valueMessages: any;

  @Output() onToggle: EventEmitter<void>;

  constructor(elementRef: ElementRef, injector: Injector) {
    super('crToggle', elementRef, injector);
  }
}
