<section class="cr-fnb-order-refund">
    <cr-title-bar icon="icon-cr-back" [label]="text.title" (onClick)="onBack()">
    </cr-title-bar>
    <div class="content stretch" *ngIf="order">
        <section class="col-left adjustable-padding">
            <div
                *ngIf="errors.reason"
                class="error-label big-error small-padding"
            >
                <div class="icon icon-cr-error"></div>
                {{ text.errorReason }}
            </div>

            <div class="page-title small-padding">{{ text.issueRefund }}</div>

            <div *ngIf="!isLoading" class="small-padding">
                <div class="field name">{{ order.customerName }}</div>
                <div class="field">
                    {{ text.orderNumber }} {{ order.externalOrderId }}
                </div>
                <div class="field">
                    {{ text.chargedTo }}
                    {{ order.cardBrand.toUpperCase() }} ****{{
                        order.cardLast4
                    }}
                </div>
            </div>

            <div class="btn-group refund-type-selection">
                <div class="refund-type-buttons">
                    <button
                        [ngClass]="{
                            btn: true,
                            'btn-primary':
                                refundTypeSelected === refundType.entire,
                            'btn-not-selected':
                                refundTypeSelected !== refundType.entire
                        }"
                        [(ngModel)]="refundTypeSelected"
                        btnRadio="{{ refundType.entire }}"
                        tabindex="0"
                        role="button"
                    >
                        {{ text.refundTypeEntire }}
                    </button>
                    <button
                        [ngClass]="{
                            btn: true,
                            'btn-primary':
                                refundTypeSelected === refundType.partial,
                            'btn-not-selected':
                                refundTypeSelected !== refundType.partial
                        }"
                        [(ngModel)]="refundTypeSelected"
                        btnRadio="{{ refundType.partial }}"
                        tabindex="0"
                        role="button"
                    >
                        {{ text.refundTypePartial }}
                    </button>
                </div>

                <form [formGroup]="refundForm">
                    <div
                        class="partial-refund-section"
                        *ngIf="refundTypeSelected === refundType.partial"
                    >
                        <div class="section-heading small-padding">
                            {{ text.partialRefundHeader }}:
                        </div>
                        <div class="small-padding">
                            <div>
                                {{ text.partialRefundExceedWarning }}
                                <b>{{ order.displayOrderTotal }}</b
                                >.
                            </div>
                            <div class="currency-symbol">
                                {{ order.currency | currencySymbol }}
                            </div>
                            <cr-input
                                id="partial-refund-amount"
                                name="partialRefundAmountControl"
                                label="Refund amount:"
                                hideValidation="false"
                                [validationMessages]="text.refundErrors"
                                type="number"
                                step=".01"
                                [formControl]="partialRefundAmountControl"
                            >
                            </cr-input>
                        </div>
                    </div>
                </form>
            </div>

            <!-- List of line items -->
            <section *ngIf="refundTypeSelected !== refundType.partial">
                <div class="section-heading small-padding">
                    <span>{{ text.itemsToRefund }}:</span>
                    <div
                        (click)="toggleHidden()"
                        class="hide-show-items-button"
                    >
                        {{ hidden ? text.showItems : text.hideItems }}
                        <span
                            [ngClass]="{
                                icon: true,
                                'icon-cr-chevron-down': hidden,
                                'icon-cr-chevron-up': !hidden
                            }"
                        ></span>
                    </div>
                </div>

                <div *ngIf="order.orderReceipt && !hidden">
                    <div
                        *ngFor="let lineItem of orderLineItems"
                        class="line-item small-padding"
                    >
                        <section>
                            <div *ngFor="let subItem of lineItem.subItems">
                                <div class="item-name">
                                    {{ subItem.displayName }}
                                </div>
                                <span
                                    class="item-modifier"
                                    *ngFor="
                                        let modifier of subItem.modifiers;
                                        let last = last
                                    "
                                >
                                    {{ modifier.displayName
                                    }}<span *ngIf="!last">,</span>
                                </span>
                                <div class="item-price">
                                    {{ subItem.displayPrice }}
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </section>

            <section *ngIf="refundReasonOptions.length > 0">
                <!-- Refund reason -->
                <div class="section-heading small-padding">
                    {{ text.selectReason }}:
                </div>

                <div class="small-padding">
                    <div
                        *ngFor="let option of refundReasonOptions"
                        class="radio-option"
                    >
                        <label
                            [ngClass]="{
                                active: refundReasonSelection === option.value
                            }"
                        >
                            <div
                                class="cr-radio-icon icon-cr-radio-off"
                                [ngClass]="{
                                    'icon-cr-radio-on':
                                        refundReasonSelection === option.value
                                }"
                            ></div>
                            <input
                                type="radio"
                                name="reason"
                                [ngModel]="refundReasonSelection"
                                (ngModelChange)="onRefundReasonChange($event)"
                                value="{{ option.value }}"
                            />
                            {{ option.label }}
                        </label>
                    </div>
                    <div *ngIf="refundReasonSelection === 'OTHER'">
                        <div class="other-reason">
                            <div class="input-label">
                                <span
                                    *ngIf="errors.refundReasonOther"
                                    class="error-label"
                                >
                                    <div class="icon icon-cr-error"></div>
                                    {{ text.errorRequiredField }}
                                </span>
                            </div>
                            <input
                                class="input-field"
                                (keyup)="onRefundReasonOtherChanged($event)"
                                (change)="onRefundReasonOtherChanged($event)"
                                (blur)="onValidateRefundReasonOther()"
                                type="text"
                                maxlength="255"
                                id="refundReasonOther"
                                name="refundReasonOther"
                            />
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div class="section-heading small-padding">
                    {{ text.receiptDeliverySection }}:
                </div>
                <form class="small-padding" [formGroup]="receiptForm">
                    <div class="section-subheading">
                        {{ text.receiptDeliveryBody }}
                    </div>
                    <cr-checkbox
                        label="{{ text.receiptDeliveryAdditionalEmail }}"
                        class="receipt-delivery-box"
                        [formControl]="sendAdditionalEmailCheckboxControl"
                    >
                    </cr-checkbox>
                    <cr-input
                        *ngIf="
                            sendAdditionalEmailCheckboxControl.value === true
                        "
                        type="email"
                        name="sendAdditionalEmailControl"
                        showValidMessage="sendAdditionalEmailControl.valid && sendAdditionalEmailControl.value"
                        hideValidation="false"
                        required="sendAdditionalEmailCheckboxControl.value === true"
                        class="receipt-delivery-input"
                        [formControl]="sendAdditionalEmailControl"
                    ></cr-input>
                    <cr-checkbox
                        label="{{ text.receiptDeliveryRefundCopy }}"
                        class="receipt-delivery-box"
                        [formControl]="printRefundCopyControl"
                    >
                    </cr-checkbox>
                </form>
            </section>
        </section>

        <section class="col-right adjustable-padding">
            <!-- Totals -->
            <div class="section-heading small-padding screen-small-only">
                {{ text.authorizeRefund }}:
            </div>

            <div class="adjustable-padding-only-small">
                <div class="totals-row value-line">
                    <div class="">{{ text.orderSubtotal }}:</div>
                    <div class="">{{ order.displayOrderSubtotal }}</div>
                </div>
                <div class="totals-row value-discount">
                    <div class="">{{ text.orderDiscount }}:</div>
                    <div class="">- {{ order.displayOrderDiscountTotal }}</div>
                </div>
                <div class="totals-row value-line" *ngFor = "let fee of feesCategory.taxable">
                    <div class="">{{ fee.displayName }}:</div>
                    <div class="">{{ fee.displaySubtotal }}</div>
                </div>
                <div class="totals-row value-line">
                    <div class="">{{ text.orderSalesTax }}:</div>
                    <div class="">{{ order.displayOrderTax }}</div>
                </div>
                <div class="totals-row value-line" *ngFor = "let fee of feesCategory.nonTaxable">
                    <div class="">{{ fee.displayName }}:</div>
                    <div class="">{{ fee.displaySubtotal }}</div>
                </div>
                <div class="totals-row value-line">
                    <div class="">{{ text.orderGratuity }}:</div>
                    <div class="">{{ order.displayOrderGratuity }}</div>
                </div>
                <div class="totals-row value-total">
                    <div class="">{{ text.orderTotal }}:</div>
                    <div class="">{{ order.displayOrderTotal }}</div>
                </div>
                <div class="totals-row value-refund">
                    <div class="">{{ text.refundTotal }}:</div>
                    <div *ngIf="refundTypeSelected === refundType.entire">
                        {{ order.displayOrderTotal }}
                    </div>
                    <div
                        *ngIf="refundTypeSelected === refundType.partial"
                        class="partial-refund-amount"
                    >
                        <div class="currency-symbol">
                            {{ order.currency | currencySymbol }}
                        </div>
                        {{ partialRefundAmountControl.value }}
                    </div>
                </div>

                <div class="input-label select-auth-label">
                    {{ text.selectAuthorizer }}:
                    <span *ngIf="errors.username" class="error-label">
                        <div class="icon icon-cr-error"></div>
                        {{ text.errorRequiredSelect }}
                    </span>
                </div>

                <div>
                    <select
                        class="input-field"
                        (change)="selectAuthorizer($event)"
                    >
                        <option value="">{{ text.selectdotdotdot }}</option>
                        <option
                            *ngFor="let user of users"
                            value="{{ user.username }}"
                        >
                            {{ user.displayName }}
                        </option>
                    </select>
                </div>

                <div class="input-label">
                    {{ text.enterAuthCode }}:
                    <span *ngIf="errors.authorizationCode" class="error-label">
                        <div class="icon icon-cr-error"></div>
                        {{ text.errorRequiredField }}
                    </span>
                    <span
                        *ngIf="errors.authorizationCodeValidation"
                        class="error-label"
                    >
                        <div class="icon icon-cr-error"></div>
                        {{ text.errorAuthCode }}
                    </span>
                </div>

                <div>
                    <input
                        class="input-field"
                        (keyup)="onAuthCodeChanged($event)"
                        (change)="onAuthCodeChanged($event)"
                        (blur)="onValidateAuthCode()"
                        type="password"
                        id="code"
                        name="code"
                        minlength="6"
                        maxlength="6"
                        size="6"
                    />
                </div>

                <div class="authorize-refund-buttons">
                    <button
                        type="button"
                        [ngClass]="{
                            btn: true,
                            'btn-primary': true,
                            disabled: isRefunding
                        }"
                        [disabled]="!canSubmit"
                        placement="center"
                        (click)="doRefund()"
                    >
                        <span *ngIf="!isRefunding">
                            {{ text.authorizeRefund }}
                        </span>
                        <cr-spinner *ngIf="isRefunding"></cr-spinner>
                    </button>

                    <button
                        type="button"
                        [ngClass]="{
                            btn: true,
                            'btn-default': true,
                            'btn-link': true,
                            disabled: isRefunding
                        }"
                        placement="center"
                        (click)="onBack()"
                    >
                        {{ text.cancel }}
                    </button>
                </div>

                <br />
            </div>
        </section>
    </div>

    <div
        *ngIf="!hasError && isLoading"
        class="cr-page-padding-top cr-flex-center"
    >
        <cr-spinner></cr-spinner>
    </div>

    <cr-data-error
        *ngIf="hasError"
        [message]="text.loadingFailed"
    ></cr-data-error>
</section>
