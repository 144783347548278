import text from './resources/locale/en.json';

class Cvt {
    constructor(crConstants) {
        this.crConstants = crConstants;
    }

    $onInit() {
        this.text = text;
    }
}

export default Cvt;
