import PoiMapController from './poi-map.controller';
import template from './poi-map.html';
import './poi-map.less';

const PoiMapComponent = {
    template,
    controller: ['$state', 'crPoiMapService', 'crErrorLoggingService', '$q', 'crAnalyticsService', PoiMapController],
};

export default PoiMapComponent;
