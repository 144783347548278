import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserService } from '../user/user.service';
import { CustomerService } from '../customer/customer.service';
import { Config } from '../../../core';

@Injectable()
export class ConfigService {
  private config: Config = {} as Config;

  constructor(private http: HttpClient, private userService: UserService, private customerService: CustomerService) {}

  get props(): Config {
    return this.config;
  }

  init(): Promise<void> {
    return this.http
      .get('/config')
      .toPromise()
      .then((res) => {
        Object.assign(this.config, res);
      });
  }

  async addAllConfigs(): Promise<void> {
    await this.addUserConfig();
    await this.addCustomerConfig();
  }

  addCustomerConfig(): Promise<void> {
    if (this.config.customer) {
      return Promise.resolve();
    }

    return this.customerService.getCustomer()
      .then((res) => {
        Object.assign(this.config, { customer: res });
      });
  }

  addUserConfig(): Promise<void> {
    if (this.config.user) {
      return Promise.resolve();
    }

    return this.userService.getUser().then((user) => {
      Object.assign(this.config, { user });
    });
  }

  clearCustomerConfig(): void {
    delete this.config.customer;
  }

  clearUserConfig(): void {
    delete this.config.user;
  }
}
