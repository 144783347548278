import AppController from './app.controller';
import template from './app.html';
import './app.less';

const AppComponent = {
    controllerAs: 'appCtrl',
    template,
    controller: ['$rootScope', AppController],
};

export default AppComponent;
