import angular from 'angular';
import text from './resources/locale/en.json';

import CreateMenuModalComponent from './create-menu-modal.component';

const CreateMenuModalModule = angular
    .module('control-room.app.menus.create-menu-modal', [])
    .constant('crCreateMenuModalText', text)
    .component('crCreateMenuModal', CreateMenuModalComponent);

export default CreateMenuModalModule;
