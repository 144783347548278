<div class="cr-image-grid">
    <div class="image-mini-container"
         *ngFor="let image of images; index as i"
         [ngClass]="{selected: selectedIndex === i}"
         [ngStyle]="{'background-image': 'url(\'' + image.displaySrc + '\')'}"
         (click)="onImageClick.emit(i)"
    ></div>
    <div
        *ngIf="(images.length > 0) && (images.length < maxImages)"
        class="image-mini-container image-add"
        (click)="onAddClick.emit()">
        <div class="icon-cr-add"></div>
    </div>
</div>
