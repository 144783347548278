
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
  })
export class LoQueueService {
    url: string;

    constructor(private http: HttpClient) {
        this.url = '/rest/loqueue';
    }

    getRides(venueId: string) {
        return this.http.get(`${this.url}/${venueId}/guest/rides`).toPromise().then((res) => res);
    }

    getAddons(venueId: string) {
        return this.http.get(`${this.url}/${venueId}/guest/addons`).toPromise().then((res) => res);
    }
}

export default LoQueueService;
