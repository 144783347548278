import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StateService } from '@uirouter/core';
import { LocalizedText } from '../../core';
import { AnalyticsService, AuthService, UserService } from '../../shared';

import text from './resources/locale/en.json';

@Component({
  selector: 'cr-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  text: LocalizedText;

  form: FormGroup;

  pending: boolean;

  title: string;

  subtitle: string;

  showErrorIcon: boolean;

  sessionExpired = false;

  constructor(
    private fb: FormBuilder,
    private location: Location,
    private state: StateService,
    private authService: AuthService,
    private userService: UserService,
    private analyticsService: AnalyticsService,
  ) {
    this.text = text as LocalizedText;
  }

  ngOnInit() {
    this.form = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });

    this.title = this.text.title;
    this.subtitle = '';
    if (this.state.params.timeout) {
      this.sessionExpired = true;
    }
  }

  handleTimeoutDismiss() {
    this.state.go('uac.login', { timeout: undefined });
  }

  async login() {
    if (this.form.valid) {
      this.pending = true;

      const { username, password } = this.form.value;

      try {
        const response = await this.authService.login(username, password);

        if (response.firstLogin) {
          this.state.go('uac.change-password', { username });
        } else if (!response.err) {
          this.analyticsService.identify(username);
          this.analyticsService.track('Sign In', { username });
          this.userService.login();
          if (this.state.params.to) {
            this.location.go(this.state.params.to);
          } else {
            this.state.go('client');
          }
        } else {
          this.pending = false;
          this.showErrorIcon = true;
          this.title = this.text.errors.server.title;
          this.subtitle = this.text.errors.server.subTitle;
        }
      } catch (err) {
        this.pending = false;
        this.showErrorIcon = true;
        this.title = this.text.errors.server.title;
        this.subtitle = this.text.errors.server.subTitle;
      }
    }
  }
}
