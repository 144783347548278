<div class="cr-input-header">
    <label class="cr-label" *ngIf="label">{{ label }}</label>
    <cr-form-error
        class="input-error"
        *ngIf="!hideValidation"
        [for]="control"
        [showValidMessage]="showValidMessage"
        [messages]="validationMessages"
    >
    </cr-form-error>
</div>

<input
    #input
    class="control"
    [type]="type"
    [attr.maxlength]="maxlength"
    [placeholder]="placeholder"
    [disabled]="disabled"
    [value]="value"
    [step]="step"
    (input)="onInput(input.value)"
    (focus)="onFocus($event)"
    (blur)="onBlur($event)"
    (keypress)="onKeypress($event)"
    (keyup)="onKeyup($event)"
/>
