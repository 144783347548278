import angular from 'angular';

import ActionItemListModule from './action-item-list/action-item-list';
import ActionSummaryModule from './action-summary-modal/action-summary-modal';
import AssociateEntityModule from './associate-entity-modal/associate-entity-modal';
import AttributePhonePreview from './attribute-phone-preview/attribute-phone-preview';
import AttributesListModule from './attributes-list/attributes-list';
import AttributesPresentationModule from './attributes-presentation/attributes-presentation';
import CtaButtonDetailsModule from './cta-button-details/cta-button-details';
import CtaButtonsDetails from './cta-buttons-details/cta-buttons-details';
import ExtensionsModule from './extensions/extensions';
import FeaturedEventsPhonePreview from './featured-events-phone-preview/featured-events-phone-preview';
import ImageUploaderModule from './image-uploader/image-uploader';
import ImageViewerModule from './image-viewer/image-viewer';
import ImageEventModule from './image-event/image-event';
import InlineChipsModule from './inline-chips/inline-chips';
import EntityCategoriesModule from './entity-categories/entity-categories';
import EntityCategoriesEditModule from './entity-categories-edit/entity-categories-edit';
import EntityLinkModule from './entity-link/entity-link';
import EntityManageDeleteLinkModule from './entity-manage-delete-link/entity-manage-delete-link';
import ManageAttributeModule from './manage-attribute/manage-attribute';
import PhoneFilterViewModule from './phone-filter-view/phone-filter-view';
import MenuModifierGroupModule from './menu-modifier-group/menu-modifier-group';
import AssociatedEntitiesListModule from './associated-entities-list/associated-entities-list';
import PdfUploaderModule from './pdf-uploader/pdf-uploader';

const ComponentsModule = angular.module('control-room.commons.content.components', [
    ActionItemListModule.name,
    ActionSummaryModule.name,
    AssociateEntityModule.name,
    AttributePhonePreview.name,
    AttributesListModule.name,
    AttributesPresentationModule.name,
    CtaButtonDetailsModule.name,
    CtaButtonsDetails.name,
    ExtensionsModule.name,
    FeaturedEventsPhonePreview.name,
    ImageEventModule.name,
    ImageUploaderModule.name,
    ImageViewerModule.name,
    InlineChipsModule.name,
    EntityCategoriesModule.name,
    EntityCategoriesEditModule.name,
    EntityLinkModule.name,
    EntityManageDeleteLinkModule.name,
    ManageAttributeModule.name,
    PhoneFilterViewModule.name,
    MenuModifierGroupModule.name,
    AssociatedEntitiesListModule.name,
    PdfUploaderModule.name,
]);

export default ComponentsModule;
