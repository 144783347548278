import { Injectable } from '@angular/core';
import { StateService } from '@uirouter/core';
import { StorageNamespace } from '../../../core';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor(private state: StateService, private userService: UserService) {}

  async getStorage(): Promise<StorageNamespace> {
    const user = await this.userService.getUser();
    const customer = this.state.params.customerId;
    const venue = this.state.params.venueId;

    let namespace = `${customer}.`;
    if (user && user.username) {
      namespace += `${user.username}.`;
    }
    if (venue) {
      namespace += `${venue}.`;
    }

    return new StorageNamespace(namespace);
  }
}
