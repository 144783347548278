import { NgModule } from '@angular/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { CrExportButtonComponent } from './export-button.component';
import { CrSpinnerModule } from '../spinner/spinner.module';

@NgModule({
  imports: [BrowserModule, TooltipModule, CrSpinnerModule],
  declarations: [CrExportButtonComponent],
  exports: [CrExportButtonComponent],
})
export class CrExportButtonModule {}
