import moment from 'moment';
import 'moment-timezone';

import text from './resources/locale/en.json';

import DataFilter from '../../../../commons/controller/data-filter';

class ExperienceCalendar extends DataFilter {
    constructor(
        $state,
        $scope,
        $http,
        crConstants,
        crExperiencesService,
        crErrorLoggingService,
        crNavigationService,
        crVenueService,
        crToastService,
        crAnalyticsService,
        crPoiService
    ) {
        super($state, crVenueService, crToastService, crAnalyticsService);

        this.$state = $state;
        this.$scope = $scope;
        this.$http = $http;
        this.crConstants = crConstants;
        this.crExperiencesService = crExperiencesService;
        this.crErrorLoggingService = crErrorLoggingService;
        this.crNavigationService = crNavigationService;
        this.crVenueService = crVenueService;
        this.crToastService = crToastService;
        this.crAnalyticsService = crAnalyticsService;
        this.crPoiService = crPoiService;

        this.text = text;
    }

    $onInit() {
        super.$onInit();
        this.initFilters();

        this.$scope.$on(this.crConstants.events.UPDATE_CALENDAR_VIEW, (e, data) => {
            this.onWeekUpdate(data);
        });

        this.$scope.$on(this.crConstants.events.CANCEL_CALENDAR_INSTANCE, (e, data) => {
            this.onExperienceCancel(e, data.toast);
        });
    }

    initFilters() {
        this.registerParam('status', this.$state.params.status);
        this.registerParam('type', this.$state.params.type);
        this.registerParam('start', moment().tz(this.venueTimezone.name).startOf('week').toISOString());
        this.registerParam('end', moment().tz(this.venueTimezone.name).endOf('week').toISOString());

        this.currentRange = {
            start: this.params.start,
            end: this.params.end,
        };

        this.initStatusFilter();
        this.initTypeFilter();
    }

    getData(params) {
        this.updateSearchDeepLink();
        this.isLoading = true;

        // clear data
        if (this.schedule) {
            this.schedule = [];
        }

        if (!this.locationMap) {
            return this.loadLocationMap().then(() => this.doGet(params));
        }

        return this.doGet(params);
    }

    loadLocationMap() {
        const locationMap = {
            POI: {},
            ANYWHERE: this.text.anywhere,
            UNKNOWN: '',
            AT_VENUE: this.text.atVenue,
            IN_VENUE: this.text.inVenue,
            OUTSIDE_VENUE: this.text.outsideVenue,
            IN_GEOFENCE: this.text.inGeofence,
            OUTSIDE_GEOFENCE: this.text.outsideGeofence,
        };

        return this.crPoiService
            .getPois({ state: 'ACTIVE', venueId: this.$state.params.venueId, userDetectEnabled: true })
            .then((res) => {
                res.content.forEach((poi) => {
                    locationMap.POI[poi.id] = poi.name;
                });

                this.locationMap = locationMap;
            });
    }

    doGet(params) {
        return this.$http
            .get('/rest/calendars/experiences', { params })
            .then((res) => {
                const { data } = res;
                data.search = data.query.search;

                if (data.search === this.params.search) {
                    this.schedule = res.data.content;
                    this.isLoading = false;
                }
            })
            .catch((err) => {
                this.dataError = true;
                this.isLoading = false;
                this.crErrorLoggingService.logError('Could not get Calendar Experiences', err, this.params);
            });
    }

    onExperienceCancel(e, toast) {
        e.stopPropagation();

        if (toast) {
            this.crToastService.toast(toast);
        }

        this.getData(this.params);
    }

    onWeekUpdate(event) {
        this.params.start = event.model.start;
        this.params.end = event.model.end;
        this.getData(this.params);
        this.currentRange = event.model;
    }

    initStatusFilter() {
        this.statusFilterData = [
            { id: 'ACTIVE', label: this.text.active },
            { id: 'INACTIVE', label: this.text.inactive },
        ];
    }

    initTypeFilter() {
        this.crExperiencesService.getExperienceTypes().then((data) => {
            const allTypes = data.automations.concat(data.broadcasts);
            this.typeFilterData = allTypes
                .filter((type) => {
                    if (type.id === 'BROADCAST_NOW') {
                        return false;
                    }

                    return !(type.parent && type.parent.id);
                })
                .map((type) => ({
                    id: type.id,
                    label: type.name,
                }));
        });
    }

    goBack() {
        this.crNavigationService.goBack('client.experience-promoter.dashboard');
    }
}

export default ExperienceCalendar;
