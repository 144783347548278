class ModalController {
    constructor(text, constants) {
        this.text = text;
        this.modalTypes = constants.modalTypes;
    }

    $onInit() {
        this.submitText = this.submitText || this.text.submit;
        this.cancelText = this.cancelText || this.text.cancel;
        this.closeText = this.closeText || this.text.close;
        this.type = this.type || this.modalTypes.SUBMIT;
    }
}

export default ModalController;
