import Controller from './events.controller';
import template from './events.html';
import './events.less';

const Component = {
    template,
    controller: ['$state', 'crConstants', 'crAnalyticsService', Controller],
};

export default Component;
