
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Beacon, BeaconsList } from './beacons.model';

@Injectable({
    providedIn: 'root',
  })
export class BeaconsService {
    url: string;

    constructor(private http: HttpClient) {
        this.url = '/rest/beacons';
    }

    getBeacons(params: any): Promise<BeaconsList> {
        return this.http.get<BeaconsList>(this.url, { params }).toPromise();
    }

    getBeaconDetails(beaconId: string): Promise<Beacon> {
        return this.http.get<Beacon>(`${this.url}/${beaconId}`, {}).toPromise();  
    }

    createBeacon(beaconData: Beacon): Promise<Beacon>  {
        return this.http.post<Beacon>(this.url, beaconData).toPromise();
    }

    updateBeacon(beaconId: string, beaconData: Beacon): Promise<Beacon> {
       return  this.http.put<Beacon>(`${this.url}/${beaconId}`, beaconData).toPromise();
    }

    deleteBeacon(beaconId): Promise<any> {
        return this.http.delete<any>(`${this.url}/${beaconId}`).toPromise();
    }
}

export default BeaconsService;
