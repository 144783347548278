import _ from 'lodash';

import text from './resources/locale/en.json';

class VenueHoursManage {
    constructor(
        $state,
        $uibModal,
        crConfig,
        crConstants,
        crEntityService,
        crNavigationService,
        crAnalyticsService,
        crErrorLoggingService,
        crLocaleService
    ) {
        this.$state = $state;
        this.$uibModal = $uibModal;
        this.crConfig = crConfig;
        this.crConstants = crConstants;
        this.crEntityService = crEntityService;
        this.crNavigationService = crNavigationService;
        this.crAnalyticsService = crAnalyticsService;
        this.crErrorLoggingService = crErrorLoggingService;
        this.crLocaleService = crLocaleService;
    }

    $onInit() {
        this.text = text;
        this.form = {};
        this.entity = {};
        this.entityType = this.crConstants.entity.types.VENUE_HOURS;
        this.titleBarLabel = `${this.isEdit ? this.text.edit : this.text.create} ${this.text.venueHours}`;
        this.isDefaultLocale = true;

        const stateParams = this.$state.params;
        this.locale = this.crLocaleService.getLocale(stateParams.venueId, stateParams.locale, !this.isEdit);
        this.initCreate();
        this.initDropdown();

        this.crNavigationService.enableConfirmNavigation(() => this.form.$dirty);

        if (this.isEdit) {
            const params = [this.entityType, this.$state.params.id, this.$state.params.venueId, this.locale.default];

            this.getEntityDetails(...params);
        } else {
            this.initCreate();
        }
    }

    getEntityDetails(entityType, id, venueId, locale) {
        this.isLoading = true;

        this.crEntityService
            .getEntity(entityType, id, venueId, locale)
            .then((entity) => {
                this.entity = entity;
                this.locale.list = this.crLocaleService.updateLocaleStatus(this.entity.localization, this.locale);

                this.entity.extensions = (this.entity.extensions || []).map((extension) => ({
                    id: extension.id,
                    value: JSON.stringify(extension.value, false, '  '),
                }));
            })
            .catch((err) => {
                this.hasDataError = true;
                this.crErrorLoggingService.logError('Could not get Venue Hours Schedule', err, { id });
                this.handleDetailsError(err, 'venue-hours.order');
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    initCreate() {
        // Init localization property
        this.entity.localization = {};
        _.forEach(this.locale.list, (lang) => {
            this.entity.localization[lang.id] = {
                label: '',
                url: '',
            };
        });

        this.entity.extensions = [];
    }

    save() {
        this.form.$setSubmitted();

        if (this.form.$valid) {
            if (this.form.$dirty) {
                this.createOrUpdate();
            } else {
                this.goBack();
            }
        }
    }

    createOrUpdate() {
        this.isLoading = true;

        const entityType = this.crConstants.entity.types.VENUE_HOURS;
        const { venueId } = this.$state.params;
        const createOrUpdate = this.isEdit ? this.updateEntity.bind(this) : this.createEntity.bind(this);

        // TODO: fix the extension builder to emit to correct model
        const entity = _.cloneDeep(this.entity);
        entity.extensions = (entity.extensions || []).map((extension) => ({
            id: extension.id,
            value: JSON.parse(extension.value),
        }));

        createOrUpdate(entityType, entity, venueId)
            .then((res) => {
                this.onSaveSuccess(res);
            })
            .catch((err) => {
                this.handleError(`Could not ${this.isEdit ? 'edit' : 'create'} entity`, err);
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    updateEntity(type, entity, venueId) {
        return this.crEntityService.updateEntity(type, entity.id, entity, venueId);
    }

    createEntity(type, entity, venueId) {
        entity.owner = {
            id: venueId,
            type: 'VENUE',
        };

        return this.crEntityService.createEntity(type, entity, venueId);
    }

    onSaveSuccess(data) {
        this.crAnalyticsService.track(`${this.isEdit ? 'Edit' : 'Create'} Venue Hours Success`, data);
        this.crNavigationService.disableConfirmNavigation();
        this.crNavigationService.goBack('client.venue-hours.order', this.getToastParams(data));
    }

    onLocaleUpdate(event) {
        this.locale.current = event.model[0].id;
        this.isDefaultLocale = this.locale.current === this.locale.default;
        this.locale.list = this.crLocaleService.updateLocaleStatus(this.entity.localization, this.locale);
        this.crAnalyticsService.track('Language Selected', { language: event.model[0].label });
    }

    onLabelChange(event) {
        if (this.locale.current === this.locale.default) {
            // Update main model to stay in sync
            this.entity.label = event.model;
        }
        this.entity.localization[this.locale.current].label = event.model;
        this.updateLocaleListStatus();
    }

    onLinkChange(event) {
        if (this.locale.current === this.locale.default) {
            // Update main model to stay in sync
            this.entity.url = event.model;
        }
        this.entity.localization[this.locale.current].url = event.model;
        this.updateLocaleListStatus();
    }

    onExtensionChange(event) {
        this.entity.extensions = event.rows;
    }

    getToastParams(data) {
        const toastMsg = this.isEdit ? this.text.hasBeenUpdated : this.text.hasBeenCreated;

        return {
            id: data.id,
            toast: {
                msg: `"${data.label}" ${toastMsg}`,
            },
        };
    }

    handleError(errMsg, err) {
        this.showErrorModal();
        this.crErrorLoggingService.logError(errMsg, err);
    }

    showErrorModal() {
        const message = this.isEdit ? this.text.updateError : this.text.createError;

        this.$uibModal
            .open({
                backdrop: 'static',
                component: 'crSimpleModal',
                windowClass: 'cr-modal-size-sm',
                resolve: {
                    message: () => message,
                },
            })
            .result.catch(() => this.form.$setDirty());
    }

    handleDetailsError(err, routeName) {
        const params = {
            customerId: this.$state.params.customerId,
            venueId: this.$state.params.venueId,
        };

        if (err.status === 404) {
            this.$state.go('client.404', params);
        } else {
            this.$uibModal
                .open({
                    backdrop: 'static',
                    component: 'crSimpleModal',
                    windowClass: 'cr-modal-size-sm',
                    resolve: {
                        message: () => this.text.standardErrorMessage,
                    },
                })
                .result.catch(() => {
                    this.$state.go(routeName, params);
                });
        }
    }

    updateLocaleListStatus() {
        this.locale.list = this.crLocaleService.updateLocaleStatus(this.entity.localization, this.locale);
    }

    initDropdown() {
        this.showDropdown =
            this.crConfig.customer.localizationEnabled && this.locale.list && this.locale.list.length > 1;
    }

    isInvalidForm() {
        const { localization } = this.entity;
        return localization && (!localization[this.locale.default].label || !localization[this.locale.default].url);
    }

    goBack() {
        const eventName = `${this.isEdit ? 'Edit' : 'Create'} Venue Hours Canceled`;
        this.crAnalyticsService.track(eventName);
        this.crNavigationService.goBack('client.venue-hours.order');
    }
}

export default VenueHoursManage;
