import controller from './product-details.controller';
import template from './product-details.html';
import './product-details.less';

const component = {
    template,
    controller: [
        '$state',
        '$uibModal',
        'crConfig',
        'crConstants',
        'crEntityService',
        'crLocaleService',
        'crToastService',
        'crErrorLoggingService',
        'crImageService',
        'crNavigationService',
        'crCommerceService',
        controller,
    ],
};

export default component;
