<div class="order-state-buttons shadow">
  <div
    *ngIf="
      order.fulfillmentStatus !== 'UNFULFILLED' &&
      order.orderStatus === 'RELEASED'
    "
    (click)="updateOrderFulfillment('UNFULFILLED')"
    class="order-state-button unfulfilled"
  >
    <div class="centered">
      {{ text.status.UNFULFILLED }}
    </div>
  </div>
  <div
    *ngIf="
      order.fulfillmentStatus !== 'IN_PROCESS' &&
      order.orderStatus === 'RELEASED'
    "
    (click)="updateOrderFulfillment('IN_PROCESS')"
    class="order-state-button in-progress"
  >
    <div class="centered">
      {{ text.status.IN_PROCESS }}
    </div>
  </div>
  <div
    *ngIf="
      order.fulfillmentStatus !== 'FULFILLED' &&
      order.orderStatus === 'RELEASED'
    "
    (click)="updateOrderFulfillment('FULFILLED')"
    class="order-state-button fulfilled"
  >
    <div class="centered">
      {{ text.status.FULFILLED }}
    </div>
  </div>
  <div
    *ngIf="
      order.fulfillmentStatus !== 'FULFILLED' &&
      order.orderStatus === 'SUBMITTED'
    "
    (click)="checkInOrder()"
    class="order-state-button fulfilled"
  >
    <div class="centered">
      {{ text.status.CHECKIN }}
    </div>
    <div class="centered" *ngIf="isLoading">
      <cr-spinner></cr-spinner>
    </div>
  </div>
  <div
    *ngIf="
      order.fulfillmentStatus !== 'FULFILLED' &&
      order.orderStatus === 'SUBMITTED'
    "
    (click)="handleCancelButton()"
    class="order-state-button cancel"
  >
    <div class="centered">CANCEL</div>
  </div>
</div>
