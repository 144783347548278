import _ from 'lodash';

import text from './resources/locale/en.json';

class AttributesBuilderRowController {
    constructor(crConstants, $timeout) {
        this.attributeTypes = crConstants.attributeTypes;
        this.$timeout = $timeout;
    }

    $onChanges(changes) {
        if (changes && changes.isSubmitted && changes.isSubmitted.currentValue) {
            this.$timeout(() => this.rowForm.$setSubmitted());
        }
    }

    $onInit() {
        this.row.isDraggable = true;
        this.text = text;
        this.rowForm = {};

        const attrList = _.find(this.crRowBuilder.attrList, (item) => item.state.id === this.row.id);
        const { enumValues } = attrList.state;

        if (enumValues) {
            enumValues.forEach((eenum) => {
                eenum.isSelected = _.findIndex(this.row.value, { id: eenum.id }) !== -1;
            });

            this.row.enumValues = enumValues;
        }
    }

    onUpdate($event) {
        this.row.value = $event.model;
    }

    onSelect($event) {
        this.row.value = $event.model;
        this.row.enumValues.forEach((eenum) => {
            eenum.isSelected = _.findIndex(this.row.value, { id: eenum.id }) !== -1;
        });
    }
}

export default AttributesBuilderRowController;
