import TabularTopItemsController from './tabular-top-items.controller';
import template from './tabular-top-items.html';
import './tabular-top-items.less';

const TabularTopItemsComponent = {
    template,
    controller: [
        '$state',
        'crReportingTabularTopItemsMockService',
        'crConstants',
        'crDateRangeService',
        'crNavigationService',
        'crReportingTabularTopItemsText',
        TabularTopItemsController,
    ],
};

export default TabularTopItemsComponent;
