import text from './resources/locale/en.json';
import ListPageController from '../../../controller/list-page';

class AttributesListController extends ListPageController {
    constructor(
        $state,
        crErrorLoggingService,
        crAnalyticsService,
        crToastService,
        crLocaleService,
        crConfig,
        crImageService,
        crAttributesListService,
        crNavigationService,
        crEntityLocalizationService
    ) {
        super($state, crErrorLoggingService, crAnalyticsService, crToastService, crLocaleService, crConfig);
        this.$state = $state;
        this.crErrorLoggingService = crErrorLoggingService;
        this.crAnalyticsService = crAnalyticsService;
        this.crToastService = crToastService;
        this.crImageService = crImageService;
        this.crAttributesListService = crAttributesListService;
        this.crNavigationService = crNavigationService;
        this.crEntityLocalizationService = crEntityLocalizationService;
    }

    $onInit() {
        super.$onInit();
        this.text = text;

        this.listData = {
            columns: [
                { key: 'icon', label: '' },
                { key: 'name', label: this.text.name, class: 'large-column name-column', sortable: true },
                { key: 'label', label: this.text.label, class: 'large-column', sortable: true },
                { key: 'values', label: this.text.values },
                { key: '', label: '', class: 'small-column' },
            ],
            rows: [],
        };

        if (this.hasMultipleLanguages()) {
            this.addMissingTranslationsColumnHeader();
        }

        this.text.title = this.crEntityLocalizationService.getLocalizedEntityName(this.entityType);
    }

    getData() {
        this.isLoading = true;
        this.dataError = false;

        return this.crAttributesListService
            .getData(this.entityType, this.params)
            .then((data) => {
                data.search = data.query.search || '';

                this.crImageService.setIconSrc(data.content);

                if (data.search === this.params.search) {
                    this.updateSearchDeepLink();
                    return this.crAttributesListService.mapToRowsModel(data.content);
                }
            })
            .catch((err) => {
                this.dataError = true;
                this.endOfResults = true;
                this.listData.rows = [];
                this.crErrorLoggingService.logError('Could not get Attributes list data', err);
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    editAttribute(attribute) {
        this.$state.go(`client.${this.attributeType}.manage-attributes`, {
            id: attribute.id,
        });
    }

    createAttribute() {
        this.$state.go(`client.${this.attributeType}.manage-attributes`);
    }

    goBack() {
        this.crNavigationService.goBack(`client.${this.attributeType}.list`);
    }

    getValuesAsCSV(values) {
        return this.crAttributesListService.getValuesAsCSV(values);
    }
}

export default AttributesListController;
