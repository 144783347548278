<section class="cr-time-picker" [ngClass]="customClass">
    <div
        ngbDropdown
        #timeDropDown="ngbDropdown"
        placement="bottom-right"
        (openChange)="dropDownOpenChange($event)"
    >
        <div class="input-container">
            <cr-input
                maxlength="8"
                data-local-qa-id="time"
                custom-class="time-input"
                [placeholder]="placeholder"
                [hideValidation]="hideValidation"
                [disabled]="disabled"
                (focus)="timeDropDown.open()"
            ></cr-input>
            <button
                ngbDropdownToggle
                class="time-icon icon-cr-time"
                [disabled]="disabled"
            ></button>
        </div>

        <ul ngbDropdownMenu #dropDownMenu class="cr-time-picker-container">
            <li *ngFor="let time of timeSeries" ngbDropdownItem>
                <a
                    role="option"
                    (click)="updateTime(time)"
                    [ngClass]="{ 'menu-item-selected': time === timeString }"
                >
                    <span>{{ time }}</span>
                </a>
            </li>
        </ul>
    </div>
</section>
