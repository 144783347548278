import angular from 'angular';

import component from './products-list.component';
import service from './products-list.service';

const ProductsList = angular
    .module('control-room.menus.components.products-list', [])
    .component('crProductsList', component)
    .service('crProductsListService', ['crConstants', 'crImageService', 'crEntityService', service]);

export default ProductsList;
