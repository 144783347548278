<section [formGroup]="form" class="content-details" *ngIf="manage">
  <div class="cr-flex-between">
    <div class="detail-section-title">{{ text.content }}</div>
    <!--
    Localization control which will be used when localization is implemented
    <cr-dropdown
      *ngIf="showLocaleDropdown"
      data-global-qa-id="locale-dropdown"
      formControlName="selectedLocale"
      hideResetOption="true"
      mode="filter"
      [menuItems]="locale.list"
    ></cr-dropdown>
    -->
  </div>
  <div class="cr-flex-between">
    <div class="cr-stretch">
      <div>
        <cr-form-label-error for="title" data-global-qa-id="entity-title-error">
          <span>{{ text.title }}</span>
          <span *ngIf="isDefaultLocale"> *</span>
        </cr-form-label-error>
        <cr-input
          name="title"
          formControlName="title"
          data-global-qa-id="entity-title"
          hideValidation="true"
          class="input-container content-detail"
          [maxlength]="maxTitleLength"
        ></cr-input>
      </div>
      <div>
        <label class="control-label">
          {{ text.description }}
        </label>
        <textarea
          formControlName="description"
          data-global-qa-id="entity-description"
          [maxlength]="maxDescriptionLength"
        ></textarea>
      </div>
    </div>
    <div class="image-uploader-container">
      <label class="control-label">{{ text.images }}</label>
      <cr-image-uploader
        imageType="{{ constants.image.types.MAIN }}"
        name="mainImageField"
        [images]="form.get('images').value"
        [imageMinSize]="imageMinSize"
        (onChange)="onImageUpdate($event)"
        [cropperToolHeader]="cropperToolHeader"
      ></cr-image-uploader>
    </div>
  </div>
</section>
