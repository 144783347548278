import { NgModule } from '@angular/core';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { LoginComponent } from './login/login.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { LegacyBrowserComponent } from './legacy-browser/legacy-browser.component';
import { UacComponent } from './uac.component';

export function dynamic(value?) {
  return {
    value,
    dynamic: true,
  };
}

export const states = [
  {
    name: 'uac',
    url: '/uac',
    component: UacComponent,
    abstract: true,
  },
  {
    name: 'uac.login',
    url: '/login?timeout&to',
    component: LoginComponent,
    params: {
      timeout: null,
      to: dynamic(),
    },
  },
  {
    name: 'uac.change-password',
    url: '/change-password?username&to',
    component: ChangePasswordComponent,
    params: {
      username: '',
      to: dynamic(),
    },
  },
  {
    name: 'uac.legacy-browser',
    url: '/legacy-browser',
    component: LegacyBrowserComponent,
  },
  {
    name: 'uac.otherwise',
    url: '*path',
    redirectTo: 'uac.login',
  },
];

@NgModule({
  imports: [UIRouterUpgradeModule.forChild({ states })],
  exports: [UIRouterUpgradeModule],
})
export class UacRoutingModule {}
