import { NgModule } from '@angular/core';
import {
  CommonModule, Location, LocationStrategy, HashLocationStrategy,
} from '@angular/common';
import { CrFormsModule, CrSpinnerModule, CrIconModule } from '../shared';
import { LoginComponent } from './login/login.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { LegacyBrowserComponent } from './legacy-browser/legacy-browser.component';
import { UacComponent } from './uac.component';
import { UacRoutingModule } from './uac.routing';

@NgModule({
  imports: [CommonModule, CrFormsModule, CrSpinnerModule, CrIconModule, UacRoutingModule],
  declarations: [LoginComponent, ChangePasswordComponent, LegacyBrowserComponent, UacComponent],
  entryComponents: [],
  providers: [
    Location,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
  ],
})
export class UacModule {}
