import angular from 'angular';

import Component from './row-manager.component';
import ComponentsModule from './components/components';

const Module = angular
    .module('control-room.commons.form.components.row-manager', [ComponentsModule.name])
    .component('crRowManager', Component);

export default Module;
