import angular from 'angular';
import text from './resources/locale/en.json';

import SetAuthCodeModalComponent from './set-auth-code-modal.component';

const SetAuthCodeModalModule = angular
    .module('control-room.app.components.set-auth-code-modal', [])
    .constant('crSetAuthCodeModalText', text)
    .component('crSetAuthCodeModal', SetAuthCodeModalComponent);

export default SetAuthCodeModalModule;
