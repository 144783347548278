<form [formGroup]="featuredAndTrending" (submit)="save()">
    <cr-edit-header (cancel)="goBack()">
        {{ text.editFeaturedAndTrending }}
    </cr-edit-header>
    <div class="loading text-center" *ngIf="loading">
        <cr-spinner></cr-spinner>
    </div>
    <div class="error" *ngIf="hasDataError">
        <cr-data-error></cr-data-error>
    </div>
    <div class="content" *ngIf="!hasDataError && !loading">
        <div class="left">
            <section class="featured">
                <h4 class="title t-section-title">{{ text.featured }}</h4>
                <div class="instructions t-smalltext">
                    {{ text.chooseOneFeaturedEvent }}
                </div>
                <cr-select
                    class="featured-select"
                    formControlName="featured"
                    data-global-qa-id="featured"
                    [options]="featuredOptions"
                    [hasDataError]="hasDataError"
                    (change)="onFeaturedSelect()"
                    clear
                    search
                ></cr-select>
            </section>

            <div class="divider"></div>

            <section class="trending">
                <h4 class="title t-section-title">{{ text.trending }}</h4>
                <div class="instructions t-smalltext">
                    {{ text.chooseUpToFiveTrendingEvents }}
                </div>
                <ng-template #trendingSelect let-control="item" let-i="index">
                    <cr-select
                        class="trending-select"
                        [formControl]="control"
                        [options]="trendingOptions"
                        [hasDataError]="hasDataError"
                        [attr.data-local-qa-id]="'trending-' + i"
                        search
                        clear
                        (change)="onTrendingSelect()"
                    ></cr-select>
                </ng-template>

                <cr-reorder-list
                    formArrayName="trending"
                    [items]="trending.controls"
                    [itemTemplate]="trendingSelect"
                    (reorder)="onTrendingReorder($event)"
                    disableMove
                >
                </cr-reorder-list>
            </section>
        </div>
        <div class="right">
            <cr-phone>
                <cr-featured-events-phone-preview
                    [featuredEvents]="phonePreview"
                ></cr-featured-events-phone-preview>
            </cr-phone>
        </div>
    </div>
</form>
