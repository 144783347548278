import text from './resources/locale/en.json';

class CtaButtonDetails {
    $onInit() {
        this.text = text;
        if (this.entity.callToAction && this.entity.callToAction.type === 'MENU' && this.menus.length > 0) {
            const { menuId } = this.entity.callToAction.options;
            const validMenus = this.menus.filter((menu) => menu.id === menuId);
            if (validMenus.length > 0) {
                this.menu = validMenus[0];
            }
        }
    }
}

export default CtaButtonDetails;
