import Controller from './entity-content.controller';
import template from './entity-content.html';
import './entity-content.less';

const Component = {
    template,
    transclude: true,
    require: {
        form: '?^form',
    },
    bindings: {
        locale: '<',
        venueId: '@',
        entity: '<',
        manage: '<',
        localizedTitle: '@',
        localizedDescription: '@',
        customClass: '@?',
        onLocaleSelect: '&',
        onTitleUpdate: '&',
        onDescriptionUpdate: '&',
        onImagesUpdate: '&',
        maxTitleLength: '@?',
        maxDescriptionLength: '@?',
        imageMinSize: '<?',
        cropperToolHeader: '@?',
    },
    controller: [
        'crImageService',
        'crConstants',
        'crErrorLoggingService',
        'crLocaleService',
        'crConfig',
        'crAnalyticsService',
        Controller,
    ],
};

export default Component;
