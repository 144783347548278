import * as _ from 'lodash';
import text from './resources/locale/en.json';

class FormErrorController {
    $onInit() {
        this.text = text;
    }

    hideDefault(type) {
        return this.errorMessages && _.has(this.errorMessages, type);
    }
}

export default FormErrorController;
