import angular from 'angular';

import Component from './week-picker.component';

import directive from './week-highlight.directive';

const WeekPicker = angular
    .module('control-room.commons.basic.components.week-picker', [directive.name])
    .component('crWeekPicker', Component);

export default WeekPicker;
