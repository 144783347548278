import angular from 'angular';

import ReportsComponent from './reports.component';
import ComponentsModule from './components/components';
import locale from './resources/locale/en.json';

import './reports.less';

const ReportsModule = angular
    .module('control-room.reports', [ComponentsModule.name])
    .component('crReports', ReportsComponent)
    .constant('crReportsLocale', locale);

export default ReportsModule;
