import PoiListController from '../../../poi/components/poi-list/poi-list.controller';
import ListPageController from '../../../../commons/controller/list-page';
import { menuRoutes } from '../../menus.routes';

class CreateKitchenModalController extends PoiListController {
    constructor(
        $state,
        $timeout,
        crKitchenModalText,
        crErrorLoggingService,
        crAnalyticsService,
        crToastService,
        crLocaleService,
        crConfig,
        crPoiListService,
        $q,
        crConstants,
        crEntitySelectionManager,
        crCreateKitchenService,
        crNavigationService
    ) {
        super(
            $state,
            crErrorLoggingService,
            crAnalyticsService,
            crToastService,
            crLocaleService,
            crConfig,
            crPoiListService,
            $q,
            crConstants,
            crEntitySelectionManager
        );

        this.text = crKitchenModalText;
        this.crCreateKitchenService = crCreateKitchenService;
        this.crNavigationService = crNavigationService;
        this.$timeout = $timeout;
    }

    $onInit() {
        // This inits the ListPageController
        ListPageController.prototype.$onInit.apply(this);

        this.registerParam('category', '');
        this.registerParam('search', '');

        // Clear search and remember the old one so we can restore it if the screen is canceled
        this.oldSearch = this.$state.params.search;
        this.$state.params.search = '';

        const { columns } = this.text;

        this.listData = {
            columns: [
                { key: 'image', label: '' },
                { key: 'name', label: columns.name, class: 'large-column name-column', sortable: false },
            ],
            rows: [],
        };

        this.stateFilterData = [{ id: 'ACTIVE', label: this.text.active, isSelected: true }];

        this.params.sort = 'asc';
        this.params.state = 'ACTIVE';
    }

    getData() {
        this.isLoading = true;
        this.dataError = false;

        return this.getDataAsync().catch((err) => {
            this.isLoading = false;
            this.dataError = true;
            this.endOfResults = true;
            this.listData.rows = [];
            this.crErrorLoggingService.logError('Could not get POI List data', err);
        });
    }

    async getDataAsync() {
        if (!this.poiToKitchenMap) {
            const kitchenList = await this.crCreateKitchenService.getKitchenList(this.params.venueId);
            const kitchenPoiIds = kitchenList.places
                .map((kitchen) => kitchen.id)
                .filter((id) => id.startsWith('poi:'))
                .map((id) => id.replace(`poi:${this.params.venueId}.`, ''));
            this.poiToKitchenMap = {};
            kitchenPoiIds.forEach((poiId) => {
                this.poiToKitchenMap[poiId] = true;
            });
        }

        if (!this.categoriesFilterData) {
            this.categoriesFilterData = await this.getCategoriesData();
        }

        const poiData = await this.crPoiListService.getData(this.params);
        poiData.content
            .filter((poi) => this.poiToKitchenMap[poi.id])
            .forEach((poi) => {
                poi.isDisabled = true;
            });

        this.isLoading = false;
        return this.crPoiListService.mapToRowsModel(poiData.content, this.categoriesFilterData);
    }

    onPoiClick(poi) {
        // This doesn't mean it was selected.
        // Have to wait a frame since this event can be called before it's marked as
        // isSelected by the selection manager
        this.$timeout(() => {
            if (poi && !poi.isDisabled && poi.isSelected) {
                this.selectedPoi = poi;
            }
        }, 0);
    }

    onClear() {
        if (this.selectedPoi) {
            this.selectedPoi.isSelected = false;
            this.selectedPoi = null;
        }
    }

    hasSelection() {
        return !!this.selectedPoi;
    }

    getTooltip(enabled) {
        return enabled ? `<span>${this.text.rowDisabled}</span>` : '';
    }

    onSubmit(e) {
        e.stopPropagation();

        if (!this.hasSelection() || this.isLoading) {
            return false;
        }

        this.crAnalyticsService.track('Create Kitchen Modal - Success');
        this.crNavigationService.goToFromCreate(menuRoutes.CREATE_KITCHEN, { poiId: this.selectedPoi.id });
        this.close();
    }

    onCancel() {
        this.close(false);
        // Restore the old search query
        this.$state.go(this.$state.current.name, { search: this.oldSearch }, { location: 'replace' });
    }
}

export default CreateKitchenModalController;
