import _ from 'lodash';
import text from './resources/locale/en.json';

class CouponManage {
    constructor(
        $state,
        $uibModal,
        crAnalyticsService,
        crConfig,
        crCouponsService,
        crErrorLoggingService,
        crLocaleService,
        crNavigationService
    ) {
        this.$state = $state;
        this.$uibModal = $uibModal;
        this.crAnalyticsService = crAnalyticsService;
        this.crConfig = crConfig;
        this.crCouponsService = crCouponsService;
        this.crErrorLoggingService = crErrorLoggingService;
        this.crLocaleService = crLocaleService;
        this.crNavigationService = crNavigationService;

        this.text = text;
    }

    $onInit() {
        this.crNavigationService.enableConfirmNavigation(() => this.form.$dirty);
        this.loading = true;
        this.locale = this.crLocaleService.getLocale(this.$state.params.venueId, this.$state.params.locale, !this.edit);
        this.showDropdown = this.crConfig.customer.localizationEnabled && _.get(this.locale, 'list.length') > 1;
        this.states = [
            { id: 'ACTIVE', label: this.text.active },
            { id: 'INACTIVE', label: this.text.inactive },
        ];
        this.typeOptions = [
            { id: 'CODE128', label: this.text.code128Type },
            { id: 'TEXT', label: this.text.textType },
            { id: 'QR', label: this.text.qrType },
        ];

        this.isCopy = !this.edit && this.$state.params.id;

        if (this.edit || this.isCopy) {
            this.loadCoupon().then(() => {
                this.refreshLocaleStatus();
                this.loading = false;
            });
        } else {
            this.initCoupon();
            this.refreshLocaleStatus();
            this.loading = false;
        }
    }

    get isDefaultLocale() {
        return this.locale.current === this.locale.default;
    }

    get isInvalidForm() {
        const hasTitle = !!_.get(this.coupon, `title.${this.locale.current}`);
        const hasTermsAndConditions = !!_.get(this.coupon, `termsAndConditions.${this.locale.current}`);

        return this.isDefaultLocale && (!hasTitle || !hasTermsAndConditions);
    }

    get titleBarLabel() {
        return this.edit ? this.text.editTitle : this.text.createTitle;
    }

    createCoupon() {
        this.crCouponsService
            .createCoupon(this.coupon)
            .then(() => this.onSaveSuccess())
            .catch((err) => this.onSaveError('Could not create coupon', err))
            .finally(() => {
                this.saving = false;
            });
    }

    goBack() {
        this.crAnalyticsService.track('Create Coupon Canceled');
        this.crNavigationService.goBack('client.experience-promoter.coupons-list');
    }

    initCoupon() {
        this.coupon = { title: {}, termsAndConditions: {}, venueId: this.$state.params.venueId };
    }

    loadCoupon() {
        const { id } = this.$state.params;
        return this.crCouponsService
            .getCoupon(id)
            .then((res) => {
                this.coupon = res;

                if (this.isCopy) {
                    this.coupon.name = `${this.text.copyOf} ${this.coupon.name}`;
                    this.coupon.id = null;
                }

                this.selectedClass = this.coupon.status;
                this.states = this.states.map((state) => {
                    state.isSelected = state.id === this.coupon.status;
                    return state;
                });

                this.typeOptions = this.typeOptions.map((type) => {
                    type.isSelected = type.id === this.coupon.type;
                    return type;
                });
            })
            .catch(() => {
                this.error = true;
            });
    }

    onCodeUpdate(event) {
        this.coupon.code = event.model;
    }

    onLocaleUpdate(event) {
        this.locale.current = event.model[0].id;
        this.crAnalyticsService.track('Language Selected', { language: event.model[0].label });
    }

    onNameUpdate(event) {
        this.coupon.name = event.model;
    }

    onSaveError(errMsg, err) {
        const message = this.edit ? this.text.updateError : this.text.createError;

        this.crErrorLoggingService.logError(errMsg, err);

        if (err.status === 400 && _.get(err, 'data.errors.0.type') === 'ASSOCIATED_TO_ENTITIES') {
            this.$uibModal
                .open({
                    backdrop: 'static',
                    component: 'crSimpleModal',
                    windowClass: 'cr-modal-size-sm',
                    resolve: {
                        message: () => this.text.couponLinkedError,
                    },
                })
                .result.catch(() => null);
        } else {
            this.$uibModal
                .open({
                    backdrop: 'static',
                    component: 'crSimpleModal',
                    windowClass: 'cr-modal-size-sm',
                    resolve: {
                        closeText: () => this.text.closeError,
                        message: () => message,
                    },
                })
                .result.catch(() => null);
        }
    }

    onSaveSuccess() {
        this.crNavigationService.disableConfirmNavigation();
        const params = {
            toast: {
                msg: `"${this.coupon.name}" ${this.edit ? this.text.hasBeenUpdated : this.text.hasBeenCreated}`,
            },
        };

        if (this.edit) {
            this.crAnalyticsService.track('Edit Coupon Success', this.coupon);
            this.crNavigationService.goBack('client.experience-promoter.coupons-list', params);
        } else {
            this.crAnalyticsService.track('Add New Coupon Success', this.coupon);
            this.crNavigationService.goToFromCreate('client.experience-promoter.coupons-list', params);
        }
    }

    onStateUpdate(event) {
        this.selectedClass = event.model[0].id;
        this.coupon.status = event.model[0].id;
    }

    onTermsAndConditionsUpdate(event) {
        this.coupon.termsAndConditions[this.locale.current] = this.getUndefinedForEmptyString(event.target.value);
        this.refreshLocaleStatus();
    }

    onTitleUpdate(event) {
        this.coupon.title[this.locale.current] = this.getUndefinedForEmptyString(event.model);
        this.refreshLocaleStatus();
    }

    getUndefinedForEmptyString(value) {
        if (typeof value !== 'string' || value.length === 0) {
            return;
        }
        return value;
    }

    onTypeUpdate(event) {
        this.coupon.type = event.model[0].id;
    }

    refreshLocaleStatus() {
        this.locale.list = this.locale.list.map((locale) => {
            let warning = false;

            if (!this.coupon.title[locale.id] || !this.coupon.termsAndConditions[locale.id]) {
                warning = true;
            }

            locale.hasStatusWarning = warning;

            return locale;
        });
    }

    save() {
        this.form.$setSubmitted();
        if (this.form.$valid) {
            this.saving = true;

            if (this.edit) {
                this.updateCoupon();
            } else {
                this.createCoupon();
            }
        }
    }

    updateCoupon() {
        this.crCouponsService
            .updateCoupon(this.coupon)
            .then(() => this.onSaveSuccess())
            .catch((err) => this.onSaveError('Could not update coupon', err))
            .finally(() => {
                this.saving = false;
            });
    }
}

export default CouponManage;
