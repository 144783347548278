import controller from './menu-modifier-group.controller';
import template from './menu-modifier-group.html';
import './menu-modifier-group.less';

const component = {
    template,
    bindings: {
        group: '<',
    },
    controller: [controller],
};

export default component;
