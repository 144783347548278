import text from './resources/locale/en.json';

class PdfUploaderController {
    constructor($sce, crFileUploadService, crConstants, $uibModal) {
        this.$sce = $sce;
        this.crFileUploadService = crFileUploadService;
        this.constants = crConstants.pdf;
        this.$uibModal = $uibModal;
        this.text = text;
    }

    trustUrl(url) {
        return this.$sce.trustAsResourceUrl(url);
    }

    $onInit() {
        this.pdfLink = this.pdfLink || null;
        this.pdfLinkFileName = this.crFileUploadService.getFileName(this.pdfLink);
        this.targetPdf = { url: this.pdfLink };
        this.maxFileSize = this.constants.maxFileSize;
        this.recommendedFileSizeBytes = this.constants.recommendedFileSizeBytes;
    }

    $onChanges(changes) {
        if (changes.pdfLink) {
            if (this.pdfLink) {
                this.pdfLinkFileName = this.crFileUploadService.getFileName(this.pdfLink);
            }
        }
    }

    deleteFile(event) {
        event.stopPropagation();

        const fileName = this.crFileUploadService.getFileName(this.pdfLink);

        this.$uibModal
            .open({
                backdrop: 'static',
                component: 'crSimpleModal',
                windowClass: 'cr-modal-size-sm',
                resolve: {
                    type: () => 'submit',
                    submitText: () => this.text.delete,
                    message: () => `${this.text.deleteConfirmationMessage} "${fileName}"?`,
                },
            })
            .result.then(() => {
                this.setDirty();
                this.pdfLink = null;
                this.targetPdf = { url: this.pdfLink };
                this.updateParent();
            })
            .catch(() => null);
    }

    manageFile() {
        this.$uibModal
            .open({
                backdrop: 'static',
                component: 'crPdfModal',
                windowClass: 'cr-modal-size-lg',
                resolve: {
                    targetPdf: () => this.targetPdf,
                    maxFileSize: () => this.maxFileSize,
                    recommendedFileSizeBytes: () => this.recommendedFileSizeBytes,
                    fileExtensions: () => this.constants.fileExtensions,
                    text: () => this.text,
                },
            })
            .result.then(() => {
                this.updateParent();
            })
            .catch(() => {
                this.setTouched();
            });
    }

    setDirty() {
        const formElem = this.form[this.name];

        if (!formElem.$dirty) {
            formElem.$setDirty();
        }
    }

    setTouched() {
        const formElem = this.form[this.name];

        if (!formElem.$touched) {
            formElem.$setTouched();
        }
    }

    updateParent() {
        this.setDirty();
        this.onChange({
            $event: {
                pdf: this.targetPdf,
            },
        });
    }
}

export default PdfUploaderController;
