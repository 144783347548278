import Controller from './beacons.controller';
import template from './beacons.html';
import './beacons.less';

const Component = {
    template,
    controller: ['$state', 'crConstants', Controller],
};

export default Component;
