import ResizeObs from 'resize-observer-polyfill';

class InlineChipsController {
    constructor($element, $scope, $timeout, $sce) {
        this.$element = $element;
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.$sce = $sce;
    }

    $onInit() {
        this.overflowCount = 0;
        this.ro = new ResizeObs(() => this.$scope.$apply(() => this.onResize()));
        this.ro.observe(this.getChipContainer());
    }

    $onDestroy() {
        if (this.ro) {
            this.ro.disconnect();
        }
    }

    $onChanges() {
        if (this.list) {
            this.listHtml = this.$sce.trustAsHtml(`<div>${this.list.join('</div><div>')}</div>`);
        }
    }

    getChipElement(text) {
        const span = document.createElement('span');

        span.classList.add('cr-chip');
        span.textContent = text;

        return span;
    }

    getTooltipHTML() {
        return this.listHtml;
    }

    getChipContainer() {
        return this.$element[0].querySelector('.chip-container');
    }

    getElementWidth(element) {
        const { position } = element.style;
        const { boxSizing } = element.style;
        const { top } = element.style;
        const { left } = element.style;

        element.style.position = 'absolute';
        element.style.boxSizing = 'content-box';
        element.style.top = '-10000px';
        element.style.left = '-10000px';

        const container = this.getChipContainer();
        container.appendChild(element);

        const es = window.getComputedStyle(element);
        const width = element.offsetWidth + parseFloat(es.marginLeft) + parseFloat(es.marginRight);

        container.removeChild(element);

        element.style.position = position;
        element.style.boxSizing = boxSizing;
        element.style.top = top;
        element.style.left = left;

        return width;
    }

    onResize() {
        let currWidth = 8;
        let currIndex = 0;

        const container = this.getChipContainer();
        container.innerHTML = '';

        while (currIndex < this.list.length) {
            const chip = this.getChipElement(this.list[currIndex]);

            chip.setAttribute('data-local-qa-id', `chip-${currIndex}`);

            const width = this.getElementWidth(chip);

            if (currWidth + width < container.offsetWidth) {
                container.appendChild(chip);
                currIndex += 1;
                currWidth += width;
            } else {
                let ellipsisName = `${this.list[currIndex].substr(0, this.list[currIndex].length - 1)}…`;
                chip.textContent = ellipsisName;

                while (currWidth + this.getElementWidth(chip) > container.offsetWidth && ellipsisName.length > 1) {
                    ellipsisName = `${ellipsisName.substr(0, ellipsisName.length - 2)}…`;
                    chip.textContent = ellipsisName;
                }

                if (ellipsisName.length > 1) {
                    container.appendChild(chip);
                }

                break;
            }

            this.$timeout(() => {
                this.overflowCount = this.list.length - container.childNodes.length;
            });
        }
    }
}

export default InlineChipsController;
