import Controller from './metric-box.controller';
import template from './metric-box.html';
import './metric-box.less';

const Component = {
    template,
    bindings: {
        subtitle: '<',
        text: '<',
        value: '<',
    },
    controller: [Controller],
};

export default Component;
