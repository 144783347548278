import _ from 'lodash';
import { AppNav } from '../../../../core';

import text from './resources/locale/en.json';

class ManageEvent {
    constructor(
        $q,
        $state,
        $uibModal,
        crConstants,
        crEntityService,
        crLocaleService,
        crNavigationService,
        crScheduleService,
        crErrorLoggingService,
        crAnalyticsService,
        crEventTabsService
    ) {
        this.$q = $q;
        this.$state = $state;
        this.$uibModal = $uibModal;
        this.crConstants = crConstants;
        this.crEntityService = crEntityService;
        this.crLocaleService = crLocaleService;
        this.crNavigationService = crNavigationService;
        this.crScheduleService = crScheduleService;
        this.crErrorLoggingService = crErrorLoggingService;
        this.crAnalyticsService = crAnalyticsService;
        this.crEventTabsService = crEventTabsService;
    }

    $onInit() {
        this.venueId = this.$state.params.venueId;
        this.text = text;

        this.entity = {
            attributes: [],
            state: '',
            extensions: [],
            displayCategories: [],
        };

        this.form = {};

        this.entityType = this.crConstants.entity.types.EVENTS;

        this.titleBarLabel = this.edit ? this.text.editEvent : this.text.createEvent;

        this.crNavigationService.enableConfirmNavigation(() => this.form.$dirty);

        this.promotedOptions = [
            {
                label: this.text.displayOnHomeScreen,
                name: 'promoted',
                checked: false,
            },
        ];

        this.eventNavigationTabsList = [];

        this.locale = this.crLocaleService.getLocale(this.venueId, this.$state.params.locale, !this.edit);
        this.localizedLists = this.crLocaleService.getLocaleObject(this.locale.list);

        this.arrivalEnums = this.crConstants.eventArrivalEnums;

        this.arrivalMenuItems = [
            { id: this.arrivalEnums.ANYTIME, label: this.text.arriveAnytime },
            { id: this.arrivalEnums.ON_TIME, label: this.text.arriveOnTime },
            { id: this.arrivalEnums.EARLY, label: this.text.arriveEarly },
        ];

        if (this.$state.params.id) {
            this.getEntityDetails();
        } else {
            this.initCreate();
        }

        // cache all lists by locale so the UI is snappy
        this.locale.list.forEach((lang) => {
            if (lang.id !== this.locale.default) {
                this.$q
                    .all([this.getAttributeListByLocale(lang.id), this.getPoiListByLocale(lang.id)])
                    .then(() => this.updatePhonePreview());
            }
        });

        this.phoneData = _.cloneDeep(this.entity);
    }

    initCreate() {
        // Init schedule group
        const initialSchedule = this.getNewScheduleRowData();
        initialSchedule.priority = 1;

        this.entity.scheduleGroup = {
            schedules: [initialSchedule],
        };

        this.entity.promoted = false;

        // Init localization property
        this.entity.localization = {};
        _.forEach(this.locale.list, (lang) => {
            this.entity.localization[lang.id] = {};
        });

        this.isLoadingLocations = true;
        this.isLoadingAttributes = true;

        const currentLocale = this.locale.current;
        this.initCategories(this.venueId, currentLocale);
        this.getAttributeListByLocale(currentLocale);
        this.getEventNavigationTabs();
        this.getPoiListByLocale(currentLocale);
    }

    getEntityDetails() {
        this.isLoading = true;
        this.isLoadingLocations = true;
        this.isLoadingAttributes = true;

        const params = [this.crConstants.entity.types.EVENTS, this.$state.params.id, this.venueId, this.locale.default];

        this.$q
            .all([
                this.crEntityService.getEntity(...params),
                this.getAttributeListByLocale(this.locale.default),
                this.getPoiListByLocale(this.locale.default),
            ])
            .then((results) => {
                this.entity = results[0];

                this.isCopy = !this.edit;

                if (this.isCopy) {
                    this.entity.name = `${this.text.copyOf} ${this.entity.name}`;

                    // remove copied entity's id
                    this.$state.params.id = null;
                    this.$state.go(this.$state.current.name, this.$state.params, {
                        location: 'replace',
                    });
                }

                this.entity.displayCategories = this.entity.displayCategories || [];
                this.entity.attributes = this.entity.attributes || [];

                if (!_.isEmpty(this.entity.associatedPois)) {
                    const optionsMap = _.keyBy(this.locationOptions, 'id');
                    this.currentLocations = this.entity.associatedPois.map((poi) => ({
                        id: poi.id,
                        chipLabel: optionsMap[poi.id].label,
                    }));
                }

                this.initCategories(this.$state.params.venueId, this.locale.current);

                this.getEventNavigationTabs();

                // Sort schedules in priority order
                this.entity.scheduleGroup.schedules = this.sortSchedules(this.entity.scheduleGroup.schedules);

                this.promotedOptions[0].checked = this.entity.promoted;

                // Init arrival selection
                this.setInitialSelectedArrival(this.entity.suggestedArrival);

                this.entity.extensions = (this.entity.extensions || []).map((extension) => ({
                    id: extension.id,
                    value: JSON.stringify(extension.value, false, '  '),
                }));

                // hack localization reset model
                _.forEach(this.entity.localization, (locale) => {
                    if (_.isObject(locale.callToAction) && _.isEmpty(locale.callToAction)) {
                        locale.callToAction = null;
                    }
                });
            })
            .catch((err) => {
                this.hasDataError = true;
                this.crErrorLoggingService.logError(`Could not get ${this.entityType}`, err);
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    getEventNavigationTabs() {
        this.isLoadingTabs = true;
        this.crEventTabsService.getEventTabs().then((tabs) => {
            if(tabs && tabs.length > 0) {
                this.eventNavigationTabsList = tabs.map((tab) => ({
                    id: tab.id,
                    name: tab.name,
                    displayOrder: tab.displayOrder,
                    label: tab.name,
                }));
                const { availableEventTabs, displayEventTabs } = this.getNavigationCategoryChipLabels(this.eventNavigationTabsList, this.entity.navigationTabs);
                this.entity.navigationTabs = displayEventTabs;
                this.entity.availableNavigationTabs = availableEventTabs;
            }
        }).catch((err) => {
            this.crErrorLoggingService.logError('Could not get event navigation tabs', err);
        }).finally(() => {
            this.isLoadingTabs = false;
        });
    }

    initCategories(venueId, locale) {
        this.isLoadingCategories = true;
        this.crEntityService
            .getCategoriesWithHierarchy(this.crConstants.entity.types.EVENT_CATEGORIES, venueId, locale)
            .then((categories) => {
                this.categoriesList = categories;

                const { availableCategories, displayCategories } = this.attributeCategoryChipLabels(
                    this.categoriesList,
                    this.entity.displayCategories
                );

                this.entity.displayCategories = displayCategories;
                this.entity.availableCategories = availableCategories;
            })
            .catch((err) => {
                this.crErrorLoggingService.logError('Could not get categories hierarchy', err, locale);
            })
            .finally(() => {
                this.isLoadingCategories = false;
            });
    }

    attributeCategoryChipLabels(categories, displayCategories) {
        const availableCategories = categories.map((category) => ({
            label: category.hierarchyLabel,
            id: category.id,
            parentId: category.parentId,
        }));

        const availableCategoriesMap = _.keyBy(availableCategories, 'id');

        displayCategories = (displayCategories || []).map((category) => availableCategoriesMap[category.id]);

        return { availableCategories, displayCategories };
    }

    getNavigationCategoryChipLabels(eventTabs, displayEventTabs) {
        const availableEventTabs = eventTabs.map((eventTab) => ({
            label: eventTab.name,
            id: eventTab.id,
            displayOrder: eventTab.displayOrder,
            name: eventTab.name,
        }));

        const availableEventTabsMap = _.keyBy(availableEventTabs, 'id');

        displayEventTabs = (displayEventTabs || []).map((eventTab) => availableEventTabsMap[eventTab.id]);

        return { availableEventTabs, displayEventTabs };
    }

    save() {
        this.form.$setSubmitted();

        if (this.form.$valid) {
            if (this.form.$dirty || this.isCopy) {
                this.isSaving = true;
                const payload = this.crEntityService.prepareEntityPayload(this.entity);

                // Adjust schedule data before sending
                const schedules = this.crScheduleService.adjustScheduleData(payload.scheduleGroup.schedules);
                payload.scheduleGroup.schedules = schedules;

                // TODO: make the GSR do this
                if (payload.callToAction === null) {
                    _.forEach(payload.localization, (locale) => {
                        locale.callToAction = { label: '', link: '', appNav: '' };
                    });
                }

                if (this.edit) {
                    this.updateEntity(this.entityType, payload.id, payload, this.venueId);
                } else {
                    this.createEntity(this.entityType, payload, this.venueId);
                }
            } else {
                this.goBack();
            }
        }
    }

    updateEntity(type, id, entity, venueId) {
        return this.crEntityService
            .updateEntity(type, id, entity, venueId)
            .then((res) => {
                this.onSaveSuccess(res);
            })
            .catch((err) => {
                this.handleError('Could not update entity', err);
            })
            .finally(() => {
                this.isSaving = false;
            });
    }

    createEntity(type, entity, venueId) {
        entity.owner = {
            id: venueId,
            type: 'VENUE',
        };

        return this.crEntityService
            .createEntity(type, entity, venueId)
            .then((res) => {
                this.onSaveSuccess(res);
            })
            .catch((err) => {
                this.handleError('Could not create entity', err);
            })
            .finally(() => {
                this.isSaving = false;
            });
    }

    getPoiListByLocale(currentLocale, displayKey = 'title') {
        return this.crEntityService
            .getEntityList(
                this.crConstants.entity.types.POIS,
                { venueId: this.venueId, sortby: displayKey, sort: 'asc' },
                currentLocale
            )
            .then((res) => {
                const locationsList = res.content.map((item) => ({ id: item.id, label: item[displayKey] }));

                this.localizedLists[currentLocale].locationsList = locationsList;

                if (_.isEmpty(this.locationOptions)) {
                    this.locationOptions = res.content.map((item) => ({
                        id: item.id,
                        label:
                            item.state !== this.crConstants.entity.states.ACTIVE
                                ? `${item.name} (${item.state.toLowerCase()})`
                                : item.name,
                    }));
                }
            })
            .catch((err) => {
                this.crErrorLoggingService.logError('could not retrieve locations list', err);
            })
            .finally(() => {
                this.isLoadingLocations = false;
            });
    }

    getAttributeListByLocale(currentLocale) {
        return this.crEntityService
            .getEntityList(
                this.crConstants.entity.types.EVENT_ATTRIBUTES,
                { venueId: this.venueId, sortby: 'title', sort: 'asc' },
                currentLocale
            )
            .then((res) => {
                this.localizedLists[currentLocale].availableAttributes = res.content;
            })
            .catch((err) => {
                this.crErrorLoggingService.logError('could not retrieve attributes list', err);
            })
            .finally(() => {
                this.isLoadingAttributes = false;
            });
    }

    getPhoneModel(entity, locale) {
        const phoneData = _.cloneDeep(entity);
        const isDefault = locale.current === locale.default;

        if (!isDefault) {
            phoneData.title = this.getLocaleTitle(phoneData, locale.current);
            phoneData.description = this.getLocaleDescription(phoneData, locale.current);
            phoneData.attributes = this.getLocaleAttrs(phoneData.attributes, locale.current);
            phoneData.associatedPois = this.getLocalePois(phoneData.associatedPois, locale.current);
            phoneData.callToAction = this.getLocaleCta(phoneData, locale.current);
        }

        return phoneData;
    }

    updatePhonePreview() {
        this.phoneData = _.cloneDeep(this.getPhoneModel(this.entity, this.locale));
    }

    getLocaleTitle(entity, currentLocale) {
        const localeTitle = entity.localization[currentLocale].title;
        return localeTitle || entity.title;
    }

    getLocaleDescription(entity, currentLocale) {
        const localeDesc = entity.localization[currentLocale].description;
        return localeDesc || entity.description;
    }

    getLocaleCta(entity, currentLocale) {
        const localeCta = entity.localization[currentLocale].callToAction;
        return localeCta && localeCta.label && localeCta.link ? localeCta : entity.callToAction;
    }

    getLocalePois(associatedPois = [], currentLocale) {
        const pois = [];
        const poiListMap = _.groupBy(this.localizedLists[currentLocale].locationsList, 'id');

        associatedPois.forEach((entity) => {
            const localizedPoi = _.first(poiListMap[entity.id]);
            if (localizedPoi) {
                pois.push(localizedPoi);
            }
        });

        return pois;
    }

    getLocaleAttrs(attributes = [], currentLocale) {
        const attrs = [];
        const attributeMap = _.groupBy(this.localizedLists[currentLocale].availableAttributes, 'id');

        attributes.forEach((entity) => {
            const localizedAttr = _.first(attributeMap[entity.id]);
            if (localizedAttr) {
                localizedAttr.value = entity.value
                    ? entity.value.map((val) => _.find(localizedAttr.enumValues, { id: val.id }))
                    : [];

                attrs.push(localizedAttr);
            }
        });

        return attrs;
    }

    onLocaleChange(event) {
        this.locale.current = event.locale.id;
        this.locale = _.cloneDeep(this.locale);

        this.updatePhonePreview();
    }

    onAttributesChange(event) {
        this.entity.attributes = event.rows;
        this.updatePhonePreview();
    }

    onExtensionsChange(event) {
        this.entity.extensions = event.rows;
    }

    onHeaderUpdate(event) {
        this.entity.images = event.model.images;
        this.entity.name = event.model.name;
        this.entity.state = event.model.state;

        this.entity = _.cloneDeep(this.entity);
        this.updatePhonePreview();
    }

    onTitleChange(event) {
        if (this.locale.current === this.locale.default) {
            // Update main model to stay in sync
            this.entity.title = event.model;
        }
        this.entity.localization[this.locale.current].title = event.model;
        this.entity = _.cloneDeep(this.entity);
        this.updatePhonePreview();
    }

    onDescriptionChange(event) {
        if (this.locale.current === this.locale.default) {
            // Update main model to stay in sync
            this.entity.description = event.model;
        }
        this.entity.localization[this.locale.current].description = event.model;
        this.entity = _.cloneDeep(this.entity);
        this.updatePhonePreview();
    }

    onImagesChange(event) {
        this.entity.images = event.model;
        this.entity = _.cloneDeep(this.entity);
        this.updatePhonePreview();
    }

    onCtaChange(event) {
        if (this.locale.current === this.locale.default) {
            // Update main model to stay in sync
            this.entity.callToAction = event.model;

            if (!this.entity.localization[this.locale.default].callToAction) {
                // init each locale with default cta model
                _.forEach(this.entity.localization, (locale) => {
                    locale.callToAction = { label: '', link: '', appNav: '' };
                });
            }
        }

        if (event.model) {
            const cta = event.model;
            cta.appNav = new AppNav.Webview(this.$state.params.venueId, cta.link).toString();
            this.entity.localization[this.locale.current].callToAction = cta;
        } else {
            this.resetCtaAndLocale();
        }

        this.entity = _.cloneDeep(this.entity);
        this.updatePhonePreview();
    }

    resetCtaAndLocale() {
        this.locale = this.crLocaleService.getLocale(this.$state.params.venueId, this.locale.default, true);
        this.entity.callToAction = null;
        _.forEach(this.entity.localization, (locale) => {
            locale.callToAction = null;
        });
    }

    onPromotedChange(event) {
        this.entity.promoted = event.model.promoted;
        this.dirtyForm();
    }

    getNewScheduleRowData() {
        return {
            instances: [
                {
                    startTime: null,
                    endTime: null,
                },
            ],
        };
    }

    onUpdateScheduleRowData(event) {
        this.entity.scheduleGroup.schedules = _.cloneDeep(event.model);
    }

    updateScheduleRowDataPriority(event) {
        _.forEach(event.model, (value, index) => {
            value.priority = event.model.length - index;
        });

        this.entity.scheduleGroup.schedules = _.cloneDeep(event.model);
    }

    updateArrival(event) {
        this.entity.suggestedArrival = event.model[0].id;
    }

    setInitialSelectedArrival(enumValue) {
        const selectedMenuItem = _.find(this.arrivalMenuItems, { id: enumValue });
        if (selectedMenuItem) {
            selectedMenuItem.isSelected = true;
        }
    }

    locationSelected(selectedTags) {
        const tags = _.cloneDeep(selectedTags) || [];
        this.currentLocations = tags;
        this.entity.associatedPois = tags.map((tag) =>
            _.find(this.localizedLists[this.locale.current].locationsList, { id: tag.id })
        );

        this.entity = _.cloneDeep(this.entity);
        this.updatePhonePreview();
    }

    categorySelected(tags) {
        this.entity.displayCategories = tags;
    }

    eventNavigationTabsSelected(tags) {
        this.entity.navigationTabs = tags;
    }

    sortSchedules(schedules) {
        return _.orderBy(schedules, 'priority', 'desc');
    }

    onSaveSuccess(data) {
        this.crNavigationService.disableConfirmNavigation();
        const params = this.getToastParams(data);

        if (this.edit) {
            this.crAnalyticsService.track('Edit Event Success', data);
            this.crNavigationService.goBack(`client.${this.entityType}.details`, params);
        } else {
            this.crAnalyticsService.track('Add New Event Success', data);
            this.crNavigationService.goToFromCreate(`client.${this.entityType}.list`, params);
        }
    }

    getToastParams(data) {
        const toastMsg = this.edit ? this.text.hasBeenUpdated : this.text.hasBeenCreated;

        return {
            id: data.id,
            toast: {
                msg: `"${data.name}" ${toastMsg}`,
            },
        };
    }

    handleError(errMsg, err) {
        this.showErrorModal();
        this.crErrorLoggingService.logError(errMsg, err);
    }

    showErrorModal() {
        const message = this.edit ? this.text.updateError : this.text.createError;

        this.$uibModal
            .open({
                backdrop: 'static',
                component: 'crSimpleModal',
                windowClass: 'cr-modal-size-sm',
                resolve: {
                    message: () => message,
                },
            })
            .result.catch(() => null);
    }

    dirtyForm() {
        this.form.$setDirty();
    }

    goBack() {
        if (this.edit) {
            this.crAnalyticsService.track('Edit Event Canceled');
            this.crNavigationService.goBack(`client.${this.entityType}.details`, { id: this.entity.id });
        } else {
            this.crAnalyticsService.track('Add New Event Canceled');
            this.crNavigationService.goBack(`client.${this.entityType}.list`);
        }
    }
}

export default ManageEvent;
