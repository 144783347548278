import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CrPolicyCheckModule } from './basic/policy-check/policy-check.module';
import { ChipModule } from './components/chip/chip.module';
import { CrContentModule } from './content/content.module';
import { CrDraggableDirective } from './directives/draggable.directive';
import { CrPolicyDirective } from './directives/policy.directive';
import { CrExportButtonModule } from './export-button/export-button.module';
import { CrFormsModule } from './forms/forms.module';
import { CrIconModule } from './icon/icon.module';
import { CrImageGridModule } from './layout/image-grid/image-grid.module';
import { CrSplitViewModule } from './layout/split-view-page/split-view-page.module';
import { CrNotificationModule } from './notification/notification.module';
import { CrPhoneModule } from './phone/phone.module';
import { CurrencySymbolPipe } from './pipes/currency-symbol.pipe';
import { ShortNumberPipe } from './pipes/short-number.pipe';
import { configProviders, CrConfigModule } from './services/config';
import { CrSpinnerModule } from './spinner/spinner.module';
import { CrSplitBtnModule } from './split-button/split-button.module';
import { CrTitleBarModule } from './title-bar/title-bar.module';
import { CrToastModule } from './toast/toast.module';
import { ImageCropperModule } from 'ngx-image-cropper';


@NgModule({
  declarations: [CrDraggableDirective, CrPolicyDirective, ShortNumberPipe, CurrencySymbolPipe],
  imports: [
    HttpClientModule,
    CrNotificationModule,
    CrFormsModule,
    CrContentModule,
    CrConfigModule,
    CrExportButtonModule,
    CrFormsModule,
    CrIconModule,
    CrNotificationModule,
    CrPhoneModule,
    CrSpinnerModule,
    CrTitleBarModule,
    CrToastModule,
    CrSplitBtnModule,
    ChipModule,
    ImageCropperModule
  ],
  exports: [
    CrDraggableDirective,
    CrPolicyDirective,
    CrExportButtonModule,
    CrFormsModule,
    CrPolicyCheckModule,
    CrContentModule,
    CrIconModule,
    CrNotificationModule,
    CrPhoneModule,
    CrSpinnerModule,
    CrTitleBarModule,
    CrToastModule,
    ShortNumberPipe,
    CurrencySymbolPipe,
    CrSplitViewModule,
    CrImageGridModule,
    CrSplitBtnModule,
    
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: SharedModule,
      providers: [...configProviders],
    };
  }
}
