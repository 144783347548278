import template from './experience-flyout.html';
import './experience-flyout.less';
import Controller from './experience-flyout.controller';

const Component = {
    template,
    bindings: {
        data: '<',
    },
    controller: [
        '$scope',
        '$state',
        '$uibModal',
        'crConstants',
        'crVenueService',
        'crTimeService',
        'crScheduleService',
        'crScrollService',
        'crAnalyticsService',
        'crExperiencesService',
        Controller,
    ],
};

export default Component;
