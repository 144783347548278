import angular from 'angular';

import PreferencesDetailsComponent from './preferences-details.component';
import ComponentsModules from './components/components';

const PreferencesDetails = angular
    .module('control-room.app-settings.components.preferences-details', [ComponentsModules.name])
    .component('crPreferencesDetails', PreferencesDetailsComponent);

export default PreferencesDetails;
