import { Component, Inject, OnInit } from "@angular/core";
import text from './resources/locale/en.json';
import { CR_CONFIG, CR_CONSTANTS, ErrorLoggingService, VenueService } from "../../shared";
import { Config, Constants, LocalizedText } from "../../core";
import { EntityService } from "../../shared/services/entity/entity.service";
import { ToastService } from "../../ng1-factories";
import { StateService } from "@uirouter/core";
import { HoursList, PhonePreviewList, VenueHoursList } from "./constants/hours.types";

@Component({
    selector: 'cr-venue-hours',
    templateUrl: './venue-hours.component.html',
    styleUrls: ['./venue-hours.component.scss']
})
export class VenueHoursComponent implements OnInit {

    text: LocalizedText;
    isLoading = false;
    isSaving = false;
    isCreateEnabled = true;
    accessError: any;
    dataError = false;
    params: any;
    hoursList: HoursList;
    phonePreviewHours: PhonePreviewList = []
    venueHoursList: VenueHoursList = []
    timezone: string;

    constructor(
        @Inject(CR_CONSTANTS) public constants: Constants,
        private crEntityService: EntityService,
        private crToastService: ToastService,
        venueService: VenueService,
        public state: StateService,
        private crErrorLoggingService: ErrorLoggingService,
    ) {
        this.text = text as LocalizedText
        this.params = {}
        this.timezone = venueService.getTimezone(state.params.venueId).name
    }

    ngOnInit(): void {
        this.isLoading = true;
        this.dataError = false;

        this.crEntityService
            .getVenueHoursByDay(
                this.constants.entity.types.VENUE_HOURS,
                {
                    venueId: this.state.params.venueId,
                    days: '7'
                },
                '',
                this.state.params.customerId
            ).then((res) => {
                this.hoursList = res;
                this.generatePhonePreviewList();
                this.generateVenueHoursList();
                if(this.phonePreviewHours.length >= 5) {
                    this.isCreateEnabled = false;
                }
            }).catch((err) => {
                this.dataError = true;
                this.crErrorLoggingService.logError('Could not retrieve venue hours', err)
            }).finally(() => {
                this.isLoading = false;
            });
    }

    isAdd(): void {
        this.state.go('client.venue-hours.create', this.getStateParams())
    }

    isEdit(): void {
        this.state.go('client.venue-hours.edit-order', this.getStateParams())
    }

    getStateParams() {
        return {
            customerId: this.state.params.customerId,
            venueId: this.state.params.venueId
        }
    }

    generatePhonePreviewList() {
        const todaysHours = this.hoursList.days[1].hours;
        todaysHours.forEach((hour, idx) => {
            const startDate = new Date(hour.schedule.start)
            const endDate = new Date(hour.schedule.end)

            this.phonePreviewHours.push({
                label: hour.label,
                startDate: startDate,
                endDate: endDate,
                status: hour.statusLabel
            })

            this.venueHoursList.push({
                label: hour.label,
                venueHours: []
            })
        });
    }

    generateVenueHoursList() {
        const hoursList = this.hoursList.days
        hoursList.shift();
        hoursList.forEach( ( dateHours ) => {
            const date = new Date(dateHours.date);
            dateHours.hours.forEach((hour, index) => {
                this.venueHoursList[index].venueHours.push({
                    statusLabel: hour.statusLabel,
                    day: `${date.toLocaleDateString('en-US', { weekday: "long" })},`,
                    date: date,
                    startDate: new Date(hour.schedule.start),
                    endDate: new Date(hour.schedule.end)
                })
            })
        })
    }
}
