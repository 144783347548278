import {
  AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, QueryList, ViewChildren,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { ChipComponent } from '../../components/chip/chip.component';

@Component({
  selector: 'cr-chip-column',
  templateUrl: './chip-column.component.html',
  styleUrls: ['./chip-column.component.scss'],
})
export class ChipColumnComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() values: [];

  @Input() columnWidth: number;

  @Input() index: number;

  @ViewChildren('chippy', { read: ElementRef }) chippysRef: QueryList<ElementRef>;

  chipOverflow: number;

  shownChips: any[];

  chipElements: Element;

  tooltipText: string[] = [];

  chipSub$: Subscription;

  public ngOnInit(): void {
    this.setTooltip();
  }

  public ngOnDestroy(): void {
    this.chipSub$.unsubscribe();
  }

  public ngAfterViewInit(): void {
    this.determineShownChips();
  }

  private determineShownChips(): void {
    let chipsToShow = 0;
    let totalChipWidth = 0;
    this.chipSub$ = this.chippysRef.changes.subscribe((chips) => {
      chips.forEach((chip: ElementRef<any>) => {
        // take the child element to determine the width of the chip
        totalChipWidth += chip.nativeElement.children[0].offsetWidth;
        if (totalChipWidth < (this.columnWidth - 50)) {
          chipsToShow++;
        }
        this.shownChips = Array(chipsToShow).fill(0).map((x, i) => this.values[i]);
        this.chipOverflow = this.values.length - chipsToShow;
      });
    });
    if (this.values) {
      this.shownChips = Array(this.values.length).fill(0).map((x, i) => this.values[i]);
    }
  }

  private setTooltip(): void {
    this.values.forEach((value) => {
      this.tooltipText.push(` ${(value as any).displayName}`);
    });
  }
}
