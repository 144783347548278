import angular from 'angular';
import QuestionListComponent from './question-list.component';
import QuestionListService from './question-list.service';

const QuestionList = angular
    .module('control-room.app-settings.components.question-list', [])
    .component('crQuestionList', QuestionListComponent)
    .service('crQuestionListService', ['crEntityService', 'crConstants', QuestionListService]);

export default QuestionList;
