import _ from 'lodash';
import text from './resources/locale/en.json';

class RowManagerController {
    constructor(crAnalyticsService, crConstants) {
        this.crAnalyticsService = crAnalyticsService;
        this.crConstants = crConstants;
    }

    $onInit() {
        this.text = text;
        this.addRowLabel = this.addRowLabel || this.text.addRow;
        this.rowManagerForm = {};
    }

    $onChanges(changes) {
        if (changes) {
            if (changes.rowsData && changes.rowsData.currentValue) {
                this.rowsData = _.cloneDeep(this.rowsData);
            }

            if (changes.isSubmitted && changes.isSubmitted.currentValue) {
                this.rowManagerForm.$setSubmitted();
            }
        }
    }

    update(model) {
        this.rowManagerForm.$touched = true;

        this.onUpdate({
            $event: {
                model,
            },
        });

        this.rowManagerForm.$setDirty();
    }

    moveRowToTop(from, to) {
        this.swapRows(from, to);
    }

    moveRow(from, to) {
        if (from !== to) {
            // This accounts for dragging up, where technically the new index is 1 less than desired.
            if (to < from) {
                to += 1;
            }

            this.swapRows(from, to);
        }
    }

    swapRows(from, to) {
        this.setDirty();
        this.rowsData.splice(to, 0, this.rowsData.splice(from, 1)[0]);
        this.update(this.rowsData);
        this.reorder();
        this.crAnalyticsService.track('row-manager | order updated', { from, to });
    }

    addRow() {
        if (this.rowManagerForm.$valid && !this.disableAdd) {
            const newRowData = this.newRowDataProvider();
            this.rowsData.push(newRowData);
            this.rowManagerForm.$setPristine();
            this.update(this.rowsData);
            this.reorder();
            this.crAnalyticsService.track('row-manager | row added');
        }
    }

    removeRow(index) {
        this.setDirty();
        this.rowsData.splice(index, 1);
        this.update(this.rowsData);
        this.reorder();
        this.crAnalyticsService.track('row-manager | row removed');
    }

    reorder() {
        if (typeof this.onReorder === 'function') {
            this.onReorder({
                $event: {
                    model: this.rowsData,
                },
            });
        }
    }

    canRemove() {
        return this.rowsData.length > 1;
    }

    setDirty() {
        if (!this.rowManagerForm.$dirty) {
            this.rowManagerForm.$setDirty();
        }
    }

    getRowId() {
        return _.uniqueId('row');
    }
}

export default RowManagerController;
