import text from './resources/locale/en.json';

class EntityManageDeleteLink {
    constructor($state, $uibModal, crEntityService, crNavigationService, crErrorLoggingService, crAnalyticsService) {
        this.$state = $state;
        this.$uibModal = $uibModal;
        this.crEntityService = crEntityService;
        this.crNavigationService = crNavigationService;
        this.crErrorLoggingService = crErrorLoggingService;
        this.crAnalyticsService = crAnalyticsService;
    }

    $onInit() {
        this.text = text;
    }

    deleteEntity(type, entity) {
        this.$uibModal
            .open({
                backdrop: 'static',
                component: 'crSimpleModal',
                windowClass: 'cr-modal-size-sm',
                resolve: {
                    type: () => 'submit',
                    submitText: () => this.text.delete,
                    message: () => `${this.text.deleteConfirmationMessage} "${entity.name || entity.label}"?`,
                },
            })
            .result.then(() =>
                this.crEntityService
                    .deleteEntity(type, entity.id)
                    .then(() => {
                        this.handleSuccess(`${entity.name || entity.label} ${this.text.hasBeenDeleted}`);
                    })
                    .catch((err) => {
                        this.showErrorModal();
                        this.crErrorLoggingService.logError('Could not delete entity', err, entity);
                    })
            )
            .catch(() => null);
    }

    getDeleteText() {
        if (this.deleteText) {
            return this.deleteText;
        }

        return this.text.delete;
    }

    showErrorModal() {
        this.$uibModal
            .open({
                backdrop: 'static',
                component: 'crSimpleModal',
                windowClass: 'cr-modal-size-sm',
                resolve: {
                    message: () => this.text.errorMessage,
                },
            })
            .result.catch(() => null);
    }

    handleSuccess(msg) {
        this.crNavigationService.disableConfirmNavigation();
        this.crNavigationService.goBack(this.successRoute, {
            toast: { msg },
        });
    }
}

export default EntityManageDeleteLink;
