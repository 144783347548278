<section>
  <header>
    <div class="heading-text">
      <span class="cancel-button"
        ><span class="icon icon-cr-close"></span
      ></span>
      <span *ngIf="featuredEvents?.discountTitle">{{
        featuredEvents?.discountTitle
      }}</span>
      <span class="placeholder-text" *ngIf="!featuredEvents?.discountTitle">{{
        text.title
      }}</span>
    </div>
  </header>

  <section class="featured-event-phone-container">
    <div
      [innerHtml]="featuredEvents?.discountDescription"
      *ngIf="featuredEvents?.discountDescription"
      class="featured-data"
    ></div>
    <div
      *ngIf="!featuredEvents?.discountDescription"
      class="featured-data placeholder-text"
    >
      {{ text.description }}
    </div>
  </section>
</section>
