<cr-split-view-page pageClass="cr-edit details-page">
  <cr-title-bar label="{{ pageTitle }}">
    <div titleBarCenter>
      <cr-form-submission-error
        *ngIf="isSubmitted && form.invalid"
        [message]="text.formSubmissionErrorText"
      ></cr-form-submission-error>
    </div>
    <div titleBarRight>
      <button
        (click)="goBack()"
        class="btn btn-nobg"
        data-global-qa-id="manage-cancel"
      >
        {{ text.cancel }}
      </button>
      <button
        (click)="onSave()"
        class="btn btn-primary"
        data-global-qa-id="manage-save"
        [disabled]="!form.valid"
      >
        {{ text.saveButton }}
      </button>
    </div>
  </cr-title-bar>
  <div *ngIf="isLoading || isSaving" top>
    <div class="text-center cr-page-padding-top">
      <cr-spinner></cr-spinner>
    </div>
  </div>
  <section left class="cr-left">
    <form [formGroup]="form">
      <cr-input
        class="input-long discounts-label-input"
        type="text"
        placeholder="{{ text.defaultDiscountsLabel }}"
        [formControl]="discountsLabelControl"
      ></cr-input>
      <hr class="details-divider" />
      <cr-section-heading>
        <div>{{ text.aboutDiscountMessaging }}</div>
      </cr-section-heading>
      <cr-input
        class="input-long"
        [label]="text.title"
        type="text"
        [formControl]="discountContentHeaderControl"
      ></cr-input>

      <div class="quill-container">
        <div class="input-header">{{ text.description }}</div>
        <quill-editor
          [style]="quillStyles"
          [modules]="quillConfig"
          formControlName="discountContentDescriptionControl"
        ></quill-editor>
      </div>
      <cr-required-field></cr-required-field>
    </form>
  </section>
  <section class="cr-right phone-view" right>
    <cr-phone>
      <cr-discount-content-phone-preview [featuredEvents]="phonePreview">
      </cr-discount-content-phone-preview>
    </cr-phone>
  </section>
</cr-split-view-page>
