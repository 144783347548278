import { Component, Output, EventEmitter } from '@angular/core';

import { LocalizedText } from '../../../../core';

import text from './resources/locale/en.json';
import { LiveMapService } from '../live-map.service';

@Component({
  selector: 'cr-live-map-metrics',
  templateUrl: './live-map-metrics.component.html',
  styleUrls: ['./live-map-metrics.component.scss'],
})
export class LiveMapMetricsComponent {
  @Output() close = new EventEmitter();

  currentUsers: number;

  experiencesSent: number;

  lastUpdated: any;

  text: LocalizedText;

  totalUsers: number;

  constructor(private liveMapService: LiveMapService) {
    this.text = text;

    this.liveMapService.metrics.subscribe((metrics) => {
      this.currentUsers = metrics.currentUsers;
      this.totalUsers = metrics.totalUsers;
      this.experiencesSent = metrics.experiencesSent;
    });

    this.liveMapService.lastUpdated.subscribe((lastUpdated) => {
      this.lastUpdated = lastUpdated;
    });
  }
}
