import angular from 'angular';
import AutofocusModule from './autofocus/autofocus';
import MobileScrollModule from './mobile-scroll/mobile-scroll';
import GradientGeneratorModule from './gradient-generator/gradient-generator';
import FlyoutModule from './flyout/flyout.directive';
import FeatureFlagsModule from './feature-flags/feature-flags';
import PolicyModule from './policy/policy';

const DirectivesModule = angular.module('control-room.commons.basic.directives', [
    AutofocusModule.name,
    MobileScrollModule.name,
    GradientGeneratorModule.name,
    FlyoutModule.name,
    FeatureFlagsModule.name,
    PolicyModule.name,
]);

export default DirectivesModule;
