import template from './check.html';
import './check.less';
import CheckController from './check.controller';

const CheckComponent = {
    template,
    bindings: {
        disabled: '<',
        options: '<',
        onUpdate: '&',
        customClass: '@?',
    },
    controller: CheckController,
    controllerAs: 'checkCtrl',
};

export default CheckComponent;
