<section>
    <div
        class="page-overlay"
        [ngClass]="{ editing: isEditing || isUpdating }"
        (click)="editOrder()"
    ></div>
    <cr-title-bar icon="icon-cr-back" [label]="text.title" (onClick)="onBack()">
    </cr-title-bar>

    <div
        *ngIf="!isLoading && !hasError"
        class="details-header adjustable-padding"
    >
        <div class="order-controls">
            <div
                class="fulfillment-button"
                crPolicy
                policyAction="write:fnb-order"
                [ngClass]="getOrderClass(order)"
                (click)="editOrder()"
                *ngIf="order.orderStatus==='SUBMITTED'"
            >
                {{
                    text.status[order.orderStatus]
                }}
            </div>
            <div
                class="fulfillment-button"
                crPolicy
                policyAction="write:fnb-order"
                [ngClass]="getOrderClass(order)"
                (click)="editOrder()"
                *ngIf="order.orderStatus!=='SUBMITTED'"
            >
                {{
                    text.status[order.paymentStatus] ||
                        text.status[order.fulfillmentStatus]
                }}<span class="icon icon-cr-edit"></span>
            </div>
            <div
                class="refund-link"
                crPolicy
                policyAction="read:fnb-refund"
                *ngIf="isRefundsEnabled && !order.isRefund"
                (click)="openRefundScreen(order.orderId)"
            >
                {{ text.issueRefund }}
            </div>
        </div>
        <div class="order-age">{{ displayOrderAge }}</div>
    </div>

    <div *ngIf="!isLoading && !hasError && order" class="content">
        <div
            *ngIf="order.error"
            class="order-notice order-error adjustable-padding"
        >
            <div class="notice-text">
                <div class="icon icon-cr-error"></div>
                <div>{{ text.orderApiErrorMessage }}</div>
            </div>
            <div class="notice-buttons">
                <span (click)="dismissNotice(order)">{{ text.dismiss }}</span>
            </div>
        </div>
        <div class="order-container shadow" [ngClass]="getOrderClass(order)">
            <div class="order-data adjustable-margin">
                <div class="order-data-row">
                    <div class="field field-medium">
                        <div class="field-label">{{ text.orderNumber }}</div>
                        <div class="value">
                            Order {{ order.externalOrderId }}
                        </div>
                    </div>
                    <div class="field field-large">
                        <div class="field-label">{{ text.orderDate }}</div>
                        <div class="value">{{ order.displayDate }}</div>
                    </div>
                    <div
                        *ngIf="order.estimatedWaitTime !== null"
                        class="field field-medium"
                    >
                        <div class="field-label">{{ text.promiseTime }}</div>
                        <div class="value">{{ order.displayPrepTime }}</div>
                    </div>
                </div>
                <div class="order-data-row">
                    <div class="field field-medium">
                        <div class="field-label">{{ text.guestName }}</div>
                        <div class="value">{{ order.customerName }}</div>
                    </div>
                    <div class="field field-large">
                        <div class="field-label">{{ text.guestEmail }}</div>
                        <div class="value">{{ order.customerEmail }}</div>
                    </div>
                    <div class="field field-medium" *ngIf="!order.isRefund">
                        <div class="field-label">{{ text.orderTotal }}</div>
                        <div class="value">
                            {{ order.displayOrderCheckTotal }}
                        </div>
                    </div>
                    <div class="field field-medium" *ngIf="order.isRefund">
                        <div class="field-label">{{ text.orderTotal }}</div>
                        <div class="value">
                            {{ order.displayTotalMinusRefund }}
                        </div>
                        <span class="value-refund"
                            >{{ order.displayOrderRefundAmount }}
                            {{ text.refunded }}</span
                        >
                    </div>
                </div>
            </div>
            <cr-fnb-order-receipt
                [order]="order"
                [kitchenId]="state.params.kitchenId"
                [isPrintingEnabled]="isPrintingEnabled"
            ></cr-fnb-order-receipt>
        </div>
        <div class="buttons-container">
            <div [ngClass]="{ editing: isEditing }" class="order-state-buttons">
                <div class="status-description">
                    {{ text.orderStatusDescription }}
                </div>
                <div
                    *ngIf="order.fulfillmentStatus !== 'IN_PROCESS'"
                    (click)="updateOrderFulfillment('IN_PROCESS')"
                    class="order-state-button in-progress"
                >
                    {{ text.status.IN_PROCESS }}
                </div>
                <div
                    *ngIf="order.fulfillmentStatus !== 'FULFILLED'"
                    (click)="updateOrderFulfillment('FULFILLED')"
                    class="order-state-button fulfilled"
                >
                    {{ text.status.FULFILLED }}
                </div>
                <div
                    *ngIf="order.fulfillmentStatus !== 'UNFULFILLED'"
                    (click)="updateOrderFulfillment('UNFULFILLED')"
                    class="order-state-button unfulfilled"
                >
                    {{ text.status.UNFULFILLED }}
                </div>
                <div class="order-state-button cancel" (click)="editOrder()">
                    {{ text.cancel }}
                </div>
            </div>
        </div>
    </div>

    <div
        *ngIf="!hasError && (isLoading || isUpdating)"
        class="cr-page-padding-top cr-flex-center"
    >
        <cr-spinner></cr-spinner>
    </div>

    <cr-data-error
        *ngIf="hasError"
        [message]="text.loadingFailed"
    ></cr-data-error>
</section>
