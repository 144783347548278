import angular from 'angular';

import ExtensionsBuilderComponent from './extensions-builder.component';
import ExtensionsBuilderRowModule from './extensions-builder-row';

const ExtensionsBuilderModule = angular
    .module('control-room.commons.form.components.extensions-builder', [ExtensionsBuilderRowModule.name])
    .component('crExtensionsBuilder', ExtensionsBuilderComponent);

export default ExtensionsBuilderModule;
