import _ from 'lodash';
import text from './resources/locale/en.json';

class CouponDetails {
    constructor(
        $state,
        $uibModal,
        crConfig,
        crConstants,
        crCouponsService,
        crLocaleService,
        crAnalyticsService,
        crNavigationService,
        crToastService,
        crErrorLoggingService
    ) {
        this.$state = $state;
        this.$uibModal = $uibModal;
        this.crConfig = crConfig;
        this.crConstants = crConstants;
        this.crCouponsService = crCouponsService;
        this.crLocaleService = crLocaleService;
        this.crAnalyticsService = crAnalyticsService;
        this.crNavigationService = crNavigationService;
        this.crToastService = crToastService;
        this.crErrorLoggingService = crErrorLoggingService;

        this.text = text;
    }

    $onInit() {
        this.locale = this.crLocaleService.getLocale(this.$state.params.venueId, this.$state.params.locale, !this.edit);
        this.showDropdown =
            this.crConfig.customer.localizationEnabled && this.locale.list && this.locale.list.length > 1;

        this.loading = true;
        this.crCouponsService
            .getCoupon(this.$state.params.id, true)
            .then((data) => {
                this.coupon = data;
                this.refreshLocaleStatus();
                this.loading = false;
            })
            .catch((err) => {
                this.crErrorLoggingService.logError('Could not get coupon', err, this.$state.params.id);
                this.error = true;
                this.loading = false;
            });

        // See if we need to show a toast when coming to this page
        this.crToastService.autoToast(this.$state.params);
    }

    isBroadcast(experience) {
        return experience.type.includes('BROADCAST');
    }

    refreshLocaleStatus() {
        const localeList = _.cloneDeep(this.locale.list);
        localeList.forEach((locale) => {
            const localizedTitle = this.coupon.title[locale.id];
            const localizedTerms = this.coupon.termsAndConditions[locale.id];

            if (!localizedTerms || !localizedTitle) {
                locale.hasStatusWarning = true;
            }
        });

        this.locale.list = localeList;
    }

    onLocaleUpdate(event) {
        this.locale.current = event.model[0].id;
        this.crAnalyticsService.track('Language Selected', { language: event.model[0].label });
    }

    deactivate() {
        if (this.loading) {
            return;
        }

        this.loading = true;

        this.crCouponsService
            .updateStatus(this.$state.params.id, { state: this.crConstants.entity.states.INACTIVE })
            .then(() => {
                this.crToastService.toast(`"${this.coupon.name}" ${this.text.hasBeenDeactivated}`);
                this.coupon.status = this.crConstants.entity.states.INACTIVE;
            })
            .catch((err) => {
                if (err.status === 400 && _.get(err, 'data.errors.0.type') === 'ASSOCIATED_TO_ENTITIES') {
                    this.openModal(this.text.couponLinkedError);
                } else {
                    this.openModal(`${this.text.problemDeactivating} "${this.coupon.name}"`);
                }
            })
            .finally(() => {
                this.loading = false;
            });
    }

    activate() {
        if (this.loading) {
            return;
        }

        this.loading = true;

        this.crCouponsService
            .updateStatus(this.$state.params.id, { state: this.crConstants.entity.states.ACTIVE })
            .then(() => {
                this.crToastService.toast(`"${this.coupon.name}" ${this.text.hasBeenActivated}`);
                this.coupon.status = this.crConstants.entity.states.ACTIVE;
            })
            .catch(() => this.openModal(`${this.text.problemActivating} "${this.coupon.name}"`))
            .finally(() => {
                this.loading = false;
            });
    }

    archive() {
        if (this.loading) {
            return;
        }

        const resolve = () => {
            this.loading = true;

            this.crCouponsService
                .archiveCoupon(this.$state.params.id)
                .then(() => {
                    this.crNavigationService.goBack('client.experience-promoter.coupons-list', {
                        toast: { msg: `"${this.coupon.name}" ${this.text.hasBeenArchived}` },
                    });
                })
                .catch((err) => {
                    if (err.status === 400 && _.get(err, 'data.errors.0.type') === 'ASSOCIATED_TO_ENTITIES') {
                        this.openModal(this.text.couponLinkedError);
                    } else {
                        this.openModal(`${this.text.problemArchiving} "${this.coupon.name}"`);
                    }
                    this.loading = false;
                });
        };

        this.$uibModal
            .open({
                backdrop: 'static',
                component: 'crSimpleModal',
                windowClass: 'cr-modal-size-sm',
                resolve: {
                    message: () => this.text.archiveModalMessage,
                    type: () => this.crConstants.modalTypes.SUBMIT,
                    submitText: () => this.text.archiveModalSubmit,
                },
            })
            .result.then(resolve)
            .catch(() => null);
    }

    saveCopy() {
        this.$state.go('client.experience-promoter.create-coupon', {
            id: this.$state.params.id,
        });
    }

    goToEditPage() {
        this.$state.go('client.experience-promoter.edit-coupon', {
            id: this.$state.params.id,
        });
    }

    openModal(message) {
        this.$uibModal
            .open({
                backdrop: 'static',
                component: 'crSimpleModal',
                windowClass: 'cr-modal-size-sm',
                resolve: {
                    message: () => message,
                },
            })
            .result.catch(() => null);
    }

    goBack() {
        this.crNavigationService.goBack('client.experience-promoter.coupons-list');
    }
}

export default CouponDetails;
