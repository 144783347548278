<cr-icon class="icon search" name="search" (click)="focusInput()"></cr-icon>
<input
    #input
    [ngClass]="{ 'not-empty': !!value }"
    data-local-qa-id="input"
    type="text"
    [attr.maxlength]="maxlength"
    [placeholder]="placeholder"
    [value]="value"
    (input)="onInput(input.value)"
    (keydown)="onKeydown($event)"
    (keypress)="onKeypress($event)"
    (keyup)="onKeyup($event)"
    (focus)="onFocus($event)"
    (blur)="onBlur($event)"
/>
<cr-icon
    class="icon clear"
    [ngClass]="{ invisible: !value }"
    (click)="clear($event)"
    name="clear"
></cr-icon>
