import _ from 'lodash';
import text from './resources/locale/en.json';

class BeaconsMapController {
    constructor($state, crBeaconsMapService, crErrorLoggingService, crAnalyticsService) {
        this.$state = $state;
        this.crBeaconsMapService = crBeaconsMapService;
        this.crErrorLoggingService = crErrorLoggingService;
        this.crAnalyticsService = crAnalyticsService;
    }

    $onInit() {
        this.text = text;

        this.params = {
            state: '',
            type: '',
            search: this.$state.params.search || '',
            venueId: this.$state.params.venueId,
        };

        this.markers = [];

        this.stateFilterData = [
            { id: 'ACTIVE', label: this.text.active },
            { id: 'INACTIVE', label: this.text.inActive },
            { id: 'DRAFT', label: this.text.draft },
        ];

        this.debounceUpdateData = _.debounce(this.updateData, 250);

        this.debounceOnFocusChange = _.debounce(this.onFocusChange, 500, {
            leading: false,
            trailing: true,
        });

        this.debounceOnZoomChange = _.debounce(this.onZoomChange, 500, {
            leading: false,
            trailing: true,
        });

        if (this.$state.params.focus) {
            try {
                const focus = this.$state.params.focus.split(',');
                this.focus = focus.map((str) => parseFloat(str));
            } catch (err) {
                this.$state.go('.', { focus: null }, { location: 'replace' });
            }
        }

        if (this.$state.params.zoom) {
            try {
                this.zoom = parseFloat(this.$state.params.zoom);
            } catch (err) {
                this.$state.go('.', { zoom: null }, { location: 'replace' });
            }
        }
    }

    onFocusChange($event) {
        this.$state.go('.', { focus: `${$event.focus[0]},${$event.focus[1]}` }, { location: 'replace' });
    }

    onZoomChange($event) {
        if ($event.user) {
            if ($event.zoom > this.$state.params.zoom) {
                this.crAnalyticsService.track('Beacon Map Zoom Changed', { zoomType: 'in' });
            } else if ($event.zoom < this.$state.params.zoom) {
                this.crAnalyticsService.track('Beacon Map Zoom Changed', { zoomType: 'out' });
            }
        }

        this.$state.go('.', { zoom: `${$event.zoom}` }, { location: 'replace' });
    }

    onMapLoad(event) {
        this.canvas = event.canvas;
        this.mapImage = event.mapImage;
    }

    $postLink() {
        this.handleDeepLink();
        this.updateData();
    }

    updateData() {
        this.getData().then((markers) => {
            this.markers = markers || [];
        });
    }

    getData() {
        this.isLoading = true;
        this.hasDataError = false;

        return this.crBeaconsMapService
            .getData(this.$state.params.venueId, this.params)
            .then((data) => {
                data.search = data.query.search || '';
                this.mapImageSrc = data.mapImageSrc;

                if (data.search === this.params.search) {
                    this.isLoading = false;

                    this.updateSearchDeepLink();

                    return this.crBeaconsMapService.mapToMarkersModel(data.beacons);
                }
            })
            .catch((err) => {
                this.hasDataError = true;
                this.markers = [];
                this.crErrorLoggingService.logError('Could not get Beacons map data', err);
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    updateParam(key, event) {
        this.params[key] = _.isEmpty(event.model) ? '' : event.model[0].id;
        this.updateData();
    }

    updateSearch(term) {
        term = term || '';

        this.params.search = term;

        if (term.length === 0 || term.length >= 3) {
            this.debounceUpdateData();
        } else {
            this.setShortSearchTimer(term);
        }
    }

    setShortSearchTimer(term) {
        setTimeout(() => {
            if (term === this.params.search) {
                this.debounceUpdateData();
            }
        }, 1000);
    }

    updateSearchDeepLink() {
        const { search } = this.params;
        if (search !== this.$state.params.search) {
            this.$state.go(this.$state.current.name, { search }, { location: 'replace' });
        }
    }

    handleDeepLink() {
        const truthyParams = _.pickBy(this.$state.params, (p) => !_.isNil(p));

        _.forOwn(truthyParams, (value, key) => {
            const hasKey = _.has(this.params, key);

            if (hasKey) {
                this.params[key] = value;
            }
        });
    }
}

export default BeaconsMapController;
