import angular from 'angular';

import text from './resources/locale/en.json';
import OrdersMockService from './orders.mock.service';
import OrdersComponents from './components/components';
import OrdersService from './orders.service';
import OrdersComponent from './orders.component';

const OrdersModule = angular
    .module('control-room.reports.components.orders', [OrdersComponents.name])
    .service('crReportingOrdersMockService', OrdersMockService)
    .service('crReportingOrdersService', ['$q', 'crCommerceService', 'crPlacesService', OrdersService])
    .constant('crReportingOrdersText', text)
    .component('crReportingOrders', OrdersComponent);

export default OrdersModule;
