import _ from 'lodash';
import text from './resources/locale/en.json';

class ExperienceManage {
    constructor(
        $state,
        $q,
        $uibModal,
        crConfig,
        crConstants,
        crAnalyticsService,
        crNavigationService,
        crExperiencesService,
        crEntityService,
        crErrorLoggingService,
        crLocaleService,
        crImageService,
        crPoiService,
        crCouponsService,
        crCommerceService,
        crVenueService,
        crExperienceManageService,
        crLoQueueService,
        crPolicyService
    ) {
        this.$state = $state;
        this.$q = $q;
        this.$uibModal = $uibModal;
        this.crConfig = crConfig;
        this.crConstants = crConstants;
        this.crAnalyticsService = crAnalyticsService;
        this.crNavigationService = crNavigationService;
        this.crExperiencesService = crExperiencesService;
        this.crEntityService = crEntityService;
        this.crErrorLoggingService = crErrorLoggingService;
        this.crLocaleService = crLocaleService;
        this.crImageService = crImageService;
        this.crPoiService = crPoiService;
        this.crCouponsService = crCouponsService;
        this.crCommerceService = crCommerceService;
        this.crVenueService = crVenueService;
        this.crExperienceManageService = crExperienceManageService;
        this.crLoQueueService = crLoQueueService;
        this.crPolicyService = crPolicyService;
        this.locationEnums = this.crConstants.userLocationEnums;

        this.text = text;
    }

    static deepObjectExtend(target, source) {
        for (const prop in source) {
            if (source.hasOwnProperty(prop)) {
                if (target[prop] && typeof source[prop] === 'object') {
                    ExperienceManage.deepObjectExtend(target[prop], source[prop]);
                } else {
                    target[prop] = source[prop];
                }
            }
        }
        return target;
    }

    $onInit() {
        this.crNavigationService.enableConfirmNavigation(() => this.form.$dirty);
        this.loading = true;
        this.copyOf = this.$state.params.copyOf;
        this.associatedEntities = {};
        this.locale = this.crLocaleService.getLocale(this.$state.params.venueId, this.$state.params.locale, !this.edit);
        this.isCorporateMarketor = false;
        this.hasCorporateMarketerRole();

        this.steps = [
            {
                label: this.text.what,
                trackingName: 'What',
                value: 1,
            },
            {
                label: this.text.who,
                trackingName: 'Who',
                value: 2,
            },
            {
                label: this.text.when,
                trackingName: 'When',
                value: 3,
            },
            {
                label: this.text.review,
                trackingName: 'Summary',
                value: 4,
            },
        ];

        this.states = [
            { id: 'ACTIVE', label: this.text.active },
            { id: 'INACTIVE', label: this.text.inactive },
            { id: 'DRAFT', label: this.text.draft },
        ];

        this.splitActions = [
            {
                label: this.text.delete,
                action: () => {
                    this.deleteExperience();
                },
            },
            {
                label: this.text.saveCopy,
                action: () => {
                    this.saveACopy();
                },
            },
        ];

        if (this.edit || this.copyOf) {
            this.$q
                .all([
                    this.loadExperience(this.copyOf),
                    this.getTagDefinitions(),
                    this.getEntityLists(),
                    this.getGeofences(),
                ])
                .then(() => {
                    this.updateExperienceTriggerLabels();
                    this.loading = false;
                });
        } else {
            this.$q
                .all([this.initExperience(), this.getTagDefinitions(), this.getEntityLists(), this.getGeofences()])
                .then(() => {
                    this.loading = false;
                });
        }
    }

    saveACopy() {
        if (this.form.$dirty) {
            this.crNavigationService
                .openConfirmModal()
                .then(() => {
                    this.crNavigationService.disableConfirmNavigation();
                    this.saveACopyNavigation();
                })
                .catch(() => null);
        } else {
            this.saveACopyNavigation();
        }
    }

    goToEdit() {
        if (this.group === 'automations') {
            this.$state.go('client.experience-promoter.edit-automation', {
                id: this.experience.id,
            });
        } else {
            this.$state.go('client.experience-promoter.edit-broadcast', {
                id: this.experience.id,
            });
        }
    }

    saveACopyNavigation() {
        if (this.group === 'automations') {
            this.$state.go('client.experience-promoter.create-automation', {
                copyOf: this.experience.id,
                type: this.experience.trigger.id,
            });
        } else if (this.group === 'broadcasts') {
            this.$state.go('client.experience-promoter.create-broadcast', {
                copyOf: this.experience.id,
                type: this.experience.trigger.id,
            });
        }
    }

    getEntityLists() {
        return this.$q
            .all({
                events: this.crEntityService.getEntityList(this.crConstants.entity.types.EVENTS, {
                    state: 'ACTIVE',
                    sort: 'asc',
                    venueId: this.$state.params.venueId,
                }),
                pois: this.crEntityService.getEntityList(this.crConstants.entity.types.POIS, {
                    state: 'ACTIVE',
                    sort: 'asc',
                    venueId: this.$state.params.venueId,
                }),
                poiCategories: this.crEntityService.getEntityList(this.crConstants.entity.types.POI_CATEGORIES, {
                    state: 'ACTIVE',
                    sort: 'asc',
                    venueId: this.$state.params.venueId,
                }),
                products: this.crCommerceService.getProducts(this.$state.params.venueId),
                poiWithBeacons: this.crPoiService.getPois({
                    state: 'ACTIVE',
                    venueId: this.$state.params.venueId,
                    userDetectEnabled: true,
                }),
                coupons: this.crCouponsService.getCoupons({
                    state: 'ACTIVE',
                    venueId: this.$state.params.venueId,
                    sortby: 'name',
                    sort: 'asc',
                }),
                menuItems: this.crExperienceManageService.getAllProductsAndCategories(this.$state.params.venueId),
                loQueueProducts: this.crLoQueueService.getAddons(this.$state.params.venueId),
            })
            .then((data) => {
                this.events = data.events.content.map((event) => ({
                    id: event.id,
                    name: event.name,
                }));

                this.pois = data.pois.content.map((poi) => ({
                    id: poi.id,
                    name: poi.name,
                    categories: poi.displayCategories,
                }));

                this.poiCategoryDefinitions = data.poiCategories.content.map((poiCategory) => ({
                    id: poiCategory.id,
                    label: poiCategory.label,
                }));

                this.products = data.products.map((product) => ({
                    id: product.id,
                    name: product.label,
                    url: product.purchaseLink
                }));

                this.poisWithBeacons = data.poiWithBeacons.content.map((poi) => ({
                    id: poi.id,
                    name: poi.name,
                    categories: poi.displayCategories,
                }));

                const poiCategoriesMap = {};
                this.poisWithBeacons
                    .filter((poi) => poi.categories)
                    .flatMap((poi) => poi.categories)
                    .forEach((category) => {
                        poiCategoriesMap[category.id] = category;
                    });
                this.poiCategories = Object.values(poiCategoriesMap);

                this.coupons = data.coupons.content;
                this.couponsMap = {};
                this.coupons.forEach((coupon) => {
                    this.couponsMap[coupon.id] = coupon;
                });

                this.menuItems = data.menuItems.products;
                this.menuCategories = data.menuItems.categories;
                this.loQueueProducts = data.loQueueProducts;

                this.poisWithRides = data.pois.content
                    .map((poi) => ({ ...poi, externalIds: _.get(poi, 'externalIds', []) }))
                    .filter((poi) => poi.externalIds.filter((e) => e.scope && e.scope === 'QSMART_RIDE').length > 0)
                    .map((poi) => ({
                        id: poi.id,
                        name: poi.name,
                        rideId: poi.externalIds.filter((e) => e.scope && e.scope === 'QSMART_RIDE')[0].id,
                    }));
            })
            .catch((err) => {
                this.error = true;
                this.loading = false;
                this.crErrorLoggingService.logError('Could not load entity lists', err);
            });
    }

    getTagDefinitions() {
        const customerParams = {
            level: 'CUSTOMER',
            supportsSegmentation: true,
        };
        const venueParams = { ...customerParams, level: 'VENUE', venueId: this.$state.params.venueId };
        const route = this.crConstants.entity.types.TAG_DEFINITIONS;

        return this.$q
            .all([
                this.crEntityService.getEntityList(route, customerParams),
                this.crEntityService.getEntityList(route, venueParams),
            ])
            .then((data) => {
                this.tagDefinitions = data[0].concat(data[1]);

                this.tagDefinitions.forEach((tagDefinition) => {
                    tagDefinition.id = tagDefinition.tagKey;

                    tagDefinition.values.forEach((value) => {
                        value.id = value.tagValueKey;
                    });
                });

                // This acts as a placeholder for when no EQ tags are selected
                this.tagDefinitions.push({
                    title: 'App Users',
                    customerId: this.$state.params.customerId,
                    id: this.crConstants.appUsersTagKey,
                    tagKey: this.crConstants.appUsersTagKey,
                    isFakeTag: true,
                    defaultValue: 'all',
                    type: 'SELECT',
                    definitionType: 'TAG',
                    supportsSegmentation: true,
                    userTagLevel: 'CUSTOMER',
                    values: [
                        {
                            label: 'All',
                            id: 'all',
                            tagValueKey: 'all',
                        },
                    ],
                });
            })
            .catch((err) => {
                this.error = true;
                this.loading = false;
                this.crErrorLoggingService.logError('Could not get tag definitions', err);
            });
    }

    getGeofences() {
        return this.crVenueService
            .getPublicById(this.$state.params.venueId)
            .then((data) => {
                this.geofences = data.geofences;
            })
            .catch((err) => {
                this.error = true;
                this.loading = false;
                this.crErrorLoggingService.logError('Could not get geofences', err);
            });
    }

    initExperience() {
        return this.getExperienceType(this.$state.params.type).then((experienceType) => {
            this.experience = {
                trigger: {
                    id: this.$state.params.type,
                    active: {
                        schedules: [
                            {
                                instances: [
                                    {
                                        startTime: null,
                                        endTime: null,
                                    },
                                ],
                                recurrence: null,
                                scheduleKey: null,
                            },
                        ],
                    },
                },
                name: '',
                venueId: this.$state.params.venueId,
                content: {
                    title: {},
                    body: {},
                    links: [],
                    coupons: [],
                    onHomeScreen: false,
                    images: [],
                },
                audience: {
                    tags: [
                        {
                            key: null,
                            op: 'EQ',
                            values: [],
                        },
                        {
                            key: null,
                            op: 'NEQ',
                            values: [],
                        },
                    ],
                    location: {
                        type: null,
                    },
                },
                expires: {
                    kind: 'DAILY',
                    daily: this.crConfig.customer.endOfDay,
                },
            };

            const disabledExpirationTypes = experienceType.disabledExpirationTypes || []
            if (disabledExpirationTypes.includes('DURATION')
                && disabledExpirationTypes.includes('DAILY')
                && disabledExpirationTypes.includes('TIME')) {
                // normal expirations are disabled, use the active end date as the expiration
                this.experience.expires = {
                    kind: 'EXPIRE_WHEN_DISABLED',
                }
            }
            else {
                // default daily expiration
                this.experience.expires = {
                    kind: 'DAILY',
                    daily: this.crConfig.customer.endOfDay,
                }
            }

            if (
                _.get(this.experienceType, 'active.type') !== 'ALWAYS' &&
                (this.group === 'automations' || this.$state.params.type === 'BROADCAST_RECURRING_SCHEDULE')
            ) {
                _.set(
                    this.experience,
                    'trigger.active.schedules[0].recurrence',
                    'FREQ=WEEKLY;BYDAY=SU,MO,TU,WE,TH,FR,SA;'
                );
            }

            if (this.group !== 'broadcasts') {
                this.experience.audience.location.type = 'ANYWHERE';
            }

            this.completeSteps = {
                what: false,
                who: false,
                when: false,
            };

            this.step = 1;
            this.trackNavigateToTab(this.step);

            this.updatePhonePreview();

            // Apply defaults from the template to the experience
            if (experienceType.parent) {
                const { parent } = experienceType;

                if (parent.id) {
                    this.experience.trigger.id = parent.id;
                }

                if (parent.defaults) {
                    ExperienceManage.deepObjectExtend(this.experience, parent.defaults);
                }
            }

            if (experienceType.active.type === 'NOW' || experienceType.active.type === 'ALWAYS') {
                this.experience.trigger.active = null;
            }
            this.experience.smsEnabled = experienceType.smsEnabled ? true: false;
        });
    }

    loadExperience(copyOf) {
        const id = copyOf || this.$state.params.id;
        return this.crExperiencesService
            .getExperience(id)
            .then((experience) => {
                this.experience = experience;
                return this.getExperienceType(this.experience.trigger.id);
            })
            .then((experienceType) => {
                // Split coupons out of normal list of links. They get added back together in preparePayload.
                this.experience.content.coupons = this.experience.content.links.filter(
                    (link) => link.type === 'COUPON'
                );

                this.experience.content.links = this.experience.content.links.filter((link) => link.type !== 'COUPON');

                if (copyOf) {
                    this.experience.name = `${this.text.copyOf} ${this.experience.name}`;
                }

                if (_.isEmpty(this.experience.content.push)) {
                    delete this.experience.content.push;
                }

                if (_.isEmpty(this.experience.content.sms)) {
                    delete this.experience.content.sms;
                }

                const eqTags = this.experience.audience.tags.filter((tag) => tag.op === 'EQ');
                const neqTags = this.experience.audience.tags.filter((tag) => tag.op === 'NEQ');

                if (eqTags.length === 0) {
                    this.experience.audience.tags.push({
                        key: null,
                        op: 'EQ',
                        values: [],
                    });
                }

                if (neqTags.length === 0) {
                    this.experience.audience.tags.push({
                        key: null,
                        op: 'NEQ',
                        values: [],
                    });
                }

                if (
                    _.get(this.experienceType, 'active.type') !== 'ALWAYS' &&
                    this.group === 'automations' &&
                    !_.has(this.experience, 'trigger.active.schedules[0].recurrence')
                ) {
                    _.set(
                        this.experience,
                        'trigger.active.schedules[0].recurrence',
                        'FREQ=WEEKLY;BYDAY=SU,MO,TU,WE,TH,FR,SA;'
                    );
                }

                this.summaryData = _.cloneDeep(this.experience);

                this.completeSteps = {
                    what: true,
                    who: true,
                    when: true,
                };

                this.selectedClass = this.experience.status;

                _.forEach(this.states, (state) => {
                    state.isSelected = state.id === this.experience.status;
                });

                // dont allow draft from other states
                if (this.selectedClass && this.selectedClass !== 'DRAFT') {
                    this.states[2].isDisabled = true;
                }

                this.experience.smsEnabled = experienceType.smsEnabled ? true: false;

                this.step = 4;
                this.trackNavigateToTab(this.step);

                this.getDisplayImage(this.experience.content.images);

                this.updatePhonePreview();
            })
            .catch((err) => {
                this.error = true;
                this.loading = false;
                this.crErrorLoggingService.logError('Could not get experience', err);
            });
    }

    getExperienceType(type) {
        return this.crExperiencesService
            .getExperienceType(type, this.group)
            .then((experienceType) => {
                if (experienceType.userBehavior) {
                    experienceType.userBehavior.forEach((behavior) => {
                        if (behavior.fields) {
                            behavior.fields.forEach((field) => {
                                if (field.description) {
                                    const { currencyCode } = _.find(this.crConfig.customer.venues, {
                                        id: this.$state.params.venueId,
                                    });
                                    field.description = field.description.replace(/%CURRENCY%/, currencyCode);
                                }
                            });
                        }
                    });
                }
                this.experienceType = experienceType;
                return this.experienceType;
            })
            .catch((err) => {
                this.error = true;
                this.loading = false;
                this.crErrorLoggingService.logError('Could not get experience type', err);
            });
    }

    updateExperienceTriggerLabels() {
        // Add labels and ids to user visit behavior poi tags and menu items
        this.crExperienceManageService.updateTriggerLabels(
            this.experience,
            'trigger.params.poi',
            this.pois,
            this.poiCategories,
            'POI',
            'POI_CATEGORY'
        );
        this.crExperienceManageService.updateTriggerLabels(
            this.experience,
            'trigger.params.menuItems',
            this.menuItems,
            this.menuCategories,
            'MENU_ITEM',
            'MENU_ITEM_CATEGORY'
        );
    }

    get titleBarLabel() {
        if (this.group === 'automations') {
            return this.edit ? this.text.editAutomation : this.text.createAutomation;
        }

        return this.edit ? this.text.editBroadcast : this.text.createBroadcast;
    }

    get experienceTypeTitle() {
        return this.group === 'automations'
            ? `${this.text.automation} ${this.experienceType.name}`
            : `${this.text.broadcast} ${this.experienceType.name}`;
    }

    get stateLabel() {
        return this.states.find((state) => state.id === this.experience.status).label;
    }

    get isAppSelectionBroadcastSupported() {
        return this.crConfig.customer.configFlags.enable_app_selection_broadcast ||
                !!this.crConfig.customer.configFlags.enable_app_selection_broadcast;
    }

    get isOOVenueExperienceSelected() {
        if(this.experience && this.experience.audience && this.experience.audience.location) {
            return this.experience.audience.location.type !== this.locationEnums.IN_VENUE;
        }
        return false;
    }

    next() {
        this.changeStep(this.step + 1);
    }

    goBack() {
        this.crAnalyticsService.track('Create Automation Canceled');
        this.crNavigationService.goBack('client.experience-promoter.dashboard', { tabId: this.group });
    }

    changeStep(step) {
        if (this.form.$valid) {
            const wasDirty = this.form.$dirty;
            this.form.$setPristine();
            this.form.$setUntouched();
            if (wasDirty) {
                this.form.$setDirty();
            }

            switch (this.step) {
                case 1:
                    this.completeSteps.what = true;
                    break;
                case 2:
                    this.completeSteps.who = true;
                    break;
                case 3:
                    this.completeSteps.when = true;
                    break;
            }

            this.trackNavigateToTab(step);

            this.step = step;
            this.locale.current = this.locale.default;
            this.summaryData = _.cloneDeep(this.experience);
            this.updatePhonePreview();
        } else {
            this.form.$setSubmitted();
            _.forEach(this.form, (item) => {
                if (item && item.$$parentForm === this.form && item.$setSubmitted) {
                    item.$setSubmitted();
                }
            });
        }
    }

    get isGlobalAppSelected() {
        let isGlobalAppSelected = false;
        if(this.crConfig.customer.configFlags.global_apps && this.crConfig.customer.configFlags.global_apps.length > 0) {
            const globalAppIds = this.crConfig.customer.configFlags.global_apps;
            if(this.experience.appIds && this.experience.appIds.length > 0) {
                const selectedApps = this.experience.appIds;
                isGlobalAppSelected = selectedApps.some(appId => {
                    return globalAppIds.includes(appId);
                });
            }
        }
        return isGlobalAppSelected;
    }


    trackNavigateToTab(stepValue) {
        const stepData = this.steps[stepValue - 1];
        if (!stepData.visited) {
            stepData.visited = true;
            this.crAnalyticsService.track(
                `Navigated to tab "${stepData.trackingName}" in ${this.edit ? 'edit' : 'create'} mode`
            );
        }
    }

    onStepChange(event) {
        this.changeStep(event.step.value);
    }

    onStateUpdate(event) {
        this.selectedClass = event.model[0].id;
        this.experience.status = event.model[0].id;
    }

    save() {
        this.form.$setSubmitted();

        if(this.edit && this.isAppSelectionBroadcastSupported && this.isOOVenueExperienceSelected && !this.isCorporateMarketor && this.isGlobalAppSelected) {
            this.$uibModal
                    .open({
                        backdrop: 'static',
                        component: 'crSimpleModal',
                        windowClass: 'cr-modal-size-md',
                        resolve: {
                            closeText: () => this.text.closeError,
                            message: () => this.text.invalidOutOfVenueBroadcastAccess,
                        },
                    })
                    .result.catch(() => null);
                // Early return if the user doesnot have role for global marketor
                return;
        }

        if (this.experience.content.coupons) {
            const invalidCoupons = this.experience.content.coupons.filter(
                (coupon) => !this.couponsMap[coupon.resourceId]
            );
            if (invalidCoupons.length > 0) {
                // Cancel the save
                this.$uibModal
                    .open({
                        backdrop: 'static',
                        component: 'crSimpleModal',
                        windowClass: 'cr-modal-size-sm',
                        resolve: {
                            closeText: () => this.text.closeError,
                            message: () => this.text.invalidCouponError,
                        },
                    })
                    .result.catch(() => null);
                // Early return if the coupons are invalid
                return;
            }
        }

        if (this.form.$valid) {
            if (this.form.$dirty || this.copyOf) {
                if (this.experience.status === 'ACTIVE') {
                    this.$uibModal
                        .open({
                            backdrop: 'static',
                            component: 'crSimpleModal',
                            windowClass: 'cr-modal-size-sm',
                            resolve: {
                                closeText: () => this.text.cancel,
                                saveText: () => this.text.save,
                                type: () => this.crConstants.modalTypes.SUBMIT,
                                message: () => this.text.confirmActivation,
                            },
                        })
                        .result.then(() => {
                            this.doSave();
                        })
                        .catch(() => null);
                } else {
                    this.doSave();
                }
            } else {
                this.goBack();
            }
        }
    }

    doSave() {
        this.saving = true;

        if (this.edit) {
            this.updateExperience();
        } else {
            this.createExperience();
        }
    }

    preparePayload() {
        const payload = _.cloneDeep(this.experience);
        payload.audience.tags = payload.audience.tags.filter(
            (tag) => tag.key && !(tag.definition && tag.definition.isFakeTag)
        );

        // Strip erroneous data out of user behavior trigger
        if (_.get(payload, 'trigger.params.poi')) {
            payload.trigger.params.poi = payload.trigger.params.poi.map((poi) => ({
                value: poi.value,
                type: poi.type,
            }));
        }

        const links = payload.content.links.map((link) => ({
            type: link.type,
            label: link.label,
            link: link.link,
            resourceId: link.resourceId,
            appNav: link.appNav[this.locale.default] ? link.appNav : null,
        }));

        payload.content.links = links;

        // Move coupons back into the list of links
        if (payload.content.coupons) {
            const coupons = payload.content.coupons.map((coupon) => ({
                type: coupon.type,
                label: coupon.label,
                link: coupon.link,
                resourceId: coupon.resourceId,
                appNav: coupon.appNav[this.locale.default] ? coupon.appNav : null,
            }));
            payload.content.links.push(...coupons);
            delete payload.content.coupons;
        }

        // Add configured redelivery expiration / squelch rules or fallback
        return this.getExperienceType(payload.trigger.id).then((experienceType) => {
            if (experienceType.redeliveryExpiration) {
                payload.redeliveryExpiration = experienceType.redeliveryExpiration;
            } else {
                payload.redeliveryExpiration = {
                    kind: 'DAILY',
                    daily: this.crConfig.customer.endOfDay,
                };
            }
            return payload;
        });
    }

    activate() {
        this.experience.status = 'ACTIVE';
        this.selectedClass = this.experience.status;
        this.saving = true;
        this.updateExperience();
    }

    deactivate() {
        this.experience.status = 'INACTIVE';
        this.selectedClass = this.experience.status;
        this.saving = true;
        this.updateExperience();
    }

    updateExperience() {
        this.preparePayload()
            .then((payload) => this.crExperiencesService.updateExperience(payload))
            .then((res) => this.onSaveSuccess(res))
            .catch((err) => {
                this.handleError('Could not update experience', err);
                this.saving = false;
            });
    }

    createExperience() {
        this.preparePayload()
            .then((payload) => this.crExperiencesService.createExperience(payload))
            .then((res) => this.onSaveSuccess(res))
            .catch((err) => {
                this.handleError('Could not create experience', err);
                this.saving = false;
            });
    }

    onSaveSuccess(data) {
        if (!data) {
            data = this.experience;
        }

        this.crNavigationService.disableConfirmNavigation();
        const params = this.getToastParams(data);

        if (this.edit) {
            let route;
            if (this.group === 'automations') {
                route = 'client.experience-promoter.details-automation';
            } else {
                route = 'client.experience-promoter.details-broadcast';
            }
            this.crAnalyticsService.track('Edit Experience Success', data);
            this.crNavigationService.goBack(route, params);
        } else {
            this.crAnalyticsService.track('Add New Experience Success', data);
            this.crNavigationService.goToFromCreate('client.experience-promoter.dashboard', params);
        }
    }

    getToastParams(data) {
        return {
            tabId: this.group,
            id: data.id,
            toast: {
                msg: `"${data.name}" ${this.edit ? this.text.hasBeenUpdated : this.text.hasBeenCreated}`,
            },
        };
    }

    handleError(errMsg, err) {
        this.showErrorModal();
        this.crErrorLoggingService.logError(errMsg, err);
    }

    showErrorModal() {
        const message = this.edit ? this.text.updateError : this.text.createError;

        this.$uibModal
            .open({
                backdrop: 'static',
                component: 'crSimpleModal',
                windowClass: 'cr-modal-size-sm',
                resolve: {
                    closeText: () => this.text.closeError,
                    message: () => message,
                },
            })
            .result.catch(() => null);
    }

    deleteExperience() {
        this.$uibModal
            .open({
                backdrop: 'static',
                component: 'crSimpleModal',
                windowClass: 'cr-modal-size-sm',
                resolve: {
                    type: () => 'submit',
                    submitText: () => this.text.deleteExperience,
                    message: () => this.text.deleteConfirmation,
                },
            })
            .result.then(() => {
                this.saving = true;
                return this.crExperiencesService
                    .archiveExperience(this.experience.id)
                    .then(() => {
                        this.crNavigationService.disableConfirmNavigation();
                        this.crAnalyticsService.track('Archive Experience Success');
                        this.crNavigationService.goBack('client.experience-promoter.dashboard', {
                            tabId: this.group,
                            toast: {
                                msg: `"${this.experience.name}" ${this.text.hasBeenDeleted}`,
                            },
                        });
                    })
                    .catch((err) => {
                        this.saving = false;
                        this.crErrorLoggingService.logError('Could not delete experience', err, this.experience);
                    });
            })
            .catch(() => null);
    }

    onImageUpdated(event) {
        this.experience.content.images = _.cloneDeep(event.images);
    }

    getDisplayImage(images) {
        const phoneImgWidth = this.crConstants.image.phoneDisplaySize.MAIN.width;
        const phoneImgHeight = this.crConstants.image.phoneDisplaySize.MAIN.height;
        if (images && images.length > 0) {
            images[0].type = this.crConstants.image.types.MAIN;
            images[0].displaySrc = this.crImageService.getMainImageSrc(images, phoneImgWidth, phoneImgHeight);
        }
    }

    updatePhonePreview($event) {
        if ($event && $event.locale) {
            this.locale = $event.locale;
        }
        this.phoneData = _.cloneDeep(this.experience);
    }

    hasCorporateMarketerRole() {
        this.crPolicyService.hasRole('exp_corporate_marketer').then(res => this.isCorporateMarketor = res);
    }
}

export default ExperienceManage;
