import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { StateService } from '@uirouter/core';
import { of } from 'rxjs/internal/observable/of';
import { catchError, take } from 'rxjs/operators';
import { LocalizedText } from '../../../../core';
import { ErrorLoggingService } from '../../../../shared';
import { Bundle } from '../../../../shared/services/bundle/bundle.model';
import { BundleService } from '../../../../shared/services/bundle/bundle.service';
import { CrTableColumnOptions, CrTableHoverIconClickEvent, CrTableOptions } from '../../../../shared/table/table.model';
import { menuRoutes } from '../../menus.routes';
import { BundleListData } from './bundle-list.model';
import text from './resources/en.json';

@Component({
  selector: 'cr-bundle-list',
  templateUrl: 'bundle-list.component.html',
  styleUrls: ['./bundle-list.component.scss'],
})

export class BundleListComponent implements OnInit {
  crTableOptions: CrTableOptions<BundleListData> = {
    data: undefined,
    columns: undefined,
    emptyStateText: text.emptyStateText,
    hoverOptions: [{ actionString: 'EDIT', icon: 'icon-cr-edit' }],
  };

  bundleListData: BundleListData[];

  searchFormControl = new FormControl();

  text: LocalizedText;

  filterState = {
    state: 'ALL',
    categories: 'ALL',
  };

  states = [];

  categories = [];

  constructor(private bundleService: BundleService, private stateService: StateService, private logger: ErrorLoggingService) {
    this.text = text;
  }

  ngOnInit() {
    this.generateColumns();
    this.getData();
    this.states = [
      { id: 'ACTIVE', label: (this.text.active as string) },
      { id: 'INACTIVE', label: (this.text.inActive as string) },
      { id: 'DRAFT', label: (this.text.draft as string) },
    ];
  }

  handleSort(column: CrTableColumnOptions): void {
    // handle data sorting based on key
    if (column.sort) {
      this.crTableOptions.data.sort((a, b) => a.displayName.localeCompare(b.displayName));
    } else {
      this.crTableOptions.data.sort((a, b) => b.displayName.localeCompare(a.displayName));
    }
  }

  onStateSelect(event): void {
    if (event.$event.model.length === 0) {
      this.filterState.state = 'ALL';
      this.filterData();
    } else {
      this.filterState.state = event.$event.model[0].id;
      this.filterData();
    }
  }

  onCategorySelect(event): void {
    if (event.$event.model.length === 0) {
      this.filterState.categories = 'ALL';
      this.filterData();
    } else {
      this.filterState.categories = (event.$event.model[0].label as string);
      this.filterData();
    }
  }

  handleSearch(searchQuery: string): void {
    if (this.bundleListData) {
      this.crTableOptions.data = this.bundleListData.filter((item) => (item.displayName.toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase())));
    }

    this.setEmptyMessage();
  }

  handleColumnClick(a: BundleListData): void {
    if (a.column.displayName === 'Name') {
      this.goToBundleDisplayItem(a.id);
    }
  }

  handleRowIconClick(e:CrTableHoverIconClickEvent<Bundle>): void {
    if (e.action === 'EDIT') {
      const { placeId, menuId } = this.stateService.params;
      this.stateService.go(menuRoutes.EDIT_BUNDLE, {
        placeId,
        menuId,
        id: e.item.id,
      });
    }
  }

  private filterData() {
    this.crTableOptions.data = this.bundleListData;

    if (this.filterState.categories !== 'ALL') {
      this.crTableOptions.data = [];
      this.bundleListData.filter((item) => item.categories.filter((category) => {
        if (category.displayName === this.filterState.categories) {
          this.crTableOptions.data.push(item);
        }
      }));
    }

    if (this.filterState.state !== 'ALL') {
      this.crTableOptions.data = this.crTableOptions.data.filter((item) => item.state.toLowerCase().includes(this.filterState.state.toLowerCase()));
    }

    this.setEmptyMessage();
  }

  private setEmptyMessage(): void {
    if (this.bundleListData.length > 0 && this.crTableOptions.data.length === 0) {
      this.crTableOptions.emptyStateText = text.noResultsFound;
    } else {
      this.crTableOptions.emptyStateText = text.emptyStateText;
    }
  }

  private getData(): void {
    const { placeId, menuId } = this.stateService.params;

    this.bundleService.getBundleListData(placeId, menuId)
      .pipe(
        take(1),
        catchError((err) => of(this.handleError(err))),
      )
      .subscribe((res) => {
        if (res) {
          this.crTableOptions.data = res;
          this.bundleListData = this.crTableOptions.data;
          this.bundleListData.forEach((bundle) => {
            bundle.title = bundle.content.name;
          });
          this.getCategories();
          this.crTableOptions.data.sort((a, b) => a.displayName.localeCompare(b.displayName));
        }
      });
  }

  private getCategories(): void {
    this.crTableOptions.data.forEach((items: BundleListData) => {
      items.categories.map((cat) => cat.displayName)
        .filter((value) => {
          if (this.categories.indexOf(value) === -1) {
            this.categories.push(value);
          }
        });
    });

    this.categories = this.categories.map((cat, index) => ({
      id: `${index}`,
      label: cat,
    }));
  }

  private generateColumns() {
    this.crTableOptions.columns = [
      {
        displayName: '',
        key: 'images',
        width: 64,
        images: true,
        customRowStyles: { 'padding-left': '16px' },
      },
      {
        displayName: 'Name',
        key: 'displayName',
        width: 200,
        sort: true,
        customColumnOnClick: true,
        customRowStyles: { color: 'black' },
      },
      {
        displayName: 'Title',
        key: 'title',
        width: 200,
      },
      {
        displayName: 'Category',
        key: 'categories',
        width: 300,
        chips: true,
      },
      {
        displayName: 'State',
        key: 'state',
        width: 150,
        customRowStyles: { 'text-transform': 'capitalize' },
      },
      {
        displayName: 'Missing Translations',
        key: 'missingTranslations',
        width: 200,
        customRowStyles: { color: 'red' },
      },
    ];
  }

  private handleError(err) {
    this.logger.logError('bundle list get data', err);
  }

  private goToBundleDisplayItem(bundleId: string): void {
    const { placeId, menuId } = this.stateService.params;
    this.stateService.go(menuRoutes.BUNDLE, {
      placeId,
      menuId,
      bundleId,
    });
  }
}
