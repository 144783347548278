class PdfModalController {
    constructor($scope, $state, $sce, crFileUploadService) {
        this.$scope = $scope;
        this.$state = $state;
        this.$sce = $sce;
        this.crFileUploadService = crFileUploadService;
        this.customerId = this.$state.params.customerId;
    }

    reset() {
        this.base64DataUrl = null;
        this.filePreviewUrl = null;
        this.file = null;
        this.errorMessage = '';
        this.loading = false;
    }

    $onInit() {
        this.reset();

        if (this.resolve) {
            this.recommendedFileSizeBytes = this.resolve.recommendedFileSizeBytes;
        }

        // It's ok if this is undefined when uploading files for non-venue customers
        this.venueId = this.$state.params.venueId;
    }

    onSubmit() {
        if (!this.isDisabled()) {
            this.uploadFile(this.base64DataUrl).then(() => {
                this.resolve.targetPdf.url = this.newUrl;
                this.close();
            });
        }
    }

    onCancel() {
        if (this.upload) {
            this.upload.abort();
        }

        this.dismiss();
    }

    isDisabled() {
        return !this.base64DataUrl || this.loading;
    }

    updateErrorMessage(msg) {
        this.errorMessage = msg;
    }

    handleFile() {
        const txt = this.resolve.text;

        // Reset any error messages
        this.updateErrorMessage('');

        // Make the file types human readable for error messages
        const readableFileTypes = this.resolve.fileExtensions.replace(/,/g, ', ');

        if (this.file && !this.file.$error) {
            if (this.file.size > this.recommendedFileSizeBytes) {
                this.updateErrorMessage(this.resolve.text.fileSizeWarning);
            }

            this.loading = true;
            this.filePreviewUrl = URL.createObjectURL(this.file);
            this.base64DataUrl = this.crFileUploadService.getBase64DataUrl(this.file).then((res) => {
                this.base64DataUrl = res;
                this.loading = false;
            });
        } else if (this.file) {
            this.updateErrorMessage(`${txt.fileErrorMessage} ${txt.pleaseTryAgain}`);
        } else {
            this.updateErrorMessage(
                `${txt.invalidFileTypeMessage} ${readableFileTypes} ${txt.maxFileSizeMessage} ${this.resolve.maxFileSize}. ${txt.pleaseTryAgain}`
            );
        }
    }

    uploadFile(base64DataUrl) {
        const txt = this.resolve.text;
        this.loading = true;

        const filename = this.file.name.replace(/[^a-zA-Z0-9\-_.]/g, '');
        this.upload = this.crFileUploadService.upload(filename, base64DataUrl, this.venueId);

        this.upload
            .then((res) => {
                const url = `https://static-${this.customerId}.te2.biz${res.data.src}`;
                console.info(`File uploaded: ${res.src} to ${url}`);
                this.newUrl = encodeURI(url);
            })
            .catch(() => {
                this.updateErrorMessage(`${txt.problemUploadingMessage} ${this.file.name}. ${txt.pleaseTryAgain}`);
                this.loading = false;
            })
            .finally(() => {
                this.upload = null;
                this.loading = false;
            });

        return this.upload;
    }

    trustUrl(url) {
        return this.$sce.trustAsResourceUrl(url);
    }

    cancel() {
        this.reset();
    }
}

export default PdfModalController;
