import { menuRoutes } from '../../menus.routes';
import text from './resources/locale/en.json';

class MenusTabs {
    constructor($state, crAnalyticsService, crConstants, $uibModal, crFeatureFlagsService) {
        this.$state = $state;
        this.crAnalyticsService = crAnalyticsService;
        this.crConstants = crConstants;
        this.$uibModal = $uibModal;
        this.crFeatureFlagsService = crFeatureFlagsService;
    }

    $onInit() {
        this.text = text;
        this.menuRoutes = menuRoutes;
    }

    goBack() {
        this.crNavigateService.goBack(menuRoutes.DASHBOARD);
    }

    goToCreatePage() {
        if (this.$state.params.tabId === 'kitchens') {
            this.crAnalyticsService.track('Add new kitchen clicked');
            this.openCreateKitchenModal();
        }
        if (this.$state.params.tabId === 'fees') {
            this.crAnalyticsService.track('Add new Fee clicked');
            this.$state.go(menuRoutes.CREATE_FEES)
        }
    }

    openCreateMenuModal() {
        this.$uibModal.open({
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            backdrop: 'static',
            component: 'crCreateMenuModal',
            windowClass: 'cr-modal-size-md',
        });
    }

    openCreateKitchenModal() {
        this.$uibModal.open({
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            backdrop: 'static',
            component: 'crCreateKitchenModal',
            windowClass: 'cr-modal-size-xlg',
        });
    }

    get hasAddButton() {
        return this.$state.params.tabId === 'kitchens' || this.$state.params.tabId === 'fees';
    }
}

export default MenusTabs;
