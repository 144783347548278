import template from './form-label-error.html';
import './form-label-error.less';

const FormLabelErrorComponent = {
    template,
    transclude: true,
    require: {
        form: '^form',
    },
    bindings: {
        errorMessages: '<?',
        for: '@',
        type: '@?',
        customClass: '@?',
    },
};

export default FormLabelErrorComponent;
