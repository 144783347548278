import { Injectable } from '@angular/core';
import bulletTrain from 'bullet-train-client';
import {
  newEnforcer, newModel, StringAdapter, Enforcer,
} from 'casbin';

async function createEnforcer(model: string, policy: string): Promise<Enforcer> {
  if (!model || !policy) {
    return Promise.reject('missing enforcer parameters');
  }
  const enforcer = await newEnforcer(newModel(model), new StringAdapter(policy));
  return enforcer;
}

@Injectable({
  providedIn: 'root',
})
export class PolicyService {
  private user: string;

  private customer: string;

  private e: Enforcer;

  async initialize(user: string, customer: string, model: string, policy: string) {
    this.user = user;
    this.customer = customer;
    this.e = await createEnforcer(model, policy);
  }

  async hasAccess(action: string, id?: string): Promise<boolean> {
    if (!bulletTrain.hasFeature('rbac')) {
      return true;
    }
    if (!this.e) {
      return false;
    }
    const object = id || '*';
    return this.e.enforce(this.user, this.customer, object, action);
  }

  async hasRole(role: string): Promise<boolean> {
    if (!bulletTrain.hasFeature('rbac')) {
      return true;
    }
    if (!this.e) {
      return false;
    }
    return this.e.hasRoleForUser(this.user, role, this.customer);
  }

}
