import text from './resources/locale/en.json';

import ListPage from '../../../../commons/controller/list-page';

class MenusList extends ListPage {
    constructor(
        $state,
        crErrorLoggingService,
        crAnalyticsService,
        crToastService,
        crLocaleService,
        crConfig,
        crMenusListService,
        crEntitySelectionManager
    ) {
        super($state, crErrorLoggingService, crAnalyticsService, crToastService, crLocaleService, crConfig);
        this.crMenusListService = crMenusListService;
        this.crEntitySelectionManager = crEntitySelectionManager;
    }

    $onInit() {
        super.$onInit();

        this.text = text;

        this.registerParam('state', '');

        const { columns } = this.text;

        this.listData = {
            columns: [
                { key: 'placeholder', label: '' },
                { key: 'name', label: columns.name, class: 'large-column name-column', sortable: true },
                { key: 'state', label: columns.state },
            ],
            rows: [],
        };

        this.stateFilterData = [
            { id: 'ACTIVE', label: this.text.active },
            { id: 'INACTIVE', label: this.text.inActive },
            { id: 'DRAFT', label: this.text.draft },
        ];
        this.stateFilterData[0].isSelected = true;
    }

    getData() {
        this.isLoading = true;
        this.dataError = false;

        return this.crMenusListService
            .getData(this.params)
            .then((data) => {
                data.search = data.query.search || '';

                if (data.search === this.params.search) {
                    this.isLoading = false;
                    return this.crMenusListService.mapToRowsModel(data.content);
                }
            })
            .catch((err) => {
                this.isLoading = false;
                this.dataError = true;
                this.endOfResults = true;
                this.listData.rows = [];
                this.crErrorLoggingService.logError('Could not get Menus List data', err);
            });
    }

    goToCreatePage() {
        this.crAnalyticsService.track('Add New Menu Clicked');
        this.$state.go('client.menus.create', {
            customerId: this.$state.params.customerId,
        });
    }
}

export default MenusList;
