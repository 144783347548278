import _ from 'lodash';

import text from './resources/locale/en.json';
import iconPlaceHolderSrc from '../../../basic/resources/images/img_placeholder@2x.png';

class FeaturedEventsPhonePreview {
    constructor(crImageService) {
        this.crImageService = crImageService;
    }

    $onChanges(changes) {
        if (changes.featuredEvents && this.featuredEvents) {
            this.featuredEvents = _.cloneDeep(this.featuredEvents);
            const { featured } = this.featuredEvents;
            this.showFeatured = featured && !_.isEmpty(featured[0]);
            this.featuredEvents.trending = this.featuredEvents.trending.filter((item) => item.id);
            this.showTrending = this.featuredEvents.trending.length;

            if (this.showFeatured) {
                this.featuredImageSrc = this.getFeaturedImage(featured[0].images);
                this.featuredTitle = this.featuredEvents.featured[0].title;
            }

            if (this.showTrending) {
                this.featuredEvents.trending.forEach((item) => {
                    if (!_.isEmpty(item.associatedPois)) {
                        item.locationText =
                            item.associatedPois.length === 1 ? item.associatedPois[0].label : this.text.multiple;
                    }
                    item.iconSrc = !_.isEmpty(item.images) ? this.getIconSrc(item.images) : iconPlaceHolderSrc;
                });
            }
        }
    }

    $onInit() {
        this.text = text;
    }

    getIconSrc(images) {
        const icon = _.find(images, { type: 'ICON' }) || _.find(images, { type: 'MAIN' });
        return !icon ? iconPlaceHolderSrc : this.crImageService.getIconSrc(icon.src);
    }

    getFeaturedImage(images) {
        let featuredImage = iconPlaceHolderSrc;
        if (!_.isEmpty(images)) {
            featuredImage = this.crImageService.getImageSrc(_.find(images, { type: 'MAIN' }), 110, 73);
        }

        return featuredImage;
    }
}

export default FeaturedEventsPhonePreview;
