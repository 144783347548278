import text from './resources/locale/en.json';

class AutomationSelect {
    constructor($state, crConstants, crAnalyticsService, crNavigationService, crExperiencesService) {
        this.$state = $state;
        this.crConstants = crConstants;
        this.crAnalyticsService = crAnalyticsService;
        this.crNavigationService = crNavigationService;
        this.crExperiencesService = crExperiencesService;

        this.text = text;

        this.categories = ['location', 'action', 'dispatch', 'commerce', 'food_beverage', 'ticket'];
    }

    $onInit() {
        this.types = {};
        this.loading = true;
        this.crExperiencesService
            .getAutomationTypes()
            .then((types) => {
                types.automations.forEach((type) => {
                    if (!this.types[type.category]) {
                        this.types[type.category] = [];
                    }

                    this.types[type.category].push(type);
                    this.loading = false;
                });
            })
            .catch((error) => {
                console.error(error);
                this.loading = false;
                this.error = true;
            });
    }

    goBack() {
        this.crAnalyticsService.track('Create Automation Canceled');
        this.crNavigationService.goBack('client.experience-promoter.dashboard', { tabId: this.$state.params.tabId });
    }

    next(type) {
        this.$state.go('client.experience-promoter.create-automation', { type });
    }
}

export default AutomationSelect;
