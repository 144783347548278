import _ from 'lodash';

class FnbReportingService {
    constructor(crCommerceService, crPlacesService, crConfig, $q) {
        this.crCommerceService = crCommerceService;
        this.crPlacesService = crPlacesService;
        this.crConfig = crConfig;
        this.$q = $q;
    }

    getPlacesFromVenueId(venueId) {
        const params = {
            venueId,
            sort: 'asc',
            sortby: 'state',
            state: 'enabled',
            page: 1,
        };
        return this.crPlacesService.getPlaces(params).then((response) => response.places);
    }

    getOrdersReports(venueId, places, timeframe, interval) {
        // If no places are selected, use the venue as the place
        if (places.length === 0) {
            places = [{ id: `venue:${venueId}` }];
        }
        const { currencyCode } = _.find(this.crConfig.customer.venues, { id: venueId });
        const placeIds = places.map((place) => place.id);
        return this.crCommerceService
            .ordersReports(placeIds, timeframe, null, interval, 10, false, currencyCode)
            .then((data) => this.getPlaceReportModel(data));
    }

    getPlaceReportModel(data) {
        const commerceData = {
            ordersPlaced: data.ordersPlaced,
            topItemsPurchased: data.topItemsPurchased,
            totalOrders: 0,
            ordersValue: 0,
        };

        const { labels } = data.ordersPlaced;
        const orderSeries = data.ordersPlaced.series[0];
        orderSeries.forEach((order, index) => {
            order.meta = { price: order.meta, date: labels[index], currency: order.currency };
        });

        commerceData.ordersPlaced.series[0].forEach((dataSet) => {
            commerceData.totalOrders += dataSet.value;
            commerceData.ordersValue += dataSet.meta.price;
        });

        return commerceData;
    }
}

export default FnbReportingService;
