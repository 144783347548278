import angular from 'angular';
import moment from 'moment';
import _ from 'lodash';

class ClientController {
    constructor(
        $http,
        $state,
        $rootScope,
        $window,
        $uibModal,
        $transitions,
        crConstants,
        layoutService,
        socket,
        clientLocale,
        navPanelOptions,
        crAnalyticsService,
        crUserService,
        crConfig,
        crNavigationService
    ) {
        this.$http = $http;
        this.$state = $state;
        this.$rootScope = $rootScope;
        this.$window = $window;
        this.$uibModal = $uibModal;
        this.$transitions = $transitions;
        this.constants = crConstants;
        this.events = crConstants.events;
        this.layoutService = layoutService;
        this.socket = socket;
        this.text = clientLocale;
        this.crAnalyticsService = crAnalyticsService;
        this.config = crConfig;
        this.crNavigationService = crNavigationService;
        this.crUserService = crUserService;

        this.navPanelOptions = [];
        navPanelOptions.forEach((category) => {
            const actions = this.config.customer.features
                .map((feature) => _.find(category.actions, {id: feature.id}))
                .filter((action) => action && action.id);

            if (actions.length > 0) {
                this.navPanelOptions.push({
                    ...category,
                    actions,
                });
            }
        });
    }

    $onInit() {
        this.lastDebounce = 0;
        this.debounce = 5000;

        this.slim = this.layoutService.getSlim();

        this.customer = this.config.customer;

        this.socket.open();
        this.socket.on('session timeout', this.onSessionTimeoutChange.bind(this));
        this.socket.on('session destroyed', this.onSessionDestroyed.bind(this));

        angular.element(this.$window).on('mousemove', this.onWindowMousemove.bind(this));

        this.crUserService.getUser().then((user) => {
            this.initAnalytics(user);
        });

        this.initHistory();
    }

    $onDestroy() {
        this.cleanupNavHook();
        this.cleanupAnalyticsHook();
        this.socket.removeAllListeners('session timeout');
        this.socket.removeAllListeners('session destroyed');
        this.socket.close();
        angular.element(this.$window).off('mousemove');
        this.crAnalyticsService.destroy();
    }

    initHistory() {
        this.cleanupNavHook = this.$transitions.onSuccess({}, (transition) => {
            const replace = transition.options().location === 'replace';
            const from = transition.from();
            const fromParams = transition.params('from');
            const to = transition.to();
            const toParams = transition.params();

            if (!from.abstract) {
                if (from.name !== to.name && !replace) {
                    this.crNavigationService.addState(
                        {state: from, params: fromParams},
                        {state: to, params: toParams}
                    );
                } else {
                    this.crNavigationService.replaceState({state: to, params: toParams});
                }
            } else {
                this.crNavigationService.initState({state: to, params: toParams});
            }
        });
    }

    initAnalytics(user) {
        const superProperties = {
            lastVisit: moment().utc().format(),
            name: user.username,
            sessionId: user.sessionId,
        };

        this.crAnalyticsService.register(superProperties);

        const userProfile = {
            $name: user.username,
            $email: user.email,
        };

        this.crAnalyticsService.identify(user.username, userProfile);

        this.cleanupAnalyticsHook = this.$transitions.onSuccess({}, () => {
            this.crAnalyticsService.trackPageView();
        });

        this.crAnalyticsService.trackPageView();
    }

    onSessionTimeoutChange(time) {
        clearTimeout(this.timeout);

        if (this.config.customer.configFlags.requiresLegacyPlatform) {
            // Keep platform session alive
            this.$http.get('/rest/mc-classic/admin/venue');
        }
        this.timeout = setTimeout(() => {
            this.socket.emit('session destroyed?');
        }, time);
    }

    onSessionDestroyed() {
        this.crUserService.timeout().finally(() => {
            this.crNavigationService.disableConfirmNavigation();
            const href = this.$state.href(this.$state.current.name, this.$state.params);
            this.$state.go('uac.login', {timeout: 1, to: href.substring(1)});
        });
    }

    onWindowMousemove() {
        const now = new Date();

        if (now - this.lastDebounce >= this.debounce) {
            this.socket.emit('activity');
            this.lastDebounce = now;
        }
    }

    onMenuToggle() {
        this.slim = !this.slim;

        this.layoutService.setSlim(this.slim);

        if (this.slim) {
            this.$rootScope.$broadcast(this.events.NAV_PANEL_CLOSE);
        } else {
            this.crAnalyticsService.track('Nav Panel Open');
            this.$rootScope.$broadcast(this.events.NAV_PANEL_OPEN);
        }
    }

    openChangePasswordModal() {
        this.$uibModal.open({
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            backdrop: 'static',
            component: 'crChangePasswordModal',
        });
    }

    openSetAuthorizationCodeModal() {
        this.$uibModal.open({
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            backdrop: 'static',
            component: 'crSetAuthCodeModal',
            windowClass: 'cr-modal-size-touch-md',
        });
    }

    signOut() {
        this.crAnalyticsService.track('Sign out');
        this.crUserService.logout().finally(() => {
            this.crNavigationService.disableConfirmNavigation();
            this.$state.go('uac.login');
        });
    }
}

export default ClientController;
