<div class="notification-container" *ngIf="open">
    <div class="icon icon-cr-info"></div>
    <div class="content">
        <div class="title">{{ title }}</div>
        <div class="message">{{ message }}</div>
    </div>
    <button (click)="close()" class="notification-close" *ngIf="allowClose">
        &times;
    </button>
</div>
