import {
  Component, EventEmitter, OnInit, ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LocalizedText } from '../../../../../core';
import { ErrorLoggingService, OrderService, SimpleModalComponent } from '../../../../../shared';
import { Order, OrderReceiptEmailRequest } from '../../../../../shared/models/order.model';
import text from '../resources/locale/en.json';

@Component({
  selector: 'cr-fnb-order-action-modal',
  templateUrl: 'order-actions-modal.component.html',
  styleUrls: ['order-actions-modal.component.scss'],
})

export class OrderActionsModalComponent implements OnInit {
  public closeEvent: EventEmitter<boolean> = new EventEmitter();

  public text: LocalizedText;

  public order: Order;

  public kitchenId: string;

  public emailControl: FormControl;

  public orderActionsForm: FormGroup;

  constructor(
    private orderService: OrderService,
    private modalService: BsModalService,
    private crErrorLogging: ErrorLoggingService,
  ) {
    this.text = text as LocalizedText;
  }

  public ngOnInit(): void {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    this.emailControl = new FormControl('', Validators.email);

    this.orderActionsForm = new FormGroup({
      emailControl: this.emailControl,
    });
  }

  public closeModal(): void {
    this.closeEvent.emit(true);
  }

  public sendEmailReceipt(alternateEmail?: string): void {
    const orderRequest: OrderReceiptEmailRequest = {
      orderId: this.order.orderId,
      alternateEmail,
      customerId: this.order.customerId,
      placeId: this.order.placeId,
      resendReason: '',
      templateOverride: '',
    };

    if (this.emailControl.valid) {
      this.orderService.sendRefundOrderReceiptEmail(orderRequest).pipe(catchError((err) => of(this.handleError('Email Notification Error', err)))).subscribe((res) => {
        this.closeModal();
      });
    }
  }

  private handleError(errMsg:string, err:Error): void {
    this.crErrorLogging.logError(errMsg, err);
    this.modalService.show(SimpleModalComponent, {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      initialState: { message: this.text.orderEmailNotificationErrorMessage },
      backdrop: 'static',
      class: 'cr-modal-size-sm',
    });
  }
}
