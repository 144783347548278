class AppUsageMockService {
    constructor() {
        this.timeframesAvailable = [
            ['Mar 1', 'Mar 7'],
            ['Mar 8', 'Mar 14'],
            ['Mar 15', 'Mar 21'],
            ['Mar 22', 'Mar 28'],
            ['Mar 29', 'Apr 3'],
            ['Apr 4', 'Apr 10'],
            ['Apr 10', 'Apr 16'],
            ['Apr 17', 'Apr 23'],
            ['Apr 24', 'Apr 30'],
            ['May 1', 'May 7'],
            ['May 8', 'May 14'],
            ['May 15', 'May 21'],
        ];

        this.screensAvailable = [
            'Home',
            'Offers',
            'Sign in',
            'Store locator',
            'Product',
            'Cart',
            'Payment',
            'Payment confirm',
            'Store detail',
            'Sign out',
        ];
    }

    getActiveUsers() {
        return Promise.resolve({
            labels: this.timeframesAvailable.map((timeframe) => timeframe[1]),
            series: [
                this.timeframesAvailable.map((timeframe) => ({
                    value: Math.floor(Math.random() * 140000) + 60000,
                    meta: `${timeframe[0]} - ${timeframe[1]}`,
                })),
            ],
        });
    }

    getTopViewed() {
        let start = Math.floor(Math.random() * 500) + 5000;
        return Promise.resolve({
            labels: this.screensAvailable,
            series: [
                this.screensAvailable.map(() => {
                    start -= Math.floor(Math.random() * 250);
                    return start;
                }),
            ],
        });
    }

    getDownloads() {
        return Promise.resolve({
            labels: this.timeframesAvailable.map((timeframe) => timeframe[1]),
            series: [
                this.timeframesAvailable.map((timeframe) => ({
                    value: Math.floor(Math.random() * 300) + 200,
                    meta: `${timeframe[0]} - ${timeframe[1]}`,
                })),
            ],
        });
    }

    getCrashes() {
        return Promise.resolve({
            labels: this.timeframesAvailable.map((timeframe) => timeframe[1]),
            series: [
                this.timeframesAvailable.map((timeframe) => ({
                    value: Math.floor(Math.random() * 100) + 100,
                    meta: `${timeframe[0]} - ${timeframe[1]}`,
                })),
            ],
        });
    }
}

export default AppUsageMockService;
