<section class="toast-container">
    <div
        class="toasts"
        @insertRemoveTrigger
        *ngFor="let toast of toasts"
        ng-repeat="toast in $ctrl.toasts"
    >
        <div
            class="toast"
            [ngClass]="toast.customClass"
            [innerHtml]="toast.msg"
        ></div>
    </div>
</section>
