import angular from 'angular';

import component from './alert.component';
import service from './alert.service';

const Alert = angular
    .module('control-room.commons.basic.components.alert', [])
    .component('crAlert', component)
    .service('crAlertService', ['$rootScope', 'crConstants', service]);

export default Alert;
