import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ExtendedPositionDto, LatLon, MapPositionDto, VenueMapDto } from "../../models/graphical-map.model";
import { VenueService } from "../venue/venue.service";


@Injectable({
    providedIn: 'root',
  })
export class GraphicalMapService {
    constructor(private http: HttpClient, private venueService: VenueService) {}

    public getXYFromLatLon(venueId: string, mapId: string, location: { lat: number; lon: number }): Promise<ExtendedPositionDto> {
        return this.http.get<ExtendedPositionDto>(`/rest/graphical-map/${venueId}/${mapId}/xy/${location.lat},${location.lon}`).toPromise();
    }

    public getLatLonFromXY(venueId: string, mapId: string, position: { x: number; y: number }): Promise<LatLon> {
        return this.http.get<LatLon>(`/rest/graphical-map/${venueId}/${mapId}/latlon/${position.x},${position.y}`).toPromise();
    }

    public attributeOptimizedMapPositions(venueId: string, mapId: string, mapPositions: (MapPositionDto & { translatedPosition: { x: number, y: number }})[]): Promise<string> {
        let mapImageSrc: string;
        return this.getByVenueAndMapId(venueId, mapId)
            .then((res) => {
                mapImageSrc = res.image.src;

                return new Promise<HTMLImageElement>((resolve, reject) => {
                    const image = new Image();
                    image.src = mapImageSrc;
                    image.onload = () => resolve(image);
                    image.onerror = (err) => reject(err);
                });
            })
            .then((image) => {
                mapPositions.forEach((mapPosition) => {
                    mapPosition.translatedPosition = {
                        x: mapPosition.position.x * image.width,
                        y: mapPosition.position.y * image.height,
                    };
                });

                return mapImageSrc;
            });
    }

    public getMapMarkerData(venueId: string, locations: ({ lat: number, lon: number })[]): Promise<{
        mapImageSrc?: string;
        defaultMapId?: string;
        translatedLocations?: ExtendedPositionDto[];
    }> {
        const result: {
            mapImageSrc?: string;
            defaultMapId?: string;
            translatedLocations?: ExtendedPositionDto[];
        } = {};

        return this.venueService.getById(venueId).then((res) => {
            result.defaultMapId = res.defaultMapId;

            const mapImagePromise = this.getByVenueAndMapId(venueId, result.defaultMapId).then((response) => {
                result.mapImageSrc = response.image.src;

                return new Promise<HTMLImageElement>((resolve, reject) => {
                    const image = new Image();
                    image.src = result.mapImageSrc;
                    image.onload = () => resolve(image);
                    image.onerror = (err) => reject(err);
                });
            });

            const promises: Promise<ExtendedPositionDto | HTMLImageElement>[] = locations.map((location) =>
                this.getXYFromLatLon(venueId, result.defaultMapId, location).then((response) => response)
            );

            promises.push(mapImagePromise);
            
            return Promise.all(promises).then((results) => {
                const image = results.filter((p) => p instanceof HTMLImageElement)[0] as HTMLImageElement;

                results.filter((r) => r instanceof HTMLImageElement === false).forEach((location: ExtendedPositionDto) => {
                    location.position.x = image.width * location.position.x;
                    location.position.y = image.height * location.position.y;
                });

                result.translatedLocations = results.filter((r) => r instanceof HTMLImageElement === false) as ExtendedPositionDto[];

                return result;
            });
        });
    }

    private getByVenueAndMapId(venueId: string, mapId: string): Promise<VenueMapDto> {
        return this.http.get<VenueMapDto>(`/rest/graphical-map/${venueId}/${mapId}`).toPromise();
    }
}
