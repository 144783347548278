import text from './resources/locale/en.json';

class EntityCategoriesController {
    constructor(
        $state,
        crEntityCategoriesService,
        crErrorLoggingService,
        crToastService,
        crImageService,
        crNavigationService,
        crPolicyService
    ) {
        this.$state = $state;
        this.crEntityCategoriesService = crEntityCategoriesService;
        this.crErrorLoggingService = crErrorLoggingService;
        this.crToastService = crToastService;
        this.crImageService = crImageService;
        this.crNavigationService = crNavigationService;
        this.crPolicyService = crPolicyService;
    }

    $onInit() {
        this.text = text;
        this.titleBarLabel = this.crEntityCategoriesService.getLocalizedName(this.entityType);
        this.drag = false;
        this.isLoading = true;
        this.panes = [];
        this.parentRoute = this.$state.$current.parent.self.name;
        this.readOnlyCategories = true;

        const { menuId } = this.$state.params;
        const entityRoute = menuId ? `menus/${menuId}/${this.entityType}` : null;

        this.crEntityCategoriesService
            .getParentCategoriesHierarchy(this.$state.params.venueId, this.entityType, null, entityRoute)
            .then((hierarchy) => {
                this.panes = hierarchy;
                this.crImageService.setIconSrc(this.panes);
                this.categories = hierarchy.map((cat) => ({ iconSrc: cat.iconSrc, label: cat.label }));
            })
            .catch((err) => {
                this.crErrorLoggingService.logError(`Could not get ${this.entityType}`, err, {
                    venueId: this.$state.params.venueId,
                });

                this.hasDataError = true;
            })
            .finally(() => {
                this.isLoading = false;
            });

        this.crToastService.autoToast(this.$state.params);
        this.hasWriteAccess().then((access) => {
            this.readOnlyCategories = !access;
        });
    }

    gotoCreatePage(topLevel) {
        if (topLevel) {
            this.$state.go(`${this.parentRoute}.category-create`, { menuId: this.$state.params.menuId });
        } else {
            this.$state.go(`${this.parentRoute}.category-create-subcategory`);
        }
    }

    gotoCategoriesEdit() {
        this.$state.go(`${this.parentRoute}.categories-edit`, { menuId: this.$state.params.menuId });
    }

    hasWriteAccess() {
        return this.crPolicyService.hasAccess(this.requiredWritePermission);
    }

    gotoEditCategoryPage(category) {
        this.hasWriteAccess().then((access) => {
            if (!access) {
                return;
            }

            this.$state.go(`${this.parentRoute}.category-edit`, {
                category,
                id: category.id,
                menuId: this.$state.params.menuId,
            });
        });
    }

    goBack() {
        this.crNavigationService.goBack(`${this.parentRoute}.list`);
    }
}

export default EntityCategoriesController;
