import _ from 'lodash';
import text from './resources/locale/en.json';

class ExperienceTestMessage {
    constructor(
        $state,
        crAnalyticsService,
        crConfig,
        crTestMessagesService,
        crLocaleService,
        crToastService,
        crUserService,
        crPolicyService,
        crErrorLoggingService
    ) {
        this.$state = $state;
        this.crAnalyticsService = crAnalyticsService;
        this.crConfig = crConfig;
        this.crTestMessagesService = crTestMessagesService;
        this.crLocaleService = crLocaleService;
        this.crToastService = crToastService;
        this.crUserService = crUserService;
        this.crPolicyService = crPolicyService;
        this.crErrorLoggingService = crErrorLoggingService;

        this.text = text;
    }

    $onInit() {
        this.locale = this.crLocaleService.getLocale(this.$state.params.venueId, this.$state.params.locale);
        this.selectedUser = {};
        this.loggedInUser = {};
        this.usersList = [];
        if (this.crConfig.customer.testMessageBlacklist) {
            this.emailBlacklistFilter = new RegExp(this.crConfig.customer.testMessageBlacklist);
        }
        this.isUsersListDisabled = true;
        this.refreshUsers();
        this.refreshLocaleStatus();
    }

    onLocaleUpdate(event) {
        this.locale.current = event.model[0].id;
        this.crAnalyticsService.track('Language Selected', { language: event.model[0].label });
    }

    onUserUpdate(event) {
        if (event.model && event.model.length) {
            this.selectedUser.id = event.model[0].id;
        }
    }

    emailFilter(otherUser) {
        if (!otherUser.id) {
            return false;
        }
        if (otherUser.id === this.loggedInUser.email) {
            return false;
        }
        if (this.emailBlacklistFilter && otherUser.id.match(this.emailBlacklistFilter)) {
            return false;
        }
        return true;
    }

    refreshUsers() {
        this.crUserService
            .getUser()
            .then((user) => {
                this.loggedInUser = user;
                if (!this.selectedUser.id) {
                    this.selectedUser = {
                        id: user.email,
                    };
                }
                return this.crUserService.getAllUsers();
            })
            .then((allUsers) => {
                const users = [];
                if (this.loggedInUser) {
                    users.push({
                        id: this.loggedInUser.email,
                        label: this.loggedInUser.email,
                        isSelected: false,
                    });
                }
                const otherUsers = _.uniqBy(allUsers, (u) => u.email)
                    .map((u) => ({
                        id: u.email,
                        label: u.email,
                        isSelected: false,
                    }))
                    .filter((otherUser) => this.emailFilter(otherUser));
                otherUsers.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
                users.push(...otherUsers);
                if (users.length) {
                    users[0].isSelected = true;
                }
                this.usersList = users;
            })
            .catch((err) => {
                if (this.loggedInUser) {
                    this.usersList = [
                        {
                            id: this.loggedInUser.email,
                            label: this.loggedInUser.email,
                            isSelected: true,
                        },
                    ];
                }
                this.crErrorLoggingService.logError('could not retrieve test message users', err);
            })
            .then(() => this.crPolicyService.hasAccess('write:exp'))
            .then((allowed) => {
                this.isUsersListDisabled = !allowed;
                if (!allowed && this.usersList && this.usersList.length) {
                    this.usersList = this.usersList.slice(0, 1);
                }
            });
    }

    refreshLocaleStatus() {
        if (this.experience) {
            this.locale.list = this.locale.list.filter((locale) => {
                let warning = false;
                const { content } = this.experience;

                if (!content.title[locale.id] || !content.body[locale.id]) {
                    warning = true;
                }

                if (this.experience.content.push && !this.experience.content.push[locale.id]) {
                    warning = true;
                }

                const invalidLink = this.experience.content.links.some(
                    (link) =>
                        !link.type ||
                        !link.label[locale.id] ||
                        (!_.isEmpty(link.link) && !_.get(link, `link.${locale.id}`))
                );

                if (invalidLink) {
                    warning = true;
                }

                return !warning;
            });
        }
    }

    sendMessage() {
        const content = _.cloneDeep(this.experience.content);
        if (content.coupons) {
            const links = content.links || [];
            content.links = links.concat(content.coupons);
        }
        const testMessagesRequest = {
            accountId: this.selectedUser.id,
            content,
            language: this.locale.current,
            venueId: this.$state.params.venueId,
        };
        this.error = null;
        this.crTestMessagesService
            .sendTestMessages(testMessagesRequest)
            .then((res) => {
                if (res.status === 200) {
                    this.crToastService.toast(this.text.successToast);
                }
            })
            .catch((err) => {
                const type = _.get(err, 'data.errors.0.type');
                if (this.text[type]) {
                    this.error = type;
                } else {
                    this.error = 'GENERAL_ERROR';
                }
            });
    }

    sendMessageIsDisabled() {
        return !this.selectedUser.id;
    }
}

export default ExperienceTestMessage;
