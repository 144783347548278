<section class="idDetail-content-preview">
  <section class="idDetail-content-container">
    <div *ngIf="isLoading" top>
      <div class="text-center cr-page-padding-top">
        <cr-spinner></cr-spinner>
      </div>
    </div>
    <section class="icon-container"></section>
    <div class="idDetail-label-header">
      <span class="title">
        {{ idDetailLabel }}
      </span>
      <span
        class="icon-cr-edit cursor-pointer edit-icon"
        data-local-qa-id="edit-idDetail-web-content"
        (click)="goToEditPage()"
      ></span>
    </div>
    <hr class="" />
    <div class="secondary-header">
      <div class="text-label">{{ text.aboutidDetailMessaging }}</div>
    </div>
    <div class="attribute">
      <div class="text-label">{{ text.title }}</div>
      <div>{{ phonePreview?.idDetailTitle }}</div>
    </div>
    <div class="attribute">
      <div class="text-label">{{ text.description }}</div>
      <div
        class="idDetail-description"
        [innerHtml]="phonePreview?.idDetailDescription"
        *ngIf="phonePreview?.idDetailDescription"
      ></div>
    </div>
  </section>
  <section class="idDetail-mobile-preview">
    <cr-phone>
      <cr-id-detail-content-phone-preview [featuredEvents]="phonePreview">
      </cr-id-detail-content-phone-preview>
    </cr-phone>
  </section>
</section>
