import Controller from './manage-attribute.controller';
import template from './manage-attribute.html';
import './manage-attribute.less';

const Component = {
    template,
    bindings: {
        entityType: '@',
        attributeType: '@',
    },
    controller: [
        '$q',
        '$state',
        '$uibModal',
        'crLocaleService',
        'crEntityService',
        'crNavigationService',
        'crErrorLoggingService',
        'crImageService',
        'crConstants',
        'crConfig',
        'crAnalyticsService',
        Controller,
    ],
};

export default Component;
