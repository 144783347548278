import angular from 'angular';
import { downgradeComponent } from '@angular/upgrade/static';
import CategoryArchiveModalModule from './category-archive-modal/category-archive-modal';
import CreateKitchenModalModule from './create-kitchen-modal/create-kitchen-modal';
import CreateMenuModalModule from './create-menu-modal/create-menu-modal';
import FnbArchiveModalModule from './fnb-archive-modal/fnb-archive-modal';
import FnbReporting from './fnb-reporting/fnb-reporting';
import KitchenDetailsModule from './kitchen-details/kitchen-details';
import KitchenManageModule from './kitchen-manage/kitchen-manage';
import KitchensListModule from './kitchens-list/kitchens-list';
import { KitchenMenusComponent } from './kitchen-menus/kitchen-menus.component';
import MenuCategoriesModule from './menu-categories/menu-categories';
import MenuCategoryManageModule from './menu-category-manage/menu-category-manage';
import MenuDetailsModule from './menu-details/menu-details';
import MenuManageModule from './menu-manage/menu-manage';
import MenusListModule from './menus-list/menus-list';
import MenusTabsModule from './menus-tabs/menus-tabs';
import ModifierGroupsListModule from './modifier-groups-list/modifier-groups-list';
import ModifierGroupsManageModule from './modifier-groups-manage/modifier-groups-manage';
import PdfPreviewModal from './pdf-preview-modal/pdf-preview-modal';
import ProductsDetailsModule from './product-details/product-details';
import ProductsListModule from './products-list/products-list';
import ProductsManageModule from './products-manage/products-manage';
import MobileOrdering from './kitchen-manage/components/mobile-ordering';


const Components = angular.module('control-room.menus.components', [
    MenusListModule.name,
    ProductsListModule.name,
    ProductsDetailsModule.name,
    ProductsManageModule.name,
    ModifierGroupsListModule.name,
    ModifierGroupsManageModule.name,
    MenusTabsModule.name,
    FnbReporting.name,
    MenuDetailsModule.name,
    MenuCategoriesModule.name,
    MenuCategoryManageModule.name,
    MenuManageModule.name,
    KitchensListModule.name,
    KitchenManageModule.name,
    KitchenDetailsModule.name,
    CreateMenuModalModule.name,
    CreateKitchenModalModule.name,
    FnbArchiveModalModule.name,
    CategoryArchiveModalModule.name,
    PdfPreviewModal.name,
    MobileOrdering.name
])
.directive('crKitchenMenus', downgradeComponent({component: KitchenMenusComponent}));

export default Components;
