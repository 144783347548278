<div class="btn-group dropdown" [class.open]="isOpen">
    <button
        class="btn btn-primary cr-button split-btn-save"
        data-global-qa-id="split-button-save"
        (click)="handleButtonClick()">
        {{ label }}
    </button>
    <button
        class="btn btn-primary cr-button split-btn dropdown-toggle"
        (click)="toggle()"
        data-global-qa-id="split-button">
        <span class="caret"></span>
    </button>
    <ul class="dropdown-menu-right dropdown-menu">
        <ng-content></ng-content>
    </ul>
</div>
