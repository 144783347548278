import _ from 'lodash';
import text from '.././resources/locale/en.json';
import bulletTrain from 'bullet-train-client';

class MobileOrdering {
    constructor($sce, $state, $timeout, crConstants, $uibModal) {
        this.$sce = $sce;
        this.$state = $state;
        this.$timeout = $timeout;
        this.crConstants = crConstants;
        this.$uibModal = $uibModal;
        this.text = text
        this.allDays = this.crConstants.iCal.weekdays;
    }

    $onInit() {
        this.initOptions();
        this.initValidDays();
        this.mapDropdownSelections();
        this.identifySameDaySchedule();
        this.initValidations();
        this.isPtwFeatureFlagEnabled = bulletTrain.hasFeature('fnb_ptw');
        this.populateTimeSelectDropdown();
    }

    initOptions() {
        this.earlyCheckinOptions = [
            {
                type: this.text.zeroMinutes,
                label: this.text.zeroMinutes,
                id: 0,
                isSelected: false
            },
            {
                type: this.text.fiveMinutes,
                label: this.text.fiveMinutes,
                id: 5,
                isSelected: false
            },
            {
                type: this.text.tenMinutes,
                label: this.text.tenMinutes,
                id: 10,
                isSelected: false
            },
            {
                type: this.text.fifteenMinutes,
                label: this.text.fifteenMinutes,
                id: 15,
                isSelected: false
            }
        ];
        this.lateCheckinOptions = [
            {
                type: this.text.zeroMinutes,
                label: this.text.zeroMinutes,
                id: 0,
                isSelected: false
            },
            {
                type: this.text.fiveMinutes,
                label: this.text.fiveMinutes,
                id: 5,
                isSelected: false
            },
            {
                type: this.text.tenMinutes,
                label: this.text.tenMinutes,
                id: 10,
                isSelected: false
            },
            {
                type: this.text.fifteenMinutes,
                label: this.text.fifteenMinutes,
                id: 15,
                isSelected: false
            }
        ];
        this.earlyOrderingOptions = [
            {
                type: this.text.zeroMinutes,
                label: this.text.zeroMinutes,
                id: 0,
                isSelected: false
            },
            {
                type: this.text.thirtyMinutes,
                label: this.text.thirtyMinutes,
                id: 30,
                isSelected: false
            },
            {
                type: this.text.SixtyMinutes,
                label: this.text.SixtyMinutes,
                id: 60,
                isSelected: false
            },
            {
                type: this.text.NinetyMinutes,
                label: this.text.NinetyMinutes,
                id: 90,
                isSelected: false
            },
            {
                type: this.text.OneTwentyMinutes,
                label: this.text.OneTwentyMinutes,
                id: 120,
                isSelected: false
            },
            {
                type: this.text.OneFiftyMinutes,
                label: this.text.OneFiftyMinutes,
                id: 150,
                isSelected: false
            },
            {
                type: this.text.OneEightyMinutes,
                label: this.text.OneEightyMinutes,
                id: 180,
                isSelected: false
            }
        ];
        this.windowSizeOptions = [
            {
                type: this.text.fifteenMinutes,
                label: this.text.fifteenMinutes,
                id: 15,
                isSelected: false
            },
            {
                type: this.text.thirtyMinutes,
                label: this.text.thirtyMinutes,
                id: 30,
                isSelected: false
            }
        ];
        this.pickupTimeEnabledOptions = [
            {
                label: this.text.enablePickUpWindowLabel,
                name: 'pickupTimeEnabled',
                checked: !!this.kitchen.features.pickupTimeWindowsEnabled,
            },
        ];
    }

    initValidations() {
        this.kitchen.hours.forEach((h, scheduleIndex) => {
            this.validateSchedule(scheduleIndex);
            if(h.duration) {
                h.duration.forEach((d, durationIndex) => {
                    this.validateWindowProperties(scheduleIndex, durationIndex);
                });
            }
        });
    }

    mapDropdownSelections() {
        this.kitchen.hours.forEach(h => {
            if(h.duration) {
                h.duration.forEach(d => {
                    d.earlyOrderingOptions = this.selectValue(d.earlyOrdering, _.cloneDeep(this.earlyOrderingOptions), 'id');
                    d.earlyCheckinOptions = this.selectValue(d.earlyCheckin, _.cloneDeep(this.earlyCheckinOptions), 'id');
                    d.lateCheckinOptions = this.selectValue(d.lateCheckin, _.cloneDeep(this.lateCheckinOptions), 'id');
                    if(d.pickupWindowProperties && d.pickupWindowProperties.length>0) {
                        d.pickupWindowProperties.forEach(ptw=> {
                            ptw.windowOptions = this.selectValue(ptw.length, _.cloneDeep(this.windowSizeOptions),'id');
                        });
                    }
                });
            }
        });
    }



    selectValue(value, array, key) {
        if (!_.isNull(value)) {
            let obj = _.find(array, [key, value])
            if(obj) {
                _.find(array, [key, value]).isSelected = true;
            }
        }
        return array;
    }

    
    identifySameDaySchedule() {
        if(this.pickupTimeWindowEnabled) {
            const d = new Date();
            const currentDayIndex = d.getDay();
            this.currentDay = this.allDays[currentDayIndex];
            this.kitchen.hours.forEach((h, scheduleIndex)=> {
                const foundIndex = h.days.findIndex(d=> d===this.currentDay);
                if(h.duration) {
                    const isPtwConfigured = h.duration.some(d =>  d.pickupWindowProperties.length > 0);
                    h.isSameDaySchedule = (foundIndex > -1 && isPtwConfigured) ? true : false;
                    if(foundIndex > -1 && isPtwConfigured) {
                        this.sameDayScheduleIndex = scheduleIndex;
                    }
                }
            });
        }
    }

    validateTimeRanges(scheduleIndex, controlName) {
        const duration = this.kitchen.hours[scheduleIndex].duration;
        const prevDuration = {
            openingTime: 0,
            closingTime: 0
        }
        duration.forEach((currentDuration, durationIndex) => {
            if( currentDuration.closingTime === '' || this.convertDate2Num(currentDuration.openingTime) < this.convertDate2Num(currentDuration.closingTime)) {
                if(this.convertDate2Num(currentDuration.openingTime) >= this.convertDate2Num(prevDuration.closingTime)) {
                    const prevPtw = {
                        startTime: 0,
                        endTime: 0
                    }
                    currentDuration.pickupWindowProperties.forEach((currPtw, ptwIndex) => {
                        if(currPtw.startTime!== "") {
                            if((this.convertDate2Num(currPtw.startTime) < this.convertDate2Num(currentDuration.openingTime)) ||
                            (this.convertDate2Num(currPtw.startTime) > this.convertDate2Num(currentDuration.closingTime)) ||
                            (this.convertDate2Num(currPtw.startTime) < this.convertDate2Num(prevPtw.endTime))) {
                                return this.setptwTimeValidity(scheduleIndex,durationIndex, ptwIndex,'startTime', false);
                            } else {
                                this.setptwTimeValidity(scheduleIndex,durationIndex, ptwIndex,'startTime', true);
                            }
                        }

                        if(currPtw.endTime!== "") {
                            if((this.convertDate2Num(currPtw.endTime) < this.convertDate2Num(currentDuration.openingTime)) ||
                            (this.convertDate2Num(currPtw.endTime) > this.convertDate2Num(currentDuration.closingTime))) {
                                return this.setptwTimeValidity(scheduleIndex,durationIndex,ptwIndex,'endTime', false);
                            } else {
                                this.setptwTimeValidity(scheduleIndex,durationIndex, ptwIndex,'endTime', true);
                            }
                            if(currPtw.endTime!== "" && currPtw.endTime!== "" &&
                                this.convertDate2Num(currPtw.startTime) > this.convertDate2Num(currPtw.endTime)) {
                                this.setptwTimeValidity(scheduleIndex,durationIndex, ptwIndex,controlName, false);
                            }
                        }


                        prevPtw.startTime = currPtw.startTime;
                        prevPtw.endTime = currPtw.endTime;
                    });
                } else {
                    this.setOrderingHoursValidity(scheduleIndex, durationIndex, controlName, false)
                }
            }
            else {
                this.setOrderingHoursValidity(scheduleIndex, durationIndex, controlName, false)
            }

            prevDuration.openingTime = currentDuration.openingTime;
            prevDuration.closingTime = currentDuration.closingTime;
        });

    }

    setptwTimeValidity(scheduleIndex, durationIndex, ptwIndex, controlName, val) {
        const formControl = this.form[`${scheduleIndex}-${controlName}-${durationIndex}-${ptwIndex}`];
        if (formControl) {
            formControl.$setTouched();
            formControl.$setValidity('time', val);
        }
    }

    setOrderingHoursValidity(scheduleIndex, durationIndex, controlName, val) {
        const formControl = this.form[`${scheduleIndex}-${controlName}-${durationIndex}`];
        if (formControl) {
            formControl.$setTouched();
            formControl.$setValidity('time', val);
        }
    }

    convertDate2Num(date) {
        if(!date) {
            return 0
        } else {
            return parseInt(date.split(':').join(''))
        }
    }


    onEarlyCheckinSelect($event, scheduleIndex, durationIndex) {
        this.kitchen.hours[scheduleIndex].duration[durationIndex].earlyCheckin = $event.model[0].id;
        this.validateSchedule(scheduleIndex);
        this.trackSameDayChanges(scheduleIndex);
    }

    onLateCheckinSelect($event, scheduleIndex, durationIndex) {
        this.kitchen.hours[scheduleIndex].duration[durationIndex].lateCheckin = $event.model[0].id;
        this.validateSchedule(scheduleIndex);
        this.trackSameDayChanges(scheduleIndex);
    }

    onEarlyOrderingSelect($event, scheduleIndex, durationIndex) {
        this.kitchen.hours[scheduleIndex].duration[durationIndex].earlyOrdering = $event.model[0].id;
        this.validateSchedule(scheduleIndex);
        this.trackSameDayChanges(scheduleIndex);
    }

    onWindowCapacityUpdate($event, window, windowIndex) {
        window.capacity = $event.model;
    }

    onWindowSizeUpdate($event, window, windowIndex) {
        window.length = $event.model[0].id;
    }

    onWindowStartTimeUpdate($event, window, windowIndex) {
        window.startTime = $event.model;
    }

    onWindowEndTimeUpdate($event, window, windowIndex) {
        window.endTime = $event.model;
    }

    onWindowPropertyUpdate($event, scheduleIndex, durationIndex, windowIndex, property) {
        if($event && $event.model && _.isArray($event.model)) {
            this.kitchen.hours[scheduleIndex].
            duration[durationIndex].pickupWindowProperties[windowIndex][property] = $event.model[0].id;
        } else {
            this.kitchen.hours[scheduleIndex].
            duration[durationIndex].pickupWindowProperties[windowIndex][property] = $event.model;
        }
        if(property === 'startTime' || property ==='endTime') {
            this.validateTimeRanges(scheduleIndex,property);
            this.populateTimeSelectDropdown();
        }
        this.validateWindowProperties(scheduleIndex, durationIndex);
        this.trackSameDayChanges(scheduleIndex);
    }

    onPickupWindowEnabledUpdate(event) {
        this.pickupTimeWindowEnabled = event.model.pickupTimeEnabled;
        this.kitchen.features.pickupTimeWindowsEnabled = event.model.pickupTimeEnabled;
        this.form.$setDirty();
        this.updateContentOptions();
    }

    isWindowAvailable(scheduleIndex, durationIndex) {
        let isWindowAvailable = false;
        if(this.kitchen.hours[scheduleIndex].duration) {
            const currentDurationClosingTime = this.kitchen.hours[scheduleIndex].duration[durationIndex].closingTime;
            const lastPickUpWindowEndTime = _.last(this.kitchen.hours[scheduleIndex].duration[durationIndex].pickupWindowProperties).endTime;
            isWindowAvailable = (currentDurationClosingTime === lastPickUpWindowEndTime) ? false : true;
        }
        return isWindowAvailable;
    }

    validateWindowProperties(scheduleIndex, durationIndex) {
        this.$timeout(() => {
            this.kitchen.hours[scheduleIndex].duration[durationIndex].validptw = this.validpickupWindowProperties(scheduleIndex, durationIndex) && this.isWindowAvailable(scheduleIndex, durationIndex);
            this.validateSchedule(scheduleIndex);
        });
    }

    validateSchedule(scheduleIndex) {
        this.$timeout(() => {
            this.kitchen.hours[scheduleIndex].validSchedule = this.hoursValid(scheduleIndex);
        });
    }

     hoursValid(scheduleIndex) {
            const i = scheduleIndex;

            const daysControl = this.form[`${i}-days`];
            if (daysControl && !daysControl.$valid) {
                return false;
            }

            if(!this.kitchen.hours[i].duration) {
                return false;
            }

            for(let j =0; j < this.kitchen.hours[i].duration.length; j++) {

                const openControl = this.form[`${i}-open-${j}`];
                if (openControl && !openControl.$valid) {
                    return false;
                }

                const closeControl = this.form[`${i}-close-${j}`];
                if (closeControl && !closeControl.$valid) {
                    return false;
                }

                const earlyOrdering = this.form[`${i}-earlyOrdering-${j}`];
                if (earlyOrdering && !earlyOrdering.$valid) {
                    return false;
                }

                const earlyCheckin = this.form[`${i}-earlyCheckin-${j}`];
                if (earlyCheckin && !earlyCheckin.$valid && this.checkInTimeWindowEnabled) {
                    return false;
                }

                const lateCheckin = this.form[`${i}-lateCheckin-${j}`];
                if (lateCheckin && !lateCheckin.$valid  && this.checkInTimeWindowEnabled) {
                    return false;
                }
            }

            for(let durationIndex = 0; durationIndex < this.kitchen.hours[scheduleIndex].duration.length; durationIndex++) {
                if(!this.validpickupWindowProperties(scheduleIndex, durationIndex)){
                    return false
                }
            }

        return true;
    }

    validSchedule() {
       for (let scheduleIndex = 0; scheduleIndex < this.kitchen.hours.length; scheduleIndex++) {
            //check PTW properties only when hours are valid
            if(!this.hoursValid(scheduleIndex)) {
                return false;
            }
            else{
                for(let durationIndex = 0; durationIndex < this.kitchen.hours[scheduleIndex].duration.length; durationIndex++) {
                    if(!this.validpickupWindowProperties(scheduleIndex, durationIndex)){
                        return false
                    }
                }
            }
       }
       return true;
    }

    validpickupWindowProperties(scheduleIndex, durationIndex) {

            for(let ptwIndex =0; ptwIndex < this.kitchen.hours[scheduleIndex].duration[durationIndex].pickupWindowProperties.length; ptwIndex++) {

                const startTimeControl = this.form[`${scheduleIndex}-startTime-${durationIndex}-${ptwIndex}`];
                if (startTimeControl && !startTimeControl.$valid) {
                    return false;
                }

                const endTimeControl = this.form[`${scheduleIndex}-endTime-${durationIndex}-${ptwIndex}`];
                if (endTimeControl && !endTimeControl.$valid) {
                    return false;
                }

                const lengthControl = this.form[`${scheduleIndex}-length-${durationIndex}-${ptwIndex}`];
                if (lengthControl && !lengthControl.$valid) {
                    return false;
                }

                const capacityControl = this.form[`${scheduleIndex}-capacity-${durationIndex}-${ptwIndex}`];
                if (capacityControl && !capacityControl.$valid) {
                    return false;
                }
            }

        return true;
    }

    addPickUpTimeWindow(pickupWindowProperties, scheduleIndex , durationIndex) {
        const newWindowOptions = this.windowSizeOptions.map(w => {
            return {...w, isSelected: false}
        });
        const range = this.getPrepopulatedPickUpTimeWindowRange(pickupWindowProperties, scheduleIndex , durationIndex);
        pickupWindowProperties.push(
            { startTime: range.start,
              endTime: range.end,
              capacity: 1,
              length: 0,
              windowOptions: newWindowOptions
        });
        this.populateTimeSelectDropdown();
        this.validateWindowProperties(scheduleIndex,durationIndex);
    }

    getPrepopulatedPickUpTimeWindowRange(pickupWindowProperties, scheduleIndex, durationIndex) {
        const currentOrderingHoursStartTime= this.kitchen.hours[scheduleIndex].duration[durationIndex].openingTime;
        const currentOrderingHoursEndTime= this.kitchen.hours[scheduleIndex].duration[durationIndex].closingTime;
        const range = {
            start: currentOrderingHoursStartTime,
            end: currentOrderingHoursEndTime
        }
        if(pickupWindowProperties && pickupWindowProperties.length > 0) {
            const prevPtwEndTime = pickupWindowProperties[pickupWindowProperties.length-1].endTime;
            range.start = prevPtwEndTime;
        }
        return range;
    }

    calculateStartTimeLimit(hour) {
        let startOfDay = 0.25;
        if(hour && hour!=='') {
            hour = hour.split(':').slice(0,-1);
            const h = Number(hour[0]);
            const minute = Number(hour[1]);
            if(minute === 15) return h + 0.25;
            if(minute === 30) return h + 0.50;
            if(minute === 45) return h + 0.75;
            if(minute === 0) return h;

        } else {
            return startOfDay;
        }

    }

    calculateEndTimeLimit(hour) {
        let endOfDay = 24;
        if(hour && hour!=='') {
            hour = hour.split(':').slice(0,-1);
            const h = Number(hour[0]);
            const minute = Number(hour[1]);
            if(minute === 15) return h + 0.25;
            if(minute === 30) return h + 0.50;
            if(minute === 45) return h + 0.75;
            if(minute === 0) return h;

        } else {
            return endOfDay;
        }
    }

    calculatePtwOptions(scheduleIndex, durationIndex, pickUpWindowIndex, calculationType) {
        //pick the hour limit from ordering hours
        if(pickUpWindowIndex===0) {
            if(calculationType==='startTime') {
                const currentOrderingHoursOpenTime = this.kitchen.hours[scheduleIndex].duration[durationIndex].openingTime;
               return this.calculateStartTimeLimit(currentOrderingHoursOpenTime);
            } else {
                const currentOrderingHoursCloseTime = this.kitchen.hours[scheduleIndex].duration[durationIndex].closingTime;
               return this.calculateEndTimeLimit(currentOrderingHoursCloseTime);
            }
        } else {
            //pick hour limit from prev ptw
            if(calculationType==='startTime') {
                const prevPtwEndTime = this.kitchen.hours[scheduleIndex].duration[durationIndex]
                                        .pickupWindowProperties[pickUpWindowIndex-1].endTime;
                return this.calculateStartTimeLimit(prevPtwEndTime);
            } else {
               const currentOrderingHoursCloseTime = this.kitchen.hours[scheduleIndex].duration[durationIndex].closingTime;
               return this.calculateEndTimeLimit(currentOrderingHoursCloseTime);
            }
        }
    }

    populateTimeSelectDropdown() {
        this.kitchen.hours.forEach((h, scheduleIndex) => {
            if(h.duration) {
                h.duration.forEach((d, durationIndex) => {
                    d.startsAtOptions = {
                        start: this.calculateOrderingHoursOptions(scheduleIndex, durationIndex, 'startTime'),
                        end: this.calculateOrderingHoursOptions(scheduleIndex, durationIndex, 'endTime')
                    };
                    d.endsAtOptions = {
                        start: d.startsAtOptions.start,
                        end: d.startsAtOptions.end
                    };
                    if(d.openingTime !== "") {
                        d.endsAtOptions.start = this.calculateStartTimeLimit(d.openingTime);
                    }
                    if(d.pickupWindowProperties && d.pickupWindowProperties.length>0) {
                        d.pickupWindowProperties.forEach((ptw, pickUpWindowIndex)=> {
                            ptw.startsAtOptions = {
                                start: this.calculatePtwOptions(scheduleIndex, durationIndex, pickUpWindowIndex, 'startTime'),
                                end: this.calculatePtwOptions(scheduleIndex, durationIndex, pickUpWindowIndex, 'endTime')
                            };
                            ptw.endsAtOptions = {
                                start: ptw.startsAtOptions.start,
                                end: ptw.startsAtOptions.end
                            };
                            if(ptw.startTime !== "") {
                                ptw.endsAtOptions.start = this.calculateStartTimeLimit(ptw.startTime);
                            }
                        });
                    }
                });
            }
        });
    }

    calculateOrderingHoursOptions(scheduleIndex, durationIndex, calculationType) {
        const startOfDay = 0.25, endOfDay = 24;
        if(durationIndex===0) {
            return calculationType==='startTime'? startOfDay : endOfDay;
        } else {
            if(calculationType==='startTime') {
                const prevDurationEndTime = this.kitchen.hours[scheduleIndex].duration[durationIndex-1].closingTime;
                return this.calculateStartTimeLimit(prevDurationEndTime);
            } else {
               return endOfDay;
            }
        }
    }

    addHours() {
        if (_.isNil(this.kitchen.hours)) {
            this.kitchen.hours = [];
        }
        const earlyOrderingOptions = _.cloneDeep(this.earlyOrderingOptions);
        const earlyCheckinOptions = _.cloneDeep(this.earlyCheckinOptions);
        const lateCheckinOptions = _.cloneDeep(this.lateCheckinOptions);
        this.kitchen.hours.push({
            days: [],
            duration: [{
                earlyOrdering: '',
                earlyCheckin: '',
                lateCheckin: '',
                openingTime: '',
                closingTime: '',
                validptw: false,
                pickupWindowProperties: [{
                    startTime: '',
                    endTime: '',
                    capacity: 1,
                    length: 0
                }],
                validSchedule: false,
                earlyOrderingOptions: earlyOrderingOptions,
                earlyCheckinOptions: earlyCheckinOptions,
                lateCheckinOptions: lateCheckinOptions
            }]
        });
        this.initValidDays();
        this.populateTimeSelectDropdown();
        this.form.$setDirty();
    }

    addDuration(index) {
        if (_.isNil(this.kitchen.hours[index].duration)) {
            this.kitchen.hours = [];
        }
        this.kitchen.hours[index].duration.push({
            earlyOrdering: '',
            earlyCheckin: '',
            lateCheckin: '',
            openingTime: '',
            closingTime: '',
            validptw: false,
            pickupWindowProperties: [{
                startTime: '',
                endTime: '',
                capacity: 1,
                length: 0
            }],
        });
        this.populateTimeSelectDropdown();
        this.validateSchedule(index);
    }

    onCloseTimeUpdate(event, scheduleIndex, durationIndex) {
        this.kitchen.hours[scheduleIndex].duration[durationIndex].closingTime = event.model;
        this.validateTimeRanges(scheduleIndex,'close');
        this.validateSchedule(scheduleIndex);
        this.prepopulateptwTime(scheduleIndex, durationIndex, event.model, 'endTime');
        this.trackSameDayChanges(scheduleIndex);
        this.populateTimeSelectDropdown();
        this.form.$setDirty();
    }

    onOpenTimeUpdate(event, scheduleIndex, durationIndex) {
        this.kitchen.hours[scheduleIndex].duration[durationIndex].openingTime = event.model;
        this.validateTimeRanges(scheduleIndex,'open');
        this.validateSchedule(scheduleIndex);
        this.prepopulateptwTime(scheduleIndex, durationIndex, event.model, 'startTime');
        this.trackSameDayChanges(scheduleIndex);
        this.populateTimeSelectDropdown();
        this.form.$setDirty();
    }

    prepopulateptwTime(scheduleIndex, durationIndex, val,property) {
        if(this.pickupTimeWindowEnabled && this.kitchen.hours[scheduleIndex].duration[durationIndex].pickupWindowProperties[0][property]==="") {
                this.kitchen.hours[scheduleIndex].duration[durationIndex].pickupWindowProperties[0][property] = val
        }
    }

    trackSameDayChanges(scheduleIndex) {
        if(scheduleIndex === this.sameDayScheduleIndex) {
            this.kitchen.hours[scheduleIndex].isSameDayScheduleChanged = true;
        }
    }


    onWeekdaySelect(event, index) {
        if(event.model) {
            this.kitchen.hours[index].days = event.model;
            this.updateSelectedDays(event, index);
            this.initValidDays();
            this.form.$setDirty();
            this.trackSameDayChanges(index);
        }
    }

    removeSchedule(scheduleIndex) {

        let message =  this.pickupTimeWindowEnabled ? this.text.deleteSchedulePickupWindowMessage :
                       this.text.deleteScheduleMessage;

        const resolve = () => {
            this.kitchen.hours.splice(scheduleIndex, 1);
            this.form.$setDirty();
            this.trackSameDayChanges(scheduleIndex);
        };

        this.$uibModal
            .open({
                backdrop: 'static',
                component: 'crSimpleModal',
                windowClass: 'cr-modal-size-sm',
                resolve: {
                    message: () => message,
                    type: () => this.crConstants.modalTypes.SUBMIT,
                    submitText: () => 'DELETE',
                },
            })
            .result.then(resolve)
            .catch(() => null);
    }

    removeOrderingHours(scheduleIndex, durationIndex) {
        this.kitchen.hours[scheduleIndex].duration.splice(durationIndex, 1);
        this.validateSchedule(scheduleIndex);
        this.trackSameDayChanges(scheduleIndex);
        this.populateTimeSelectDropdown();
        this.form.$setDirty();
    }

    removePickUpWindow(scheduleIndex, durationIndex, pickUpWindowIndex) {
        this.kitchen.hours[scheduleIndex].duration[durationIndex].pickupWindowProperties.splice(pickUpWindowIndex, 1);
        this.validateWindowProperties(scheduleIndex,durationIndex);
        this.trackSameDayChanges(scheduleIndex);
        this.populateTimeSelectDropdown();
        this.form.$setDirty();
    }

    updateSelectedDays(event, index) {
        if(this.selectedDays.find(f => f.index === index)) {
            this.selectedDays[index] = { days: event.model, index: index };
        } else {
            this.selectedDays.push(
                { days: event.model,
                  index: index });
        }
    }

    isDuplicateDaySelected(userSelected, index) {
        let isDuplicateDay = false;
        let originalSelection =  this.selectedDays.filter(f => f.index !== index);
        let originalSelectedDays = []
        if(originalSelection) {
            originalSelection.forEach(s => {
                originalSelectedDays = originalSelectedDays.concat(s.days);
            });
            originalSelectedDays = _.flattenDeep(originalSelectedDays);
            const dayExist = _.intersection(_.uniq(originalSelectedDays), userSelected);

            isDuplicateDay = dayExist.length > 0 ? true : false;
        }

        return false;
    }

    isWindowExist(scheduleIndex) {
        return this.kitchen.hours[scheduleIndex].duration.some(d=> d.pickupWindowProperties.length>0);
    }

    initValidDays() {
        let allSelectedDays = [];
        this.kitchen.hours.forEach(h=> {
            allSelectedDays = _.uniq(allSelectedDays.concat(h.days));
        });
        this.kitchen.hours.forEach(h=> {
            h.validDays = _.union(_.difference(this.allDays, allSelectedDays), h.days);
        });
    }

}

export default MobileOrdering;
