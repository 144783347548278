import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient) {}

  login(username: string, password: string): Promise<any> {
    return this.http
      .post('/auth/login', {
        username,
        password,
      })
      .toPromise();
  }

  authcode(authCode: string): Promise<any> {
    return this.http
      .post('/auth/authcode', {
        authCode,
      })
      .toPromise();
  }

  password(previousPassword: string, newPassword: string): Promise<any> {
    return this.http
      .post('/auth/password', {
        previousPassword,
        newPassword,
      })
      .toPromise();
  }
}
