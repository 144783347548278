import Controller from './entity-header.controller';
import template from './entity-header.html';
import './entity-header.less';

const Component = {
    template,
    transclude: true,
    bindings: {
        entity: '<',
        manage: '<',
        onUpdate: '&',
        entityName: '@',
        customClass: '@?',
        cropperToolHeader: '@?',
    },
    controller: ['crConstants', 'crImageService', Controller],
};

export default Component;
