import angular from 'angular';
import text from './resources/locale/en.json';
import HoursBuilderComponent from './hours-builder.component';
import ComponentsModule from './components/components';

const HoursBuilderModule = angular
    .module('control-room.commons.form.components.hours-builder', [ComponentsModule.name])
    .constant('hoursBuilderText', text)
    .component('crHoursBuilder', HoursBuilderComponent);

export default HoursBuilderModule;
