import {
  Directive, ElementRef, Injector, Input, Output, EventEmitter,
} from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
  selector: 'cr-dropdown-upgraded',
})
export class DropdownUpgradedComponent extends UpgradeComponent {
  @Input() menuItems;

  @Input() mode;

  @Input() label;

  @Input() placeholder;

  @Input() sort;

  @Input() enableClear;

  @Input() hasDataError;

  @Input() crRequired;

  @Input() displayKey;

  @Input() showSearch;

  @Input() customClass;

  @Input() showStatus;

  @Input() routeQueryId;

  @Input() isMultiSelect;

  @Input() isDisabled;

  @Input() selectedClass;

  @Input() hideResetOption;

  @Input() hideCurrentSelection;

  @Input() disableTracking;

  @Input() validator;

  @Input() noResultsMessage;

  @Input() ignoreDirty;

  @Input() upgradedFormControl;

  @Output() onSelect: EventEmitter<CrDropdownUpgradedEvent>;

  constructor(elementRef: ElementRef, injector: Injector) {
    super('crDropdown', elementRef, injector);
  }
}

export interface CrDropdownUpgradedComponentItem {
  id: string;
  label: string;
  isSelected?: boolean;
}

export interface CrDropdownUpgradedEvent{
  // this makes you have to $event.$event which is really odd, changing it would have to fix many instances
  // where crdropdown is bring used
  $event:{
    model: CrDropdownUpgradedComponentItem[]
  }
}
