import bluePalette from '../../constant/color-palette/resources/palettes/blue.json';

const defaultOptions = {
    colorPalette: bluePalette,
    styleProperties: ['color'],
};

export default {
    defaultOptions,
};
