import angular from 'angular';

import EntityCategoriesComponent from './entity-categories.component';
import EntityCategoriesService from './entity-categories.service';
import EntityCategoriesComponents from './components/components';

const EntityCategoriesModule = angular
    .module('control-room.commons.content.components.entity-categories', [EntityCategoriesComponents.name])
    .component('crEntityCategories', EntityCategoriesComponent)
    .service('crEntityCategoriesService', ['crEntityService', 'crEntityLocalizationService', EntityCategoriesService]);

export default EntityCategoriesModule;
