import moment from 'moment';
import _ from 'lodash';

class DateRangePicker {
    constructor(locale, $state, crConstants, crDateRangeService, crVenueService) {
        this.text = locale;
        this.$state = $state;
        this.crConstants = crConstants;
        this.crDateRangeService = crDateRangeService;
        this.crVenueService = crVenueService;
    }

    $onInit() {
        this.currentStartDate = '';
        this.currentEndDate = '';

        this.dateOptions = {
            formatDay: this.crConstants.date.format.pickerDay,
            maxDate: !this.hasFutureDates ? moment().toISOString() : '',
            showWeeks: false,
        };

        const pastRangeOptions = [
            { id: 'today', unit: null, label: this.text.pastRangeOptions.today },
            { id: 'pastWeek', unit: 'week', label: this.text.pastRangeOptions.week },
            { id: 'pastMonth', unit: 'month', label: this.text.pastRangeOptions.month },
            { id: 'pastQuarter', unit: 'quarter', label: this.text.pastRangeOptions.quarter },
            { id: 'custom', unit: null, label: this.text.pastRangeOptions.custom },
        ];

        const futureDatesOptions = [
            { id: 'all', unit: null, label: this.text.futureRangeOptions.all },
            { id: 'today', unit: null, label: this.text.futureRangeOptions.today },
            { id: 'week', unit: 'week', label: this.text.futureRangeOptions.week },
            { id: 'month', unit: 'month', label: this.text.futureRangeOptions.month },
            { id: 'quarter', unit: 'quarter', label: this.text.futureRangeOptions.quarter },
            { id: 'custom', unit: null, label: this.text.futureRangeOptions.custom },
        ];

        this.rangeOptions = this.hasFutureDates ? futureDatesOptions : pastRangeOptions;

        const defaultRange = this.defaultRange || this.hasFutureDates ? 'all' : 'pastWeek';

        if (this.$state.params.start && this.$state.params.end) {
            this.handleDeepLink();
        } else {
            this.currentRangeOption = _.find(this.rangeOptions, { id: defaultRange });

            if (this.hasFutureDates) {
                this.currentRangeText = this.text.futureRangeOptions.all;
            } else {
                this.rangeSelected(this.currentRangeOption);
            }
        }
    }

    $onChanges(changes) {
        if (changes.range && this.range.start && this.range.end) {
            this.range = _.clone(this.range);
            this.showCustomRangeSelector = false;
            this.updateRangeText();
            this.updateRoute();
        }
    }

    update(range) {
        this.onUpdate({
            $event: { range },
        });
    }

    rangeSelected(option, e) {
        switch (option.id) {
            case 'today':
                this.showToday();
                break;
            case 'pastWeek':
            case 'pastMonth':
            case 'pastQuarter':
                this.updatePastRange(option);
                break;
            case 'all':
                this.handleAllFilter();
                break;
            case 'week':
            case 'month':
            case 'quarter':
                this.updateFutureRange(option);
                break;
            case 'custom':
                if (e) {
                    e.stopPropagation();
                    this.initCustomRange();
                } else {
                    this.customRangeUpdate();
                }
                break;
        }
    }

    initCustomRange() {
        this.showCustomRangeSelector = true;

        if ((this.hasFutureDates && !this.range.start) || !this.range.end) {
            const tz = this.crVenueService.getTimezone(this.$state.params.venueId).name;
            this.range.start = moment().tz(tz).startOf('day').toISOString();
            this.range.end = moment().tz(tz).endOf('day').toISOString();
        }
    }

    isValidRange(range) {
        return moment(range.start).isBefore(range.end);
    }

    showToday() {
        this.update(this.crDateRangeService.getToday());
    }

    updatePastRange(option) {
        this.currentRangeOption = option;
        this.update(this.crDateRangeService.getPastRange(option.unit));
    }

    updateFutureRange(option) {
        this.currentRangeOption = option;
        this.update(this.crDateRangeService.getFutureRange(option.unit, this.$state.params.venueId));
    }

    handleAllFilter() {
        this.range.start = '';
        this.range.end = '';
        this.update(this.range);
        this.updateRoute();
        this.showCustomRangeSelector = false;
        this.currentRangeText = this.text.futureRangeOptions.all;
    }

    customRangeUpdate() {
        this.updateRangeText();

        if (this.hasFutureDates) {
            const tz = this.crVenueService.getTimezone(this.$state.params.venueId).name;
            this.range.end = moment(this.range.end).tz(tz).endOf('day').toISOString();
        }

        this.update({
            start: moment(this.range.start).toISOString(),
            end: moment(this.range.end).toISOString(),
        });
    }

    updateRangeText() {
        if (this.hasFutureDates) {
            const tz = this.crVenueService.getTimezone(this.$state.params.venueId).name;
            const start = moment(this.range.start).tz(tz);
            const end = moment(this.range.end).tz(tz);

            this.currentRangeText = start.format('MMM DD');

            if (!moment(start).isSame(end, 'year')) {
                this.currentRangeText = `${start.format('MMM DD, YYYY')}-${end.format('MMM DD, YYYY')}`;
                return;
            }

            if (!moment(start).isSame(end, 'month')) {
                this.currentRangeText += `-${end.format('MMM DD')}`;
                return;
            }

            if (!moment(start).isSame(end, 'day')) {
                this.currentRangeText += `-${end.format('DD')}`;
            }
        } else {
            this.currentStartDate = moment(this.range.start).format('ll');
            this.currentEndDate = moment(this.range.end).format('ll');
        }
    }

    handleDeepLink() {
        if (this.hasFutureDates) {
            this.range.start = this.$state.params.start;
            this.range.end = this.$state.params.end;
            this.updateRangeText();
        } else {
            this.update({
                start: this.$state.params.start,
                end: this.$state.params.end,
            });
        }
    }

    updateRoute() {
        const currentState = this.$state.current.name;

        if (!this.range.start || !this.range.end) {
            this.$state.params.start = '';
            this.$state.params.end = '';
        } else {
            const start = moment(this.range.start);
            const end = moment(this.range.end);

            if (this.hasFutureDates) {
                this.$state.params.start = start.toISOString();
                this.$state.params.end = end.toISOString();
            } else {
                this.$state.params.start = start.format('YYYY-MM-DD');
                this.$state.params.end = end.format('YYYY-MM-DD');
            }
        }

        this.$state.go(currentState, this.$state.params, {
            location: 'replace',
        });
    }

    keepOpen(e) {
        e.stopPropagation();
    }
}

export default DateRangePicker;
