import text from './resources/locale/en.json';

class VenueHoursOrder {
    constructor($state, crConstants, crEntityService, crErrorLoggingService, crToastService) {
        this.$state = $state;
        this.crConstants = crConstants;
        this.crEntityService = crEntityService;
        this.crErrorLoggingService = crErrorLoggingService;
        this.crToastService = crToastService;
    }

    $onInit() {
        this.text = text;
        this.isLoading = true;
        this.hasDataError = false;

        // See if we need to show a toast when coming to this page
        this.crToastService.autoToast(this.$state.params);

        this.crEntityService
            .getEntityList(this.crConstants.entity.types.VENUE_HOURS, { venueId: this.$state.params.venueId })
            .then((res) => {
                this.schedules = res.content;
                this.isCreateEnabled = res.content.length < 5;
            })
            .catch((err) => {
                this.hasDataError = true;
                this.crErrorLoggingService.logError('could not retrieve venue hours', err);
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    goToCreatePage() {
        this.$state.go('client.venue-hours.create', this.getStateParams());
    }

    goToReorderPage() {
        this.$state.go('client.venue-hours.edit-order', this.getStateParams());
    }

    getStateParams() {
        return { customerId: this.$state.params.customerId, venueId: this.$state.params.venueId };
    }
}

export default VenueHoursOrder;
