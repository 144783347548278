import angular from 'angular';

import cvtDashboardHeaderModule from './cvt-dashboard-header/cvt-dashboard-header';
import cvtIssuesSectionModule from './cvt-issues-section/cvt-issues-section';
import cvtIssuesHeaderModule from './cvt-issues-header/cvt-issues-header';

const Components = angular.module('control-room.internal.components.cvt.components.cvt-dashboard.components', [
    cvtDashboardHeaderModule.name,
    cvtIssuesSectionModule.name,
    cvtIssuesHeaderModule.name,
]);

export default Components;
