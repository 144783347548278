<div
    class="edit-header"
    data-global-qa-id="title-bar"
    [ngClass]="{ gray: gray }"
>
    <div class="title t-title">
        <ng-content></ng-content>
    </div>
    <div class="actions">
        <button
            type="button"
            class="cancel btn btn-nobg"
            data-global-qa-id="manage-cancel"
            (click)="cancelClicked()"
        >
            {{ text.cancel }}
        </button>
        <button
            type="submit"
            class="save btn btn-primary btn-save"
            data-global-qa-id="manage-save"
            (click)="saveClicked()"
        >
            {{ text.save }}
        </button>
    </div>
</div>
