import _ from 'lodash';
import ResizeObs from 'resize-observer-polyfill';

class PositionStickyDirective {
    constructor($window, $document, crConstants, layoutService) {
        this.$window = $window;
        this.$document = $document;
        this.restrict = 'A';
        this.events = crConstants.events;
        this.layoutService = layoutService;
    }

    static directiveFactory($window, $document, crConstants, layoutService) {
        return new PositionStickyDirective($window, $document, crConstants, layoutService);
    }

    link(scope, elem, attrs) {
        if (attrs.crPositionSticky !== 'false') {
            let placeholder;
            const pxFromTop = attrs.crPositionSticky;

            const rect = elem[0].getBoundingClientRect();
            const ros = [];

            if (attrs.crPositionStickyCopy) {
                placeholder = elem.clone()[0];
            } else {
                placeholder = this.$document[0].createElement('div');
            }

            placeholder.style.height = `${rect.height}px`;
            placeholder.style.width = '100%';
            placeholder.style.display = 'none';

            elem[0].style.position = 'relative';

            if (attrs.crPositionStickyIndex) {
                elem[0].style.zIndex = attrs.crPositionStickyIndex;
            }

            elem[0].parentElement.insertBefore(placeholder, elem[0]);

            this.handleNavAnimation(scope, elem);

            this.throttleGetPlaceholderHeight = _.throttle(() => {
                placeholder.style.height = `${elem[0].getBoundingClientRect().height}px`;
            }, 1000);

            const onScroll = () => {
                if (attrs.crPositionStickyDynamicHeight) {
                    this.throttleGetPlaceholderHeight();
                }
                this.onScroll(pxFromTop, elem, placeholder, attrs, ros);
            };

            this.$window.addEventListener('scroll', onScroll);

            scope.$on('$destroy', () => {
                ros.forEach((ro) => ro.disconnect());
                ros.splice(0, ros.length);
                this.$window.removeEventListener('scroll', onScroll);
            });
        }
    }

    handleNavAnimation(scope, elem) {
        const navOpen = 'cr-sticky-nav-open';
        const navClose = 'cr-sticky-nav-close';

        const initialState = this.layoutService.getSlim() ? navClose : navOpen;
        elem.addClass(initialState);

        scope.$on(this.events.NAV_PANEL_OPEN, () => {
            elem.addClass(navOpen);
            elem.removeClass(navClose);
        });

        scope.$on(this.events.NAV_PANEL_CLOSE, () => {
            elem.addClass(navClose);
            elem.removeClass(navOpen);
        });
    }

    onScroll(pxFromTop, elem, placeholder, attrs, ros) {
        // css class applied when at top
        const stickyClass = 'cr-stick-to-top';

        const identityBarHeight = 56;

        if (this.$window.pageYOffset >= identityBarHeight) {
            placeholder.style.display = null;
            elem[0].style.top = `${pxFromTop}px`;
            elem[0].style.position = null;
            if (attrs.crPositionStickyCopy) {
                placeholder.innerHTML = elem[0].innerHTML;

                if (attrs.crPositionStickyResizeSelector) {
                    const placeholders = placeholder.querySelectorAll(attrs.crPositionStickyResizeSelector);
                    const reals = elem[0].querySelectorAll(attrs.crPositionStickyResizeSelector);

                    for (let i = 0; i < placeholders.length; i++) {
                        ((placehold, real) => {
                            real.style.width = `${placehold.clientWidth}px`;

                            const ro = new ResizeObs(() => {
                                real.style.width = `${placehold.clientWidth}px`;
                            });

                            ro.observe(placehold);

                            ros.push(ro);
                        })(placeholders[i], reals[i]);
                    }
                }
            }
            elem.addClass(stickyClass);
        } else {
            if (attrs.crPositionStickyCopy && attrs.crPositionStickyResizeSelector) {
                ros.forEach((ro) => ro.disconnect());
                ros.splice(0, ros.length);
            }

            placeholder.style.display = 'none';
            elem[0].style.top = 0;
            elem[0].style.position = 'relative';
            elem[0].style.marginLeft = 0;
            elem.removeClass(stickyClass);
        }
    }
}

PositionStickyDirective.directiveFactory.$inject = ['$window', '$document', 'crConstants', 'layoutService'];

export default PositionStickyDirective;
