import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { StateService } from '@uirouter/core';
import { of } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { LocalizedText } from '../../../../core';
import { ErrorLoggingService, NavigationService } from '../../../../shared';
import { BundleGroupListData } from '../../../../shared/services/bundle-groups/bundle-groups.model';
import { BundleGroupsService } from '../../../../shared/services/bundle-groups/bundle-groups.service';
import { CrTableColumnOptions, CrTableHoverIconClickEvent, CrTableOptions } from '../../../../shared/table/table.model';
import { menuRoutes } from '../../menus.routes';
import text from './resources/en.json';

@Component({
  selector: 'bundle-group-list',
  templateUrl: 'bundle-group-list.component.html',
  styleUrls: ['./bundle-group-list.component.scss'],
})

export class BundleGroupListComponent implements OnInit {
  crTableOptions: CrTableOptions<BundleGroupListData> = {
    data: undefined,
    columns: undefined,
    emptyStateText: text.emptyStateText,
    hoverOptions: [{ actionString: 'EDIT', icon: 'icon-cr-edit' }],
  };

  text: LocalizedText;

  bundleGroupData: BundleGroupListData[];

  searchFormControl = new FormControl();

  constructor(private nav: NavigationService, private bundleService: BundleGroupsService, private state: StateService, private logger: ErrorLoggingService) {
    this.text = text;
  }

  ngOnInit(): void {
    this.getData();
    this.generateColumns();
  }

  getData(): void {
    this.bundleService.getBundleGroupsList(this.state.params.placeId, this.state.params.menuId)
      .pipe(
        take(1),
        catchError((err) => of(this.handleError(err))),
      ).subscribe((data) => {
        if (data) {
          this.bundleGroupData = data;
          this.crTableOptions.data = data;
          this.getBundleProductNames();
        }
      });
  }

  goToCreatePage(): void {
    const { placeId, menuId } = this.state.params;
    this.state.go(menuRoutes.CREATE_BUNDLE_GROUPS, {
      placeId,
      menuId,
    });
  }

  handleRowIconClick(e:CrTableHoverIconClickEvent<BundleGroupListData>): void {
    if (e.action === 'EDIT') {
      const { placeId, menuId } = this.state.params;
      this.state.go(menuRoutes.EDIT_BUNDLE_GROUPS, {
        placeId,
        menuId,
        bundleGroupId: e.item.id,
      });
    }
  }

  handleSort(column: CrTableColumnOptions): void {
    // handle data sorting based on key
    if (column.sort) {
      this.crTableOptions.data.sort((a, b) => a.displayName.localeCompare(b.displayName));
    } else {
      this.crTableOptions.data.sort((a, b) => b.displayName.localeCompare(a.displayName));
    }
  }

  handleSearch(searchQuery: string): void {
    if (this.bundleGroupData) {
      this.crTableOptions.data = this.bundleGroupData.filter((item) => (item.displayName.toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase())));
    }
  }

  private generateColumns(): void {
    this.crTableOptions.columns = [
      {
        displayName: '',
        key: 'images',
        width: 64,
        images: true,
        customRowStyles: { 'padding-left': '16px' },
      },
      {
        displayName: 'Name',
        width: 200,
        key: 'displayName',
      },
      {
        displayName: 'Items',
        width: 600,
        key: 'bundleProductNames',
      },
      {
        displayName: 'Missing Translations',
        width: 200,
        key: 'missingTranslations',
        customRowStyles: { color: 'red' },
      },
    ];
  }

  onBack(): void {
    this.nav.goBack(menuRoutes.PRODUCTS, {
      menuId: this.state.params.menuId,
      tabId: this.state.params.tabId,
      placeId: this.state.params.placeId,
    });
  }

  // create a list of bundle product names for the table component display
  private getBundleProductNames(): void {
    this.crTableOptions.data.map((item) => {
      item.bundleProductNames = [];
      item.bundledProducts.forEach((product) => {
        item.bundleProductNames.push(product.displayName);
      });
    });
  }

  private handleError(err): void {
    this.logger.logError('BundleGroupList', err);
  }
}
