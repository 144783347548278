import controller from './preferences-brand-content.controller';
import template from './preferences-brand-content.html';
import './preferences-brand-content.less';

const component = {
    template,
    bindings: {
        content: '<',
        locale: '<',
        onLocaleSelect: '&',
    },
    controller: ['crConfig', 'crAnalyticsService', controller],
};

export default component;
