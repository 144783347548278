import Controller from './beacons-list.controller';
import template from './beacons-list.html';
import './beacons-list.less';

const Component = {
    template,
    controller: [
        '$state',
        'crErrorLoggingService',
        'crAnalyticsService',
        'crToastService',
        'crLocaleService',
        'crBeaconsListService',
        'crConstants',
        'crEntitySelectionManager',
        Controller,
    ],
};

export default Component;
