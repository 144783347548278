import angular from 'angular';

import component from './cvt.component';
import componentsModule from './components/components';

const Cvt = angular
    .module('control-room.internal.components.cvt', [componentsModule.name])
    .component('crCvt', component);

export default Cvt;
