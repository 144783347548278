import Controller from './calendar-event.controller';
import template from './calendar-event.html';
import './calendar-event.less';

const Component = {
    template,
    require: {
        calendar: '^crCalendar',
    },
    bindings: {
        data: '<',
    },
    controller: ['$timeout', Controller],
};

export default Component;
