import controller from './experience-manage.controller';
import template from './experience-manage.html';

import './experience-manage.less';

const component = {
    template,
    bindings: {
        edit: '<?',
        group: '@?',
    },
    controller: [
        '$state',
        '$q',
        '$uibModal',
        'crConfig',
        'crConstants',
        'crAnalyticsService',
        'crNavigationService',
        'crExperiencesService',
        'crEntityService',
        'crErrorLoggingService',
        'crLocaleService',
        'crImageService',
        'crPoiService',
        'crCouponsService',
        'crCommerceService',
        'crVenueService',
        'crExperienceManageService',
        'crLoQueueService',
        'crPolicyService',
        controller,
    ],
};

export default component;
