import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { StateService } from '@uirouter/core';
import { Subject } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { debounceTime, tap } from 'rxjs/operators';
import { LocalizedText } from '../../../../core';
import { ReservationService } from '../../../../shared';
import { ReservationEvent } from '../../../../shared/services/reservations/reservation.types';
import { NavigationHeaderComponent, NavLocation } from '../nav-header/nav-header.component';
import text from './resources/locale/en.json';
import { CreateDiningLocationModalComponent } from '..';

@Component({
  selector: 'cr-dining-locations',
  templateUrl: './dining-locations.component.html',
  styleUrls: ['./dining-locations.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DiningLocationsComponent implements OnInit {
  navLocation: NavLocation;

  text: LocalizedText;

  isLoading: boolean;

  isSearching: boolean;

  eventLocations: ReservationEvent[] = [];

  filteredEventLocations: ReservationEvent[] = [];

  searchSub = new Subject<string>();

  searchQuery = '';

  constructor(
    private state: StateService,
    private reservationService: ReservationService,
    private modalService: BsModalService,
  ) {
    this.isLoading = true;
    this.navLocation = NavLocation.DiningLocations;
    this.text = text;
  }

  ngOnInit() {
    this.reservationService
      .getReservationEvents(this.state.params.venueId)
      .then((eventsResponse) => {
        this.eventLocations = eventsResponse.content;
        this.filteredEventLocations = eventsResponse.content.sort(this.sortEventLocations);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        this.isLoading = false;
      });

    this.searchSub
      .pipe(
        tap((query) => {
          this.searchQuery = query;
        }),
        debounceTime(300),
      )
      .subscribe((query) => {
        this.isLoading = true;
        this.filteredEventLocations = this.handleSearch(query);
        this.isLoading = false;
      });
  }

  handleSearch(query: string): ReservationEvent[] {
    /* TODO: Add search to both name + poi name */
    if (!query) {
      return this.eventLocations;
    }

    const filteredLocations = this.eventLocations.filter((location) => {
      if (location.name.toLowerCase().includes(query.toLowerCase())) {
        return true;
      }

      return false;
    });

    return filteredLocations.sort(this.sortEventLocations);
  }

  sortEventLocations = (event1: ReservationEvent, event2: ReservationEvent): number => {
    if (event1.name.toLowerCase() < event2.name.toLowerCase()) {
      return -1;
    }
    if (event1.name.toLowerCase() > event2.name.toLowerCase()) {
      return 1;
    }
    return 0;
  };

  searchChangeEvent(event: Event) {
    this.searchSub.next((event.target as HTMLInputElement).value);
  }

  clearSearch() {
    this.searchQuery = '';
    this.isSearching = false;
    this.filteredEventLocations = this.eventLocations;
  }

  searchFocus() {
    this.isSearching = true;
  }

  searchBlur() {
    this.isSearching = false;
  }

  get hasAddButton() {
    return this.state.is(NavigationHeaderComponent.DINING_LOCATIONS_STATE);
  }

  openCreateRestaurantModal() {
    this.modalService.show(CreateDiningLocationModalComponent, { class: 'gray modal-lg' });
  }

  editLocationAction(eventId: string) {
    this.state.go('client.fnb-reservations.dining-locations-editor', { eventId });
  }

  previewLocationAction(eventId: string) {
    this.state.go('client.fnb-reservations.dining-locations-editor', { preview: eventId });
  }
}
