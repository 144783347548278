import './styles/styles';
import angular from 'angular';

import ComponentsModule from './components/components';
import DirectivesModule from './directives/directives';
import ServicesModule from './services/services';

const LayoutModule = angular.module('control-room.commons.layout', [
    ComponentsModule.name,
    DirectivesModule.name,
    ServicesModule.name,
]);

export default LayoutModule;
