import angular from 'angular';

import ComponentsModule from './components/components';
import component from './experience-manage.component';
import ExperienceManageService from './experience-manage.service';

const ExperienceManage = angular
    .module('control-room.experience-promoter.components.experience-manage', [ComponentsModule.name])
    .component('crExperienceManage', component)
    .service('crExperienceManageService', [
        'crEntityService',
        'crConstants',
        'crProductsListService',
        '$http',
        '$q',
        ExperienceManageService,
    ]);

export default ExperienceManage;
