import controller from './experience-test-message.controller';
import template from './experience-test-message.html';

import './experience-test-message.less';

const component = {
    template,
    bindings: {
        experience: '<',
    },
    controller: [
        '$state',
        'crAnalyticsService',
        'crConfig',
        'crTestMessagesService',
        'crLocaleService',
        'crToastService',
        'crUserService',
        'crPolicyService',
        'crErrorLoggingService',
        controller,
    ],
};

export default component;
