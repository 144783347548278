class EntityLink {
    constructor($state) {
        this.$state = $state;
    }

    $onInit() {
        const { id } = this;
        const { customerId, venueId } = this.$state.params;

        // map to route
        if (this.type === 'pois') {
            this.type = 'poi';
        }

        if (this.type === 'event') {
            this.type = 'events';
        }

        if (this.type === 'beacon') {
            this.type = 'beacons';
        }

        let entityState = `client.${this.type}.${this.page}`;

        if (this.type === 'poi-categories') {
            entityState = 'client.poi.category-edit';
        }

        if (this.type === 'event-categories') {
            entityState = 'client.events.categories-edit';
        }

        this.linkHref = this.$state.href(entityState, { id, customerId, venueId });

        if (!this.linkHref) {
            throw Error('entity-link | invalid href');
        }
    }
}

export default EntityLink;
