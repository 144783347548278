import _ from 'lodash';
import text from './resources/locale/en.json';

class ExperienceManageService {
    constructor(crEntityService, crConstants, crProductsListService, $http, $q) {
        this.crEntityService = crEntityService;
        this.crProductsListService = crProductsListService;
        this.crConstants = crConstants;
        this.$http = $http;
        this.$q = $q;
        this.url = '/rest/menus';
        this.text = text;
    }

    getAllProductsAndCategories(venueId) {
        const menuQueryParams = { venueId, state: 'ACTIVE' };
        return this.crEntityService
            .getEntityList(this.crConstants.entity.types.MENUS, menuQueryParams)
            .then((response) => {
                const menus = _.uniqBy(response.content, (menu) => menu.id);
                return this.$q.all(
                    menus.map((menu) => this.getProductsAndCategoriesForMenu(menu.id, venueId, menu.placeInfo.id))
                );
            })
            .then((responses) => {
                // Combine response arrays
                const products = [];
                const categories = [];
                responses.map((responseMenu) => {
                    products.push(...responseMenu.products);
                    categories.push(...responseMenu.categories);
                });
                return {
                    products: _.uniqBy(products, (p) => p.id),
                    categories: _.uniqBy(categories, (c) => c.id),
                };
            })
            .catch((err) => {
                console.warn(err);
                return { products: [], categories: [] };
            });
    }

    getProductsAndCategoriesForMenu(menuId, venueId, placeId) {
        const productQueryParams = { venueId, placeId };
        return this.$q
            .all([
                this.crProductsListService.getData(productQueryParams, menuId),
                this.crProductsListService.getCategories(menuId, venueId, placeId),
            ])
            .then((data) => ({ menuId, products: data[0].content, categories: data[1] }));
    }

    updateTriggerLabels(experience, triggerPath, items, categories, itemType, catType) {
        // Add labels and ids to user visit behavior triggers
        const triggerParams = _.get(experience, triggerPath);
        if (triggerParams) {
            const itemMap = _.keyBy(items, 'id');
            const catMap = _.keyBy(categories, 'id');
            return triggerParams.map((param) => {
                param.id = param.value;
                if (param.type === itemType) {
                    const item = itemMap[param.value];
                    if (item) {
                        let prefix = '';
                        if (item.categories && item.categories.length > 0) {
                            prefix = `${item.categories.map((cat) => cat.label || cat.displayName).join(', ')} ${
                                this.text.categorySeparator
                            } `;
                        }
                        param.label = `${prefix}${item.name || item.displayName}`;
                    }
                }
                if (param.type === catType) {
                    const category = catMap[param.value];
                    if (!category) {
                        console.warn(`Category is missing for value ${param.value}`);
                    }
                    param.label = `${category.label} ${this.text.categorySeparator} ${this.text.all}`;
                }
                return param;
            });
        }
        return null;
    }
}

export default ExperienceManageService;
