<cr-title-bar [label]="pageTitle">
  <div titleBarRight>
    <button class="btn btn-nobg" (click)="onCancel()">
      {{ text.cancelButton }}
    </button>
    <button
      *ngIf="!state.params.edit"
      class="btn btn-primary"
      (click)="onSave()"
      [disabled]="!feesManageFormGroup.valid"
    >
      {{ text.saveButton }}
    </button>
    <cr-split-btn
      *ngIf="state.params.edit"
      [label]="text.saveButton"
      (onButtonClick)="onSave()"
    >
      <li>
        <a (click)="openModal(archiveTemplate)" data-global-qa-id="delete">
          {{ text.archiveFee }}
        </a>
      </li>
    </cr-split-btn>
  </div>
</cr-title-bar>

<section class="manage-fee-section">
  <form [formGroup]="feesManageFormGroup">
    <cr-input
      class="input-long"
      [label]="text.displayNameLabel"
      type="text"
      [formControl]="displayNameControl"
    ></cr-input>
    <div class="coupled-input">
      <div class="input-header">{{ text.status }}</div>
      <select [formControl]="statusControl" class="fee-dropdown">
        <option *ngFor="let item of statusItems" [value]="item.id">
          {{ item.label }}
        </option>
      </select>
    </div>
    <hr />
    <div class="section-header">{{ text.feeCalc }}</div>
    <div class="coupled-inputs">
      <div class="coupled-input">
        <div class="input-header">{{ text.feeMethod }}</div>
        <select [formControl]="feeMethodControl" class="fee-dropdown">
          <option *ngFor="let item of feeMethodItems" [value]="item.id">
            {{ item.label }}
          </option>
        </select>
      </div>
      <div class="coupled-input">
        <cr-input
          *ngIf="feeMethodControl.value === 'PERCENT'"
          [label]="text.percentage"
          type="number"
          min="0"
          max="100"
          [formControl]="amountControl"
        ></cr-input>
        <cr-input
          *ngIf="feeMethodControl.value === 'FIXED'"
          [label]="text.flatFeeLabel"
          type="number"
          step=".01"
          min="0.01"
          [formControl]="amountControl"
        ></cr-input>
      </div>
    </div>
    <div class="coupled-inputs">
      <div class="coupled-input">
        <div class="input-header">{{ text.tax }}</div>
        <select [formControl]="taxControl" class="fee-dropdown">
          <option *ngFor="let item of taxItems" [value]="item.id">
            {{ item.label }}
          </option>
        </select>
      </div>
      <div class="coupled-input" *ngIf="taxControl.value === '1'">
        <div class="input-header">{{ text.taxCode }}</div>
        <cr-dropdown
          mode="select"
          name="tax-code"
          [showSearch]="true"
          [hideResetOption]="true"
          [menuItems]="taxCodeItems"
          [formControl]="taxCodeControl"
        >
        </cr-dropdown>
      </div>
    </div>
    <div class="coupled-inputs">
      <div class="co">
        <div class="input-header">{{ text.feeCategory }}</div>
        <select [formControl]="feeCategoryControl" class="fee-dropdown">
          <option *ngFor="let item of feeCategoryItems" [value]="item.id">
            {{ item.label }}
          </option>
        </select>
      </div>
    </div>
    <cr-required-field></cr-required-field>
  </form>
</section>

<ng-template #archiveTemplate>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{ text.deleteFee }}</h4>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    {{ text.deleteMessage }}
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modalRef.hide()">
      {{ text.cancel }}
    </button>
    <button type="button" class="btn btn-danger" (click)="archiveFee()">
      {{ text.deleteFee }}
    </button>
  </div>
</ng-template>
