import template from './extensions.html';
import './extensions.less';

const ExtensionsComponent = {
    template,
    bindings: {
        model: '<',
    },
};

export default ExtensionsComponent;
