import text from './resources/locale/en.json';

class AttributesListService {
    constructor(crEntityService, crConstants) {
        this.crEntityService = crEntityService;
        this.crConstants = crConstants;
        this.text = text;
    }

    getData(entityType, params) {
        return this.crEntityService.getAttributes(entityType, params);
    }

    mapToRowsModel(attributes) {
        return attributes.map((attribute) => ({
            data: attribute,
        }));
    }

    getValuesAsCSV(values) {
        return values.map((obj) => obj.label).join(', ') || '--';
    }
}

export default AttributesListService;
