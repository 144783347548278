class BeaconEditService {
    constructor($q, crBeaconsService, crImageService) {
        this.$q = $q;
        this.crBeaconsService = crBeaconsService;
        this.crImageService = crImageService;
    }

    getData(beaconId) {
        return this.crBeaconsService.getBeaconDetails(beaconId).then((res) => res);
    }

    createBeacon(beaconData) {
        return this.crBeaconsService.createBeacon(beaconData).then((res) => res);
    }

    updateBeacon(beaconId, beaconData) {
        return this.crBeaconsService.updateBeacon(beaconId, beaconData).then((res) => res);
    }
}

export default BeaconEditService;
