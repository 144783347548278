import _ from 'lodash';
import text from './resources/locale/en.json';

class EntitySelectionManager {
    constructor(
        $q,
        $state,
        $uibModal,
        crConstants,
        crEntityService,
        crPolicyService,
        crAnalyticsService,
        crEntityLocalizationService
    ) {
        this.$q = $q;
        this.$state = $state;
        this.$uibModal = $uibModal;
        this.crConstants = crConstants;
        this.crEntityService = crEntityService;
        this.crPolicyService = crPolicyService;
        this.crAnalyticsService = crAnalyticsService;
        this.crEntityLocalizationService = crEntityLocalizationService;
    }

    async getConfig(entityType, items, policyEntity) {
        if (!entityType) {
            throw Error('EntitySelectionManager - Missing EntityType');
        }

        const options = [];
        const { types } = this.crConstants.entity;

        if (policyEntity) {
            const policyAction = `write:${policyEntity}`;
            const anyAllowed = this.crPolicyService.hasAccess(policyAction);
            const itemsAllowed = (items || []).map((item) => {
                if (!item.id) {
                    return true;
                }
                return this.crPolicyService.hasAccess(policyAction, item.id);
            });
            const allAllowed = await Promise.all([anyAllowed, ...itemsAllowed]);
            const allowed = allAllowed.reduce((a, b) => a && b, true);
            if (!allowed) {
                return options;
            }
        }

        if (entityType === types.EVENTS) {
            options.push(this.getLocationConfig(entityType, items));
        }

        if (entityType === types.EVENTS || entityType === types.POIS) {
            options.push(this.getCategoryConfig(entityType, items));
        }

        if (
            entityType === types.EVENTS ||
            entityType === types.POIS ||
            entityType === types.COUPONS ||
            entityType === types.MENUS ||
            entityType === types.MENU_PRODUCTS ||
            entityType === types.EXPERIENCES
        ) {
            const stateMap = _.groupBy(items, (item) => item.originalData.state || item.originalData.status);
            const { states } = this.crConstants.entity;

            if (Object.keys(stateMap).length > 1) {
                options.push(this.getActiveConfig(entityType, items));
                options.push(this.getInactiveConfig(entityType, items));
            } else {
                if (!stateMap[states.ACTIVE]) {
                    options.push(this.getActiveConfig(entityType, items));
                }

                if (!stateMap[states.INACTIVE]) {
                    options.push(this.getInactiveConfig(entityType, items));
                }
            }
        }

        if (entityType !== types.POIS_SELECT_ONLY) {
            options.push(this.getArchiveConfig(entityType, items));
        }

        return options;
    }

    updateEntitiesState(entityType, items, action) {
        const success = [];
        const failure = [];

        const { menuId } = this.$state.params;
        const entityRoute = menuId ? `menus/${menuId}/${entityType}` : entityType;

        return this.$q((resolve) => {
            items.forEach((item) => {
                const placeId = item.placeInfo ? item.placeInfo.id : null;
                this.crEntityService
                    .updateState(entityRoute, item.id, { state: item.state }, this.$state.params.venueId, { placeId })
                    .then(() => success.push(item))
                    .catch((e) => {
                        item.error = e.error;
                        failure.push(item);
                    })
                    .finally(() => {
                        if (success.length + failure.length === items.length) {
                            this.crAnalyticsService.track('Bulk Action', {
                                entityType,
                                action,
                                successCount: success.length,
                                failureCount: failure.length,
                            });

                            resolve({ success, failure, action });
                        }
                    });
            });
        });
    }

    updateEntities(entityType, items, action) {
        const success = [];
        const failure = [];

        const { menuId } = this.$state.params;
        const entityRoute = menuId ? `menus/${menuId}/${entityType}` : entityType;

        const additionalParams = {};
        if (this.$state.params.placeId) {
            additionalParams.placeId = this.$state.params.placeId;
        }

        return this.$q((resolve) => {
            items.forEach((item) => {
                // account for inconsistent GET/PUT model
                if (entityType === this.crConstants.entity.types.MENU_PRODUCTS) {
                    item.categories = item.categories.map((cat) => cat.id);
                    item.modifierGroups = item.modifierGroups.map((mod) => mod.id);
                }

                this.crEntityService
                    .updateEntity(entityType, item.id, item, this.$state.params.venueId, entityRoute, additionalParams)
                    .then(() => success.push(item))
                    .catch((e) => {
                        item.error = e.error;
                        failure.push(item);
                    })
                    .finally(() => {
                        if (success.length + failure.length === items.length) {
                            this.crAnalyticsService.track('Bulk Action', {
                                entityType,
                                action,
                                successCount: success.length,
                                failureCount: failure.length,
                            });

                            resolve({ success, failure, action });
                        }
                    });
            });
        });
    }

    deleteEntities(entityType, items, action) {
        const success = [];
        const failure = [];
        const { menuId } = this.$state.params;
        const entityRoute = menuId ? `menus/${menuId}/${entityType}` : entityType;

        const additionalParams = {};
        if (this.$state.params.placeId) {
            additionalParams.placeId = this.$state.params.placeId;
        }

        return this.$q((resolve) => {
            items.forEach((item) => {
                this.crEntityService
                    .deleteEntity(entityRoute, item.id, this.$state.params.venueId, additionalParams)
                    .then(() => success.push(item))
                    .catch((e) => {
                        item.error = e.error;
                        failure.push(item);
                    })
                    .finally(() => {
                        if (success.length + failure.length === items.length) {
                            this.crAnalyticsService.track('Bulk Action', {
                                entityType,
                                action,
                                successCount: success.length,
                                failureCount: failure.length,
                            });
                            resolve({ success, failure, action });
                        }
                    });
            });
        });
    }

    getLocationConfig(entityType, items) {
        const params = [
            entityType,
            items,
            this.crConstants.entity.types.POIS,
            'associatedPois',
            items.length > 1 ? text.addLocation : text.addLocations,
            text.addLocation,
        ];

        return this.getAssociateEntityConfig(...params);
    }

    getCategoryConfig(entityType, items) {
        const { types } = this.crConstants.entity;
        const params = [
            entityType,
            items,
            entityType === types.POIS ? types.POI_CATEGORIES : types.EVENT_CATEGORIES,
            'displayCategories',
            items.length > 1 ? text.addCategories : text.addCategory,
            text.addCategory,
        ];

        return this.getAssociateEntityConfig(...params);
    }

    getAssociateEntityConfig(entityType, items, associateType, associateKey, actionText, menuText) {
        return {
            text: menuText,
            click: () => {
                const modalData = {
                    type: this.crConstants.modalTypes.SUBMIT,
                    title: `${text.add} ${this.getEntityName(associateType)}`,
                    tags: [],
                    associateType,
                    imageShape: 'circle',
                    items: _.map(items, (item) => item.originalData),
                };

                this.$uibModal
                    .open({
                        backdrop: 'static',
                        component: 'crAssociateEntityModal',
                        resolve: { modalData },
                    })
                    .result.then(
                        () => {
                            this.associateEntities(
                                entityType,
                                modalData.items,
                                modalData.tags,
                                associateKey,
                                actionText
                            );
                        },
                        () => {
                            this.crAnalyticsService.track('Bulk Action - cancel');
                        }
                    );
            },
        };
    }

    associateEntities(entityType, entities, tags, associateKey, actionText) {
        entities.forEach((entity) => {
            const associatedItems = _.unionBy(tags, entity[associateKey] || [], 'id');

            entity[associateKey] = _.map(associatedItems, (item) => ({
                id: item.id,
                label: item.label,
                name: item.name,
            }));
        });

        this.updateEntities(entityType, entities, actionText).then((results) => {
            let successMsg = '';
            const entityName = this.getEntityName(entityType);
            const failureMsg = `${text.failedTo} <strong>${actionText}</strong> ${text.toTheFollowing} ${entityName}:`;

            if (results.success.length) {
                const resultCount = results.success.length;
                successMsg = `${text.successfullyUpdated} ${resultCount} ${entityName}`;
            }

            this.refreshData(entityType, successMsg, failureMsg, results.failure);
        });
    }

    getActiveConfig(entityType, items) {
        const params = [
            entityType,
            items,
            text.activate,
            text.activateConfirmationMessage,
            this.crConstants.entity.states.ACTIVE,
        ];

        return this.getStateConfig(...params);
    }

    getInactiveConfig(entityType, items) {
        const params = [
            entityType,
            items,
            text.deactivate,
            text.deactivateConfirmationMessage,
            this.crConstants.entity.states.INACTIVE,
            text.description[entityType],
        ];

        return this.getStateConfig(...params);
    }

    getArchiveConfig(entityType, items) {
        const options = { component: 'crActionSummaryModal' };
        if (entityType === this.crConstants.entity.types.PLACES) {
            options.component = 'crFnbArchiveModal';
        } else if (entityType === this.crConstants.entity.types.MENU_CATEGORIES) {
            options.component = 'crCategoryArchiveModal';
        }

        return this.getStateConfig(
            entityType,
            items,
            text.archive,
            text.archiveConfirmationMessage,
            this.crConstants.entity.states.ARCHIVED,
            text.description[entityType],
            options
        );
    }

    getImageShape(entityType) {
        if (entityType === this.crConstants.entity.types.MENUS) {
            return 'hide';
        }
        return entityType === this.crConstants.entity.types.EVENTS ? 'square' : 'circle';
    }

    getStateUpdateMethod(entityType, state) {
        const isArchive = state === this.crConstants.entity.states.ARCHIVED;
        const { types } = this.crConstants.entity;
        switch (entityType) {
            case types.MENU_CATEGORIES:
                if (isArchive) {
                    return this.deleteEntities.bind(this);
                }
                return this.updateEntities.bind(this);
            case types.MENU_PRODUCTS:
                return this.updateEntities.bind(this);
            default:
                return this.updateEntitiesState.bind(this);
        }
    }

    getStateConfig(entityType, items, menuText, modalTitle, state, description, options) {
        return {
            text: menuText,
            click: () => {
                this.$uibModal
                    .open({
                        backdrop: 'static',
                        component: options ? options.component : 'crActionSummaryModal',
                        resolve: {
                            type: () => this.crConstants.modalTypes.SUBMIT,
                            title: () => `${modalTitle} ${this.getEntityName(entityType)}:`,
                            description: () => description,
                            imageShape: () => this.getImageShape(entityType),
                            items: () => _.map(items, (item) => item.originalData),
                            submitText: () => this.getSubmitText(state, entityType, items.length > 1),
                        },
                    })
                    .result.then(
                        () => {
                            const selectedItems = this.setState(items, state);
                            const params = [entityType, selectedItems, state];
                            const stateUpdateMethod = this.getStateUpdateMethod(entityType, state);

                            stateUpdateMethod(...params).then((results) => {
                                let successMsg = '';
                                const entityName = this.getEntityName(entityType);
                                const actionText = text.stateActionMap[results.action];
                                const stateText = text.stateMap[results.action];
                                const failureMsg = `${text.failedTo} <strong>${actionText}</strong> ${text.theFollowing} ${entityName}:`;

                                if (results.success.length) {
                                    const resultCount = results.success.length;
                                    successMsg = `${text.successfullySet} ${resultCount} ${entityName} ${text.to} ${stateText}`;
                                }

                                this.refreshData(entityType, successMsg, failureMsg, results.failure);
                            });
                        },
                        () => {
                            this.crAnalyticsService.track('Bulk Action - cancel');
                        }
                    );
            },
        };
    }

    getSubmitText(state, entityType, isPlural) {
        return `${text.submitText[state]} ${this.getEntityName(entityType, isPlural)}`;
    }

    refreshData(entityType, successMsg, failureMsg, problemUpdates) {
        const stateParams = this.$state.params;
        stateParams.toast = { msg: successMsg };
        const { types } = this.crConstants.entity;

        this.$state.reload(this.$state.current.name).then(() => {
            if (problemUpdates.length) {
                let problems = _.cloneDeep(problemUpdates);
                if (entityType === types.COUPONS) {
                    problems = problems.filter(
                        (problem) => _.get(problem, 'error.errors.0.type') !== 'ASSOCIATED_TO_ENTITIES'
                    );
                }

                if (problems.length > 0) {
                    this.showErrorModal(entityType, failureMsg, problems);
                }

                if (problemUpdates.length > problems.length) {
                    this.$uibModal
                        .open({
                            backdrop: 'static',
                            component: 'crSimpleModal',
                            windowClass: 'cr-modal-size-sm',
                            resolve: {
                                message: () => text.couponLinkedError,
                            },
                        })
                        .result.catch(() => null);
                }
            }
        });
    }

    showErrorModal(entityType, title, items) {
        this.$uibModal
            .open({
                backdrop: 'static',
                component: 'crActionSummaryModal',
                resolve: {
                    type: () => this.crConstants.modalTypes.CANCEL,
                    title: () => title,
                    imageShape: () => this.getImageShape(entityType),
                    items: () => items,
                },
            })
            .result.then(
                () => {
                    // intentionally empty
                },
                () => {
                    this.crAnalyticsService.track('Bulk Action - Error', {
                        items: _.map(items, (item) => item.name).join(','),
                    });
                }
            );
    }

    setState(items, state) {
        return _.map(items, (item) => {
            item.originalData.state = state;
            return item.originalData;
        });
    }

    getEntityName(entityType, isPlural) {
        return this.crEntityLocalizationService.getLocalizedEntityName(entityType, isPlural);
    }
}

export default EntitySelectionManager;
