import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TooltipModule } from 'ngx-bootstrap';
import { ChipModule } from '../components/chip/chip.module';
import { ImgHolderModule } from '../components/img-holder/img-holder.module';
import { CrIconModule } from '../icon/icon.module';

import { CrTableComponent } from './table.component';
import { ChipColumnComponent } from './chip-column/chip-column.component';

@NgModule({
  imports: [BrowserModule, ImgHolderModule, ChipModule, CrIconModule, TooltipModule],
  exports: [CrTableComponent],
  declarations: [CrTableComponent, ChipColumnComponent],
  providers: [],
})
export class CrTableModule { }
