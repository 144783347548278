import _ from 'lodash';
import locationTargetUrl from '../../../../commons/basic/resources/images/location_target.svg';
import { AppNav } from '../../../../core';
import text from './resources/locale/en.json';

function getLocaleTitle(entity, currentLocale) {
    const localeTitle = entity.localization[currentLocale].title;
    return localeTitle || entity.title;
}

function getLocaleDescription(entity, currentLocale) {
    const localeDesc = entity.localization[currentLocale].description;
    return localeDesc || entity.description;
}

function getLocaleCta(entity, currentLocale) {
    const localeCta = entity.localization[currentLocale].callsToAction;
    return localeCta && localeCta.length > 0 ? localeCta : entity.callsToAction;
}

const QSMART_RIDE_SCOPE = 'QSMART_RIDE';

class PoiEditController {
    constructor(
        $q,
        $state,
        $uibModal,
        crEntityService,
        crLocaleService,
        crConstants,
        poiConstants,
        poiEditService,
        navigationService,
        crImageService,
        crErrorLoggingService,
        graphicalMapService,
        crAnalyticsService,
        crPoiMapService
    ) {
        this.$q = $q;
        this.$state = $state;
        this.$uibModal = $uibModal;
        this.crEntityService = crEntityService;
        this.crLocaleService = crLocaleService;
        this.crConstants = crConstants;
        this.imageConstants = crConstants.image;
        this.poiConstants = poiConstants;
        this.poiEditService = poiEditService;
        this.navigationService = navigationService;
        this.crImageService = crImageService;
        this.crErrorLoggingService = crErrorLoggingService;
        this.graphicalMapService = graphicalMapService;
        this.crAnalyticsService = crAnalyticsService;
        this.crPoiMapService = crPoiMapService;
    }

    $onInit() {
        this.poi = {};
        this.poiForm = {};
        this.text = text;
        this.isLoading = false;
        this.hasDataError = false;

        this.label = this.edit ? this.text.editPOI : this.text.createPOI;

        this.locale = this.crLocaleService.getLocale(this.$state.params.venueId, this.$state.params.locale, !this.edit);
        this.localizedLists = this.crLocaleService.getLocaleObject(this.locale.list);

        this.entityType = this.crConstants.entity.types.POIS;

        this.options = {
            attributes: [],
            states: _.cloneDeep(this.poiConstants.stateItems),
            landmark: [
                {
                    label: this.text.landmark,
                    name: 'landmark',
                    checked: false,
                },
            ],
            virtualQueueingEnabled: [
                {
                    label: this.text.enableVirtualQueueing,
                    name: 'virtualQueueingEnabled',
                    checked: false,
                },
            ],
            rides: [],
            extensions: [],
            poiTypes: {},
            handlers: {
                onLatLonChange: this.updateXY.bind(this),
            },
        };

        this.poiMapCheckConfig = [
            {
                label: this.text.showNearbyPOI,
                name: 'poiMapCheck',
                checked: true,
            },
        ];

        this.marker = {
            source: locationTargetUrl,
            w: this.crConstants.marker.defaultDimensions.h,
            h: this.crConstants.marker.defaultDimensions.w,
            x: 0,
            y: 0,
        };

        this.markers = [this.marker];

        this.getData();
        this.getPoiMapData();

        this.navigationService.enableConfirmNavigation(() => this.poiForm.$dirty);

        this.debouncedUpdateLatLon = _.debounce(this.updateLatLon, this.crConstants.debounce.SHORT);

        // cache all lists by locale so the UI is snappy
        this.locale.list.forEach((lang) => {
            if (lang.id !== this.locale.default) {
                this.getLocalizedAttributeList(lang.id);
            }
        });
    }

    onTitleChange(event) {
        if (this.locale.current === this.locale.default) {
            // Update main model to stay in sync
            this.poi.title = event.model;
        }
        this.poi.localization[this.locale.current].title = event.model;

        this.poi = _.cloneDeep(this.poi);
        this.updatePhonePreview();
    }

    onDescriptionChange(event) {
        if (this.locale.current === this.locale.default) {
            // Update main model to stay in sync
            this.poi.description = event.model;
        }
        this.poi.localization[this.locale.current].description = event.model;

        this.poi = _.cloneDeep(this.poi);
        this.updatePhonePreview();
    }

    onImagesChange(event) {
        this.poi.images = event.model;
        this.poi = _.cloneDeep(this.poi);
        this.updatePhonePreview();
    }

    onLocaleChange(event) {
        this.locale.current = event.locale.id;
        this.locale = _.cloneDeep(this.locale);

        this.updatePhonePreview();
    }

    onVirtualQueueingEnabledChange(event) {
        this.options.virtualQueueingEnabled[0].checked = event.model.virtualQueueingEnabled;
        if (event.model.virtualQueueingEnabled) {
            const selected = _.first(this.options.rides.filter((ride) => ride.isSelected));
            this.onRideUpdate({
                model: [selected],
            });
        } else {
            this.onRideUpdate();
        }
        this.dirtyForm();
        this.updatePhonePreview();
    }

    onRideUpdate(event = {}) {
        this.poi.externalIds = this.poi.externalIds.filter((eid) => eid.scope !== QSMART_RIDE_SCOPE);
        const ride = _.first(event.model);
        if (ride && ride.id) {
            this.poi.externalIds.push({
                id: ride.id,
                scope: QSMART_RIDE_SCOPE,
            });
        }
    }

    getQsmartRide() {
        return _.find(this.poi.externalIds, (eid) => eid.scope === QSMART_RIDE_SCOPE);
    }

    onCtaChange(event) {
        if(event.model && event.model.length > 0){
            event.model.forEach((cta, index) => {
                cta.displayOrder = index;
            });
        }
        if (this.locale.current === this.locale.default) {
            // Update main model to stay in sync
            this.poi.callsToAction = event.model;
            if (!this.poi.localization[this.locale.default].callsToAction) {
                // init each locale with default cta model
                _.forEach(this.poi.localization, (locale) => {
                    locale.callsToAction = [{ label: '', link: '', appNav: '' }];
                });
            }
        }

        if (event.model) {
            this.poi.localization[this.locale.current].callsToAction = event.model;
            this.poi.localization[this.locale.current].callsToAction.forEach((cta, index) => {
                this.updateAppNav(cta, index);
            });
        } else {
            this.resetCtaAndLocale();
        }

        this.poi = _.cloneDeep(this.poi);
        this.updatePhonePreview();
    }

    updateAppNav(ctaModel, index) {
        if (!ctaModel.appNav) {
            switch (ctaModel.type) {
                case 'WEB': {
                    this.onAppNavChange(new AppNav.Webview(this.$state.params.venueId, ctaModel.link), index);
                    break;
                }
                default:
                    break;
            }
        } else {
            switch (ctaModel.type) {
                case 'WEB': {
                    const appNav = new AppNav.Webview(this.$state.params.venueId, ctaModel.link);
                    this.onAppNavChange(appNav, index);
                    break;
                }
                default: {
                    this.onAppNavChange(null, index);
                    break;
                }
            }
        }
    }

    onAppNavChange(appNav, index) {
        try {
            const appNavUrl = appNav ? appNav.toString() : null;
            if (this.locale.current === this.locale.default) {
                this.poi.callsToAction[index].appNav = appNavUrl;
            }
            this.poi.localization[this.locale.current].callsToAction[index].appNav = appNavUrl || '';
        } catch (err) {
            this.crErrorLoggingService.logError('could not serialize appnav', err);
        }
    }

    resetCtaAndLocale() {
        this.locale = this.crLocaleService.getLocale(this.$state.params.venueId, this.locale.default, true);
        this.poi.callsToAction = [];
        this.poi.callToAction = null;
        _.forEach(this.poi.localization, (locale) => {
            locale.callToAction = null;
            locale.callsToAction = [];
        });
    }

    onLandmarkChange(event) {
        this.poi.landmark = event.model.landmark;
        this.dirtyForm();
    }

    getLocalizedAttributeList(currentLocale) {
        const queryParams = [
            this.crConstants.entity.types.POI_ATTRIBUTES,
            { venueId: this.$state.params.venueId, sort: 'title,asc' },
            currentLocale,
        ];

        return this.crEntityService
            .getEntityList(...queryParams)
            .then((res) => {
                this.localizedLists[currentLocale].availableAttributes = res.content;
                this.updatePhonePreview();
            })
            .catch((err) => {
                this.crErrorLoggingService.logError('could not retrieve localized details', err);
            });
    }

    getData() {
        const poiId = this.$state.params.id;
        this.isLoading = true;

        this.$q
            .all([
                poiId ? this.getEditData() : this.getCreateData(),
                this.getPoiTypes(),
                this.poiEditService.getPoiCategories(this.$state.params.venueId).catch((err) => {
                    this.crErrorLoggingService.logError('could not get categories', err);
                }),
                this.poiEditService.getPoiAttributes(this.$state.params.venueId).catch((err) => {
                    this.crErrorLoggingService.logError('could not get attributes', err);
                }),
                this.poiEditService.getMenus(this.$state.params.venueId, poiId, this.locale.default).catch((err) => {
                    this.crErrorLoggingService.logError('could not get menus', err);
                    return [];
                }),
                this.poiEditService.getQsmartRides(this.$state.params.venueId),
            ])
            .then((results) => {
                const categories = results[2];
                const attributes = results[3];
                const menuOptions = results[4];
                const rideOptions = results[5];

                const { availableCategories, displayCategories } = this.poiEditService.attributeCategoryChipLabels(
                    categories,
                    this.poi.displayCategories
                );

                this.poi.displayCategories = displayCategories;
                this.currentTags = _.cloneDeep(displayCategories);
                this.options.availableCategories = availableCategories;
                this.options.availableAttributes = attributes;
                this.options.attributes = _.cloneDeep(this.poi.attributes);
                this.options.landmark[0].checked = this.poi.landmark;

                this.options.menus = menuOptions;

                if (rideOptions.length) {
                    this.options.rides = rideOptions.map((ride) => ({
                        isSelected: false,
                        ...ride,
                    }));
                }

                const qsmartRide = this.getQsmartRide() || {};
                if (qsmartRide.id) {
                    this.options.virtualQueueingEnabled[0].checked = true;
                    this.options.rides.forEach((ride) => {
                        ride.isSelected = ride.id === qsmartRide.id;
                    });
                }

                this.initStateValues();
                this.initPoiTypeValues();
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    getEditData() {
        this.isLoading = true;

        return this.crEntityService
            .getEntity(this.entityType, this.$state.params.id, this.$state.params.venueId, this.locale.default)
            .then((details) => {
                this.poi = details;

                if (!this.edit) {
                    this.poi.name = `${this.text.copyOf} ${this.poi.name}`;
                    this.isCopy = true;

                    // remove copied entity's id
                    this.$state.params.id = null;
                    this.$state.go(this.$state.current.name, this.$state.params, {
                        location: 'replace',
                    });
                }

                const typeId = details.type ? details.type.id : '';

                this.poi.type = { id: typeId };

                if (typeId) {
                    this.showTypeDropdown = true;
                }

                this.poi.attributes = this.poi.attributes || [];
                if (!_.isEmpty(this.poi.attributes)) {
                    this.crImageService.setIconSrc(this.poi.attributes);
                }
                this.poi.extensions = (this.poi.extensions || []).map((extension) => ({
                    id: extension.id,
                    value: JSON.stringify(extension.value, false, '  '),
                }));

                this.options.extensions = _.cloneDeep(this.poi.extensions);

                this.poi.externalIds = _.cloneDeep(this.poi.externalIds) || [];

                this.getMapMarkerData([this.poi.location]);
                this.updatePhonePreview();

                // hack localization reset model
                _.forEach(this.poi.localization, (locale) => {
                    if (_.isObject(locale.callToAction) && _.isEmpty(locale.callToAction)) {
                        locale.callToAction = null;
                    }
                    if(locale.callsToAction && locale.callsToAction.length < 0){
                        locale.callsToAction = [];
                    }
                });
            })
            .catch((err) => {
                this.crErrorLoggingService.logError('Could not get poi details', err, this.$state.params.id);

                if (err.status === 404) {
                    this.$state.go('client.404', {
                        customerId: this.$state.params.customerId,
                        venueId: this.$state.params.venueId,
                    });
                } else {
                    this.$uibModal
                        .open({
                            backdrop: 'static',
                            component: 'crSimpleModal',
                            windowClass: 'cr-modal-size-sm',
                            resolve: {
                                message: () => this.text.standardErrorMessage,
                            },
                        })
                        .result.catch(() => {
                            this.$state.go('client.poi.list', {
                                customerId: this.$state.params.customerId,
                                venueId: this.$state.params.venueId,
                            });
                        });
                }
            });
    }

    getCreateData() {
        this.mapLoading = true;
        this.mapError = false;

        this.poi = {
            attributes: [],
            extensions: [],
            externalIds: [],
            displayCategories: [],
            landmark: false,

            // TODO: handle PLACE as well
            owner: {
                id: this.$state.params.venueId,
                type: 'VENUE',
            },
            state: '',
            type: {},
            location: {},
        };

        // init localization property
        this.poi.localization = {};
        _.forEach(this.locale.list, (lang) => {
            this.poi.localization[lang.id] = {};
        });

        this.getMapMarkerData([]);
        this.updatePhonePreview();
    }

    getPoiMapData() {
        const state = 'ACTIVE';
        const { venueId } = this.$state.params;
        this.crPoiMapService.getData(venueId, { venueId, state }).then((data) => {
            const poiList = this.crPoiMapService.mapToMarkersModel(data.pois);

            if (this.$state.params.id) {
                const poiIndex = _.findIndex(poiList, { id: this.$state.params.id });
                poiList.splice(poiIndex, 1);
            }

            this.poiList = poiList;
            this.markers = this.poiList.concat(this.markers);
        });
    }

    getMapMarkerData(locations) {
        return this.graphicalMapService
            .getMapMarkerData(this.$state.params.venueId, locations)
            .then((res) => {
                this.defaultMapId = res.defaultMapId;
                if (res.translatedLocations.length) {
                    this.focus = [res.translatedLocations[0].position.x, res.translatedLocations[0].position.y];
                }

                this.mapImageSrc = res.mapImageSrc;

                if (!this.$state.params.id) {
                    return this.graphicalMapService
                        .getLatLonFromXY(this.$state.params.venueId, this.defaultMapId, { x: 0.5, y: 0.5 })
                        .then(() => {
                            this.setLatLon(res);
                        });
                }
            })
            .catch((err) => {
                this.mapError = true;
                this.crErrorLoggingService.logError(
                    'Couldnt not get graphical map for poi',
                    err,
                    this.$state.params.id
                );
            })
            .finally(() => {
                this.mapLoading = false;
            });
    }

    onHeaderUpdate(event) {
        this.poi.images = event.model.images;
        this.poi.name = event.model.name;
        this.poi.state = event.model.state;

        this.poi = _.cloneDeep(this.poi);
    }

    onTypeUpdate(event) {
        this.poi.type.id = event.model[0].id;
    }

    addType() {
        this.showTypeDropdown = true;
    }

    removeType() {
        this.dirtyForm();
        this.poi.type = null;
        this.showTypeDropdown = false;
        this.options.poiTypes.forEach((type) => {
            type.isSelected = false;
        });
    }

    onCategoriesSelect(categories) {
        this.poi.displayCategories = categories;
    }

    onAttributesChange($event) {
        this.poi.attributes = $event.rows;
        this.updatePhonePreview();
    }

    onExtensionsChange($event) {
        this.poi.extensions = $event.rows;
        this.poi.localization[this.locale.current].extensions = $event.rows;
    }

    getPhoneModel(entity, locale) {
        const phoneData = _.cloneDeep(entity);
        const isDefault = locale.current === locale.default;

        if (!isDefault) {
            phoneData.title = getLocaleTitle(phoneData, locale.current);
            phoneData.description = getLocaleDescription(phoneData, locale.current);
            phoneData.attributes = this.getLocaleAttrs(phoneData.attributes, locale.current);
            phoneData.callToAction = getLocaleCta(phoneData, locale.current);
            phoneData.callsToAction = getLocaleCta(phoneData, locale.current)
        }

        if (this.options.virtualQueueingEnabled[0].checked) {
            phoneData.callToAction = {
                label: this.text.qsmartCtaLabel,
                link: 'virtual-queue',
            };
        }

        return phoneData;
    }

    updatePhonePreview() {
        this.phoneData = _.cloneDeep(this.getPhoneModel(this.poi, this.locale));
    }

    getLocaleAttrs(attributes = [], currentLocale) {
        const attrs = [];
        const attributeMap = _.groupBy(this.localizedLists[currentLocale].availableAttributes, 'id');

        attributes.forEach((entity) => {
            const localizedAttr = _.first(attributeMap[entity.id]);
            if (localizedAttr) {
                localizedAttr.value = entity.value
                    ? entity.value.map((val) => _.find(localizedAttr.enumValues, { id: val.id }))
                    : [];

                attrs.push(localizedAttr);
            }
        });

        return attrs;
    }

    updateLatLon() {
        this.graphicalMapService
            .getLatLonFromXY(this.$state.params.venueId, this.defaultMapId, {
                x: this.marker.x / this.mapImage.width,
                y: this.marker.y / this.mapImage.height,
            })
            .then((res) => {
                this.setLatLon(res);
                this.dirtyForm();
            })
            .catch((err) => {
                this.crErrorLoggingService.logError('Could not get lat-lon from x-y', err);
            });
    }

    setLatLon(location) {
        if (location && !_.isNil(location.lat) && !_.isNil(location.lon)) {
            this.poi.location.lat = location.lat;
            this.poi.location.lon = location.lon;
        }
    }

    onUpdateLat($event) {
        this.poi.location.lat = $event.model;

        this.updateXY();
    }

    onUpdateLon($event) {
        this.poi.location.lon = $event.model;

        this.updateXY();
    }

    updateXY() {
        if (!_.isNil(this.poi.location.lat && !_.isNil(this.poi.location.lon))) {
            this.graphicalMapService
                .getXYFromLatLon(this.$state.params.venueId, this.defaultMapId, this.poi.location)
                .then((res) => {
                    const { position } = res;

                    this.focus = [
                        Math.min(this.mapImage.width, Math.max(0, position.x * this.mapImage.width)),
                        Math.min(this.mapImage.height, Math.max(0, position.y * this.mapImage.height)),
                    ];

                    this.marker.x = this.focus[0];
                    this.marker.y = this.focus[1];
                })
                .catch((err) => {
                    this.crErrorLoggingService.logError('Could not get x-y from lat-lon', err);
                });
        }
    }

    onPoiMapCheckChange(event) {
        if (event.model.poiMapCheck) {
            this.markers = this.poiList.concat(this.markers);
        } else {
            this.markers = [_.last(this.markers)];
        }
    }

    onMapLoad(event) {
        this.mapImage = event.mapImage;
        this.canvas = event.canvas;
        if (!this.focus) {
            this.focus = [this.mapImage.width / 2, this.mapImage.height / 2];
        }
        this.marker.x = this.focus[0];
        this.marker.y = this.focus[1];
    }

    onMapClick(e) {
        if (this.fullscreen) {
            const mapX = Math.min(this.mapImage.width, Math.max(0, e.mapX));
            const mapY = Math.min(this.mapImage.height, Math.max(0, e.mapY));
            this.marker.x = mapX;
            this.marker.y = mapY;

            this.focus[0] = mapX;
            this.focus[1] = mapY;

            this.debouncedUpdateLatLon();
        }
    }

    onMapDrag(e) {
        if (!this.fullscreen) {
            this.marker.x = Math.min(this.mapImage.width, Math.max(0, this.marker.x - e.mapMovementX));
            this.marker.y = Math.min(this.mapImage.height, Math.max(0, this.marker.y - e.mapMovementY));

            this.debouncedUpdateLatLon();
        }
    }

    initStateValues() {
        _.forEach(this.options.states, (state) => {
            // Set current state
            state.isSelected = state.id === this.poi.state;

            // Don't allow draft from other states
            state.isDisabled = state.restricted && state.id !== this.poi.state;
        });
    }

    initPoiTypeValues() {
        _.forEach(this.options.poiTypes, (type) => {
            // Set current poi type
            type.isSelected = type.id === this.poi.type.id;
        });
    }

    getPoiTypes() {
        return this.poiEditService
            .getPoiTypes(this.$state.params.venueId)
            .then((types) => {
                this.options.poiTypes = types;
            })
            .catch((err) => {
                this.crErrorLoggingService.logError('Could not get poi types', err);
            });
    }

    showErrorModal() {
        const message = this.edit ? this.text.updateError : this.text.createError;

        this.$uibModal
            .open({
                backdrop: 'static',
                component: 'crSimpleModal',
                windowClass: 'cr-modal-size-sm',
                resolve: {
                    message: () => message,
                },
            })
            .result.catch(() => null);
    }

    getToastParams(poiData) {
        const toastMsg = this.edit ? this.text.updateSuccess : this.text.createSuccess;

        const params = {
            id: poiData.id,
            toast: {
                msg: `"${poiData.name}" ${toastMsg}`,
            },
        };

        return params;
    }

    onSaveSuccess(poiData) {
        this.navigationService.disableConfirmNavigation();
        const params = this.getToastParams(poiData);
        if (this.edit) {
            this.crAnalyticsService.track('Edit POI Success', poiData);
            this.navigationService.goBack('client.poi.details', params);
        } else {
            this.crAnalyticsService.track('Add New POI Success', poiData);
            this.navigationService.goToFromCreate('client.poi.list', params);
        }
    }

    handleError(errMsg, err) {
        this.showErrorModal();
        this.crErrorLoggingService.logError(errMsg, err);
    }

    dirtyForm() {
        this.poiForm.$setDirty();
    }

    goBack() {
        if (this.edit) {
            this.crAnalyticsService.track('Edit POI Canceled');
            this.navigationService.goBack('client.poi.details', { id: this.poi.id });
        } else {
            this.crAnalyticsService.track('Add New POI Canceled');
            this.navigationService.goBack('client.poi.list');
        }
    }

    updateEntity(type, id, entity, venueId) {
        return this.crEntityService
            .updateEntity(type, id, entity, venueId)
            .then(() => {
                this.onSaveSuccess(entity);
            })
            .catch((err) => {
                this.handleError('Could not update poi', err);
                this.saving = false;
            });
    }

    createEntity(type, entity, venueId) {
        this.crEntityService
            .createEntity(type, entity, venueId)
            .then(() => {
                this.onSaveSuccess(entity);
            })
            .catch((err) => {
                this.handleError('Could not create poi', err);
                this.saving = false;
            });
    }

    mapCallsToActionDataOnSave(){
        if(this.poi.callsToAction && this.poi.callsToAction.length > 0 && this.locale && this.locale.list && this.locale.list.length > 0){
            this.poi.callsToAction = this.poi.callsToAction.map((cta, index) => {
                let ct = {};
                ct.type = cta.type || "WEB";
                ct.displayOrder = index;
                ct.appNav = cta.appNav;
                ct.label = cta.label;
                ct.options = cta.options;
                ct.link = cta.link;
                return ct;
            });
            this.poi.callToAction = null;

            //saving localized data
            this.locale.list.forEach((lang) => {

                //default locale mapping
                if(lang.id === this.locale.default) {
                    this.poi.localization[lang.id].callsToAction = this.poi.localization[lang.id].callsToAction.map((cta) => {
                        let ct = {};
                        ct.type = cta.type || 'WEB';
                        ct.appNav = cta.appNav;
                        ct.label = cta.label;
                        ct.link = cta.link;
                        ct.options = cta.options;
                        return ct;
                    });
                    this.poi.localization[lang.id].callToAction = null;
                }

                //non default locale mapping. Non default locale CTA should be same or less than default locale CTA
                if(lang.id !== this.locale.default) {
                    const defaultLocaleCtaCount = this.poi.localization[this.locale.default].callsToAction.length;
                    const ctas = [];
                    for(let index = 0; index < defaultLocaleCtaCount; index++) {
                        let cta = {}
                        if(typeof this.poi.localization[lang.id].callsToAction[index] === 'undefined'){

                            //handles the delete for localization PUT endpoint
                            cta = {
                                type: '',
                                appNav: '',
                                label: '',
                                link: '',
                                options: ''

                            };
                        }
                        else {
                            cta = {
                                type: this.poi.localization[lang.id].callsToAction[index].type || "WEB",
                                appNav: this.poi.localization[lang.id].callsToAction[index].appNav,
                                label: this.poi.localization[lang.id].callsToAction[index].label,
                                link: this.poi.localization[lang.id].callsToAction[index].link,
                                options: this.poi.localization[lang.id].callsToAction[index].options
                            };

                        }
                        ctas.push(cta);
                    }
                    this.poi.localization[lang.id].callsToAction = ctas;
                    this.poi.localization[lang.id].callToAction = null;
                }

            });

        }
        if(this.poi.callsToAction.length === 0){
            this.poi.callToAction = null;

            if(this.locale.list && this.locale.list.length > 0){
                this.locale.list.forEach((lang) => {
                    if(lang.id) {
                        this.poi.localization[lang.id].callToAction = null;
                        this.poi.localization[lang.id].callsToAction = [];
                    }
                });
            }
        }
    }


    save() {
        this.poiForm.$setSubmitted();
        if (this.poiForm.$valid) {
            if (this.poiForm.$dirty || this.isCopy) {
                this.saving = true;
                this.mapCallsToActionDataOnSave();
                const { entityType } = this;
                const { id } = this.$state.params;
                const payload = this.crEntityService.prepareEntityPayload(this.poi);
                const { venueId } = this.$state.params;

                // TODO: make the GSR do this
                if (payload.callsToAction === null) {
                    _.forEach(payload.localization, (locale) => {
                        locale.callsToAction = [{ label: '', link: '', appNav: '' }];
                    });
                }


                if (this.edit) {
                    this.updateEntity(entityType, id, payload, venueId);
                } else {
                    this.createEntity(entityType, payload, venueId);
                }
            } else {
                this.goBack();
            }
        }
    }
}

export default PoiEditController;
