import controller from './breadcrumbs.controller';
import template from './breadcrumbs.html';

import './breadcrumbs.less';

const component = {
    template,
    bindings: {
        steps: '<',
        currentStep: '<',
        onStepChange: '&',
    },
    controller: [controller],
};

export default component;
