import * as angular from 'angular';
import { downgradeInjectable } from '@angular/upgrade/static';
import { ErrorLoggingService } from './error-logging.service';

export const Ng1ErrorLoggingModule = angular
  .module('control-room.angular.error-logging-service', [
    'control-room.angular.config',
    'control-room.angular.analytics-service',
  ])
  .factory('crErrorLoggingService', downgradeInjectable(ErrorLoggingService));
