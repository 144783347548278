import text from './resources/locale/en.json';

import ReorderList from '../../../../commons/controller/reorder-list';

class VenueHoursEditOrder extends ReorderList {
    constructor(
        $state,
        $uibModal,
        crConstants,
        crEntityService,
        crNavigationService,
        crErrorLoggingService,
        crAnalyticsService
    ) {
        super();
        this.$state = $state;
        this.$uibModal = $uibModal;
        this.crConstants = crConstants;
        this.crEntityService = crEntityService;
        this.crNavigationService = crNavigationService;
        this.crErrorLoggingService = crErrorLoggingService;
        this.crAnalyticsService = crAnalyticsService;
    }

    $onInit() {
        this.text = text;
        this.form = {};
        this.isLoading = true;
        this.hasDataError = false;
        this.entityType = this.crConstants.entity.types.VENUE_HOURS;

        this.crEntityService
            .getEntityList(this.entityType, { venueId: this.$state.params.venueId })
            .then((res) => {
                this.schedules = res.content;
            })
            .catch((err) => {
                this.hasDataError = true;
                this.crErrorLoggingService.logError('could not retrieve venue hours', err);
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    save() {
        this.isLoading = true;

        const data = {
            owner: {
                id: this.$state.params.venueId,
                type: 'VENUE',
            },
            ids: this.schedules.map((schedule) => ({ id: schedule.id })),
        };

        if (this.form.$dirty) {
            this.crEntityService
                .updateRanks(data, this.entityType, this.$state.params.venueId )
                .then(() => {
                    const newOrder = this.schedules.map((schedule) => schedule.label);
                    this.crAnalyticsService.track('Venue hours rank updated', newOrder);
                    this.crNavigationService.disableConfirmNavigation();
                    this.goBack({
                        toast: { msg: this.text.toastMsg },
                    });
                })
                .catch((err) => {
                    this.showErrorModal();
                    this.crErrorLoggingService.logError('Could not update Venue Hours Ranks', err);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        } else {
            this.goBack();
        }
    }

    showErrorModal() {
        this.$uibModal
            .open({
                backdrop: 'static',
                component: 'crSimpleModal',
                windowClass: 'cr-modal-size-sm',
                resolve: {
                    message: () => this.text.updateError,
                },
            })
            .result.catch(() => null);
    }

    goBack(params = {}) {
        this.crNavigationService.goBack('client.venue-hours.order', params);
    }
}

export default VenueHoursEditOrder;
