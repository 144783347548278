import _ from 'lodash';

class OrdersMockService {
    constructor() {
        this.monthsAvailable = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

        this.itemsAvailable = [
            'Smokehouse Brisket Sandwich',
            'Reuben',
            'Loaded Italian',
            'Roast Turkey Gyro',
            'Roast Beef Gyro',
            'Classic Roast Beef',
            'Roast Beef - Mid',
            'Roast Beef - Max',
            'Beef n Cheddar - Classic',
            'Beef n Cheddar - Mid',
            'French Dip & Swiss',
            'Three Cheese Steak Sandwich',
            'Jalapeno Roast Beef Slider',
            "Corned Beef 'N Cheese Slider",
            'Reuben',
            "Roast Beef 'N Cheese Slider",
            'Smokehouse Brisket Sandwich',
            'Roast Beef Gyro',
            'Fire Roasted Philly',
            'Buttermilk Chicken Cordon Bleu Sandwich',
            'Loaded Italian',
            "Ham 'N Cheese Slider",
            'Buttermilk Chicken Bacon & Swiss',
            'Crispy Chicken Farmhouse Salad',
            'Grand Turkey Club',
            'Roast Turkey Farmhouse Salad',
            'Roast Turkey Ranch & Bacon Sandwich',
            'Jr. Ham and Cheddar Melt',
            'Jr. Bacon Cheddar Melt',
        ];

        this.topCities = [
            'Los Angeles',
            'San Diego',
            'San Jose',
            'San Francisco',
            'Fresno',
            'Sacramento',
            'Long Beach',
            'Oakland',
            'Bakersfield',
            'Anaheim',
            'Santa Ana',
            'Riverside',
            'Stockton',
            'Chula Vista',
            'Irvine',
            'Fremont',
        ];

        this.bottomCities = [
            'Orinda',
            'Los Altos',
            'Monte Sereno',
            'Mill Valley',
            'Ross',
            'San Pasqual',
            'Hidden Hills',
            'Rancho Santa Margarita',
            'Saratoga',
            'Kentfield',
            'Manhattan Beach',
            'Rancho Santa Fe',
            'San Ramon',
            'Camino Tassajara',
            'Corte Madera',
            'Ladera Ranch',
        ];
    }

    getOrdersPlacedAndTopItemsPurchased() {
        return new Promise((resolve) => {
            Promise.all(this.getOrdersPlaced(), this.getTopItemsPurchased()).then((values) => {
                resolve({
                    ordersPlaced: values[0],
                    topItemsPurchased: values[1],
                });
            });
        });
    }

    getOrdersPlaced() {
        return new Promise((resolve) => {
            const series = [];

            const startIndex = Math.floor(Math.random() * 6) + 1;
            const labels = this.monthsAvailable.slice(startIndex, startIndex + 6);

            labels.forEach(() => {
                const orderCount = Math.floor(Math.random() * 5000) + 3000;

                series.push({
                    value: orderCount,
                    meta: orderCount * 3.02,
                });
            });

            resolve({
                labels,
                series: [series],
            });
        });
    }

    getTopItemsPurchased() {
        return new Promise((resolve) => {
            const series = [];

            const labels = _.shuffle(this.itemsAvailable).slice(0, 10);

            let top = Math.floor(Math.random() * 1000) + 5000;

            labels.forEach(() => {
                series.push(top);

                top -= Math.floor(Math.random() * 300) + 50;
            });

            resolve({
                labels,
                series: [series],
            });
        });
    }

    getTopPlaces() {
        return new Promise((resolve) => {
            const rows = [];

            this.topCities = _.shuffle(this.topCities);

            let top = Math.floor(Math.random() * 1000) + 3000;

            for (let i = 0; i < 5; i++) {
                rows.push({
                    data: {
                        place: {
                            name: this.topCities[i],
                            id: Math.floor(Math.random() * 40000) + 10000,
                        },
                        orders: top,
                        revenue: top * 3.02,
                    },
                });

                top -= Math.floor(Math.random() * 500) + 50;
            }

            resolve({
                columns: [{ label: 'Place' }, { label: 'Orders placed' }, { label: 'Revenue' }],
                rows,
            });
        });
    }

    getBottomPlaces() {
        return new Promise((resolve) => {
            const rows = [];

            this.bottomCities = _.shuffle(this.bottomCities);

            let bottom = Math.floor(Math.random() * 500) + 50;

            for (let i = 0; i < 5; i++) {
                rows.push({
                    data: {
                        place: {
                            name: this.bottomCities[i],
                            id: Math.floor(Math.random() * 40000) + 10000,
                        },
                        orders: bottom,
                        revenue: bottom * 3.02,
                    },
                });

                bottom += Math.floor(Math.random() * 200) + 50;
            }

            resolve({
                columns: [{ label: 'Place' }, { label: 'Orders placed' }, { label: 'Revenue' }],
                rows,
            });
        });
    }
}

export default OrdersMockService;
