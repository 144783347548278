import template from './weekday-viewer.html';
import './weekday-viewer.less';
import Controller from './weekday-viewer.controller';

const Component = {
    template,
    bindings: {
        smallView: '<?',
        selectedDays: '<',
        customClass: '@?',
    },
    controller: ['crConstants', Controller],
};

export default Component;
