import template from './input.html';
import './input.less';
import InputController from './input.controller';

const InputComponent = {
    template,
    bindings: {
        required: '@',
        hideValidationMessaging: '@',
        validation: '<',
        charactersRemaining: '@',
        minlength: '@',
        maxlength: '@',
        inputCustomClass: '@',
        name: '@',
        placeholder: '@',
        disabled: '@',
        value: '@',
        type: '@',
        autofocus: '@',
        onFocus: '&',
        onChange: '&',
        onKeyup: '&',
        onBlur: '&',
    },
    require: {
        form: '?^form',
    },
    controller: ['crInputText', InputController],
    controllerAs: 'inputCtrl',
};

export default InputComponent;
