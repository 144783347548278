<cr-split-view-page pageClass="cr-edit details-page">
  <cr-title-bar [label]="title" (onClick)="goBack()">
    <div titleBarCenter>
      <cr-form-submission-error
        *ngIf="!isLoading && !eventTabsForm.valid && tabsViewChild.submitted"
        [message]="text.formSubmissionErrorText"
      ></cr-form-submission-error>
    </div>
    <div *ngIf="!isLoading && !isSaving" titleBarRight>
      <button
        (click)="goBack()"
        class="btn btn-nobg"
        data-global-qa-id="manage-cancel"
      >
        {{ text.cancel }}
      </button>
      <button
        *ngIf="!isEdit"
        class="btn btn-primary"
        (click)="onSave()"
        data-global-qa-id="manage-save"
      >
        {{ text.save }}
      </button>
    </div>
  </cr-title-bar>
  <section top>
    <div class="text-center cr-page-padding-top" *ngIf="isLoading || isSaving">
      <cr-spinner></cr-spinner>
    </div>
    <cr-data-error
      *ngIf="hasDataError"
      [message]="text.standardDataErrorMessage"
    ></cr-data-error>
  </section>

  <section top class="drag-text" *ngIf="!isLoading">
    {{ text.dragText }}
  </section>

  <section class="cr-left" left>
    <section class="cr-item-row cr-page-padding-top">
      <form
        [formGroup]="eventTabsForm"
        (ngSubmit)="onSubmit()"
        #tabsViewChild="ngForm"
      >
        <div class="cr-label max-limit-text" *ngIf="!isLoading">
          {{ text.maxTabs }}
        </div>
        <ng-template
          #tabItemTemplate
          let-eventTabsReorderItem="item"
          let-i="index"
          let-eventTabsReorderItemsLength="itemsLength"
        >
          <div class="event-tabs-items">
            <div class="eventItem">
              <cr-input
                id="name"
                class="big-input"
                name="name"
                label="{{ text.name }} *"
                [hideValidation]="true"
                type="text"
                [formControl]="eventTabsReorderItem.controls.name"
              ></cr-input>
              <a
                class="cursor-pointer icon-cr-close event-tab-remove"
                *ngIf="
                  eventTabsReorderItem?.controls.name.value !== 'Calendar' &&
                  eventTabsReorderItem?.controls.name.value !== 'Today'
                "
                (click)="onEventsTabItemRemove(i)"
              ></a>
            </div>
          </div>
        </ng-template>
        <cr-reorder-list
          formArrayName="eventTabs"
          [items]="eventTabsReorder.controls"
          [itemTemplate]="tabItemTemplate"
          (reorder)="onEventsTabItemReorder($event)"
          disableMove
        ></cr-reorder-list>
        <div
          class="add-event-tab-item"
          *ngIf="eventTabsReorder.controls.length < 5 && !isLoading"
        >
          <button
            type="button"
            class="btn btn-link"
            (click)="onEventsTabItemAdd()"
            data-global-qa-id="event-tab-add"
          >
            {{ text.addNavigationTabItem }}
          </button>
        </div>
      </form>
    </section>
  </section>
</cr-split-view-page>
