import Controller from './row-manager.controller';
import template from './row-manager.html';
import './row-manager.less';

const Component = {
    template,
    bindings: {
        onUpdate: '&',
        rowsData: '<',
        isSubmitted: '<',
        newRowDataProvider: '&',

        onReorder: '&?',
        addRowLabel: '@?',
        customClass: '@?',
        isRequired: '<?',
        disableAdd: '<?',
    },
    require: {
        form: '^form',
    },
    transclude: true,
    controller: ['crAnalyticsService', 'crConstants', Controller],
};

export default Component;
