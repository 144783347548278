import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { StateService } from '@uirouter/core';
import _ from 'lodash';
import moment from 'moment';
import 'moment-timezone';
import { Constants } from '../../../core/constants';
import { CalendarEvent } from '../../models/event.model';
import { CR_CONSTANTS } from '../constants/constants';
import { VenueService } from '../venue/venue.service';

@Injectable({
    providedIn: 'root',
})
export class ScheduleService {
    private isoDateFormat: string;
    private iCalDateFormat: string;
    private shortDateFormat: string;
    private shortDateNoYearFormat: string;
    private shortTimeFormat: string;
    private isoTimeNoOffset: string;
    private scheduleKey: {
        FULLDAY: string;
        CHECKPARK: string;
    };

    constructor(private http: HttpClient,
                private state: StateService,
                @Inject(CR_CONSTANTS) private constants: Constants,
                private venueService: VenueService) {
        this.init();
    }

    init(): void {
        this.isoDateFormat = this.constants.date.format.isoNoOffset;
        this.iCalDateFormat = this.constants.iCal.format.date;
        this.shortDateFormat = this.constants.date.format.short;
        this.shortDateNoYearFormat = this.constants.date.format.shortNoYear;
        this.shortTimeFormat = this.constants.time.format.short;
        this.isoTimeNoOffset = this.constants.time.format.isoNoOffset;
        this.scheduleKey = this.constants.calendarService.scheduleKey;
    }

    cancelEventSchedule(eventId: string, scheduleId: string, exceptionDateTime: string, tz: string): Promise<any> {
        exceptionDateTime = moment.tz(exceptionDateTime, tz).format(this.isoDateFormat);

        const exceptionData = {
            recurrenceExceptions: this.isoDateToIcalDate(exceptionDateTime),
        };

        return this.http
            .post(`/rest/event-schedule/${eventId}/${scheduleId}/exceptions`, exceptionData)
            .toPromise()
            .then((res) => res);
    }

    cancelExperienceSchedule(experienceId: string, scheduleId: string, exceptionDateTime: string, tz: string): Promise<any> {
        exceptionDateTime = moment.tz(exceptionDateTime, tz).format(this.isoDateFormat);

        const exceptionData = {
            recurrenceExceptions: this.isoDateToIcalDate(exceptionDateTime),
        };

        return this.http
            .post(`/rest/experience-schedule/${experienceId}/${scheduleId}/exceptions`, exceptionData)
            .toPromise()
            .then((res) => res);
    }

    getNextEventInstance(eventId: string): Promise<CalendarEvent> {
        const timezone = this.venueService.getTimezone(this.state.params.venueId).name;
        const params: {
            start: string,
            end: string,
            count: string,
        } = {
            start: undefined,
            end: undefined,
            count: undefined,
        };

        // Get 1 instance from today to 1 year from today (venue time)
        params.start = moment().tz(timezone).toISOString();
        params.end = moment(params.end).tz(timezone).add(366, 'day').startOf('day').toISOString();
        params.count = '1';

        return this.http.get<CalendarEvent>(`/rest/calendars/events/${eventId}`, { params: {
            start: params.start,
            end: params.end,
            count: params.count,
        } }).toPromise().then((res) => res);
    }

    isSameDay(startDate: string, endDate: string, ignoreTimezone = false): boolean {
        let sameDay = false;

        if (!startDate || !endDate) {
            sameDay = false;
        } else if (ignoreTimezone) {
            sameDay = moment(startDate).isSame(moment(endDate), 'day');
        } else {
            const timezone = this.venueService.getTimezone(this.state.params.venueId).name;
            sameDay = moment(startDate).tz(timezone).isSame(moment(endDate).tz(timezone), 'day');
        }

        return sameDay;
    }

    isToday(dateTime: string): boolean {
        const today = moment().toISOString();
        return this.isSameDay(dateTime, today);
    }

    isTomorrow(dateTime: string): boolean {
        const tomorrow = moment().add(1, 'day').toISOString();
        return this.isSameDay(dateTime, tomorrow);
    }

    isCurrentYear(dateTime: string): boolean {
        const timezone = this.venueService.getTimezone(this.state.params.venueId).name;
        return moment().tz(timezone).year() === moment(dateTime).tz(timezone).year();
    }

    isPast(dateTime: string): boolean {
        const timezone = this.venueService.getTimezone(this.state.params.venueId).name;
        const now = moment().tz(timezone);
        const time = moment.tz(dateTime, timezone);
        return time.isSameOrBefore(now);
    }

    formatDateTime(dateTime: moment.MomentInput, fromFormat: moment.MomentFormatSpecification, toFormat: string): string {
        return moment(dateTime, fromFormat).format(toFormat);
    }

    formatDateTimeInVenueTime(dateTime: moment.MomentInput, fromFormat: moment.MomentFormatSpecification, toFormat: string): string {
        const timezone = this.venueService.getTimezone(this.state.params.venueId).name;
        return moment(dateTime, fromFormat).tz(timezone).format(toFormat);
    }

    isoDateToIcalDate(dateStr: string): string {
        return this.formatDateTime(dateStr, this.isoDateFormat, this.iCalDateFormat);
    }

    iCalDateToIsoDate(dateStr: string): string {
        return this.formatDateTime(dateStr, this.iCalDateFormat, this.isoDateFormat);
    }

    getCombinedDateTimeIsoString(date: string, time: string): string {
        let dateTime: string = null;

        if (date) {
            dateTime = date;
        }

        if (time) {
            if (date) {
                dateTime = `${this.getDateFromIsoString(date)}T${time}`;
            } else {
                dateTime = null;
            }
        }

        return dateTime;
    }

    getDateFromIsoString(isoString: string): string {
        return isoString.split('T')[0];
    }

    getTimeFromIsoString(isoString: string): string {
        return isoString.split('T')[1];
    }

    getBeginningOfDay(isoString: moment.MomentInput): string {
        return moment(isoString).startOf('day').format(this.isoDateFormat);
    }

    getEndOfDay(isoString: moment.MomentInput): string {
        return moment(isoString).endOf('day').format(this.isoDateFormat);
    }

    getEndOfDayInVenueTime(isoString: moment.MomentInput): string {
        const timezone = this.venueService.getTimezone(this.state.params.venueId).name;
        return moment(isoString).tz(timezone).endOf('day').format(this.isoDateFormat);
    }

    getBeginningOfNextDay(isoString: moment.MomentInput): string {
        return moment(isoString).add(1, 'day').startOf('day').format(this.isoDateFormat);
    }

    getEndOfPreviousDay(isoString: moment.MomentInput): string {
        return moment(isoString).subtract(1, 'day').startOf('day').format(this.isoDateFormat);
    }

    addSecond(time: moment.MomentInput): string {
        return moment(time, this.isoTimeNoOffset).add(1, 'second').format(this.isoTimeNoOffset);
    }

    getDurationInSeconds(startTime: moment.MomentInput, endTime: moment.MomentInput): number {
        return moment(endTime, this.isoTimeNoOffset).diff(moment(startTime, this.isoTimeNoOffset), 'seconds');
    }

    endTimeIsAfterStartTime(startTime: moment.MomentInput, endTime: moment.MomentInput): boolean {
        return moment(endTime, this.isoTimeNoOffset).isAfter(moment(startTime, this.isoTimeNoOffset));
    }

    endTimeIsSameOrBeforeStartTime(startTime: moment.MomentInput, endTime: moment.MomentInput): boolean {
        return moment(endTime, this.isoTimeNoOffset).isSameOrBefore(moment(startTime, this.isoTimeNoOffset));
    }

    isStartOfDay(isoString: moment.MomentInput): boolean {
        return moment(isoString).format() === moment(isoString).startOf('day').format();
    }

    isAllDay(startTime: moment.MomentInput, endTime: moment.MomentInput): boolean {
        return startTime && endTime && this.isStartOfDay(startTime) && this.isStartOfDay(endTime);
    }

    isSameOrAfter(startTime: moment.MomentInput, endTime: moment.MomentInput): boolean {
        return moment(endTime).isSameOrAfter(moment(startTime));
    }

    adjustScheduleData(schedules: any[]): any[] {
        const { weekdays } = this.constants.iso;
        schedules.forEach((schedule) => {
            // Repeat mode only
            if (schedule.recurrence) {
                const recurrenceObj = this.getRecurrenceObject(schedule.recurrence);

                // Get iso weekday (Mon to Sun, 1-7)
                const startWeekdayNum = moment(schedule.instances[0].startTime).isoWeekday();
                const weekdayId = weekdays[startWeekdayNum - 1];

                // See if the user selected this weekday
                const isSelected = recurrenceObj.byDay.indexOf(weekdayId) !== -1;

                // Update instance startTimes if the user hasn't selected the weekday
                if (!isSelected) {
                    const tempSelectedDays: string[] = _.cloneDeep(recurrenceObj.byDay);

                    // Push the current startTime weekday into the selected days
                    tempSelectedDays.push(weekdayId);

                    // Sort the selected days based on our full weekday array
                    tempSelectedDays.sort((a, b) => weekdays.indexOf(a) - weekdays.indexOf(b));

                    // Find the next weekday after the startTime weekday
                    const startIndex = tempSelectedDays.indexOf(weekdayId);
                    const nextDayId = tempSelectedDays[startIndex + 1] || tempSelectedDays[0];

                    const firstDayIndex = weekdays.indexOf(nextDayId);
                    const firstDayNum = firstDayIndex + 1;

                    // Handle creating a wrap-around value. If the weekday of the startTime is greater than the
                    // weekday of the first selected weekday, we need to add 7 days to get the next weekday.
                    const newWeekdayNum = startWeekdayNum > firstDayNum ? weekdays.length + firstDayNum : firstDayNum;

                    const { startTime } = schedule.instances[0];
                    const newStartMoment = moment(startTime).isoWeekday(newWeekdayNum);

                    // Need to add diff in days to each startTime in the instances array (same day, different times)
                    const daysToAdd = newStartMoment.diff(moment(startTime), 'days');

                    schedule.instances.forEach((instance) => {
                        instance.startTime = moment(instance.startTime)
                            .add(daysToAdd, 'days')
                            .format(this.isoDateFormat);
                    });

                    // If there is an endTime, this is an all day schedule. Move end time to beginning of next day
                    if (schedule.instances[0].endTime) {
                        schedule.instances[0].endTime = this.getBeginningOfNextDay(newStartMoment);
                    }

                    // Adjust 'UNTIL' since it could now be before the startDate
                    if (recurrenceObj.until) {
                        if (moment(recurrenceObj.until).isBefore(newStartMoment, 'day')) {
                            recurrenceObj.until = this.getEndOfDay(newStartMoment.format(this.isoDateFormat));
                            schedule.recurrence = this.getRecurrenceString(recurrenceObj);
                        }
                    }
                } else if (schedule.instances[0].startTime && schedule.instances[0].endTime &&
                    this.endTimeIsSameOrBeforeStartTime(schedule.instances[0].startTime, schedule.instances[0].endTime)) {
                    schedule.instances[0].endTime = this.getBeginningOfNextDay(schedule.instances[0].startTime);
                }
            }

            if (schedule.scheduleKey?.toUpperCase().includes(this.scheduleKey.CHECKPARK.toUpperCase())) {
                schedule.scheduleKey = this.scheduleKey.CHECKPARK;
            } else if (this.isAllDay(schedule.instances[0].startTime, schedule.instances[0].endTime)) {
                schedule.scheduleKey = this.addScheduleKey(schedule.scheduleKey, this.scheduleKey.FULLDAY);
            } else if (schedule.scheduleKey) {
                schedule.scheduleKey = this.deleteScheduleKey(schedule.scheduleKey, this.scheduleKey.FULLDAY);
            }
        });

        return schedules;
    }

    addScheduleKey(scheduleKeys: string, value: string): string {
        if (!scheduleKeys) {
            return value;
        }
        const keys = scheduleKeys.split(';');

        const hasKey = _.find(keys, (key) => key.toLowerCase() === value.toLowerCase());

        if (!hasKey) {
            keys.push(value);
        }

        return keys.join(';');
    }

    deleteScheduleKey(scheduleKeys: string, value: string): string {
        const keys = scheduleKeys.split(';');

        _.remove(keys, (key) => key.toLowerCase() === value.toLowerCase());

        return keys.join(';') || null;
    }

    getRecurrenceString(obj: any): string {
        const rules: string[] = [];

        _.forOwn(obj, (value, key) => {
            switch (key) {
                case 'freq': {
                    rules.push(`${key}=${value}`);
                    break;
                }
                case 'byDay': {
                    rules.push(`${key}=${value.join()}`);
                    break;
                }
                case 'until': {
                    const formattedUntil = this.isoDateToIcalDate(value);

                    rules.push(`${key}=${formattedUntil}`);
                    break;
                }
            }
        });

        return rules.join(';').toUpperCase();
    }

    getRecurrenceObject(str: string): any {
        const ruleObj: { freq?: string, byDay?: string[], until?: string } = {};
        const rules = str.split(';');

        rules.forEach((part) => {
            const keyValueParts = part.split('=');
            const key = keyValueParts[0];
            const value = keyValueParts[1];

            switch (key) {
                case 'FREQ': {
                    ruleObj.freq = value;
                    break;
                }
                case 'BYDAY': {
                    ruleObj.byDay = value.split(',');
                    break;
                }
                case 'UNTIL': {
                    const formattedUntil = this.iCalDateToIsoDate(value);

                    ruleObj.until = formattedUntil;
                    break;
                }
            }
        });

        return ruleObj;
    }

    getRecurrenceExceptionsString(array: any[]): string {
        return _.map(array, 'dateTime').join(',');
    }

    getRecurrenceExceptionsArray(str: string): { label: string, dateTime: string }[] {
        const exceptionsListStrings = str.split(',');
        exceptionsListStrings.sort();

        const exceptionsList = exceptionsListStrings.map((dateTime) => {
            let dateFormat = this.shortDateNoYearFormat;

            if (!this.isCurrentYear(dateTime)) {
                dateFormat = this.shortDateFormat;
            }

            const date = this.formatDateTime(dateTime, this.iCalDateFormat, dateFormat);
            const time = this.formatDateTime(dateTime, this.iCalDateFormat, this.shortTimeFormat);

            return {
                label: `${date}, ${time}`,
                dateTime,
            };
        });

        return exceptionsList;
    }
}
