import angular from 'angular';
import text from './resources/locale/en.json';

import Component from './pdf-preview-modal.component';

const PdfPreviewModalModule = angular
    .module('control-room.app.menus.pdf-preview-modal', [])
    .constant('crPdfPreviewModalText', text)
    .component('crPdfPreviewModal', Component);

export default PdfPreviewModalModule;
