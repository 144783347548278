import _ from 'lodash';
import text from './resources/locale/en.json';

class ExperienceReport {
    constructor(
        $filter,
        $state,
        crConstants,
        crExperiencesService,
        crNavigationService,
        crReportsService,
        crScheduleService
    ) {
        this.$filter = $filter;
        this.$state = $state;
        this.crConstants = crConstants;
        this.crExperiencesService = crExperiencesService;
        this.crNavigationService = crNavigationService;
        this.crReportsService = crReportsService;
        this.crScheduleService = crScheduleService;

        this.dateFormat = this.crConstants.date.format.pickerModel;
        this.startDateFormat = this.crConstants.date.format.isoUtc;
        this.isoDateFormat = this.crConstants.date.format.isoNoOffset;
        this.yearFirstDate = this.crConstants.date.format.yearFirstDate;

        this.text = text;
    }

    $onInit() {
        this.loading = true;

        this.endDate = this.crScheduleService.getEndOfDayInVenueTime();
        this.maxDate = this.crScheduleService.getEndOfDayInVenueTime();

        this.crExperiencesService.getExperienceTypes(this.group).then((types) => {
            this.types = {};
            types[this.group].forEach((type) => {
                this.types[type.id] = type.name;
            });
        });

        this.crExperiencesService
            .getExperience(this.$state.params.id)
            .then((experience) => {
                this.loading = false;
                this.experience = experience;

                this.startDate = this.crScheduleService.formatDateTimeInVenueTime(
                    this.experience.created,
                    this.startDateFormat,
                    this.yearFirstDate
                );
                this.minDate = this.experience.created;

                this.ctaOptions = this.experience.content.links.map((link, index) => ({
                    label: link.type === 'COUPON' ? `Coupon: ${link.label.en}` : `Link: ${link.label.en}`,
                    value: `link_${index}`,
                    isSelected: index === 0,
                }));
                this.cta = this.ctaOptions[0];

                this.getMessageConversion();
            })
            .catch(() => {
                this.loading = false;
                this.error = true;
            });
    }

    get formattedMinDate() {
        return this.crScheduleService.formatDateTime(this.minDate, this.startDateFormat, this.dateFormat);
    }

    get formattedMaxDate() {
        return this.crScheduleService.formatDateTime(this.maxDate, this.startDateFormat, this.dateFormat);
    }

    get isFiltered() {
        return (this.startDate && this.minDate && this.startDate > this.minDate) || this.endDate < this.maxDate;
    }

    get maxTapRate() {
        const percent =
            (_.get(this.maxReportData, `links.${_.get(this.cta, 'value')}.tapped`) /
                _.get(this.maxReportData, 'viewed')) *
            100;
        return `${this.$filter('number')(percent || 0, 1)}%`;
    }

    get maxRedeemRate() {
        const percent =
            (_.get(this.maxReportData, `links.${_.get(this.cta, 'value')}.converted`) /
                _.get(this.maxReportData, `links.${_.get(this.cta, 'value')}.tapped`)) *
            100;
        return `${this.$filter('number')(percent || 0, 1)}%`;
    }

    get maxViewRate() {
        const percent = (_.get(this.maxReportData, 'viewed') / _.get(this.maxReportData, 'delivered')) * 100;
        return `${this.$filter('number')(percent || 0, 1)}%`;
    }

    get tapRate() {
        const percent =
            (_.get(this.reportData, `links.${_.get(this.cta, 'value')}.tapped`) / this.reportData.viewed) * 100;
        return `${this.$filter('number')(percent || 0, 1)}%`;
    }

    get redeemRate() {
        const percent =
            (_.get(this.reportData, `links.${_.get(this.cta, 'value')}.converted`) /
                _.get(this.reportData, `links.${_.get(this.cta, 'value')}.tapped`)) *
            100;
        return `${this.$filter('number')(percent || 0, 1)}%`;
    }

    get viewRate() {
        const percent = (this.reportData.viewed / this.reportData.delivered) * 100;
        return `${this.$filter('number')(percent || 0, 1)}%`;
    }

    getLargestMetric() {
        const metrics = [
            _.get(this.reportData, 'sent') || 0,
            _.get(this.reportData, 'delivered') || 0,
            _.get(this.reportData, 'viewed') || 0,
            _.get(this.reportData, `links.${_.get(this.cta, 'value')}.tapped`) || 0,
            _.get(this.reportData, `links.${_.get(this.cta, 'value')}.converted`) || 0,
        ];

        const maxMetrics = [
            _.get(this.maxReportData, 'sent') || 0,
            _.get(this.maxReportData, 'delivered') || 0,
            _.get(this.maxReportData, 'viewed') || 0,
            _.get(this.maxReportData, `links.${_.get(this.cta, 'value')}.tapped`) || 0,
            _.get(this.maxReportData, `links.${_.get(this.cta, 'value')}.converted`) || 0,
        ];

        return _.max(metrics.concat(maxMetrics));
    }

    getMessageConversion() {
        const promises = [];
        this.loading = true;

        const params = {
            start: this.crScheduleService.formatDateTime(this.startDate, this.isoDateFormat, this.yearFirstDate),
            end: this.crScheduleService.formatDateTime(this.endDate, this.isoDateFormat, this.yearFirstDate),
            venueId: this.$state.params.venueId,
        };
        promises.push(this.crReportsService.getMessageConversion(this.experience.id, params));

        if (this.isFiltered) {
            const filteredParams = {
                start: this.crScheduleService.formatDateTime(this.minDate, this.isoDateFormat, this.yearFirstDate),
                end: this.crScheduleService.formatDateTime(this.maxDate, this.isoDateFormat, this.yearFirstDate),
                venueId: this.$state.params.venueId,
            };
            promises.push(this.crReportsService.getMessageConversion(this.experience.id, filteredParams));
        }

        Promise.all(promises)
            .then(([data, maxData]) => {
                this.reportData = data;
                this.maxReportData = maxData;
                this.loading = false;
            })
            .catch(() => {
                this.loading = false;
                this.error = true;
            });
    }

    get noMaxData() {
        return !this.maxReportData || _.isEmpty(this.maxReportData);
    }

    goBack() {
        this.crNavigationService.goBack('client.experience-promoter.dashboard', { tabId: this.group });
    }

    onCtaUpdate(event) {
        this.cta = event.model[0];
    }

    onEndDateUpdate(event) {
        this.endDate = event.model;
    }

    onStartDateUpdate(event) {
        this.startDate = event.model;
    }
}

export default ExperienceReport;
