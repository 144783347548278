import {
  Component, Input, Output, EventEmitter, OnInit, Inject,
} from '@angular/core';
import { StateService } from '@uirouter/core';

import { LocalizedText, Constants } from '../../core';
import { CR_CONSTANTS, VenueService, TagService } from '../../shared';

@Component({
  selector: 'cr-title-bar',
  styleUrls: ['./title-bar.component.scss'],
  templateUrl: './title-bar.component.html',
})
export class CrTitleBarComponent implements OnInit {
  @Input() disabled = false;
  @Input() icon: string;
  @Input() label: string;

  @Output() onClick = new EventEmitter<any>();

  disableNavigation = false
  stickyIndex = 0;

  constructor(
    @Inject(CR_CONSTANTS) private constants: Constants, 
    private state: StateService
  ) {}

  ngOnInit() {
    this.disableNavigation = this.state.params.disableNavigation;
    this.stickyIndex = this.constants.stickyZindex;
  }

  handleClick() {
    this.onClick.emit();
  }
}
