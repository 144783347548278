import angular from 'angular';
import template from './row-builder.html';
import './row-builder.less';

import RowBuilderController from './row-builder.controller';

class RowBuilderDirective {
    constructor($compile) {
        this.restrict = 'E';
        this.controller = ['$scope', 'crImageService', RowBuilderController];
        this.controllerAs = '$ctrl';
        this.bindToController = true;
        this.scope = {
            name: '@',
            onChange: '&',
            addList: '<',
            defaultState: '<',
            rows: '<',
            addText: '@',
            showSearch: '<?',
            maxRows: '<?',
            addTooltipText: '@?',
        };
        this.require = {
            parentForm: '^form',
        };
        this.$compile = $compile;
    }

    static directiveFactory($compile) {
        return new RowBuilderDirective($compile);
    }

    compile(element) {
        const rowTemplate = `<div class="cr-row-container" data-local-qa-id="row-{{ $index }}" ng-init="rows = $ctrl.rows" ng-repeat="row in $ctrl.rows">
        <div class="cr-drop" ng-drop="true" ng-drop-success="$ctrl.shiftRow($data, $index)"></div>
        <div class="cr-row" ng-drag="row.isDraggable" ng-drag-data="$index">
            <span ng-drag-handle ng-show="row.isDraggable" class="icon-cr-sort"></span>
            ${element.html()}
            <span ng-click="$ctrl.removeRow($index)" data-local-qa-id="remove-{{ $index }}" class="icon-cr-close"></span>
        </div>
        <div ng-if="$last" class="cr-drop" ng-drop="true" ng-drop-success="$ctrl.shiftRow($data, $index + 1)"></div>
    </div>`;

        const ngTemplate = angular.element(template);

        element.empty();

        ngTemplate[0].querySelector('.cr-rows').innerHTML = rowTemplate;

        return this.link.bind(this, ngTemplate);
    }

    link(ngTemplate, scope, element) {
        const templateClone = ngTemplate.clone();

        element.append(templateClone);

        this.$compile(templateClone)(scope);

        scope.onChange = scope.onChange || (() => null);

        scope.$watch('$ctrl.rows', (rows) => scope.$ctrl.onChange({ $event: { rows } }), true);
    }
}

RowBuilderDirective.directiveFactory.$inject = ['$compile'];

export default RowBuilderDirective;
