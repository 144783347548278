import _ from 'lodash';
import text from './resources/locale/en.json';

class AdditionalField {
    constructor($state, $filter, crConstants, crConfig) {
        this.$state = $state;
        this.$filter = $filter;
        this.crConstants = crConstants;
        this.crConfig = crConfig;

        this.text = text;
        
        this.locationEnums = this.crConstants.userLocationEnums;
        this.locations = [
            {
                type: this.locationEnums.IN_VENUE,
                label: this.text.IN_VENUE,
                id: this.$state.params.venueId,
            },
        ];

        if (this.showOutOfVenueMessagingOptions()) {
            this.locations.push(
                {
                    type: this.locationEnums.OUTSIDE_VENUE,
                    label: this.text.OUTSIDE_VENUE,
                    id: this.$state.params.venueId,
                },
                {
                    type: this.locationEnums.ANYWHERE,
                    label: this.text.ANYWHERE,
                    id: null,
                }
            );
        }
    }

    showOutOfVenueMessagingOptions() {
        let showOOVOptions = true;

        if (this.crConfig.customer.configFlags && this.crConfig.customer.configFlags.restrict_out_of_venue_messaging && 
            this.crConfig.customer.configFlags.restrict_out_of_venue_messaging.length > 0) {
            showOOVOptions = this.crConfig.customer.configFlags.restrict_out_of_venue_messaging.includes(
                this.$state.params.venueId
            );
        }

        return showOOVOptions;
    }

    updateLocation(operation) {
        const locations = _.cloneDeep(this.locationOptions);
        if(operation === 'add') {
            this.locations = locations;
        }
        else {
            this.onUpdate({ path: 'audience.location'});
            this.locations = locations.filter(l => l.type===this.locationEnums.IN_VENUE);
        }
    }

    $onInit() {
        this.data = _.get(this.model, this.fieldData.targetPath, null);
        this.optionalChecked = !_.isNil(this.data);
        this.optionalOptions = [
            {
                label: this.fieldData.description,
                name: 'optional',
                checked: this.optionalChecked,
            },
        ];
        this.updateOutOfVenueLocationOptions = { 
            updateLocation : this.updateLocation.bind(this)
        };
        this.setupPoiAndCategories();
        this.setupMenuItemsAndCategories();

        if (this.fieldData.type === 'LOCATION_SELECT') {
            let locations = _.cloneDeep(this.locations);
            if (this.showOutOfVenueMessagingOptions() && this.geofences) {
                locations = locations.concat(
                    this.geofences.map((geo) => {
                        geo.type = 'IN_GEOFENCE';
                        return geo;
                    })
                );
            }

            locations.forEach((location) => {
                if (this.data.type === 'IN_GEOFENCE') {
                    location.isSelected = location.id === this.data.id;
                } else {
                    location.isSelected = location.type === this.data.type;
                }
            });

            this.locations = locations;
        } else if (this.fieldData.type === 'POI_SELECT') {
            this.updatePoiOptions();
        } else if (this.fieldData.type === 'SELECT') {
            this.fieldData.menuItems = Object.keys(this.fieldData.options).map((key) => ({
                name: this.fieldData.options[key],
                id: key,
            }));
        } else if (this.fieldData.type === 'SIMPLE_SELECT') {
            // Mark the selected value as selected
            this.fieldData.options
                .filter((option) => option.value === this.data)
                .forEach((option) => {
                    option.isSelected = true;
                });
        }

        // If the data isn't set yet, supply a default value from the MIN_VALUE validator, if present
        if (
            ((this.data === null && this.fieldData.type === 'NUMBER') || this.fieldData.type === 'PRICE') &&
            !this.fieldData.optional
        ) {
            this.setMinValue();
        }

        const minValueValidator = this.fieldData.validators.find((validator) => validator.type === 'MIN_VALUE');
        if (minValueValidator) {
            let { value } = minValueValidator;
            if (this.fieldData.type === 'PRICE') {
                value = this.$filter('currency')(value, '', 2);
            }

            this.errorMessages = {
                minValue: `${this.text.minValueError} ${value}`,
            };
        }
        this.locationOptions = _.cloneDeep(this.locations);
    }

    $onChanges(changes) {
        if (changes.pois || changes.poiCategories) {
            this.setupPoiAndCategories();
        }
    }

    get useCheckbox() {
        return (
            this.fieldData.optional &&
            (this.fieldData.type === 'TEXT' || this.fieldData.type === 'PRICE' || this.fieldData.type === 'NUMBER')
        );
    }

    setupPoiAndCategories() {
        if (this.fieldData.type === 'POI_OR_CATEGORY_SELECT') {
            this.poiAndCategories = this.poiCategories
                .map((cat) => ({
                    label: `${cat.label} ${this.text.categorySeparator} ${this.text.all}`,
                    id: cat.id,
                    value: cat.id,
                    type: 'POI_CATEGORY',
                }))
                .concat(
                    this.pois.map((poi) => {
                        let prefix = '';
                        if (poi.categories && poi.categories.length > 0) {
                            prefix = `${poi.categories.map((cat) => cat.label).join(', ')} ${
                                this.text.categorySeparator
                            } `;
                        }
                        return {
                            label: `${prefix}${poi.name}`,
                            id: poi.id,
                            value: poi.id,
                            type: 'POI',
                        };
                    })
                );
        }
    }

    setupMenuItemsAndCategories() {
        if (this.fieldData.type === 'MENU_ITEM_OR_CATEGORY_SELECT') {
            if (!this.menuCategories) {
                console.warn('No Menu Categories specified!');
                return;
            }
            if (!this.menuItems) {
                console.warn('No Menu Items specified!');
                return;
            }
            this.menuItemsAndCategories = this.menuCategories
                .map((cat) => ({
                    label: `${cat.displayName} ${this.text.categorySeparator} ${this.text.all}`,
                    id: cat.id,
                    value: cat.id,
                    type: 'MENU_ITEM_CATEGORY',
                }))
                .concat(
                    this.menuItems.map((menuItem) => {
                        let prefix = '';
                        if (menuItem.categories && menuItem.categories.length > 0) {
                            prefix = `${menuItem.categories.map((cat) => cat.displayName).join(', ')} ${
                                this.text.categorySeparator
                            } `;
                        }
                        return {
                            label: `${prefix}${menuItem.displayName}`,
                            id: menuItem.id,
                            value: menuItem.id,
                            type: 'MENU_ITEM',
                        };
                    })
                );
        }
    }

    setMinValue() {
        this.fieldData.validators
            .filter((validator) => validator.type === 'MIN_VALUE')
            .forEach((validator) => {
                let { value } = validator;
                if (this.fieldData.type === 'PRICE') {
                    value = parseFloat(value).toFixed(2);
                }
                this.onUpdate({ path: this.fieldData.targetPath, data: value, checked: true });
                this.data = value;
            });
    }

    unsetMinValue() {
        this.onUpdate({ path: this.fieldData.targetPath, data: null });
        this.data = null;
    }

    get isRequired() {
        return this.fieldData.validators.find((validator) => validator.type === 'REQUIRED' && validator.value === true);
    }

    onLocationSelect(event) {
        const data = {
            type: event.model[0].type,
            id: event.model[0].id,
        };
        if(data.type === this.locationEnums.IN_VENUE) {
            this.updateAppOptions({locationType : this.locationEnums.IN_VENUE});
        }
        else{
            this.updateAppOptions({locationType : this.locationEnums.OUTSIDE_VENUE});
        }
        this.onUpdate({ path: this.fieldData.targetPath, data, checked: true });
    }

    onDropdownSelect(event) {
        const data = event.model[0].id;
        this.onUpdate({ path: this.fieldData.targetPath, data, checked: true });
    }

    onSimpleSelect(event) {
        const data = event.model[0].value;
        this.onUpdate({ path: this.fieldData.targetPath, data, checked: true });
    }

    onNumberUpdate(event) {
        this.onUpdate({
            path: this.fieldData.targetPath,
            data: _.get(event, 'model.length') > 0 ? Number(event.model) : null,
            checked: true,
        });
    }

    onPriceUpdate(event) {
        this.onUpdate({
            path: this.fieldData.targetPath,
            data: _.get(event, 'model.length') > 0 ? parseFloat(event.model).toFixed(2) : null,
            checked: true,
        });
    }

    onPoiSelect(event) {
        this.onUpdate({ path: this.fieldData.targetPath, data: event.model[0].id, checked: true });
    }

    onSelect(event) {
        this.onUpdate({ path: this.fieldData.targetPath, data: event.model[0], checked: true });
    }

    onTextUpdate(event) {
        this.onUpdate({ path: this.fieldData.targetPath, data: event.model, checked: true });
    }

    onChipSelect(data) {
        this.onUpdate({ path: this.fieldData.targetPath, data, checked: true });
    }

    onOptionalUpdate(event) {
        this.optionalChecked = event.model.optional;
        if (this.optionalChecked) {
            this.setMinValue();
        } else {
            this.data = null;
            this.onUpdate({ path: this.fieldData.targetPath, data: null, checked: false });
        }
    }

    updatePoiOptions() {
        if (!this.pois) {
            return;
        }

        this.poiOptions = this.pois.map((poi) => {
            const poiOption = _.clone(poi);
            poiOption.isSelected = poiOption.id === this.data;
            return poiOption;
        });
    }
}

export default AdditionalField;
