import _ from 'lodash';
import { menuRoutes } from '../../menus.routes';
import text from './resources/locale/en.json';

class ProductDetails {
    constructor(
        $state,
        $uibModal,
        crConfig,
        crConstants,
        crEntityService,
        crLocaleService,
        crToastService,
        crErrorLoggingService,
        crImageService,
        crNavigationService,
        crCommerceService
    ) {
        this.$state = $state;
        this.$uibModal = $uibModal;
        this.crConfig = crConfig;
        this.crConstants = crConstants;
        this.crEntityService = crEntityService;
        this.crLocaleService = crLocaleService;
        this.crToastService = crToastService;
        this.crErrorLoggingService = crErrorLoggingService;
        this.crImageService = crImageService;
        this.crNavigationService = crNavigationService;
        this.crCommerceService = crCommerceService;
    }

    $onInit() {
        this.text = text;
        this.entity = {};
        this.associatedEntities = {};
        this.taxCodeMap = {};

        this.venueId = this.$state.params.venueId;
        this.placeId = this.$state.params.placeId;

        this.locale = this.crLocaleService.getLocale(this.$state.params.venueId, this.$state.params.locale);
        this.currencyCode = _.find(this.crConfig.customer.venues, { id: this.venueId }).currencyCode;

        this.entityType = this.crConstants.entity.types.MENU_PRODUCTS;

        this.isAlreadyPrepared = false;
        this.isDiscountable = true;

        const params = [
            this.entityType,
            this.$state.params.id,
            this.$state.params.venueId,
            this.$state.params.menuId,
            this.locale.current,
        ];

        this.getData(...params);
        this.getDiscountTypes();
        this.initTaxCodes();

        // See if we need to show a toast when coming to this page
        this.crToastService.autoToast(this.$state.params);
    }

    getData(entityType, id, venueId, menuId, locale) {
        const entityRoute = `menus/${menuId}/${entityType}`;
        this.isLoading = true;

        this.crEntityService
            .getEntity(entityType, id, venueId, locale, entityRoute, { placeId: this.placeId })
            .then((entity) => {
                this.entity = entity;
                const { modifierGroups, categories } = this.entity;
                this.associatedEntities[locale] = { modifierGroups, categories };
                this.entityName = this.entity.content.name;
                this.entity.mainImageSrc = this.crImageService.getMainImageSrc(entity.images);
                this.entity.stickerSrc = this.crImageService.getStickerSrc(entity.images) || this.entity.mainImageSrc;
                this.entity.modifierGroups = entity.modifierGroups || [];
                this.entity.price = parseFloat(this.entity.price).toFixed(2);
                this.updateLocaleMap(this.entity, this.locale);
                if (this.entity.attributes) {
                    this.isAlreadyPrepared = this.entity.attributes.includes('noPrepTime');
                    this.isDiscountable = !this.entity.attributes.includes('notDiscountable');
                }
            })
            .catch((err) => {
                this.crErrorLoggingService.logError(`Could not get ${entityType}`, err, id);

                if (err.status === 404) {
                    this.$state.go('client.404', {
                        customerId: this.$state.params.customerId,
                        venueId,
                    });
                } else {
                    this.hasDataError = true;
                }
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    getDiscountTypes() {
        this.isLoadingDiscounts = true;
        this.crCommerceService
            .getDiscountTypes()
            .then((res) => {
                this.discountTypes = res;
            })
            .catch((err) => {
                this.crErrorLoggingService.logError('Could not get discount types for customer', err);
            })
            .finally(() => {
                this.isLoadingDiscounts = false;
            });
    }

    initTaxCodes() {
        const taxCodeMap = {};
        this.crCommerceService.getTaxCodes(this.placeId).then((results) => {
            if (results && results.length) {
                results.forEach((option) => {
                    taxCodeMap[option.id] = option.label;
                });
            }
        });
        this.taxCodeMap = taxCodeMap;
    }

    localeUpdated(event) {
        this.locale.current = event.locale.id;
        this.updateLocaleMap(this.entity, this.locale);
        this.locale = _.cloneDeep(this.locale);
    }

    updateLocaleMap(entity, locale) {
        const currentLocale = entity.localization[locale.current];

        // use default language if localization values are not present.
        if (!currentLocale.description) {
            currentLocale.description = entity.localization[locale.default].description;
        }

        if (!currentLocale.title) {
            currentLocale.title = entity.localization[locale.default].title;
        }

        if (!this.associatedEntities[locale.current]) {
            const params = [
                this.entityType,
                this.$state.params.id,
                this.$state.params.venueId,
                this.$state.params.menuId,
                this.locale.current,
            ];
            this.getTranslatedEntities(...params);
        }

        this.entity = _.cloneDeep(this.entity);
    }

    getTranslatedEntities(entityType, id, venueId, menuId, locale) {
        const entityRoute = `menus/${menuId}/${entityType}`;
        this.crEntityService
            .getEntity(entityType, id, venueId, locale, entityRoute, { placeId: this.placeId })
            .then((entity) => {
                const { modifierGroups, categories } = entity;
                this.associatedEntities[locale] = { modifierGroups, categories };
                this.updateLocaleMap(entity, this.locale);
            })
            .catch((err) => this.crErrorLoggingService.logError(`Could not get ${entityType}`, err, id));
    }

    getStateUpdatePayload(state, entity = this.entity) {
        entity = _.cloneDeep(entity);
        entity.state = state;
        entity.categories = entity.categories.map((cat) => cat.id);
        entity.modifierGroups = entity.modifierGroups.map((mod) => mod.id);

        return entity;
    }

    getIsAlreadyPrepared() {
        return this.isAlreadyPrepared && this.text.alreadyPrepared;
    }

    getIsDiscountable() {
        return this.isDiscountable ? 'Yes.' : 'No.';
    }

    getDiscountName(key) {

        if(key) {
            let found = this.discountTypes.find((type) => {
                if( type.id === key && type.displayName) {
                    return type
                }
            })

            if(found && found.displayName) {
                return found.displayName;
            }
        }
    }

    updateStateDropdown(state) {
        this.entity.state = state;
    }

    deactivate() {
        if (this.isLoading) {
            return;
        }

        this.isLoading = true;
        const { venueId } = this.$state.params;
        const entityRoute = `menus/${this.$state.params.menuId}/${this.entityType}`;
        const state = this.crConstants.entity.states.INACTIVE;

        this.crEntityService
            .updateEntity(this.entityType, this.entity.id, this.getStateUpdatePayload(state), venueId, entityRoute, {
                placeId: this.placeId,
            })
            .then(() => {
                this.crToastService.toast(`"${this.entityName}" ${this.text.hasBeenDeactivated}`);
                this.updateStateDropdown(this.crConstants.entity.states.INACTIVE);
            })
            .catch(() => this.openModal(`${this.text.problemDeactivating} "${this.entityName}"`))
            .finally(() => {
                this.isLoading = false;
            });
    }

    activate() {
        if (this.isLoading) {
            return;
        }

        this.isLoading = true;
        const { venueId } = this.$state.params;
        const entityRoute = `menus/${this.$state.params.menuId}/${this.entityType}`;
        const state = this.crConstants.entity.states.ACTIVE;

        this.crEntityService
            .updateEntity(this.entityType, this.entity.id, this.getStateUpdatePayload(state), venueId, entityRoute, {
                placeId: this.placeId,
            })
            .then(() => {
                this.crToastService.toast(`"${this.entityName}" ${this.text.hasBeenActivated}`);
                this.updateStateDropdown(this.crConstants.entity.states.ACTIVE);
            })
            .catch(() => this.openModal(`${this.text.problemActivating} "${this.entityName}"`))
            .finally(() => {
                this.isLoading = false;
            });
    }

    archive() {
        if (this.isLoading) {
            return;
        }

        const resolve = () => {
            this.isLoading = true;
            const entityRoute = `menus/${this.$state.params.menuId}/${this.entityType}`;

            this.crEntityService
                .deleteEntity(entityRoute, this.$state.params.id, this.$state.params.venueId, { placeId: this.placeId })
                .then(() => {
                    this.$state.go(menuRoutes.PRODUCTS, {
                        menuId: this.$state.params.menuId,
                        placeId: this.$state.params.placeId,
                        toast: {
                            msg: `"${this.entityName}" ${this.text.hasBeenArchived}`,
                        },
                    });
                })
                .catch(() => {
                    this.openModal(`${this.text.problemArchiving} "${this.entityName}"`);
                    this.isLoading = false;
                });
        };

        this.$uibModal
            .open({
                backdrop: 'static',
                component: 'crSimpleModal',
                windowClass: 'cr-modal-size-sm',
                resolve: {
                    message: () => this.text.archiveModalMessage,
                    type: () => this.crConstants.modalTypes.SUBMIT,
                    submitText: () => this.text.archiveModalSubmit,
                },
            })
            .result.then(resolve)
            .catch(() => null);
    }

    openModal(message) {
        this.$uibModal
            .open({
                backdrop: 'static',
                component: 'crSimpleModal',
                windowClass: 'cr-modal-size-sm',
                resolve: {
                    message: () => message,
                },
            })
            .result.catch(() => null);
    }

    saveCopy() {
        this.$state.go(menuRoutes.CREATE_PRODUCT, {
            id: this.$state.params.id,
            menuId: this.$state.params.menuId,
            placeId: this.$state.params.placeId,
            customerId: this.$state.params.customerId,
        });
    }

    goToEditPage() {
        this.$state.go(menuRoutes.EDIT_PRODUCT, {
            id: this.$state.params.id,
            menuId: this.$state.params.menuId,
            placeId: this.$state.params.placeId,
            customerId: this.$state.params.customerId,
        });
    }

    goBack() {
        this.crNavigationService.goBack(menuRoutes.PRODUCTS, {
            menuId: this.$state.params.menuId,
            placeId: this.$state.params.placeId,
            tabId: 'menu-items',
        });
    }
}

export default ProductDetails;
