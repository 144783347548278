import angular from 'angular';

import AlertModule from './alert/alert';
import BreadcrumbsModule from './breadcrumbs/breadcrumbs';
import CardModule from './card/card';
import ChangeArrowModule from './change-arrow/change-arrow';
import DateRangePickerModule from './date-range-picker/date-range-picker';
import DatePickerModule from './date-picker/date-picker';
import DropdownModule from './dropdown/dropdown';
import DurationInputModule from './duration-input/duration-input';
import DurationEditorModule from './duration-editor/duration-editor';
import FilterMenuModule from './filter-menu/filter-menu';
import IconLabelValueModule from './icon-label-value/icon-label-value';
import ManageButtonsModule from './manage-buttons/manage-buttons';
import SectionHeadingModule from './section-heading/section-heading';
import SimpleModalModule from './simple-modal/simple-modal';
import SpinnerModule from './spinner/spinner';
import SplitBtnModule from './split-btn/split-btn';
import TimePickerModule from './time-picker/time-picker';
import TimeFramePickerModule from './time-frame-picker/time-frame-picker';
import ToastModule from './toast/toast';
import PhoneModule from './phone/phone';
import WeekdayViewerModule from './weekday-viewer/weekday-viewer';
import WeekdayEditorModule from './weekday-editor/weekday-editor';
import WeekPickerModule from './week-picker/week-picker';
import ReorderItemModule from './reorder-item/reorder-item';
import TooltipWrapperModule from './tooltip-wrapper/tooltip-wrapper';

const ComponentsModule = angular.module('control-room.commons.basic.components', [
    AlertModule.name,
    BreadcrumbsModule.name,
    CardModule.name,
    ChangeArrowModule.name,
    DatePickerModule.name,
    DateRangePickerModule.name,
    DropdownModule.name,
    DurationInputModule.name,
    DurationEditorModule.name,
    FilterMenuModule.name,
    IconLabelValueModule.name,
    ManageButtonsModule.name,
    SectionHeadingModule.name,
    SimpleModalModule.name,
    SpinnerModule.name,
    SplitBtnModule.name,
    TimePickerModule.name,
    TimeFramePickerModule.name,
    ToastModule.name,
    PhoneModule.name,
    WeekdayViewerModule.name,
    WeekdayEditorModule.name,
    WeekPickerModule.name,
    ReorderItemModule.name,
    TooltipWrapperModule.name,
]);

export default ComponentsModule;
