/* eslint-disable no-undefined */
import {
  Component, ViewEncapsulation, OnInit, OnDestroy, TemplateRef,
} from '@angular/core';
import { StateService } from '@uirouter/core';
import moment, { Moment } from 'moment';
import { Subject } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { LocalizedText } from '../../../../core';
import { NavigationService, ReservationService, PolicyService } from '../../../../shared';
import { ReservationTicket, Summary } from '../../../../shared/services/reservations/reservation.types';
import text from './resources/locale/en.json';
import { NavLocation } from '../nav-header/nav-header.component';

export enum ReservationStatus {
  PENDING,
  ARRIVED,
  CANCELLED,
}

export interface ReservationTicketGroup {
  [orderId: string]: ReservationTicket[];
}

export interface PerformanceGroup {
  [performanceTimeKey: string]: ReservationTicket[][];
}

export interface PerformanceSummaryMap {
  [performanceTimestamp: number]: Summary;
}

@Component({
  selector: 'cr-reservations-list',
  templateUrl: './reservations-list.component.html',
  styleUrls: [
    './reservations-list.component.scss',
    '../../../../../../node_modules/ngx-bootstrap/datepicker/bs-datepicker.scss',
  ],
  encapsulation: ViewEncapsulation.None,
})
export class ReservationsListComponent implements OnInit, OnDestroy {
  ReservationStatus = ReservationStatus;

  productId: string;

  text: LocalizedText;

  productTitle: string;

  isLoading: boolean;

  isSearching: boolean;

  selectedTab: ReservationStatus;

  selectedPerformance: number;

  sortedPerformanceTimes: Moment[] = [];

  performanceSummaryMap: any;

  startDate: Moment;

  endDate: Moment;

  performanceEditLoading = false;

  performanceSessionEdit = false;

  dateSessionEditValue: string = moment().format('MM/DD/YYYY');

  editSessionPerformanceTimes: Moment[] = [];

  timeslotEditValue: number;

  productSummaries: Summary[] = [];

  groupedTickets: ReservationTicketGroup = {};

  searchResults: ReservationTicketGroup[] = [];

  filteredTickets: ReservationTicketGroup[] = [];

  pendingTickets: PerformanceGroup = {};

  arrivedTickets: PerformanceGroup = {};

  cancelledTickets: PerformanceGroup = {};

  searchSub = new Subject<string>();

  searchQuery = '';

  editingTicketId: string;

  editTicketGroup: ReservationTicket[];

  isWriteAllowed: boolean;

  errorReservationId: string;

  markedArrivedTicketId: string;

  markedCancelledTicketId: string;

  successMessage: string;

  intervalId: number;

  modalRef: BsModalRef;

  navLocation: NavLocation;

  constructor(
    private state: StateService,
    private navigationService: NavigationService,
    private reservationService: ReservationService,
    private policyService: PolicyService,
    private modalService: BsModalService,
  ) {
    this.text = text as LocalizedText;
    this.isLoading = true;
    this.isSearching = false;
    this.selectedTab = ReservationStatus.PENDING;
    this.navLocation = NavLocation.Reservations;
  }

  ngOnInit(): void {
    this.productTitle = this.state.params.name;

    this.updateDate(moment(), true);

    this.searchSub
      .pipe(
        tap((query) => {
          this.searchQuery = query;
        }),
        debounceTime(300),
      )
      .subscribe((query) => {
        this.searchQuery = query;
        this.handleSearch();
      });

    this.policyService.hasAccess('write:reservation').then((allowed) => {
      if (allowed) {
        this.isWriteAllowed = true;
      }
    });
    // Set update schedule to 5 minutes.
    this.intervalId = window.setInterval(() => {
      if (!this.isSearching) {
        this.getReservationData();
      }
    }, 300000);
  }

  ngOnDestroy() {
    window.clearInterval(this.intervalId);
  }

  getReservationData(overridePerformanceSelection = false, refreshSearchResults = false) {
    this.isLoading = true;

    const { startDate } = this;
    const { endDate } = this;
    this.productId = this.state.params.productId;
    const dateString = startDate.format('YYYY-MM-DD');

    Promise.all([
      this.reservationService.getProductTickets(this.productId, startDate, endDate).then((response) => response.tickets.reduce((accumulator, ticket) => {
        /* Group tickets by orderId */
        (accumulator[ticket.extendedData.orderId] = accumulator[ticket.extendedData.orderId] || []).push(
          ticket,
        );

        return accumulator;
      }, {})),
      this.reservationService
        .getProductDaySummary(this.productId, dateString)
        .then((productSummaries) => productSummaries.productUnits),
    ])
      .then((response) => {
        const [groupedTickets, productSummaries] = response;
        this.groupedTickets = groupedTickets;
        this.productSummaries = productSummaries;

        if (productSummaries.length) {
          this.updatePerformancesFromSummaries(productSummaries, overridePerformanceSelection);

          const pendingTickets: PerformanceGroup = {};
          const arrivedTickets: PerformanceGroup = {};
          const cancelledTickets: PerformanceGroup = {};

          Object.keys(groupedTickets).forEach((orderId) => {
            const ticketGroup = groupedTickets[orderId];
            const ticketTimeKey = moment(ticketGroup[0].validity.startDateTime).valueOf();

            if (ticketGroup[0].status === 'PENDING') {
              pendingTickets[ticketTimeKey] = pendingTickets[ticketTimeKey] && pendingTickets[ticketTimeKey].length
                ? pendingTickets[ticketTimeKey].concat([ticketGroup])
                : [ticketGroup];
            } else if (ticketGroup[0].status === 'FULFILLED') {
              arrivedTickets[ticketTimeKey] = arrivedTickets[ticketTimeKey] && arrivedTickets[ticketTimeKey].length
                ? arrivedTickets[ticketTimeKey].concat([ticketGroup])
                : [ticketGroup];
            } else if (ticketGroup[0].status === 'REVOKED') {
              cancelledTickets[ticketTimeKey] = cancelledTickets[ticketTimeKey] && cancelledTickets[ticketTimeKey].length
                ? cancelledTickets[ticketTimeKey].concat([ticketGroup])
                : [ticketGroup];
            }
          });

          this.pendingTickets = pendingTickets;
          this.arrivedTickets = arrivedTickets;
          this.cancelledTickets = cancelledTickets;
        } else {
          this.sortedPerformanceTimes = [];
          this.selectedPerformance = null;
        }

        if (refreshSearchResults) {
          this.handleSearch(false);
        } else {
          this.selectFilter();
        }

        this.isLoading = false;
      })
      .catch(() => {
        this.isLoading = false;
      });
  }

  updatePerformancesFromSummaries(productSummaries: Summary[], overridePerformanceSelection?: boolean) {
    const performanceSummaryMap = {};

    const performanceTimes: Moment[] = productSummaries
      .map((summary) => {
        const performanceDateTime = moment(
          `${summary.productUnitMetadata.date} ${summary.productUnitMetadata.startTime}`,
        );
        performanceSummaryMap[performanceDateTime.valueOf()] = summary;

        return performanceDateTime;
      })
      .sort(this.sortSessions);
    this.editSessionPerformanceTimes = performanceTimes;
    this.sortedPerformanceTimes = performanceTimes;

    if (overridePerformanceSelection) {
      let selectedPerformance;
      const nearestPerformance = this.findNearestPerformance(this.sortedPerformanceTimes);
      if (nearestPerformance && nearestPerformance.isValid()) {
        selectedPerformance = nearestPerformance.valueOf();
      }

      this.timeslotEditValue = selectedPerformance;
      this.selectedPerformance = selectedPerformance;
    }

    this.performanceSummaryMap = performanceSummaryMap;
  }

  selectFilter() {
    let filteredTickets = [];

    switch (this.selectedTab) {
      case ReservationStatus.PENDING:
        filteredTickets = this.selectedPerformance ? this.pendingTickets[this.selectedPerformance] : [];

        break;
      case ReservationStatus.ARRIVED:
        filteredTickets = this.selectedPerformance ? this.arrivedTickets[this.selectedPerformance] : [];

        break;
      case ReservationStatus.CANCELLED:
        filteredTickets = this.selectedPerformance ? this.cancelledTickets[this.selectedPerformance] : [];

        break;
      default:
        this.selectedTab = ReservationStatus.PENDING;

        filteredTickets = this.pendingTickets[this.selectedPerformance];
    }

    this.filteredTickets = filteredTickets && filteredTickets.length ? filteredTickets.sort(this.sortByLastName) : [];
  }

  sortSessions = (firstSession, secondSession): number => firstSession.valueOf() - secondSession.valueOf();

  sortByLastName = (ticketGroup1: ReservationTicket[], ticketGroup2: ReservationTicket[]): number => {
    const guestName1 = ticketGroup1[0].ticketholderName.split(' ');
    const guestName2 = ticketGroup2[0].ticketholderName.split(' ');

    const lastName1 = guestName1[guestName1.length - 1];
    const lastName2 = guestName2[guestName2.length - 1];

    if (lastName1.toLowerCase() < lastName2.toLowerCase()) {
      return -1;
    }
    if (lastName1.toLowerCase() > lastName2.toLowerCase()) {
      return 1;
    }
    return 0;
  };

  sessionIsCancelled(sessionTimestamp: number): boolean {
    const performanceSummary: Summary = this.performanceSummaryMap[sessionTimestamp];

    return performanceSummary ? performanceSummary.summary.cancelled : false;
  }

  get isCurrentSessionCancelled(): boolean {
    if (this.selectedPerformance) {
      return this.sessionIsCancelled(this.selectedPerformance);
    }
    return false;
  }

  findNearestPerformance(performances: Moment[]): Moment {
    const now: Moment = moment();

    return performances.reduce((previous, current) => {
      if (current.isBefore(now)) {
        return current;
      }

      return previous;
    });
  }

  searchChangeEvent(event: Event) {
    this.searchSub.next((event.target as HTMLInputElement).value);
  }

  /**
     * Selects the next performance slot from memory.
     * If it has reached the last performance for that day, load the next available date.
     */
  nextPerformance() {
    const selectedPerfIndex = this.sortedPerformanceTimes.findIndex(
      (performanceDateTime) => performanceDateTime.valueOf() === this.selectedPerformance,
    );

    if (selectedPerfIndex !== undefined && selectedPerfIndex + 1 < this.sortedPerformanceTimes.length) {
      const next = this.sortedPerformanceTimes[selectedPerfIndex + 1].valueOf();
      this.timeslotEditValue = next;
      this.selectedPerformance = next;
    } else {
      const nextDate = moment(this.startDate.format()).add(1, 'days');

      this.updateDate(nextDate);
    }

    this.selectFilter();
  }

  /**
     * Selects the previous performance slot from memory.
     * If it has reached the first performance for that day, load the previous available date.
     */
  prevPerformance() {
    const selectedPerfIndex = this.sortedPerformanceTimes.findIndex(
      (performanceDateTime) => performanceDateTime.valueOf() === this.selectedPerformance,
    );

    if (selectedPerfIndex !== undefined && selectedPerfIndex > 0) {
      // TODO: Add no previous date check
      const prev = this.sortedPerformanceTimes[selectedPerfIndex - 1].valueOf();
      this.timeslotEditValue = prev;
      this.selectedPerformance = prev;
    } else {
      const previousDate = moment(this.startDate.format()).subtract(1, 'days');

      if (!previousDate.isBefore(moment(), 'day')) {
        this.updateDate(previousDate);
      }
    }

    this.selectFilter();
  }

  updateDate(selectedDate: Moment, overridePerformanceSelection = false) {
    const startDate = moment(selectedDate.format('YYYY-MM-DD[Z]')).utcOffset(0);
    startDate.set({
      hours: 0, minutes: 0, seconds: 0, milliseconds: 0,
    });

    const endDate = moment(selectedDate.format('YYYY-MM-DD[Z]')).utcOffset(0);
    endDate.set({
      hours: 23, minutes: 59, seconds: 59, milliseconds: 999,
    });

    this.startDate = startDate;
    this.endDate = endDate;

    this.getReservationData(overridePerformanceSelection);
  }

  onBack(): void {
    this.navigationService.goBack('client.fnb-reservations.dashboard', {});
  }

  selectTab(tabSelection: ReservationStatus, refresh = false): void {
    this.errorReservationId = null;

    this.selectedTab = tabSelection;
    if (refresh || !!this.markedArrivedTicketId || !!this.markedCancelledTicketId) {
      this.getReservationData();

      this.successMessage = null;
      this.markedArrivedTicketId = null;
      this.markedCancelledTicketId = null;
    } else {
      this.selectFilter();
    }
  }

  refreshReservations() {
    this.getReservationData();
  }

  openModal(template: TemplateRef<any>, ticketGroup: ReservationTicket[]) {
    this.editTicketGroup = ticketGroup;
    this.modalRef = this.modalService.show(template, { ...{ class: 'modal-sm' } });
  }

  hideModal() {
    this.modalRef.hide();
  }

  confirmCancellation() {
    this.modalRef.hide();
    this.updateReservations(this.editTicketGroup, 'CANCELLED');
  }

  searchFocus() {
    if (this.toastIsShowing()) {
      this.successMessage = null;
      this.markedArrivedTicketId = null;
      this.markedCancelledTicketId = null;
      this.errorReservationId = null;

      this.getReservationData();
    }

    if (!this.isSearching) {
      this.filteredTickets = [];
    }

    this.isSearching = true;
  }

  searchBlur() {
    this.isSearching = !!this.searchQuery;

    if (!this.isSearching) {
      this.selectFilter();
    }
  }

  handleSearch(dismissToasts = true) {
    this.isLoading = true;

    if (dismissToasts) {
      this.successMessage = null;
      this.markedArrivedTicketId = null;
      this.markedCancelledTicketId = null;
      this.errorReservationId = null;
    }

    if (!this.searchQuery) {
      this.filteredTickets = [];
      this.isLoading = false;

      return;
    }

    const lowerCaseQuery = this.searchQuery.toLowerCase();
    const filteredTickets = [];
    const groupedTicketIDs = Object.keys(this.groupedTickets);

    for (let index = 0; index < groupedTicketIDs.length; index++) {
      const ticket = this.groupedTickets[groupedTicketIDs[index]][0];
      if (
        ticket.ticketholderName.toLowerCase().includes(lowerCaseQuery)
                || ticket.orderEmail.toLowerCase().includes(lowerCaseQuery)
                || ticket.extendedData.orderPhone.toLowerCase().includes(lowerCaseQuery)
                || ticket.extendedData.thirdPartyReference.toLowerCase().includes(lowerCaseQuery)
                || this.groupedTickets[groupedTicketIDs[index]].length === parseInt(lowerCaseQuery)
      ) {
        filteredTickets.push(this.groupedTickets[groupedTicketIDs[index]]);
      }
    }

    this.filteredTickets = filteredTickets.sort(this.sortByLastName);
    this.isLoading = false;
  }

  clearSearch() {
    this.isSearching = false;
    this.searchQuery = '';
    this.selectFilter();
  }

  toggleSessionEditor() {
    this.performanceSessionEdit = !this.performanceSessionEdit;
  }

  selectedPerformanceDateString(): string {
    let performanceDate;

    if (this.selectedPerformance && this.performanceSummaryMap[this.selectedPerformance]) {
      performanceDate = moment(
        `${this.performanceSummaryMap[this.selectedPerformance].productUnitMetadata.date}, ${
          this.performanceSummaryMap[this.selectedPerformance].productUnitMetadata.startTime
        }`,
      );
    } else {
      performanceDate = this.startDate;
    }

    if (performanceDate.isSame(moment(), 'day')) {
      return performanceDate.format('[Today,] h:mm A');
    }
    if (performanceDate.isSame(moment().add(1, 'day'), 'day')) {
      return performanceDate.format('[Tomorrow,] h:mm A');
    }

    return performanceDate.format('MMM D, YYYY, h:mm A');
  }

  timeslotEditString(): string {
    if (this.performanceEditLoading) {
      return 'Loading ...';
    }
    if (this.timeslotEditValue) {
      return moment(this.timeslotEditValue).format('h:mm A');
    }

    return '--:--';
  }

  selectTimeslot(selectedPerformance: number) {
    this.successMessage = null;
    this.markedArrivedTicketId = null;
    this.markedCancelledTicketId = null;
    this.errorReservationId = null;

    this.selectedPerformance = selectedPerformance;
    this.timeslotEditValue = selectedPerformance;
    this.selectFilter();
  }

  initialDatePickerValue() {
    return new Date(this.startDate.valueOf());
  }

  submitSessionEdit() {
    if (this.timeslotEditValue && !moment.utc(this.timeslotEditValue.valueOf()).isSame(this.startDate, 'day')) {
      this.updateDate(moment(this.timeslotEditValue));
    }

    this.toggleSessionEditor();
  }

  changeDate(date: Date) {
    const dateMoment = moment(date.toISOString());

    if (dateMoment.isValid()) {
      this.dateSessionEditValue = dateMoment.format('MM/DD/YYYY');
      this.performanceEditLoading = true;
      this.reservationService
        .getProductDaySummary(this.productId, dateMoment.format('YYYY-MM-DD'))
        .then((productSummaries) => {
          const { productUnits } = productSummaries;

          this.updatePerformancesFromSummaries(productUnits, true);
          this.performanceEditLoading = false;
        })
        .catch(() => {
          this.performanceEditLoading = false;
          this.editSessionPerformanceTimes = [];
          this.timeslotEditValue = null;
        });
    }
  }

  editTicket(ticket: ReservationTicket): void {
    if (this.isWriteAllowed) {
      if (
        ticket.status !== 'REVOKED'
                && ticket.status !== 'FULFILLED'
                && this.editingTicketId !== ticket.ticketId
      ) {
        this.editingTicketId = ticket.ticketId;
      } else {
        this.editingTicketId = '';
      }
    }
  }

  onSwipeLeft($event, ticket) {
    this.editTicket(ticket);
  }

  onSwipeRight($event, ticket) {
    if (this.editingTicketId === ticket.ticketId) {
      this.editingTicketId = '';
    }
  }

  updateReservations(tickets: ReservationTicket[], status: string) {
    this.editingTicketId = '';
    if (tickets[0].status !== 'FULFILLED' && status === 'ARRIVED') {
      this.reservationService
        .admitGuest(tickets)
        .then(() => {
          this.errorReservationId = null;
          this.markedArrivedTicketId = tickets[0].extendedData.orderId;

          const updatedTickets = this.arrivedTickets[this.selectedPerformance]
            ? this.arrivedTickets[this.selectedPerformance]
            : [];
          updatedTickets.concat([tickets]);

          this.successMessage = `${this.text.successMessageStart} ${tickets[0].ticketholderName} ${this.text.successMovedArrived}`;
          this.arrivedTickets[this.selectedPerformance] = updatedTickets;

          if (this.isSearching || this.searchQuery) {
            this.getReservationData(false, true);
          }
        })
        .catch((error) => {
          this.successMessage = null;
          this.markedArrivedTicketId = null;
          this.markedCancelledTicketId = null;
          this.errorReservationId = tickets[0].extendedData.orderId;
          console.log(`Unable to update reservation: ${error}`);
        });
    } else if (tickets[0].status !== 'REVOKED') {
      this.reservationService
        .cancelTickets(tickets)
        .then(() => {
          this.errorReservationId = null;
          this.markedCancelledTicketId = tickets[0].extendedData.orderId;

          const updatedTickets = this.cancelledTickets[this.selectedPerformance]
            ? this.cancelledTickets[this.selectedPerformance]
            : [];
          updatedTickets.concat([tickets]);

          this.successMessage = `${this.text.successMessageStart} ${tickets[0].ticketholderName} ${this.text.succeessMovedCancelled}`;
          this.cancelledTickets[this.selectedPerformance] = updatedTickets;

          if (this.isSearching || this.searchQuery) {
            this.getReservationData(false, true);
          }
        })
        .catch((error) => {
          this.successMessage = null;
          this.markedArrivedTicketId = null;
          this.markedCancelledTicketId = null;
          this.errorReservationId = tickets[0].extendedData.orderId;
          console.log(`Unable to update reservation: ${error}`);
        });
    }
  }

  toastIsShowing(): boolean {
    return !!this.markedArrivedTicketId || !!this.markedCancelledTicketId;
  }

  dismissToasts() {
    this.successMessage = null;
    this.markedArrivedTicketId = null;
    this.markedCancelledTicketId = null;
    this.errorReservationId = null;

    if (this.isSearching || this.searchQuery) {
      this.getReservationData(false, true);
    } else {
      this.getReservationData();
    }
  }

  navigateToReservation() {
    if (this.isSearching) {
      this.clearSearch();
    }

    if (this.markedArrivedTicketId && !this.markedCancelledTicketId) {
      this.selectTab(ReservationStatus.ARRIVED, true);
    } else if (!this.markedArrivedTicketId && this.markedCancelledTicketId) {
      this.selectTab(ReservationStatus.CANCELLED, true);
    }

    this.dismissToasts();
  }

  bsConfig(): Partial<BsDatepickerConfig> {
    return {
      minDate: new Date(),
      showWeekNumbers: false,
      containerClass: 'theme-default',
    };
  }

  reservationTicketTimeStr(date: string): string {
    return moment(date).format('h:mmA');
  }

  toastExists(): boolean {
    return Boolean(this.markedArrivedTicketId || this.markedCancelledTicketId);
  }
}
