import angular from 'angular';

import PosEnableModalComponent from './pos-enable-modal.component';

import locale from './resources/locale/en.json';

const PosEnableModalModule = angular
    .module('control-room.places.components.place-details.components.pos-enable-modal', [])
    .constant('crPosEnableModalLocale', locale)
    .component('crPosEnableModal', PosEnableModalComponent);

export default PosEnableModalModule;
