import { Inject, Injectable } from '@angular/core';
// Using uirouter/hybrid but need to import uirouter/core
import { StateService } from '@uirouter/core';
import * as _ from 'lodash';
import { CR_CONFIG } from '../config';
import { Config, LanguageConfig } from '../../../core';
import {
  Locale, LocaleListItem, LocalizedEnumValue, LocalizedValues,
} from './locale.model';

@Injectable({ providedIn: 'root' })
export class LocaleService {
  constructor(@Inject(CR_CONFIG) private config: Config, private state: StateService) {
  }

  getLocale(venueId: string, localeSearch?: string, useDefault?: boolean, skipStateUpdate?: boolean): Locale {
    let languages: LanguageConfig[];

    if (venueId && venueId !== 'default') {
      const venue = _.find(this.config.customer.venues, { id: venueId });
      languages = venue.localization.languages;
    } else {
      languages = this.config.customer.localization.languages;
    }

    const defaultLocale = _.find(languages, { default: true });
    const deepLinkedLocale = _.find(languages, { tag: localeSearch });

    let currentLocale = defaultLocale;
    if (useDefault || !deepLinkedLocale) {
      if (!skipStateUpdate) {
        if (_.has(this.state.params, 'locale') && this.state.params.locale !== defaultLocale.tag) {
          // eslint-disable-next-line no-void
          void this.state.go(
            this.state.current.name,
            { locale: defaultLocale.tag },
            { location: 'replace' },
          );
        }
      }
    } else {
      currentLocale = deepLinkedLocale;
    }

    return {
      default: defaultLocale.tag,
      current: currentLocale.tag,
      list: this.mapLanguagesToLocalListItems(currentLocale.tag, languages),
    };
  }

  getLocaleObject(list: LocaleListItem[]): { [key:string]: unknown } {
    const obj = {};
    list.forEach((lang) => { obj[lang.id] = {}; });
    return obj;
  }

  updateLocaleListStatus(missingTranslations: string[], locale: Locale): LocaleListItem[] {
    if (locale.list.length > 1) {
      locale.list.forEach((i) => {
        const item = i;
        item.hasStatusWarning = _.includes(missingTranslations, item.id);
      });
    }

    return locale.list;
  }

  updateLocaleStatus(localizedValues: LocalizedValues, locale: Locale): LocaleListItem[] {
    const localeClone = _.cloneDeep(locale);
    const missingTranslations = this.getMissingPropertyTranslations(localizedValues, localeClone);
    return this.updateLocaleListStatus(missingTranslations, localeClone);
  }

  getMissingPropertyTranslations(localization: LocalizedValues, locale: Locale): string[] {
    const missingTranslations: string[] = [];

    if (locale.list.length > 1) {
      locale.list
        .filter((item) => item.id !== locale.default)
        .forEach((item) => {
          _.forOwn(localization[locale.default], (value, key) => {
            let hasStatusWarning = false;

            if (key === 'id') {
              return true;
            }

            if (localization[item.id]) {
              if (_.isObject(value)) {
                hasStatusWarning = value.link
                  && value.label
                  && !(
                    localization[item.id][key]
                    && _.get(localization[item.id][key], 'link')
                    && _.get(localization[item.id][key], 'label')
                  );
              } else {
                hasStatusWarning = value && !localization[item.id][key];
              }
            }

            if (hasStatusWarning) {
              missingTranslations.push(item.id);
              return false;
            }

            return true;
          });
        });
    }

    return missingTranslations;
  }

  getMissingEnumPropertyTranslations(enums: LocalizedEnumValue[], locale: Locale): string[] {
    let missingTranslations: string[] = [];
    const enumsClone = _.cloneDeep(enums);
    _.forEach(enumsClone, (e) => {
      _.forEach(missingTranslations, (value) => {
        delete e.localization[value];
      });

      const newMissingTranslations = this.getMissingPropertyTranslations(e.localization, locale);
      missingTranslations = missingTranslations.concat(newMissingTranslations);
    });
    return missingTranslations;
  }

  private mapLanguagesToLocalListItems(currentLocale: string, localeList: LanguageConfig[]): LocaleListItem[] {
    return localeList.map((locale) => ({
      id: locale.tag,
      label: locale.name,
      isSelected: currentLocale === locale.tag,
    }));
  }
}
