<section class="bundle-list-header">
  <cr-dropdown
    class="header-item"
    [label]="text.stateLabel"
    [menuItems]="states"
    (onSelect)="onStateSelect($event)"
    mode="filter"
  >
  </cr-dropdown>
  <cr-dropdown
    class="header-item"
    [label]="text.categoryLabel"
    [menuItems]="categories"
    (onSelect)="onCategorySelect($event)"
    mode="filter"
    showSearch="true"
    sort="asc"
  >
  </cr-dropdown>
  <cr-search
    class="header-item"
    [placeholder]="text.search"
    (update)="handleSearch($event)"
    [formControl]="searchFormControl"
  >
  </cr-search>
</section>

<cr-table
  [options]="crTableOptions"
  (columnSort)="handleSort($event)"
  (columnClick)="handleColumnClick($event)"
  (hoverIconClick)="handleRowIconClick($event)"
>
</cr-table>
