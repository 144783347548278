<cr-title-bar icon="icon-cr-back" [label]="label" (onClick)="onBack()">
  <div titleBarRight>
    <div class="icon-cr-edit cta" (click)="goToEditPage()"></div>
  </div>
</cr-title-bar>
<cr-table [options]="crTableOptions" *ngIf="!loading; else spinner"></cr-table>
<ng-template #spinner>
  <div class="text-center">
    <cr-spinner small="true"></cr-spinner>
  </div>
</ng-template>
