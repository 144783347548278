import { embedDashboard } from 'amazon-quicksight-embedding-sdk';
import text from './resources/locale/en.json';

class QuicksightEmbedController {
    constructor($q, $state, crQuicksightService) {
        this.$q = $q;
        this.$state = $state;
        this.crQuicksightService = crQuicksightService;
        this.text = text;
    }

    $onInit() {
        this.isLoading = true;
        this.isError = false;
    }

    $onChanges(changes) {
        if (changes.url && this.enabled) {
            this.initializeDashboard();
        }
    }

    initializeDashboard() {
        if (this.url) {
            const options = {
                url: this.url,
                container: '#quicksight-embed-target',
                parameters: {
                    venueId: this.$state.params.venueId,
                    customerId: this.$state.params.customerId,
                },
                height: 'AutoFit',
                loadingHeight: '500px',
                loadCallback: this.onQsLoad.bind(this),
                errorCallback: this.onQsError.bind(this),
                scrolling: 'yes',
            };

            this.dashboard = embedDashboard(options);
        }
    }

    onQsLoad() {
        this.isLoading = false;
        this.isError = false;
    }

    onQsError(errors) {
        this.isError = true;
        console.error(`Error: ${errors}`);
    }
}

export default QuicksightEmbedController;
