import template from './inline-chips.html';
import './inline-chips.less';
import InlineChipsController from './inline-chips.controller';

const InlineChipsComponent = {
    template,
    bindings: {
        list: '<',
    },
    controller: ['$element', '$scope', '$timeout', '$sce', InlineChipsController],
};

export default InlineChipsComponent;
