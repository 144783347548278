import angular from 'angular';

import PoiMapComponent from './poi-map.component';
import PoiMapTooltipComponent from './poi-map-tooltip.component';

const PoiMap = angular
    .module('control-room.poi.components.poi-map', [])
    .component('crPoiMap', PoiMapComponent)
    .component('crPoiMapTooltip', PoiMapTooltipComponent);

export default PoiMap;
