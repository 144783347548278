import _ from 'lodash';

class PlacesListController {
    constructor($state, placesListService, crConstants, crAnalyticsService, placesListLocale, errorLoggingService) {
        this.$state = $state;
        this.placesListService = placesListService;
        this.filterTypes = crConstants.filterTypes;
        this.analyticsService = crAnalyticsService;
        this.text = placesListLocale;
        this.errorLoggingService = errorLoggingService;
    }

    $onInit() {
        this.params = {
            page: 1,
            perPage: 20,
            sort: '',
            sortby: '',
            search: this.$state.params.search || '',
            state: '',
            timeframe: {
                start: '',
                end: '',
            },
        };

        this.isLoading = false;
        this.endOfResults = false;
        this.hasDataError = false;

        this.stateFilterData = _.cloneDeep(this.filterTypes.state);
        this.stateFilterData[0].isSelected = true;

        const { columns } = this.text;
        this.listData = {
            columns: [
                { key: 'placeholder', label: '' },
                { key: 'name', label: columns.name, class: 'large-column name-column', sortable: true },
                { key: 'state', label: columns.state, sortable: true },
                { key: 'id', label: columns.id },
                { key: 'organizationId', label: columns.organizationId, sortable: true },
                { key: 'orders', label: columns.orders, class: 'medium-column' },

                /* { key: 'change', label: columns.change } */
            ],
            rows: [],
        };

        this.debounceUpdateData = _.debounce(this.updateData, 250);

        this.loadNextPage = () => {
            if (!this.isLoading && !this.endOfResults) {
                this.params.page += 1;
                this.analyticsService.track('Page Scroll', { currentPage: this.params.page });
                this.getData().then((rows) => {
                    this.listData.rows.push(...rows);
                    this.isLoading = false;
                });
            }
        };
    }

    rangeUpdated(event) {
        this.params.timeframe = event.range;

        if (this.isLinked) {
            this.updateData();
        }
    }

    $postLink() {
        this.isLinked = true;
        this.handleDeepLink();
        this.updateData();
    }

    handleDeepLink() {
        const truthyParams = _.pickBy(this.$state.params, (p) => !_.isNil(p));

        _.forOwn(truthyParams, (value, key) => {
            const hasKey = _.has(this.params, key);

            if (hasKey && key !== 'timeframe') {
                this.params[key] = value;
            }
        });
    }

    getData() {
        this.isLoading = true;
        this.hasDataError = false;

        return this.placesListService
            .getData(this.params)
            .then((data) => {
                data.search = data.search || '';

                if (data.search === this.params.search) {
                    this.isLoading = false;

                    if (data.places.length) {
                        this.updateSearchDeepLink();
                    }

                    if (data.places.length !== this.params.perPage) {
                        this.endOfResults = true;
                    }

                    return this.placesListService.mapToRowsModel(data.places, data.orders);
                }
            })
            .catch((err) => {
                this.isLoading = false;
                this.endOfResults = true;
                this.hasDataError = true;
                this.listData.rows = [];
                this.errorLoggingService.logError('Could not get Places List Data', err, this.params);
            });
    }

    updateData() {
        this.params.page = 1;
        this.endOfResults = false;
        this.listData.rows = [];

        this.getData().then((rows) => {
            this.listData.rows = rows;
        });
    }

    updateSearch(term) {
        term = term || '';
        this.params.search = term;

        if (term.length === 0 || term.length >= 3) {
            this.debounceUpdateData();
        } else {
            this.setShortSearchTimer(term);
        }
    }

    setShortSearchTimer(term) {
        setTimeout(() => {
            if (term === this.params.search) {
                this.debounceUpdateData();
            }
        }, 1000);
    }

    updateRoute(params) {
        return this.$state.go(this.$state.current.name, params, {
            location: 'replace',
        });
    }

    updateSearchDeepLink() {
        const { search } = this.params;
        if (search !== this.$state.params.search) {
            this.updateRoute({ search });
        }
    }

    updateParam(key, event) {
        this.params[key] = _.isEmpty(event.model) ? '' : event.model[0].id;
        this.updateData();
    }

    sortChange(column) {
        this.params.sort = column.sort;
        this.params.sortby = column.key;
        this.updateData();
    }

    listIsEmpty() {
        return this.listData.rows && !this.listData.rows.length && !this.isLoading && !this.hasDataError;
    }
}

export default PlacesListController;
