import {
  Component, OnChanges, SimpleChanges, Input, ElementRef, ViewEncapsulation,
} from '@angular/core';
import { CrIconService } from './icon.service';

@Component({
  selector: 'cr-icon',
  template: '',
  styleUrls: ['./icon.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CrIconComponent implements OnChanges {
  @Input()
  name: string;

  el: HTMLElement;

  constructor(private iconService: CrIconService, elementRef: ElementRef) {
    this.el = elementRef.nativeElement;
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.name && changes.name.currentValue) {
      const svgHtml = await this.iconService.get(changes.name.currentValue);
      this.el.innerHTML = svgHtml;
    }
  }
}
