import Controller from './action-item-list.controller';
import template from './action-item-list.html';
import './action-item-list.less';

const Component = {
    template,
    bindings: {
        items: '<',
        imageShape: '@?',
    },
    controller: ['crImageService', Controller],
};

export default Component;
