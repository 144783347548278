import KitchenDetailsController from './kitchen-details.controller';
import template from './kitchen-details.html';
import './kitchen-details.less';

const Component = {
    template,
    bindings: {
        onBack: '&',
        onEdit: '&',
        onArchive: '&',
        kitchenid: '<?',
    },
    controller: [
        '$state',
        '$window',
        '$uibModal',
        '$q',
        'crNavigationService',
        'crPlacesService',
        'crKitchenManageService',
        'crLocaleService',
        'crLocalizationService',
        'crConstants',
        'crErrorLoggingService',
        'crEntityService',
        'crConfig',
        'crMenusService',
        'crPrinterService',
        'crPrintManagerService',
        'crFeesService',
        'crTimeService',
        'crLockerService',
        KitchenDetailsController,
    ],
};
export default Component;
