import { InvalidPropertyError } from '../../../error/error';

class RadioController {
    $onInit() {
        if (!Array.isArray(this.options) || !this.options.length) {
            throw new InvalidPropertyError('options');
        }

        this.disabled = this.disabled || false;
        this.required = this.required || false;
        this.name = this.name || '';
        this.onChange = this.onChange || (() => null);
    }

    setValue(value) {
        if (!this.disabled) {
            this.model = value;

            if (this.form) {
                this.form.$setDirty();
            }

            this.onChange({ value });
        }
    }
}

export default RadioController;
