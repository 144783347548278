import angular from 'angular';
import bulletTrain from 'bullet-train-client';
import _ from 'lodash';
import moment from 'moment';
import { catchError, take } from 'rxjs/operators';
import { menuRoutes } from '../../menus.routes';
import text from './resources/locale/en.json';

export default class KitchenDetailsController {
    constructor(
        $state,
        $window,
        $uibModal,
        $q,
        crNavigationService,
        crPlacesService,
        crKitchenManageService,
        crLocaleService,
        crLocalizationService,
        crConstants,
        crErrorLoggingService,
        crEntityService,
        crConfig,
        crMenusService,
        crPrinterService,
        crPrintManagerService,
        crFeesService,
        crTimeService,
        crLockerService
    ) {
        this.$state = $state;
        this.$window = $window;
        this.$uibModal = $uibModal;
        this.$q = $q;
        this.crNavigationService = crNavigationService;
        this.crPlacesService = crPlacesService;
        this.crKitchenManageService = crKitchenManageService;
        this.crLocaleService = crLocaleService;
        this.crLocalizationService = crLocalizationService;
        this.crConstants = crConstants;
        this.crErrorLoggingService = crErrorLoggingService;
        this.crEntityService = crEntityService;
        this.crConfig = crConfig;
        this.crMenusService = crMenusService;
        this.crPrinterService = crPrinterService;
        this.crPrintManagerService = crPrintManagerService;
        this.crFeesService = crFeesService;
        this.crTimeService = crTimeService;
        this.crLockerService = crLockerService;
        this.fees = [];
        this.pickupZones = [];
        this.isMobile = false;
        this.currentLocker = null;
        this.lockerWebContentFields = [
            'lockerPickupDetailsHeader',
            'lockerPickupDetailsBody',
            'lockerFulfillmentNotificationInfo'
        ];
    }

    $onInit() {
        this.text = text;
        this.setupKitchenDetails();
        this.isMobile = this.$window.innerWidth < 992;
        angular.element(this.$window).on('resize', () => {
            this.isMobile = this.$window.innerWidth < 992;
        });
    }

    $onChanges() {
        this.setupKitchenDetails();
    }

    setupKitchenDetails() {
        this.loading = true;
        this.profiles = [];
        this.configOptions = undefined;
        this.originalFeeList = [];
        this.relatedMenus = [];
        this.configOptionsForZonesMap = {};
        this.fees = [];
        this.pickupZones = [];

        this.isPtwFeatureFlagEnabled = bulletTrain.hasFeature('fnb_ptw');

        this.locale = this.crLocaleService.getLocale(this.$state.params.venueId, this.$state.params.locale, true);
        this.initLocaleDropdown();

        this.loadConfigOptions();

        this.getCurrencyCode();

        if (!this.kitchenid) {
            this.kitchenid = this.$state.params.kitchenId;
        }
        
        this.loadKitchen().then(() => {
            this.loadFees();
            this.updatePtwWebContent();
            this.loadLockers();
            
            this.loading = false;
        });


        this.crMenusService.getMenusForPlace(this.kitchenid, this.locale.current).then((menus) => {
            this.relatedMenus = menus.content;
        });

        this.loadPrinters();
        this.testPrintSucceeded = null;

        this.webContentWithoutPtw = [
            'preorderBillingMessage',
            'preorderTaxAndDiscount',
            'preorderConfirmationMessage',
            'preorderConfirmationPickupLocation',
            'preorderConfirmationPickupInstructions',
            'preorderPickupMessage',
            'phoneNumberCollectionTitle',
            'phoneNumberCollectionContent',
            'phoneNumberCollectionTooltip',
            'phoneNumberCollectionOptInTitleOne',
        ];
        this.webContentWithPtw = [
            'preorderConfirmationMessage',
            'preorderConfirmationPickupLocation',
            'preorderConfirmationPickupInstructions',
            'checkinMessage',
            'preCheckinMessage',
            'checkInButton',
            'checkedInMessage',
            'postCheckinMessage',
            'postCheckinMessageButton',
            'checkinErrorTitle',
            'checkinErrorBody',
            'phoneNumberCollectionTitle',
            'phoneNumberCollectionContent',
            'phoneNumberCollectionTooltip',
            'phoneNumberCollectionOptInTitleOne',
        ];
    }

    updatePtwWebContent() {
        this.webContent = this.kitchen.features.pickupTimeWindowsEnabled? 
                        [...this.webContentWithPtw]: [...this.webContentWithoutPtw];
    }

    formatTime(time) {
        return moment(this.roundTimeToNearQuarterHour(time), 'HH:mm').format('h:mm a');
    }

    roundTimeToNearQuarterHour(time) {
        time = time.split(":");
        let hours = parseInt(time[0]);
        let minutes = parseInt(time[1]);
        if(hours === 23 && minutes > 52) {
            return '23:45:00';
        } else {
            return this.crTimeService.roundTimeToNearQuarterHour(hours, minutes)
        }
    }

    goBack() {
        this.onBack();
    }

    goToEdit() {
        this.onEdit({
            $event: {
                kitchenId: this.kitchen.id
            }
        });
    }

    archive() {
        if (this.isLoading) {
            return;
        }

        let message = this.text.archiveModalMessage;

        if (this.relatedMenus && this.relatedMenus.length > 0) {
            const menuNames = this.relatedMenus.map((menu) => menu.content.name);
            const menuInfoList = `<div>${menuNames.join('</div><div>')}</div>`;
            message = `<p>${this.text.archiveModalMessageWithMenus}</p><p>${menuInfoList}</p>`;
        }

        this.$uibModal
            .open({
                backdrop: 'static',
                component: 'crSimpleModal',
                windowClass: 'cr-modal-size-md',
                resolve: {
                    message: () => message,
                    type: () => this.crConstants.modalTypes.SUBMIT,
                    submitText: () => this.text.archiveModalSubmit,
                },
            })
            .result.then(() => {
                this.isLoading = true;

                this.crEntityService
                    .deleteEntity(this.crConstants.entity.types.PLACES, this.kitchen.id, this.$state.params.venueId)
                    .then(() => {
                        this.$state.go(menuRoutes.DASHBOARD, {
                            tabId: 'kitchens',
                        });
                        this.onArchive({
                                toast: {
                                    msg: `"${this.kitchen.name}" ${this.text.hasBeenArchived}`,
                                },
                        });
                    })
                    .catch(() => {
                        this.openModal(`${this.text.problemArchiving} "${this.kitchen.name}"`);
                        this.isLoading = false;
                    });
            })
            .catch(() => null);
    }

    openModal(message) {
        this.$uibModal
            .open({
                backdrop: 'static',
                component: 'crSimpleModal',
                windowClass: 'cr-modal-size-sm',
                resolve: {
                    message: () => message,
                },
            })
            .result.catch(() => null);
    }

    loadConfigOptions() {
        // Load the commerce configuration options to set up the F&B location
        this.crKitchenManageService.getConfigOptions(this.$state.params.customerId).then((result) => {
            this.configOptions = result.configOptions; // This is an array
            this.updateConfigurationDisplay();
        });

        this.crKitchenManageService.getConfigOptionsForZones(this.$state.params.customerId).then((result) => {
            this.configOptionsForZonesMap = {};
            result.forEach((zone) => {
                this.configOptionsForZonesMap[zone.regex] = zone.label;
            });
        });
    }

    loadKitchen() {
        return this.crPlacesService
            .getPlaceWithICalDays(this.kitchenid)
            .then((kitchen) => {
                this.kitchen = kitchen;
                this.pickupZones = this.kitchen.features.pickupZones || [{ requiresEntry: false }];
                this.updateConfigurationDisplay();
                this.onLoaded();
                return this.getLocalization();
            })
            .catch((err) => {
                this.crErrorLoggingService.logError('Could not get kitchen', err, this.kitchenid);
                this.error = true;
            });
    }

    printerUrl() {
        const apiGateway = 'https://api.us.te2.io';
        return `${apiGateway}/customer/${this.$state.params.customerId}/venue/${this.$state.params.venueId}/print-jobs`;
    }

    loadPrinters() {
        this.printers = [];
        this.autoPrintEnabled = false;
        this.crPrintManagerService.getPrinters(this.kitchenid).then((response) => {
            this.printers = response.printers;
            this.autoPrintEnabled = !!_.find(this.printers, (printer) => printer.printOnCheckin);
        });
    }

    loadLockers() {
        if (this.loading && this.kitchen?.features?.pickupZones?.length === 1 && this.kitchen.features.pickupZones[0].type === 'LOCKER_PICKUP') {                
            this.crLockerService.getLockers().then((data) => {
                if (data) {
                    this.currentLocker = data.filter(locker => locker.id === this.kitchen.features.pickupZones[0].pickupZoneId)[0];
                }
            });
        }
    }

    loadFees() {
        this.crFeesService.getFeesByVenue(this.$state.params.venueId).pipe(
            take(1),
            catchError(
                (err) => this.crErrorLoggingService.logError('Could not get kitchen', err, this.kitchenid)),
          ).subscribe((fees) => {
            if(fees.length) {
                this.originalFeeList = [...fees];
                this.feesOptions = fees.filter(f => f.displayName && f.publishingStatus === "ACTIVE");
                this.mapSelectedFees();
            }
        });
    }

    mapSelectedFees() {
        if(!this.kitchen.features.applicableFees) {
            this.kitchen.features.applicableFees = [];
        }

        const feeIdList = this.originalFeeList.map((fee) => fee.id)
        const allFeeExist = this.kitchen.features.applicableFees.every((feeId) => {
                return feeIdList.includes(feeId);
        });
        
        if(allFeeExist) {
            const selectedFees = this.kitchen.features.applicableFees.map((feeId) => {
            return this.originalFeeList.find(fee => fee.id === feeId);
            });
            this.fees = selectedFees.map((fee) => ({
                ...fee,
                type: this.determineFeeCalcDisplay(fee),
            }));
        } else {
            const promises = [];

            this.kitchen.features.applicableFees.forEach((feeId) => {
                promises.push(
                    this.crFeesService.getFeeById(feeId).toPromise().
                    catch((err) => {
                        if(err.status === 404) return null; 
                    }
                ));
            });

            this.$q.all(promises).then((selectedFees) => {
                selectedFees = selectedFees.filter((fee) => fee!== null);
                this.fees = selectedFees.map((fee) => ({
                    ...fee,
                    type: this.determineFeeCalcDisplay(fee),
                }));
            });
        }
    }

    determineFeeCalcDisplay(fee) {
        let displayValue = 'unknown';

        if (fee.type === 'FIXED') {
        displayValue = `Flat Rate (${fee.amountOrPercent} ${this.currencyCode})`;
        } else if (fee.type === 'PERCENT') {
        displayValue = `Percentage of total (${fee.amountOrPercent}%)`;
        }

        return displayValue;
    }

    getCurrencyCode() {
        this.currencyCode = _.find(this.crConfig.customer.venues, {
          id: this.$state.params.venueId,
        }).currencyCode;
    }

    sendTestPrint() {
        const job = {
            externalOrderId: this.text.testPrintTitle,
            items: [
                {
                    quantity: 99,
                    name: this.text.testPrintTitle,
                    instructions: this.text.testPrintBody,
                },
            ],
            pickupLocation: this.text.testPrintTitle,
        };
        this.testPrintSucceeded = null;
        this.crPrinterService
            .printOrder(this.kitchenid, job)
            .then(() => {
                this.testPrintSucceeded = true;
            })
            .catch((err) => {
                this.crErrorLoggingService.logError('Could not send test print', err, this.kitchenid);
                this.testPrintSucceeded = false;
            });
    }

    updateConfigurationDisplay() {
        if (this.kitchen && this.configOptions && this.kitchen.features.configurationIdMap) {
            const kitchenOpts = this.kitchen.features.configurationIdMap;
            // Add selected items to profile list
            this.profiles = [];
            this.configOptions.forEach((category) => {
                category.options.forEach((option) => {
                    if (kitchenOpts[option.optionId]) {
                        this.profiles.push({ categoryName: category.categoryName, optionName: option.optionName });
                    }
                });
            });
        }
    }

    onLoaded() {
        // This is set in kitchen-manage.controller when a new kitchen is created
        if (this.$state.params.created && !this.createMenuModalDisplayed) {
            this.createMenuModalDisplayed = true;
            this.openCreateMenuRedirectModal(this.kitchen);
        }
    }

    openCreateMenuRedirectModal(kitchen) {
        const message = `<h1>${kitchen.name} ${this.text.locationCreatedTitle}</h1>
                         <p>${this.text.locationCreated}</p>`;
        this.$uibModal
            .open({
                backdrop: 'static',
                component: 'crSimpleModal',
                windowClass: 'cr-modal-size-md',
                resolve: {
                    closeText: () => this.text.cancel,
                    submitText: () => this.text.createMenu,
                    type: () => this.crConstants.modalTypes.SUBMIT,
                    message: () => message,
                },
            })
            .result.then(() => {
                this.crNavigationService.goToFromCreate(menuRoutes.CREATE_MENU, { kitchenId: kitchen.id });
            })
            .catch(() => {
                this.crNavigationService.replaceParams({ kitchenId: kitchen.id, created: false });
                this.createMenuModalDisplayed = false;
            });
    }

    // Localization support

    isDefaultLocale() {
        return this.locale.current === this.locale.default;
    }

    getSafeKitchenId() {
        // The GSR treats dots in a special way. The mnemonic also does this replacement in content service
        return this.kitchen.id.replace(/\./g, '_');
    }

    updateLocaleStatus() {
        this.locale.list = this.crLocaleService.updateLocaleStatus(this.kitchen.localization, this.locale);
        const contentKeys = ['content.priceDisclaimer', ...this.webContent.map((key) => `webContent.${key}`)];
        this.locale.list.forEach((loc) => {
            const hasLocalizedContent = contentKeys.map((key) =>
                _.get(this.kitchen.localization, [loc.id, ...key.split('.')])
            );
            const isFullyLocalized = hasLocalizedContent.reduce((a, b) => a && b, true);
            loc.hasStatusWarning = !isFullyLocalized;
        });
    }

    getLocalization() {
        const entityType = this.crConstants.entity.types.PLACES;
        this.crLocalizationService
            .getEntityLocalization(entityType, { id: this.getSafeKitchenId(), venueId: this.$state.params.venueId })
            .then((res) => {
                this.kitchen.localization = res;
                this.updateLocaleStatus();
            });
    }

    initLocaleDropdown() {
        this.showLocaleDropdown =
            this.crConfig.customer.localizationEnabled && this.locale.list && this.locale.list.length > 1;
    }

    onLocaleSelect(event) {
        this.locale.current = event.model[0].id;
        // Trigger other dropdowns to refresh
        this.locale.list = _.clone(this.locale.list);
    }
}
