import {Component, Inject, ViewEncapsulation} from "@angular/core";

import text from './resources/locale/en.json';
import {Constants, LocalizedText} from "../../../../core";
import {PolicyService, UserService, MenuService, ErrorLoggingService, CR_CONSTANTS} from "../../../../shared";
import {UsersWithRolesAndVenues} from "../../../../shared/services/user/user.model";
import {FileSaveService} from "../../../../shared/services/file-save/file-save.service";
import {MenuReport} from "../../../../shared/services/menus/menu-report.model";
import {MenuProduct, ModifierGroup, ProductCategory} from "../../../../shared/services/menus/menu.model";
import {PlacesService} from "../../../../shared/services/places/places.service";
import {Place} from "../../../../shared/models/places.model";
import {UIRouterGlobals} from "@uirouter/core";

@Component({
     selector: 'report-select',
     templateUrl: './report-select.component.html',
     styleUrls: ['./report-select.component.scss'],
     encapsulation: ViewEncapsulation.None,
})
export class ReportSelectComponent {
     text: LocalizedText;
     loaderFor: string;
     isLoading: boolean;
     hasError: boolean;

     constructor(
       private userService: UserService,
       private fileSaveService: FileSaveService,
       private crPolicyService: PolicyService,
       private menuService: MenuService,
       private placesService: PlacesService,
       private routerGlobal: UIRouterGlobals,
       private errorLoggingService: ErrorLoggingService,
       @Inject(CR_CONSTANTS) private constants: Constants,
     ) {
          this.text = text as LocalizedText;
          this.loaderFor = '';
          this.isLoading = false;
          this.hasError = false;
     }

     async generateUserAuditReport(): Promise<any> {
          this.loaderFor = 'USER_REPORT';
          this.isLoading = true;
         const data = [];

          await this.userService.getAllUsersWithRolesAndVenues().then((users: UsersWithRolesAndVenues[]) => {
              users.forEach(user => {
                  data.push({
                      ['Username']: user.username,
                      ['Email']: user.email,
                      ['Display Name']: user.displayName,
                      ['Venue']: user.venueNames.join("||"),
                      ['Role']: user.roleNames.join("||"),
                  });
              });
              this.fileSaveService.exportArrayOfObjectsAsCsv('users-audit-report', data);

              this.loaderFor = '';
              this.isLoading = false;
          }).catch((error: any) => {
              this.loaderFor = '';
              this.isLoading = false;
              this.hasError = true;
              this.errorLoggingService.logError('Error getting all users.', error);
          });

          return data;
     }

     async generateMenuAuditReport(): Promise<any> {
         this.loaderFor = 'MENU_REPORT';
         this.isLoading = true;
         const data = [];

         await this.menuService.getMenusInAllVenues().then((menuReportRes: MenuReport[]) => {
             menuReportRes.forEach(menuReport => {
                 if (menuReport.products) {
                     menuReport.products.forEach(product => {
                         this.appendMenuData(menuReport, product).forEach(o => data.push(o));
                     })
                 }
             });

             this.fileSaveService.exportArrayOfObjectsAsCsv('menu-audit-report', data);
             this.loaderFor = '';
             this.isLoading = false;
         }).catch((error: any) => {
             this.loaderFor = '';
             this.isLoading = false;
             this.hasError = true;
             this.errorLoggingService.logError('Error getting menus.', error);
         });

         return data;
     }

     async generatePlaceReport(): Promise<any> {
         this.loaderFor = 'PLACE_REPORT';
         this.isLoading = true;
         const data = [];

         await this.placesService.getAllPlacesInAllVenues().then((placesRes: Place[]) => {
             const pickUpZoneMaxSize = this.getMaxArraySize(this.getAllArrayOfObjectsInPlace(placesRes,
                 (arr, o) => {
                     if (o.features && o.features.pickupZones) {
                         arr.push(o.features.pickupZones);
                     }
                 }));
             const orderingHoursMaxSize = this.getMaxArraySize(this.getAllArrayOfObjectsInPlace(placesRes,
                 (arr, o) => {
                     if (o.hours) {
                         arr.push(o.hours);
                     }
                 }));
             const feesMaxSize = this.getMaxArraySize(this.getAllArrayOfObjectsInPlace(placesRes,
                 (arr, o) => {
                     if (o.features && o.features.fees) {
                         arr.push(o.features.fees);
                     }
                 }));
             const printerMaxSize = this.getMaxArraySize(this.getAllArrayOfObjectsInPlace(placesRes,
                 (arr, o) => {
                     if (o.printers && o.printers.length !== 0) {
                         arr.push(o.printers);
                     }
                 }));
             const allCommerceConfig = this.getAllArrayOfObjectsInPlace(placesRes,
                 (arr, o) => {
                     if (o.commerceConfiguration && o.commerceConfiguration.length) {
                         arr.push(o.commerceConfiguration);
                     }
                 });
             const commerceConfigMaxSize = this.getMaxArraySize(allCommerceConfig);
             const commerceConfigHeaders = new Set<string>();
             allCommerceConfig.flatMap(o => o).forEach(o => commerceConfigHeaders.add(o.categoryName));

             placesRes.forEach(place => {
                 if (!place.id.match(/venue:.+/g)) {
                     data.push(this.appendPlaceData(place, pickUpZoneMaxSize, orderingHoursMaxSize, feesMaxSize,
                         printerMaxSize, commerceConfigMaxSize, Array.from(commerceConfigHeaders)));
                 }
             });

             this.fileSaveService.exportArrayOfObjectsAsCsv('place-audit-report', data);
             this.loaderFor = '';
             this.isLoading = false;
         }).catch((error: any) => {
             this.loaderFor = '';
             this.isLoading = false;
             this.hasError = true;
             this.errorLoggingService.logError('Error getting all places.', error);
         });

         return data;
     }

     private findCommaAndReplace = (str: string, rep = '') => str.replace(/,/g, rep);

     private appendMenuData = (menuReport: MenuReport, product: MenuProduct): any[] => {
         const commons = {
             ['Venue Name']: this.findCommaAndReplace(menuReport.venueName),
             ['POI Label']: this.findCommaAndReplace(menuReport.poiLabel),
             ['Menu Name']: this.findCommaAndReplace(menuReport.menuName),
             ['Category']: this.joinCategories(product.categories),
             ['Product Name']: this.findCommaAndReplace(product.displayName),
             ['Product State']: product.state,
             ['Product Price']: product.price,
             ['Product Currency']: product.currency,
             ['Product Max Quantity']: product.maxQuantity,
             ['External Price Code']: this.parseExternalPriceCode(product.externalPriceCode),
             ['Tax Code']: product.tax ? product.tax.id : '',
             ['Tax Label']: product.tax ? this.findCommaAndReplace(product.tax.label, ' ') : '',
             ['Eligible for Discount']: this.isItemDiscountable(product.attributes),
             ['Discount Type']: this.parseDiscountTypes(product.amendments),
             ['Modifier Group Display Name']: '',
             ['Modifier Group Category']: '',
             ['Modifier Group Min']: '',
             ['Modifier Group Max']: '',
             ['Modifier Display Name']: '',
             ['Modifier Price']: '',
             ['Modifier Currency']: '',
             ['Modifier Min']: '',
             ['Modifier Max']: ''
         };

         const data = [];

         if (product.modifierGroups && product.modifierGroups.length !== 0) {
             product.modifierGroups.forEach((group, index) => {
                 if (index === 0 && this.hasOptionalModifiers(group)) {
                     data.push(commons);
                 }

                 if (group.modifiers && group.modifiers.length !== 0) {
                     group.modifiers.forEach(modifier => {
                         data.push({
                             ...commons,
                             ['Modifier Group Display Name']: this.findCommaAndReplace(group.displayName),
                             ['Modifier Group Category']: this.findCommaAndReplace(group.category),
                             ['Modifier Group Min']: group.min,
                             ['Modifier Group Max']: group.max,
                             ['Modifier Display Name']: this.findCommaAndReplace(modifier.displayName),
                             ['Modifier Price']: modifier.price,
                             ['Modifier Currency']: modifier.currency,
                             ['Modifier Min']: modifier.min,
                             ['Modifier Max']: modifier.max
                         });
                     })
                 } else {
                     data.push(commons);
                 }
             })
         } else {
             data.push(commons);
         }

         return data;
     }

     private appendPlaceData = (place: Place, pickUpZoneMaxSize: number, allOrderingHoursMaxSize: number,
                                feeMaxSize: number, printerMaxSize: number, commerceConfigMaxSize: number,
                                commerceConfigHeaders: string[]): any => {
         let data = {
             ['Name']: place.name,
             ['Place ID']: place.id,
             ['Organization']: place.organization.id,
             ['Commerce Enabled']: place.features.commerceEnabled
         };

         let commerceConfigData = {};
         if (place.commerceConfiguration) {
             place.commerceConfiguration.forEach(o => {
                 commerceConfigData = {
                     ...commerceConfigData,
                     [o.categoryName]: o.selectedOption.optionName
                 }
             })

             const columnsHasNoData = commerceConfigHeaders
                 .filter(header => !Object.keys(commerceConfigData).includes(header));

             commerceConfigData = {
                 ...commerceConfigData,
                 ...this.addEmptyColumn(columnsHasNoData,
                     place.commerceConfiguration.length, commerceConfigMaxSize)
             }
         } else {
             commerceConfigData = {
                 ...commerceConfigData,
                 ...this.addEmptyColumn(commerceConfigHeaders,0, commerceConfigMaxSize)
             }
         }


         let pickupZoneData = {};
         const pickupZoneTitle = 'Drop Off Zone %counter%: Title';
         const pickupZoneFormLabel = 'Drop Off Zone %counter%: Form Label';
         const pickupZoneRegex = 'Drop Off Zone %counter%: Regex Character Rule';
         if (place.features.pickupZones) {
             place.features.pickupZones.forEach((o, index) => {
                 const counter = index + 1;
                 const regex = o.rule ? o.rule.regex : '';
                 pickupZoneData = {
                     ...pickupZoneData,
                     [pickupZoneTitle.replace('%counter%', String(counter))]: o.label,
                     [pickupZoneFormLabel.replace('%counter%', String(counter))]: this.returnEmptyIfUndefined(o.entryPrompt),
                     [pickupZoneRegex.replace('%counter%', String(counter))]: regex
                 };
             });
             pickupZoneData = {
                 ...pickupZoneData,
                 ...this.addEmptyColumn([pickupZoneTitle, pickupZoneFormLabel, pickupZoneRegex],
                     place.features.pickupZones.length, pickUpZoneMaxSize)
             }
         } else {
             pickupZoneData = {
                 ...pickupZoneData,
                 ...this.addEmptyColumn([pickupZoneTitle, pickupZoneFormLabel, pickupZoneRegex],
                     0, pickUpZoneMaxSize)
             }
         }

         let orderingHoursData = {};
         const orderingHoursDays = 'Ordering Hours %counter%: Days';
         const orderingHoursOpen = 'Ordering Hours %counter%: Open';
         const orderingHoursClose = 'Ordering Hours %counter%: Close';
         if (place.hours) {
             place.hours.forEach((o, index) => {
                 const counter = index + 1;
                 orderingHoursData = {
                     ...orderingHoursData,
                     [orderingHoursDays.replace('%counter%', String(counter))]: o.days.join('|'),
                     [orderingHoursOpen.replace('%counter%', String(counter))]: o.openingTime,
                     [orderingHoursClose.replace('%counter%', String(counter))]: o.closingTime
                 };
             });
             orderingHoursData = {
                 ...orderingHoursData,
                 ...this.addEmptyColumn([orderingHoursDays, orderingHoursOpen, orderingHoursClose],
                     place.hours.length, allOrderingHoursMaxSize)
             }
         } else {
             orderingHoursData = {
                 ...orderingHoursData,
                 ...this.addEmptyColumn([orderingHoursDays, orderingHoursOpen, orderingHoursClose],
                     0, allOrderingHoursMaxSize)
             }
         }

         let feesData = {};
         const feeName = 'Fee %counter%: Name';
         const feeType = 'Fee %counter%: Type';
         const feeCalculation = 'Fee %counter%: Calculation';
         const feeStatus = 'Fee %counter%: Status';
         const feeTaxable = 'Fee %counter%: Taxable';
         if (place.features.fees) {
             place.features.fees.forEach((o, index) => {
                 const counter = index + 1;
                 if (o) {
                     feesData = {
                         ...feesData,
                         [feeName.replace('%counter%', String(counter))]: this.findCommaAndReplace(o.displayName, '|'),
                         [feeType.replace('%counter%', String(counter))]: o.type,
                         [feeCalculation.replace('%counter%', String(counter))]: o.amountOrPercent,
                         [feeStatus.replace('%counter%', String(counter))]: o.publishingStatus,
                         [feeTaxable.replace('%counter%', String(counter))]: o.taxable
                     }
                 } else {
                     feesData = {
                         ...feesData,
                         ...this.addEmptyColumn([feeName, feeType, feeCalculation, feeStatus, feeTaxable],
                             0, 1)
                     }
                 }
             });
             feesData = {
                 ...feesData,
                 ...this.addEmptyColumn([feeName, feeType, feeCalculation, feeStatus, feeTaxable],
                     place.features.fees.length, feeMaxSize)
             }
         } else {
             feesData = {
                 ...feesData,
                 ...this.addEmptyColumn([feeName, feeType, feeCalculation, feeStatus, feeTaxable],
                     0, feeMaxSize)
             }
         }

         let printersData = {};
         const printersUrl = 'Printer %counter%: URL';
         const printersAutoPrint = 'Printer %counter%: Auto Print';
         const printersName = 'Printer %counter%: Name';
         const printersUsername = 'Printer %counter%: Username';
         if (place.printers) {
             place.printers.forEach((o, index) => {
                 const counter = index + 1;
                 printersData = {
                     ...printersData,
                     [printersUrl.replace('%counter%', String(counter))]: this.printerUrl(this.getVenueId(place.id)),
                     [printersAutoPrint.replace('%counter%', String(counter))]: Boolean(o.printOnCheckin).toString(),
                     [printersName.replace('%counter%', String(counter))]: o.name,
                     [printersUsername.replace('%counter%', String(counter))]: o.username
                 }
             });
             printersData = {
                 ...printersData,
                 ...this.addEmptyColumn([printersUrl, printersAutoPrint, printersName, printersUsername],
                     place.printers.length, printerMaxSize)
             }
         } else {
             printersData = {
                 ...printersData,
                 ...this.addEmptyColumn([printersUrl, printersAutoPrint, printersName, printersUsername],
                     0, printerMaxSize)
             }
         }

         data = {
             ...data,
             ...commerceConfigData,
             ...pickupZoneData,
             ...{
                 ['Allow Order Out of Venue']: !place.features.overrideInVenue,
                 ['Special Requests Enabled']: place.features.specialRequestsEnabled,
                 ['Enable Pickup Time Window']: place.features.pickupTimeWindowsEnabled
             },
             ...orderingHoursData,
             ...feesData,
             ...printersData,
             ...{
                 ['Price Disclaimer']: this.findCommaAndReplace(this.returnEmptyIfUndefined(place.content?.priceDisclaimer),' '),
                 ['Customer service number']: this.findCommaAndReplace(this.returnEmptyIfUndefined(place.content?.phoneNumber),' '),
                 ['Billing information message']: this.findCommaAndReplace(this.returnEmptyIfUndefined(place.webContent?.preorderBillingMessage),' '),
                 ['Tax and/or discount copy']: this.findCommaAndReplace(this.returnEmptyIfUndefined(place.webContent?.preorderTaxAndDiscount),' '),
                 ['Order details confirmation message']: this.findCommaAndReplace(this.returnEmptyIfUndefined(place.webContent?.preorderConfirmationMessage),' '),
                 ['Order details pickup location']: this.findCommaAndReplace(this.returnEmptyIfUndefined(place.webContent?.preorderConfirmationPickupLocation),' '),
                 ['Order details pickup instructions']: this.findCommaAndReplace(this.returnEmptyIfUndefined(place.webContent?.preorderConfirmationPickupInstructions),''),
                 ['Pickup instructions']: this.findCommaAndReplace(this.returnEmptyIfUndefined(place.webContent?.preorderPickupMessage),''),
                 ['Order details check-in message']: this.findCommaAndReplace(this.returnEmptyIfUndefined(place.webContent?.checkinMessage),''),
                 ['Order details check-in window']: this.findCommaAndReplace(this.returnEmptyIfUndefined(place.webContent?.preCheckinMessage),''),
                 ['Order details check-in button label']: this.findCommaAndReplace(this.returnEmptyIfUndefined(place.webContent?.checkInButton),''),
                 ['Order details checked-in message']: this.findCommaAndReplace(this.returnEmptyIfUndefined(place.webContent?.checkedInMessage),''),
                 ['Order details missed check-in window description']: this.findCommaAndReplace(this.returnEmptyIfUndefined(place.webContent?.postCheckinMessage),''),
                 ['Order details missed check-in window button label']: this.findCommaAndReplace(this.returnEmptyIfUndefined(place.webContent?.postCheckinMessageButton),''),
                 ['Order details check-in error prompt title']: this.findCommaAndReplace(this.returnEmptyIfUndefined(place.webContent?.checkinErrorTitle),''),
                 ['Order details check-in error prompt description']: this.findCommaAndReplace(this.returnEmptyIfUndefined(place.webContent?.checkinErrorBody),''),
                 ['Street address line 1']: this.findCommaAndReplace(this.returnEmptyIfUndefined(place.content?.addressComponents?.street),' '),
                 ['Street address line 2']: this.findCommaAndReplace(this.returnEmptyIfUndefined(place.content?.addressComponents?.street2),' '),
                 ['City']: this.findCommaAndReplace(this.returnEmptyIfUndefined(place.content?.addressComponents?.city), ''),
                 ['State']: this.returnEmptyIfUndefined(place.content?.addressComponents?.state),
                 ['Zip Code']: this.returnEmptyIfUndefined(place.content?.addressComponents?.zipCode),
                 ['Country Code']: this.returnEmptyIfUndefined(place.content?.addressComponents?.countryCode),
                 ['Contact card content']: this.returnEmptyIfUndefined(place.controls?.promptForPhoneNumber),
                 ['Send an SMS Text Message when an order is ready']: Boolean(place.controls?.blockOrderNotifications),
                 ['Require customer phone number collection']: this.returnEmptyIfUndefined(place.controls?.phoneNumberRequired),
                 ['Contact Card Title']: this.findCommaAndReplace(this.returnEmptyIfUndefined(place.webContent?.phoneNumberCollectionTitle),''),
                 ['Contact Card Details']: this.findCommaAndReplace(this.returnEmptyIfUndefined(place.webContent?.phoneNumberCollectionContent),''),
                 ['Contact Card Tooltip']: this.findCommaAndReplace(this.returnEmptyIfUndefined(place.webContent?.phoneNumberCollectionTooltip),''),
                 ['Contact Card Opt-in Title']: this.findCommaAndReplace(this.returnEmptyIfUndefined(place.webContent?.phoneNumberCollectionOptInTitleOne),''),
                 ['Time Zone']: this.returnEmptyIfUndefined(place.timeZone)
             }
         }

         return data;
     };

     private joinCategories = (productCategories: ProductCategory[]): string => {
         if (!productCategories && productCategories.length === 0) {
             return '';
         }
         const categories = [];
         productCategories.forEach(cat => {
             categories.push(this.findCommaAndReplace(cat.displayName));
         })
         return categories.join('|');
     }

     private isItemDiscountable = (attributes: string[]): string => {
         if (attributes && attributes.length !== 0 && attributes.find(o => o === 'notDiscountable')) {
             return Boolean(false).toString();
         }
         return Boolean(true).toString();
     }

     private parseExternalPriceCode = (externalPriceCode: string) => {
         // to filter out null or undefined value
         if (!externalPriceCode) {
             return this.constants.externalPriceCode.food;
         }

         if ([this.constants.externalPriceCode.alcohol,
             this.constants.externalPriceCode.beer,
             this.constants.externalPriceCode.wine].find(o => o === externalPriceCode.toLocaleUpperCase())) {
             return externalPriceCode.toLocaleUpperCase();
         } else {
             return this.constants.externalPriceCode.food;
         }
     }

     private parseDiscountTypes = (amendments: any[]): string => {
         const discountTypes: string[] = [];
         amendments.forEach(o => discountTypes.push(o.key));
         return discountTypes.join('|');
     }

     private hasOptionalModifiers = (group: ModifierGroup): boolean => group.min === 0;

     private getAllArrayOfObjectsInPlace = (placesRes: Place[], callback: (arg0: any[], arg1: Place) => void): any[] => {
         const arr = [];
         placesRes.forEach(o => {
             callback(arr, o);
         })
         return arr;
     }

     private printerUrl = (venueId: string): string => {
         const apiGateway = 'https://api.us.te2.io';
         return `${apiGateway}/customer/${this.routerGlobal.params.customerId}/venue/${venueId}/print-jobs`.toLowerCase();
     }

     private getVenueId = (placeId: string) => placeId.replace(/(poi:)|(venue:)|(\..+)/g, '');

     private getMaxArraySize = (arr: any[]): number => {
         let max = 0;
         arr.forEach(o => {
             if (max < o.length) {
                 max = o.length
             }
         })
         return max;
     }

     private addEmptyColumn = (header: string[], start: number, size: number): any  => {
         let emptyColumns = {};
         for(let i = start; i < size; i++) {
             let emptyColumn = {};
             header.forEach((o) => {
                 const counter = i + 1;
                 const headerVal = o.replace('%counter%' , String(counter));
                 emptyColumn = {
                   ...emptyColumn,
                     [headerVal]: ''
                 };
             });

             emptyColumns = {
                 ...emptyColumns,
                 ...emptyColumn
             };
         }

         return emptyColumns;
     }

     private returnEmptyIfUndefined = (str: string): string => {
         if (str) {
             return str;
         }
         return '';
     }
}
