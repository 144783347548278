import _ from 'lodash';
import ReorderList from '../../../../commons/controller/reorder-list';
import { menuRoutes } from '../../menus.routes';
import text from './resources/locale/en.json';

function attributeCategoryChipLabels(available, current) {
    const availableCategories = available.map((cat) => ({
        label: cat.hierarchyLabel,
        id: cat.id,
        parentId: cat.parentId,
    }));

    const availableCategoriesMap = _.keyBy(availableCategories, 'id');
    const categories = (current || []).map((cat) => availableCategoriesMap[cat.id]);

    return { availableCategories, categories };
}

function preparePayload(entity) {
    const payload = _.cloneDeep(entity);

    payload.categories = payload.categories.map((cat) => cat.id);
    payload.modifierGroups = payload.modifierGroups.map((mg) => mg.id);

    return payload;
}

function getLocaleTitle() {
    /* const localeTitle = entity.localization[currentLocale].title;
    return localeTitle ? localeTitle : entity.title; */
    // TODO:
}

function getLocaleDescription() {
    /* const localeDesc = entity.localization[currentLocale].description;
    return localeDesc ? localeDesc : entity.description; */
    // TODO:
}

function getPhoneModel(entity, locale) {
    const phoneData = _.cloneDeep(entity);
    const isDefault = locale.current === locale.default;

    if (!isDefault) {
        phoneData.title = getLocaleTitle(phoneData, locale.current);
        phoneData.description = getLocaleDescription(phoneData, locale.current);
    }

    return phoneData;
}

class ProductsManage extends ReorderList {
    constructor(
        $state,
        $uibModal,
        $sce,
        crConfig,
        crConstants,
        crEntityService,
        crLocaleService,
        crNavigationService,
        crErrorLoggingService,
        crAnalyticsService,
        crCommerceService
    ) {
        super();

        this.$state = $state;
        this.$uibModal = $uibModal;
        this.$sce = $sce;
        this.crConfig = crConfig;
        this.crConstants = crConstants;
        this.crEntityService = crEntityService;
        this.crLocaleService = crLocaleService;
        this.crNavigationService = crNavigationService;
        this.crErrorLoggingService = crErrorLoggingService;
        this.crAnalyticsService = crAnalyticsService;
        this.crCommerceService = crCommerceService;
    }

    $onInit() {
        this.text = text;
        console.log('this.text', this.text);
        this.entity = {
            content: {},
            modifierGroups: [],
            amendments: [],
        };
        this.form = {};
        this.venueId = this.$state.params.venueId;
        this.placeId = this.$state.params.placeId;
        this.entityType = this.crConstants.entity.types.MENU_PRODUCTS;
        this.entityRoute = `menus/${this.$state.params.menuId}/${this.entityType}`;
        this.titleBarLabel = this.edit ? this.text.editItem : this.text.createItem;
        this.crNavigationService.enableConfirmNavigation(() => this.form.$dirty);
        this.locale = this.crLocaleService.getLocale(this.venueId, this.$state.params.locale, !this.edit);
        this.currencyCode = _.find(this.crConfig.customer.venues, { id: this.$state.params.venueId }).currencyCode;
        this.entity.currency = this.currencyCode;

        this.maxQuantityInfoText = this.$sce.trustAsHtml(
            `<strong>${this.text.note}</strong>: ${this.text.maxQuantityInfo}`
        );

        if (this.$state.params.id) {
            this.getEntityDetails();
        } else {
            this.initCreate();
        }

        this.isAlreadyPreparedOptions = [
            {
                label: this.text.alreadyPrepared,
                name: 'isAlreadyPrepared',
                checked: false,
            },
        ];

        this.isAgeIdentificationOptions = [
            {
                label: this.text.governmentAgeVerificationCheckBox,
                name: 'hasAgeRestriction',
                checked: false,
            },
        ];

        this.isDiscountableOptions = [
            {
                label: this.text.isDiscountable,
                name: 'isDiscountable',
                checked: true,
            },
        ];

    }

    initCreate() {
        // Init localization property
        this.entity.localization = {};
        this.entity.productCode = null;
        _.forEach(this.locale.list, (lang) => {
            this.entity.localization[lang.id] = {
                content: {},
            };
        });
        this.initCategories(this.$state.params.menuId, this.$state.params.venueId, this.locale.current);
        this.initModifierGroups(this.$state.params.menuId, this.$state.params.venueId);
        this.initDiscountTypes();
        this.initTaxCodes();
    }

    getEntityDetails() {
        this.isLoading = true;

        const params = [
            this.entityType,
            this.$state.params.id,
            this.venueId,
            this.locale.default,
            this.entityRoute,
            { placeId: this.placeId },
        ];

        this.crEntityService
            .getEntity(...params)
            .then((entity) => {
                this.entity = entity;
                this.isCopy = !this.edit;

                if (this.isCopy) {
                    this.entity.content.name = `${this.text.copyOf} ${this.entity.content.name}`;

                    // remove copied entity's id
                    this.$state.params.id = null;
                    this.$state.go(this.$state.current.name, this.$state.params, {
                        location: 'replace',
                    });

                    this.entity.id = null;
                }

                this.entity.price = parseFloat(this.entity.price).toFixed(2);
                if (!this.entity.modifierGroups) {
                    this.entity.modifierGroups = [];
                }

                if (!this.entity.amendments) {
                    this.entity.amendments = [];
                }

                this.initCategories(this.$state.params.menuId, this.$state.params.venueId, this.locale.current);
                this.initModifierGroups(this.$state.params.menuId, this.$state.params.venueId);
                this.initDiscountTypes();
                this.initTaxCodes();

                this.isAlreadyPreparedOptions[0].checked = false;
                this.isDiscountableOptions[0].checked = true;
                if (!this.entity.attributes) {
                    this.entity.attributes = [];
                }
                if (this.entity.attributes.includes('noPrepTime')) {
                    this.isAlreadyPreparedOptions[0].checked = true;
                }
                if (this.entity.attributes.includes('notDiscountable')) {
                    this.isDiscountableOptions[0].checked = false;
                }
                this.isAgeIdentificationOptions[0].checked = !!this.entity.hasAgeRestriction;
            })
            .catch((err) => {
                this.hasDataError = true;
                this.crErrorLoggingService.logError(`Could not get ${this.entityType}`, err);
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    initCategories(menuId, venueId, locale) {
        this.isLoadingCategories = true;
        const type = this.crConstants.entity.types.MENU_CATEGORIES;
        this.crEntityService
            .getCategoriesWithHierarchy(type, venueId, locale, `menus/${menuId}/${type}`, { placeId: this.placeId })
            .then((displayCategories) => {
                this.categoriesList = displayCategories;

                const { availableCategories, categories } = attributeCategoryChipLabels(
                    this.categoriesList,
                    this.entity.categories
                );

                this.entity.categories = categories;
                this.entity.availableCategories = availableCategories;
            })
            .catch((err) => this.crErrorLoggingService.logError('Could not get categories hierarchy', err, locale))
            .finally(() => {
                this.isLoadingCategories = false;
            });
    }

    initModifierGroups(menuId, venueId) {
        const type = this.crConstants.entity.types.MENU_MODIFIER_GROUPS;
        const route = `menus/${menuId}/${type}`;
        const params = {
            search: '',
            sort: 'asc',
            sortby: 'name',
            venueId,
            placeId: this.placeId,
        };

        this.crEntityService
            .getEntityList(route, params)
            .then((response) => {
                this.modifierGroups = response.content || [];

                this.entity.modifierGroups.forEach((modifierGroup) => {
                    this.modifierGroups.some((mgOption) => {
                        if (mgOption.id === modifierGroup.id) {
                            mgOption.isDisabled = true;
                            return true;
                        }
                    });
                });
            })
            .catch((err) => this.crErrorLoggingService.logError('Could not get modifier groups for menu', err));
    }

    initDiscountTypes() {
        this.isLoadingDiscounts = true;
        this.crCommerceService
            .getDiscountTypes()
            .then((res) => {
                this.discountTypes = res;
                this.entity.amendments.forEach((amendment) => {
                    this.discountTypes.forEach((type) => {
                        if (type.id === amendment.key) {
                            type.isDisabled = true;
                        }
                    });
                });
            })
            .catch((err) => {
                this.crErrorLoggingService.logError('Could not get discount types for customer', err);
            })
            .finally(() => {
                this.isLoadingDiscounts = false;
            });
    }

    initTaxCodes() {
        // If there are tax codes, use those, plus the fullyTaxable option
        this.crCommerceService.getTaxCodes(this.placeId).then((results) => {
            // Default, just one option that is automatically selected
            this.taxCodeOptions = [{ id: null, label: this.text.fullyTaxable, isSelected: !this.entity.productCode }];

            if (results && results.length) {
                const newOptions = results.map((option) => ({
                    ...option,
                    isSelected: option.id === this.entity.productCode,
                }));
                this.taxCodeOptions.push(...newOptions);
            }

            // If the tax code is unknown by the place, tax service treats it as fully taxable, and so should we
            if (this.taxCodeOptions.filter((code) => code.isSelected).length === 0) {
                this.taxCodeOptions[0].isSelected = true;
            }
        });
    }

    save() {
        this.form.$setSubmitted();

        if (this.form.$valid) {
            if (this.form.$dirty || this.isCopy) {
                this.isSaving = true;
                const payload = preparePayload(this.entity);

                if (this.edit) {
                    this.updateEntity(this.entityType, this.entity.id, payload, this.venueId, this.entityRoute);
                } else {
                    this.createEntity(this.entityType, payload, this.venueId, this.entityRoute);
                }
            } else {
                this.goBack();
            }
        }
    }

    updateEntity(type, id, entity, venueId, entityRoute) {
        return this.crEntityService
            .updateEntity(type, id, entity, venueId, entityRoute, { placeId: this.placeId })
            .then((res) => this.onSaveSuccess(res))
            .catch((err) => this.handleError('Could not update entity', err))
            .finally(() => {
                this.isSaving = false;
            });
    }

    createEntity(type, entity, venueId, entityRoute) {
        return this.crEntityService
            .createEntity(type, entity, venueId, entityRoute, { placeId: this.placeId })
            .then((res) => this.onSaveSuccess(res))
            .catch((err) => this.handleError('Could not create entity', err))
            .finally(() => {
                this.isSaving = false;
            });
    }

    updatePhonePreview() {
        this.phoneData = _.cloneDeep(getPhoneModel(this.entity, this.locale));
    }

    getDiscountName(key) {
        return this.discountTypes.find((type) => type.id === key).displayName;
    }

    onLocaleChange(event) {
        this.locale.current = event.locale.id;
        this.locale = _.cloneDeep(this.locale);

        this.updatePhonePreview();
    }

    onHeaderUpdate(event) {
        this.entity.images = event.model.images;
        this.entity.content.name = event.model.name;
        this.entity.state = event.model.state;

        this.entity = _.cloneDeep(this.entity);
        this.updatePhonePreview();
    }

    onTitleChange(event) {
        if (this.locale.current === this.locale.default) {
            // Update main model to stay in sync
            this.entity.displayName = event.model;
        }
        this.entity.localization[this.locale.current].displayName = event.model;
        this.entity = _.cloneDeep(this.entity);
        this.updatePhonePreview();
    }

    onDescriptionChange(event) {
        if (this.locale.current === this.locale.default) {
            // Update main model to stay in sync
            this.entity.content.description = event.model;
        }

        this.entity.localization[this.locale.current].content.description = event.model;
        this.entity = _.cloneDeep(this.entity);
        this.updatePhonePreview();
    }

    onImagesChange(event) {
        this.entity.images = event.model;
        this.entity = _.cloneDeep(this.entity);
        this.updatePhonePreview();
    }

    categorySelected(tags) {
        this.entity.categories = tags;
    }

    onPriceChange(event) {
        this.entity.price = event.model;
    }

    onTaxCodeUpdate(event) {
        this.entity.productCode = event.model[0].id;
        this.dirtyForm();
    }

    onMaxQuantityChange(event) {
        this.entity.maxQuantity = event.model;
    }

    onExternalIdChanged(event) {
        this.entity.externalId = event.model;
    }

    onExternalPriceCodeChanged(event) {
        this.entity.externalPriceCode = event.model;
    }

    onAlreadyPreparedChanged(event) {
        const alreadyPrepared = event.model.isAlreadyPrepared;
        this.updateEntityAttribute('noPrepTime', alreadyPrepared);
    }

    onAgeIdentificationChanged(event) {
        const hasAgeRestriction = event.model.hasAgeRestriction;
        this.entity.hasAgeRestriction = hasAgeRestriction;
        if(!hasAgeRestriction) {
            this.entity.ageRestrictionLabel = null;
        }
        if(hasAgeRestriction && !this.entity.ageRestrictionLabel) {
            this.entity.ageRestrictionLabel = this.text.defaultAgeVerificationMessage;
        }
        this.dirtyForm();
    }
    
    onAgeRestrictionLabelChanged(event) {
        const model = event.target ? event.target.value : event.model;
        this.entity.ageRestrictionLabel = model;
        this.dirtyForm();
    }

    onIsDiscountableChanged(event) {
        const notDiscountable = !event.model.isDiscountable;
        this.updateEntityAttribute('notDiscountable', notDiscountable);
    }

    updateEntityAttribute(key, value) {
        // Prime the attributes if they don't exist
        if (!this.entity.attributes) {
            this.entity.attributes = [];
        }
        const nDiscIndex = this.entity.attributes.indexOf(key);
        const attributeExists = nDiscIndex !== -1;
        if (value && !attributeExists) {
            // Add the attribute
            this.entity.attributes.push(key);
        } else if (!value && attributeExists) {
            // Remove the attribute
            this.entity.attributes.splice(nDiscIndex, 1);
        }
        this.dirtyForm();
    }

    onDiscountTypeExternalIdChange(event, index) {
        _.set(this.entity, `amendments.${index}.fields.externalId`, event.model);
    }

    onDiscountTypePricingCodeChange(event, index) {
        _.set(this.entity, `amendments.${index}.fields.externalPriceCode`, event.model);
    }

    onSaveSuccess(data) {
        this.crNavigationService.disableConfirmNavigation();
        const params = this.getToastParams(data);

        if (this.edit) {
            this.crAnalyticsService.track('Edit Product Success', data);
            this.crNavigationService.goBack(menuRoutes.PRODUCT_DETAILS, params);
        } else {
            this.crAnalyticsService.track('Add New Product Success', data);
            this.crNavigationService.goToFromCreate(menuRoutes.PRODUCTS, params);
        }
    }

    getToastParams(data, menuId = this.$state.params.menuId, placeId = this.$state.params.placeId) {
        return {
            id: data.id,
            menuId,
            placeId,
            toast: {
                msg: `"${data.content.name}" ${this.edit ? this.text.hasBeenUpdated : this.text.hasBeenCreated}`,
            },
        };
    }

    handleError(errMsg, err) {
        this.showErrorModal();
        this.crErrorLoggingService.logError(errMsg, err);
    }

    showErrorModal() {
        const message = this.edit ? this.text.updateError : this.text.createError;

        this.$uibModal
            .open({
                backdrop: 'static',
                component: 'crSimpleModal',
                windowClass: 'cr-modal-size-sm',
                resolve: {
                    message: () => message,
                },
            })
            .result.catch(() => null);
    }

    dirtyForm() {
        this.form.$setDirty();
    }

    onModifierGroupSelect(event) {
        const mg = event.model[0];
        this.entity.modifierGroups.push(mg);
        mg.isDisabled = true;
    }

    onDiscountTypeSelect(event) {
        const discount = event.model[0];
        this.entity.amendments.push({
            key: discount.id,
        });
        discount.isDisabled = true;
    }

    removeDiscountType(index) {
        const discount = this.entity.amendments.splice(index, 1)[0];
        this.discountTypes.forEach((type) => {
            if (discount.key === type.id) {
                type.isDisabled = false;
            }
        });
        this.dirtyForm();
    }

    modifierOrderChange(data, index) {
        this.moveRow(data, index, this.entity.modifierGroups);
        this.dirtyForm();
    }

    modifierOrderChangeTop(data, index) {
        this.moveRowToTop(data, index, this.entity.modifierGroups);
        this.dirtyForm();
    }

    getBehaviorDisplayText(group) {
        return group.min === 0 ? this.text.optional : this.text.required;
    }

    removeModifierGroup(index) {
        const mg = this.entity.modifierGroups.splice(index, 1)[0];
        this.modifierGroups.some((mgOption) => {
            if (mg.id === mgOption.id) {
                mgOption.isDisabled = false;
                return true;
            }
        });
        this.dirtyForm();
    }

    goBack() {
        if (this.edit) {
            this.crAnalyticsService.track('Edit Product Canceled');
            this.crNavigationService.goBack(menuRoutes.PRODUCT_DETAILS, {
                id: this.entity.id,
                menuId: this.$state.params.menuId,
                placeId: this.$state.params.placeId,
            });
        } else {
            this.crAnalyticsService.track('Add New Product Canceled');
            this.crNavigationService.goBack(menuRoutes.PRODUCTS, {
                menuId: this.$state.params.menuId,
                placeId: this.$state.params.placeId,
                tabId: 'menu-items',
            });
        }
    }
}

export default ProductsManage;
