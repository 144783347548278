import template from './chip-selector.html';
import './chip-selector.less';
import ChipSelectorController from './chip-selector.controller';

const ChipSelectorComponent = {
    template,
    require: {
        form: '^form',
    },
    bindings: {
        name: '@',
        crRequired: '<?',

        label: '@?',
        sort: '@?',
        maxlength: '@?',
        options: '<',
        currentTags: '<',
        onSelect: '&?',
        addButton: '<',
        canAdd: '<',
        disableRemove: '<',
        emptyOptionsText: '@?',
        isGrouped: '<?',
        isLoading: '<',
        placeholder: '@?',
    },
    controller: ['$filter', '$element', 'crConstants', ChipSelectorController],
};

export default ChipSelectorComponent;
