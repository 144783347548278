import _ from 'lodash';
import {
  Component, Input
} from '@angular/core';
import { LocalizedText } from '../../../core/index.js';
import text from './resources/locale/en.json';
@Component({
  selector: 'cr-discount-content-phone-preview',
  templateUrl: './discount-content-phone-preview.component.html',
  styleUrls: ['./discount-content-phone-preview.component.scss'],
})
export class DiscountContentPhonePreviewComponent{
  @Input() featuredEvents = { discountTitle: "",
        discountDescription: ""};

  text: LocalizedText;

  constructor() {
    this.text = text;
  }

}
