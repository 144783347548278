import text from './resources/locale/en.json';

class PoiTypeListController {
    constructor($state, $uibModal, poiTypeListService, navigationService, errorLoggingService) {
        this.$state = $state;
        this.$uibModal = $uibModal;
        this.poiTypeListService = poiTypeListService;
        this.navigationService = navigationService;
        this.errorLoggingService = errorLoggingService;

        this.text = text;
    }

    $onInit() {
        this.types = [];

        this.getData();
    }

    getData() {
        this.isLoading = true;
        this.hasDataError = false;

        this.poiTypeListService
            .getTypes(this.$state.params.venueId)
            .then((types) => {
                this.types = types;

                return this.poiTypeListService.getPois(this.$state.params.venueId).then((pois) => {
                    this.pois = pois;
                    this.matchPoiAndTypes();
                });
            })
            .catch((err) => {
                this.hasDataError = true;
                this.errorLoggingService.logError('Could not get poi types', err, {
                    venueId: this.$state.params.venueId,
                });
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    matchPoiAndTypes() {
        this.types.forEach((type) => {
            type.pois = this.pois.filter((poi) => poi.type && poi.type.id === type.id);
        });
    }

    goBack() {
        this.navigationService.goBack('client.poi.list');
    }

    gotoAssociatedPoisList(type) {
        this.$state.go('client.poi.type-inventory', { typeId: type.id, typeName: type.name });
    }

    openCreateModal() {
        this.$uibModal
            .open({
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                backdrop: 'static',
                component: 'crPoiTypeListModal',
                resolve: {},
            })
            .result.then(() => this.getData())
            .catch(() => null);
    }

    openEditModal(type) {
        this.$uibModal
            .open({
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                backdrop: 'static',
                component: 'crPoiTypeListModal',
                resolve: {
                    type,
                },
            })
            .result.then(() => this.getData())
            .catch(() => null);
    }
}

export default PoiTypeListController;
