import {
  Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import _ from 'lodash';
import { Subscription } from 'rxjs';
import { Constants, LocalizedText } from '../../../core';
import { ImageService } from '../../../ng1-factories';
import { ImageUploaderChangeEvent } from '../../content/image-uploader/image-uploader.component';
import { Image } from '../../models';
import { CR_CONSTANTS } from '../../services/constants/constants';
import text from './resources/locale/en.json';
import { CrDropdownUpgradedComponentItem } from '../dropdown/dropdown-upgraded.component';

export interface EntityHeaderComponentModel {
  name: string;
  images?: Image[];
  state: string;
}

export interface EntityHeaderComponentUpdateEvent {
  $event: {
    model: EntityHeaderComponentModel
  };
}

@Component({
  selector: 'cr-entity-header',
  templateUrl: './entity-header.component.html',
  styleUrls: ['./entity-header.component.scss'],
})
export class EntityHeaderComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  entity: EntityHeaderComponentModel;

  @Input()
  manage: boolean;

  @Input()
  customClass?: string;

  @Output()
  onUpdate: EventEmitter<EntityHeaderComponentUpdateEvent> = new EventEmitter<EntityHeaderComponentUpdateEvent>();

  text: LocalizedText;

  states: { id: string, label: string, isSelected?: boolean, isDisabled?: boolean }[];

  selectedClass: string;

  model: EntityHeaderComponentModel;

  form: FormGroup;

  private onStateChangeSubscription: Subscription;

  private onNameChangeSubscription: Subscription;

  constructor(
    @Inject(CR_CONSTANTS) public constants: Constants,
    private imageService: ImageService,
    private formBuilder: FormBuilder,
  ) {
    this.text = text;
    this.model = this.entity || {
      name: '',
      state: '',
    };
    this.initForm();
  }

  ngOnInit(): void {
    this.initState();
  }

  ngOnDestroy(): void {
    if (this.onStateChangeSubscription) {
      this.onStateChangeSubscription.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.entity && changes.entity.currentValue) {
      this.entity = _.cloneDeep(changes.entity.currentValue) as EntityHeaderComponentModel;
      this.model = {
        name: this.entity.name,
        images: this.entity.images,
        state: this.entity.state,
      };
      this.refreshEntityState();
      if (this.form) {
        this.form.patchValue({
          name: changes.entity.currentValue.name,
          state: changes.entity.currentValue.state,
          images: changes.entity.currentValue.images || [],
        });
      }
    }
  }

  updateHeader(model: EntityHeaderComponentModel): void {
    this.onUpdate.emit({
      $event: { model },
    });
  }

  onNameUpdate(name: string): void {
    this.model.name = name;
    this.updateHeader(this.model);
  }

  onImageUpdate({ $event }: ImageUploaderChangeEvent): void {
    this.model.images = $event.images;
    this.updateHeader(this.model);
  }

  onStateUpdate(item: CrDropdownUpgradedComponentItem): void {
    this.selectedClass = item.id;
    this.model.state = item.id;
    this.updateHeader(this.model);
  }

  showAsCircle(): boolean {
    return this.imageService.showAsCircle();
  }

  private initForm(): void {
    this.form = this.formBuilder.group({
      name: ['', [(ctrl) => Validators.required(ctrl)]],
      state: ['', [(ctrl) => Validators.required(ctrl)]],
      images: [[]],
    });
    this.states = [
      { id: 'ACTIVE', label: this.text.active as string },
      { id: 'INACTIVE', label: this.text.inactive as string },
      { id: 'DRAFT', label: this.text.draft as string },
    ];

    this.onNameChangeSubscription = this.form.get('name').valueChanges.subscribe((value) => this.onNameUpdate(value));
    this.onStateChangeSubscription = this.form.get('state').valueChanges.subscribe((value) => this.onStateUpdate(value));
  }

  private initState(): void {
    this.selectedClass = this.entity.state;
    if (this.selectedClass && this.selectedClass !== 'DRAFT') {
      this.states[2].isDisabled = true;
    }

    this.refreshEntityState();
  }

  private refreshEntityState(): void {
    if (this.states) {
      // change the object so change detection fires
      this.states = this.states.map((s) => {
        const state = s;
        state.isSelected = state.id === this.entity.state;
        return state;
      });
    }
  }
}
