import { InvalidPropertyError } from '../../../error/error';

class SimpleTableController {
    $onInit() {
        if (typeof this.data !== 'object') {
            throw new InvalidPropertyError('data');
        }
    }
}

export default SimpleTableController;
