class PlaceDetailsService {
    constructor(crCommerceService, crPlacesService) {
        this.crCommerceService = crCommerceService;
        this.crPlacesService = crPlacesService;
    }

    getDetails(placeId) {
        return this.crPlacesService.getPlaceWithICalDays(placeId);
    }

    getOrdersReports(placeId, timeframe, interval) {
        return this.crCommerceService
            .ordersReports([placeId], timeframe, null, interval, 5)
            .then((data) => this.getPlaceReportModel(data));
    }

    getPlaceReportModel(data) {
        const commerceData = {
            ordersPlaced: data.ordersPlaced,
            topItemsPurchased: data.topItemsPurchased,
            totalOrders: 0,
            ordersValue: 0,
        };

        commerceData.ordersPlaced.series[0].forEach((dataSet) => {
            commerceData.totalOrders += dataSet.value;
            commerceData.ordersValue += dataSet.meta;
        });

        return commerceData;
    }
}

export default PlaceDetailsService;
