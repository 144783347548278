import { NgModule } from '@angular/core';
import { SharedModule } from "../../shared";
import { LiftsTrailsActivitiesComponent } from "./lifts-trails-activities.component";
import { CommonModule } from "@angular/common";
import { TabsModule, TooltipModule } from "ngx-bootstrap";
import { CrSearchModule } from "../../shared/forms/search/search.module";
import { CrTableModule } from "../../shared/table/table.module";
import { CrDropdownModule } from "../../commons/basic/components/dropdown/dropdown.module";
import { ListComponent } from "./components/list/list.component";
import { ToggleModule } from "../../commons/form/components/toggle/toggle.module";
import { EditDetailsComponent } from './components/edit-details/edit-details.component';
import { ViewDetailsComponent } from './components/view-details/view-details.component';
import { FormsModule } from "@angular/forms";
import { AddComponent } from "./components/add/add.component";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        CrDropdownModule,
        CrSearchModule,
        CrTableModule,
        SharedModule,
        TabsModule.forRoot(),
        ToggleModule,
        TooltipModule,
    ],
    declarations: [
        LiftsTrailsActivitiesComponent,
        ListComponent,
        EditDetailsComponent,
        ViewDetailsComponent,
        AddComponent
    ],
    providers: [],
    exports: []
})
export class LiftsTrailsActivitiesModule { }
