import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { StateService } from "@uirouter/core";
import { sanitize } from 'dompurify';
import { Subscription } from "rxjs";
import { LocalizedText } from '../../../../core';
import { NavigationService } from "../../../../shared";
import { PlacesService } from "../../../../shared/services/places/places.service";
import { menuRoutes } from "../../menus.routes";
import text from './resources/en.json';

import { Place } from "../../../../shared/models/places.model";
@Component({
    selector: "cr-id-detail-content-manage",
    templateUrl: "./id-detail-content-manage.component.html",
    styleUrls: ["./id-detail-content-manage.component.scss"]
})
export class IdDetailContentManageComponent implements OnInit, OnDestroy {
    pageTitle: string;
    
    text: LocalizedText;

    edit = true;

    isSubmitted = false;

    venueData: Place

    isLoading = false;

    form = new FormGroup({});

    idDetailContentHeaderControl: FormControl;

    idDetailContentDescriptionControl: FormControl;

    sanitize: (html: string) => string;

    isSaving = false;

    phonePreview: {
        idDetailTitle: string,
        idDetailDescription: string
    }

    formSubs: Subscription[] = [];

    quillConfig = {
        toolbar: {
          container: [
            ["bold"]
          ]
        },
      };

    quillStyles = {height: '347px'};

    constructor(public state: StateService, private crPlacesService: PlacesService, 
         private navigationService: NavigationService) {}
    
    ngOnInit() {
        this.text = text;
        this.sanitize = sanitize;
        this.pageTitle = this.state.params.edit ? this.text.pageTitleEdit : this.text.pageTitleCreate;
        this.isLoading = true;
        this.initForm();
        this.initVenueData();
    }

    initForm(): void {
        this.idDetailContentHeaderControl = new FormControl( '', [Validators.required]);
        this.idDetailContentDescriptionControl = new FormControl( '', [Validators.required]);
        this.form.addControl('idDetailContentHeaderControl', this.idDetailContentHeaderControl);
        this.form.addControl('idDetailContentDescriptionControl', this.idDetailContentDescriptionControl);
        const idDetailHeader$ = this.idDetailContentHeaderControl.valueChanges.subscribe((value) => {
            this.updatePhonePreview();
          });
      
        const idDetailDescrition$ = this.idDetailContentDescriptionControl.valueChanges.subscribe((value) => {
        this.updatePhonePreview();
        });
      
        this.formSubs.push(idDetailHeader$);
        this.formSubs.push(idDetailDescrition$);
    }

    initFormState(): void {
        if(this.venueData.webContent && this.venueData.webContent.idDetailTitle){
            this.edit = false
        }
    }

    async initVenueData() {
        this.isLoading = true;
        try {
            const res: Place = await this.crPlacesService.getPlace(`venue:${this.state.params.venueId}`);
            this.venueData = res;
            this.fillFormEditFields();
            this.initFormState();
        } catch (err) {
            console.log(err);
        } finally {
            this.isLoading = false;
        }
    }

    fillFormEditFields(): void {
        if(this.venueData.webContent && this.venueData.webContent.idDetailTitle) {
            this.idDetailContentHeaderControl.setValue(this.venueData.webContent.idDetailTitle);
        }
        if(this.venueData.webContent && this.venueData.webContent.idDetailDescription) {
            this.idDetailContentDescriptionControl.setValue(this.venueData.webContent.idDetailDescription);
        }
    }

    ngOnDestroy() {
        this.formSubs.forEach((sub) => {
            sub.unsubscribe();
          });
    }


    onCancel(): void {
        this.goBack();
    }

    onSave(): void{
        if(this.form.valid){
            this.isSaving = true;
            this.venueData.webContent = { ...this.venueData.webContent,
                idDetailTitle: this.idDetailContentHeaderControl.value,
                idDetailDescription: this.sanitize(this.idDetailContentDescriptionControl.value)
            };
            if(this.venueData.transientPlace){
                this.crPlacesService
                .createPlace(this.venueData)
                .then((data) => {
                    this.isSaving = false;
                    this.goBack();
                }).catch((err) => {
                    this.isSaving = false;
                    console.log(err);
                }
                );
            } else {
                this.crPlacesService
                .updatePlace(this.venueData)
                .then((data) => {
                    this.isSaving = false;
                    this.goBack();
                }).catch((err) => {
                    this.isSaving = false;
                    console.log(err);
                }
                );
            }
        }
    }

    goBack(): void {
        this.navigationService.goBack(menuRoutes.DASHBOARD, {
          tabId: 'id-detail-content'
        });
      }

      updatePhonePreview(): void {
        this.phonePreview = {
            idDetailTitle: this.idDetailContentHeaderControl.value,
            idDetailDescription: this.idDetailContentDescriptionControl.value
        }
      }

}