import angular from 'angular';

import ComponentsModule from './components/components';
import DirectivesModule from './directives/directives';
import ValidatorsModule from './validators/validators';

const FormModule = angular.module('control-room.commons.form', [
    ComponentsModule.name,
    DirectivesModule.name,
    ValidatorsModule.name,
]);

export default FormModule;
