import angular from 'angular';
import text from './resources/locale/en.json';

import Component from './fnb-archive-modal.component';

const FnbArchiveModalModule = angular
    .module('control-room.app.menus.fnb-archive-modal', [])
    .constant('crFnbArchiveModalText', text)
    .component('crFnbArchiveModal', Component);

export default FnbArchiveModalModule;
