<div class="modal-body cr-modal-contents">
    {{ message }}
</div>
<div class="modal-footer">
    <button
        class="btn btn-nobg"
        *ngIf="confirm"
        type="button"
        data-local-qa-id="cancel"
        (click)="cancel()"
    >
        {{ cancelText }}
    </button>
    <button
        class="btn btn-primary"
        type="button"
        data-local-qa-id="submit"
        (click)="submit()"
    >
        {{ getSubmitText() }}
    </button>
</div>
