import controller from './when.controller';
import template from './when.html';

import './when.less';

const component = {
    template,
    bindings: {
        experience: '<',
        experienceType: '<',
        group: '<',
        edit: '<',
        form: '<',
    },
    controller: ['$state', 'crConfig', 'crConstants', 'crScheduleService', controller],
};

export default component;
