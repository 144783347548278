/* eslint no-bitwise: 0 */
import _ from 'lodash';
import ReorderList from '../../../../commons/controller/reorder-list';
import text from './resources/locale/en.json';

function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = (Math.random() * 16) | 0;
        const v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}

class QuestionManageController extends ReorderList {
    constructor(
        $state,
        $uibModal,
        $q,
        $timeout,
        $sce,
        crConfig,
        crConstants,
        crQuestionService,
        crLocaleService,
        crLocalizationService,
        crNavigationService,
        crErrorLoggingService,
        crAnalyticsService,
        crToastService
    ) {
        super();

        this.$state = $state;
        this.$uibModal = $uibModal;
        this.$q = $q;
        this.$timeout = $timeout;
        this.crConfig = crConfig;
        this.crConstants = crConstants;
        this.crQuestionService = crQuestionService;
        this.crLocaleService = crLocaleService;
        this.crLocalizationService = crLocalizationService;
        this.crNavigationService = crNavigationService;
        this.crErrorLoggingService = crErrorLoggingService;
        this.crAnalyticsService = crAnalyticsService;
        this.crToastService = crToastService;
        this.text = text;

        this.levelInfo = $sce.trustAsHtml(this.text.levelInfo);
        this.selectBehaviorInfo = $sce.trustAsHtml(this.text.selectBehaviorInfo);
        this.settingsInfo = $sce.trustAsHtml(this.text.settingsInfo);
    }

    $onInit() {
        this.titleBarLabel = this.edit ? this.text.editQuestion : this.text.createQuestion;
        this.type = this.crConstants.entity.types.TAG_DEFINITIONS;
        this.deletedLocalizations = {};

        this.crToastService.autoToast(this.$state.params);

        this.setLevel(this.$state.params.level);
        this.key = this.$state.params.key;

        this.crNavigationService.enableConfirmNavigation(() => this.form.$dirty);

        this.levelOptions = [
            {
                label: this.text.levelOptions.customer,
                value: 'CUSTOMER',
            },
            {
                label: this.text.levelOptions.venue,
                value: 'VENUE',
            },
        ];

        this.selectBehaviorOptions = [
            {
                label: this.text.selectBehaviorOptions.single,
                value: 'SELECT',
            },
            {
                label: this.text.selectBehaviorOptions.multi,
                value: 'MULTISELECT',
            },
        ];

        this.splitActions = [
            {
                label: this.text.delete,
                action: () => {
                    this.deleteQuestion();
                },
            },
        ];

        this.valueLocalizations = {};

        this.defaultExpToggle = [
            {
                label: this.text.enableExpiration,
                name: 'default-expiration',
                checked: false,
            },
        ];
        this.defaultExpiration = {
            units: 'days',
        };
        this.defaultExpirationUnitOptions = [
            {
                label: this.text.minutes,
                id: 'minutes',
            },
            {
                label: this.text.hours,
                id: 'hours',
            },
            {
                label: this.text.days,
                id: 'days',
            },
        ];
        this.defaultExpirationValidators = [
            {
                type: 'MIN_VALUE',
                value: 1,
            },
        ];
        this.defaultExpirationErrorMessages = {
            minValue: this.text.expirationMinValueError,
        };

        if (this.edit) {
            this.loadQuestion();
        } else {
            this.initQuestion();
        }
    }

    setLevel(level) {
        this.level = level;
        this.venueId = level === 'VENUE' ? this.$state.params.venueId : null;
        this.locale = this.crLocaleService.getLocale(this.venueId, this.$state.params.locale, !this.edit);
        this.showDropdown =
            this.crConfig.customer.localizationEnabled && this.locale.list && this.locale.list.length > 1;

        if (this.question && this.question.localization) {
            this.refreshLocaleStatus();
        }
    }

    loadQuestion() {
        this.isLoading = true;
        this.crQuestionService
            .getQuestion(this.key, this.venueId, this.locale)
            .then((data) => {
                this.question = data;

                this.levelSetting = this.level === 'VENUE' ? this.text.isVenueLevel : this.text.isCustomerLevel;
                this.behaviorSetting =
                    this.question.type === 'SELECT'
                        ? this.text.selectBehaviorOptions.single
                        : this.text.selectBehaviorOptions.multi;
                this.syncValueOrder();
                this.refreshLocaleStatus();
                const numValues = this.question.values.length;
                this.addValue(2 - numValues);
                this.refreshDefaultExpirationSelector();

                this.isLoading = false;
            })
            .catch((err) => {
                this.hasDataError = true;
                this.crErrorLoggingService.logError('Could not get question', err);
                this.isLoading = false;
            });
    }

    syncValueOrder() {
        const orderingMap = {};
        this.question.values.forEach((value, index) => {
            orderingMap[value.tagValueKey] = index;

            this.locale.list.forEach((locale) => {
                if (!this.question.localization[locale.id]) {
                    this.question.localization[locale.id] = {};
                }
                const localization = this.question.localization[locale.id];
                if (!localization.values) {
                    localization.values = [];
                }
                const valueLocalization = localization.values.find((vl) => vl.tagValueKey === value.tagValueKey);

                if (!valueLocalization) {
                    localization.values.push({ tagValueKey: value.tagValueKey });
                }
            });
        });

        this.locale.list.forEach((locale) => {
            if (!this.question.localization[locale.id]) {
                this.question.localization[locale.id] = {};
            }
            const localization = this.question.localization[locale.id];
            if (!localization.values) {
                localization.values = [];
            } else {
                localization.values.sort((a, b) => {
                    if (_.isUndefined(orderingMap[a.tagValueKey])) {
                        return 1;
                    }
                    if (_.isUndefined(orderingMap[b.tagValueKey])) {
                        return -1;
                    }
                    return orderingMap[a.tagValueKey] - orderingMap[b.tagValueKey];
                });

                const deleted = localization.values.splice(this.question.values.length).map((value) => ({
                    tagValueKey: value.tagValueKey,
                    label: '',
                }));
                if (!this.deletedLocalizations[locale.id]) {
                    this.deletedLocalizations[locale.id] = [];
                }
                this.deletedLocalizations[locale.id].push(...deleted);
            }
        });
    }

    initQuestion() {
        this.question = {
            tagKey: uuidv4(),
            definitionType: 'QUESTION',
            values: [],
            localization: {},
        };
        this.syncValueOrder();
        this.addValue(2);
        this.refreshDefaultExpirationSelector();
    }

    get isDefaultLocale() {
        return this.locale.current === this.locale.default;
    }

    onLevelChange(value) {
        this.question.userTagLevel = value;
        this.setLevel(value);
        this.question.venueId = this.venueId;
    }

    onSelectBehaviorChange(value) {
        this.question.type = value;
    }

    onLabelChange(event) {
        if (this.isDefaultLocale) {
            this.question.label = event.model;
        }

        if (!this.question.localization[this.locale.current]) {
            this.question.localization[this.locale.current] = {};
        }
        this.question.localization[this.locale.current].label = event.model;

        this.refreshLocaleStatus();
    }

    getLabelPlaceholder() {
        return this.isDefaultLocale
            ? this.text.labelPlaceholder
            : this.question.localization[this.locale.default].label;
    }

    getTitlePlaceholder() {
        return this.isDefaultLocale
            ? this.text.titlePlaceholder
            : this.question.localization[this.locale.default].title;
    }

    onTitleChange(event) {
        if (this.isDefaultLocale) {
            this.question.title = event.model;
        }
        if (!this.question.localization[this.locale.current]) {
            this.question.localization[this.locale.current] = {};
        }
        this.question.localization[this.locale.current].title = event.model;

        this.refreshLocaleStatus();
    }

    refreshDefaultExpirationSelector() {
        const s = this.question.defaultDurationOfValue;
        this.defaultExpToggle[0].checked = !!s;
        if (s) {
            if (s % 86400 === 0) {
                this.defaultExpiration.units = 'days';
                this.defaultExpiration.value = s / 86400;
            } else if (s % 3600 === 0) {
                this.defaultExpiration.units = 'hours';
                this.defaultExpiration.value = s / 3600;
            } else {
                this.defaultExpiration.units = 'minutes';
                this.defaultExpiration.value = Math.floor(s / 60);
            }
        }
        this.defaultExpirationUnitOptions.forEach((option) => {
            option.isSelected = option.id === this.defaultExpiration.units;
        });
    }

    onDefaultExpToggleUpdate(event) {
        const on = event.model['default-expiration'];
        this.defaultExpToggle[0].checked = on;
        if (on) {
            this.updateDefaultExpiration();
        } else {
            this.question.defaultDurationOfValue = null;
        }

        this.$timeout(() => {
            this.dirtyForm();
        });
    }

    onDefaultExpirationValueUpdate(event) {
        this.defaultExpiration.value = event.model;
        this.updateDefaultExpiration();
    }

    onDefaultExpirationUnitUpdate(event) {
        this.defaultExpiration.units = event.model[0].id;
        this.updateDefaultExpiration();
    }

    updateDefaultExpiration() {
        let unitMultiplier = 1;
        switch (this.defaultExpiration.units) {
            case 'days': {
                unitMultiplier = 86400;
                break;
            }
            case 'hours': {
                unitMultiplier = 3600;
                break;
            }
            case 'minutes': {
                unitMultiplier = 60;
                break;
            }
        }
        this.question.defaultDurationOfValue = unitMultiplier * this.defaultExpiration.value;
    }

    goBack() {
        if (this.edit) {
            this.crAnalyticsService.track('Edit Question Canceled');
            this.crNavigationService.goBack('client.app-settings.question-list');
        } else {
            this.crAnalyticsService.track('Add New Question Canceled');
            this.crNavigationService.goBack('client.app-settings.question-list');
        }
    }

    onValueAdd() {
        this.dirtyForm();
        this.addValue();
        this.refreshLocaleStatus();
    }

    onValueRemove(index) {
        this.dirtyForm();
        this.question.values.splice(index, 1);
        this.locale.list.forEach((locale) => {
            const localization = this.question.localization[locale.id];
            const deleted = localization.values.splice(index, 1);
            if (deleted[0]) {
                this.deletedLocalizations[locale.id].push({
                    tagValueKey: deleted[0].tagValueKey,
                    label: '',
                });
            }
        });
        this.refreshLocaleStatus();
    }

    onValueLabelUpdate(event, index) {
        if (this.isDefaultLocale) {
            this.question.values[index].label = event.model;
        }

        this.question.localization[this.locale.current].values[index].label = event.model;

        this.refreshLocaleStatus();
    }

    getValueLabelPlaceholder(index) {
        if (this.isDefaultLocale) {
            return this.text.valuePlaceholders[index];
        }

        const defaultLocalization = this.question.localization[this.locale.default];

        return defaultLocalization.values[index].label;
    }

    dirtyForm() {
        this.form.$setDirty();
    }

    valueOrderChange(data, index) {
        this.moveRow(data, index, this.question.values);
        this.syncValueOrder();
        this.dirtyForm();
    }

    valueOrderChangeTop(data, index) {
        this.moveRowToTop(data, index, this.question.values);
        this.syncValueOrder();
        this.dirtyForm();
    }

    get valuesValid() {
        for (let i = 0; i < this.question.values.length; i++) {
            const control = this.form[`question-value-${i}`];

            if (!control) {
                continue;
            }

            if (!control.$valid) {
                return false;
            }
        }

        return true;
    }

    addValue(count = 1) {
        for (let i = 0; i < count; i++) {
            if (this.question.values.length >= 10) {
                break;
            }

            const tagValueKey = uuidv4();

            this.question.values.push({ tagValueKey });
            this.locale.list.forEach((locale) => {
                this.question.localization[locale.id].values.push({ tagValueKey });
            });
        }
    }

    save(addAnother) {
        this.form.$setSubmitted();

        if (this.form.$valid) {
            if (this.form.$dirty || this.isCopy) {
                this.isSaving = true;

                if (this.edit) {
                    this.updateQuestion();
                } else {
                    this.createQuestion(addAnother);
                }
            } else {
                this.goBack();
            }
        }
    }

    preparePayload() {
        const payload = _.cloneDeep(this.question);

        this.locale.list.forEach((locale) => {
            const deleted = this.deletedLocalizations[locale.id];
            payload.localization[locale.id].values.push(...deleted);
        });

        return payload;
    }

    updateQuestion() {
        const payload = this.preparePayload();
        this.crQuestionService
            .updateQuestion(this.key, payload, this.venueId)
            .then((res) => this.onSaveSuccess(res))
            .catch((err) => this.handleError('Could not update question', err))
            .finally(() => {
                this.isSaving = false;
            });
    }

    createQuestion(addAnother) {
        const question = { venueId: this.venueId, ...this.question };
        this.crQuestionService
            .createQuestion(question, this.venueId)
            .then((res) => this.onSaveSuccess(res, addAnother))
            .catch((err) => this.handleError('Could not create question', err))
            .finally(() => {
                this.isSaving = false;
            });
    }

    onSaveSuccess(data, addAnother) {
        if (!data) {
            data = this.question;
        }

        this.crNavigationService.disableConfirmNavigation();
        const params = this.getToastParams(data);

        if (this.edit) {
            this.crAnalyticsService.track('Edit Question Success', data);
            // There is no detail page, so go back to the list view
            this.crNavigationService.goBack('client.app-settings.question-list', params);
        } else {
            this.crAnalyticsService.track('Add New Question Success', data);

            if (addAnother) {
                this.crNavigationService.goToFromCreate('client.app-settings.question-create', params, {
                    reload: true,
                });
            } else {
                this.crNavigationService.goToFromCreate('client.app-settings.question-list', params);
            }
        }
    }

    getToastParams(data) {
        return {
            toast: {
                msg: `"${data.label}" ${this.edit ? this.text.hasBeenUpdated : this.text.hasBeenCreated}`,
            },
        };
    }

    handleError(errMsg, err) {
        this.showErrorModal();
        this.crErrorLoggingService.logError(errMsg, err);
    }

    showErrorModal(deleteConflict) {
        let message;
        if (deleteConflict) {
            message = this.text.deleteConflict;
        } else {
            message = this.edit ? this.text.updateError : this.text.createError;
        }

        this.$uibModal
            .open({
                backdrop: 'static',
                component: 'crSimpleModal',
                windowClass: deleteConflict ? 'cr-modal-size-md' : 'cr-modal-size-sm',
                resolve: {
                    closeText: () => this.text.closeError,
                    message: () => message,
                },
            })
            .result.catch(() => null);
    }

    onLocaleUpdate(event) {
        this.locale.current = event.model[0].id;
        this.crAnalyticsService.track('Language Selected', { language: event.model[0].label });
    }

    isInvalidForm() {
        const { localization } = this.question;
        const hasLabel = !_.isEmpty(localization) && localization[this.locale.default].label;
        return localization && this.isDefaultLocale && (!hasLabel || !this.valuesValid);
    }

    refreshLocaleStatus() {
        this.locale.list.forEach((locale) => {
            let warning = false;
            const localization = this.question.localization[locale.id];

            if (!localization || !localization.label || !localization.title) {
                warning = true;
            }

            localization.values.forEach((value) => {
                if (!value.label) {
                    warning = true;
                }
            });

            locale.hasStatusWarning = warning;
        });
    }

    deleteQuestion() {
        this.$uibModal
            .open({
                backdrop: 'static',
                component: 'crSimpleModal',
                windowClass: 'cr-modal-size-sm',
                resolve: {
                    type: () => 'submit',
                    submitText: () => this.text.deleteQuestion,
                    message: () => this.text.deleteConfirmation,
                },
            })
            .result.then(() => {
                this.isSaving = true;
                return this.crQuestionService
                    .deleteQuestion(this.question.tagKey, this.venueId)
                    .then(() => {
                        this.crNavigationService.disableConfirmNavigation();
                        this.crAnalyticsService.track('Delete Question Success');
                        this.crNavigationService.goBack('client.app-settings.question-list', {
                            toast: {
                                msg: `"${this.question.label}" ${this.text.hasBeenDeleted}`,
                            },
                        });
                    })
                    .catch((err) => {
                        this.isSaving = false;
                        const deleteConflict = err.status === 400;
                        this.showErrorModal(deleteConflict);
                        this.crErrorLoggingService.logError('Could not delete question', err, this.question);
                    });
            })
            .catch(() => null);
    }
}

export default QuestionManageController;
