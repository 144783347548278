import angular from 'angular';

import PlaceDetailComponent from './place-details.component';
import PlaceDetailsLocale from './resources/locale/en.json';
import PlaceDetailsService from './place-details.service';

import ComponentsModule from './components/components';

const PlaceDetails = angular
    .module('control-room.places.place-details', [ComponentsModule.name])
    .constant('placeDetailsLocale', PlaceDetailsLocale)
    .service('placeDetailsService', ['crCommerceService', 'crPlacesService', PlaceDetailsService])
    .component('crPlaceDetails', PlaceDetailComponent);

export default PlaceDetails;
