import {
  Component, Input, OnInit, OnChanges, SimpleChanges,
} from '@angular/core';
import { FormControl, ControlContainer, NgForm } from '@angular/forms';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { LocalizedText, ValidationMessages } from '../../../core';

import text from './resources/locale/en.json';

@Component({
  selector: 'cr-form-error',
  templateUrl: './form-error.component.html',
  styleUrls: ['./form-error.component.scss'],
})
export class FormErrorComponent implements OnInit, OnChanges {
  text: LocalizedText;

  form: NgForm;

  control: FormControl;

  @Input()
  for: string | FormControl;

  @Input()
  messages: ValidationMessages = {};

  get showValidMessage(): boolean {
    return this._showValidMessage;
  }

  @Input()
  set showValidMessage(value: boolean) {
    this._showValidMessage = coerceBooleanProperty(value);
  }

  private _showValidMessage: boolean;

  constructor(private controlContainer: ControlContainer) {
    this.text = text;
  }

  ngOnInit() {
    this.form = this.controlContainer as NgForm;

    if (typeof this.for === 'string') {
      this.control = this.form.control.get(this.for) as FormControl;
    } else {
      this.control = this.for;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const messagesChange = changes.messages;
    if (messagesChange && !messagesChange.currentValue) {
      this.messages = {};
    }
  }

  get errorMessage(): string {
    const errors = this.control ? this.control.errors : null;

    if (!errors) {
      return null;
    }

    if (errors.required) {
      return this.getMessage(this.messages.required, errors.required, this.text.required);
    }

    if (errors.min) {
      return this.getMessage(this.messages.min, errors.min, this.text.min);
    }

    if (errors.max) {
      return this.getMessage(this.messages.max, errors.max, this.text.max);
    }

    if (errors.minlength) {
      const defaultMessage = `${this.text.minlength.before} ${errors.minlength.requiredLength} ${this.text.minlength.after}`;
      return this.getMessage(this.messages.minlength, errors.minlength, defaultMessage);
    }

    if (errors.maxlength) {
      const defaultMessage = `${this.text.maxlength.before} ${errors.maxlength.requiredLength} ${this.text.maxlength.after}`;
      return this.getMessage(this.messages.maxlength, errors.maxlength, defaultMessage);
    }

    if (errors.password) {
      return this.getMessage(this.messages.password, errors.minlength, this.text.password);
    }

    if (errors.match) {
      return this.getMessage(this.messages.match, errors.match, this.text.match);
    }

    if (errors.noMatch) {
      return this.getMessage(this.messages.noMatch, errors.noMatch, this.text.match);
    }

    for (const key in errors) {
      if (errors.hasOwnProperty(key)) {
        const message = this.getMessage(this.messages[key], errors[key], this.text[key]);
        if (message) {
          return message;
        }
      }
    }

    return null;
  }

  private getMessage(
    customMessage: string | ((errorData?: { [key: string]: any }) => string),
    error: { [key: string]: any },
    defaultMessage: string,
  ): string {
    if (customMessage) {
      return typeof customMessage === 'function' ? customMessage(error) : customMessage;
    }

    return defaultMessage;
  }
}
