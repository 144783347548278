/* eslint-disable max-classes-per-file */
import * as _ from 'lodash';
import { AppNavType } from './app-nav-type';
import { AppNavParams } from './app-nav-params';
import { AppNavData } from './app-nav-data';
import { APP_ORIGIN, baseData } from './base-data';

function getBaseData(type: AppNavType): AppNavData {
  if (!type) {
    return;
  }

  if (!baseData[type]) {
    return;
  }

  return {
    ...baseData[type],
    query: {
      ...baseData[type].query,
    },
  };
}

export class AppNav {
  static CouponDetails = class extends AppNav {
    constructor(venueId: string, couponId: string) {
      const query = { couponId };
      super(AppNavType.COUPON_DETAILS, venueId, query);
    }
  };

  static EventDetails = class extends AppNav {
    constructor(venueId: string, eventId: string) {
      const query = { eventId };
      super(AppNavType.EVENT_DETAILS, venueId, query);
    }
  };

  static DiningMenu = class extends AppNav {
    constructor(venueId: string, placeId: string) {
      const query = { placeId };
      super(AppNavType.DINING_MENU, venueId, query);
    }
  };

  static GuestPreferencesVenue = class extends AppNav {
    constructor(venueId: string) {
      super(AppNavType.GUEST_PREFS_VENUE, venueId);
    }
  };

  static GuestPreferencesBrand = class extends AppNav {
    constructor() {
      super(AppNavType.GUEST_PREFS_BRAND);
    }
  };

  static LoQueueProduct = class extends AppNav {
    constructor(venueId: string, productId: string) {
      const query = { productId };
      super(AppNavType.LOQUEUE_PRODUCT_DETAILS, venueId, query);
    }
  };

  static LoQueueRide = class extends AppNav {
    constructor(venueId: string, poiId: string, rideId: string) {
      const query = { poiId, rideId };
      super(AppNavType.LOQUEUE_RIDE_DETAILS, venueId, query);
    }
  };

  static Literal = class extends AppNav {
    constructor(literal: string) {
      super(AppNavType.LITERAL);
      this.raw = literal;
    }

    toString() {
      return this.raw;
    }
  };

  static PoiDetails = class extends AppNav {
    constructor(venueId: string, poiId: string) {
      const query = { poiId };
      super(AppNavType.POI_DETAILS, venueId, query);
    }
  };

  static ProductDetails = class extends AppNav {
    constructor(venueId: string, productId: string) {
      const query = { productId };
      super(AppNavType.PRODUCT_DETAILS, venueId, query);
    }
  };

  static Webview = class extends AppNav {
    constructor(venueId: string, url: string, title?: string) {
      if (url && url.startsWith(APP_ORIGIN)) {
        return new AppNav.Literal(url);
      }
      const query: AppNavParams = { url };
      if (title) {
        query.title = title;
      }
      super(AppNavType.WEBVIEW, venueId, query);
    }
  };

  raw: string;

  origin: string;

  context: string;

  contextId: string;

  category: string;

  feature: string;

  subFeature: string;

  query: AppNavParams;

  constructor(public type: AppNavType, venueId?: string, params?: AppNavParams) {
    const base = getBaseData(type);

    if (base) {
      this.origin = base.origin;
      this.context = base.context;
      this.contextId = base.contextId;
      this.category = base.category;
      this.feature = base.feature;
      this.subFeature = base.subFeature;
      this.query = base.query;
    }

    this.contextId = venueId || this.contextId;
    this.query = params || this.query;
  }

  static fromString(s: string): AppNav {
    const url = new URL(s);
    // eslint-disable-next-line prefer-const
    let [, context, contextId, category, feature, subfeature] = url.pathname.split('/');

    if (context === 'app') {
      [, context, category, feature, subfeature] = url.pathname.split('/');
    }

    const query = {};
    url.searchParams.forEach((value, key) => {
      query[key] = value;
    });

    let type = null;
    for (const typeKey in baseData) {
      if (baseData.hasOwnProperty(typeKey)) {
        const knownType = baseData[typeKey];

        if (
          !(RegExp(knownType.context).exec(context))
                    || (!contextId && knownType.contextId)
                    || (contextId && !(RegExp(knownType.contextId).exec(contextId)))
                    || !(RegExp(knownType.category).exec(category))
                    || (!feature && knownType.feature)
                    || (feature && !(RegExp(knownType.feature).exec(feature)))
                    || (!subfeature && knownType.subFeature)
                    || (subfeature && !(RegExp(knownType.subFeature).exec(subfeature)))
        ) {
          continue; // property mismatch - skip to next known type
        }

        let queryMatch = true;
        for (const param in knownType.query) {
          if (knownType.query.hasOwnProperty(param)) {
            const knownValue = knownType.query[param];
            const value = query[param];

            if (knownValue === '.*') {
              // match any, including empty/missing
              continue;
            }

            if (!value || !value.match(knownValue)) {
              queryMatch = false;
              break; // query mismatch - exit inner iteration early
            }
          }
        }

        if (queryMatch) {
          // found type - set and exit iteration early
          type = typeKey;
          break;
        }
      }
    }

    if (!type) {
      return new AppNav.Literal(s);
    }

    const appNav = new AppNav(type, contextId, query as AppNavParams);
    appNav.raw = s;
    appNav.context = context;
    appNav.category = category;
    appNav.feature = feature;
    appNav.subFeature = subfeature;

    return appNav;
  }

  toString(): string {
    let url = `${this.origin}/${this.context}`;

    if (this.contextId) {
      url += `/${this.contextId}`;
    }
    url += `/${this.category}`;

    if (!this.feature) {
      return this.withQuery(url);
    }
    url += `/${this.feature}`;
    if (!this.subFeature) {
      return this.withQuery(url);
    }
    url += `/${this.subFeature}`;
    return this.withQuery(url);
  }

  private withQuery(url: string): string {
    const query = {};
    _.forIn(this.query, (qParam, qKey) => {
      if (qParam !== null) {
        query[qKey] = qParam;
      }
    });
    const q = new URLSearchParams(query).toString();
    if (q.length > 0) {
      return `${url}?${q}`;
    }
    return url;
  }
}
