import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserCountResponseDto } from '../../models/order.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TagService {
  baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = '/rest/tag-definitions';
  }

  getTagDefinitionsByLevel(params: any): Promise<any> {
    return this.http.get(`${this.baseUrl}`, { params }).toPromise();
  }

  getTagDefinitionsForUsers(venueId: string, userIds: any): Promise<any> {
    return this.http.post(`${this.baseUrl}/users`, userIds, { params: { venueId } }).toPromise();
  }

  getTagDefinitionsCount(venueId: string, userIds: any, tags: any[]): Observable<UserCountResponseDto> {
      const tagCriteria = tags.map((tag) => ({
          tagKey: tag.key,
          tagOperator: tag.op,
          tagValues: tag.values
      }));
      return this.http
          .post<UserCountResponseDto>(`${this.baseUrl}/users/count`, { userIds, tagCriteria }, { params: { venueId } })
          

  }

  getUserCountEstimate(venueId: string, tags: any[]): Observable<UserCountResponseDto> {
      const tagCriteria = tags.map((tag) => ({
          tagKey: tag.key,
          tagOperator: tag.op,
          tagValues: tag.values
      }));
      return this.http
          .post<UserCountResponseDto>(`${this.baseUrl}/users/count/estimate`, { tagCriteria }, { params: { venueId } })
  }
}
