import angular from 'angular';
import FilterMenuComponent from './filter-menu.component';
import text from './resources/locale/en.json';

const FilterMenuModule = angular
    .module('control-room.commons.basic.components.filter-menu', ['ui.bootstrap.dropdown'])
    .constant('filterMenuText', text)
    .component('crFilterMenu', FilterMenuComponent);

export default FilterMenuModule;
