import controller from './experience-summary.controller';
import template from './experience-summary.html';

import './experience-summary.less';

const component = {
    template,
    bindings: {
        title: '@',
        experience: '<',
        experienceType: '<',
        tagDefinitions: '<',
        showLocaleDropdown: '<?',
        step: '<',
        completeSteps: '<',
        onStepChange: '&',
        poiDefinitions: '<',
        poiCategories: '<',
        menuItems: '<',
        menuCategories: '<',
        geofences: '<?',
        coupons: '<',
        onChanges: '&',
        group: '<?',
        readOnly: '<?',
    },
    controller: [
        '$state',
        'crLocaleService',
        'crAnalyticsService',
        'crScheduleService',
        'crConstants',
        'crExperienceManageService',
        'crConfig',
        controller,
    ],
};

export default component;
