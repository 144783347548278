import angular from 'angular';
// Using @uirouter/hybrid - Says to remove @uirouter/angularjs from package.json
// eslint-disable-next-line import/no-extraneous-dependencies
import UIRouter from '@uirouter/angularjs';

import ClientModule from './client/client';

import AppComponent from './app.component';
import AppRouting from './app.routing';
import CommonsModule from './commons';
import routerEvents from './router-events';
import { Ng1SharedModule } from './shared/shared.ng1';

const AppModule = angular
    .module('control-room', [UIRouter, 'ui.router.upgrade', ClientModule.name, CommonsModule.name, Ng1SharedModule.name])
    .component('app', AppComponent)
    .config(['$urlServiceProvider', ($urlService) => $urlService.deferIntercept()])
    .config(['$locationProvider', AppRouting])
    .run(['$transitions', '$state', 'crUserService', 'crPolicyService', routerEvents])
    .factory('$exceptionHandler', [
        '$injector',
        ($injector) => function catchUnhandledException(exception, cause) {
                $injector.get('crErrorLoggingService').logError('unhandled exception', exception, cause, true);
            },
    ]);

export default AppModule;
