import _ from 'lodash';
import text from './resources/locale/en.json';

class ManageCategoryController {
    constructor(
        $q,
        $state,
        $uibModal,
        crConstants,
        crConfig,
        crEntityService,
        crLocaleService,
        crImageService,
        navigationService,
        errorLoggingService,
        crAnalyticsService
    ) {
        this.$q = $q;
        this.$state = $state;
        this.$uibModal = $uibModal;
        this.crConstants = crConstants;
        this.crConfig = crConfig;
        this.crEntityService = crEntityService;
        this.crLocaleService = crLocaleService;
        this.crImageService = crImageService;
        this.navigationService = navigationService;
        this.errorLoggingService = errorLoggingService;
        this.crAnalyticsService = crAnalyticsService;
    }

    static setSelectedCategory(category, categories) {
        if (category.parentId) {
            categories.forEach((cat) => {
                cat.isSelected = category ? cat.id === category.parentId : false;
            });
        }
    }

    static createCategoryChildrenMap(categories) {
        const map = {};

        categories.forEach((category) => {
            map[category.parentId] = map[category.parentId] || [];
            map[category.parentId].push(category);
        });

        return map;
    }

    $onInit() {
        this.text = text;
        this.loading = true;
        this.venueId = this.$state.params.venueId;
        this.placeId = this.$state.params.placeId;
        this.allCategories = {};
        this.displayKey = this.key || 'label';
        this.category = {
            owner: {
                id: this.venueId,
                type: 'VENUE',
            },
            localization: {},
        };

        this.locale = this.crLocaleService.getLocale(this.venueId, this.$state.params.locale, !this.edit);

        // Set initial languages when creating
        if (!this.edit) {
            this.locale.list.forEach((lang) => {
                this.category.localization[lang.id] = {};
                this.category.localization[lang.id][this.displayKey] = '';
            });
        }

        this.imageConstants = this.crImageService.constants.image;
        this.parentRoute = this.$state.$current.parent.self.name;
        this.getData();

        this.navigationService.enableConfirmNavigation(() => this.form.$dirty);

        this.initDropdown();
    }

    getData() {
        if (this.edit) {
            if (this.iconRequired) {
                this.$q.all([this.getIcons(), this.getCategory()]).then((result) => {
                    this.setIcon(result[1].images, result[0]);
                });
            } else {
                this.getCategory();
            }
        } else {
            this.loading = false;
            this.placement = this.$state.params.topLevel
                ? this.crConstants.categoryPlacementLocations.TOP
                : this.crConstants.categoryPlacementLocations.WITHIN;
            const isTopLevel = this.isTopLevel();
            this.title = isTopLevel ? this.text.createCategory : this.text.createSubcategory;

            if (this.iconRequired) {
                this.getIcons();
            }

            if (!isTopLevel) {
                this.getCategories();
            }
            this.initCategorySelection();
            this.getLabelMaxCharValue();
        }
    }

    getCategories() {
        const { menuId } = this.$state.params;
        const entityRoute = menuId ? `menus/${menuId}/${this.entityType}` : this.entityType;

        this.loading = true;

        return this.crEntityService
            .getEntityList(entityRoute, { venueId: this.venueId, placeId: this.placeId }, this.locale.current)
            .then((data) => {
                this.allCategories[this.locale.current] = data.content;
                const categories = data.content;
                this.categories = this.filterCategories(categories);
                return this.categories;
            })
            .catch((err) => {
                this.errorLoggingService.logError('could not retrieve categories', err, {
                    entityType: this.entityType,
                });
            })
            .finally(() => {
                this.loading = false;
            });
    }

    onLocaleSelect(event) {
        this.locale.current = event.model[0].id;
        this.crAnalyticsService.track('Language Selected', { language: event.model[0].label });

        // Show dropdown spinner
        this.categories = null;

        // Get the categories in the new language if we don't have them yet
        const storedCategories = this.allCategories[this.locale.current];

        if (storedCategories) {
            this.initCategories(storedCategories);
        } else {
            this.getLocalizedCategories();
        }
    }

    onUpdateLabel(event) {
        if (this.isDefaultLocale()) {
            this.category[this.displayKey] = event.model;
        }

        this.category.localization[this.locale.current][this.displayKey] = event.model;

        this.updateLocaleStatus();
    }

    updateLocaleStatus() {
        this.locale.list = this.crLocaleService.updateLocaleStatus(this.category.localization, this.locale);
    }

    isDefaultLocale() {
        return this.locale.current === this.locale.default;
    }

    get isDistributedChitPrintingSupported() {
        return this.crConfig.customer.configFlags.enable_distributed_chit_printing && 
                this.crConfig.customer.configFlags.enable_distributed_chit_printing.includes(this.$state.params.venueId);
    }

    getToastParams(categoryData, deleteCategory) {
        let toastMsg;
        if (deleteCategory) {
            toastMsg = this.text.deleteSuccess;
        } else {
            toastMsg = this.edit ? this.text.updateSuccess : this.text.createSuccess;
        }
        return {
            menuId: this.$state.params.menuId,
            placeId: this.$state.params.placeId,
            toast: {
                msg: `"${this.origLabel || categoryData[this.displayKey]}" ${toastMsg}`,
            },
        };
    }

    gotoCategories(categoryData, deleteCategory) {
        this.navigationService.disableConfirmNavigation();
        const params = this.getToastParams(categoryData, deleteCategory);
        this.navigationService.goBack(`${this.parentRoute}.categories`, params);
    }

    goBack() {
        const actionText = this.edit ? 'Edit' : 'Create';
        const typeText = this.isTopLevel() ? 'Category' : 'Subcategory';
        this.crAnalyticsService.track(`${actionText} ${typeText} Canceled`);
        this.navigationService.goBack(`${this.parentRoute}.categories`);
    }

    hasSiblingWithSameName(parentKey, category) {
        return _.some(this.allCategories, (localizedCategories, key) => {
            const categoryChildrenMap = ManageCategoryController.createCategoryChildrenMap(localizedCategories);
            const parentCategoryContents = (categoryChildrenMap[parentKey] || []).filter(
                (cat) => cat[this.displayKey] && cat.id !== category.id
            );
            return _.find(
                parentCategoryContents,
                (cat) =>
                    category.localization[key][this.displayKey].toLowerCase() === cat[this.displayKey].toLowerCase()
            );
        });
    }

    save(preventNavigation) {
        const withinType = this.crConstants.categoryPlacementLocations.WITHIN;
        const parentKey = this.placement === withinType ? this.category.parentId : null;
        const isGoodPlacement = this.placement !== withinType || this.category.parentId;

        this.form.$setSubmitted();

        if (this.form.$valid) {
            if (this.placement && isGoodPlacement && this.form.$dirty) {
                if (this.hasSiblingWithSameName(parentKey, this.category)) {
                    this.openErrorModal(this.text.sameNameError).catch(() => null);
                } else {
                    this.loading = true;
                    const params = this.getSaveParams(
                        this.edit,
                        this.entityType,
                        this.category,
                        this.venueId,
                        this.placeId,
                        this.$state.params.menuId
                    );
                    const upsertPromise = this.edit
                        ? this.crEntityService.updateEntity(...params)
                        : this.crEntityService.createEntity(...params);

                    return upsertPromise
                        .then(() => {
                            const actionText = this.edit ? 'Edit' : 'Create';
                            const typeText = this.category.parentId ? 'Subcategory' : 'Category';
                            this.crAnalyticsService.track(`${actionText} ${typeText} Saved`, {
                                category: this.category,
                            });
                            if (!preventNavigation) {
                                this.gotoCategories(this.category);
                            }
                        })
                        .catch((err) => {
                            const action = this.edit ? this.text.update : this.text.create;
                            this.handleError(`Could not ${action} this Category.`, err);
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                }
            } else {
                this.goBack();
            }
        }
    }

    getSaveParams(isEdit, entityType, category, venueId, placeId, menuId) {
        // build up params, pass entityRoute if menuId is present
        const params = [entityType];

        if (isEdit) {
            params.push(category.id);
        }

        // this is special for commerce
        if (entityType === this.crConstants.entity.types.MENU_CATEGORIES) {
            category.state = this.crConstants.entity.states.ACTIVE;
        }

        params.push(category);
        params.push(venueId);

        if (menuId) {
            const entityRoute = `menus/${menuId}/${entityType}`;
            params.push(entityRoute);
        } else {
            params.push(entityType);
        }

        // Additional params map at the end
        params.push({ placeId });

        return params;
    }

    handleError(errMsg, err) {
        this.showErrorModal();
        this.errorLoggingService.logError(errMsg, err);
    }

    showErrorModal() {
        const message = this.edit ? this.text.updateError : this.text.createError;

        this.$uibModal
            .open({
                backdrop: 'static',
                component: 'crSimpleModal',
                windowClass: 'cr-modal-size-sm',
                resolve: {
                    message: () => message,
                },
            })
            .result.catch(() => null);
    }

    initCategorySelection() {
        this.categoryOptionValues = {
            menuCategory: 1,
            printerCategory: 2,
            notSelected: 0
        }
        
        this.categoryDisplayOptions = [
            {
                label: this.text.menuCategory,
                value: this.categoryOptionValues.menuCategory,
            },
            {
                label: this.text.printerCategory,
                value: this.categoryOptionValues.printerCategory,
            },
        ];

        if(this.category.categoryGroup) {
            this.selectedCategory = this.categoryOptionValues.printerCategory;
        } else if(this.edit && !this.category.categoryGroup) {
            this.selectedCategory = this.categoryOptionValues.menuCategory;
        }
        else{
            this.selectedCategory = null;
        }
        this.menuCategoryTooltipInfo = `<div>This will create a category of
                                 <br />alike items that appear as a
                                 <br />section of the menu</div>
                                `;
        this.menuPrinterTooltipInfo = `<div>This will create a category of
                                 <br />menu items that only get printed
                                 <br />if the category is applied to the
                                 <br />printer. Items on order not set
                                 <br />on the category will not be
                                 <br />printed</div>
                                `;
    }

    deleteCategory() {
        if (this.edit) {
            let deleteText = this.text.deleteModalMessage;
            if (this.entityType === this.crConstants.entity.types.POI_CATEGORIES && !this.category.parentId) {
                deleteText = this.text.deleteTopLevelModalMessage;
            }

            this.openDeleteModal(deleteText)
                .then(() => {
                    this.loading = true;
                    const { menuId } = this.$state.params;
                    const entityRoute = menuId ? `menus/${menuId}/${this.entityType}` : this.entityType;

                    this.crEntityService
                        .deleteEntity(entityRoute, this.category.id, this.venueId, { placeId: this.placeId })
                        .then(() => this.gotoCategories(this.category, true))
                        .catch((err) => this.handleError('Could NOT delete this Category', err))
                        .finally(() => {
                            this.loading = false;
                        });
                })
                .catch(() => null);
        }
    }

    onPlacementDropdownSelect($event) {
        if (this.category.parentId !== $event.model[0].id) {
            this.category.parentId = $event.model[0].id;
            this.form.$setDirty();
        }
    }

    openDeleteModal(message) {
        return this.$uibModal.open({
            backdrop: 'static',
            component: 'crSimpleModal',
            windowClass: 'cr-modal-size-sm',
            resolve: {
                message: () => message,
                type: () => this.crConstants.modalTypes.SUBMIT,
                submitText: () => this.text.deleteModalSubmit,
            },
        }).result;
    }

    openErrorModal(message) {
        return this.$uibModal.open({
            backdrop: 'static',
            component: 'crSimpleModal',
            windowClass: 'cr-modal-size-sm',
            resolve: {
                message: () => message,
                type: () => this.crConstants.modalTypes.CANCEL,
            },
        }).result;
    }

    filterCategories(categories) {
        return categories.filter((category) => category.id !== this.$state.params.id && !category.parentId);
    }

    initCategories(data) {
        this.allCategories[this.locale.current] = data;
        const categories = data;
        this.categories = this.filterCategories(categories);
        ManageCategoryController.setSelectedCategory(this.category, this.categories);
    }

    getLocalizedCategories() {
        const { menuId } = this.$state.params;
        const entityRoute = menuId ? `menus/${menuId}/${this.entityType}` : this.entityType;

        this.crEntityService
            .getEntityList(entityRoute, { venueId: this.venueId, placeId: this.placeId }, this.locale.current)
            .then((data) => {
                this.initCategories(data.content);
            });
    }

    getCategory() {
        const { menuId } = this.$state.params;
        const entityRoute = menuId ? `menus/${menuId}/${this.entityType}` : this.entityType;

        // TODO: When is there ever a category state param?
        const promise = this.$state.params.category
            ? this.$q.resolve(this.$state.params.category)
            : this.crEntityService.getEntity(
                  this.entityType,
                  this.$state.params.id,
                  this.venueId,
                  this.locale.default,
                  entityRoute,
                  { placeId: this.placeId }
              );

        return promise
            .then((category) => {
                this.category = category;
                this.origLabel = this.category[this.displayKey];
                this.locale.list = this.crLocaleService.updateLocaleListStatus(
                    this.category.missingTranslations,
                    this.locale
                );
                if(category.categoryGroup){ this.category.categoryGroup = category.categoryGroup};
                this.placement = category.parentId
                    ? this.crConstants.categoryPlacementLocations.WITHIN
                    : this.crConstants.categoryPlacementLocations.TOP;
                const isTopLevel = this.isTopLevel();
                this.deleteText = isTopLevel ? this.text.deleteCategory : this.text.deleteSubcategory;
                this.title = isTopLevel ? this.text.editCategory : this.text.editSubcategory;

                if (this.category.parentId) {
                    this.getCategories().then((categories) => {
                        categories.forEach((cat) => {
                            cat.isSelected = cat.id === this.category.parentId;
                        });
                    });
                }

                this.initCategorySelection();

                return this.category;
            })
            .catch((err) => {
                this.errorLoggingService.logError(`Could not get ${this.parentRoute}-category`, err, {
                    venueId: this.venueId,
                    categoryId: this.$state.params.id,
                });
                if (err.status === 404) {
                    this.$state.go('client.404', {
                        customerId: this.$state.params.customerId,
                        venueId: this.venueId,
                    });
                } else {
                    this.$uibModal
                        .open({
                            backdrop: 'static',
                            component: 'crSimpleModal',
                            windowClass: 'cr-modal-size-sm',
                            resolve: {
                                message: () => this.text.standardErrorMessage,
                            },
                        })
                        .result.catch(() => {
                            this.$state.go(`${this.parentRoute}.categories`, {
                                customerId: this.$state.params.customerId,
                                venueId: this.venueId,
                            });
                        });
                }

                this.hasDataError = true;
            })
            .finally(() => {
                this.getLabelMaxCharValue();
                this.loading = false;
            });
    }

    isTopLevel() {
        return this.placement === this.crConstants.categoryPlacementLocations.TOP;
    }

    getLabelMaxCharValue() {
        if (this.isTopLevel()) {
            this.charMaxLength = this.crConstants.input.maxLength.CATEGORY;
        } else {
            this.charMaxLength = this.crConstants.input.maxLength.SUBCATEGORY;
        }
    }

    getIcons() {
        return this.crImageService
            .getIcons(this.imageConstants.folderNames[this.entityType])
            .then((icons) => {
                this.icons = icons.map((icon) => ({
                    id: icon.src,
                    label: icon.name,
                    iconSrc: this.crImageService.getIconSrc(icon.src),
                }));

                return this.icons;
            })
            .catch(() => {
                this.errorLoggingService.logError('Could not get icons');
            });
    }

    setIcon(images, icons) {
        if (images) {
            const icon = _.find(this.category.images, { type: this.imageConstants.types.ICON });

            if (icon) {
                const currentIcon = _.find(icons, { id: icon.src });
                currentIcon.isSelected = true;
            }
        }
    }

    updateIcon(event) {
        this.category.images = this.crImageService.updateIconAndGetImagesModel(this.category.images, event.model[0].id);
    }

    initDropdown() {
        this.showDropdown =
            this.crConfig.customer.localizationEnabled && this.locale.list && this.locale.list.length > 1;
    }
}

export default ManageCategoryController;
