const connectionStates = {
    disabled: 'disabled',
    pending: 'pending',
    error: 'error',
    inactive: 'inactive',
    active: 'active',
};

class PosEnableController {
    constructor(
        $q,
        $state,
        $uibModal,
        $timeout,
        crPosEnableLocale,
        crPosEnableService,
        crAnalyticsService,
        crErrorLoggingService
    ) {
        this.$q = $q;
        this.$state = $state;
        this.$uibModal = $uibModal;
        this.$timeout = $timeout;
        this.text = crPosEnableLocale;
        this.crPosEnableService = crPosEnableService;
        this.crAnalyticsService = crAnalyticsService;
        this.crErrorLoggingService = crErrorLoggingService;
    }

    $onInit() {
        this.toggleText = {
            on: this.text.active,
            off: this.text.inactive,
        };

        // pos-enable-modal will add the 'posInfo' property
        this.modalData = {};

        this.customerId = this.$state.params.customerId;
        this.placeId = this.$state.params.placeId;

        this.posAdapter = {
            id: '',
            displayName: '',
            eurekaServiceName: '',
        };

        this.isLoading = true;
        this.$q
            .all([
                this.crPosEnableService.getCommercePlaceConfig(this.placeId),
                this.crPosEnableService.getPosAdaptersList(),
            ])
            .then((res) => {
                const [commerceConfig, posAdapterList] = res;

                if (posAdapterList[commerceConfig.posAdapterId]) {
                    this.posAdapter = posAdapterList[commerceConfig.posAdapterId];
                }

                if (!this.posAdapter.eurekaServiceName) {
                    this.updateState(connectionStates.error);
                    return;
                }

                if (commerceConfig.commerceEnabled) {
                    this.initEnabledStore();
                } else {
                    this.updateWrangleState(this.posAdapter.eurekaServiceName, this.placeId).then(() => {
                        this.isLoading = false;
                        this.getPosAdapterConfig(this.posAdapter.eurekaServiceName, this.placeId);
                    });
                }
            })
            .catch((e) => {
                if (e.status === 404) {
                    this.isActive = false;
                    this.updateState(connectionStates.disabled);
                    this.isLoading = false;
                } else {
                    this.updateState(connectionStates.error);
                    this.crErrorLoggingService.logError('POS connection error', e, this.placeId);
                }
            });
    }

    initEnabledStore() {
        this.isActive = true;
        this.connectionState = connectionStates.active;
        this.connectionStateText = this.text.activated;
        this.getPosAdapterConfig(this.posAdapter.eurekaServiceName, this.placeId);
        this.isLoading = false;
    }

    updateWrangleState(eurekaServiceName, placeId) {
        return this.crPosEnableService
            .getWrangleState(eurekaServiceName, placeId)
            .then((res) => {
                const { state, progressPercent } = res.data;

                switch (state) {
                    case 'WRANGLING':
                        this.percentComplete = progressPercent;
                        this.updateState(connectionStates.pending);
                        break;
                    case 'FAILED':
                        this.updateState(connectionStates.error);
                        break;
                    case 'COMPLETED':
                        this.isActive = false;
                        this.connectionState = connectionStates.inactive;
                        this.connectionStateText = this.text.readyForActivation;
                        break;
                }

                this.crAnalyticsService.track(`wrangle state: ${state}`);
            })
            .catch((e) => {
                this.updateState(connectionStates.error);
                this.crErrorLoggingService.logError(`could not wrangle from ${eurekaServiceName}`, e);
            });
    }

    startWrangling(eurekaServiceName, placeId) {
        return this.crPosEnableService
            .startWrangling(eurekaServiceName, placeId)
            .then(() => {
                this.updateWrangleState(eurekaServiceName, placeId);
            })
            .catch((e) => {
                this.crErrorLoggingService.logError('Could not start wrangling', e, placeId);
            });
    }

    updateState(state) {
        this.connectionState = state;

        switch (state) {
            case connectionStates.pending:
                this.$timeout(() => {
                    this.updateWrangleState(this.posAdapter.eurekaServiceName, this.placeId);
                }, 5000);
                break;
            case connectionStates.inactive:
                this.deactivateCommerce(this.placeId);
                break;
            case connectionStates.active:
                this.crPosEnableService
                    .getCommercePlaceConfig(this.placeId)
                    .then((commerceConfig) => {
                        this.enableCommerce(commerceConfig);
                    })
                    .catch((e) => {
                        this.updateState(connectionStates.error);
                        this.crErrorLoggingService.logError('POS connection error', e, this.placeId);
                    });
                break;
        }
    }

    showEnableOrderingModal() {
        this.$uibModal
            .open({
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                backdrop: 'static',
                component: 'crPosEnableModal',
                resolve: {
                    modalData: this.modalData,
                },
            })
            .result.then(
                () => {
                    this.validatePosConfig(this.modalData);
                },
                () => {
                    this.crAnalyticsService.track('Enable Ordering Modal Closed');
                }
            );
    }

    changePosConfig() {
        this.modalData.posInfo = this.pos;
        this.modalData.posInfo.adapterId = this.posAdapter.id;
        this.showEnableOrderingModal();
    }

    validatePosConfig(modalData) {
        this.crAnalyticsService.track('POS config submitted');

        this.isLoading = true;

        this.crPosEnableService
            .validatePosConfig(this.placeId, modalData.posInfo)
            .then((pos) => {
                this.posAdapter = modalData.posInfo.system;
                this.updatePos(pos);
                this.connectionState = connectionStates.pending;
                this.percentComplete = 0;
                this.createCommerceConfig(this.placeId, {
                    commerceEnabled: false,
                    menuIds: [modalData.posInfo.menu],
                    posAdapterId: this.posAdapter.id,
                });
                this.startWrangling(this.posAdapter.eurekaServiceName, this.placeId);
            })
            .catch((e) => {
                this.updateState(connectionStates.error);
                this.crErrorLoggingService.logError('unable to validate POS config', e, modalData.posInfo);
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    getPosAdapterConfig(eurekaServiceName, placeId) {
        this.isLoadingConfig = true;
        return this.crPosEnableService
            .getPosAdapterConfig(eurekaServiceName, placeId)
            .then((pos) => {
                this.updatePos(pos);
            })
            .catch((e) => {
                this.updateState(connectionStates.error);
                this.crErrorLoggingService.logError(
                    `could not retrieve pos adapter config from ${eurekaServiceName}`,
                    e
                );
            })
            .finally(() => {
                this.isLoadingConfig = false;
            });
    }

    createCommerceConfig(placeId, config) {
        this.crPosEnableService.updateCommerceConfig(placeId, config).catch((e) => {
            this.crErrorLoggingService.logError('could not create commerce config', e, {
                placeId: this.placeId,
                config,
            });
        });
    }

    enableCommerce(config) {
        this.isActive = true;
        config.commerceEnabled = true;
        this.crPosEnableService
            .updateCommerceConfig(this.placeId, config)
            .then(() => {
                this.connectionStateText = this.text.activated;
                this.crAnalyticsService.track('place successfully activated', { placeId: this.placeId });
            })
            .catch((e) => {
                this.isActive = false;
                this.updateState(connectionStates.error);
                this.crErrorLoggingService.logError('could not enable commerce', e, this.placeId);
            });
    }

    deactivateCommerce() {
        this.isActive = false;
        this.crPosEnableService
            .deactivateCommerce(this.placeId)
            .then(() => {
                this.connectionStateText = this.text.readyForActivation;
                this.crAnalyticsService.track('place successfully deactivated', { placeId: this.placeId });
            })
            .catch((e) => {
                this.isActive = true;
                this.updateState(connectionStates.error);
                this.crErrorLoggingService.logError('there was a problem deactivating commerce', e, this.placeId);
            });
    }

    updatePos(pos) {
        this.pos = {
            posSystemName: this.posAdapter.displayName,
            posLocationIdentifier: pos.posLocationIdentifier,
            posMenuIdentifier: pos.posMenuIdentifier,
        };
    }

    toggleMobileOrdering(e) {
        this.isActive = !e.isOn;

        const state = this.isActive ? connectionStates.active : connectionStates.inactive;

        this.updateState(state);
    }
}

export default PosEnableController;
