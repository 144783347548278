import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment, { Moment } from 'moment';
import { forkJoin } from 'rxjs';
import { constants } from '../constants/constants';
import {
  CreateReservationObject, ProductSummaryResponse, ReservationEvent, ReservationEventResponse, ReservationLocationList, ReservationTicket, ReservationTicketsResponse, Schedule
} from './reservation.types';

@Injectable({
  providedIn: 'root',
})
export class ReservationService {
  private baseUrl: string;

  private baseUrlAdmin: string;

  constructor(private http: HttpClient) {
    this.baseUrl = '/rest/reservations';
    this.baseUrlAdmin = '/rest/reservations-admin';
  }

  async getReservationLocationList(
    venueId: string,
    page = 1,
    sortBy = 'name',
    sort: 'asc' | 'desc' = 'asc',
    state = 'ACTIVE',
  ): Promise<ReservationLocationList> {
    const resp = await this.http
      .get<ReservationLocationList>(`${this.baseUrl}/${venueId}/locations`, {
        params: {
          venueId, page: page.toString(), sortby: sortBy, sort, state,
        },
      })
      .toPromise();
    return resp ?? { places: [], query: { page: 1, pages: 1, total: 0 } };
  }

  async getProductTickets(
    productId: string,
    startDate: Moment,
    endDate: Moment,
  ): Promise<ReservationTicketsResponse> {
    const response: any = await this.http
      .post(`${this.baseUrl}/tickets/${productId}`, {
        startDate: startDate.format(),
        endDate: endDate.format(),
      })
      .toPromise();

    response.tickets.forEach((ticket) => {
      if (!ticket.status) {
        if (ticket.entitlements[0].usages) {
          ticket.status = 'FULFILLED';
        } else {
          ticket.status = 'PENDING';
        }
      }
    });

    return response;
  }

  async getProductDaySummary(productId: string, date: string): Promise<ProductSummaryResponse> {
    const response: any = await this.http
      .get(`${this.baseUrl}/summary`, {
        params: {
          productId,
          date,
        },
      })
      .toPromise();

    return response;
  }

  admitGuest(ticketGroup: ReservationTicket[]): Promise<{}[]> {
    return forkJoin(
      ticketGroup.map((ticket: ReservationTicket) => this.http.post(`${this.baseUrl}/${ticket.ticketId}/admit-guest`, {
        redemptionId: ticket.entitlements[0].entitlementId,
        userId: ticket.authorizedUsers[0],
      })),
    ).toPromise();
  }

  cancelTickets(ticketGroup: ReservationTicket[]): Promise<{}> {
    const ticketIds = ticketGroup.map((ticket) => ticket.ticketId);

    return this.http
      .post(`${this.baseUrl}/cancel-tickets`, {
        ticketIds,
        userId: ticketGroup[0].authorizedUsers[0],
      })
      .toPromise();
  }

  async getReservationEvents(venueId): Promise<ReservationEventResponse> {
    const response: any = await this.http
      .get(`${this.baseUrlAdmin}/dining-locations`, {
        params: {
          venueId,
        },
      })
      .toPromise();

    return response;
  }

  async createReservationEvent(eventObject: CreateReservationObject): Promise<any> {
    const response: any = await this.http
      .post(`${this.baseUrlAdmin}/dining-locations`, {
        ...eventObject,
      })
      .toPromise();

    return response;
  }

  async getReservationEventById(eventId: string): Promise<ReservationEvent> {
    const response: any = await this.http.get(`${this.baseUrlAdmin}/dining-locations/${eventId}`).toPromise();

    return response;
  }

  async updateEventById(eventId: string, eventObject: ReservationEvent): Promise<any> {
    const response: any = await this.http
      .put(`${this.baseUrlAdmin}/dining-locations/${eventId}`, {
        ...eventObject,
      })
      .toPromise();

    return response;
  }

  async updateEventState(eventId: string, state: string): Promise<any> {
    const response: any = await this.http
      .put(`${this.baseUrlAdmin}/dining-locations/${eventId}/state`, {
        state,
      })
      .toPromise();

    return response;
  }

  scheduleIsActive(schedule: Schedule): boolean {
    const { recurrence } = schedule;
    const now = moment();

    if (recurrence) {
      if (!recurrence.includes('UNTIL=')) {
        return true;
      }

      for (const part of recurrence.split(';')) {
        if (part.startsWith('UNTIL=')) {
          const endDate = moment(part.substring(6), constants.iCal.format.date).toDate();

          if (now.isAfter(endDate, 'second')) {
            return false;
          }
        }
      }
    }

    return !!schedule.instances.find((instance) => moment(instance.startTime).isAfter(now, 'second'));
  }
}
