import _ from 'lodash';
import text from './resources/locale/en.json';

class ExperienceDetails {
    constructor(
        $state,
        $q,
        $uibModal,
        crConfig,
        crConstants,
        crAnalyticsService,
        crNavigationService,
        crExperiencesService,
        crEntityService,
        crErrorLoggingService,
        crLocaleService,
        crImageService,
        crPoiService,
        crCouponsService,
        crCommerceService,
        crVenueService,
        crToastService,
        crExperienceManageService
    ) {
        this.$state = $state;
        this.$q = $q;
        this.$uibModal = $uibModal;
        this.crConfig = crConfig;
        this.crConstants = crConstants;
        this.crAnalyticsService = crAnalyticsService;
        this.crNavigationService = crNavigationService;
        this.crExperiencesService = crExperiencesService;
        this.crEntityService = crEntityService;
        this.crErrorLoggingService = crErrorLoggingService;
        this.crLocaleService = crLocaleService;
        this.crImageService = crImageService;
        this.crPoiService = crPoiService;
        this.crCouponsService = crCouponsService;
        this.crCommerceService = crCommerceService;
        this.crVenueService = crVenueService;
        this.crToastService = crToastService;
        this.crExperienceManageService = crExperienceManageService;

        this.text = text;
    }

    $onInit() {
        this.loading = true;
        this.associatedEntities = {};
        this.locale = this.crLocaleService.getLocale(this.$state.params.venueId, this.$state.params.locale, false);

        this.splitActions = [
            {
                label: this.text.delete,
                action: () => {
                    this.deleteExperience();
                },
            },
            {
                label: this.text.saveCopy,
                action: () => {
                    this.saveACopy();
                },
            },
        ];

        this.crToastService.autoToast(this.$state.params);

        this.$q
            .all([
                this.loadExperience(this.copyOf),
                this.getTagDefinitions(),
                this.getEntityLists(),
                this.getGeofences(),
            ])
            .then(() => {
                this.updateExperienceTriggerLabels();
                this.loading = false;
            });
    }

    saveACopy() {
        if (this.group === 'automations') {
            this.$state.go('client.experience-promoter.create-automation', {
                copyOf: this.experience.id,
                type: this.experience.trigger.id,
            });
        } else if (this.group === 'broadcasts') {
            this.$state.go('client.experience-promoter.create-broadcast', {
                copyOf: this.experience.id,
                type: this.experience.trigger.id,
            });
        }
    }

    goToEdit() {
        if (this.group === 'automations') {
            this.$state.go('client.experience-promoter.edit-automation', {
                id: this.experience.id,
            });
        } else {
            this.$state.go('client.experience-promoter.edit-broadcast', {
                id: this.experience.id,
            });
        }
    }

    getEntityLists() {
        return this.$q
            .all([
                this.crEntityService.getEntityList(this.crConstants.entity.types.EVENTS, {
                    state: 'ACTIVE',
                    sort: 'asc',
                    venueId: this.$state.params.venueId,
                }),
                this.crEntityService.getEntityList(this.crConstants.entity.types.POIS, {
                    state: 'ACTIVE',
                    sort: 'asc',
                    venueId: this.$state.params.venueId,
                }),
                this.crEntityService.getEntityList(this.crConstants.entity.types.POI_CATEGORIES, {
                    state: 'ACTIVE',
                    sort: 'asc',
                    venueId: this.$state.params.venueId,
                }),
                this.crCommerceService.getProducts(this.$state.params.venueId),
                this.crPoiService.getPois({
                    state: 'ACTIVE',
                    venueId: this.$state.params.venueId,
                    userDetectEnabled: true,
                }),
                this.crCouponsService.getCoupons({
                    state: 'ACTIVE',
                    venueId: this.$state.params.venueId,
                    sortby: 'name',
                    sort: 'asc',
                }),
                this.crExperienceManageService.getAllProductsAndCategories(this.$state.params.venueId),
            ])
            .then((data) => {
                this.events = data[0].content.map((event) => ({
                    id: event.id,
                    name: event.name,
                }));

                this.pois = data[1].content.map((poi) => ({
                    id: poi.id,
                    name: poi.name,
                    categories: poi.displayCategories,
                }));

                this.poiCategoryDefinitions = data[2].content.map((poiCategory) => ({
                    id: poiCategory.id,
                    label: poiCategory.label,
                }));

                this.products = data[3].map((product) => ({
                    id: product.id,
                    name: product.label,
                }));

                this.poisWithBeacons = data[4].content.map((poi) => ({
                    id: poi.id,
                    name: poi.name,
                    categories: poi.displayCategories,
                }));

                const poiCategoriesMap = {};
                this.poisWithBeacons
                    .filter((poi) => poi.categories)
                    .flatMap((poi) => poi.categories)
                    .forEach((category) => {
                        poiCategoriesMap[category.id] = category;
                    });
                this.poiCategories = Object.values(poiCategoriesMap);

                this.coupons = data[5].content;
                this.couponsMap = {};
                this.coupons.forEach((coupon) => {
                    this.couponsMap[coupon.id] = coupon;
                });

                this.menuItems = data[6].products;
                this.menuCategories = data[6].categories;
            })
            .catch((err) => {
                this.error = true;
                this.loading = false;
                this.crErrorLoggingService.logError('Could not load entity lists', err);
            });
    }

    getTagDefinitions() {
        const customerParams = {
            level: 'CUSTOMER',
            supportsSegmentation: true,
        };
        const venueParams = { ...customerParams, level: 'VENUE', venueId: this.$state.params.venueId };
        const route = this.crConstants.entity.types.TAG_DEFINITIONS;

        return this.$q
            .all([
                this.crEntityService.getEntityList(route, customerParams),
                this.crEntityService.getEntityList(route, venueParams),
            ])
            .then((data) => {
                this.tagDefinitions = data[0].concat(data[1]);

                this.tagDefinitions.forEach((tagDefinition) => {
                    tagDefinition.id = tagDefinition.tagKey;

                    tagDefinition.values.forEach((value) => {
                        value.id = value.tagValueKey;
                    });
                });
            })
            .catch((err) => {
                this.error = true;
                this.loading = false;
                this.crErrorLoggingService.logError('Could not get tag definitions', err);
            });
    }

    getGeofences() {
        return this.crVenueService
            .getPublicById(this.$state.params.venueId)
            .then((data) => {
                this.geofences = data.geofences;
            })
            .catch((err) => {
                this.error = true;
                this.loading = false;
                this.crErrorLoggingService.logError('Could not get geofences', err);
            });
    }

    loadExperience() {
        const { id } = this.$state.params;
        return this.crExperiencesService
            .getExperience(id)
            .then((experience) => {
                this.experience = experience;
                return this.getExperienceType(this.experience.trigger.id);
            })
            .then(() => {
                // Split coupons out of normal list of links. They get added back together in preparePayload.
                this.experience.content.coupons = this.experience.content.links.filter(
                    (link) => link.type === 'COUPON'
                );
                this.experience.content.links = this.experience.content.links.filter((link) => link.type !== 'COUPON');

                if (_.isEmpty(this.experience.content.push)) {
                    delete this.experience.content.push;
                }

                const eqTags = this.experience.audience.tags.filter((tag) => tag.op === 'EQ');
                const neqTags = this.experience.audience.tags.filter((tag) => tag.op === 'NEQ');

                if (eqTags.length === 0) {
                    this.experience.audience.tags.push({
                        key: null,
                        op: 'EQ',
                        values: [],
                    });
                }

                if (neqTags.length === 0) {
                    this.experience.audience.tags.push({
                        key: null,
                        op: 'NEQ',
                        values: [],
                    });
                }

                if (
                    _.get(this.experienceType, 'active.type') !== 'ALWAYS' &&
                    this.group === 'automations' &&
                    !_.has(this.experience, 'trigger.active.schedules[0].recurrence')
                ) {
                    _.set(
                        this.experience,
                        'trigger.active.schedules[0].recurrence',
                        'FREQ=WEEKLY;BYDAY=SU,MO,TU,WE,TH,FR,SA;'
                    );
                }

                this.summaryData = _.cloneDeep(this.experience);

                this.completeSteps = {
                    what: true,
                    who: true,
                    when: true,
                };

                this.selectedClass = this.experience.status;

                this.getDisplayImage(this.experience.content.images);

                this.updatePhonePreview();
            })
            .catch((err) => {
                this.error = true;
                this.loading = false;
                this.crErrorLoggingService.logError('Could not get experience', err);
            });
    }

    getExperienceType(type) {
        return this.crExperiencesService
            .getExperienceType(type, this.group)
            .then((experienceType) => {
                if (experienceType.userBehavior) {
                    experienceType.userBehavior.forEach((behavior) => {
                        if (behavior.fields) {
                            behavior.fields.forEach((field) => {
                                if (field.description) {
                                    const { currencyCode } = _.find(this.crConfig.customer.venues, {
                                        id: this.$state.params.venueId,
                                    });
                                    field.description = field.description.replace(/%CURRENCY%/, currencyCode);
                                }
                            });
                        }
                    });
                }
                this.experienceType = experienceType;
                return this.experienceType;
            })
            .catch((err) => {
                this.error = true;
                this.loading = false;
                this.crErrorLoggingService.logError('Could not get experience type', err);
            });
    }

    updateExperienceTriggerLabels() {
        // Add labels and ids to user visit behavior poi tags and menu items
        this.crExperienceManageService.updateTriggerLabels(
            this.experience,
            'trigger.params.poi',
            this.pois,
            this.poiCategories,
            'POI',
            'POI_CATEGORY'
        );
        this.crExperienceManageService.updateTriggerLabels(
            this.experience,
            'trigger.params.menuItems',
            this.menuItems,
            this.menuCategories,
            'MENU_ITEM',
            'MENU_ITEM_CATEGORY'
        );
    }

    get titleBarLabel() {
        if (this.group === 'automations') {
            return this.text.detailsAutomation;
        }

        return this.text.detailsBroadcast;
    }

    get experienceTypeTitle() {
        return this.group === 'automations'
            ? `${this.text.automation} ${this.experienceType.name}`
            : `${this.text.broadcast} ${this.experienceType.name}`;
    }

    next() {
        this.changeStep(this.step + 1);
    }

    goBack() {
        this.crNavigationService.goBack('client.experience-promoter.dashboard', { tabId: this.group });
    }

    activate() {
        this.experience.status = 'ACTIVE';
        this.selectedClass = this.experience.status;
        this.saving = true;
        this.updateExperience();
    }

    deactivate() {
        this.experience.status = 'INACTIVE';
        this.selectedClass = this.experience.status;
        this.saving = true;
        this.updateExperience();
    }

    updateExperience() {
        const payload = this.preparePayload();
        this.crExperiencesService
            .updateExperience(payload)
            .then((res) => this.onSaveSuccess(res))
            .catch((err) => this.handleError('Could not update experience', err))
            .finally(() => {
                this.saving = false;
            });
    }

    preparePayload() {
        const payload = _.cloneDeep(this.experience);
        payload.audience.tags = payload.audience.tags.filter((tag) => tag.key);

        // Strip erroneous data out of user behavior trigger
        if (_.get(payload, 'trigger.params.poi')) {
            payload.trigger.params.poi = payload.trigger.params.poi.map((poi) => ({
                value: poi.value,
                type: poi.type,
            }));
        }

        // Move coupons back into the list of links
        if (payload.content.coupons) {
            payload.content.links.push(...payload.content.coupons);
            payload.content.coupons = null;
        }

        return payload;
    }

    onSaveSuccess(data) {
        if (!data) {
            data = this.experience;
        }

        this.crAnalyticsService.track('Experience State Modified', data);
    }

    handleError(errMsg, err) {
        this.showErrorModal();
        this.crErrorLoggingService.logError(errMsg, err);
    }

    showErrorModal() {
        const message = this.text.updateError;

        this.$uibModal
            .open({
                backdrop: 'static',
                component: 'crSimpleModal',
                windowClass: 'cr-modal-size-sm',
                resolve: {
                    closeText: () => this.text.closeError,
                    message: () => message,
                },
            })
            .result.catch(() => null);
    }

    deleteExperience() {
        this.$uibModal
            .open({
                backdrop: 'static',
                component: 'crSimpleModal',
                windowClass: 'cr-modal-size-sm',
                resolve: {
                    type: () => 'submit',
                    submitText: () => this.text.deleteExperience,
                    message: () => this.text.deleteConfirmation,
                },
            })
            .result.then(() => {
                this.saving = true;
                return this.crExperiencesService
                    .archiveExperience(this.experience.id)
                    .then(() => {
                        this.crAnalyticsService.track('Archive Experience Success');
                        this.crNavigationService.goBack('client.experience-promoter.dashboard', {
                            tabId: this.group,
                            toast: {
                                msg: `"${this.experience.name}" ${this.text.hasBeenDeleted}`,
                            },
                        });
                    })
                    .catch((err) => {
                        this.saving = false;
                        this.crErrorLoggingService.logError('Could not delete experience', err, this.experience);
                    });
            })
            .catch(() => null);
    }

    onImageUpdated(event) {
        this.experience.content.images = _.cloneDeep(event.images);
    }

    getDisplayImage(images) {
        const phoneImgWidth = this.crConstants.image.phoneDisplaySize.MAIN.width;
        const phoneImgHeight = this.crConstants.image.phoneDisplaySize.MAIN.height;
        if (images && images.length > 0) {
            images[0].type = this.crConstants.image.types.MAIN;
            images[0].displaySrc = this.crImageService.getMainImageSrc(images, phoneImgWidth, phoneImgHeight);
        }
    }

    updatePhonePreview($event) {
        if ($event && $event.locale) {
            this.locale = $event.locale;
        }
        this.phoneData = _.cloneDeep(this.experience);
    }
}

export default ExperienceDetails;
