import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KitchenManageComponent } from './kitchen-manage.component';
import { KitchenMenusModule } from '../kitchen-menus/kitchen-menus.module';



@NgModule({
  declarations: [
    KitchenManageComponent
  ],
  imports: [
    CommonModule,
    KitchenMenusModule
  ],
  exports: [
    KitchenManageComponent
  ]
})
export class KitchenManageModule { }
