import controller from './experience-details.controller';
import template from './experience-details.html';

import './experience-details.less';

const component = {
    template,
    bindings: {
        group: '@?',
    },
    controller: [
        '$state',
        '$q',
        '$uibModal',
        'crConfig',
        'crConstants',
        'crAnalyticsService',
        'crNavigationService',
        'crExperiencesService',
        'crEntityService',
        'crErrorLoggingService',
        'crLocaleService',
        'crImageService',
        'crPoiService',
        'crCouponsService',
        'crCommerceService',
        'crVenueService',
        'crToastService',
        'crExperienceManageService',
        controller,
    ],
};

export default component;
