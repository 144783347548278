import _ from 'lodash';
import text from './resources/locale/en.json';

import ListPage from '../../../../commons/controller/list-page';

class CouponsList extends ListPage {
    constructor(
        $state,
        crErrorLoggingService,
        crAnalyticsService,
        crNavigationService,
        crToastService,
        crLocaleService,
        crConfig,
        crConstants,
        crCouponsListService,
        crEntitySelectionManager
    ) {
        super($state, crErrorLoggingService, crAnalyticsService, crToastService, crLocaleService, crConfig);
        this.crCouponsListService = crCouponsListService;
        this.crNavigationService = crNavigationService;
        this.crConstants = crConstants;
        this.crEntitySelectionManager = crEntitySelectionManager;
    }

    $onInit() {
        super.$onInit();
        this.text = text;

        const { columns } = this.text;

        this.listData = {
            columns: [
                { key: '', label: '' },
                { key: 'name', label: columns.name, class: 'large-column name-column', sortable: true },
                { key: 'type', label: columns.type, class: 'medium-column' },
                { key: 'code', label: columns.code },
                { key: 'state', label: columns.state, class: 'medium-column' },
                { key: 'editButton', label: '', class: 'small-column' },
            ],
            rows: [],
        };

        if (this.hasMultipleLanguages()) {
            this.addMissingTranslationsColumnHeader();
        }

        this.stateFilterData = [
            { id: 'ACTIVE', label: this.text.active, isSelected: true },
            { id: 'INACTIVE', label: this.text.inactive },
        ];

        this.params.state = 'ACTIVE';

        this.typeFilterData = [
            { id: 'CODE128', label: this.text.types.code128 },
            { id: 'QR', label: this.text.types.qr },
            { id: 'TEXT', label: this.text.types.text },
        ];
    }

    getData() {
        this.isLoading = true;
        this.dataError = false;

        return this.crCouponsListService
            .getData(this.params)
            .then((data) => {
                data.search = data.query.search || '';
                if (data.search === this.params.search) {
                    this.isLoading = false;
                    return this.crCouponsListService.mapToRowsModel(data.content);
                }
            })
            .catch((err) => {
                this.isLoading = false;
                this.dataError = true;
                this.endOfResults = true;
                this.listData.rows = [];
                this.crErrorLoggingService.logError('Could not get Coupons List data', err);
            });
    }

    listIsEmpty() {
        return !this.isLoading && this.listData.rows.length === 0;
    }

    updateParam(key, event) {
        this.params[key] = _.isEmpty(event.model) ? '' : event.model[0].id;
        this.updateData();
    }

    getTypeLabel(type) {
        return this.text.types[type.toLowerCase()];
    }

    goToCreate() {
        this.$state.go('client.experience-promoter.create-coupon');
    }

    goBack() {
        this.crNavigationService.goBack('client.experience-promoter.dashboard');
    }
}

export default CouponsList;
