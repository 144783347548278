<section class="fees-list-header">
  <cr-search
    class="header-item"
    placeholder="Search by name"
    (update)="handleSearch($event)"
    [formControl]="searchFormControl"
  >
  </cr-search>
</section>

<cr-table
  [options]="crTableOptions"
  (columnSort)="handleSort($event)"
  (columnClick)="handleColumnClick($event)"
>
</cr-table>
