/* eslint-disable max-classes-per-file */
import { StateService } from '@uirouter/core';
import NgFileUpload from 'ng-file-upload';

export class ImageService {
  [key: string]: any;
}

export class ToastService {
  [key: string]: any;
}

export class FeatureFlagService {
  [key: string]: any;
}

export function stateServiceFactory(i: any) {
  return i.get('$state');
}

export const stateServiceProvider = {
  provide: StateService,
  useFactory: stateServiceFactory,
  deps: ['$injector'],
};

export function imageServiceFactory(i: any) {
  return i.get('crImageService');
}

export const imageServiceProvider = {
  provide: ImageService,
  useFactory: imageServiceFactory,
  deps: ['$injector'],
};

export function featureFlagServiceFactory(i: any) {
  return i.get('crFeatureFlagsService');
}

export const featureFlagServiceProvider = {
  provide: FeatureFlagService,
  useFactory: featureFlagServiceFactory,
  deps: ['$injector'],
};

export function toastServiceFactory(i: any) {
  return i.get('crToastService');
}

export const toastServiceProvider = {
  provide: ToastService,
  useFactory: toastServiceFactory,
  deps: ['$injector'],
};

export function fileUploadServiceFactory(i: any) {
  return i.get('Upload');
}

export const fileUploadServiceProvider = {
  provide: 'Upload',
  useFactory: fileUploadServiceFactory,
  deps: ['$injector'],
};

