import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import _ from 'lodash';
import { Days, iCalDays, Place, PlaceOpenAndCloseSchedule } from '../../models/places.model';

@Injectable({
  providedIn: 'root',
})
export class PlacesService {
  private fullToICal: { [day: string]: string } = {
      'SUNDAY': iCalDays.SUNDAY,
      'MONDAY': iCalDays.MONDAY,
      'TUESDAY': iCalDays.TUESDAY,
      'WEDNESDAY': iCalDays.WEDNESDAY,
      'THURSDAY': iCalDays.THURSDAY,
      'FRIDAY': iCalDays.FRIDAY,
      'SATURDAY': iCalDays.SATURDAY,
  };

  private iCalToFull: { [day: string]: string } = {
    'SU': Days.SUNDAY,
    'MO': Days.MONDAY,
    'TU': Days.TUESDAY,
    'WE': Days.WEDNESDAY,
    'TH': Days.THURSDAY,
    'FR': Days.FRIDAY,
    'SA': Days.SATURDAY,
  };

  constructor(private http: HttpClient) { }

  public async getPlace(placeId: string): Promise<Place>  {
    return this.http.get<Place>(`/rest/places/${placeId}`).toPromise();
  }

  public async updatePlace(place: Place): Promise<Place> {
    return this.http.put<Place>(`/rest/places/${place.id}`, place).toPromise();
  }

  public async createPlace(place: Place): Promise<Place> {
    return this.http.post<Place>(`/rest/places`, place).toPromise();
  }

  public updatePlaceWithICalDays(placeId: string, data: Place): Promise<Place> {
    const payload = _.cloneDeep(data);
    payload.hours = this.fromICalDays(payload.hours);
    return this.http.put<Place>(`/rest/places/${placeId}`, payload).toPromise();
  }

  public getPlaces(params: HttpParams): Promise<Place[]> {
      return this.http.get<Place[]>('/rest/places', { params }).toPromise();
  }

  public getAllPlacesInAllVenues(): Promise<Place[]> {
      return this.http.get<Place[]>('/rest/places/list/allPlacesInAllVenues').toPromise();
  }

  public getPlaceWithICalDays(placeId: string): Promise<Place> {
      return this.http.get<Place>(`/rest/places/${placeId}`).toPromise().then((res) => {
          const details = res;
          details.hours = this.toICalDays(details.hours);

          // TODO Remove when api bug fixed
          const idSplit = details.id.split(':');
          if (idSplit.length <= 1) {
              details.id = `venue:${details.id}`;
          }

          return details;
      });
  }

  private toICalDays(hours: PlaceOpenAndCloseSchedule[]): PlaceOpenAndCloseSchedule[] {
      return _.forEach(hours, (hour) => {
        hour.days = _.map(hour.days, (day) => this.fullToICal[day.toString()] as iCalDays);
      });
  }

  private fromICalDays(hours: PlaceOpenAndCloseSchedule[]): PlaceOpenAndCloseSchedule[] {
      return _.forEach(hours, (hour) => {
        hour.days = _.map(hour.days, (day) => this.iCalToFull[day.toString()] as Days);
      });
  }
}
