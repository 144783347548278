<section class="cr-venue-hours-drop-down">
    <section class="header">
        <div class="label-area">
            <span class="label">
                {{ venueHours.label }}
            </span>
            <span class="icon-cr-edit edit-icon" (click)="'Test'"></span>
        </div>

        <div class="drop-down-button"></div>

        <div class="drop-down-button" (click)="this.isOpen = !this.isOpen">
            <div *ngIf="!this.isOpen">
                <span class="drop-down-label">
                    {{ this.text.show }}
                </span>
                <span class="icon-cr-chevron-down drop-down-icon-closed"></span>
            </div>
            <div *ngIf="this.isOpen">
                <span class="drop-down-label">
                    {{ this.text.hide }}
                </span>
                <span class="icon-cr-chevron-up drop-down-icon-open"></span>
            </div>
        </div>
    </section>
    <section class="content" *ngIf="this.isOpen">
        <div *ngFor="let day of this.days">
            <div class="content-row">
                <div class="day-of-week">
                    <span class="day">
                        {{ day }}
                        <span class="date">
                            {{ this.getDateByDay(day) | date: 'MMMM d' }}
                        </span>
                    </span>
                </div>
                <div class="opening-hours">
                    <span>
                        {{ this.getHoursByDay(day) }}
                    </span>
                </div>
            </div>
        </div>
    </section>
</section>
