import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CrFormsModule, SharedModule } from '../../shared';
import { FeaturedEventsComponent } from './components/featured-events/featured-events.component';
import { FeaturedEventsManageComponent } from './components/featured-events-manage/featured-events-manage.component';
import { CrContentModule } from '../../shared/content/content.module';

@NgModule({
  imports: [CommonModule, SharedModule, CrContentModule, CrFormsModule],
  declarations: [FeaturedEventsComponent, FeaturedEventsManageComponent],
  entryComponents: [FeaturedEventsComponent, FeaturedEventsManageComponent],
})
export class EventsModule {}
