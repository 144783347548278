import _ from 'lodash';

class CollapsiblePaneController {
    togglePane(pane) {
        pane.expanded = !pane.expanded;
    }

    onDropComplete(toIndex, $data, pane) {
        let matchIndex = _.findIndex(this.panes, $data);

        if (pane) {
            pane.children = pane.children || [];
            pane.expanded = true;
        }

        if (matchIndex !== -1) {
            if (matchIndex !== toIndex) {
                const offset = matchIndex < toIndex ? -1 : 0;

                this.panes.splice(toIndex + offset, 0, this.panes.splice(matchIndex, 1)[0]);
                this.onChange({ $event: this.panes });
            }
        } else {
            for (let i = 0; i < this.panes.length; i++) {
                matchIndex = _.findIndex(this.panes[i].children, $data);

                if (matchIndex !== -1) {
                    if (matchIndex !== toIndex) {
                        const offset = matchIndex < toIndex ? -1 : 0;

                        if (!pane) {
                            this.panes.splice(toIndex + offset, 0, this.panes[i].children.splice(matchIndex, 1)[0]);
                        } else {
                            const sameParent = pane === this.panes[i];
                            pane.children.splice(
                                toIndex + (sameParent ? offset : 0),
                                0,
                                this.panes[i].children.splice(matchIndex, 1)[0]
                            );
                        }

                        this.onChange({ $event: this.panes });
                    }

                    break;
                }
            }
        }
    }

    startDrag($data) {
        if($data && $data.index &&  $data.parent && $data.pane.isUnderSubCategory) {
            const categoryIndexList = [];
            let categoryStartIndex = 0, categoryEndIndex = 0;
            $data.parent.children.forEach((child, index) => child.isChildHeader ? categoryIndexList.push(index) : null);
            categoryIndexList.push($data.parent.children.length-1);
            categoryIndexList.forEach((catIndex,i) => {
                if(catIndex < $data.index) {
                    categoryStartIndex = catIndex;
                    categoryEndIndex = categoryIndexList[i + 1];
                }
            });
            $data.categoryStartIndex = categoryStartIndex;
            $data.categoryEndIndex = categoryEndIndex;
        }
        this.childDragging = $data && $data.parent;
        this.dragTarget = $data;
    }

    canDropTopParent($index) {
        return (
            !this.childDragging && this.dragTarget !== this.panes[$index] && this.dragTarget !== this.panes[$index - 1]
        );
    }

    canDropBottomParent() {
        return !this.childDragging && this.dragTarget !== this.panes[this.panes.length - 1];
    }

    canDropTopChild(pane) {
        if(pane.hasChildHeader) {
            return false;
        }
        return this.childDragging && this.dragTarget.pane !== pane.children[0];
    }

    canDropBottomChild(pane, $index) {
        if(pane.hasChildHeader) {
            return (
                this.childDragging &&
                this.dragTarget.pane !== pane.children[$index] &&
                this.dragTarget.pane !== pane.children[$index + 1] && 
                $index >= this.dragTarget.categoryStartIndex && $index < this.dragTarget.categoryEndIndex
            ); 
        }
        return (
            this.childDragging &&
            this.dragTarget.pane !== pane.children[$index] &&
            this.dragTarget.pane !== pane.children[$index + 1]
        );
    }
}

export default CollapsiblePaneController;
