import Controller from './poi-list.controller';
import template from './poi-list.html';
import './poi-list.less';

const Component = {
    template,
    controller: [
        '$state',
        'crErrorLoggingService',
        'crAnalyticsService',
        'crToastService',
        'crLocaleService',
        'crConfig',
        'crPoiListService',
        '$q',
        'crConstants',
        'crEntitySelectionManager',
        Controller,
    ],
};

export default Component;
