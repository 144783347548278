import controller from './create-kitchen-modal.controller';
import template from './create-kitchen-modal.html';
import './create-kitchen-modal.less';

const component = {
    template,
    bindings: {
        close: '&',
        modalInstance: '<',
    },
    controller: [
        '$state',
        '$timeout',
        'crCreateKitchenModalText',
        'crErrorLoggingService',
        'crAnalyticsService',
        'crToastService',
        'crLocaleService',
        'crConfig',
        'crPoiListService',
        '$q',
        'crConstants',
        'crEntitySelectionManager',
        'crCreateKitchenService',
        'crNavigationService',
        controller,
    ],
};

export default component;
