import ToastController from './toast.controller';
import template from './toast.html';
import './toast.less';

const ToastComponent = {
    template,
    controller: ['$scope', '$timeout', '$sce', 'crConstants', ToastController],
};

export default ToastComponent;
