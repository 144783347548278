import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { ConfigService } from './config.service';
import { CR_CONFIG } from './cr-config';

export function configInit(config: ConfigService) {
  return () => config.init();
}

export function configFactory(config: ConfigService) {
  return config.props;
}

export const configProviders = [
  ConfigService,
  {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: configInit,
    deps: [ConfigService],
  },
  {
    provide: CR_CONFIG,
    useFactory: configFactory,
    deps: [ConfigService],
  },
];

@NgModule({
  imports: [HttpClientModule],
})
export class CrConfigModule {
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: CrConfigModule,
      providers: configProviders,
    };
  }
}
