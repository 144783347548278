<section class="table-columns">
  <div *ngFor="let c of options.columns">
    <div
      [ngClass]="{ 'sub-col': c.sort === undefined }"
      class="column"
      [ngStyle]="{ 'width.px': c.width }"
    >
      {{ c.displayName }}
      <span
        *ngIf="c.sort !== undefined"
        (click)="sortColumn($event, c)"
        class="sortable-col"
        [ngClass]="
          c.sort ? 'icon-cr-sort-ascending' : 'icon-cr-sort-descending'
        "
      ></span>
    </div>
  </div>
</section>
<div
  class="table-row"
  *ngFor="let item of options.data; let i = index"
  (mouseenter)="item.hovered = true"
  (mouseleave)="item.hovered = false"
>
  <div *ngFor="let c of options.columns">
    <div [ngStyle]="{ 'width.px': c.width }">
      <!-- basic column -->
      <div
        *ngIf="!c.customColumnOnClick && !c.images && !c.chips"
        [ngStyle]="c.customRowStyles"
        class="table-row-content"
        tooltip="{{ item[c.key] }}"
      >
        {{ item[c.key] }}
      </div>
      <!-- onClick Column -->
      <div
        *ngIf="c.customColumnOnClick && !c.images && !c.chips"
        [ngStyle]="c.customRowStyles"
        (click)="onColumnItemClick(item, c)"
        class="table-row-content"
        tooltip="{{ item[c.key] }}"
      >
        <a [ngStyle]="c.customRowStyles">
          {{ item[c.key] }}
        </a>
      </div>
      <!-- Image column -->
      <div
        *ngIf="c.images"
        [ngStyle]="c.customRowStyles"
        class="table-row-content"
      >
        <cr-img-holder type="sticker" size="icon" [roundedCorners]="true" [images]="item[c.key]"></cr-img-holder>
      </div>
      <div *ngIf="c.chips" [ngStyle]="c.customRowStyles">
        <cr-chip-column
          [index]="i"
          [values]="item[c.key]"
          [columnWidth]="c.width"
        ></cr-chip-column>
      </div>
    </div>
  </div>
  <div
    *ngIf="options.hoverOptions && item.hovered"
    class="on-row-hover table-row-content"
  >
    <div
      *ngFor="let ho of options.hoverOptions"
      tooltip="{{ ho.actionString }}"
    >
      <div
        [ngClass]="ho.icon"
        class="hover-icon"
        (click)="onHoverIconClick(ho.actionString, item)"
      ></div>
    </div>
  </div>
</div>

<div *ngIf="options?.data?.length === 0" class="empty-list">
  {{ options.emptyStateText }}
</div>
