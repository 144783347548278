import {
  Component, Input, OnChanges,
} from '@angular/core';

@Component({
  selector: 'cr-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss'],
})
export class ChipComponent implements OnChanges {
  @Input() label: string;

  @Input() bgColor: string;

  @Input() labelColor: string;

  @Input() type: 'state';

  customBorder;

  public ngOnChanges(changes):void {
    if (this.type) {
      this.determineType();
    }
  }

  private determineType(): void {
    if (this.type === 'state') {
      this.handleStateLabel();
    }
  }

  /**
   * when the type is state, change the styling of the chip
   * based on the types and the label
   */
  private handleStateLabel(): void {
    switch (this.label) {
      case 'ACTIVE':
        this.label = this.label.toLowerCase();
        this.bgColor = ' #43a047';
        this.labelColor = 'white';
        this.customBorder = 'none';
        break;

      case 'INACTIVE':
        this.label = this.label.toLowerCase();
        this.bgColor = '#afafaf';
        this.labelColor = 'black';
        this.customBorder = 'none';
        break;

      case 'ARCHIVED':
        this.label = this.label.toLowerCase();
        this.bgColor = 'white';
        this.labelColor = '#afafaf';
        this.customBorder = '1px solid #afafaf';
        break;

      default:
    }
  }
}
