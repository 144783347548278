import controller from './additional-field.controller';
import template from './additional-field.html';

import './additional-field.less';

const component = {
    template,
    bindings: {
        fieldData: '<',
        model: '<',
        onUpdate: '&',
        pois: '<?',
        poiCategories: '<?',
        menuItems: '<?',
        menuCategories: '<?',
        geofences: '<?',
        hideDivider: '<?',
        hideValidation: '<?',
        updateOutOfVenueLocationOptions : '=',
        updateAppOptions: '&?'
    },
    controller: ['$state', '$filter', 'crConstants', 'crConfig', controller],
};

export default component;
