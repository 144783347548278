import {
  Directive, ElementRef, EventEmitter, Injector, Input, Output
} from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
  
  @Directive({
    selector: 'cr-kitchen-manage',
  })
  export class KitchenManageComponent extends UpgradeComponent {
  
    @Input() kitchenid?: string;
    @Input() edit?: boolean;

    @Output() cancel: EventEmitter<any>;
    @Output() saveEvent: EventEmitter<any>;

    constructor(elementRef: ElementRef, injector: Injector) {
      super('crKitchenManage', elementRef, injector);
    }
  }
  