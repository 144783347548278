import angular from 'angular';

import component from './modifier-groups-list.component';
import service from './modifier-groups-list.service';

const ModifierGroupsList = angular
    .module('control-room.menus.components.modifier-groups-list', [])
    .component('crModifierGroupsList', component)
    .service('crModifierGroupsListService', ['crEntityService', 'crConstants', service]);

export default ModifierGroupsList;
