import angular from 'angular';

import PositionStickyDirective from './position-sticky.directive';
import './position-sticky.less';

const PositionStickyModule = angular
    .module('control-room.commons.layout.directives.position-sticky', [])
    .directive('crPositionSticky', PositionStickyDirective.directiveFactory);

export default PositionStickyModule;
