import _ from 'lodash';
import moment from 'moment';
import text from './resources/locale/en.json';

class EventScheduleController {
    constructor($scope, $timeout, crConstants, crScheduleService) {
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.crConstants = crConstants;
        this.crScheduleService = crScheduleService;
    }

    $onInit() {
        this.text = text;
        this.NAME_START_DATE = 'experience-startDate';
        this.NAME_START_TIME = 'experience-startTime';
        this.NAME_END_TIME = 'experience-endTime';
        this.NAME_UNTIL_DATE = 'experience-untilDate';
        this.NAME_WEEKDAYS = 'experience-weekdays';

        this.VALIDATION_END_TIME = 'end-time';
        this.VALIDATION_UNTIL_DATE = 'end-date';

        this.pushed = {};
        this.scheduleKey = this.crConstants.calendarService.scheduleKey;

        this.setInitialStates(this.experience);

        this.dayOrder = {};
        this.crConstants.schedule.defaultExperienceWeekdays.forEach((day, index) => {
            this.dayOrder[day] = index;
        });

        this.activeText = this.showEndDate ? this.text.activeFrom : this.text.activeOn;
        this.sendsText = this.showEndTime ? this.text.sendsDaily : this.text.sendsAt;

        this.allDayCheckOptions = [{ label: this.text.allDay, name: 'allDay', checked: this.allDay }];
        this.repeatCheckOptions = [
            {
                label: this.text.specificWeekdays,
                name: 'repeat',
                checked: this.recurrence.byDay.length < 7 || this.disableWeekdayToggle,
            },
        ];
    }

    setInitialStates(data) {
        const schedule = data.trigger.active.schedules[0];
        const instance = schedule.instances[0];
        const { startTime } = instance;
        const { endTime } = instance;

        this.allDay =
            schedule.scheduleKey && schedule.scheduleKey.toUpperCase() === this.scheduleKey.FULLDAY.toUpperCase();

        this.showEndTime =
            this.experienceType.active.type !== 'ONCE' && this.experienceType.active.type !== 'RECURRING_INSTANT';
        this.showEndDate = this.experienceType.active.type !== 'ONCE';
        this.showWeekdays = this.experienceType.active.type !== 'ONCE';
        this.disableWeekdayToggle = this.experienceType.active.type === 'RECURRING_INSTANT';

        this.setInitialData(schedule, startTime, endTime);
    }

    setInitialData(data, startTime, endTime) {
        // Handle recurrence rule
        if (data.recurrence) {
            this.recurrence = this.crScheduleService.getRecurrenceObject(data.recurrence);
            this.repeat = this.recurrence.byDay.length < 7 || this.disableWeekdayToggle;
        } else {
            this.recurrence = {
                freq: 'weekly',
                byDay: _.cloneDeep(this.crConstants.schedule.defaultExperienceWeekdays),
            };
            this.repeat = false;
        }

        if (data.recurrenceException) {
            this.exceptions = data.recurrenceException.split(',').sort();
            this.displayExceptions = this.exceptions.filter((exception) => !this.crScheduleService.isPast(exception));
        }

        if (startTime) {
            this.startDate = startTime;
            if (this.allDay) {
                this.pushed.startTime = this.crScheduleService.getTimeFromIsoString(startTime);
            } else {
                this.startTime = this.crScheduleService.getTimeFromIsoString(startTime);
            }
        }

        if (endTime) {
            this.endDate = endTime;
            if (this.allDay) {
                this.pushed.endTime = this.crScheduleService.getTimeFromIsoString(endTime);
            } else {
                this.endTime = this.crScheduleService.getTimeFromIsoString(endTime);
            }
        }

        this.validWeekdays = this.getValidWeekdays();
    }

    // Date and time management

    onStartDateUpdate(event) {
        this.startDate = event.model;
        this.updateDateTime('startTime', this.startDate, this.startTime);

        this.updateEndDate();
        this.updateDateTime('endTime', this.endDate, this.endTime);
        this.update();
        this.validWeekdays = this.getValidWeekdays();
        this.form.$setDirty();
    }

    onStartTimeUpdate(event) {
        this.startTime = event.model;
        this.updateDateTime('startTime', this.startDate, this.startTime);

        this.updateEndDate();
        if (!this.showEndTime) {
            this.endTime = this.crScheduleService.addSecond(this.startTime);
        }
        this.updateDateTime('endTime', this.endDate, this.endTime);
        this.update();
        this.form.$setDirty();
    }

    onEndTimeUpdate(event) {
        this.endTime = event.model;

        this.updateEndDate();
        this.updateDateTime('endTime', this.endDate, this.endTime);
        this.update();
        this.form.$setDirty();
    }

    onUntilDateUpdate(event) {
        this.recurrence.until = event.model;

        this.validateUntilDate();
        this.update();
        this.validWeekdays = this.getValidWeekdays();
        this.form.$setDirty();
    }

    updateEndDate() {
        if (this.startDate) {
            this.endDate = this.startDate;

            if (!this.allDay) {
                const endFirst = this.crScheduleService.endTimeIsSameOrBeforeStartTime(this.startTime, this.endTime);
                if (endFirst && this.showEndTime) {
                    this.endDate = this.crScheduleService.getBeginningOfNextDay(this.endDate);
                }
            } else {
                this.endDate = this.crScheduleService.getBeginningOfNextDay(this.endDate);
            }

            this.validateUntilDate();
        }
    }

    updateDateTime(prop, date, time) {
        // When all day, do not use the custom time entered
        time = this.allDay ? null : time;

        // Start time for allDay should be midnight
        if (this.allDay && prop === 'startTime') {
            time = '00:00:00';
        }

        const dateTime = this.crScheduleService.getCombinedDateTimeIsoString(date, time);
        this.experience.trigger.active.schedules[0].instances[0][prop] = dateTime;
    }

    updateDateTimes() {
        this.updateDateTime('startTime', this.startDate, this.startTime);
        this.updateEndDate();
        this.updateDateTime('endTime', this.endDate, this.endTime);
    }

    addAllDayEndDate(date) {
        if (date) {
            const endDate = this.crScheduleService.getBeginningOfNextDay(date);
            this.experience.trigger.active.schedules[0].instances[0].endTime = endDate;
        }
    }

    // State management

    manageState() {
        if (!this.repeat) {
            // Reset recurrence days when not in repeat state
            this.recurrence.byDay = _.cloneDeep(this.crConstants.schedule.defaultExperienceWeekdays);
        }

        if (this.allDay) {
            this.pushTimes();
        } else {
            this.popTimes();
        }

        this.updateDateTimes();
        this.update();
    }

    pushTimes() {
        this.pushed.startTime = this.startTime;
        this.pushed.endTime = this.endTime;

        this.startTime = null;
        this.endTime = null;
    }

    popTimes() {
        this.startTime = this.pushed.startTime;
        this.endTime = this.pushed.endTime;

        this.pushed.startTime = null;
        this.pushed.endTime = null;
    }

    // Component update callbacks

    onCheckChange(event) {
        const checkName = _.keys(event.model)[0];
        this[checkName] = event.model[checkName];
        this.manageState();
        this.validateUntilDate();
        this.form[this.NAME_START_DATE].$setTouched();
        this.form.$setDirty();
    }

    onWeekdaySelect(event) {
        const days = _.cloneDeep(event.model);
        this.recurrence.byDay = days.sort((a, b) => this.dayOrder[a] - this.dayOrder[b]);
        this.update();
        this.form.$setDirty();
    }

    onValidWeekdayChange(event) {
        const days = _.cloneDeep(event.model);
        this.recurrence.byDay = days.sort((a, b) => this.dayOrder[a] - this.dayOrder[b]);
        this.update();
    }

    getValidWeekdays() {
        const schedule = this.experience.trigger.active.schedules[0];
        if (schedule.instances[0].startTime && this.recurrence.until) {
            const startTime = moment(schedule.instances[0].startTime);
            const until = moment(this.recurrence.until);
            const weekdays = this.crConstants.schedule.defaultExperienceWeekdays;
            const nextWeek = startTime.add(1, 'weeks');

            if (until.isSameOrAfter(nextWeek)) {
                return _.cloneDeep(weekdays);
            }

            const validWeekdays = [];

            let startIndex = startTime.isoWeekday();
            if (startIndex === 7) {
                startIndex = 0;
            }

            let endIndex = until.isoWeekday();
            if (endIndex === 7) {
                endIndex = 0;
            }

            if (startIndex > endIndex) {
                validWeekdays.push(...weekdays.slice(0, endIndex + 1));
                validWeekdays.push(...weekdays.slice(startIndex));
            } else {
                validWeekdays.push(...weekdays.slice(startIndex, endIndex + 1));
            }

            return validWeekdays;
        }

        return this.crConstants.schedule.defaultExperienceWeekdays.slice();
    }

    validateUntilDate() {
        let validUntilDate = true;

        if (this.endDate && this.recurrence.until) {
            validUntilDate = this.crScheduleService.isSameOrAfter(this.startDate, this.recurrence.until);
            this.setValidity(this.NAME_UNTIL_DATE, this.VALIDATION_UNTIL_DATE, validUntilDate);
        }

        this.setValidity(this.NAME_UNTIL_DATE, this.VALIDATION_UNTIL_DATE, validUntilDate);
    }

    update() {
        const schedule = this.experience.trigger.active.schedules[0];
        if (this.showWeekdays) {
            schedule.recurrence = this.crScheduleService.getRecurrenceString(this.recurrence);
        }

        if (this.allDay) {
            schedule.scheduleKey = this.crScheduleService.addScheduleKey(
                schedule.scheduleKey,
                this.scheduleKey.FULLDAY
            );
        } else if (schedule.scheduleKey) {
            schedule.scheduleKey = this.crScheduleService.deleteScheduleKey(
                schedule.scheduleKey,
                this.scheduleKey.FULLDAY
            );
        }
    }

    setValidity(formName, validationId, valid) {
        this.$timeout(() => {
            const formElem = this.form[formName];

            if (formElem) {
                formElem.$setValidity(validationId, valid);
                formElem.$setTouched();
            }
        });
    }

    formatException(dateTime) {
        const year = dateTime.substring(0, 4); // YYYY
        const month = dateTime.substring(4, 6); // MM
        const day = dateTime.substring(6, 8); // DD

        return `${month}/${day}/${year}`;
    }

    removeException(index) {
        const [exception] = this.displayExceptions.splice(index, 1);
        const i = this.exceptions.indexOf(exception);
        this.exceptions.splice(i, 1);
        this.updateExceptions();
    }

    updateExceptions() {
        const exceptions = this.exceptions.join(',');
        this.experience.trigger.active.schedules[0].recurrenceException = exceptions;
        this.form.$setDirty();
    }
}

export default EventScheduleController;
