import template from './inline-check.html';
import './inline-check.less';
import CheckController from '../check/check.controller';

const InlineCheckComponent = {
    template,
    bindings: {
        disabled: '@',
        options: '<',
        onChange: '&',
    },
    controller: CheckController,
    controllerAs: 'inlineCheckCtrl',
};

export default InlineCheckComponent;
