import _ from 'lodash';
import text from './resources/locale/en.json';

class ManageAttributeController {
    constructor(
        $q,
        $state,
        $uibModal,
        crLocaleService,
        crEntityService,
        crNavigationService,
        crErrorLoggingService,
        crImageService,
        crConstants,
        crConfig,
        crAnalyticsService
    ) {
        this.$q = $q;
        this.$state = $state;
        this.$uibModal = $uibModal;
        this.crLocaleService = crLocaleService;
        this.crEntityService = crEntityService;
        this.crNavigationService = crNavigationService;
        this.crErrorLoggingService = crErrorLoggingService;
        this.crImageService = crImageService;
        this.crConstants = crConstants;
        this.crConfig = crConfig;
        this.crAnalyticsService = crAnalyticsService;
    }

    $onInit() {
        this.form = {};
        this.text = text;
        this.isLoading = true;
        this.hasDataError = false;
        this.venueId = this.$state.params.venueId;

        this.phoneData = {};

        this.attribute = {
            owner: {
                id: this.venueId,
                type: 'VENUE',
            },
            localization: {},
            type: this.crConstants.attributeTypes.MULTI_SELECT_ENUM,
        };

        this.edit = !!this.$state.params.id;

        this.locale = this.crLocaleService.getLocale(this.venueId, this.$state.params.locale, !this.edit);

        // Set initial languages when creating
        if (!this.edit) {
            this.attribute.localization = this.crLocaleService.getLocaleObject(this.locale.list);
        }

        this.imageConstants = this.crImageService.constants.image;

        this.setText();

        if (this.edit) {
            this.initEdit();
        } else {
            this.getIcons();
            this.isLoading = false;
        }

        this.crNavigationService.enableConfirmNavigation(() => this.form.$dirty);

        this.initDropdown();
    }

    initEdit() {
        this.title = `${this.text.edit} ${this.text.attribute}`;

        this.$q
            .all([this.getIcons(), this.getAttribute(this.entityType, this.$state.params.id)])
            .then(this.setIcon.bind(this));
    }

    localizeEnumValues(attribute) {
        const enumValueLocaleMap = {};

        // Create a map of localized enum values to minimize looping
        _.forOwn(attribute.localization, (value, key) => {
            enumValueLocaleMap[key] = _.keyBy(value.enumValues, 'id');
            value.enumValues = [];
        });

        // Loop through the attribute's enumValues and set a localization property
        attribute.enumValues.forEach((enumValue) => {
            enumValue.localization = {};

            _.forOwn(enumValueLocaleMap, (value, key) => {
                enumValue.localization[key] = enumValueLocaleMap[key][enumValue.id];
            });

            // Set label to label for current locale
            const currentLocalization = enumValue.localization[this.locale.current];
            enumValue.label = currentLocalization ? currentLocalization.label : '';
        });

        return attribute;
    }

    unLocalizeEnumValues(attribute) {
        attribute.enumValues.forEach((enumValue) => {
            // Set label back to default
            enumValue.label = enumValue.localization[this.locale.default].label;

            // Update attribute.localization enumValues
            _.forOwn(enumValue.localization, (value, key) => {
                if (!attribute.localization[key].enumValues) {
                    attribute.localization[key].enumValues = [];
                }

                attribute.localization[key].enumValues.push(enumValue.localization[key]);
            });

            // Remove extra localization properties on enumValues
            delete enumValue.localization;
        });

        return attribute;
    }

    initMissingLocales(attribute) {
        _.forEach(attribute.localization, (locale) => {
            if (!locale.enumValues) {
                locale.enumValues = [];
            }
            attribute.enumValues.forEach((value) => {
                const existingValue = locale.enumValues.find((localeValue) => localeValue.id === value.id);
                if (!existingValue) {
                    locale.enumValues.push({ id: value.id, label: '' });
                }
            });
        });
    }

    getAttribute(entityType, id) {
        return this.crEntityService
            .getEntity(entityType, id, this.venueId, this.locale.default)
            .then((attribute) => {
                this.initMissingLocales(attribute);
                this.attribute = this.localizeEnumValues(attribute);
                this.origName = this.attribute.name;
                this.locale.list = this.crLocaleService.updateLocaleListStatus(
                    this.attribute.missingTranslations,
                    this.locale
                );
                this.updatePhoneData(this.attribute);
            })
            .catch((err) => {
                this.crErrorLoggingService.logError('Could not get attribute', err, `id: ${id}`);

                if (err.status === 404) {
                    this.$state.go('client.404', {
                        customerId: this.$state.params.customerId,
                        venueId: this.venueId,
                    });
                } else {
                    this.hasDataError = true;
                    this.$uibModal
                        .open({
                            backdrop: 'static',
                            component: 'crSimpleModal',
                            windowClass: 'cr-modal-size-sm',
                            resolve: {
                                message: () => this.text.standardErrorMessage,
                            },
                        })
                        .result.catch(() => {
                            const routeRoot =
                                entityType === this.crConstants.entity.types.EVENT_ATTRIBUTES ? 'events' : 'poi';
                            this.$state.go(`${routeRoot}.attributes-list`, {
                                customerId: this.$state.params.customerId,
                                venueId: this.venueId,
                            });
                        });
                }
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    isDefaultLocale() {
        return this.locale.current === this.locale.default;
    }

    save() {
        this.form.$setSubmitted();
        if (this.form.$valid) {
            if (this.form.$dirty) {
                const attribute = this.unLocalizeEnumValues(_.cloneDeep(this.attribute));

                if (this.edit) {
                    this.editAttribute(this.entityType, attribute);
                } else {
                    this.createAttribute(this.entityType, attribute);
                }
            } else {
                this.goToListPage();
            }
        }
    }

    createAttribute(entityType, attribute) {
        this.isLoading = true;
        return this.crEntityService
            .createEntity(entityType, attribute, this.venueId)
            .then(() => {
                this.crAnalyticsService.track(`${entityType} added successfully`, { attribute });
                this.goToListPage(`"${this.origName || attribute.name}" ${this.text.hasBeenCreated}`);
            })
            .catch((err) => {
                this.showErrorModal();
                this.crErrorLoggingService.logError('Could not create attribute', err, attribute);
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    editAttribute(entityType, attribute) {
        this.isLoading = true;
        return this.crEntityService
            .updateEntity(entityType, attribute.id, attribute, this.venueId)
            .then(() => {
                this.crAnalyticsService.track(`${entityType} updated successfully`, { attribute });
                this.goToListPage(`"${this.origName || attribute.name}" ${this.text.hasBeenUpdated}`);
            })
            .catch((err) => {
                this.showErrorModal();
                this.crErrorLoggingService.logError('Could not edit attribute', err, attribute);
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    deleteAttribute(entityType, attribute) {
        this.$uibModal
            .open({
                backdrop: 'static',
                component: 'crSimpleModal',
                windowClass: 'cr-modal-size-sm',
                resolve: {
                    type: () => 'submit',
                    submitText: () => this.text.delete,
                    message: () => this.text.deleteConfirmationMessage,
                },
            })
            .result.then(() =>
                this.crEntityService
                    .deleteEntity(entityType, attribute.id)
                    .then(() => {
                        this.goToListPage(`"${this.origName || attribute.name}" ${this.text.hasBeenDeleted}`);
                    })
                    .catch((err) => {
                        this.showErrorModal();
                        this.crErrorLoggingService.logError('Could not delete attribute', err, attribute);
                    })
            )
            .catch(() => null);
    }

    getIcons() {
        return this.crImageService
            .getIcons(this.imageConstants.folderNames[this.entityType])
            .then((icons) => {
                this.icons = icons.map((icon) => ({
                    id: icon.src,
                    iconSrc: this.crImageService.getIconSrc(icon.src),
                }));
            })
            .catch((err) => {
                this.crErrorLoggingService.logError('Could not get icons', err);
            });
    }

    setIcon() {
        if (this.attribute.images && this.icons) {
            const icon = _.find(this.attribute.images, { type: this.imageConstants.types.ICON });

            if (icon) {
                const currentIcon = _.find(this.icons, { id: icon.src });

                if (currentIcon) {
                    currentIcon.isSelected = true;
                }
            }
        }
    }

    goToListPage(msg) {
        this.crNavigationService.disableConfirmNavigation();
        this.crNavigationService.goBack(`client.${this.attributeType}.attributes-list`, {
            toast: {
                msg,
            },
        });
    }

    showErrorModal() {
        this.$uibModal
            .open({
                backdrop: 'static',
                component: 'crSimpleModal',
                windowClass: 'cr-modal-size-sm',
                resolve: {
                    message: () => this.text.errorMessage,
                },
            })
            .result.catch(() => null);
    }

    setText() {
        this.title = `${this.text.add} ${this.text.attribute}`;
    }

    onLocaleSelect(event) {
        this.locale.current = event.model[0].id;
        this.crAnalyticsService.track('Language Selected', { language: event.model[0].label });

        // Update the labels of the enumValues based on the selected locale
        _.forEach(this.attribute.enumValues, (enumValue) => {
            const localeObj = enumValue.localization[this.locale.current];
            enumValue.label = localeObj ? localeObj.label : '';
        });

        this.updatePhoneData(this.attribute);
    }

    updateLocaleStatus() {
        const missingLabels = this.crLocaleService.getMissingPropertyTranslations(
            this.attribute.localization,
            this.locale
        );
        const missingEnumLabels = this.crLocaleService.getMissingEnumPropertyTranslations(
            this.attribute.enumValues,
            this.locale
        );

        // Combine returned missing translations lists
        const missingTranslations = missingLabels.concat(missingEnumLabels);

        this.locale.list = this.crLocaleService.updateLocaleListStatus(missingTranslations, this.locale);
        this.locale = _.cloneDeep(this.locale);
    }

    updateName(event) {
        this.attribute.name = event.model;
        this.updatePhoneData(this.attribute);
    }

    updateLabel(event) {
        if (this.isDefaultLocale()) {
            this.attribute.label = event.model;
        }

        this.attribute.localization[this.locale.current].label = event.model;
        this.updateLocaleStatus();
        this.updatePhoneData(this.attribute);
    }

    updateEnumValues(event) {
        this.attribute.enumValues = event.model;

        _.forEach(this.attribute.enumValues, (enumValue) => {
            // Handle new row added
            if (!enumValue.localization) {
                enumValue.localization = this.crLocaleService.getLocaleObject(this.locale.list);
                _.forOwn(enumValue.localization, (value) => {
                    value.id = enumValue.id;
                    value.label = '';
                });
            }

            // Update the localization enumValue labels
            const localeObj = enumValue.localization[this.locale.current];
            localeObj.label = enumValue.label || '';
        });

        this.updateLocaleStatus();
        this.updatePhoneData(this.attribute);
    }

    updateIcon(event) {
        this.attribute.images = this.crImageService.updateIconAndGetImagesModel(
            this.attribute.images,
            event.model[0].id
        );
        this.updatePhoneData(this.attribute);
    }

    getLabelPlaceholder() {
        let txt = '';

        if (!this.isDefaultLocale()) {
            txt = `${this.attribute.localization[this.locale.default].label} (${this.locale.default})`;
        }

        return txt;
    }

    goBack() {
        this.crAnalyticsService.track(`${this.edit ? 'Edit' : 'Add'} ${this.entityType} canceled`);
        this.crNavigationService.goBack(`client.${this.attributeType}.attributes-list`);
    }

    initDropdown() {
        this.showDropdown =
            this.crConfig.customer.localizationEnabled && this.locale.list && this.locale.list.length > 1;
    }

    updatePhoneData(data) {
        this.phoneData = {
            images: data.images,
            label: data.localization[this.locale.current].label || data.localization[this.locale.default].label,
            enumValues: (data.enumValues || []).map((val) => {
                const currentModel = val.localization[this.locale.current];
                return currentModel && currentModel.label ? currentModel : val.localization[this.locale.default];
            }),
        };
    }
}

export default ManageAttributeController;
