import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FeaturedEventsPhonePreviewComponent } from './featured-events-phone-preview/featured-events-phone-preview.component';
import { DiscountContentPhonePreviewComponent } from './discount-content-phone-preview/discount-content-phone-preview.component';
import { IdDetailContentPhonePreviewComponent } from './id-detail-content-phone-preview/id-detail-content-phone-preview.component';
import { PhoneComponent } from './phone.component';

@NgModule({
  imports: [CommonModule],
  exports: [FeaturedEventsPhonePreviewComponent, DiscountContentPhonePreviewComponent, IdDetailContentPhonePreviewComponent, PhoneComponent],
  declarations: [FeaturedEventsPhonePreviewComponent, DiscountContentPhonePreviewComponent, IdDetailContentPhonePreviewComponent, PhoneComponent],
  providers: [],
})
export class CrPhoneModule {}
