import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BsModalService, ButtonsModule } from 'ngx-bootstrap';
import { QuillModule } from 'ngx-quill';
import { CrFormsModule, SharedModule, VenueService } from '../../../../shared';
import { FeesService } from '../../../../shared/services/fees/fees.service';
import { PlacesService } from '../../../../shared/services/places/places.service';
import { CrSplitBtnModule } from '../../../../shared/split-button/split-button.module';
import { CrTitleBarModule } from '../../../../shared/title-bar/title-bar.module';
import { IdDetailContentManageModule } from '../id-detail-content-manage/id-detail-content-manage.module';
import { IdDetailContentComponent } from './id-detail-content.component';


@NgModule({
  imports: [ReactiveFormsModule, SharedModule, BrowserModule, CrTitleBarModule, CrFormsModule, ButtonsModule, CrSplitBtnModule, QuillModule, IdDetailContentManageModule],
  exports: [IdDetailContentComponent],
  declarations: [IdDetailContentComponent],
  providers: [FeesService, BsModalService, VenueService, PlacesService],
  entryComponents: [IdDetailContentComponent], 
})
export class IdDetailContentModule {}
