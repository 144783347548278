import { Directive, ElementRef, Input, OnInit } from "@angular/core";

@Directive({
    selector: "[crPositionSticky]",
})
export class PositionStickyDirective implements OnInit {
    @Input() crPositionStickyIndex = 0;

    constructor(private elementRef: ElementRef) {} 

    ngOnInit(){
        this.elementRef.nativeElement.style.zIndex = this.crPositionStickyIndex;
        if (this.crPositionStickyIndex !== 0) {
            this.elementRef.nativeElement.style.position = "sticky";
        } else {
            this.elementRef.nativeElement.style.position = "relative";
        }
    }
}