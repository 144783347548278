import { noop } from 'angular';

export default function ($transitions, $state, crUserService, crPolicyService) {
    // this sucks and we need to replace it as soon as we refactor tabs/dropdown routing
    $state._defaultErrorHandler = (error) => {
        if (error.type === 2) {
            // 2 = transition superseded
            noop(error);
        } else {
            console.error(error);
        }
    };

    $transitions.onBefore(
        { to: 'client.**' },
        () =>
            new Promise((resolve, reject) => {
                crUserService
                    .isLoggedIn()
                    .then(() => resolve())
                    .catch(() => reject('notLoggedIn'));
            }),
        { priority: 1 }
    );

    $transitions.onEnter(
        { to: 'client.**' },
        (transition) =>
            new Promise((resolve, reject) => {
                const stateParams = transition.injector().get('$stateParams');
                const permissions = transition.$to().data ? transition.$to().data.permissions : [];

                let promiseChain = Promise.resolve();
                if (stateParams.venueId) {
                    const venueObject = `venue/${stateParams.venueId}`;
                    promiseChain = crPolicyService.hasAccess('access:venue', venueObject).then((access) => {
                        if (!access) {
                            reject('accessDenied');
                        }
                    });
                }

                if (permissions) {
                    promiseChain = promiseChain.then(() =>
                        Promise.all(
                            permissions.map((policy) => crPolicyService.hasAccess(policy.action, policy.object))
                        )
                            .then((access) => access.reduce((a, b) => a && b, true))
                            .then((allow) => {
                                if (!allow) {
                                    reject('accessDenied');
                                }
                            })
                    );
                }

                promiseChain.then(() => resolve());
            })
    );

    $transitions.onBefore(
        { to: 'uac.**' },
        () =>
            new Promise((resolve, reject) => {
                crUserService
                    .isLoggedIn()
                    .then(() => reject('alreadyLoggedIn'))
                    .catch(() => resolve());
            }),
        { priority: 1 }
    );

    $transitions.onError({}, (transition) => {
        const { detail } = transition.error();
        if (detail === 'notLoggedIn') {
            const params = {
                to: transition.params().path,
            };
            $state.go('uac.login', params);
        } else if (detail === 'alreadyLoggedIn') {
            $state.go('client');
        } else if (detail === 'accessDenied') {
            $state.go('client.access-denied');
        }
    });
}
