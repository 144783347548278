import { Component, OnInit, ViewChild } from "@angular/core";
import { EventTabsService } from "../../../../shared/services/event-tabs/event-tabs.service";
import { EventTabs, EventTabsType } from "../../../../shared/services/event-tabs/event-tabs.model";
import text from './resources/locale/en.json';
import { LocalizedText } from '../../../../core';
import {
    AbstractControl,
    FormArray, FormControl, FormGroup, NgForm, Validators,
  } from '@angular/forms';
import { ErrorLoggingService, NavigationService } from "../../../../shared";
import { StateService } from "@uirouter/core";

@Component({
    selector: 'cr-event-tabs-manage',
    templateUrl: './event-tabs-manage.component.html',
    styleUrls: ['./event-tabs-manage.component.scss']
})

export class EventTabsManageComponent implements OnInit {

    @ViewChild('tabsViewChild', { static: false }) tabsViewChild: NgForm;
    
    eventTabsData: EventTabs[] = [];

    text: LocalizedText;

    title = '';

    eventTabsForm: FormGroup;

    isLoading = false;

    isSaving = false;

    hasDataError = false;

    showStandardMessage = false;

    constructor(private eventTabsService: EventTabsService,
        private logger: ErrorLoggingService,
        private state: StateService,
        private navigationService: NavigationService) { 
        this.text = text as LocalizedText;
    }

    ngOnInit() {
        this.getEventTabs();
        this.title = this.text.editItem;
        this.eventTabsForm = new FormGroup({
            eventTabs: new FormArray([]),
        })
        
    }

    async getEventTabs() {
        this.isLoading = true;
        try {
          const res: EventTabs[] = await this.eventTabsService.getEventTabs();
          this.eventTabsData = res;
          this.setFormValues(this.eventTabsData);
        } catch (err) {
          this.hasDataError = true;
          this.logger.logError(err);
        } finally {
          this.isLoading = false;
        }
      }

    getReorderItemFormGroup(item?: EventTabs): FormGroup {
        const isReadonly = item && (item.name === 'Today' || item.name === 'Calendar');
        return new FormGroup({
            name: new FormControl({ value: item ? item.name : '', disabled: isReadonly }, Validators.required),
        });
    }

    setFormValues(eventTabs: EventTabs[]) {
        this.eventTabsData.forEach((eventTab) => {
            this.mapEventTabItemToForm(eventTab);
        });
    }

    mapEventTabItemToForm(eventTabItem: EventTabs) {
        const item: EventTabs = {
            name: eventTabItem.name,
            displayOrder: eventTabItem.displayOrder,
            owner: {
                id: eventTabItem.owner.id,
                type: eventTabItem.owner.type,
            },

        }
        this.eventTabsReorder.push(this.getReorderItemFormGroup(item));
    }

    get eventTabsReorder(): FormArray {
        return (<FormArray> this.eventTabsForm.get('eventTabs'));
    }

    onEventsTabItemAdd(): void {
       const newItem: EventTabs = {
          name: '',
          displayOrder: this.eventTabsReorder.length + 1,
          owner: {
            id: this.state.params.venueId , 
            type: EventTabsType.VENUE,
          },
        };
      
        const formGroup = this.getReorderItemFormGroup(newItem);
        this.eventTabsReorder.push(formGroup);
    }

    onEventsTabItemRemove(index: number): void {
        this.eventTabsReorder.controls.splice(index, 1);
        this.eventTabsForm.markAsDirty();
    }

    onEventsTabItemReorder(event: any): void {
        const { previousIndex, currentIndex } = event;
        const toBeReordered = this.eventTabsReorder.controls[previousIndex];
        this.eventTabsReorder.controls.splice(previousIndex, 1);
        this.eventTabsReorder.controls.splice(currentIndex, 0, toBeReordered);
        this.eventTabsForm.markAsDirty();
    }

    goBack() {
        this.navigationService.goBack('client.events.tab-navigation');
    }

    onSave() {
        this.tabsViewChild.onSubmit(undefined);
    }

    onSubmit() {
        if (this.eventTabsForm.valid && this.eventTabsForm.dirty) {
          this.isSaving = true;
          const venueId = this.state.params.venueId; 
      
          const eventTabsData = this.eventTabsReorder.controls.map((eventTab, index) => {
            return {
              ...eventTab.value,
              displayOrder: index + 1,
              owner: {
                id: venueId, 
                type: 'VENUE',
              },
            };
          });
      
          this.eventTabsService.saveEventTabs(eventTabsData)
            .then((res: EventTabs[]) => {
              this.isSaving = false;
              this.goBack();
            })
            .catch((err) => {
              this.hasDataError = true;
              this.isSaving = false;
              this.logger.logError(err);
            });
      
        }
      }
      
}
    