import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import moment from 'moment';
import { PTWCapacity } from '../../../../shared/services/ptw/ptw.model';

@Component({
  selector: 'cr-ptw-list-item',
  templateUrl: './ptw-list-item.component.html',
  styleUrls: ['./ptw-list-item.component.scss']
})
export class PtwListItemComponent implements OnInit {
  @Input() ptw: PTWCapacity

  @Output() selectPTW = new EventEmitter<PTWCapacity>();

  capacityStyle: string

  capacityStringStyle: string

  itemStyling = '';


  ngOnInit() {
    this.ptw.startTime = this.convertTime(this.ptw.startTime)
    this.ptw.endTime = this.convertTime(this.ptw.endTime)
    this.determineCapacityStyling(this.ptw.capacity, this.ptw.allocation)
  }

  public isSelected(): string {
    return this.ptw.selected ? 'selected' : '' 
  }
  
  
  private convertTime(ptwTime: string): string {
    if(this.ptw.startTime == 'ALL'){
      return ptwTime
    }
    return moment(ptwTime, 'HH:mm').format('h:mm A');
  }
  
  private determineCapacityStyling(capacity, allocation): void {
    const filledPercentage = allocation / capacity;
    
    if(filledPercentage > .70 && filledPercentage < .99) {
      this.capacityStyle = "circle half"
      this.capacityStringStyle = 'warning'
    }else if (filledPercentage >= 1){
      this.capacityStyle = "circle full"
      this.capacityStringStyle = 'exceeded'
    }
  }
  
  public onSelectPTW(ptw: PTWCapacity): void {
    this.ptw.selected = false;
    ptw.selected = true;
    this.itemStyling = 'selected'

    this.selectPTW.emit(ptw)
  }

}
