import angular from 'angular';

import ChartDirective from './chart.directive';
import ChartConstant from './chart.constant';
import locale from './resources/locale/en.json';
import ChartService from './chart.service';

const ChartModule = angular
    .module('control-room.commons.data.components.chart', [])
    .directive('crChart', ChartDirective.directiveFactory)
    .constant('crChartConstant', ChartConstant)
    .constant('crChartLocale', locale)
    .service('crChartService', ['numberFilter', 'crChartLocale', ChartService]);

export default ChartModule;
