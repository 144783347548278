import PhoneController from './phone.controller';
import template from './phone.html';
import './phone.less';

const PhoneComponent = {
    template,
    transclude: true,
    bindings: {
        customClass: '@?',
        cta: '<?',
        links: '<?',
        locale: '<?',
    },
    controller: [PhoneController],
};

export default PhoneComponent;
