import { Component, Input } from '@angular/core';

@Component({
  selector: 'cr-form-label-error',
  templateUrl: './form-label-error.component.html',
  styleUrls: ['./form-label-error.component.scss'],
})
export class FormLabelErrorComponent {
  @Input() errorMessages: any;

  @Input() for: string;

  @Input() type: string;

  @Input() customClass: string;
}
