import Controller from './tooltip-wrapper.controller';
import template from './tooltip-wrapper.html';
import './tooltip-wrapper.less';

const Component = {
    template,
    bindings: {
        tooltipAppendToBody: '<?',
        tooltipPlacement: '@?',
        tooltipText: '<?',
    },
    controller: [Controller],
};

export default Component;
