import _ from 'lodash';

import text from './resources/locale/en.json';

class RowBuilderController {
    constructor($scope, crImageService) {
        this.$scope = $scope;
        this.crImageService = crImageService;
    }

    $onInit() {
        this.text = text;
        this.rowBuilderForm = {};
        this.defaultState = this.defaultState || {};
        this.addText = this.addText || this.text.addText;

        if (this.addList) {
            this.attrList = this.getAttributesListModel(this.addList);
        }

        this.crImageService.setIconSrc(this.rows);

        this.$scope.$watch('$ctrl.parentForm.$submitted', (newVal) => {
            if (newVal) {
                this.rowBuilderForm.$setSubmitted();
            }
        });
    }

    $onChanges(changes) {
        if (changes.addList && changes.addList.currentValue) {
            this.attrList = this.getAttributesListModel(changes.addList.currentValue);
        }

        this.crImageService.setIconSrc(this.rows);
    }

    getAttributesListModel(attributeList) {
        this.crImageService.setIconSrc(attributeList);
        const availableAttributes = attributeList.map((attribute) => ({
            label: attribute.label,
            state: {
                id: attribute.id,
                label: attribute.label,
                type: attribute.type,
                enumValues: attribute.enumValues,
            },
            iconSrc: attribute.iconSrc,
        }));

        return availableAttributes;
    }

    addRow(state) {
        if (!this.rowBuilderForm.$valid) {
            return;
        }
        
        if(this.maxRows && this.rows && this.rows.length >= this.maxRows) {
            return; 
        }
        this.rows.push(state || _.cloneDeep(this.defaultState));
    }

    onSelect($event) {
        this.rowBuilderForm.$touched = true;
        if (!this.rowBuilderForm.$valid) {
            return;
        }
        $event.model[0].state.iconSrc = $event.model[0].iconSrc;
        const selectItem = $event.model[0];
        this.addRow(_.cloneDeep(selectItem.state));
    }

    removeRow(index) {
        this.rows.splice(index, 1);

        this.rowBuilderForm.$setDirty();
    }

    shiftRow(rowIndex, targetIndex) {
        const offset = rowIndex < targetIndex ? 1 : 0;

        this.rows.splice(targetIndex - offset, 0, this.rows.splice(rowIndex, 1)[0]);

        this.rowBuilderForm.$setDirty();
    }

    setValidity(formError, validation) {
        _.forOwn(formError, (value, errorType) => {
            this.parentForm[this.name].$setValidity(errorType, validation);
        });
    }
}

export default RowBuilderController;
