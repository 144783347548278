import _ from 'lodash';
import text from './resources/locale/en.json';

class PreferencesBrandContent {
    constructor(crConfig, crAnalyticsService) {
        this.crConfig = crConfig;
        this.crAnalyticsService = crAnalyticsService;
    }

    $onInit() {
        this.text = text;
        this.initDropdown();
    }

    $onChanges() {
        if (this.locale && this.content) {
            this.localizedTitle = this.getLocalizedProp('descriptionTitle', this.content.localization);
            this.localizedDescription = this.getLocalizedProp('descriptionText', this.content.localization);
            this.locale.list = this.getLocaleStatus(this.content);
        }
    }

    getLocaleStatus(content, localeList = _.cloneDeep(this.locale.list)) {
        localeList.forEach((locale) => {
            const localization = content.localization[locale.id];
            if (!localization || !localization.screens || !localization.screens[0]) {
                locale.hasStatusWarning = true;
            } else if (!locale.hasStatusWarning) {
                locale.hasStatusWarning = !(
                    localization.screens[0].descriptionTitle && localization.screens[0].descriptionText
                );
            }
        });

        return localeList;
    }

    getLocalizedProp(prop, localization, locale = this.locale) {
        const current = localization[locale.current];
        if (!current || !current.screens || !current.screens[0][prop]) {
            return localization[locale.default].screens[0][prop];
        }

        return current.screens[0][prop];
    }

    onLocaleUpdate(event) {
        const locale = event.model[0];
        this.crAnalyticsService.track('Language Selected', { language: locale.label });
        this.onLocaleSelect({
            $event: { locale },
        });
    }

    initDropdown() {
        this.showDropdown =
            this.crConfig.customer.localizationEnabled && this.locale.list && this.locale.list.length > 1;
    }
}

export default PreferencesBrandContent;
