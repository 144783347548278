import angular from 'angular';

import PoiTypeListComponent from './poi-type-list.component';
import PoiTypeListService from './poi-type-list.service';
import ComponentsModule from './components/components';

const PoiTypeListModule = angular
    .module('control-room.poi.components.poi-type-list', [ComponentsModule.name])
    .component('crPoiTypeList', PoiTypeListComponent)
    .service('crPoiTypeListService', ['crPoiService', 'crImageService', PoiTypeListService]);

export default PoiTypeListModule;
