import _ from 'lodash';

class HoursBuilderRowController {
    constructor(text) {
        this.text = text;
    }

    $onInit() {
        this.state = this.state || {};
        this.disabled = this.disabled || false;

        this.state.days =
            this.state.days ||
            this.text.days.map((dayText) => ({
                text: dayText,
                active: false,
            }));

        this.update();
    }

    update() {
        this.change({
            key: this.key,
            state: this.state,
            valid: this.isValid(),
        });
    }

    onDayChange(day) {
        if (this.disabled) {
            return;
        }

        day.active = !day.active;
        this.update();
    }

    onOpenChange(value) {
        if (this.disabled) {
            return;
        }

        this.state.open = value;
        this.update();
    }

    onCloseChange(value) {
        if (this.disabled) {
            return;
        }

        this.state.close = value;
        this.update();
    }

    isValid() {
        return this.state.open && this.state.close;
    }

    isRemovable() {
        const index = _.findIndex(this.parentState.rows, { key: this.key });

        return (
            this.parentState.rows.length > 2 ||
            (this.parentState.rows.length === 2 && (index !== 0 || this.parentState.valid))
        );
    }
}

export default HoursBuilderRowController;
