import { Component, Inject, OnInit } from '@angular/core';
import { StateService } from '@uirouter/core';
import { of } from 'rxjs';
import _ from 'lodash';
import { catchError, take } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { CR_CONFIG, ErrorLoggingService } from '../../../../shared';
import { FeesService } from '../../../../shared/services/fees/fees.service';
import { FnbFee } from '../../../../shared/services/fees/fees.model';
import { CrTableColumnOptions, CrTableOptions } from '../../../../shared/table/table.model';
import { Config } from '../../../../core';
import { menuRoutes } from '../../menus.routes';

@Component({
  selector: 'cr-fees-list',
  templateUrl: './fees-list.component.html',
  styleUrls: ['./fees-list.component.scss'],
})
export class FeesListComponent implements OnInit {
  crTableOptions: CrTableOptions<FnbFee> = {
    data: undefined,
    columns: undefined,
    emptyStateText: 'There are currently no fees configured.',
  };

  searchFormControl = new FormControl();

  currencyCode: string;

  feeListData: FnbFee[];

  constructor(
    @Inject(CR_CONFIG) private config: Config,
    private feesService: FeesService,
    private stateService: StateService,
    private logger: ErrorLoggingService,
  ) {}

  public ngOnInit(): void {
    this.getCurrencyCode();
    this.generateColumns();
    this.getFeesListByVenue();
  }

  private getFeesListByVenue() {
    const { venueId } = this.stateService.params;

    this.feesService
      .getFeesByVenue(venueId)
      .pipe(
        take(1),
        catchError((err) => of(this.handleError(err))),
      )
      .subscribe((res) => {
        if (res) {
          this.feeListData = res;
          this.crTableOptions.data = this.alterDataForGridDisplay(res);
          this.crTableOptions.data.sort((a, b) => a.displayName.localeCompare(b.displayName));
        }
      });
  }

  private generateColumns() {
    this.crTableOptions.columns = [
      {
        displayName: '',
        key: '',
        width: 64,
        customRowStyles: { 'padding-left': '16px' },
      },
      {
        displayName: 'Name',
        key: 'displayName',
        width: 200,
        sort: true,
        customColumnOnClick: true,
        customRowStyles: { color: 'black' },
      },
      {
        displayName: 'Fee Calculation',
        key: 'type',
        width: 200,
      },
      {
        displayName: 'Status',
        key: 'publishingStatus',
        width: 200,
      },
    ];
  }

  public handleColumnClick(a): void {
    if (a.column.displayName === 'Name') {
      this.stateService.go(menuRoutes.EDIT_FEES, { edit: true, feeId: a.id });
    }
  }

  public handleSearch(searchQuery: string): void {
    if (this.feeListData) {
      this.crTableOptions.data = this.feeListData.filter((item) => item.displayName.toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase()));
    }
  }

  handleSort(column: CrTableColumnOptions): void {
    // handle data sorting based on key
    if (column.sort) {
      this.crTableOptions.data.sort((a, b) => a.displayName.localeCompare(b.displayName));
    } else {
      this.crTableOptions.data.sort((a, b) => b.displayName.localeCompare(a.displayName));
    }
  }

  private alterDataForGridDisplay(fees: FnbFee[]): FnbFee[] {
    return fees.map((fee) => ({
      ...fee,
      publishingStatus: fee.publishingStatus.toLowerCase(),
      type: this.determineFeeCalcDisplay(fee),
    }));
  }

  private handleError(err) {
    this.logger.logError('fees list get data', err);
  }

  private determineFeeCalcDisplay(fee: FnbFee): string {
    let displayValue = 'unkown';

    if (fee.type === 'FIXED') {
      displayValue = `Flat Rate (${fee.amountOrPercent} ${this.currencyCode})`;
    } else if (fee.type === 'PERCENT') {
      displayValue = `Percentage of total (${fee.amountOrPercent}%)`;
    }

    return displayValue;
  }

  private getCurrencyCode(): void {
    this.currencyCode = _.find(this.config.customer.venues, {
      id: this.stateService.params.venueId,
    }).currencyCode;
  }
}
