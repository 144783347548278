import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CrFormsModule, SharedModule } from '../../../../shared';
import { OrderReceiptComponent } from './order-receipt.component';
import { OrderActionsModalComponent } from './orderActionsModal/order-actions-modal.component';

@NgModule({
  imports: [CommonModule, SharedModule, CrFormsModule],
  declarations: [OrderReceiptComponent, OrderActionsModalComponent],
  exports: [OrderReceiptComponent, OrderActionsModalComponent],
  entryComponents: [OrderActionsModalComponent],
})
export class OrderReceiptModule {}
