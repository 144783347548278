import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

const registry = {
  clear: 'assets/icons/cr-clear.svg',
  done: 'assets/icons/cr-done.svg',
  'dropdown-down': 'assets/icons/cr-dropdown-down.svg',
  'error-bandaid': 'assets/icons/cr-error-bandaid.svg',
  error: 'assets/icons/cr-error.svg',
  search: 'assets/icons/cr-search.svg',
  sort: 'assets/icons/cr-sort.svg',
  'user-pin': 'assets/icons/cr-user-pin.svg',
};

@Injectable({
  providedIn: 'root',
})
export class CrIconService {
  private cache: { [key: string]: string } = {};

  constructor(private http: HttpClient) {}

  async get(name: string): Promise<string> {
    if (!registry[name]) {
      throw new Error(`Icon ${name} not found in registry`);
    }

    if (this.cache[name]) {
      return this.cache[name];
    }

    const svg = await this.http.get(registry[name], { responseType: 'text' }).toPromise();

    this.cache[name] = svg;

    return svg;
  }
}
