import text from './resources/locale/en.json';

class CvtDashboard {
    constructor(
        $state,
        $uibModal,
        $timeout,
        crCvtDashboardService,
        crErrorLoggingService,
        crAnalyticsService,
        crAlertService
    ) {
        this.$state = $state;
        this.$uibModal = $uibModal;
        this.$timeout = $timeout;
        this.crCvtDashboardService = crCvtDashboardService;
        this.crErrorLoggingService = crErrorLoggingService;
        this.crAnalyticsService = crAnalyticsService;
        this.crAlertService = crAlertService;
    }

    $onInit() {
        this.text = text;
        this.execution = {};
        this.isLoading = true;

        const { venueId } = this.$state.params;

        this.crCvtDashboardService
            .getExecutionStatuses(venueId)
            .then((statuses) => this.handleStatusResults(statuses, venueId))
            .catch((err) => {
                this.hasDataError = true;
                this.crErrorLoggingService.logError('could not retrieve cvt execution', err);
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    handleStatusResults(statuses, venueId) {
        if (statuses.executing) {
            this.hasPendingExecution = true;
            this.pollExecutionStatus();
        }

        if (statuses.lastCompleted) {
            return this.crCvtDashboardService.getExecution(statuses.lastCompleted.id, venueId).then((execution) => {
                this.execution = execution;
                this.hasPendingExecution = statuses.executing;
                this.lastRun = statuses.lastCompleted.endTime;
            });
        }
        this.hasNoResults = true;
    }

    startExecution(venueId = this.$state.params.venueId) {
        this.hasPendingExecution = true;
        return this.crCvtDashboardService
            .getExecutionStatuses(venueId)
            .then((statuses) => {
                if (statuses.executing) {
                    this.showModal(this.text.pendingExecution);
                    this.pollExecutionStatus();
                } else {
                    return this.crCvtDashboardService.startExecution(venueId).then(() => {
                        this.crAnalyticsService.track('CVT - start execution');
                        this.pollExecutionStatus();
                    });
                }
            })
            .catch((err) => {
                this.hasPendingExecution = false;
                this.crErrorLoggingService.logError('could not start cvt execution', err);
                this.showModal(this.text.executeError);
            });
    }

    pollExecutionStatus(venueId = this.$state.params.venueId) {
        this.$timeout(() => {
            this.crCvtDashboardService
                .getExecutionStatuses(venueId)
                .then((statuses) => {
                    if (statuses.executing) {
                        this.pollExecutionStatus(venueId);
                    } else {
                        this.showAlert();
                        this.isRunComplete = true;
                        this.hasPendingExecution = false;
                    }
                })
                .catch((err) => {
                    this.crErrorLoggingService.logError('problem polling cvt statuses endpoint', err);
                });
        }, 10000);
    }

    showAlert() {
        const { customerId, venueId } = this.$state.params;
        const cta = {
            label: this.text.loadResults,
            onClick: () => this.$state.go('client.cvt.dashboard', { customerId, venueId }, { reload: true }),
        };

        this.crAlertService.alert(this.text.theTestIsFinishedRunning, cta);
    }

    showModal(message) {
        this.$uibModal
            .open({
                backdrop: 'static',
                component: 'crSimpleModal',
                windowClass: 'cr-modal-size-sm',
                resolve: {
                    message: () => message,
                },
            })
            .result.catch(() => null);
    }
}

export default CvtDashboard;
