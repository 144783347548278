import _ from 'lodash';

import text from './resources/locale/en.json';

class EntityCategoriesMobilePreviewController {
    constructor() {
        this.text = text;
    }

    $onInit() {
        this.selectedCategory = _.first(this.categories);
    }

    selectCategory(category) {
        this.selectedCategory = category;
    }
}

export default EntityCategoriesMobilePreviewController;
