import text from './resources/locale/en.json';

import ListPage from '../../../../commons/controller/list-page';

class EventsList extends ListPage {
    constructor(
        $state,
        crErrorLoggingService,
        crAnalyticsService,
        crToastService,
        crLocaleService,
        crConfig,
        crEventsListService,
        $q,
        crEntitySelectionManager
    ) {
        super($state, crErrorLoggingService, crAnalyticsService, crToastService, crLocaleService, crConfig);
        this.crEventsListService = crEventsListService;
        this.$q = $q;
        this.crEntitySelectionManager = crEntitySelectionManager;
    }

    $onInit() {
        super.$onInit();

        this.text = text;

        this.registerParam('state', '');
        this.registerParam('category', '');
        this.registerParam('start', '');
        this.registerParam('end', '');

        const { columns } = this.text;

        this.listData = {
            columns: [
                { key: 'image', label: '' },
                { key: 'name', label: columns.name, class: 'large-column name-column', sortable: true },
                { key: 'title', label: columns.title, class: 'large-column', sortable: true },
                { key: 'categories', label: columns.categories },
                { key: 'location', label: columns.location },
                { key: 'state', label: columns.state, class: 'medium-column' },
                { key: 'editButton', label: '', class: 'small-column' },
            ],
            rows: [],
        };

        if (this.hasMultipleLanguages()) {
            this.addMissingTranslationsColumnHeader();
        }

        this.stateFilterData = [
            { id: 'ACTIVE', label: this.text.active },
            { id: 'INACTIVE', label: this.text.inActive },
            { id: 'DRAFT', label: this.text.draft },
        ];
        this.stateFilterData[0].isSelected = true;

        this.range = {
            start: '',
            end: '',
        };

        this.getCategoriesData();
    }

    getData() {
        this.isLoading = true;
        this.dataError = false;

        return this.crEventsListService
            .getData(this.params)
            .then((data) => {
                data.search = data.query.search || '';

                if (data.search === this.params.search) {
                    const categoriesPromise = !this.categoriesFilterData ? this.getCategoriesData() : this.$q.resolve();

                    return categoriesPromise.then(() => {
                        this.isLoading = false;
                        return this.crEventsListService.mapToRowsModel(data.content, this.categoriesFilterData);
                    });
                }
            })
            .catch((err) => {
                this.isLoading = false;
                this.dataError = true;
                this.endOfResults = true;
                this.listData.rows = [];
                this.crErrorLoggingService.logError('Could not get Events List data', err);
            });
    }

    rangeUpdated(event) {
        this.params.start = event.range.start;
        this.params.end = event.range.end;
        this.range = event.range;
        this.updateData();
    }

    getCategoriesData() {
        return this.crEventsListService.getCategories(this.$state.params.venueId).then((eventCategories) => {
            this.categoriesFilterData = eventCategories;
        });
    }
}

export default EventsList;
