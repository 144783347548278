import { NgModule } from '@angular/core';

import { DropdownUpgradedComponent } from './dropdown-upgraded.component';
import { DropdownComponent } from './dropdown.component';

@NgModule({
  imports: [],
  exports: [DropdownComponent],
  declarations: [DropdownUpgradedComponent, DropdownComponent],
  providers: [],
})
export class CrDropdownModule { }
