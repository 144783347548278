import _ from 'lodash';
import text from './resources/locale/en.json';
import {
    ImageCropperModalComponent
  } from '../../../../shared';

class ImageUploaderController {
    constructor(crImageService, crConstants, $uibModal, crAnalyticsService, bsModalService) {
        this.crImageService = crImageService;
        this.constants = crConstants.image;
        this.$uibModal = $uibModal;
        this.crAnalyticsService = crAnalyticsService;
        this.text = text;
        this.bsModalService = bsModalService;
    }

    $onInit() {
        this.images = this.images || [];
        this.imageIndex = this.images.length ? 0 : null;
        this.maxImages = this.constants.maxCount[this.imageType];
        this.cropDisplaySize = this.constants.cropDisplaySize[this.imageType];
        this.maxFileSize = this.constants.maxFileSize;
        this.zoomAmount = this.constants.zoomAmount;

        this.initImages();
    }

    $onChanges(changes) {
        if (changes.images) {
            this.images = _.cloneDeep(this.images);
            if (!changes.images.isFirstChange()) {
                this.initImages();
            }
        }
    }

    initImages() {
        this.minSize = this.imageMinSize || this.constants.minSize[this.imageType];
        if(!this.minSize.aspectRatio) {
            this.minSize.aspectRatio = this.constants.minSize[this.imageType].aspectRatio;
        }

        this.imageTrackingData = {
            entityType: this.crImageService.getEntityType(),
            imageType: this.imageType,
        };

        const { nonTargetImages, targetImages } = this.crImageService.initImages(this.images, this.imageType);
        this.nonTargetImages = nonTargetImages;
        this.targetImages = targetImages;

        this.updateModel();

        if (this.targetImages.length) {
            if (this.imageIndex === null) {
                this.imageIndex = 0;
            }
            this.selectImage(this.imageIndex);
        } else {
            this.resetImage();
        }
    }

    updateModel() {
        this.formModel = this.targetImages.length || null;
    }

    selectImage(index) {
        this.imageSrc = this.targetImages[index].displaySrc;
        this.imageIndex = index;
    }

    resetImage() {
        this.imageSrc = null;
        this.imageIndex = null;
    }

    getCleanImages() {
        _.forEach(this.targetImages, (image) => {
            delete image.displaySrc;
        });

        return this.nonTargetImages.concat(this.targetImages);
    }

    deleteImage(event) {
        event.stopPropagation();

        const imageName = this.crImageService.getImageName(this.targetImages[this.imageIndex].src);

        this.$uibModal
            .open({
                backdrop: 'static',
                component: 'crSimpleModal',
                windowClass: 'cr-modal-size-sm',
                resolve: {
                    type: () => 'submit',
                    submitText: () => this.text.delete,
                    message: () => `${this.text.deleteConfirmationMessage} "${imageName}"?`,
                },
            })
            .result.then(() => {
                this.setDirty();
                this.targetImages.splice(this.imageIndex, 1);

                if (this.imageIndex > 0) {
                    this.imageIndex -= 1;
                } else {
                    this.imageIndex = null;
                }

                this.updateParent();

                const imageTrackingData = _.cloneDeep(this.imageTrackingData);
                imageTrackingData.filename = imageName;
                this.crAnalyticsService.track('Delete Image', imageTrackingData);
            })
            .catch(() => null);
    }

    manageImage(add) {
        this.crAnalyticsService.track('Add New Image Clicked', this.imageTrackingData);
       this.openCropperImageModal(add)
  }

    openCropperImageModal(add) {
        this.bsModalService.show(ImageCropperModalComponent, {
            initialState: {
                showAsCircle: this.showAsCircle(),
                targetImages: this.targetImages,
                imageIndex: add ? null : this.imageIndex,
                imageType: this.imageType,
                imageTypes: this.constants.types,
                minSize: this.minSize,
                headerText: this.cropperToolHeader || 'Add an image',
                imageTrackingData:  this.imageTrackingData,
            },
            backdrop: 'static',
            class: 'cr-modal-size-lg',
        }).content.result.then(() => {
            //Select the new image
            if (add) {
                this.imageIndex = this.targetImages.length - 1;
            }

            this.updateParent();
        })
        .catch(() => this.setTouched());
    }

   setDirty() {
        if (this.form) {
            const formElem = this.form[this.name];

            if (!formElem.$dirty) {
                formElem.$setDirty();
            }
        }
        else if (this.upgradedFormControl) {
            if (!this.upgradedFormControl.dirty) {
                this.upgradedFormControl.markAsDirty();
            }
        }
    }

    setTouched() {
        if (this.form) {
            const formElem = this.form[this.name];

            if (!formElem.$touched) {
                formElem.$setTouched();
            }
        }
        else if (this.upgradedFormControl) {
            if (!this.upgradedFormControl.touched) {
                this.upgradedFormControl.markAsTouched();
            }
        }
    }

    updateParent() {
        this.setDirty();
        this.onChange({
            $event: {
                images: this.getCleanImages(),
            },
        });
    }

    showAsCircle() {
        return this.crImageService.showAsCircle();
    }
}

export default ImageUploaderController;
