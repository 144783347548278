import FilterMenuController from './filter-menu.controller';
import template from './filter-menu.html';
import './filter-menu.less';

const FilterMenuComponent = {
    template,
    controller: ['filterMenuText', '$state', FilterMenuController],
    bindings: {
        title: '@?',
        options: '<',
        onSelect: '&',
        currentOptionId: '<?',
        routeQueryId: '@',
        defaultFilterId: '@?',
        hideResetOption: '<?',
        menuAlignRight: '<?',
    },
};

export default FilterMenuComponent;
