import moment, { Moment } from 'moment';
import { constants } from '../../../../../../shared/services/constants/constants';
import { Schedule, ScheduleInstance } from '../../../../../../shared/services/reservations/reservation.types';

export class ScheduleData implements Schedule {
  priority: number;

  durationSecs: number;

  recurrence: string;

  instances: any[];

  scheduleType: string;

  id: string;

  quantity: number;

  days: string[];

  startDate: Date;

  endDate?: Date;

  instanceTimes: string[];

  recurrenceExceptionsMap: Map<string, Moment[]> = new Map();

  constructor(schedule?: Schedule) {
    if (schedule) {
      Object.assign(this, schedule);
      if (schedule.recurrence) {
        this.parseRecurrence(schedule.recurrence);
      }
      if (schedule.instances && schedule.instances.length > 0) {
        this.setInstanceTimes(schedule.instances);
      }
      if (schedule.recurrenceExceptions) {
        this.setRecurrenceExceptionDates(schedule.recurrenceExceptions);
      }
    }
  }

  private parseRecurrence(recurrence: string) {
    recurrence.split(';').forEach((part) => {
      if (part.startsWith('BYDAY=')) {
        this.days = part.substring(6).split(',');
      } else if (part.startsWith('UNTIL=')) {
        this.endDate = moment(part.substring(6), constants.iCal.format.date).toDate();
      }
    });
  }

  private setInstanceTimes(instances: ScheduleInstance[]) {
    const startTimeMoment = moment(instances[0].startTime);
    if (startTimeMoment.isValid) {
      this.startDate = startTimeMoment.toDate();
    }
    this.instanceTimes = instances.map((instance) => instance.startTime);
  }

  private setRecurrenceExceptionDates(recurrenceExceptions: string) {
    // eslint-disable-next-line @typescript-eslint/require-array-sort-compare
    const recurrenceExceptionDates = recurrenceExceptions
      .split(',')
      .sort()
      .map((exception) => moment(exception, constants.iCal.format.date))
      .filter((date) => date.isValid());
    const startDate = moment(this.startDate);
    recurrenceExceptionDates.forEach((exceptionTime) => {
      startDate.year(exceptionTime.year()).month(exceptionTime.month()).date(exceptionTime.date());
      if (exceptionTime.isBefore(startDate)) {
        startDate.add(1, 'days');
      }
      const dateString = startDate.format(constants.date.format.yearFirstDate);
      if (!this.recurrenceExceptionsMap.has(dateString)) {
        this.recurrenceExceptionsMap.set(dateString, []);
      }
      this.recurrenceExceptionsMap.get(dateString).push(exceptionTime);
    });
  }
}
