class ToastService {
    constructor($rootScope, crConstants) {
        this.$rootScope = $rootScope;
        this.crConstants = crConstants;
    }

    toast(msg, timeToLive, customStyle) {
        this.$rootScope.$broadcast(this.crConstants.events.SHOW_TOAST, {
            msg,
            timeToLive,
            customStyle,
        });
    }

    autoToast(stateParams) {
        const { toast } = stateParams;

        if (toast && toast.msg) {
            this.toast(toast.msg, toast.timeToLive, toast.customStyle);
            stateParams.toast = null;
            return true;
        }

        // Did not toast
        return false;
    }
}

export default ToastService;
