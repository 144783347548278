import angular from 'angular';
import PoiDetailsComponent from './poi-details.component';
import PoiDetailsService from './poi-details.service';

const PoiDetails = angular
    .module('control-room.poi.components.poi-details', [])
    .component('crPoiDetails', PoiDetailsComponent)
    .service('crPoiDetailsService', [
        '$q',
        'crPoiService',
        'crImageService',
        'crEntityService',
        'crMenusService',
        'crLoQueueService',
        PoiDetailsService,
    ]);

export default PoiDetails;
