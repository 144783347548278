import controller from './experience-promoter.controller';
import template from './experience-promoter.html';

const component = {
    template,
    bindings: {},
    controller: ['$state', 'crConstants', 'crAnalyticsService', 'crFeatureFlagsService', controller],
};

export default component;
