<section class="component-body">
    <cr-title-bar
        icon="icon-cr-back"
        [label]="productTitle"
        (onClick)="onBack()"
    >
    </cr-title-bar>
    <cr-nav-header [navLocation]="navLocation"></cr-nav-header>

    <div class="queue-header page-padding">
        <div class="queue-title">{{ text.title }}</div>
        <div class="time-session-title">
            {{ text.sessionTitle }}
            <span *ngIf="isCurrentSessionCancelled" class="cancelled-title">{{
                text.sessionCancelledLabel
            }}</span>
        </div>
        <div class="reservation-timeslot-container">
            <div
                class="reservation-timeslot-selector"
                *ngIf="!performanceSessionEdit; else sessionEditor"
            >
                <div class="timeslot-arrow-left" (click)="prevPerformance()">
                    <span
                        class="timeslot-select-icon icon-cr-chevron-left"
                    ></span>
                </div>
                <div class="selected-timeslot">
                    {{ selectedPerformanceDateString() }}
                </div>
                <div class="timeslot-arrow-right" (click)="nextPerformance()">
                    <span
                        class="timeslot-select-icon icon-cr-chevron-right"
                    ></span>
                </div>
            </div>
            <ng-template #sessionEditor>
                <div class="reservation-session-edit-container row">
                    <div class="col-xs-6">
                        <div class="session-edit-item">
                            <input
                                type="text"
                                placeholder="Datepicker"
                                class="form-control session-edit-control"
                                (bsValueChange)="changeDate($event)"
                                [value]="dateSessionEditValue"
                                [bsConfig]="bsConfig()"
                                bsDatepicker
                            />
                            <span
                                class="edit-field-icon icon-cr-calendar-basic"
                            ></span>
                        </div>
                    </div>
                    <div class="col-xs-6">
                        <div dropdown #dropdown="bs-dropdown">
                            <div class="session-edit-item">
                                <input
                                    dropdownToggle
                                    value="{{ this.timeslotEditString() }}"
                                    id="button-basic"
                                    type="text"
                                    class="form-control session-edit-control"
                                    aria-controls="dropdown-basic"
                                />
                                <span
                                    class="edit-field-icon icon-cr-time"
                                ></span>
                            </div>
                            <ul
                                id="dropdown-basic"
                                class="dropdown-menu"
                                *dropdownMenu
                                role="menu"
                                aria-labelledby="button-basic"
                            >
                                <li
                                    role="menuitem"
                                    *ngFor="
                                        let reservationTimeslot of editSessionPerformanceTimes
                                    "
                                >
                                    <a
                                        class="dropdown-item"
                                        (click)="
                                            selectTimeslot(
                                                reservationTimeslot.valueOf()
                                            )
                                        "
                                    >
                                        <span
                                            class="dropdown-item-date"
                                            [ngClass]="{
                                                cancelled: sessionIsCancelled(
                                                    reservationTimeslot.valueOf()
                                                )
                                            }"
                                        >
                                            {{
                                                reservationTimeslot.format(
                                                    "h:mm A"
                                                )
                                            }}
                                        </span>
                                        {{
                                            sessionIsCancelled(
                                                reservationTimeslot.valueOf()
                                            )
                                                ? text.sessionCancelledLabel
                                                : ""
                                        }}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </ng-template>
            <div class="reservation-timeslot-edit">
                <button
                    *ngIf="!performanceSessionEdit; else finishEditing"
                    class="link-button"
                    (click)="toggleSessionEditor()"
                >
                    {{ text.sessionEditText }}
                </button>
                <ng-template #finishEditing>
                    <button class="link-button" (click)="submitSessionEdit()">
                        Done editing
                    </button>
                </ng-template>
            </div>
        </div>
        <div class="search-bar">
            <span *ngIf="!isSearching" class="icon icon-cr-search"></span>
            <span
                *ngIf="isSearching"
                (click)="clearSearch()"
                class="icon icon-cr-chevron-left"
            ></span>
            <input
                #searchReservations
                [value]="searchQuery"
                [placeholder]="text.searchPlaceholder"
                (keydown)="searchChangeEvent($event)"
                (focus)="searchFocus()"
                (blur)="searchBlur()"
            />
        </div>
        <div *ngIf="isSearching" class="search-instructions">
            {{ text.searchInstruction }}
        </div>
    </div>

    <div class="queue-controls scroll-fix" *ngIf="!isSearching">
        <div>
            <div class="tabs page-padding">
                <div
                    class="tab"
                    [ngClass]="{
                        selected: selectedTab === ReservationStatus.PENDING
                    }"
                    (click)="selectTab(ReservationStatus.PENDING)"
                >
                    {{ text.selectedTabs.PENDING }} ({{
                        pendingTickets[this.selectedPerformance]
                            ? pendingTickets[this.selectedPerformance].length -
                              (toastExists() ? 1 : 0)
                            : 0
                    }})
                </div>
                <div
                    class="tab"
                    [ngClass]="{
                        selected: selectedTab === ReservationStatus.ARRIVED
                    }"
                    (click)="selectTab(ReservationStatus.ARRIVED)"
                >
                    {{ text.selectedTabs.ARRIVED }} ({{
                        arrivedTickets[this.selectedPerformance]
                            ? arrivedTickets[this.selectedPerformance].length
                            : 0
                    }})
                </div>
                <div
                    class="tab"
                    [ngClass]="{
                        selected: selectedTab === ReservationStatus.CANCELLED
                    }"
                    (click)="selectTab(ReservationStatus.CANCELLED)"
                >
                    {{ text.selectedTabs.CANCELLED }} ({{
                        cancelledTickets[this.selectedPerformance]
                            ? cancelledTickets[this.selectedPerformance].length
                            : 0
                    }})
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="!isLoading; else loading" class="content">
        <section
            class="reservations"
            *ngIf="
                filteredTickets && filteredTickets.length > 0;
                else noReservations
            "
        >
            <div
                *ngFor="let ticketGroup of filteredTickets"
                class="reservation-parent shadow"
            >
                <div
                    class="toast-message-container success page-padding"
                    *ngIf="
                        markedArrivedTicketId ===
                            ticketGroup[0].extendedData.orderId ||
                            markedCancelledTicketId ===
                                ticketGroup[0].extendedData.orderId;
                        else reservationTicket
                    "
                >
                    <div class="row toast-message">
                        <div class="col-xs-12 col-sm-12 col-md-8">
                            {{ successMessage }}
                        </div>
                        <div
                            class="col-xs-12 col-sm-12 col-md-3 action-button"
                            (click)="navigateToReservation()"
                        >
                            {{ text.showReservationButtonText }}
                        </div>
                        <div
                            class="col-xs-12 col-sm-12 col-md-1 action-button"
                            (click)="dismissToasts()"
                        >
                            {{ text.dismissButtonText }}
                        </div>
                    </div>
                </div>
                <ng-template #reservationTicket>
                    <div
                        class="toast-message-container error page-padding"
                        *ngIf="
                            errorReservationId &&
                            errorReservationId ===
                                ticketGroup[0].extendedData.orderId
                        "
                    >
                        <div class="row toast-message">
                            <div class="col-xs-12 col-sm-12 col-md-10">
                                {{ text.errorMessage }}
                            </div>
                            <div
                                class="col-xs-12 col-sm-12 col-md-2 action-button"
                                (click)="dismissToasts()"
                            >
                                {{ text.dismissButtonText }}
                            </div>
                        </div>
                    </div>
                    <div
                        class="reservation-container"
                        [ngClass]="{
                            pending: selectedTab === ReservationStatus.PENDING,
                            arrived:
                                selectedTab === ReservationStatus.ARRIVED ||
                                ticketGroup[0].status === 'FULFILLED',
                            cancelled:
                                selectedTab === ReservationStatus.CANCELLED ||
                                ticketGroup[0].status === 'REVOKED',
                            editing: ticketGroup[0].ticketId === editingTicketId
                        }"
                    >
                        <div class="reservation-flex-container page-padding">
                            <div class="reservation-data">
                                <div class="reservation-data-row">
                                    <div class="field field-medium">
                                        <div class="field-label">
                                            {{
                                                text.reservationTicket.guestName
                                            }}
                                        </div>
                                        <div class="value">
                                            {{
                                                ticketGroup[0].ticketholderName
                                            }}
                                        </div>
                                    </div>
                                    <div class="field field-large">
                                        <div class="field-label">
                                            {{
                                                text.reservationTicket
                                                    .reservationId
                                            }}
                                        </div>
                                        <div class="value">
                                            {{
                                                ticketGroup[0].extendedData
                                                    .thirdPartyReference
                                            }}
                                        </div>
                                    </div>
                                    <div class="field field-medium">
                                        <div class="field-label">
                                            {{
                                                text.reservationTicket
                                                    .reservationTime
                                            }}
                                        </div>
                                        <div class="value">
                                            {{
                                                reservationTicketTimeStr(
                                                    ticketGroup[0].validity
                                                        .startDateTime
                                                )
                                            }}
                                        </div>
                                    </div>
                                </div>
                                <div class="reservation-data-row">
                                    <div class="field field-medium">
                                        <div class="field-label">
                                            {{
                                                text.reservationTicket.partySize
                                            }}
                                        </div>
                                        <div class="value">
                                            {{ ticketGroup.length }}
                                        </div>
                                    </div>
                                    <div class="field field-large">
                                        <div class="field-label">
                                            {{
                                                text.reservationTicket
                                                    .guestPhone
                                            }}
                                        </div>
                                        <div class="value">
                                            {{
                                                ticketGroup[0].extendedData
                                                    .orderPhone
                                            }}
                                        </div>
                                    </div>
                                    <div class="field field-medium">
                                        <div class="field-label">
                                            {{
                                                text.reservationTicket
                                                    .guestEmail
                                            }}
                                        </div>
                                        <div class="value">
                                            {{ ticketGroup[0].orderEmail }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                *ngIf="ticketGroup[0].status === 'PENDING'"
                                class="reservation-controls"
                            >
                                <div
                                    class="fulfillment-button"
                                    [ngClass]="{ 'in-progress': true }"
                                    (click)="editTicket(ticketGroup[0])"
                                >
                                    PENDING
                                    <span class="icon icon-cr-edit"></span>
                                </div>
                            </div>
                            <div class="reservation-state-buttons shadow">
                                <div
                                    *ngIf="
                                        ticketGroup[0].status !== 'FULFILLED'
                                    "
                                    (click)="
                                        updateReservations(
                                            ticketGroup,
                                            'ARRIVED'
                                        )
                                    "
                                    class="reservation-state-button not-arrived"
                                >
                                    <div class="centered">
                                        {{ text.controls.ARRIVED }}
                                    </div>
                                </div>
                                <div
                                    *ngIf="ticketGroup[0].status !== 'REVOKED'"
                                    (click)="
                                        openModal(
                                            cancelReservationModal,
                                            ticketGroup
                                        )
                                    "
                                    class="reservation-state-button not-cancelled"
                                >
                                    <div class="centered">
                                        {{ text.controls.CANCEL }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>
        </section>
    </div>

    <ng-template #loading>
        <div class="cr-page-padding-top cr-flex-center">
            <cr-spinner></cr-spinner>
        </div>
    </ng-template>

    <ng-template #noReservations>
        <div class="no-reservations-container" *ngIf="!isSearching">
            <div class="no-reservations-text">
                <p>{{ text.noReservationsText }}</p>
                <p *ngIf="selectedTab === ReservationStatus.PENDING">
                    {{ text.noReservationsRefreshText }}
                </p>
            </div>
            <!-- "updateReservations(ticketGroup, 'CANCEL')" -->
            <button
                class="link-button"
                *ngIf="selectedTab === ReservationStatus.PENDING"
                (click)="refreshReservations()"
            >
                {{ text.refreshButtonText }}
            </button>
        </div>
    </ng-template>

    <ng-template #cancelReservationModal>
        <div class="modal-content">
            <div class="modal-body">
                {{ text.cancelReservationModalText }}
            </div>
            <div class="modal-footer">
                <button
                    type="button"
                    class="btn btn-light"
                    (click)="hideModal()"
                >
                    {{ text.closeModalButtonText }}
                </button>
                <button
                    type="button"
                    class="btn btn-primary"
                    (click)="confirmCancellation()"
                    data-dismiss="modal"
                >
                    {{ text.confirmedCancelButtonText }}
                </button>
            </div>
        </div>
    </ng-template>
</section>
