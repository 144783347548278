import angular from 'angular';

import BeaconsComponent from './beacons.component';

import ComponentsModule from './components/components';

const BeaconsModule = angular
    .module('control-room.beacons', [ComponentsModule.name])
    .component('crBeacons', BeaconsComponent);

export default BeaconsModule;
