<div
    class="error-container"
    *ngIf="(form.submitted || control.touched) && control.invalid"
>
    <cr-icon class="error-icon" name="error"></cr-icon>
    <div class="error-message">
        {{ errorMessage }}
    </div>
</div>

<div
    class="valid-container"
    *ngIf="showValidMessage && control.touched && control.valid"
>
    <cr-icon class="valid-icon" name="done"></cr-icon>
    <div class="valid-message">
        {{ text.valid }}
    </div>
</div>
