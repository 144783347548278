import Controller from './mc-classic.controller';
import template from './mc-classic.html';
import './mc-classic.less';

const ClassicComponent = {
    template,
    controller: ['$state', Controller],
};

export default ClassicComponent;
