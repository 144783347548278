import template from './poi-edit.html';
import './poi-edit.less';
import PoiEditController from './poi-edit.controller';

const PoiEditComponent = {
    template,
    bindings: {
        edit: '@',
    },
    controller: [
        '$q',
        '$state',
        '$uibModal',
        'crEntityService',
        'crLocaleService',
        'crConstants',
        'crPoiConstants',
        'crPoiEditService',
        'crNavigationService',
        'crImageService',
        'crErrorLoggingService',
        'crGraphicalMapService',
        'crAnalyticsService',
        'crPoiMapService',
        PoiEditController,
    ],
};

export default PoiEditComponent;
