import { Component, Input } from '@angular/core';

@Component({
  selector: 'cr-notification',
  templateUrl: 'notification.component.html',
  styleUrls: ['notification.component.scss'],
})
export class NotificationComponent {
  @Input() allowClose: boolean;

  @Input() message: string;

  @Input() title: string;

  open = true;

  close() {
    this.open = false;
  }
}
