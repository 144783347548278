import _ from 'lodash';

class FnbArchiveModalController {
    constructor($state, $q, text, crLocaleService, crMenusService) {
        this.$state = $state;
        this.$q = $q;
        this.text = text;
        this.crLocaleService = crLocaleService;
        this.crMenusService = crMenusService;
    }

    $onInit() {
        this.locale = this.crLocaleService.getLocale(this.$state.params.venueId, this.$state.params.locale, true);

        if (this.resolve) {
            this.pending = true;

            const promises = this.resolve.items.map((kitchen) =>
                this.crMenusService
                    .getMenusForPlace(kitchen.id, this.locale.current)
                    .then((menus) => menus.content)
            );

            this.$q
                .all(promises)
                .then((responses) => {
                    this.menuNames = _.flatMap(responses).map((menu) => menu.content.name);
                    this.description =
                        this.menuNames.length > 0 ? this.text.descriptionWithMenus : this.text.description;
                })
                .finally(() => {
                    this.pending = false;
                    this.loaded = true;
                });
        } else {
            this.loaded = true;
        }
    }

    onCancel() {
        this.dismiss();
    }

    onSubmit() {
        this.close();
    }
}

export default FnbArchiveModalController;
