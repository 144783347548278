import { downgradeComponent } from '@angular/upgrade/static';
import angular from 'angular';
import { BundleListComponent } from './components/bundle-list/bundle-list.component';
import ComponentsModule from './components/components';
import component from './menus.component';

const Menus = angular.module('control-room.menus',  [
        ComponentsModule.name,     
])
    .component('crMenus', component)
    .directive('crBundleList', downgradeComponent({component: BundleListComponent}))

export default Menus;
