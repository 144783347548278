import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { OrderHistoryExportParams } from '../services/order-history/order-history.model';


@Injectable({
  providedIn: 'root',
})
export class ExportService {
  constructor(private http: HttpClient) {}

  exportPOI(venueId: string): Observable<Blob> {
    return this.http.get(`/rest/export/pois?venueId=${venueId}`, { responseType: 'blob' });
  }

  exportOrders(venueId: string, placeId: string): Observable<Blob> {
    return this.http.get(`/rest/export/orders?placeId=${placeId}&venueId=${venueId}`, { responseType: 'blob' });
  }

  exportOrderHistory(orderHistoryQuery:  OrderHistoryExportParams): Observable<Blob> {
    return this.http.get(`/rest/export/orders/history`,  { responseType: 'blob',  params: orderHistoryQuery as HttpParams});
  }

}
