import ModalController from './modal.controller';
import template from './modal.html';
import './modal.less';

const ModalComponent = {
    template,
    transclude: {
        crContents: 'crContents',
        crExtraOptions: '?crExtraOptions',
    },
    bindings: {
        type: '@',
        title: '@',
        errorMessage: '<',
        cancelText: '<',
        submitText: '<',
        closeText: '<',
        disabled: '<',
        onSubmit: '&',
        onCancel: '&',
        pending: '<',
    },
    controllerAs: 'modalCtrl',
    controller: ['crModalText', 'crConstants', ModalController],
};

export default ModalComponent;
