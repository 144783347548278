import template from './entity-categories-mobile-preview.html';
import './entity-categories-mobile-preview.less';
import EntityCategoriesMobilePreviewController from './entity-categories-mobile-preview.controller';

const EntityCategoriesMobilePreviewComponent = {
    template,
    bindings: {
        categories: '<',
    },
    controller: [EntityCategoriesMobilePreviewController],
};

export default EntityCategoriesMobilePreviewComponent;
