import _ from 'lodash';
import text from './resources/locale/en.json';

class ChipSelectorController {
    constructor($filter, $element, crConstants) {
        this.filter = $filter('filter');
        this.$element = $element;
        this.crConstants = crConstants;
    }

    $onInit() {
        this.text = text;

        this.maxlength = this.maxlength || 32;
        this.emptyOptionsText = this.emptyOptionsText || this.text.emptyOptions;
        this.input = '';

        this.setFilteredOptions();
        this.isOpen = false;
    }

    $onChanges() {
        this.currentTags = _.isEmpty(this.currentTags) ? null : _.cloneDeep(this.currentTags);
        this.setFilteredOptions();
    }

    addTag(e, tag) {
        this.isOpen = false;
        this.input = '';

        this.currentTags = this.currentTags || [];
        this.currentTags.push(tag);

        this.update();
    }

    removeTag(e, index) {
        e.stopPropagation();
        this.currentTags.splice(index, 1);

        // This lets the input tag know that it's actually empty,
        // which is important for the "required" directive to work
        if (_.isEmpty(this.currentTags)) {
            this.currentTags = null;
        }

        this.update();
    }

    update() {
        this.setFilteredOptions();

        this.setDirty();

        this.onSelect({ tags: this.currentTags });
    }

    onKeydown(e) {
        if (e.keyCode === 13 && this.input.length && this.canAdd) {
            const value = this.input;
            this.input = '';
            this.currentTags.push({ value, label: value });

            this.update();
        } else if (e.keyCode === 8 && this.input.length === 0) {
            this.currentTags.pop();

            this.update();
        }
    }

    onKeyup(e) {
        this.isOpen = e.keyCode !== 13 && this.input.length;
        this.setFilteredOptions();
    }

    onAddClick(e) {
        e.stopPropagation();
        this.isOpen = !this.isOpen;

        this.setFilteredOptions();
        this.focusInput();
    }

    setFocus(to) {
        this.isFocused = to;

        if (!this.isFocused) {
            this.setTouched();
        }
    }

    setTouched() {
        const formElem = this.form[this.name];

        if (!formElem.$touched) {
            formElem.$setTouched();
        }
    }

    setDirty() {
        if (this.form) {
            this.form.$setDirty();
        }
    }

    onClick() {
        this.focusInput();
    }

    focusInput() {
        const input = this.$element[0].querySelector('input.cr-chip-input');
        if (input) {
            input.focus();
        }
    }

    sortItems(items, key, dir) {
        if (dir) {
            if (dir === this.crConstants.sort.ASC || dir === this.crConstants.sort.DESC) {
                items = _.sortBy(items, (item) => item[key].toLowerCase());

                if (dir === this.crConstants.sort.DESC) {
                    items = items.reverse();
                }
            }
        }

        return items;
    }

    setFilteredOptions() {
        if (!this.options) {
            return;
        }

        let subsetOptions = this.options.filter((option) => !_.find(this.currentTags, (tag) => _.isEqual(tag, option)));

        subsetOptions = _.differenceBy(this.options, this.currentTags, 'id');

        this.filteredOptions = this.filter(subsetOptions, { label: this.input });

        this.filteredOptions = this.sortItems(this.filteredOptions, 'label', this.sort);
    }

    onToggle(isOpen) {
        if (!isOpen) {
            this.setTouched();
        }
    }
}

export default ChipSelectorController;
