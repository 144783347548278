<div class="cr-checkbox">
    <input
        #checkbox
        type="checkbox"
        class="control"
        
        (input)="toggleCheckbox()"
    />
    <label class="checkbox-label" 
        [class.checkbox-label-checked]="checked" 
        (click)="toggleCheckbox()"> 
        {{ label }} 
    </label>
</div>
