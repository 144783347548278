import controller from './mobile-ordering.controller';
import template from './mobile-ordering.html';

import './mobile-ordering.less';

const component = {
    template,
    bindings: {
        kitchen: '<',
        isEdit: '<',
        form: '<',
        selectedDays: '<',
        pickupTimeWindowEnabled: '<',
        updateContentOptions: '&?',
        checkInTimeWindowEnabled: '<',
    },
    controller: ['$sce', '$state', '$timeout', 'crConstants', '$uibModal', controller],
};

export default component;
