import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ExperienceManageModule } from './components/experience-manage/experience-manage.module';
import { LiveMapModule } from './live-map/live-map.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, ExperienceManageModule, LiveMapModule],
  exports: [],
  providers: [],
})
export class ExperiencePromoterModule {}
