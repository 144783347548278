import PreferencesManageController from './preferences-manage.controller';
import template from './preferences-manage.html';
import './preferences-manage.less';

const PreferencesManageComponent = {
    template,
    bindings: {
        edit: '<?',
    },
    controller: [
        '$state',
        '$q',
        '$uibModal',
        '$sce',
        'crConfig',
        'crConstants',
        'crLocaleService',
        'crNavigationService',
        'crErrorLoggingService',
        'crAnalyticsService',
        'crEntityService',
        'crQuestionService',
        'crUserPreferencesService',
        PreferencesManageController,
    ],
};

export default PreferencesManageComponent;
