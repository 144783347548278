<div class="cr-kitchen-master-details">
    <div class="kitchens-list" *ngIf="!kitchenId || !isMobile">
        <section class="list-header">
            <div class="search-wrapper">
                <div class="search-box">
                    <div class="icon-cr-search cta"></div>
                    <input
                        class="search-field"
                        [placeholder]="text.searchByName"
                        (input)="handleSearch($event.target.value)"
                        type="text"
                    />
                </div>
            </div>
            <div class="name-header" (click)="handleSort()">
                <span>{{ text.columns.name }}</span>
                <span class="icon icon-cr-sort-{{ this.params.sort === 'asc' ? 'ascending' : 'descending' }}"></span>
            </div>
        </section>
        <section class="loading-indicator" *ngIf="isLoading">
            <cr-spinner></cr-spinner>
        </section>
        <section class="kitchen-list-wrapper" *ngIf="!isLoading && !isError && filteredKitchens.length">
            <div class="kitchen-in-list"
                [ngClass]="{ selected: kitchenId && kitchenId === kitchen.id }"
                *ngFor="let kitchen of filteredKitchens"
                (click)="onKitchenSelected(kitchen)"
            >
                <cr-img-holder type="sticker" size="icon" [circleImage]="true" [images]="kitchen.poiImages"></cr-img-holder>
                <div class="kitchen-props-wrapper">
                    <div class="name-and-poi">
                        <div class="kitchen-name">{{ kitchen.name }}</div>
                        <div class="kitchen-poi">{{ kitchen.poiName }}</div>
                    </div>
                    <span>{{ kitchen.menuCount }} {{ text.columns.menuCountLabel }}</span>
                </div>
            </div>
        </section>
        <section class="error-message-wrapper" *ngIf="!isLoading && !isError && kitchens.length > 0 && filteredKitchens.length === 0">
            <p>{{ text.noSearchResultsFound }}</p>
        </section>
        <section class="error-message-wrapper" *ngIf="!isLoading && !isError && kitchens.length === 0">
            <p>{{ text.noResultsFound }}</p>
        </section>
        <section class="error-message-wrapper" *ngIf="!isLoading && isError">
            <cr-data-error></cr-data-error>
        </section>
    </div>
    <div class="empty-kitchen-details" *ngIf="!kitchenId && !isMobile">
        <div>
            {{ text.noKitchenSelectedMessage }}
        </div>
    </div>
    <div class="kitchen-details-wrapper" *ngIf="kitchenId">
        <cr-kitchen-details *ngIf="!editKitchen"
                            class="kitchen-details"
                            (onBack)="onKitchenBack()"
                            (onEdit)="onKitchenEdit($event)"
                            (onArchive)="onKitchenArchive($event)"
                            [kitchenid]="kitchenId">
        </cr-kitchen-details>
        <cr-kitchen-manage *ngIf="editKitchen" 
                           class="kitchen-details"
                           (cancel)="onKitchenEditCancel($event)"
                           (saveEvent)="onKitchenEditSave($event)"
                           [edit]="true"
                           [kitchenid]="kitchenId">
        </cr-kitchen-manage>
    </div>
</div>