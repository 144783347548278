import { Component, Inject, OnInit } from '@angular/core';
import {
    AnalyticsService,
    CR_CONSTANTS,
    ErrorLoggingService,
    NavigationService,
    VenueService
} from "../../../../shared";
import { Constants, LocalizedText } from "../../../../core";
import { StateService } from "@uirouter/core";
import { EntityService } from "../../../../shared/services/entity/entity.service";
import { LocaleService } from "../../../../shared/services/locale/locale.service";
import { PoiService } from "../../../../shared/services/poi/poi.service";
import { ToastService } from "../../../../ng1-factories";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import text from '../../resources/locale/en.json';
import {
    daysList,
    difficultyLabelList,
    iconTypeList,
    statusList,
    trailIconList,
    typeList
} from "../../constants/options";
import { getAutoStatusInfo, getIconUrl, timeTo24HourDecimal } from "../../common/common.util";
import _ from "lodash";
import {
    FormValues,
    paramValue,
    PoiExtension,
    PoiModel,
    SNOAAExtensionValue,
    SNOAALegacyExtensionValue
} from "../../common/types";
import { Locale } from "../../../../shared/services/locale/locale.model";
import { EntityType } from "../../../../shared/models/entity.model";
import { InternalLegacyStatuses, SNOAA_Type } from "../../constants/statuses";

@Component({
    selector: 'cr-add',
    templateUrl: './add.component.html',
    styleUrls: ['./add.component.scss']
})
export class AddComponent implements OnInit {

    text: LocalizedText = text;
    isLoading = false;
    isLift = false;
    isTrail = false;
    isActivity = false;
    isSaving = false;
    hasDataError = false;
    isAdd = false;
    title: string;
    poi: PoiModel;
    locale: Locale;
    poiExtension: SNOAAExtensionValue;
    legacyStatus: SNOAALegacyExtensionValue;
    params: paramValue;
    addForm: FormGroup;
    poiList: PoiModel[];
    iconSrc = '';

    timezone: string;
    selectedIconType: string;
    selectedTrailIcon: string;
    selectedDifficultyId: string;
    type: string;
    isGlades: boolean;
    isGrooming: boolean;
    isMoguls: boolean;
    isNightSkiing: boolean;
    isSnowMaking: boolean;
    operationStatus: any = {label: '', status: '', color: ''};

    statusList = statusList;
    typeOptions = typeList;
    difficultyOptions = difficultyLabelList;
    iconTypeOptions = iconTypeList;
    trailIconOptions = trailIconList;
    daysOptions = daysList;

    constructor(
        @Inject(CR_CONSTANTS) public constants: Constants,
        public state: StateService,
        public crEntityService: EntityService,
        private crLocaleService: LocaleService,
        private crPoiService: PoiService,
        private crNavigationService: NavigationService,
        private crErrorLoggingService: ErrorLoggingService,
        private crToastService: ToastService,
        private crAnalyticsService: AnalyticsService,
        private formBuilder: FormBuilder,
        venueService: VenueService,
    ) {
        this.params = {
            "page": 1,
            "perPage": 0,
            "sort": "asc",
            "sortby": "name",
            "search": "",
            "venueId": state.params.venueId,
            "category": "",
            "state": "ACTIVE"
        };
        this.timezone = venueService.getTimezone(state.params.venueId).name;

        this.type = this.state.params.tabId;
        this.isLift = this.type === SNOAA_Type.LIFT;
        this.isTrail = this.type === SNOAA_Type.TRAIL;
        this.isActivity = this.type === SNOAA_Type.ACTIVITY;
    }

    ngOnInit(): void {
        this.setTitle();
        this.locale = this.crLocaleService.getLocale(this.state.params.venueId, this.state.params.locale);

        this.addForm = this.formBuilder.group({
            mountainArea: ['', Validators.required],
            waitTime: [''],
            ...(this.daysOptions.reduce((controls, opt) => ({
                ...controls,
                [opt.key + '_open']: [''],
                [opt.key + '_close']: [''],
            }), {}))
        }, {validators: this.createRangeValidator.bind(this)});

        this.getData(EntityType.POIS, this.state.params.poiId, this.state.params.venueId, this.locale.current);
    }

    setTitle() {
        if (this.isLift) this.title = text.addLiftTitle;
        if (this.isTrail) this.title = text.addTrailTitle;
        if (this.isActivity) this.title = text.addActivityTitle;
    }

    getData(entityType, id, venueId, locale) {
        this.isLoading = true;

        this.crEntityService.getEntity(entityType, id, venueId, locale, entityType)
            .then((poi: PoiModel) => {
                this.poi = poi;
                this.poiExtension = {};
                this.poiExtension.hours = {};
                this.poiExtension.type = this.type;
                this.poiExtension.SNOAAType = this.type;
                const legacyData = this.poi?.extensions.find(e => e.id === InternalLegacyStatuses.INTERNAL_LEGACY_CURRENT_STATUS);
                this.legacyStatus = legacyData?.value || {};
                this.operationStatus.status = '';
            })
            .catch((err) => {
                this.crErrorLoggingService.logError('Could not get poi details', err, id);

                if (err.status === 404) {
                    this.state.go('client.404', {
                        customerId: this.state.params.customerId,
                        venueId,
                    });
                } else {
                    this.hasDataError = true;
                }
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    goBack() {
        if (this.isAdd) {
            this.crAnalyticsService.track('Edit Canceled');
            this.state.go('client.lifts-trails-activities.list', {
                tabId: this.state.params.tabId
            });
        }
    }

    onCancel() {
        this.crAnalyticsService.track('Edit Canceled');
        this.state.go('client.lifts-trails-activities.list', {
            tabId: this.state.params.tabId
        });
    }

    onSave() {
        const newValues: FormValues = this.addForm.value;

        if (!newValues.mountainArea) {
            this.hasDataError = true;
            this.addForm.markAllAsTouched();
            return;
        }

        this.hasDataError = false;
        this.isSaving = true;

        this.poiExtension.mountainArea = newValues.mountainArea;
        this.setHoursToExtension();

        console.log(newValues.waitTime);
        this.legacyStatus.waitTime = newValues.waitTime;
        this.poiExtension.glades = this.isGlades;
        this.poiExtension.grooming = this.isGrooming;
        this.poiExtension.moguls = this.isMoguls;
        this.poiExtension.nightSkiing = this.isNightSkiing;
        this.poiExtension.snowMaking = this.isSnowMaking;
        this.poiExtension.SNOAAType = this.type;
        this.poiExtension.type = this.selectedIconType;
        this.poiExtension.trailIcon = this.selectedTrailIcon;
        this.poiExtension.difficulty = this.selectedDifficultyId;
        this.poiExtension.name = this.poi.title;

        const extensions = [...this.poi.extensions, {id: this.buildExtensionId(), value: this.poiExtension}];
        const legacyData = extensions.find(e => e.id === InternalLegacyStatuses.INTERNAL_LEGACY_CURRENT_STATUS);
        if (!legacyData) {
            extensions.push({id: 'internalLegacyCurrentStatus', value: this.legacyStatus});
        }

        const poiToSave = {
            ...this.poi,
            extensions: extensions.map(ex => ({...ex, value: JSON.stringify(ex.value)}))
        };

        const payload = this.crEntityService.prepareEntityPayload(poiToSave); console.log(payload);
        const {venueId} = this.state.params;
        this.crEntityService
            .updateEntity(EntityType.POIS, this.poi.id, payload, venueId)
            .then(() => {
                this.crToastService.toast('Data successfully saved.', '');
                this.state.go('client.lifts-trails-activities.details', {
                    id: this.poi.id,
                    tabId: this.state.params.tabId
                });
            })
            .finally(() => this.isSaving = false);
    }

    setHoursToExtension() {
        const newValues: FormValues = this.addForm.value;
        this.poiExtension.hours = this.poiExtension.hours ?? {};
        this.daysOptions.forEach(opt => {
            const timeRange = this.poiExtension.hours?.[opt.key] ?? {};
            timeRange.open = newValues[opt.key + '_open'];
            timeRange.close = newValues[opt.key + '_close'];
            this.poiExtension.hours[opt.key] = timeRange;
        });
    }

    buildExtensionId() {
        if (this.isLift)
            return 'internalLift';
        if (this.isTrail)
            return 'internalTrail';

        return 'internalActivity';
    }

    createRangeValidator(fg: FormControl) {
        const errors: any = {};
        for (const {key} of this.daysOptions) {
            const open = fg.get(`${key}_open`);
            const close = fg.get(`${key}_close`);

            if (open.value || close.value) {
                if (!open.value) {
                    errors[`${key}_open_error`] = this.text.invalidTimeRange;
                } else if (!close.value) {
                    errors[`${key}_close_error`] = this.text.invalidTimeRange;
                } else {
                    const start = timeTo24HourDecimal(open.value);
                    const end = timeTo24HourDecimal(close.value);
                    if (start > end) {
                        errors[`${key}_close_error`] = this.text.invalidTimeRange;
                    }
                }
            }
        }
        return errors;
    }

    setOperationStatusValues() {
        this.operationStatus = (this.poiExtension.autoStatus)
            ? getAutoStatusInfo(this.poiExtension, this.text, this.timezone)
            : {
                label: this.poiExtension.statusLabel ?? '',
                status: this.legacyStatus.operationalStatus?.toLowerCase() ?? '',
                color: this.poiExtension.statusColor
            };

        this.statusList = this.statusList
            .filter(s => (s.visibleIn.includes(this.state.params.tabId)))
            .map(s => ({
                ...s,
                isSelected: s.id === this.operationStatus.label
            }));
    }

    onToggleAutoStatus() {
        this.poiExtension.autoStatus = !this.poiExtension.autoStatus;
        this.setOperationStatusValues();
    }

    onChangeOperationStatus(labelId) {
        this.poiExtension.autoStatus = false;
        this.poiExtension.statusLabel = labelId;

        if (labelId) {
            const newStatus = statusList.find(s => s.id === labelId);
            this.poiExtension.statusLabel = newStatus?.id;
            this.poiExtension.statusColor = newStatus?.color;
            this.legacyStatus.operationalStatus = newStatus?.type;
        }

        this.setOperationStatusValues();
    }

    onChangeIcon() {
        this.iconSrc = this.selectedIconType ? getIconUrl(this.poiExtension.SNOAAType, this.selectedIconType, this.text.imageUrl) : getIconUrl(this.poiExtension.SNOAAType, this.selectedTrailIcon, this.text.imageUrl);
    }

    checkAutoStatus() {
        if (this.poiExtension.autoStatus) {
            this.setHoursToExtension();
            this.operationStatus = getAutoStatusInfo(this.poiExtension, this.text, this.timezone);

            this.statusList = this.statusList
                .filter(s => (s.visibleIn.includes(this.state.params.tabId)))
                .map(s => ({
                    ...s,
                    isSelected: s.id === this.operationStatus.label
                }));
        }
    }

}
