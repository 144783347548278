import angular from 'angular';

import PoiListModule from './poi-list/poi-list';
import PoiMapModule from './poi-map/poi-map';
import PoiTypeListModule from './poi-type-list/poi-type-list';
import PoiTypeInventoryModule from './poi-type-inventory/poi-type-inventory';
import PoiDetailsModule from './poi-details/poi-details';
import PoiEditModule from './poi-edit/poi-edit';
import PoiPhonePreview from './poi-phone-preview/poi-phone-preview';

const ComponentsModule = angular.module('control-room.poi.components', [
    PoiListModule.name,
    PoiMapModule.name,
    PoiTypeListModule.name,
    PoiTypeInventoryModule.name,
    PoiDetailsModule.name,
    PoiEditModule.name,
    PoiPhonePreview.name,
]);

export default ComponentsModule;
