import template from './cvt-issues-section.html';
import './cvt-issues-section.less';

const component = {
    template,
    bindings: {
        section: '<',
    },
};

export default component;
