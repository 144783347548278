<div class="container">
    <div class="banner" @insertRemoveTrigger *ngIf="showBanner">
        <div class="message">{{ text.inactiveMessage }}</div>
        <div class="ctas">
            <button class="btn" (click)="dismissBanner()">
                {{ text.dismiss }}
            </button>
            <button class="btn" (click)="removeInactiveEvents()">
                {{ text.removeEvents }}
            </button>
        </div>
    </div>

    <div class="header">
        <a class="link" (click)="edit()">
            <span class="icon-cr-edit" data-local-qa-id="featured-edit"></span>
        </a>
    </div>
    <div class="content">
        <div class="left">
            <section class="section">
                <h4 class="title">{{ text.featured }}</h4>
                <div *ngIf="!loading; else spinner">
                    <div *ngIf="hasFeatured; else noFeatured">
                        <div
                            class="event"
                            *ngIf="events.featured[0]"
                            data-local-qa-id="featured-event"
                        >
                            <div
                                class="event-link"
                                (click)="goToEvent(events.featured[0].id)"
                            >
                                {{ events.featured[0].title }}
                            </div>
                        </div>
                        <section
                            class="inactive-events"
                            *ngIf="inactiveEvents.featured"
                        >
                            <div class="inactive-title">
                                {{ text.inactive }}
                            </div>
                            <div class="inactive-event">
                                <div
                                    class="event-link"
                                    (click)="
                                        goToEvent(inactiveEvents.featured.id)
                                    "
                                >
                                    {{ inactiveEvents.featured.title }}
                                </div>
                            </div>
                        </section>
                    </div>
                    <ng-template #noFeatured
                        >{{ text.noResultsFound }}.</ng-template
                    >
                </div>
                <ng-template #spinner
                    ><cr-spinner small="true"></cr-spinner
                ></ng-template>
            </section>
            <hr />
            <section class="section">
                <h4 class="title">{{ text.trending }}</h4>
                <div *ngIf="!loading; else spinner">
                    <div *ngIf="hasTrending; else noTrending">
                        <div
                            class="event"
                            *ngFor="let trendy of events.trending; index as i"
                            [attr.data-local-qa-id]="'trending-' + i"
                        >
                            <div
                                class="event-link"
                                (click)="goToEvent(trendy.id)"
                            >
                                {{ trendy.title }}
                            </div>
                        </div>
                        <section
                            class="inactive-events"
                            *ngIf="inactiveEvents.trending.length"
                        >
                            <div class="inactive-title">
                                {{ text.inactive }}
                            </div>
                            <div
                                class="inactive-event"
                                *ngFor="let event of inactiveEvents.trending"
                            >
                                <div
                                    class="event-link"
                                    (click)="goToEvent(event.id)"
                                >
                                    {{ event.title }}
                                </div>
                            </div>
                        </section>
                    </div>
                    <ng-template #noTrending
                        >{{ text.noResultsFound }}.</ng-template
                    >
                </div>
                <ng-template #spinner
                    ><cr-spinner small="true"></cr-spinner
                ></ng-template>
            </section>
        </div>
        <div class="right">
            <cr-phone>
                <cr-featured-events-phone-preview
                    [featuredEvents]="events"
                ></cr-featured-events-phone-preview>
            </cr-phone>
        </div>
    </div>
</div>
