import Controller from './event-schedule-viewer.controller';
import template from './event-schedule-viewer.html';
import './event-schedule-viewer.less';

const Component = {
    template,
    bindings: {
        scheduleData: '<',

        customClass: '@?',
    },
    controller: ['$state', 'crConstants', 'crVenueService', 'crScheduleService', Controller],
};

export default Component;
