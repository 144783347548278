import controller from './fnb-archive-modal.controller';
import template from './fnb-archive-modal.html';
import './fnb-archive-modal.less';

const component = {
    template,
    bindings: {
        close: '&',
        dismiss: '&',
        resolve: '<',
    },
    controller: ['$state', '$q', 'crFnbArchiveModalText', 'crLocaleService', 'crMenusService', controller],
};

export default component;
