import { Component, Inject, OnInit } from '@angular/core';
import { CR_CONSTANTS, ErrorLoggingService, VenueService } from "../../shared";
import { Constants, LocalizedText } from '../../core';
import text from './resources/locale/en.json';
import { PoiService } from "../../shared/services/poi/poi.service";
import { StateService } from "@uirouter/core";
import _ from "lodash";
import { POI } from "../../shared/models/poi.model";
import { EntityType } from "../../shared/models/entity.model";
import { EntityService } from "../../shared/services/entity/entity.service";
import { ToastService } from "../../ng1-factories";
import { PoiExtension, PoiModel } from "./common/types";

@Component({
    selector: 'cr-lifts-trails-activities',
    templateUrl: './lifts-trails-activities.component.html',
    styleUrls: ['./lifts-trails-activities.component.scss']
})
export class LiftsTrailsActivitiesComponent implements OnInit {

    text: LocalizedText;
    isLoading = false;
    isSaving = false;
    isAdd = false;
    accessError: any;
    dataError = false;
    params: any;
    poiList: POI[];
    filteredPoi: PoiModel[];
    timezone: string;
    currentTabId = 'lift';
    defaultType = this.state.params.tabId ?? 'lift';

    constructor(
        @Inject(CR_CONSTANTS) public constants: Constants,
        private crPoiService: PoiService,
        public state: StateService,
        private crErrorLoggingService: ErrorLoggingService,
        private crEntityService: EntityService,
        private crToastService: ToastService,
        venueService: VenueService,
    ) {
        this.text = text as LocalizedText;
        this.params = {
            "page": 1,
            "perPage": 0,
            "sort": "asc",
            "sortby": "name",
            "search": "",
            "venueId": state.params.venueId,
            "category": "",
            "state": "ACTIVE"
        };
        this.currentTabId = state.params.tabId || 'lift';
        this.timezone = venueService.getTimezone(state.params.venueId).name;
    }

    ngOnInit(): void {
        this.getData();
        this.getPoiList();
    }

    getPoiList() {
        this.isLoading = true;

        const queryParams: any = _.pickBy(this.params, (value) => !_.isNil(value) && value !== '' && value !== 'all');
        return this.crPoiService.getPois(queryParams)
            .then((data: any) => data.content as PoiModel[])
            .then((data) => this.filteredPoi = data
                .filter(p => !p.extensions.find((e: PoiExtension) => (/^(internalLift|internalTrail|internalActivity)/).exec(e.id))))
            .catch((err) => {
                this.filteredPoi = [];
                this.crErrorLoggingService.logError('Could not get lifts data.', err);
            })
            .finally(() => this.isLoading = false);
    }

    getData() {
        this.isLoading = true;
        this.dataError = false;

        const queryParams = _.pickBy(this.params, (value) => !_.isNil(value) && value !== '' && value !== 'all');
        return this.crPoiService.getPois(queryParams)
            .then((data) => this.poiList = data.content)
            .catch((err) => {
                this.dataError = true;
                this.poiList = [];
                this.crErrorLoggingService.logError('Could not get lifts data.', err);
            })
            .finally(() => this.isLoading = false);
    }

    public savePoiFunction = this.savePoi.bind(this);

    private savePoi(poiToSave) {
        this.isSaving = true;
        return new Promise((resolve, reject) => {
            const payload = this.crEntityService.prepareEntityPayload(poiToSave);
            const {venueId} = this.state.params;
            this.crEntityService
                .updateEntity(EntityType.POIS, poiToSave.id, payload, venueId)
                .then(() => {
                    this.crToastService.toast(`Data successfully updated.`, 10);
                    resolve(poiToSave);
                })
                .catch((err) => {
                    this.handleError('Could not update poi', err);
                    reject(err);
                })
                .finally(() => this.isSaving = false);
        });
    }

    private handleError(couldNotUpdatePoi: string, err: any) {
        this.crErrorLoggingService.logError(couldNotUpdatePoi, err);
    }

    setTab(tabId: string) {
        this.defaultType = tabId;
        this.state.go('.', { tabId: tabId }, { location: 'replace' });
        this.isAdd = false;
    }

    onSelectPoi(poi) {
        this.state.go('client.lifts-trails-activities.add', {
            poiId: poi.id,
            tabId: this.state.params.tabId || 'lift'
        });
    }
}
