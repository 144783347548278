<div
  class="chip"
  [ngStyle]="{
    'background-color': bgColor,
    color: labelColor,
    border: customBorder
  }"
>
  {{ label }}
</div>
