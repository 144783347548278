<cr-title-bar icon="icon-cr-back" label="Bundle" (onClick)="onBack()">
  <div titleBarRight>
    <div class="title-bar-buttons">
      <div
        class="icon-cr-edit title-bar-button"
        (click)="editBundleItem()"
      ></div>
      <div class="title-bar-button" dropdown>
        <div
          class="icon-cr-more"
          id="more-dropdown"
          dropdownToggle
          (click)="(false)"
          aria-controls="basic-link-dropdown"
        ></div>
        <ul
          id="basic-link-dropdown"
          *dropdownMenu
          class="dropdown-menu"
          role="menu"
          aria-labelledby="more-dropdown"
        >
          <li>
            <a
              class="dropdown-item"
              (click)="stateChangeClick(stateChangeTo)"
              >{{ stateChangeTo }}</a
            >
          </li>
          <li>
            <a
              class="dropdown-item"
              (click)="openDeleteModal(deleteTemplate)"
              >{{ text.archive }}</a
            >
          </li>
          <li class="divider"></li>
          <li>
            <a class="dropdown-item" (click)="createBundleCopy()">{{
              text.saveCopy
            }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</cr-title-bar>
<div *ngIf="item">
  <section class="bundle-header bundle-section">
    <div class="bundle-img-small">
      <cr-img-holder [src]="stickerImageSrc" type="sticker" [roundedCorners]="true"></cr-img-holder>
    </div>
    <div>
      <div class="bundle-headline">{{ item?.displayName }}</div>
      <div id="bundle-id">{{ item?.id }}</div>
      <cr-chip [label]="item?.state" type="state"></cr-chip>
    </div>
  </section>

  <!-- Content Section -->
  <section class="bundle-section bundle-content">
    <div>
      <div class="bundle-section-title">{{ text.content }}</div>
      <div class="descriptions">
        <div class="bundle-label">{{ text.title }}</div>
        <div class="bundle-value">{{ item?.content.name }}</div>
      </div>
      <div class="descriptions">
        <div class="bundle-label">{{ text.description }}</div>
        <div class="bundle-value">{{ item.content.description }}</div>
      </div>
    </div>
    <div class="bundle-img-main">
      <div class="bundle-label">{{ text.images }}</div>
      <cr-img-holder [src]="mainImageSrc" type="main"></cr-img-holder>
    </div>
  </section>

  <!-- Presentation Secton -->
  <section class="bundle-section">
    <div class="bundle-section-title">{{ text.presentation }}</div>
    <div class="bundle-label">{{ text.category }}</div>
    <div class="descriptions categories">
      <div *ngFor="let category of item?.categories" class="category-chip">
        <cr-chip [label]="category.displayName"></cr-chip>
      </div>
    </div>
  </section>

  <!-- Item Details Section -->
  <section class="bundle-section">
    <div class="bundle-section-title">{{ text.itemDetails }}</div>
    <div class="descriptions">
      <div class="bundle-label">{{ text.basePrice }} ({{ currencyCode }})</div>
      <div class="bundle-value">{{ item.price }}</div>
    </div>
    <div class="descriptions">
      <div class="bundle-label">{{ text.taxCode }}</div>
      <div class="bundle-value">{{ item.productCode }}</div>
    </div>
    <div class="descriptions">
      <div class="bundle-label">{{ text.maxQuantity }}</div>
      <div class="bundle-value">{{ item.maxQuantity }}</div>
    </div>
  </section>

  <!-- Bundle Groups Section -->
  <section class="bundle-section">
    <div class="bundle-section-title">{{ text.bundleGroups }}</div>
    <div *ngFor="let bundle of bundleGroups; let last = last">
      <div class="bundle-group" [ngClass]="{ 'bundle-group-last': last }">
        <div class="bundle-group-name">{{ bundle.displayName }}</div>
        <div class="bundle-group-values">
          <div class="bundle-label">{{ text.bundleGroupRequired }}</div>
          <div class="bundle-values">{{ bundle.min }}</div>
          <div class="bundle-label">{{ text.menuItems }}</div>
          <div *ngFor="let item of bundle.bundledProducts">
            <div class="bundle-values">
              {{ item.displayName }} ({{ text.bundleProductMax }}
              {{ item.maxQuantity }}, {{ text.bundleProductUpsell }}
              {{ item.upsellPrice }})
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Point of Sale Section -->
  <section class="bundle-section">
    <div class="bundle-section-title">{{ text.pointOfSale }}</div>
    <div class="descriptions">
      <div class="bundle-label">{{ text.externalId }}</div>
      <div class="bundle-value">{{ item.externalId }}</div>
    </div>
    <div class="descriptions">
      <div class="bundle-label">{{ text.pricingCode }}</div>
      <div class="bundle-value">{{ item.externalPriceCode }}</div>
    </div>
  </section>
  <div class="bundle-label">{{ item.lastChanged }}</div>
</div>

<ng-template #deleteTemplate>
  <div class="modal-body text-center">
    <p>{{ text.archiveMessage }}</p>
    <button type="button" class="btn btn-default" (click)="deleteCanceled()">
      Cancel
    </button>
    <button type="button" class="btn btn-primary" (click)="deleteConfirm()">
      Archive
    </button>
  </div>
</ng-template>
