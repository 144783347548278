import text from './resources/locale/en.json';

class When {
    constructor($state, crConfig, crConstants, crScheduleService) {
        this.$state = $state;
        this.crConfig = crConfig;
        this.crConstants = crConstants;
        this.crScheduleService = crScheduleService;

        this.text = text;
    }

    $onInit() {
        this.duration = {
            units: 'minutes',
        };

        if (this.experience.expires.kind === 'DURATION') {
            const s = this.experience.expires.secs;

            if (s % 86400 === 0) {
                // is a number of days
                this.duration.units = 'days';
                this.duration.value = s / 86400;
            } else if (s % 3600 === 0) {
                // is a number of hours
                this.duration.units = 'hours';
                this.duration.value = s / 3600;
            } else {
                // gotta be minutes
                this.duration.units = 'minutes';
                this.duration.value = Math.floor(s / 60);
            }
        } else if (this.experience.expires.kind === 'TIME') {
            this.expiresDate = this.experience.expires.at;
            this.expiresTime = this.crScheduleService.getTimeFromIsoString(this.experience.expires.at);
        }

        this.durationOptions = [
            {
                label: this.text.minutes,
                id: 'minutes',
            },
            {
                label: this.text.hours,
                id: 'hours',
            },
            {
                label: this.text.days,
                id: 'days',
            },
        ];
        this.durationValidators = [
            {
                type: 'MIN_VALUE',
                value: 0,
            },
        ];

        this.durationErrorMessages = {
            minValue: this.text.durationMinValueError,
        };

        this.durationOptions.forEach((option) => {
            option.isSelected = option.id === this.duration.units;
        });

        this.showSchedule = this.experienceType.active.type !== 'NOW' && this.experienceType.active.type !== 'ALWAYS';
        this.endOfDay = this.crConfig.customer.endOfDay;
    }

    isExpirationTypeEnabled(type) {
        return (
            !this.experienceType.disabledExpirationTypes || !this.experienceType.disabledExpirationTypes.includes(type)
        );
    }

    isAllExpirationControlsDisabled() {
        return !(this.isExpirationTypeEnabled('TIME')
            || this.isExpirationTypeEnabled('DAILY')
            || this.isExpirationTypeEnabled('DURATION'))
    }

    setExpiresKind(kind) {
        this.experience.expires.kind = kind;
        if (kind === 'DAILY') {
            this.experience.expires.secs = null;
            this.experience.expires.at = null;

            this.experience.expires.daily = this.endOfDay;
        } else if (kind === 'TIME') {
            this.experience.expires.secs = null;
            this.experience.expires.daily = null;

            this.updateTime();
        } else {
            this.experience.expires.at = null;
            this.experience.expires.daily = null;

            this.updateDuration();
        }

        this.form.$setDirty();
    }

    onExpiresDailyUpdate(event) {
        this.experience.expires.daily = event.model;
        this.form.$setDirty();
    }

    onExpiresDurationValueUpdate(event) {
        this.duration.value = event.model;
        this.updateDuration();
    }

    onExpiresDurationUnitsUpdate(event) {
        this.duration.units = event.model[0].id;
        this.updateDuration();
    }

    updateDuration() {
        if (this.duration.units === 'days') {
            this.experience.expires.secs = this.duration.value * 86400;
        } else if (this.duration.units === 'hours') {
            this.experience.expires.secs = this.duration.value * 3600;
        } else {
            this.experience.expires.secs = this.duration.value * 60;
        }
    }

    onExpiresDateUpdate(event) {
        this.expiresDate = event.model;
        this.updateTime();
    }

    onExpiresTimeUpdate(event) {
        this.expiresTime = event.model;
        this.updateTime();
    }

    updateTime() {
        if (this.expiresDate && this.expiresTime) {
            this.experience.expires.at = this.crScheduleService.getCombinedDateTimeIsoString(
                this.expiresDate,
                this.expiresTime
            );
        }
    }
}

export default When;
