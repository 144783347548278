import _ from 'lodash';

class ModifierGroupsListService {
    constructor(crEntityService, crConstants) {
        this.crEntityService = crEntityService;
        this.crConstants = crConstants;
    }

    getData(menuId, params) {
        const queryParams = _.pickBy(params, (value) => !_.isNil(value) && value !== '' && value !== 'all');

        const type = this.crConstants.entity.types.MENU_MODIFIER_GROUPS;
        const route = `menus/${menuId}/${type}`;

        return this.crEntityService.getEntityList(route, queryParams);
    }

    mapToRowsModel(groups) {
        return groups.map((group) => ({
            data: {
                id: group.id,
                name: group.content.name || '**Missing Name property**',
                displayName: group.displayName,
                modifiers: group.modifiers
                    .map((mod) => `${mod.displayName} (${parseFloat(mod.price).toFixed(2)})`)
                    .join(', '),
                missingTranslations: _.isArray(group.missingTranslations) ? group.missingTranslations.join(', ') : '',
                originalData: group,
            },
        }));
    }
}

export default ModifierGroupsListService;
