import {
  Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import _ from 'lodash';
import { constants } from '../..';

@Component({
  selector: 'cr-weekday-editor',
  templateUrl: './weekday-editor.component.html',
  styleUrls: ['./weekday-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class WeekdayEditorComponent implements OnInit {
  weekdays: string[];

  control: FormControl;

  @Input()
  name: string;

  @Input()
  customClass: string;

  @Input()
  crRequired: boolean;

  @Input()
  disabled: boolean;

  @Input()
  selectedDays: string[];

  @Output()
  selectedDaysEmitter: EventEmitter<string[]> = new EventEmitter<string[]>();

  constructor() {
    this.weekdays = constants.iCal.weekdays;
  }

  ngOnInit(): void {
    const validators = [];
    if (this.crRequired) {
      validators.push(Validators.required);
    }
    this.control = new FormControl(this.selectedDays, validators);
  }

  update(weekday) {
    if (this.disabled) {
      return;
    }

    if (!this.selectedDays) {
      this.selectedDays = [];
    }
    // Add or remove the weekday from the list
    if (!_.includes(this.selectedDays, weekday)) {
      this.selectedDays.push(weekday);
    } else {
      _.pull(this.selectedDays, weekday);
    }
    this.control.markAsTouched();
    this.control.setValue(this.selectedDays);
    this.selectedDaysEmitter.emit(this.selectedDays);
  }

  isSelected(weekday) {
    return _.includes(this.selectedDays, weekday);
  }
}
