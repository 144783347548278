import angular from 'angular';

import icons from './resources/icons.json';
import component from './icons.component';

const Icons = angular
    .module('control-room.internal.components.icons', [])
    .constant('crIconNames', icons)
    .component('crIcons', component);

export default Icons;
