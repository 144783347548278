import angular from 'angular';
import text from './resources/locale/en.json';
import SearchComponent from './search.component';

const SearchModule = angular
    .module('control-room.commons.form.components.search', ['ui.bootstrap.dropdown'])
    .constant('searchText', text)
    .component('crSearch', SearchComponent);

export default SearchModule;
