import Controller from './manage-category.controller';
import template from './manage-category.html';
import './manage-category.less';

const Component = {
    template,
    bindings: {
        entityType: '@',
        edit: '@?',
        iconRequired: '<?',
        key: '@?',
    },
    controller: [
        '$q',
        '$state',
        '$uibModal',
        'crConstants',
        'crConfig',
        'crEntityService',
        'crLocaleService',
        'crImageService',
        'crNavigationService',
        'crErrorLoggingService',
        'crAnalyticsService',
        Controller,
    ],
};

export default Component;
