import _ from 'lodash';

class DataFilter {
    constructor($state, crVenueService, crToastService, crAnalyticsService) {
        this.$state = $state;
        this.crVenueService = crVenueService;
        this.crToastService = crToastService;
        this.crAnalyticsService = crAnalyticsService;
    }

    $onInit() {
        this.isLoading = true;
        this.dataError = false;

        this.venueTimezone = this.crVenueService.getTimezone(this.$state.params.venueId);

        this.params = {
            venueId: this.$state.params.venueId,
            search: this.$state.params.search,
        };

        // See if we need to show a toast when coming to this page
        this.crToastService.autoToast(this.$state.params);

        this.debounceUpdateData = _.debounce(this.updateData, 350);
    }

    $postLink() {
        this.handleDeepLink();
        this.updateData();
    }

    registerParam(paramName, value) {
        this.params[paramName] = value;
    }

    updateParam(key, event) {
        this.params[key] = _.isEmpty(event.model) ? '' : event.model[0].id;
        this.updateData();
    }

    updateData() {
        return this.getData(this.params);
    }

    updateSearch(term = '') {
        this.params.search = term;

        if (term.length === 0 || term.length >= 3) {
            this.debounceUpdateData();
        } else {
            this.setShortSearchTimer(term);
        }
    }

    setShortSearchTimer(term) {
        setTimeout(() => {
            if (term === this.params.search) {
                this.debounceUpdateData();
            }
        }, 1000);
    }

    updateSearchDeepLink() {
        const { search } = this.params;
        if (search !== this.$state.params.search) {
            this.$state.go(this.$state.current.name, { search }, { location: 'replace' });

            if (search) {
                this.crAnalyticsService.track('Search', { term: search });
            }
        }
    }

    handleDeepLink() {
        const truthyParams = _.pickBy(this.$state.params, (p) => !_.isNil(p));

        _.forOwn(truthyParams, (value, key) => {
            const hasKey = _.has(this.params, key);

            if (hasKey) {
                this.params[key] = value;
            }
        });
    }
}

export default DataFilter;
