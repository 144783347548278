import _ from 'lodash';

class PlacesListService {
    constructor(PlacesService, CommerceService) {
        this.PlacesService = PlacesService;
        this.CommerceService = CommerceService;
    }

    getData(params) {
        return this.getPlaces(params).then((data) => {
            const placeIds = _.map(data.places, (place) => place.id);

            return this.CommerceService.ordersReports(placeIds, params.timeframe, null, null, null, true).then(
                (orders) => ({
                    search: data.query.search,
                    places: data.places,
                    orders,
                })
            );
        });
    }

    getPlaces(params) {
        const queryParams = _.pickBy(params, (value, key) => !_.isNil(value) && value !== '' && key !== 'timeframe');

        return this.PlacesService.getPlaces(queryParams).then((res) => res);
    }

    mapToRowsModel(places, commerceData) {
        let row;
        const commerceMap = _.groupBy(commerceData, 'placeId');

        return places.map((place) => {
            row = {
                data: {
                    name: {
                        label: place.name,
                    },
                    state: {
                        icon: 'cr-mobile',
                        isEnabled: place.features.commerceEnabled,
                        label: place.features.commerceEnabled ? 'Enabled' : 'Disabled',
                    },
                    id: place.id,
                    organization: place.organization.name,
                    orders: _.first(commerceMap[place.id]).orderCount.reduce((prev, curr) => prev + curr.count, 0),
                },
            };

            return row;
        });
    }
}

export default PlacesListService;
