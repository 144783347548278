import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImgHolderComponent } from './img-holder.component';
import { CrIconModule } from '../../icon/icon.module';

@NgModule({
  imports: [
    CommonModule,
    CrIconModule,
  ],
  exports: [ImgHolderComponent],
  declarations: [ImgHolderComponent],
})
export class ImgHolderModule { }
