import {FnbFee} from "../services/fees/fees.model";

export interface Place {
    closure: PlaceClosureDto,
    content: PlaceContentDto,
    controls: any,
    createdBy: string,
    createdDate: string,
    externalId: string,
    features: PlaceFeatures,
    hours: PlaceOpenAndCloseSchedule[],
    id: string,
    images: any,
    lastChanged: string,
    location: any,
    modifiedBy: string,
    name: string,
    organization: any,
    tags: string[],
    timeZone: any,
    transientPlace: boolean,
    visibility: any,
    webContent: any,
    printers: Printer[],
    commerceConfiguration: CommerceConfiguration[],
}

export interface PlaceOpenAndCloseSchedule {
    closingTime: ScheduleLocalTime,
    days: Days[] | iCalDays[],
    earlyCheckin: number,
    earlyOrdering: number,
    lateCheckin: number,
    openingTime: ScheduleLocalTime,
    pickupWindowProperties: PickupWindowProperties[],
}

export interface PickupWindowProperties {
    capacity: number,
    endTime: ScheduleLocalTime,
    length: number,
    startTime: ScheduleLocalTime,
}

export enum Days {
    SUNDAY = 'SUNDAY',
    MONDAY = 'MONDAY',
    TUESDAY = 'TUESDAY',
    WEDNESDAY = 'WEDNESDAY',
    THURSDAY = 'THURSDAY',
    FRIDAY = 'FRIDAY',
    SATURDAY = 'SATURDAY',
}

export enum iCalDays {
    SUNDAY = 'SU',
    MONDAY = 'MO',
    TUESDAY = 'TU',
    WEDNESDAY = 'WE',
    THURSDAY = 'TH',
    FRIDAY = 'FR',
    SATURDAY = 'SA',
}

export interface ScheduleLocalTime {
    hour: number,
    minute: number,
    nano: number,
    second: number,
}

interface PlaceClosureDto {
    openDate: any,
    status: string,
}

interface PlaceContentDto {
    address: string,
    addressComponents: PlaceAddressComponents,
    hours: any,
    phoneNumber: string,
    priceDisclaimer: string,
    restaurantNumber: string,
}

interface PlaceAddressComponents {
    street: string,
    street2: string,
    city: string,
    state: string,
    zipCode: string,
    countryCode: string
}

interface PlaceFeatures {
    commerceEnabled: boolean,
    overrideInVenue: boolean,
    specialRequestsEnabled: boolean,
    pickupTimeWindowsEnabled: boolean,
    defaultOrderingScheme: string,
    configurationIdMap: {
      [key: string]: string;
    },
    pickupZones: PickUpZone[],
    applicableFees: string[],
    fees: FnbFee[],
}

interface PickUpZone {
    pickupZoneId: string,
    label: string,
    entryPrompt: string,
    requiresEntry: boolean
    type: string,
    rule: PickUpZoneRule
}

interface PickUpZoneRule {
    sampleInput: string,
    regex: string
}

interface Printer {
    id: string,
    name: string,
    username: string,
    printOnCheckin: boolean,
    categoryIds: any,
}

export interface CommerceConfiguration {
    categoryId: string,
    categoryName: string,
    selectedOption: ConfigSelectedOption,
}

interface ConfigSelectedOption {
    optionId: string,
    optionName: string
}
