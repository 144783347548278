import angular from 'angular';

import PoiTypeInventoryComponent from './poi-type-inventory.component';
import PoiTypeInventoryService from './poi-type-inventory.service';

const PoiTypeInventoryModule = angular
    .module('control-room.poi.components.poi-type-inventory', [])
    .component('crPoiTypeInventory', PoiTypeInventoryComponent)
    .service('crPoiTypeInventoryService', ['crPoiService', 'crImageService', PoiTypeInventoryService]);

export default PoiTypeInventoryModule;
