class TabsController {
    constructor($state, crAnalyticsService, crNavigationService) {
        this.tabs = [];
        this.$state = $state;
        this.crAnalyticsService = crAnalyticsService;
        this.crNavigationService = crNavigationService;
    }

    $postLink() {
        setTimeout(() => {
            this.setActiveTab(this.$state.params.tabId);
        });
    }

    addTab(tab) {
        this.tabs.push(tab);
    }

    setActiveDeepLink(tabId) {
        this.tabs.some((tab) => {
            if (tabId === tab.routeId) {
                tab.active = true;
                return true;
            }
        });
    }

    setActiveTab(routeId) {
        this.tabs.forEach((t) => {
            t.active = t.routeId === routeId;
        });
    }

    selectTab(tab) {
        const params = {
            tabId: tab.routeId,
            customerId: this.$state.params.customerId,
            venueId: this.$state.params.venueId,
            menuId: this.$state.params.menuId,
            placeId: this.$state.params.placeId,
        };
        
        if (this.crNavigationService.conditionalLambda === undefined || !this.crNavigationService.conditionalLambda()) {
            this.$state.go(tab.stateName ? tab.stateName : this.$state.current.name, params, { inherit: false, location: 'replace' }).then(() => {
                this.crAnalyticsService.trackPageView();
                this.setActiveTab(tab.routeId);
            });
        } else {
            this.crNavigationService.openConfirmModal().then(() => {
                this.crNavigationService.disableConfirmNavigation();
                this.$state.go(tab.stateName ? tab.stateName : this.$state.current.name, params, { inherit: false, location: 'replace' }).then(() => {
                    this.crAnalyticsService.trackPageView();
                    this.setActiveTab(tab.routeId);
                });                    
            }).catch(() => null);
        }
    }

    overflowItemSelected(item) {
        const params = {
            customerId: this.$state.params.customerId,
            venueId: this.$state.params.venueId,
            menuId: this.$state.params.menuId,
            placeId: this.$state.params.placeId,
        };

        this.crAnalyticsService.track('More Option Item Selected', { item: item.name });
        this.$state.go(item.state, params);
    }

    trackOverflowMenuClick() {
        this.crAnalyticsService.track('More Option Menu Click');
    }
}

export default TabsController;
