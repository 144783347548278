<div class="metrics-container">
    <div class="header">
        <span class="icon-cr-level"></span>
        <div class="title">{{ text.venueMetricsTitle }}</div>
        <div class="icon-cr-close" (mousedown)="close.emit()"></div>
    </div>
    <div class="content">
        <h5 class="title">{{ text.appUsersTitle }}</h5>
        <div class="metric-container">
            <div class="metric-value">{{ currentUsers | shortNumber }}</div>
            <div class="metric-label">{{ text.currentUsersLabel }}</div>
        </div>
        <div class="metric-container">
            <div class="metric-value">{{ totalUsers | shortNumber }}</div>
            <div class="metric-label">{{ text.totalUsersLabel }}</div>
        </div>
        <hr />
        <h5 class="title">{{ text.experiencesTitle }}</h5>
        <div class="metric-container">
            <div class="metric-value">{{ experiencesSent | shortNumber }}</div>
            <div class="metric-label">{{ text.experiencesSentLabel }}</div>
        </div>
        <div class="footer">{{ text.lastUpdated }} {{ lastUpdated }}</div>
    </div>
</div>
