import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ToastService {
  toasts: Observable<any>;

  private toasts$: Subject<any>;

  constructor() {
    this.toasts$ = new Subject<any>();
    this.toasts = this.toasts$.asObservable();
  }

  autoToast(stateParams) {
    const { toast } = stateParams;

    if (toast && toast.msg) {
      this.toast(toast.msg, toast.timeToLive, toast.customStyle);
      stateParams.toast = null;
      return true;
    }

    return false;
  }

  toast(msg, timeToLive, customStyle?) {
    this.toasts$.next({
      msg,
      timeToLive,
      customStyle,
    });
  }
}
