import _ from 'lodash';
import moment from 'moment';
import 'moment-timezone';

class CalendarDayHeading {
    constructor($scope, $state, crVenueService, crConstants, crAnalyticsService) {
        this.$scope = $scope;
        this.$state = $state;
        this.crVenueService = crVenueService;
        this.crConstants = crConstants;
        this.crAnalyticsService = crAnalyticsService;
    }

    $onChanges(changes) {
        if (changes && this.day) {
            this.day = _.cloneDeep(this.day);
            this.tz = this.crVenueService.getTimezone(this.$state.params.venueId).name;
            this.day.displayDate = moment(this.day.start).tz(this.tz).startOf('day').format('ddd DD');
            this.isDayView = this.day.start === this.$state.params.start && this.day.end === this.$state.params.end;
        }
    }

    updateView() {
        let { start } = this.day;
        let { end } = this.day;

        if (this.isDayView) {
            start = moment(start).tz(this.tz).startOf('week').toISOString();

            // check for last day of week
            if (moment(end).tz(this.tz).isSame(moment(start).tz(this.tz), 'week')) {
                end = moment(end).tz(this.tz).endOf('week').add(1, 'milliseconds').toISOString();
            }
        } else {
            this.crAnalyticsService.track('Calendar Day View Selected', { start });
        }

        this.$state.go(this.$state.current.name, { start, end }, { location: 'replace' }).then(() => {
            this.$scope.$emit(this.crConstants.events.UPDATE_CALENDAR_VIEW, {
                model: { start, end },
            });
        });
    }
}

export default CalendarDayHeading;
