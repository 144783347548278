import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Customer } from './customer.model';

@Injectable({
    providedIn: 'root',
  })
export class CustomerService {
    url: string;

    constructor(private http: HttpClient) {
        this.url = '/rest/customer';
    }

    getCustomer(): Promise<Customer> {
        return this.http.get<Customer>(this.url, { headers : {'cache': 'true'}}).toPromise();
    }

}

export default CustomerService;
