import template from './poi-type-inventory.html';
import './poi-type-inventory.less';
import PoiTypeInventoryController from './poi-type-inventory.controller';

const PoiTypeInventoryComponent = {
    template,
    bindings: {},
    controller: [
        '$state',
        'crPoiTypeInventoryService',
        'crNavigationService',
        'crErrorLoggingService',
        PoiTypeInventoryController,
    ],
};

export default PoiTypeInventoryComponent;
