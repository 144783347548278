import PlacesListController from './places-list.controller';
import template from './places-list.html';
import './places-list.less';

const PlacesListComponent = {
    template,
    controller: [
        '$state',
        'placesListService',
        'crConstants',
        'crAnalyticsService',
        'placesListLocale',
        'crErrorLoggingService',
        PlacesListController,
    ],
};

export default PlacesListComponent;
