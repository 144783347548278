class ReorderList {
    moveRowToTop(from, to, list) {
        this.swapRows(from, to, list);
    }

    moveRow(from, to, list) {
        if (from !== to) {
            // This accounts for dragging up, where technically the new index is 1 less than desired.
            if (to < from) {
                to += 1;
            }

            this.swapRows(from, to, list);
        }
    }

    swapRows(from, to, list) {
        list.splice(to, 0, list.splice(from, 1)[0]);
        this.setDirty();
    }

    setDirty() {
        if (this.form && !this.form.$dirty) {
            this.form.$setDirty();
        }
    }
}

export default ReorderList;
