import EventPhonePreviewController from './event-phone-preview.controller';
import template from './event-phone-preview.html';
import './event-phone-preview.less';

const EventPhonePreviewComponent = {
    bindings: {
        event: '<',
        locale: '<?',
        manage: '<?',
    },
    template,
    controller: [
        'crConstants',
        'crImageService',
        'crScheduleService',
        'crErrorLoggingService',
        EventPhonePreviewController,
    ],
};

export default EventPhonePreviewComponent;
