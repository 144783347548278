import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BsModalService, ButtonsModule } from 'ngx-bootstrap';
import { CrFormsModule } from '../../../../shared';
import { FeesService } from '../../../../shared/services/fees/fees.service';
import { CrSplitBtnModule } from '../../../../shared/split-button/split-button.module';
import { CrTitleBarModule } from '../../../../shared/title-bar/title-bar.module';
import { FeesManageComponent } from './fees-manage.component';

@NgModule({
  imports: [ReactiveFormsModule, BrowserModule, CrTitleBarModule, CrFormsModule, ButtonsModule, CrSplitBtnModule],
  exports: [FeesManageComponent],
  declarations: [FeesManageComponent],
  providers: [FeesService, BsModalService],
  entryComponents: [FeesManageComponent], 
})
export class FeesManageModule { }
