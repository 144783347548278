class ImageEventDirective {
    constructor() {
        this.restrict = 'A';
        this.scope = {
            onLoad: '&',
            onError: '<',
            data: '<?',
        };
    }

    static directiveFactory() {
        return new ImageEventDirective();
    }

    link(scope, elem) {
        elem.on('load', (evt) => {
            scope.onLoad({ event: evt, data: scope.data });
            scope.$apply();
        });

        elem.on('error', (evt) => {
            scope.onError({ event: evt, data: scope.data });
            scope.$apply();
        });
    }
}

export default ImageEventDirective;
