class CvtDashboardService {
    constructor($http) {
        this.$http = $http;
    }

    getExecutionStatuses(venueId) {
        return this.$http.get('/rest/cvt/execution-statuses', { params: { venueId } }).then((res) => res.data);
    }

    getExecution(id, venueId) {
        return this.$http.get(`/rest/cvt/executions/${id}`, { params: { venueId } }).then((res) => res.data);
    }

    startExecution(venueId) {
        return this.$http.post('/rest/cvt/executions', { venueId });
    }
}

export default CvtDashboardService;
