import controller from './kitchen-manage.controller';
import template from './kitchen-manage.html';
import './kitchen-manage.less';

export default {
    template,
    bindings: {
        cancel: '&',
        saveEvent: '&',
        edit: '<?',
        kitchenid: '<?',
    },
    controller: [
        '$state',
        '$uibModal',
        '$q',
        'crKitchenManageService',
        'crKitchenService',
        'crLocaleService',
        'crLocalizationService',
        'crConfig',
        'crAnalyticsService',
        'crErrorLoggingService',
        'crNavigationService',
        'crPlacesService',
        'crLockerService',
        'crConstants',
        'crPrintManagerService',
        'crMenuCategoriesService',
        'crMenusService',
        'crFeesService',
        'crTimeService',
        controller,
    ],
};
