import { Component, Input } from '@angular/core';

import text from './resources/locale/en.json';

@Component({
  selector: 'cr-data-error',
  templateUrl: './data-error.component.html',
  styleUrls: ['./data-error.component.scss'],
})
export class DataErrorComponent {
  @Input()
  icon = 'error-bandaid';

  @Input()
  message: string = text.standardDataErrorMessage;
}
