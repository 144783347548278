import * as _ from 'lodash';
import {
  Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation,
} from '@angular/core';

import { LocalizedText } from '../../../../core';
import { LiveMapService } from '../live-map.service';

import text from './resources/locale/en.json';

@Component({
  selector: 'cr-live-map-app-usage',
  templateUrl: './live-map-app-usage.component.html',
  styleUrls: ['./live-map-app-usage.component.scss'],
  // TODO Properly style dropdown component with its own css and remove encapsulation
  encapsulation: ViewEncapsulation.None,
})
export class LiveMapAppUsageComponent implements OnInit {
  @Input() showingHeatmap: boolean;

  @Output() onTagChange = new EventEmitter<any>();

  @Output() onTagValuesChange = new EventEmitter<any>();

  @Output() showIndividualUsers = new EventEmitter();

  @Output() showHeatmap = new EventEmitter();

  colors: string[];

  currentTab: string;

  selectedTag: any;

  selectedTagValues: any;

  tags: any[];

  text: LocalizedText;

  constructor(private liveMapService: LiveMapService) {
    this.text = text;
  }

  ngOnInit(): void {
    this.currentTab = 'all';
    this.selectedTagValues = {};
    this.colors = ['#BF2265', '#D06701', '#8E6ACC', '#4A88E7'];

    this.liveMapService.tags.subscribe((tags) => {
      this.tags = tags;
    });
  }

  getAvailableColor() {
    for (let i = 0; i < this.colors.length; i++) {
      const color = this.colors[i];
      if (!_.find(this.selectedTagValues, { color })) {
        return color;
      }
    }
  }

  isValuePresent(value) {
    return this.liveMapService.isValuePresent(value);
  }

  onShowHeatmapToggle() {
    if (this.showingHeatmap) {
      this.showIndividualUsers.emit();
    } else {
      this.showHeatmap.emit();
    }
  }

  onTabChange(tab: string) {
    this.currentTab = tab;
    if (this.currentTab === 'all') {
      this.showHeatmap.emit();
    } else if (this.currentTab === 'some') {
      this.showIndividualUsers.emit();
      this.onTagChange.emit('');
      this.selectedTag = null;
      this.selectedTagValues = {};
      if (this.tags) {
        this.tags.forEach((tag) => {
          tag.isSelected = false;
        });
      }
    }
  }

  onUserTagSelect(event) {
    this.selectedTagValues = {};
    this.selectedTag = event.$event.model[0];
    this.onTagChange.emit(this.selectedTag.id);
  }

  onUserTagValueToggle(id, active) {
    if (_.size(this.selectedTagValues) === 4 && active) {
      return;
    }

    _.set(this.selectedTagValues, `${id}.selected`, active);
    if (active) {
      this.selectedTagValues[id].color = this.getAvailableColor();
      this.onTagValuesChange.emit({ tag: this.selectedTag.id, values: this.selectedTagValues });
    } else {
      delete this.selectedTagValues[id];
      this.onTagValuesChange.emit({ tag: this.selectedTag.id, values: this.selectedTagValues });
    }
  }
}
