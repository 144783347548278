import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonsModule } from 'ngx-bootstrap';
import { CrFormsModule, OrderService, SharedModule } from '../../../../shared';
import { OrderReceiptModule } from '../order-receipt/order-receipt.module';
import { OrderListItemDetailsComponent } from './order-list-item-details/order-list-item-details.component';

import { OrderListItemComponent } from './order-list-item.component';
import { OrderStateButtonComponent } from './order-state-button/order-state-button.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    OrderReceiptModule,
    CrFormsModule,
    ButtonsModule,
  ],
  exports: [OrderListItemComponent],
  declarations: [
    OrderListItemComponent,
    OrderListItemDetailsComponent,
    OrderStateButtonComponent,
  ],
  providers: [OrderService],
})
export class OrderListItemModule { }
