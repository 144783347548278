import controller from './fnb-order-queue.controller';
import template from './fnb-order-queue.html';

const component = {
    template,
    bindings: {},
    controller: [controller],
};

export default component;
