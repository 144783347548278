class BeaconDetailService {
    constructor($q, crBeaconsService, crImageService) {
        this.$q = $q;
        this.crBeaconsService = crBeaconsService;
        this.crImageService = crImageService;
    }

    getData(beaconId) {
        return this.crBeaconsService.getBeaconDetails(beaconId).then((res) => res);
    }

    deleteBeacon(beaconId) {
        return this.crBeaconsService.deleteBeacon(beaconId).then((res) => res);
    }
}

export default BeaconDetailService;
