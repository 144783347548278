import { HttpClient, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash';
import { Kitchen, KitchenList, KitchenMinimalList } from '../../models/kitchen.model';
import { Days, iCalDays, Place, PlaceOpenAndCloseSchedule } from '../../models/places.model';

@Injectable({
  providedIn: 'root',
})
export class KitchenService {
  private baseUrl: string;
  private kitchenUrl: string;

  private iCalToFull = {
    SU: Days.SUNDAY,
    MO: Days.MONDAY,
    TU: Days.TUESDAY,
    WE: Days.WEDNESDAY,
    TH: Days.THURSDAY,
    FR: Days.FRIDAY,
    SA: Days.SATURDAY,
  };

  constructor(private http: HttpClient) {
    this.baseUrl = '/rest/places';
    this.kitchenUrl = '/rest/kitchen';    
  }

  async getKitchenList(
    venueId: string,
    page = 1,
    sortBy = 'name',
    sort: 'asc' | 'desc' = 'asc',
    state = 'enabled',
  ): Promise<KitchenList> {
    const resp: any = await this.http
      .get(this.baseUrl, {
        params: {
          venueId, page: page.toString(), sortby: sortBy, sort, state,
        },
      })
      .toPromise();
    return resp;
  }

  async getKitchen(id: string): Promise<Kitchen> {
    const resp: any = await this.http.get(`${this.baseUrl}/${id}`).toPromise();
    return resp;
  }

  async getMinimalKitchensList(
    venueId: string,
    page = 1,
    sortBy = 'name',
    sort: 'asc' | 'desc' = 'asc',
    state = 'enabled'): Promise<KitchenMinimalList> {
    const resp = await this.http.get<KitchenMinimalList>(`${this.baseUrl}/list`, {
        params: {
          venueId, page: page.toString(), sortby: sortBy, sort, state,
        }
      }).toPromise();
    return resp;
  }

  createKitchen(customerId: string, placeId: string, place: Place, params: any): Promise<HttpEvent<Kitchen>> {
      const payload = cloneDeep(place);
      payload.hours.forEach((hour: PlaceOpenAndCloseSchedule) => {
          hour.days = (hour.days as iCalDays[]).map((day: iCalDays) =>  {
            return this.iCalToFull[day];
          });
      });

      return this.http.post<Kitchen>(`${this.kitchenUrl}/${placeId}`, payload, { ...params, customerId }).toPromise();
  }
}
