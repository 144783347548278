import angular from 'angular';

import ComponentsModule from './components/components';

import PoiEditComponent from './poi-edit.component';
import PoiEditService from './poi-edit.service';

const PoiEditModule = angular
    .module('control-room.poi.components.poi-edit', [ComponentsModule.name])
    .component('crPoiEdit', PoiEditComponent)
    .service('crPoiEditService', [
        '$q',
        'crPoiService',
        'crImageService',
        'crEntityService',
        'crMenusService',
        'crLoQueueService',
        PoiEditService,
    ]);

export default PoiEditModule;
