import _ from 'lodash';

class EntityCategoriesService {
    constructor(crEntityService, crEntityLocalizationService) {
        this.crEntityService = crEntityService;
        this.crEntityLocalizationService = crEntityLocalizationService;
    }

    getParentCategoriesHierarchy(venueId, entityType, locale, entityRoute) {
        const params = [entityType, venueId, locale];

        if (entityRoute) {
            params.push(entityRoute);
        }

        return this.crEntityService.getCategoriesWithHierarchy(...params).then((categories) => {
            const parents = [];

            categories.forEach((category) => {
                if (!category.parentId) {
                    category.expanded = true;
                    parents.push(category);
                }
            });

            return parents;
        });
    }

    updateEntityCategoriesRank(venueId, hierarchy, entityRoute) {
        const ids = _.flattenDeep(
            hierarchy.map((pane) => [
                this.mapPoiCategoryRank(pane),
                (pane.children || []).map((child) => this.mapPoiCategoryRank(child, pane)),
            ])
        );

        const data = {
            owner: {
                id: venueId,
                type: 'VENUE',
            },
            ids,
        };
        return this.crEntityService.updateRanks(data, entityRoute, venueId);
    }

    mapPoiCategoryRank(pane, parent) {
        const mapped = { id: pane.id };

        if (parent) {
            mapped.parentId = parent.id;
        }

        return mapped;
    }

    getLocalizedName(entityType) {
        return this.crEntityLocalizationService.getLocalizedEntityName(entityType);
    }
}

export default EntityCategoriesService;
