<cr-split-view-page pageClass="cr-edit details-page">
  <cr-title-bar [label]="title" (onClick)="goBack()">
    <div titleBarCenter>
      <cr-form-submission-error
        *ngIf="!isLoading && !bundleGroupForm.valid && bundleViewChild.submitted"
        [message]="text.formSubmissionErrorText"
      ></cr-form-submission-error>
    </div>
    <div *ngIf="!isLoading && !isSaving" titleBarRight>
      <button
        (click)="goBack()"
        class="btn btn-nobg"
        data-global-qa-id="manage-cancel"
      >
        {{ text.cancel }}
      </button>
      <button
        *ngIf="!isEdit"
        class="btn btn-primary"
        (click)="onSave()"
        data-global-qa-id="manage-save"
      >
        {{ text.save }}
      </button>
      <cr-split-btn *ngIf="isEdit" [label]="text.save" (onButtonClick)="onSave()">
        <li>
          <a (click)="archiveBundleGroup()" data-global-qa-id="delete">
            {{ text.archiveBundleGroup }}
          </a>
        </li>
        <li>
          <a (click)="archiveBundleGroup()" data-global-qa-id="delete">
            {{ text.saveACopy }}
          </a>
        </li>
      </cr-split-btn>
    </div>
  </cr-title-bar>
  <section top>
    <div class="text-center cr-page-padding-top" *ngIf="isLoading || isSaving">
      <cr-spinner></cr-spinner>
    </div>
    <cr-data-error
      *ngIf="hasDataError"
      [message]="text.standardDataErrorMessage"
    ></cr-data-error>
  </section>
  <section class="cr-left" left>
    <section class="cr-item-row cr-page-padding-top">
      <form [formGroup]="bundleGroupForm" (ngSubmit)="onSubmit()" #bundleViewChild="ngForm">
        <!-- Name area -->
        <section class="cr-flex-between">
          <section class="flex-remaining-space">
            <div class="big-input">
              <cr-input
                id="displayName"
                class="big-input"
                name="displayName"
                label="{{ text.name }} *"
                [validationMessages]="text.fieldError"
                type="text"
                [formControl]="bundleGroupForm.get('displayName')"
              ></cr-input>
            </div>
          </section>
        </section>
        <hr class="details-divider reduce-margin-top" />
        <cr-section-heading>
          {{ text.orderRequirements }}
        </cr-section-heading>
        <cr-input
          id="menuItemsQuantity"
          class="little-input"
          name="menuItemsQuantity"
          label="{{ text.requiredMenuItemsCount }} *"
          placeholder="1"
          [validationMessages]="text.fieldError"
          type="text"
          [formControl]="bundleGroupForm.get('menuItemsQuantity')"
        ></cr-input>
        <hr class="details-divider reduce-margin-top" />
        <cr-section-heading>
          {{ text.bundleGroupItems }}
        </cr-section-heading>
        <cr-form-submission-error 
          *ngIf="getError('maxQuantity', 'notInteger')" 
          [message]="text.notIntegerError">
        </cr-form-submission-error>
        <cr-form-submission-error 
          *ngIf="getError('maxQuantity', 'exceedRequired')" 
          [message]="text.exceedRequiredError">
        </cr-form-submission-error>
        <cr-form-submission-error 
          *ngIf="getError('maxQuantity', 'required') || getError('upsellPrice', 'required')" 
          [message]="text.required">
        </cr-form-submission-error>
        <cr-form-submission-error 
          *ngIf=" getError('upsellPrice', 'incorrectFormat')" 
          [message]="text.incorrectFormatError">
        </cr-form-submission-error>
        <cr-form-submission-error *ngIf="sumIsLessThanRequired" [message]="text.sumIsLessThanRequiredError">
        </cr-form-submission-error>
        <ng-template
          #bundleGroupItemTemplate
          let-bundleGroupReorderItem="item"
          let-i="index"
          let-bundleGroupItemsLength="itemsLength"
        >
          <div class="bundle-group-item-form">
            <div class="menuItem">
              <div class="cr-input-header">
                <label class="cr-label">{{ text.selectMenuItem }} *</label>
              </div>
              <cr-select
                class="menuItems"
                [formControl]="bundleGroupReorderItem.controls.productId"
                [options]="menuItems"
                name="productId"
                id="productId"
                [hasDataError]="hasDataError"
                data-local-qa-id="bundle-group-menu-item"
                search
                (change)="onMenuItemSelect($event)"
              ></cr-select>
            </div>
            <cr-input
              id="maxQuantity"
              class="little-input"
              name="maxQuantity"
              label="{{ text.maxQuantityLabel }} *"
              placeholder="1"
              [hideValidation]="true"
              type="text"
              [formControl]="bundleGroupReorderItem.controls.maxQuantity"
            ></cr-input>
            <cr-input
              id="upsellPrice"
              class="little-input"
              name="upsellPrice"
              label="{{ text.upsellPriceLabel }}"
              placeholder="0.00"
              [hideValidation]="true"
              type="text"
              [formControl]="bundleGroupReorderItem.controls.upsellPrice"
            ></cr-input>
            <cr-checkbox
              label="{{ text.defaultSelection }}"
              [formControl]="bundleGroupReorderItem.controls.defaultSelection"
              (click)="
                setDefaultSelectionIndex(
                  bundleGroupReorderItem.controls.defaultSelection.index
                )
              "
            >
            </cr-checkbox>
            <a
              class="cursor-pointer icon-cr-close bundle-group-remove"
              *ngIf="bundleGroupItemsLength > 1"
              (click)="onBundleGroupItemRemove(i)"
            ></a>
          </div>
        </ng-template>
        <cr-reorder-list
          formArrayName="bundledProducts"
          [items]="bundleGroupsReorder.controls"
          [itemTemplate]="bundleGroupItemTemplate"
          (reorder)="onBundleGroupItemReorder($event)"
          disableMove
        >
        </cr-reorder-list>
        <div class="add-bundle-group-item">
          <!-- TODO: Manage disabled state -->
          <button
            type="button"
            class="btn btn-link"
            (click)="onBundleGroupItemAdd()"
            data-global-qa-id="modifier-group-modifier-add"
          >
            {{ text.addBundleGroupItem }}
          </button>
        </div>
        <cr-required-field></cr-required-field>
      </form>
    </section>
  </section>
  <section class="cr-right" right></section>
</cr-split-view-page>
