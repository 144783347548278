import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KitchenDetailsComponent } from './kitchen-details.component';
import { KitchenMenusModule } from '../kitchen-menus/kitchen-menus.module';



@NgModule({
  declarations: [
    KitchenDetailsComponent
  ],
  imports: [
    CommonModule,
    KitchenMenusModule
  ],
  exports: [
    KitchenDetailsComponent
  ]
})
export class KitchenDetailsModule { }
