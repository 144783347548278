import controller from './cvt-dashboard.controller';
import template from './cvt-dashboard.html';
import './cvt-dashboard.less';

const component = {
    template,
    bindings: {},
    controller: [
        '$state',
        '$uibModal',
        '$timeout',
        'crCvtDashboardService',
        'crErrorLoggingService',
        'crAnalyticsService',
        'crAlertService',
        controller,
    ],
};

export default component;
