import _ from 'lodash';

function mapToRowsModel(categories) {
    return categories.map((category) => {
        const row = {
            data: {
                id: category.id,
                name: category.displayName,
                missingTranslations: _.isArray(category.missingTranslations)
                    ? category.missingTranslations.join(', ')
                    : '',
                originalData: category,
            },
        };

        return row;
    });
}

class MenuCategoriesService {
    constructor(crEntityService, crConstants) {
        this.crEntityService = crEntityService;
        this.crConstants = crConstants;

        this.mapToRowsModel = mapToRowsModel;
    }

    getData(menuId, params) {
        const queryParams = _.pickBy(params, (value) => !_.isNil(value) && value !== '' && value !== 'all');
        const menus = this.crConstants.entity.types.MENUS;
        const menuCategories = this.crConstants.entity.types.MENU_CATEGORIES;

        return this.crEntityService.getEntityList(`${menus}/${menuId}/${menuCategories}`, queryParams);
    }
}

export default MenuCategoriesService;
