import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import text from './resources/locale/en.json';
import { LocalizedText } from '../../../core/index.js';

@Component({
  selector: 'cr-simple-modal',
  templateUrl: './simple-modal.component.html',
  styleUrls: ['./simple-modal.component.scss'],
})
export class SimpleModalComponent {
  text: LocalizedText = text;

  message: string;

  submitText: string;

  cancelText: string = text.cancel;

  confirm: boolean;

  get result() {
    return this._result;
  }

  private _result: Promise<any>;

  private _resolve: (value?: any) => void;

  private _reject: () => void;

  constructor(private _modalRef: BsModalRef) {
    this._result = new Promise((resolve, reject) => {
      this._resolve = resolve;
      this._reject = reject;
    });
  }

  cancel() {
    this._reject();
    this._modalRef.hide();
  }

  submit() {
    this._resolve();
    this._modalRef.hide();
  }

  getSubmitText() {
    if (this.submitText) {
      return this.submitText;
    }

    return this.confirm ? this.text.submit : this.text.close;
  }
}
