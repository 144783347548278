import _ from 'lodash';

class TableController {
    constructor($state, crAnalyticsService) {
        this.$state = $state;
        this.crAnalyticsService = crAnalyticsService;
    }

    $onInit() {
        this.onSortChange = this.onSortChange || (() => null);
        this.onRowSelect = this.onRowSelect || (() => null);
        this.positionSticky = this.positionSticky || false;
    }

    $postLink() {
        this.currentSortColumn = null;
        this.handleDeepLink();
    }

    getClasses(index) {
        const classes = [];
        const column = this.data.columns[index];

        if (column.sortable) {
            classes.push('cr-sortable');
        }

        if (column.class) {
            classes.push(column.class);
        }

        return classes;
    }

    handleDeepLink() {
        let currentColumn = this.defaultSortBy || _.first(this.data.columns);
        const sortBy = this.$state.params.sortby;

        this.data.columns.forEach((column) => {
            if (column.sortable) {
                column.sort = this.$state.params.sort || 'asc';
            }

            if (sortBy && sortBy === column.key) {
                currentColumn = column;
            }
        });

        currentColumn.isAscending = currentColumn.sort === 'asc';
        currentColumn.isDescending = currentColumn.sort === 'desc';
        this.currentSortColumn = currentColumn;
        this.updateRoute(currentColumn);
    }

    toggleSort(column) {
        if (!column.sortable) {
            return;
        }

        this.crAnalyticsService.track('Sort View', {
            sortBy: column.label,
            sort: column.sort,
        });

        if (column === this.currentSortColumn) {
            column.sort = column.sort === 'asc' ? 'desc' : 'asc';
        }

        this.data.columns.forEach((col) => {
            col.isAscending = false;
            col.isDescending = false;
            col.sort = column.sort;
        });

        column.isAscending = column.sort === 'asc';
        column.isDescending = column.sort === 'desc';

        this.currentSortColumn = column;

        this.updateRoute(column);
        this.onSortChange({ column });
    }

    updateRoute(column) {
        this.$state.params.sortby = column.key;
        this.$state.params.sort = column.sort;
        this.$state.go(this.$state.current.name, this.$state.params, { location: 'replace' });
    }
}

export default TableController;
