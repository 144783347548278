import angular from 'angular';

import component from './coupons-list.component';
import service from './coupons-list.service';

const CouponsList = angular
    .module('control-room.coupons.list', [])
    .service('crCouponsListService', ['crCouponsService', service])
    .component('crCouponsList', component);

export default CouponsList;
