import controller from './coupons-list.controller';
import template from './coupons-list.html';
import './coupons-list.less';

const component = {
    template,
    bindings: {},
    controller: [
        '$state',
        'crErrorLoggingService',
        'crAnalyticsService',
        'crNavigationService',
        'crToastService',
        'crLocaleService',
        'crConfig',
        'crConstants',
        'crCouponsListService',
        'crEntitySelectionManager',
        controller,
    ],
};

export default component;
