import controller from './menus-tabs.controller';
import template from './menus-tabs.html';
import './menus-tabs.less';

const component = {
    template,
    bindings: {},
    controller: ['$state', 'crAnalyticsService', 'crConstants', '$uibModal', 'crFeatureFlagsService', controller],
};

export default component;
