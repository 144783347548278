<div class="live-map-container">
    <div class="map-toolbar">
        <div class="toolbar">
            <button
                (click)="onPanelToggle('liveAppUsage')"
                [ngClass]="{ active: activeTab === 'liveAppUsage' }"
            >
                <cr-tooltip-wrapper
                    [tooltipAppendToBody]="true"
                    tooltipPlacement="right"
                    [tooltipText]="text.liveAppUsageTooltip"
                ></cr-tooltip-wrapper>
                <span class="icon-cr-mobile"></span>
            </button>
            <button (click)="onMetricsToggle()">
                <cr-tooltip-wrapper
                    [tooltipAppendToBody]="true"
                    tooltipPlacement="right"
                    [tooltipText]="text.metricsTooltip"
                ></cr-tooltip-wrapper>
                <span class="icon-cr-level"></span>
            </button>
        </div>
        <div
            class="panel"
            [ngClass]="{ minimize: minimizePanel, open: showPanel }"
        >
            <div class="content" [ngSwitch]="activeTab">
                <cr-live-map-app-usage
                    *ngSwitchCase="'liveAppUsage'"
                    [showingHeatmap]="showingHeatmap"
                    (showHeatmap)="showHeatmap()"
                    (showIndividualUsers)="showIndividualUsers()"
                    (onTagChange)="onTagChange($event)"
                    (onTagValuesChange)="onTagValuesChange($event)"
                ></cr-live-map-app-usage>
            </div>
            <button class="cta" (click)="onPanelMinimizeToggle()">
                <cr-tooltip-wrapper
                    *ngIf="minimizePanel"
                    [tooltipAppendToBody]="true"
                    tooltipPlacement="right"
                    [tooltipText]="text.expandTooltip"
                ></cr-tooltip-wrapper>
                <cr-tooltip-wrapper
                    *ngIf="!minimizePanel"
                    [tooltipAppendToBody]="true"
                    tooltipPlacement="right"
                    [tooltipText]="text.collapseTooltip"
                ></cr-tooltip-wrapper>
                <span class="icon-cr-chevron-left"></span>
            </button>
        </div>
    </div>
    <div class="map-container" #container>
        <div *ngIf="!isLoading">
            <mgl-map
                [attributionControl]="false"
                [style]="style"
                [zoom]="zoom"
                [center]="center"
                [bearing]="bearing"
                [maxBounds]="maxBounds"
                [maxZoom]="maxZoom"
                [minZoom]="minZoom"
                [cursorStyle]="cursorStyle"
                [accessToken]="
                    'pk.eyJ1IjoiamRlbmJyb2VkZXItdGUyIiwiYSI6ImNqeWFxaHpjdTAxYXEzbW1xa2FibjI1bmMifQ.pQdpI6f9SiE3tLlogCnrnA'
                "
                (click)="onMapClick($event)"
                (load)="onMapLoad($event)"
                (zoomEvt)="onMapZoom($event)"
            >
                <mgl-control
                    mglAttribution
                    [customAttribution]="attribution"
                    position="bottom-left"
                ></mgl-control>
                <mgl-raster-source
                    id="park-map"
                    [tiles]="tiles"
                    [tileSize]="256"
                    [bounds]="bounds"
                    [maxzoom]="maxZoom"
                    [minzoom]="minZoom"
                ></mgl-raster-source>
                <mgl-geojson-source id="users">
                    <mgl-feature
                        *ngFor="let user of users"
                        [geometry]="user.geometry"
                        [properties]="user.properties"
                    ></mgl-feature>
                </mgl-geojson-source>
                <mgl-layer
                    id="overlay"
                    source="park-map"
                    type="raster"
                ></mgl-layer>
                <mgl-layer
                    id="users-point"
                    source="users"
                    type="circle"
                    [paint]="paint?.usersPoint"
                    [filter]="filter.usersPoint"
                    (click)="onUserClick($event)"
                    (mouseEnter)="onUserMouseEnter($event)"
                    (mouseLeave)="onUserMouseLeave($event)"
                ></mgl-layer>
                <mgl-layer
                    id="users-heat"
                    source="users"
                    type="heatmap"
                    [paint]="paint?.usersHeat"
                    [layout]="layout.usersHeat"
                ></mgl-layer>
                <mgl-control
                    mglNavigation
                    position="bottom-right"
                    [showCompass]="false"
                ></mgl-control>
                <mgl-popup
                    *ngIf="selectedUser || hoveredUser"
                    [closeButton]="false"
                    [closeOnClick]="false"
                    [anchor]="'bottom'"
                    [feature]="selectedUser || hoveredUser"
                    [offset]="[0, -5]"
                >
                    <button
                        class="popup-close"
                        (click)="deselectUser()"
                        *ngIf="selectedUser"
                    >
                        &times;
                    </button>
                    <cr-live-map-popup
                        [properties]="
                            selectedUser?.properties || hoveredUser?.properties
                        "
                        [isLoading]="isLoadingUsers"
                    ></cr-live-map-popup>
                    <img src="/assets/icons/cr-user-pin.svg" />
                </mgl-popup>
            </mgl-map>
            <cr-notification
                [title]="text.alertTitleLoading"
                [message]="text.alertMessageLoading"
                *ngIf="isLoadingUsers && firstLoad"
            ></cr-notification>
            <cr-notification
                [title]="text.alertTitleNoUsers"
                [message]="text.alertMessageNoUsers"
                allowClose="true"
                *ngIf="users?.length === 0"
            ></cr-notification>
            <cr-live-map-metrics
                *ngIf="showMetrics"
                (close)="onMetricsToggle()"
                crDraggable
                dragHandle=".header"
                dragTarget=".metrics-container"
                [startingOffset]="startingMetricsOffset"
            ></cr-live-map-metrics>
        </div>
    </div>
</div>
