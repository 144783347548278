import OrdersController from './orders.controller';
import template from './orders.html';
import './orders.less';

const OrdersComponent = {
    template,
    controller: [
        '$q',
        '$state',
        'crReportingOrdersService',
        'crReportingOrdersText',
        'numberFilter',
        'crConstants',
        'crColorPaletteConstant',
        'crDateRangeService',
        'crErrorLoggingService',
        OrdersController,
    ],
    controllerAs: 'ordersCtrl',
};

export default OrdersComponent;
