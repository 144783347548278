import { Component, Input } from '@angular/core';

@Component({
  selector: 'cr-required-field',
  templateUrl: './required-field.component.html',
  styleUrls: ['./required-field.component.scss'],
})
export class RequiredFieldComponent {
  @Input() customClass = '';

  @Input() requiredFieldMessage = 'Required field';
}
