import controller from './duration-input.controller';
import template from './duration-input.html';
import './duration-input.less';

const Component = {
    template,
    require: {
        form: '^form',
    },
    bindings: {
        name: '@',
        label: '@',
        secondsPerUnit: '@',
        onUpdate: '&',
        duration: '<',

        hideValidation: '<?',
        crRequired: '<?',
        maxValue: '@?',
        customClass: '@?',
    },
    controller: ['crConstants', '$timeout', controller],
};

export default Component;
