import _ from 'lodash';
import moment from 'moment';
import distance from '@turf/distance';
import transformScale from '@turf/transform-scale';
import * as helpers from '@turf/helpers';
import ResizeObserver from 'resize-observer-polyfill';
import {
  Component, OnInit, ViewEncapsulation, ViewChild, ElementRef, OnDestroy,
} from '@angular/core';
import { StateService } from '@uirouter/core';
import {
  LngLatLike, Map, MapboxGeoJSONFeature, Style, MapMouseEvent,
} from 'mapbox-gl';

import { LocalizedText } from '../../../core';
import { TagService, VenueService } from '../../../shared';
import { LiveMapService } from './live-map.service';

import text from './resources/locale/en.json';

import 'mapbox-gl/dist/mapbox-gl.css';

@Component({
  selector: 'cr-live-map',
  templateUrl: './live-map.component.html',
  styleUrls: ['./live-map.component.scss'],
  providers: [LiveMapService],
  encapsulation: ViewEncapsulation.None,
})
export class LiveMapComponent implements OnDestroy, OnInit {
  @ViewChild('container', { static: false }) container: ElementRef;

  activeTab: string;

  attribution?: string;

  bearing: number[];

  bounds: number[];

  center: LngLatLike;

  cursorStyle: string;

  filter: any;

  firstLoad = true;

  heatmapMaxZoom = 19;

  hoveredUser: MapboxGeoJSONFeature;

  intervalId: any;

  isLoading: boolean;

  isLoadingUsers: boolean;

  lastUpdate: string;

  layout: any;

  minimizePanel: boolean;

  map: Map;

  maxBounds: number[];

  maxZoom: number;

  minZoom: number;

  paint: any;

  showMetrics: boolean;

  showPanel: boolean;

  style: Style;

  tags: any;

  text: LocalizedText;

  tiles: string[];

  resizeObserver: ResizeObserver;

  selectedUser: MapboxGeoJSONFeature;

  startingMetricsOffset = { x: -20, y: 20 };

  users: any[];

  usersMap: any;

  zoom: number;

  constructor(
    private liveMapService: LiveMapService,
    private state: StateService,
    private tagService: TagService,
    private venueService: VenueService,
  ) {
    this.text = text as LocalizedText;
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.showMetrics = true;
    this.usersMap = {};
    this.lastUpdate = moment().subtract(2, 'hours').toISOString();
    this.venueService.getMetadataById(this.state.params.venueId, 'mapbox').then((data: any) => {
      this.isLoading = false;
      const lbmData = data.value;
      this.bounds = lbmData.bounds;
      this.center = [lbmData.center.longitude, lbmData.center.latitude];
      this.zoom = lbmData.zoom.default;
      this.bearing = [lbmData.bearing || 0];
      this.maxZoom = lbmData.zoom.max;
      this.minZoom = lbmData.zoom.min;
      this.tiles = [lbmData.tileUrl];
      this.attribution = lbmData.attribution;

      this.heatmapMaxZoom = this.heatmapMaxZoom > lbmData.zoom.max ? lbmData.zoom.max : this.heatmapMaxZoom;
      this.paint = {
        usersPoint: {
          'circle-color': '#0d70b3',
          'circle-stroke-color': '#fbfbfb',
          'circle-radius': 6,
          'circle-stroke-width': 1,
          'circle-opacity': [
            'interpolate',
            ['linear'],
            ['zoom'],
            this.heatmapMaxZoom - 1,
            0,
            this.heatmapMaxZoom,
            1,
          ],
        },
        usersHeat: {
          'heatmap-color': [
            'interpolate',
            ['linear'],
            ['heatmap-density'],
            0,
            'rgba(33,102,172,0)',
            0.1,
            '#8C8BFA',
            0.3,
            '#C571A3',
            0.5,
            '#F86E60',
            0.7,
            '#FFF867',
            1,
            '#B41C2C',
          ],
          'heatmap-opacity': [
            'interpolate',
            ['linear'],
            ['zoom'],
            this.heatmapMaxZoom - 1,
            1,
            this.heatmapMaxZoom,
            0,
          ],
        },
      };

      this.liveMapService.tags.subscribe((tags) => {
        this.tags = {};
        tags.forEach((tag) => {
          const tagClone = _.cloneDeep(tag);
          tagClone.values = _.keyBy(tagClone.values, (value) => value.tagValueKey);
          this.tags[tagClone.tagKey] = tagClone;
        });
      });
      this.calculateBounds();
      this.getUserLocations();
      this.intervalId = setInterval(() => {
        this.getUserLocations();
      }, 60000);
    });

    this.style = {
      version: 8,
      name: 'Park Map',
      glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
      sources: {},
      layers: [],
    };

    this.layout = {
      usersHeat: {
        visibility: 'visible',
      },
    };

    this.filter = {
      usersPoint: ['has', 'userId'],
    };
  }

  ngOnDestroy() {
    clearInterval(this.intervalId);
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

  get showingHeatmap() {
    return this.layout.usersHeat.visibility === 'visible';
  }

  buildUserGeoJson(users, tagsByUser): any {
    return _.map(users, (user) => {
      // Can't have array values without this https://github.com/mapbox/mapbox-gl-js/pull/7197
      const expandedTags = {};
      let displayProps: any;
      _.forEach(tagsByUser[user.userId], (values, tagId) => {
        _.forEach(values, (value) => {
          expandedTags[`${tagId}-${value}`] = value;
        });
        if (this.tags[tagId]) {
          if (!displayProps) {
            displayProps = {};
          }
          const displayValues = values.map((value) => {
            const label = _.get(this.tags, `${tagId}.values.${value}.label`);
            return label || value;
          });
          displayProps[tagId] = {
            title: this.tags[tagId].title,
            values: displayValues,
          };
        }
      });
      return {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [user.longitude, user.latitude],
        },
        properties: {
          lastSeenAt: user.lastSeenAt,
          userId: user.userId,
          ...expandedTags,
          displayProps,
        },
      };
    });
  }

  calculateBounds() {
    const nwCorner = helpers.point([this.bounds[0], this.bounds[3]]);
    const swCorner = helpers.point([this.bounds[0], this.bounds[1]]);
    const seCorner = helpers.point([this.bounds[2], this.bounds[1]]);

    const latDistance = distance(nwCorner, swCorner, { units: 'degrees' });
    const lonDistance = distance(swCorner, seCorner, { units: 'degrees' });

    const height = this.container.nativeElement.clientHeight;
    const width = this.container.nativeElement.clientWidth;

    const modifier = (height / width) * (lonDistance / latDistance);
    const bounds = [...this.bounds];
    if (_.isNaN(modifier)) {
      return;
    }
    if (modifier >= 1) {
      const scaled = transformScale(
        helpers.lineString([
          [this.bounds[0], this.bounds[1]],
          [this.bounds[0], this.bounds[3]],
        ]),
        modifier,
      );
      bounds[1] = scaled.geometry.coordinates[0][1];
      bounds[3] = scaled.geometry.coordinates[1][1];
    } else {
      const scaled = transformScale(
        helpers.lineString([
          [this.bounds[0], this.bounds[1]],
          [this.bounds[2], this.bounds[1]],
        ]),
        1 / modifier,
      );
      bounds[0] = scaled.geometry.coordinates[0][0];
      bounds[2] = scaled.geometry.coordinates[1][0];
    }
    this.maxBounds = bounds;
  }

  createResizeObserver() {
    this.resizeObserver = new ResizeObserver(() => {
      this.map.resize();
      this.calculateBounds();
    });
    this.resizeObserver.observe(this.container.nativeElement);
  }

  deselectUser() {
    this.selectedUser = null;
    if (_.isString(this.paint.usersPoint['circle-color'])) {
      return;
    }
    if (this.paint.usersPoint['circle-color'].length > 4) {
      this.paint.usersPoint['circle-color'].splice(1, 2);
    } else {
      this.paint.usersPoint['circle-color'] = '#0d70b3';
    }
    this.paint.usersPoint = { ...this.paint.usersPoint };
  }

  async getUserLocations(): Promise<void> {
    this.isLoadingUsers = true;
    const { venueId } = this.state.params;
    const users: any = await this.venueService.getUserLocations(venueId, this.lastUpdate);
    users.locations.reduce((map, user) => {
      map[user.userId] = user;
      return map;
    }, this.usersMap);
    if (this.firstLoad) {
      this.users = this.buildUserGeoJson(this.usersMap, {});
    }
    const chunked = _.chunk(Object.keys(this.usersMap), 100);
    const tagResponses = await Promise.all(
      chunked.map((userIds: string[]) => this.tagService.getTagDefinitionsForUsers(venueId, { userIds })),
    );
    const tags = tagResponses.reduce((map: any[], tagResponse: any) => {
      _.assign(map, tagResponse.users);
      return map;
    }, {});
    _.forEach(tags, (user) => {
      _.forEach(user, (tag, id) => {
        user[id] = tag.map((value) => value.tagValue);
      });
    });
    this.users = this.buildUserGeoJson(this.usersMap, tags);
    this.liveMapService.setUsers(this.users);
    this.lastUpdate = users.timestamp;
    if (this.selectedUser) {
      const user = _.find(this.users, {
        properties: { userId: this.selectedUser.properties.userId },
      });
      if (user) {
        this.selectUser(user);
      } else {
        this.deselectUser();
      }
    } else if (this.hoveredUser) {
      const user = _.find(this.users, {
        properties: { userId: this.hoveredUser.properties.userId },
      });
      if (user) {
        this.hoveredUser = user;
      } else {
        this.hoveredUser = null;
      }
    }
    this.firstLoad = false;
    this.isLoadingUsers = false;
  }

  onMapClick(event: MapMouseEvent): void {
    if (this.showingHeatmap && this.map.getZoom() < this.heatmapMaxZoom) {
      return;
    }

    const features = this.map.queryRenderedFeatures(event.point);
    if (features.filter((feature) => feature.source === 'users').length === 0) {
      this.deselectUser();
    }
  }

  onMapLoad(map): void {
    this.map = map;
    this.createResizeObserver();
  }

  onMapZoom(event: MapMouseEvent): void {
    if (this.showingHeatmap && event.target.getZoom() < this.heatmapMaxZoom) {
      this.hoveredUser = null;
      this.deselectUser();
    }
  }

  onMetricsToggle(): void {
    this.showMetrics = !this.showMetrics;
  }

  onPanelMinimizeToggle(): void {
    this.minimizePanel = !this.minimizePanel;
  }

  onPanelToggle(panelName): void {
    this.showPanel = !this.showPanel;
    this.activeTab = this.showPanel ? panelName : null;
    if (!this.showPanel) {
      this.reset();
    }
  }

  onTagChange(tag) {
    this.filter.usersPoint = ['has', tag, ['get', 'displayProps']];
    this.paint.usersPoint['circle-color'] = '#0d70b3';
    this.paint.usersPoint = { ...this.paint.usersPoint };
  }

  onTagValuesChange({ tag, values }) {
    const filter: any[] = ['any'];
    const paint: any[] = ['case'];
    const multiMatch: any[] = ['+'];
    const singleMatches: any[] = [];
    _.forEach(values, ({ color }, value) => {
      const id = `${tag}-${value}`;
      filter.push(['==', ['get', id], value]);
      multiMatch.push(['to-number', ['has', id]]);
      singleMatches.push(['has', id]);
      singleMatches.push(color);
    });
    paint.push(['>', multiMatch, 1]);
    paint.push('#2F4858');
    singleMatches.push('#0d70b3');
    this.paint.usersPoint['circle-color'] = paint.concat(singleMatches);
    this.paint.usersPoint = { ...this.paint.usersPoint };
    this.filter.usersPoint = filter;
  }

  onUserClick(event) {
    if (this.showingHeatmap && this.map.getZoom() < this.heatmapMaxZoom) {
      return;
    }

    this.selectUser(event.features[0]);
  }

  onUserMouseEnter(event) {
    if (this.showingHeatmap && this.map.getZoom() < this.heatmapMaxZoom) {
      return;
    }

    this.cursorStyle = 'pointer';
    if (!this.selectedUser) {
      this.hoveredUser = event.features[0];
    }
  }

  onUserMouseLeave() {
    if (this.showingHeatmap && this.map.getZoom() < this.heatmapMaxZoom) {
      return;
    }

    this.cursorStyle = '';
    this.hoveredUser = null;
  }

  reset() {
    this.showHeatmap();
    this.filter.usersPoint = ['has', 'userId'];
  }

  selectUser(user) {
    if (this.paint.usersPoint['circle-color'][0] === 'case' && !this.selectedUser) {
      this.paint.usersPoint['circle-color'].splice(
        1,
        0,
        ['==', ['get', 'userId'], user.properties.userId],
        '#444444',
      );
    } else if (this.paint.usersPoint['circle-color'][0] === 'case' && this.selectedUser) {
      this.paint.usersPoint['circle-color'][1][2] = user.properties.userId;
    } else {
      this.paint.usersPoint['circle-color'] = [
        'case',
        ['==', ['get', 'userId'], user.properties.userId],
        '#444444',
        '#0d70b3',
      ];
    }
    this.paint.usersPoint = { ...this.paint.usersPoint };
    this.selectedUser = user;
  }

  showHeatmap() {
    this.selectedUser = null;
    this.hoveredUser = null;
    this.layout.usersHeat.visibility = 'visible';
    this.layout.usersHeat = { ...this.layout.usersHeat };
    this.paint.usersPoint['circle-opacity'] = ['interpolate', ['linear'], ['zoom'], 18, 0, this.heatmapMaxZoom, 1];
    this.paint.usersPoint['circle-color'] = '#0d70b3';
    this.paint.usersPoint = { ...this.paint.usersPoint };
    this.filter.usersPoint = ['has', 'userId'];
  }

  showIndividualUsers() {
    this.selectedUser = null;
    this.hoveredUser = null;
    this.layout.usersHeat.visibility = 'none';
    this.layout.usersHeat = { ...this.layout.usersHeat };
    this.paint.usersPoint['circle-opacity'] = 1;
    this.paint.usersPoint['circle-color'] = '#0d70b3';
    this.paint.usersPoint = { ...this.paint.usersPoint };
    this.filter.usersPoint = ['has', 'userId'];
  }
}
