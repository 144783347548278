import _ from 'lodash';

class NavPanelController {
    hasAdminAccess = false;

    constructor($state, $window, $transitions, crAnalyticsService, crConfig, crPolicyService) {
        this.$state = $state;
        this.$window = $window;
        this.$transitions = $transitions;
        this.crAnalyticsService = crAnalyticsService;
        this.crConfig = crConfig;
        this.crPolicyService = crPolicyService;
        this.categoryActions = this.categoryActions || [];
        this.slim = !_.isNil(this.slim) ? this.slim : false;
    }

    $onInit() {
        this.cleanupActiveHook = this.$transitions.onSuccess({}, (transition) => {
            this.setActiveModule(transition.to());
        });
        if (this.features) {
            const newCategoryActions = [];

            this.checkUserRole().then(() => {
                this.categoryActions.forEach((category) => {
                    const actions = category.actions.filter((action) =>
                        _.find(this.features, { id: action.id }) && this.actionHasAccess(action));

                    if (actions.length > 0) {
                        newCategoryActions.push({
                            title: category.title,
                            actions,
                        });
                    }
                });

                this.categoryActions = newCategoryActions;
            });
        }

        this.setActiveModule(this.$state.current);
    }

    actionHasAccess(action) {
        return (action.id !== 'LIFTS_TRAILS_ACTIVITIES' || this.hasAdminAccess);
    }

    $onDestroy() {
        this.cleanupActiveHook();
    }


    async checkUserRole() {
        const result = await Promise.all([
            this.crPolicyService.hasRole('super_admin'),
            this.crPolicyService.hasRole('admin'),
            this.crPolicyService.hasRole('activity_manager'),
        ]);
        result.forEach(res => this.hasAdminAccess |= res);
    }

    setActiveModule(toState) {
        const stateParts = toState.name.split('.');
        const moduleName = stateParts[1];

        this.categoryActions.forEach((category) => {
            category.actions.forEach((action) => {
                action.active = _.startsWith(action.route.split('.')[1], moduleName);
            });
        });
    }

    getVenueId() {
        return this.$window.localStorage.getItem('venueId') || this.$state.params.venueId;
    }

    onActionSelect(action) {
        this.crAnalyticsService.track('Module Selected', { moduleName: action.text });
        this.$state.go(action.route, {
            customerId: this.crConfig.customer.id,
            venueId: this.getVenueId(),
        });
    }
}

export default NavPanelController;
