import angular from 'angular';

import component from './experience-list.component';
import service from './experience-list.service';

const ExperienceList = angular
    .module('control-room.experience-promoter.components.experience-list', [])
    .component('crExperienceList', component)
    .service('crExperienceListService', ['crExperiencesService', 'crImageService', service]);

export default ExperienceList;
