import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { StateService } from '@uirouter/core';
import { PageChangedEvent } from 'ngx-bootstrap';
import { of } from 'rxjs/internal/observable/of';
import { catchError, finalize, take } from 'rxjs/operators';
import { LocalizedText } from '../../../../core';
import { ErrorLoggingService, NavigationService, PrinterService } from '../../../../shared';
import { patchOrderDates, patchOrderProductDisplayPrice } from '../../../../shared/helpers/patch-order';
import { GetOrderHistoryParams, OrderHistoryResponse } from '../../../../shared/services/order-history/order-history.model';
import { OrderHistoryService } from '../../../../shared/services/order-history/order-history.service';
import { fnbRoutes } from '../../constants/routes';
import text from './resources/en.json';

@Component({
  selector: 'cr-order-history',
  templateUrl: 'order-history.component.html',
  styleUrls: ['./order-history.component.scss'],
})

export class OrderHistoryComponent implements OnInit {
  text: LocalizedText;

  isPrintingEnabled = false;

  isLoading = false;

  loaded = false;

  currentPage: number;

  totalShown: string;

  exportFilename: string;

  page = 1;

  perPage = 10;

  isMoreResults = false;

  orderHistoryExportParams: GetOrderHistoryParams;

  newSearch = true;

  resultsError = '';

  totalCount = 0;

  orderHistory: OrderHistoryResponse = { orders: [], totalOrders: '0', page: 0 };

  orderHistoryForm: FormGroup;

  orderNumberControl: FormControl;

  orderGuestNameControl: FormControl;

  orderGuestEmailControl: FormControl;

  orderGuestLast4Control: FormControl;

  orderHistoryResult: string;

  constructor(
    private nav: NavigationService,
    private state: StateService,
    private orderHistoryService: OrderHistoryService,
    private printerService: PrinterService,
    private logging: ErrorLoggingService,
  ) {
    this.text = text;
  }

  public ngOnInit(): void {
    this.initializeForm();

    this.printerService.getPrinters(this.state.params.kitchenId).then((response) => {
      this.isPrintingEnabled = response.printers.length > 0;
    });
    this.exportFilename = `${this.state.params.kitchenId.replace('.', '_').replace(':', '_')}__orders`;
  }

  onBack(): void {
    this.nav.goBack(fnbRoutes.orderList,
      { kitchenId: (this.state.params.kitchenId as string) });
  }

  onAdvancedSearch(page: number, newSearch: boolean): void {
    this.resultsError = '';
    if (newSearch) {
      this.orderHistory.orders = [];
    }
    this.currentPage = page;
    const placeId = this.state.params.kitchenId as string;
    const query = this.generateQueryParams(page, newSearch);

    this.orderHistoryExportParams = query;

    this.isLoading = true;

    this.orderHistoryService.getOrderHistory(placeId, query).pipe(
      take(1),
      catchError((err) => of(this.handleError(err))),
      finalize(() => {
        this.getPagingDetails(this.orderHistory.totalOrders);
        this.isLoading = false;
        this.newSearch = false;
        this.isThereMorePages();
      }),
    ).subscribe(
      (orderHistory) => {
        if (orderHistory && orderHistory.totalOrders !== '0') {
          orderHistory.orders.forEach((order) => {
            patchOrderDates(order);
            patchOrderProductDisplayPrice(order);
          });
          this.orderHistory.totalOrders = orderHistory.totalOrders;
          this.orderHistory.page = orderHistory.page;
          this.orderHistory.orders = [...this.orderHistory.orders, ...orderHistory.orders];
          this.getResultsString(orderHistory.totalOrders);
        } else if (orderHistory && orderHistory.totalOrders === '0') {
          this.getResultsString('0');
        }
      },
    );
  }

  pageChanged(event: PageChangedEvent): void {
    if (!this.isLoading) {
      this.onAdvancedSearch(event.page, false);
    }
  }

  private handleError(err): void {
    this.orderHistoryResult = '';
    this.totalShown = '';
    this.logging.logError('Error getting Order History', err);
    this.resultsError += `${(text.resultsError)}`;
  }

  private getPagingDetails(totalOrders: string): void {
    const totalOrderCount = parseInt(totalOrders, 10);
    this.totalCount = totalOrderCount;
  }

  private getResultsString(totalCount: string): void {
    if (totalCount === '0') {
      this.orderHistoryResult = this.text.orderHistoryResultsNone as string;
      this.totalShown = '';
    } else {
      this.orderHistoryResult = `${totalCount} ${this.text.orderHistoryResults as string}`;
      this.totalShown = `Showing ${this.orderHistory.orders.length} of ${totalCount} order(s)`;
    }
  }

  private isThereMorePages(): void {
    const totalPages = Math.ceil(this.totalCount / this.perPage);

    if (this.totalCount === 0) {
      this.currentPage = 0;
    }

    this.isMoreResults = !(this.currentPage === totalPages);
  }

private generateQueryParams(page: number, newSearch: boolean): GetOrderHistoryParams {
    if (newSearch) {
      this.newSearch = true;
      this.totalCount = 0;
      this.currentPage = 1;
      this.orderHistory.totalOrders = '0';
    }

    let query: GetOrderHistoryParams = {
      page,
      perPage: this.perPage,
    };
    if (this.orderGuestLast4Control.value !== '') {
      query = { ...query, cardLast4: (this.orderGuestLast4Control.value as number) };
    }
    if (this.orderGuestEmailControl.value !== '') {
      query = { ...query, customerEmail: (this.orderGuestEmailControl.value as string) };
    }
    if (this.orderGuestNameControl.value !== '') {
      query = { ...query, customerName: (this.orderGuestNameControl.value as string) };
    }
    if (this.orderNumberControl.value !== '') {
      query = { ...query, customerOrderId: (this.orderNumberControl.value as number) };
    }

    return query;
  }

  private initializeForm(): void {
    this.orderNumberControl = new FormControl('', Validators.pattern(/^\d+$/));
    this.orderGuestNameControl = new FormControl('');
    this.orderGuestEmailControl = new FormControl('', Validators.email);
    this.orderGuestLast4Control = new FormControl('', [Validators.maxLength(4), Validators.minLength(4), Validators.pattern(/^\d+$/)]);

    this.orderHistoryForm = new FormGroup({
      orderNumberControl: this.orderNumberControl,
      orderGuestNameControl: this.orderGuestNameControl,
      orderGuestEmailControl: this.orderGuestEmailControl,
      orderGuestLast4Control: this.orderGuestLast4Control,
    });

    this.orderHistoryForm.setValidators(this.atLeastOneValidator());
  }

  private atLeastOneValidator = () => (group: FormGroup) => {
    const { value } = group;
    if (!Object.values(value).find((v) => v !== '')) {
      return { message: 'Please input at least one value' };
    }
    return null;
  };
}
