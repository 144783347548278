import Controller from './form-error.controller';
import template from './form-error.html';
import './form-error.less';

const FormErrorComponent = {
    template,
    transclude: true,
    require: {
        form: '^form',
    },
    bindings: {
        errorMessages: '<?',
        for: '@',
        type: '@?',
        customClass: '@?',
    },
    controller: [Controller],
};

export default FormErrorComponent;
