class TableContainerController {
    constructor($sce) {
        this.$sce = $sce;
    }

    $onChanges() {
        if (this.noResultsText) {
            this.noResults = this.$sce.trustAsHtml(this.noResultsText);
        }
    }
}

export default TableContainerController;
