import angular from 'angular';

import PreferencesBrandContentModule from './preferences-brand-content/preferences-brand-content';
import PreferencesGuidelinesModule from './preferences-guidelines/preferences-guidelines';
import PreferencesQuestionsOverviewModule from './preferences-questions-overview/preferences-questions-overview';

const Components = angular.module('control-room.app-settings.components.preferences-details.components', [
    PreferencesBrandContentModule.name,
    PreferencesGuidelinesModule.name,
    PreferencesQuestionsOverviewModule.name,
]);

export default Components;
