import controller from './time-frame-picker.controller';
import template from './time-frame-picker.html';

const TimeFramePickerComponent = {
    template,
    bindings: {
        range: '<',
        interval: '<',
        label: '@?',
        menuAlignRight: '<?',
        defaultRange: '@',
        onUpdate: '&',
    },
    controller: ['$state', 'crDateRangeService', controller],
};

export default TimeFramePickerComponent;
