import angular from 'angular';
import EntityLocalizationModule from './entity-localization/entity-localization';
import EntitySelectionManagerModule from './entity-selection-manager/entity-selection-manager';

const ServicesModule = angular.module('control-room.commons.content.services', [
    EntityLocalizationModule.name,
    EntitySelectionManagerModule.name,
]);

export default ServicesModule;
