import Controller from './enum-builder.controller';
import template from './enum-builder.html';
import './enum-builder.less';

const EnumBuilderComponent = {
    template,
    bindings: {
        isRequired: '<?',
        disableAdd: '<?',
        enumValues: '<',
        locale: '<',
        onUpdate: '&',
        isSubmitted: '<',
    },
    controller: ['crAnalyticsService', 'crValidationBuilderService', 'crConstants', Controller],
};

export default EnumBuilderComponent;
