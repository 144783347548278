import angular from 'angular';
import _ from 'lodash';

const WeekHighlight = angular
    .module('control-room.commons.basic.week-picker.week-highlight', [])
    .directive('crWeekHighlight', () => ({
        restrict: 'A',
        link: (scope, elem) => {
            setTimeout(() => {
                const radiusSize = '4px';
                const weeks = elem[0].querySelectorAll('.uib-weeks');

                let activeDays = [];

                _.some(weeks, (week) => {
                    const days = week.querySelectorAll('.uib-day');

                    _.some(days, (day) => {
                        const isActiveWeek = day.querySelector('button.active');

                        if (isActiveWeek) {
                            activeDays = days;
                            return true;
                        }
                    });

                    if (!_.isEmpty(activeDays)) {
                        return true;
                    }
                });

                activeDays.forEach((activeDay, index) => {
                    const button = activeDay.querySelector('button.btn');
                    button.style.backgroundColor = '#c7dfef';
                    button.style.color = '#444';

                    if (index === 0) {
                        button.style.borderTopLeftRadius = radiusSize;
                        button.style.borderBottomLeftRadius = radiusSize;
                    }

                    if (index === 6) {
                        button.style.borderTopRightRadius = radiusSize;
                        button.style.borderBottomRightRadius = radiusSize;
                    }
                });
            }, 0);
        },
    }));

export default WeekHighlight;
