class ActionItemList {
    constructor(crImageService) {
        this.crImageService = crImageService;
    }

    $onInit() {
        this.items.forEach((item) => {
            item.stickerSrc = this.crImageService.getStickerThumbSrc(item.images);
        });

        this.imageShape = this.imageShape || 'circle';
    }
}

export default ActionItemList;
