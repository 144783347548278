import angular from 'angular';
import DateRangePickerComponent from './date-range-picker.component';
import locale from './resources/locale/en.json';

const DateRangePickerModule = angular
    .module('control-room.commons.basic.components.date-range-picker', [])
    .constant('crDateRangePickerLocale', locale)
    .component('crDateRangePicker', DateRangePickerComponent);

export default DateRangePickerModule;
