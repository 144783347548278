export enum EventTabsType {
    VENUE = "VENUE",
}

export interface EventTabs {
    id?: string,
    name: string,
    displayOrder: number,
    owner: {
        id: string,
        type: EventTabsType,
    },
}

export interface EventTabsResponse {
    content: EventTabs[],
}