import Controller from './experience-schedule.controller';
import template from './experience-schedule.html';
import './experience-schedule.less';

const Component = {
    template,
    bindings: {
        customClass: '@?',
        experience: '<',
        experienceType: '<',
    },
    require: {
        form: '^form',
    },
    controller: ['$scope', '$timeout', 'crConstants', 'crScheduleService', Controller],
};

export default Component;
