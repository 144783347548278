<cr-title-bar icon="icon-cr-waiter" [label]="text.header"> </cr-title-bar>
<cr-nav-header [navLocation]="navLocation"></cr-nav-header>

<div *ngIf="!hasError && !isLoading" class="content">
    <div class="title">{{ text.title }}</div>
    <div class="subtitle">{{ text.subtitle }}</div>
    <div class="restaurants">
        <div
            *ngFor="let location of locations"
            class="restaurant"
            (click)="selectLocation(location.productId, location.name)"
        >
            <span class="restaurant-icon icon-cr-dining"></span>
            <div class="restaurant-name">{{ location.name }}</div>
        </div>
    </div>
</div>

<div *ngIf="!hasError && isLoading" class="cr-page-padding-top cr-flex-center">
    <cr-spinner></cr-spinner>
</div>

<cr-data-error *ngIf="hasError" [message]="text.loadingFailed"></cr-data-error>
