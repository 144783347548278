import text from './resources/locale/en.json';

class EventFlyoutController {
    constructor(
        $scope,
        $state,
        $uibModal,
        crConstants,
        crVenueService,
        crTimeService,
        crScheduleService,
        crScrollService,
        crAnalyticsService
    ) {
        this.$scope = $scope;
        this.$state = $state;
        this.$uibModal = $uibModal;
        this.crConstants = crConstants;
        this.crVenueService = crVenueService;
        this.crTimeService = crTimeService;
        this.crScheduleService = crScheduleService;
        this.crScrollService = crScrollService;
        this.crAnalyticsService = crAnalyticsService;
    }

    $onInit() {
        this.crScrollService.disablePageScroll();
        this.text = text;
        this.maxPoiChips = 4;
        this.CANCEL_EVENT = this.crConstants.events.CANCEL_CALENDAR_INSTANCE;
        this.tz = this.crVenueService.getTimezone(this.$state.params.venueId).name;
        this.schedule = this.data.schedule;
        this.startAndEnd = this.crTimeService.getShortTimeRange(this.schedule.start, this.schedule.end, this.tz);

        this.trackingData = { eventName: this.data.name, eventId: this.data.id };
        this.crAnalyticsService.track('Calendar - Event Clicked', this.trackingData);
    }

    $onDestroy() {
        this.crScrollService.enablePageScroll();
    }

    viewEvent() {
        this.crAnalyticsService.track('Calendar Event Flyout - View Details', this.trackingData);
        this.goToDetailsPage(this.data.id);
    }

    editEvent() {
        this.crAnalyticsService.track('Calendar Event Flyout - Edit Event', this.trackingData);
        this.goToEditPage(this.data.id);
    }

    confirmCancelEvent() {
        this.$uibModal
            .open({
                backdrop: 'static',
                component: 'crSimpleModal',
                windowClass: 'cr-modal-size-sm',
                resolve: {
                    type: () => 'submit',
                    submitText: () => this.text.yes,
                    message: () => this.text.deleteConfirmationMessage,
                },
            })
            .result.then(() => {
                this.cancelEvent(this.data.id, this.schedule.scheduleId, this.schedule.start);
            })
            .catch(() => {
                this.crAnalyticsService.track('Calendar Event Flyout - Cancel Not Confirmed', this.trackingData);
            });
    }

    cancelEvent(eventId, scheduleId, scheduleStart) {
        this.crScheduleService
            .cancelEventSchedule(eventId, scheduleId, scheduleStart, this.tz)
            .then((data) => {
                const toastObj = { toast: null };

                // Set a toast if this was the final schedule instance
                if (data.finalInstance && this.data.state === this.crConstants.entity.states.ACTIVE) {
                    toastObj.toast = `"${this.data.name}" ${this.text.inactiveMessage}`;
                }

                this.$scope.$emit(this.CANCEL_EVENT, toastObj);
                this.crAnalyticsService.track('Calendar Event Flyout - Event Cancelled', this.trackingData);
            })
            .catch(() => {
                this.showErrorModal();
            });
    }

    goToDetailsPage(id) {
        this.$state.go('client.events.details', { id });
    }

    goToEditPage(id) {
        this.$state.go('client.events.edit', { id });
    }

    showErrorModal() {
        this.crAnalyticsService.track('Calendar Event Flyout - Error Canceling', this.trackingData);
        this.$uibModal
            .open({
                backdrop: 'static',
                component: 'crSimpleModal',
                windowClass: 'cr-modal-size-sm',
                resolve: {
                    message: () => this.text.errorMessage,
                },
            })
            .result.catch(() => null);
    }
}

export default EventFlyoutController;
