import _ from 'lodash';

class PoiListService {
    constructor(crPoiService, crImageService, crEntityService) {
        this.crPoiService = crPoiService;
        this.crImageService = crImageService;
        this.crEntityService = crEntityService;
    }

    getData(params) {
        const queryParams = _.pickBy(params, (value) => !_.isNil(value) && value !== '' && value !== 'all');

        return this.crPoiService.getPois(queryParams).then((res) => res);
    }

    getPoiCategories(venueId, locale) {
        return this.crEntityService.getCategoriesWithHierarchy('poi-categories', venueId, locale);
    }

    mapToRowsModel(pois, categories) {
        return pois.map((poi) => {
            const categoriesMap = _.keyBy(categories, 'id');

            const row = {
                data: {
                    id: poi.id,
                    stickerSrc: this.getStickerThumbSrc(poi),
                    isLandmark: poi.landmark,
                    name: {
                        label: poi.name,
                    },
                    title: {
                        label: poi.title,
                    },
                    state: {
                        label: poi.state.toLowerCase(),
                    },
                    categories: (poi.displayCategories || []).map((category) => {
                        const matchedCategory = categoriesMap[category.id];

                        return matchedCategory ? matchedCategory.hierarchyLabel : category.label;
                    }),
                    experiences: {
                        label: 'out of scope',
                    },
                    events: {
                        label: 'out of scope',
                    },
                    missingTranslations: _.isArray(poi.missingTranslations) ? poi.missingTranslations.join(', ') : '',
                    isDisabled: poi.isDisabled,
                    originalData: poi,
                },
            };

            return row;
        });
    }

    getStickerThumbSrc(poi) {
        return this.crImageService.getStickerThumbSrc(poi.images);
    }
}

export default PoiListService;
