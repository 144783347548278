import Controller from './calendar-all-day-event.controller';
import template from './calendar-all-day-event.html';
import './calendar-all-day-event.less';

const Component = {
    template,
    require: {
        calendar: '^crCalendar',
    },
    bindings: {
        data: '<',
    },
    controller: ['$state', Controller],
};

export default Component;
