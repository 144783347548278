import _ from 'lodash';
import text from './resources/locale/en.json';

class EntityContentController {
    constructor(
        crImageService,
        crConstants,
        crErrorLoggingService,
        crLocaleService,
        crConfig,
        crAnalyticsService
    ) {
        this.crImageService = crImageService;
        this.imageConstants = crConstants.image;
        this.crErrorLoggingService = crErrorLoggingService;
        this.crLocaleService = crLocaleService;
        this.crConfig = crConfig;
        this.crAnalyticsService = crAnalyticsService;
    }

    $onInit() {
        this.text = text;
    }

    $onChanges() {
        this.entity = _.cloneDeep(this.entity);
        this.isDefaultLocale = this.locale.current === this.locale.default;

        if (this.entity.localization && this.locale) {
            if (this.manage) {
                this.descriptionModel = this.localizedDescription;
                this.updateLocaleStatus();
            } else {
                this.locale.list = this.crLocaleService.updateLocaleListStatus(
                    this.entity.missingTranslations,
                    this.locale
                );
            }

            this.initDropdown();
        }
    }

    updateLocaleStatus() {
        this.locale.list = this.crLocaleService.updateLocaleStatus(this.entity.localization, this.locale);
    }

    onImageChange(event) {
        this.onImagesUpdate({
            $event: { model: event.images },
        });
    }

    // TODO: this should update onKeyPressUp not blur
    onTitleChange(event) {
        this.onTitleUpdate({
            $event: { model: event.model },
        });
    }

    onDescriptionChange(model) {
        this.onDescriptionUpdate({
            $event: { model },
        });
    }

    onLocaleUpdate(event) {
        const locale = event.model[0];
        this.isDefaultLocale = locale.id === this.locale.default;

        this.crAnalyticsService.track('Language Selected', { language: locale.label });

        this.onLocaleSelect({
            $event: { locale },
        });
    }

    isInvalidForm() {
        const { localization } = this.entity;
        const isDefaultLanguage = this.locale.current === this.locale.default;
        const hasCta = !_.isEmpty(localization && localization[this.locale.default].callToAction);
        let hasValidCta = false;

        if (localization && localization[this.locale.default].callToAction) {
            const cta = localization[this.locale.default].callToAction;
            hasValidCta = cta.label && cta.link;
        }

        const ctaValidIfPresent = !hasCta || hasValidCta;

        return (localization && isDefaultLanguage && !this.localizedTitle) || !ctaValidIfPresent;
    }

    initDropdown() {
        this.showDropdown =
            this.crConfig.customer.localizationEnabled && this.locale.list && this.locale.list.length > 1;
    }
}

export default EntityContentController;
