import controller from './coupon-manage.controller';
import template from './coupon-manage.html';

import './coupon-manage.less';

const component = {
    template,
    bindings: {
        edit: '<?',
    },
    controller: [
        '$state',
        '$uibModal',
        'crAnalyticsService',
        'crConfig',
        'crCouponsService',
        'crErrorLoggingService',
        'crLocaleService',
        'crNavigationService',
        controller,
    ],
};

export default component;
