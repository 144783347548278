import _ from 'lodash';

class ProductsListService {
    constructor(crConstants, crImageService, crEntityService) {
        this.crConstants = crConstants;
        this.crImageService = crImageService;
        this.crEntityService = crEntityService;
    }

    getData(params, menuId) {
        const queryParams = _.pickBy(params, (value) => !_.isNil(value) && value !== '' && value !== 'all');

        const entityType = this.crConstants.entity.types.MENU_PRODUCTS;
        const url = `menus/${menuId}/${entityType}`;

        return this.crEntityService.getEntityList(url, queryParams);
    }

    getCategories(menuId, venueId, placeId) {
        const entityType = this.crConstants.entity.types.MENU_CATEGORIES;
        const entityRoute = `menus/${menuId}/${entityType}`;
        return this.crEntityService.getCategoriesWithHierarchy(entityType, venueId, null, entityRoute, { placeId });
    }

    mapToRowsModel(products, categories) {
        return products.map((product) => {
            const categoriesMap = _.keyBy(categories, 'id');
            return {
                data: {
                    id: product.id,
                    stickerSrc: this.crImageService.getStickerThumbSrc(product.images),
                    name: product.content.name,
                    title: product.displayName,
                    categories: (product.categories || []).map((category) => {
                        const matchedCategory = categoriesMap[category.id];
                        return matchedCategory ? matchedCategory.hierarchyLabel : category.label;
                    }),
                    state: product.state.toLowerCase(),
                    missingTranslations: _.isArray(product.missingTranslations)
                        ? product.missingTranslations.join(', ')
                        : '',
                    originalData: product,
                },
            };
        });
    }
}

export default ProductsListService;
