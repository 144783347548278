import moment from 'moment';
import {
  Component, OnInit, Input, SimpleChanges, OnChanges, Inject,
} from '@angular/core';

import { HttpErrorResponse } from '@angular/common/http';
import { catchError, take } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { LocalizedText, Constants } from '../../../../../../core';
import { CR_CONSTANTS, VenueService, TagService } from '../../../../../../shared';
import { UserCountResponseDto } from '../../../../../../shared/models/order.model';

import text from './resources/locale/en.json';

@Component({
  selector: 'cr-reach',
  templateUrl: 'reach.component.html',
  styleUrls: ['./reach.component.scss'],
})
export class ReachComponent implements OnChanges, OnInit {
  @Input() tags: any[];

  @Input() venueId: string;

  @Input() inVenue: boolean;

  loading = false;

  reach: string;

  text: LocalizedText;

  userTags = {};

  constructor(
    @Inject(CR_CONSTANTS) private constants: Constants,
    private tagService: TagService,
    private venueService: VenueService,
  ) {
    this.text = text as LocalizedText;
  }

  ngOnInit() {
    this.getReach();
  }

  ngOnChanges(changes: SimpleChanges) {
    const { venueId, tags, inVenue } = changes;
    if (venueId && venueId.currentValue !== venueId.previousValue
        || tags && tags.currentValue !== tags.previousValue
        || inVenue && inVenue.currentValue !== inVenue.previousValue) {
      this.getReach();
    }
  }

  private handleError(error?: HttpErrorResponse): void {
    this.reach = 'Unknown';
    this.loading = false;
  }

  private handleResponseUserLocations({ locations }: { locations: Array<any> }, filteredTags: Array<any>) {
    const userIds = locations.map((location) => location.userId);
    if (filteredTags.length > 0 && userIds.length > 0) {
      this.tagService.getTagDefinitionsCount(this.venueId, userIds, filteredTags)
        .pipe(take(1), catchError((err) => of(this.handleError(err))))
        .subscribe((res: UserCountResponseDto) => this.handleResponseUserCount(res));
    } else this.handleResponseUserCount({ userCount: userIds.length });
  }

  private handleResponseUserCount({ userCount }: UserCountResponseDto) {
    this.reach = `${Math.ceil(Number(Number(userCount).toPrecision(2)))}`;
    this.loading = false;
  }

  private getFilteredTags(): Array<any> {
    return this.tags.filter(({ key, values }) => !(key === null || key === this.constants.appUsersTagKey || values.length === 0));
  }

  private getReach() {
    this.loading = true;
    try {
      const filteredTags = this.getFilteredTags();
      if (this.inVenue) {
        const timeRange = moment().subtract(8, 'hours').toISOString();
        from(this.venueService.getUserLocations(this.venueId, timeRange))
          .pipe(take(1), catchError((err) => of(this.handleError(err))))
          .subscribe((response) => this.handleResponseUserLocations(response, filteredTags));
      } else {
        this.tagService.getUserCountEstimate(this.venueId, filteredTags)
          .pipe(take(1), catchError((err) => of(this.handleError(err))))
          .subscribe((res: UserCountResponseDto) => this.handleResponseUserCount(res));
      }
    } catch (error) {
      this.handleError(error);
    }
  }
}
