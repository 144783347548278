import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Component, EventEmitter, Input, OnInit, Output
} from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { noop } from 'rxjs';
import { ValidationMessages } from '../../../core';

@Component({
  selector: 'cr-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent implements OnInit, ControlValueAccessor {

  @Input() type = 'text';

  @Input() maxlength: number | string;

  @Input() placeholder = '';

  @Input() step: number | string = '';

  @Input() validationMessages: ValidationMessages;

  @Input() label: string;

  get disabled(): boolean {
    return this._disabled;
  }

  @Input()
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
  }

  get hideValidation(): boolean {
    return this._hideValidation;
  }

  @Input()
  set hideValidation(value: boolean) {
    this._hideValidation = coerceBooleanProperty(value);
  }

  get showValidMessage(): boolean {
    return this._showValidMessage;
  }

  @Input()
  set showValidMessage(value: boolean) {
    this._showValidMessage = coerceBooleanProperty(value);
  }

  @Output()
  keypress = new EventEmitter<KeyboardEvent>();

  @Output()
  keyup = new EventEmitter<KeyboardEvent>();

  @Output()
  focus = new EventEmitter<FocusEvent>();

  @Output()
  blur = new EventEmitter<FocusEvent>();

  control: FormControl;

  private _value: string;

  private _disabled: boolean;

  private _hideValidation: boolean;

  private _showValidMessage: boolean;

  constructor(private ngControl: NgControl) {
    this.ngControl.valueAccessor = this;
  }

  ngOnInit() {
    this.control = this.ngControl.control as FormControl;
  }

  onChange: (_?: any) => void = () => noop();

  onTouched: (_?: any) => void = () => noop();

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  get value(): string {
    return this._value;
  }

  set value(value: string) {
    this._value = value;
    this.onChange(this._value);
  }

  writeValue(value: string) {
    this.value = value;
  }

  onInput(value: string) {
    this.value = value;
  }

  onFocus(event: FocusEvent) {
    this.focus.emit(event);
  }

  onBlur(event: FocusEvent) {
    this.onTouched(event);
    this.blur.emit(event);
  }

  onKeypress(event: KeyboardEvent) {
    this.keypress.emit(event);
  }

  onKeyup(event: KeyboardEvent) {
    this.keyup.emit(event);
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
}
