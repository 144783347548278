import Controller from './page-not-found.controller';
import template from './page-not-found.html';
import './page-not-found.less';

const Component = {
    template,
    controller: Controller,
};

export default Component;
