import angular from 'angular';

import AutomationSelectModule from './automation-select/automation-select';
import BroadcastSelectModule from './broadcast-select/broadcast-select';
import CouponDetailsModule from './coupon-details/coupon-details';
import CouponManageModule from './coupon-manage/coupon-manage';
import CouponsList from './coupons-list/coupons-list';
import ExperienceCalendarModule from './experience-calendar/experience-calendar';
import ExperienceDashboardModule from './experience-dashboard/experience-dashboard';
import ExperienceDetailsModule from './experience-details/experience-details';
import ExperienceFlyoutModule from './experience-flyout/experience-flyout';
import ExperienceListModule from './experience-list/experience-list';
import ExperienceManageModule from './experience-manage/experience-manage';
import ExperiencePhonePreviewModule from './experience-phone-preview/experience-phone-preview';
import ExperienceReportModule from './experience-report/experience-report';

const Components = angular.module('control-room.experience-promoter.components', [
    ExperienceManageModule.name,
    AutomationSelectModule.name,
    ExperienceListModule.name,
    BroadcastSelectModule.name,
    ExperienceDashboardModule.name,
    ExperiencePhonePreviewModule.name,
    ExperienceReportModule.name,
    CouponDetailsModule.name,
    CouponsList.name,
    CouponManageModule.name,
    ExperienceCalendarModule.name,
    ExperienceFlyoutModule.name,
    ExperienceDetailsModule.name,
]);

export default Components;
