<section [ngClass]="{ 'is-focused': isFocused }">
    <div *ngIf="!isLoading">
        <div>
            <div
                class="cr-chip-container"
                [ngClass]="{ 'cr-disabled': !options.length }"
                [ngbTooltip]="emptyOptionsText"
                [disableTooltip]="!!options.length"
                attr.data-local-qa-id="chip-container"
                (click)="onClick($event)"
            >
                <div class="cr-chip-input-container">
                    <div
                        class="cr-chip-placeholder"
                        *ngIf="
                            !isFocused &&
                            (!currentTags || currentTags.length === 0)
                        "
                    >
                        {{ placeholder }}
                    </div>
                    <div class="cr-chips">
                        <div
                            *ngFor="
                                let tag of currentTags;
                                let i = index;
                                trackBy: trackByTagId
                            "
                            class="cr-chip-wrapper"
                        >
                            <div
                                class="cr-chip"
                                [attr.data-global-qa-id]="'chip-' + i"
                            >
                                {{ tag.chipLabel || tag.label
                                }}<span
                                    (click)="removeTag($event, i)"
                                    *ngIf="!disableRemove"
                                    data-local-qa-id="remove-chip"
                                    class="icon-cr-close"
                                ></span>
                            </div>
                        </div>

                        <input
                            type="hidden"
                            [formControl]="currentTagsControl"
                            [attr.name]="name"
                        />
                    </div>
                    <div
                        *ngIf="!disableRemove"
                        class="cr-chip-input-wrapper"
                        ngbDropdown
                        #dropdown="ngbDropdown"
                        (openChange)="onToggle($event)"
                        placement="bottom-left"
                    >
                        <input
                            #inputElement
                            ngbDropdownAnchor
                            class="cr-chip-input"
                            data-local-qa-id="input"
                            [formControl]="inputControl"
                            (keydown)="onKeydown($event)"
                            (keyup)="onKeyup()"
                            (focus)="setFocus(true)"
                            (blur)="setFocus(false)"
                            [maxlength]="maxlength"
                        />
                        <ul
                            ngbDropdownMenu
                            class="dropdown-menu"
                            *ngIf="filteredOptions.length"
                            data-global-qa-id="dropdown-menu"
                        >
                            <li
                                *ngFor="
                                    let option of filteredOptions;
                                    let i = index;
                                    trackBy: trackByTagId
                                "
                                [ngClass]="{ indent: option.indent }"
                                [attr.data-global-qa-id]="'list-' + i"
                            >
                                <a
                                    ngbDropdownItem
                                    (mousedown)="addTag(option)"
                                    >{{ option.label }}</a
                                >
                            </li>
                        </ul>
                    </div>
                </div>
                <span
                    *ngIf="addButton && options.length"
                    class="icon-cr-add"
                    data-local-qa-id="add"
                    (click)="onAddClick($event)"
                ></span>
            </div>
        </div>
    </div>
    <div *ngIf="isLoading">
        <cr-spinner small-spinner="true"></cr-spinner>
    </div>
</section>
