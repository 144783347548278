import text from './resources/locale/en.json';

import ListPage from '../../../../commons/controller/list-page';
import { menuRoutes } from '../../menus.routes';

class MenuCategories extends ListPage {
    constructor(
        $state,
        crErrorLoggingService,
        crAnalyticsService,
        crToastService,
        crLocaleService,
        crConfig,
        crMenuCategoriesService,
        crEntitySelectionManager,
        crNavigationService
    ) {
        super($state, crErrorLoggingService, crAnalyticsService, crToastService, crLocaleService, crConfig);
        this.crMenuCategoriesService = crMenuCategoriesService;
        this.crEntitySelectionManager = crEntitySelectionManager;
        this.crNavigationService = crNavigationService;

        this.text = text;
    }

    $onInit() {
        super.$onInit();

        this.registerParam('sortby', 'displayName');
        this.registerParam('sort', 'asc');

        this.menuId = this.$state.params.menuId;
        this.placeId = this.$state.params.placeId;
        const { columns } = this.text;

        this.listData = {
            columns: [
                { key: 'placeholder', label: '' },
                { key: 'displayName', label: columns.name, class: 'xlarge-column name-column', sortable: true },
                { key: '', label: '', class: '' },
            ],
            rows: [],
        };

        if (this.hasMultipleLanguages()) {
            this.addMissingTranslationsColumnHeader();
        }

        this.checkDistributedChitPrintingSupported();
    }

    getData() {
        this.isLoading = true;
        this.dataError = false;

        this.params.placeId = this.placeId;

        return this.crMenuCategoriesService
            .getData(this.menuId, this.params)
            .then((data) => {
                data.search = data.query.search || '';

                if (data.search === this.params.search) {
                    this.isLoading = false;
                    return this.crMenuCategoriesService.mapToRowsModel(data.content);
                }
            })
            .catch((err) => {
                this.isLoading = false;
                this.dataError = true;
                this.endOfResults = true;
                this.listData.rows = [];
                this.crErrorLoggingService.logError('Could not get menu categories data', err);
            });
    }

    checkDistributedChitPrintingSupported() {
        this.isDistributedChitPrintingSupported = (this.crConfig.customer.configFlags.enable_distributed_chit_printing && 
                this.crConfig.customer.configFlags.enable_distributed_chit_printing.includes(this.$state.params.venueId)) ? true: false;
    }

    goBack() {
        this.crNavigationService.goBack(menuRoutes.PRODUCTS, { menuId: this.menuId, placeId: this.placeId });
    }

    goToCreatePage() {
        this.crAnalyticsService.track('Add New Menu Category Clicked');
        this.$state.go(menuRoutes.CREATE_CATEGORY, {
            customerId: this.$state.params.customerId,
            menuId: this.menuId,
            placeId: this.placeId,
        });
    }
}

export default MenuCategories;
