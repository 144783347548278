import _ from 'lodash';
import moment from 'moment';
import Chartist from 'chartist';
import template from './chart.html';
import tooltipPlugin from './vendor/tooltip.chartist';
import './vendor/chartist.css';
import './chart.less';
import ChartController from './chart.controller';
import { InvalidPropertyError } from '../../../error/error';
import ResizeObs from 'resize-observer-polyfill';

class ChartDirective {
    constructor(constant) {
        this.constant = constant;
        this.restrict = 'E';
        this.controller = ChartController;
        this.controllerAs = 'chartCtrl';
        this.bindToController = true;
        this.template = template;
        this.scope = {
            data: '<',
            options: '<',
            responsiveOptions: '<',
            mini: '<',
            type: '@',
        };
    }

    static directiveFactory(constant) {
        return new ChartDirective(constant);
    }

    link(scope, element, attrs, controller) {
        let Type;
        const type = controller.type.toLowerCase();

        controller.type = controller.type || 'line';

        const defaultOptions = _.cloneDeep(
            !controller.mini ? this.constant.defaultOptions : this.constant.miniDefaultOptions
        );

        const options = _.mergeWith({}, defaultOptions, controller.options || {}, (objValue, srcValue) => {
            if (_.isArray(srcValue)) {
                return srcValue;
            }
        });

        if (options.tooltip !== false) {
            options.plugins.push(tooltipPlugin(options.tooltip));
        }

        if (options.rotateLabels) {
            options.chartPadding.bottom = 55;
            options.height += 35;
        }

        switch (type) {
            case 'line':
                Type = Chartist.Line;
                break;
            case 'bar':
                Type = Chartist.Bar;
                break;
            default:
                throw new InvalidPropertyError('type');
        }

        const updateAxis = () => {
            let low = Infinity;
            let high = -Infinity;

            if (controller.data) {
                controller.data.series[0].forEach((data) => {
                    low = Math.min(low, data.value);
                    high = Math.max(high, data.value);
                });

                if (low === Infinity || low > 0) {
                    low = 0;
                }

                if (high === -Infinity) {
                    high = 10;
                }

                if (high - low <= 10) {
                    high = low + 10;
                } else {
                    low = null;
                    high = null;
                }

                if (low === null && high === null) {
                    delete options.low;
                    delete options.high;
                    options.type = Chartist.AutoScaleAxis;
                } else {
                    options.low = low;
                    options.high = high;
                    options.type = Chartist.FixedScaleAxis;
                }
            } else {
                options.low = 0;
                options.high = 10;
                options.type = Chartist.FixedScaleAxis;
            }
        };

        updateAxis();

        const chart = new Type(element.children()[0], controller.data, options, controller.responsiveOptions);

        chart.on('draw', (data) => {
            if (type === 'line') {
                if (data.type === 'line') {
                    data.element._node.setAttribute(
                        'transform',
                        `translate(${(data.chartRect.width() - data.element.width()) / 2}, 0)`
                    );
                } else if (data.type === 'point') {
                    data.element._node.setAttribute('transform', `translate(${data.axisX.stepLength / 2}, 0)`);
                    const clone = data.element._node.cloneNode();
                    clone.style.stroke = options.holeColor;
                    clone.style.strokeWidth = options.holeWidth;
                    data.element._node.parentElement.appendChild(clone);
                }
                const isLabel = data.type === 'label';
                if (isLabel && data.axis.counterUnits.pos === 'y' && data.text === moment().format('M/DD')) {
                    data.element._node.childNodes[0].classList.add('cr-end');
                }
                if (
                    isLabel &&
                    options.hideMiddleLabels &&
                    data.index !== 0 &&
                    data.index !== data.axis.ticks.length - 1
                ) {
                    data.element._node.style.display = 'none';
                }
                if (isLabel && options.rotateLabels && data.axis.counterUnits.pos === 'y') {
                    data.element._node.childNodes[0].classList.add('cr-rotate');
                }
            } else if (type === 'bar') {
                if (data.type === 'bar') {
                    const indexMultiplier = options.colorPalette.length / data.series.length;
                    data.element._node.style.stroke = options.colorPalette[Math.round(data.index * indexMultiplier)];
                }
                if (data.type === 'label' && data.axis.counterUnits.pos === 'y') {
                    data.element._node.childNodes[0].classList.add('cr-end');
                }
                if (data.type === 'label' && options.rotateLabels && data.axis.counterUnits.pos === 'y') {
                    data.element._node.childNodes[0].classList.add('cr-rotate');
                }
            }
        });

        scope.$watch(
            () => controller.data || controller.options,
            () => {
                updateAxis();

                chart.update(controller.data, options);
            }
        );

        const ro = new ResizeObs(() => chart.update());

        ro.observe(element[0].parentElement);

        scope.$on('$destroy', () => {
            ro.disconnect();
            chart.detach();
        });
    }
}

ChartDirective.directiveFactory.$inject = ['crChartConstant'];

export default ChartDirective;
