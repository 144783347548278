import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { StateService } from '@uirouter/core';
import { BsModalService } from 'ngx-bootstrap/modal';

import {
  EventsService,
  FeaturedEventsService,
  NavigationService,
  ErrorLoggingService,
  SimpleModalComponent,
} from '../../../../shared';
import { LocalizedText, SelectOption, ReorderEvent } from '../../../../core';

import text from './resources/locale/en.json';

@Component({
  selector: 'cr-featured-events-manage',
  templateUrl: './featured-events-manage.component.html',
  styleUrls: ['./featured-events-manage.component.scss'],
})
export class FeaturedEventsManageComponent implements OnInit {
  text: LocalizedText = text;

  featuredOptions: SelectOption[];

  trendingOptions: SelectOption[];

  featuredAndTrending: FormGroup;

  trending: FormArray;

  hasDataError: boolean;

  phonePreview: any;

  loading = true;

  private _eventsMap: { [id: string]: any } = {};

  constructor(
    private featuredEventsService: FeaturedEventsService,
    private eventsService: EventsService,
    private state: StateService,
    private fb: FormBuilder,
    private navigationService: NavigationService,
    private errorLoggingService: ErrorLoggingService,
    private modalService: BsModalService,
  ) {}

  ngOnInit() {
    this.featuredAndTrending = this.fb.group({
      featured: [null],
      trending: this.fb.array([[null], [null], [null], [null], [null]]),
    });

    this.trending = this.featuredAndTrending.controls.trending as FormArray;
    const { venueId } = this.state.params;

    this.navigationService.enableConfirmNavigation(() => this.featuredAndTrending.dirty);

    Promise.all([
      this.eventsService.getList({ state: 'ACTIVE', sort: 'asc', venueId }),
      this.featuredEventsService.get(venueId),
    ])
      .then((data: [any, any]) => {
        const events = data[0].content;
        const selections = data[1];
        const featured = selections.featured ? selections.featured[0] : null;
        const { trending } = selections;

        events.forEach((event) => {
          this._eventsMap[event.id] = event;
        });

        this.featuredOptions = events.map((event) => ({
          value: event.id,
          label: event.title,
        }));

        this.trendingOptions = events.map((event) => ({
          value: event.id,
          label: event.title,
        }));

        if (featured && featured.state === 'ACTIVE') {
          this.featuredAndTrending.controls.featured.setValue(featured.id);
        }

        trending.forEach((event, index) => {
          if (event && event.state === 'ACTIVE') {
            this.trending.at(index).setValue(event.id);
          }
        });

        this.updateDisabledOptions();
        this.updatePhonePreview();

        this.loading = false;
      })
      .catch((err) => {
        this.hasDataError = true;
        this.loading = false;
        this.errorLoggingService.logError('could not retrieve featured events', err);
      });
  }

  onTrendingReorder(event: ReorderEvent) {
    const control = this.trending.at(event.previousIndex);
    this.trending.removeAt(event.previousIndex);
    this.trending.insert(event.currentIndex, control);

    this.featuredAndTrending.controls.trending.markAsDirty();
    this.updatePhonePreview();
  }

  onTrendingSelect() {
    this.updateDisabledOptions();
    this.updatePhonePreview();
  }

  onFeaturedSelect() {
    this.updatePhonePreview();
  }

  updateDisabledOptions() {
    const trending = this.featuredAndTrending.controls.trending as FormArray;
    const trendingValues = trending.value.filter((v) => !!v);

    if (this.trendingOptions) {
      this.trendingOptions.forEach((option) => {
        option.disabled = trendingValues.includes(option.value);
      });
    }
  }

  updatePhonePreview() {
    const featuredId = this.featuredAndTrending.value.featured;
    const trendingIds = this.trending.value.filter((v) => !!v);

    this.phonePreview = {
      featured: [this._eventsMap[featuredId]],
      trending: trendingIds.map((id) => this._eventsMap[id]),
    };
  }

  goBack(params: { tabId?: string; toast?: object } = { tabId: 'featured-and-trending' }) {
    this.navigationService.goBack('client.events.list', params);
  }

  save() {
    this.loading = true;
    const { featured, trending } = this.featuredAndTrending.value;

    const payload = {
      featured: featured ? [this._eventsMap[featured]] : [],
      trending: trending.map((id) => this._eventsMap[id]).filter((event) => !!event),
    };

    this.featuredEventsService
      .update(payload, this.state.params.venueId)
      .then(() => {
        this.navigationService.disableConfirmNavigation();
        this.goBack({
          tabId: 'featured-and-trending',
          toast: {
            msg: this.text.featuredAndTrendingUpdated,
          },
        });
      })
      .catch((err) => {
        this.loading = false;
        this.modalService.show(SimpleModalComponent, {
          initialState: {
            message: this.text.updateError,
          },
          backdrop: 'static',
          class: 'cr-modal-size-sm',
        });

        this.errorLoggingService.logError('could not update featured events', err);
      });
  }
}
