import angular from 'angular';

import text from './resources/locale/en.json';
import TabularTopItemsMockService from './tabular-top-items.mock.service';
import TabularTopItemsComponent from './tabular-top-items.component';

const TabularTopItemsModule = angular
    .module('.control-room.reports.components.orders.components.tabular-top-items', [])
    .service('crReportingTabularTopItemsMockService', TabularTopItemsMockService)
    .constant('crReportingTabularTopItemsText', text)
    .component('crReportingTabularTopItems', TabularTopItemsComponent);

export default TabularTopItemsModule;
