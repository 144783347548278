/* eslint no-template-curly-in-string: */
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { StateService } from '@uirouter/core';
import { cloneDeep, find, forEach, isEmpty, remove } from 'lodash';
import { Config } from '../../../core/config';
import { Constants } from '../../../core/constants';
import { IconDto, IconsResponse, ImageType } from '../../models';
import { CR_CONFIG } from '../config/cr-config';
import { CR_CONSTANTS } from '../constants/constants';

@Injectable({
    providedIn: 'root',
})
export class ImageService {
    private Upload: any;
    private imageServiceUrl: string;
    constructor(private state: StateService,
                private http: HttpClient, 
                @Inject('Upload') Upload: any,
                @Inject(CR_CONFIG) private config: Config,
                @Inject(CR_CONSTANTS) private constants: Constants) {
        this.Upload = Upload;
        this.imageServiceUrl = config.customer.serviceUrls.image;
    }

    getIcons(folderName: string): Promise<IconDto[]> {
        return this.http.get<IconsResponse>(`/rest/icon/${folderName}`).toPromise().then((res: IconsResponse) => res.icons);
    }

    updateIconAndGetImagesModel(images: any[], iconSrc: string): any[] {
        if (isEmpty(images)) {
            images = [this.getIconModel(iconSrc)];
        } else {
            const icon = this.getImage(images, ImageType.ICON);

            if (icon) {
                icon.src = iconSrc;
            } else {
                images.push(this.getIconModel(iconSrc));
            }
        }

        return images;
    }

    getIconModel(src: string): { src: string, type: ImageType } {
        return { src, type: ImageType.ICON };
    }

    getImageName(src: string): string {
        return src.substring(src.lastIndexOf('/') + 1);
    }

    setIconSrc(array: { iconSrc: string, images: any[] }[]): void {
        forEach(array, (item) => {
            if (!isEmpty(item.images)) {
                item.iconSrc = this.getIconSrc(find(item.images, ['type', this.constants.image.types.ICON]).src);
            }
        });
    }

    getIconSrc(src: string, width = 'orig', height = 'orig'): string {
        return this.generateImageSrc(src, width, height, null, this.imageServiceUrl);
    }

    getImageSrc(img: any, width: string, height: string): string {
        return this.generateImageSrc(img.src, width, height, img.crop, this.imageServiceUrl);
    }

    getStickerImage(images: any[]) {
        return this.getImage(images, ImageType.STICKER);
    }

    getMainImage(images: any[]): any {
        return this.getImage(images, ImageType.MAIN);
    }

    getStickerSrc(images: any[], width: string, height: string): string {
        width = width || `${this.constants.image.sizes[ImageType.STICKER].medium.width}`;
        height = height || `${this.constants.image.sizes[ImageType.STICKER].medium.height}`;
        return this.getImageSrcByTypeAndWidth(images, ImageType.STICKER, width, height, this.imageServiceUrl);
    }

    getMainImageSrc(images: any[], width: string, height: string): string {
        width = width || `${this.constants.image.sizes[ImageType.MAIN].medium.width}`;
        height = height || `${this.constants.image.sizes[ImageType.MAIN].medium.height}`;
        return this.getImageSrcByTypeAndWidth(images, ImageType.MAIN, width, height, this.imageServiceUrl);
    }

    getStickerThumbSrc(images: any[]): string {
        const { width } = this.constants.image.sizes[ImageType.STICKER].small;
        const { height } = this.constants.image.sizes[ImageType.STICKER].small;
        return this.getStickerSrc(images, `${width}`, `${height}`) || this.getMainImageSrc(images, `${width}`, `${height}`);
    }

    getEntityType(): string {
        return this.state.current.name.split('.')[0] || 'unknown-entity';
    }

    showAsCircle(): boolean {
        // TODO: Resolve the difference between the entity type in constants (pois) and our routes (poi).
        return this.getEntityType() === 'poi';
    }

    upload(fileName: string, dataUrl: string, venueId: string): any {
        const folderName = this.getEntityType();
        return this.Upload.upload({
            url: venueId ? `/rest/image/venue/${venueId}/${folderName}` : `/rest/image/${folderName}`,
            data: {
                file: this.Upload.dataUrltoBlob(dataUrl, fileName),
            },
        });
    }

    getImageDimensions(file: any): any {
        return this.Upload.imageDimensions(file);
    }

    getBase64DataUrl(file: string): any {
        return this.Upload.base64DataUrl(file);
    }

    initImages(images: any[], type: ImageType) {
        const nonTargetImages = cloneDeep(images);

        const { width } = this.constants.image.sizes[type].medium;
        const { height } = this.constants.image.sizes[type].medium;

        // Store only the images of the desired type
        const targetImages = remove(nonTargetImages, { type });

        // Create a displaySrc for display purposes
        forEach(targetImages, (image: any) => {
            image.displaySrc = this.getImageSrc(image, width, height);
        });

        return { nonTargetImages, targetImages };
    }

    private getImage(images: any[], type: ImageType) {
        return find(images, { type });
    }

    private generateImageSrc(src: string, width: string, height: string, crop: string, imageServiceUrl: string) {
        // TODO: Remove me when we update all of the POI to have proper image JSON payloads (keep code in else block)
        if (src.indexOf('http') === 0) {
            if (src.indexOf('${width}') !== -1) {
                src = src.replace('${width}', `${width}`).replace('${height}', `${height}`);
            }
        } else {
            crop = crop ? `${crop}/` : '';
            src = `${imageServiceUrl}${crop}${width}x${height}${src}`;
        }
    
        return src;
    }

    private getImageSrcByTypeAndWidth(images: any[], type: ImageType, width: string, height: string, imageServiceUrl: string): string {
        const image = this.getImage(images, type);
        let src: string = null;
    
        if (image && image.src) {
            src = this.generateImageSrc(image.src, width, height, image.crop, imageServiceUrl);
        }
        return src;
    }
}
