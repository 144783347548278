import { menuRoutes } from "../../menus.routes";

class CreateMenuModalController {
    constructor($state, text, crErrorLoggingService, crAnalyticsService, crKitchensListService) {
        this.$state = $state;
        this.text = text;
        this.crErrorLoggingService = crErrorLoggingService;
        this.crAnalyticsService = crAnalyticsService;
        this.crKitchensListService = crKitchensListService;
    }

    $onInit() {
        this.kitchenList = null;
        this.selectedKitchen = null;

        const params = {
            page: 1,
            perPage: 40,
            sort: 'asc',
            sortby: 'name',
            state: 'enabled',
            venueId: this.$state.params.venueId,
        };
        this.pending = true;
        this.crKitchensListService
            .getData(params)
            .then((data) => {
                this.kitchenList = data.places.map((place) => ({ id: place.id, label: place.name }));
                this.pending = false;
            })
            .catch((err) => {
                this.kitchenList = [];
                this.crErrorLoggingService.logError('Could not get Kitchens List data', err);
            });
    }

    onKitchenSelect(e) {
        if (e.model && e.model.length) {
            this.selectedKitchen = e.model[0].id;
        }
    }

    onSubmit(e) {
        e.stopPropagation();

        if (!this.form.$valid || this.pending) {
            return false;
        }

        this.crAnalyticsService.track('Create Menu Modal - Success');
        this.$state.go(menuRoutes.CREATE_MENU, { kitchenId: this.selectedKitchen });
        this.close();
    }

    onCancel() {
        this.close(false);
    }
}

export default CreateMenuModalController;
