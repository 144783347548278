import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CrSearchModule } from '../../../../shared/forms/search/search.module';
import { CrTableModule } from '../../../../shared/table/table.module';
import { CrTitleBarModule } from '../../../../shared/title-bar/title-bar.module';
import { BundleGroupsService } from '../../../../shared/services/bundle-groups/bundle-groups.service';

import { BundleGroupListComponent } from './bundle-group-list.component';
import { CrIconModule } from '../../../../shared';

@NgModule({
  imports: [CrTableModule, CrSearchModule, FormsModule, ReactiveFormsModule, CrTitleBarModule, CrIconModule],
  exports: [BundleGroupListComponent],
  declarations: [BundleGroupListComponent],
  providers: [BundleGroupsService],
  entryComponents: [BundleGroupListComponent],
})
export class BundleGroupListModule { }
