import {
  Component, OnDestroy, OnInit, TemplateRef,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { StateService } from '@uirouter/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { of, Subscription, from } from 'rxjs';
import { catchError, finalize, take } from 'rxjs/operators';
import { LocalizedText } from '../../../../core';
import { CommerceService, ErrorLoggingService, TaxCode } from '../../../../shared';
import { conditionallyRequireBasedOnField } from '../../../../shared/forms/validators.helper';
import { FnbFee } from '../../../../shared/services/fees/fees.model';
import { FeesService } from '../../../../shared/services/fees/fees.service';
import { menuRoutes } from '../../menus.routes';
import text from './resources/en.json';

@Component({
  selector: 'cr-fees-manage',
  templateUrl: './fees-manage.component.html',
  styleUrls: ['./fees-manage.component.scss'],
})
export class FeesManageComponent implements OnInit, OnDestroy {
  text: LocalizedText;

  feesManageFormGroup = new FormGroup({});

  displayNameControl: FormControl;

  feeMethodControl: FormControl;

  statusControl: FormControl;

  amountControl: FormControl;

  taxControl: FormControl;

  taxCodeControl: FormControl;

  feeCategoryControl: FormControl;

  feeMethodItems = [];

  taxItems = [];

  statusItems = [];

  taxCodeItems: TaxCode[] = [];

  pageTitle: string;

  feeCategoryItems = [];

  modalRef: BsModalRef;

  currentFee: FnbFee = {
    displayName: '',
    amountOrPercent: 0,
    publishingStatus: '',
    taxable: undefined,
    type: '',
    venueId: this.state.params.venueId as string,
    accountingGroup: '',
    taxCode: '',
  };

  formSubs: Subscription[] = [];

  constructor(
    public state: StateService,
    private feesService: FeesService,
    private logger: ErrorLoggingService,
    private commerceService: CommerceService,
    private modalService: BsModalService,
  ) {
    this.text = text;
    this.feeMethodItems = [
      { id: 'PERCENT', label: this.text.percOfTotal as string },
      { id: 'FIXED', label: this.text.flatRate as string },
    ];

    this.taxItems = [
      { id: 1, label: this.text.taxable as string },
      { id: 2, label: this.text.nonTaxable as string },
    ];

    this.statusItems = [
      { id: 'ACTIVE', label: this.text.active as string },
      { id: 'INACTIVE', label: this.text.inactive as string },
      { id: 'DRAFT', label: this.text.draft as string },
    ];

    this.feeCategoryItems = [
      { id: 'convenience', label: 'Convenience' },
      { id: 'obligatory', label: 'Obligatory' },
    ];
  }

  ngOnInit() {
    this.pageTitle = this.state.params.edit ? this.text.pageTitleEdit : this.text.pageTitleCreate;
    this.initForm();
    this.initTaxCodes();
    if (this.state.params.edit === true) {
      this.feesService.getFeeById(this.state.params.feeId).pipe(
        take(1),
        catchError((err) => of(this.handleError(err))),
        finalize(() => {
          this.refreshState();
          this.refreshTaxCode();
        }),
      ).subscribe((res) => {
        if (res) {
          this.currentFee = res;
          this.fillEditFields();
        }
      });
    }
  }

  ngOnDestroy() {
    this.formSubs.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  initForm() {
    this.displayNameControl = new FormControl('', [Validators.required, Validators.maxLength(25)]);
    this.statusControl = new FormControl('', Validators.required);

    this.feeMethodControl = new FormControl('', Validators.required);
    this.amountControl = new FormControl('', [Validators.required, Validators.min(0)]);

    this.taxControl = new FormControl('', Validators.required);
    this.taxCodeControl = new FormControl('', conditionallyRequireBasedOnField('taxControl', '1'));

    this.feeCategoryControl = new FormControl('');
    this.feesManageFormGroup.addControl('statusControl', this.statusControl);
    this.feesManageFormGroup.addControl('displayNameControl', this.displayNameControl);
    this.feesManageFormGroup.addControl('feeMethodControl', this.feeMethodControl);
    this.feesManageFormGroup.addControl('amountControl', this.amountControl);
    this.feesManageFormGroup.addControl('taxControl', this.taxControl);
    this.feesManageFormGroup.addControl('taxCodeControl', this.taxCodeControl);
    this.feesManageFormGroup.addControl('feeCategoryControl', this.feeCategoryControl);

    const tax$ = this.taxControl.valueChanges.subscribe((value) => {
      if (value === 2) {
        this.taxCodeControl.reset();
      }
    });

    const formGroup$ = this.feesManageFormGroup.valueChanges.subscribe((value) => {
      this.refreshTaxCode();
    });

    this.formSubs.push(formGroup$);
    this.formSubs.push(tax$);
  }

  public onSave(): void {
    this.feesManageFormGroup.updateValueAndValidity();
    if (this.feesManageFormGroup.valid && this.state.params.edit === false) {
      this.createFee();
    } else {
      this.editFee();
    }
  }

  public onCancel(): void {
    this.navigateToFeesTab();
  }

  public createFee(): void {
    this.feesService.createFee(this.createFeeObject()).pipe(
      take(1),
      catchError((err) => of(this.handleError(err))),
    )
      .subscribe((res) => {
        if (res) {
          this.navigateToFeesTab();
        }
      });
  }

  public openModal(template: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(template);
  }

  public archiveFee(): void {
    this.feesService.deleteFee(this.currentFee.id).pipe(
      take(1),
      catchError((err) => of(this.handleError(err))),
    )
      .subscribe((res) => {
        this.modalRef.hide();
        this.navigateToFeesTab();
      });
  }

  public editFee(): void {
    this.feesService.updateFee(this.createFeeObject()).pipe(
      take(1),
      catchError((err) => of(this.handleError(err))),
    )
      .subscribe((res) => {
        if (res) {
          this.navigateToFeesTab();
        }
      });
  }

  private handleError(err) {
    this.logger.logError('fees', err);
  }

  private determineTaxable(): boolean {
    if (this.taxControl.value === '1') {
      return true;
    }
    return false;
  }

  private createFeeObject(): FnbFee {
    let taxCode: string = this.taxCodeControl.value ? this.taxCodeControl.value.id : '';
    if (this.taxControl.value === '2') {
      taxCode = '';
    }
    const newFee: FnbFee = {
      ...this.currentFee,
      displayName: this.displayNameControl.value as string,
      amountOrPercent: this.amountControl.value as number,
      publishingStatus: this.statusControl.value as string,
      taxable: this.determineTaxable(),
      type: this.feeMethodControl.value as string,
      venueId: this.state.params.venueId as string,
      taxCode,
      accountingGroup: this.feeCategoryControl.value as string,
    };

    return newFee;
  }

  private fillEditFields() {
    this.displayNameControl.setValue(this.currentFee.displayName);
    this.statusControl.setValue(this.currentFee.publishingStatus);
    this.feeMethodControl.setValue(this.currentFee.type);
    this.amountControl.setValue(this.currentFee.amountOrPercent);
    this.taxControl.setValue(this.currentFee.taxable ? '1' : '2');
    this.feeCategoryControl.setValue(this.currentFee.accountingGroup);
  }

  private initTaxCodes() {
    from(this.commerceService.getTaxCodes(`venue:${this.state.params.venueId}`)).pipe(
      take(1),
      catchError((err) => of(this.handleError(err))),
    ).subscribe((results: TaxCode[]) => {
      if (results && results.length) {
        const newOptions = results.map((option) => ({
          ...option,
          isSelected: (this.currentFee.taxCode === option.id),
        }));

        this.taxCodeItems.push(...newOptions);
      }

      // If the tax code is unknown by the place, tax service treats it as fully taxable, and so should we
      if (this.taxCodeItems.filter((code: TaxCode) => code.isSelected).length === 0) {
        this.taxCodeItems[0].isSelected = true;
        this.currentFee.taxCode = this.taxCodeItems[0].id;
      }

      const found: TaxCode = this.taxCodeItems.find((code: TaxCode) => code.isSelected === true);

      this.taxCodeControl.setValue(found);
      this.feesManageFormGroup.updateValueAndValidity();

      this.refreshTaxCode();
    });
  }

  private refreshState(): void {
    if (this.statusItems) {
      // change the object so change detection fires
      this.statusItems = this.statusItems.map((s) => {
        const state = s;
        state.isSelected = state.id === (this.statusControl.value ? this.statusControl.value : this.currentFee.publishingStatus);
        return state;
      });
    }
  }

  private refreshTaxCode(): void {
    if (this.taxCodeItems) {
      // change the object so change detection fires
      this.taxCodeItems = this.taxCodeItems.map((taxCode) => {
        const code = taxCode;
        code.isSelected = code.id === (this.taxCodeControl.value ? this.taxCodeControl.value.id : this.currentFee.taxCode);
        return code;
      });
    }
  }

  private navigateToFeesTab(): void {
    const params = {
      tabId: 'fees',
    };
    this.state.go(menuRoutes.DASHBOARD, params);
  }
}
