import controller from './modifier-groups-list.controller';
import template from './modifier-groups-list.html';

const component = {
    template,
    bindings: {},
    controller: [
        '$state',
        'crErrorLoggingService',
        'crAnalyticsService',
        'crToastService',
        'crLocaleService',
        'crConfig',
        'crNavigationService',
        'crModifierGroupsListService',
        controller,
    ],
};

export default component;
