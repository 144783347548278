import controller from './entity-manage-delete-link.controller';
import template from './entity-manage-delete-link.html';
import './entity-manage-delete-link.less';

const component = {
    template,
    bindings: {
        type: '@',
        entity: '<',
        deleteText: '@',
        customClass: '@',
        successRoute: '@',
    },
    controller: [
        '$state',
        '$uibModal',
        'crEntityService',
        'crNavigationService',
        'crErrorLoggingService',
        'crAnalyticsService',
        controller,
    ],
};

export default component;
