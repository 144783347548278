import {
  Component, Inject, OnInit, TemplateRef,
} from '@angular/core';
import { StateService } from '@uirouter/core';
import _ from 'lodash';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { forkJoin, of } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { Config, LocalizedText } from '../../../../core';
import { ImageService } from '../../../../ng1-factories';
import { CR_CONFIG, ErrorLoggingService } from '../../../../shared';
import { BundleGroupListData } from '../../../../shared/services/bundle-groups/bundle-groups.model';
import { BundleGroupsService } from '../../../../shared/services/bundle-groups/bundle-groups.service';
import { Bundle, BundleRequest } from '../../../../shared/services/bundle/bundle.model';
import { BundleService } from '../../../../shared/services/bundle/bundle.service';
import { menuRoutes } from '../../menus.routes';
import text from './resources/en.json';

@Component({
  selector: 'cr-bundle-item',
  templateUrl: './bundle-item.component.html',
  styleUrls: ['./bundle-item.component.scss'],
})
export class BundleItemComponent implements OnInit {
  item: Bundle;

  text: LocalizedText;

  mainImageSrc: string;

  stickerImageSrc: string;

  modalRef: BsModalRef;

  stateChangeTo: 'Activate' | 'Deactivate' = 'Activate';

  bundleGroups: BundleGroupListData[] = [];

  currencyCode: string;

  constructor(
    @Inject(CR_CONFIG) private config: Config,
    private logger: ErrorLoggingService,
    private stateService: StateService,
    private bundleGroupService: BundleGroupsService,
    private imageService: ImageService,
    private bundleService: BundleService,
    private modalService: BsModalService,
  ) {
    this.text = text;
  }

  ngOnInit() {
    this.getBundleItem();
    this.getCurrencyCode();
  }

  public onBack(): void {
    const { placeId, menuId } = this.stateService.params;
    this.stateService.go(menuRoutes.PRODUCTS, {
      placeId,
      menuId,
      tabId: 'menu-bundles',
    });
  }

  public editBundleItem(): void {
    const { placeId, menuId, bundleId } = this.stateService.params;
    this.stateService.go(menuRoutes.EDIT_BUNDLE, {
      placeId,
      menuId,
      id: bundleId,
    });
  }

  private determineStateChangeButton(): void {
    if (this.item.state === 'ACTIVE') {
      this.stateChangeTo = 'Deactivate';
    } else {
      this.stateChangeTo = 'Activate';
    }
  }

  private getCurrencyCode(): void {
    this.currencyCode = _.find(this.config.customer.venues, {
      id: this.stateService.params.venueId,
    }).currencyCode;
  }

  public openDeleteModal(template: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
  }

  public deleteConfirm(): void {
    const { placeId, menuId, bundleId } = this.stateService.params;
    this.bundleService.deleteBundle(placeId, menuId, bundleId)
      .pipe(
        take(1),
        catchError((err) => of(this.handleError(err))),
      )
      .subscribe((res) => {
        if (res === null) {
          this.modalRef.hide();
          this.onBack();
        }
      });
  }

  public deleteCanceled(): void {
    this.modalRef.hide();
  }

  public stateChangeClick(state: string): void {
    let updateStateTo: string;

    switch (state) {
      case 'Archive':
        updateStateTo = 'ARCHIVED';
        break;
      case 'Deactivate':
        updateStateTo = 'INACTIVE';
        break;
      case 'Activate':
        updateStateTo = 'ACTIVE';
        break;
      default:
        break;
    }
    this.updateBundleState(updateStateTo);
  }

  private getImage(): void {
    let mainImage;
    let stickerImage;
    if (this.item.images) {
      mainImage = this.imageService.getMainImage(this.item.images);
      stickerImage = this.imageService.getStickerImage(this.item.images) || this.mainImageSrc;
    }

    if (mainImage) {
      this.mainImageSrc = this.imageService.getImageSrc(mainImage, 272, 184);
    }
    if (stickerImage) {
      this.stickerImageSrc = this.imageService.getImageSrc(stickerImage, 80, 80);
    }
  }

  private getBundleItem() {
    const { placeId, menuId, bundleId } = this.stateService.params;

    this.bundleService.getBundle(placeId, menuId, bundleId)
      .pipe(
        take(1),
        catchError((err) => of(this.handleError(err))),
      )
      .subscribe((res) => {
        if (res) {
          this.item = res;
          this.getImage();
          this.determineStateChangeButton();
          this.getBundleGroups(this.item.productBundleGroupIds).subscribe((bundleGroups: BundleGroupListData[]) => {
            this.bundleGroups = bundleGroups;
          });
        }
      });
  }

  private getBundleGroups(productBundleGroupIds) {
    return forkJoin(productBundleGroupIds.map((groupId) => this.getBundleGroup(groupId)));
  }

  private updateBundleState(state: string): void {
    const { placeId, menuId, bundleId } = this.stateService.params;

    const updatedItem: BundleRequest = {
      id: (bundleId as string),
      externalId: this.item.externalId,
      externalPriceCode: this.item.externalPriceCode,
      displayName: this.item.displayName,
      state,
      images: this.item.images,
      categories: this.item.categories.map((item) => item.id),
      price: this.item.price,
      maxQuantity: this.item.maxQuantity,
      productBundleGroupIds: this.item.productBundleGroupIds,
      content: this.item.content,
    };
    updatedItem.state = state;
    this.bundleService.updateBundle(placeId, menuId, bundleId, updatedItem)
      .pipe(
        take(1),
        catchError((err) => of(this.handleError(err))),
      )
      .subscribe((res) => {
        if (res) {
          this.item = res;
          this.determineStateChangeButton();
        }
      });
  }

  public createBundleCopy(): void {
    const { placeId, menuId } = this.stateService.params;
    this.stateService.go(menuRoutes.CREATE_BUNDLE, {
      placeId,
      menuId,
      loadedBundleItem: {
        ...this.item,
        displayName: `Copy of ${this.item.displayName}`,
      },
    });
  }

  private getBundleGroup(bundleGroupId) {
    const { placeId, menuId } = this.stateService.params;

    return this.bundleGroupService.getBundleGroupById(placeId, menuId, bundleGroupId).pipe(
      take(1),
      catchError((err) => of(this.handleError(err))),
    );
  }

  private handleError(err) {
    this.logger.logError('bundle-item error', err);
  }
}
