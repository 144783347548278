import { Order } from '../../../shared/models/order.model';

export interface OrderClassInterface {
  'in-progress': boolean;
  completed: boolean;
  refunded: boolean;
  editing: boolean
}

export function getOrderClass(order: Order): OrderClassInterface {
  return {
    'in-progress': order.fulfillmentStatus === 'IN_PROCESS' || order.orderStatus==='SUBMITTED',
    completed: order.fulfillmentStatus === 'FULFILLED',
    refunded: order.paymentStatus === 'REFUNDED' || order.paymentStatus === 'PARTIAL_REFUND_APPLIED' || order.fulfillmentStatus === 'CANCELLED',
    editing: order.editing,
  };
}
