import TabController from './tab.controller';
import template from './tab.html';

const TabComponent = {
    template,
    transclude: true,
    require: {
        tabsCtrl: '^crTabs',
    },
    bindings: {
        label: '@',
        routeId: '@',
        stateName: '@?', // Used instead of state.current.name when provided
    },
    controller: TabController,
};

export default TabComponent;
