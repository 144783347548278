import controller from './preferences-details.controller';
import template from './preferences-details.html';
import './preferences-details.less';

const component = {
    template,
    controller: [
        '$q',
        '$state',
        'crConstants',
        'crLocaleService',
        'crEntityService',
        'crErrorLoggingService',
        'crUserPreferencesService',
        'crQuestionService',
        controller,
    ],
};

export default component;
