export class StorageNamespace {
  private _namespace: string;

  private _storage: Storage;

  get namespace(): string {
    return this._namespace;
  }

  constructor(namespace: string) {
    this._namespace = namespace;
    this._storage = window.localStorage;
  }

  get(key: string): any {
    const fullKey = `${this._namespace}.${key}`;

    const value = this._storage.getItem(fullKey);
    if (value) {
      return JSON.parse(value);
    }
  }

  set(key: string, value: any) {
    const fullKey = `${this._namespace}.${key}`;

    const stringValue = JSON.stringify(value);
    this._storage.setItem(fullKey, stringValue);
  }

  unset(key: string) {
    const fullKey = `${this._namespace}.${key}`;
    this._storage.removeItem(fullKey);
  }
}
