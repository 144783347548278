import text from '../resources/locale/en.json';

const { statusLabel} = text;

export const color = {
    red: '#A02040',
    green: '#36B44C',
    blue: '#05ADFC',
    yellow: '#FFB400'
};

export type ListType = 'lift' | 'trail' | 'activity';

export type Status = {
    id: string;
    label: string;
    type: string;
    color: string;
    visibleIn: ListType[];
}

export const statusList: Status[] = [
    {
        id: '30_minutes_or_less',
        label: statusLabel.minutesOrLess,
        type: 'CLOSED',
        color: color.yellow,
        visibleIn: ['lift']
    },
    {
        id: 'anticipated_weather_impact',
        label: statusLabel.anticipatedWeatherImpact,
        type: 'CLOSED',
        color: color.red,
        visibleIn: ['lift']
    },
    {
        id: 'closed',
        label: statusLabel.closed,
        type: 'CLOSED',
        color: color.red,
        visibleIn: ['lift', 'trail', 'activity']
    },
    {
        id: 'competition',
        label: statusLabel.competition,
        type: 'OPEN',
        color: color.yellow,
        visibleIn: ['trail']
    },
    {
        id: 'closed_for_build',
        label: statusLabel.closedForBuild,
        type: 'CLOSED',
        color: color.red,
        visibleIn: ['trail']
    },
    {
        id: 'closed_for_Event',
        label: statusLabel.closedForEvent,
        type: 'CLOSED',
        color: color.red,
        visibleIn: ['trail']
    },
    {
        id: 'closed_for_inspection',
        label: statusLabel.closedForInspection,
        type: 'CLOSED',
        color: color.red,
        visibleIn: ['trail']
    },
    {
        id: 'closed_for_season',
        label: statusLabel.closedForSeason,
        type: 'CLOSED',
        color: color.red,
        visibleIn: ['lift', 'trail', 'activity']
    },
    {
        id: 'closed_race_in_progress',
        label: statusLabel.closedRaceInProgress,
        type: 'CLOSED',
        color: color.red,
        visibleIn: ['trail']
    },
    {
        id: 'closed_for_private_function',
        label: statusLabel.closedForPrivateFunction,
        type: 'CLOSED',
        color: color.red,
        visibleIn: ['activity']
    },
    {
        id: 'delayed',
        label: statusLabel.delayed,
        type: 'CLOSED',
        color: color.yellow,
        visibleIn: ['lift', 'trail', 'activity']
    },
    {
        id: 'download_only',
        label: statusLabel.downloadOnly,
        type: 'CLOSED',
        color: color.yellow,
        visibleIn: ['lift']
    },
    {
        id: 'expected',
        label: statusLabel.expected,
        type: 'CLOSED',
        color: color.yellow,
        visibleIn: ['lift']
    },
    {
        id: 'event',
        label: statusLabel.event,
        type: 'OPEN',
        color: color.yellow,
        visibleIn: ['trail']
    },
    {
        id: 'groomers_choice',
        label: statusLabel.groomersChoice,
        type: 'OPEN',
        color: color.blue,
        visibleIn: ['trail']
    },
    {
        id: 'hold',
        label: statusLabel.hold,
        type: 'CLOSED',
        color: color.yellow,
        visibleIn: ['lift', 'trail', 'activity']
    },
    {
        id: 'lightning_closure',
        label: statusLabel.lightningClosure,
        type: 'CLOSED',
        color: color.red,
        visibleIn: ['lift']
    },
    {
        id: 'lightning_hold',
        label: statusLabel.lightningHold,
        type: 'CLOSED',
        color: color.yellow,
        visibleIn: ['lift']
    },
    {
        id: 'lower_open',
        label: statusLabel.lowerOpen,
        type: 'OPEN',
        color: color.green,
        visibleIn: ['trail']
    },
    {
        id: 'mechanical_closure',
        label: statusLabel.mechanicalClosure,
        type: 'CLOSED',
        color: color.red,
        visibleIn: ['lift']
    },
    {
        id: 'mechanical_hold',
        label: statusLabel.mechanicalHold,
        type: 'CLOSED',
        color: color.yellow,
        visibleIn: ['lift']
    },
    {
        id: 'mid_station_only',
        label: statusLabel.midStationOnly,
        type: 'CLOSED',
        color: color.yellow,
        visibleIn: ['lift']
    },
    {
        id: 'open_but_closed_to_foot_traffic',
        label: statusLabel.openButClosedToFootTraffic,
        type: 'OPEN',
        color: color.green,
        visibleIn: ['lift']
    },
    {
        id: 'open_first_tracks',
        label: statusLabel.openFirstTracks,
        type: 'OPEN',
        color: color.blue,
        visibleIn: ['lift', 'trail']
    },
    {
        id: 'open_ski_ride_school_only',
        label: statusLabel.openSkiRideSchoolOnly,
        type: 'OPEN',
        color: color.yellow,
        visibleIn: ['lift']
    },
    {
        id: 'open_to_mid_station_only',
        label: statusLabel.openToMidStationOnly,
        type: 'OPEN',
        color: color.yellow,
        visibleIn: ['lift']
    },
    {
        id: 'open_uphill_only',
        label: statusLabel.openUphillOnly,
        type: 'OPEN',
        color: color.green,
        visibleIn: ['lift']
    },
    {
        id: 'off_season',
        label: statusLabel.offSeason,
        type: 'CLOSED',
        color: color.red,
        visibleIn: ['lift', 'trail', 'activity']
    },
    {
        id: 'open',
        label: statusLabel.open,
        type: 'OPEN',
        color: color.green,
        visibleIn: ['lift', 'trail', 'activity']
    },
    {
        id: 'open_bottom',
        label: statusLabel.openBottom,
        type: 'OPEN',
        color: color.green,
        visibleIn: ['trail']
    },
    {
        id: 'open_for_climbing_only',
        label: statusLabel.openForClimbingOnly,
        type: 'OPEN',
        color: color.yellow,
        visibleIn: ['trail']
    },
    {
        id: 'open_for_cross_country',
        label: statusLabel.openForCrossCountry,
        type: 'HOLD',
        color: color.yellow,
        visibleIn: ['trail']
    },
    {
        id: 'open_for_downhill',
        label: statusLabel.openForDownhill,
        type: 'OPEN',
        color: color.green,
        visibleIn: ['trail']
    },
    {
        id: 'open_for_downhill_only',
        label: statusLabel.openForDownhillOnly,
        type: 'OPEN',
        color: color.green,
        visibleIn: ['trail']
    },
    {
        id: 'open_for_hiking',
        label: statusLabel.openForHiking,
        type: 'OPEN',
        color: color.green,
        visibleIn: ['trail']
    },
    {
        id: 'open_for_mountain_bike',
        label: statusLabel.openForMountainBike,
        type: 'OPEN',
        color: color.green,
        visibleIn: ['trail']
    },
    {
        id: 'open_for_multi_use',
        label: statusLabel.openForMultiUse,
        type: 'OPEN',
        color: color.green,
        visibleIn: ['trail']
    },
    {
        id: 'open_for_racing',
        label: statusLabel.openForRacing,
        type: 'OPEN',
        color: color.yellow,
        visibleIn: ['trail']
    },
    {
        id: 'open_for_two_way_traffic',
        label: statusLabel.openForTwoWayTraffic,
        type: 'OPEN',
        color: color.green,
        visibleIn: ['trail']
    },
    {
        id: 'open_pass_required',
        label: statusLabel.openPassRequired,
        type: 'OPEN',
        color: color.yellow,
        visibleIn: ['trail']
    },
    {
        id: 'open_pro_pass_required',
        label: statusLabel.openProPassRequired,
        type: 'HOLD',
        color: color.yellow,
        visibleIn: ['trail']
    },
    {
        id: 'open_ski_school_only',
        label: statusLabel.openSkiSchoolOnly,
        type: 'HOLD',
        color: color.yellow,
        visibleIn: ['trail']
    },
    {
        id: 'open_top',
        label: statusLabel.openTop,
        type: 'OPEN',
        color: color.green,
        visibleIn: ['trail']
    },
    {
        id: 'open_to_foot_traffic_only',
        label: statusLabel.openToFootTrafficOnly,
        type: 'OPEN',
        color: color.blue,
        visibleIn: ['lift']
    },
    {
        id: 'open_downhill_mountain_bike_only',
        label: statusLabel.openDownhillMountainBikeOnly,
        type: 'OPEN',
        color: color.green,
        visibleIn: ['trail']
    },
    {
        id: 'patrol_closure',
        label: statusLabel.patrolClosure,
        type: 'CLOSED',
        color: color.red,
        visibleIn: ['lift']
    },
    {
        id: 'patrol_hold',
        label: statusLabel.patrolHold,
        type: 'CLOSED',
        color: color.red,
        visibleIn: ['lift']
    },
    {
        id: 'pending_mitigation',
        label: statusLabel.pendingMitigation,
        type: 'CLOSED',
        color: color.red,
        visibleIn: ['lift', 'trail', 'activity']
    },
    {
        id: 'power_outage',
        label: statusLabel.powerOutage,
        type: 'CLOSED',
        color: color.red,
        visibleIn: ['lift']
    },
    {
        id: 'progressive_opening',
        label: statusLabel.progressiveOpening,
        type: 'CLOSED',
        color: color.yellow,
        visibleIn: ['lift', 'trail']
    },
    {
        id: 'scenic_ride_only',
        label: statusLabel.scenicRideOnly,
        type: 'OPEN',
        color: color.blue,
        visibleIn: ['lift']
    },
    {
        id: 'scheduled',
        label: statusLabel.scheduled,
        type: 'CLOSED',
        color: color.yellow,
        visibleIn: ['lift']
    },
    {
        id: 'sold_out',
        label: statusLabel.soldOut,
        type: 'CLOSED',
        color: color.yellow,
        visibleIn: ['activity']
    },
    {
        id: 'training',
        label: statusLabel.training,
        type: 'OPEN',
        color: color.yellow,
        visibleIn: ['trail']
    },
    {
        id: 'upper_open',
        label: statusLabel.upperOpen,
        type: 'OPEN',
        color: color.green,
        visibleIn: ['trail']
    },
    {
        id: 'weather_hold',
        label: statusLabel.weatherHold,
        type: 'CLOSED',
        color: color.yellow,
        visibleIn: ['lift']
    },
    {
        id: 'windy_conditions',
        label: statusLabel.windyConditions,
        type: 'OPEN',
        color: color.yellow,
        visibleIn: ['lift']
    },
    {
        id: 'wind_closure',
        label: statusLabel.windClosure,
        type: 'CLOSED',
        color: color.red,
        visibleIn: ['lift']
    },
    {
        id: 'wind_hold',
        label: statusLabel.windHold,
        type: 'CLOSED',
        color: color.yellow,
        visibleIn: ['lift']
    },
    {
        id: 'work_in_progress',
        label: statusLabel.workInProgress,
        type: 'CLOSED',
        color: color.red,
        visibleIn: ['trail']
    }
];

export const typeList = [
    {id: 'lift', label: 'Lift'},
    {id: 'trail', label: 'Trail'},
    {id: 'activity', label: 'Activity'}
];

export const status = [
    {id: true, label: 'True'},
    {id: false, label: 'False'}
];

export const difficultyLabelList = [
    {id: 'Easy', label: 'Easy'},
    {id: 'Intermediate', label: 'Intermediate'},
    {id: 'Advanced Intermediate', label: 'Advanced Intermediate'},
    {id: 'Difficult', label: 'Difficult'},
    {id: 'Expert', label: 'Expert'},
    {id: 'Very difficult', label: 'Very Difficult'},
    {id: 'Extreme Terrain', label: 'Extreme'},
    {id: 'Small Features', label: 'Small Features'},
    {id: 'Medium Features', label: 'Medium Features'},
    {id: 'Large Features', label: 'Large Features'},
    {id: 'Glades', label: 'Glades'},
    {id: 'Special Terrain', label: 'Special Terrain'},
    {id: 'Snowshoe', label: 'Snowshoe'},
    {id: 'Half Pipe', label: 'Half Pipe'},
];

export const options = [
    {id: true, label: 'True'},
    {id: false, label: 'False'}
];

export const iconTypeList = [
    {id: 'Cabriolet', label: 'Cabriolet'},
    {id: 'Chondola', label: 'Chondola'},
    {id: 'Double', label: 'Double'},
    {id: 'Funitel', label: 'Funitel'},
    {id: 'Gondola', label: 'Gondola'},
    {id: 'High-Speed Quad', label: 'High Speed Quad'},
    {id: 'High-Speed 6 Chair', label: 'High Speed Six Chair'},
    {id: 'Magic Carpet', label: 'Magic Carpet'},
    {id: 'Quad Chair', label: 'Quad Chair'},
    {id: 'Rope Tow', label: 'Rope Tow'},
    {id: 'Single', label: 'Single'},
    {id: '6 Chair', label: 'Six Chair'},
    {id: 'Sled', label: 'Sled'},
    {id: 'T-Bar', label: 'T Bar'},
    {id: 'Tram', label: 'Tram'},
    {id: 'Triple Chair', label: 'Triple Chair'},
];

export const trailIconList = [
    {id: 'BlackDiamond', label: 'Black Diamond'},
    {id: 'BlackFreeride', label: 'Black Freeride'},
    {id: 'BlackTechnical', label: 'Black Technical'},
    {id: 'BlackTriangle', label: 'Black Triangle'},
    {id: 'BlueBlackSquare', label: 'Blue Black Square mm'},
    {id: 'BlueBlackSquare', label: 'Blue Black Square wp'},
    {id: 'BlueBlueSquare', label: 'Blue Blue Square'},
    {id: 'BlueFreeride', label: 'Blue Freeride'},
    {id: 'BlueSquare', label: 'Blue Square'},
    {id: 'BlueTechnical', label: 'Blue Technical'},
    {id: 'DoubleBlackDiamond', label: 'Double Black Diamond'},
    {id: 'DoubleblackTechnical', label: 'Double Black technical'},
    {id: 'ExtremeTerrain', label: 'Extreme Terrain'},
    {id: 'Glades', label: 'Glades'},
    {id: 'GreenBlackCircle', label: 'Green Black Circle'},
    {id: 'GreenCircle', label: 'Green Circle'},
    {id: 'GreenFreeride', label: 'Green Freeride'},
    {id: 'Halfpipe', label: 'Half Pipe'},
    {id: 'Moon', label: 'Moon'},
    {id: 'Park', label: 'Park'},
    {id: 'Pink Line', label: 'Pink Line'},
    {id: 'PurpleStar', label: 'Purple Star'},
    {id: 'Snowboarder Dude', label: 'Snowboarder Dude'},
    {id: 'Snowshoe', label: 'Snowshoe'},
    {id: 'SpecialTerrain', label: 'Special Terrain'},
    {id: 'UphillArrow', label: 'Uphill Arrow'},
];

export const daysList = [
    {key: 'monday', label: 'Monday'},
    {key: 'tuesday', label: 'Tuesday'},
    {key: 'wednesday', label: 'Wednesday'},
    {key: 'thursday', label: 'Thursday'},
    {key: 'friday', label: 'Friday'},
    {key: 'saturday', label: 'Saturday'},
    {key: 'sunday', label: 'Sunday'},
];
