import _ from 'lodash';
import text from './resources/locale/en.json';

import locationTargetUrl from '../../../../commons/basic/resources/images/location_target.svg';

const QSMART_RIDE_SCOPE = 'QSMART_RIDE';

class PoiDetailsController {
    constructor(
        $q,
        $state,
        $uibModal,
        crEntityService,
        crLocaleService,
        crPoiDetailsService,
        crNavigationService,
        crErrorLoggingService,
        crGraphicalMapService,
        crConstants,
        crImageService,
        crToastService,
        crPoiConstants
    ) {
        this.$q = $q;
        this.$state = $state;
        this.$uibModal = $uibModal;
        this.crEntityService = crEntityService;
        this.crLocaleService = crLocaleService;
        this.crPoiDetailsService = crPoiDetailsService;
        this.crNavigationService = crNavigationService;
        this.crErrorLoggingService = crErrorLoggingService;
        this.crGraphicalMapService = crGraphicalMapService;
        this.crConstants = crConstants;
        this.crImageService = crImageService;
        this.crToastService = crToastService;
        this.crPoiConstants = crPoiConstants;
        this.attributeTypes = this.crConstants.attributeTypes;
    }

    $onInit() {
        this.text = text;
        this.poi = {};
        this.marker = {
            source: locationTargetUrl,
            w: this.crConstants.marker.defaultDimensions.w,
            h: this.crConstants.marker.defaultDimensions.h,
            x: 0,
            y: 0,
        };

        this.locale = this.crLocaleService.getLocale(this.$state.params.venueId, this.$state.params.locale);

        this.markers = [this.marker];

        // See if we need to show a toast when coming to this page
        this.crToastService.autoToast(this.$state.params);

        this.entityType = this.crConstants.entity.types.POIS;

        const params = [this.entityType, this.$state.params.id, this.$state.params.venueId, this.locale.current];

        this.getData(...params);
        this.getAssociatedEntities(...params);
    }

    goToEditPage() {
        this.$state.go('client.poi.edit', {
            id: this.$state.params.id,
            customerId: this.$state.params.customerId,
        });
    }

    onMapLoad(event) {
        this.canvas = event.canvas;
        this.mapImage = event.mapImage;
        this.focus = [this.focusRatio[0], this.focusRatio[1]];
        this.marker.x = this.focus[0];
        this.marker.y = this.focus[1];
    }

    getData(entityType, id, venueId, locale) {
        this.isLoading = true;
        this.mapLoading = true;
        this.poiError = false;
        this.mapError = false;
        this.categoriesLoading = true;

        this.$q
            .all([
                this.crEntityService.getEntity(entityType, id, venueId, locale),
                this.crPoiDetailsService.getQsmartRides(venueId),
                this.crPoiDetailsService
                    .getMenus(venueId, id, locale)
                    .then((menus) => {
                        this.menus = menus;
                    })
                    .catch(() => {
                        this.menus = [];
                    }),
            ])
            .then((results) => {
                const details = results[0];
                const rides = results[1];

                // POI Data
                this.poi = details;
                this.poi.mainImageSrc = this.crPoiDetailsService.getMainImageSrc(details.images);
                this.poi.stickerSrc = this.crPoiDetailsService.getStickerSrc(details.images) || this.poi.mainImageSrc;
                this.poi.displayCategories = details.displayCategories || [];
                this.poi.attributes = details.attributes || [];
                this.poi.externalIds = details.externalIds || [];

                if (!_.isEmpty(this.poi.attributes)) {
                    this.crImageService.setIconSrc(this.poi.attributes);
                }

                this.poi.ride = _.find(this.poi.externalIds, (eid) => eid.scope === QSMART_RIDE_SCOPE);
                if (_.get(this.poi.ride, 'id')) {
                    const ride = _.find(rides, (r) => r.id === this.poi.ride.id) || {};
                    this.poi.ride.name = ride.name;

                    // Fake the CTA label
                    this.poi.localization[this.locale.current].callToAction = {
                        label: this.text.qsmartCtaLabel,
                        link: 'virtual-queue',
                    };
                }

                this.getMapMarkerData([this.poi.location]);
                this.getCategoryHierarchyForCategories(this.poi.displayCategories);

                this.updateLocaleMap(this.poi, this.locale);
            })
            .catch((err) => {
                this.crErrorLoggingService.logError('Could not get poi details', err, id);

                if (err.status === 404) {
                    this.$state.go('client.404', {
                        customerId: this.$state.params.customerId,
                        venueId,
                    });
                } else {
                    this.hasDataError = true;
                }
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    getAssociatedEntities(entityType, id, venueId) {
        this.crEntityService
            .getAssociatedEntities(entityType, id, venueId)
            .then((entities) => {
                this.associatedEntities = entities;
            })
            .catch((err) => this.crErrorLoggingService.logError('Could not get associated entities', err, id));
    }

    localeSelected(event) {
        this.locale.current = event.locale.id;

        const entityType = this.crConstants.entity.types.POIS;
        const { venueId } = this.$state.params;
        const locale = this.locale.current;
        const { id } = this.$state.params;

        if (!this.poi.localization[locale].attributes) {
            this.crEntityService
                .getEntity(entityType, id, venueId, locale)
                .then((entity) => {
                    this.updateLocaleMap(entity, this.locale);
                })
                .catch((err) => {
                    this.crErrorLoggingService.logError('could not get locale data', err, locale);
                });
        } else {
            this.locale = _.cloneDeep(this.locale);
            this.updateLocaleMap(this.poi, this.locale);
        }
    }

    updateLocaleMap(entity, locale) {
        const currentLocale = this.poi.localization[locale.current];

        // use default language if localization values are not present.
        if (!currentLocale.description) {
            currentLocale.description = this.poi.localization[locale.default].description;
        }

        if (!currentLocale.title) {
            currentLocale.title = this.poi.localization[locale.default].title;
        }

        if (!currentLocale.callToAction.label && !currentLocale.callToAction.link) {
            currentLocale.callToAction = this.poi.localization[locale.default].callToAction;
        }

        currentLocale.attributes = entity.attributes || [];

        if (!_.isEmpty(currentLocale.attributes)) {
            this.crImageService.setIconSrc(currentLocale.attributes);
        }

        if (!currentLocale.callsToAction) {
            currentLocale.callsToAction = this.poi.localization[locale.default].callsToAction;
        }

        this.poi.callsToAction = currentLocale.callsToAction;

        this.poi = _.cloneDeep(this.poi);
    }

    getMapMarkerData(locations) {
        return this.crGraphicalMapService
            .getMapMarkerData(this.$state.params.venueId, locations)
            .then((res) => {
                this.defaultMapId = res.defaultMapId;
                this.focusRatio = [res.translatedLocations[0].position.x, res.translatedLocations[0].position.y];
                this.mapImageSrc = res.mapImageSrc;
            })
            .catch((err) => {
                this.mapError = true;
                this.crErrorLoggingService.logError(
                    'Couldnt not get graphical map for poi',
                    err,
                    this.$state.params.id
                );
            })
            .finally(() => {
                this.mapLoading = false;
            });
    }

    getCategoryHierarchyForCategories(displayCategories) {
        this.crPoiDetailsService
            .getCategoryHierarchyForCategories(this.$state.params.venueId, displayCategories, this.locale.current)
            .then((hierarchyCategories) => {
                this.categoriesLoading = false;
                this.hierarchyCategories = hierarchyCategories;
            })
            .catch((err) => {
                this.crErrorLoggingService.logError('Could not get poi categories', err);
            });
    }

    openModal(message) {
        this.$uibModal
            .open({
                backdrop: 'static',
                component: 'crSimpleModal',
                windowClass: 'cr-modal-size-sm',
                resolve: {
                    message: () => message,
                },
            })
            .result.catch(() => null);
    }

    deactivate() {
        if (this.isLoading) {
            return;
        }

        this.isLoading = true;

        this.crPoiDetailsService
            .updatePoiState(this.$state.params.id, { state: this.crPoiConstants.states.INACTIVE })
            .then(() => {
                this.crToastService.toast(`"${this.poi.name}" ${this.text.poiDeactivated}`);
                this.poi.state = this.crPoiConstants.states.INACTIVE;
            })
            .catch(() => this.openModal(this.text.problemDeactivatingPoi))
            .finally(() => {
                this.isLoading = false;
            });
    }

    activate() {
        if (this.isLoading) {
            return;
        }

        this.isLoading = true;

        this.crPoiDetailsService
            .updatePoiState(this.$state.params.id, { state: this.crPoiConstants.states.ACTIVE })
            .then(() => {
                this.crToastService.toast(`"${this.poi.name}" ${this.text.poiActivated}`);
                this.poi.state = this.crPoiConstants.states.ACTIVE;
            })
            .catch(() => this.openModal(this.text.problemActivatingPoi))
            .finally(() => {
                this.isLoading = false;
            });
    }

    archive() {
        if (this.isLoading) {
            return;
        }

        const resolve = () => {
            this.isLoading = true;

            this.crPoiDetailsService
                .deletePoi(this.$state.params.id)
                .then(() => {
                    this.$state.go('client.poi.list', {
                        toast: {
                            msg: `"${this.poi.name}" ${this.text.poiArchived}`,
                        },
                    });
                })
                .catch(() => {
                    this.openModal(this.text.problemArchivingPoi);

                    this.isLoading = false;
                });
        };

        this.$uibModal
            .open({
                backdrop: 'static',
                component: 'crSimpleModal',
                windowClass: 'cr-modal-size-sm',
                resolve: {
                    message: () => this.text.archiveModalMessage,
                    type: () => this.crConstants.modalTypes.SUBMIT,
                    submitText: () => this.text.archiveModalSubmit,
                },
            })
            .result.then(resolve)
            .catch(() => null);
    }

    saveCopy() {
        this.$state.go('client.poi.create', {
            id: this.$state.params.id,
            customerId: this.$state.params.customerId,
        });
    }

    goBack() {
        this.crNavigationService.goBack('client.poi.list');
    }
}

export default PoiDetailsController;
