import _ from 'lodash';
import moment from 'moment';
import text from './resources/locale/en.json';

import ListPage from '../../../../commons/controller/list-page';

class ExperienceListPage extends ListPage {
    constructor(
        $state,
        $sce,
        crErrorLoggingService,
        crAnalyticsService,
        crToastService,
        crLocaleService,
        crConfig,
        crConstants,
        crExperienceListService,
        crEntitySelectionManager,
        crExperiencesService
    ) {
        super($state, crErrorLoggingService, crAnalyticsService, crToastService, crLocaleService, crConfig);
        this.crExperienceListService = crExperienceListService;
        this.crEntitySelectionManager = crEntitySelectionManager;
        this.crExperiencesService = crExperiencesService;
        this.crConstants = crConstants;
        this.$sce = $sce;
    }

    $onInit() {
        super.$onInit();

        this.text = text;

        this.reportTooltip = this.$sce.trustAsHtml(this.text.reportTooltip);
        this.editTooltip = this.$sce.trustAsHtml(this.text.editTooltip);

        const includeType = this.experienceGroupType === 'broadcasts' ? 'BROADCAST' : '';
        const excludeType = this.experienceGroupType === 'broadcasts' ? '' : 'BROADCAST';

        this.registerParam('state', '');
        this.registerParam('type', '');
        this.registerParam('modifiedBy', '');
        this.registerParam('modified', '');
        this.registerParam('start', '');
        this.registerParam('end', '');
        this.registerParam('include', includeType);
        this.registerParam('exclude', excludeType);

        const columns = this.text.columns[this.experienceGroupType];

        this.listData = {
            columns: [
                { key: '', label: '' },
                { key: 'name', label: columns.name, class: 'large-column name-column', sortable: true },
                { key: 'automationId', label: columns.experience, class: 'large-column', sortable: true },
                { key: 'app-display', label: columns.appDisplay, class: 'medium-column' },
                { key: 'state', label: columns.state, class: 'medium-column' },
                { key: 'modified', label: text.modified, sortable: true },
                { key: '', label: '', class: 'medium-column' },
            ],
            rows: [],
        };

        if (this.hasMultipleLanguages()) {
            this.addMissingTranslationsColumnHeader();
        }

        this.stateFilterData = [
            { id: 'ALL', label: this.text.all },
            { id: 'ACTIVE', label: this.text.active },
            { id: 'INACTIVE', label: this.text.inActive },
            { id: 'DRAFT', label: this.text.draft },
        ];
        this.stateFilterData[0].isSelected = true;

        this.range = {
            start: '',
            end: '',
        };

        this.typeFilterData = [];
        this.crExperiencesService.getExperienceTypes(this.experienceGroupType).then((types) => {
            this.typeFilterData = types[this.experienceGroupType]
                .filter((type) => !(type.parent && type.parent.id))
                .map((type) => ({
                    id: type.id,
                    label: type.name,
                }));
        });

        this.modifiedByFilterData = [];

        this.getAutomationsData();
    }

    getData() {
        this.isLoading = true;
        this.dataError = false;
        if (this.params.type) {
            this.params.include = '';
            this.params.exclude = '';
        }
        if (this.params.start) {
            this.params.start = this.convertDatetime(this.params.start);
        }
        if (this.params.end) {
            this.params.end = this.convertDatetime(this.params.end);
        }

        return this.crExperienceListService
            .getData(this.params)
            .then((data) => {
                data.search = data.query.search || '';

                if (data.search === this.params.search) {
                    this.isLoading = false;
                    const rows = this.crExperienceListService.mapToRowsModel(data.content);
                    if (!this.params.modifiedBy) {
                        this.modifiedByFilterData = this.getUsers(rows.map((row) => row.data.modified.username));
                    }
                    return rows;
                }
            })
            .catch((err) => {
                this.isLoading = false;
                this.dataError = true;
                this.endOfResults = true;
                this.listData.rows = [];
                this.crErrorLoggingService.logError('Could not get Experience Promoter List data', err);
            });
    }

    getTypeLabel(id) {
        const type = this.typeFilterData.find((typeData) => typeData.id === id);
        return type ? type.label : id;
    }

    getModifiedLabel(modified) {
        const shortDate = moment(modified.time).format('MMM D');
        return `${shortDate} by ${modified.username}`;
    }

    getUsers(usernames) {
        const users = _.uniq(usernames).map((username) => ({
            id: username,
            label: username,
        }));
        return _.sortBy(users, 'label');
    }

    updateParam(key, event) {
        if (key === 'type') {
            this.params.include = '';
            this.params.exclude = '';
            if (_.isEmpty(event.model)) {
                // The 'All/Reset' option
                this.params.include = this.experienceGroupType === 'broadcasts' ? 'BROADCAST' : '';
                this.params.exclude = this.experienceGroupType === 'broadcasts' ? '' : 'BROADCAST';
            }
        }
        this.params[key] = _.isEmpty(event.model) ? '' : event.model[0].id;
        this.updateData();
    }

    convertDatetime(time) {
        return time ? time.split('.')[0] : '';
    }

    rangeUpdated(event) {
        this.params.start = this.convertDatetime(event.range.start);
        this.params.end = this.convertDatetime(event.range.end);
        this.range = event.range;
        this.updateData();
    }

    getAutomationsData() {
        // TODO: call the automations endpoint
    }
}

export default ExperienceListPage;
