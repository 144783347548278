import controller from './menu-details.controller';
import template from './menu-details.html';
import './menu-details.less';

const component = {
    template,
    controller: [
        '$state',
        '$sce',
        'crConstants',
        'crEntityService',
        'crMenusService',
        'crLocaleService',
        'crErrorLoggingService',
        '$uibModal',
        'crPolicyService',
        controller,
    ],
};

export default component;
