import angular from 'angular';

import PoiComponent from './poi.component';
import ComponentsModule from './components/components';
import PoiConstantsModule from './constants/poi.constants';

const PoiModule = angular
    .module('control-room.poi', [ComponentsModule.name, PoiConstantsModule.name])
    .component('crPoi', PoiComponent);

export default PoiModule;
