<div class="app-usage-container">
    <div class="header">
        <h2>{{ text.title }}</h2>
        <div class="description">{{ text.description }}</div>
        <div class="tabs">
            <label class="btn" [ngClass]="{ active: currentTab === 'all' }">
                <input
                    type="radio"
                    [ngModel]="currentTab"
                    (ngModelChange)="onTabChange($event)"
                    value="all"
                />
                {{ text.allUsers }}
            </label>
            <label class="btn" [ngClass]="{ active: currentTab === 'some' }">
                <input
                    type="radio"
                    [ngModel]="currentTab"
                    (ngModelChange)="onTabChange($event)"
                    value="some"
                />
                {{ text.someUsers }}
            </label>
        </div>
    </div>
    <div class="app-usage-content" [ngSwitch]="currentTab">
        <div *ngSwitchCase="'all'">
            <cr-toggle
                [valueMessages]="{
                    on: text.showIndividual,
                    off: text.showIndividual
                }"
                [isOn]="!showingHeatmap"
                (onToggle)="onShowHeatmapToggle()"
            ></cr-toggle>
        </div>
        <div *ngSwitchCase="'some'">
            <label for="user-tag-dropdown" class="form-label">
                {{ text.selectUserTagLabel }}
            </label>
            <cr-dropdown
                mode="select"
                name="user-tag-dropdown"
                customClass="tag-dropdown"
                [hideResetOption]="true"
                (onSelect)="onUserTagSelect($event)"
                [menuItems]="tags"
                sort="asc"
                displayKey="title"
            ></cr-dropdown>
            <div class="tag-values" *ngIf="selectedTag">
                <label for="user-tag-values" class="form-label">
                    {{ text.filterByValuesLabel }}
                </label>
                <div class="tag-values-description">
                    {{ text.filterByValuesDescription }}
                </div>
                <label
                    class="tag-value"
                    [ngClass]="{
                        active: selectedTagValues[value.id]?.selected,
                        unavailable: !isValuePresent(value.id)
                    }"
                    *ngFor="let value of selectedTag.values"
                >
                    <cr-tooltip-wrapper
                        *ngIf="!isValuePresent(value.id)"
                        [tooltipAppendToBody]="true"
                        tooltipPlacement="right"
                        [tooltipText]="text.noUsersTooltip"
                    ></cr-tooltip-wrapper>
                    <input
                        [disabled]="!isValuePresent(value.id)"
                        type="checkbox"
                        [ngModel]="selectedTagValues[value.id]?.selected"
                        (ngModelChange)="onUserTagValueToggle(value.id, $event)"
                    />
                    <span
                        class="tag-value-circle"
                        *ngIf="selectedTagValues[value.id]?.selected"
                        [ngStyle]="{
                            'background-color':
                                selectedTagValues[value.id]?.color
                        }"
                    ></span>
                    {{ value.label }}
                </label>
            </div>
        </div>
    </div>
</div>
