import './mobile-scroll.less';

class MobileScrollDirective {
    constructor() {
        this.restrict = 'A';
    }

    static directiveFactory() {
        return new MobileScrollDirective();
    }

    link(scope, element) {
        let lastmouse;
        let isdown = false;
        let pe;

        element[0].classList.add('cr-mobile-scroll');
        element[0].style.overflow = 'hidden';

        const mdListener = (event) => {
            pe = element[0].style.pointerEvents;

            document.body.classList.add('cr-mobile-scroll');

            lastmouse = [event.clientX, event.clientY];

            isdown = true;
        };

        const muListener = () => {
            document.body.classList.remove('cr-mobile-scroll');

            element[0].style.pointerEvents = pe;

            isdown = false;
        };

        const mmListener = (event) => {
            if (isdown) {
                const xDiff = event.clientX - lastmouse[0];
                const yDiff = event.clientY - lastmouse[1];

                element[0].style.pointerEvents = 'none';

                element[0].scrollLeft = element[0].scrollLeft - xDiff;
                element[0].scrollTop = element[0].scrollTop - yDiff;

                lastmouse = [event.clientX, event.clientY];
            }
        };

        element[0].addEventListener('mousedown', mdListener);
        document.addEventListener('mousemove', mmListener);
        document.addEventListener('mouseup', muListener);

        scope.$on('$destroy', () => {
            element[0].removeEventListener('mousedown', mdListener);
            document.removeEventListener('mousemove', mmListener);
            document.removeEventListener('mouseup', muListener);
        });
    }
}

MobileScrollDirective.directiveFactory.$inject = [];

export default MobileScrollDirective;
