<div class="menus-wrapper">
    <div class="menu-card" *ngFor="let menu of menus" (click)="goToViewMenu(menu)">
        <section class="menu-card-header">
            <span class="menu-title">{{ menu.content.name }}</span>
        </section>
        <section class="menu-card-body">
            <cr-chip *ngIf="menu.state === 'ACTIVE'" label="{{ text.states.active }}" bgColor="#43a047" labelColor="white"></cr-chip>
            <cr-chip *ngIf="menu.state === 'INACTIVE'" label="{{ text.states.inactive }}" bgColor="#b30007" labelColor="white"></cr-chip>
            <cr-chip *ngIf="menu.state === 'DRAFT'" label="{{ text.states.draft }}" bgColor="#444" labelColor="white"></cr-chip>
        </section>
    </div>
    <div class="menu-card add-menu-card" *ngIf="showAddMenuButton">
        <button (click)="goToAddMenu()">{{ text.addMenuText }}</button>
    </div>
    <div class="menu-card add-menu-card empty-msg" *ngIf="!showAddMenuButton && !menus">
        <div>{{ text.noMenusText }}</div>
    </div>
</div>