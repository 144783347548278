import moment from 'moment';
import 'moment-timezone';

import text from './resources/locale/en.json';

import DataFilter from '../../../../commons/controller/data-filter';

class EventsSchedule extends DataFilter {
    constructor(
        $state,
        crVenueService,
        crToastService,
        crAnalyticsService,
        $http,
        crEntityService,
        crConstants,
        crErrorLoggingService,
        $scope
    ) {
        super($state, crVenueService, crToastService);
        this.$state = $state;
        this.crVenueService = crVenueService;
        this.crToastService = crToastService;
        this.crAnalyticsService = crAnalyticsService;
        this.$http = $http;
        this.crEntityService = crEntityService;
        this.crConstants = crConstants;
        this.crErrorLoggingService = crErrorLoggingService;
        this.$scope = $scope;
    }

    $onInit() {
        super.$onInit();

        this.text = text;
        this.initFilters();

        this.$scope.$on(this.crConstants.events.CANCEL_CALENDAR_INSTANCE, (e, data) => {
            this.onEventCancel(e, data.toast);
        });

        this.$scope.$on(this.crConstants.events.UPDATE_CALENDAR_VIEW, (e, data) => {
            this.onWeekUpdate(data);
        });
    }

    initFilters() {
        this.registerParam('state', this.$state.params.state);
        this.registerParam('category', this.$state.params.category);
        this.registerParam('start', moment().tz(this.venueTimezone.name).startOf('week').toISOString());
        this.registerParam('end', moment().tz(this.venueTimezone.name).endOf('week').toISOString());

        this.initStateFilter();
        this.initCategoriesFilter(this.$state.params.venueId);
    }

    getData(params) {
        this.updateSearchDeepLink();
        this.isLoading = true;

        // clear data
        if (this.schedule) {
            this.schedule = [];
        }

        return this.$http
            .get('/rest/calendars/events', { params })
            .then((res) => {
                const { data } = res;
                data.search = data.query.search;

                if (data.search === this.params.search) {
                    this.schedule = res.data.content;
                    this.isLoading = false;
                }
            })
            .catch((err) => {
                this.dataError = true;
                this.isLoading = false;
                this.crErrorLoggingService.logError('Could not get Calendar Events', err, this.params);
            });
    }

    onEventCancel(e, toast) {
        e.stopPropagation();

        if (toast) {
            this.crToastService.toast(toast);
        }

        this.getData(this.params);
    }

    onWeekUpdate(event) {
        this.params.start = event.model.start;
        this.params.end = event.model.end;
        this.getData(this.params);
        this.currentRange = event.model;
    }

    initStateFilter() {
        this.stateFilterData = [
            { id: 'ACTIVE', label: this.text.active },
            { id: 'INACTIVE', label: this.text.inActive },
            { id: 'DRAFT', label: this.text.draft },
        ];
        this.stateFilterData[0].isSelected = true;
    }

    initCategoriesFilter(venueId) {
        this.crEntityService
            .getCategoriesWithHierarchy(this.crConstants.entity.types.EVENT_CATEGORIES, venueId)
            .then((categories) => {
                this.categoriesList = categories;
            });
    }
}

export default EventsSchedule;
