import _ from 'lodash';

class ReportsController {
    constructor($state, crCommerceService, locale, crChartService, crErrorLoggingService, crDateRangeService) {
        this.$state = $state;
        this.commerceService = crCommerceService;
        this.text = locale;
        this.chartService = crChartService;
        this.errorLoggingService = crErrorLoggingService;
        this.crDateRangeService = crDateRangeService;
    }

    $onInit() {
        this.ordersPlacedConfig = this.chartService.getDefaultConfig('ordersPlaced');
        this.getOrdersPlaced();
    }

    getOrdersPlaced() {
        const range = this.crDateRangeService.getRange('week');

        return this.commerceService
            .ordersReports(null, range, null, 'day', 1)
            .then((data) => {
                this.ordersPlaced = data.ordersPlaced;
                this.topItem = {
                    label: data.topItemsPurchased.labels[0],
                    total: _.first(data.topItemsPurchased.series[0]),
                };
            })
            .catch((err) => {
                this.errorLoggingService.logError('Could not get orders placed', err);
            });
    }

    goToPage(pageId) {
        this.$state.go(`performance.reporting.${pageId}`, { customerId: this.$state.params.customerId });
    }
}

export default ReportsController;
