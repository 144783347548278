import _ from 'lodash';
import text from './resources/locale/en.json';

class AttributesPresentation {
    $onInit() {
        this.text = text;
    }

    getEnumDisplayValue(value) {
        if (_.isArray(value)) {
            return value.map((obj) => obj.label).join(', ');
        }

        return value;
    }
}

export default AttributesPresentation;
