import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ProductsList } from './product.model';
import { constants } from '../constants/constants';

@Injectable({ providedIn: 'root' })
export class ProductsService {
  constructor(private httpClient: HttpClient) { }

  getProductsList(menuId: string, placeId: string): Observable<ProductsList> {
    const url = `rest/menus/${menuId}/${constants.entity.types.MENU_PRODUCTS}`;
    return this.httpClient.get<ProductsList>(url, { params: { all: 'all', placeId } });
  }

}
