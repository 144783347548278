import controller from './dashboard.controller';
import template from './dashboard.html';
import './dashboard.less';

const Component = {
    template,
    controller: ['$state', 'crReportFileService', 'crAnalyticsService', 'crErrorLoggingService', controller],
};

export default Component;
