import {
  Component, Input, Output, EventEmitter,
} from '@angular/core';
import { of } from 'rxjs';
import { take, catchError } from 'rxjs/operators';
import { LocalizedText } from '../../../../../core';
import { ErrorLoggingService, OrderService } from '../../../../../shared';
import { Order, CheckinOrderRequest } from '../../../../../shared/models/order.model';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { SimpleModalComponent } from '../../../../../shared';

@Component({
  selector: 'cr-order-state-button',
  templateUrl: 'order-state-button.component.html',
  styleUrls: ['./order-state-button.component.scss'],
})

export class OrderStateButtonComponent  {

  @Input() order: Order;

  @Input() kitchenId: string;

  @Input() text: LocalizedText;

  @Input() orderClassStyles;

  @Input() selectedTab: string;

  @Output() updateFulfillment = new EventEmitter<void>();

  @Output() onCancel = new EventEmitter<void>();

  modalText: { message: string; submit: string, cancel: string };

  discardModalText: { message: string; submit: string, cancel: string };

  confirmModal: BsModalRef;

  isLoading = false;


  constructor(private orderService: OrderService, private logger: ErrorLoggingService, private modalService: BsModalService) {
  }


  public updateOrderFulfillment(fulfillmentStatus: string): void {
    this.orderService
      .updateFulfillmentStatus(this.kitchenId, this.order.orderId, fulfillmentStatus)
      .pipe(take(1), catchError((err) => of(this.handleFulfillmentError(err))))
      .subscribe(() => {
        // Store old state in the .notice property so it can be displayed (only if the tab wasn't switched)
        if (fulfillmentStatus !== this.selectedTab) {
          this.order.notice = this.order.fulfillmentStatus;
        }
        // Update status locally, no data is returned from this service
        this.order.fulfillmentStatus = fulfillmentStatus;
        this.order.editing = false;
        this.updateFulfillment.emit();
      });
  }

  private handleFulfillmentError(err): void {
    this.logger.logError('Order State Button Fulfillment Erorr', err);
    this.order.error = true;
  }

  public checkInOrder(): void {
    this.openCheckInConfirmModal()
      .then(() => {
        this.isLoading = true;
        this.orderService
          .checkInOrder(this.order.orderId, this.generateCheckInOrderRequest())
          .pipe(take(1), catchError((err) => of(this.handleFulfillmentError(err))))
          .subscribe((order) => {
            this.order.notice = order.fulfillmentStatus;
            this.order.fulfillmentStatus = order.fulfillmentStatus;
            this.order.orderStatus = order.orderStatus;
            this.isLoading = false;
            this.order.editing = false;
            this.updateFulfillment.emit();
          });
      }).
      catch(() => {
        this.isLoading = false;
        this.onCancel.emit();
      });
    
  }

  public generateCheckInOrderRequest(): CheckinOrderRequest {
    const checkInOrderRequest: CheckinOrderRequest = {
      customerId: this.order.customerId,
      externalOrderId: this.order.externalOrderId,
      placeId: this.order.placeId,
      smsOptIn: this.order.smsOptIn,
      vehicleColor: this.order.vehicleColor,
      vehicleMake: this.order.vehicleMake,
      vehicleModel: this.order.vehicleModel,
      zone: this.order.pickupZoneId,
  }
    return checkInOrderRequest;
  }

  public handleCancelButton(): void {
    this.onCancel.emit();
  }

  openCheckInConfirmModal() {
    this.confirmModal = this.modalService.show(SimpleModalComponent, {
      initialState: {
        message: this.text.discardModalMessage,
        submitText: this.text.discardModalSubmit,
        cancelText: this.text.discardModalCancel,
        confirm: true,
      },
      backdrop: 'static',
      class: 'cr-modal-size-sm',
    });

    return this.confirmModal.content.result;
  }
}
