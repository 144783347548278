import Chartist from 'chartist';

class ChartService {
    constructor(numberFilter, locale) {
        this.numberFilter = numberFilter;
        this.text = locale;
    }

    getDefaultConfig(tooltipType) {
        let tooltip = false;

        if (tooltipType) {
            tooltip = {
                tooltipFnc: this.getTooltip(tooltipType),
            };
        }

        return {
            axisY: {
                labelInterpolationFnc: (value) => this.numberFilter(value, 0),
            },
            tooltip,
            type: Chartist.FixedScaleAxis,
        };
    }

    getTooltip(type) {
        const tooltips = {
            ordersPlaced: (meta, value) => {
                const txt = document.createElement('textarea');
                txt.innerHTML = !meta || meta === 'null' ? '0' : meta;
                meta = txt.value;

                return (
                    `<div class="cr-stat-title">${this.text.ordersPlaced}</div>` +
                    `<div><b>${this.numberFilter(value, 0)}</b></div>` +
                    `<div>$${this.numberFilter(meta, 0)}</div>`
                );
            },
        };

        return tooltips[type];
    }
}

export default ChartService;
