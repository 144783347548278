import _ from 'lodash';
import text from './resources/locale/en.json';

class WeekdayViewerController {
    constructor(crConstants) {
        this.crConstants = crConstants;
    }

    $onInit() {
        this.text = text;
        this.weekdays = this.crConstants.iCal.weekdays;
    }

    isSelected(weekday) {
        return _.includes(this.selectedDays, weekday);
    }
}

export default WeekdayViewerController;
