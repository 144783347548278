import angular from 'angular';

import GradientGeneratorConstant from './gradient-generator.constant';
import GradientGeneratorDirective from './gradient-generator.directive';

const GradientGeneratorModule = angular
    .module('control-room.commons.basic.directives.gradient-generator', [])
    .constant('crGradientGeneratorConstant', GradientGeneratorConstant)
    .directive('crGradientGenerator', GradientGeneratorDirective.directiveFactory);

export default GradientGeneratorModule;
