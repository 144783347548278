import { FactoryProvider, InjectionToken, PLATFORM_ID } from '@angular/core';
import { windowFactory } from './window.factory';
import { WindowRef } from '../../../core';

export const WINDOW = new InjectionToken('WindowToken');

export const windowProvider: FactoryProvider = {
  provide: WINDOW,
  useFactory: windowFactory,
  deps: [WindowRef, PLATFORM_ID],
};
