import text from './resources/locale/en.json';

class CtaButtonBuilderRowController {
    $onInit() {
        this.text = text;
        const isMenu = this.row.type === 'MENU';
        this.row.isDraggable = true;

        this.callsToAction = this.rows;

        this.options = {};
        this.types = [
            { name: this.text.web, id: 'WEB', isSelected: !isMenu },
            { name: this.text.menu, id: 'MENU', isSelected: isMenu },
        ];
        if(this.row.menus) {
            this.refreshMenuDropdown();
        }
    }

    refreshMenuDropdown() {
        const cta = this.row;
        if (cta && cta.options) {
            this.row.menus.forEach((menu) => {
                    if(menu.id === cta.options.menuId) {
                        menu.isSelected = true;
                    } else {
                        menu.isSelected = false;
                    }
                });
        }
    }

    onLabelChange(event) {
        if (!this.row) {
            this.row = { type: 'WEB' };
        }
        this.row.label = event.model;
    }

    onLinkChange(event) {
        if (!this.row) {
            this.row = { type: 'WEB' };
        }
        this.row.link = event.model;
    }

    onTypeChange(event) {
        if (!this.row) {
            this.row = {};
        }
        this.row.type = event.model[0].id;
        if (this.row.type === 'MENU') {
            this.row.label = this.text.orderNow;
            this.row.link = '';
        } else {
            this.row.label = '';
            this.row.link = 'https://';
            this.row.options = null;
        }
    }

    onMenuChange(event) {
        if (!this.row) {
            this.row = { type: 'MENU' };
        }
        const menuId = event.model[0].id;
        this.row.link = `/menus/${menuId}`;
        this.row.options = { menuId };

    }

}

export default CtaButtonBuilderRowController;
