import _ from 'lodash';

class GradientGeneratorDirective {
    constructor(constant) {
        this.restrict = 'A';
        this.scope = {
            options: '<crGradientGenerator',
            index: '@crGradientGeneratorIndex',
            size: '@crGradientGeneratorSize',
        };
        this.constant = constant;
    }

    static directiveFactory(constant) {
        return new GradientGeneratorDirective(constant);
    }

    link(scope, element) {
        const options = _.mergeWith({}, this.constant.defaultOptions, scope.options || {}, (objValue, srcValue) => {
            if (_.isArray(srcValue)) {
                return srcValue;
            }
        });
        const indexMultiplier = options.colorPalette.length / scope.size;
        const color = options.colorPalette[Math.floor(scope.index * indexMultiplier)];

        options.styleProperties.forEach((property) => {
            element[0].style[property] = color;
        });
    }
}

GradientGeneratorDirective.directiveFactory.$inject = ['crGradientGeneratorConstant'];

export default GradientGeneratorDirective;
