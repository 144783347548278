import controller from './time-picker.controller';
import template from './time-picker.html';
import './time-picker.less';

const Component = {
    template,
    require: {
        form: '^form',
    },
    bindings: {
        name: '@',
        onUpdate: '&',
        isoTimeString: '<',

        onClose: '&?',
        disableClose: '<?',
        hideValidation: '<?',
        crRequired: '<?',
        crDisabled: '<?',
        customClass: '@?',
        start: '<?',
        end: '<?',
        interval: '<?',
        disableUserInput: '<?'
    },
    controller: ['crConstants', 'crTimeService', '$timeout', controller],
};

export default Component;
