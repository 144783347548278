import _ from 'lodash';

function PlacesListMockService($q) {
    this.getData = (params) => {
        console.log('getData', params);

        const row = {
            data: {
                name: {
                    label: "Arby's Sacramento",
                },
                state: {
                    icon: 'cr-mobile',
                    isEnabled: false,
                    label: 'Disabled',
                },
                id: 'arbys00011',
                organization: 'United States Beef Corp',
                orders: '1346',
                change: {
                    value: '10.6',
                    isDescending: true,
                },
            },
            customClass: '',
        };

        const row2 = {
            data: {
                name: {
                    label: "Arby's San Diego",
                },
                state: {
                    icon: 'cr-mobile',
                    isEnabled: true,
                    label: 'Enabled',
                },
                id: 'arbys00002',
                organization: 'Home Cafe, INC',
                orders: '3895',
                change: {
                    value: '1.2',
                    isDescending: false,
                },
            },
            customClass: '',
        };

        const row3 = {
            data: {
                name: {
                    label: "Arby's San Francisco",
                },
                state: {
                    icon: 'cr-mobile',
                    isEnabled: true,
                    label: 'Enabled',
                },
                id: 'arbys00003',
                organization: 'Home Cafe, INC',
                orders: null,
                change: {
                    value: '8.7',
                    isDescending: false,
                },
            },
            customClass: '',
        };

        const row4 = {
            data: {
                name: {
                    label: "Arby's Wichita",
                },
                state: {
                    icon: 'cr-mobile',
                    isEnabled: true,
                    label: 'Enabled',
                },
                id: 'arbys00004',
                organization: 'Home Cafe, INC',
                orders: '2879',
                change: {
                    value: '5.2',
                    isDescending: false,
                },
            },
            customClass: '',
        };

        const row5 = {
            data: {
                name: {
                    label: "Arby's Newton",
                },
                state: {
                    icon: 'cr-mobile',
                    isEnabled: true,
                    label: 'Enabled',
                },
                id: 'arbys00008',
                organization: 'Home Cafe, INC',
                orders: '3904',
                change: {
                    value: '2.1',
                    isDescending: false,
                },
            },
            customClass: '',
        };

        const mockRows = [row, row2, row3, row4, row5];

        const rows = [];

        let index = null;

        let rowCopy = null;

        for (let i = 0; i < 20; i++) {
            index = _.random(0, 4);
            rowCopy = _.cloneDeep(mockRows[index]);
            rows.push(rowCopy);
        }

        return $q.when(rows);
    };
}

export default PlacesListMockService;
