import Controller from './events-schedule.controller';
import template from './events-schedule.html';
import './events-schedule.less';

const Component = {
    template,
    controller: [
        '$state',
        'crVenueService',
        'crToastService',
        'crAnalyticsService',
        '$http',
        'crEntityService',
        'crConstants',
        'crErrorLoggingService',
        '$scope',
        Controller,
    ],
};

export default Component;
