import _ from 'lodash';

class CouponsListService {
    constructor(crCouponsService) {
        this.crCouponsService = crCouponsService;
    }

    getData(params) {
        const queryParams = _.pickBy(params, (value) => !_.isNil(value) && value !== '' && value !== 'all');

        return this.crCouponsService.getCoupons(queryParams);
    }

    mapToRowsModel(coupons) {
        return coupons.map((coupon) => {
            const data = {
                id: coupon.id,
                name: coupon.name,
                code: coupon.code,
                type: coupon.type,
                state: coupon.status.toLowerCase(),
                missingTranslations: _.isArray(coupon.missingTranslations) ? coupon.missingTranslations.join(', ') : '',
                originalData: coupon,
            };
            // The entity selection manager service needs this
            data.originalData.state = data.originalData.status;
            return { data };
        });
    }
}

export default CouponsListService;
