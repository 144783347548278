class LayoutController {
    constructor($scope, crConstants, crLayoutService) {
        this.$scope = $scope;
        this.events = crConstants.events;
        this.crLayoutService = crLayoutService;
    }

    $onInit() {
        this.isOpen = !this.crLayoutService.getSlim();
        this.$scope.$on(this.events.NAV_PANEL_OPEN, () => {
            this.isOpen = true;
        });
        this.$scope.$on(this.events.NAV_PANEL_CLOSE, () => {
            this.isOpen = false;
        });
    }
}

export default LayoutController;
