import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { stateServiceProvider } from '../../../../ng1-factories';
import { ChipModule } from '../../../../shared/components/chip/chip.module';
import { KitchenMenusComponent } from './kitchen-menus.component';

@NgModule({
  declarations: [
    KitchenMenusComponent
  ],
  imports: [
    CommonModule,
    ChipModule
  ],
  exports: [
    KitchenMenusComponent
  ],
  providers: [
    stateServiceProvider
  ],
  entryComponents: [
    KitchenMenusComponent
  ]
})
export class KitchenMenusModule { }
