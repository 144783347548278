/* eslint no-bitwise: 0 */
import _ from 'lodash';
import ReorderList from '../../../../commons/controller/reorder-list';
import { menuRoutes } from '../../menus.routes';
import text from './resources/locale/en.json';

class ModifierGroupsManage extends ReorderList {
    constructor(
        $state,
        $uibModal,
        $q,
        crConfig,
        crConstants,
        crEntityService,
        crLocaleService,
        crLocalizationService,
        crNavigationService,
        crErrorLoggingService,
        crAnalyticsService
    ) {
        super();

        this.$state = $state;
        this.$uibModal = $uibModal;
        this.$q = $q;
        this.crConfig = crConfig;
        this.crConstants = crConstants;
        this.crEntityService = crEntityService;
        this.crLocaleService = crLocaleService;
        this.crLocalizationService = crLocalizationService;
        this.crNavigationService = crNavigationService;
        this.crErrorLoggingService = crErrorLoggingService;
        this.crAnalyticsService = crAnalyticsService;
    }

    $onInit() {
        this.text = text;
        this.entity = {
            displayName: '',
            content: { name: '' },
            modifiers: [],
        };
        this.localizedModifiers = {};
        this.requiredModes = [
            { id: 1, name: this.text.optionRequired },
            { id: 0, name: this.text.optionOptional },
        ];
        this.minModifiersValidators = [
            {
                type: 'MIN_VALUE',
                value: 1,
            },
        ];
        this.maxModifiersValidators = [
            {
                type: 'MIN_VALUE_OR_NULL',
                value: 1,
            },
        ];

        this.form = {};
        this.venueId = this.$state.params.venueId;
        this.placeId = this.$state.params.placeId;
        this.entityType = this.crConstants.entity.types.MENU_MODIFIER_GROUPS;
        this.entityRoute = `menus/${this.$state.params.menuId}/${this.entityType}`;
        this.titleBarLabel = this.edit ? this.text.editItem : this.text.createItem;
        this.crNavigationService.enableConfirmNavigation(() => this.form.$dirty);
        this.locale = this.crLocaleService.getLocale(this.venueId, this.$state.params.locale, !this.edit);
        this.currencyCode = _.find(this.crConfig.customer.venues, { id: this.$state.params.venueId }).currencyCode;

        if (this.$state.params.id) {
            this.getEntityDetails();
            this.getAssociatedItems();
        } else {
            this.initCreate();
        }

        this.initDropdown();
    }

    get isDefaultLocale() {
        return this.locale.current === this.locale.default;
    }

    initCreate() {
        // Init localization property
        this.entity.max = null;
        this.entity.localization = {};
        _.forEach(this.locale.list, (lang) => {
            this.entity.localization[lang.id] = {
                content: {},
                displayName: '',
            };
        });

        this.addBlankModifier();
    }

    getAssociatedItems() {
        const menus = this.crConstants.entity.types.MENUS;
        const menuItems = this.crConstants.entity.types.MENU_PRODUCTS;
        const associatedItemsRoute = `${menus}/${this.$state.params.menuId}/${menuItems}`;

        const params = {
            modifierGroup: this.$state.params.id,
            venueId: this.$state.params.venueId,
            placeId: this.$state.params.placeId,
            sortby: 'displayName',
            sort: 'asc',
            all: true,
        };

        this.crEntityService
            .getEntityList(associatedItemsRoute, params)
            .then((data) => {
                this.associatedItems = _.filter(data.content, (item) =>
                    _.find(item.modifierGroups, { id: this.entity.id })
                );
            })
            .catch((err) => {
                this.crErrorLoggingService.logError(
                    'Could not get associated items for modifier group',
                    err,
                    this.$state.params.id
                );
            });
    }

    getEntityDetails() {
        this.isLoading = true;
        this.isLoadingModifiers = true;

        const params = [
            this.entityType,
            this.$state.params.id,
            this.venueId,
            this.locale.default,
            this.entityRoute,
            { placeId: this.placeId },
        ];

        this.crEntityService
            .getEntity(...params)
            .then((entity) => {
                this.entity = entity;
                this.isCopy = !this.edit;

                if (this.isCopy) {
                    this.entity.content.name = `${this.text.copyOf} ${this.entity.content.name}`;

                    // remove copied entity's id
                    this.$state.params.id = null;
                    this.$state.go(this.$state.current.name, this.$state.params, {
                        location: 'replace',
                    });
                }

                this.entity.modifiers.forEach((modifier) => {
                    modifier.price = parseFloat(modifier.price).toFixed(2);
                });

                this.onRequiredModeSelect({ model: [{ id: entity.min >= 1 ? 1 : 0 }] });

                this.getLocalizedModifierList(this.entity.modifiers.map((m) => m.id)).finally(() => {
                    this.refreshLocaleStatus();
                    this.isLoadingModifiers = false;
                    this.isLoading = false;
                });
            })
            .catch((err) => {
                this.hasDataError = true;
                this.crErrorLoggingService.logError(`Could not get ${this.entityType}`, err);
                this.isLoadingModifiers = false;
            })
            .finally(() => {
                this.isLoading = this.isLoadingModifiers;
            });
    }

    getLocalizedModifierList(
        modifierIds,
        modifiers = this.entity.modifiers,
        entityType = this.crConstants.entity.types.MENU_MODIFIERS
    ) {
        return this.crEntityService
            .getLocalizationFromIdList(this.crConstants.entity.types.MENU_MODIFIERS, modifierIds, this.venueId)
            .then((res) => {
                this.localizedModifiers = {};
                modifierIds.forEach((id, index) => {
                    if (_.isEmpty(res[index])) {
                        this.localizedModifiers[id] = this.crLocalizationService.getLocalizationDefaultModel(
                            entityType,
                            modifiers[index],
                            this.venueId
                        );
                    } else {
                        this.localizedModifiers[id] = res[index];
                    }
                });
            })
            .catch((err) => {
                this.crErrorLoggingService.logError('could not retrieve localized details', err);
            });
    }

    save() {
        this.form.$setSubmitted();

        if (this.form.$valid) {
            if (this.form.$dirty || this.isCopy) {
                this.isSaving = true;
                const payload = this.preparePayload(this.entity);

                if (this.edit) {
                    this.updateEntity(
                        this.entityType,
                        this.entity.id,
                        payload,
                        this.venueId,
                        this.placeId,
                        this.entityRoute
                    );
                } else {
                    this.createEntity(this.entityType, payload, this.venueId, this.placeId, this.entityRoute);
                }
            } else {
                this.goBack();
            }
        }
    }

    deleteModifierGroup(entity = this.entity) {
        if (_.size(this.associatedItems) > 0) {
            this.$uibModal.open({
                backdrop: 'static',
                component: 'crSimpleModal',
                windowClass: 'cr-modal-size-md',
                resolve: {
                    message: () => this.text.cannotDelete,
                    type: () => this.crConstants.modalTypes.CANCEL,
                },
            });
        } else {
            this.$uibModal
                .open({
                    backdrop: 'static',
                    component: 'crSimpleModal',
                    windowClass: 'cr-modal-size-sm',
                    resolve: {
                        type: () => 'submit',
                        submitText: () => this.text.deleteModifierGroup,
                        message: () => this.text.deleteConfirmationMessage,
                    },
                })
                .result.then(() =>
                    this.crEntityService
                        .deleteEntity(this.entityRoute, entity.id, this.$state.params.venueId, {
                            placeId: this.$state.params.placeId,
                        })
                        .then(() => {
                            this.crNavigationService.disableConfirmNavigation();
                            this.crAnalyticsService.track('Delete Modifier Group Success');
                            this.crNavigationService.goBack(menuRoutes.MODIFIER_GROUPS, {
                                menuId: this.$state.params.menuId,
                                placeId: this.$state.params.placeId,
                                toast: {
                                    msg: `"${entity.content.name}" ${this.text.hasBeenDeleted}`,
                                },
                            });
                        })
                        .catch((err) => {
                            this.showErrorModal();
                            this.crErrorLoggingService.logError('Could not delete modifierGroup', err, this.entity);
                        })
                )
                .catch(() => null);
        }
    }

    preparePayload(entity) {
        const payload = _.cloneDeep(entity);
        payload.modifiers.forEach((mod) => {
            mod.currency = this.currencyCode;
        });

        // ROOM-2053 - patches for missing data in the mobile app on create
        payload.category = payload.content.name;
        if (!this.edit) {
            payload.min = _.isUndefined(payload.min) ? 0 : payload.min;
            payload.max = _.isUndefined(payload.max) ? null : payload.max;
        }

        return payload;
    }

    updateEntity(type, id, entity, venueId, placeId, entityRoute) {
        return this.$q
            .all([
                this.crEntityService.updateEntity(type, id, entity, venueId, entityRoute, { placeId }),
                this.updateModifierLocalization(this.crConstants.entity.types.MENU_MODIFIERS, this.localizedModifiers),
            ])
            .then((res) => this.onSaveSuccess(res[0]))
            .catch((err) => this.handleError('Could not update entity', err))
            .finally(() => {
                this.isSaving = false;
            });
    }

    createEntity(type, entity, venueId, placeId, entityRoute) {
        return this.$q
            .all([
                this.crEntityService.createEntity(type, entity, venueId, entityRoute, { placeId }),
                this.updateModifierLocalization(this.crConstants.entity.types.MENU_MODIFIERS, this.localizedModifiers),
            ])
            .then((res) => this.onSaveSuccess(res[0]))
            .catch((err) => this.handleError('Could not create entity', err))
            .finally(() => {
                this.isSaving = false;
            });
    }

    updateModifierLocalization(entityType, modifiers) {
        return this.$q.all(
            Object.keys(modifiers).map((modifierId) => {
                const identifiers = { id: modifierId, venueId: this.venueId };
                this.crLocalizationService.updateEntityLocalization(entityType, modifiers[modifierId], identifiers);
            })
        );
    }

    onModifierAdd() {
        this.dirtyForm();
        this.addBlankModifier();
        this.refreshLocaleStatus();
    }

    addBlankModifier() {
        // Add a blank modifier to the list
        const id = this.uuidv4();
        const price = '0.00';
        const max = 1;
        this.entity.modifiers.push({ id, price, max });
        this.localizedModifiers[id] = {};
    }

    uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
            const r = (Math.random() * 16) | 0;
            const v = c === 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    }

    onModifierRemove(index) {
        this.dirtyForm();
        // Remove one element from the modifiers list
        const uuid = this.entity.modifiers[index].id;
        this.localizedModifiers[uuid] = null;
        this.entity.modifiers.splice(index, 1);
        this.refreshLocaleStatus();
    }

    onModifierUpdateDisplayName(event, index) {
        if (this.isDefaultLocale) {
            // Update main model to stay in sync
            this.entity.modifiers[index].displayName = event.model;
        }

        const modifierId = this.entity.modifiers[index].id;
        const localizedModifier = this.localizedModifiers[modifierId];
        if (!localizedModifier[this.locale.current]) {
            localizedModifier[this.locale.current] = {};
        }

        localizedModifier[this.locale.current].displayName = event.model;

        this.refreshLocaleStatus();

        this.entity = _.cloneDeep(this.entity);
    }

    onModifierUpdatePrice(event, index) {
        this.entity.modifiers[index].price = event.model;
    }

    onModifierUpdateMaxQuantity(event, index) {
        this.entity.modifiers[index].max = event.model;
    }

    onModifierUpdateExternalId(event, index) {
        this.entity.modifiers[index].externalId = event.model;
    }

    onModifierUpdateExternalPriceCode(event, index) {
        this.entity.modifiers[index].externalPriceCode = event.model;
    }

    modifiersValid() {
        for (let i = 0; i < this.entity.modifiers.length; i++) {
            const priceControl = this.form[`modifier-price-${i}`];
            const labelControl = this.form[`modifier-label-${i}`];

            if (!priceControl || !labelControl) {
                continue;
            }

            if (!priceControl.$valid || !labelControl.$valid) {
                return false;
            }
        }

        return true;
    }

    isGroupRequired() {
        return this.entity.min > 0;
    }

    shouldShowMaxCount() {
        return this.entity && typeof this.entity.min === 'number';
    }

    onRequiredModeSelect(event) {
        const requiredSelectionId = event.model[0].id || 0;
        if (requiredSelectionId === 1) {
            const min = this.entity.min >= 1 ? this.entity.min : 1;
            this.entity.min = min;
        } else {
            this.entity.min = 0;
        }
        this.requiredModes.forEach((mode) => {
            mode.isSelected = mode.id === requiredSelectionId;
        });
    }

    onMinModifierCountUpdate(event) {
        if (!event.model) {
            return;
        }

        const min = parseInt(event.model);
        this.entity.min = min;
        if (typeof this.entity.max === 'number' && this.entity.max < min) {
            this.entity.max = min;
        }
    }

    onMaxModifierCountUpdate(event) {
        if (!event.model) {
            this.entity.max = null;
            return;
        }

        const max = parseInt(event.model);
        this.entity.max = max;
        if (max < this.entity.min) {
            this.entity.min = max;
        }
    }

    onNameUpdate(event) {
        this.entity.content.name = event.model;
        if (this.entity.localization[this.locale.current].content) {
            this.entity.localization[this.locale.current].content.name = '';
        }
    }

    onTitleChange(event) {
        if (this.locale.current === this.locale.default) {
            // Update main model to stay in sync
            this.entity.displayName = event.model;
        }
        this.entity.localization[this.locale.current].displayName = event.model;
        this.refreshLocaleStatus();
        this.entity = _.cloneDeep(this.entity);
    }

    onPriceChange(event) {
        this.entity.price = event.model;
    }

    onSaveSuccess(data) {
        this.crNavigationService.disableConfirmNavigation();
        const params = this.getToastParams(data);

        if (this.edit) {
            this.crAnalyticsService.track('Edit Modifier Group Success', data);
            // There is no detail page, so go back to the list view
            this.crNavigationService.goBack(menuRoutes.MODIFIER_GROUPS, params);
        } else {
            this.crAnalyticsService.track('Add New Modifier Group Success', data);
            this.crNavigationService.goToFromCreate(menuRoutes.MODIFIER_GROUPS, params);
        }
    }

    getToastParams(data, menuId = this.$state.params.menuId, placeId = this.$state.params.placeId) {
        return {
            id: data.id,
            menuId,
            placeId,
            toast: {
                msg: `"${data.content.name}" ${this.edit ? this.text.hasBeenUpdated : this.text.hasBeenCreated}`,
            },
        };
    }

    handleError(errMsg, err) {
        this.showErrorModal();
        this.crErrorLoggingService.logError(errMsg, err);
    }

    showErrorModal() {
        const message = this.edit ? this.text.updateError : this.text.createError;

        this.$uibModal
            .open({
                backdrop: 'static',
                component: 'crSimpleModal',
                windowClass: 'cr-modal-size-sm',
                resolve: {
                    message: () => message,
                },
            })
            .result.catch(() => null);
    }

    dirtyForm() {
        this.form.$setDirty();
    }

    modifierOrderChange(data, index) {
        this.moveRow(data, index, this.entity.modifiers);
        this.dirtyForm();
    }

    modifierOrderChangeTop(data, index) {
        this.moveRowToTop(data, index, this.entity.modifiers);
        this.dirtyForm();
    }

    goBack() {
        if (this.edit) {
            this.crAnalyticsService.track('Edit Modifier Group Canceled');
            this.crNavigationService.goBack(menuRoutes.MODIFIER_GROUPS, {
                id: this.entity.id,
                menuId: this.$state.params.menuId,
                placeId: this.$state.params.placeId,
            });
        } else {
            this.crAnalyticsService.track('Add New Modifier Group Canceled');
            this.crNavigationService.goBack(menuRoutes.MODIFIER_GROUPS, {
                menuId: this.$state.params.menuId,
                placeId: this.$state.params.placeId,
            });
        }
    }

    goToItem(id) {
        this.$state.go(menuRoutes.PRODUCT_DETAILS, {
            id,
            menuId: this.$state.params.menuId,
            placeId: this.$state.params.placeId,
        });
    }

    initDropdown() {
        this.showDropdown =
            this.crConfig.customer.localizationEnabled && this.locale.list && this.locale.list.length > 1;
    }

    onLocaleUpdate(event) {
        this.locale.current = event.model[0].id;
        this.crAnalyticsService.track('Language Selected', { language: event.model[0].label });

        this.updateModifierDisplaynameFromUndefinedToString();
    }

    updateModifierDisplaynameFromUndefinedToString() {
        Object.keys(this.localizedModifiers).map((modifierId) => {
            const path = `${modifierId}.${this.locale.current}.displayName`;
            if (_.isUndefined(_.get(this.localizedModifiers, path))) {
                _.set(this.localizedModifiers, path, '');
            }
        });
    }

    isInvalidForm() {
        const { localization } = this.entity;
        const hasDisplayName = !_.isEmpty(localization && localization[this.locale.default].displayName);
        return localization && this.isDefaultLocale && (!hasDisplayName || !this.modifiersValid());
    }

    refreshLocaleStatus() {
        this.locale.list.forEach((locale) => {
            let warning = false;

            if (!this.entity.localization[locale.id].displayName) {
                warning = true;
            }

            Object.keys(this.localizedModifiers).map((modifierId) => {
                const localeModifier = this.localizedModifiers[modifierId];
                if (localeModifier && (!localeModifier[locale.id] || !localeModifier[locale.id].displayName)) {
                    warning = true;
                }
            });

            locale.hasStatusWarning = warning;
        });
    }
}

export default ModifierGroupsManage;
