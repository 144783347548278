import _ from 'lodash';

import text from './resources/locale/en.json';

class EntityHeader {
    constructor(crConstants, crImageService) {
        this.imageConstants = crConstants.image;
        this.crImageService = crImageService;
    }

    $onChanges(changes) {
        if (changes) {
            this.entity = _.cloneDeep(this.entity);

            this.model = {
                name: this.entityName,
                images: this.entity.images,
                state: this.entity.state,
            };
        }
    }

    $onInit() {
        this.text = text;
        this.initState();
    }

    initState() {
        this.states = [
            { id: 'ACTIVE', label: this.text.active },
            { id: 'INACTIVE', label: this.text.inactive },
            { id: 'DRAFT', label: this.text.draft },
        ];

        this.selectedClass = this.entity.state;

        _.forEach(this.states, (state) => {
            state.isSelected = state.id === this.entity.state;
        });

        // dont allow draft from other states
        if (this.selectedClass && this.selectedClass !== 'DRAFT') {
            this.states[2].isDisabled = true;
        }
    }

    updateHeader(model) {
        this.onUpdate({
            $event: { model },
        });
    }

    onNameUpdate(event) {
        this.model.name = event.model;
        this.updateHeader(this.model);
    }

    onImageUpdate(event) {
        this.model.images = event.images;
        this.updateHeader(this.model);
    }

    onStateUpdate(event) {
        this.selectedClass = event.model[0].id;
        this.model.state = event.model[0].id;
        this.updateHeader(this.model);
    }

    showAsCircle() {
        return this.crImageService.showAsCircle();
    }
}

export default EntityHeader;
