import controller from './quicksight-dashboard.controller';
import template from './quicksight-dashboard.html';
import './quicksight-dashboard.less';

const Component = {
    template,
    controller: [
        '$state',
        'crReportFileService',
        'crAnalyticsService',
        'crErrorLoggingService',
        'crQuicksightService',
        controller,
    ],
};

export default Component;
