import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BsModalService, ButtonsModule } from 'ngx-bootstrap';
import { QuillModule } from 'ngx-quill';
import { CrFormsModule, SharedModule, VenueService } from '../../../../shared';
import { CrSplitViewModule } from '../../../../shared/layout/split-view-page/split-view-page.module';
import { FeesService } from '../../../../shared/services/fees/fees.service';
import { PlacesService } from '../../../../shared/services/places/places.service';
import { CrSplitBtnModule } from '../../../../shared/split-button/split-button.module';
import { CrTitleBarModule } from '../../../../shared/title-bar/title-bar.module';
import { DiscountContentManageComponent } from './discount-content-manage.component';


@NgModule({
  imports: [ReactiveFormsModule, SharedModule , CommonModule, CrSplitViewModule , BrowserModule, CrTitleBarModule, CrFormsModule, ButtonsModule, CrSplitBtnModule, QuillModule],
  exports: [DiscountContentManageComponent],
  declarations: [DiscountContentManageComponent],
  providers: [FeesService, BsModalService, VenueService, PlacesService],
  entryComponents: [DiscountContentManageComponent], 
})
export class DiscountContentManageModule {}
