<div class="modal-header">
    <h4 class="modal-title pull-left">
        {{ text.title }} {{ date }}
    </h4>
    <button
        type="button"
        class="btn-close close pull-right"
        aria-label="Close"
        (click)="modalRef?.hide()"
    >
        <span aria-hidden="true" class="visually-hidden">&times;</span>
  </button>
</div>
<div class="modal-body">
    <div>
        {{ text.modalBodyText }}
    </div>
    <div class="field-label">
        {{ text.totalOrdersLabel }}
    </div>
    <div class="field-value">
        <h4 class="modal-title">
            {{ submittedOrderCount }}
         </h4>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modalRef?.hide()">
        {{ text.confirmText }}
    </button>
</div>