import angular from 'angular';

import Component from './calendar.component';
import ComponentsModule from './components/components';
import Service from './calendar.service';

const Calendar = angular
    .module('control-room.commons.data.components.calendar', [ComponentsModule.name])
    .component('crCalendar', Component)
    .service('crCalendarService', ['$state', 'crConstants', 'crVenueService', 'crErrorLoggingService', Service]);

export default Calendar;
