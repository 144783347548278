import angular from 'angular';

import component from './cvt-dashboard.component';
import componentsModule from './components/components';

import CvtDashboardService from './cvt-dashboard.service';

const CvtDashboard = angular
    .module('control-room.internal.components.cvt.components.cvt-dashboard', [componentsModule.name])
    .component('crCvtDashboard', component)
    .service('crCvtDashboardService', ['$http', CvtDashboardService]);

export default CvtDashboard;
