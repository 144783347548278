class AlertService {
    constructor($rootScope, crConstants) {
        this.$rootScope = $rootScope;
        this.crConstants = crConstants;
    }

    alert(msg, cta) {
        if (cta && (!cta.onClick || !cta.label)) {
            throw Error('AlertService - invalid cta | expecting label and onClick');
        }

        this.$rootScope.$broadcast(this.crConstants.events.SHOW_ALERT, { msg, cta });
    }
}

export default AlertService;
