import text from './resources/locale/en.json';
import ListPage from '../../../../commons/controller/list-page';

class QuestionListController extends ListPage {
    constructor(
        $state,
        crErrorLoggingService,
        crAnalyticsService,
        crToastService,
        crLocaleService,
        crConfig,
        crNavigationService,
        crQuestionListService
    ) {
        super($state, crErrorLoggingService, crAnalyticsService, crToastService, crLocaleService, crConfig);
        this.crNavigationService = crNavigationService;
        this.crQuestionListService = crQuestionListService;
    }

    $onInit() {
        super.$onInit();
        this.text = text;

        this.listData = {
            columns: [
                { key: '', label: '', class: 'small-column' },
                { key: 'label', label: this.text.question, class: '2xlarge-column name-column' },
                { key: 'answers', label: this.text.answers, class: '2xlarge-column' },
                { key: 'displayLevel', label: this.text.displayLevel, class: 'medium-column' },
                { key: '', label: '', class: 'small-column' },
            ],
            rows: [],
        };

        if (this.hasMultipleLanguages()) {
            this.addMissingTranslationsColumnHeader();
        }
    }

    getData() {
        this.isLoading = true;
        this.dataError = false;

        return this.crQuestionListService
            .getData(this.params)
            .then((data) => {
                data.search = data.query.search || '';

                if (data.search === this.params.search) {
                    this.updateSearchDeepLink();
                    return this.crQuestionListService.mapToRowsModel(data.content);
                }
            })
            .catch((err) => {
                this.dataError = true;
                this.endOfResults = true;
                this.listData.rows = [];
                this.crErrorLoggingService.logError('Could not get questions', err);
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    goToCreatePage() {
        this.$state.go('client.app-settings.question-create');
    }

    goBack() {
        this.crNavigationService.goBack('client.app-settings.preferences');
    }
}

export default QuestionListController;
