<section>
    <header>
        <div class="customer-name" data-local-qa-id="mobile-customer-name">
            {{ this.venueName }}
        </div>
        <div class="icon-cr-notifications"></div>
    </header>

    <section class="content-container">
        <section class="welcome-section">
            <div class="section-title">
                {{ this.text.welcome }}
            </div>

            <section class="cr-flex-between">
                <div></div>
                <div></div>
                <div></div>
            </section>
        </section>

        <section class="venue-hours-container">
            <div *ngFor="let hour of phonePreviewList">
                <div *ngIf="this.isVenueOpen(hour)">
                    <span class="icon-cr-time"
                        data-local-qa-id="mobile-icon"
                    ></span>
                    <span data-local-qa-id="mobile-label">
                        {{ this.getFormattedLabel(hour) }}
                    </span>
                    <span data-local-qa-id="mobile-time">
                        {{ this.getFormattedTimeString(hour.startDate, hour.endDate) }}
                    </span>
                </div>
                <div *ngIf="!this.isVenueOpen(hour)">
                    <span class="icon-cr-time"
                        data-local-qa-id="mobile-icon"
                    ></span>
                    <span data-local-qa-id="mobile-label">
                        {{ this.getFormattedLabel(hour) }}
                    </span>
                    <span data-local-qa-id="mobile-label">
                        {{ hour.status }}
                    </span>
                </div>
            </div>
        </section>

        <section>
            <div class="section-title">
                {{ this.text.whatsNew }}
                <div></div>
            </div>
        </section>
    </section>
</section>
