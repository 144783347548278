import text from './resources/locale/en.json';

class EventsController {
    constructor($state, crConstants, crAnalyticsService) {
        this.$state = $state;
        this.crConstants = crConstants;
        this.crAnalyticsService = crAnalyticsService;
    }

    $onInit() {
        this.text = text;
        this.overflowItems = [
            {
                name: this.text.eventCategories,
                state: 'client.events.categories',
            },
            {
                name: this.text.eventAttributes,
                state: 'client.events.attributes-list',
            },
            {
                name: this.text.eventTabNavigation,
                state: 'client.events.tab-navigation',
            }
        ];
    }

    goToCreatePage() {
        this.crAnalyticsService.track('Add New Event Clicked');
        this.$state.go('client.events.create', {
            customerId: this.$state.params.customerId,
        });
    }
}

export default EventsController;
