import angular from 'angular';

import Component from './attributes-list.component';
import AttributesListService from './attributes-list.service';

const Module = angular
    .module('control-room.commons.content.components.attributes-list', [])
    .component('crAttributesList', Component)
    .service('crAttributesService', ['crEntityService', 'crConstants', AttributesListService]);

export default Module;
