import _ from 'lodash';
import text from './resources/locale/en.json';

class ExperiencePhonePreviewController {
    constructor($state, crConstants, crImageService, crErrorLoggingService) {
        this.$state = $state;
        this.crConstants = crConstants;
        this.crImageService = crImageService;
        this.crErrorLoggingService = crErrorLoggingService;
        this.imageConstants = crConstants.image;
    }

    $onInit() {
        this.text = text;
        this.setUpDisplayData();
        this.refreshCouponsMap();
    }

    $onChanges(changes) {
        this.experience = _.cloneDeep(this.experience);
        this.setUpDisplayData();

        if (changes.coupons) {
            this.refreshCouponsMap();
        }
    }

    refreshCouponsMap() {
        this.couponsMap = {};
        if (this.coupons) {
            this.coupons.forEach((coupon) => {
                this.couponsMap[coupon.id] = coupon;
            });
        }
    }

    onImageChange(event) {
        if (event && event.images) {
            this.experience.content.images = event.images;
            this.getDisplayImage(this.experience.content.images);
            this.onImageUpdated({ $event: { images: this.experience.content.images } });
        }
    }

    setUpDisplayData() {
        if (this.experience) {
            this.getDisplayImage(this.experience.content.images);
        }
    }

    getDisplayImage(images) {
        const phoneImgWidth = this.crConstants.image.phoneDisplaySize.MAIN.width;
        const phoneImgHeight = this.crConstants.image.phoneDisplaySize.MAIN.height;
        if (images && images.length > 0) {
            this.phoneImgSrc = this.crImageService.getMainImageSrc(images, phoneImgWidth, phoneImgHeight);
            images[0].displaySrc = this.phoneImgSrc;
        } else {
            this.phoneImgSrc = null;
        }
    }
}

export default ExperiencePhonePreviewController;
