import angular from 'angular';

import BeaconsMapComponent from './beacons-map.component';
import BeaconsMapTooltipComponent from './beacons-map-tooltip.component';
import BeaconsMapService from './beacons-map.service';

const BeaconsMapModule = angular
    .module('control-room.beacons.components.beacons-map', [])
    .component('crBeaconsMap', BeaconsMapComponent)
    .component('crBeaconsMapTooltip', BeaconsMapTooltipComponent)
    .service('crBeaconsMapService', [
        'crConstants',
        'crBeaconsService',
        'crGraphicalMapService',
        'crVenueService',
        BeaconsMapService,
    ]);

export default BeaconsMapModule;
