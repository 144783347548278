import text from './resources/locale/en.json';

class VenueHoursPhonePreview {
    constructor(crConfig) {
        this.crConfig = crConfig;
    }

    $onInit() {
        this.text = text;
        this.customerName = this.crConfig.customer.name;
    }
}

export default VenueHoursPhonePreview;
