import { Inject, Injectable } from '@angular/core';
import _ from 'lodash';
import { Constants } from '../../../core/constants';
import { EntityType } from '../../models/entity.model';
import { MapMarker, PoiMapData } from '../../models/poi-map.model';
import { POI, POIType } from '../../models/poi.model';
import { BoundingCircleDto } from '../../models/venue.model';
import { CR_CONSTANTS } from '../constants/constants';
import { EntityService } from '../entity/entity.service';
import { GraphicalMapService } from '../graphical-map/graphical-map.service';
import { PoiService } from '../poi/poi.service';
import { VenueService } from '../venue/venue.service';

function withinBounds(position: { x: number, y: number }): boolean {
    return position.x >= 0 && position.x <= 1 && position.y >= 0 && position.y <= 1;
}

@Injectable({
    providedIn: 'root',
  })
export class PoiMapService {
    constructor(
        @Inject(CR_CONSTANTS) private constants: Constants,
        private poiService: PoiService,
        private graphicalMapService: GraphicalMapService,
        private venueService: VenueService,
        private entityService: EntityService) {
    }

    getData(venueId: string, params: { [param: string]: string }): Promise<PoiMapData> {
        const queryParams = _.pickBy(params, (value) => !_.isNil(value) && value !== '' && value !== 'all');

        let mapId;
        let location: BoundingCircleDto;

        return this.venueService
            .getById(venueId)
            .then((res) => {
                mapId = res.defaultMapId;
                location = res.location;

                queryParams.mapId = mapId;

                return this.poiService.getPois(queryParams);
            })
            .then((res) =>
                this.graphicalMapService
                    .attributeOptimizedMapPositions(
                        venueId,
                        mapId,
                        res.content.map((poi) => poi.mapPosition)
                    )
                    .then((mapImageSrc) => ({ pois: res.content, mapImageSrc, query: res.query, location }))
            );
    }

    getPoiCategories(venueId: string, locale: string): Promise<any[]> {
        return this.entityService.getCategoriesWithHierarchy(EntityType.POI_CATEGORIES, venueId, locale);
    }

    getPoiTypes(venueId: string): Promise<POIType[]> {
        return this.poiService.getPoiTypes({ venueId }).then((res) => res.content);
    }

    mapToMarkersModel(pois: POI[]): MapMarker[] {
        return pois
            .filter(
                (poi) => poi.mapPosition && poi.mapPosition.translatedPosition && withinBounds(poi.mapPosition.position)
            )
            .map((poi) => ({
                tooltip: true,
                cursor: 'pointer',
                anchor: 'center-bottom',
                source: require('./resources/pin_orange.svg'),
                id: poi.id,
                label: poi.name,
                w: this.constants.marker.defaultDimensions.w,
                h: this.constants.marker.defaultDimensions.h,
                x: poi.mapPosition.translatedPosition.x,
                y: poi.mapPosition.translatedPosition.y,
            }));
    }
}
