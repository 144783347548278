import _ from 'lodash';
import { Component, OnInit, Inject } from '@angular/core';
import { StateService } from '@uirouter/core';
import {
  animate, style, transition, trigger,
} from '@angular/animations';

import { CR_CONSTANTS, ErrorLoggingService, FeaturedEventsService } from '../../../../shared';
import { Constants, LocalizedText } from '../../../../core';
import { ToastService } from '../../../../ng1-factories';
import text from './resources/locale/en.json';

@Component({
  selector: 'cr-featured-events',
  templateUrl: './featured-events.component.html',
  styleUrls: ['./featured-events.component.scss'],
  animations: [
    trigger('insertRemoveTrigger', [
      transition(':enter', [
        style({ height: 0, top: '-52px' }),
        animate('0.22s cubic-bezier(0.0, 0.0, 0.2, 1)', style({ height: '52px', top: 0 })),
      ]),
      transition(':leave', [animate('0.22s cubic-bezier(0.4, 0.0, 1, 1)', style({ height: 0, top: '-52px' }))]),
    ]),
  ],
})
export class FeaturedEventsComponent implements OnInit {
  error: boolean;

  events: any = {};

  hasFeatured: boolean;

  hasTrending: boolean;

  inactiveEvents: any;

  loading: boolean;

  showBanner: boolean;

  text: LocalizedText;

  constructor(
    @Inject(CR_CONSTANTS) private constants: Constants,
    private errorLoggingService: ErrorLoggingService,
    private featuredEventsService: FeaturedEventsService,
    private state: StateService,
    private toastService: ToastService,
  ) {
    this.text = text;
  }

  ngOnInit() {
    this.loadData();
  }

  dismissBanner() {
    this.showBanner = false;
  }

  edit() {
    this.state.go('client.events.featured-events-manage');
  }

  goToEvent(id) {
    this.state.go('client.events.details', { id });
  }

  async loadData() {
    this.loading = true;
    this.toastService.autoToast(this.state.params);

    try {
      this.events = await this.featuredEventsService.get(this.state.params.venueId);
      this.inactiveEvents = {};
      const activeState = this.constants.entity.states.ACTIVE;

      if (!_.isEmpty(this.events.featured)) {
        this.hasFeatured = true;
        if (this.events.featured[0].state !== activeState) {
          this.inactiveEvents.featured = this.events.featured.pop();
        }
      } else {
        this.hasFeatured = false;
      }

      if (!_.isEmpty(this.events.trending)) {
        this.hasTrending = true;
        this.inactiveEvents.trending = _.remove(this.events.trending, (item: any) => item.state !== activeState);
      } else {
        this.hasTrending = false;
      }

      if (!_.isEmpty(this.inactiveEvents.featured) || !_.isEmpty(this.inactiveEvents.trending)) {
        this.showBanner = true;
      }
    } catch (error) {
      this.error = true;
      this.errorLoggingService.logError('could not retrieve featured events', error);
    }
    this.loading = false;
  }

  async removeInactiveEvents() {
    this.showBanner = false;
    try {
      await this.featuredEventsService.update(this.events, this.state.params.venueId);
      this.loadData();
    } catch (error) {
      this.error = true;
      this.loading = false;
    }
  }
}
