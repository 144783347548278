const MINUTES_PER_DAY = 60 * 24;

class HoursSelectorController {
    $onInit() {
        this.disabled = this.disabled || false;
        this.onChange = this.onChange || (() => null);
        this.name = this.name || '';
        this.interval = Math.max(1, parseInt(this.interval) || 30);
        this.state = this.state || {};
        this.times = this.timeGenerator();
    }

    timeGenerator() {
        const times = [];
        let minute = 0;

        while (minute < MINUTES_PER_DAY) {
            const hour = Math.floor(minute / 60);
            const hourMod = hour % 12;
            const hourFormatted = hourMod === 0 ? 12 : hourMod;
            const minuteFormatted = minute % 60;
            const period = hour < 12 ? 'AM' : 'PM';

            times.push({
                text: `${hourFormatted}:${this.pad(minuteFormatted, 2)}${period}`,
                value: minute,
            });
            minute += this.interval;
        }

        return times;
    }

    onTimeSelect(time) {
        if (this.disabled) {
            return;
        }

        this.state.text = time.text;
        this.state.value = time.value;
        this.onChange({ value: time });
    }

    pad(n, width, z) {
        z = z || '0';
        n = `${n}`;
        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    }
}

export default HoursSelectorController;
