import {
  FormControl,
} from '@angular/forms';

export class Validator {
  static notInteger(ctrl: FormControl): null | { [key: string]: boolean } {
    if (ctrl.value !== 0 && !ctrl.value) {
      return null;
    }

    return /^\d+$/gm.test(ctrl.value) ? null : { notInteger: true };
  }

  static incorrectFormat(ctrl: FormControl): null | { [key: string]: boolean } {
    if (ctrl.value === undefined || ctrl.value === null || ctrl.value === '' || ctrl.value === '0') { return null; }

    return /^\d+\.\d{0,2}$/gm.test(ctrl.value) ? null : { incorrectFormat: true };
  }
}
