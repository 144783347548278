<div>
    <div
        class="loading-container"
        *ngIf="isLoading && !displayProps; else showTags"
    >
        <div class="icon-cr-info"></div>
        <div class="loading-title">{{ text.loadingTitle }}</div>
        <div class="loading-content">{{ text.loadingMessage }}</div>
    </div>
    <ng-template #showTags>
        <div class="popup-title">
            {{ text.userTagsPopupTitle }}
        </div>
        <div class="popup-content">
            <div *ngFor="let prop of displayProps | keyvalue">
                {{ prop.value.title }}:
                <strong>{{ formatValues(prop.value.values) }}</strong>
            </div>
        </div>
    </ng-template>
    <div class="popup-footer">
        {{ text.userLastOpen }}
        {{ properties.lastSeenAt | date: "shortTime" }}
    </div>
</div>
