/* eslint-disable max-len */
import _ from 'lodash';
import { FeaturedEventsManageComponent } from './events/components/featured-events-manage/featured-events-manage.component';
import {
    KitchenSelectComponent,

    OrderDetailsComponent,

    OrderHistoryComponent, OrderListComponent,

    OrderRefundComponent
} from './fnb-order-queue/components';
import { ReportSelectComponent } from "./system-reports/components";
import { fnbPermissions } from "./fnb-order-queue/constants/permissions";
import { fnbRoutes } from "./fnb-order-queue/constants/routes";
import { menuRoutes } from "./menus/menus.routes";
import {
    DiningLocationEditorComponent, DiningLocationsComponent, ReservationsListComponent,
    RestaurantSelectComponent
} from './fnb-reservations/components';

import { BundleGroupsManageComponent } from './menus/components/bundle-groups-manage/bundle-groups-manage.component';
import { BundleGroupListComponent } from './menus/components/bundle-group-list/bundle-group-list.component';
import { BundleManageComponent } from "./menus/components/bundle-manage/bundle-manage.component";
import { BundleItemComponent } from './menus/components/bundle-item/bundle-item.component';
import { FeesManageComponent } from './menus/components/fees-manage/fees-manage.component';
import { DiscountContentManageComponent } from './menus/components/discount-content-manage/discount-content-manage.component';
import { DiscountContentComponent } from './menus/components/discount-content/discount-content.component';
import { IdDetailContentManageComponent } from './menus/components/id-detail-content-manage/id-detail-content-manage.component';
import { IdDetailContentComponent } from './menus/components/id-detail-content/id-detail-content.component';
import { VenueHoursComponent } from "./venue-hours-angular/venue-hours.component"
import { LiftsTrailsActivitiesComponent } from "./lifts-trails-activities/lifts-trails-activities.component";
import { ViewDetailsComponent } from "./lifts-trails-activities/components/view-details/view-details.component";
import { EditDetailsComponent } from "./lifts-trails-activities/components/edit-details/edit-details.component";
import { AddComponent } from "./lifts-trails-activities/components/add/add.component";
import { EventTabsListComponent } from "./events/components/event-tabs-list/event-tabs-list.component";
import { EventTabsManageComponent } from "./events/components/event-tab-manage/event-tabs-manage.component";

function addViewModulePermissions(state, object) {
    if (!state.data) {
        state.data = {};
    }

    if (!state.data.permissions) {
        state.data.permissions = [];
    }

    state.data.permissions.push({ action: 'view:module', object });
}

function ClientRouting($stateProvider, $urlRouterProvider) {
    $urlRouterProvider.otherwise('/404');

    const defaultRoutes = {
        DASHBOARD: 'client.dashboard',
        QUICKSIGHT_DASHBOARD: 'client.quicksight-dashboard',
        EXPERIENCE_PROMOTER: 'client.experience-promoter.dashboard',
        REPORTING: 'client.reporting.orders',
        PLACES: 'client.places.list',
        EVENTS: 'client.events.list',
        POI: 'client.poi.list',
        MENUS: menuRoutes.DASHBOARD,
        FNB_ORDER_QUEUE: 'client.fnb-order-queue.dashboard',
        FNB_RESERVATIONS: 'client.fnb-reservations.dashboard',
        BEACONS: 'client.beacons.list',
        CONTENT: 'client.content.list',
        CLASSIC: 'client.mc-classic',
        VENUE_HOURS: 'client.venue-hours.order',
        APP_SETTINGS: 'client.app-settings.preferences',
        SYSTEM_REPORTS: 'client.system-reports.dashboard',
        LIFTS_TRAILS_ACTIVITIES: 'client.lifts-trails-activities.list',
    };

    const baseStateUrlPrefix = '/:customerId/:venueId';

    function dynamic(value) {
        return {
            value,
            dynamic: true,
        };
    }

    const getVenueId = (config) => {
        let venueId = localStorage.getItem('venueId');

        if (!venueId || !_.find(config.customer.venues, { id: venueId })) {
            venueId = config.customer.venues.length ? config.customer.venues[0].id : null;
        }

        return venueId;
    };

    const baseState = {
        name: 'client',
        url: '',
        component: 'crClient',
        resolve: {
            loadConfig: [
                'crConfigService',
                'crFeatureFlagsService',
                'crPolicyService',
                function (crConfigService, crFeatureFlagsService, crPolicyService) {
                    return crConfigService
                        .addAllConfigs()
                        .then(() => {
                            const { config } = crConfigService;

                            return crFeatureFlagsService
                                .initialize(config.customer.bulletTrain, config.user)
                                .then(() =>
                                    crPolicyService.initialize(
                                        config.user.username,
                                        config.customer.id,
                                        config.user.model,
                                        config.user.policy
                                    )
                                );
                        })
                        .catch((err) => {
                            if (err.status === 401) {
                                return Promise.reject('notLoggedIn');
                            }

                            return Promise.reject(err);
                        });
                },
            ],
        },
        redirectTo: (transition) => {
            const injector = transition.injector();

            return injector.getAsync('loadConfig').then(() => {
                const crConfig = injector.get('crConfig');
                return {
                    state: defaultRoutes[crConfig.customer.features[0].id],
                    params: {
                        customerId: crConfig.customer.id,
                        venueId: getVenueId(crConfig),
                    },
                };
            });
        },
        onExit: [
            'crConfigService',
            'crFeatureFlagsService',
            (crConfigService, crFeatureFlagsService) => {
                crFeatureFlagsService.logout();
                crConfigService.clearUserConfig();
                crConfigService.clearCustomerConfig();
            },
        ],
    };

    const otherwise = [
        {
            name: 'client.404',
            url: '/404',
            params: {
                customerId: null,
                venueId: null,
            },
            component: 'crPageNotFound',
        },
        {
            name: 'client.access-denied',
            url: '/access-denied',
            params: {
                customerId: null,
                venueId: null,
            },
            component: 'crAccessDenied',
        },
    ];

    const dashboardStates = [
        {
            name: 'client.dashboard',
            url: `${baseStateUrlPrefix}/dashboard`,
            component: 'crDashboard',
        },
        {
            name: 'client.quicksight-dashboard',
            url: `${baseStateUrlPrefix}/quicksight-dashboard`,
            component: 'crQuicksightDashboard',
        },
    ];

    const experiencePromoterStates = [
        {
            name: 'client.experience-promoter',
            url: `${baseStateUrlPrefix}/experience-promoter`,
            template: '<ui-view />',
            abstract: true,
        },
        {
            name: 'client.experience-promoter.dashboard',
            url: '/:tabId?name&search&state&status&category&type&sortby&sort&start&end',
            params: {
                tabId: dynamic('dashboard'),
                name: dynamic(),
                search: dynamic(),
                state: dynamic(),
                status: dynamic(),
                category: dynamic(),
                type: dynamic(),
                sortby: dynamic(),
                sort: dynamic(),
                start: dynamic(),
                end: dynamic(),
                toast: dynamic(null),
            },
            component: 'crExperiencePromoter',
        },
        {
            name: 'client.experience-promoter.automation-select',
            url: '/automations/create',
            params: {
                tabId: null,
            },
            component: 'crAutomationSelect',
            data: {
                permissions: [
                    {
                        action: 'write:exp',
                    },
                ],
            },
        },
        {
            name: 'client.experience-promoter.details-automation',
            url: '/automations/:id',
            component: 'crExperienceDetails',
            resolve: {
                group: () => 'automations',
            },
            params: {
                toast: dynamic(null),
            },
        },
        {
            name: 'client.experience-promoter.create-automation',
            url: '/automations/create/:type',
            params: {
                disableNavigation: true,
                copyOf: null,
            },
            component: 'crExperienceManage',
            resolve: {
                group: () => 'automations',
            },
            data: {
                permissions: [
                    {
                        action: 'write:exp',
                    },
                ],
            },
        },
        {
            name: 'client.experience-promoter.edit-automation',
            url: '/automations/edit/:id',
            params: {
                disableNavigation: true,
            },
            component: 'crExperienceManage',
            resolve: {
                edit: () => true,
                group: () => 'automations',
            },
            data: {
                permissions: [
                    {
                        action: 'write:exp',
                    },
                ],
            },
        },
        {
            name: 'client.experience-promoter.automation-report',
            url: '/automations/report/:id',
            component: 'crExperienceReport',
            resolve: {
                group: () => 'automations',
            },
        },
        {
            name: 'client.experience-promoter.broadcast-select',
            url: '/broadcasts/create',
            component: 'crBroadcastSelect',
            params: {
                tabId: null,
            },
            data: {
                permissions: [
                    {
                        action: 'write:exp',
                    },
                ],
            },
        },
        {
            name: 'client.experience-promoter.details-broadcast',
            url: '/broadcasts/:id',
            component: 'crExperienceDetails',
            resolve: {
                group: () => 'broadcasts',
            },
            params: {
                toast: dynamic(null),
            },
        },
        {
            name: 'client.experience-promoter.create-broadcast',
            url: '/broadcasts/create/:type',
            params: {
                disableNavigation: true,
                copyOf: null,
            },
            component: 'crExperienceManage',
            resolve: {
                group: () => 'broadcasts',
            },
            data: {
                permissions: [
                    {
                        action: 'write:exp',
                    },
                ],
            },
        },
        {
            name: 'client.experience-promoter.edit-broadcast',
            url: '/broadcasts/edit/:id',
            params: {
                disableNavigation: true,
            },
            component: 'crExperienceManage',
            resolve: {
                edit: () => true,
                group: () => 'broadcasts',
            },
            data: {
                permissions: [
                    {
                        action: 'write:exp',
                    },
                ],
            },
        },
        {
            name: 'client.experience-promoter.broadcast-report',
            url: '/broadcasts/report/:id',
            component: 'crExperienceReport',
            resolve: {
                group: () => 'broadcasts',
            },
        },
        {
            name: 'client.experience-promoter.coupons-list',
            url: '/coupons/list?name&state&type&sortby&sort',
            params: {
                toast: dynamic(null),
                name: dynamic(),
                state: dynamic(),
                type: dynamic(),
                sortby: dynamic(),
                sort: dynamic(),
            },
            component: 'crCouponsList',
        },
        {
            name: 'client.experience-promoter.create-coupon',
            url: '/coupons/create',
            params: {
                disableNavigation: true,
                id: dynamic(null),
            },
            component: 'crCouponManage',
            data: {
                permissions: [
                    {
                        action: 'write:exp',
                    },
                ],
            },
        },
        {
            name: 'client.experience-promoter.edit-coupon',
            url: '/coupons/edit/:id',
            params: {
                disableNavigation: true,
            },
            component: 'crCouponManage',
            resolve: {
                edit: () => true,
            },
            data: {
                permissions: [
                    {
                        action: 'write:exp',
                    },
                ],
            },
        },
        {
            name: 'client.experience-promoter.coupon-details',
            url: '/coupons/details/:id',
            component: 'crCouponDetails',
        },
    ];

    const eventStates = [
        {
            name: 'client.events',
            url: `${baseStateUrlPrefix}/events`,
            template: '<ui-view />',
            abstract: true,
        },
        {
            name: 'client.events.categories',
            url: '/categories',
            params: {
                toast: dynamic(null),
            },
            component: 'crEntityCategories',
            resolve: {
                entityType: () => 'event-categories',
                requiredWritePermission: () => 'write:event',
            },
        },
        {
            name: 'client.events.categories-edit',
            url: '/categories/edit',
            params: {
                disableNavigation: true,
            },
            component: 'crEntityCategoriesEdit',
            resolve: {
                entityType: () => 'event-categories',
            },
            data: {
                permissions: [
                    {
                        action: 'write:event',
                    },
                ],
            },
        },
        {
            name: 'client.events.category-edit',
            url: '/categories/edit/:id?locale',
            params: {
                disableNavigation: true,
                locale: dynamic(),
            },
            component: 'crManageCategory',
            resolve: {
                edit: () => true,
                entityType: () => 'event-categories',
                iconRequired: () => true,
            },
            data: {
                permissions: [
                    {
                        action: 'write:event',
                    },
                ],
            },
        },
        {
            name: 'client.events.category-create',
            url: '/categories/create?locale',
            params: {
                disableNavigation: true,
                topLevel: true,
                locale: dynamic(),
            },
            component: 'crManageCategory',
            resolve: {
                entityType: () => 'event-categories',
                iconRequired: () => true,
            },
            data: {
                permissions: [
                    {
                        action: 'write:event',
                    },
                ],
            },
        },
        {
            name: 'client.events.category-create-subcategory',
            url: '/categories/create/subcategory',
            params: {
                disableNavigation: true,
            },
            component: 'crManageCategory',
            resolve: {
                entityType: () => 'event-categories',
            },
            data: {
                permissions: [
                    {
                        action: 'write:event',
                    },
                ],
            },
        },
        {
            name: 'client.events.attributes-list',
            url: '/attributes/list?search&sortby&sort&type',
            params: {
                toast: dynamic(null),
                search: dynamic(),
                sortby: dynamic(),
                sort: dynamic(),
                type: dynamic(),
            },
            component: 'crAttributesList',
            resolve: {
                entityType: () => 'event-attributes',
                attributeType: () => 'events',
                requiredWritePermission: () => 'write:event',
            },
        },
        {
            name: 'client.events.manage-attributes',
            url: '/attributes/manage/:id?locale',
            params: {
                disableNavigation: true,
                locale: dynamic(),
                id: null,
            },
            component: 'crManageAttribute',
            resolve: {
                entityType: () => 'event-attributes',
                attributeType: () => 'events',
            },
        },
        {
            name: 'client.events.list',
            url: '/:tabId?search&state&category&sortby&sort&start&end',
            component: 'crEvents',
            params: {
                tabId: dynamic('list'),
                search: dynamic(),
                state: dynamic(),
                category: dynamic(),
                sortby: dynamic(),
                sort: dynamic(),
                start: dynamic(),
                end: dynamic(),
                toast: dynamic(null),
            },
        },
        {
            name: 'client.events.details',
            url: '/details/:id?locale',
            component: 'crEventDetails',
            params: {
                toast: dynamic(null),
                locale: dynamic(),
            },
        },
        {
            name: 'client.events.edit',
            url: '/edit/:id?locale',
            params: {
                disableNavigation: true,
                locale: dynamic(),
            },
            component: 'crEventManage',
            resolve: {
                edit: () => true,
            },
            data: {
                permissions: [
                    {
                        action: 'write:event',
                    },
                ],
            },
        },
        {
            name: 'client.events.create',
            url: '/create/:id?locale',
            params: {
                disableNavigation: true,
                locale: dynamic(),
                id: dynamic(null),
            },
            component: 'crEventManage',
            data: {
                permissions: [
                    {
                        action: 'write:event',
                    },
                ],
            },
        },
        {
            name: 'client.events.featured-events-manage',
            url: '/manage/featured-and-trending',
            params: {
                disableNavigation: true,
            },
            component: FeaturedEventsManageComponent,
            data: {
                permissions: [
                    {
                        action: 'write:event',
                    },
                ],
            },
        },
        {
            name: 'client.events.tab-navigation',
            url: '/tab-navigation',
            component: EventTabsListComponent,
            data: {
                permissions: [
                    {
                        action: 'write:event',
                    },
                ],
            },

        },
        {
            name: 'client.events.event-tabs-manage',
            url: '/manage/event-tabs-manage',
            params: {
                disableNavigation: true,
            },
            component: EventTabsManageComponent,
            data: {
                permissions: [
                    {
                        action: 'write:event',
                    },
                ],
            },
        },
    ];

    const poiStates = [
        {
            name: 'client.poi',
            url: `${baseStateUrlPrefix}/poi`,
            template: '<ui-view />',
            abstract: true,
        },
        {
            name: 'client.poi.categories',
            url: '/categories',
            params: {
                toast: dynamic(null),
            },
            component: 'crEntityCategories',
            resolve: {
                entityType: () => 'poi-categories',
                requiredWritePermission: () => 'write:poi',
            },
        },
        {
            name: 'client.poi.categories-edit',
            url: '/categories/edit',
            params: {
                disableNavigation: true,
            },
            component: 'crEntityCategoriesEdit',
            resolve: {
                entityType: () => 'poi-categories',
            },
            data: {
                permissions: [
                    {
                        action: 'write:poi',
                    },
                ],
            },
        },
        {
            name: 'client.poi.category-edit',
            url: '/categories/edit/:id?locale',
            params: {
                disableNavigation: true,
                locale: dynamic(),
            },
            component: 'crManageCategory',
            resolve: {
                edit: () => true,
                entityType: () => 'poi-categories',
                iconRequired: () => true,
            },
            data: {
                permissions: [
                    {
                        action: 'write:poi',
                    },
                ],
            },
        },
        {
            name: 'client.poi.category-create',
            url: '/categories/create?locale',
            params: {
                disableNavigation: true,
                topLevel: 'true',
                locale: dynamic(),
            },
            component: 'crManageCategory',
            resolve: {
                entityType: () => 'poi-categories',
                iconRequired: () => true,
            },
            data: {
                permissions: [
                    {
                        action: 'write:poi',
                    },
                ],
            },
        },
        {
            name: 'client.poi.category-create-subcategory',
            url: '/categories/create/subcategory?locale',
            params: {
                disableNavigation: true,
                locale: dynamic(),
            },
            component: 'crManageCategory',
            resolve: {
                entityType: () => 'poi-categories',
            },
            data: {
                permissions: [
                    {
                        action: 'write:poi',
                    },
                ],
            },
        },
        {
            name: 'client.poi.types',
            url: '/types',
            component: 'crPoiTypeList',
        },
        {
            name: 'client.poi.list',
            url: '/:tabId?search&state&category&sortby&sort&focus&zoom',
            component: 'crPoi',
            params: {
                tabId: dynamic('list'),
                search: dynamic(),
                state: dynamic(),
                category: dynamic(),
                sortby: dynamic(),
                sort: dynamic(),
                focus: dynamic(),
                zoom: dynamic(),
                toast: dynamic(null),
            },
        },
        {
            name: 'client.poi.attributes-list',
            url: '/attributes/list?search&sortby&sort&type',
            params: {
                toast: dynamic(null),
                search: dynamic(),
                sortby: dynamic(),
                sort: dynamic(),
                type: dynamic(),
            },
            component: 'crAttributesList',
            resolve: {
                entityType: () => 'poi-attributes',
                attributeType: () => 'poi',
                requiredWritePermission: () => 'write:poi',
            },
        },
        {
            name: 'client.poi.manage-attributes',
            url: '/attributes/manage/:id?locale',
            params: {
                disableNavigation: true,
                locale: dynamic(),
                id: null,
            },
            component: 'crManageAttribute',
            resolve: {
                entityType: () => 'poi-attributes',
                attributeType: () => 'poi',
            },
            data: {
                permissions: [
                    {
                        action: 'write:poi',
                    },
                ],
            },
        },
        {
            name: 'client.poi.details',
            url: '/details/:id?locale',
            component: 'crPoiDetails',
            params: {
                toast: dynamic(null),
                locale: dynamic(),
            },
        },
        {
            name: 'client.poi.edit',
            url: '/edit/:id?locale',
            params: {
                disableNavigation: true,
                locale: dynamic(),
            },
            component: 'crPoiEdit',
            resolve: {
                edit: () => true,
            },
            data: {
                permissions: [
                    {
                        action: 'write:poi',
                    },
                ],
            },
        },
        {
            name: 'client.poi.create',
            url: '/create/:id?locale',
            params: {
                disableNavigation: true,
                id: dynamic(null),
                locale: dynamic(),
            },
            component: 'crPoiEdit',
            data: {
                permissions: [
                    {
                        action: 'write:poi',
                    },
                ],
            },
        },
        {
            name: 'client.poi.type-inventory',
            url: '/type/:typeId/inventory',
            component: 'crPoiTypeInventory',
            params: {
                typeName: null,
            },
        },
    ];

    const menuStates = [
        {
            name: menuRoutes.MENUS,
            url: `${baseStateUrlPrefix}/food-and-beverage`,
            template: '<ui-view />',
            abstract: true,
        },
        {
            name: menuRoutes.DASHBOARD,
            url: '/:tabId?search&state&sortby&sort',
            params: {
                tabId: dynamic('dashboard'),
                toast: dynamic(null),
                search: dynamic(),
                state: dynamic(),
                sortby: dynamic(),
                sort: dynamic(),
            },
            component: 'crMenusTabs',
        },
        {
            name: menuRoutes.CREATE_MENU,
            url: '/menu/create?kitchenId',
            params: {
                kitchenId: dynamic(),
                disableNavigation: true,
            },
            component: 'crMenuManage',
        },
        {
            name: menuRoutes.EDIT_MENU,
            url: '/menu/:menuId/place/:placeId',
            params: {
                disableNavigation: true,
                fromDetails: false
            },
            component: 'crMenuManage',
            resolve: {
                edit: () => true,
            },
            data: {
                permissions: [
                    {
                        action: 'write:fnb',
                    },
                ],
            },
        },
        {
            name: menuRoutes.CATEGORIES,
            url: '/menu/:menuId/place/:placeId/categories?search&sortby&sort',
            params: {
                toast: dynamic(null),
                tabId: null,
                search: dynamic(),
                sortby: dynamic(),
                sort: dynamic(),
            },
            component: 'crMenuCategories',
        },
        {
            name: menuRoutes.EDIT_CATEGORY,
            url: '/menu/:menuId/place/:placeId/categories/edit/:id?locale',
            params: {
                disableNavigation: true,
                locale: dynamic(),
            },
            component: 'crMenuCategoryManage',
            resolve: {
                edit: () => true,
            },
            data: {
                permissions: [
                    {
                        action: 'write:fnb',
                    },
                ],
            },
        },
        {
            name: menuRoutes.CREATE_CATEGORY,
            url: '/menu/:menuId/place/:placeId/categories/create?locale',
            params: {
                disableNavigation: true,
                topLevel: true,
                locale: dynamic(),
            },
            component: 'crMenuCategoryManage',
            data: {
                permissions: [
                    {
                        action: 'write:fnb',
                    },
                ],
            },
        },
        {
            name: menuRoutes.MODIFIER_GROUPS,
            url: '/menu/:menuId/place/:placeId/modifier-groups/list?search&sort&sortby',
            params: {
                toast: dynamic(null),
                search: dynamic(),
                sort: dynamic(),
                sortby: dynamic(),
            },
            component: 'crModifierGroupsList',
        },
        {
            name: menuRoutes.CREATE_MODIFIER_GROUPS,
            url: '/menu/:menuId/place/:placeId/modifier-groups/create/:id?locale',
            params: {
                disableNavigation: true,
                locale: dynamic(),
                id: dynamic(null),
            },
            component: 'crModifierGroupsManage',
            data: {
                permissions: [
                    {
                        action: 'write:fnb',
                    },
                ],
            },
        },
        {
            name: menuRoutes.EDIT_MODIFIER_GROUPS,
            url: '/menu/:menuId/place/:placeId/modifier-groups/edit/:id?locale',
            params: {
                disableNavigation: true,
                locale: dynamic(),
            },
            component: 'crModifierGroupsManage',
            resolve: {
                edit: () => true,
            },
            data: {
                permissions: [
                    {
                        action: 'write:fnb',
                    },
                ],
            },
        },
        {
            name: menuRoutes.BUNDLE,
            url: '/menu/:menuId/place/:placeId/bundle/:bundleId',
            component: BundleItemComponent
        },
        {
            name: menuRoutes.BUNDLE_GROUPS,
            url: '/menu/:menuId/place/:placeId/bundle-groups',
            component: BundleGroupListComponent
        } ,
        {
            name: menuRoutes.CREATE_BUNDLE,
            url: '/menu/:menuId/place/:placeId/menu-bundles/create',
            params: {
                disableNavigation: true,
                loadedBundleItem: null,
                locale: dynamic()
            },
            component: BundleManageComponent,
            data: {
                permissions: [
                    {
                        action: 'write:fnb'
                    }
                ]
            }
        },
        {
            name: menuRoutes.EDIT_BUNDLE,
            url: '/menu/:menuId/place/:placeId/menu-bundles/edit/:id?locale',
            params: {
                disableNavigation: true,
                locale: dynamic()
            },
            component: BundleManageComponent,
            data: {
                edit: true,
                permissions: [
                    {
                        action: 'write:fnb'
                    }
                ]
            }
        },
        {
            name: menuRoutes.CREATE_BUNDLE_GROUPS,
            url: '/menu/:menuId/place/:placeId/bundle-groups/create',
            params: {
                disableNavigation: true,
                locale: dynamic(),
                action: "create",
                bundleGroupId: dynamic(null)
            },
            component: BundleGroupsManageComponent,
            data: {
                permissions: [
                    {
                        action: 'write:fnb',
                    },
                ],
            },
        },
        {
            name: menuRoutes.EDIT_BUNDLE_GROUPS,
            url: '/menu/:menuId/place/:placeId/bundle-groups/edit/:bundleGroupId?locale',
            params: {
                disableNavigation: true,
                action: "edit",
                locale: dynamic()
            },
            component: BundleGroupsManageComponent,
            resolve: {
                edit: () => true,
            },
            data: {
                permissions: [
                    {
                        action: 'write:fnb',
                    },
                ],
            },
        },
        {
            name: menuRoutes.PRODUCTS,
            url: '/menu/:menuId/place/:placeId/:tabId?search&state&sortby&sort&category',
            component: 'crMenus',
            params: {
                tabId: dynamic('overview'),
                toast: dynamic(null),
                search: dynamic(),
                state: dynamic(),
                sortby: dynamic(),
                sort: dynamic(),
                category: dynamic(),
            },
        },
        {
            name: menuRoutes.PRODUCT_DETAILS,
            url: '/menu/:menuId/place/:placeId/menu-item/details/:id?locale',
            component: 'crProductDetails',
            params: {
                toast: dynamic(null),
                locale: dynamic(),
            },
        },
        {
            name: menuRoutes.EDIT_PRODUCT,
            url: '/menu/:menuId/place/:placeId/menu-item/edit/:id?locale',
            params: {
                disableNavigation: true,
                locale: dynamic(),
            },
            component: 'crProductsManage',
            resolve: {
                edit: () => true,
            },
            data: {
                permissions: [
                    {
                        action: 'write:fnb',
                    },
                ],
            },
        },
        {
            name: menuRoutes.CREATE_PRODUCT,
            url: '/menu/:menuId/place/:placeId/menu-item/create/:id?locale',
            params: {
                disableNavigation: true,
                locale: dynamic(),
                id: dynamic(null),
            },
            component: 'crProductsManage',
            data: {
                permissions: [
                    {
                        action: 'write:fnb',
                    },
                ],
            },
        },
        {
            name: menuRoutes.CREATE_KITCHEN,
            url: '/kitchen/create?poiId',
            params: {
                disableNavigation: true,
                toast: dynamic(null),
                poiId: dynamic(),
            },
            component: 'crKitchenManage',
            resolve: {
                edit: () => false,
            },
            data: {
                permissions: [
                    {
                        action: 'write:fnb',
                    },
                ],
            },
        },
        {
            name: menuRoutes.EDIT_KITCHEN,
            url: '/:kitchenId',
            params: {
                tabId: dynamic('kitchens'),
                toast: dynamic(null),
                search: dynamic(),
                state: dynamic(),
                sortby: dynamic(),
                sort: dynamic(),
            },
            component: 'crMenusTabs'
        },
        {
            name: menuRoutes.KITCHEN,
            url: '/:kitchenId/details',
            params: {
                tabId: dynamic('kitchens'),
                toast: dynamic(null),
                created: dynamic(null),
                search: dynamic(),
                state: dynamic(),
                sortby: dynamic(),
                sort: dynamic(),
            },
            component: 'crMenusTabs',
        },
        {
            name: menuRoutes.CREATE_FEES,
            url: '/fees/create',
            component: FeesManageComponent,
            params: {
                edit: false
            }
        },
        {
            name: menuRoutes.EDIT_FEES,
            url: '/fees/:feeId/edit',
            component: FeesManageComponent,
            params: {
                edit: true,
            }
        },
        {
            name: menuRoutes.DISCOUNT_CONTENT,
            url: '/discount-content',
            component: DiscountContentComponent,
        },
        {
            name: menuRoutes.EDIT_DISCOUNT_CONTENT,
            url: '/discount-content/edit',
            component: DiscountContentManageComponent,
            params: {
                edit: true,
            }
        },
        {
            name: menuRoutes.CREATE_DISCOUNT_CONTENT,
            url: '/discount-content/create',
            component: DiscountContentManageComponent,
            params: {
                edit: false,
            }
        },
        {
            name: menuRoutes.ID_DETAIL_CONTENT,
            url: '/id-detail-content',
            component: IdDetailContentComponent,
        },
        {
            name: menuRoutes.EDIT_ID_DETAIL_CONTENT,
            url: '/id-detail-content/edit',
            component: IdDetailContentManageComponent,
            params: {
                edit: true,
            }
        },
        {
            name: menuRoutes.CREATE_ID_DETAIL_CONTENT,
            url: '/id-detail-content/create',
            component: IdDetailContentManageComponent,
            params: {
                edit: false,
            }
        }
    ];

    const fnbReservationStates = [
        {
            name: 'client.fnb-reservations',
            url: `${baseStateUrlPrefix}/fnb-reservations`,
            template: '<ui-view />',
            abstract: true,
        },
        {
            name: 'client.fnb-reservations.dashboard',
            url: '/dashboard',
            component: RestaurantSelectComponent,
        },
        {
            name: 'client.fnb-reservations.reservations-list',
            url: '/reservations/:productId?name',
            component: ReservationsListComponent,
            params: {
                productId: dynamic('productId'),
                name: dynamic(),
            },
            data: {
                permissions: [
                    {
                        action: 'read:reservation',
                    },
                ],
            },
        },
        {
            name: 'client.fnb-reservations.dining-locations',
            url: '/locations',
            component: DiningLocationsComponent,
            data: {
                permissions: [
                    {
                        action: 'read:reservation-admin',
                    },
                ],
            },
        },
        {
            name: 'client.fnb-reservations.dining-locations-editor',
            url: '/locations/editor?poiId&eventId&preview',
            params: {
                poiId: dynamic(),
                eventId: dynamic(),
                preview: dynamic(),
            },
            component: DiningLocationEditorComponent,
            data: {
                permissions: [
                    {
                        action: 'read:reservation-admin',
                    },
                ],
            },
        },
    ];

    const fnbOrderQueueStates = [
        {
            name: fnbRoutes.orderQueue,
            url: `${baseStateUrlPrefix}/fnb-order-queue`,
            template: '<ui-view />',
            abstract: true,
        },
        {
            name: fnbRoutes.kitchenSelect,
            url: '/dashboard',
            component: KitchenSelectComponent,
            data: {
                permissions: [
                    {
                        action: fnbPermissions.readOrder,
                    },
                ],
            },
        },
        {
            name: fnbRoutes.orderList,
            url: '/kitchen/:kitchenId?state&search&sort',
            params: {
                kitchenId: dynamic('kitchenId'),
                state: dynamic(),
                search: dynamic(),
                sort: dynamic(),
                orderId: dynamic(),
                orderNotice: dynamic(),
            },
            component: OrderListComponent,
            data: {
                permissions: [
                    {
                        action: fnbPermissions.readOrder,
                    },
                ],
            },
        },
        {
            name: fnbRoutes.orderDetails,
            url: '/kitchen/:kitchenId/order/:orderId?state',
            params: {
                state: dynamic(),
                kitchenId: dynamic('kitchenId'),
                orderId: dynamic('orderId'),
            },
            component: OrderDetailsComponent,
            data: {
                permissions: [
                    {
                        action: fnbPermissions.readOrder,
                    },
                ],
            },
        },
        {
            name: fnbRoutes.orderRefund,
            url: '/kitchen/:kitchenId/refund/order/:orderId?state',
            params: {
                state: dynamic(),
                kitchenId: dynamic('kitchenId'),
                orderId: dynamic('orderId'),
            },
            component: OrderRefundComponent,
            data: {
                permissions: [
                    {
                        action: fnbPermissions.readRefund,
                    },
                ],
            },
        },
        {
            name: fnbRoutes.orderHistory,
            url: '/kitchen/:kitchenId/history',
            params: {
                state: dynamic(),
                kitchenId: dynamic('kitchenId')
            },
            component: OrderHistoryComponent,
            data: {
                permissions: [
                    {
                        action: fnbPermissions.readOrder
                    }
                ]
            }
        }
    ];

    const beaconStates = [
        {
            name: 'client.beacons',
            url: `${baseStateUrlPrefix}/beacons`,
            template: '<ui-view />',
            abstract: true,
        },
        {
            name: 'client.beacons.list',
            url: '/:tabId?search&sortby&sort&focus&zoom',
            component: 'crBeacons',
            params: {
                tabId: dynamic('list'),
                search: dynamic(),
                sortby: dynamic(),
                sort: dynamic(),
                focus: dynamic(),
                zoom: dynamic(),
                toast: dynamic(null),
            },
        },
        {
            name: 'client.beacons.details',
            url: '/details/:id',
            component: 'crBeaconDetails',
            params: {
                toast: dynamic(null),
            },
        },
        {
            name: 'client.beacons.edit',
            url: '/edit/:id',
            params: {
                disableNavigation: true,
            },
            component: 'crBeaconEdit',
            resolve: {
                edit: () => true,
            },
            data: {
                permissions: [
                    {
                        action: 'write:beacon',
                    },
                ],
            },
        },
        {
            name: 'client.beacons.create',
            url: '/create/:id',
            params: {
                disableNavigation: true,
                id: dynamic(null),
            },
            component: 'crBeaconEdit',
            data: {
                permissions: [
                    {
                        action: 'write:beacon',
                    },
                ],
            },
        },
    ];

    const mcClassicStates = [
        {
            name: 'client.classic',
            url: `${baseStateUrlPrefix}/mc-classic`,
            component: 'crMcClassic',
        },
    ];

    const venueHoursStates = [
        {
            name: 'client.venue-hours',
            url: `${baseStateUrlPrefix}/venue-hours`,
            template: '<ui-view />',
            abstract: true,
        },
        {
            name: 'client.venue-hours.order',
            url: '/order',
            component: VenueHoursComponent,
            params: {
                toast: dynamic(null),
            },
        },
        {
            name: 'client.venue-hours.edit-order',
            url: '/order/edit',
            component: 'crVenueHoursEditOrder',
            params: {
                disableNavigation: true,
            },
            data: {
                permissions: [
                    {
                        action: 'write:beacon',
                    },
                ],
            },
        },
        {
            name: 'client.venue-hours.create',
            url: '/create/:id?locale',
            params: {
                disableNavigation: true,
                id: dynamic(null),
            },
            component: 'crVenueHoursManage',
            data: {
                permissions: [
                    {
                        action: 'write:beacon',
                    },
                ],
            },
        },
        {
            name: 'client.venue-hours.edit',
            url: '/edit/:id?locale',
            params: {
                disableNavigation: true,
            },
            component: 'crVenueHoursManage',
            resolve: {
                isEdit: () => true,
            },
            data: {
                permissions: [
                    {
                        action: 'write:beacon',
                    },
                ],
            },
        },
    ];

    const systemReportsStates = [
        {
            name: 'client.system-reports',
            url: `${baseStateUrlPrefix}/system-reports`,
            template: '<ui-view />',
            abstract: true,
        },
        {
            name: 'client.system-reports.dashboard',
            url: '/dashboard',
            component: ReportSelectComponent,
        }
    ];

    const appSettingsStates = [
        {
            name: 'client.app-settings',
            url: `${baseStateUrlPrefix}/app-settings`,
            template: '<ui-view />',
            abstract: true,
        },
        {
            name: 'client.app-settings.preferences',
            url: '/preferences?locale',
            component: 'crAppSettings',
            params: {
                tabId: 'preferences',
                locale: dynamic(),
            },
        },
        {
            name: 'client.app-settings.preferences-create',
            url: '/preferences/create',
            params: {
                disableNavigation: true,
            },
            component: 'crPreferencesManage',
            data: {
                permissions: [
                    {
                        action: 'write:guestpref',
                    },
                ],
            },
        },
        {
            name: 'client.app-settings.preferences-edit',
            url: '/preferences/edit',
            params: {
                disableNavigation: true,
            },
            component: 'crPreferencesManage',
            resolve: {
                edit: () => true,
            },
            data: {
                permissions: [
                    {
                        action: 'write:guestpref',
                    },
                ],
            },
        },
        {
            name: 'client.app-settings.question-list',
            url: '/questions',
            params: {
                toast: dynamic(null),
            },
            component: 'crQuestionList',
        },
        {
            name: 'client.app-settings.question-create',
            url: '/questions/create',
            params: {
                disableNavigation: true,
                toast: dynamic(null),
            },
            component: 'crQuestionManage',
            data: {
                permissions: [
                    {
                        action: 'write:guestpref',
                    },
                ],
            },
        },
        {
            name: 'client.app-settings.question-edit',
            url: '/questions/edit/:level/:key',
            params: {
                disableNavigation: true,
            },
            component: 'crQuestionManage',
            resolve: {
                edit: () => true,
            },
            data: {
                permissions: [
                    {
                        action: 'write:guestpref',
                    },
                ],
            },
        },
    ];

    const reportingStates = [
        {
            name: 'client.reporting',
            url: `${baseStateUrlPrefix}/reporting`,
            template: '<ui-view />',
            abstract: true,
        },
        {
            name: 'client.reporting.tabular',
            url: '',
            template: '<ui-view />',
            abstract: true,
        },
        {
            name: 'client.reporting.tabular.orders-placed',
            url: '/orders/tabular/placed?interval&channel',
            component: 'crReportingTabularOrdersPlaced',
            params: {
                interval: dynamic(),
                channel: dynamic(),
            },
        },
        {
            name: 'client.reporting.tabular.top-items',
            url: '/orders/tabular/top-items?interval&channel',
            component: 'crReportingTabularTopItems',
            params: {
                interval: dynamic(),
                channel: dynamic(),
            },
        },
        {
            name: 'client.reporting.orders',
            url: '/orders?channel&start&end&interval',
            component: 'crReportingOrders',
            params: {
                interval: dynamic(),
                channel: dynamic(),
                start: dynamic(),
                end: dynamic(),
            },
        },
        {
            name: 'client.reporting.app-usage',
            url: '/app-usage?interval&channel',
            component: 'crReportingAppUsage',
            params: {
                interval: dynamic(),
                channel: dynamic(),
            },
        },
        {
            name: 'client.reporting.list',
            url: '',
            component: 'crReports',
        },
    ];

    // const placeStates = [
    //     {
    //         name: 'client.places',
    //         url: `${baseStateUrlPrefix}/places`,
    //         template: '<ui-view />',
    //         abstract: true
    //     },
    //     {
    //         name: 'client.places.list',
    //         url: '/:tabId?search&state&sortby&sort&timeframe',
    //         params: {
    //             tabId: null
    //         },
    //         component: 'crPlaces'
    //     },
    //     {
    //         name: 'client.places.details',
    //         url: '/details/:placeId?timeframe',
    //         component: 'crPlaceDetails'
    //     }
    // ];

    const cvtStates = [
        {
            name: 'client.cvt',
            url: `${baseStateUrlPrefix}/cvt`,
            template: '<ui-view />',
            abstract: true,
        },
        {
            name: 'client.cvt.dashboard',
            url: '/dashboard',
            component: 'crCvt',
        },
    ];

    const iconStates = [
        {
            name: 'client.icons',
            url: `${baseStateUrlPrefix}/icons`,
            component: 'crIcons',
        },
    ];

    const liftsTrailsActivitiesStates = [
        {
            name: 'client.lifts-trails-activities',
            url: `${baseStateUrlPrefix}/lifts-trails-activities`,
            template: '<ui-view />',
            abstract: true,
        },
        {
            name: 'client.lifts-trails-activities.list',
            url: '/list?tabId',
            component: LiftsTrailsActivitiesComponent,
            params: {
                tabId: dynamic(),
            },
        },
        {
            name: 'client.lifts-trails-activities.details',
            url: '/details/:id?locale&tabId',
            component: ViewDetailsComponent,
            params: {
                toast: dynamic(null),
                locale: dynamic(),
                tabId: dynamic(),
            },
        },
        {
            name: 'client.lifts-trails-activities.edit',
            url: '/edit/:id?locale&tabId',
            params: {
                disableNavigation: true,
                locale: dynamic(),
                tabId: dynamic(),
            },
            component: EditDetailsComponent,
            resolve: {
                edit: () => true,
            },
            data: {
                permissions: [
                    {
                        action: 'write:poi',
                    },
                ],
            },
        },
        {
            name: 'client.lifts-trails-activities.add',
            url: '/add/:poiId?tabId',
            params: {
                disableNavigation: true,
                locale: dynamic(),
                tabId: dynamic(),
                poiId: dynamic()
            },
            component: AddComponent,
            resolve: {
                edit: () => true,
            },
            data: {
                permissions: [
                    {
                        action: 'write:poi',
                    },
                ],
            },
        }
    ];

    $stateProvider.state(baseState);

    dashboardStates.forEach((state) => {
        addViewModulePermissions(state, 'module/home');
        $stateProvider.state(state);
    });

    experiencePromoterStates.forEach((state) => {
        addViewModulePermissions(state, 'module/exp-promoter');
        $stateProvider.state(state);
    });

    eventStates.forEach((state) => {
        addViewModulePermissions(state, 'module/events');
        $stateProvider.state(state);
    });

    poiStates.forEach((state) => {
        addViewModulePermissions(state, 'module/poi');
        $stateProvider.state(state);
    });

    menuStates.forEach((state) => {
        addViewModulePermissions(state, 'module/fnb');
        $stateProvider.state(state);
    });

    fnbOrderQueueStates.forEach((state) => {
        addViewModulePermissions(state, 'module/fnb-order');
        $stateProvider.state(state);
    });

    fnbReservationStates.forEach((state) => {
        addViewModulePermissions(state, 'module/fnb-reservations');
        $stateProvider.state(state);
    });

    beaconStates.forEach((state) => {
        addViewModulePermissions(state, 'module/beacons');
        $stateProvider.state(state);
    });

    mcClassicStates.forEach((state) => {
        addViewModulePermissions(state, 'module/mc-classic');
        $stateProvider.state(state);
    });

    venueHoursStates.forEach((state) => {
        addViewModulePermissions(state, 'module/venue-hours');
        $stateProvider.state(state);
    });

    systemReportsStates.forEach((state) => {
        addViewModulePermissions(state, 'module/system-reports');
        $stateProvider.state(state);
    });

    appSettingsStates.forEach((state) => {
        addViewModulePermissions(state, 'module/app-settings');
        $stateProvider.state(state);
    });

    reportingStates.forEach((state) => {
        addViewModulePermissions(state, 'module/legacy-reporting');
        $stateProvider.state(state);
    });

    cvtStates.forEach((state) => {
        addViewModulePermissions(state, 'module/internal-cvt');
        $stateProvider.state(state);
    });

    iconStates.forEach((state) => {
        addViewModulePermissions(state, 'module/internal-icon');
        $stateProvider.state(state);
    });

    liftsTrailsActivitiesStates.forEach((state) => {
        addViewModulePermissions(state, 'module/lifts-trails-activities');
        $stateProvider.state(state);
    });

    otherwise.forEach((state) => {
        $stateProvider.state(state);
    });
}

export default ClientRouting;
