import template from './split-view-page.html';
import './split-view-page.less';

const SplitViewComponent = {
    template,
    transclude: {
        crTitleBar: 'crTitleBar',
        crFiltersContainer: '?crFiltersContainer',
        crTop: '?crTop',
        crLeft: 'crLeft',
        crRight: 'crRight',
    },
    bindings: {
        pageClass: '@?',
    },
};

export default SplitViewComponent;
