const CLIENT_MENUS = 'client.menus';

export const menuRoutes = {
  MENUS: `${CLIENT_MENUS}`,
  DASHBOARD: `${CLIENT_MENUS}.dashboard`,
  CREATE_MENU: `${CLIENT_MENUS}.menu-create`,
  EDIT_MENU: `${CLIENT_MENUS}.menu-edit`,
  CATEGORIES: `${CLIENT_MENUS}.categories`,
  EDIT_CATEGORY: `${CLIENT_MENUS}.category-edit`,
  CREATE_CATEGORY: `${CLIENT_MENUS}.category-create`,
  MODIFIER_GROUPS: `${CLIENT_MENUS}.modifier-groups`,
  CREATE_MODIFIER_GROUPS: `${CLIENT_MENUS}.modifier-groups-create`,
  EDIT_MODIFIER_GROUPS: `${CLIENT_MENUS}.modifier-groups-edit`,
  BUNDLE: `${CLIENT_MENUS}.bundle-item`,
  CREATE_BUNDLE: `${CLIENT_MENUS}.bundle-create`,
  EDIT_BUNDLE: `${CLIENT_MENUS}.bundle-edit`,
  BUNDLE_GROUPS: `${CLIENT_MENUS}.bundle-groups`,
  CREATE_BUNDLE_GROUPS: `${CLIENT_MENUS}.bundle-groups-create`,
  EDIT_BUNDLE_GROUPS: `${CLIENT_MENUS}.bundle-groups-edit`,
  PRODUCTS: `${CLIENT_MENUS}.products`,
  PRODUCT_DETAILS: `${CLIENT_MENUS}.product-details`,
  EDIT_PRODUCT: `${CLIENT_MENUS}.product-edit`,
  CREATE_PRODUCT: `${CLIENT_MENUS}.product-create`,
  KITCHEN: `${CLIENT_MENUS}.dashboard.kitchen-details`,
  CREATE_KITCHEN: `${CLIENT_MENUS}.kitchen-create`,
  EDIT_KITCHEN: `${CLIENT_MENUS}.dashboard.kitchen-edit`,
  CREATE_FEES: `${CLIENT_MENUS}.fees-create`,
  EDIT_FEES: `${CLIENT_MENUS}.fees-edit`,
  DISCOUNT_CONTENT: `${CLIENT_MENUS}.dashboard.discount-content`,
  EDIT_DISCOUNT_CONTENT: `${CLIENT_MENUS}.discount-content-edit`,
  CREATE_DISCOUNT_CONTENT: `${CLIENT_MENUS}.discount-content-create`,
  ID_DETAIL_CONTENT: `${CLIENT_MENUS}.dashboard.id-detail-content`,
  EDIT_ID_DETAIL_CONTENT: `${CLIENT_MENUS}.id-detail-content-edit`,
  CREATE_ID_DETAIL_CONTENT: `${CLIENT_MENUS}.id-detail-content-create`,
  FEES: `${CLIENT_MENUS}.fees`,
};
