import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { stateServiceProvider, ToastService } from '../../../../ng1-factories';
import { CrContentModule, CrSpinnerModule, NavigationService } from '../../../../shared';
import { ImgHolderModule } from '../../../../shared/components/img-holder/img-holder.module';
import { KitchenDetailsModule } from '../kitchen-details/kitchen-details.module';
import { KitchenManageModule } from '../kitchen-manage/kitchen-manage.module';
import { KitchenMasterDetailsComponent } from './kitchen-master-details.component';

@NgModule({
  imports: [
    CommonModule,
    KitchenDetailsModule,
    KitchenManageModule,
    ImgHolderModule,
    CrContentModule,
    CrSpinnerModule,
  ],
  exports: [KitchenMasterDetailsComponent],
  declarations: [
    KitchenMasterDetailsComponent
  ],
  providers: [
    ToastService,
    stateServiceProvider,
    NavigationService
  ],
  entryComponents: [KitchenMasterDetailsComponent]
})
export class KitchenMasterDetailsModule { }
