<section>
  <header>
    <div class="heading-text">
      <span class="cancel-button"><span class="fab icon-cr-back"></span></span>
      <span class="placeholder-text">{{ text.order }}</span>
    </div>
  </header>

  <section class="featured-event-phone-container">
    <section class="toast">
      <div class="toast-content">
        <div class="close-button">
          <span class="icon-cr-close"></span>
          <span> {{ text.close }}</span>
        </div>
        <div
          [innerHtml]="featuredEvents?.idDetailTitle"
          *ngIf="featuredEvents?.idDetailTitle"
          class="id-require-text"
        ></div>
        <div
          [innerHtml]="featuredEvents?.idDetailDescription"
          *ngIf="featuredEvents?.idDetailDescription"
          class="featured-data"
        ></div>
        <div>
          <span>{{ text.declineText1 }}</span>
          <span class="id-text-bold">{{ text.declineText2 }}</span>
          <span>{{ text.declineText3 }}</span>
        </div>
        <div class="button-container">
          <div class="toast-button btn-accept">{{ text.acceptButtontext }}</div>
          <div class="toast-button btn-decline">{{ text.declineButtonText }}</div>
        </div>
      </div>
    </section>
  </section>
</section>
