import _ from 'lodash';

class ExperienceListService {
    constructor(crExperiencesService, crImageService) {
        this.crExperiencesService = crExperiencesService;
        this.crImageService = crImageService;
    }

    getData(params) {
        const queryParams = _.pickBy(params, (value) => !_.isNil(value) && value !== '' && value !== 'all');

        return this.crExperiencesService.getList(queryParams);
    }

    mapToRowsModel(experiences) {
        return experiences.map((auto) => ({
            data: {
                id: auto.id,
                name: auto.name,
                type: auto.trigger.id, // TODO: This should read labels from a dictionary
                appDisplay: auto.content.onHomeScreen ? 'Home' : 'Inbox',
                state: auto.status.toLowerCase(),
                modified: {
                    time: auto.modified,
                    username: auto.modifiedBy,
                },
                missingTranslations: _.isArray(auto.missingTranslations) ? auto.missingTranslations.join(', ') : '',
                imageSrc: _.get(auto, 'content.images.0')
                    ? this.crImageService.getImageSrc(_.get(auto, 'content.images.0'), 100, 100)
                    : null,
                originalData: auto,
            },
        }));
    }
}

export default ExperienceListService;
