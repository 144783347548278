import angular from 'angular';

import CalendarModule from './calendar/calendar';
import ChartModule from './chart/chart';
import TableModule from './table/table';
import SimpleTableModule from './simple-table/simple-table';
import CanvasMapModule from './canvas-map/canvas-map';
import CollapsiblePaneModule from './collapsible-pane/collapsible-pane';
import EventScheduleViewerModule from './event-schedule-viewer/event-schedule-viewer';
import BarGraphModule from './bar-graph/bar-graph';
import MetricBoxModule from './metric-box/metric-box';

const ComponentsModule = angular.module('control-room.commons.data.components', [
    CalendarModule.name,
    ChartModule.name,
    TableModule.name,
    SimpleTableModule.name,
    CanvasMapModule.name,
    CollapsiblePaneModule.name,
    EventScheduleViewerModule.name,
    BarGraphModule.name,
    MetricBoxModule.name,
]);

export default ComponentsModule;
