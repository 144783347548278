import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { POI, POIList, POIType } from '../../models/poi.model';
import { POIList as POIListCommerce } from '../../models/poi-commerce.model';

@Injectable({
  providedIn: 'root',
})
export class PoiService {
  private baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = '/rest';
  }

  async getPoi(poiId: string): Promise<POI> {
    const resp: POI = await this.http.get<POI>(`${this.baseUrl}/poi/${poiId}`).toPromise();

    return resp;
  }

  async getPoisPage(
    venueId: string,
    page = 1,
    sortBy = 'name',
    sort: 'asc' | 'desc' = 'asc',
    state = 'ACTIVE',
    perPage = 40,
  ): Promise<POIList> {
    const resp: POIList = await this.http
      .get<POIList>(`${this.baseUrl}/poi`, {
        params: {
          venueId, page: page.toString(), sortby: sortBy, sort, state, perPage: perPage.toString(),
        },
      })
      .toPromise();
    return resp;
  }

  async getPoiCategories(venueId: string, locale = 'en', additionalParams: { [param: string]: string } = null): Promise<any[]> {
    const config = {
      params: { ...additionalParams, venueId },
      headers: locale ? { 'Accept-Language': locale } : {},
    };

    const response: any = await this.http.get(`${this.baseUrl}/poi-categories/hierarchy`, config).toPromise();

    return response;
  }

  getPois(params: { [param: string]: string }): Promise<POIList> {
      return this.http.get<POIList>('/rest/poi', { params }).toPromise();
  }

  getPoisByCommerce(params: { [param: string]: string }): Promise<POIListCommerce> {
       params = {
            ...params,
            public: "true",
            commerceEnabled: "true"
       }
      return this.http.get<POIListCommerce>('/rest/poi', { params }).toPromise();
  }

  getPoiAttributes(params: { [param: string]: string }) {
      return this.http.get('/rest/poi-attributes', { params }).toPromise();
  }

  getPoiTypes(venueOrPlace: { [param: string]: string }): Promise<{ content: POIType[] }> {
      return this.http.get<{ content: POIType[] }>('/rest/poi-types', { params: venueOrPlace }).toPromise();
  }

  updatePoiState(poiId: string, poiData: any) {
      return this.http.put(`/rest/poi/${poiId}/state`, poiData).toPromise();
  }

  deletePoi(poiId: string) {
      return this.http.delete(`/rest/poi/${poiId}`).toPromise();
  }

  getPoiType(id: string) {
      return this.http.get(`/rest/poi-types/${id}`).toPromise();
  }

  createPoiType(name: string, owner: string) {
      return this.http.post('/rest/poi-types', { name, owner }).toPromise();
  }

  deletePoiType(id: string) {
      return this.http.delete(`/rest/poi-types/${id}`).toPromise();
  }

  updatePoiType(id: string, name: string) {
      return this.http.put(`/rest/poi-types/${id}`, { name }).toPromise();
  }
}
