import text from './resources/locale/en.json';

class Phone {
    $onInit() {
        this.text = text;
        this.refreshClass();
    }

    $onChanges(changes) {
        if (changes.links || changes.cta) {
            this.refreshClass();
        }
    }

    refreshClass() {
        this.dynamicStyle = {};
        this.dynamicStyle['has-cta'] = this.cta && this.cta.label && this.cta.link;
        if (this.links) {
            this.dynamicStyle[`has-links-${this.links.length}`] = true;
        }
        if (this.cta) {
            this.dynamicStyle[`has-links-${this.cta.length}`] = true;
        }
    }
}

export default Phone;
