import _ from 'lodash';
import Chartist from 'chartist';

class OrdersController {
    constructor(
        $q,
        $state,
        service,
        text,
        numberFilter,
        crConstants,
        colorPalette,
        crDateRangeService,
        crErrorLoggingService
    ) {
        this.$q = $q;
        this.$state = $state;
        this.service = service;
        this.text = text;
        this.numberFilter = numberFilter;
        this.crConstants = crConstants;
        this.colorPalette = colorPalette;
        this.crDateRangeService = crDateRangeService;
        this.crErrorLoggingService = crErrorLoggingService;
    }

    $onInit() {
        this.ordersPlacedOptions = {
            axisY: {
                labelInterpolationFnc: (value) => this.numberFilter(value, 0),
            },
            tooltip: {
                tooltipFnc: this.tooltipFnc.bind(this),
            },
            type: Chartist.FixedScaleAxis,
            low: 0,
        };

        this.topItemsPurchasedOptions = {
            axisY: {
                labelInterpolationFnc: (value) => this.numberFilter(value, 0),
            },
            tooltip: {
                tooltipFnc: this.tooltipBarFnc.bind(this),
            },
            rotateLabels: true,
        };

        this.intervalFilterData = this.crConstants.filterTypes.interval;
        this.channelFilterData = this.crConstants.filterTypes.channel;

        this.topPlaces = {
            columns: [{ label: 'Place' }, { label: 'Orders placed' }, { label: 'Revenue' }],
            rows: [],
        };

        this.bottomPlaces = {
            columns: [{ label: 'Place' }, { label: 'Orders placed' }, { label: 'Revenue' }],
            rows: [],
        };

        this.timeframe = {
            start: '',
            end: '',
        };

        this.interval = this.$state.params.interval || 'day';

        this.isLoadingOrdersAndItems = true;
        this.isLoadingTopAndBottomPlaces = true;

        this.channel = this.$state.params.channel;
    }

    $postLink() {
        this.isLinked = true;
        this.updateIntervalPicker();
        this.updateData();
    }

    rangeUpdated(event) {
        this.timeframe = event.range;

        if (this.isLinked) {
            this.updateIntervalPicker();
            this.updateData();
        }
    }

    updateIntervalPicker() {
        const intervals = this.crDateRangeService.getIntervalsForRange(this.timeframe);

        this.interval = intervals[0];

        _.forEach(this.intervalFilterData, (interval) => {
            interval.disabled = !_.includes(intervals, interval.id);
        });
    }

    // gotoOrdersPlacedTabularView() {
    //     const params = {
    //         customerId: this.$state.params.customerId
    //     };

    //     this.$state.go('performance.reporting.tabular.orders-placed', params);
    // }

    // gotoTopItemsTabularView() {
    //     const params = {
    //         customerId: this.$state.params.customerId
    //     };

    //     this.$state.go('performance.reporting.tabular.top-items', params);
    // }

    goBack() {
        const params = {
            customerId: this.$state.params.customerId,
        };

        this.$state.go('performance.reporting.list', params);
    }

    tooltipBarFnc(meta, value) {
        const txt = document.createElement('textarea');
        txt.innerHTML = !meta || meta === 'null' ? '0' : meta;
        meta = txt.value;

        return `<div class="cr-stat-title">${meta}</div>` + `<div><b>${this.numberFilter(value, 0)}</b></div>`;
    }

    tooltipFnc(meta, value) {
        const txt = document.createElement('textarea');
        txt.innerHTML = !meta || meta === 'null' ? '0' : meta;
        meta = txt.value;

        return (
            `<div class="cr-stat-title">${this.text.ordersPlaced.toUpperCase()}</div>` +
            `<div><b>${this.numberFilter(value, 0)}</b></div>` +
            `<div>$${this.numberFilter(meta, 0)}</div>`
        );
    }

    updateParam(param, value) {
        if (this[param] !== value.id) {
            this[param] = value.id;
            this.updateData();
        }
    }

    updateData() {
        this.getOrdersAndTopItems();
        this.getTopAndBottomPlaces();
    }

    getOrdersAndTopItems() {
        this.isLoadingOrdersAndItems = true;
        this.errorLoadingOrdersAndItems = false;

        this.service
            .getOrdersPlacedAndTopItemsPurchased(this.timeframe, this.channel, this.interval)
            .then((data) => {
                this.ordersPlaced = data.ordersPlaced;
                this.topItemsPurchased = data.topItemsPurchased;
            })
            .catch((e) => {
                this.errorLoadingOrdersAndItems = true;
                this.crErrorLoggingService.logError('Error calling getOrdersPlacedAndTopItemsPurchased', e);
            })
            .finally(() => {
                this.isLoadingOrdersAndItems = false;
            });
    }

    getTopAndBottomPlaces() {
        this.isLoadingTopAndBottomPlaces = true;
        this.errorLoadingTopAndBottomPlaces = false;

        const promises = [
            this.service.getTopPlaces(this.timeframe, this.channel),
            this.service.getBottomPlaces(this.timeframe, this.channel),
        ];

        this.$q
            .all(promises)
            .then((data) => {
                this.topPlaces = data[0];
                this.bottomPlaces = data[1];
            })
            .catch((e) => {
                this.errorLoadingTopAndBottomPlaces = true;
                this.crErrorLoggingService.logError('Could not get Top/Bottom places', e);
            })
            .finally(() => {
                this.isLoadingTopAndBottomPlaces = false;
            });
    }
}

export default OrdersController;
