import {
  Component, EventEmitter, Input, OnChanges, OnInit, Output,
} from '@angular/core';
import { StateService } from '@uirouter/core';
import bulletTrain from 'bullet-train-client';
import { LocalizedText } from '../../../../core';
import { Order } from '../../../../shared/models/order.model';
import { getOrderClass } from '../../helpers/order-class';

@Component({
  selector: 'cr-order-list-item',
  templateUrl: 'order-list-item.component.html',
  styleUrls: ['./order-list-item.component.scss'],
})

export class OrderListItemComponent implements OnInit, OnChanges {
  @Input() order: Order;

  @Input() text: LocalizedText;

  @Input() isSearching: boolean;

  @Input() writeAllowed: boolean;

  @Input() isPrintingEnabled: boolean;

  @Input() kitchenId: number;

  @Input() selectedTab: string;

  @Output() updateFilter = new EventEmitter<void>();

  @Output() updateTab = new EventEmitter<string>();

  isRefundsEnabled: boolean = bulletTrain.hasFeature('refunds');

  orderNoticeMessage = '';

  expanded = false;

  isChanging = false;

  isExpanded = false;

  orderAge;

  orderClassStyles;

  constructor(private state: StateService) { }

  ngOnInit(): void {
    this.orderClassStyles = getOrderClass(this.order);
    this.orderNoticeMessage = this.getNoticeMessage();
    this.orderAge = this.getOrderAge();
  }

  ngOnChanges(): void {
    this.orderClassStyles = getOrderClass(this.order);
    this.orderNoticeMessage = this.getNoticeMessage();
  }

  public dismissNotice(): void {
    this.order.notice = null;
    this.order.error = false;
    this.updateFilter.emit();
  }

  public selectTab(newTab: string): void {
    this.updateTab.emit(newTab);
  }

  onItemEdit(): void {
    this.orderClassStyles = getOrderClass(this.order);
  }

  onSwipeLeft(): void {
    if (this.writeAllowed && !this.order.isRefund) {
      this.order.editing = true;
      this.orderClassStyles = getOrderClass(this.order);
    }
  }

  onSwipeRight(): void {
    if (this.order.editing) {
      this.order.editing = false;
      this.orderClassStyles = getOrderClass(this.order);
    }
  }

  private getNoticeMessage(): string {
    return (this.text.orderConfirmedMessage as string)
      .replace('%NUMBER%', this.order.externalOrderId)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      .replace('%STATUS%', (this.text.status[this.order.fulfillmentStatus] as string).toLowerCase());
  }

  private getOrderAge(): string {
    const ageMs = Date.now() - this.order._date.getTime();
    const ageMinutes = Math.round(ageMs / (1000 * 60));
    if (ageMinutes < 60) {
      return (this.text.orderCreatedAgo as string).replace('%min%', `${ageMinutes}`);
    }
    const ageHours = Math.floor(ageMinutes / 60);
    return (this.text.orderCreatedAgoHours as string).replace('%min%', `${ageMinutes % 60}`).replace('%hr%', `${ageHours}`);
  }

  openOrderDetails(orderId: string):void {
    const { kitchenId, state } = this.state.params;
    this.state.go('client.fnb-order-queue.order-details', { kitchenId, state, orderId });
  }

  openRefundScreen(orderId: string):void {
    const { kitchenId, state } = this.state.params;
    this.state.go('client.fnb-order-queue.order-refund', { kitchenId, state, orderId });
  }

  onFulfillmentUpdate(): void {
    this.orderNoticeMessage = this.getNoticeMessage();
  }
}
