import controller from './experience-calendar.controller';
import template from './experience-calendar.html';

import './experience-calendar.less';

const component = {
    template,
    bindings: {},
    controller: [
        '$state',
        '$scope',
        '$http',
        'crConstants',
        'crExperiencesService',
        'crErrorLoggingService',
        'crNavigationService',
        'crVenueService',
        'crToastService',
        'crAnalyticsService',
        'crPoiService',
        controller,
    ],
};

export default component;
