import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  Order, OrderReceiptLineItem, OrderReceiptModifier, OrderReceiptSubItem,
} from '../../models/order.model';
import { ɵCodegenComponentFactoryResolver } from '@angular/core';

export interface JobRequestDTO {
  nonce?: string;
  externalOrderId: string;
  items: JobItemDTO[];
  pickupLocation?: string;
  hidePrinter: boolean;
  hideTime: boolean;
  customerName?: string;
  jobType: JobType;
  orderTotal?: number;
  refundAmount?: number;
  cardBrand?: string;
  cardLast4?: string;
  authCode?: string;
  orderCreated?: string;
  orderLastUpdated?: string;
}

export enum JobType {
  CHECKIN = 'CHECKIN',
  REFUND = 'REFUND',
}

export interface JobItemDTO {
  quantity: number;
  name: string;
  modifiers: string[];
  instructions?: string;
}

export interface JobResponseDTO {
  id: string;
  externalOrderId: string;
  hidePrinter: boolean;
  items: JobItemDTO[];
  hideTime: boolean;
  pickupLocation?: string;
  created: string;
  modified: string;
  state: string;
}

export interface PrinterResponseDTO {
  printers: PrinterDTO[];
}

export interface PrinterDTO {
  id: string;
  name: string;
  username: string;
  printOnCheckin: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class PrinterService {
  private baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = '/rest/printers';
  }

  async print(placeId: string, job: JobRequestDTO): Promise<JobResponseDTO> {
    return this.http.post<JobResponseDTO>(`${this.baseUrl}/places/${placeId}/jobs`, job).toPromise();
  }

  async printOrder(placeId: string, order: Order): Promise<JobResponseDTO> {
    const job: JobRequestDTO = this.mapOrderToJobRequest(order, JobType.CHECKIN);
    return this.print(placeId, job);
  }

  async printRefund(placeId: string, order: Order): Promise<JobResponseDTO> {
    const job: JobRequestDTO = this.mapOrderToJobRequest(order, JobType.REFUND);
    return this.print(placeId, job);
  }

  async getPrinters(placeId: string): Promise<PrinterResponseDTO> {
    return this.http.get<PrinterResponseDTO>(`${this.baseUrl}/places/${placeId}/printers`).toPromise();
  }

  mapOrderToJobRequest(order: Order, jobType: JobType): JobRequestDTO {   
    const job: JobRequestDTO = {
      externalOrderId: order.externalOrderId,
      pickupLocation: (order.orderReceipt && order.orderReceipt.pickupZoneLabel) || order.zone || null,
      hidePrinter: false,
      hideTime: false,
      customerName: order.customerName,
      items: order.orderReceipt && this.mapOrderLineItemsToJobItems(order.orderReceipt.lineItems),
      jobType,
      orderTotal: order.orderTotal,
      refundAmount: order.paymentAttributes && parseFloat(order.paymentAttributes.refundAmount),
      cardBrand: order.cardBrand,
      cardLast4: order.cardLast4,
      authCode: order.authCode,
      orderCreated: order.created && order.created.toString(),
      orderLastUpdated: order.lastUpdated && order.lastUpdated.toString()
    };
    return job;
  }

  // Each item in the order can be multiple lines on the receipt
  mapOrderLineItemsToJobItems(lineItems: OrderReceiptLineItem[]): JobItemDTO[] {
    const jobs: JobItemDTO[] = [];
    lineItems.forEach((lineItem) => {
      // Only bundles will display the parent line item
      if (lineItem.lineItemType === 'LINE_ITEM_BUNDLE') {
        jobs.push(this.mapBundleToJobItem(lineItem));
      }
      // Bundles and products will display the sub items
      // checking for Fees/Discounts which will never have subItems
      if(lineItem.subItems){
        lineItem.subItems.forEach((item) => {
          jobs.push(this.mapProductToJobItem(item));
        });
      }
    });
    return jobs;
  }

  mapProductToJobItem(subItem: OrderReceiptSubItem): JobItemDTO {
    const item: JobItemDTO = {
      quantity: subItem.quantity,
      name: subItem.displayName,
      modifiers: subItem.modifiers.map(this.mapModifierToString.bind(this)),
      instructions: subItem.specialInstructions,
    };
    return item;
  }

  mapBundleToJobItem(lineItem: OrderReceiptLineItem): JobItemDTO {
    const item: JobItemDTO = {
      quantity: lineItem.quantity,
      name: lineItem.displayName,
      modifiers: [],
      instructions: lineItem.specialInstructions,
    };
    return item;
  }

  mapModifierToString(modifier: OrderReceiptModifier): string {
    return modifier.action === 'default'
      ? modifier.displayName
      : `${modifier.action.toUpperCase()} ${modifier.displayName}`;
  }
}
