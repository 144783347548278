import QuestionManageController from './question-manage.controller';
import template from './question-manage.html';
import './question-manage.less';

const QuestionManageComponent = {
    template,
    bindings: {
        edit: '<?',
    },
    controller: [
        '$state',
        '$uibModal',
        '$q',
        '$timeout',
        '$sce',
        'crConfig',
        'crConstants',
        'crQuestionService',
        'crLocaleService',
        'crLocalizationService',
        'crNavigationService',
        'crErrorLoggingService',
        'crAnalyticsService',
        'crToastService',
        QuestionManageController,
    ],
};

export default QuestionManageComponent;
