import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CrExportButtonModule, CrFormsModule, CrSpinnerModule, PrinterService } from '../../../../shared';
import { OrderHistoryService } from '../../../../shared/services/order-history/order-history.service';
import { CrTitleBarModule } from '../../../../shared/title-bar/title-bar.module';
import { OrderListItemModule } from '../order-list-item/order-list-item.module';
import { OrderHistoryComponent } from './order-history.component';

@NgModule({
  imports: [CrTitleBarModule, CrFormsModule, OrderListItemModule, CommonModule, CrSpinnerModule, CrExportButtonModule],
  exports: [],
  declarations: [OrderHistoryComponent],
  entryComponents: [OrderHistoryComponent],
  providers: [OrderHistoryService, PrinterService],
})
export class OrderHistoryModule { }
