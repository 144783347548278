import Controller from './nav-panel.controller';
import template from './nav-panel.html';
import './nav-panel.less';

const Component = {
    template,
    bindings: {
        slim: '<',
        disabled: '<?',
        title: '@',
        features: '<',
        categoryActions: '<',
    },
    controllerAs: 'navPanelCtrl',
    controller: ['$state', '$window', '$transitions', 'crAnalyticsService', 'crConfig', 'crPolicyService', Controller],
    transclude: true,
};

export default Component;
