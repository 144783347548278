class KitchenManageService {
    constructor(crConfigurationService) {
        this.crConfigurationService = crConfigurationService;
    }

    getConfigOptions(customerId) {
        return this.crConfigurationService.getConfigurationOptions({ customerId });
    }

    getConfigOptionsForZones(customerId) {
        return this.crConfigurationService.getConfigurationForZones({ customerId }).then((result) => {
            const configOptionsForZones = [];
            Object.keys(result.configuration).forEach((key) => {
                const parts = key.split('.');
                const name = parts[2];
                const value = result.configuration[key];
                const index = Number(parts[1]) - 1;
                if (!configOptionsForZones[index]) {
                    configOptionsForZones[index] = {};
                }
                configOptionsForZones[index][name] = value;
            });
            return configOptionsForZones;
        });
    }
}

export default KitchenManageService;
