import controller from './required-field.controller';
import template from './required-field.html';
import './required-field.less';

const RequiredFieldComponent = {
    template,
    bindings: {
        customClass: '@?',
    },
    controller,
};

export default RequiredFieldComponent;
