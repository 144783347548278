import ExtensionsBuilderController from './extensions-builder.controller';
import template from './extensions-builder.html';

const ExtensionsBuilderComponent = {
    template,
    bindings: {
        model: '=',
        onChange: '&',
    },
    require: {
        form: '^form',
    },
    controller: [ExtensionsBuilderController],
};

export default ExtensionsBuilderComponent;
