import {
  Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges
} from '@angular/core';
import { StateService } from '@uirouter/core';
import moment from 'moment';
import { LocalizedText } from '../../../../../core';
import { Order } from '../../../../../shared/models/order.model';

@Component({
  selector: 'cr-order-list-item-details',
  templateUrl: 'order-list-item-details.component.html',
  styleUrls: ['./order-list-item-details.component.scss'],
})

export class OrderListItemDetailsComponent implements OnInit, OnChanges {
  @Input() text: LocalizedText;

  @Input() order: Order;

  @Input() kitchenId: number;

  @Input() orderClassStyles;

  @Input() writeAllowed = true;

  @Input() isRefundsEnabled: boolean;

  @Input() isPrintingEnabled: boolean;

  @Output() orderEdit = new EventEmitter<void>();

  orderAge;

  isExpanded = false;

  ptwStartTimeDisplay: string;
  ptwEndTimeDisplay: string;

  constructor(private state: StateService) {}

  ngOnInit(): void {
    this.orderAge = this.getOrderAge();
    this.startUpdateTimer();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.order && changes.order.currentValue !== changes.order.previousValue)
      this.convertPtwTime();
  }

  convertPtwTime(): void {
    if(this.order.pickupWindow && this.order.pickupWindow.startTime && this.order.pickupWindow.endTime) {
      this.ptwStartTimeDisplay = moment(this.order.pickupWindow.startTime, 'HH:mm').format('h:mm A');
      this.ptwEndTimeDisplay = moment(this.order.pickupWindow.endTime, 'HH:mm').format('h:mm A');
    }
  }

  editOrder(): void {
    if (this.writeAllowed) {
      this.order.editing = !this.order.editing;
      this.orderEdit.emit();
    }
  }

  openOrderDetails(): void {
    const { kitchenId, state } = this.state.params;
    const { orderId } = this.order;
    this.state.go('client.fnb-order-queue.order-details', { kitchenId, state, orderId });
  }

  openRefundScreen(): void {
    const { kitchenId, state } = this.state.params;
    const { orderId } = this.order;
    this.state.go('client.fnb-order-queue.order-refund', { kitchenId, state, orderId });
  }

  private startUpdateTimer(): void {
    const toExactMinute = 60000 - (new Date().getTime() % 60000);
    setInterval(() => {
      this.orderAge = this.getOrderAge();
    }, toExactMinute);
  }

  private getOrderAge(): string {
    const ageMs = Date.now() - this.order._date.getTime();
    const ageMinutes = Math.round(ageMs / (1000 * 60));
    if (ageMinutes < 60) {
      return (this.text.orderCreatedAgo as string).replace('%min%', `${ageMinutes}`);
    }
    const ageHours = Math.floor(ageMinutes / 60);
    if (ageHours > 24) {
      return '';
    }
    return (this.text.orderCreatedAgoHours as string).replace('%min%', `${ageMinutes % 60}`).replace('%hr%', `${ageHours}`);
  }
}
