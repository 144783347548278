import {
  Directive, ElementRef, Injector, Input,
} from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

// TODO Remove and get an Angular tooltip component
@Directive({
  selector: 'cr-tooltip-wrapper',
})
export class TooltipWrapperDirective extends UpgradeComponent {
  @Input() tooltipAppendToBody: boolean;

  @Input() tooltipPlacement: string;

  @Input() tooltipText: string;

  constructor(elementRef: ElementRef, injector: Injector) {
    super('crTooltipWrapper', elementRef, injector);
  }
}
