import controller from './broadcast-select.controller';
import template from './broadcast-select.html';

import './broadcast-select.less';

const component = {
    template,
    bindings: {},
    controller: [
        '$state',
        'crConstants',
        'crAnalyticsService',
        'crNavigationService',
        'crExperiencesService',
        controller,
    ],
};

export default component;
