import PoiDetailsController from './poi-details.controller';
import template from './poi-details.html';
import './poi-details.less';

const PoiDetailsComponent = {
    template,
    controller: [
        '$q',
        '$state',
        '$uibModal',
        'crEntityService',
        'crLocaleService',
        'crPoiDetailsService',
        'crNavigationService',
        'crErrorLoggingService',
        'crGraphicalMapService',
        'crConstants',
        'crImageService',
        'crToastService',
        'crPoiConstants',
        PoiDetailsController,
    ],
};

export default PoiDetailsComponent;
