import text from './resources/locale/en.json';
import ListPage from '../../../../commons/controller/list-page';
import { menuRoutes } from '../../menus.routes';

class ModifierGroupsList extends ListPage {
    constructor(
        $state,
        crErrorLoggingService,
        crAnalyticsService,
        crToastService,
        crLocaleService,
        crConfig,
        crNavigationService,
        crModifierGroupsListService
    ) {
        super($state, crErrorLoggingService, crAnalyticsService, crToastService, crLocaleService, crConfig);
        this.crNavigationService = crNavigationService;
        this.crModifierGroupsListService = crModifierGroupsListService;
    }

    $onInit() {
        super.$onInit();
        this.text = text;
        this.menuId = this.$state.params.menuId;
        this.placeId = this.$state.params.placeId;
        this.params.placeId = this.placeId;

        this.listData = {
            columns: [
                { key: '', label: '' },
                { key: 'name', label: this.text.name, class: 'large-column name-column', sortable: true },
                { key: 'displayName', label: this.text.modifierGroupLabel, class: 'large-column' },
                { key: 'modifiers', label: this.text.modifiers },
                { key: '', label: '', class: 'small-column' },
            ],
            rows: [],
        };

        if (this.hasMultipleLanguages()) {
            this.addMissingTranslationsColumnHeader();
        }
    }

    getData() {
        this.isLoading = true;
        this.dataError = false;

        return this.crModifierGroupsListService
            .getData(this.menuId, this.params)
            .then((data) => {
                data.search = data.query.search || '';

                if (data.search === this.params.search) {
                    this.updateSearchDeepLink();
                    return this.crModifierGroupsListService.mapToRowsModel(data.content);
                }
            })
            .catch((err) => {
                this.dataError = true;
                this.endOfResults = true;
                this.listData.rows = [];
                this.crErrorLoggingService.logError('Could not get modifier groups', err);
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    goToCreatePage(menuId = this.menuId, placeId = this.placeId) {
        this.$state.go(menuRoutes.CREATE_MODIFIER_GROUPS, { menuId, placeId });
    }

    goBack(menuId = this.menuId, placeId = this.placeId) {
        this.crNavigationService.goBack(menuRoutes.PRODUCTS, { menuId, placeId });
    }
}

export default ModifierGroupsList;
