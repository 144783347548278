import Controller from './action-summary-modal.controller';
import template from './action-summary-modal.html';
import './action-summary-modal.less';

const Component = {
    template,
    bindings: {
        close: '&',
        dismiss: '&',
        resolve: '<',
    },
    controller: ['$sce', Controller],
};

export default Component;
