import controller from './who.controller';
import template from './who.html';

import './who.less';

const component = {
    template,
    bindings: {
        experience: '<',
        experienceType: '<',
        tagDefinitions: '<',
        pois: '<',
        poiCategories: '<',
        menuItems: '<',
        menuCategories: '<',
        associatedEntities: '<',
        edit: '<',
        form: '<',
        group: '<?',
        geofences: '<?',
    },
    controller: ['$sce', '$state', '$timeout', '$window', 'crConstants','crVenueService','crConfig','crPolicyService', controller],
};

export default component;
