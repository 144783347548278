import text from './resources/locale/en.json';

import ListPageController from '../../../../commons/controller/list-page';

class BeaconsListController extends ListPageController {
    constructor(
        $state,
        crErrorLoggingService,
        crAnalyticsService,
        crToastService,
        crLocaleService,
        crBeaconsListService,
        crConstants,
        crEntitySelectionManager
    ) {
        super($state, crErrorLoggingService, crAnalyticsService, crToastService, crLocaleService);
        this.$state = $state;
        this.crBeaconsListService = crBeaconsListService;
        this.crErrorLoggingService = crErrorLoggingService;
        this.crToastService = crToastService;
        this.crConstants = crConstants;
        this.crEntitySelectionManager = crEntitySelectionManager;
    }

    $onInit() {
        super.$onInit();

        this.text = text;
        const { columns } = this.text;

        this.listData = {
            columns: [
                { key: 'placeholder', label: '' },
                { key: 'name', label: columns.name, class: 'large-column name-column', sortable: true },
                { key: 'zone', label: columns.zone },
                { key: 'associatedPoi', label: columns.associatedPoi },
                { key: 'uuid', label: columns.uuid },
                { key: 'major', label: columns.major, class: 'medium-column' },
                { key: 'minor', label: columns.minor, class: 'medium-column' },
                { key: 'editButton', label: '', class: 'small-column' },
            ],
            rows: [],
        };
    }

    getData() {
        this.isLoading = true;
        this.dataError = false;

        return this.crBeaconsListService
            .getData(this.params)
            .then((data) => {
                data.search = data.query.search || '';

                if (data.search === this.params.search) {
                    this.isLoading = false;
                    this.updateSearchDeepLink();

                    return this.crBeaconsListService.mapToRowsModel(data.content);
                }
            })
            .catch((err) => {
                this.isLoading = false;
                this.dataError = true;
                this.listData.rows = [];
                this.crErrorLoggingService.logError('Could not get beacons list data', err);
            });
    }
}

export default BeaconsListController;
