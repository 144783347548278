import _ from 'lodash';

class IdentityBarController {
    constructor(crConfig, $transitions, $state, $window, crAnalyticsService, crNavigationService) {
        this.crConfig = crConfig;
        this.$transitions = $transitions;
        this.$state = $state;
        this.$window = $window;
        this.crAnalyticsService = crAnalyticsService;
        this.crNavigationService = crNavigationService;
    }

    $onInit() {
        // TODO: https://te2web.atlassian.net/browse/CMP-1722
        // add route for cvt module
        const internalModule = { route: 'cvt.dashboard' };
        this.categoryActions[0].actions.push(internalModule);

        this.hideVenueMenu = true;
        this.initVenueSelector();
    }

    $onDestroy() {
        this.cleanupVenueHook();
    }

    initVenueSelector() {
        if (this.crConfig.customer.type === 'venues') {
            this.hideVenueMenu = false;
            this.venues = this.crConfig.customer.venues;
            this.venueMap = _.keyBy(this.venues, 'id');

            this.setVenueMenu();

            this.cleanupVenueHook = this.$transitions.onSuccess({}, () => {
                this.setVenueMenu();
            });
        }
    }

    setVenueMenu() {
        const stateVenue = this.venueMap[this.$state.params.venueId];
        const localVenue = this.$window.localStorage.getItem('venueId');
        const firstVenue = this.venues[0];

        if (stateVenue) {
            this.selectedVenue = stateVenue;
            this.$window.localStorage.setItem('venueId', stateVenue.id);
        } else if (localVenue) {
            this.selectedVenue = localVenue;
            this.$window.localStorage.setItem('venueId', localVenue.id);
        } else {
            this.selectedVenue = firstVenue;
            this.$window.localStorage.setItem('venueId', firstVenue.id);
        }

        this.hideVenueMenu =
            this.$state.current.name === 'client.classic' ||
            this.$state.current.name === 'client.404' ||
            this.$state.current.name === 'client.access-denied';
    }

    selectVenue(venue) {
        let routeName = this.$state.current.name;
        if (this.categoryActions) {
            const mainModuleName = routeName.split('.')[1];
            if (mainModuleName) {
                let actionMatch;
                this.categoryActions.forEach((category) => {
                    actionMatch =
                        actionMatch ||
                        _.find(category.actions, (action) => action.route.indexOf(mainModuleName) !== -1);
                });

                routeName = actionMatch ? actionMatch.route : routeName;
            }
        }

        this.$window.localStorage.setItem('venueId', venue.id);

        const params = {
            venueId: venue.id,
            customerId: this.$state.params.customerId,
        };

        const { venueName } = _.find(this.venues, { id: venue.id });
        this.crAnalyticsService.track('Venue Selected', { venueName });

        this.crNavigationService.clearHistory();
        this.$state.go(routeName, params, { inherit: false });
    }

    handleMenuSelect() {
        this.onToggle();
    }
}

export default IdentityBarController;
