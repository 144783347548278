import Controller from './calendar-all-day.controller';
import template from './calendar-all-day.html';
import './calendar-all-day.less';

const Component = {
    template,
    require: {
        calendar: '^crCalendar',
    },
    bindings: {
        events: '<',
    },
    controller: ['$state', 'crConstants', Controller],
};

export default Component;
