<section class="discount-content-preview">
  <section class="discount-content-container">
    <div *ngIf="isLoading" top>
      <div class="text-center cr-page-padding-top">
        <cr-spinner></cr-spinner>
      </div>
    </div>
    <section class="icon-container"></section>
    <div class="discount-label-header">
      <span class="title">
        {{ discountsLabel }}
      </span>
      <span
        class="icon-cr-edit cursor-pointer edit-icon"
        data-local-qa-id="edit-discount-web-content"
        (click)="goToEditPage()"
      ></span>
    </div>
    <hr class="" />
    <div class="secondary-header">
      <div class="text-label">{{ text.aboutDiscountMessaging }}</div>
    </div>
    <div class="attribute">
      <div class="text-label">{{ text.title }}</div>
      <div>{{ phonePreview?.discountTitle }}</div>
    </div>
    <div class="attribute">
      <div class="text-label">{{ text.description }}</div>
      <div
        class="discount-description"
        [innerHtml]="phonePreview?.discountDescription"
        *ngIf="phonePreview?.discountDescription"
      ></div>
    </div>
  </section>
  <section class="discount-mobile-preview">
    <cr-phone>
      <cr-discount-content-phone-preview [featuredEvents]="phonePreview">
      </cr-discount-content-phone-preview>
    </cr-phone>
  </section>
</section>
