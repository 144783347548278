import { Component, Input, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { StateService } from '@uirouter/core';
import moment from 'moment';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { take } from 'rxjs/operators';
import { LocalizedText } from '../../../../core';
import { Order, PolicyService } from '../../../../shared';
import { PTWCapacity, PTWCapacityOverride } from '../../../../shared/services/ptw/ptw.model';
import { PTWService } from '../../../../shared/services/ptw/ptw.service';
import text from './resources/en.json';

interface WindowInfo {
  title: string,
  data: number
}

interface ModalConfig {
  animated?: boolean,
  keyboard?: boolean,
  backdrop?: boolean,
  ignoreBackdropClick?: boolean,
  class?: string
}

@Component({
  selector: 'cr-ptw-override',
  templateUrl: './ptw-override.component.html',
  styleUrls: ['./ptw-override.component.scss']
})
export class PtwOverrideComponent {

  @Input() modalRef: BsModalRef

  @Input() orders: Order[]

  @ViewChild('removeAllInfo', { static: true }) removeAllInfoModal: ElementRef;

  ptws: PTWCapacity[]

  removeAllModalRef: BsModalRef

  removeAllInfoModalRef: BsModalRef

  text: LocalizedText

  date: string;

  windowInformation: WindowInfo[]

  selectedPTW: PTWCapacity

  selectedPTWOrders: Order[]

  selectedWindowInfo: WindowInfo[] = []

  statusWindowInfo: WindowInfo[] = []

  enoughCapacity10 = false;

  enoughCapacity5 = false;

  enoughCapacity1 = false;

  isFnbAdmin =  false;

  submittedOrderCount = 0;

  ptwRemoveInfoModal : ModalConfig = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: false,
    class: "ptw-remove-info-modal"
  }

  ptwRemoveActionModal : ModalConfig = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: false,
    class: "ptw-remove-action-modal"
  }


  constructor(
    private ptwService: PTWService, 
    private modalService: BsModalService, 
    private state: StateService, 
    private crPolicyService: PolicyService
  ) {
    this.text = text;
    this.date = moment().format('L')
    this.ptwService.getPTWByPlace(this.state.params.kitchenId).pipe(take(1)).subscribe((res) => {
      this.ptws = res;
      this.selectedPTW = this.ptws[0]
      this.selectedPTW.selected = true;
      this.gatherDataFromSelectedPtwOrders();
      this.calculateTotalSubmittedOrders();
    })
    this.crPolicyService.hasRole('fnb_admin').then(res => this.isFnbAdmin = res);
    
  }


  selectPTW(ptw: PTWCapacity): void {
    this.selectedPTW.selected = false;
    this.selectedPTW = ptw;
    this.selectedPTW.selected = true;
    this.selectedPTWOrders = this.orders.filter((order) => order.pickupWindowId === ptw.windowId)

    this.gatherDataFromSelectedPtwOrders()
    
  }
  
  gatherDataFromSelectedPtwOrders(): void {
    // reset data
    this.selectedWindowInfo = []
    this.statusWindowInfo = []
    this.determineCapacity();
    this.submittedItems()
    this.totalSelectedOrders();
    this.totalItems();
    this.orderStates();
  }

  overridePTW(capacity: number): void {
    const adjustedCapacity = this.selectedPTW.capacity + capacity;
    this.selectedPTW = {...this.selectedPTW, capacity: adjustedCapacity}

    const capacityRequest: PTWCapacityOverride[] = [{
      capacity: this.selectedPTW.capacity,
      windowId: this.selectedPTW.windowId
    }]

    this.ptwService.overridePTWbyPlace(this.state.params.kitchenId, capacityRequest).pipe(take(1)).subscribe((res) => {
      this.ptws = res;
      
    })
  }

  determineCapacity(): void {
    this.enoughCapacity10 = this.enoughCapacity(-10)
    this.enoughCapacity5 = this.enoughCapacity(-5)
    this.enoughCapacity1 = this.enoughCapacity(-1)
  }

  enoughCapacity(amount: number): boolean {
    if(this.selectedPTW) {
      // dont reduce capacity below 0
      if(this.selectedPTW.capacity + amount < 0) {
        return false;
      }
      // dont reduce capacity below current allocation
      if(this.selectedPTW.capacity + amount < this.selectedPTW.allocation) {
        return false;
      }
    }

    return true
  }

  submittedItems(): WindowInfo {
    const reducer = (sum: number, val:Order) => {
      let total = 0;
      if(val.orderStatus === 'SUBMITTED'){
        val.products.forEach((p) => {
          total = total + p.quantity;
        })
      }

      return total + sum
    };
    let items: number;
    if(this.selectedPTWOrders) {
      items = this.selectedPTWOrders.reduce(reducer, 0)
    } else {
      items = 0
    }

    const info: WindowInfo = {
      title: "Submitted Items",
      data: items
    }

    this.selectedWindowInfo.push(info);
    
    return info;
  }

  totalItems(): WindowInfo {
    const reducer = (sum: number, val:Order) => {
      let total = 0;
        val.products.forEach((p) => {
          total = total + p.quantity;
        })

      return total + sum
    };
    let items: number;
    if(this.selectedPTWOrders) {
      items = this.selectedPTWOrders.reduce(reducer, 0)
    } else {
      items = 0
    }

    const info: WindowInfo = {
      title: "Total Items",
      data: items
    }

    this.selectedWindowInfo.push(info);
    
    return info;
  }

  orderStates(): void {

    const submittedInfo: WindowInfo = {
      title: "Status Submitted",
      data: this.selectedPTWOrders ? this.selectedPTWOrders.filter(o => o.orderStatus === "SUBMITTED").length : 0
    }

    this.statusWindowInfo.push(submittedInfo)

    const checkedInInfo: WindowInfo = {
      title: "Status Checked-in",
      data: this.selectedPTWOrders ? this.selectedPTWOrders.filter(o => o.fulfillmentStatus === "UNFULFILLED" && o.orderStatus === "RELEASED").length : 0
    }

    this.statusWindowInfo.push(checkedInInfo)


    const completedInfo: WindowInfo = {
      title: "Status Completed",
      data: this.selectedPTWOrders ? this.selectedPTWOrders.filter(o => o.fulfillmentStatus === "FULFILLED").length : 0
    }

    this.statusWindowInfo.push(completedInfo)

    const refundedInfo: WindowInfo = {
      title: "Status Refunded",
      data: this.selectedPTWOrders ? this.selectedPTWOrders.filter(o => o.paymentStatus === "REFUNDED" || o.paymentStatus === 'PARTIAL_REFUND_APPLIED').length : 0
    }

    this.statusWindowInfo.push(refundedInfo)

  }

  totalSelectedOrders(): WindowInfo {
    const info: WindowInfo = {
      title: "Total Orders",
      data: this.selectedPTWOrders ? this.selectedPTWOrders.length : 0
    }

    this.selectedWindowInfo.push(info)
    return info
  }

  calculateTotalSubmittedOrders(): void {
    const byRefunded = (ord: Order) => ord.paymentStatus === 'REFUNDED' || ord.paymentStatus === 'PARTIAL_REFUND_APPLIED';
    const byFulfillment = (status) => (ord: Order) => ord.fulfillmentStatus === status && !byRefunded(ord) && ord.orderStatus !== 'SUBMITTED';
    const byOrderStatus = (status) => (ord: Order) => ord.orderStatus === status;
    this.submittedOrderCount = this.orders.filter(byOrderStatus('SUBMITTED')).length + this.orders.filter(byFulfillment('UNFULFILLED')).length;
  }

  onRemoveAll() {
    this.modalRef.hide();
    this.removeAllInfoModalRef = this.modalService.show(this.removeAllInfoModal, this.ptwRemoveInfoModal);
  }

  openModal(template: TemplateRef<any>, config: ModalConfig) {
    this.removeAllModalRef = this.modalService.show(template, config);
  }

}
