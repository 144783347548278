import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  baseUrl = '/rest/events';

  constructor(private http: HttpClient) {}

  getList(params?: { [key: string]: string }, locale?: string): Promise<object> {
    const headers = {};
    if (locale) {
      headers['Accept-Language'] = locale;
    }

    return this.http.get(this.baseUrl, { params, headers }).toPromise();
  }
}
